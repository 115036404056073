import React, { useContext, useEffect, useState } from 'react';

import { IDashboardUser } from 'common/interfaces';
import Card from 'components/card';
import Header from 'components/header';
import { ContentContainer, MainContainer } from 'pages/styles';
import { AuthContext } from 'store/contexts/AuthContext';

import { Box } from '@mui/material';

import { CustomerSalesOrderStatus } from './dashboard.order-status';
import { CustomerSalesBackorder } from './dashboard.sales-backorder';
import { CustomerSalesHeatmap } from './dashboard.sales-map';

function CustomerDashboard() {
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const defUser: IDashboardUser = {
    customerId: 0,
    customerLocationId: 0,
    customerFacilityId: 0,
  };
  const [dashboardUser, setDashboardUser] = useState<IDashboardUser>(defUser);
  useEffect(() => {
    if (currentUser && currentLocationAndFacility) {
      setDashboardUser((prev) => ({
        ...prev,
        customerId: currentUser.Claim_WarehouseCustomerId,
        customerLocationId: currentLocationAndFacility.customerLocationId,
        customerFacilityId: currentLocationAndFacility.customerFacilityId,
      }));
    }
  }, [currentUser, currentLocationAndFacility]);
  return (
    <MainContainer>
      <Header />
      <ContentContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row' },
              width: '100%',
              gap: '12px',
            }}
          >
            <Card
              sx={{ width: { xs: '100%', lg: '70%' }, position: 'relative' }}
            >
              <CustomerSalesHeatmap
                customerId={dashboardUser.customerId}
                customerLocationId={dashboardUser.customerLocationId}
                customerFacilityId={dashboardUser.customerFacilityId}
              />
            </Card>
            <Card sx={{ width: { xs: '100%', lg: '30%' } }}>
              <CustomerSalesOrderStatus
                customerId={dashboardUser.customerId}
                customerLocationId={dashboardUser.customerLocationId}
                customerFacilityId={dashboardUser.customerFacilityId}
              />
            </Card>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row' },
              width: '100%',
              minHeight: '40%',
              gap: '12px',
              paddingBottom: 5,
            }}
          >
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '100%' },
                gap: '8px',
              }}
            >
              <CustomerSalesBackorder
                customerId={dashboardUser.customerId}
                customerLocationId={dashboardUser.customerLocationId}
                customerFacilityId={dashboardUser.customerFacilityId}
                to="warehouse-customer-inventory"
              />
            </Card>
          </Box>
        </Box>
      </ContentContainer>
    </MainContainer>
  );
}

export default React.memo(CustomerDashboard);
