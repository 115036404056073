import { memo, useContext, useEffect, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import { PrinterSettingsState } from 'pages/settings/tabviews/PrinterSettings';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import {
  batchshippinglogs,
  getBatchshippingDDL,
  postBatchshipping,
} from 'services/api/packandship/packandship.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Autocomplete,
  Box,
  Modal,
  TextField,
  Typography,
  Grid as MUIGrid,
  Button,
  CircularProgress,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';

import { DatagridPremium } from 'styles/mui/datagrid/premium';

import { usePickQueueDataTableViewContext } from '..';

export const BatchShippingLogStatusEnum = {
  1: 'Picking',
  2: 'Packing',
  3: 'Shipping',
  4: 'Complete Order',
  5: 'Send Fulfillment',
  6: 'Print Label',
  7: 'Complete',
};

interface AutoCompleteModel {
  value?: number;
  text?: string;
}

type CarrierTypeModel = {
  isShippingRate: boolean;
  serviceCode?: string;
  carrierCode?: string;
};
type CarrierServiceTypeModel = {
  id: number;
};
type PackageTypeModel = {
  acceptsVoidFill: boolean;
  carrier: string;
  carrierId: number;
  dimensions: string;
  isExternalPackage: boolean;
  packageType: string;
};

type CarrierType = CarrierTypeModel & AutoCompleteModel;
type CarrierServiceType = CarrierServiceTypeModel & AutoCompleteModel;
type PackageType = PackageTypeModel & AutoCompleteModel;
type VoidFillType = AutoCompleteModel;

type BatchShipModel = {
  carrier?: CarrierType;
  carrierService?: CarrierServiceType;
  package?: PackageType;
  voidFill?: VoidFillType;
  packageWeight?: string;
};
export default memo(() => {
  const { currentLocationAndFacility } = useContext(AuthContext);

  const {
    isBatchShipModalOpen,
    setIsBatchShipModalOpen,
    isBatchShipLogErrorModalOpen,
    setIsBatchShipLogErrorModalOpen,
  } = useContext(GlobalContext);

  const { rowSelectionModel, setRowSelectionModel, onLoadPickingQueue } =
    usePickQueueDataTableViewContext();

  // DROPDOWNS ---------------------------------------------------------------
  const [optionCarriers, setOptionCarriers] = useState<
    CarrierType[] | null | []
  >([]);
  const [optionCarrierServices, setOptionCarrierServices] = useState<
    CarrierServiceType[] | null | []
  >([]);
  const [optionPackageCartonCodes, setOptionPackageCartonCodes] = useState<
    PackageType[] | null | []
  >([]);
  const [optionShipVoidFill, setOptionShipVoidFill] = useState<VoidFillType[]>(
    [],
  );

  // TEMPORARY CONTAINER -----------------------------------------------------
  const [optionCarrierServicesTemp, setOptionCarrierServicesTemp] = useState<
    CarrierServiceType[] | null | []
  >([]);
  const [optionPackageCartonCodesTemp, setOptionPackageCartonCodesTemp] =
    useState<PackageType[] | null | []>([]);

  // MODELS  ------------------------------------------------------------------
  const [batchShipForm, setBatchShipForm] = useState<BatchShipModel>({});
  const [batchShipFormError, setBatchShipFormError] = useState(null);

  const [batchShipDataGridError, setBatchShipDataGridError] = useState(null);
  const [batchShiploading, setBatchShiploading] = useState(false);

  const onLoadgetBatchshippingDDL = async (warehouseCustomerId) => {
    try {
      const result = await getBatchshippingDDL(warehouseCustomerId);
      return result;
    } catch (err) {
      return err;
    }
  };

  const getStatusDescription = (statusValue) =>
    BatchShippingLogStatusEnum[statusValue] || 'Unknown Status';

  const safeParseJSON = (jsonString) => {
    if (typeof jsonString === 'string') {
      try {
        return JSON.parse(jsonString);
      } catch (error) {
        console.error('Failed to parse JSON:', error);
      }
    }
    return jsonString; // Return the original object if it's not a string
  };

  const batchShipErrorColumns: GridColDef[] = [
    {
      field: 'pickTicketNo',
      width: 100,
      flex: 0.5,
      headerName: 'Pick Ticket No',
    },
    {
      field: 'message',
      width: 100,
      flex: 1,
      headerName: 'Error Message',
      valueGetter: (params) => {
        const logDetails = safeParseJSON(params.row.message);
        return logDetails?.MessageLogs?.[0]?.Message || 'No message';
      },
    },
    {
      field: 'status',
      width: 70,
      flex: 0.5,
      headerName: 'Status',
      valueGetter: (params) => getStatusDescription(params.row.status),
    },
  ];

  const handleOnCloseModal = async () => {
    setBatchShipFormError((prevState) => ({
      ...prevState,
      carrier: '',
      carrierService: '',
      package: '',
      voidFill: '',
    }));

    setIsBatchShipModalOpen(false);
    setBatchShipForm({});
    setBatchShipDataGridError(null);
  };

  const batchShipValidation = () => {
    const temp: any = {};

    temp.carrier = batchShipForm?.carrier ? '' : 'Please select a carrier.';
    temp.carrierService = batchShipForm?.carrierService
      ? ''
      : 'Please select a carrier service.';
    temp.package = batchShipForm?.package ? '' : 'Please select a package.';

    if (batchShipForm?.package?.acceptsVoidFill === true) {
      temp.voidFill = batchShipForm?.voidFill
        ? ''
        : 'Please select a void fill.';
    }

    setBatchShipFormError({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const handleOnSave = async (e: any) => {
    e.preventDefault();

    if (!batchShipValidation()) {
      return;
    }
    setBatchShiploading(true);
    try {
      const stateFromLocalStorage = localStorage?.getItem(
        'printerSettingsState',
      );

      const printerSettingsState = JSON.parse(
        stateFromLocalStorage,
      ) as PrinterSettingsState;

      const payload = {
        CustomerLocationId: currentLocationAndFacility.customerLocationId,
        CustomerFacilityId: currentLocationAndFacility.customerFacilityId,
        PickTicketIds: rowSelectionModel,
        CarrierId: batchShipForm.carrier?.value,
        CarrierServiceId: batchShipForm.carrierService?.value,
        PackageId: batchShipForm.package?.value,
        Package_IsExternalPackage: batchShipForm.package?.isExternalPackage,
        ItemAsVoidFillId: batchShipForm.voidFill?.value,
        PackageWeight: batchShipForm.packageWeight,
        PrinterId: printerSettingsState?.printerId,
      };

      postBatchshipping(payload)
        .then(() => {
          setIsBatchShipModalOpen(false);
          snackActions.success('Batch shipping successfully created!');
          onLoadPickingQueue();
          handleOnCloseModal();
        })
        .catch((err) => {
          snackActions.error(err);
          batchshippinglogs({ PickTicketIds: rowSelectionModel }).then(
            (res) => {
              const processedLogs = res.pickTicketLogs.map((log) => ({
                batchShippingLogId: log.batchShippingLogId,
                pickTicketNo: log.pickTicketNo,
                status: log.status,
                isComplete: log.isComplete,
                message: log.logDetailJson,
              }));

              setBatchShipDataGridError(processedLogs);
            },
          );
        })
        .finally(() => {
          setBatchShiploading(false);
        });
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    if (isBatchShipModalOpen) {
      const getStoredWarehouseValue = JSON.parse(
        localStorage.getItem('storeWarehouseCustomer'),
      );

      onLoadgetBatchshippingDDL(
        getStoredWarehouseValue ? getStoredWarehouseValue.id : 0,
      ).then((opt) => {
        setOptionCarriers(opt.carrierDDL);
        setOptionCarrierServicesTemp(opt.carrierServiceDDL);
        setOptionPackageCartonCodesTemp(opt.packageDDL);
        setOptionShipVoidFill(opt.voidFillDDL);
      });
    }
  }, [isBatchShipModalOpen]);

  useEffect(() => {
    if (batchShipForm?.carrier) {
      const filteredCarrierServices = optionCarrierServicesTemp.filter(
        (x: CarrierServiceType) => x.id === batchShipForm?.carrier?.value,
      );
      setOptionCarrierServices(filteredCarrierServices);

      const filteredPackageCartonCodes = optionPackageCartonCodesTemp.filter(
        (x: PackageType) =>
          x.carrierId === batchShipForm?.carrier?.value ||
          x.isExternalPackage === false,
      );
      setOptionPackageCartonCodes(filteredPackageCartonCodes);
    }
  }, [batchShipForm?.carrier?.value]);

  useEffect(() => {
    if (isBatchShipLogErrorModalOpen) {
      batchshippinglogs({ PickTicketIds: rowSelectionModel }).then((res) => {
        const processedLogs = res.pickTicketLogs.map((log) => ({
          batchShippingLogId: log.batchShippingLogId,
          pickTicketNo: log.pickTicketNo,
          status: log.status,
          isComplete: log.isComplete,
          message: log.logDetailJson,
        }));

        setBatchShipDataGridError(processedLogs);
      });
    }
  }, [isBatchShipLogErrorModalOpen]);

  return (
    <>
      <Modal open={isBatchShipModalOpen}>
        <MUIContainer sx={{ width: '30% !important' }}>
          <Typography
            className="modalTextHeader"
            variant="h6"
            fontWeight="bold"
          >
            Batch Ship
          </Typography>
          <MUIContent sx={{ padding: '10px 20px !important' }}>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={6}>
                  <Autocomplete
                    disableClearable
                    options={optionCarriers}
                    getOptionLabel={(option: CarrierType) => option.text}
                    value={batchShipForm?.carrier}
                    onChange={(event: any, newValue: CarrierType) => {
                      if (newValue !== null) {
                        setBatchShipForm((prev) => ({
                          ...prev,
                          carrier: newValue,
                          carrierService: null,
                          package: null,
                        }));
                      } else {
                        setBatchShipForm((prev) => ({
                          ...prev,
                          carrier: null,
                          carrierService: null,
                          package: null,
                        }));
                      }
                    }}
                    id="controllable-states"
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option) => (
                      <li data-name="carrierType" {...props}>
                        {option.text}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        label="Carrier"
                        id="outlined"
                        size="small"
                        sx={{ width: '100%', background: '#ffffff' }}
                        required
                        {...(batchShipFormError?.carrier && {
                          error: true,
                          helperText: batchShipFormError?.carrier,
                        })}
                      />
                    )}
                  />
                </MUIGrid>
                <MUIGrid item xs={6}>
                  <Autocomplete
                    options={optionCarrierServices}
                    getOptionLabel={(option: CarrierServiceType) => option.text}
                    value={batchShipForm?.carrierService || null}
                    onChange={(event: any, newValue: CarrierServiceType) => {
                      if (newValue !== null) {
                        setBatchShipForm((prev) => ({
                          ...prev,
                          carrierService: newValue,
                        }));
                      } else {
                        setBatchShipForm((prev) => ({
                          ...prev,
                          carrierService: null,
                        }));
                      }
                    }}
                    id="controllable-states"
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option) => (
                      <li data-name="carrierType" {...props}>
                        {option.text}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        id="outlined"
                        label="Carrier Service"
                        size="small"
                        sx={{ width: '100%', background: '#ffffff' }}
                        required
                        {...(batchShipFormError?.carrierService && {
                          error: true,
                          helperText: batchShipFormError?.carrierService,
                        })}
                      />
                    )}
                  />
                </MUIGrid>
                <MUIGrid item xs={6}>
                  <Autocomplete
                    id="grouped-demo"
                    options={optionPackageCartonCodes}
                    value={batchShipForm?.package || null}
                    onChange={(event: any, newValue: PackageType) => {
                      if (newValue !== null) {
                        setBatchShipForm((prev) => ({
                          ...prev,
                          package: newValue,
                          voidFill: null,
                        }));
                      } else {
                        setBatchShipForm((prev) => ({
                          ...prev,
                          package: null,
                          voidFill: null,
                        }));
                      }
                    }}
                    getOptionLabel={(option: PackageType) => option.text}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    groupBy={(option) => option.packageType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                          endadornment: null,
                        }}
                        autoComplete="off"
                        label="Package"
                        id="outlined"
                        size="small"
                        sx={{ width: '100%', background: '#ffffff' }}
                        required
                        {...(batchShipFormError?.package && {
                          error: true,
                          helperText: batchShipFormError?.package,
                        })}
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Box display="flex" width="100%">
                          <Box display="flex" flexDirection="column">
                            <Typography color="text.primary">
                              {option.text}
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                              {option.dimensions}
                            </Typography>
                          </Box>
                        </Box>
                      </li>
                    )}
                    renderGroup={(params) => (
                      <Box
                        key={params.key}
                        display="flex"
                        flexDirection="column"
                      >
                        <Typography fontWeight="bold" p={1}>
                          {params.group}
                        </Typography>
                        {params.children}
                      </Box>
                    )}
                  />
                </MUIGrid>
                {batchShipForm?.package?.acceptsVoidFill === true && (
                  <MUIGrid item xs={6}>
                    <Autocomplete
                      options={optionShipVoidFill}
                      getOptionLabel={(option: VoidFillType) => option.text}
                      value={batchShipForm?.voidFill || null}
                      onChange={(event: any, newValue: VoidFillType) => {
                        if (newValue !== null) {
                          setBatchShipForm((prev) => ({
                            ...prev,
                            voidFill: newValue,
                          }));
                        } else {
                          setBatchShipForm((prev) => ({
                            ...prev,
                            voidFill: null,
                          }));
                        }
                      }}
                      id="controllable-states"
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderOption={(props, option) => (
                        <li data-name="voidFill" {...props}>
                          {option.text}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          id="outlined"
                          label="Void Fill"
                          size="small"
                          sx={{ width: '100%', background: '#ffffff' }}
                          required
                          {...(batchShipFormError?.voidFill && {
                            error: true,
                            helperText: batchShipFormError?.voidFill,
                          })}
                        />
                      )}
                    />
                  </MUIGrid>
                )}
                <MUIGrid item xs={6}>
                  <TextField
                    inputProps={{
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined"
                    name="weight"
                    value={batchShipForm?.packageWeight}
                    onChange={(e) => {
                      const validateDecimal = decimalValidationHelper(
                        e.target.value,
                      );

                      setBatchShipForm((prev) => ({
                        ...prev,
                        packageWeight: validateDecimal,
                      }));
                    }}
                    size="small"
                    sx={{ width: '100%' }}
                    label="Package Weight"
                  />
                </MUIGrid>

                {batchShipDataGridError && batchShipDataGridError.length > 0 && (
                  <MUIGrid item xs={12}>
                    <DatagridPremium
                      autoHeight
                      rows={batchShipDataGridError || []}
                      columns={batchShipErrorColumns}
                      hideFooterSelectedRowCount
                      getRowId={() => Math.random()}
                      pagination
                      disableColumnMenu
                      density="compact"
                      disableSelectionOnClick
                      hideFooter
                    />
                  </MUIGrid>
                )}
              </MUIGrid>
            </Box>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={8}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={() => {
                    handleOnCloseModal();
                  }}
                  variant="outlined"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => {
                    handleOnSave(e);
                  }}
                  disabled={batchShiploading}
                >
                  {batchShiploading ? (
                    <Box display="flex" alignItems="center" gap={1}>
                      <CircularProgress size={20} sx={{ color: 'white' }} />
                      Loading...
                    </Box>
                  ) : (
                    <>Confirm</>
                  )}
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
      <Modal open={isBatchShipLogErrorModalOpen}>
        <MUIContainer sx={{ width: '50% !important' }}>
          <Typography
            className="modalTextHeader"
            variant="h6"
            fontWeight="bold"
          >
            Batch Ship Logs
          </Typography>
          <MUIContent sx={{ padding: '10px 20px !important' }}>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {batchShipDataGridError && batchShipDataGridError.length > 0 && (
                  <MUIGrid item xs={12}>
                    <DatagridPremium
                      autoHeight
                      rows={batchShipDataGridError || []}
                      columns={batchShipErrorColumns}
                      hideFooterSelectedRowCount
                      getRowId={() => Math.random()}
                      pagination
                      disableColumnMenu
                      density="compact"
                      disableSelectionOnClick
                      hideFooter
                    />
                  </MUIGrid>
                )}
              </MUIGrid>
            </Box>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={3}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={() => {
                    setIsBatchShipLogErrorModalOpen(false);
                    setRowSelectionModel([]);
                  }}
                  variant="contained"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                >
                  Close
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
    </>
  );
});
