import React, { useContext, useState, ChangeEvent, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import Button from 'components/button';
import Input from 'components/input';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { useInventoryItemDetailsContext } from 'pages/inventory/item/details';
import {
  getCustomerBinsByLocationId,
  getCustomerFacilityZones,
  lookupCustomerBinByZoneId,
} from 'services/api/customerfacilities/customerfacilities.api';
import { createItemTransfer } from 'services/api/item/item.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Typography, Autocomplete, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface TransferBinModalProps {
  ItemDetails?: any;
  callBack?: () => void;
}

interface AutoCompleteOptionType {
  id: number;
  label: string;
}
export default React.memo(
  ({ ItemDetails, callBack }: TransferBinModalProps) => {
    const theme = useTheme();
    const [urlSearchParams] = useSearchParams();

    const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
    const { isTransferModalOpen, onCloseTransferModal, handleUpdateData } =
      useContext(GlobalContext);

    const { formValues: inventoryItemDetail } =
      useInventoryItemDetailsContext();

    const [zones, setZones] = useState([]);
    const [bins, setBins] = useState([]);
    const [binSelectionOptions, setBinSelectionOptions] = useState([]);
    const [isDisable, setIsDisable] = useState<boolean>(true);

    const defaultAutocompleteOption: AutoCompleteOptionType | null = {
      id: -1,
      label: '',
    };

    const initialState: any = {
      customerId: currentUser.Claim_CustomerId,
      customerLocationId: currentLocationAndFacility.customerLocationId,
      itemId: urlSearchParams.get('id'),
      itemFacilityId: '',
      itemBinId: '',
      sku: ItemDetails.sku,
      newZoneId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      newBinId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      qtyToTransfer: 0,
    };

    const [formErrors, setFormErrors] = useState<any>({
      newZoneId: '',
      newBinId: '',
      qtyToTransfer: '',
    });
    const [form, setForm] = useState<any>(initialState);

    const currentItem = inventoryItemDetail.desc.sku;

    const zoneSelectionOptions = Array.isArray(zones)
      ? zones
          .filter(
            (z) =>
              z.customerFacilityId ===
                currentLocationAndFacility.customerFacilityId &&
              z.customerLocationId ===
                currentLocationAndFacility.customerLocationId,
          )
          .map((zone) => ({
            id: zone.zoneId,
            label: zone.name,
          }))
      : [{ id: -1, label: '' }];

    const onForm = (key, text) => {
      setForm(() => ({
        ...form,
        [key]: text,
      }));
    };

    const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) =>
      onForm(key, event.target.value);

    const handleCloseTransferModal = () => {
      setForm(initialState);
      setFormErrors({ newZoneId: '', newBinId: '', qtyToTransfer: '' });
      setIsDisable((e) => true);
      if (callBack !== undefined) {
        callBack();
      }
      onCloseTransferModal();
    };

    const autoCompleteInputHandler = (
      key: string,
      value: AutoCompleteOptionType,
    ) => {
      setForm(() => ({
        ...form,
        [key]:
          value === null
            ? {
                id: defaultAutocompleteOption.id,
                label: defaultAutocompleteOption.label,
              }
            : value,
      }));
    };

    const onLoadLocationsZonesAndBins = async () => {
      try {
        const binsFromApi = await getCustomerBinsByLocationId(
          currentLocationAndFacility.customerLocationId,
        );
        const zonesFromApi = await getCustomerFacilityZones(
          currentLocationAndFacility.customerLocationId,
        );
        setZones(zonesFromApi);
        setBins(binsFromApi);
        return true;
      } catch (err) {
        return err;
      }
    };

    const validateOnlick = (fieldValues = form) => {
      const temp: any = {};

      if ('newZoneId' in fieldValues) {
        temp.newZoneId =
          fieldValues.newZoneId.id !== -1 ? '' : 'This field is required';
      }
      if ('newBinId' in fieldValues) {
        if (
          fieldValues.newBinId !== undefined ||
          fieldValues.newBinId !== '' ||
          fieldValues.newBinId !== null
        ) {
          temp.newBinId =
            fieldValues.newBinId.id !== -1 ? '' : 'This field is required';
        } else if (
          fieldValues.newBinId === undefined ||
          fieldValues.newBinId === '' ||
          fieldValues.newBinId === null
        ) {
          temp.newBinId = 'This field is required';
        }
      }

      if ('qtyToTransfer' in fieldValues) {
        temp.qtyToTransfer = fieldValues.qtyToTransfer
          ? ''
          : 'This field is required';
      }

      setFormErrors({
        ...temp,
      });

      return Object.values(temp).every((x) => x === '');
    };

    const onCreateNewTransfer = async (e: any) => {
      e.preventDefault();
      form.qtyToTransfer = form.qtyToTransfer <= 0 ? '' : form.qtyToTransfer;

      if (validateOnlick()) {
        const Payload = {
          customerId: currentUser.Claim_CustomerId,
          customerLocationId: ItemDetails.customerLocationId,
          itemBinId: ItemDetails.binId,
          itemFacilityId: ItemDetails.customerFacilityId,
          ItemPalletInventoryId: ItemDetails.palletInventoryId
            ? ItemDetails.palletInventoryId
            : '',
          itemId: urlSearchParams.get('id'),
          newBinId: form.newBinId.id,
          newZoneId: form.newZoneId.id,
          qtyToTransfer: form.qtyToTransfer,
        };

        try {
          await createItemTransfer(Payload);
          snackActions.success(
            `Successfully added ${currentItem} to transfer queue.`,
          );
          handleCloseTransferModal();
          handleUpdateData();
        } catch (err: any) {
          snackActions.error(`${err}`);
        }
      }
    };

    const isLocationIsDisable = async (zoneId: any) => {
      if (zoneId.id !== -1) {
        setForm((e) => ({ ...e, newBinId: '' }));
        setBinSelectionOptions(
          Array.isArray(bins) && zoneId.id !== ''
            ? bins
                .filter((z) => z.zoneId === zoneId.id)
                .map((bin) => ({
                  id: bin.binId,
                  label: bin.name,
                }))
            : [{ id: -1, label: '' }],
        );
        setIsDisable((e) => false);
      } else {
        setIsDisable((e) => true);
      }
    };

    useEffect(() => {
      onLoadLocationsZonesAndBins();
    }, [ItemDetails, currentLocationAndFacility, isDisable]);

    if (!isTransferModalOpen) return null;

    return (
      <Modal
        open={isTransferModalOpen}
        onClose={() => handleCloseTransferModal()}
      >
        <ModalBox>
          <ModalContent>
            <Typography
              sx={{ marginBotton: '16px' }}
              variant="h6"
              fontWeight="bold"
            >
              Transfer
            </Typography>
            <Input
              sx={{ width: '100%' }}
              placeholder="SKU"
              disabled
              value={currentItem}
            />

            <Typography variant="subtitle1" fontWeight="bold">
              From:
            </Typography>
            <Input
              sx={{ width: '100%' }}
              placeholder="Current Facility"
              disabled
              value={ItemDetails.facility}
            />
            <Input
              sx={{ width: '100%' }}
              placeholder="Current Zone"
              disabled
              value={ItemDetails.zone}
            />
            <Input
              sx={{ width: '100%' }}
              placeholder="Current Location"
              disabled
              value={ItemDetails.bin}
            />
            <Input
              sx={{ width: '100%' }}
              placeholder="Qty on Hand"
              disabled
              value={ItemDetails.qtyOnHand}
            />
            <Typography variant="subtitle1" fontWeight="bold">
              To:
            </Typography>

            <Autocomplete
              sx={{ width: '100%' }}
              options={zoneSelectionOptions}
              getOptionLabel={(option) => (option.label ? option.label : '')}
              size="small"
              value={form.newZoneId}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              disableClearable
              onChange={(event: any, value: AutoCompleteOptionType | null) => {
                autoCompleteInputHandler('newZoneId', value);
                isLocationIsDisable(value);
              }}
              renderInput={(params) =>
                formErrors.newZoneId ? (
                  <TextField
                    {...params}
                    label="Area"
                    error
                    helperText={formErrors.newZoneId}
                  />
                ) : (
                  <TextField {...params} label="Area" />
                )
              }
            />
            <Autocomplete
              sx={{ width: '100%' }}
              options={binSelectionOptions}
              getOptionLabel={(option) => (option.label ? option.label : '')}
              size="small"
              value={form.newBinId}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              disableClearable
              onChange={(event: any, value: AutoCompleteOptionType | null) => {
                autoCompleteInputHandler('newBinId', value);
              }}
              disabled={isDisable}
              renderInput={(params) =>
                formErrors.newBinId ? (
                  <TextField
                    {...params}
                    label="Location"
                    error
                    helperText={formErrors.newBinId}
                  />
                ) : (
                  <TextField {...params} label="Location" />
                )
              }
            />
            <Input
              type="number"
              sx={{ width: '100% ' }}
              placeholder="Quantity to Transfer"
              value={form.qtyToTransfer < 0 ? 0 : form.qtyToTransfer}
              error={formErrors.qtyToTransfer}
              onChange={(value) => inputHandler('qtyToTransfer', value)}
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                  justifyContent: 'flex-end',
                },
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'center',
                },
                gap: '8px',
              }}
            >
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="text"
                size="large"
                onClick={() => handleCloseTransferModal()}
              >
                Cancel
              </Button>
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="contained"
                size="large"
                onClick={(e) => onCreateNewTransfer(e)}
              >
                Queue
              </Button>
            </Box>
          </ModalContent>
        </ModalBox>
      </Modal>
    );
  },
);
