import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';

import { PageLoadStateEnum } from 'common/enums';
import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import Card from 'components/card';
import Header from 'components/header';
import TabPanel, { a11yProps } from 'components/tabpanel';
import { snackActions } from 'config/snackbar.js';
import BinCategory from 'helpers/bincategoryhelper';
import FileInputModal from 'pages/shared/addfilemodal';
import BillTypeWarningModal from 'pages/shared/billingtypewarning';
import LocationPrintBarcode from 'pages/shared/binprintbarcode';
import ConfirmationDialog from 'pages/shared/confirmdeletemodal';
import CreateBinModal from 'pages/shared/createbinmodal';
import CustomerFacilityZoneModal from 'pages/shared/customerfacilityzonemodal';
import {
  getZoneStorageManagement,
  getStorageManagementDetails,
  addImportedZonesAndBins,
  bulkDelete,
} from 'services/api/customerfacilities/customerfacilities.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridPremium } from 'styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Link,
  Card as MUICard,
  LinearProgress,
  linearProgressClasses,
  Chip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridActionsCellItem,
  GridColumns,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';

import ImportGlobalMessageModal from '../shared/import-global-message-modal';
import { MainContainer, ContentContainer } from '../styles';
import { LocationContextProvider, useLocationContext } from './context';

interface State {
  pageLoadState: PageLoadStateEnum;
}

const INITIAL_STATE: State = {
  pageLoadState: PageLoadStateEnum.LOAD_NONE,
};

enum LocationStatusEnum {
  All = 'All',
  OpenSpaces = 'Open Spaces',
}

type ImportedZonesBins = {
  customerId?: number;
  customerLocationId?: number;
  customerFacilityId?: number;
  binZone?: number;
  zoneName: string;
  binName: string;
  category: string;
  sequenceNo?: number;
  aisle?: string;
  row?: string;
  shelf?: string;
  level?: string;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          flex: '1',
          width: '100% !important',
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>
  );
}

function BinManagement() {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    updateData,
    updateLoading,
    handleUpdateData,
    onOpenCreateBinModal,
    onCloseInputFilenModal,
    onOpenFacilityZoneModal,
    onOpenConfirmDeleteDialog,
    onOpenBinPrintBarcodeModal,
    isBulkDeleteLocationModalOpen,
    setIsImportGlobalErrorModalOpen,
    setImportGlobalErrorMessageList,
    setIsBulkDeleteLocationModalOpen,
  } = useContext(GlobalContext);

  // context
  const {
    state,
    editZone,
    binCount,
    zoneCount,
    currentView,
    customerBins,
    filteredZones,
    zoneAction,
    filteredBins,
    facilityZones,
    deleteLocation,
    locationBarcode,
    billTypeCallback,
    openSpaces,
    setState,
    setBinCount,
    setZoneCount,
    setOpenSpaces,
    setZoneAction,
    setCustomerBins,
    setFilteredBins,
    setFacilityZones,
    setFilteredZones,
    setDeleteLocation,
    setLocationBarcode,
    setCurrentView,
    setEditZone,
    setBillTypeCallback,
    pageLoadingStart,
    selectedRows,
    setSelectedRows,
    notForDeletion,
    forDeletion,
  } = useLocationContext();

  const { currentUser, currentLocationAndFacility, handleUserPermissionAllow } =
    useContext(AuthContext);

  const initialDataSelected = {
    binId: '',
    customerLocationId: '',
    zoneId: '',
    name: '',
    sequenceNumber: '',
    aisle: '',
    row: '',
    shelf: '',
    level: '',
  };

  const [dataSelected, setDataSelected] = useState(initialDataSelected);

  const provideCsvTemplate = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const obj = [
      'Location Name',
      'Area',
      'Category',
      'Sequence No',
      'Aisle',
      'Row',
      'Shelf',
      'Level',
      'Is Picking',
      'Bin Size',
      'Length',
      'Width',
      'Height',
    ];

    const csvContent = `data:text/csv;charset=utf-8,${obj}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'location_template.csv');
    document.body.appendChild(link);

    link.click();
  };

  const mapAndAddZonesBins = async (value) => {
    if (!value) {
      snackActions.error('No data to import');
      return;
    }
    const importedZonesBins = value
      .filter((zb) => zb.LocationName)
      .map((zb) => ({
        customerId: currentUser.Claim_CustomerId,
        customerLocationId: currentLocationAndFacility.customerLocationId,
        customerFacilityId: currentLocationAndFacility.customerFacilityId,
        binName: zb?.LocationName,
        zoneName: zb?.Area,
        category: zb?.Category,
        sequenceNo: zb?.SequenceNo,
        aisle: zb?.Aisle,
        row: zb?.Row,
        shelf: zb?.Shelf,
        level: zb?.Level,
        isPicking: zb?.IsPicking,
        binSize: zb?.BinSize,
        length: zb?.Length,
        height: zb?.Height,
        width: zb?.Width,
      }));

    try {
      const response = await addImportedZonesAndBins(
        currentLocationAndFacility.customerLocationId,
        importedZonesBins,
      );
      const { status, data } = response;
      if (status === 200) {
        if (data?.errorMessages && data?.errorMessages.length > 0) {
          setImportGlobalErrorMessageList(data?.errorMessages);
          setIsImportGlobalErrorModalOpen(true);
        } else {
          snackActions.success(
            `Successfully imported areas and locations for ${currentLocationAndFacility.locationName}, ${currentLocationAndFacility.facilityName}.`,
          );
          handleUpdateData();
        }
      } else {
        snackActions.error(`${data} `);
      }
    } catch (err) {
      snackActions.error(err);
    }

    updateLoading(false);
    onCloseInputFilenModal();
  };

  const handleChange = (newValue: number) => {
    setCurrentView(newValue); // Correct usage
  };

  const handleResetDataSelected = () => {
    setDataSelected({
      binId: '',
      customerLocationId: '',
      zoneId: '',
      name: '',
      sequenceNumber: '',
      aisle: '',
      row: '',
      shelf: '',
      level: '',
    });
    setEditZone(false);
    setZoneAction(false);
    setDeleteLocation(false);
  };

  const onLoadCustomerBinsAndZones = async () => {
    try {
      const customerBinsFromApi = await getStorageManagementDetails(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
      );

      const zonesFromApi = await getZoneStorageManagement(
        currentLocationAndFacility.customerFacilityId,
        '',
      );

      setCustomerBins(customerBinsFromApi.binDetails);
      setFilteredBins(customerBinsFromApi.binDetails);
      setBinCount(customerBinsFromApi.bins);

      setOpenSpaces(customerBinsFromApi.openSpaces);
      setFacilityZones(zonesFromApi);
      setFilteredZones(zonesFromApi);
      setZoneCount(zonesFromApi?.length);

      return true;
    } catch (err: any) {
      return err;
    }
  };

  const handleBulkDelete = () => {
    const ids = forDeletion.map((x) => x.binId);
    bulkDelete(ids).then(() => {
      const bins = notForDeletion.map((row) => ` [${row.name}]`).join(',');
      if (bins.length <= 0) {
        // This will not show the modal or we can remove this implementation if not used already?
        withReactContent(Swal).fire({
          title: 'Bulk Delete',
          text: `Location ${bins} was not deleted because it's not an empty location`,
          icon: 'info',
          confirmButtonText: 'Ok',
        });
      }

      // this will automatically trigger viewLoadData
      pageLoadingStart();
    });
  };

  useEffect(() => {
    if (state?.pageLoadState === PageLoadStateEnum.LOAD_START) {
      setDataSelected({
        binId: '',
        customerLocationId: '',
        zoneId: '',
        name: '',
        sequenceNumber: '',
        aisle: '',
        row: '',
        shelf: '',
        level: '',
      });
      setBinCount(0);
      setZoneCount(0);
      updateLoading(true);
      setCustomerBins([]);
      setFilteredBins([]);
      setFacilityZones([]);
      setFilteredZones([]);
      onLoadCustomerBinsAndZones().then(() => {
        setState((prev) => ({
          ...prev,
          pageLoadState: PageLoadStateEnum.LOAD_FINISH,
        }));
      });
    }
  }, [state?.pageLoadState]);

  const handleClickDeleteBin = (rowData) => {
    setDeleteLocation(true);
    setZoneAction(false);
    setDataSelected(rowData);
    onOpenConfirmDeleteDialog();
    return true;
  };

  const handleEditBin = (rowData) => {
    setDataSelected(rowData);
    onOpenCreateBinModal();
  };

  const handleClickViewBinDetails = (rowData) => {
    setDataSelected(rowData);
    const querySearchParams = {
      binId: rowData.binId,
    };
    navigate({
      pathname: `/locations/location/${rowData.name}`,
      search: `?${createSearchParams(querySearchParams)}`,
    });
  };

  const binColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Details"
          onClick={() => handleClickViewBinDetails(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEditBin(params.row)}
          showInMenu
          disabled={
            !handleUserPermissionAllow(UserPermissionEnum.Locations_Edit)
          }
        />,
        <GridActionsCellItem
          label="Delete"
          disabled={params.row.uniqueSKU !== 0}
          onClick={() => handleClickDeleteBin(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'name',
      width: 200,
      headerName: 'Location',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`locations/location/${params.row.name}?binId=${params.row.binId}`}
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.name}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'zoneName',
      width: 200,
      headerName: 'Area',
      renderCell: (params) => <Typography>{params.row.zone}</Typography>,
    },
    {
      field: 'uniqueSKUs',
      width: 120,
      headerName: 'Unique SKUs',
      renderCell: (params) => (
        <Typography>{params.row.uniqueSKU?.toLocaleString()}</Typography>
      ),
    },
    {
      field: 'category',
      width: 110,
      headerName: 'Category',
      renderCell: (params) => (
        <Typography>{BinCategory[params.row.category]}</Typography>
      ),
    },
    {
      field: 'binSize',
      width: 100,
      headerName: 'Bin Size',
      renderCell: (params) => <Typography>{params.row.binSizeStr}</Typography>,
    },
    {
      field: 'warehouseCustomer',
      width: 200,
      flex: 0.5,
      headerName: 'Client',
      renderCell: (params) => (
        <Typography>{params.row.warehouseCustomer}</Typography>
      ),
    },
    {
      field: 'sequenceNumber',
      width: 220,
      flex: 0.5,
      headerName: 'Sequence No.',
      renderCell: (params) => (
        <Typography>{params.row.sequenceNumber}</Typography>
      ),
    },
    {
      field: 'aisle',
      width: 100,
      flex: 0.5,
      headerName: 'Aisle',
      renderCell: (params) => <Typography>{params.row.aisle}</Typography>,
    },
    {
      field: 'row',
      width: 100,
      flex: 0.5,
      headerName: 'Row',
      renderCell: (params) => <Typography>{params.row.row}</Typography>,
    },
    {
      field: 'shelf',
      width: 100,
      flex: 0.5,
      headerName: 'Shelf',
      renderCell: (params) => <Typography>{params.row.shelf}</Typography>,
    },
    {
      field: 'level',
      width: 100,
      flex: 0.5,
      headerName: 'Level',
      renderCell: (params) => <Typography>{params.row.level}</Typography>,
    },
    {
      field: 'length',
      width: 100,
      flex: 0.5,
      headerName: 'Length',
      renderCell: (params) => <Typography>{params.row?.length}</Typography>,
    },
    {
      field: 'height',
      width: 100,
      flex: 0.5,
      headerName: 'Height',
      renderCell: (params) => <Typography>{params.row?.height}</Typography>,
    },
    {
      field: 'width',
      width: 100,
      flex: 0.5,
      headerName: 'Width',
      renderCell: (params) => <Typography>{params.row?.width}</Typography>,
    },
  ];

  type ZoneRows = typeof facilityZones[number];

  const handleClickEditZone = (rowData) => {
    setEditZone(true);
    setDataSelected(rowData);
    onOpenFacilityZoneModal();
  };

  const handleClickDeleteZone = (rowData) => {
    setZoneAction(true);
    setDataSelected(rowData);
    onOpenConfirmDeleteDialog();
    return true;
  };

  const handleClickBillTypeCallback = (data) => {
    setBillTypeCallback(data);
  };

  const zoneColumns: GridColumns<ZoneRows> = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleClickEditZone(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          disabled={params.row.uniqueSKU !== 0}
          onClick={() => handleClickDeleteZone(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'billingType',
      width: 341,
      hide: true,
      headerName: 'Billing Type',
      renderCell: (params) => <Typography>{params.row.billingType}</Typography>,
    },
    {
      field: 'name',
      width: 200,
      headerName: 'Name',
      renderCell: (params) => <Typography>{params.row.name}</Typography>,
    },
    {
      field: 'locations',
      width: 200,
      headerName: 'Locations',
      renderCell: (params) => (
        <Typography>{params.row.locations?.toLocaleString()}</Typography>
      ),
    },
    {
      field: 'uniqueSKU',
      width: 200,
      headerName: 'Unique SKUs',
      renderCell: (params) => (
        <Typography>{params.row.uniqueSKU?.toLocaleString()}</Typography>
      ),
    },
    {
      field: 'itemQty',
      width: 200,
      headerName: 'Item Qty',
      renderCell: (params) => (
        <Typography>{params.row.itemQty?.toLocaleString()}</Typography>
      ),
    },
  ];

  useEffect(() => {
    if (selectedRows.length > 0) {
      setLocationBarcode((prev) => ({
        ...prev,
        locations: selectedRows.map((item) => ({
          name: item.name,
        })),
      }));
    }
  }, [selectedRows]);

  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_NONE) {
      pageLoadingStart();
    }
  }, [state.pageLoadState]);

  // facility change
  useEffect(() => {
    // this will automatically trigger viewLoadData
    pageLoadingStart();
  }, [currentLocationAndFacility, updateData]);

  const handleCardClick = (value) => {
    if (value === 'Locations') {
      setFilteredBins(customerBins);
      handleChange(0);
    } else if (value === 'Open Spaces') {
      setFilteredBins(customerBins.filter((x) => x.uniqueSKU === 0));
      handleChange(0);
    } else if (value === 'Areas') {
      handleChange(1);
    }
  };

  return (
    <>
      <ImportGlobalMessageModal title="Import Location Error" />

      {deleteLocation && (
        <ConfirmationDialog
          deleteBin
          binData={dataSelected}
          callBack={handleResetDataSelected}
        />
      )}

      {isBulkDeleteLocationModalOpen && (
        <ConfirmationDialog
          deleteBinList
          binDataList={forDeletion}
          callBack={handleBulkDelete}
        />
      )}

      <FileInputModal
        callBack={mapAndAddZonesBins}
        csvTemplate={provideCsvTemplate}
      />
      <CreateBinModal
        binData={dataSelected}
        resetData={handleResetDataSelected}
      />
      {zoneAction && (
        <ConfirmationDialog
          deleteZone
          zoneData={dataSelected}
          callBack={handleResetDataSelected}
        />
      )}
      {editZone ? (
        <CustomerFacilityZoneModal
          edit
          zoneData={dataSelected}
          callBack={handleResetDataSelected}
          billTypeCallBack={handleClickBillTypeCallback}
        />
      ) : (
        <CustomerFacilityZoneModal />
      )}

      <LocationPrintBarcode data={locationBarcode} />
      <BillTypeWarningModal cb={billTypeCallback} />
      <ContentContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            marginBottom: '16px',
          }}
        >
          <MUICard
            className="cardLayoutPicking"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => handleCardClick('Locations')}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box>
                <LocationOnIcon
                  sx={{
                    backgroundColor: '#ccf1ff',
                    fontSize: 60,
                    color: '#21b7ee',
                  }}
                  className="hourGlassIcon"
                />
              </Box>
              <Box sx={{ width: '100%', padding: '0px 10px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '5px',
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Locations
                  </Typography>
                  <Typography variant="h6" fontWeight="bold">
                    {binCount?.toLocaleString()}
                  </Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={100}
                  sx={{
                    height: '10px',
                    borderRadius: 2,
                    backgroundColor: '#ccf1ff',
                    [`& .${linearProgressClasses.bar1Determinate}`]: {
                      borderRadius: 2,
                      backgroundColor: '#21b7ee',
                    },
                  }}
                />
              </Box>
            </Box>
          </MUICard>
          <MUICard
            className="cardLayoutPicking"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => handleCardClick('Areas')}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box>
                <HolidayVillageIcon
                  sx={{
                    backgroundColor: '#d3ffe3',
                    fontSize: 60,
                    color: '#28d23f',
                  }}
                  className="hourGlassIcon"
                />
              </Box>
              <Box sx={{ width: '100%', padding: '0px 10px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '5px',
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Areas
                  </Typography>
                  <Typography variant="h6" fontWeight="bold">
                    {zoneCount?.toLocaleString()}
                  </Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={100}
                  sx={{
                    height: '10px',
                    borderRadius: 2,
                    backgroundColor: '#d3ffe3',
                    [`& .${linearProgressClasses.bar1Determinate}`]: {
                      borderRadius: 2,
                      backgroundColor: '#28d23f',
                    },
                  }}
                />
              </Box>
            </Box>
          </MUICard>
          <MUICard
            className="cardLayoutPicking"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => handleCardClick('Open Spaces')}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box>
                <TravelExploreIcon
                  sx={{
                    backgroundColor: '#ffe2e7',
                    fontSize: 60,
                    color: 'red',
                  }}
                  className="hourGlassIcon"
                />
              </Box>
              <Box sx={{ width: '100%', padding: '0px 10px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '5px',
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Open Spaces
                  </Typography>
                  <Typography variant="h6" fontWeight="bold">
                    {openSpaces?.toLocaleString()}
                  </Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={100}
                  sx={{
                    height: '10px',
                    borderRadius: 2,
                    backgroundColor: '#ffe2e7',
                    [`& .${linearProgressClasses.bar1Determinate}`]: {
                      borderRadius: 2,
                      backgroundColor: 'red',
                    },
                  }}
                />
              </Box>
            </Box>
          </MUICard>
        </Box>

        <Card sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Tabs
            value={currentView}
            onChange={(event, newValue) => handleChange(newValue)}
          >
            <Tab label="Locations" {...a11yProps(0)} />
            <Tab label="Areas" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={currentView} index={0}>
            <DatagridPremium
              autoHeight
              rows={filteredBins}
              columns={binColumns}
              initialState={{ pagination: { pageSize: 100 } }}
              rowsPerPageOptions={[100, 250, 500]}
              pagination
              rowGroupingColumnMode="single"
              density="compact"
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              disableSelectionOnClick
              checkboxSelection
              getRowId={(row) => row.binId}
              onSelectionModelChange={(binId) => {
                const selectedId = binId[0];
                const selectedRowData = customerBins.filter(
                  (c) => c.binId === selectedId,
                );

                const selectedRowsData = binId.map((id) =>
                  customerBins.find((row) => row.binId === id),
                );

                setSelectedRows(selectedRowsData);
                if (selectedId === undefined) {
                  setDataSelected({
                    binId: '',
                    customerLocationId: '',
                    zoneId: '',
                    name: '',
                    sequenceNumber: '',
                    aisle: '',
                    row: '',
                    shelf: '',
                    level: '',
                  });
                } else {
                  setDataSelected(selectedRowData[0]);
                }
              }}
              components={{ Toolbar: CustomToolbar }}
            />
          </TabPanel>
          <TabPanel value={currentView} index={1}>
            <DatagridPremium
              autoHeight
              rows={filteredZones}
              columns={zoneColumns}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              pageSize={15}
              density="compact"
              rowsPerPageOptions={[15]}
              getRowId={(row) => row.zoneId}
              onSelectionModelChange={(customerId) => {
                const selectedId = customerId[0];
                const selectedRowData = facilityZones.filter(
                  (f) => f.zoneId === selectedId,
                );
                setDataSelected(selectedRowData[0]);
              }}
              components={{ Toolbar: CustomToolbar }}
            />
          </TabPanel>
        </Card>
      </ContentContainer>
    </>
  );
}

const BinManagementPage = () => (
  <MainContainer>
    <LocationContextProvider>
      <Header />
      <BinManagement />
    </LocationContextProvider>
  </MainContainer>
);

export default React.memo(BinManagementPage);
