import React, { useContext, useEffect, useState } from 'react';

import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import {
  getShopifyIntegrationDetail,
  getShopifyLocations,
  updateShopifyIntegration,
} from 'services/api/integrations/integrations.api';

import {
  Autocomplete,
  Box,
  Checkbox,
  Modal,
  TextField,
  Typography,
  Button,
} from '@mui/material';

import { useWarehouseCustomerContext } from '..';

interface AutoCompleteOptionType {
  id: number;
  label: string;
}

export default React.memo(() => {
  const {
    isSetShopifyLocationModalOpen,
    setIsSetShopifyLocationModalOpen,
    selectedIntegrationId,
    setSelectedIntegrationId,
  } = useWarehouseCustomerContext();

  const initialState: any = {
    storeUrl: '',
    customerId: null,
    warehouseCustomerId: null,
    customerLocationId: null,
    accessToken: '',
    orderSource: null,
    orderSourceId: null,
    locationId: null,
  };
  const [form, setForm] = useState<any>(initialState);

  const [locationOptions, setLocationOptions] = useState<
    AutoCompleteOptionType[]
  >([]);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);

  useEffect(() => {
    if (isSetShopifyLocationModalOpen) {
      getShopifyLocations(selectedIntegrationId).then((res) => {
        setLocationOptions(
          res.map((c: any) => ({
            value: c.value,
            label: c.text,
          })),
        );

        getShopifyIntegrationDetail(selectedIntegrationId).then((details) => {
          setForm(() => ({
            ...details,
            orderSourceId: details.orderSource,
          }));

          if (details.locationId) {
            const selectedValue = res.filter(
              (c) => c.value === details.locationId,
            )[0];
            setSelectedLocation({
              id: selectedValue.value,
              label: selectedValue.text,
            });
          }
        });
      });
    }
  }, [isSetShopifyLocationModalOpen, selectedIntegrationId]);

  const handleCloseModal = () => {
    setIsSetShopifyLocationModalOpen(false);
    setSelectedIntegrationId(null);
  };

  const handleSubmitButton = () => {
    if (selectedLocation === null) {
      snackActions.error('Please select a location.');
      return;
    }

    const payload = {
      storeUrl: form.storeUrl,
      customerId: form.customerId,
      warehouseCustomerId: form.warehouseCustomerId,
      customerLocationId: form.customerLocationId,
      customerFacilityId: form.customerFacilityId,
      accessToken: form.accessToken,
      orderSourceId: form.orderSourceId,
      orderSource: form.orderSourceId,
      locationId: selectedLocation.value,
    };

    updateShopifyIntegration(selectedIntegrationId, payload).then(() => {
      snackActions.success('Integration updated.');
      handleCloseModal();
    });
  };

  return (
    <Modal
      open={isSetShopifyLocationModalOpen}
      onClose={() => handleCloseModal()}
    >
      <ModalBox>
        <ModalContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Location
          </Typography>
          <Autocomplete
            id="checkboxes-tags-demo"
            options={locationOptions}
            value={selectedLocation}
            getOptionLabel={(option: AutoCompleteOptionType) => option.label}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.label}</li>
            )}
            onChange={(event: any, newValue) => {
              if (newValue !== null) {
                setSelectedLocation(newValue);
              } else {
                setSelectedLocation(null);
              }
            }}
            sx={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Location"
                placeholder="Location"
                required
              />
            )}
          />

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: '8px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="text"
              size="medium"
              onClick={() => handleCloseModal()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={(e) => handleSubmitButton()}
            >
              Submit
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
