import React, { useContext, useState, ChangeEvent, useEffect } from 'react';

import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { getCountries } from 'services/api/country/country.api';
import {
  createCustomerSupplier,
  EditCustomerSupplier,
} from 'services/api/customer/customer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import * as yup from 'yup';

import {
  Modal,
  Box,
  Typography,
  Autocomplete,
  TextField,
  Grid as MUIGrid,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface SupplierModalProps {
  supplier?: any;
  callBack?: () => void;
}

interface AutoCompleteOptionType {
  id: number;
  label: string;
}

export default React.memo(({ supplier, callBack }: SupplierModalProps) => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const defaultAutocompleteOption: AutoCompleteOptionType | null = {
    id: -1,
    label: '',
  };
  const { isSupplierModalOpen, onCloseSupplierModal, handleUpdateData } =
    useContext(GlobalContext);

  const [countries, setCountries] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [error, setError] = useState('');
  const initialFormErrorsState: any = {
    supplierId: '',
    customerId: '',
    companyName: '',
    address1: '',
    address2: '',
    cityTownship: '',
    stateProvinceRegion: '',
    postalCode: '',
    country: '',
    phone: '',
    recipientName: '',
    email: '',
    departmentNumber: '',
  };
  const initialState: any = {
    supplierId: '',
    customerId: '',
    name: '',
    address1: '',
    address2: '',
    cityTownship: '',
    stateProvinceRegion: '',
    zipPostalCode: '',
    country: {
      id: 236,
      label: 'United States',
    },
    phone: '',
    recipientName: '',
    email: '',
    departmentNumber: '',
  };
  const [formErrors, setFormErrors] = useState<any>(initialFormErrorsState);
  const [form, setForm] = useState<any>(initialState);
  const [isCreate, setIsCreate] = useState(Boolean);

  const onForm = (key, text) => {
    setForm(() => ({
      ...form,
      [key]: text,
    }));
  };

  const inputHandler = (
    key: string,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => onForm(key, event.target.value);

  const autoCompleteInputHandler = (
    key: string,
    value: AutoCompleteOptionType,
  ) => {
    setForm(() => ({
      ...form,
      [key]:
        value === null
          ? {
              id: defaultAutocompleteOption.id,
              label: defaultAutocompleteOption.label,
            }
          : value,
    }));
  };

  const countrySelectionOptions = Array.isArray(countries)
    ? countries.map((c, index) => ({
        id: c.countryId,
        label: c.name,
      }))
    : [{ id: 236, label: 'United States' }];

  const schema = yup.object().shape({
    customerId: yup.number().required('Required.'),
    companyName: yup.string().required('Required.'),
    address1: yup.string().required('Required'),
    cityTownship: yup.string().required('Required'),
    stateProvinceRegion: yup.string().required('Required'),
  });

  const handleCloseSupplierModal = () => {
    setForm(initialState);
    setFormErrors(initialFormErrorsState);
    if (callBack !== undefined) {
      callBack();
    }
    setError('');
    onCloseSupplierModal();
  };

  const onSaveNewSupplier = async () => {
    form.customerId = currentUser.Claim_CustomerId;
    try {
      const fieldValues = form;
      const temp: any = {};
      if (
        fieldValues.email !== '' &&
        fieldValues.email !== null &&
        fieldValues.email !== undefined
      ) {
        temp.email =
          fieldValues.email && /$^|.+@.+..+/.test(fieldValues.email)
            ? ''
            : 'Email Address is not valid';
      }

      temp.companyName = fieldValues.companyName
        ? ''
        : 'This field is required';

      setFormErrors({
        ...temp,
      });

      if (!Object.values(temp).every((x) => x === '')) {
        return;
      }

      const newForm: any = {};
      newForm.customerId = form.customerId;
      newForm.companyName = form.companyName;
      newForm.address1 = form.address1;
      newForm.address2 = form.address2;
      newForm.cityTownship = form.cityTownship;
      newForm.stateProvinceRegion = form.stateProvinceRegion;
      newForm.zipPostalCode = form.zipPostalCode;
      if (form.country.id === -1) {
        newForm.country = null;
      } else {
        newForm.country = form.country.id;
      }
      newForm.phone = form.phone;
      newForm.recipientName = form.recipientName;
      newForm.email = form.email;
      newForm.departmentNumber = form.departmentNumber;

      if (isCreate) {
        const newSupplier = await createCustomerSupplier(newForm);
        snackActions.success(`Successfully added new supplier.`);
      } else {
        newForm.supplierId = form.supplierId;
        const editSupplier = await EditCustomerSupplier(
          form.supplierId,
          newForm,
        );
        snackActions.success(
          `Successfully update ${editSupplier.companyName} supplier.`,
        );
      }
      handleUpdateData();
      handleCloseSupplierModal();
      setForm(initialState);
    } catch (err: any) {
      setError(err);
      setFormErrors(initialFormErrorsState);
      setShowErrors(true);
      snackActions.error(`${err}`);
    }
  };

  const onLoadEditSupplier = () => {
    if (supplier.supplierId === '' || supplier.supplierId === null) {
      setIsCreate(true);
    } else {
      setIsCreate(false);
      setForm({
        customerId: currentUser.Claim_CustomerId,
        supplierId: supplier.supplierId,
        companyName: supplier.name,
        address1: supplier.address1,
        address2: supplier.address2,
        country: supplier.countryId
          ? {
              id: supplier.countryId,
              label: supplier.country,
            }
          : { id: 236, label: 'United States' },
        cityTownship: supplier.cityTownship,
        stateProvinceRegion: supplier.stateProvinceRegion,
        zipPostalCode: supplier.zipPostalCode,
        recipientName: supplier.recipientName,
        phone: supplier.phone,
        email: supplier.email,
        departmentNumber: supplier.departmentNumber,
      });
    }
  };

  const onLoadCountries = async () => {
    const countriesfromApi = await getCountries('');
    setCountries(countriesfromApi);
  };

  useEffect(() => {
    setForm(initialState);
    onLoadEditSupplier();
    onLoadCountries();
  }, [supplier]);

  if (!isSupplierModalOpen) return null;

  return (
    <Modal
      open={isSupplierModalOpen}
      onClose={() => handleCloseSupplierModal()}
    >
      <MUIContainer sx={{ width: '20% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          {isCreate ? 'Add Supplier' : 'Edit Supplier'}
        </Typography>
        <MUIContent sx={{ padding: '10px !important' }}>
          <>
            <TextField
              sx={{ width: '100%' }}
              label="Company Name"
              size="small"
              value={form.companyName}
              error={formErrors.companyName}
              onChange={(value) => inputHandler('companyName', value)}
              {...(formErrors?.companyName && {
                error: true,
                helperText: formErrors?.companyName,
              })}
              required
            />
            <TextField
              sx={{ width: '100%' }}
              label="Address"
              size="small"
              value={form.address1}
              error={formErrors.address1}
              onChange={(value) => inputHandler('address1', value)}
              {...(formErrors?.address1 && {
                error: true,
                helperText: formErrors?.address1,
              })}
            />
            <TextField
              sx={{ width: '100%' }}
              label="Address 2"
              size="small"
              value={form.address2}
              error={formErrors.address2}
              onChange={(value) => inputHandler('address2', value)}
            />
            <Autocomplete
              disableClearable
              options={countrySelectionOptions}
              getOptionLabel={(option) => (option.label ? option.label : '')}
              size="small"
              value={form.country}
              sx={{ width: '100%' }}
              onChange={(event: any, value: AutoCompleteOptionType | null) => {
                autoCompleteInputHandler('country', value);
              }}
              renderInput={(params) =>
                formErrors.country ? (
                  <TextField
                    {...params}
                    label="Country"
                    error
                    helperText={formErrors.country}
                  />
                ) : (
                  <TextField {...params} label="Country" />
                )
              }
            />
            <TextField
              sx={{ width: '100%' }}
              label="City/Township"
              size="small"
              value={form.cityTownship}
              error={formErrors.cityTownship}
              onChange={(value) => inputHandler('cityTownship', value)}
              {...(formErrors?.cityTownship && {
                error: true,
                helperText: formErrors?.cityTownship,
              })}
            />
            <TextField
              sx={{ width: '100%' }}
              label="State/Province/Region"
              size="small"
              value={form.stateProvinceRegion}
              error={formErrors.stateProvinceRegion}
              onChange={(value) => inputHandler('stateProvinceRegion', value)}
            />
            <TextField
              sx={{ width: '100%' }}
              label="ZIP/Postal Code"
              size="small"
              value={form.zipPostalCode}
              onChange={(value) => inputHandler('zipPostalCode', value)}
              {...(formErrors?.zipPostalCode && {
                error: true,
                helperText: formErrors?.zipPostalCode,
              })}
            />
            <TextField
              sx={{ width: '100%' }}
              label="Receipient Name"
              size="small"
              value={form.recipientName}
              error={formErrors.recipientName}
              onChange={(value) => inputHandler('recipientName', value)}
            />
            <TextField
              sx={{ width: '100%' }}
              label="Phone Number"
              size="small"
              value={form.phone}
              error={formErrors.phone}
              onChange={(value) => inputHandler('phone', value)}
              {...(formErrors?.phone && {
                error: true,
                helperText: formErrors?.phone,
              })}
            />
            <TextField
              sx={{ width: '100%' }}
              label="Email"
              size="small"
              value={form.email}
              error={formErrors.email}
              onChange={(value) => inputHandler('email', value)}
              {...(formErrors?.email && {
                error: true,
                helperText: formErrors?.email,
              })}
            />
            <TextField
              sx={{ width: '100%' }}
              size="small"
              label="Department No."
              value={form.departmentNumber}
              error={formErrors.departmentNumber}
              onChange={(value) => inputHandler('departmentNumber', value)}
            />
          </>

          <Typography variant="subtitle2" color="#d32f2f">
            {error}
          </Typography>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={8}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                sx={{ minHeight: '48px' }}
                variant="outlined"
                size="medium"
                onClick={() => handleCloseSupplierModal()}
              >
                Cancel
              </Button>
              <Button
                sx={{ minHeight: '48px' }}
                variant="contained"
                size="medium"
                onClick={() => onSaveNewSupplier()}
              >
                Submit
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
