import { PickTicketLineItemPickPickingWebRequest } from 'common/models/picktickets/pickingweb/pickticketlineitempick';
import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

// Pick Ticket Issue
// GET /api/pickticket/issue/list/{customerLocationId}/{customerFacilityId}
export const getPickTicketIssues = async (
  customerLocationId,
  customerFacilityId,
) => {
  try {
    const response = await httpClient.get(
      `PickTicket/Issue/List/${customerLocationId}/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// Pick Ticket Issue
// POST /api/pickticket/issue/resolve
export const updatePickTicketLineItemIssueResolve = async (data) => {
  try {
    const response = await httpClient.post(`PickTicket/Issue/Resolve/`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/pickticket/pickingweb/pickticketlineitems/{pickTicketId}
export const GetPickTicketLineItemsPickingWeb = async (
  pickTicketId: number,
) => {
  try {
    const response = await httpClient.get(
      `PickTicket/PickingWeb/PickTicketLineItems/${pickTicketId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/pickticket/pickingweb/pickticketlineitemlocations/{pickTicketLineItemId}/{customerFacilityId}
export const GetPickTicketLineItemLocationsPickingWeb = async (
  pickTicketLineItemId: number,
  customerFacilityId: number,
) => {
  try {
    const response = await httpClient.get(
      `PickTicket/PickingWeb/PickTicketLineItemLocations/${pickTicketLineItemId}/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/pickticket/pickingweb/ddl/inventorybindetail/itemunitofmeasure/{pickTicketLineItemId}/{inventoryBinDetailId}
export const GetInventoryBinDetailItemUnitOfMeasureDDLPickingWeb = async (
  pickTicketLineItemId: number,
  inventoryBinDetailId: number,
) => {
  try {
    const response = await httpClient.get(
      `/PickTicket/PickingWeb/DDL/InventoryBinDetail/ItemUnitOfMeasure/${pickTicketLineItemId}/${inventoryBinDetailId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// POST /api/pickticket/pickingweb/pickticketlineitemPick
export const PickTicketLineItemPickPickingWeb = async (
  data: PickTicketLineItemPickPickingWebRequest,
) => {
  try {
    const response = await httpClient.post(
      `PickTicket/PickingWeb/PickTicketLineItemPick`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
