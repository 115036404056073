import React, { useEffect } from 'react';
import Barcode from 'react-barcode/lib/react-barcode';

import { PrintContainer, PrintDivider } from 'components/styles';
import moment from 'moment';

import { Typography, Box } from '@mui/material';

export const SinglePrintLicensePlate = React.forwardRef(
  (props: any, ref: any) => {
    useEffect(() => {
      props.data.button?.click();
    }, [props.data]);

    return (
      <PrintContainer ref={ref} sx={{ display: 'block' }}>
        {props.data.licensePlateType === 1 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              pageBreakBefore: 'always',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                paddingY: '24px',
              }}
            >
              <Barcode
                value={
                  props.data?.lpUnknown?.licensePlateNo === undefined
                    ? '00000000'
                    : props.data?.lpUnknown?.licensePlateNo
                }
                width={5}
                height={120}
                fontSize={75}
              />
            </Box>
            {props.data?.lpUnknown?.lineItems.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '95%',
                    fontSize: '15px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '20%',
                    }}
                  >
                    SKU
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '15%',
                    }}
                  >
                    Cases
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '10%',
                    }}
                  >
                    Ea
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '10%',
                    }}
                  >
                    Qty
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '30%',
                    }}
                  >
                    Lot No.
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '20%',
                    }}
                  >
                    Lot Exp.
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', width: '95%' }}>
                  <PrintDivider />
                </Box>
              </Box>
            )}

            {props.data?.lpUnknown?.lineItems.map((i) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '95%',
                    fontSize: '10px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '20%',
                      fontSize: '10px',
                    }}
                  >
                    {i.sku}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '15%',
                    }}
                  >
                    {i?.cases}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '10%',
                    }}
                  >
                    {i?.eaCase}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '10%',
                    }}
                  >
                    {i?.totalQty}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '30%',
                    }}
                  >
                    {i?.lotNo}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '20%',
                    }}
                  >
                    {i.lotExpirationDate
                      ? moment(i.lotExpirationDate).format('MM/DD/yyyy')
                      : ''}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              pageBreakBefore: 'always',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                paddingY: '24px',
              }}
            >
              <Typography variant="subtitle1">
                {`Reference No: ${props.data?.lpKnown?.referenceNo}`}
              </Typography>
              <Typography variant="subtitle1">
                {`Received: ${moment(props.data?.lpKnown?.received).format(
                  'l, h:mm a',
                )}`}
              </Typography>
              <Barcode
                value={
                  props.data?.lpKnown?.licensePlateNo === undefined
                    ? '00000000'
                    : props.data?.lpKnown?.licensePlateNo
                }
                width={5}
                height={120}
                fontSize={75}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '95%',
                  fontSize: '15px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    width: '20%',
                  }}
                >
                  SKU
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    width: '15%',
                  }}
                >
                  Cases
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    width: '10%',
                  }}
                >
                  Ea
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    width: '10%',
                  }}
                >
                  Qty
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    width: '30%',
                  }}
                >
                  Lot No.
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    width: '20%',
                  }}
                >
                  Lot Exp.
                </Box>
              </Box>
              <Box sx={{ display: 'flex', width: '95%' }}>
                <PrintDivider />
              </Box>
            </Box>
            {props.data?.lpKnown?.lineItems.map((i) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '95%',
                    fontSize: '10px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '20%',
                      fontSize: '10px',
                    }}
                  >
                    {i.sku}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '15%',
                    }}
                  >
                    {i?.cases}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '10%',
                    }}
                  >
                    {i?.eaCase}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '10%',
                    }}
                  >
                    {i?.totalQty}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '30%',
                    }}
                  >
                    {i?.lotNo}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      width: '20%',
                    }}
                  >
                    {i.lotExpirationDate
                      ? moment(i.lotExpirationDate).format('MM/DD/yyyy')
                      : ''}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </PrintContainer>
    );
  },
);

export const MultiplePrintLicensePlate = React.forwardRef(
  (props: any, ref: any) => {
    useEffect(() => {
      if (props.data.length > 0) {
        props.data[0].button?.click();
      }
    }, [props.data]);
    return (
      <PrintContainer ref={ref} sx={{ display: 'block' }}>
        {props.data.map((element, index) => (
          <div>
            {element.licensePlateType === 1 ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  pageBreakBefore: 'always',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    paddingY: '24px',
                  }}
                >
                  <Barcode
                    value={
                      element?.lpUnknown?.licensePlateNo === undefined
                        ? '00000000'
                        : element?.lpUnknown?.licensePlateNo
                    }
                    width={5}
                    height={120}
                    fontSize={75}
                  />
                </Box>
                {element?.lpUnknown?.lineItems.length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '95%',
                        fontSize: '15px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '20%',
                        }}
                      >
                        SKU
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '15%',
                        }}
                      >
                        Cases
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '10%',
                        }}
                      >
                        Ea
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '10%',
                        }}
                      >
                        Qty
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '30%',
                        }}
                      >
                        Lot No.
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '20%',
                        }}
                      >
                        Lot Exp.
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', width: '95%' }}>
                      <PrintDivider />
                    </Box>
                  </Box>
                )}

                {element?.lpUnknown?.lineItems.map((i) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '95%',
                        fontSize: '10px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '20%',
                          fontSize: '10px',
                        }}
                      >
                        {i.sku}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '15%',
                        }}
                      >
                        {i?.cases}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '10%',
                        }}
                      >
                        {i?.eaCase}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '10%',
                        }}
                      >
                        {i?.totalQty}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '30%',
                        }}
                      >
                        {i?.lotNo}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '20%',
                        }}
                      >
                        {i.lotExpirationDate
                          ? moment(i.lotExpirationDate).format('MM/DD/yyyy')
                          : ''}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  pageBreakBefore: 'always',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    paddingY: '24px',
                  }}
                >
                  <Typography variant="subtitle1">
                    {`Reference No: ${element?.lpKnown?.referenceNo}`}
                  </Typography>
                  <Typography variant="subtitle1">
                    {`Received: ${moment(element?.lpKnown?.received).format(
                      'l, h:mm a',
                    )}`}
                  </Typography>
                  <Barcode
                    value={
                      element?.lpKnown?.licensePlateNo === undefined
                        ? '00000000'
                        : element?.lpKnown?.licensePlateNo
                    }
                    width={5}
                    height={120}
                    fontSize={75}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '95%',
                      fontSize: '15px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'start',
                        width: '20%',
                      }}
                    >
                      SKU
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'start',
                        width: '15%',
                      }}
                    >
                      Cases
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'start',
                        width: '10%',
                      }}
                    >
                      Ea
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'start',
                        width: '10%',
                      }}
                    >
                      Qty
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'start',
                        width: '30%',
                      }}
                    >
                      Lot No.
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'start',
                        width: '20%',
                      }}
                    >
                      Lot Exp.
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', width: '95%' }}>
                    <PrintDivider />
                  </Box>
                </Box>
                {element?.lpKnown?.lineItems.map((i) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '95%',
                        fontSize: '10px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '20%',
                          fontSize: '10px',
                        }}
                      >
                        {i.sku}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '15%',
                        }}
                      >
                        {i?.cases}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '10%',
                        }}
                      >
                        {i?.eaCase}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '10%',
                        }}
                      >
                        {i?.totalQty}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '30%',
                        }}
                      >
                        {i?.lotNo}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '20%',
                        }}
                      >
                        {i.lotExpirationDate
                          ? moment(i.lotExpirationDate).format('MM/DD/yyyy')
                          : ''}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </div>
        ))}
      </PrintContainer>
    );
  },
);
