import React, { useContext } from 'react';

import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { deleteCustomRule } from 'services/api/orderprocessingrule';

import { Modal, Box, Typography, Grid as MUIGrid, Button } from '@mui/material';

import { useOrderProcessingRulesContext } from '../context';

export default React.memo(() => {
  const {
    viewLoadDataGrid,
    customeRuleIdToDelete,
    isDeleteCustomeRuleModalOpen,
    setIsDeleteCustomeRuleModalOpen,
  } = useOrderProcessingRulesContext();

  const handleOnDeleteConfirm = async () => {
    await deleteCustomRule(customeRuleIdToDelete)
      .then(() => {
        setIsDeleteCustomeRuleModalOpen(false);
        viewLoadDataGrid();
        snackActions.success('Successfully deleted');
      })
      .catch((err) => {
        snackActions.error(err);
      });
  };
  return (
    <Modal open={isDeleteCustomeRuleModalOpen}>
      <MUIContainer sx={{ width: '30% !important' }}>
        <Typography
          className="modalTextHeaderError"
          variant="h6"
          fontWeight="bold"
        >
          Delete Custom Rule
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <Typography variant="h6">
              Are you sure you want to delete?
            </Typography>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={5}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => setIsDeleteCustomeRuleModalOpen(false)}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(214, 79, 79) !important',
                }}
                onClick={() => handleOnDeleteConfirm()}
              >
                Confirm
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
