import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getCarrierAccountByCarrierId = async (carrierId: number) => {
  try {
    const response = await httpClient.get(
      `/easypost/carrier/account/internal/bycarrierId/${carrierId}`,
      { params: { showLoading: true } },
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const editCarrierAccount = async (data) => {
  try {
    const response = await httpClient.post(
      `/easypost/carrier/account/internal/edit`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCarrierAccountByCustomer = async () => {
  try {
    const response = await httpClient.get(
      `/easypost/carrier/account/internal/customer`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const deleteCarrierAccountByCustomer = async (data) => {
  try {
    const response = await httpClient.post(
      `/easypost/carrier/account/internal/customer/delete`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createEasyPostConfiguration = async (payload) => {
  try {
    const response = await httpClient.post(
      `/easypost/configuration/create`,
      payload,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getEasyPostConfiguration = async () => {
  try {
    const response = await httpClient.get(`/easypost/configuration`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const disconnectConfiguration = async () => {
  try {
    const response = await httpClient.get(`/easypost/configuration/disconnect`);

    return response.data;
  } catch (err) {
    throw errorCatch(err);
  }
};

export const createCarrierWitCustomService = async (payload) => {
  try {
    const response = await httpClient.post(
      `/easypost/carrier/createCarrierWitCustomService`,
      payload,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
