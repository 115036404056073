import { EnumBrightness } from 'common/enums';

export const colorAdjustment = (color: string, percent: number) => {
  let usePound = false;
  let hexColor = color;

  if (color[0] === '#') {
    hexColor = color.slice(1).toString();
    usePound = true;
  }

  let R = parseInt(hexColor.substring(0, 2), 16);
  let G = parseInt(hexColor.substring(2, 4), 16);
  let B = parseInt(hexColor.substring(4, 6), 16);

  R += percent;
  G += percent;
  B += percent;

  if (R > 255) R = 255;
  else if (R < 0) R = 0;

  if (G > 255) G = 255;
  else if (G < 0) G = 0;

  if (B > 255) B = 255;
  else if (B < 0) B = 0;

  const RR =
    R.toString(16).length === 1 ? `0 ${R.toString(16)}` : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? `0 ${G.toString(16)}` : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? `0 ${B.toString(16)}` : B.toString(16);

  return (usePound ? '#' : '') + RR + GG + BB;
};

export const randomColor = () => {
  const rgb = [
    Math.floor(Math.random() * (255 - 0 + 1) + 0),
    Math.floor(Math.random() * (255 - 0 + 1) + 0),
    Math.floor(Math.random() * (255 - 0 + 1) + 0),
  ];
  const mixedrgb = [rgb[0], rgb[1], rgb[2]];
  const rgba = mixedrgb.join(',');
  return {
    color: `rgba(${rgba}, 0.7)`,
    borderColor: `rgb(${rgba})`,
  };
};

export const randomColors = (length: number) =>
  Array(length)
    .fill(undefined)
    .map(() => randomColor());
