/* eslint-disable dot-notation */
import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useRef,
  createContext,
  useMemo,
} from 'react';
import Barcode from 'react-barcode/lib/react-barcode';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import Button from 'components/button';
import Card from 'components/card';
import CardWithHeader from 'components/card/CardWithHeader';
import DatePickerInput from 'components/datepicker';
import Header from 'components/header';
import Input from 'components/input';
import { PrintContainer, PrintDivider } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import NewLotNumberModal from 'pages/shared/createnewlotnumber';
import { GetItemLookupByCarton } from 'services/api/item/item.api';
import {
  createLicensePlateKnown,
  getGenerateLicensePlateNo,
} from 'services/api/licensePlates/licensePlates.api';
import { getLotLookup } from 'services/api/lot/lot.api.';
import { getCustomersLookup } from 'services/api/salesorders/salesorders-new.api';
import { getSupplierLookupByName } from 'services/api/suppliers/suppliers.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { NumberSchema } from 'yup';
import * as yup from 'yup';

import { ChildCareOutlined, SkipPrevious } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Autocomplete,
  CardContent,
  TextField,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';

import { MainContainer, ContentContainer } from '../../styles';

interface AutoCompleteOptionType {
  id: number;
  label: string;
  expirationDate: Date | null;
}

const LicensePlateContext = createContext(
  {} as {
    onSaveLicensePlate;
    onPutAwayQueue;
    form: any;
    setForm: any;
    print: any;
    rollRef: any;
  },
);
export const useLicensePlateContext = () => useContext(LicensePlateContext);

function NewLicensePlate() {
  const { loading, updateData, onOpenNewLotNumberModal } =
    useContext(GlobalContext);
  const { currentUser } = useContext(AuthContext);
  const { onSaveLicensePlate, form, setForm, print, rollRef } =
    useLicensePlateContext();

  const defaultAutocompleteOption: AutoCompleteOptionType | null = {
    id: -1,
    label: '',
    expirationDate: null,
  };
  const [selectedItemForNewLot, setSelectedItemForNewLot] = useState<{
    id: number;
    index: number;
  }>();
  const [isReadonly, setIsReadonly] = useState(false);
  const [customerSKUs, setCustomerSKUs] = useState([]);
  const [customerSupliers, setCustomerSuppliers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [itemLotNumbers, setItemLotNumbers] = useState<
    {
      lotNos: any[];
    }[]
  >([]);
  const [nextInSequence, setNextInSequence] = useState('');
  const [printQty, setPrintQty] = useState(1);

  const date = new Date();
  const hours =
    date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
  const currentTime = `${hours}:${minutes}`;

  const [error, setError] = useState('');
  const initialFormErrorsState: any = {
    arrivalDate: '',
    lotExpirationDate: '',
    arrivalTime: '',
    supplierId: '',
    customerId: '',
    warehouseCustomerId: '',
    referenceNo: '',
    skus: [
      {
        itemId: '',
        cases: '',
        eaCase: '',
        totalQty: '',
        totalWeight: '',
        eaches: '',
      },
    ],
  };

  const initialState: any = {
    licensePlateNo: '',
    arrivalDate: date,
    lotExpirationDate: '',
    lotExpiration: '',
    arrivalTime: currentTime,
    supplierId: {
      id: defaultAutocompleteOption.id,
      label: defaultAutocompleteOption.label,
    },
    customerId: '',
    warehouseCustomerId: {
      id: defaultAutocompleteOption.id,
      label: defaultAutocompleteOption.label,
    },
    referenceNo: 0,
    skus: [
      {
        id: 1,
        itemId: {
          id: defaultAutocompleteOption.id,
          label: defaultAutocompleteOption.label,
        },
        lotId: {
          id: defaultAutocompleteOption.id,
          label: defaultAutocompleteOption.label,
        },
        cases: 0,
        eaches: 0,
        eaCase: 0,
        totalQty: 0,
        totalWeight: 0,
      },
    ],
  };

  // const [form, setForm] = useState<any>(initialState);
  const [formErrors, setFormErrors] = useState<any>(initialFormErrorsState);
  // const rollRef = useRef(null);

  const itemSelectionOptions = Array.isArray(customerSKUs)
    ? customerSKUs.map((i, index) => ({
        id: i.itemId,
        label: i.sku,
      }))
    : [{ id: 0, label: 'New Item' }];

  const supplierSelectionOptions = Array.isArray(customerSupliers)
    ? customerSupliers.map((v, index) => ({
        id: v.supplierId,
        label: v.name,
      }))
    : [{ id: 0, label: 'New Supplier' }];

  const customerOptions = Array.isArray(customers)
    ? customers.map((v, index) => ({
        id: v.warehouseCustomerId,
        label: v.name,
      }))
    : [{ id: 0, label: 'New Supplier' }];

  const generateLotSelectionOptions = (index) => {
    const lotNumberSelectionOptions = Array.isArray(
      itemLotNumbers[index]?.lotNos,
    )
      ? itemLotNumbers[index]?.lotNos.map((i) => ({
          id: i.lotId,
          label: i.lotNo,
          expirationDate: i.expirationDate,
        }))
      : [];
    return lotNumberSelectionOptions;
  };

  const onLoadLotNumbers = async (itemId, index) => {
    try {
      const lotNumbersFromApi = await getLotLookup(itemId);
      itemLotNumbers[index] = {
        lotNos: lotNumbersFromApi,
      };

      setItemLotNumbers([...itemLotNumbers]);
      return true;
    } catch (err) {
      return err;
    }
  };

  const onForm = (key, text) => {
    setForm(() => ({
      ...form,
      [key]: text,
    }));
  };

  const schema = yup.object().shape({
    arrivalDate: yup.string().required('Required'),
    arrivalTime: yup.string().required('Required'),
    warehouseCustomerId: yup.object().required('Required'),
    skus: yup
      .array()
      .of(
        yup.object().shape({
          itemId: yup
            .object()
            .shape({ id: yup.string().nullable(), label: yup.string() })
            .test(
              'empty-check',
              'An item SKU must be selected',
              (item) => !!item.label,
            )
            .typeError('Required.'),
        }),
      )
      .required('Required'),
  });

  const dynamicInputHandler = (
    key: string,
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    form.skus[index] = { ...form.skus[index], [key]: event.target.value };

    setForm(() => ({
      ...form,
    }));

    if (key === 'eaCase' || key === 'cases') {
      form.skus[index].totalQty =
        form.skus[index].eaCase * form.skus[index].cases;
      setForm(() => ({
        ...form,
      }));
    }
  };

  const dynamicAutoCompleteInputHandler = (
    key: string,
    value: AutoCompleteOptionType,
    index: number,
  ) => {
    if (key === 'itemId') {
      if (value) {
        onLoadLotNumbers(value.id, index);
      }
    }

    if (key === 'lotId') {
      form.skus[index] = {
        ...form.skus[index],
        lotExpiration: value?.expirationDate,
      };
    }

    form.skus[index] = {
      ...form.skus[index],
      [key]: value || {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
    };

    setForm(() => ({
      ...form,
    }));
  };

  const autoCompleteInputHandler = (
    key: string,
    value: AutoCompleteOptionType,
  ) => {
    setForm(() => ({
      ...form,
      [key]:
        value === null
          ? {
              id: defaultAutocompleteOption.id,
              label: defaultAutocompleteOption.label,
            }
          : value,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    onForm(key, event.target.value);
  };

  const dateInputHandler = (key: string, newValue: Date | null) => {
    onForm(key, newValue);
  };

  const timeInputHandler = (newValue: any) => {
    onForm('arrivalTime', newValue.target.value);
  };

  const onLoadResources = async () => {
    try {
      const suppliersFromApi = await getSupplierLookupByName(
        currentUser.Claim_CustomerId,
        '',
      );
      const nextInSequenceFromApi = await getGenerateLicensePlateNo(
        currentUser.Claim_CustomerId,
      );

      const customerFromApi = await getCustomersLookup(
        currentUser.Claim_CustomerId,
        '',
        true,
      );

      const itemSKUsFromApi = await GetItemLookupByCarton();

      setCustomerSKUs(itemSKUsFromApi);
      setCustomers(customerFromApi);
      setCustomerSuppliers(suppliersFromApi);
      setNextInSequence(nextInSequenceFromApi);

      return true;
    } catch (err) {
      return err;
    }
  };

  const handleAddNewSKU = () => {
    form.skus.push({
      id: form.skus.length,
      itemId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      supplierId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      customerId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      lotId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      referenceNo: 0,
      cases: 0,
      eaches: 0,
      eaCase: 0,
      totalQty: 0,
      totalWeight: 0,
    });
    setForm({ ...form });
  };

  const handleNewLotModalOpen = (item) => {
    setSelectedItemForNewLot(item);
    onOpenNewLotNumberModal();
  };

  const handleUpdateLotNumberDropdown = () => {
    onLoadLotNumbers(selectedItemForNewLot.id, selectedItemForNewLot.index);
  };

  const handleUpdatePrintQty = (event: ChangeEvent<HTMLInputElement>) => {
    setPrintQty(parseInt(event.target.value, 10));
  };
  useEffect(() => {
    onLoadResources();
  }, [loading, updateData]);

  const handleSequenceBarCode = (currentIndex: number) => {
    const aux = (parseInt(nextInSequence, 10) + currentIndex).toString();
    const result = Array(nextInSequence.length + 1 - aux.length).join('0');
    return result + aux;
  };

  return (
    <MainContainer>
      <NewLotNumberModal
        itemId={selectedItemForNewLot}
        callBack={handleUpdateLotNumberDropdown}
      />
      <Box
        sx={{
          display: 'none',
        }}
      >
        <PrintContainer ref={rollRef} sx={{ display: 'block' }}>
          {Array(printQty >= 1 ? printQty : 0)
            .fill(0)
            .map((x, i) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                      paddingY: '24px',
                    }}
                  >
                    <Typography variant="subtitle1">
                      {`Reference No: ${form.referenceNo}`}
                    </Typography>
                    <Typography variant="subtitle1">
                      {`Received: ${moment(form.arrivalDate).format(
                        'l, h:mm:ss a',
                      )}`}
                    </Typography>
                    <Barcode
                      value={
                        nextInSequence.length > 0
                          ? handleSequenceBarCode(i)
                          : 'DEFAULT'
                      }
                      width={5}
                      height={120}
                      fontSize={100}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '95%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '20%',
                        }}
                      >
                        SKU
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '15%',
                        }}
                      >
                        Cases
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '10%',
                        }}
                      >
                        Ea
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '10%',
                        }}
                      >
                        Qty
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '30%',
                        }}
                      >
                        Lot No.
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '20%',
                        }}
                      >
                        Lot Exp.
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', width: '95%' }}>
                      <PrintDivider />
                    </Box>
                  </Box>

                  {form.skus.map((sku) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '95%',
                          fontSize: '12px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'start',
                            width: '20%',
                          }}
                        >
                          {sku.itemId.label}
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'start',
                            width: '15%',
                          }}
                        >
                          {sku.cases}
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'start',
                            width: '10%',
                          }}
                        >
                          {sku.eaCase}
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'start',
                            width: '10%',
                          }}
                        >
                          {sku.totalQty}
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'start',
                            width: '30%',
                          }}
                        >
                          {sku.lotId.label}
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'start',
                            width: '20%',
                          }}
                        >
                          {moment(sku.lotExpiration).format('MM/DD/yyyy')}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <div className="page-break" />
              </>
            ))}
        </PrintContainer>
      </Box>
      <Header />
      <ContentContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: '12px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Accordion sx={{ flexGrow: 1 }} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="body1" fontWeight="bold">
                    Header
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: '24px',
                    }}
                  >
                    <Box sx={{ display: 'flex', width: '100%', gap: '24px' }}>
                      <Box sx={{ display: 'flex', width: '25%' }}>
                        <Input
                          sx={{ width: '100%', background: 'white' }}
                          placeholder="Next in Sequence"
                          size="large"
                          value={nextInSequence}
                          disabled={isReadonly}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', width: '25%' }}>
                        <Input
                          sx={{ width: '100%', background: 'white' }}
                          placeholder="Print Qty"
                          type="number"
                          min={0}
                          size="large"
                          value={printQty}
                          onChange={(value) => handleUpdatePrintQty(value)}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', width: '25%' }}>
                        <Autocomplete
                          options={customerOptions}
                          size="medium"
                          value={form.warehouseCustomerId}
                          sx={{ width: '100%' }}
                          disabled={isReadonly}
                          onChange={(
                            event: any,
                            value: AutoCompleteOptionType | null,
                          ) => {
                            autoCompleteInputHandler(
                              'warehouseCustomerId',
                              value,
                            );
                          }}
                          renderInput={(params) =>
                            formErrors.warehouseCustomerId ? (
                              <TextField
                                sx={{
                                  '& .MuiInputBase-root': {
                                    background: 'white',
                                  },
                                }}
                                {...params}
                                label="Client"
                                error
                                helperText={formErrors.warehouseCustomerId}
                                disabled={isReadonly}
                              />
                            ) : (
                              <TextField
                                sx={{
                                  '& .MuiInputBase-root': {
                                    background: 'white',
                                  },
                                }}
                                {...params}
                                label="Client"
                                disabled={isReadonly}
                              />
                            )
                          }
                        />
                      </Box>
                      <Box sx={{ display: 'flex', width: '25%' }}>
                        <Autocomplete
                          options={supplierSelectionOptions}
                          size="medium"
                          value={form.supplierId}
                          sx={{ width: '100%' }}
                          disabled={isReadonly}
                          onChange={(
                            event: any,
                            value: AutoCompleteOptionType | null,
                          ) => {
                            autoCompleteInputHandler('supplierId', value);
                          }}
                          renderInput={(params) =>
                            formErrors.supplierId ? (
                              <TextField
                                sx={{
                                  '& .MuiInputBase-root': {
                                    background: 'white',
                                  },
                                }}
                                {...params}
                                label="Supplier"
                                error
                                helperText={formErrors.supplierId}
                                disabled={isReadonly}
                              />
                            ) : (
                              <TextField
                                sx={{
                                  '& .MuiInputBase-root': {
                                    background: 'white',
                                  },
                                }}
                                {...params}
                                label="Supplier"
                                disabled={isReadonly}
                              />
                            )
                          }
                        />
                      </Box>
                      <Box sx={{ display: 'flex', width: '25%' }}>
                        <Input
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-root': {
                              background: 'white',
                            },
                          }}
                          placeholder="Reference Number"
                          type="text"
                          min={0}
                          size="large"
                          value={form.referenceNo}
                          error={formErrors.referenceNo}
                          disabled={isReadonly}
                          onChange={(value) =>
                            inputHandler('referenceNo', value)
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ flexGrow: 1 }} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="body1" fontWeight="bold">
                    Product
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '24px',
                    }}
                  >
                    {form.skus.map((sku, index) => (
                      <Box
                        key={sku.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          gap: '24px',
                          pb: '24px',
                          borderBottom: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            width: '100%',
                            gap: '24px',
                            alignItems: 'start',
                          }}
                        >
                          <Box sx={{ display: 'flex', width: '215px' }}>
                            <Autocomplete
                              options={itemSelectionOptions}
                              getOptionLabel={(
                                option: AutoCompleteOptionType,
                              ) => `${option.label}` || ''}
                              size="medium"
                              value={sku.itemId}
                              sx={{ width: '100%' }}
                              disabled={isReadonly}
                              onChange={(
                                event: any,
                                value: AutoCompleteOptionType | null,
                              ) => {
                                dynamicAutoCompleteInputHandler(
                                  'itemId',
                                  value,
                                  index,
                                );
                              }}
                              renderInput={(params) =>
                                formErrors.skus?.[index]?.itemId ? (
                                  <TextField
                                    sx={{
                                      background: 'white',
                                    }}
                                    {...params}
                                    label="Item SKU"
                                    error
                                    helperText={formErrors.skus[index]?.itemId}
                                    disabled={isReadonly}
                                  />
                                ) : (
                                  <TextField
                                    sx={{
                                      background: 'white',
                                    }}
                                    {...params}
                                    label="SKU"
                                    disabled={isReadonly}
                                  />
                                )
                              }
                            />
                          </Box>
                          <Box sx={{ display: 'flex', width: '215px' }}>
                            <Autocomplete
                              options={generateLotSelectionOptions(index)}
                              getOptionLabel={(
                                option: AutoCompleteOptionType,
                              ) => `${option.label}` || ''}
                              size="medium"
                              value={sku.lotId}
                              sx={{ width: '100%' }}
                              disabled={isReadonly}
                              onChange={(
                                event: any,
                                value: AutoCompleteOptionType | null,
                              ) => {
                                dynamicAutoCompleteInputHandler(
                                  'lotId',
                                  value,
                                  index,
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ background: 'white' }}
                                  {...params}
                                  label="Lot Number"
                                  disabled={isReadonly}
                                />
                              )}
                              noOptionsText={
                                <Button
                                  variant="text"
                                  size="medium"
                                  onMouseDown={() => {
                                    handleNewLotModalOpen({
                                      id: form.skus[index]?.itemId?.id,
                                      index,
                                    });
                                  }}
                                >
                                  + Add Lot Number
                                </Button>
                              }
                            />
                          </Box>
                          <Box sx={{ display: 'flex', width: '180px' }}>
                            <DatePickerInput
                              sx={{ width: '100%', background: 'white' }}
                              inputFormat="MM/dd/yyyy"
                              value={form.skus[index]?.lotExpiration || null}
                              label="Lot Exp."
                              onChange={(value) =>
                                dateInputHandler('lotExpiration', value)
                              }
                              renderInput={(params) =>
                                formErrors.lotExpiration ? (
                                  <TextField
                                    sx={{
                                      width: '100%',
                                      background: 'white',
                                    }}
                                    {...params}
                                    size="medium"
                                    error
                                    helperText={formErrors.lotExpiration}
                                    disabled={isReadonly}
                                  />
                                ) : (
                                  <TextField
                                    sx={{
                                      width: '100%',
                                      background: 'white',
                                    }}
                                    {...params}
                                    error={false}
                                    size="medium"
                                    disabled={isReadonly}
                                  />
                                )
                              }
                            />
                          </Box>
                          <Box sx={{ display: 'flex', width: '100px' }}>
                            <Input
                              sx={{
                                width: '100%',
                                background: 'white',
                              }}
                              placeholder="Cases"
                              type="number"
                              min={0}
                              size="medium"
                              value={sku.cases}
                              error={formErrors.skus?.[index]?.cases}
                              disabled={isReadonly}
                              onChange={(value) =>
                                dynamicInputHandler('cases', value, index)
                              }
                            />
                          </Box>
                          <Box sx={{ display: 'flex', width: '100px' }}>
                            <Input
                              sx={{
                                width: '100%',
                                background: 'white',
                              }}
                              placeholder="EA/CS"
                              type="number"
                              min={0}
                              size="medium"
                              value={sku.eaCase}
                              error={formErrors.skus?.[index]?.eaCase}
                              disabled={isReadonly}
                              onChange={(value) =>
                                dynamicInputHandler('eaCase', value, index)
                              }
                            />
                          </Box>
                          <Box sx={{ display: 'flex', width: '100px' }}>
                            <Input
                              sx={{
                                width: '100%',
                                background: 'white',
                              }}
                              placeholder="Total Qty"
                              type="number"
                              min={0}
                              size="medium"
                              value={sku.totalQty}
                              error={formErrors.skus?.[index]?.totalQty}
                              onChange={(value) =>
                                dynamicInputHandler('totalQty', value, index)
                              }
                            />
                          </Box>
                          <Box sx={{ display: 'flex', width: '100px' }}>
                            <Input
                              sx={{
                                width: '100%',
                                background: 'white',
                              }}
                              placeholder="Weight"
                              type="number"
                              min={0}
                              size="medium"
                              value={sku.totalWeight}
                              error={formErrors.skus?.[index]?.totalWeight}
                              disabled={isReadonly}
                              onChange={(value) =>
                                dynamicInputHandler('totalWeight', value, index)
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    ))}
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ display: 'flex' }}>
                        <Button
                          sx={{
                            background: 'red',
                            width: '100%',
                            justifyContent: 'start',
                            borderColor: '#cad0d3',
                          }}
                          variant="outlined"
                          size="large"
                          disabled={isReadonly}
                          onClick={handleAddNewSKU}
                        >
                          Add Another SKU
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ flexGrow: 1 }} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="body1" fontWeight="bold">
                    Arrival
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: '24px',
                    }}
                  >
                    <Box sx={{ display: 'flex', width: '100%', gap: '24px' }}>
                      <Box sx={{ display: 'flex', width: '33%' }}>
                        <DatePickerInput
                          sx={{ width: '100%', background: 'white' }}
                          inputFormat="MM/dd/yyyy"
                          value={form.arrivalDate}
                          onChange={(value) =>
                            dateInputHandler('arrivalDate', value)
                          }
                          renderInput={(params) =>
                            formErrors.arrivalDate ? (
                              <TextField
                                sx={{
                                  width: '100%',
                                  '& .MuiInputBase-root': {
                                    background: 'white',
                                  },
                                }}
                                {...params}
                                size="medium"
                                error
                                helperText={formErrors.arrivalDate}
                                disabled={isReadonly}
                              />
                            ) : (
                              <TextField
                                sx={{
                                  width: '100%',
                                  '& .MuiInputBase-root': {
                                    background: 'white',
                                  },
                                }}
                                {...params}
                                error={false}
                                size="medium"
                                disabled={isReadonly}
                              />
                            )
                          }
                        />
                      </Box>
                      <Box sx={{ display: 'flex', width: '33%' }}>
                        {formErrors.arrivalTime ? (
                          <TextField
                            id="time"
                            type="time"
                            value={form.arrivalTime}
                            disabled={isReadonly}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            sx={{
                              width: '100%',
                              '& .MuiInputBase-root': {
                                background: 'white',
                              },
                            }}
                            error
                            helperText={formErrors.arrivalTime}
                            onChange={timeInputHandler}
                          />
                        ) : (
                          <TextField
                            id="time"
                            type="time"
                            value={form.arrivalTime}
                            disabled={isReadonly}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            sx={{
                              width: '100%',
                              '& .MuiInputBase-root': {
                                background: 'white',
                              },
                            }}
                            onChange={timeInputHandler}
                          />
                        )}
                      </Box>
                      <Box sx={{ display: 'flex', width: '33%' }} />
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Card>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
            <CardWithHeader
              sx={{ display: 'flex', flexDirection: 'column' }}
              header="How it works"
            >
              <Box sx={{ display: 'flex', pr: '36px' }}>
                <Typography variant="body1" fontWeight="regular">
                  Generate labels that contain known supplier or product
                  information using this form. You may also create license
                  plates directly from Purchase Order line items in the Purchase
                  Order details page.
                </Typography>
              </Box>
            </CardWithHeader>
          </Box>
        </Box>
      </ContentContainer>
    </MainContainer>
  );
}

export const ComponentsProvider = ({ children }: any) => {
  const { loading, updateData, onOpenNewLotNumberModal } =
    useContext(GlobalContext);
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const navigate = useNavigate();
  const defaultAutocompleteOption: AutoCompleteOptionType | null = {
    id: -1,
    label: '',
    expirationDate: null,
  };
  const [selectedItemForNewLot, setSelectedItemForNewLot] = useState<{
    id: number;
    index: number;
  }>();
  const [isReadonly, setIsReadonly] = useState(false);
  const [customerSKUs, setCustomerSKUs] = useState([]);
  const [customerSupliers, setCustomerSuppliers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [itemLotNumbers, setItemLotNumbers] = useState<
    {
      lotNos: any[];
    }[]
  >([]);
  const [nextInSequence, setNextInSequence] = useState('');
  const [printQty, setPrintQty] = useState(1);

  const date = new Date();
  const hours =
    date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
  const currentTime = `${hours}:${minutes}`;

  const [error, setError] = useState('');
  const initialFormErrorsState: any = {
    arrivalDate: '',
    lotExpirationDate: '',
    arrivalTime: '',
    supplierId: '',
    customerId: '',
    warehouseCustomerId: '',
    referenceNo: '',
    skus: [
      {
        itemId: '',
        cases: '',
        eaCase: '',
        totalQty: '',
        totalWeight: '',
        eaches: '',
      },
    ],
  };

  const initialState: any = {
    licensePlateNo: '',
    arrivalDate: date,
    lotExpirationDate: '',
    lotExpiration: '',
    arrivalTime: currentTime,
    supplierId: {
      id: defaultAutocompleteOption.id,
      label: defaultAutocompleteOption.label,
    },
    customerId: '',
    warehouseCustomerId: {
      id: defaultAutocompleteOption.id,
      label: defaultAutocompleteOption.label,
    },
    referenceNo: 0,
    skus: [
      {
        id: 1,
        itemId: {
          id: defaultAutocompleteOption.id,
          label: defaultAutocompleteOption.label,
        },
        lotId: {
          id: defaultAutocompleteOption.id,
          label: defaultAutocompleteOption.label,
        },
        cases: 0,
        eaches: 0,
        eaCase: 0,
        totalQty: 0,
        totalWeight: 0,
      },
    ],
  };

  const [form, setForm] = useState<any>(initialState);
  const [formErrors, setFormErrors] = useState<any>(initialFormErrorsState);
  const rollRef = useRef(null);
  const print = useReactToPrint({
    content: () => rollRef.current,
  });

  const itemSelectionOptions = Array.isArray(customerSKUs)
    ? customerSKUs.map((i, index) => ({
        id: i.itemId,
        label: i.sku,
      }))
    : [{ id: 0, label: 'New Item' }];

  const supplierSelectionOptions = Array.isArray(customerSupliers)
    ? customerSupliers.map((v, index) => ({
        id: v.supplierId,
        label: v.name,
      }))
    : [{ id: 0, label: 'New Supplier' }];

  const customerOptions = Array.isArray(customers)
    ? customers.map((v, index) => ({
        id: v.warehouseCustomerId,
        label: v.name,
      }))
    : [{ id: 0, label: 'New Supplier' }];

  const generateLotSelectionOptions = (index) => {
    const lotNumberSelectionOptions = Array.isArray(
      itemLotNumbers[index]?.lotNos,
    )
      ? itemLotNumbers[index]?.lotNos.map((i) => ({
          id: i.lotId,
          label: i.lotNo,
          expirationDate: i.expirationDate,
        }))
      : [];
    return lotNumberSelectionOptions;
  };

  const onLoadLotNumbers = async (itemId, index) => {
    try {
      const lotNumbersFromApi = await getLotLookup(itemId);
      itemLotNumbers[index] = {
        lotNos: lotNumbersFromApi,
      };

      setItemLotNumbers([...itemLotNumbers]);
      return true;
    } catch (err) {
      return err;
    }
  };

  const onForm = (key, text) => {
    setForm(() => ({
      ...form,
      [key]: text,
    }));
  };

  const schema = yup.object().shape({
    arrivalDate: yup.string().required('Required'),
    arrivalTime: yup.string().required('Required'),
    warehouseCustomerId: yup.object().required('Required'),
    skus: yup
      .array()
      .of(
        yup.object().shape({
          itemId: yup
            .object()
            .shape({ id: yup.string().nullable(), label: yup.string() })
            .test(
              'empty-check',
              'An item SKU must be selected',
              (item) => !!item.label,
            )
            .typeError('Required.'),
        }),
      )
      .required('Required'),
  });

  const dynamicInputHandler = (
    key: string,
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    form.skus[index] = { ...form.skus[index], [key]: event.target.value };

    setForm(() => ({
      ...form,
    }));
  };

  const dynamicAutoCompleteInputHandler = (
    key: string,
    value: AutoCompleteOptionType,
    index: number,
  ) => {
    if (key === 'itemId') {
      if (value) {
        onLoadLotNumbers(value.id, index);
      }
    }

    if (key === 'lotId') {
      form.skus[index] = {
        ...form.skus[index],
        lotExpiration: value?.expirationDate,
      };
    }

    form.skus[index] = {
      ...form.skus[index],
      [key]: value || {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
    };

    setForm(() => ({
      ...form,
    }));
  };

  const autoCompleteInputHandler = (
    key: string,
    value: AutoCompleteOptionType,
  ) => {
    setForm(() => ({
      ...form,
      [key]:
        value === null
          ? {
              id: defaultAutocompleteOption.id,
              label: defaultAutocompleteOption.label,
            }
          : value,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    onForm(key, event.target.value);
  };

  const dateInputHandler = (key: string, newValue: Date | null) => {
    onForm(key, newValue);
  };

  const timeInputHandler = (newValue: any) => {
    onForm('arrivalTime', newValue.target.value);
  };

  const onLoadResources = async () => {
    try {
      const suppliersFromApi = await getSupplierLookupByName(
        currentUser.Claim_CustomerId,
        '',
      );
      const nextInSequenceFromApi = await getGenerateLicensePlateNo(
        currentUser.Claim_CustomerId,
      );

      const customerFromApi = await getCustomersLookup(
        currentUser.Claim_CustomerId,
        '',
      );

      const itemSKUsFromApi = await GetItemLookupByCarton();

      setCustomerSKUs(itemSKUsFromApi);
      setCustomers(customerFromApi);
      setCustomerSuppliers(suppliersFromApi);
      setNextInSequence(nextInSequenceFromApi);

      return true;
    } catch (err) {
      return err;
    }
  };

  const handleAddNewSKU = () => {
    form.skus.push({
      id: form.skus.length,
      itemId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      supplierId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      customerId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      lotId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      referenceNo: 0,
      cases: 0,
      eaches: 0,
      eaCase: 0,
      totalQty: 0,
      totalWeight: 0,
    });
    setForm({ ...form });
  };

  const handleNewLotModalOpen = (item) => {
    setSelectedItemForNewLot(item);
    onOpenNewLotNumberModal();
  };

  const handleUpdateLotNumberDropdown = () => {
    onLoadLotNumbers(selectedItemForNewLot.id, selectedItemForNewLot.index);
  };

  const handleUpdatePrintQty = (event: ChangeEvent<HTMLInputElement>) => {
    setPrintQty(parseInt(event.target.value, 10));
  };

  const createLicensePlate = async (isPutAway: boolean) => {
    schema
      .validate(form, {
        abortEarly: false,
      })
      .then(async () => {
        try {
          const newForm: any = {};
          form.arrivalDate.setHours(
            parseInt(form.arrivalTime.split(':')[0], 10),
            parseInt(form.arrivalTime.split(':')[1], 10),
          );
          newForm.customerId = currentUser.Claim_CustomerId;
          newForm.warehouseCustomerId = form.warehouseCustomerId.id;
          newForm.arrivalDateTime = moment(form.arrivalDate)
            .utcOffset(0, true)
            .format();
          newForm.licensePlateNo = nextInSequence;
          newForm.isPutAway = isPutAway;
          newForm.customerLocationId =
            currentLocationAndFacility.customerLocationId;
          newForm.products = form.skus.map((sku) => ({
            itemId: sku.itemId.id.toString(),
            lotId: sku.lotId.id === -1 ? null : sku.lotId.id.toString(),
            supplierId:
              form.supplierId.id === -1 ? null : form.supplierId.id.toString(),
            customerId:
              form.customerId === -1 ? null : form.customerId.toString(),
            referenceNo: form.referenceNo,
            cases: sku.cases,
            eaches: sku.eaches,
            eaCase: sku.eaCase,
            totalQty: sku.totalQty,
            totalWeight: sku.totalWeight,
          }));

          const newLicensePlate = await createLicensePlateKnown(newForm);

          if (isPutAway) {
            snackActions.success(
              `Successfully created new license plate and put away.`,
            );
            navigate(`/license-plates`);
          } else {
            snackActions.success(`Successfully created new license plate.`);
            setIsReadonly(true);
            setFormErrors(initialFormErrorsState);
          }
        } catch (err: any) {
          setError(err);
          setFormErrors(initialFormErrorsState);
          snackActions.error(`${err}`);
        }
      })
      .catch((err) => {
        const errorsFound = err.inner.reduce((acc, curr) => {
          if (
            !acc[curr.path] &&
            !curr.path.includes('[') &&
            !curr.path.includes(']')
          )
            acc[curr.path] = curr.message;
          if (curr.path.includes('[') && curr.path.includes(']')) {
            const index = parseInt(
              curr.path.slice(
                curr.path.indexOf('[') + 1,
                curr.path.lastIndexOf(']'),
              ),
              10,
            );
            const nestedField = curr.path.substring(curr.path.indexOf('.') + 1);

            if (!acc['skus']) acc['skus'] = [];
            if (!acc['skus'][index]) acc['skus'][index] = {};

            switch (nestedField) {
              case 'itemId':
                acc['skus'][index].itemId = curr.message;
                break;
              case 'supplierId':
                acc['skus'][index].supplierId = curr.message;
                break;
              case 'customerId':
                acc['skus'][index].customerId = curr.message;
                break;
              case 'referenceNo':
                acc['skus'][index].referenceNo = curr.message;
                break;
              case 'cases':
                acc['skus'][index].cases = curr.message;
                break;
              case 'eaCase':
                acc['skus'][index].eaCase = curr.message;
                break;
              case 'totalQty':
                acc['skus'][index].totalQty = curr.message;
                break;
              case 'totalWeight':
                acc['skus'][index].totalWeight = curr.message;
                break;
              default:
                console.log('nothing');
            }
          }
          return acc;
        }, {});
        setFormErrors(errorsFound);
        setError('');
      });
  };

  const onSaveLicensePlate = async () => {
    createLicensePlate(false);
  };

  const onPutAwayQueue = async () => {
    createLicensePlate(true);
  };

  useEffect(() => {
    onLoadResources();
  }, [loading, updateData]);

  const handleSequenceBarCode = (currentIndex: number) => {
    const aux = (parseInt(nextInSequence, 10) + currentIndex).toString();
    const result = Array(nextInSequence.length + 1 - aux.length).join('0');
    return result + aux;
  };

  const value = useMemo(
    () => ({
      onSaveLicensePlate,
      onPutAwayQueue,
      form,
      setForm,
      print,
      rollRef,
    }),
    [onSaveLicensePlate, onPutAwayQueue, form, setForm, print, rollRef],
  );

  return (
    <LicensePlateContext.Provider value={value}>
      {children}
    </LicensePlateContext.Provider>
  );
};

function LicensePlateContextContainer() {
  return (
    <MainContainer>
      <ComponentsProvider>
        <NewLicensePlate />
      </ComponentsProvider>
    </MainContainer>
  );
}

export default React.memo(LicensePlateContextContainer);
