import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getLicensePlateToPrint = async (licensePlateId) => {
  try {
    const response = await httpClient.get(
      `LicensePlate/Print/${licensePlateId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteLicensePlate = async (licensePlateId) => {
  try {
    const response = await httpClient.delete(`LicensePlate/${licensePlateId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
