import React, { useContext, useEffect } from 'react';
import Barcode from 'react-barcode/lib/react-barcode';

import { GlobalContext } from 'store/contexts/GlobalContext';

import { Box, Dialog } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { maxWidth, width } from '@mui/system';

interface IlocationName {
  name?: string;
}
export interface IPrintBarcodeLocation {
  locations?: IlocationName[] | [];
}
interface PrintBarcodeLocationDialogProps {
  data?: IPrintBarcodeLocation;
}

export default React.memo(({ data }: PrintBarcodeLocationDialogProps) => {
  const { isBinPrintBarcodeModalOpen, onCloseBinPrintBarcodeModal } =
    useContext(GlobalContext);

  const handleModalClose = () => {
    onCloseBinPrintBarcodeModal();
  };

  useEffect(() => {
    if (
      isBinPrintBarcodeModalOpen &&
      data?.locations !== undefined &&
      data?.locations.length > 0
    ) {
      setTimeout(() => {
        window.print();
        handleModalClose();
      }, 800);
    }
  }, [isBinPrintBarcodeModalOpen, data?.locations]);

  return (
    <Dialog
      sx={{ height: '100% !important' }}
      maxWidth="md"
      fullWidth
      open={isBinPrintBarcodeModalOpen}
      onClose={() => handleModalClose()}
      className="barcodeDisplayOnPrint"
    >
      <DialogContent>
        <div>
          <style type="text/css" media="print">
            {`
            
             @media print {
                    body > *:not(.barcodeDisplayOnPrint) {
                      visibility:hidden !important;
                      background-color: inherit !important;
                    }
                }
            @media print and (width: 4in) and (height: 6in) {
                   @page { 
                        size: landscape !important;
                        margin: 0 !important;
                    }

                    html, body {
                      height: 100vh !important;  
                      margin: 0 !important;
                      padding: 0 !important;
                      overflow: hidden;
                    }

                    .barcodeDisplayOnPrint * {
                      max-width: inherit !important;  
                      overflow: hidden;
                      margin-bottom: 0 !important;
                      padding: 0 !important;
                    }
                    
                  }
               
              `}
          </style>
          {data?.locations !== undefined &&
            data?.locations.length > 0 &&
            data?.locations.map((row) => (
              <div
                className="barcodeDisplayOnPrint"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '90vh',
                }}
              >
                <Barcode
                  key={`bar-${row?.name}`}
                  margin={0}
                  value={row?.name}
                  displayValue={false}
                  width={2.5}
                />
                <p
                  style={{
                    fontSize: '50px',
                    lineHeight: 'normal',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {row.name}
                </p>
              </div>
            ))}
        </div>
      </DialogContent>
    </Dialog>
  );
});
