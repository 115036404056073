import { memo, useContext } from 'react';

import Input from 'components/input';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import { usePackAndShipContext } from 'pages/packandship/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Button,
  Box,
  Typography,
  TextField,
  Grid as MUIGrid,
  InputAdornment,
} from '@mui/material';

export default memo(() => {
  const { isPackAndShipCompleteModalOpen, setIsPackAndShipCompleteModalOpen } =
    useContext(GlobalContext);

  const {
    pickTicketFormObj,
    setPickTicketFormObj,
    setAllowBarcodeScanner,
    updatePackageToShipAndCompleteOnClick,
  } = usePackAndShipContext();

  const handleCloseFreighhtInfo = () => {
    setIsPackAndShipCompleteModalOpen(false);
    setAllowBarcodeScanner(true);

    setPickTicketFormObj((prev) => ({
      ...prev,
      proNumberFromCarrier: '',
      notesFromCarrier: '',
      shippingCost: null,
    }));
  };

  return (
    <Modal open={isPackAndShipCompleteModalOpen}>
      <MUIContainer sx={{ width: '25% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Provide Information to Complete Order
        </Typography>
        <MUIContent sx={{ padding: '10px 20px !important' }}>
          <Box autoComplete="off" component="form" noValidate>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item sm={12}>
                <TextField
                  id="outlined"
                  label="PRO or Tracking #"
                  value={pickTicketFormObj?.proNumberFromCarrier}
                  name="note"
                  size="small"
                  onChange={(e) => {
                    setPickTicketFormObj((prev) => ({
                      ...prev,
                      proNumberFromCarrier: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item sm={12}>
                <Input
                  placeholder="Shipping Cost"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  type="number"
                  value={pickTicketFormObj?.shippingCost}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );

                    setPickTicketFormObj((prev) => ({
                      ...prev,
                      shippingCost: Number(validateDecimal),
                    }));
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </MUIGrid>
              <MUIGrid item sm={12}>
                <TextField
                  id="outlined"
                  label="Notes (Optional)"
                  value={pickTicketFormObj?.notesFromCarrier}
                  name="note"
                  size="small"
                  sx={{ width: '100%' }}
                  onChange={(e) => {
                    setPickTicketFormObj((prev) => ({
                      ...prev,
                      notesFromCarrier: e.target.value,
                    }));
                  }}
                  multiline
                  rows={3}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={8}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => handleCloseFreighhtInfo()}
                variant="outlined"
                size="large"
                sx={{ padding: 1, width: '100%' }}
              >
                CANCEL
              </Button>

              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={(e) => {
                  updatePackageToShipAndCompleteOnClick(e);
                }}
              >
                Complete
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
