import React, { useContext } from 'react';

import Button from 'components/button';
import { ModalFooterEnd } from 'components/modal/styles';
import { ModalBox, ModalContent } from 'components/styles';
import moment from 'moment';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridStandard } from 'styles';

import { Modal, Box, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';

import { useSalesOrderContext } from '../salesorder/context';

export const ModalPackageLineItem = () => {
  const {
    isOrderPackageLineItemModalOpen,
    setIsOrderPackageLineItemModalOpen,
  } = useContext(GlobalContext);

  const { orderPackageLineItems, orderPackageName } = useSalesOrderContext();

  const orderPackageLineItemsColumns: GridColumns = [
    {
      field: 'sku',
      minWidth: 150,
      flex: 1,
      headerName: 'SKU',
    },
    {
      field: 'description',
      minWidth: 150,
      flex: 1,
      headerName: 'Description',
    },
    {
      field: 'serialNo',
      minWidth: 100,
      flex: 1,
      headerName: 'Serial No',
    },
    {
      field: 'uom',
      minWidth: 100,
      flex: 1,
      headerName: 'UOM',
    },
    {
      field: 'purchaseOrderNo',
      minWidth: 150,
      flex: 1,
      headerName: 'PO No',
    },
    {
      field: 'lotNo',
      minWidth: 100,
      flex: 1,
      headerName: 'Lot No',
    },
    {
      field: 'lotExpirationDate',
      minWidth: 150,
      flex: 1,
      headerName: 'Lot Expiration Date',
      renderCell: (params) => (
        <Typography>
          {params.row.lotExpirationDate &&
            moment(params.row.lotExpirationDate).format('MM/DD/YYYY')}
        </Typography>
      ),
    },
    {
      field: 'qty',
      minWidth: 100,
      flex: 1,
      headerName: 'Quantity',
    },
  ];

  return (
    <Modal open={isOrderPackageLineItemModalOpen}>
      <ModalBox sx={{ maxWidth: '1000px !important' }}>
        <ModalContent>
          <Typography
            sx={{ marginBottom: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Package {orderPackageName}
          </Typography>

          <Box
            sx={{
              width: '100%',
            }}
          >
            <DatagridStandard
              autoHeight
              rows={orderPackageLineItems}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              disableSelectionOnClick
              hideFooter
              columns={orderPackageLineItemsColumns}
              rowsPerPageOptions={[25]}
              pageSize={25}
              getRowId={(row) => row.packageLineItemId}
            />
          </Box>
          <ModalFooterEnd>
            <Button
              variant="text"
              size="medium"
              onClick={() => setIsOrderPackageLineItemModalOpen(false)}
            >
              Close
            </Button>
          </ModalFooterEnd>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
};
