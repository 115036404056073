/* eslint-disable no-unused-vars */
export enum ItemInventoryUOMEnum {
  Each = 1,
  Carton = 2,
  InnerCarton = 3,
  Pallet = 4,
  Drum = 5,
  Bag = 6,
  Pound = 7,
  Kilogram = 8,
  Gram = 9,
  Ounce = 10,
  Yard = 11,
  Foot = 12,
  Inch = 13,
  Millimiter = 14,
  Liter = 15,
  SquareFeet = 16,
  SquareInch = 17,
  Pails = 18,
  HazmatPails = 19,
  SmallCylinders = 20,
  MediumCylinders = 21,
  LargeCylinders = 22,
  IBCTote = 23,
  HazmatIBCTote = 24,
  HazmatDrums = 25,
  HazmatCartons = 26,
}

export function getItemInventoryUOMEnumDescription(uom: ItemInventoryUOMEnum) {
  switch (uom) {
    case ItemInventoryUOMEnum.Each:
      return 'Each (ea)';
    case ItemInventoryUOMEnum.Carton:
      return 'Carton (ct)';
    case ItemInventoryUOMEnum.InnerCarton:
      return 'Inner Carton (in ct)';
    case ItemInventoryUOMEnum.Pallet:
      return 'Pallet (pallet)';
    case ItemInventoryUOMEnum.Drum:
      return 'Drum (drm)';
    case ItemInventoryUOMEnum.Bag:
      return 'Bag (bag)';
    case ItemInventoryUOMEnum.Pound:
      return 'Pound (lbs)';
    case ItemInventoryUOMEnum.Kilogram:
      return 'Kilogram (kg)';
    case ItemInventoryUOMEnum.Gram:
      return 'Gram (g)';
    case ItemInventoryUOMEnum.Ounce:
      return 'Ounce (oz)';
    case ItemInventoryUOMEnum.Yard:
      return 'Yard (yd)';
    case ItemInventoryUOMEnum.Foot:
      return 'Foot (ft)';
    case ItemInventoryUOMEnum.Inch:
      return 'Inch (in)';
    case ItemInventoryUOMEnum.Millimiter:
      return 'Millimiter (ml)';
    case ItemInventoryUOMEnum.Liter:
      return 'Liter (L)';
    case ItemInventoryUOMEnum.SquareFeet:
      return 'Square Feet (sq ft)';
    case ItemInventoryUOMEnum.SquareInch:
      return 'Square Inch (sq in)';
    case ItemInventoryUOMEnum.Pails:
      return 'Pails (PLS)';
    case ItemInventoryUOMEnum.HazmatPails:
      return 'Hazmat Pails (HPLS)';
    case ItemInventoryUOMEnum.SmallCylinders:
      return 'Small Cylinders - (CYLS)';
    case ItemInventoryUOMEnum.MediumCylinders:
      return 'Medium Cylinders - (CYLM)';
    case ItemInventoryUOMEnum.LargeCylinders:
      return 'Large Cylinders - (CYLL)';
    case ItemInventoryUOMEnum.IBCTote:
      return 'IBC / Tote - (TOTE)';
    case ItemInventoryUOMEnum.HazmatIBCTote:
      return 'Hazmat IBC / Tote (HTOTE)';
    case ItemInventoryUOMEnum.HazmatDrums:
      return 'Hazmat Drums (HDRM)';
    case ItemInventoryUOMEnum.HazmatCartons:
      return 'Hazmat Cartons (HCTN)';
    default:
      return '';
  }
}

export function getItemInventoryUOMEnumAbbreviation(uom: ItemInventoryUOMEnum) {
  switch (uom) {
    case ItemInventoryUOMEnum.Each:
      return 'ea';
    case ItemInventoryUOMEnum.Carton:
      return 'CT';
    case ItemInventoryUOMEnum.InnerCarton:
      return 'In CT';
    case ItemInventoryUOMEnum.Pallet:
      return 'pallet';
    case ItemInventoryUOMEnum.Drum:
      return 'drm';
    case ItemInventoryUOMEnum.Bag:
      return 'bag';
    case ItemInventoryUOMEnum.Pound:
      return 'lbs';
    case ItemInventoryUOMEnum.Kilogram:
      return 'kg';
    case ItemInventoryUOMEnum.Gram:
      return 'k';
    case ItemInventoryUOMEnum.Ounce:
      return 'oz';
    case ItemInventoryUOMEnum.Yard:
      return 'yd';
    case ItemInventoryUOMEnum.Foot:
      return 'ft';
    case ItemInventoryUOMEnum.Inch:
      return 'in';
    case ItemInventoryUOMEnum.Millimiter:
      return 'ML';
    case ItemInventoryUOMEnum.Liter:
      return 'l';
    case ItemInventoryUOMEnum.SquareFeet:
      return 'sq ft';
    case ItemInventoryUOMEnum.SquareInch:
      return 'sq in';
    case ItemInventoryUOMEnum.Pails:
      return 'Pails (PLS)';
    case ItemInventoryUOMEnum.HazmatPails:
      return 'hpls';
    case ItemInventoryUOMEnum.SmallCylinders:
      return 'cyls';
    case ItemInventoryUOMEnum.MediumCylinders:
      return 'cylm';
    case ItemInventoryUOMEnum.LargeCylinders:
      return 'cyll';
    case ItemInventoryUOMEnum.IBCTote:
      return 'tote';
    case ItemInventoryUOMEnum.HazmatIBCTote:
      return 'htote';
    case ItemInventoryUOMEnum.HazmatDrums:
      return 'hdrm';
    case ItemInventoryUOMEnum.HazmatCartons:
      return 'hctn';
    default:
      return '';
  }
}
