import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

export const DatagridStandard = styled(DataGrid)(() => ({
  color: '#888888',
  border: '1px solid #dde0e3',
  borderRadius: 0,
  background: '#ffffff',
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-main': {
    borderTop: '1px solid #c0c1c5',
  },
  '& .MuiDataGrid-virtualScrollerRenderZone,': {
    borderBottom: '1px solid #c0c1c5',
  },
  '& .MuiDataGrid-virtualScroller': {
    maxHeight: '65vh !important',
    wrapText: true,
    marginTop: '30px !important',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    backgroundColor: '#f7f7fb',
  },
  '& .MuiDataGrid-columnSeparator--sideRight': {
    display: 'none !important',
  },
  '& .MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeadersInner': {
    minHeight: '30px!important',
    maxHeight: 'none!important',
    height: '30px !important',
    lineHeight: '30px !important',
    fontSize: '14px !important',
    fontWeight: '600',
    color: '#333 !important',
    borderColor: '#c0c1c5 !important',
    '& .MuiDataGrid-columnHeader': {
      minWidth: '30px!important',
      maxWidth: 'none !important',
      height: '30px',
      borderRight: '1px solid #dde0e3',
      '&:focus': {
        outline: 'none !important',
      },
      '&:focus-within': {
        outline: 'none !important',
      },
      '& p, .MuiDataGrid-columnHeaderTitle': {
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '30px !important',
      },
      '& .MuiDataGrid-menuIcon': {
        marginRight: 5,
      },
    },
    '& .MuiDataGrid-columnHeaderCheckbox': {
      maxWidth: '30px!important',
      width: '30px !important',
      '& .MuiCheckbox-root': {
        padding: '0 !important',
        '& > svg': {
          fontSize: '1.2rem',
        },
      },
    },
    '& .MuiDataGrid-iconButtonContainer': {
      position: 'absolute',
      right: 0,
      width: 'auto',
      '& .MuiIconButton-root': {
        fontSize: '14px !important',
        color: '#777b7d',
      },
    },
  },
  '& .MuiDataGrid-row, .tr': {
    wrapText: true,
    borderBottom: '1px solid #e7e9eb',
    fontSize: '14px !important',
    '&:nth-of-type(even)': {
      backgroundColor: '#f4f5f5',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: '#ffffff',
    },
    '&:last-child': {
      borderBottom: 'none !important',
    },
    '&:hover': {
      backgroundColor: '#fc0',
      color: '#333',
    },
    '& .MuiDataGrid-cell': {
      height: 'auto !important;',
      wrapText: true,
      borderRight: '1px solid #e7e9eb',
      backgroundColor: 'transparent',
      '&:focus': {
        outline: 'none !important',
      },
      '&:focus-within': {
        outline: 'none !important',
      },
      '& p, .MuiButton-text': {
        fontSize: '13px !important',
      },
    },
    '& .MuiDataGrid-cellCheckbox': {
      minWidth: '30px!important',
      maxWidth: '30px!important',
      width: '30px !important',
      '& .MuiCheckbox-root': {
        padding: '0 !important',
        '& > svg': {
          fontSize: '1.2rem',
        },
      },
    },
  },
}));
