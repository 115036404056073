import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getTransferHistory = async (
  customerLocationId,
  customerFacilityId,
) => {
  try {
    const response = await httpClient.get(
      `/transfer/transferhistory/${customerLocationId}/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateTransferStatusToCancel = async (data) => {
  try {
    const response = await httpClient.post(
      `/transfer/update/status/cancel`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const removeTransferHistory = async (payload) => {
  try {
    const response = await httpClient.post(`/transfer/delete`, payload);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const holdTransferHistory = async (payload) => {
  try {
    const response = await httpClient.post(
      `/transfer/update/status/cancel`,
      payload,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const UpdateTransferStatusToOpen = async (payload) => {
  try {
    const response = await httpClient.post(
      `/transfer/update/status/open`,
      payload,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const UpdateTransferToComplete = async (payload) => {
  try {
    const response = await httpClient.post(
      `/transfer/transferToComplete`,
      payload,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
