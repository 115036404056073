import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { OrderType } from 'pages/sales/context-provider';
import { getReturnList } from 'services/api/receipts/receipts-new.api';
import { AuthContext } from 'store/contexts/AuthContext';

const ReturnsContext = createContext(
  {} as {
    setFilteredRows: React.Dispatch<React.SetStateAction<OrderType[]>>;
    filteredRows: OrderType[];
    setRows: React.Dispatch<React.SetStateAction<OrderType[]>>;
    rows: OrderType[];
  },
);

export const ReturnsViewContext = () => useContext(ReturnsContext);

export const ReturnsContextProvider = ({ children }: any) => {
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);

  const initialRows: OrderType[] = [];
  const [rows, setRows] = useState<OrderType[]>(initialRows);
  const [filteredRows, setFilteredRows] = useState<OrderType[]>(initialRows);

  const onLoadReturnsDatatable = async () => {
    try {
      const getStoredWarehouseValue = JSON.parse(
        localStorage.getItem('storeWarehouseCustomer'),
      );

      const response = await getReturnList(
        currentUser.Claim_CustomerId,
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
        getStoredWarehouseValue ? getStoredWarehouseValue.id : null,
      );
      return response;
    } catch (err) {
      return err;
    }
  };

  const transformOrderData = (orders: OrderType[]): OrderType[] =>
    orders.map((order) => ({
      ...order,
      creationDate: order.creationDateTime
        ? moment(order.creationDateTime).format('MM/DD/YYYY')
        : undefined,
      creationTime: order.creationDateTime
        ? moment(order.creationDateTime).format('LT')
        : undefined,
      shipByDateTime: order.shipByDateTime
        ? moment(order.shipByDateTime).format('MMM DD YYYY')
        : undefined,
      shipCancelDateTime: order.shipCancelDateTime
        ? moment(order.shipCancelDateTime).format('MMM DD YYYY')
        : undefined,
      shipDateDay: order.shipDate
        ? moment(order.shipDate).format('MM/DD/YYYY')
        : undefined,
      shipDateTime: order.shipDate
        ? moment(order.shipDate).format('LT')
        : undefined,
    }));

  useEffect(() => {
    let isMounted = true;

    const loadData = async () => {
      const response = await onLoadReturnsDatatable();
      if (isMounted) {
        const transformedOrders = transformOrderData(response);
        setRows(transformedOrders);
        setFilteredRows(transformedOrders);
      }
    };

    // eslint-disable-next-line no-console
    loadData().catch(console.error);

    return () => {
      isMounted = false;
    };
  }, [currentUser.Claim_CustomerId, currentLocationAndFacility]);

  const value = useMemo(
    () => ({
      rows,
      filteredRows,
      setRows,
      setFilteredRows,
    }),
    [filteredRows, rows],
  );

  return (
    <ReturnsContext.Provider value={value}>{children}</ReturnsContext.Provider>
  );
};

export default ReturnsContextProvider;
