import { API_URL } from 'config/constants';
import { environmentVariable } from 'store/environment';
import { getValue } from 'store/localStorage/useLocalStorage';

function openPage(urlParam) {
  const baseURL = environmentVariable(API_URL);
  let url = baseURL + urlParam;
  const token = getValue('token');
  if (token) {
    url += `?authToken=${token}`;
  }

  window.open(url, '_blank', 'noreferrer');
}

export default openPage;
