/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React, {
  useMemo,
  useContext,
  createContext,
  useState,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router';

import { InvoiceTypeEnum } from 'common/enums';
import {
  InvoiceCreateUpdateModel,
  InvoiceDetailGetModel,
  InvoiceLineItemModel,
  OrderDetailForNewInvoiceGetModel,
  OrderLineItemModel,
} from 'common/models/invoices';
import Card from 'components/card';
import Footer from 'components/footer';
import Header from 'components/header';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import InvoiceDetailForm from 'pages/shared/invoicedetailform';
import {
  InvoiceDetailFormContextProvider,
  useInvoiceDetailFormContext,
} from 'pages/shared/invoicedetailform/context';
import { InvoiceDetailFormModel } from 'pages/shared/invoicedetailform/models';
import { MainContainer, ContentContainer } from 'pages/styles';
import {
  CreateUpdateInvoice,
  GetInvoiceDetail,
  GetOrderDetailForNewInvoice,
} from 'services/api/invoice/invoice.api';
import { AuthContext } from 'store/contexts/AuthContext';

type QueryStringType = {
  form: string;
  orderId: number;
  invoiceId?: number;
};

const InvoiceDataTableViewContext = createContext(
  {} as {
    setQueryStringInvoiceDetailPage: React.Dispatch<
      React.SetStateAction<QueryStringType>
    >;
    queryStringInvoiceDetailPage: QueryStringType;
    OnClickCreateUpdateOrderInvoice: (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
    ) => Promise<void>;
  },
);

export const useInvoiceDataTableViewContext = () =>
  useContext(InvoiceDataTableViewContext);

export const InvoiceDataTableViewContextProvider = ({ children }: any) => {
  const query = useQuery();
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);

  const { state, setForm, validateForm, setIsBusy, setFormIsValidToFalse } =
    useInvoiceDetailFormContext();

  const initialStateOfQueryParams: QueryStringType = {
    form: query.get('form'),
    orderId: Number(query.get('orderId')),
    invoiceId: Number(query.get('invoiceId')),
  };

  const [queryStringInvoiceDetailPage, setQueryStringInvoiceDetailPage] =
    useState(initialStateOfQueryParams);

  const OnClickCreateUpdateOrderInvoice = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): Promise<void> => {
    event.preventDefault();

    // this will trigger a state changed at state.vm.formIsValid
    // state.vm.formIsValid has useEffect where we can trigger the actual save
    validateForm();
  };

  useEffect(() => {
    if (state.vm.formIsValid) {
      setIsBusy(true);

      const PAYLOAD: InvoiceCreateUpdateModel = {
        invoiceId: state.vm.form?.invoiceId,
        warehouseCustomerId: state.vm.form?.warehouseCustomerId,
        orderId: state.vm.form?.orderId,
        invoiceType: state.vm.form?.invoiceType,
        invoiceNo: state.vm.form?.invoiceNo,
        invoiceDate: state.vm.form?.invoiceDate,
        dueDate: state.vm.form?.dueDate,
        subTotal: state.vm.form?.subtotal,
        adjustmentAmount: state.vm.form?.adjustmentAmount,
        total: state.vm.form?.total,
        comment: state.vm.form?.comment,
        lineItems: state.vm.form?.lineItems,
      };

      CreateUpdateInvoice(PAYLOAD)
        .then((x) => {
          snackActions.success(`Order invoice has been successfully saved.`);

          const responseData = x.data as InvoiceDetailGetModel;
          if (responseData.invoiceId) {
            navigate(
              `?orderId=${responseData.orderId}&invoiceId=${responseData.invoiceId}`,
            );
          }
          setQueryStringInvoiceDetailPage((prevForm) => ({
            ...prevForm,
            orderId: responseData.orderId,
            invoiceId: responseData.invoiceId,
          }));
        })
        .catch((err) => {
          snackActions.error(err);
        })
        .finally(() => {
          setFormIsValidToFalse();
          setIsBusy(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.vm.formIsValid]);

  useEffect(() => {
    if (
      queryStringInvoiceDetailPage.invoiceId > 0 &&
      queryStringInvoiceDetailPage.orderId
    ) {
      GetInvoiceDetail(queryStringInvoiceDetailPage.invoiceId)
        .then((x: InvoiceDetailGetModel) => {
          // use null-coalescing, so we don't get undefined value
          const model: InvoiceDetailFormModel = {
            invoiceId: x.invoiceId,
            warehouseCustomerId: x.warehouseCustomerId ?? null,
            orderId: x.orderId ?? null,
            invoiceType: x.invoiceType,
            invoiceNo: x.invoiceNo ?? null,
            invoiceDate: x.invoiceDate ?? null,
            dueDate: x.dueDate ?? null,
            totalQuantity: x?.totalQuantities,
            subtotal: x.subTotal ?? null,
            adjustmentAmount: x.adjustmentAmount ?? null,
            total: x.total ?? null,
            comment: x.comment ?? null,
            lineItems: x.lineItems.map((z: InvoiceLineItemModel) => ({
              invoiceDetailId: z.invoiceDetailId,
              description: z.description,
              quantity: z.quantity,
              rate: z.rate,
              amount: z.amount,
            })),
          };
          console.log(model, '11111111');

          setForm(model);
        })
        .catch((err) => {
          snackActions.error(err);
        });
    } else if (queryStringInvoiceDetailPage.orderId) {
      GetOrderDetailForNewInvoice(queryStringInvoiceDetailPage.orderId)
        .then((x: OrderDetailForNewInvoiceGetModel) => {
          let getTotal = 0;
          x.lineItems.forEach((z) => {
            getTotal += (z.qty ?? 0) * (z.rate ?? 0);
          });

          // use null-coalescing, so we don't get undefined value
          const model: InvoiceDetailFormModel = {
            invoiceId: null,
            warehouseCustomerId: x.warehouseCustomerId ?? null,
            orderId: x.orderId ?? null,
            invoiceType: InvoiceTypeEnum.Order,
            invoiceNo: x.invoiceNo,
            invoiceDate: new Date(),
            dueDate: null,
            totalQuantity: x?.totalQuantities,
            subtotal: getTotal,
            adjustmentAmount: null,
            total: getTotal,
            comment: null,
            lineItems: x.lineItems.map((z: OrderLineItemModel) => ({
              rowId: Math.random(),
              invoiceDetailId: null,
              description: z.description,
              quantity: z.qty,
              rate: z.rate,
              amount: (z.qty ?? 0) * (z.rate ?? 0),
            })),
          };
          console.log(getTotal, 'getTotal');
          console.log(model, 'modelmodelmodel');
          setForm(model);
        })
        .catch((err) => {
          snackActions.error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUser.Claim_CustomerId,
    queryStringInvoiceDetailPage.invoiceId,
    queryStringInvoiceDetailPage.orderId,
  ]);

  const value = useMemo(
    () => ({
      queryStringInvoiceDetailPage,
      setQueryStringInvoiceDetailPage,
      OnClickCreateUpdateOrderInvoice,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryStringInvoiceDetailPage],
  );

  return (
    <InvoiceDataTableViewContext.Provider value={value}>
      {children}
    </InvoiceDataTableViewContext.Provider>
  );
};

const InvoiceOrder = () => (
  <Card
    sx={{
      paddingTop: '20px !impoortant',
      marginBottom: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    }}
  >
    <InvoiceDetailForm />
  </Card>
);

function InvoiceOrderDetailsView() {
  return (
    <MainContainer>
      <InvoiceDetailFormContextProvider>
        <InvoiceDataTableViewContextProvider>
          <Header />
          <ContentContainer>
            <InvoiceOrder />
          </ContentContainer>
        </InvoiceDataTableViewContextProvider>
      </InvoiceDetailFormContextProvider>
      <Footer />
    </MainContainer>
  );
}

export default React.memo(InvoiceOrderDetailsView);
