import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { BinItemTypeEnum } from 'common/enums';
import { snackActions } from 'config/snackbar.js';
import {
  getPickTicketIssues,
  updatePickTicketLineItemIssueResolve,
} from 'services/api/picktickets/picktickets.api';
import { AuthContext } from 'store/contexts/AuthContext';

export enum PickTicketLineItemIssueEnum {
  NoStock = 1,
  LowStock = 2,
  InvalidLocation = 3,
  NoLocation = 4,
  InvalidBarcode = 5,
}

export interface IPickTicketLineItem {
  pickTicketLineItemId: number;
  pickTicketId: number;
  pickId: string;
  orderId: number;
  orderNo: string;
  issue: PickTicketLineItemIssueEnum;
  issueStr: string;
  bin?: string;
  lpn?: string;
  binItemType?: BinItemTypeEnum;
  contextMenu_Resolve_Enable: boolean;
}

interface IPickingIssue {
  pickTicketLineItems: IPickTicketLineItem[];
  setPickTicketLineItems: React.Dispatch<
    React.SetStateAction<IPickTicketLineItem[] | []>
  >;
  pickTicketLineItemsReset: IPickTicketLineItem[];
  onPickTicketLineItemIssueResolve: (
    event: React.SyntheticEvent,
    pickTicketLineItemId: number,
  ) => void;
}

type PickingIssueProviderProps = {
  children: React.ReactNode;
};

const PickingIssueContext = createContext<IPickingIssue>({} as IPickingIssue);
export const usePickingIssueContext = () => useContext(PickingIssueContext);

export const PickingIssueProvider = ({
  children,
}: PickingIssueProviderProps) => {
  const { currentLocationAndFacility } = useContext(AuthContext);

  const [pickTicketLineItems, setPickTicketLineItems] = useState<
    IPickTicketLineItem[] | []
  >([]);

  const [pickTicketLineItemsReset, setPickTicketLineItemsReset] = useState<
    IPickTicketLineItem[] | []
  >([]);

  const onLoadPickingIssue = async () => {
    try {
      const response = await getPickTicketIssues(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
      );

      return response;
    } catch (err) {
      return err;
    }
  };

  const viewLoadDataPickingIssue = async () => {
    const response = onLoadPickingIssue();
    response
      .then((opt) => {
        setPickTicketLineItems(
          opt.map((x: IPickTicketLineItem) => ({
            pickTicketLineItemId: x.pickTicketLineItemId,
            pickTicketId: x.pickTicketId,
            pickId: x.pickId,
            orderId: x.orderId,
            orderNo: x.orderNo,
            issue: x.issue,
            issueStr: x.issueStr ?? '',
            bin: x.bin ?? '',
            lpn: x.lpn ?? '',
            binItemType: x.binItemType,
            contextMenu_Resolve_Enable: x.contextMenu_Resolve_Enable,
          })),
        );

        setPickTicketLineItemsReset(
          opt.map((x: IPickTicketLineItem) => ({
            pickTicketLineItemId: x.pickTicketLineItemId,
            pickTicketId: x.pickTicketId,
            pickId: x.pickId,
            orderId: x.orderId,
            orderNo: x.orderNo,
            issue: x.issue,
            issueStr: x.issueStr ?? '',
            bin: x.bin ?? '',
            lpn: x.lpn ?? '',
            binItemType: x.binItemType,
            contextMenu_Resolve_Enable: x.contextMenu_Resolve_Enable,
          })),
        );
      })
      .catch();
  };

  const onPickTicketLineItemIssueResolve = async (
    event: React.SyntheticEvent,
    pickTicketLineItemId: number,
  ): Promise<void> => {
    event.preventDefault();

    try {
      const PAYLOAD = {
        PickTicketLineItemId: pickTicketLineItemId,
      };

      await updatePickTicketLineItemIssueResolve(PAYLOAD);
      snackActions.success('Successfully updated pict ticket line item.');

      viewLoadDataPickingIssue();
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    viewLoadDataPickingIssue();
  }, [currentLocationAndFacility]);

  const value: IPickingIssue = useMemo(
    () => ({
      pickTicketLineItems,
      pickTicketLineItemsReset,
      setPickTicketLineItems,
      setPickTicketLineItemsReset,
      onPickTicketLineItemIssueResolve,
    }),
    [pickTicketLineItems, pickTicketLineItemsReset],
  );
  return (
    <PickingIssueContext.Provider value={value}>
      {children}
    </PickingIssueContext.Provider>
  );
};
