import { ValidateOrderLineItemSerialNumberRequestModel } from 'common/models/serialnumbers/ValidateOrderLineItemSerialNumber';
import { ValidateSerialNumberBySKURequestModel } from 'common/models/serialnumbers/ValidateSerialNumberBySKU';
import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

// POST /api/serialnumber/validate/bysku
export const ValidateSerialNumberBySKU = async (
  request: ValidateSerialNumberBySKURequestModel,
) => {
  try {
    const response = await httpClient.post(
      `/SerialNumber/Validate/BySKU`,
      request,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// POST /api/serialnumber/validate/orderlineitem
export const ValidateOrderLineItemSerialNumber = async (
  request: ValidateOrderLineItemSerialNumberRequestModel,
) => {
  try {
    const response = await httpClient.post(
      `/SerialNumber/Validate/OrderLineItem`,
      request,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
