import React, { useEffect, useState, useContext } from 'react';

import { RoleEnum } from 'common/enums';
import { IUserInfo } from 'common/interfaces';
import Card from 'components/card';
import Header from 'components/header';
import { snackActions } from 'config/snackbar.js';
import UserRoles from 'helpers/userrolehelper';
import ConfirmationDialog from 'pages/shared/confirmdeletemodal';
import UserCreationModal from 'pages/shared/customerusermodal';
import {
  IChangePasswordData,
  ModalChangePassword,
} from 'pages/shared/customerusermodal/change-password';
import { MainContainer, ContentContainer } from 'pages/styles';
import {
  changeUserActiveStatus,
  UserChangePassword,
} from 'services/api/user/users.api';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridPremium } from 'styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Key } from '@mui/icons-material';
import { Typography, Box, Switch, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid-premium';

import {
  CheckboxState,
  UsersContextProvider,
  defaultItemStates,
  useUsersContext,
} from './context';
import UserPermissionsModal from './users-permission-modal';

interface IPasswordChange {
  open: boolean;
  user?: IUserInfo;
}

const UserManagementDetail = () => {
  const theme = useTheme();
  const {
    updateData,
    handleUpdateData,
    onOpenUserModal,
    onOpenConfirmDeleteDialog,
    setUserPermission,
    setUsersPermissionModal,
    setUserPermissionStates,
  } = useContext(GlobalContext);

  const {
    users,
    checked,
    filteredUsers,
    setUsers,
    setChecked,
    onLoadCustomerData,
  } = useUsersContext();

  const [openChangePassword, setOpenChangePassword] = useState<IPasswordChange>(
    {
      open: false,
    },
  );
  const [editUser, setEditUser] = useState(false);
  const [dataSelected, setDataSelected] = useState({
    userId: '',
    name: '',
    username: '',
    email: '',
    roleId: '',
    password: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onLoadCustomerData();
  };

  const handleDeactivateUser = async (rowData) => {
    try {
      if (rowData.isActive === true) {
        const form = {
          userId: rowData.userId,
          isActive: false,
        };
        await changeUserActiveStatus(form);
        handleUpdateData();
        return snackActions.success(
          `Successfully deactivated user: ${rowData.username}`,
        );
      }
      const form = {
        userId: rowData.userId,
        isActive: true,
      };
      await changeUserActiveStatus(form);
      handleUpdateData();
      return snackActions.success(
        `Successfully reactivated user: ${rowData.username}`,
      );
    } catch (error) {
      return snackActions.error(
        `Unable to deactivate user: ${rowData.username}`,
      );
    }
  };

  const handleEditUser = (rowData) => {
    setDataSelected(rowData);
    setEditUser(true);
    onOpenUserModal();
  };

  const handleResetDataSelected = () => {
    setDataSelected({
      userId: '',
      name: '',
      username: '',
      email: '',
      roleId: '',
      password: '',
    });
    setEditUser(false);
  };

  const handleDeleteUser = (rowData) => {
    setDataSelected(rowData);
    onOpenConfirmDeleteDialog();
  };

  const handleEditUserPermission = (row) => {
    setUserPermission(row);
    setUsersPermissionModal(true);

    const permissions = [...defaultItemStates];

    const newState = permissions.map((obj) => {
      if (row?.userPermissionIds.some((x) => x === obj.value)) {
        return {
          ...obj,
          isUserPermissionChecked: true,
          state: CheckboxState.CHECKED,
        };
      }
      return obj;
    });
    setUserPermissionStates(newState);
  };

  const handleChangePassword = (user: IUserInfo) => {
    setOpenChangePassword((prev) => ({
      ...prev,
      open: true,
      user,
    }));
  };

  const handleChangePasswordConfirm = async (data: IChangePasswordData) => {
    setOpenChangePassword((prev) => ({
      ...prev,
      open: false,
    }));
    const result = await UserChangePassword(data);
    if (result) {
      withReactContent(Swal).fire({
        title: `${openChangePassword.user.firstName} password is successfully changed.`,
        icon: 'info',
        confirmButtonText: 'Ok',
      });
    }
    setOpenChangePassword((prev) => ({
      ...prev,
      user: undefined,
    }));
  };

  const userColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit Permission"
          onClick={() => {
            handleEditUserPermission(params.row);
          }}
          showInMenu
          style={{
            display:
              params.row?.roleId === Number(RoleEnum.SupplierAccount)
                ? 'none'
                : 'block',
          }}
        />,
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEditUser(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => handleDeleteUser(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Deactivate/Reactivate"
          onClick={() => handleDeactivateUser(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'menu',
      type: 'actions',
      width: 34,
      renderCell: (params) => (
        <IconButton
          title="Change Password"
          onClick={() => handleChangePassword(params.row)}
          size="small"
        >
          <Key sx={{ width: '18px', height: '18px', fill: '#888888' }} />
        </IconButton>
      ),
    },
    {
      field: 'firstName',
      width: 200,
      flex: 0.5,
      headerName: 'First Name',
    },
    {
      field: 'lastName',
      width: 200,
      flex: 0.5,
      headerName: 'Last Name',
    },
    {
      field: 'username',
      width: 200,
      flex: 0.5,
      headerName: 'Username',
    },
    {
      field: 'email',
      width: 200,
      flex: 0.5,
      headerName: 'Email',
    },
    {
      field: 'roleId',
      width: 200,
      flex: 0.5,
      headerName: 'Role',
      renderCell: (params) => (
        <Typography style={{ flexDirection: 'column' }}>
          {UserRoles[params.row.roleId]}
          {params.row.roleId === 9 && `(${params.row.customerName})`}
        </Typography>
      ),
    },
    {
      field: 'isActive',
      width: 200,
      flex: 0.5,
      headerName: 'Status',
    },
  ];

  useEffect(() => {
    setUsers([]);
    onLoadCustomerData();
  }, [updateData, checked]);

  return (
    <ContentContainer>
      <ModalChangePassword
        isOpen={openChangePassword.open}
        onCancel={() =>
          setOpenChangePassword((prev) => ({
            ...prev,
            open: false,
          }))
        }
        onConfirm={handleChangePasswordConfirm}
        user={openChangePassword.user}
      />
      {editUser ? (
        <UserCreationModal
          userData={dataSelected}
          callBack={handleResetDataSelected}
          edit
          admin
        />
      ) : (
        <UserCreationModal
          userData={dataSelected}
          callBack={handleResetDataSelected}
          admin
        />
      )}
      <ConfirmationDialog
        userData={dataSelected}
        deleteUser
        callBack={onLoadCustomerData}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          marginBottom: '16px',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '192px',
          }}
        >
          <Typography sx={{ color: [theme.palette.primary.main] }} variant="h3">
            {users?.length}
          </Typography>
          <Typography variant="caption" fontWeight="bold">
            Total Users
          </Typography>
        </Card>

        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '192px',
          }}
        >
          <Typography sx={{ color: [theme.palette.primary.main] }} variant="h3">
            {users?.filter((u) => u.roleId === 2).length}
          </Typography>
          <Typography variant="caption" fontWeight="bold">
            Admins
          </Typography>
        </Card>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '192px',
          }}
        >
          <Typography sx={{ color: [theme.palette.primary.main] }} variant="h3">
            {users?.filter((u) => u.roleId === 3).length}
          </Typography>
          <Typography variant="caption" fontWeight="bold">
            Ops Manager
          </Typography>
        </Card>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '192px',
          }}
        >
          <Typography sx={{ color: [theme.palette.primary.main] }} variant="h3">
            {users?.filter((u) => u.roleId === 4).length}
          </Typography>
          <Typography variant="caption" fontWeight="bold">
            Operator
          </Typography>
        </Card>
      </Box>
      <Card sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <div>
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          Show/Hide Client Accounts
        </div>
        <DatagridPremium
          autoHeight
          rows={filteredUsers}
          density="compact"
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          hideFooterSelectedRowCount
          columns={userColumns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          getRowId={(row) => row.userId}
        />
      </Card>
    </ContentContainer>
  );
};

function UserManagement() {
  return (
    <MainContainer>
      <Header />
      <UsersContextProvider>
        <UserPermissionsModal />
        <UserManagementDetail />
      </UsersContextProvider>
    </MainContainer>
  );
}

export default React.memo(UserManagement);
