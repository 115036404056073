import styled from 'styled-components';

import { Box, Paper } from '@mui/material';

export const AutoCompleteTablePaper = styled(Paper)`
  width: 100%;
  border: 1px solid #cbcbcb;
  & ul {
    padding: 0 !important;
    color: #333333;
    & li {
      display: flex !important;
      gap: 15px !important;
      padding: 0 !important;
      font-size: 14px !important;
      border-bottom: 1px solid #e1dfdf;
      &:nth-of-type(even) {
        background-color: #f4f5f5 !important;
      }
      &:nth-of-type(odd) {
        background-color: #ffffff !important;
      }
    }
    & .td {
      padding: 5px 10px;
    }
    & .tw-140 {
      width: 140px;
      border-right: 1px solid #e1dfdf;
    }
  }
`;

export const AutoCompleteTableHeader = styled(Box)`
  display: flex;
  column-gap: 15px;
  background: #ffffff;
  color: #333333;
  border-bottom: 1px solid #e1dfdf;
  & .th {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
  }
  & .tw-140 {
    width: 140px;
    border-right: 1px solid #e1dfdf;
    text-wrap: break-word;
  }
`;
