import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonMapOption = styled(Button)(() => ({
  padding: '0 7px',
  minWidth: '50px',
  height: '40px',
  fontSize: '12px',
  borderRadius: 0,
}));
