export const itemsInformation = (items) => {
  const listItem = items.map(
    (item) => `<li>
       <div class="sku"><span>SKU :</span><strong>${item.sku}</strong></div>
       <div class="description"><span>Description :</span><strong>${item.description}</strong></div>
       <div class="lot-no"><span>Lot No :</span><strong>${item.lotNo}</strong></div>
       <div class="qty"><span>Qty On Hand :</span><strong>${item.qty}</strong></div>
     </li>`,
  );
  return `<ul>${listItem.join('')}</ul>`;
};
