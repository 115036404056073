import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Grid } from 'components/styles';
import { getDashboardLowStock } from 'services/api/dashboard/dashboard.api';
import { AuthContext } from 'store/contexts/AuthContext';

import { Box, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridColumns } from '@mui/x-data-grid';

function DashboardBackorder() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentLocationAndFacility } = useContext(AuthContext);
  const [lowStockData, setLowStockData] = useState([]);

  const fecthStockData = async () => {
    try {
      const lowStockFromApi = await getDashboardLowStock(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
      );
      setLowStockData(lowStockFromApi);
      return true;
    } catch (err: any) {
      return err;
    }
  };

  type LowStockRow = typeof lowStockData[number];

  const lowStockColumns: GridColumns<LowStockRow> = [
    {
      field: 'sku',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>SKU</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`/inventory/item-details?form=view&id=${params.row.itemId}`}
          target="_blank"
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.sku} 2
          </Typography>
        </Link>
      ),
    },
    {
      field: 'description',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Description</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.description}</Typography>,
    },
    {
      field: 'expect',
      minWidth: 85,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Expect.</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.expect}</Typography>,
    },
    {
      field: 'onHand',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '8px' }}>
          <Typography>On Hand</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.onHand}</Typography>,
    },
    {
      field: 'sold',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '8px' }}>
          <Typography>Sold</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.sold}</Typography>,
    },
    {
      field: 'backorder',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '8px' }}>
          <Typography>Back Order</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.backorder}</Typography>,
    },
  ];

  useEffect(() => {
    fecthStockData();
  }, [currentLocationAndFacility]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '450px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontSize: '20px' }} variant="h5" fontWeight="bold">
          Backorder
        </Typography>
        <Typography
          sx={{
            cursor: 'pointer',
            color: [theme.palette.primary.main],
            fontWeight: 'bold',
          }}
          onClick={() => {
            navigate('/inventory');
          }}
        >
          Inventory
        </Typography>
      </Box>
      <Grid
        headerHeight={120}
        sx={{
          '& .MuiDataGrid-cell': {
            borderBottomColor: '#F0F0F0',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: 'none',
          },
          '& .MuiTypography-body1': {
            fontWeight: 'bold',
          },
        }}
        rows={lowStockData}
        columns={lowStockColumns}
        pageSize={10}
        density="compact"
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        rowsPerPageOptions={[10]}
        getRowId={(row) => row.itemId}
        componentsProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  );
}

export default React.memo(DashboardBackorder);
