import React, { useContext } from 'react';

import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Divider,
  Button,
  Grid as MUIGrid,
} from '@mui/material';
import { useTheme, styled as MuiStyled } from '@mui/material/styles';

import { usePackAndShipContext } from '../context';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 500px;
    border-radius: 4px;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 24px;
    gap: 16px;
  }
`;

export const OrderReadyForPickupModal = () => {
  const theme = useTheme();

  const { isOrderReadyForPickupModal, setIsOrderReadyForPickupModal } =
    useContext(GlobalContext);

  const { readyForPickupOnClick } = usePackAndShipContext();

  return (
    <Modal open={isOrderReadyForPickupModal}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Order Ready for Pickup
          </Typography>
          <Box autoComplete="off" component="form">
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={12}>
                <Typography variant="subtitle1">
                  Are you sure you want to change this order status?
                </Typography>
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                  justifyContent: 'flex-end',
                },
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'center',
                },
                gap: '8px',
              }}
            >
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="outlined"
                size="large"
                onClick={() => setIsOrderReadyForPickupModal(false)}
              >
                Cancel
              </Button>
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="contained"
                size="large"
                onClick={(e) => {
                  readyForPickupOnClick(e);
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
};
