import React, { useState, useEffect } from 'react';

import Button from 'components/button';
import { useSalesOrderContext } from 'pages/sales/salesorder/context';

import {
  Modal,
  Box,
  Typography,
  Divider,
  Grid as MUIGrid,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 500px;
    border-radius: 4px;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 24px;
    gap: 16px;
  }
`;

export default React.memo(() => {
  const { apiBackOrderResponse, setApiBackOrderResponse } =
    useSalesOrderContext();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');
  const triggerCallback = (cb) => cb();
  useEffect(() => {
    if (apiBackOrderResponse !== null) {
      const { data } = apiBackOrderResponse;
      setContent(data.message === undefined ? data : data.message);
      setOpen(true);
    }
  }, [apiBackOrderResponse]);

  return (
    <Modal open={isOpen}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Backorder Warning
          </Typography>
          <Box autoComplete="off" component="form">
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={12}>
                <Typography variant="subtitle1">{content}</Typography>
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={10}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => setOpen(false)}
                    variant="outlined"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    onClick={() => {
                      triggerCallback(apiBackOrderResponse.click);
                      setOpen(false);
                    }}
                    variant="contained"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CONFIRM AND ADD
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
