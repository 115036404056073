import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IChartDataSet } from 'common/interfaces';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export interface IBarChart {
  title?: string;
  labels: string[];
  datasets: IChartDataSet[];
}

export const BarChart = React.memo((props: IBarChart) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: props.title === undefined,
        text: props.title ?? '',
      },
    },
  };
  const data = {
    labels: props.labels,
    datasets: props.datasets,
  };
  return <Bar options={options} data={data} />;
});
