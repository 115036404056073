/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from 'react';

import Button from 'components/button';
import Input from 'components/input';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { getLicensePlatesForBatchPrint } from 'services/api/licensePlates/licensePlates.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  TextField,
  Grid as MUIGrid,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface BatchPrintLicensePlateModalProps {
  callBack?: (data: any[]) => void;
}

export default React.memo(({ callBack }: BatchPrintLicensePlateModalProps) => {
  const theme = useTheme();

  const { isBatchPrintLPNModalOpen, onCloseBatchPrintLPNModal } =
    useContext(GlobalContext);
  const initialForm = {
    from: null,
    to: null,
  };
  const from = 0;

  const [formValues, setFormValues] = useState<any>(initialForm);

  const handleModalClose = () => {
    onCloseBatchPrintLPNModal();
    callBack([]);
    setFormValues(initialForm);
  };

  const handleConfirmClick = async () => {
    try {
      if (formValues.from > formValues.to) {
        snackActions.error('From value cant be greater than To.');
        return;
      }

      if (!formValues.from) {
        snackActions.error('From value is required.');
        return;
      }

      if (!formValues.to) {
        snackActions.error('To value is required.');
        return;
      }

      const response = await getLicensePlatesForBatchPrint(
        formValues.from,
        formValues.to,
      );

      setFormValues(initialForm);
      callBack(response);
      onCloseBatchPrintLPNModal();
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };

  useEffect(() => {
    if (isBatchPrintLPNModalOpen) {
      setFormValues(initialForm);
      callBack([]);
    }
  }, [isBatchPrintLPNModalOpen]);
  if (!isBatchPrintLPNModalOpen) return null;

  return (
    <Modal open={isBatchPrintLPNModalOpen} onClose={handleModalClose}>
      <ModalBox>
        <ModalContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            License Plates Range
          </Typography>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <Input
                  sx={{ width: '100%', background: 'white' }}
                  placeholder="From"
                  type="number"
                  min={0}
                  size="small"
                  value={formValues.from}
                  onChange={(e) => {
                    setFormValues((prevState) => ({
                      ...prevState,
                      from: e.target.value,
                    }));
                  }}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Input
                  placeholder="To"
                  sx={{ width: '100%', background: 'white' }}
                  size="small"
                  value={formValues.to}
                  type="number"
                  min={0}
                  onChange={(e) => {
                    setFormValues((prevState) => ({
                      ...prevState,
                      to: e.target.value,
                    }));
                  }}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end',
              },
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
              },
              gap: '8px',
            }}
          >
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="text"
              size="large"
              onClick={() => handleModalClose()}
            >
              Cancel
            </Button>
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="contained"
              size="large"
              onClick={() => handleConfirmClick()}
            >
              Confirm
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
