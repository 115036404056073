import { memo, useState } from 'react';

import moment from 'moment';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { DataGridProStyle } from 'styles/mui/datagrid/premiumpro';

import { Box, Modal, Typography, Grid as MUIGrid, Button } from '@mui/material';
import {
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';

import { useLotsContext } from '../context';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default memo(() => {
  const {
    showLotIdHistoryData,
    isShowLotIdHistoryModal,
    setShowLotIdHistoryData,
    setIsShowLotIdHistoryModal,
  } = useLotsContext();

  const lotActivityColumns: GridColDef[] = [
    {
      field: 'lotActivityTypeStr',
      minWidth: 140,
      flex: 1,
      headerName: 'Activity Type',
    },
    {
      field: 'userName',
      minWidth: 150,
      flex: 1,
      headerName: 'User',
    },
    {
      field: 'activityDateTimeStr',
      minWidth: 90,
      flex: 0.5,
      headerName: 'Activity Date',
      renderCell: (params) => (
        <Typography>
          {params.row?.activityDateTime &&
            moment(params.row?.activityDateTime).format('MM/DD/yyyy')}
        </Typography>
      ),
    },
    {
      field: 'quantity',
      minWidth: 90,
      flex: 0.5,
      headerName: 'Changed Qty',
    },
    {
      field: 'reason',
      minWidth: 150,
      flex: 1,
      headerName: 'Reason',
    },
    {
      field: 'lotNo',
      minWidth: 150,
      flex: 1,
      headerName: 'Lot No',
    },
    {
      field: 'lpNo',
      minWidth: 150,
      flex: 1,
      headerName: 'Lp No',
    },
  ];

  return (
    <Modal open={isShowLotIdHistoryModal}>
      <MUIContainer sx={{ width: '80% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Lot Activity Logs
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Box
            sx={{
              width: '100%',
              textAlignLast: 'center',
            }}
          >
            {showLotIdHistoryData === null ||
            showLotIdHistoryData?.length <= 0 ? (
              <div> No Data</div>
            ) : (
              <DataGridProStyle
                autoHeight
                density="compact"
                rows={showLotIdHistoryData}
                disableColumnSelector
                disableColumnMenu
                hideFooterSelectedRowCount
                columns={lotActivityColumns}
                pageSizeOptions={[25]}
                autoPageSize
                getRowId={() => Math.random()}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            )}
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={2}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => {
                  setIsShowLotIdHistoryModal(false);
                  setShowLotIdHistoryData(null);
                }}
                variant="contained"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                Close
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
