import React, { useContext, useEffect, useRef, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import moment from 'moment';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { useInvoiceDetailFormContext } from 'pages/shared/invoicedetailform/context';
import { sendInvoiceEmail } from 'services/api/warehousecustomer/warehouseCustomer.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  Grid as MUIGrid,
  Divider,
} from '@mui/material';

import { useInvoiceInterfaceTabContext } from '../tabs/invoices/context';

export default React.memo(() => {
  const { setIsInvoiceSendEmail, isInvoiceSendEmail } =
    useContext(GlobalContext);

  const { state } = useInvoiceDetailFormContext();
  const { viewLoadData } = useInvoiceInterfaceTabContext();

  const ref = useRef(null);

  const initialFormEmailValue = {
    emailAddress: '',
    cc: '',
    subject: '',
    body: '',
    fileStringHTML: '',
    fileName: '',
  };

  const [emailValue, setEmailValue] = useState(initialFormEmailValue);
  const [formErrors, setFormErrors] = useState<any>([initialFormEmailValue]);
  const [isDisableSaveButton, setIsDisableSaveButton] = useState(false);

  const validateOnlick = (fieldValues = emailValue) => {
    const temp: any = {};

    if ('emailAddress' in fieldValues) {
      if (
        fieldValues.emailAddress !== '' ||
        fieldValues.emailAddress !== null ||
        fieldValues.emailAddress !== undefined
      ) {
        temp.emailAddress = /$^|.+@.+..+/.test(fieldValues.emailAddress)
          ? ''
          : 'Email is not valid';
      } else {
        temp.emailAddress = fieldValues.emailAddress
          ? ''
          : 'This field is required';
      }
    }

    if ('subject' in fieldValues) {
      temp.subject = fieldValues.subject ? '' : 'This field is required';
    }

    if ('subject' in fieldValues) {
      temp.body = fieldValues.body ? '' : 'This field is required';
    }

    setFormErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleOnCreateSendInvoiceEmail = async (e: any) => {
    e.preventDefault();

    if (!validateOnlick()) {
      snackActions.error('Please complete required fields');
      return;
    }

    try {
      setIsDisableSaveButton(true);
      await sendInvoiceEmail(emailValue).then(() => {
        snackActions.success(
          `The invoice has been successfully sent to "${emailValue.emailAddress}"`,
        );
        setIsInvoiceSendEmail(false);
        setEmailValue(initialFormEmailValue);
        viewLoadData();
      });
    } catch (err) {
      snackActions.error(err);
    }
    setIsDisableSaveButton(false);
  };

  useEffect(() => {
    if (isInvoiceSendEmail && ref.current) {
      const template = ref.current.innerHTML;

      setEmailValue((prev) => ({
        ...prev,
        fileName: state.vm.form?.invoiceNo,
        fileStringHTML: encodeURIComponent(template),
        invoiceId: state.vm.form.invoiceId,
      }));
    }
  }, [isInvoiceSendEmail]);

  return (
    <>
      {isInvoiceSendEmail && (
        <div
          ref={ref}
          id="invoiceDocument"
          className="printDialog"
          style={{
            margin: '0px',
            padding: '0px',
            width: '100%',
            display: 'none',
          }}
        >
          <table width="100%" style={{ padding: '15px' }}>
            <tr>
              <td width="50%">
                <p
                  style={{
                    fontSize: '40px',
                    color: 'rgb(8 20 165)',
                  }}
                >
                  <b>Invoice</b>
                </p>
              </td>
              <td align="right">
                <table width="70%">
                  <tr>
                    <td>
                      <Typography style={{ color: 'GrayText' }}>
                        Invoice no.:
                      </Typography>
                    </td>
                    <td align="right">
                      <p>{state.vm.form?.invoiceNo} </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography style={{ color: 'GrayText' }}>
                        Invoice date:
                      </Typography>
                    </td>
                    <td align="right">
                      <p>
                        {state.vm.form?.invoiceDate
                          ? moment(state.vm.form?.invoiceDate).format(
                              'MM/DD/YYYY',
                            )
                          : ''}{' '}
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <br />
                <table width="100%">
                  <tr>
                    <td>
                      <Typography
                        style={{ fontSize: '20px', fontWeight: 'bold' }}
                      >
                        {state.vm.form?.companyName}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>
                        {state.vm.form?.address1} {state.vm.form?.address2}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>
                        {state.vm.form?.city} {state.vm.form?.state}{' '}
                        {state.vm.form?.zipCode}
                      </Typography>
                    </td>
                  </tr>
                </table>
              </td>
              <td align="right">
                <br />
                <table width="70%">
                  <tr>
                    <td>
                      <Typography
                        style={{ fontSize: '18px', fontWeight: 'bold' }}
                      >
                        BILL TO
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>
                        {state.vm.form?.billTo?.companyName}{' '}
                        {state.vm.form?.billTo?.address1}
                        {state.vm.form?.billTo?.address2}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>
                        {state.vm.form?.billTo?.city}
                        {state.vm.form?.billTo?.state}
                        {state.vm.form?.billTo?.zipCode}
                      </Typography>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td colSpan={2}>
                <br />
                <br />
                <table width="100%" cellSpacing="0">
                  <thead style={{ fontWeight: 'bold' }}>
                    <tr>
                      <td width="40%">Description</td>
                      <td width="20%">Quantity</td>
                      <td width="20%">Rate</td>
                      <td align="right">Total</td>
                    </tr>
                  </thead>
                </table>
                <Divider sx={{ mt: 1, mb: 1, borderWidth: '1.8px' }} />
                {state.vm.form?.lineItems !== undefined &&
                  state.vm.form?.lineItems.length > 0 && (
                    <div>
                      {state.vm.form?.lineItems.map((row, index) => (
                        <>
                          <table width="100%" cellSpacing="0">
                            <tbody>
                              <tr>
                                <td width="40%">{row?.description}</td>
                                <td width="20%">{row?.quantity}</td>
                                <td width="20%">
                                  ${row.rate ? row.rate.toFixed(2) : `0.00`}
                                </td>
                                <td align="right">
                                  $
                                  {row?.amount ? row.amount.toFixed(2) : `0.00`}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <Divider sx={{ mt: 1, mb: 1 }} />
                        </>
                      ))}
                    </div>
                  )}
              </td>
            </tr>
            <tr>
              <td width="50%" />
              <td align="right" valign="top">
                <br />
                <table width="100%">
                  <tr>
                    <td>
                      <b>Subtotal:</b>
                    </td>
                    <td align="right">{state.vm.form?.subtotal}</td>
                  </tr>
                </table>

                <table width="100%">
                  <tr>
                    <td>
                      <b>Adjustment Amount:</b>
                    </td>
                    <td align="right">{state.vm.form?.adjustmentAmount}</td>
                  </tr>
                </table>

                <Divider sx={{ mt: 1, mb: 1, borderWidth: '1.8px' }} />
                <table width="100%">
                  <tr>
                    <td>
                      <b>Total:</b>
                    </td>
                    <td align="right">{state.vm.form?.total}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <br />
                <table width="70%">
                  <tr>
                    <td>
                      <b>Other Comments:</b>
                    </td>
                  </tr>
                  <tr>
                    <td>{state.vm.form?.comment}</td>
                  </tr>
                </table>
                <br />
                <table>
                  <tr>
                    <td>
                      <b style={{ color: '#0052CC' }}>
                        Thank You For Your Business!
                      </b>
                    </td>
                  </tr>
                </table>
              </td>
              <td />
            </tr>
          </table>
        </div>
      )}

      <Modal open={isInvoiceSendEmail}>
        <MUIContainer sx={{ maxWidth: '600px !important' }}>
          <Typography
            className="modalTextHeader"
            variant="h6"
            fontWeight="bold"
          >
            Email Invoice
          </Typography>

          <MUIContent>
            <Box autoComplete="off" component="form" noValidate>
              <MUIGrid
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={12}>
                  <TextField
                    inputProps={{
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    label="To"
                    value={emailValue.emailAddress}
                    error={formErrors.emailAddress}
                    helperText={formErrors.emailAddress}
                    onChange={(e) => {
                      setEmailValue((prev) => ({
                        ...prev,
                        emailAddress: e.target.value,
                      }));
                    }}
                    sx={{ width: '100%' }}
                    size="small"
                  />
                </MUIGrid>
                <MUIGrid item xs={12}>
                  <TextField
                    rows={2}
                    multiline
                    id="outlined-multiline-flexible"
                    label="Cc"
                    value={emailValue.cc}
                    onChange={(e) => {
                      setEmailValue((prev) => ({
                        ...prev,
                        cc: e.target.value,
                      }));
                    }}
                    size="small"
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
                <MUIGrid item xs={12}>
                  <TextField
                    inputProps={{
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    label="Subject"
                    value={emailValue.subject}
                    error={formErrors.subject}
                    helperText={formErrors.subject}
                    onChange={(e) => {
                      setEmailValue((prev) => ({
                        ...prev,
                        subject: e.target.value,
                      }));
                    }}
                    sx={{ width: '100%' }}
                    size="small"
                  />
                </MUIGrid>
                <MUIGrid item xs={12}>
                  <TextField
                    rows={4}
                    multiline
                    id="outlined-multiline-flexible"
                    label="Body"
                    value={emailValue.body}
                    error={formErrors.body}
                    helperText={formErrors.body}
                    onChange={(e) => {
                      setEmailValue((prev) => ({
                        ...prev,
                        body: e.target.value,
                      }));
                    }}
                    size="small"
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
              </MUIGrid>
            </Box>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={6}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={() => {
                    setIsInvoiceSendEmail(false);
                    setEmailValue(initialFormEmailValue);
                  }}
                  variant="outlined"
                  size="large"
                  sx={{ padding: 1, width: '100%' }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => {
                    handleOnCreateSendInvoiceEmail(e);
                  }}
                  disabled={isDisableSaveButton}
                >
                  Send
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
    </>
  );
});
