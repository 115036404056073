/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';

import Button from 'components/button';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Typography, Grid as MUIGrid } from '@mui/material';

export default React.memo(() => {
  const {
    isPackingListNotesModalOpen,
    setIsPackingListNotesModalOpen,
    globalMessageModal,
    setGlobalMessageModal,
  } = useContext(GlobalContext);

  return (
    <Modal open={isPackingListNotesModalOpen}>
      <MUIContainer sx={{ maxWidth: '500px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Notes
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{ marginBottom: '16px' }}
              variant="h6"
              fontWeight="bold"
            >
              {globalMessageModal}
            </Typography>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="center"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={5}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                  textTransform: 'capitalize !important',
                }}
                onClick={() => {
                  setIsPackingListNotesModalOpen(false);
                  setGlobalMessageModal(null);
                }}
              >
                OK
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
