import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const createCycleCount = async (form) => {
  try {
    const response = await httpClient.post(`/cyclecount/create`, form);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const editCycleCount = async (form) => {
  try {
    const response = await httpClient.post(`/cyclecount/edit`, form);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// get items by customer
export const getItemsByCustomerId = async (customerId) => {
  try {
    const response = await httpClient.get(
      `/Item/Lookup/Detail/Basic/${customerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// get Main Cycle count
export const getCycleCountList = async (
  customerLocationId,
  customerFacilityId,
) => {
  try {
    const response = await httpClient.get(
      `/cyclecount/getlist/${customerLocationId}/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// get Users by Facility List
export const getUsersByFacilityList = async (
  customerLocationId,
  customerFacilityId,
) => {
  try {
    const response = await httpClient.get(
      `/cyclecount/getUsersbyfacilitylist/${customerLocationId}/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// get cycle count details
export const getCycleCountDetail = async (cycleCountId) => {
  try {
    const response = await httpClient.get(
      `/cyclecount/getcyclecountdetail/${cycleCountId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// delete cycle count
export const deleteCycleCountFromModal = async (form) => {
  try {
    const response = await httpClient.post(`/cyclecount/delete`, form);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// update counted cycle count detail
export const UpdateCycleCountItemCounted = async (form) => {
  try {
    const response = await httpClient.post(
      `/cyclecount/updatecyclecountitemcounted`,
      form,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// End cycle count
export const UpdateEndCycleCount = async (form) => {
  try {
    const response = await httpClient.post(
      `/cyclecount/UpdateEndCycleCount`,
      form,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
