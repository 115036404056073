/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import Footer from 'components/footer';
import { getCustomerById } from 'services/api/customer/customer.api';
import { getPutAwayPickingAndTransferCountNotComplete } from 'services/api/putaway/putaway.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { ClassNames } from '@emotion/react';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import InboxIcon from '@mui/icons-material/Inbox';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined';
import MoveToInboxOutlinedIcon from '@mui/icons-material/MoveToInboxOutlined';
import PanoramaHorizontalIcon from '@mui/icons-material/PanoramaHorizontal';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import QueueIcon from '@mui/icons-material/Queue';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsIcon from '@mui/icons-material/Settings';
import TapAndPlayOutlinedIcon from '@mui/icons-material/TapAndPlayOutlined';
import ViewInArSharpIcon from '@mui/icons-material/ViewInArSharp';
import WidgetsIcon from '@mui/icons-material/Widgets';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  Link,
  Divider,
  Typography,
  DrawerProps as MuiDrawerProps,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Grid as MUIGrid,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';

import { NavBarLogo, NavBarLogoOnly } from '../styles';

interface IAppBarFABProps {
  theme?: any;
  open?: any;
}

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 20px) !important`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const NavbarFABContainer = styled('div')<IAppBarFABProps>(
  ({ theme, open }) => ({
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    position: 'absolute',
    top: 0,
    left: 20,
    [theme.breakpoints.up('sm')]: {
      minHeight: { ...openedMixin(theme) },
      position: 'fixed',
    },
    ...(open && {
      minHeight: 0,
      position: 'absolute',
      ...openedMixin(theme),
    }),
    ...(!open && {
      position: 'absolute',
      ...closedMixin(theme),
    }),
  }),
);

export const NavbarFABButton = styled(Button)<IAppBarFABProps>(({ open }) => ({
  ...(open && {
    background: '#ffffff',
  }),
  ...(!open && {
    background: '#3375E0',
  }),
  borderRadius: 50,
  minWidth: 25,
  minHeight: 25,
  padding: 0,
  border: '2px solid #1C9DCC',
  '&:hover': {
    ...(open && {
      background: '#ffffff',
    }),
    ...(!open && {
      background: '#3375E0',
    }),
    border: '2px solid #1C9DCC',
  },
}));

const NavBar = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerProps>(({ theme, open, enter }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  scrollbarColor: '#6B6B6B #2B2B2B',
  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    width: 0,
  },
  ...(!!enter && {
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      width: 8,
    },
  }),
  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    backgroundColor: '#4A4A4A',
    borderRadius: 0,
    minHeight: 10,
  },
  '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#C0C0C0',
  },
  '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
    backgroundColor: '#4A4A4A',
  },
}));

interface DrawerProps extends MuiDrawerProps {
  enter?: number;
}

interface INotificationsNotComplete {
  putaway?: number;
  picking?: number;
  transfer?: number;
}

export const getInventoryColumns = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { handleUserPermissionAllow } = useContext(AuthContext);

  const latestRoutes = [
    {
      priority: 0,
      multiple: false,
      hide: !handleUserPermissionAllow(UserPermissionEnum.Dashboard),
      navigations: [
        {
          link: `/dashboard`,
          label: `Dashboard`,
          icon: (v) => <HomeIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(UserPermissionEnum.Dashboard_View),
        },
      ],
    },
    {
      priority: 1,
      multiple: true,
      groupName: 'Orders',
      icon: (v) => <ReceiptLongIcon sx={{ color: v }} />,
      hide:
        !handleUserPermissionAllow(UserPermissionEnum.Orders) &&
        !handleUserPermissionAllow(UserPermissionEnum.Returns) &&
        !handleUserPermissionAllow(UserPermissionEnum.Kitting),
      navigations: [
        {
          link: `/orders`,
          label: `List`,
          icon: (v) => <ListAltIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(UserPermissionEnum.Orders_View),
        },
        {
          link: `/returns`,
          label: `Returns`,
          icon: (v) => <ReceiptLongIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(UserPermissionEnum.Returns_View),
        },
        {
          link: `/production`,
          label: `Kitting/Assembly`,
          icon: (v) => <WorkspacesOutlinedIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(UserPermissionEnum.Kitting_View),
        },
      ],
    },
    {
      priority: 2,
      multiple: true,
      groupName: 'Inventory',
      icon: (v) => <ViewInArSharpIcon sx={{ color: v }} />,
      hide:
        !handleUserPermissionAllow(UserPermissionEnum.Inventory) &&
        !handleUserPermissionAllow(UserPermissionEnum.Locations) &&
        !handleUserPermissionAllow(UserPermissionEnum.Suppliers) &&
        !handleUserPermissionAllow(UserPermissionEnum.CycleCount),
      navigations: [
        {
          link: `/inventory`,
          label: `List`,
          icon: (v) => <ListAltIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(UserPermissionEnum.Inventory_View),
        },
        {
          link: `/locations`,
          label: `Storage Locations`,
          icon: (v) => <InboxIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(UserPermissionEnum.Locations_View),
        },
        {
          link: `/suppliers`,
          label: `Suppliers`,
          icon: (v) => <PeopleIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(UserPermissionEnum.Suppliers_View),
        },
        {
          link: `/cycle-count`,
          label: `Cycle Counts`,
          icon: (v) => <HourglassEmptyIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(UserPermissionEnum.CycleCount_View),
        },
        {
          link: `/lots`,
          label: `Item Activity Log`,
          icon: (v) => <WidgetsIcon sx={{ color: v }} />,
        },
      ],
    },
    {
      priority: 3,
      multiple: true,
      groupName: 'Inbound',
      icon: (v) => <ReceiptLongIcon sx={{ color: v }} />,
      navigations: [
        {
          link: `/receiving`,
          label: `POs`,
          icon: (v) => <DownloadIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(
            UserPermissionEnum.PurchaseOrder_View,
          ),
        },
        {
          link: `/receiving/receipts`,
          label: `Receipts`,
          icon: (v) => <ReceiptIcon sx={{ color: v }} />,
        },
        {
          link: `/license-plates`,
          label: `Pallets`,
          icon: (v) => <PanoramaHorizontalIcon sx={{ color: v }} />,
        },
      ],
    },
    {
      priority: 4,
      multiple: true,
      groupName: 'Work Queues',
      icon: (v) => <QueueIcon sx={{ color: v }} />,
      navigations: [
        {
          link: `/put-away`,
          label: `Put Away`,
          icon: (v) => <MoveToInboxOutlinedIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(
            UserPermissionEnum.Receiving_Putaway,
          ),
        },
        {
          link: `/picking`,
          label: `Picking`,
          icon: (v) => <MoveDownOutlinedIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(UserPermissionEnum.Picking_View),
        },
        {
          link: `/locations/transfer-queue`,
          label: `Replenishment`,
          icon: (v) => <ImportExportOutlinedIcon sx={{ color: v }} />,
        },
        {
          link: `/pack-and-ship/find-pick-ticket`,
          label: `Pack & Ship`,
          icon: (v) => <Inventory2OutlinedIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(UserPermissionEnum.PackAndShip_View),
        },
      ],
    },
    {
      priority: 5,
      multiple: false,
      hide: !handleUserPermissionAllow(UserPermissionEnum.Client),
      navigations: [
        {
          link: `/customers`,
          label: `Clients`,
          icon: (v) => <AddBusinessOutlinedIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(UserPermissionEnum.Client_View),
        },
      ],
    },
    {
      priority: 6,
      multiple: false,
      hide: !handleUserPermissionAllow(UserPermissionEnum.Reports),
      navigations: [
        {
          link: `/reports`,
          label: `Reports`,
          icon: (v) => <DriveFileMoveOutlinedIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(UserPermissionEnum.Reports_View),
        },
      ],
    },
    {
      priority: 7,
      multiple: true,
      groupName: 'Administrative',
      hide: !handleUserPermissionAllow(UserPermissionEnum.Administrative),
      icon: (v) => <PersonIcon sx={{ color: v }} />,
      navigations: [
        {
          link: `/settings`,
          label: `Settings`,
          icon: (v) => <SettingsIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(
            UserPermissionEnum.Administrative_Settings,
          ),
        },
        {
          link: `/users`,
          label: `Users`,
          icon: (v) => <ManageAccountsOutlinedIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(
            UserPermissionEnum.Administrative_UserManagement,
          ),
        },
        {
          link: `/devices`,
          label: `Devices`,
          icon: (v) => <TapAndPlayOutlinedIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(
            UserPermissionEnum.Administrative_Devices,
          ),
        },
        {
          link: `/facilities`,
          label: `Facilities`,
          icon: (v) => <CorporateFareOutlinedIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(
            UserPermissionEnum.Administrative_Facilities,
          ),
        },
        {
          link: `/printoutforms`,
          label: `Forms`,
          icon: (v) => <DescriptionOutlinedIcon sx={{ color: v }} />,
          hide: !handleUserPermissionAllow(
            UserPermissionEnum.Administrative_Forms,
          ),
        },
      ],
    },
    {
      priority: 8,
      multiple: false,
      navigations: [
        {
          link: `/login`,
          label: `Log out`,
          icon: (v) => <PowerSettingsNewOutlinedIcon sx={{ color: v }} />,
        },
      ],
    },
  ];

  return latestRoutes;
};
function NavbarV2() {
  const [notification, setNotification] =
    React.useState<INotificationsNotComplete>();
  const [open, setOpen] = React.useState(false);
  const [locked, setLocked] = React.useState(false);
  const [seletedMenuItem, setSeletedMenuItem] = React.useState('');
  const { setIsLockedNavbar } = useContext(GlobalContext);

  const navigate = useNavigate();
  const {
    logout,
    isAdmin,
    isOperator,
    currentUser,
    isSuperAdmin,
    isSupplierAccount,
    isInventoryViewer,
    roleSupplierPermission,
    handleUserPermissionAllow,
    isWarehouseCustomerAccount,
    currentLocationAndFacility,
  } = useContext(AuthContext);

  const [enter, setEnter] = useState(false);

  const setNotificationsNotComplete = async () => {
    const customerPutAwayQueueFromApi =
      await getPutAwayPickingAndTransferCountNotComplete(
        currentLocationAndFacility?.customerLocationId ?? 0,
        currentLocationAndFacility?.customerFacilityId ?? 0,
      );
    setNotification((prevState) => ({
      ...prevState,
      transfer: customerPutAwayQueueFromApi.transfer,
      picking: customerPutAwayQueueFromApi.picking,
      putaway: customerPutAwayQueueFromApi.putaway,
    }));
  };

  const handleDrawerOpen = () => {
    setEnter(true);
  };

  const handleDrawerClose = () => {
    setEnter(false);
  };

  const onLogout = async () => {
    logout();
    navigate('/login');
  };

  const [companyLogo64, setCompanyLogo64] = useState(null);
  const [favicon64, setFavicon] = useState(null);
  const [companyName, setCompanyName] = useState('');

  const setCurrentUserCustomer = async () => {
    try {
      const customerFromAPI = await getCustomerById(
        currentUser.Claim_CustomerId,
      );
      setCompanyName(customerFromAPI.name);
      setCompanyLogo64(customerFromAPI.warehouseLogo);
      setFavicon(customerFromAPI.favicon);
      return true;
    } catch (err: any) {
      return err;
    }
  };

  useEffect(() => {
    setNotificationsNotComplete();
  }, [currentLocationAndFacility]);

  useEffect(() => {
    if (
      window.localStorage.getItem('isOpen') !== undefined &&
      window.localStorage.getItem('isOpen') === 'open'
    ) {
      setOpen(true);
    }
    if (
      window.localStorage.getItem('isLocked') !== undefined &&
      window.localStorage.getItem('isLocked') === 'true'
    ) {
      setLocked(true);
      setOpen(true);
    }
    if (isWarehouseCustomerAccount) {
      setCurrentUserCustomer();
    }
  }, []);

  useEffect(() => {
    if (window.localStorage.getItem('seletedMenuItem') !== undefined) {
      setSeletedMenuItem(window.localStorage.getItem('seletedMenuItem'));
    }
  }, [seletedMenuItem]);

  const routings = () => {
    if (isSuperAdmin)
      return [
        {
          priority: 3,
          group: 'Admin',
          navigations: [
            {
              link: `/customer-management`,
              label: `Client Management`,
              icon: (v) => <AdminPanelSettingsOutlinedIcon sx={{ color: v }} />,
            },
            {
              link: `/login`,
              label: `Log out`,
              icon: (v) => <PowerSettingsNewOutlinedIcon sx={{ color: v }} />,
            },
          ],
        },
      ];
    if (isInventoryViewer)
      return [
        {
          priority: 0,
          group: 'Unlabeled',
          navigations: [
            {
              link: `/inventory`,
              label: `Inventory`,
              icon: (v) => <CenterFocusStrongIcon sx={{ color: v }} />,
            },
            {
              link: `/login`,
              label: `Log out`,
              icon: (v) => <PowerSettingsNewOutlinedIcon sx={{ color: v }} />,
            },
          ],
        },
      ];
    if (isOperator)
      return [
        {
          priority: 0,
          group: 'Inbound',
          navigations: [
            {
              link: `/license-plates`,
              label: `License Plates`,
              icon: (v) => <PanoramaHorizontalIcon sx={{ color: v }} />,
            },
            {
              link: `/login`,
              label: `Log out`,
              icon: (v) => <PowerSettingsNewOutlinedIcon sx={{ color: v }} />,
            },
          ],
        },
      ];
    if (isWarehouseCustomerAccount)
      return [
        {
          priority: 0,
          multiple: false,
          navigations: [
            {
              link: `/warehouse-customer`,
              label: `Dashboard`,
              icon: (v) => <HomeIcon sx={{ color: v }} />,
              hide: !handleUserPermissionAllow(
                UserPermissionEnum.Dashboard_View,
              ),
            },
          ],
        },
        {
          priority: 1,
          multiple: true,
          groupName: 'Inventory',
          icon: (v) => <ViewInArSharpIcon sx={{ color: v }} />,
          navigations: [
            {
              link: `/warehouse-customer-inventory`,
              label: `List`,
              icon: (v) => <CenterFocusStrongIcon sx={{ color: v }} />,
              hide: !handleUserPermissionAllow(
                UserPermissionEnum.Inventory_View,
              ),
            },
            {
              link: `/warehouse-customer-lots`,
              label: `Item Activity Log`,
              icon: (v) => <WidgetsIcon sx={{ color: v }} />,
            },
          ],
        },
        {
          priority: 2,
          group: 'Unlabeled',
          navigations: [
            {
              link: `/warehouse-customer-orders`,
              label: `Orders`,
              icon: (v) => <ReceiptLongIcon sx={{ color: v }} />,
              hide: !handleUserPermissionAllow(UserPermissionEnum.Orders_View),
            },
            {
              link: `/warehouse-customer-receiving`,
              label: `POs`,
              icon: (v) => <DownloadIcon sx={{ color: v }} />,
              hide: !handleUserPermissionAllow(
                UserPermissionEnum.PurchaseOrder_View,
              ),
            },
            {
              link: `/warehouse-customer-integrations`,
              label: `Integrations`,
              icon: (v) => <IntegrationInstructionsIcon sx={{ color: v }} />,
              hide: !handleUserPermissionAllow(
                UserPermissionEnum.Client_Integration_View,
              ),
            },
            {
              link: `/warehouse-customer-shipping-accounts`,
              label: `Shipping Accounts`,
              icon: (v) => <LocalShippingIcon sx={{ color: v }} />,
              hide: !handleUserPermissionAllow(
                UserPermissionEnum.Client_ShippingAccounts,
              ),
            },
            {
              link: `/warehouse-customer-invoices`,
              label: `Invoices`,
              icon: (v) => <DescriptionIcon sx={{ color: v }} />,
              hide: !handleUserPermissionAllow(
                UserPermissionEnum.Invoices_View,
              ),
            },
            {
              link: `/warehouse-customer-reports`,
              label: `Reports`,
              icon: (v) => <DriveFileMoveOutlinedIcon sx={{ color: v }} />,
              hide: !handleUserPermissionAllow(UserPermissionEnum.Reports_View),
            },
          ],
        },
        {
          priority: 3,
          multiple: false,
          navigations: [
            {
              link: `/login`,
              label: `Log out`,
              icon: (v) => <PowerSettingsNewOutlinedIcon sx={{ color: v }} />,
            },
          ],
        },
      ];
    if (isSupplierAccount)
      return [
        {
          priority: 0,
          group: 'Unlabeled',
          navigations: [
            {
              link: `/supplier-purchase-order`,
              label: `POs`,
              icon: (v) => <DownloadIcon sx={{ color: v }} />,
              hide: !roleSupplierPermission.PO,
            },
            {
              link: `/login`,
              label: `Log out`,
              icon: (v) => <PowerSettingsNewOutlinedIcon sx={{ color: v }} />,
            },
          ],
        },
      ];
    if (!isSuperAdmin && isAdmin) return [...getInventoryColumns()];
    return [];
  };
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const menus = routings();

  const DocTitle = () => {
    useEffect(() => {
      const previousTitle = document.title;

      document.title = isWarehouseCustomerAccount ? companyName : previousTitle;

      return () => {
        document.title = previousTitle;
      };
    }, []);

    return null;
  };

  const DocFavicon = ({ base64string }) => {
    useEffect(() => {
      const faviconElement = document.querySelector(
        "link[rel='icon']",
      ) as HTMLLinkElement;
      const previousFaviconHref = faviconElement.href;

      faviconElement.href = isWarehouseCustomerAccount
        ? `data:image/png;base64,${base64string}`
        : previousFaviconHref;

      return () => {
        faviconElement.href = previousFaviconHref;
      };
    }, [base64string]);

    return null;
  };

  return (
    <Box
      sx={{
        zIndex: '9',
      }}
      className="menubarRadius"
    >
      <DocFavicon base64string={favicon64} />
      <DocTitle />
      <NavBar
        sx={{
          '& .MuiDrawer-paper': { background: '#ffffff' },
          display: {
            xs: 'none',
            sm: 'none',
            md: 'flex',
            lg: 'flex',
            xl: 'flex',
          },
          textAlign: 'center',
        }}
        onMouseOver={() => {
          setOpen(true);
          window.localStorage.setItem('isOpen', 'open');
          handleDrawerOpen();
        }}
        // eslint-disable-next-line consistent-return
        onMouseLeave={() => {
          if (!locked) {
            const timer = setTimeout(() => {
              setOpen(false);
              window.localStorage.setItem('isOpen', 'close');
              handleDrawerClose();
            }, 500);
            return () => {
              clearTimeout(timer);
            };
          }
        }}
        variant="permanent"
        open={open}
        enter={enter ? 1 : 0}
        transitionDuration={0}
      >
        <Box>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                margin: '20px',
              }}
              onClick={() => console.log('NO IMPLEMENTATION')}
            >
              <NavBarLogoOnly
                base64Image={isWarehouseCustomerAccount ? companyLogo64 : null}
              />

              <ListItemText>
                <Typography marginRight={5} color="black" textAlign="right">
                  {isWarehouseCustomerAccount ? (
                    <Typography
                      fontWeight="bold"
                      variant="subtitle1"
                      sx={{
                        textTransform: 'uppercase',
                        textAlign: 'left',
                        width: '50px',
                        height: '17px',
                        position: 'relative',
                        marginLeft: '40px',
                      }}
                    >
                      {companyName}
                    </Typography>
                  ) : (
                    <NavBarLogo />
                  )}
                </Typography>
              </ListItemText>

              {open && (
                <IconButton
                  onClick={() => {
                    if (locked) {
                      setLocked(false);
                      window.localStorage.setItem('isLocked', 'false');
                      setIsLockedNavbar(false);
                    } else {
                      setLocked(true);
                      window.localStorage.setItem('isLocked', 'true');
                      setIsLockedNavbar(true);
                    }
                  }}
                  sx={{ marginRight: '-40px' }}
                >
                  {locked ? <LockIcon /> : <LockOpenIcon />}
                </IconButton>
              )}
            </ListItemButton>
          </ListItem>
        </Box>
        <List sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box>
            {menus.map((m) => (
              <div key={m.priority}>
                {m?.multiple ? (
                  <Accordion
                    sx={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      display: m?.hide ? 'none' : 'block',
                    }}
                    expanded={expanded === m.groupName}
                    onChange={handleChange(m?.groupName)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={m?.label}
                      id={m?.groupName}
                      className={!open && 'accordionSummaryAlign'}
                      sx={{ width: '95%' }}
                    >
                      <Typography
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          ml: 0.5,
                          mb: 0,
                          justifyContent: 'center',
                        }}
                      >
                        {m?.icon(
                          seletedMenuItem === m?.label ? 'white' : '#3375E0',
                        )}
                      </Typography>
                      <Typography
                        sx={{
                          display: open ? 'block' : 'none',
                          fontSize: 15,
                          fontWeight: 500,
                        }}
                      >
                        {open ? m?.groupName : ''}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ marginTop: -3, marginBottom: -1 }}>
                      {m.navigations.map(
                        (menu) =>
                          !menu.hide && (
                            <ListItemButton
                              key={menu.label}
                              sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                if (menu.link !== '/login') {
                                  navigate(menu.link);
                                  setSeletedMenuItem(menu.label);
                                  window.localStorage.setItem(
                                    'seletedMenuItem',
                                    menu.label,
                                  );
                                } else {
                                  onLogout();
                                }
                              }}
                              {...(open &&
                                seletedMenuItem === menu.label && {
                                  className: 'menubarSelectedInsideParent',
                                })}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : 'auto',
                                  justifyContent: 'center',
                                }}
                                hidden={menu.hide}
                                className={
                                  !open && seletedMenuItem === menu.label
                                    ? 'menubarSelected'
                                    : ''
                                }
                              >
                                {menu.icon(
                                  seletedMenuItem === menu.label
                                    ? 'white'
                                    : '#3375E0',
                                )}
                              </ListItemIcon>
                              <ListItemText>
                                <Link
                                  sx={{
                                    fontSize: 15,
                                    fontWeight: 500,
                                    color:
                                      seletedMenuItem === menu.label
                                        ? 'white'
                                        : 'black',
                                    opacity: open ? 1 : 0,
                                    textDecoration: 'none',
                                  }}
                                  onClick={() => {
                                    setSeletedMenuItem(menu.label);
                                    window.localStorage.setItem(
                                      'seletedMenuItem',
                                      menu.label,
                                    );
                                  }}
                                  href={menu.link}
                                >
                                  {menu.label}
                                  {menu.label === 'Put Away' &&
                                    notification !== undefined &&
                                    notification.putaway > 0 && (
                                      <Chip
                                        label={notification.putaway}
                                        size="small"
                                        className="blueChip"
                                      />
                                    )}
                                  {menu.label === 'Picking' &&
                                    notification !== undefined &&
                                    notification.picking > 0 && (
                                      <Chip
                                        label={notification.picking}
                                        size="small"
                                        className="blueChip"
                                      />
                                    )}
                                  {menu.label === 'Transfers' &&
                                    notification !== undefined &&
                                    notification.transfer > 0 && (
                                      <Chip
                                        label={notification.transfer}
                                        size="small"
                                        className="blueChip"
                                      />
                                    )}
                                </Link>
                              </ListItemText>
                            </ListItemButton>
                          ),
                      )}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <ListItem
                    disablePadding
                    sx={{ display: m?.hide ? 'none' : 'block' }}
                  >
                    {m.navigations.map(
                      (menu) =>
                        !menu.hide && (
                          <ListItemButton
                            key={menu.label}
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                              marginLeft:
                                seletedMenuItem === menu.label &&
                                open &&
                                '-9px !important',
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (menu.link !== '/login') {
                                setSeletedMenuItem(menu.label);
                                window.localStorage.setItem(
                                  'seletedMenuItem',
                                  menu.label,
                                );
                                navigate(menu.link);
                              } else {
                                onLogout();
                              }
                            }}
                          >
                            <Box
                              {...(!open &&
                                seletedMenuItem === menu.label && {
                                  className: 'menubarSelectedInsideParent',
                                })}
                              {...(open &&
                                seletedMenuItem === menu.label && {
                                  className: 'menubarSelected',
                                })}
                              sx={{ display: 'flex', width: '100%' }}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : 'auto',
                                  justifyContent: 'center',
                                }}
                                hidden={menu.hide}
                              >
                                {menu.icon(
                                  seletedMenuItem === menu.label
                                    ? 'white'
                                    : '#3375E0',
                                )}
                              </ListItemIcon>
                              {open && (
                                <ListItemText>
                                  <Link
                                    sx={{
                                      fontSize: 15,
                                      fontWeight: 500,
                                      opacity: open ? 1 : 0,
                                      textDecoration: 'none',
                                      color:
                                        seletedMenuItem === menu.label
                                          ? 'white'
                                          : 'black',
                                    }}
                                    onClick={() => {
                                      setSeletedMenuItem(menu.label);
                                      window.localStorage.setItem(
                                        'seletedMenuItem',
                                        menu.label,
                                      );
                                    }}
                                    href={menu.link}
                                  >
                                    {menu.label}
                                    {menu.label === 'Put Away' &&
                                      notification !== undefined && (
                                        <Chip
                                          label={notification.putaway}
                                          size="small"
                                          className="blueChip"
                                        />
                                      )}
                                    {menu.label === 'Picking' &&
                                      notification !== undefined && (
                                        <Chip
                                          label={notification.picking}
                                          size="small"
                                          className="blueChip"
                                        />
                                      )}
                                    {menu.label === 'Transfers' &&
                                      notification !== undefined && (
                                        <Chip
                                          label={notification.transfer}
                                          size="small"
                                          className="blueChip"
                                        />
                                      )}
                                  </Link>
                                </ListItemText>
                              )}
                            </Box>
                          </ListItemButton>
                        ),
                    )}
                  </ListItem>
                )}
              </div>
            ))}
          </Box>
        </List>

        <Box sx={{ position: 'fixed', marginLeft: '16px' }}>
          {open && <Footer />}
        </Box>
      </NavBar>
    </Box>
  );
}

export default React.memo(NavbarV2);
