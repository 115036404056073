/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { SyntheticEvent, useContext, useEffect } from 'react';

import { OrderSearchStatusesData, optionSortings } from 'common/constant';
import { UserFilterValueTypeEnum } from 'common/enums/UserFilterValueTypeEnum';
import {
  IAutoCompleteOption,
  IOptionColumn,
  IOrderSearchStatus,
} from 'common/interfaces';
import DatePickerInput from 'components/datepicker';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { useSalesOrderDataTableViewContext } from 'pages/sales/context-provider';
import { GlobalContext } from 'store/contexts/GlobalContext';

import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Fade,
  Input,
  InputAdornment,
  Grid as MUIGrid,
  TextField,
  Typography,
} from '@mui/material';

export type CarrierType = {
  label?: string;
  value?: string;
};

const carrierOptions: CarrierType[] = [
  { label: 'LTL', value: 'LTL' },
  { label: 'Parcel', value: 'Parcel' },
];

const optionColumnNames: IOptionColumn[] = [
  { value: 'BatchNo', label: 'Batch No' },
  { value: 'Status', label: 'Status' },
  { value: 'OrderNo', label: 'Order No.' },
  { value: 'ReferenceNo', label: 'Reference No.' },
  { value: 'PO', label: 'PO' },
  { value: 'ItemCount', label: 'Item Count' },
  { value: 'TotalQuantity', label: 'Total Quantity' },
  { value: 'Carrier', label: 'Carrier' },
  { value: 'Customer', label: 'Customer' },
  { value: 'CreationDateTime', label: 'Creation Date' },
  { value: 'ShipByDateTime', label: 'Ship By' },
  { value: 'ShipCancelDateTime', label: 'Ship Cancel Date' },
  { value: 'ShipStationTags', label: 'Tags' },
];

export interface ISearchPanelSidebarProps {
  show: boolean;

  onCloseSidebar: () => void;
}

const SearchPanelSidebar = ({
  show,
  onCloseSidebar,
}: ISearchPanelSidebarProps) => {
  const {
    onOpenAdvancedSearchModal,
    setIsDeleteFilterModalOpen,
    setFilterValueEnum,
    isAdvancedSearchModalOpen,
  } = useContext(GlobalContext);

  const {
    advanceSearchValue,
    countries,
    initialFormFilterSearchValue,
    filterSearchOptions,
    loadCountries,
    setOrderFilterListLocal,
    setAdvanceSearchValue,
    inputHandler,
    onLoadFilterSearchValueByOptionValue,
    OnClickUserFilterSearchApply,
    initialStateSearchParams,
    setSearchParams,
    optionWarehouseUser,
    optionWarehouseUserApprovedBy,
  } = useSalesOrderDataTableViewContext();

  const countrySelectionOptions = Array.isArray(countries)
    ? countries.map((v) => ({
        value: v.countryId,
        label: v.name,
      }))
    : [{ value: 0, label: '' }];

  const handleChangeOrderStatus = (e: any, index: number) => {
    const clone = [...advanceSearchValue.orderStatuses];
    const obj = clone[index];
    obj.isChecked = e;
    clone[index] = obj;
    setAdvanceSearchValue((prev) => ({
      ...prev,
      orderStatuses: clone,
    }));
  };

  const handleCloseSidebar = async () => {
    onCloseSidebar();
  };

  const clearSearchValues = () => {
    const clearStatuses = advanceSearchValue.orderStatuses.map((v) => ({
      value: v.value,
      isChecked: false,
    }));
    const clearItem = initialFormFilterSearchValue;
    clearItem.orderStatuses = clearStatuses;
    setAdvanceSearchValue(clearItem);
  };

  const SaveOrUpdateSearchValues = (e?: any) => {
    e?.preventDefault();
    onOpenAdvancedSearchModal();
  };

  const handleDropdownFilterChange = async (
    e: SyntheticEvent,
    newValue: IAutoCompleteOption,
  ) => {
    e.preventDefault();
    if (newValue) {
      setAdvanceSearchValue((prev) => ({
        ...prev,
        filterSearchParameter: {
          value: newValue.value,
          label: newValue.label,
        },
        setFilterName: newValue.label,
      }));
      onLoadFilterSearchValueByOptionValue(newValue.value);
      return;
    }
    setSearchParams({
      ...initialStateSearchParams,
      status: '',
    });
  };

  useEffect(() => {
    loadCountries();
  }, [isAdvancedSearchModalOpen]);

  return (
    <Fade in={show}>
      <Box
        sx={{
          position: 'fixed',
          right: '1px',
          width: '30%',
          height: '88.3vh !important',
          bgcolor: 'rgb(255 255 255)',
          padding: '10px',
          bottom: '0',
          zIndex: '100',
          boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
          overflowY: 'scroll',
        }}
      >
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            padding: '20px !important',
          }}
        >
          <MUIGrid item xs={6}>
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{ marginBottom: '10px', float: 'left' }}
            >
              Advanced Search
            </Typography>
          </MUIGrid>
          <MUIGrid item xs={6}>
            <Autocomplete
              options={filterSearchOptions}
              getOptionLabel={(option: IAutoCompleteOption) => option.label}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              value={advanceSearchValue.filterSearchParameter || null}
              onChange={handleDropdownFilterChange}
              renderInput={(params) => (
                <div>
                  <TextField
                    {...params}
                    placeholder="Filters"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <FilterListIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ boxShadow: 'none !important' }}
                    fullWidth
                    size="small"
                  />
                </div>
              )}
            />
          </MUIGrid>
        </MUIGrid>
        <Divider />
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ padding: '20px', paddingBottom: '0px' }}
        >
          <MUIGrid item>
            <Typography variant="body1" fontWeight="bold">
              Order
            </Typography>
          </MUIGrid>
        </MUIGrid>
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            paddingLeft: '20px',
            padding: '20px',
          }}
        >
          {/* First Column */}
          <MUIGrid item xs={6}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item>
                <Input
                  placeholder="Order No"
                  value={advanceSearchValue.orderNo}
                  name="orderNo"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    inputHandler('orderNo', e)
                  }
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item>
                <Typography variant="body2" fontWeight="bold">
                  Search by Order Status
                </Typography>
              </MUIGrid>
              {OrderSearchStatusesData.map(
                (model: IOrderSearchStatus, index: number) => (
                  <MUIGrid
                    key={model.value}
                    item
                    xs={12}
                    sx={{
                      padding: '0px !important',
                      paddingLeft: '20px !important',
                      display: 'flex',
                      placeItems: 'center',
                    }}
                  >
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={
                        advanceSearchValue.orderStatuses[index].isChecked ||
                        false
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleChangeOrderStatus(event.target.checked, index);
                      }}
                    />
                    {model.label}
                  </MUIGrid>
                ),
              )}
            </MUIGrid>
          </MUIGrid>
          {/* Second Column */}
          <MUIGrid item xs={6}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <Input
                  placeholder="PO"
                  value={advanceSearchValue.po}
                  name="po"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    inputHandler('po', e)
                  }
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Reference Number"
                  value={advanceSearchValue.referenceNo}
                  onChange={(e) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      referenceNo: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <Autocomplete
                  value={advanceSearchValue?.carrierType}
                  options={carrierOptions}
                  onChange={(e: any, newValue: CarrierType) => {
                    if (newValue) {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        carrierType: newValue,
                      }));
                    } else {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        carrierType: null,
                      }));
                    }
                  }}
                  id="controllable-states"
                  getOptionLabel={(option: CarrierType) => option.label}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li data-name="carrierType" {...props}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      size="small"
                      label="Carrier Type"
                      sx={{ width: '100%', background: '#ffffff' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Item Name"
                  value={advanceSearchValue.itemName}
                  onChange={(e) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      itemName: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <Autocomplete
                  value={advanceSearchValue?.assignedTo}
                  options={optionWarehouseUser}
                  onChange={(e: any, newValue: CarrierType) => {
                    if (newValue) {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        assignedTo: newValue,
                      }));
                    } else {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        assignedTo: null,
                      }));
                    }
                  }}
                  id="controllable-states"
                  getOptionLabel={(option: CarrierType) => option.label}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li data-name="assignedTo" {...props}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      size="small"
                      label="Assigned To"
                      sx={{ width: '100%', background: '#ffffff' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <Autocomplete
                  value={advanceSearchValue?.approvedBy}
                  options={optionWarehouseUserApprovedBy}
                  onChange={(e: any, newValue: CarrierType) => {
                    if (newValue) {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        approvedBy: newValue,
                      }));
                    } else {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        approvedBy: null,
                      }));
                    }
                  }}
                  id="controllable-states"
                  getOptionLabel={(option: CarrierType) => option.label}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li data-name="approvedBy" {...props}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      size="small"
                      label="Approved By"
                      sx={{ width: '100%', background: '#ffffff' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid
                item
                xs={12}
                sx={{ display: 'flex', placeItems: 'center' }}
              >
                <Checkbox
                  color="primary"
                  size="small"
                  checked={advanceSearchValue.ishold || false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      ishold: event.target.checked,
                    }));
                  }}
                />
                Is Hold?
              </MUIGrid>
              <MUIGrid
                item
                xs={12}
                sx={{
                  paddingTop: '0px !important',
                  display: 'flex',
                  placeItems: 'center',
                }}
              >
                <Checkbox
                  color="primary"
                  size="small"
                  checked={advanceSearchValue.isBackOrder || false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      isBackOrder: event.target.checked,
                    }));
                  }}
                />
                Is Backorder?
              </MUIGrid>
              <MUIGrid
                item
                xs={12}
                sx={{
                  paddingTop: '0px !important',
                  display: 'flex',
                  placeItems: 'center',
                }}
              >
                <Checkbox
                  color="primary"
                  size="small"
                  checked={advanceSearchValue.isShowUnassignedOrders}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      isShowUnassignedOrders: event.target.checked,
                    }));
                  }}
                />
                Show Unassigned Orders
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Tags"
                  value={advanceSearchValue.tags || ''}
                  onChange={(e) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      tags: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
          <MUIGrid item xs={12}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <Typography variant="body1" fontWeight="bold">
                  Order Date Range
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={6}>
                <DatePickerInput
                  label="From Date"
                  inputFormat="MM/dd/yyyy"
                  value={advanceSearchValue?.orderDateFrom}
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid Date') {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        orderDateFrom: e,
                      }));
                    } else {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        orderDateFrom: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField disabled {...params} size="small" />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <DatePickerInput
                  label="To Date"
                  inputFormat="MM/dd/yyyy"
                  value={advanceSearchValue?.orderDateTo}
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid Date') {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        orderDateTo: e,
                      }));
                    } else {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        orderDateTo: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField disabled {...params} size="small" />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
          <MUIGrid item xs={12}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <Typography variant="body1" fontWeight="bold">
                  Order Ship Date Range
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={6}>
                <DatePickerInput
                  label="From Ship Date"
                  inputFormat="MM/dd/yyyy"
                  value={advanceSearchValue?.orderShipDateFrom}
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid Date') {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        orderShipDateFrom: e,
                      }));
                    } else {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        orderShipDateFrom: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField disabled {...params} size="small" />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <DatePickerInput
                  label="To Ship Date"
                  inputFormat="MM/dd/yyyy"
                  value={advanceSearchValue?.orderShipDateTo}
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid Date') {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        orderShipDateTo: e,
                      }));
                    } else {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        orderShipDateTo: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField disabled {...params} size="small" />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
          <MUIGrid item xs={12}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <Typography variant="body1" fontWeight="bold">
                  Order Ship By Date Range
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={6}>
                <DatePickerInput
                  label="From Ship By Date"
                  inputFormat="MM/dd/yyyy"
                  value={advanceSearchValue?.orderShipDateByFrom}
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid Date') {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        orderShipDateByFrom: e,
                      }));
                    } else {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        orderShipDateByFrom: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField disabled {...params} size="small" />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <DatePickerInput
                  label="To Ship By Date"
                  inputFormat="MM/dd/yyyy"
                  value={advanceSearchValue?.orderShipDateByTo}
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid Date') {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        orderShipDateByTo: e,
                      }));
                    } else {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        orderShipDateByTo: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField disabled {...params} size="small" />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
        <Divider />
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ padding: '20px' }}
        >
          <MUIGrid item xs={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{ paddingBottom: '10px' }}
            >
              Client
            </Typography>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Name"
                  value={advanceSearchValue.customerName}
                  onChange={(e) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      customerName: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Point Of Contact Name"
                  value={advanceSearchValue.customerPointOfContactName}
                  onChange={(e) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      customerPointOfContactName: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
        <Divider />
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ padding: '20px', paddingBottom: '0px !important' }}
        >
          <MUIGrid item>
            <Typography variant="body1" fontWeight="bold">
              Ship To
            </Typography>
          </MUIGrid>
        </MUIGrid>
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ padding: '20px' }}
        >
          <MUIGrid item xs={6}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Name"
                  value={advanceSearchValue.shipToName}
                  onChange={(e) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      shipToName: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Phone"
                  value={advanceSearchValue.shipToPhone}
                  onChange={(e) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      shipToPhone: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Address"
                  value={advanceSearchValue.shipToAddress}
                  onChange={(e) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      shipToAddress: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="State"
                  value={advanceSearchValue.shipToState}
                  onChange={(e) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      shipToState: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
          <MUIGrid item xs={6}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Company"
                  value={advanceSearchValue.shipToCompany}
                  onChange={(e) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      shipToCompany: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Email"
                  value={advanceSearchValue.shipToEmail}
                  onChange={(e) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      shipToEmail: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="City"
                  value={advanceSearchValue.shipToCity}
                  onChange={(e) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      shipToCity: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Zip Code"
                  value={advanceSearchValue.shipToZipcode}
                  onChange={(e) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      shipToZipcode: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>

          <MUIGrid item xs={12}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 1, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <Autocomplete
                  options={countrySelectionOptions}
                  getOptionLabel={(option: IAutoCompleteOption) => option.label}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  value={advanceSearchValue.shipToCountry || null}
                  onChange={(
                    event: any,
                    newValue: IAutoCompleteOption | null,
                  ) => {
                    if (newValue === null) {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        shipToCountry: null,
                      }));
                    } else {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        shipToCountry: {
                          value: newValue.value,
                          label: newValue.label,
                        },
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <div>
                      <TextField
                        {...params}
                        label="Country"
                        variant="outlined"
                        sx={{ boxShadow: 'none !important' }}
                        fullWidth
                        size="small"
                      />
                    </div>
                  )}
                />
              </MUIGrid>

              <MUIGrid
                item
                xs={12}
                sx={{ display: 'flex', placeItems: 'center' }}
              >
                <Checkbox
                  color="primary"
                  size="small"
                  checked={advanceSearchValue.isInternational || false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAdvanceSearchValue((prev) => ({
                      ...prev,
                      isInternational: event.target.checked,
                    }));
                  }}
                />
                <p>International</p>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
        <Divider />
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            padding: '20px',
            paddingBottom: '0px !important',
            marginBottom: '15% !important',
          }}
        >
          <MUIGrid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              Sorting
            </Typography>
          </MUIGrid>

          <MUIGrid item xs={6}>
            <Autocomplete
              options={optionColumnNames}
              getOptionLabel={(option: any) => option.label}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              value={advanceSearchValue?.filterColumnName}
              onChange={(event: any, newValue: any | null) => {
                if (newValue === null) {
                  setAdvanceSearchValue((prev) => ({
                    ...prev,
                    filterColumnName: null,
                  }));
                } else {
                  setAdvanceSearchValue((prev) => ({
                    ...prev,
                    filterColumnName: {
                      value: newValue.value,
                      label: newValue.label,
                    },
                  }));
                }
              }}
              renderInput={(params) => (
                <div>
                  <TextField
                    {...params}
                    label="Column Name"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    sx={{ boxShadow: 'none !important' }}
                    fullWidth
                    size="small"
                  />
                </div>
              )}
            />
          </MUIGrid>
          <MUIGrid item xs={6}>
            <Autocomplete
              options={optionSortings}
              getOptionLabel={(option: any) => option.label}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              value={advanceSearchValue?.filterSorting}
              onChange={(event: any, newValue: any | null) => {
                if (newValue === null) {
                  setAdvanceSearchValue((prev) => ({
                    ...prev,
                    filterSorting: null,
                  }));
                } else {
                  setAdvanceSearchValue((prev) => ({
                    ...prev,
                    filterSorting: {
                      value: newValue.value,
                      label: newValue.label,
                    },
                  }));
                }
              }}
              renderInput={(params) => (
                <div>
                  <TextField
                    {...params}
                    label="Sort"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    sx={{ boxShadow: 'none !important' }}
                    fullWidth
                    size="small"
                  />
                </div>
              )}
            />
          </MUIGrid>
        </MUIGrid>
        <Box
          sx={{
            position: 'fixed',
            right: '1px',
            width: '30%',
            height: '8vh !important',
            bgcolor: 'rgb(255 255 255)',
            padding: '10px',
            bottom: '0',
            zIndex: '100',
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
            display: 'flex',
            alignContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={12}>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  marginRight: '10px',
                  boxShadow: 'none !important',
                }}
                onClick={handleCloseSidebar}
              >
                Close
              </Button>

              <Button
                variant="contained"
                size="small"
                className="buttonFloatRight"
                onClick={(e) => {
                  OnClickUserFilterSearchApply(e, true);
                }}
              >
                Apply
              </Button>
              <Button
                variant="contained"
                size="small"
                className="buttonFloatRight"
                onClick={(e) => SaveOrUpdateSearchValues(e)}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                size="small"
                className="buttonFloatRight"
                onClick={(e) => {
                  clearSearchValues();
                  setOrderFilterListLocal(null);
                  OnClickUserFilterSearchApply(e);
                }}
              >
                Reset
              </Button>
              <Button
                variant="outlined"
                size="small"
                className="buttonFloatRight"
                sx={{ borderColor: '#ff6969', color: '#ff6969' }}
                onClick={() => {
                  setIsDeleteFilterModalOpen(true);
                  setFilterValueEnum(UserFilterValueTypeEnum.Order);
                }}
                disabled={!advanceSearchValue?.userFilterSearchValueId}
              >
                Delete
              </Button>
            </MUIGrid>
          </MUIGrid>
        </Box>
      </Box>
    </Fade>
  );
};

export default React.memo(SearchPanelSidebar);
