import React, { useContext, useEffect, useState } from 'react';

import { USDollar } from 'common/utils';
import { Grid } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { usePackAndShipContext } from 'pages/packandship/context';
import { getShipmentRates } from 'services/api/integrations/shippo/shippo.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Divider,
  Grid as MUIGrid,
  Autocomplete,
  TextField,
  ImageList,
  ImageListItem,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import {
  GridToolbar,
  GridActionsCellItem,
  GridColumns,
} from '@mui/x-data-grid';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 1050px;
    border-radius: 4px;
    max-height: 100%;
    overflow: auto;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 24px;
    gap: 16px;
  }
`;

export default React.memo(() => {
  const {
    isCreateShipmentModalOpen,
    onCloseCreateShipmentModal,
    onOpenShipAndCompleteModalModal,
  } = useContext(GlobalContext);

  const { setShipmentRateId, queryStringItemPackAndShip, pickTicketFormObj } =
    usePackAndShipContext();

  const [selectedShipment, setSelectedShipment] = React.useState(undefined);
  const [rates, setRates] = useState<any[]>([]);

  useEffect(() => {
    if (selectedShipment) setShipmentRateId(selectedShipment);
  }, [selectedShipment]);

  const loadShipmentRates = async () => {
    setSelectedShipment(undefined);
    getShipmentRates(
      queryStringItemPackAndShip.id,
      pickTicketFormObj.customerWarehouseId,
    ).then((shipmentRates) => {
      if (shipmentRates.rates !== null && shipmentRates.rates.length > 0) {
        setRates(shipmentRates.rates);
      }
      if (
        shipmentRates.messages !== null &&
        shipmentRates.messages.length > 0
      ) {
        shipmentRates.messages.forEach((message) => {
          snackActions.error(`${message.source}: ${message.text}`);
        });
      }
    });
  };

  const shipmentRateColumns: GridColumns<any> = [
    {
      field: 'provider',
      width: 120,
      sortable: false,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Provider</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.provider}</Typography>,
    },
    {
      field: 'amount',
      width: 120,
      sortable: false,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Amount</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography sx={{ textAlign: 'right' }}>
          {USDollar.format(params.row.amount)}
        </Typography>
      ),
    },
    {
      field: 'currency',
      width: 100,
      sortable: false,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Currency</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.currency}</Typography>,
    },
    {
      field: 'estimated_days',
      width: 150,
      sortable: false,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Estimated Days</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.estimated_days}</Typography>
      ),
    },
    {
      field: 'duration_terms',
      width: 450,
      sortable: false,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Duration Terms</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.duration_terms}</Typography>
      ),
    },
  ];

  useEffect(() => {
    if (isCreateShipmentModalOpen) {
      setRates([]);
      loadShipmentRates();
    }
  }, [isCreateShipmentModalOpen]);

  return (
    <Modal
      open={isCreateShipmentModalOpen}
      onClose={() => onCloseCreateShipmentModal()}
    >
      <MUIContainer>
        <MUIContent>
          <Box autoComplete="off" component="form" noValidate>
            <Typography variant="h6" fontWeight="bold">
              Shipment Rates
            </Typography>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={12} sx={{ minHeight: 300 }}>
                <Grid
                  autoHeight
                  rows={rates}
                  columns={shipmentRateColumns}
                  pageSize={10}
                  density="compact"
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnMenu
                  checkboxSelection
                  onSelectionModelChange={(ids) => {
                    setSelectedShipment(ids.pop());
                  }}
                  selectionModel={selectedShipment}
                  getRowId={(row) => row.object_id}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      title: 'Order Details',
                    },
                  }}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'amount', sort: 'asc' }],
                    },
                  }}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
          <Box>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              sx={{ width: '50%', float: 'left' }}
            >
              <MUIGrid item xs={2}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => onCloseCreateShipmentModal()}
                >
                  Cancel
                </Button>
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              direction="row"
              justifyContent="right"
              sx={{ width: '50%', marginRight: '-30px' }}
            >
              <MUIGrid item xs={2}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    onOpenShipAndCompleteModalModal();
                  }}
                >
                  Complete
                </Button>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
