import React from 'react';

import Header from 'components/header';
import { OrderReadyForPickupModal } from 'pages/packandship/order-ready-for-pickup-modal';
import CreateShipmentModal from 'pages/shared/createShipments';
import { ContentContainer, MainContainer } from 'pages/styles';

import PackAndShipContainer from './container';
import { PackAndShipContextProvider } from './context';
import AddItemModal from './modal/additem';
import CarrierModal from './modal/carrier';
import ShipAndCompleteModal from './modal/shipandcompletewarning';
import ShipToAndBillToModal from './modal/shiptoandbillto';

const PackAndShipPickTicketDetails = () => (
  <MainContainer>
    <PackAndShipContextProvider>
      <Header />
      <AddItemModal />
      <CarrierModal />
      <ShipToAndBillToModal />
      <ShipAndCompleteModal />
      <CreateShipmentModal />
      <OrderReadyForPickupModal />
      <ContentContainer>
        <PackAndShipContainer />
      </ContentContainer>
    </PackAndShipContextProvider>
  </MainContainer>
);

export default React.memo(PackAndShipPickTicketDetails);
