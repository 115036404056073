import React, { useContext, useState, ChangeEvent, useEffect } from 'react';

import Button from 'components/button';
import DatePickerInput from 'components/datepicker';
import Input from 'components/input/Input';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { createLot } from 'services/api/lot/lot.api.';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import * as yup from 'yup';

import { Modal, Box, Typography, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface NewLotModalProps {
  itemId: any;
  callBack?: () => void;
}

type NewLotNumber = {
  customerId: number;
  customerLocationId: number;
  itemId: number;
  lotNo: string;
  expirationDate: string;
};
export default React.memo(({ itemId, callBack }: NewLotModalProps) => {
  const theme = useTheme();
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const {
    isNewLotNumberModalOpen,
    onCloseNewLotNumberModal,
    handleUpdateData,
  } = useContext(GlobalContext);
  const [error, setError] = useState('');
  const initialErrorsState = {
    lotNumber: '',
    expirationDate: '',
  };
  const initialState = {
    lotNumber: '',
    expirationDate: '',
  };
  const [formErrors, setFormErrors] = useState(initialErrorsState);
  const [form, setForm] = useState(initialState);

  const onForm = (key, text) => {
    setForm(() => ({
      ...form,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) =>
    onForm(key, event.target.value);

  const dateInputHandler = (newValue: Date | null) => {
    onForm('expirationDate', newValue);
  };

  const schema = yup.object().shape({
    lotNumber: yup.string().required('Required.'),
    expirationDate: yup.string().required('Required'),
  });

  const handleCloseNewLotNumberModal = () => {
    setForm(initialState);
    onCloseNewLotNumberModal();
  };

  const onCreateNewLotNumber = async () => {
    schema
      .validate(form, {
        abortEarly: false,
      })
      .then(async () => {
        try {
          const newLotNumberForm = {} as NewLotNumber;
          newLotNumberForm.customerId = currentUser.Claim_CustomerId;
          newLotNumberForm.customerLocationId =
            currentLocationAndFacility.customerLocationId;
          newLotNumberForm.itemId = itemId.id;
          newLotNumberForm.lotNo = form.lotNumber;
          newLotNumberForm.expirationDate = form.expirationDate;

          const newLotNumberEntity = await await createLot(newLotNumberForm);
          snackActions.success(`Successfully created new lot number.`);
          setForm(initialState);
          callBack();
          handleCloseNewLotNumberModal();
          handleUpdateData();
        } catch (err: any) {
          setError(err);
          setFormErrors(initialErrorsState);
          snackActions.error(`${err}`);
        }
      })
      .catch((err) => {
        const errorsFound = err.inner.reduce((acc, curr) => {
          if (!acc[curr.path]) acc[curr.path] = curr.message;
          return acc;
        }, {});
        setFormErrors(errorsFound);
        setError('');
      });
  };

  useEffect(() => {
    setForm(initialState);
  }, [itemId]);

  if (!isNewLotNumberModalOpen) return null;

  return (
    <Modal
      open={isNewLotNumberModalOpen}
      onClose={() => handleCloseNewLotNumberModal()}
    >
      <ModalBox>
        <ModalContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            New Lot Number
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
            <Input
              sx={{ width: '100% ' }}
              type="text"
              placeholder="Lot Number"
              value={form.lotNumber}
              error={formErrors.lotNumber}
              onChange={(value) => inputHandler('lotNumber', value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onCreateNewLotNumber();
                }
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <DatePickerInput
              sx={{ width: '100%', '& .MuiStack-root': { width: '100%' } }}
              inputFormat="MM/dd/yyyy"
              value={form.expirationDate}
              onChange={dateInputHandler}
              renderInput={(params) =>
                formErrors.expirationDate ? (
                  <TextField
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-root': {
                        background: 'white',
                      },
                    }}
                    {...params}
                    size="small"
                    error
                    helperText={formErrors.expirationDate}
                  />
                ) : (
                  <TextField
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-root': {
                        background: 'white',
                      },
                    }}
                    {...params}
                    error={false}
                    size="small"
                  />
                )
              }
            />
          </Box>
          <Typography variant="subtitle2" color="#d32f2f">
            {error}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end',
              },
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
              },
              gap: '8px',
            }}
          >
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="text"
              size="large"
              onClick={() => handleCloseNewLotNumberModal()}
            >
              Cancel
            </Button>
            <Button
              sx={{ minHeight: '48px', maxWidth: '150px' }}
              variant="contained"
              size="large"
              onClick={() => onCreateNewLotNumber()}
            >
              Create
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
