import React, { useState } from 'react';

import { OrderVoidTypeEnum } from 'common/enums';
import { OrderVoidThePackModel } from 'common/models/orders/voidthepack';
import { snackActions } from 'config/snackbar.js';
import { useSalesOrderContext } from 'pages/sales/salesorder/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { VoidThePack } from 'services/api/salesorders/salesorders-new.api';

import {
  Modal,
  Box,
  Typography,
  Button,
  Grid as MUIGrid,
  CircularProgress,
} from '@mui/material';

function VoidThePackModal() {
  const {
    salesOrderForm,
    viewLoadData,
    voidType,
    isVoidThePackModal,
    setIsVoidThePackModal,
  } = useSalesOrderContext();

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const handleSave = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();

    setIsBusy(true);

    const PAYLOAD: OrderVoidThePackModel = {
      voidType,
      orderId: salesOrderForm.orderId,
    };

    VoidThePack(PAYLOAD)
      .then(() => {
        viewLoadData();
        setIsVoidThePackModal(false);
      })
      .catch((err) => {
        snackActions.error(err);
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  return (
    <Modal open={isVoidThePackModal}>
      <MUIContainer sx={{ maxWidth: '600px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          {voidType === OrderVoidTypeEnum.VoidThePack
            ? 'Void The Pack'
            : 'Void The Label'}
        </Typography>

        <MUIContent>
          <Typography variant="subtitle1">
            This will undo the completed order and place it back into the pack
            and ship queue. Are you sure you want to continue?
          </Typography>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={isBusy ? 6 : 4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                disabled={isBusy}
                onClick={() => {
                  setIsVoidThePackModal(false);
                  setIsBusy(false);
                }}
              >
                CANCEL
              </Button>

              {!isBusy && (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => handleSave(e)}
                >
                  SAVE
                </Button>
              )}
              {isBusy && (
                <Button
                  disabled
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                    color: 'white !important',
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: 'white !important',
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                  <Box sx={{ paddingLeft: '5px' }}>Saving</Box>
                </Button>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
}

export default React.memo(VoidThePackModal);
