import React, { useContext, useState } from 'react';

import Button from 'components/button';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import Papa from 'papaparse';
import {
  createListImportItemDetail,
  updateImportItemDetail,
} from 'services/api/inventory/inventory-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import * as XLSX from 'xlsx';

import AddIcon from '@mui/icons-material/Add';
import { Modal, Box, Link, Typography, CircularProgress } from '@mui/material';
import { blue } from '@mui/material/colors';

import { MUIContainer, MUIContent } from '../editreceiptmodal';

interface AddFileProps {
  callBack?: (x: any) => void;
  csvTemplate?: (e: React.SyntheticEvent) => void;
  csvTemplateForUpdate?: (e: React.SyntheticEvent) => void;
}

type ImportedTitleAndComponent = {
  primaryInventoryUOM: number;
  useSecondaryInventoryUOM: boolean;
  status: number;
  description: string;
  sku: string;
};

export default React.memo(
  ({ callBack, csvTemplate, csvTemplateForUpdate }: AddFileProps) => {
    const {
      isFileInputModalOpen,
      onCloseInputFilenModal,
      isFileInputModalInventoryOpen,
      onCloseInputFileInventoryModal,
      isUpdateFileInputModalInventoryOpen,
      onCloseUpdateInputFileInventoryModal,
      updateLoading,
      loading,
    } = useContext(GlobalContext);
    // const [mySheetData, setMySheetData] = useState({});

    const { currentUser, currentLocationAndFacility } = useContext(AuthContext);

    const changeHandler = (event) => {
      // Passing file data (event.target.files[0]) to parse using Papa.parse
      // https://medium.com/how-to-react/how-to-parse-or-read-csv-files-in-reactjs-81e8ee4870b0
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        transformHeader(h) {
          return h.replace(/\s/g, '');
        },
        complete(results) {
          callBack(results.data);
        },
      });
      updateLoading(true);
    };

    const readExcelHandler = (event) => {
      const [file] = event.target.files;
      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });

        for (let i = 0; i < wb.SheetNames.length; i += 1) {
          const sheetName = wb.SheetNames[i];
          const workSheet = wb.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });

          let itemV2CreateModellist: ImportedTitleAndComponent[] = [];
          if (Array.isArray(jsonData)) {
            if (sheetName === 'Black Friday') {
              itemV2CreateModellist = jsonData
                .filter((data) => data !== '')
                .map((data) => ({
                  status: 1,
                  primaryInventoryUOM: 1,
                  useSecondaryInventoryUOM: false,
                  sku: data.at(4),
                  description: data.at(5),
                }));
            } else {
              itemV2CreateModellist = jsonData
                .filter((data) => data !== '')
                .map((data) => ({
                  status: 1,
                  primaryInventoryUOM: 1,
                  useSecondaryInventoryUOM: false,
                  sku: data.at(5),
                  description: data.at(7),
                }));
            }

            const Payload = {
              itemV2CreateModellist,
            };

            const response = createListImportItemDetail(
              sheetName,
              currentUser.Claim_CustomerId,
              Payload,
            )
              .then((r) => {
                if (r.status === 200) {
                  snackActions.success(
                    `Warehouse customer ${r.data} items has been successfully imported.`,
                  );
                  callBack(r.data);
                } else {
                  snackActions.error(`${r.data} `);
                }
              })
              .catch((error) => {
                snackActions.error(`${error} `);
                callBack(error);
              });
          }
        }
      };
      reader.readAsBinaryString(file);
    };

    const updateDataReadExcelHandler = (event) => {
      const [file] = event.target.files;
      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });

        wb.SheetNames.forEach((sheetName) => {
          const rowObject = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
          const response = updateImportItemDetail(
            JSON.parse(
              JSON.stringify(rowObject, (k, v) =>
                v && typeof v === 'object' ? v : `${v}`,
              ),
            ),
            sheetName,
            currentUser.Claim_CustomerId,
            currentLocationAndFacility.customerFacilityId,
          )
            .then((r) => {
              if (r.status === 200) {
                snackActions.success(
                  `Warehouse customer ${sheetName} items has been successfully updated.`,
                );
                callBack(r.data);
              } else {
                snackActions.error(`${r.data} `);
              }
            })
            .catch((error) => {
              snackActions.error(`${error} `);
              callBack(error);
            });
        });
      };
      reader.readAsBinaryString(file);
    };

    const handleCloseImportModal = () => {
      onCloseInputFilenModal();
      onCloseInputFileInventoryModal();
      onCloseUpdateInputFileInventoryModal();
    };

    return (
      <>
        <Modal
          open={isFileInputModalOpen}
          onClose={() => handleCloseImportModal()}
        >
          <MUIContainer sx={{ maxWidth: '500px !important' }}>
            <Typography
              className="modalTextHeader"
              variant="h6"
              fontWeight="bold"
            >
              Import Location
            </Typography>
            <MUIContent sx={{ padding: '10px 20px !important ' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                }}
              >
                <Typography variant="body2">
                  Use this import feature to add location in bulk using the CSV
                  upload
                </Typography>
              </Box>
              <Box>
                {
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link href="#" onClick={csvTemplate}>
                    CSV Template
                  </Link>
                }
              </Box>
              <Box>
                <Button
                  sx={{ width: '100%' }}
                  variant="contained"
                  size="large"
                  component="label"
                  fileInput
                >
                  {loading && (
                    <>
                      <CircularProgress sx={{ color: 'blue !important' }} />
                      <p> &nbsp; Please Wait</p>
                    </>
                  )}
                  {!loading && (
                    <>
                      <AddIcon />
                      <p> Upload a file</p>
                      <input
                        type="file"
                        name="file"
                        accept=".csv"
                        onChange={changeHandler}
                        hidden
                      />
                    </>
                  )}
                </Button>
              </Box>
            </MUIContent>
          </MUIContainer>
        </Modal>

        <Modal
          open={isFileInputModalInventoryOpen}
          onClose={() => handleCloseImportModal()}
        >
          <ModalBox>
            <ModalContent>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
              >
                <Typography variant="body2">
                  Use this import feature to add inventory in bulk using the CSV
                  upload
                </Typography>
              </Box>
              <Box>
                {
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link href="#" onClick={csvTemplate}>
                    CSV Template
                  </Link>
                }
              </Box>
              <Box>
                <Button
                  sx={{ width: '100%' }}
                  variant="contained"
                  size="large"
                  component="label"
                  fileInput
                >
                  <AddIcon />
                  Create inventory
                  <input
                    type="file"
                    name="file"
                    accept=".xlsx"
                    onChange={readExcelHandler}
                    hidden
                  />
                </Button>
              </Box>
            </ModalContent>
          </ModalBox>
        </Modal>

        <Modal
          open={isUpdateFileInputModalInventoryOpen}
          onClose={() => handleCloseImportModal()}
        >
          <ModalBox>
            <ModalContent>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
              >
                <Typography variant="body2">
                  Use this import feature to update your existing inventory
                  quantity and location
                </Typography>
              </Box>
              <Box>
                {
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link href="#" onClick={csvTemplateForUpdate}>
                    CSV Template
                  </Link>
                }
              </Box>
              <Box>
                <Button
                  sx={{ width: '100%' }}
                  variant="contained"
                  size="large"
                  component="label"
                  fileInput
                >
                  <AddIcon /> Update inventory
                  <input
                    type="file"
                    name="file"
                    accept=".xlsx"
                    onChange={updateDataReadExcelHandler}
                    hidden
                  />
                </Button>
              </Box>
            </ModalContent>
          </ModalBox>
        </Modal>
      </>
    );
  },
);
