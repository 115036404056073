import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import { SnackbarConfig } from 'config/snackbar.js';
import { SnackbarProvider } from 'notistack';
import AuthProvider from 'store/contexts/AuthContext';
import ColorModeProvider from 'store/contexts/ColorModeContext';
import GlobalProvider from 'store/contexts/GlobalContext';
import ThemeProvider from 'store/contexts/ThemeContext';

import { CssBaseline } from '@mui/material/';
import { LicenseInfo } from '@mui/x-data-grid-premium';

import AxiosInterceptor from './infra/httpClient/axiosinterceptor.js';
import LoadingModal from './pages/shared/loadingModal';
import MainRoutes from './routes';

const queryClient = new QueryClient();

LicenseInfo.setLicenseKey(process.env.REACT_APP_LICENSE_KEY);

(function (c: Window, l: Document, a: string, r: string, i: string) {
  // Define a closure to handle clarity function and queue
  function initializeClarity() {
    const clarityFunction = function () {
      // eslint-disable-next-line prefer-rest-params
      (clarityFunction.q = clarityFunction.q || []).push(arguments);
    };
    clarityFunction.q = [];
    return clarityFunction;
  }

  // eslint-disable-next-line no-param-reassign
  c[a] = c[a] || initializeClarity();

  // Create a script element
  const t: HTMLScriptElement = l.createElement('script');
  t.async = true;
  t.src = `https://www.clarity.ms/tag/${i}`;

  // eslint-disable-next-line prefer-const
  let y: HTMLScriptElement | null = l.getElementsByTagName('script')[0];
  if (y && y.parentNode) {
    y.parentNode.insertBefore(t, y);
  }
})(window, document, 'clarity', 'script', 'mrv3ir6742');

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <SnackbarConfig />
      <AuthProvider>
        <GlobalProvider>
          <AxiosInterceptor>
            <ColorModeProvider>
              <QueryClientProvider client={queryClient}>
                <Router>
                  <ThemeProvider>
                    <CssBaseline />
                    <MainRoutes />
                    <LoadingModal />
                  </ThemeProvider>
                </Router>
              </QueryClientProvider>
            </ColorModeProvider>
          </AxiosInterceptor>
        </GlobalProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
}

export default App;
