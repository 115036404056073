import React, { useContext, useEffect, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { UpdateExtensivCartSource } from 'services/api/integrations/extensiv/extensive.api';
import { getOrderSourceList } from 'services/api/salesorders/salesorders-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Grid as MUIGrid,
  Autocomplete,
} from '@mui/material';

type AutocompleteBase = {
  label?: string;
  value?: number;
};

export default React.memo(() => {
  const {
    isExtensivEditOrderRulesModalOpen,
    setIsExtensivEditOrderRulesModalOpen,
  } = useContext(GlobalContext);
  const { currentUser } = useContext(AuthContext);

  const { queryString, extensivIntegrationProps, loadExtensivIntegrations } =
    useWarehouseCustomerContext();

  const [optionOrderSource, setOptionOrderSource] = useState([]);
  const [orderSourceValue, setOrderSourceValue] =
    useState<AutocompleteBase>(null);

  const submitForm = async () => {
    try {
      const payload = {
        extensivCartConfigurationId:
          extensivIntegrationProps.extensivCartConfigurationId,
        orderSourceId: orderSourceValue.value,
      };
      await UpdateExtensivCartSource(payload).then(() => {
        loadExtensivIntegrations().then(() => {
          setIsExtensivEditOrderRulesModalOpen(false);
          snackActions.success('Order Source updated successfully');
        });
      });
    } catch (error) {
      snackActions.error(error);
    }
  };
  useEffect(() => {
    const optOrderSources = getOrderSourceList(
      queryString.id ?? currentUser.Claim_WarehouseCustomerId,
    );

    optOrderSources
      .then((opt) => {
        setOptionOrderSource(
          opt.map((c: any) => ({
            label: c.text,
            value: c.value,
          })),
        );
      })
      .catch();
  }, [isExtensivEditOrderRulesModalOpen]);

  useEffect(() => {
    const getValue = optionOrderSource.find(
      (o) => o.value === extensivIntegrationProps?.orderSource,
    );

    if (getValue) {
      setOrderSourceValue((e) => ({
        ...e,
        value: getValue?.value,
        label: getValue?.label,
      }));
    } else {
      setOrderSourceValue(null);
    }
  }, [optionOrderSource]);

  return (
    <Modal open={isExtensivEditOrderRulesModalOpen}>
      <MUIContainer sx={{ maxWidth: '400px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Update {extensivIntegrationProps?.name} Source
        </Typography>

        <MUIContent sx={{ padding: '10px 20px !important ' }}>
          <Box autoComplete="off" component="form">
            <Autocomplete
              onChange={(event: any, newValue: AutocompleteBase) => {
                if (newValue !== null) {
                  setOrderSourceValue(newValue);
                } else {
                  setOrderSourceValue(null);
                }
              }}
              id="controllable-states"
              options={optionOrderSource}
              getOptionLabel={(option: AutocompleteBase) => option.label}
              value={orderSourceValue}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected?.value
              }
              renderOption={(props, option) => (
                <li data-name="orderSourceId" {...props} key={option.value}>
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  autoComplete="off"
                  id="outlined-required"
                  size="small"
                  sx={{ width: '100%' }}
                  label="Source"
                />
              )}
            />
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                onClick={() => {
                  setIsExtensivEditOrderRulesModalOpen(false);
                }}
              >
                CANCEL
              </Button>

              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={() => submitForm()}
              >
                SAVE
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
