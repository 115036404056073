import React, { useContext, useEffect, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import {
  createCarrierWitCustomService,
  getCarrierAccountByCarrierId,
} from 'services/api/integrations/easypost/easypost.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {
  Box,
  Modal,
  Typography,
  Grid as MUIGrid,
  Button,
  TextField,
} from '@mui/material';

export default React.memo(() => {
  const {
    carrierCustomId,
    setCarrierCustomId,
    setIsLoadCarrierTab,
    isAddNewCarrierModalOpen,
    setIsAddNewCarrierModalOpen,
  } = useContext(GlobalContext);

  const { currentUser } = useContext(AuthContext);

  const initialFormState = {
    carrierId: null,
    carrierName: '',
  };

  const [carrierForm, setCarrierForm] = useState(initialFormState);
  const [carrierServices, setCarrierServices] = useState([]);

  const initialFormError: any = {
    name: '',
    carrierName: '',
  };

  const [carrierServicesError, setCarrierServicesError] = useState<any>([
    initialFormError,
  ]);

  const handleFormChangeServiceCarrier = (arrayInput, index, event) => {
    event.preventDefault();
    const datas = [...arrayInput];
    const data = { ...datas[index] };
    data[event.target.name] = event.target.value;
    datas[index] = data;
    setCarrierServices(datas);
  };

  const addFieldServiceCarrier = () => {
    const newfield = { carrierServiceId: null, name: '' };

    setCarrierServices([...carrierServices, newfield]);
    setCarrierServicesError([...carrierServices, newfield]);
  };

  const removeFieldServiceCarrier = (index) => {
    const data = [...carrierServices];
    data.splice(index, 1);
    setCarrierServices(data);
    setCarrierServicesError(data);
  };

  const handleOnCloseModal = async (e: any) => {
    e.preventDefault();

    setCarrierForm(initialFormState);
    setCarrierServices([]);
    setCarrierCustomId(null);
    setIsAddNewCarrierModalOpen(false);
  };

  const handleOnCreateCarrierAndService = async (e: any) => {
    e.preventDefault();

    const temp: any = {};

    temp.carrierName = carrierForm.carrierName ? '' : 'This field is required';
    temp.name =
      carrierServices &&
      carrierServices.length > 0 &&
      carrierServices.some((x) => x.name !== '')
        ? ''
        : 'Please add at least one service';

    setCarrierServicesError({
      ...temp,
    });

    if (!Object.values(temp).every((x) => x === '')) {
      return;
    }

    try {
      const payload = {
        CustomerId: currentUser.Claim_CustomerId,
        CarrierId: carrierForm?.carrierId ?? null,
        CarrierName: carrierForm.carrierName,
        CarrierServicesAndFeatures: carrierServices.map((x) => ({
          CarrierServiceId: x?.carrierServiceId,
          Name: x?.name,
        })),
      };

      await createCarrierWitCustomService(payload);
      snackActions.success(`Carrier has been saved successfully.`);
      setIsLoadCarrierTab(true);
      handleOnCloseModal(e);
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (isAddNewCarrierModalOpen && carrierCustomId) {
        await getCarrierAccountByCarrierId(carrierCustomId)
          .then((res) => {
            setCarrierForm((prev) => ({
              ...prev,
              carrierId: res.carrierId,
              carrierName: res.carrierName,
            }));

            setCarrierServices(
              res.customServices.map((x) => ({
                carrierServiceId: x?.carrierServiceId,
                name: x?.name,
              })),
            );
          })
          .catch((err) => {
            snackActions.error(err);
          });
      }
    }
    fetchData();
  }, [isAddNewCarrierModalOpen]);

  return (
    <Modal open={isAddNewCarrierModalOpen}>
      <MUIContainer sx={{ width: '30% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Add New Carrier
        </Typography>
        <MUIContent sx={{ padding: '10px 10px !important ' }}>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ marginBottom: 1 }}
            >
              <MUIGrid item xs={12}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Carrier Name"
                  value={carrierForm?.carrierName}
                  size="medium"
                  onChange={(e) => {
                    setCarrierForm((prev) => ({
                      ...prev,
                      carrierName: e.target.value,
                    }));
                  }}
                  required
                  {...(carrierServicesError?.carrierName !== undefined && {
                    error: carrierServicesError?.carrierName,
                    helperText: carrierServicesError?.carrierName,
                  })}
                />
              </MUIGrid>

              <MUIGrid item xs={12}>
                <Button onClick={() => addFieldServiceCarrier()}>
                  <Typography
                    variant="body2"
                    sx={{ textTransform: 'capitalize' }}
                  >
                    Add Service
                  </Typography>{' '}
                  &nbsp;
                  <ControlPointIcon />
                </Button>
              </MUIGrid>
              {carrierServicesError?.name !== undefined && (
                <MUIGrid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{ textTransform: 'capitalize', color: 'red' }}
                  >
                    {carrierServicesError?.name}
                  </Typography>
                </MUIGrid>
              )}
              {carrierServices &&
                carrierServices.map((input, index) => (
                  <>
                    <MUIGrid item xs={10}>
                      <TextField
                        label="Service"
                        size="small"
                        sx={{
                          width: '100%',
                          backgroundColor: '#ffffff !important',
                        }}
                        name="name"
                        value={input.name}
                        onChange={(event) =>
                          handleFormChangeServiceCarrier(
                            carrierServices,
                            index,
                            event,
                          )
                        }
                        required={index === 0}
                      />
                    </MUIGrid>
                    <MUIGrid item xs={2}>
                      <Button
                        sx={{ color: 'red', paddingLeft: '10px' }}
                        onClick={() => removeFieldServiceCarrier(index)}
                      >
                        <b>X</b>
                      </Button>
                    </MUIGrid>
                  </>
                ))}
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={(e) => {
                  handleOnCloseModal(e);
                }}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={(e) => {
                  handleOnCreateCarrierAndService(e);
                }}
              >
                Save
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
