import React, { useContext } from 'react';

import { GlobalContext } from 'store/contexts/GlobalContext';

import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default React.memo(() => {
  const { onLoading } = useContext(GlobalContext);

  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={onLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
});
