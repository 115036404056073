import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import React from 'react';
import { PolarArea } from 'react-chartjs-2';
import { Box } from '@mui/material';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

export interface IPolarProps {
  caption: string;
  labels: string[];
  colors: string[];
  data: number[];
}

export const PolarChart = React.memo(
  ({ caption, data, labels, colors }: IPolarProps) => {
    const chartOptions = {
      responsive: true,
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      legend: {
        position: 'bottom',
      },
    };

    const charData = {
      labels,
      datasets: [
        {
          label: caption,
          data,
          backgroundColor: colors,
          borderWidth: 1,
        },
      ],
    };
    return (
      <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
        <PolarArea data={charData} options={chartOptions} />
      </div>
    );
  },
);
