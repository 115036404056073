import React, { useState } from 'react';

import {
  OrderChecklistCreateModel,
  OrderChecklistEditModel,
} from 'common/models/orders/orderchecklists';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import { useSalesOrderContext } from 'pages/sales/salesorder/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import {
  createOrderChecklist,
  editOrderChecklist,
} from 'services/api/salesorders/salesorders-new.api';

import {
  Modal,
  Box,
  Typography,
  Button,
  Grid as MUIGrid,
  CircularProgress,
} from '@mui/material';

function ChecklistModal() {
  const {
    salesOrderForm,
    viewLoadData,
    checklistModal: model,
    setChecklistModal: setModel,
  } = useSalesOrderContext();

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const validateForm = () => {
    let { nameError } = model;

    if (model?.name === null || model?.name === '') {
      nameError = 'This field is required';
    } else {
      nameError = '';
    }

    setModel((prevState) => ({
      ...prevState,
      nameError,
    }));

    if (nameError !== '') {
      return false;
    }

    return true;
  };

  const handleSave = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();

    if (!validateForm()) {
      snackActions.error('Please complete required fields');
    } else {
      setIsBusy(true);

      if (model.orderChecklistId === null) {
        const PAYLOAD: OrderChecklistCreateModel = {
          orderId: salesOrderForm.orderId,
          name: model.name,
        };

        createOrderChecklist(PAYLOAD)
          .then(() => {
            viewLoadData();
            setModel(null);
          })
          .catch((err) => {
            snackActions.error(err);
          })
          .finally(() => {
            setIsBusy(false);
          });
      } else {
        const PAYLOAD: OrderChecklistEditModel = {
          orderChecklistId: model.orderChecklistId,
          name: model.name,
        };

        editOrderChecklist(PAYLOAD)
          .then(() => {
            viewLoadData();
            setModel(null);
          })
          .catch((err) => {
            snackActions.error(err);
          })
          .finally(() => {
            setIsBusy(false);
          });
      }
    }
  };

  return (
    <Modal open={model !== null}>
      <MUIContainer sx={{ maxWidth: '600px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          {model?.orderChecklistId ? 'Edit ' : 'New '}
          Checklist
        </Typography>

        <MUIContent>
          <Input
            required
            placeholder="Name"
            size="small"
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            value={model?.name}
            disabled={isBusy}
            onChange={(e) => {
              setModel((prev) => ({
                ...prev,
                name: e.target.value,
              }));
            }}
            type="text"
            InputProps={{
              autoComplete: 'off',
            }}
            error={model?.nameError}
          />
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={isBusy ? 6 : 4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                disabled={isBusy}
                onClick={() => {
                  setModel(null);
                  setIsBusy(false);
                }}
              >
                CANCEL
              </Button>

              {!isBusy && (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => handleSave(e)}
                >
                  SAVE
                </Button>
              )}
              {isBusy && (
                <Button
                  disabled
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                    color: 'white !important',
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: 'white !important',
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                  <Box sx={{ paddingLeft: '5px' }}>Saving</Box>
                </Button>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
}

export default React.memo(ChecklistModal);
