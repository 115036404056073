import React, { useContext, useEffect, useState } from 'react';

import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { shopifyInventorySyncUp } from 'services/api/integrations/integrations.api';
import { GlobalContext } from 'store/contexts/GlobalContext';
import ConfirmationModal from 'pages/shared/confirmationmodal';

import { Box, Modal, Typography, Button, Grid as MUIGrid } from '@mui/material';

import { useWarehouseCustomerContext } from '..';

export default React.memo(() => {
  const {
    selectedIntegrationId,
    setSelectedIntegrationId,
    isSyncInventoryModalOpen,
    setIsSyncInventoryModalOpen,
  } = useWarehouseCustomerContext();

  const { onOpenItemPictureModal, onOpenConfirmationModal } =
    useContext(GlobalContext);

  const handleCloseModal = () => {
    setIsSyncInventoryModalOpen(false);
    setSelectedIntegrationId(null);
  };

  const syncShopifyInventory = async (fullInventory) => {
    try {
      const response = await shopifyInventorySyncUp(
        selectedIntegrationId,
        fullInventory,
      );
      snackActions.success(
        'Your inventory sync has been queued, you will be notified when the task is completed.',
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal open={isSyncInventoryModalOpen} onClose={() => handleCloseModal()}>
      <ModalBox>
        <ModalContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Sync Inventory
          </Typography>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={6}>
              <Button
                variant="contained"
                size="medium"
                onClick={() => onOpenConfirmationModal(true)}
                sx={{ whiteSpace: 'nowrap' }}
              >
                Full Inventory
              </Button>
            </MUIGrid>
            <MUIGrid item xs={6}>
              <Button
                variant="contained"
                size="medium"
                onClick={() => syncShopifyInventory(false)}
                sx={{ whiteSpace: 'nowrap' }}
              >
                New Inventory
              </Button>
            </MUIGrid>
          </MUIGrid>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: '8px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="text"
              size="medium"
              onClick={() => handleCloseModal()}
            >
              Cancel
            </Button>
          </Box>
          <ConfirmationModal
            dialogText="This will overwrite all item quantities in WMS from Shopify. Are you sure you want to continue?"
            callBack={() => {
              syncShopifyInventory(true);
            }}
          />
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
