import React, { useContext, useState, useEffect } from 'react';

import DatePickerInput from 'components/datepicker';
import { REPORT_URL } from 'config/constants';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { reportUrlVariable } from 'store/reporturl';

import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  Grid as MUIGrid,
  Autocomplete,
} from '@mui/material';

import { MUIContainer, MUIContent } from '../editreceiptmodal';

export default React.memo(() => {
  const { isBillingReportModalOpen, onCloseBillingReportModal } =
    useContext(GlobalContext);

  const { currentUser } = useContext(AuthContext);
  const query = useQuery();
  const { form } = useWarehouseCustomerContext();

  interface AutoCompleteOptionType {
    id: number;
    label: string;
  }

  const monthsOfYear: AutoCompleteOptionType[] = [
    { label: 'January', id: 0 },
    { label: 'February', id: 1 },
    { label: 'March', id: 2 },
    { label: 'April', id: 3 },
    { label: 'May', id: 4 },
    { label: 'June', id: 5 },
    { label: 'July', id: 6 },
    { label: 'August', id: 7 },
    { label: 'September', id: 8 },
    { label: 'October', id: 9 },
    { label: 'November', id: 10 },
    { label: 'December', id: 11 },
  ];
  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }
  function getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0);
  }
  const date = new Date();
  const firstDay = getFirstDayOfMonth(date.getFullYear(), date.getMonth());
  const lastDayCurrentMonth = getLastDayOfMonth(
    date.getFullYear(),
    date.getMonth(),
  );
  const [selectedMonth, setSelectedMonth] = useState<AutoCompleteOptionType>(
    monthsOfYear.filter((x) => x.id === date.getMonth())[0],
  );
  const initialFormBillingReport = {
    customerId: 0,
    warehouseCustomerId: 0,
    from: moment(firstDay).format('MM/DD/yyyy'),
    to: moment(lastDayCurrentMonth).format('MM/DD/yyyy'),
  };

  const initialFormBillingReportError = {
    customerId: 0,
    warehouseCustomerId: 0,
    from: '',
    to: '',
  };

  const [billingReportModel, setBillingReportModel] = useState<any>(
    initialFormBillingReport,
  );

  const [billingReportFormErrors, setBillingReportFormErrors] = useState<any>(
    initialFormBillingReportError,
  );

  const BillingReportValidation = () => {
    const temp: any = {};

    if (billingReportModel.to) {
      if (!billingReportModel.from) {
        temp.from = 'Please input Date From.';
      }
    }

    if (billingReportModel.from) {
      if (!billingReportModel.to) {
        temp.to = 'Please input Date To.';
      }
    }

    if (billingReportModel.from && billingReportModel.to) {
      if (
        moment(billingReportModel.from).format('MM-DD-yyyy') >
        moment(billingReportModel.to).format('MM-DD-yyyy')
      ) {
        temp.to = 'Date From is greater than Date To.';
        setBillingReportModel((prev) => ({
          ...prev,
          to: '',
        }));
      } else {
        temp.to = '';
      }
    }

    setBillingReportFormErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const OnClickReportViewer = async () => {
    let UrlStringSummary = '';

    if (!BillingReportValidation()) {
      return;
    }

    if (billingReportModel.from && billingReportModel.to) {
      UrlStringSummary = `${reportUrlVariable(
        REPORT_URL,
      )}/reports/billing-report?customerIdString=${btoa(
        currentUser.Claim_CustomerId,
      )}&warehouseCustomerIdString=${btoa(query.get('id'))}&from=${moment(
        billingReportModel.from,
      ).format('MM-DD-yyyy')}&to=${moment(billingReportModel.to).format(
        'MM-DD-yyyy',
      )}`;
    } else {
      UrlStringSummary = `${reportUrlVariable(
        REPORT_URL,
      )}/reports/billing-report?customerIdString=${btoa(
        currentUser.Claim_CustomerId,
      )}&warehouseCustomerIdString=${btoa(query.get('id'))}`;
    }

    window.open(UrlStringSummary, '_blank', 'noreferrer');
  };

  const lastWeek = (startOfWeek: number): { start: Date; end: Date } | null => {
    if (startOfWeek < 0 || startOfWeek > 6) {
      return null;
    }

    const today = moment();
    const lastDayOfWeek = today.date() - today.day() + startOfWeek;

    const startDateLastWeek = moment(today)
      .date(lastDayOfWeek - 7)
      .toDate();

    const endDateLastWeek = moment(today)
      .date(lastDayOfWeek - 1)
      .toDate();

    return { start: startDateLastWeek, end: endDateLastWeek };
  };

  const setBillingReportDates = (value) => {
    if (form.billingFrequency === 3) {
      setBillingReportModel((prev) => ({
        ...prev,
        from: getFirstDayOfMonth(date.getFullYear(), value),
        to: getLastDayOfMonth(date.getFullYear(), value),
      }));
    }
  };

  useEffect(() => {
    if (isBillingReportModalOpen) {
      if (form.billingFrequency === 2) {
        setBillingReportModel((prev) => ({
          ...prev,
          from: lastWeek(form.startOfWeek).start,
          to: lastWeek(form.startOfWeek).end,
        }));
      }
    }
  }, [isBillingReportModalOpen, form]);

  return (
    <Modal open={isBillingReportModalOpen}>
      <MUIContainer sx={{ maxWidth: '600px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Invoice
        </Typography>

        <MUIContent>
          <Box autoComplete="off" component="form" noValidate>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {form.billingFrequency !== 3 && (
                <>
                  <MUIGrid item xs={12} sm={12}>
                    <DatePickerInput
                      label="From Date"
                      inputFormat="MM/dd/yyyy"
                      value={billingReportModel.from}
                      onChange={(e) =>
                        setBillingReportModel((prev) => ({
                          ...prev,
                          from: e,
                        }))
                      }
                      renderInput={(params) => (
                        <TextField
                          disabled
                          {...params}
                          error={billingReportFormErrors.from}
                          helperText={billingReportFormErrors.from}
                          size="small"
                        />
                      )}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={12} sm={12}>
                    <DatePickerInput
                      label="To Date"
                      inputFormat="MM/dd/yyyy"
                      value={billingReportModel.to}
                      onChange={(e) =>
                        setBillingReportModel((prev) => ({
                          ...prev,
                          to: e,
                        }))
                      }
                      renderInput={(params) => (
                        <TextField
                          disabled
                          {...params}
                          error={billingReportFormErrors.to}
                          helperText={billingReportFormErrors.to}
                          size="small"
                        />
                      )}
                    />
                  </MUIGrid>
                </>
              )}
              {form.billingFrequency === 3 && (
                <MUIGrid item xs={12} sm={12}>
                  <Autocomplete
                    disableClearable
                    sx={{ width: '100%' }}
                    options={monthsOfYear}
                    getOptionLabel={(option: AutoCompleteOptionType) =>
                      option.label ? option.label : ''
                    }
                    isOptionEqualToValue={(
                      option: AutoCompleteOptionType,
                      selected: any,
                    ) => option.id === selected.value}
                    size="small"
                    value={selectedMonth}
                    onChange={(
                      event: any,
                      value: AutoCompleteOptionType | null,
                    ) => {
                      setSelectedMonth(value);

                      if (value !== null) {
                        setBillingReportDates(value.id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        autoComplete="off"
                        sx={{
                          '& .MuiInputBase-root': {
                            background: 'white',
                          },
                        }}
                        label="Month"
                      />
                    )}
                  />
                </MUIGrid>
              )}
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => {
                  onCloseBillingReportModal();
                }}
                variant="outlined"
                size="large"
                sx={{ padding: 1, width: '100%' }}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={(e) => {
                  OnClickReportViewer();
                }}
              >
                Generate
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
