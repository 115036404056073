import * as THREE from 'three';
import { itemsInformation } from './items.js';

export const createBin = (x, y, z, data) => {
  let binColor = 0x4682b4;

  if (data.items && data.items.length > 0 && data.items[0].uomColor) {
    binColor = data.items[0].uomColor.toLowerCase();
  }

  const binMaterial = new THREE.MeshStandardMaterial({ color: binColor });
  const binGeometry = new THREE.BoxGeometry(3, 3, 3);
  const bin = new THREE.Mesh(binGeometry, binMaterial);

  bin.position.set(x, y, z);
  bin.name = `BIN: ${data.name}`;
  bin.userData = {
    originalMaterial: binMaterial,
    selected: false,
    items: data.items,
    name: data.name,
  };

  return bin;
};
export const toggleBinSelection = (
  scene,
  camera,
  bin,
  currentlySelectedBin,
) => {
  const overlay = document.getElementById('bin-info-overlay');
  let currentBin = currentlySelectedBin;

  if (currentBin && currentBin !== bin) {
    currentBin.material = currentBin.userData.originalMaterial;
    currentBin.userData.selected = false;
  }

  let binSelected = bin;

  if (!bin.userData.selected) {
    binSelected.material = new THREE.MeshStandardMaterial({ color: 0xff0000 });
    binSelected.userData.selected = true;

    document.getElementById('bin-owner').innerText =
      `${binSelected.userData.name}`.toUpperCase();
    document.getElementById('bin-items').innerHTML = itemsInformation(
      binSelected.userData.items,
    );

    overlay.style.display = 'block';
    currentBin = binSelected;

    return currentBin;
  }

  document.getElementById('bin-items').innerText = '';
  document.getElementById('bin-owner').innerText = '';
  overlay.style.display = 'none';

  binSelected.material = binSelected.userData.originalMaterial;
  binSelected.userData.selected = false;
  binSelected = null;

  return null;
};
