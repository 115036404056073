import React, { ReactNode, useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { UserPermissionEnum } from 'common/enums';
import { snackActions } from 'config/snackbar.js';
import BinManagement from 'pages/bins';
import BinView from 'pages/bins/binview';
import Transfers from 'pages/bins/transfers';
import Activation from 'pages/customerportal';
import DashboardCustomer from 'pages/customerportal/dashboard';
import CycleCount from 'pages/cyclecount';
import CycleCountsView from 'pages/cyclecount/detail';
import Dashboard from 'pages/dashboard';
import DeviceManagement from 'pages/devices';
import DeviceView from 'pages/devices/deviceview';
import FacilityManagement from 'pages/facilities';
import Inventory from 'pages/inventory';
import InventoryItemDetails from 'pages/inventory/item/details';
import InventoryTempNewItem from 'pages/inventory/item/new';
import ItemView from 'pages/inventory/itemview';
import InventoryTempList from 'pages/inventory/list';
import LicensePlates from 'pages/licenseplates';
import NewLicensePlate from 'pages/licenseplates/createlicenseplate';
import LicensePlateAssignment from 'pages/licenseplates/licenseplateassignment';
import LocationBuilder from 'pages/location/builders';
import LocationModeler from 'pages/location/model/index.jsx';
import Login from 'pages/login';
import Lots from 'pages/lots';
import OrderCustomers from 'pages/ordercustomers';
import OrderCustomerDetails from 'pages/ordercustomers/ordercustomerdetails';
import WareHouseCustomer, {
  IntegrationsInterfacePageWithHeader,
  InvoiceInterfacePageWithHeader,
  ShippingAccountsInterfacePageWithHeader,
} from 'pages/ordercustomers/warehousecustomer';
import { CustomerType } from 'pages/ordercustomers/warehousecustomer/customer-rules-management';
import PackAndShipPickTicketDetails from 'pages/packandship';
import PackAndShipFindPickTicket from 'pages/packandship/findpickticket';
import PasswordReset from 'pages/passwordreset';
import Picking from 'pages/picking';
import PickingIssue from 'pages/picking/pickingissue';
import Printoutforms from 'pages/printoutforms';
import Formdetails from 'pages/printoutforms/formdetails';
import ProductionView from 'pages/production';
import ProductionJobDetails from 'pages/production/job-details';
import PutAway from 'pages/putaway';
import Receipts from 'pages/receiving';
import ReceiptItemView from 'pages/receiving/itemview';
import PurchaseOrders from 'pages/receiving/purchaseorders';
import PurchaseOrderDetails from 'pages/receiving/purchaseordersv2';
import ReceiptTempList from 'pages/receiving/receipts';
import ReceiptsTempSubpage from 'pages/receiving/receipts/subpage';
import Reports from 'pages/reports';
import ReportsOrderStatusViewer from 'pages/reports/viewer/order-status-report';
import ReturnsView from 'pages/returns';
import InvoiceOrderDetails from 'pages/sales/invoiceorderdetailsview';
import OrderProcessingRules from 'pages/sales/orderprocessingrules';
import SalesOrders from 'pages/sales/salesorder';
import SalesOrderView from 'pages/sales/salesorderview';
import OrdersView from 'pages/sales/view';
import Settings from 'pages/settings';
import CustomerManagement from 'pages/superadmin/customermanagement';
import CustomerView from 'pages/superadmin/customermanagement/customerview';
import Suppliers from 'pages/suppliers';
import SupplierDetail from 'pages/suppliers/supplierdetail';
import SupplierActivation from 'pages/suppliers/supplierportal';
import UserManagement from 'pages/users';
import { checkCustomerIsActive } from 'services/api/customer/customer.api';
import {
  IsUserNeedRefreshToken,
  UpdateIsUserNeedRefreshTokenToFalse,
} from 'services/api/user/users.api';
import { AuthContext } from 'store/contexts/AuthContext';

import KitchenSink from './pages/kitchensink';
import StyledKitchenSink from './pages/styledkitchensink';
import EmptyLayout from './styles/emptylayout';
import MainLayout from './styles/mainlayout';

interface RouteProps {
  element?: any;
  path?: string;
  superAdminPath?: boolean;
  adminPath?: boolean;
  inventoryViewerPath?: boolean;
  operatorPath?: boolean;
  warehouseCustomerAccountPath?: boolean;
  supplierAccountPath?: boolean;
  children?: ReactNode;
}

function ProtectedRoute({
  element,
  path,
  superAdminPath,
  adminPath,
  inventoryViewerPath,
  operatorPath,
  warehouseCustomerAccountPath,
  supplierAccountPath,
  children,
}: RouteProps) {
  const {
    isAuth,
    isSuperAdmin,
    isAdmin,
    isOpManager,
    isOperator,
    isInventoryViewer,
    isWarehouseCustomerAccount,
    isSupplierAccount,
  } = useContext(AuthContext);

  if (superAdminPath && !isSuperAdmin) {
    snackActions.warning('Not authorized to access this page.');
    return <Navigate to="/inventory" />;
  }

  if ((adminPath && isOperator) || (adminPath && isOpManager)) {
    snackActions.warning('You must be an admin to access this portal.');
    return <Navigate to="/login" />;
  }
  if (adminPath && !isAdmin) {
    snackActions.warning('You must be an admin to access this portal.');
    return <Navigate to="/login" />;
  }
  if (inventoryViewerPath && !isInventoryViewer) {
    snackActions.warning('You do not have permission to access this portal.');
    return <Navigate to="/login" />;
  }
  if (operatorPath && !isOperator) {
    snackActions.warning('You do not have permission to access this portal.');
    return <Navigate to="/login" />;
  }
  if (warehouseCustomerAccountPath && !isWarehouseCustomerAccount) {
    snackActions.warning('You do not have permission to access this portal.');
    return <Navigate to="/login" />;
  }
  if (supplierAccountPath && !isSupplierAccount) {
    snackActions.warning('You do not have permission to access this portal.');
    return <Navigate to="/login" />;
  }

  return isAuth ? (
    <Route path={path} element={element}>
      {children}
    </Route>
  ) : (
    <Navigate to="/login" />
  );
}

export default function MainRoutes() {
  const { roleSupplierPermission, logout, handleUserPermissionAllow } =
    useContext(AuthContext);
  const location = useLocation();
  let flag = false;

  useEffect(() => {
    const pathname = location.pathname.toLowerCase();
    if (
      pathname !== '/login' &&
      pathname !== '/activation' &&
      pathname !== '/password-reset' &&
      pathname !== '/supplieractivation'
    ) {
      if (!flag) {
        flag = true;
        checkCustomerIsActive()
          .catch((err) => {
            if (err !== undefined) {
              snackActions.error(err);
              logout();
            }
          })
          .finally(() => {
            flag = false;
          });
      }

      IsUserNeedRefreshToken().then((isNeedRefreshToken: boolean) => {
        if (isNeedRefreshToken) {
          UpdateIsUserNeedRefreshTokenToFalse().then(() => {
            logout();
          });
        }
      });
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/demo" element={<MainLayout />}>
        <Route path="/kitchen" element={<KitchenSink />} />
        <Route path="/styled-kitchen" element={<StyledKitchenSink />} />
      </Route>

      <ProtectedRoute adminPath element={<MainLayout />}>
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/inventory/temp-list" element={<InventoryTempList />} />
        <Route
          path="/inventory/item-details"
          element={<InventoryItemDetails />}
        />
        <Route
          path="/inventory/temp-new-item"
          element={<InventoryTempNewItem />}
        />
        <Route path="/inventory/item/:itemSKU" element={<ItemView />} />
        <Route path="/inventory/suppliers" element={<Suppliers />} />
        <Route path="/suppliers" element={<Suppliers />} />
        <Route
          path="/inventory/supplier-details"
          element={<SupplierDetail />}
        />

        <Route path="/locations" element={<BinManagement />} />
        <Route path="/locations/location/:binName" element={<BinView />} />
        <Route path="/locations/transfer-queue" element={<Transfers />} />
        <Route path="/locations/builder" element={<LocationBuilder />} />
        <Route path="/locations/model" element={<LocationModeler />} />
        {/* <Route path="/orders" element={<Sales />} /> */}
        <Route path="/orders" element={<OrdersView />} />
        <Route path="/returns" element={<ReturnsView />} />
        <Route path="/orders/sales-orders" element={<SalesOrders />} />
        <Route
          path="/orders/order-processing-rules"
          element={
            <OrderProcessingRules customerType={CustomerType.Customer} />
          }
        />

        <Route
          path="/order/invoice-details"
          element={<InvoiceOrderDetails />}
        />

        <Route
          path="/orders/order/:salesOrderId"
          element={<SalesOrderView />}
        />
        <Route path="/customers" element={<OrderCustomers />} />
        <Route
          path="/customers/warehouseCustomer"
          element={<WareHouseCustomer />}
        />
        <Route path="/put-away" element={<PutAway />} />
        <Route
          path="/customers/:customerId"
          element={<OrderCustomerDetails />}
        />
        <Route path="/receiving" element={<Receipts />} />
        <Route
          path="/receiving/item/:purchaseOrderNo"
          element={<ReceiptItemView />}
        />

        <Route
          path="/receiving/purchase-orders"
          element={<PurchaseOrderDetails />}
        />
        <Route
          path="/receiving/purchase-ordersold"
          element={<PurchaseOrders />}
        />
        <Route path="/receiving/receipts" element={<ReceiptTempList />} />
        <Route
          path="/receiving/receipts/subpage"
          element={<ReceiptsTempSubpage />}
        />
        <Route path="/license-plates" element={<LicensePlates />} />
        <Route path="/license-plates/new" element={<NewLicensePlate />} />

        <Route path="/facilities" element={<FacilityManagement />} />
        <Route path="/devices" element={<DeviceManagement />} />
        <Route path="/users" element={<UserManagement />} />
        <Route path="/devices/device/:deviceId" element={<DeviceView />} />
        <Route path="/receiving/:poNo" element={<ReceiptItemView />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/picking" element={<Picking />} />
        <Route path="/picking/issue" element={<PickingIssue />} />
        <Navigate to="/inventory" />
        <Route path="/production" element={<ProductionView />} />
        <Route
          path="/production/job-details"
          element={<ProductionJobDetails />}
        />
        <Route
          path="/pack-and-ship/find-pick-ticket"
          element={<PackAndShipFindPickTicket />}
        />
        <Route
          path="/pack-and-ship/pick-ticket"
          element={<PackAndShipPickTicketDetails />}
        />

        <Route path="/printoutforms" element={<Printoutforms />} />
        <Route path="/printoutforms/formdetails" element={<Formdetails />} />

        <Route path="/reports" element={<Reports />} />
        <Route
          path="/reports/order-status-report"
          element={<ReportsOrderStatusViewer />}
        />
        <Route path="/cycle-count" element={<CycleCount />} />
        <Route path="/cycle-count/detail" element={<CycleCountsView />} />

        <Route path="/lots" element={<Lots />} />
      </ProtectedRoute>

      <ProtectedRoute superAdminPath element={<MainLayout />}>
        <Route path="/customer-management" element={<CustomerManagement />} />
        <Route
          path="/customer-management/customer/:customerId"
          element={<CustomerView />}
        />
      </ProtectedRoute>

      <ProtectedRoute inventoryViewerPath element={<MainLayout />}>
        <Route path="/customer-inventory" element={<Inventory />} />
        <Route
          path="/customer-inventory/item/:itemSKU"
          element={<ItemView />}
        />
        <Route path="/customer-inventory/suppliers" element={<Suppliers />} />
        <Navigate to="/customer-inventory" />
      </ProtectedRoute>

      <ProtectedRoute operatorPath element={<MainLayout />}>
        <Route
          path="/license-plates/license-plates-assignment"
          element={<LicensePlateAssignment />}
        />
        <Navigate to="/license-plates/license-plates-assignment" />
      </ProtectedRoute>

      {/* -------------------------------- warehouseCustomer ------------------------------------- */}
      <ProtectedRoute warehouseCustomerAccountPath element={<MainLayout />}>
        {handleUserPermissionAllow(UserPermissionEnum.Dashboard_View) && (
          <Route path="/warehouse-customer" element={<DashboardCustomer />} />
        )}

        {handleUserPermissionAllow(
          UserPermissionEnum.Client_Integration_View,
        ) && (
          <Route
            path="/warehouse-customer-integrations"
            element={<IntegrationsInterfacePageWithHeader />}
          />
        )}

        {handleUserPermissionAllow(
          UserPermissionEnum.Client_ShippingAccounts_View,
        ) && (
          <Route
            path="/warehouse-customer-shipping-accounts"
            element={<ShippingAccountsInterfacePageWithHeader />}
          />
        )}

        {handleUserPermissionAllow(UserPermissionEnum.Invoices_View) && (
          <Route
            path="/warehouse-customer-invoices"
            element={<InvoiceInterfacePageWithHeader />}
          />
        )}

        {handleUserPermissionAllow(UserPermissionEnum.Orders_View) && (
          <>
            <Route path="/warehouse-customer-orders" element={<OrdersView />} />
            <Route
              path="/warehouse-customer-orders/sales-orders"
              element={<SalesOrders />}
            />
            <Route
              path="/warehouse-customer-orders/order/:salesOrderId"
              element={<SalesOrderView />}
            />
          </>
        )}

        {handleUserPermissionAllow(UserPermissionEnum.PurchaseOrder_View) && (
          <>
            <Route
              path="/warehouse-customer-receiving"
              element={<Receipts />}
            />
            <Route
              path="/warehouse-customer-receiving/purchase-orders"
              element={<PurchaseOrderDetails />}
            />
          </>
        )}

        {handleUserPermissionAllow(UserPermissionEnum.Receiving_View) && (
          <>
            <Route
              path="/warehouse-customer-receiving/receipts"
              element={<ReceiptTempList />}
            />
            <Route
              path="/warehouse-customer-receiving/receipts/subpage"
              element={<ReceiptsTempSubpage />}
            />
          </>
        )}
        {handleUserPermissionAllow(UserPermissionEnum.Inventory_View) && (
          <>
            <Route
              path="/warehouse-customer-inventory"
              element={<Inventory />}
            />
            <Route
              path="/warehouse-customer-inventory/item-details"
              element={<InventoryItemDetails />}
            />
            <Route
              path="/warehouse-customer-inventory/item/:itemSKU"
              element={<ItemView />}
            />
          </>
        )}

        <Route path="/warehouse-customer-lots" element={<Lots />} />

        {handleUserPermissionAllow(UserPermissionEnum.Reports_View) && (
          <Route path="/warehouse-customer-reports" element={<Reports />} />
        )}
      </ProtectedRoute>

      {/* -------------------------------- warehouseCustomer ------------------------------------- */}
      <ProtectedRoute supplierAccountPath element={<MainLayout />}>
        {roleSupplierPermission.PO && (
          <>
            <Route path="/supplier-purchase-order" element={<Receipts />} />
            <Route
              path="/supplier-purchase-order-detail"
              element={<PurchaseOrderDetails />}
            />
          </>
          // put the purchase order details here after merge on develop -------------------
        )}
      </ProtectedRoute>

      <Route element={<EmptyLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/login/:customer" element={<Login />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/Activation" element={<Activation />} />
        <Route path="/SupplierActivation" element={<SupplierActivation />} />
        <Navigate to="/login" />
      </Route>
    </Routes>
  );
}
