export const autoCompleteTimeStamp = () => {
  const isPerformanceSupported =
    window.performance &&
    window.performance.now &&
    window.performance.timing &&
    window.performance.timing.navigationStart;

  const timeStampInMs = isPerformanceSupported
    ? window.performance.now() + window.performance.timing.navigationStart
    : Date.now();
  return timeStampInMs;
};
