export const GetISOIdString = () => {
  const ISO = new Date()
    .toISOString()
    .replaceAll(' ', '')
    .replaceAll(':', '')
    .replaceAll('.', '')
    .replaceAll('-', '');
  const MLS = new Date().getMilliseconds() * 3;
  return `${MLS}${ISO}`;
};

export const GetRandomString = (len: number) =>
  Math.random()
    .toString(36)
    .substring(2, len + 2);

export const GetLastring = (word: string, len: number) =>
  word.substring(word.length >= len ? word.length - len : word.length);

export const GetEmailFormatValidation =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
