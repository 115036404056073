const UserRoles = {
  1: 'Super Admin',
  2: 'Admin',
  3: 'Ops Manager',
  4: 'Operator',
  5: 'Viewer',
  6: 'Customer Admin',
  9: 'Customer',
};

export default UserRoles;
