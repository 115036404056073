import React, { useContext, useState, useEffect } from 'react';

import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { AESEncrypt } from 'helpers/encryptdecrypt';
import { getCustomerFacilitiesByCustomerId } from 'services/api/customerfacilities/customerfacilities.api';
import { getCustomerLocationsById } from 'services/api/customerlocations/customerlocations.api';
import { getCustomersLookup } from 'services/api/salesorders/salesorders-new.api';
import { getWarehouseCustomerList } from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Autocomplete,
  TextField,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface AutoCompleteOptionType {
  id: number;
  label: string;
}

export default React.memo(() => {
  const theme = useTheme();
  const {
    currentUser,
    updateLocationAndFacility,
    currentLocationAndFacility,
    setStoreWarehouseCustomer,
    storeWarehouseCustomer,
  } = useContext(AuthContext);

  const {
    isLocationAndFacilityModalOpen,
    updateData,
    onCloseLocationAndFacilityModal,
    handleUpdateData,
  } = useContext(GlobalContext);

  const [facilityValue, setFacilityValue] =
    useState<AutoCompleteOptionType>(null);
  const [warehouseCustomerValue, setWarehouseCustomerValue] =
    useState<AutoCompleteOptionType>(null);

  const [locations, setLocations] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [warehouseCustomers, setWarehouseCustomers] = useState([]);

  const locationDefault: AutoCompleteOptionType | null = {
    id: -1,
    label: '',
  };
  const facilityDefault: AutoCompleteOptionType | null = {
    id: -1,
    label: '',
  };

  const [showErrors, setShowErrors] = useState(false);
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState({
    customerLocationId: '',
    customerFacilityId: '',
    warehouseCustomerId: '',
  });
  const initialState: any = {
    customerLocationId: locationDefault,
    customerFacilityId: facilityDefault,
  };
  const [form, setForm] = useState<any>(initialState);

  const selectOptions = Array.isArray(locations)
    ? locations.map((location) => ({
        id: location.customerLocationId,
        label: location.name,
      }))
    : [{ id: 0, label: '' }];

  const facilityOptions =
    Array.isArray(facilities) && form.customerLocationId !== null
      ? facilities
          .filter((f) => f.customerLocationId === form.customerLocationId.id)
          .map((facility) => ({
            id: facility.customerFacilityId,
            label: facility.name,
          }))
      : [{ id: 0, label: '' }];

  const autoCompleteInputHandler = (
    key: string,
    value: AutoCompleteOptionType,
  ) => {
    if (key === 'customerLocationId') {
      form.customerFacilityId = facilityDefault;
      setFacilityValue(facilityDefault);
    }
    setForm(() => ({
      ...form,
      [key]: value,
    }));
  };

  const handleCloseLocationAndFacilityModal = () => {
    onCloseLocationAndFacilityModal();
  };

  const validateFacilityInput = () => {
    const temp: any = {};
    if (facilityValue.id === -1) {
      temp.customerFacilityId = 'This field is required';
    } else {
      temp.customerFacilityId = '';
    }

    setFormErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const onSaveLocationAndFacility = () => {
    if (!validateFacilityInput()) {
      snackActions.error('Please complete required fields');
    } else {
      form.customerLocationId = form.customerLocationId.id;
      form.customerFacilityId = form.customerFacilityId.id;

      const locationData: any = locations.filter(
        (l) => l.customerLocationId === form.customerLocationId,
      );
      const facilityData: any = facilities.filter(
        (f) =>
          f.customerFacilityId === form.customerFacilityId &&
          f.customerLocationId === form.customerLocationId,
      );
      const locationAndFacilityData = facilityData.map((facility) => ({
        customerFacilityId: facility.customerFacilityId,
        customerLocationId: facility.customerLocationId,
        locationName: locationData[0].name,
        facilityName: facility.name,
        facilityAddress: facility.address,
        facilityAddress2: facility.address2,
        facilityCity: facility.city,
        facilityZip: facility.zipPostalCode,
        facilityStateProvince: facility.stateProvince,
        facilityPhoneNumber: facility.phoneNumber,
      }));

      const encryptedLocationAndFacility = AESEncrypt(
        locationAndFacilityData[0],
      );

      updateLocationAndFacility({
        locationFacility: locationAndFacilityData[0],
        encryptedLocation: encryptedLocationAndFacility,
      });
      localStorage.setItem(
        'storeWarehouseCustomer',
        JSON.stringify(warehouseCustomerValue),
      );
      setForm(initialState);
      handleCloseLocationAndFacilityModal();
      handleUpdateData();
    }
  };

  const onLoadLocationsAndFacilities = async () => {
    try {
      const locationsFromApi = await getCustomerLocationsById(
        currentUser.Claim_CustomerId,
      );
      const facilitiesFromApi = await getCustomerFacilitiesByCustomerId(
        currentUser.Claim_CustomerId,
      );

      setLocations(locationsFromApi);
      setFacilities(facilitiesFromApi);

      return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadCustomersLookup = async () => {
    try {
      const warehouseCustomersLookupFromApi = await getCustomersLookup(
        currentUser.Claim_CustomerId,
        '',
      );

      return warehouseCustomersLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    setLocations([]);
    onLoadLocationsAndFacilities();
  }, [currentLocationAndFacility]);

  useEffect(() => {
    if (currentLocationAndFacility) {
      setForm({
        customerLocationId: {
          id: currentLocationAndFacility.customerLocationId,
          label: currentLocationAndFacility.locationName,
        },
        customerFacilityId: {
          id: currentLocationAndFacility.customerFacilityId,
          label: currentLocationAndFacility.facilityName,
        },
      });
    }

    if (warehouseCustomers.length === 0) {
      const optWarehouseCustomer = onLoadCustomersLookup();
      optWarehouseCustomer
        .then((ddl) => {
          setWarehouseCustomers(
            ddl.map((c: any) => ({
              id: c.warehouseCustomerId,
              label: c.name,
            })),
          );

          const getStoredWarehouseValue = JSON.parse(
            localStorage.getItem('storeWarehouseCustomer'),
          );
          if (getStoredWarehouseValue) {
            const getWcValue = ddl.find(
              (e) => e.warehouseCustomerId === getStoredWarehouseValue.id,
            );
            if (getWcValue) {
              setWarehouseCustomerValue((v) => ({
                ...v,
                id: getWcValue.warehouseCustomerId,
                label: getWcValue.name,
              }));
            }
          }
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    }
  }, [updateData, currentLocationAndFacility]);

  useEffect(() => {
    setFacilityValue(form.customerFacilityId);
  }, [isLocationAndFacilityModalOpen]);

  if (!isLocationAndFacilityModalOpen) return null;

  return (
    <Modal
      open={isLocationAndFacilityModalOpen}
      onClose={() => handleCloseLocationAndFacilityModal()}
    >
      <ModalBox>
        <ModalContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Choose Facility
            {warehouseCustomers.length > 0
              ? ', Location & Client'
              : ' & Location'}
          </Typography>
          {!showErrors ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Autocomplete
                sx={{ width: '100%' }}
                freeSolo
                options={selectOptions}
                getOptionLabel={(option: AutoCompleteOptionType) =>
                  `${option.label}` || ''
                }
                size="small"
                value={form.customerLocationId}
                onChange={(
                  event: any,
                  value: AutoCompleteOptionType | null,
                ) => {
                  autoCompleteInputHandler('customerLocationId', value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Location" />
                )}
              />
              {form.customerLocationId !== locationDefault ? (
                <Autocomplete
                  sx={{ width: '100%', display: 'none' }}
                  options={facilityOptions}
                  getOptionLabel={(option: AutoCompleteOptionType) =>
                    `${option.label}` || ''
                  }
                  size="small"
                  value={form.customerFacilityId}
                  onChange={(
                    event: any,
                    value: AutoCompleteOptionType | null,
                  ) => {
                    autoCompleteInputHandler('customerFacilityId', value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Facility"
                      className="clientHeaderFontFamily"
                    />
                  )}
                />
              ) : (
                <Autocomplete
                  sx={{ width: '100%', display: 'none' }}
                  disabled
                  options={facilityOptions}
                  getOptionLabel={(option: AutoCompleteOptionType) =>
                    `${option.label}` || ''
                  }
                  size="small"
                  value={form.customerFacilityId}
                  onChange={(
                    event: any,
                    value: AutoCompleteOptionType | null,
                  ) => {
                    autoCompleteInputHandler('customerFacilityId', value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled
                      label="Facility"
                      className="clientHeaderFontFamily"
                    />
                  )}
                />
              )}
            </Box>
          ) : (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Autocomplete
                sx={{ width: '100%' }}
                options={selectOptions}
                getOptionLabel={(option: AutoCompleteOptionType) =>
                  `${option.label}` || ''
                }
                size="small"
                value={form.customerLocationId}
                onChange={(
                  event: any,
                  value: AutoCompleteOptionType | null,
                ) => {
                  autoCompleteInputHandler('customerLocationId', value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error
                    helperText={formErrors.customerLocationId}
                    className="clientHeaderFontFamily"
                    label="Location"
                  />
                )}
              />
              <Autocomplete
                sx={{ width: '100%', display: 'none' }}
                options={facilityOptions}
                getOptionLabel={(option: AutoCompleteOptionType) =>
                  `${option.label}` || ''
                }
                size="small"
                value={form.customerFacilityId}
                onChange={(
                  event: any,
                  value: AutoCompleteOptionType | null,
                ) => {
                  autoCompleteInputHandler('customerFacilityId', value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error
                    helperText={formErrors.customerFacilityId}
                    className="clientHeaderFontFamily"
                    label="Facility"
                  />
                )}
              />
            </Box>
          )}
          <Autocomplete
            sx={{ width: '100%' }}
            value={facilityValue}
            options={facilityOptions}
            getOptionLabel={(option: AutoCompleteOptionType) =>
              `${option.label}` || ''
            }
            size="small"
            onChange={(event: any, value: AutoCompleteOptionType | null) => {
              if (value !== null) {
                setFacilityValue(value);
                setForm((prev) => ({
                  ...prev,
                  customerFacilityId: value,
                }));
              } else {
                setFacilityValue(facilityDefault);
              }
            }}
            defaultValue={facilityOptions?.find(
              (o: AutoCompleteOptionType) => o.label === facilityValue?.label,
            )}
            renderInput={(params) => (
              <TextField
                required
                id="outlined-required"
                {...params}
                {...(formErrors.customerFacilityId && {
                  error: true,
                  helperText: formErrors.customerFacilityId,
                })}
                className="clientHeaderFontFamily"
                label="Facility"
              />
            )}
          />
          {warehouseCustomers && warehouseCustomers?.length > 0 && (
            <Autocomplete
              id="controllable-states"
              options={warehouseCustomers}
              value={warehouseCustomerValue}
              getOptionLabel={(option: AutoCompleteOptionType) => option.label}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoComplete="off"
                  id="outlined-required"
                  label="Client"
                  size="small"
                  className="clientHeaderFontFamily"
                  sx={{ width: '100%' }}
                />
              )}
              onChange={(event: any, newValue: AutoCompleteOptionType) => {
                if (newValue !== null) {
                  setWarehouseCustomerValue(newValue);
                } else {
                  setWarehouseCustomerValue(null);
                }
              }}
            />
          )}
          <Typography variant="subtitle2" color="#d32f2f">
            {error}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end',
              },
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
              },
              gap: '8px',
            }}
          >
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="outlined"
              size="large"
              onClick={() => handleCloseLocationAndFacilityModal()}
            >
              Cancel
            </Button>
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="contained"
              disabled={form.customerFacilityId.id < 0}
              size="large"
              onClick={() => onSaveLocationAndFacility()}
            >
              Save
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
