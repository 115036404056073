/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';

import { UserFilterValueTypeEnum } from 'common/enums/UserFilterValueTypeEnum';
import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { useInventoryListPageContext } from 'pages/inventory/context';
import { useSalesOrderDataTableViewContext } from 'pages/sales/context-provider';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Typography,
  Grid as MUIGrid,
  Box,
  TextField,
  Button,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    gap: 16px;
    top: 28%;
    left: 90%;
    transform: translate(-50%, -50%);
    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;
    width: 100%;
    max-width: 250px;
    border-radius: 4px;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;

export default React.memo(() => {
  const {
    isDeleteFilterModalOpen,
    isAdvancedSearchModalOpen,
    onCloseAdvancedSearchModal,
    setIsDeleteFilterModalOpen,
    isAdvancedSearchInventoryModalOpen,
    onCloseAdvancedSearchInventoryModal,
    filterValueEnum,
  } = useContext(GlobalContext);

  const {
    setAdvanceSearchValue,
    advanceSearchValue,
    OnClickCreateUserFilterSearch,
    OnClickDeleteFilterSearch,
  } = useSalesOrderDataTableViewContext();

  const {
    advanceSearchValueInventory,
    setAdvanceSearchValueInventory,
    OnClickDeleteFilterSearchInventory,
    OnClickCreateUserFilterSearchInventory,
  } = useInventoryListPageContext();

  const [filterNameFormError, setFilterNameFormError] = useState<any>({});
  const [filterNameInventoryFormError, setFilterNameInventoryFormError] =
    useState<any>({});

  const OnClickSaveFilterOrder = async (e?: any) => {
    e?.preventDefault();

    const temp: any = {};
    temp.setFilterName = advanceSearchValue.setFilterName
      ? ''
      : 'This field is required';

    setFilterNameFormError({
      ...temp,
    });

    if (!Object.values(temp).every((x) => x === '')) {
      snackActions.error('Please enter filter name');
      return;
    }
    OnClickCreateUserFilterSearch(e);
    onCloseAdvancedSearchModal();
  };

  const OnClickDeleteFilterOrder = async (e?: any) => {
    e?.preventDefault();

    if (filterValueEnum === UserFilterValueTypeEnum.Order) {
      OnClickDeleteFilterSearch(e);
    } else if (filterValueEnum === UserFilterValueTypeEnum.Inventory) {
      OnClickDeleteFilterSearchInventory(e);
    }
    setIsDeleteFilterModalOpen(false);
  };

  const OnClickSaveFilterIventory = async (e?: any) => {
    e?.preventDefault();

    const temp: any = {};
    temp.setFilterName = advanceSearchValueInventory.setFilterName
      ? ''
      : 'This field is required';

    setFilterNameInventoryFormError({
      ...temp,
    });

    if (!Object.values(temp).every((x) => x === '')) {
      snackActions.error('Please enter filter name');
      return;
    }

    OnClickCreateUserFilterSearchInventory(e);
    onCloseAdvancedSearchInventoryModal();
  };

  return (
    <>
      {isAdvancedSearchModalOpen && (
        <Modal open={isAdvancedSearchModalOpen}>
          <MUIContainer>
            <MUIContent>
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item>
                  <Typography variant="h6" fontWeight="bold">
                    Enter Filter Name
                  </Typography>
                </MUIGrid>
                <MUIGrid item>
                  <TextField
                    inputProps={{
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    placeholder="Filter Name"
                    value={advanceSearchValue.setFilterName}
                    onChange={(e) => {
                      setAdvanceSearchValue((prev) => ({
                        ...prev,
                        setFilterName: e.target.value,
                      }));
                    }}
                    multiline
                    minRows={2}
                    sx={{ width: '100%' }}
                    size="small"
                    {...(filterNameFormError.setFilterName && {
                      error: true,
                      helperText: filterNameFormError.setFilterName,
                    })}
                  />
                </MUIGrid>
                <MUIGrid item xs={12}>
                  <MUIGrid
                    container
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ justifyContent: 'space-between' }}
                  >
                    <MUIGrid item xs={12}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          boxShadow: 'none !important',
                        }}
                        onClick={onCloseAdvancedSearchModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          float: 'right !important',
                          boxShadow: 'none !important',
                        }}
                        onClick={(e) => OnClickSaveFilterOrder(e)}
                      >
                        Save
                      </Button>
                    </MUIGrid>
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
            </MUIContent>
          </MUIContainer>
        </Modal>
      )}
      {/* INVENTORY ------------------------------------------ */}
      {isAdvancedSearchInventoryModalOpen && (
        <Modal open={isAdvancedSearchInventoryModalOpen}>
          <MUIContainer>
            <MUIContent>
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item>
                  <Typography variant="h6" fontWeight="bold">
                    Enter Filter Name
                  </Typography>
                </MUIGrid>
                <MUIGrid item>
                  <TextField
                    inputProps={{
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    placeholder="Filter Name"
                    value={advanceSearchValueInventory.setFilterName}
                    onChange={(e) => {
                      setAdvanceSearchValueInventory((prev) => ({
                        ...prev,
                        setFilterName: e.target.value,
                      }));
                    }}
                    multiline
                    minRows={2}
                    sx={{ width: '100%' }}
                    size="small"
                    {...(filterNameInventoryFormError.setFilterName && {
                      error: true,
                      helperText: filterNameInventoryFormError.setFilterName,
                    })}
                  />
                </MUIGrid>
                <MUIGrid item xs={12}>
                  <MUIGrid
                    container
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ justifyContent: 'space-between' }}
                  >
                    <MUIGrid item xs={12}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          boxShadow: 'none !important',
                        }}
                        onClick={onCloseAdvancedSearchInventoryModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          float: 'right !important',
                          boxShadow: 'none !important',
                        }}
                        onClick={(e) => OnClickSaveFilterIventory(e)}
                      >
                        Save
                      </Button>
                    </MUIGrid>
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
            </MUIContent>
          </MUIContainer>
        </Modal>
      )}
      {isDeleteFilterModalOpen && (
        <Modal open={isDeleteFilterModalOpen}>
          <MUIContainer>
            <MUIContent>
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Are you sure want to delete this filter `
                    {advanceSearchValue?.setFilterName}`?
                  </Typography>
                </MUIGrid>
                <MUIGrid item xs={12}>
                  <MUIGrid
                    container
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ justifyContent: 'space-between' }}
                  >
                    <MUIGrid item xs={12}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          boxShadow: 'none !important',
                        }}
                        onClick={() => setIsDeleteFilterModalOpen(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          float: 'right !important',
                          boxShadow: 'none !important',
                        }}
                        onClick={(e) => OnClickDeleteFilterOrder(e)}
                      >
                        Confirm
                      </Button>
                    </MUIGrid>
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
            </MUIContent>
          </MUIContainer>
        </Modal>
      )}
    </>
  );
});
