import React, { useContext } from 'react';
import { createSearchParams } from 'react-router-dom';

import moment from 'moment';
import { FormTemplateTypeEnum } from 'pages/shared/printformtemplate';
import { PackemInvoiceLogo } from 'pages/styles';
import { AuthContext } from 'store/contexts/AuthContext';

import PrintIcon from '@mui/icons-material/Print';
import { Box, Chip, IconButton, Link, Typography } from '@mui/material';

import ChannelIcon from '../channel-image';
import { useSalesOrderContext } from '../context';

const OrderStatusChip = () => {
  const {
    pageViewer,
    salesOrderForm,
    salesOrderFlagStatus,
    queryStringItemOrders,
    printFormTemplateOnClick,
  } = useSalesOrderContext();

  const { isSupplierAccount, isWarehouseCustomerAccount } =
    useContext(AuthContext);

  const handleViewReturnDetails = (receiptId) => {
    const querySearchParams = {
      form: 'view',
      id: receiptId,
    };

    const url = `/receiving/receipts/subpage?${createSearchParams(
      querySearchParams,
    )}`;

    window.open(url, '_blank');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {pageViewer.isNew() && (
          <Typography
            sx={{ paddingRight: 1 }}
            variant="h6"
            fontWeight="bold"
            textTransform="capitalize"
          >
            New {queryStringItemOrders.isReturn === true && 'Return'} Order
          </Typography>
        )}
        {pageViewer.isViewOrEdit() && (
          <>
            <Typography
              sx={{ paddingRight: 1 }}
              variant="h6"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Orders / {salesOrderForm.orderNo}
            </Typography>
            <Chip
              label={salesOrderFlagStatus.statusStr}
              sx={{ marginRight: 1 }}
            />
            {salesOrderFlagStatus.isBackOrder && (
              <Chip
                label={[
                  'Backorder ',
                  moment(salesOrderForm.backOrderDateTime)
                    .format('MMM DD')
                    .toUpperCase(),
                ].join('')}
                color="error"
                sx={{ marginRight: 1 }}
              />
            )}
            {salesOrderFlagStatus.isPartial && (
              <Chip label="Partial" color="error" sx={{ marginRight: 1 }} />
            )}
            {salesOrderFlagStatus.isHold && (
              <Chip label="Hold" color="error" sx={{ marginRight: 1 }} />
            )}
            {salesOrderForm.isAddressInvalid && (
              <Chip
                label="Invalid By Postal Office"
                color="warning"
                sx={{ marginRight: 1 }}
                size="small"
              />
            )}
            {salesOrderForm?.channel && (
              <ChannelIcon type={salesOrderForm?.channel.toLowerCase()} />
            )}
            {salesOrderForm?.orderId && salesOrderForm?.isReturnOrder === true && (
              <IconButton
                title="Return Order"
                onClick={() =>
                  handleViewReturnDetails(salesOrderForm.receiptId)
                }
              >
                <Chip
                  label="Return Order"
                  color="warning"
                  size="small"
                  sx={{ cursor: 'pointer' }}
                />
              </IconButton>
            )}
            {salesOrderForm?.orderId && (
              <IconButton
                aria-label="Print Order"
                title="Print Order"
                onClick={(e) => {
                  printFormTemplateOnClick(e, FormTemplateTypeEnum.OrderDetail);
                }}
                style={{ color: '#3375e0' }}
              >
                <PrintIcon />
              </IconButton>
            )}
            {salesOrderForm?.orderId &&
              !isWarehouseCustomerAccount &&
              !isSupplierAccount && (
                <IconButton
                  title="Print Invoice"
                  onClick={(e) => {
                    if (salesOrderForm?.invoiceId) {
                      printFormTemplateOnClick(e, FormTemplateTypeEnum.Invoice);
                    }
                  }}
                >
                  <Link
                    sx={{ textDecoration: 'none', fontFamily: 'inherit' }}
                    href={`/order/invoice-details?orderId=${
                      queryStringItemOrders.id
                    }${
                      salesOrderForm.invoiceId
                        ? `&invoiceId=${salesOrderForm.invoiceId}`
                        : ``
                    }`}
                    target="_blank"
                  >
                    <PackemInvoiceLogo />
                  </Link>
                </IconButton>
              )}
          </>
        )}
      </Box>
    </Box>
  );
};
export default React.memo(OrderStatusChip);
