import { FC } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { ISelectListFilter } from 'common/interfaces';

interface IDropdownSelectList {
  label?: string;
  filters: ISelectListFilter[];
  onSelect: (selected: ISelectListFilter | undefined) => void;
}

export const DropdownSelectList: FC<IDropdownSelectList> = ({
  label,
  filters,
  onSelect,
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedField = event.target.value;
    const selected = filters.find((filter) => filter.field === selectedField);
    onSelect(selected);
  };

  return (
    <FormControl fullWidth>
      <Select
        labelId="filter-select-label"
        id="filter-select"
        defaultValue={
          filters && filters.length > 0 ? filters[0].field : undefined
        }
        label={label}
        onChange={handleChange}
      >
        {filters.map((filter) => (
          <MenuItem key={filter.field} value={filter.field}>
            {filter.alias}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
