import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getCarriers = async () => {
  try {
    const response = await httpClient.get(`/shippo/carriers/all`);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getCarrierById = async (id: string) => {
  try {
    const response = await httpClient.get(`/shippo/carriers/${id}`);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const connectCarrier = async (payload, warehouseCustomerId: number) => {
  try {
    const response = await httpClient.post(
      `/shippo/carrier/${warehouseCustomerId}`,
      payload,
    );
    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getShipmentRates = async (
  pickTicketId: number,
  warehouseCustomerId: number,
) => {
  try {
    const response = await httpClient.get(
      `/shippo/createShipment/${pickTicketId}/${warehouseCustomerId}`,
    );
    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const createShipmentLabel = async (
  shipmentRateId: string,
  pickTicketId: number,
) => {
  try {
    const response = await httpClient.post(
      `/shippo/createLabels/${shipmentRateId}/${pickTicketId}`,
    );
    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};
