import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const createPurchaseOrder = async (data) => {
  try {
    const response = await httpClient.post(
      `/PurchaseOrder/CreatePurchaseOrder`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const addImportedPurchaseOrders = async (customerLocationId, data) => {
  try {
    const response = await httpClient.post(
      `/PurchaseOrder/AddImportedPurchaseOrders/${customerLocationId}`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deletePurchaseOrder = async (deleteForm) => {
  try {
    const response = await httpClient.post(`/PurchaseOrder/Delete`, deleteForm);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPurchaseOrder = async (purchaseOrderId) => {
  try {
    const response = await httpClient.get(
      `/PurchaseOrder/Detail/${purchaseOrderId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPurchaseOrdersByCustomerLocationId = async (
  customerLocationId,
) => {
  try {
    const response = await httpClient.get(
      `/PurchaseOrder/PurchaseOrders/${customerLocationId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPurchaseOrdersByCustomerId = async () => {
  try {
    const response = await httpClient.get(
      `/PurchaseOrder/PurchaseOrders/GetPurchaseOrdersByCustomerId`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const removePurchaseOrder = async (data) => {
  try {
    const response = await httpClient.post(`/PurchaseOrder/Delete`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// Begin purchase order v2 endpoints
export const createPurchaseOrderV2 = async (purchaseOrderData) => {
  try {
    const response = await httpClient.post(
      `/PurchaseOrder/CreatePurchaseOrder/V2`,
      purchaseOrderData,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getAllPurchaseOrdersV2 = async (
  customerFacilityId,
  warehouseCustomerId,
) => {
  try {
    const response = await httpClient.get(
      `/PurchaseOrder/List/${customerFacilityId}`,
      {
        params: {
          warehouseCustomerId,
        },
      },
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const getPurchaseOrderDetailV2 = async (purchaseOrderId) => {
  try {
    const response = await httpClient.get(
      `/PurchaseOrder/Detail/V2/${purchaseOrderId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const editPurchaseOrder = async (editForm) => {
  try {
    const response = await httpClient.post(`/PurchaseOrder/Edit`, editForm);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const getGeneratedPurchaseOrderNo = async (
  customerId,
  warehouseCustomerId,
) => {
  try {
    const response = await httpClient.get(
      `/PurchaseOrder/Generate/${customerId}/${warehouseCustomerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// ------------------------------------------ NEW PURCHASE ORDER APIs
export const createEditPurchaseOrder = async (model) => {
  try {
    const response = await httpClient.post(
      `/purchaseorder/Detail/createedit`,
      model,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const getPurchaseOrderDetail = async (purchaseOrderId) => {
  try {
    const response = await httpClient.get(
      `/purchaseorder/detail/${purchaseOrderId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const uploadPurchaseOrderDocuments = async (
  purchaseOrderId,
  files,
  documentType,
) => {
  try {
    const formData = new FormData();

    for (let i = 0; i < files.length; i += 1) formData.append('file', files[i]);

    const response = await httpClient.post(
      `purchaseorder/documents/upload/${purchaseOrderId}/${documentType}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPurchaseOrderDocuments = async (purchaseOrderId) => {
  try {
    const response = await httpClient.get(
      `purchaseorder/documents/getList/${purchaseOrderId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPurchaseOrderDocumentService = async (awsFileKey) => {
  try {
    window.open(
      `${httpClient.defaults.baseURL}/purchaseorder/documents/aws/${awsFileKey}`,
      '_blank',
    );
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deletePurchaseOrderDocument = async (documentId) => {
  try {
    const response = await httpClient.delete(
      `purchaseorder/documents/delete/${documentId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const importPurchaseOrder = async (model) => {
  try {
    const response = await httpClient.post(
      `/purchaseorder/importPurchaseOrder`,
      model,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};
