import { ENV_LOC, API_URL } from 'config/constants';
import { PackemQRCode, PackemQRCodeDev, PackemQRCodeFex } from 'pages/styles';
import { environmentVariable } from 'store/environment';

import { Box, Dialog, DialogContent, Typography } from '@mui/material';

export interface IHeaderQRCodeDialogProps {
  isOpen: boolean;
  onClose: () => void;
}
function HeaderQRCodeDialog({ isOpen, onClose }: IHeaderQRCodeDialogProps) {
  const EnvLoc = environmentVariable(ENV_LOC);
  const ApiUrl = environmentVariable(API_URL);

  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Box sx={{ padding: '5px 80px' }}>
        <Typography
          variant="h6"
          style={{
            textAlign: 'center',
            padding: 20,
            borderRadius: 10,
            fontWeight: 700,
            marginTop: 15,
          }}
        >
          Scan the QR code to download our app
        </Typography>
        <DialogContent style={{ padding: 20 }}>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            {/* .env.staging */}
            {!ApiUrl.includes('fexapi') && EnvLoc === 'Staging' && (
              <PackemQRCode />
            )}

            {/* .env.develop */}
            {EnvLoc === 'Develop' && <PackemQRCodeDev />}

            {/* .env.fexbuild */}
            {ApiUrl.includes('fexapi') && EnvLoc === 'Staging' && (
              <PackemQRCodeFex />
            )}
          </Box>
          <Box
            sx={{ m: 5 }}
            style={{ display: 'flex', justifyContent: 'center' }}
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
}

export default HeaderQRCodeDialog;
