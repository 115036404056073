/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RoleEnum } from 'common/enums';
import { IAutoCompleteIdName } from 'common/props';
import Card from 'components/card';
import CardTab, { tabProps } from 'components/card/card-tab';
import DatePickerInput from 'components/datepicker';
import Header from 'components/header';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import { StyledMenu } from 'pages/sales/salesorder/orderstatusdropdown';
import Printformtemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridStandard } from 'styles';

import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NoteIcon from '@mui/icons-material/Note';
import {
  Typography,
  Box,
  Grid as MUIGrid,
  TextField,
  Autocomplete,
  InputAdornment,
  Button,
  Divider,
  LinearProgress,
  linearProgressClasses,
  Chip,
  Link,
  Checkbox,
  Tooltip,
  IconButton,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import {
  GridActionsCellItem,
  GridColumns,
  gridClasses,
} from '@mui/x-data-grid';

import { MainContainer, ContentContainer } from '../../styles';
import DocumentsModal from '../receipts/documentsModal';
import {
  CustomerType,
  IPurchaseOrderPackingListLineItem,
  PurchaseOrderContextProvider,
  usePurchaseOrderContext,
} from './context';
import PackingListNotesModal from './packing-list-notes-modal';
import PackingListErrorImportModal from './packinglist-error-message-modal';
import PackingListImportModal from './packinglist-import-modal';
import PODeletelineItemModal from './podeletemodal';
import POlineItemModal from './polineitemmodal';

type AutocompleteBaseValueAndLabel = {
  label?: string;
  value?: number;
};

type PaymentStatusTypes = AutocompleteBaseValueAndLabel;

const optionPaymentStatusType: readonly PaymentStatusTypes[] = [
  { value: 1, label: 'Partially Paid' },
  { value: 2, label: 'Not Paid' },
  { value: 3, label: 'Fully Paid' },
];

const formatMoney = (amount) =>
  Number(amount)
    .toFixed(4)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');

const PurchaseOrderStatusProgressBar = [
  'New',
  'Submitted',
  'Receiving',
  'Closed',
];

const PurchaseOrderProgressBar = () => {
  const [purchaseOrderStatusValue, setPurchaseOrderStatusValue] =
    useState<number>(1);

  const { purchaseOrderDetails, pageViewerPO } = usePurchaseOrderContext();

  useEffect(() => {
    if (!pageViewerPO.isNew()) {
      // submitted enum on backend
      if (purchaseOrderDetails.status === 2) {
        setPurchaseOrderStatusValue(3);
      } else if (purchaseOrderDetails.status === 4) {
        setPurchaseOrderStatusValue(2);
      } else if (purchaseOrderDetails.status === 3) {
        setPurchaseOrderStatusValue(4);
      }
    }
  }, [pageViewerPO]);

  return (
    <Box sx={{ width: '100%', padding: '15px 70px' }}>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          {Object.keys(PurchaseOrderStatusProgressBar).map((key, index) => (
            <Typography variant="body1" fontWeight="bold">
              {PurchaseOrderStatusProgressBar[index]}
            </Typography>
          ))}
        </MUIGrid>
        <MUIGrid item xs={12}>
          <LinearProgress
            variant="determinate"
            value={
              purchaseOrderStatusValue === 4
                ? 100
                : purchaseOrderStatusValue * 25
            }
            sx={{
              width: '100%',
              height: '10px',
              borderRadius: 2,
              backgroundColor: 'rgb(211 211 211)',
              [`& .${linearProgressClasses.bar1Determinate}`]: {
                borderRadius: 2,
                backgroundColor: purchaseOrderStatusValue === 4 && '#00cf00',
              },
            }}
          />
        </MUIGrid>
      </MUIGrid>
    </Box>
  );
};

const PurchaseOrderHeaderCard = () => {
  const { purchaseOrderDetails, purchaseOrderIdPrint } =
    usePurchaseOrderContext();

  return (
    <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={7.7}>
        <PurchaseOrderProgressBar />
      </MUIGrid>
      <MUIGrid item xs={4.3}>
        <Card
          sx={{
            marginBottom: 2,
            textAlignLast: 'center',
            padding: '10px 24px !important',
          }}
        >
          {purchaseOrderIdPrint !== 0 && (
            <Printformtemplate
              preview={false}
              dynamicId={purchaseOrderIdPrint}
              formtemplateTypeId={FormTemplateTypeEnum.PurchaseOrder}
            />
          )}
          <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <MUIGrid item xs={12}>
              <Typography variant="h6" fontWeight="bold">
                {purchaseOrderDetails.shipments}
              </Typography>
            </MUIGrid>
            <MUIGrid item xs={12} sx={{ paddingTop: '0px' }}>
              <Typography variant="subtitle1">Shipments</Typography>
            </MUIGrid>
          </MUIGrid>

          <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <MUIGrid item xs={12}>
              <Typography variant="h6" fontWeight="bold">
                {purchaseOrderDetails.skus}
              </Typography>
            </MUIGrid>
            <MUIGrid item xs={12}>
              <Typography variant="subtitle1">SKUs</Typography>
            </MUIGrid>
          </MUIGrid>

          <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <MUIGrid item xs={12}>
              <Typography variant="h6" fontWeight="bold">
                {purchaseOrderDetails.unitsExpected}
              </Typography>
            </MUIGrid>
            <MUIGrid item xs={12}>
              <Typography variant="subtitle1">Units Expected</Typography>
            </MUIGrid>
          </MUIGrid>

          <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <MUIGrid item xs={12}>
              <Typography variant="h6" fontWeight="bold">
                {purchaseOrderDetails.unitsReceived}
              </Typography>
            </MUIGrid>
            <MUIGrid item xs={12}>
              <Typography variant="subtitle1">Units Received</Typography>
            </MUIGrid>
          </MUIGrid>
        </Card>
      </MUIGrid>
    </MUIGrid>
  );
};

const PurchaseOrderDescription = () => {
  const {
    purchaseOrderDetails,
    optionCustomers,
    optionCountries,
    optionCustomerSupplier,
    purchaseOrderDetailsFormError,
    isAddNewSupplierPage,
    pageViewerPO,
    hasAlreadySupplier,
    setPurchaseOrderDetails,
    onLoadGeneratedPONumber,
    viewLoadDataPO,
    setIsAddNewSupplierPage,
  } = usePurchaseOrderContext();
  const { currentUser, isSupplierAccount, isWarehouseCustomerAccount } =
    useContext(AuthContext);
  const [isEdit, setIsEdit] = useState(false);

  const handleOnChangeAddSupplierAdd = () => {
    setIsAddNewSupplierPage(false);
  };

  const handleOnChangeAddSupplierCancel = () => {
    setIsAddNewSupplierPage(true);
  };

  const isPast = (prev: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return prev < today;
  };

  const getMinDate = (value: string) => {
    const savedDate = new Date(value);
    return savedDate && isPast(savedDate) ? savedDate : new Date();
  };

  useEffect(() => {
    if (!isAddNewSupplierPage) {
      setPurchaseOrderDetails((e) => ({
        ...e,
        poDescription: {
          ...e.poDescription,
          knownSuppliers: {
            value: -1,
            label: '',
          },
          companyName: '',
          address1: '',
          address2: '',
          cityTownship: '',
          stateProvinceRegion: '',
          zipPostalCode: '',
          supplierIdentifier: '',
          departmentNo: '',
          country: {
            value: 236,
            label: 'United States of America',
          },
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddNewSupplierPage]);

  useEffect(() => {
    setIsAddNewSupplierPage(true);

    const currClient: IAutoCompleteIdName = JSON.parse(
      localStorage.getItem('storeWarehouseCustomer'),
    );

    if (
      currentUser.Claim_UserRole === RoleEnum.SupplierAccount ||
      currentUser.Claim_UserRole === RoleEnum.Admin
    ) {
      setIsEdit(true);
    }

    if (pageViewerPO.isNew() && !isWarehouseCustomerAccount) {
      if (currClient && currClient?.id) {
        if (Number(currClient?.id) > 0) {
          onLoadGeneratedPONumber(Number(currClient.id));
        }
      } else {
        onLoadGeneratedPONumber(0);
      }
    }
    if (
      pageViewerPO.isNew() &&
      optionCustomers.length > 0 &&
      optionCustomers.length === 1
    ) {
      setPurchaseOrderDetails((e) => ({
        ...e,
        poDescription: {
          ...e.poDescription,
          customer: {
            value: optionCustomers[0].value,
            label: optionCustomers[0].label,
          },
        },
      }));

      if (isWarehouseCustomerAccount) {
        onLoadGeneratedPONumber(optionCustomers[0].value);
      }
    }

    if (pageViewerPO.isNew() && optionCustomers.length > 0 && currClient) {
      const clientIndex = optionCustomers.findIndex(
        (r: any) => Number(r.value) === Number(currClient.id),
      );

      setPurchaseOrderDetails((e) => ({
        ...e,
        poDescription: {
          ...e.poDescription,
          customer: {
            value: optionCustomers[clientIndex].value,
            label: optionCustomers[clientIndex].label,
          },
        },
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.Claim_CustomerId, optionCustomers]);

  const purchaseOrderActivityLog: GridColumns = [
    {
      field: 'changeBy',
      minWidth: 100,
      flex: 0.5,
      renderHeader: () => <Typography>Change By</Typography>,
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.changeBy}
        </Typography>
      ),
    },
    {
      field: 'changeDate',
      minWidth: 120,
      flex: 0.5,
      renderHeader: () => <Typography>Change Date</Typography>,
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.changeDate}
        </Typography>
      ),
    },
    {
      field: 'description',
      minWidth: 150,
      flex: 1,
      renderHeader: () => <Typography>Description</Typography>,
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.description}
        </Typography>
      ),
    },
  ];

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Card
        sx={{
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          width: '65%',
          paddingBottom: '0px !important',
        }}
      >
        <Box>
          <MUIGrid
            container
            spacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={5} marginBottom={1}>
              <Typography variant="body1" fontWeight="bold">
                PO Description
              </Typography>
            </MUIGrid>
          </MUIGrid>
        </Box>

        <Box>
          <MUIGrid
            container
            spacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={4}>
              <Autocomplete
                id="controllable-states"
                options={optionCustomers}
                value={purchaseOrderDetails.poDescription.customer}
                disabled={!isAddNewSupplierPage || pageViewerPO.isView()}
                getOptionLabel={(option: CustomerType) => option.label}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined-required"
                    label="Client"
                    size="small"
                    sx={{ width: '100%' }}
                    {...(purchaseOrderDetailsFormError.customer && {
                      error: true,
                      helperText: purchaseOrderDetailsFormError.customer,
                    })}
                  />
                )}
                onChange={(event: any, newValue: CustomerType) => {
                  if (newValue !== null) {
                    setPurchaseOrderDetails((e) => ({
                      ...e,
                      poDescription: {
                        ...e.poDescription,
                        customer: newValue,
                      },
                    }));
                    onLoadGeneratedPONumber(newValue.value);
                  } else {
                    setPurchaseOrderDetails((e) => ({
                      ...e,
                      poDescription: {
                        ...e.poDescription,
                        customer: {
                          value: -1,
                          label: '',
                        },
                      },
                    }));
                  }
                }}
              />
            </MUIGrid>
            <MUIGrid item xs={4}>
              <TextField
                inputProps={{
                  'data-state': 'PO No.',
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                id="outlined"
                label="PO No."
                size="small"
                name="PO No."
                value={purchaseOrderDetails.poDescription.poNumber}
                onChange={(v) =>
                  setPurchaseOrderDetails((e) => ({
                    ...e,
                    poDescription: {
                      ...e.poDescription,
                      poNumber: v.target.value,
                    },
                  }))
                }
                sx={{
                  width: '100%',
                  backgroundColor: '#ffffff !important',
                }}
                {...(purchaseOrderDetailsFormError.poNumber && {
                  error: true,
                  helperText: purchaseOrderDetailsFormError.poNumber,
                })}
                required
                disabled={pageViewerPO.isView()}
              />
            </MUIGrid>
            <MUIGrid item xs={4}>
              <DatePickerInput
                label="Issue Date"
                inputFormat="MM/dd/yyyy"
                value={purchaseOrderDetails.poDescription.issueDate}
                maxDate={new Date()}
                disableFuture
                onChange={(e) => {
                  if (e !== null && e !== 'Invalid Date') {
                    setPurchaseOrderDetails((prev) => ({
                      ...prev,
                      poDescription: {
                        ...prev.poDescription,
                        issueDate: e,
                      },
                    }));
                  } else {
                    setPurchaseOrderDetails((prev) => ({
                      ...prev,
                      poDescription: {
                        ...prev.poDescription,
                        issueDate: null,
                      },
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    {...(purchaseOrderDetailsFormError.issueDate && {
                      error: true,
                      helperText: purchaseOrderDetailsFormError.issueDate,
                    })}
                  />
                )}
                disabled={pageViewerPO.isView()}
              />
            </MUIGrid>
            <MUIGrid item xs={4}>
              <DatePickerInput
                label="Accepted Date"
                inputFormat="MM/dd/yyyy"
                value={purchaseOrderDetails.poDescription.acceptedDate || null}
                maxDate={new Date()}
                disableFuture
                onChange={(e) => {
                  if (e !== null && e !== 'Invalid Date') {
                    setPurchaseOrderDetails((prev) => ({
                      ...prev,
                      poDescription: {
                        ...prev.poDescription,
                        acceptedDate: e,
                      },
                    }));
                  } else {
                    setPurchaseOrderDetails((prev) => ({
                      ...prev,
                      poDescription: {
                        ...prev.poDescription,
                        acceptedDate: null,
                      },
                    }));
                  }
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
                disabled={pageViewerPO.isView()}
              />
            </MUIGrid>
            <MUIGrid item xs={4}>
              <DatePickerInput
                label="Expected Arrival Date"
                inputFormat="MM/dd/yyyy"
                value={
                  purchaseOrderDetails?.poDescription?.expectedArrivalDate ||
                  null
                }
                onChange={(e) => {
                  if (e !== null && e !== 'Invalid date') {
                    setPurchaseOrderDetails((prev) => ({
                      ...prev,
                      poDescription: {
                        ...prev.poDescription,
                        expectedArrivalDate: e,
                      },
                    }));
                  } else {
                    setPurchaseOrderDetails((prev) => ({
                      ...prev,
                      poDescription: {
                        ...prev.poDescription,
                        expectedArrivalDate: null,
                      },
                    }));
                  }
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
                disabled={pageViewerPO.isView()}
              />
            </MUIGrid>
            <MUIGrid item xs={4}>
              <DatePickerInput
                label="Estimated Bulk Ready Date"
                inputFormat="MM/dd/yyyy"
                value={
                  purchaseOrderDetails.poDescription.estimatedBulkReadyDate ||
                  null
                }
                minDate={getMinDate(
                  purchaseOrderDetails.poDescription.estimatedBulkReadyDate ??
                    new Date().toLocaleDateString(),
                )}
                onChange={(e) => {
                  if (e !== null && e !== 'Invalid Date') {
                    setPurchaseOrderDetails((prev) => ({
                      ...prev,
                      poDescription: {
                        ...prev.poDescription,
                        estimatedBulkReadyDate: e,
                      },
                    }));
                  } else {
                    setPurchaseOrderDetails((prev) => ({
                      ...prev,
                      poDescription: {
                        ...prev.poDescription,
                        estimatedBulkReadyDate: null,
                      },
                    }));
                  }
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
                disabled={pageViewerPO.isView() && !isSupplierAccount}
              />
            </MUIGrid>
            <MUIGrid item xs={12}>
              <TextField
                placeholder="Note"
                size="small"
                value={purchaseOrderDetails.poDescription.note || ''}
                sx={{
                  width: '100%',
                  backgroundColor: '#ffffff !important',
                  marginBottom: '16px',
                }}
                onChange={(v) =>
                  setPurchaseOrderDetails((e) => ({
                    ...e,
                    poDescription: {
                      ...e.poDescription,
                      note: v.target.value,
                    },
                  }))
                }
                multiline
                rows={2}
                disabled={pageViewerPO.isView()}
              />
            </MUIGrid>
          </MUIGrid>
        </Box>

        <Box>
          <MUIGrid
            container
            spacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={5} marginBottom={1}>
              <Typography variant="body1" fontWeight="bold">
                Suppliers
              </Typography>
            </MUIGrid>
          </MUIGrid>
        </Box>
        {!hasAlreadySupplier && (
          <Box marginBottom={1}>
            <MUIGrid
              container
              spacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={3}>
                <Autocomplete
                  onChange={(event: any, newValue: CustomerType) => {
                    if (newValue !== null) {
                      setPurchaseOrderDetails((e) => ({
                        ...e,
                        poDescription: {
                          ...e.poDescription,
                          knownSuppliers: newValue,
                        },
                      }));
                    } else {
                      setPurchaseOrderDetails((e) => ({
                        ...e,
                        poDescription: {
                          ...e.poDescription,
                          knownSuppliers: {
                            value: -1,
                            label: '',
                          },
                        },
                      }));
                    }
                  }}
                  disabled={!isAddNewSupplierPage || pageViewerPO.isView()}
                  id="controllable-states"
                  options={optionCustomerSupplier}
                  getOptionLabel={(option: CustomerType) => option.label}
                  value={purchaseOrderDetails.poDescription.knownSuppliers}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Known Suppliers"
                      size="small"
                      sx={{ width: '100%' }}
                      {...(purchaseOrderDetailsFormError.knownSuppliers && {
                        error: true,
                        helperText:
                          purchaseOrderDetailsFormError.knownSuppliers,
                      })}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={1.5}>
                <Button
                  variant={isAddNewSupplierPage ? 'contained' : 'outlined'}
                  size="small"
                  sx={{
                    padding: '8px !important;',
                    textTransform: 'capitalize !important',
                  }}
                  onClick={() =>
                    isAddNewSupplierPage
                      ? handleOnChangeAddSupplierAdd()
                      : handleOnChangeAddSupplierCancel()
                  }
                  disabled={pageViewerPO.isView()}
                >
                  {isAddNewSupplierPage ? 'Add New' : 'Cancel'}
                </Button>
              </MUIGrid>
              {isAddNewSupplierPage && (
                <MUIGrid item xs={3}>
                  <Autocomplete
                    disableClearable
                    onChange={(event: any, newValue: PaymentStatusTypes) => {
                      if (newValue !== null) {
                        setPurchaseOrderDetails((e) => ({
                          ...e,
                          poDescription: {
                            ...e.poDescription,
                            paymentStatus: newValue,
                          },
                        }));
                      } else {
                        setPurchaseOrderDetails((e) => ({
                          ...e,
                          poDescription: {
                            ...e.poDescription,
                            paymentStatus: {
                              value: -1,
                              label: '',
                            },
                          },
                        }));
                      }
                    }}
                    disabled={!isAddNewSupplierPage || pageViewerPO.isView()}
                    id="controllable-states"
                    options={optionPaymentStatusType}
                    getOptionLabel={(option: PaymentStatusTypes) =>
                      option.label
                    }
                    value={purchaseOrderDetails.poDescription.paymentStatus}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        id="outlined-required"
                        label="Payment Status"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </MUIGrid>
              )}
            </MUIGrid>
          </Box>
        )}
        {hasAlreadySupplier && (
          <Box marginBottom={1}>
            <MUIGrid
              container
              spacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={3}>
                <Input
                  placeholder="Company Name"
                  size="small"
                  value={purchaseOrderDetails.poDescription.companyName || ''}
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  onChange={(v) =>
                    setPurchaseOrderDetails((e) => ({
                      ...e,
                      poDescription: {
                        ...e.poDescription,
                        companyName: v.target.value,
                      },
                    }))
                  }
                  {...(purchaseOrderDetailsFormError.companyName && {
                    error: true,
                    helperText: purchaseOrderDetailsFormError.companyName,
                  })}
                  required
                  disabled={pageViewerPO.isViewOrEdit()}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Autocomplete
                  disableClearable
                  onChange={(event: any, newValue: CustomerType) => {
                    if (newValue !== null) {
                      setPurchaseOrderDetails((e) => ({
                        ...e,
                        poDescription: {
                          ...e.poDescription,
                          paymentStatus: newValue,
                        },
                      }));
                    } else {
                      setPurchaseOrderDetails((e) => ({
                        ...e,
                        poDescription: {
                          ...e.poDescription,
                          paymentStatus: { value: -1, label: '' },
                        },
                      }));
                    }
                  }}
                  disabled={pageViewerPO.isView()}
                  id="controllable-states"
                  options={optionPaymentStatusType}
                  getOptionLabel={(option: PaymentStatusTypes) => option.label}
                  value={purchaseOrderDetails.poDescription.paymentStatus}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Payment Status"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
        )}
        {!isAddNewSupplierPage && (
          <Box sx={{ height: '100%', overflowY: 'auto', padding: ' 10px 0px' }}>
            <MUIGrid
              container
              spacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={3}>
                <Input
                  placeholder="Company Name"
                  size="small"
                  value={purchaseOrderDetails.poDescription.companyName || ''}
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  onChange={(v) =>
                    setPurchaseOrderDetails((e) => ({
                      ...e,
                      poDescription: {
                        ...e.poDescription,
                        companyName: v.target.value,
                      },
                    }))
                  }
                  {...(purchaseOrderDetailsFormError.companyName && {
                    error: true,
                    helperText: purchaseOrderDetailsFormError.companyName,
                  })}
                  required
                  disabled={pageViewerPO.isView()}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Autocomplete
                  disableClearable
                  onChange={(event: any, newValue: CustomerType) => {
                    if (newValue !== null) {
                      setPurchaseOrderDetails((e) => ({
                        ...e,
                        poDescription: {
                          ...e.poDescription,
                          paymentStatus: newValue,
                        },
                      }));
                    } else {
                      setPurchaseOrderDetails((e) => ({
                        ...e,
                        poDescription: {
                          ...e.poDescription,
                          paymentStatus: {
                            value: -1,
                            label: '',
                          },
                        },
                      }));
                    }
                  }}
                  disabled={pageViewerPO.isView()}
                  id="controllable-states"
                  options={optionPaymentStatusType}
                  getOptionLabel={(option: PaymentStatusTypes) => option.label}
                  value={purchaseOrderDetails.poDescription.paymentStatus}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Payment Status"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Input
                  placeholder="Recipient Name"
                  size="small"
                  value={purchaseOrderDetails.poDescription.recipientName || ''}
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  onChange={(v) =>
                    setPurchaseOrderDetails((e) => ({
                      ...e,
                      poDescription: {
                        ...e.poDescription,
                        recipientName: v.target.value,
                      },
                    }))
                  }
                  {...(purchaseOrderDetailsFormError.recipientName && {
                    error: true,
                    helperText: purchaseOrderDetailsFormError.recipientName,
                  })}
                  disabled={pageViewerPO.isView()}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Input
                  placeholder="Phone"
                  size="small"
                  value={purchaseOrderDetails.poDescription.phone || ''}
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  onChange={(v) =>
                    setPurchaseOrderDetails((e) => ({
                      ...e,
                      poDescription: {
                        ...e.poDescription,
                        phone: v.target.value,
                      },
                    }))
                  }
                  {...(purchaseOrderDetailsFormError.phone && {
                    error: true,
                    helperText: purchaseOrderDetailsFormError.phone,
                  })}
                  disabled={pageViewerPO.isView()}
                  required
                />
              </MUIGrid>

              <MUIGrid item xs={3}>
                <TextField
                  inputProps={{
                    'data-state': 'Email',
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="Email"
                  size="small"
                  name="Email"
                  value={purchaseOrderDetails.poDescription.email || ''}
                  onChange={(v) =>
                    setPurchaseOrderDetails((e) => ({
                      ...e,
                      poDescription: {
                        ...e.poDescription,
                        email: v.target.value,
                      },
                    }))
                  }
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  disabled={pageViewerPO.isView()}
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <MUIGrid
                  container
                  spacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={3}>
                    <TextField
                      inputProps={{
                        'data-state': 'Address1',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Address 1"
                      size="small"
                      name="Address 1"
                      value={purchaseOrderDetails.poDescription.address1 || ''}
                      onChange={(v) =>
                        setPurchaseOrderDetails((e) => ({
                          ...e,
                          poDescription: {
                            ...e.poDescription,
                            address1: v.target.value,
                          },
                        }))
                      }
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      disabled={pageViewerPO.isView()}
                      {...(purchaseOrderDetailsFormError?.address1 && {
                        error: true,
                        helperText: purchaseOrderDetailsFormError?.address1,
                      })}
                      required
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      inputProps={{
                        'data-state': 'address2',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Address 2"
                      size="small"
                      name="Address 2"
                      value={purchaseOrderDetails.poDescription.address2 || ''}
                      onChange={(v) =>
                        setPurchaseOrderDetails((e) => ({
                          ...e,
                          poDescription: {
                            ...e.poDescription,
                            address2: v.target.value,
                          },
                        }))
                      }
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      disabled={pageViewerPO.isView()}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      inputProps={{
                        'data-state': 'City/Township',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="City/Township"
                      size="small"
                      name="City/Township"
                      value={
                        purchaseOrderDetails.poDescription.cityTownship || ''
                      }
                      onChange={(v) =>
                        setPurchaseOrderDetails((e) => ({
                          ...e,
                          poDescription: {
                            ...e.poDescription,
                            cityTownship: v.target.value,
                          },
                        }))
                      }
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      disabled={pageViewerPO.isView()}
                      {...(purchaseOrderDetailsFormError?.cityTownship && {
                        error: true,
                        helperText: purchaseOrderDetailsFormError?.cityTownship,
                      })}
                      required
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      inputProps={{
                        'data-state': 'State/Province/Region',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="State/Province/Region"
                      size="small"
                      name="State/Province/Region"
                      value={
                        purchaseOrderDetails.poDescription
                          .stateProvinceRegion || ''
                      }
                      onChange={(v) =>
                        setPurchaseOrderDetails((e) => ({
                          ...e,
                          poDescription: {
                            ...e.poDescription,
                            stateProvinceRegion: v.target.value,
                          },
                        }))
                      }
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      disabled={pageViewerPO.isView()}
                    />
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
              <MUIGrid item xs={12}>
                <MUIGrid
                  container
                  spacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={3}>
                    <TextField
                      inputProps={{
                        'data-state': 'ZIP/PostalCode',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="ZIP/Postal Code"
                      size="small"
                      name="ZIP/Postal Code"
                      value={
                        purchaseOrderDetails.poDescription.zipPostalCode || ''
                      }
                      onChange={(v) =>
                        setPurchaseOrderDetails((e) => ({
                          ...e,
                          poDescription: {
                            ...e.poDescription,
                            zipPostalCode: v.target.value,
                          },
                        }))
                      }
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      disabled={pageViewerPO.isView()}
                      {...(purchaseOrderDetailsFormError?.zipPostalCode && {
                        error: true,
                        helperText:
                          purchaseOrderDetailsFormError?.zipPostalCode,
                      })}
                      required
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <Autocomplete
                      disableClearable
                      onChange={(event: any, newValue: CustomerType) => {
                        if (newValue !== null) {
                          setPurchaseOrderDetails((e) => ({
                            ...e,
                            poDescription: {
                              ...e.poDescription,
                              country: newValue,
                            },
                          }));
                        } else {
                          setPurchaseOrderDetails((e) => ({
                            ...e,
                            poDescription: {
                              ...e.poDescription,
                              country: null,
                            },
                          }));
                        }
                      }}
                      id="controllable-states"
                      options={optionCountries}
                      getOptionLabel={(option: CustomerType) => option.label}
                      value={purchaseOrderDetails.poDescription.country}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          {...(purchaseOrderDetailsFormError.country && {
                            error: true,
                            helperText: purchaseOrderDetailsFormError.country,
                          })}
                          autoComplete="off"
                          id="outlined-required"
                          label="Country"
                          size="small"
                          sx={{ width: '100%' }}
                          required
                        />
                      )}
                      disabled={pageViewerPO.isView()}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      inputProps={{
                        'data-state': 'Supplier Identifier',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Supplier Identifier"
                      size="small"
                      name="Supplier Identifier"
                      value={
                        purchaseOrderDetails.poDescription.supplierIdentifier ||
                        ''
                      }
                      onChange={(v) =>
                        setPurchaseOrderDetails((e) => ({
                          ...e,
                          poDescription: {
                            ...e.poDescription,
                            supplierIdentifier: v.target.value,
                          },
                        }))
                      }
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      disabled={pageViewerPO.isView()}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      inputProps={{
                        'data-state': 'Department No',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Department No."
                      size="small"
                      name="Department No."
                      value={
                        purchaseOrderDetails.poDescription.departmentNo || ''
                      }
                      onChange={(v) =>
                        setPurchaseOrderDetails((e) => ({
                          ...e,
                          poDescription: {
                            ...e.poDescription,
                            departmentNo: v.target.value,
                          },
                        }))
                      }
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      disabled={pageViewerPO.isView()}
                    />
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
          </Box>
        )}
      </Card>
      <Card
        sx={{
          marginLeft: 2,
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          width: '35%',
        }}
      >
        <Typography variant="body1" fontWeight="bold" mb={1}>
          Change Log
        </Typography>
        <MUIGrid container spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={12}>
            <DatagridStandard
              sx={{
                [`& .${gridClasses.cell}`]: {
                  maxHeight: 'none !important;',
                  height: 'auto !important;',
                  wrapText: true,
                },
                [`& .${gridClasses.row}`]: {
                  maxHeight: 'none !important;',
                  height: 'auto !important;',
                  wrapText: true,
                },
                [`& .${gridClasses}`]: {
                  maxHeight: 'none !important;',
                  height: '100% !important;',
                  wrapText: true,
                },
                [`& .${gridClasses.virtualScroller}`]: {
                  height: '220px !important;',
                  wrapText: true,
                },
              }}
              autoHeight
              rows={purchaseOrderDetails.poActivityLog}
              columns={purchaseOrderActivityLog}
              density="compact"
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              hideFooterSelectedRowCount
              hideFooter
              getRowId={() => Math.random()}
            />
          </MUIGrid>
        </MUIGrid>
      </Card>
    </Box>
  );
};

const PurchaseOrderLineItems = () => {
  const { onOpenPOLineItemModal, onOpenPODeleteLineItemModal } =
    useContext(GlobalContext);
  const { currentUser, isWarehouseCustomerAccount, handleUserPermissionAllow } =
    useContext(AuthContext);
  const navigate = useNavigate();

  const {
    purchaseOrderDetails,
    pageViewerPO,
    optionCustomerFacility,
    purchaseOrderDetailsFormError,
    setPurchaseOrderDetails,
    setPurchaseOrderLineItemModal,
    getFees,
  } = usePurchaseOrderContext();

  const handleOpenEditPOLineItemModal = (rowData) => {
    setPurchaseOrderLineItemModal({
      dataGridId: rowData.row.dataGridId,
      receiveId: rowData?.row.receiveId,
      item: rowData.row.item,
      itemId: rowData.row.itemId,
      sku: rowData.row.sku,
      description: rowData.row.description,
      qty: rowData.row.qty,
      unitCost: rowData.row.unitCost,
      shippingCost: rowData.row.shippingCost,
      itemCostSheets: rowData.row.itemCostSheets,
    });

    onOpenPOLineItemModal();
  };

  const handleOpenDeletePOLineItemModal = (rowData) => {
    setPurchaseOrderLineItemModal({
      dataGridId: rowData.row.dataGridId,
      receiveId: rowData?.row.receiveId,
      sku: rowData?.row.sku,
    });
    onOpenPODeleteLineItemModal();
  };

  const handleOpenAddPOLineItemModal = () => {
    onOpenPOLineItemModal();
  };

  const purchaseOrderLineItemColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleOpenEditPOLineItemModal(params)}
          showInMenu
          disabled={pageViewerPO.isView() || isWarehouseCustomerAccount}
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => handleOpenDeletePOLineItemModal(params)}
          showInMenu
          disabled={pageViewerPO.isView() || isWarehouseCustomerAccount}
        />,
      ],
    },
    {
      field: 'lineIdCount',
      width: 90,
      renderHeader: () => <Typography>Line ID</Typography>,
      renderCell: (params) => <Typography>{params.row.lineIdCount}</Typography>,
    },
    {
      field: 'sku',
      minWidth: 150,
      flex: 1,
      renderHeader: () => <Typography>SKU</Typography>,
      renderCell: (params) => <Typography>{params.row.sku}</Typography>,
    },
    {
      field: 'description',
      minWidth: 150,
      flex: 1,
      renderHeader: () => <Typography>Description</Typography>,
      renderCell: (params) => <Typography>{params.row.description}</Typography>,
    },
    {
      field: 'qty',
      width: 100,
      renderHeader: () => <Typography>Qty</Typography>,
      renderCell: (params) => (
        <Typography>
          {params.row.qtyWithUom
            ? params.row.qtyWithUom
            : `${params.row.qty}${params.row.primaryInventoryUOMStr}`}
        </Typography>
      ),
    },
    {
      field: 'unitCost',
      minWidth: 150,
      flex: 0.5,
      renderHeader: () => <Typography>Unit Cost</Typography>,
      renderCell: (params) => (
        <Typography>
          ${params.row.unitCost ? params.row.unitCost : '0.00'}
        </Typography>
      ),
    },
    {
      field: 'shippingCost',
      minWidth: 150,
      flex: 0.5,
      renderHeader: () => <Typography>Shipping Cost</Typography>,
      renderCell: (params) => (
        <Typography>
          {params.row.shippingCost ? `$${params.row.shippingCost}` : ''}
        </Typography>
      ),
    },
  ];

  useEffect(() => {
    if (optionCustomerFacility.length === 1) {
      setPurchaseOrderDetails((e) => ({
        ...e,
        poLineItem: {
          ...e.poLineItem,
          shipTo: {
            value: optionCustomerFacility[0].value,
            label: optionCustomerFacility[0].label,
          },
        },
      }));
    }
    getFees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionCustomerFacility, purchaseOrderDetails.poLineItem.lineItems]);

  return (
    <Box sx={{ padding: '24px !important', paddingBottom: '5px !important' }}>
      <MUIGrid container spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={3}>
          <Autocomplete
            disableClearable
            onChange={(event: any, newValue: CustomerType) => {
              if (newValue !== null) {
                setPurchaseOrderDetails((e) => ({
                  ...e,
                  poLineItem: {
                    ...e.poLineItem,
                    shipTo: newValue,
                  },
                }));
              } else {
                setPurchaseOrderDetails((e) => ({
                  ...e,
                  poLineItem: { ...e.poLineItem, shipTo: null },
                }));
              }
            }}
            id="controllable-states"
            options={optionCustomerFacility}
            getOptionLabel={(option: CustomerType) => option.label}
            value={purchaseOrderDetails.poLineItem.shipTo}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                id="outlined-required"
                label="Ship To"
                size="small"
                sx={{ width: '100%' }}
                required
                {...(purchaseOrderDetailsFormError.shipTo && {
                  error: true,
                  helperText: purchaseOrderDetailsFormError.shipTo,
                })}
              />
            )}
            disabled={pageViewerPO.isView()}
          />
        </MUIGrid>

        <MUIGrid
          item
          xs={9}
          sx={{
            textAlignLast: 'right',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {pageViewerPO.isNew() && (
            <>
              <Checkbox
                color="primary"
                size="small"
                checked={
                  purchaseOrderDetails?.poLineItem.isCreatePackingListItems
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPurchaseOrderDetails((e) => ({
                    ...e,
                    poLineItem: {
                      ...e.poLineItem,
                      isCreatePackingListItems: event.target.checked,
                    },
                  }));
                }}
              />
              <p style={{ marginRight: '30px' }}>
                Create packing list for this PO
              </p>
            </>
          )}
          <Button
            variant="contained"
            size="small"
            disabled={pageViewerPO.isView()}
            sx={{
              padding: '8px !important;',
              textTransform: 'capitalize !important',
            }}
            onClick={() => handleOpenAddPOLineItemModal()}
          >
            Add Line Item
          </Button>
        </MUIGrid>
      </MUIGrid>

      <MUIGrid
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt={1}
      >
        <MUIGrid item xs={12}>
          <DatagridStandard
            autoHeight
            rows={purchaseOrderDetails.poLineItem.lineItems}
            columns={purchaseOrderLineItemColumns}
            pageSize={25}
            density="compact"
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            hideFooterSelectedRowCount
            rowsPerPageOptions={[25]}
            getRowId={() => Math.random()}
          />
        </MUIGrid>
        <MUIGrid item xs={12}>
          <MUIGrid
            container
            spacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ justifyContent: 'right' }}
          >
            <MUIGrid item xs={3}>
              <MUIGrid
                container
                spacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ margin: 1.5 }}
              >
                <MUIGrid item xs={6}>
                  <Typography variant="body2">
                    <b>Subtotal</b> (QTY:
                    {purchaseOrderDetails.totalQuantity}):
                  </Typography>
                </MUIGrid>
                <MUIGrid item xs={6} sx={{ textAlignLast: 'right' }}>
                  <Typography variant="body2" fontWeight="bold">
                    $
                    {purchaseOrderDetails.subTotalCost
                      ? formatMoney(purchaseOrderDetails.subTotalCost)
                      : '0.00'}
                  </Typography>
                </MUIGrid>
              </MUIGrid>
              <MUIGrid
                container
                spacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ margin: 1.5 }}
              >
                <MUIGrid item xs={6}>
                  <Typography variant="body2">
                    <b>Shipping Fees</b>
                  </Typography>
                </MUIGrid>
                <MUIGrid item xs={6} sx={{ textAlignLast: 'right' }}>
                  <Typography variant="body2" fontWeight="bold">
                    $
                    {purchaseOrderDetails.shippingFees
                      ? formatMoney(purchaseOrderDetails.shippingFees)
                      : '0.00'}
                  </Typography>
                </MUIGrid>
              </MUIGrid>
              <MUIGrid
                container
                spacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ display: 'flex', alignItems: 'baseline' }}
              >
                <MUIGrid item xs={6}>
                  <Typography variant="body2">
                    <b>Adjustments</b>
                  </Typography>
                </MUIGrid>
                <MUIGrid item xs={6} sx={{ textAlignLast: 'right' }}>
                  <Input
                    type="number"
                    placeholder="Amount"
                    size="small"
                    value={purchaseOrderDetails.adjustmentAmount}
                    onChange={(e) => {
                      const validateDecimal = decimalValidationHelper(
                        e.target.value,
                      );

                      const total =
                        Number(purchaseOrderDetails.subTotalCost) +
                        Number(purchaseOrderDetails.shippingFees);

                      setPurchaseOrderDetails((v) => ({
                        ...v,
                        adjustmentAmount: validateDecimal,
                        totalCost: (total + Number(validateDecimal)).toString(),
                      }));
                    }}
                    sx={{
                      width: '100%',
                      backgroundColor: '#ffffff !important',
                      marginBottom: '16px',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    disabled={pageViewerPO.isView()}
                  />
                </MUIGrid>
              </MUIGrid>
              <Divider sx={{ mb: 2 }} />
              <MUIGrid
                container
                spacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={6}>
                  <Typography variant="body2">
                    <b>Total:</b>
                  </Typography>
                </MUIGrid>
                <MUIGrid item xs={6} sx={{ textAlignLast: 'right' }}>
                  <Typography variant="body2" fontWeight="bold">
                    $
                    {purchaseOrderDetails.totalCost
                      ? formatMoney(purchaseOrderDetails.totalCost)
                      : '0.00'}
                  </Typography>
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
      </MUIGrid>
    </Box>
  );
};

const PurchaseOrderTabInterface = () => {
  const theme = useTheme();
  const query = useQuery();

  const {
    setGlobalMessageModal,
    setIsPackingListNotesModalOpen,
    setIsPackingListImportModalOpen,
    onOpenPOPackingListLineItemModal,
    onOpenPODeletePackingListLineItemModal,
  } = useContext(GlobalContext);

  const { isWarehouseCustomerAccount, isSupplierAccount } =
    useContext(AuthContext);

  const {
    pageViewerPO,
    purchaseOrderDetails,
    purchaseOrderTabValue,
    optionCustomerFacility,
    poPackingListFormError,
    handleChangeTabValue,
    setPackinglistIdImport,
    setPurchaseOrderDetails,
    setPurchaseOrderTabValue,
    setPoPackingListFormError,
    handleOnCreatePurchaseOrder,
    setPurchaseOrderPackingListModal,
    addPurchaseOrderPackingListLineItem,
    setPurchaseOrderPackingListLineItemModal,
    validationForItemQtyLimit,
  } = usePurchaseOrderContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditPackingListLineItemModal = (rowData) => {
    setPurchaseOrderPackingListModal((e) => ({
      ...e,
      packingListCount: rowData.row.packingListCount,
    }));

    setPurchaseOrderPackingListLineItemModal({
      packingListCount: rowData.row.packingListCount,
      dataGridId: rowData?.row.dataGridId,
      packingListLineItemId: rowData?.row.packingListLineItemId,
      receiveId: rowData?.row.receiveId,
      item: rowData.row.item,
      binItemType: rowData.row.binItemType,
      palletCount: rowData.row.palletCount,
      weight: rowData.row.weight,
      lotNoValue: rowData.row.lotNoValue,
      itemId: rowData.row.itemId,
      itemUnitOfMeasureId: rowData.row.itemUnitOfMeasureId,
      sku: rowData.row.sku,
      description: rowData.row.description,
      isSerialNo: rowData.row.isSerialNo,
      serialNo: rowData.row.serialNo,
      eaCase: rowData?.row?.eaCase,
      totalCases: rowData?.row?.totalCases,
      eachesPerCase: rowData?.row?.eachesPerCase,
      qty: rowData.row.qty,
      received: rowData.row.received,
      unitCost: rowData.row.unitCost,
      notes: rowData.row.notes,
      primaryUOMAbbr: rowData.row.primaryUOMAbbr,
      otherUOMAbbr: rowData.row.otherUOMAbbr,
    });

    onOpenPOPackingListLineItemModal();
  };

  const handleOpenDeletePackingListLineItemModal = (rowData) => {
    setPurchaseOrderPackingListLineItemModal({
      packingListCount: rowData.row.packingListCount,
      dataGridId: rowData?.row.dataGridId,
      packingListLineItemId: rowData?.row.packingListLineItemId,
      receiveId: rowData?.row.receiveId,
      sku: rowData.row.sku,
      isSerialNo: rowData.row.isSerialNo,
    });
    onOpenPODeletePackingListLineItemModal();
  };

  const handleEstimatedArriavalValidation = () => {
    const temp: any = [];

    let results = [];
    let result = [];
    const datas = [...purchaseOrderDetails.poPackingList];
    datas.forEach((value) => {
      result.push(
        value.estimatedArrival && value.estimatedArrival !== 'Invalid date'
          ? null
          : 'This field is required',
      );
      results = [...results, result];
      result = [];
    });
    temp.poPackingList = results;

    setPoPackingListFormError({
      ...temp,
    });
    let IsReturnTrue = true;
    temp.poPackingList.forEach((element) => {
      if (!Object.values(element).every((x) => x === null))
        IsReturnTrue = false;
    });

    return IsReturnTrue;
  };

  const handleOpenImportPackingListLineModal = (packingListId) => {
    setPackinglistIdImport(packingListId);
    setIsPackingListImportModalOpen(true);
  };
  const handleOpenAddPackingListLineItemModal = (
    rowData: any,
    indexOfPackinglist: number,
  ) => {
    if (!validationForItemQtyLimit(true)) {
      snackActions.error(
        'All PO items have already been added to the Packing list. You can add more to the PO.',
      );
      return;
    }

    setPurchaseOrderPackingListModal((e) => ({
      ...e,
      packingListCount: rowData.packingListCount
        ? rowData.packingListCount
        : indexOfPackinglist,
    }));
    setPurchaseOrderPackingListLineItemModal((e) => ({
      ...e,
      packingListCount: rowData.packingListCount
        ? rowData.packingListCount
        : indexOfPackinglist,
      binItemType: { value: 1, label: 'No' },
      palletCount: '',
      weight: '',
      qty: '',
    }));
    onOpenPOPackingListLineItemModal();
  };

  const purchaseOrderPackingListLineItemColumns = (
    isShowLots: boolean,
    isShowNoOfPallets: boolean,
  ): GridColumns => [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleOpenEditPackingListLineItemModal(params)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => handleOpenDeletePackingListLineItemModal(params)}
          showInMenu
        />,
      ],
    },
    {
      field: 'lineIdCount',
      width: 200,
      renderHeader: () => <Typography>Line ID</Typography>,
      renderCell: (params) => <Typography>{params.row.lineIdCount}</Typography>,
    },
    {
      field: 'sku',
      width: 200,
      renderHeader: () => <Typography>SKU</Typography>,
      renderCell: (params) => <Typography>{params.row.sku}</Typography>,
    },
    {
      field: 'description',
      width: 200,
      renderHeader: () => <Typography>Description</Typography>,
      renderCell: (params) => <Typography>{params.row.description}</Typography>,
    },
    {
      field: 'lotNo',
      width: 150,
      hide: isShowLots,
      renderHeader: () => <Typography>Lot No</Typography>,
      renderCell: (params) => <Typography>{params.row.lotNo}</Typography>,
    },
    {
      field: 'lotExpirationDate',
      width: 200,
      hide: isShowLots,
      renderHeader: () => <Typography>Expiration Date</Typography>,
      renderCell: (params) => (
        <Typography>
          {params.row.lotExpirationDate &&
            moment(params.row.lotExpirationDate).format('MM/DD/yyyy')}{' '}
          {isShowLots}
        </Typography>
      ),
    },
    {
      field: 'qty',
      width: 200,
      renderHeader: () => <Typography>Qty</Typography>,
      // renderCell: (params) => <Typography>{params.row.qty}</Typography>,
      renderCell: (params) => (
        <Typography>
          {params?.row?.totalCases
            ? `${params.row.totalCases.toLocaleString()} ${
                params?.row?.otherUOMAbbr
              } (${params.row.otherUOMCount} ${params.row.primaryUOMAbbr})`
            : `${params.row.qty.toLocaleString()} ${params.row.primaryUOMAbbr}`}
        </Typography>
      ),
    },
    {
      field: 'palletCount',
      width: 200,
      hide: isShowNoOfPallets,
      renderHeader: () => <Typography># of Pallets</Typography>,
      renderCell: (params) => <Typography>{params.row.palletCount}</Typography>,
    },
    {
      field: 'totalqty',
      width: 200,
      renderHeader: () => <Typography>Total Quantity</Typography>,
      renderCell: (params) => (
        <Typography>
          {params.row?.palletCount
            ? params.row.palletCount * params.row.qty
            : params.row.qty}{' '}
          {params.row.primaryUOMAbbr}
        </Typography>
      ),
    },
    {
      field: 'received',
      width: 150,
      renderHeader: () => <Typography>Received</Typography>,
      renderCell: (params) => <Typography>{params.row.received}</Typography>,
    },
    {
      field: 'unitCost',
      width: 150,
      renderHeader: () => <Typography>Unit Cost</Typography>,
      renderCell: (params) => (
        <Typography>
          ${params.row.unitCost ? params.row.unitCost : '0.00'}
        </Typography>
      ),
    },
    {
      field: 'receipts',
      minWidth: 200,
      flex: 0.5,
      renderHeader: () => <Typography>Receipt(s)</Typography>,
      renderCell: (params) =>
        params.row.receipts &&
        params.row.receipts.length > 0 &&
        params.row.receipts.map((v, index) => (
          <Link
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: '#303538',
              textDecoration: 'none',
            }}
            href={`/receiving/receipts/subpage?form=view&id=${v.value}`}
            target="_blank"
          >
            <Typography
              sx={{
                cursor: 'pointer',
                color: [theme.palette.primary.main],
              }}
            >
              {v?.label}
              {index + 1 < params.row.receipts.length ? ',' : ''}&ensp;
            </Typography>
          </Link>
        )),
    },
    {
      field: 'notes',
      width: 150,
      renderHeader: () => <Typography>Notes</Typography>,
      renderCell: (params) =>
        params.row?.notes && (
          <Typography>
            <IconButton
              aria-label="print"
              color="primary"
              onClick={() => {
                setGlobalMessageModal(params.row?.notes);
                setIsPackingListNotesModalOpen(true);
              }}
            >
              <NoteIcon />
            </IconButton>
          </Typography>
        ),
    },
  ];

  const handleFormChangePOPackingList = (
    arrayInput,
    index,
    event?,
    newValueDDL?,
    targetName?,
  ) => {
    const datas = [...arrayInput];
    const data = { ...datas[index] };

    if (targetName === 'shipTo') {
      data.shipTo = newValueDDL;
    } else if (targetName === 'shipDate') {
      data.shipDate = newValueDDL;
    } else if (targetName === 'estimatedArrival') {
      data.estimatedArrival = moment(newValueDDL).format('MM/DD/yyyy');
    } else if (event.target.name === 'shippingCost') {
      const validateDecimal = decimalValidationHelper(event.target.value);
      data[event.target.name] = validateDecimal;
    } else {
      data[event.target.name] = event.target.value;
    }

    datas[index] = data;
    setPurchaseOrderDetails((e) => ({
      ...e,
      poPackingList: datas,
    }));
  };

  const removePackingList = (index) => {
    const data = [...purchaseOrderDetails.poPackingList];
    data.splice(index, 1);
    setPurchaseOrderDetails((e) => ({
      ...e,
      poPackingList: data,
    }));

    setPurchaseOrderTabValue(`${index}`);
  };

  const addAllItemsToPackingList = (index) => {
    if (!validationForItemQtyLimit(false)) {
      snackActions.error(
        'All PO items have already been added to the Packing list. You can add more to the PO.',
      );
      return;
    }

    const data = [...purchaseOrderDetails.poPackingList];
    const dataFormPoLineItem = [...purchaseOrderDetails.poLineItem.lineItems];

    const rowData = data[index];

    const getPOLineItems: IPurchaseOrderPackingListLineItem[] =
      dataFormPoLineItem.map((v) => ({
        dataGridId: Math.random(),
        item: v?.item,
        binItemType: { value: 1, label: 'No' },
        receiveId: v?.receiveId,
        itemId: v?.itemId,
        sku: v?.sku,
        description: v?.description,
        isSerialNo: v?.isSerialNo,
        qty: v?.qty,
        unitCost: v?.unitCost,
      }));

    rowData.packingLineItems = [...rowData.packingLineItems, ...getPOLineItems];

    setPurchaseOrderDetails((e) => ({
      ...e,
      poPackingList: data,
    }));

    addPurchaseOrderPackingListLineItem();
    handleOnCreatePurchaseOrder();

    setPurchaseOrderTabValue(`${index + 1}`);
  };

  const PurchaseOrderLineItemsInterface: tabProps[] = [
    {
      title: (
        <Typography
          fontWeight="bold"
          sx={{
            textTransform: 'capitalize !important',
            marginTop:
              purchaseOrderDetails?.poPackingList?.length > 0 && '10px',
          }}
        >
          PO Line Items
        </Typography>
      ),
      value: '0',
      isDisplay: true,
      children: <PurchaseOrderLineItems />,
    },
  ];

  const PackingListInterface: tabProps[] =
    purchaseOrderDetails.poPackingList &&
    purchaseOrderDetails.poPackingList.map((row, index) => ({
      title: (
        <>
          <Typography
            fontWeight="bold"
            sx={{
              textTransform: 'capitalize !important',
            }}
          >
            Packing List {Number(index + 1)}
          </Typography>
          <Typography
            sx={{
              textTransform: 'capitalize !important',
              marginBottom: '10px',
            }}
          >
            {row?.isConfirmedReceipt ? (
              <Chip
                sx={{ marginRight: '5px' }}
                label="Received"
                size="small"
                className="statusGreenChip"
              />
            ) : (
              <Chip
                sx={{ marginRight: '5px' }}
                label="Pending"
                size="small"
                className="statusRedChip"
              />
            )}
            {row?.isConfirmedReceipt}
            {row?.shipDate && moment(row.shipDate).format('MM/DD/yyyy')}
          </Typography>
        </>
      ),
      value: `${Number(index) + 1}`,
      isDisplay: true,
      children: (
        <Box
          sx={{ padding: '24px !important', paddingBottom: '0px !important' }}
        >
          <MUIGrid
            container
            spacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid
              sx={{
                textAlign: 'right',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              item
              xs={12}
              marginBottom={1}
            >
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Actions
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  disableRipple
                  onClick={() => {
                    handleOpenImportPackingListLineModal(row.packingListId);
                  }}
                >
                  Import Packing List
                </MenuItem>
                <MenuItem
                  disableRipple
                  onClick={() =>
                    handleOpenAddPackingListLineItemModal(
                      row.packingLineItems,
                      index,
                    )
                  }
                >
                  Add Line Item
                </MenuItem>
                <MenuItem
                  disableRipple
                  onClick={() => addAllItemsToPackingList(index)}
                >
                  Add All PO Items
                </MenuItem>
              </StyledMenu>
              {pageViewerPO.isView() &&
                !isWarehouseCustomerAccount &&
                !isSupplierAccount && (
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      padding: '8px !important;',
                      textTransform: 'capitalize !important',
                      marginLeft: '10px',
                    }}
                    disabled={row?.packingLineItems?.length === 0}
                  >
                    <Link
                      sx={{
                        textDecoration: 'none',
                      }}
                      href={`/receiving/receipts/subpage?form=new&lineItem=${row.packingListId}`}
                      target="_blank"
                    >
                      <Typography
                        sx={{
                          fontSize: '0.9rem',
                          cursor: 'pointer',
                        }}
                      >
                        Receive this Packing List
                      </Typography>
                    </Link>
                  </Button>
                )}
              {row?.packingLineItems?.length === 0 && (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: '8px !important;',
                    textTransform: 'capitalize !important',
                    marginLeft: '10px',
                  }}
                  onClick={() => removePackingList(index)}
                >
                  Remove Packing List
                </Button>
              )}
            </MUIGrid>
            <MUIGrid item xs={2.4}>
              <Autocomplete
                onChange={(event: any, newValue: CustomerType) => {
                  handleFormChangePOPackingList(
                    purchaseOrderDetails.poPackingList,
                    index,
                    event,
                    newValue,
                    'shipTo',
                  );
                }}
                id="controllable-states"
                options={optionCustomerFacility}
                getOptionLabel={(option: CustomerType) => option.label}
                value={row.shipTo}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined-required"
                    label="Ship To"
                    size="small"
                    sx={{ width: '100%' }}
                    name="shipTo"
                    required
                  />
                )}
                disabled={pageViewerPO.isView() && !isSupplierAccount}
              />
            </MUIGrid>
            <MUIGrid item xs={2.4}>
              <DatePickerInput
                label="Ship Date"
                inputFormat="MM/dd/yyyy"
                value={row?.shipDate}
                onChange={(value) => {
                  handleFormChangePOPackingList(
                    purchaseOrderDetails.poPackingList,
                    index,
                    null,
                    value,
                    'shipDate',
                  );
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
                disabled={pageViewerPO.isView() && !isSupplierAccount}
              />
            </MUIGrid>
            <MUIGrid item xs={2.4}>
              <DatePickerInput
                label="Estimated Arrival"
                inputFormat="MM/dd/yyyy"
                value={row.estimatedArrival}
                onChange={(value) => {
                  handleFormChangePOPackingList(
                    purchaseOrderDetails.poPackingList,
                    index,
                    null,
                    value,
                    'estimatedArrival',
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    {...(poPackingListFormError.poPackingList !== undefined && {
                      error: poPackingListFormError.poPackingList[index]
                        ? poPackingListFormError.poPackingList[index][0]
                        : null,
                      helperText: poPackingListFormError.poPackingList[index]
                        ? poPackingListFormError.poPackingList[index][0]
                        : null,
                    })}
                  />
                )}
                disabled={pageViewerPO.isView() && !isSupplierAccount}
              />
            </MUIGrid>
            <MUIGrid item xs={2.4}>
              <TextField
                inputProps={{
                  'data-state': 'carrier',
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                id="outlined"
                label="Carrier"
                size="small"
                name="carrier"
                value={row.carrier}
                onChange={(value) => {
                  handleFormChangePOPackingList(
                    purchaseOrderDetails.poPackingList,
                    index,
                    value,
                  );
                }}
                sx={{
                  width: '100%',
                  backgroundColor: '#ffffff !important',
                }}
                disabled={pageViewerPO.isView() && !isSupplierAccount}
              />
            </MUIGrid>
            <MUIGrid item xs={2.4}>
              <TextField
                autoComplete="off"
                id="outlined"
                label={
                  <div style={{ display: 'flex' }}>
                    <p>Tracking No.</p>
                    <Tooltip
                      title={
                        <h5>
                          Use comma(,) to separate multiple tracking numbers
                        </h5>
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: 'white ',
                            backgroundColor: 'rgb(46, 105, 255)',
                          },
                        },
                      }}
                    >
                      <InfoIcon />
                    </Tooltip>
                  </div>
                }
                size="small"
                name="trackingNo"
                value={row.trackingNo}
                onChange={(value) => {
                  handleFormChangePOPackingList(
                    purchaseOrderDetails.poPackingList,
                    index,
                    value,
                  );
                }}
                sx={{
                  width: '100%',
                  backgroundColor: '#ffffff !important',
                }}
                disabled={pageViewerPO.isView() && !isSupplierAccount}
              />
            </MUIGrid>
          </MUIGrid>
          <MUIGrid
            container
            spacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            mt={1}
          >
            <MUIGrid item xs={12}>
              <DatagridStandard
                key={row.packingListId}
                autoHeight
                rows={row.packingLineItems}
                columns={purchaseOrderPackingListLineItemColumns(
                  !row.packingLineItems.every((x) => x?.islotDisplay),
                  row.packingLineItems.every(
                    (x) =>
                      x?.palletCount === undefined || x?.palletCount === null,
                  ),
                )}
                pageSize={25}
                density="compact"
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                hideFooterSelectedRowCount
                rowsPerPageOptions={[25]}
                getRowId={() => Math.random()}
              />
            </MUIGrid>
          </MUIGrid>
        </Box>
      ),
    }));

  return (
    <>
      <DocumentsModal />
      <CardTab
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0px !important',
          overflow: 'auto',
          borderRadius: 2,
          border: '1px solid #cdcdcd',
        }}
        tabValue={purchaseOrderTabValue}
        handleChange={handleChangeTabValue}
        tabView={[...PurchaseOrderLineItemsInterface, ...PackingListInterface]}
      />
    </>
  );
};

const PurchaseOrderDetails = () => (
  <MainContainer>
    <PurchaseOrderContextProvider>
      <Header />
      <ContentContainer>
        <PackingListImportModal />
        <PackingListNotesModal />
        <PackingListErrorImportModal />
        <POlineItemModal />
        <PODeletelineItemModal />
        <PurchaseOrderHeaderCard />
        <PurchaseOrderDescription />
        <PurchaseOrderTabInterface />
      </ContentContainer>
    </PurchaseOrderContextProvider>
  </MainContainer>
);

export default React.memo(PurchaseOrderDetails);
