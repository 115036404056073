/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import Button from 'components/button';
import DatePickerInput from 'components/datepicker';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import {
  FormQueryStringStatus,
  useCycleCountContext,
} from 'pages/cyclecount/context';
import { CustomerType } from 'pages/production/context';
import {
  createCycleCount,
  getItemsByCustomerId,
  getUsersByFacilityList,
} from 'services/api/cyclecount/cyclecount.api';
import { getWarehouseCustomerLookup } from 'services/api/inventory/inventory-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Box,
  Divider,
  Modal,
  TextField,
  Typography,
  Grid as MUIGrid,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import { MUIContainer } from '../productionpickticketmodal';
import { MUIContent } from '../productionreportmodal';

type AutocompleteBase = {
  value?: number;
  label?: string;
};
type CategoryType = AutocompleteBase;

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const binCategoryOptions: CategoryType[] = [
  {
    value: 1,
    label: 'Unassigned',
  },
  {
    value: 2,
    label: 'Picking',
  },
  {
    value: 3,
    label: 'Bulk',
  },
];
enum cycleCountType {
  Item = 1,
  Location = 2,
}

export default React.memo(() => {
  const query = useQuery();
  const navigate = useNavigate();

  const {
    isCycleCountModalOpen,
    onCloseCycleCountModal,
    isCycleCountDetailModalOpen,
    onCloseCycleCountDetailModal,
  } = useContext(GlobalContext);

  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);

  const {
    cycleCountUpdateCountDetailModal,
    queryStringItemCycleCount,
    pageViewer,
    OnLoadCycleCountDetail,
    setCycleCountUpdateCountDetailModal,
  } = useCycleCountContext();

  const [optionWarehouseCustomers, setOptionsWarehouseCustomers] = useState<
    CustomerType[]
  >([]);
  const [usersOption, setUsersOption] = useState<CustomerType[]>([]);
  const [itemsOption, setItemsOption] = useState<CustomerType[]>([]);

  const initialStateOfDataModal = {
    cycleCountName: '',
    warehouseCustomer: {
      value: -1,
      label: '',
    },
    dueDate: '',
    assignedUser: {
      value: -1,
      label: '',
    },
    locationType: [],
    itemType: [],
    isBlindAccount: true,
    isRecountDiscrepancy: false,
    isFlagItemsLotDiscrepancy: false,
    isFlagForRecount: false,
  };

  const [formData, setFormData] = useState<any>(initialStateOfDataModal);
  const [formDataErrors, setFormDataErrors] = useState<any>({});

  const onLoadWarehouseCustomerLookup = async () => {
    try {
      return await getWarehouseCustomerLookup(currentUser.Claim_CustomerId, '');
    } catch (err) {
      return err;
    }
  };

  const onLoadUserCustomerData = async () => {
    try {
      return await getUsersByFacilityList(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
      );
    } catch (err) {
      return err;
    }
  };

  const onLoadUItemsByCustomerData = async () => {
    try {
      return await getItemsByCustomerId(currentUser.Claim_CustomerId);
    } catch (err) {
      return err;
    }
  };

  const handleCloseCycleCountMainModal = () => {
    setFormData(initialStateOfDataModal);
    setFormDataErrors(null);
    onCloseCycleCountModal();
    onCloseCycleCountDetailModal();
  };

  const validateOnlick = (fieldValues = formData) => {
    const temp: any = {};

    if ('cycleCountName' in fieldValues) {
      temp.cycleCountName = fieldValues.cycleCountName
        ? ''
        : 'This field is required';
    }
    // if ('warehouseCustomer' in fieldValues) {
    //   temp.warehouseCustomer =
    //     fieldValues.warehouseCustomer.value !== -1
    //       ? ''
    //       : 'This field is required';
    // }
    if ('dueDate' in fieldValues) {
      temp.dueDate = fieldValues.dueDate ? '' : 'This field is required';
    }
    // if (query.get('type') === 'location') {
    //   if ('locationType' in fieldValues) {
    //     temp.locationType =
    //       fieldValues.locationType.length > 0 ? '' : 'This field is required';
    //   }
    // } else if (query.get('type') === 'item') {
    //   if ('itemType' in fieldValues) {
    //     temp.itemType =
    //       fieldValues.itemType.length > 0 ? '' : 'This field is required';
    //   }
    // }

    setFormDataErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const onHandleCycleCountCreate = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    const payload = {
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      customerId: parseInt(currentUser.Claim_CustomerId, 10),
      warehouseCustomerId: formData.warehouseCustomer
        ? formData.warehouseCustomer.value
        : null,
      cycleCountName: formData.cycleCountName,
      dueDate: moment(formData.dueDate).format('MM/DD/YYYY'),
      assignedToUserId: formData.assignedUser.value,
      isBlindAccount: formData.isBlindAccount,
      isRecountDiscrepancy: formData.isRecountDiscrepancy,
      isFlagItemsLotDiscrepancy: formData.isFlagItemsLotDiscrepancy,
      isFlagForRecount: formData.isFlagForRecount,
      cycleCountType:
        query.get('type') === 'location'
          ? cycleCountType.Location
          : cycleCountType.Item,
      locationTypes: formData.locationType
        ? Object.keys(formData.locationType).map(
            (key) => formData.locationType[key].value,
          )
        : null,
      itemTypes: formData.itemType
        ? Object.keys(formData.itemType).map(
            // eslint-disable-next-line no-return-assign
            (key) => formData.itemType[key].value,
          )
        : null,
    };

    if (validateOnlick()) {
      try {
        const result = await createCycleCount(payload);
        snackActions.success(
          `Cycle Count ${result.cycleCountName} has been successfully created.`,
        );

        if (result.cycleCountType === 1) {
          navigate(
            `/cycle-count/detail?type=${cycleCountType[
              cycleCountType.Item
            ].toLocaleLowerCase()}&form=${FormQueryStringStatus.View}&id=${
              result.cycleCountId
            }`,
          );
        } else {
          navigate(
            `/cycle-count/detail?type=${cycleCountType[
              cycleCountType.Location
            ].toLocaleLowerCase()}&form=${FormQueryStringStatus.View}&id=${
              result.cycleCountId
            }`,
          );
        }

        handleCloseCycleCountMainModal();
      } catch (err) {
        snackActions.error(err);
      }
    }
  };

  useEffect(() => {
    const optionsCustomer = onLoadWarehouseCustomerLookup();
    optionsCustomer
      .then((opt) => {
        setOptionsWarehouseCustomers(
          opt.map((c: any) => ({
            label: c.name,
            value: c.warehouseCustomerId,
          })),
        );
      })
      .catch((err) => console.log(err));

    const optionsUserCustomer = onLoadUserCustomerData();
    optionsUserCustomer
      .then((opt) => {
        setUsersOption(
          opt.map((c: any) => ({
            label: `${c.firstName} ${c.lastName}`,
            value: c.userId,
          })),
        );
      })
      .catch((err) => console.log(err));

    const optionsItemsCustomer = onLoadUItemsByCustomerData();
    optionsItemsCustomer
      .then((opt) => {
        setItemsOption(
          opt.map((c: any) => ({
            label: c.sku,
            value: c.itemId,
          })),
        );
      })
      .catch((err) => console.log(err));

    if (pageViewer.isEdit()) {
      OnLoadCycleCountDetail(queryStringItemCycleCount.id);
    }
  }, [currentUser.Claim_CustomerId]);

  return (
    <>
      <Modal open={isCycleCountModalOpen}>
        <MUIContainer>
          <MUIContent>
            <Typography
              sx={{ marginBottom: '16px' }}
              variant="h6"
              fontWeight="bold"
            >
              {pageViewer.isEdit() && 'Edit '}
              {query.get('type') === 'location' ? 'Locations ' : 'Items '}
              Cycle Count
            </Typography>

            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <Input
                  required
                  placeholder="Cycle Count Name"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={formData?.cycleCountName}
                  error={formDataErrors?.cycleCountName}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      cycleCountName: e.target.value,
                    }));
                  }}
                />
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <Autocomplete
                  onChange={(event: any, newValue: CustomerType) => {
                    if (newValue !== null) {
                      setFormData((prev) => ({
                        ...prev,
                        assignedUser: {
                          value: newValue.value,
                          label: newValue.label,
                        },
                      }));
                    }
                  }}
                  id="controllable-states"
                  options={usersOption}
                  getOptionLabel={(option: CustomerType) => option.label}
                  value={formData?.assignedUser}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      {...(formDataErrors?.assignedUser && {
                        error: true,
                        helperText: formDataErrors?.assignedUser,
                      })}
                      autoComplete="off"
                      id="outlined-required"
                      label="Assigned To"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <DatePickerInput
                  label="Due Date"
                  inputFormat="MM/dd/yyyy"
                  value={formData?.dueDate}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      dueDate: e,
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      disabled
                      {...params}
                      error={formDataErrors?.dueDate}
                      helperText={formDataErrors?.dueDate}
                      size="small"
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>

            <Divider sx={{ mt: 2, mb: 2 }} />
            <Typography
              sx={{ marginBottom: '0px' }}
              variant="subtitle2"
              fontWeight="bold"
            >
              {query.get('type') === 'location' ? 'Location ' : 'Item '}
              parameters
            </Typography>

            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                {query.get('type') === 'location' && (
                  <Autocomplete
                    multiple
                    size="small"
                    id="checkboxes-tags-demo"
                    options={binCategoryOptions}
                    value={formData?.locationType}
                    disableCloseOnSelect
                    getOptionLabel={(option: AutocompleteBase) => option.label}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                    onChange={(event: any, newValue) => {
                      if (newValue !== null) {
                        setFormData((prev) => ({
                          ...prev,
                          locationType: newValue,
                        }));
                      } else {
                        setFormData((prev) => ({
                          ...prev,
                          locationType: [],
                        }));
                      }
                    }}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location Type"
                        {...(formDataErrors?.locationType && {
                          error: true,
                          helperText: formDataErrors?.locationType,
                        })}
                        placeholder="Location Type"
                      />
                    )}
                  />
                )}
                {query.get('type') === 'item' && (
                  <Autocomplete
                    multiple
                    size="small"
                    id="checkboxes-tags-demo"
                    options={itemsOption}
                    value={formData.itemType}
                    disableCloseOnSelect
                    getOptionLabel={(option: AutocompleteBase) => option.label}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                    onChange={(event: any, newValue) => {
                      if (newValue !== null) {
                        setFormData((prev) => ({
                          ...prev,
                          itemType: newValue,
                        }));
                      } else {
                        setFormData((prev) => ({
                          ...prev,
                          itemType: [],
                        }));
                      }
                    }}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="SKUs"
                        {...(formDataErrors?.itemType && {
                          error: true,
                          helperText: formDataErrors?.itemType,
                        })}
                        placeholder="SKUs"
                      />
                    )}
                  />
                )}
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Autocomplete
                  onChange={(event: any, newValue: CustomerType) => {
                    if (newValue !== null) {
                      setFormData((prev) => ({
                        ...prev,
                        warehouseCustomer: {
                          value: newValue.value,
                          label: newValue.label,
                        },
                      }));
                    } else {
                      setFormData((prev) => ({
                        ...prev,
                        warehouseCustomer: null,
                      }));
                    }
                  }}
                  id="controllable-states"
                  options={optionWarehouseCustomers}
                  getOptionLabel={(option: CustomerType) => option.label}
                  value={formData?.warehouseCustomer}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      {...(formDataErrors?.warehouseCustomer && {
                        error: true,
                        helperText: formDataErrors?.warehouseCustomer,
                      })}
                      autoComplete="off"
                      id="outlined-required"
                      label="Client"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData?.isBlindAccount}
                      onChange={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          isBlindAccount: e.target.checked,
                        }));
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={
                    <Typography fontWeight="bold" variant="subtitle2">
                      Blind count
                    </Typography>
                  }
                  labelPlacement="end"
                />
                <Typography variant="subtitle2" pt={-1} ml={4}>
                  Users won&apos;t see the expected amount while counting
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData?.isRecountDiscrepancy}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          isRecountDiscrepancy: e.target.checked,
                        }))
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={
                    <Typography fontWeight="bold" variant="subtitle2">
                      Flag items with lot discrepancies
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData?.isFlagItemsLotDiscrepancy}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          isFlagItemsLotDiscrepancy: e.target.checked,
                        }))
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={
                    <Typography fontWeight="bold" variant="subtitle2">
                      Recount discrepancies
                    </Typography>
                  }
                  labelPlacement="end"
                />
                <Typography variant="subtitle2" pt={-1} ml={4}>
                  As soon as a user counts an item with unit discrepancies,
                  Packem will flag this SKU as a recount, Additional count(s)
                  will be completed until there are two consecutive counts with
                  matching discrepancies for the count to be effective in the
                  inventory.
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData?.isFlagForRecount}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          isFlagForRecount: e.target.checked,
                        }))
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={
                    <Typography fontWeight="bold" variant="subtitle2">
                      Flag for recount items that were unexpectedly found in a
                      location
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </MUIGrid>
            </MUIGrid>

            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => {
                      handleCloseCycleCountMainModal();
                    }}
                    variant="outlined"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                    onClick={(e) => {
                      onHandleCycleCountCreate(e);
                    }}
                  >
                    SAVE
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </MUIContent>
        </MUIContainer>
      </Modal>
      <Modal open={isCycleCountDetailModalOpen}>
        <MUIContainer>
          <MUIContent>
            <Typography
              sx={{ marginBottom: '16px' }}
              variant="h6"
              fontWeight="bold"
            >
              Update Count
            </Typography>

            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <Input
                  placeholder="Item"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={cycleCountUpdateCountDetailModal?.item}
                  // error={cycleCountUpdateCountDetailModal?.cycleCountName}
                  aria-readonly
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Input
                  placeholder="Location"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={cycleCountUpdateCountDetailModal?.location}
                  // error={locationDataModalFormErrors?.cycleCountName}
                  aria-readonly
                />
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <Input
                  placeholder="Expected"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={cycleCountUpdateCountDetailModal?.expected}
                  // error={locationDataModalFormErrors?.cycleCountName}
                  aria-readonly
                  type="number"
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Input
                  placeholder="Counted"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={cycleCountUpdateCountDetailModal?.counted}
                  // error={locationDataModalFormErrors?.cycleCountName}
                  onChange={(e) =>
                    setCycleCountUpdateCountDetailModal((prev) => ({
                      ...prev,
                      counted: e.target.value,
                    }))
                  }
                  type="number"
                />
              </MUIGrid>
            </MUIGrid>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => {
                      handleCloseCycleCountMainModal();
                    }}
                    variant="outlined"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    SAVE
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </MUIContent>
        </MUIContainer>
      </Modal>
    </>
  );
});
