import React from 'react';

import Amazon from 'assets/images/integrations/amazon.png';
import DefaultImage from 'assets/images/integrations/defaultimage.png';
import Extensiv from 'assets/images/integrations/extensiv.png';
import Shipstation from 'assets/images/integrations/shipstation.png';
import Shopify from 'assets/images/integrations/shopify.png';
import Walmart from 'assets/images/integrations/walmart.png';
import WooCommerce from 'assets/images/integrations/woocommerce.png';

import { Box, Typography } from '@mui/material';

// Define your image sources
const imageSources = {
  amazon: Amazon,
  defaultimage: DefaultImage,
  extensiv: Extensiv,
  shipstation: Shipstation,
  shopify: Shopify,
  walmart: Walmart,
  woocommerce: WooCommerce,
};

const ChannelIcon = ({ type, altText = 'Image', withText = '' }) => {
  let imageSrc = imageSources[type]; // Default image if type doesn't match
  if (
    type !== null &&
    type !== undefined &&
    type?.toLowerCase().includes('amazon')
  ) {
    imageSrc = imageSources.amazon;
  }

  if (imageSrc === undefined) {
    imageSrc = imageSources.defaultimage;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      {withText && <Typography fontWeight="bold">{withText}</Typography>}
      <img
        src={imageSrc}
        alt={altText}
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </Box>
  );
};

export default ChannelIcon;
