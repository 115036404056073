import React, { useContext, memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { PressBShipAndComplete } from 'services/api/packandship/packandship.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Typography, CircularProgress } from '@mui/material';

export default memo(() => {
  const navigate = useNavigate();

  const {
    isAutoCreateLabelData,
    isAutoCreateLabelModalOpen,
    setIsAutoCreateLabelModalOpen,
    setIsAutoCreateLabelErrorModal,
    setIsAutoCreateLabelErrorMessage,
  } = useContext(GlobalContext);

  useEffect(() => {
    if (isAutoCreateLabelModalOpen) {
      PressBShipAndComplete(isAutoCreateLabelData)
        .then((response) => {
          const { status } = response;

          if (status === 200) {
            setIsAutoCreateLabelModalOpen(false);
            navigate('/pack-and-ship/find-pick-ticket');
          }
        })
        .catch((error) => {
          setIsAutoCreateLabelErrorMessage(error);
          setIsAutoCreateLabelModalOpen(false);
          setIsAutoCreateLabelErrorModal(true);
        });
    }
  }, [isAutoCreateLabelModalOpen]);

  return (
    <Modal open={isAutoCreateLabelModalOpen}>
      <MUIContainer sx={{ width: '15% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Label is printing
        </Typography>
        <MUIContent
          sx={{ padding: '10px 10px !important', alignSelf: 'center' }}
        >
          <CircularProgress />
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
