import React, { useContext } from 'react';

import Input from 'components/input';
import { ModalBox, ModalContent } from 'components/styles';
import { CheckboxState, defaultItemStates } from 'pages/users/context';
import { PageUsedEnum } from 'pages/users/users-permission-modal';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Box, Modal, Button, Typography, Divider } from '@mui/material';

import { useWarehouseCustomerContext } from '..';

export default React.memo(() => {
  const {
    setUserPermission,
    setPageUsedPermission,
    setUsersPermissionModal,
    setUserPermissionStates,
    isCustomerPortalPermissionModalOpen,
  } = useContext(GlobalContext);

  const {
    form,
    setForm,
    userInfo,
    formErrors,
    permissionWarehouseCustomerValue,
    handleOnCreateCustomerPortalAccount,
    handleCloseCustomerPortalAccountModal,
  } = useWarehouseCustomerContext();

  const handleManagePermissions = () => {
    const newValue = {
      roleId: 9,
      userPermissionIds: [],
    };
    setPageUsedPermission(PageUsedEnum.ClientPortalActivateNewAccount);
    setUserPermission(newValue);

    const permissions = [...defaultItemStates];

    const newState = permissions.map((obj) => {
      if (permissionWarehouseCustomerValue.some((x) => x === obj.value)) {
        return {
          ...obj,
          isUserPermissionChecked: true,
          state: CheckboxState.CHECKED,
        };
      }
      return obj;
    });
    setUserPermissionStates(newState);
    setUsersPermissionModal(true);
  };

  return (
    <Modal open={isCustomerPortalPermissionModalOpen}>
      <ModalBox>
        <ModalContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Permissions
          </Typography>
          <Input
            placeholder="Email Address"
            size="small"
            sx={{
              backgroundColor: '#ffffff !important',
              width: '100%',
            }}
            value={form.customerEmailPortal}
            onChange={(e) => {
              setForm((prev) => ({
                ...prev,
                customerEmailPortal: e.target.value,
              }));
            }}
            error={formErrors?.customerEmailPortal}
            required
            disabled={userInfo?.userId}
          />
          <Button
            variant="contained"
            onClick={() => {
              handleManagePermissions();
            }}
            size="medium"
            sx={{ textTransform: 'none' }}
          >
            Manage User Permission
          </Button>
          <Divider sx={{ margin: '5px 0' }} />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: '8px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="text"
              size="medium"
              onClick={() => handleCloseCustomerPortalAccountModal()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={(e) => handleOnCreateCustomerPortalAccount(e)}
            >
              Submit
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
