import React, { useContext, useState } from 'react';

import DatePickerInput from 'components/datepicker';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import { createLotNo } from 'services/api/receipts/receipts-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Typography,
  Grid as MUIGrid,
  Box,
  Modal,
  TextField,
  CircularProgress,
  Button,
} from '@mui/material';

import { MUIContainer, MUIContent } from '../editreceiptmodal';

interface ModalProps {
  paramId: string;
}

export const AddLotNoModal = ({ paramId }: ModalProps) => {
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const {
    isAddLotNoModalOpen,
    setIsAddLotNoModalOpen,
    addLotNoModalData,
    setAddLotNoModalData,
    setIsAddLotNoUpdateOptions,
  } = useContext(GlobalContext);

  const [formValueErrors, setFormValueErrors] = useState(null);

  const handleOnClickCloseModal = async () => {
    setIsAddLotNoModalOpen(false);
    setFormValueErrors(null);
    setAddLotNoModalData((prevState) => ({
      ...prevState,
      value: null,
      label: '',
      lotId: null,
      lotCreationDate: null,
      lotExpirationDate: null,
    }));
  };

  const handleOnClickAddLotNo = async (e: any) => {
    e.preventDefault();
    const temp: any = {};

    temp.label = addLotNoModalData.label ? '' : 'This field is required';

    if (
      addLotNoModalData?.lotExpirationDate === null ||
      addLotNoModalData?.lotExpirationDate === undefined ||
      addLotNoModalData?.lotExpirationDate === ''
    ) {
      temp.lotExpirationDate = 'This field is required';
    } else {
      temp.lotExpirationDate = '';
    }

    setFormValueErrors({
      ...temp,
    });

    if (!Object.values(temp).every((x) => x === '')) {
      return;
    }

    if (addLotNoModalData.label !== '') {
      if (
        addLotNoModalData.lotCreationDate === null ||
        addLotNoModalData.lotCreationDate === undefined ||
        addLotNoModalData.lotCreationDate === ''
      ) {
        addLotNoModalData.lotCreationDate = new Date();
      }
    }

    const PAYLOAD = {
      customerId: parseInt(currentUser.Claim_CustomerId, 10),
      customerLocationId: currentLocationAndFacility.customerLocationId,
      itemId: paramId,
      lotNo: addLotNoModalData.label,
      creationDate: addLotNoModalData.lotCreationDate
        ? moment(addLotNoModalData.lotCreationDate).format('MM/DD/YY')
        : '',
      expirationDate: addLotNoModalData.lotExpirationDate
        ? moment(addLotNoModalData.lotExpirationDate).format('MM/DD/YY')
        : '',
    };

    try {
      setLoadingPage(true);

      const getResponse = await createLotNo(PAYLOAD);

      setAddLotNoModalData((prevState) => ({
        ...prevState,
        value: getResponse?.lotId,
        label: getResponse?.lotNo,
        lotId: getResponse?.lotId,
        lotCreationDate: getResponse?.creationDate,
        lotExpirationDate: getResponse?.expirationDate,
      }));

      setIsAddLotNoModalOpen(false);
      setFormValueErrors(null);
      setIsAddLotNoUpdateOptions(true);

      snackActions.success('Successfully created new lot number.');
    } catch (err) {
      snackActions.error(err);
    }
    setLoadingPage(false);
  };

  return (
    <Modal open={isAddLotNoModalOpen}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Add Lot No
        </Typography>
        <MUIContent>
          <Box autoComplete="off" component="form" noValidate>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item md={4}>
                <TextField
                  required
                  value={addLotNoModalData?.label}
                  id="outlined-required"
                  label="Lot No"
                  size="small"
                  sx={{ width: '100%' }}
                  onChange={(e) => {
                    setAddLotNoModalData((prevState) => ({
                      ...prevState,
                      label: e.target.value,
                    }));
                  }}
                  {...(formValueErrors?.label && {
                    error: true,
                    helperText: formValueErrors?.label,
                  })}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12} md={4}>
                <DatePickerInput
                  label="Lot Creation Date"
                  inputFormat="MM/dd/yyyy"
                  value={
                    addLotNoModalData?.lotCreationDate !== undefined
                      ? addLotNoModalData?.lotCreationDate
                      : null
                  }
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid Date') {
                      setAddLotNoModalData((prevState) => ({
                        ...prevState,
                        lotCreationDate: e,
                      }));
                    } else {
                      setAddLotNoModalData((prevState) => ({
                        ...prevState,
                        lotCreationDate: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...(formValueErrors?.lotCreationDate && {
                        error: true,
                        helperText: formValueErrors?.lotCreationDate,
                      })}
                      size="small"
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12} md={4}>
                <DatePickerInput
                  label="Lot Exp. Date"
                  inputFormat="MM/dd/yyyy"
                  value={
                    addLotNoModalData?.lotExpirationDate !== undefined
                      ? addLotNoModalData?.lotExpirationDate
                      : null
                  }
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid Date') {
                      setAddLotNoModalData((prevState) => ({
                        ...prevState,
                        lotExpirationDate: e,
                      }));
                    } else {
                      setAddLotNoModalData((prevState) => ({
                        ...prevState,
                        lotExpirationDate: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...(formValueErrors?.lotExpirationDate && {
                        error: true,
                        helperText: formValueErrors?.lotExpirationDate,
                      })}
                      size="small"
                      required
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '10px',
                padding: '0px',
                justifyContent: 'flex-end',
              }}
            >
              {loadingPage ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ padding: 1 }}
                    onClick={handleOnClickCloseModal}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: ' rgb(75, 126, 254) !important',
                      marginRight: '10px',
                    }}
                    onClick={(e) => handleOnClickAddLotNo(e)}
                    disabled={loadingPage}
                  >
                    SUBMIT
                  </Button>
                </>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
};
