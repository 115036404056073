import { memo } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import Header from 'components/header';
import { id } from 'date-fns/locale';
import moment from 'moment';
import ChannelIcon from 'pages/sales/salesorder/channel-image';
import { ContentContainer, MainContainer } from 'pages/styles';
import { ContainerFlat, DatagridPremium } from 'styles';

import { Button, Chip, Link, Tooltip, Typography } from '@mui/material';
import {
  GridActionsCellItem,
  GridColumns,
  useGridApiRef,
} from '@mui/x-data-grid-premium';

import ReturnsContextProvider, { ReturnsViewContext } from './context';

function ReturnsDatable() {
  const handleViewReturnDetails = (receiptId) => {
    const querySearchParams = {
      form: 'view',
      id: receiptId,
    };

    const url = `/receiving/receipts/subpage?${createSearchParams(
      querySearchParams,
    )}`;

    window.open(url, '_blank');
  };

  const { filteredRows } = ReturnsViewContext();
  const columns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      hideable: false,
      width: 30,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params: any) => [
        <GridActionsCellItem
          label="Delete Return"
          //   onClick={() => {
          //     setOrderId(Number(params.row.orderId));

          //     if (params.row.isOpenPickTicketCompleted) {
          //       setIsDeleteOrderWithCompletedPickTicketModalOpen(true);
          //     } else {
          //       setOrderNo(params.row.orderNo);
          //       onOpenConfirmSalesOrderModal();
          //     }
          //   }}
          showInMenu
        />,
      ],
    },
    {
      field: 'orderNo',
      minWidth: 120,
      flex: 1,
      headerName: 'Order No.',
      renderCell: (params: any) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`receiving/receipts/subpage?form=view&id=${params.row.receiptId}`}
          target="_blank"
        >
          <Typography
            className="cellDatagridWrapper"
            sx={{ cursor: 'pointer', color: '#1C9DCC' }}
          >
            {params.row.orderNo}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'referenceNo',
      minWidth: 120,
      flex: 1,
      headerName: 'Reference No.',
      renderCell: (params: any) => (
        <Typography className="cellDatagridWrapper">
          {params.row.referenceNo}
        </Typography>
      ),
    },
    {
      field: 'channel',
      minWidth: 100,
      flex: 0.3,
      headerName: 'Channel',
      renderCell: (params: any) =>
        params.row?.channel && (
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <ChannelIcon type={params.row?.channel.toLowerCase()} />
          </div>
        ),
    },
    {
      field: 'po',
      minWidth: 120,
      flex: 1,
      headerName: 'PO',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">{params.row.po}</Typography>
      ),
    },
    {
      field: 'itemCount',
      minWidth: 120,
      flex: 1,
      headerName: 'Item Count',
      type: 'number',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.itemCount}
        </Typography>
      ),
    },
    {
      field: 'totalQuantity',
      minWidth: 120,
      flex: 1,
      headerName: 'Total Quantity',
      type: 'number',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.totalQuantity}
        </Typography>
      ),
    },
    {
      field: 'weightTotal',
      minWidth: 120,
      flex: 1,
      headerName: 'Weight',
      type: 'number',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.weightTotal && params.row.weightTotal.toFixed(2)} (lb)
        </Typography>
      ),
    },
    {
      field: 'carrier',
      minWidth: 120,
      flex: 1,
      headerName: 'Carrier',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.carrier}
        </Typography>
      ),
    },
    {
      field: 'assignedTo',
      minWidth: 100,
      flex: 1,
      headerName: 'Assigned To',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row?.assignedTo}
        </Typography>
      ),
    },
    {
      field: 'customer',
      minWidth: 130,
      flex: 1,
      headerName: 'Client',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.customer}
        </Typography>
      ),
    },
    {
      field: 'creationDateTime',
      minWidth: 170,
      flex: 1,
      headerName: 'Order Date',
      type: 'date',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row?.creationDateTime
            ? moment(params.row?.creationDateTime).format(
                'MM/DD/YYYY : hh:mm A',
              )
            : ''}
        </Typography>
      ),
    },
    {
      field: 'shipDateDay',
      minWidth: 150,
      flex: 1,
      headerName: 'Ship Date',
      type: 'date',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipDateDay} {params.row.shipDateTime}
        </Typography>
      ),
    },
    {
      field: 'shipName',
      minWidth: 150,
      flex: 1,
      headerName: 'Ship Name',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipName}
        </Typography>
      ),
    },
    {
      field: 'shipCompany',
      minWidth: 150,
      flex: 1,
      headerName: 'Ship Company',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipCompany}
        </Typography>
      ),
    },
    {
      field: 'shipAddress1',
      minWidth: 150,
      flex: 1,
      headerName: 'Ship Address 1',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipAddress1}
        </Typography>
      ),
    },
    {
      field: 'shipAddress2',
      minWidth: 120,
      flex: 1,
      headerName: 'Ship Address 2',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipAddress2}
        </Typography>
      ),
    },
    {
      field: 'shipCity',
      minWidth: 130,
      flex: 1,
      headerName: 'Ship City',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipCity}
        </Typography>
      ),
    },
    {
      field: 'shipState',
      minWidth: 130,
      flex: 1,
      headerName: 'Ship State',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipState}
        </Typography>
      ),
    },
    {
      field: 'shipZip',
      minWidth: 130,
      flex: 1,
      headerName: 'Ship Zip',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipZip}
        </Typography>
      ),
    },
    {
      field: 'shipCountry',
      minWidth: 130,
      flex: 1,
      headerName: 'Ship Country',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipCountry}
        </Typography>
      ),
    },
    {
      field: 'shipEmail',
      minWidth: 130,
      flex: 1,
      headerName: 'Ship Email',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipEmail}
        </Typography>
      ),
    },
    {
      field: 'shipPhone',
      minWidth: 130,
      flex: 1,
      headerName: 'Ship Phone',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipPhone}
        </Typography>
      ),
    },
    {
      field: 'shipByDateTime',
      minWidth: 120,
      flex: 1,
      headerName: 'Ship By',
      type: 'date',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row?.shipByDateTime
            ? moment(params.row?.shipByDateTime).format('MM/DD/YYYY')
            : ''}
        </Typography>
      ),
    },
    {
      field: 'shipCancelDateTime',
      minWidth: 150,
      flex: 1,
      headerName: 'Ship Cancel Date',
      type: 'date',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipCancelDateTime}
        </Typography>
      ),
    },
    // {
    //   field: 'shipStationTags',
    //   minWidth: 200,
    //   flex: 2,
    //   disableExport: true,
    //   headerName: 'Tags',
    //   renderCell: (params) =>
    //     params.row.shipStationTags != null ? (
    //       <>
    //         {params.row.shipStationTags.map((tag) => ({
    //           ...(
    //             <Tooltip title={tag.tagName}>
    //               <Chip
    //                 label={tag.tagName}
    //                 size="small"
    //                 sx={{
    //                   marginLeft: 1,
    //                   fontWeight: 'bold',
    //                   color: tag.tagColor === 'White' ? 'black' : 'white',
    //                 }}
    //                 style={{ backgroundColor: tag.tagColor }}
    //               />
    //             </Tooltip>
    //           ),
    //         }))}
    //         <span />
    //       </>
    //     ) : (
    //       <span />
    //     ),
    // },
    // {
    //   field: 'shipStationTagsExport',
    //   minWidth: 150,
    //   flex: 1,
    //   hide: true,
    //   headerName: 'Tags',
    // },
  ];

  const apiRef = useGridApiRef();

  return (
    <ContentContainer>
      <DatagridPremium
        autoHeight
        checkboxSelection
        disableColumnMenu
        disableSelectionOnClick
        rows={filteredRows}
        columns={columns}
        getRowId={() => Math.random()}
        rowGroupingColumnMode="single"
        density="compact"
        apiRef={apiRef}
        initialState={{ pagination: { pageSize: 100 } }}
        rowsPerPageOptions={[50, 100, 200]}
        pagination
      />
    </ContentContainer>
  );
}

const ReturnsDatableView = () => (
  <MainContainer>
    <Header />
    <ReturnsContextProvider>
      <ReturnsDatable />
    </ReturnsContextProvider>
  </MainContainer>
);
export default memo(ReturnsDatableView);
