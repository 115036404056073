import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import DatePickerInput from 'components/datepicker';
import { snackActions } from 'config/snackbar.js';
import {
  useInventoryItemDetailsContext,
  KitSkuType,
} from 'pages/inventory/item/details';
import { ModalTitleStatus } from 'pages/sales/salesorder/context';
import {
  createKitlineItem,
  editKitlineItem,
  deleteKitlineItem,
} from 'services/api/inventory/inventory-new.api';
import { createLotNo, getLotNo } from 'services/api/receipts/receipts-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Autocomplete,
  Modal,
  Box,
  Typography,
  TextField,
  Divider,
  Grid as MUIGrid,
  Button,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { styled as MuiStyled } from '@mui/material/styles';

import { autoCompleteTimeStamp } from '../../../helpers/autocompletetimestamp';

// //Utilities
function isNumberKey(evt) {
  const a = /^\d*\.?\d*$/;
  const regEx = new RegExp(a);
  if (regEx.test(evt.target.value)) {
    return true;
  }
  return false;
}
const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;
    width: 100%;
    max-width: 600px;
    border-radius: 4px;
  }
`;

type AutocompleteBase = {
  label?: string;
  value?: number;
};
type UOMType = AutocompleteBase;

type ExtendSkuType = {
  sku?: string;
  description: string;
};
type ExtendLotNoType = {
  lotId?: number;
  lotExpiration?: string;
};
export type LotNoType = AutocompleteBase & ExtendLotNoType;

type KitComponentItemType = {
  itemKitComponentId?: number;
  itemId: number;
  uom: UOMType;
  quantity: string;
  lotId: number;
  lotNo: string;
  lotExpiration: string;
  note: string;
} & ExtendSkuType;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;

export default React.memo(() => {
  const navigate = useNavigate();

  const initialKitComponentItemState: KitComponentItemType = {
    itemKitComponentId: 0,
    itemId: 0,
    uom: {
      label: '',
      value: 0,
    },
    description: '',
    quantity: '',
    lotId: 0,
    lotNo: '',
    lotExpiration: '',
    note: '',
  };

  const errorInitialKitComponentItemState: KitComponentItemType = {
    itemId: 0,
    uom: {
      label: '',
      value: 0,
    },
    description: '',
    quantity: '',
    lotId: 0,
    lotNo: '',
    lotExpiration: '',
    note: '',
  };

  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);

  const {
    pageViewer,
    modalTitle,
    setModalTitle,
    optionKitSkus,
    kitSkuValue,
    setKitSkuValue,
    formValues,
    kitComponentItemRows,
    setKitComponentItemRows,
    setFormValues,
    kitComponentSingleItemRow,
    queryStringItemDetails,
    setQueryStringItemDetails,
  } = useInventoryItemDetailsContext();
  const { isKittingLineIteModalOpen, onCloseKittingLineItemModal } =
    useContext(GlobalContext);

  const [optionLotNo, setOptionLotNo] = useState<LotNoType[]>([]);
  const [lotNoValue, setLotNoValue] = useState(null);

  const [kitComponentItem, setKitComponentItem] =
    useState<KitComponentItemType>(initialKitComponentItemState);
  const [kitComponentLineFormErrors, setKitComponentLineFormErrors] = useState(
    errorInitialKitComponentItemState,
  );
  const onLoadGetLotNoOnChange = async (itemId) => {
    try {
      return await getLotNo(itemId);
    } catch (err) {
      return err;
    }
  };
  const handleOnClickAddLotNoV2 = async (e: any) => {
    e.preventDefault();
    const PAYLOAD = {
      customerId: parseInt(currentUser.Claim_CustomerId, 10),
      customerLocationId: currentLocationAndFacility.customerLocationId,
      itemId: kitComponentItem.itemId,
      lotNo: kitComponentItem.lotNo,
      expirationDate: kitComponentItem.lotExpiration,
    };

    try {
      const response = await createLotNo(PAYLOAD);

      const lotExpirationDate =
        response.expirationDate === '0001-01-01T00:00:00' ||
        response.expirationDate === '0001-01-02T00:00:00'
          ? null
          : response.expirationDate;

      setLotNoValue({
        value: response.lotId,
        label: response.lotNo,
        lotExpirationDate,
      });
      setOptionLotNo((prev) => [
        ...prev,
        {
          value: response.lotId,
          label: response.lotNo,
          lotExpirationDate,
        },
      ]);

      snackActions.success('Successfully created new lot number.');
    } catch (err) {
      snackActions.error(err);
    }
  };
  const relatedOnLotInputHandler = (
    key: string,
    value?: AutocompleteBase,
    event?: any,
  ) => {
    if (key === 'lotNo') {
      setLotNoValue(value);

      const lotExpiration =
        event.target.dataset.lotexpirationdate === '0001-01-01T00:00:00' ||
        event.target.dataset.lotexpirationdate === '0001-01-02T00:00:00'
          ? null
          : event.target.dataset.lotexpirationdate;

      const lot = {
        value: value.value,
        label: value.label,
        lotExpiration,
      };

      setLotNoValue((prev) => ({
        ...prev,
        ...lot,
      }));

      setKitComponentLineFormErrors(errorInitialKitComponentItemState);
    }
    if (key === 'lotExpiration') {
      if (event !== null && event !== 'Invalid Date') {
        setKitComponentItem((prevState) => ({
          ...prevState,
          lotExpiration: event,
        }));
      } else {
        setKitComponentItem((prevState) => ({
          ...prevState,
          lotExpiration: null,
        }));
      }
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

  const kitComponentLineFormValidation = () => {
    const temp: any = {};
    const re = /[0-9]+/g;
    if (kitSkuValue === null) {
      temp.sku = 'This field is required';
    } else {
      temp.sku = '';
    }

    if (
      kitComponentItem.quantity === '' ||
      kitComponentItem.quantity === undefined
    ) {
      temp.quantity = 'This field is required';
    } else if (!re.test(kitComponentItem.quantity)) {
      temp.quantity = 'Must be a number';
    } else {
      temp.quantity = '';
    }

    setKitComponentLineFormErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const addKitLineItemComponentOnClick = async (e: any) => {
    e.preventDefault();

    if (pageViewer.isNew() && modalTitle === ModalTitleStatus.Add) {
      const value = {
        itemKitComponentId: `NEW_${formValues.kitComponents.lineItems.length}`,
        itemId: kitSkuValue.value,
        sku: kitSkuValue.label,
        description: kitComponentItem.description,
        uom: kitComponentItem.uom.value,
        uomStr: kitComponentItem.uom.label,
        qty: kitComponentItem.quantity,
        lotId: kitComponentItem.lotId,
        lotNo: kitComponentItem.lotNo,
        lotExpiration: kitComponentItem.lotExpiration,
      };
      setFormValues((prevState) => ({
        ...prevState,
        kitComponents: {
          lineItems: [...prevState.kitComponents.lineItems, value],
        },
      }));
      setKitComponentItemRows((prevState) => [...prevState, value]);
      onCloseKittingLineItemModal();
    }
    if (pageViewer.isNew() && modalTitle === ModalTitleStatus.Edit) {
      const value = {
        itemKitComponentId: kitComponentSingleItemRow.itemKitComponentId,
        itemId: kitSkuValue.value,
        sku: kitSkuValue.label,
        description: kitComponentItem.description,
        uom: kitComponentItem.uom.value,
        uomStr: kitComponentItem.uom.label,
        qty: kitComponentItem.quantity,
        lotId: kitComponentItem.lotId,
        lotNo: kitComponentItem.lotNo,
        lotExpiration: kitComponentItem.lotExpiration,
      };

      setFormValues((prevState) => ({
        ...prevState,
        kitComponents: {
          lineItems: prevState.kitComponents.lineItems.map((obj) => {
            if (
              obj.itemKitComponentId ===
              kitComponentSingleItemRow.itemKitComponentId
            ) {
              return {
                ...obj,
                ...value,
              };
            }
            return obj;
          }),
        },
      }));
      setKitComponentItemRows((prev) =>
        prev.map((row) =>
          row.itemKitComponentId ===
          kitComponentSingleItemRow.itemKitComponentId
            ? {
                ...row,
                ...value,
              }
            : row,
        ),
      );
    }
    if (pageViewer.isEdit() && modalTitle === ModalTitleStatus.Add) {
      const PAYLOAD = {
        itemKitId: queryStringItemDetails.id,
        // itemComponentId: kitComponentItem.uom.value,
        itemComponentId: kitSkuValue.value,
        lotId:
          kitComponentItem.lotId === 0 || kitComponentItem.lotId === undefined
            ? null
            : kitComponentItem.lotId,
        lotExpirationDate: kitComponentItem.lotExpiration,
        qty: kitComponentItem.quantity,
      };

      try {
        const response = await createKitlineItem(PAYLOAD);
        snackActions.success('Successfully created new kit item.');
        const value = {
          itemKitComponentId: response.itemKitComponentId,
          itemId: response.itemId,
          sku: response.sku,
          description: response.description,
          uom: response.uom,
          uomStr: response.uomStr,
          qty: response.qty,
          lotId: kitComponentItem.lotId,
          lotNo: kitComponentItem.lotNo,
          lotExpiration: kitComponentItem.lotExpiration,
        };
        setFormValues((prevState) => ({
          ...prevState,
          kitComponents: {
            lineItems: [...prevState.kitComponents.lineItems, value],
          },
        }));
        setKitComponentItemRows((prevState) => [...prevState, value]);
        setQueryStringItemDetails((prevState) => ({
          ...prevState,
          form: 'view',
        }));
        navigate(
          `/inventory/item-details?form=view&id=${queryStringItemDetails.id}`,
        );
      } catch (err) {
        snackActions.error(err);
      }
    }
    if (pageViewer.isEdit() && modalTitle === ModalTitleStatus.Edit) {
      const PAYLOAD = {
        itemKitComponentId: kitComponentSingleItemRow.itemKitComponentId,
        lotId:
          kitComponentItem.lotId === 0 || kitComponentItem.lotId === undefined
            ? null
            : kitComponentItem.lotId,
        lotExpirationDate: kitComponentItem.lotExpiration,
        qty: kitComponentItem.quantity,
      };

      try {
        const response = await editKitlineItem(PAYLOAD);
        snackActions.success('Successfully updated kit item.');
        const value = {
          itemKitComponentId: response.itemKitComponentId,
          itemId: response.itemId,
          sku: response.sku,
          description: response.description,
          uom: response.uom,
          uomStr: response.uomStr,
          qty: response.qty,
          lotId: response.lotId,
          lotNo: response.lotNo,
          lotExpiration: response.lotExpirationDate,
        };
        setKitComponentItemRows((prev) =>
          prev.map((row) =>
            row.itemKitComponentId ===
            kitComponentSingleItemRow.itemKitComponentId
              ? {
                  ...row,
                  ...value,
                }
              : row,
          ),
        );
        setQueryStringItemDetails((prevState) => ({
          ...prevState,
          form: 'view',
        }));
        navigate(
          `/inventory/item-details?form=view&id=${queryStringItemDetails.id}`,
        );
      } catch (err) {
        snackActions.error(err);
      }
    }
    onCloseKittingLineItemModal();
  };

  useEffect(() => {
    if (
      (pageViewer.isNew() && modalTitle === ModalTitleStatus.Add) ||
      (pageViewer.isNew() && modalTitle === ModalTitleStatus.Edit) ||
      (pageViewer.isEdit() && modalTitle === ModalTitleStatus.Add) ||
      (pageViewer.isEdit() && modalTitle === ModalTitleStatus.Edit)
    ) {
      if (kitSkuValue !== null) {
        onLoadGetLotNoOnChange(kitSkuValue?.value).then((x) => {
          setOptionLotNo([]);
          x.map((lot) =>
            setOptionLotNo((prev) => [
              ...prev,
              {
                value: lot.lotId,
                label: lot.lotNo,
                lotExpiration: lot.expirationDate,
              },
            ]),
          );
        });
      }
    }
  }, [kitSkuValue]);
  useEffect(() => {
    if (modalTitle === ModalTitleStatus.Add) {
      setKitSkuValue(null);
      setLotNoValue(null);
      setKitComponentItem(initialKitComponentItemState);
    }

    if (
      (pageViewer.isNew() && modalTitle === ModalTitleStatus.Edit) ||
      (pageViewer.isEdit() && modalTitle === ModalTitleStatus.Edit)
    ) {
      console.log(kitComponentSingleItemRow, 'kitComponentSingleItemRow');
      if (optionKitSkus !== null) {
        const sku = (el: AutocompleteBase) => {
          if (pageViewer.isViewOrEdit() || pageViewer.isNew())
            return el.value === kitComponentSingleItemRow.itemId;
          return null;
        };

        const vmSku = optionKitSkus.find(sku);

        if (vmSku !== undefined) {
          setKitSkuValue(vmSku);
          setKitComponentItem((prevState) => ({
            ...prevState,
            itemId: vmSku.value,
            description: vmSku.description,
            uom: {
              value: vmSku.value,
              label: vmSku.uomStr,
            },
          }));

          console.log(kitComponentSingleItemRow, 'kitComponentSingleItemRow');
          if (
            kitComponentSingleItemRow.lotId !== undefined &&
            kitComponentSingleItemRow.lotId !== null &&
            kitComponentSingleItemRow.lotId !== 0
          ) {
            onLoadGetLotNoOnChange(vmSku?.value).then((x) => {
              setOptionLotNo([]);

              x.map((lot) =>
                setOptionLotNo((prev) => [
                  ...prev,
                  {
                    value: lot.lotId,
                    label: lot.lotNo,
                    lotExpiration: lot.expirationDate,
                  },
                ]),
              );
              const lotValue = x.find(
                (lot) => lot.lotId === kitComponentSingleItemRow.lotId,
              );
              setLotNoValue({
                value: lotValue?.lotId,
                label: lotValue?.lotNo,
                lotExpiration: lotValue?.expirationDate,
              });
              setKitComponentItem((prevState) => ({
                ...prevState,
                value: lotValue?.lotId,
                label: lotValue?.lotNo,
                lotExpiration: lotValue?.expirationDate,
              }));
            });
          } else if (
            kitComponentSingleItemRow?.lotExpiration !== undefined &&
            kitComponentSingleItemRow?.lotExpiration !== null
          ) {
            setLotNoValue(null);
            setKitComponentItem((prevState) => ({
              ...prevState,
              lotExpiration: kitComponentItem?.lotExpiration,
            }));
          } else {
            setLotNoValue(null);
            setKitComponentItem((prevState) => ({
              ...prevState,
              lotId: null,
              lotNo: '',
              lotExpiration: '',
            }));
          }
        }
      }
      setKitComponentItem((prevState) => ({
        ...prevState,
        quantity: kitComponentSingleItemRow?.qty,
      }));
    }
  }, [modalTitle]);

  return (
    <Modal open={isKittingLineIteModalOpen}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            {modalTitle} BOM Item
          </Typography>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              if (!kitComponentLineFormValidation()) return;
              addKitLineItemComponentOnClick(e);
              setModalTitle(null);
              setKitSkuValue(null);
            }}
            noValidate
          >
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Autocomplete
                  disabled={
                    (pageViewer.isNew() &&
                      modalTitle === ModalTitleStatus.Edit) ||
                    (pageViewer.isEdit() &&
                      modalTitle === ModalTitleStatus.Edit)
                  }
                  onChange={(event: any, newValue: KitSkuType) => {
                    if (newValue !== null) {
                      setKitSkuValue(newValue);
                      setKitComponentItem((prevState) => ({
                        ...prevState,
                        itemId: newValue.value,
                        description: newValue.description,
                        uom: {
                          value: newValue.value,
                          label: newValue.uomStr,
                        },
                      }));
                    } else {
                      setKitSkuValue(null);
                    }
                  }}
                  id="controllable-states"
                  options={optionKitSkus}
                  getOptionLabel={(option: KitSkuType) => option.label}
                  value={kitSkuValue || null}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option?.value} data-name="sku">
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      {...(kitComponentLineFormErrors.sku && {
                        error: true,
                        helperText: kitComponentLineFormErrors.sku,
                      })}
                      required
                      id="outlined-required"
                      label="SKU"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="Description"
                  value={kitComponentItem.description || ''}
                  size="small"
                  disabled
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  required
                  {...(kitComponentLineFormErrors.quantity && {
                    error: true,
                    helperText: kitComponentLineFormErrors.quantity,
                  })}
                  id="outlined-required"
                  label="Quantity per Kit"
                  onChange={(e: any) => {
                    e.preventDefault();
                    if (isNumberKey(e)) {
                      setKitComponentItem((prev) => ({
                        ...prev,
                        quantity: e.target.value,
                      }));
                    }
                  }}
                  value={kitComponentItem.quantity || ''}
                  size="small"
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <Button
                onClick={() => {
                  onCloseKittingLineItemModal();
                  setModalTitle(null);
                  setKitSkuValue(null);
                  setKitComponentItem(initialKitComponentItemState);
                  setKitComponentLineFormErrors(
                    errorInitialKitComponentItemState,
                  );
                }}
                variant="outlined"
                size="medium"
              >
                CANCEL
              </Button>
              <Button type="submit" variant="contained" size="medium">
                Save
              </Button>
            </Box>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
