import * as d3 from 'd3';
import React, { useRef } from 'react';
import { IChoroplethData } from 'common/interfaces';
import * as colors from 'helpers/colorScale';

type MapProps = {
  states: any[];
  summary: IChoroplethData[];
};

export const ChoroplethUs = React.memo(({ states, summary }: MapProps) => {
  const tipHolder = useRef<HTMLDivElement>();
  const RangeAlias = ['50', '250', '500', '1, 000', '5, 000', '10, 000'];
  const RangeDomain = [50, 250, 500, 1000, 5000, '10000'];
  const colorScheme = [
    '#cccccc',
    '#bdd7e7',
    '#6baed6',
    '#3182bd',
    '#08519c',
    '#01264d',
  ];
  const colorScale = d3
    .scaleThreshold<number, string>()
    .domain(RangeDomain)
    .range(colorScheme);
  const projection = d3.geoAlbersUsa().scale(900);
  const geoPathGenerator = d3.geoPath().projection(projection);

  const handlePathMouseIn = (event: React.MouseEvent<SVGPathElement>) => {
    const path = event.target as SVGPathElement;
    const tooltip = document.createElement('div');

    tooltip.className = 'tooltip';
    tooltip.style.cssText =
      'position: absolute; border: 1px solid #3375e0; background-color: #052fff;  color: #fff; padding: 4px 7px; font-size: 12px; z-index: 999; pointer-events: none; border-radius: 0; width: 120px;';

    const tipState = document.createElement('div');
    tipState.textContent = path.getAttribute('data-geo-name') || '';

    const tipCount = document.createElement('div');
    tipCount.textContent = path.getAttribute('data-geo-count') || '';

    const tipSale = document.createElement('div');
    tipSale.textContent = path.getAttribute('data-geo-sales') || '';

    tooltip.append(tipState);
    tooltip.append(tipCount);
    tooltip.append(tipSale);

    if (tipHolder.current) {
      tipHolder.current.appendChild(tooltip);

      const rect = path.getBoundingClientRect();
      const top = rect.top - tooltip.offsetHeight - 5;
      const left = rect.left + rect.width / 2 - tooltip.offsetWidth / 2;

      tooltip.style.top = `${top}px`;
      tooltip.style.left = `${left}px`;
    }
  };

  const handlePathMouseOut = () => {
    const tooltips = document.querySelectorAll('.tooltip');
    tooltips.forEach((tooltip) => {
      tooltip.remove();
    });
  };

  const handlePathHover = (event: React.MouseEvent<SVGPathElement>) => {
    const path = event.target as SVGPathElement;
    const pathColor = path.getAttribute('data-geo-fill') ?? '#ffffff';
    const fillColor =
      pathColor === '#ffffff'
        ? '#dee2f1'
        : colors.colorAdjustment(pathColor, 10);
    path.style.fill = fillColor;
  };

  const handlePathUnhover = (e: React.MouseEvent<SVGPathElement>) => {
    const elem = e.target as SVGPathElement;
    elem.style.fill = '';
  };

  const allSvgPaths = states.map((state) => {
    const info = summary.find(
      (row) => row.code.toLowerCase() === state.properties.name.toLowerCase(),
    );
    const color = info ? colorScale(info.orders) : '#ffffff';
    const stoke = '#333333';
    const statename = `State: ${state.properties.name}`;
    const stateCount = `Order: ${info?.orders ?? 0}`;
    const stateSales = `Sales: ${info?.currency ?? '$'} ${info?.sales ?? '0'}`;
    return (
      <path
        key={`geo-${state.id}`}
        data-geo-id={state.id}
        data-geo-name={statename}
        data-geo-count={stateCount}
        data-geo-sales={stateSales}
        data-geo-fill={color}
        d={geoPathGenerator(state) ?? undefined}
        stroke={stoke}
        strokeWidth={0.5}
        fill={color}
        fillOpacity={1}
        onMouseOver={handlePathMouseIn}
        onMouseOut={handlePathMouseOut}
        onMouseEnter={handlePathHover}
        onMouseLeave={handlePathUnhover}
      />
    );
  });

  return (
    <div
      style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
      ref={tipHolder}
    >
      <svg viewBox="0 0 1050 480">{allSvgPaths}</svg>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        {RangeAlias.map((value, index) => (
          <svg key={colorScheme[index]} width={40} height="50">
            <rect width={40} height="15" fill={colorScheme[index]} />
            <text
              x={40 / 2}
              y="30"
              textAnchor="middle"
              fontSize="12"
              fill="#333"
            >
              {value}
            </text>
          </svg>
        ))}
      </div>
    </div>
  );
});
