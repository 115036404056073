import React, { useEffect, useState, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import Header from 'components/header';
import { getWarehouseCustomerList } from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { ContainerFlat, DatagridPremium } from 'styles';

import { Typography, Box, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridColumns,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';

import { MainContainer, ContentContainer } from '../styles';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          flex: '1',
          width: '100% !important',
        }}
      >
        <GridToolbarExport />
        <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>
  );
}

function OrderCustomers() {
  const theme = useTheme();
  const { updateData } = useContext(GlobalContext);
  const [initiatePrint, setInitiatePrint] = useState(false);
  const [editOrderCustomer, setEditOrderCustomer] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [dataSelected, setDataSelected] = useState({
    customerId: '',
    name: '',
    itemSKU: '',
    qtyOnHand: '',
    qtyOrdered: '',
  });
  const [searchParams, setSearchParams] = useState({
    searchOrderCustomerId: '',
    searchCustomerName: '',
    searchNoShippingAddresses: '',
    searchNoBillingAddresses: '',
  });

  const [filteredWarehouseCustomers, setFilteredWarehouseCustomers] = useState(
    [],
  );
  const [warehouseCustomers, setWarehouseCustomers] = useState([]);
  const ticketRef = useRef(null);
  const print = useReactToPrint({
    content: () => ticketRef.current,
  });

  const onForm = (key, text) => {
    setSearchParams(() => ({
      ...searchParams,
      [key]: text,
    }));
  };

  const onLoadWarehouseCustomers = async () => {
    try {
      const warehouseCustomersFromApi = await getWarehouseCustomerList(
        currentUser.Claim_CustomerId,
      );
      setWarehouseCustomers(warehouseCustomersFromApi);
      setFilteredWarehouseCustomers(warehouseCustomersFromApi);
      return true;
    } catch (err: any) {
      return err;
    }
  };

  type WarehouseCustomerRows = typeof warehouseCustomers[number];

  const warehouseCustomerColumns: GridColumns<WarehouseCustomerRows> = [
    {
      field: 'name',
      width: 250,
      headerName: 'Name',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`/customers/warehouseCustomer?form=view&id=${params.row.warehouseCustomerId}`}
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.name}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'skUs',
      width: 130,
      align: 'right',
      headerAlign: 'right',
      headerName: 'SKUs(Active)',
    },
    {
      field: 'packages',
      width: 130,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Packages',
    },
    {
      field: 'licensePlates',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      headerName: 'License Plates',
    },
    {
      field: 'locations',
      width: 120,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Locations',
    },
    {
      field: 'pointOfContact',
      width: 200,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Point of Contact',
    },
    {
      field: 'email',
      width: 250,
      headerName: 'Email',
    },
    {
      field: 'phone',
      width: 150,
      align: 'left',
      headerName: 'Phone',
    },
    {
      field: 'phone2',
      width: 200,
      headerName: 'Phone 2',
    },
  ];

  useEffect(() => {
    onLoadWarehouseCustomers();

    if (initiatePrint) {
      print();
      setInitiatePrint(false);
    }
  }, [updateData, initiatePrint]);

  return (
    <MainContainer>
      <Header />
      <ContentContainer>
        <ContainerFlat>
          <DatagridPremium
            autoHeight
            rows={warehouseCustomers}
            columns={warehouseCustomerColumns}
            pageSize={15}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            rowsPerPageOptions={[15]}
            getRowId={(row) => row.warehouseCustomerId}
            components={{ Toolbar: CustomToolbar }}
            density="compact"
          />
        </ContainerFlat>
      </ContentContainer>
    </MainContainer>
  );
}

export default React.memo(OrderCustomers);
