import { IState } from 'common/interfaces/states';
import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getUsStatesList = async () => {
  try {
    const response = await httpClient.get('/state/states');
    return response.data as IState[];
  } catch (error) {
    throw errorCatch(error);
  }
};
