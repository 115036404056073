import React, { useContext, useEffect, useState } from 'react';

import { ModalBox, ModalContent } from 'components/styles';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Typography, Button } from '@mui/material';

import { useWarehouseCustomerContext } from '..';

export default React.memo(() => {
  const { isCopyDefaultCustomerModalOpen, setIsCopyDefaultCustomerModalOpen } =
    useContext(GlobalContext);
  const { handleCopyDefaultFromCustomer } = useWarehouseCustomerContext();

  return (
    <Modal open={isCopyDefaultCustomerModalOpen}>
      <ModalBox>
        <ModalContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="subtitle1"
            fontWeight="bold"
          >
            Are you sure you want to override the rules with the defaults?
          </Typography>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: '8px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="outlined"
              size="medium"
              onClick={() => setIsCopyDefaultCustomerModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={() => handleCopyDefaultFromCustomer()}
            >
              Confirm
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
