import React, { useContext, useState } from 'react';

import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Grid as MUIGrid,
  Button,
  CircularProgress,
} from '@mui/material';

import { useInventoryItemDetailsContext } from '../details';

export default React.memo(() => {
  const { isDeleteInventoryModalOpen, setIsDeleteInventoryModalOpen } =
    useContext(GlobalContext);

  const [loadingPage, setLoadingPage] = useState<boolean>(false);

  const { onClickDeleteInventories } = useInventoryItemDetailsContext();

  return (
    <Modal open={isDeleteInventoryModalOpen}>
      <MUIContainer sx={{ width: '30% !important' }}>
        <Typography
          className="modalTextHeaderError"
          variant="h5"
          fontWeight="bold"
        >
          Delete Inventory
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <Typography variant="h6">
              Are you sure to delete the selected inventories?
            </Typography>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={5}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '10px',
                padding: '0px',
                justifyContent: 'flex-end',
              }}
            >
              {loadingPage ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    onClick={() => setIsDeleteInventoryModalOpen(false)}
                    variant="outlined"
                    size="small"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      padding: 1,
                      width: '100%',
                      backgroundColor: ' rgb(75, 126, 254) !important',
                    }}
                    onClick={() => {
                      setLoadingPage(true);
                      onClickDeleteInventories().then(() => {
                        setIsDeleteInventoryModalOpen(false);
                        setLoadingPage(false);
                      });
                    }}
                  >
                    Confirm
                  </Button>
                </>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
