import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import {
  getLotActivityLog,
  getLotLookupDatagrid,
} from 'services/api/lot/lot.api.';
import { AuthContext } from 'store/contexts/AuthContext';

interface ILots {
  setLotsDataGrid: React.Dispatch<React.SetStateAction<any[]>>;
  lotsDataGrid: any[];
  setFilteredLotsDataGrid: React.Dispatch<React.SetStateAction<any[]>>;
  filteredLotsDataGrid: any[];
  setIsShowLotIdHistoryModal: React.Dispatch<React.SetStateAction<boolean>>;
  isShowLotIdHistoryModal: boolean;
  viewLoadDataLots: (view?: string) => Promise<void>;
  setShowLotIdHistoryData: React.Dispatch<any>;
  showLotIdHistoryData: any;
  viewOpenLotsModal: (
    id: number,
    itemId: number,
    view: string,
  ) => Promise<void>;
  setViewBy: React.Dispatch<React.SetStateAction<string>>;
  viewBy: string;
}

type LotsProviderProps = {
  children: React.ReactNode;
};

const LotsContext = createContext<ILots>({} as ILots);

export const useLotsContext = () => useContext(LotsContext);

export const LotsContextProvider = ({ children }: LotsProviderProps) => {
  const { currentLocationAndFacility } = useContext(AuthContext);

  const [lotsDataGrid, setLotsDataGrid] = useState([]);
  const [filteredLotsDataGrid, setFilteredLotsDataGrid] = useState([]);
  const [isShowLotIdHistoryModal, setIsShowLotIdHistoryModal] = useState(false);
  const [showLotIdHistoryData, setShowLotIdHistoryData] = useState(null);
  const [viewBy, setViewBy] = useState('lot');

  const viewOpenLotsModal = async (
    id: number,
    itemId: number,
    view: string,
  ) => {
    getLotActivityLog(id, itemId, view).then((data) => {
      setShowLotIdHistoryData(data);
    });
  };

  const viewLoadDataLots = async (view?: string) => {
    getLotLookupDatagrid(
      currentLocationAndFacility.customerFacilityId,
      view,
    ).then((data) => {
      setLotsDataGrid(data);
      setFilteredLotsDataGrid(data);
    });
  };

  useEffect(() => {
    viewLoadDataLots();
  }, [currentLocationAndFacility]);

  const value: ILots = useMemo(
    () => ({
      lotsDataGrid,
      isShowLotIdHistoryModal,
      showLotIdHistoryData,
      filteredLotsDataGrid,
      setLotsDataGrid,
      viewLoadDataLots,
      viewOpenLotsModal,
      setShowLotIdHistoryData,
      setFilteredLotsDataGrid,
      setIsShowLotIdHistoryModal,
      setViewBy,
      viewBy,
    }),
    [
      filteredLotsDataGrid,
      lotsDataGrid,
      isShowLotIdHistoryModal,
      showLotIdHistoryData,
      viewBy,
    ],
  );
  return <LotsContext.Provider value={value}>{children}</LotsContext.Provider>;
};
