import React, { useContext, useEffect, useRef, useState } from 'react';

import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import Button from 'components/button';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import {
  deletePurchaseOrderDocument,
  getPurchaseOrderDocumentService,
  getPurchaseOrderDocuments,
  uploadPurchaseOrderDocuments,
} from 'services/api/purchaseOrders/purchaseOrders.api';
import {
  getReceiptsDocuments,
  uploadReceiptsDocuments,
  deleteReceiptDocument,
  getReceiptDocumentService,
} from 'services/api/receipts/receipts-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Link,
  Autocomplete,
  TextField,
  Grid as MUIGrid,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { GridColumns, DataGrid, GridToolbar } from '@mui/x-data-grid';

import { useSubpageReceiptsContext } from './context';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 1000px;
    border-radius: 4px;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;

interface ModalProps {
  receiptId?: any;
}

type AutocompleteBase = {
  label?: string;
  value?: number;
};

const DocumentTypeOption: AutocompleteBase[] = [
  {
    value: 1,
    label: 'Bill of Lading',
  },
  {
    value: 2,
    label: 'Invoice',
  },
  {
    value: 3,
    label: 'Packing List',
  },
  {
    value: 4,
    label: 'Purchase Order',
  },
  {
    value: 5,
    label: 'Note',
  },
  {
    value: 6,
    label: 'Removal Order',
  },
  {
    value: 7,
    label: 'Other',
  },
];

export default React.memo(() => {
  const { queryStringItemReceipts } = useSubpageReceiptsContext();

  const query = useQuery();
  const receiptId = Number(query.get('id')) || queryStringItemReceipts?.id;

  const {
    isReceiptDocumentModalOpen,
    onCloseReceiptDocumentModal,
    isPurchaseOrderDocumentModalOpen,
    onClosePurchaseOrderDocumentModal,
  } = useContext(GlobalContext);

  const { handleUserPermissionAllow, isSupplierAccount } =
    useContext(AuthContext);

  const [documentRows, setDocumentRows] = useState([]);

  const [documentTypeValue, setDocumentTypeValue] =
    useState<AutocompleteBase>(null);
  const [documentTypeError, setDocumentTypeError] = useState(null);

  const [documentRowsPurchaseOrder, setDocumentRowsPurchaseOrder] = useState(
    [],
  );

  const loadReceiptDocuments = () => {
    if (receiptId > 0) {
      getReceiptsDocuments(receiptId).then((data) => {
        setDocumentRows(data);
      });
    }
  };

  const deleteDocumentClick = (documentId) => {
    try {
      deleteReceiptDocument(documentId).then(() => {
        snackActions.success('Document removed');
        loadReceiptDocuments();
      });
    } catch (error) {
      snackActions.error(error);
    }
  };

  const getReceiptDocument = (awsFileKey, fileName) => {
    try {
      getReceiptDocumentService(awsFileKey);
    } catch (error) {
      snackActions.error(error);
    }
  };

  const lineItemsColumns: GridColumns = [
    {
      field: 'documentName',
      width: 350,
      hide: false,
      hideable: false,
      filterable: false,
      headerName: 'Document Name',
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Document Name</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Button
          variant="text"
          size="small"
          onClick={() => {
            getReceiptDocument(
              params?.row?.awsFileKey,
              params?.row?.documentName,
            );
          }}
        >
          {params?.row?.documentName}
        </Button>
      ),
    },
    {
      field: 'documentType',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Document Type</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row?.documentType}</Typography>
      ),
    },
    {
      field: 'uploadDate',
      width: 250,
      hide: false,
      hideable: false,
      filterable: false,
      headerName: 'Upload Date',
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Upload Date</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params?.row?.uploadDate !== undefined
            ? moment(params?.row?.uploadDate).format('MMM DD YYYY HH:mm')
            : ''}
        </Typography>
      ),
    },
    {
      field: 'awsFileKey',
      width: 100,
      hide: true,
      hideable: false,
      filterable: false,
      headerName: 'File Key',
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">File Key</Typography>
        </Box>
      ),
    },
    {
      field: 'delete',
      width: 70,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold" />
        </Box>
      ),
      renderCell: (params) => (
        <IconButton
          aria-label="print"
          color="primary"
          onClick={(e) => {
            deleteDocumentClick(params.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const inputFile = useRef(null);
  const handleReset = () => {
    if (inputFile.current) {
      inputFile.current.value = '';
      inputFile.current.type = 'text';
      inputFile.current.type = 'file';
    }
  };

  const uploadDocument = (event) => {
    const temp: any = {};

    temp.documentTypeValue = documentTypeValue ? '' : 'This field is required';

    setDocumentTypeError({
      ...temp,
    });

    if (!Object.values(temp).every((x) => x === '')) {
      return;
    }

    if (receiptId > 0) {
      try {
        uploadReceiptsDocuments(
          receiptId,
          event.target.files,
          documentTypeValue.value,
        ).then(() => {
          loadReceiptDocuments();
        });
      } catch (error) {
        snackActions.error(error);
      }
    }
    handleReset();
  };

  // purchase order ---------------------------------------------------
  const loadPurchaseOrderDocuments = () => {
    if (receiptId > 0) {
      getPurchaseOrderDocuments(receiptId).then((data) => {
        setDocumentRowsPurchaseOrder(data);
      });
    }
  };

  const uploadDocumentPurchaseOrder = (event) => {
    const temp: any = {};

    temp.documentTypeValue = documentTypeValue ? '' : 'This field is required';

    setDocumentTypeError({
      ...temp,
    });

    if (!Object.values(temp).every((x) => x === '')) {
      return;
    }

    if (receiptId > 0) {
      try {
        uploadPurchaseOrderDocuments(
          receiptId,
          event.target.files,
          documentTypeValue.value,
        ).then(() => {
          loadPurchaseOrderDocuments();
        });
      } catch (error) {
        snackActions.error(error);
      }
    }
    handleReset();
  };

  const deleteDocumentClickPurchaseOrder = (documentId) => {
    try {
      deletePurchaseOrderDocument(documentId).then(() => {
        snackActions.success('Document removed');
        loadPurchaseOrderDocuments();
      });
    } catch (error) {
      snackActions.error(error);
    }
  };

  const getPurchaseOrderDocument = (awsFileKey, fileName) => {
    try {
      getPurchaseOrderDocumentService(awsFileKey);
    } catch (error) {
      snackActions.error(error);
    }
  };

  const lineItemsColumnsPurchaseOrder: GridColumns = [
    {
      field: 'documentName',
      minWidth: 200,
      flex: 1,
      hide: false,
      hideable: false,
      filterable: false,
      headerName: 'Document Name',
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Document Name</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Button
          variant="text"
          size="small"
          onClick={() => {
            getPurchaseOrderDocument(
              params?.row?.awsFileKey,
              params?.row?.documentName,
            );
          }}
        >
          {params?.row?.documentName}
        </Button>
      ),
    },
    {
      field: 'documentType',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Document Type</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row?.documentType}</Typography>
      ),
    },
    {
      field: 'uploadDate',
      minWidth: 200,
      flex: 1,
      hide: false,
      hideable: false,
      filterable: false,
      headerName: 'Upload Date',
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Upload Date</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params?.row?.uploadDate !== undefined
            ? moment(params?.row?.uploadDate).format('MMM DD YYYY HH:mm')
            : ''}
        </Typography>
      ),
    },
    {
      field: 'awsFileKey',
      width: 100,
      hide: true,
      hideable: false,
      filterable: false,
      headerName: 'File Key',
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">File Key</Typography>
        </Box>
      ),
    },
    {
      field: 'delete',
      width: 70,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold" />
        </Box>
      ),
      renderCell: (params) => (
        <IconButton
          aria-label="print"
          color="primary"
          onClick={() => {
            deleteDocumentClickPurchaseOrder(params.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    if (isReceiptDocumentModalOpen) {
      loadReceiptDocuments();
      setDocumentTypeValue(null);
    }
  }, [isReceiptDocumentModalOpen]);

  useEffect(() => {
    if (isPurchaseOrderDocumentModalOpen) {
      loadPurchaseOrderDocuments();
      setDocumentTypeValue(null);
    }
  }, [isPurchaseOrderDocumentModalOpen]);

  return (
    <>
      {/* RECEIPT */}
      <Modal
        open={isReceiptDocumentModalOpen}
        onClose={onCloseReceiptDocumentModal}
      >
        <MUIContainer>
          <MUIContent>
            <Box
              sx={{
                marginBotton: '16px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                Receipt Documents
              </Typography>
              <IconButton onClick={onCloseReceiptDocumentModal} title="Close">
                <CloseIcon />
              </IconButton>
            </Box>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={3}>
                <Autocomplete
                  onChange={(event: any, newValue: AutocompleteBase) => {
                    if (newValue !== null) {
                      setDocumentTypeValue(newValue);
                      setDocumentTypeError(null);
                    } else {
                      setDocumentTypeValue(null);
                    }
                  }}
                  id="controllable-states"
                  options={DocumentTypeOption}
                  getOptionLabel={(option: AutocompleteBase) => option.label}
                  value={documentTypeValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Document Types"
                      size="small"
                      type="text"
                      sx={{ width: '100%' }}
                      {...(documentTypeError?.documentTypeValue && {
                        error: true,
                        helperText: documentTypeError?.documentTypeValue,
                      })}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Button
                  sx={{ width: '100%' }}
                  variant="contained"
                  size="large"
                  component="label"
                  fileInput
                >
                  <AddIcon /> Upload files
                  <input
                    type="file"
                    name="file"
                    accept=".pdf, .jpg, .jpeg, .png, .doc, .docx, .xls, .csv, .xlsx, .xml"
                    multiple
                    onChange={uploadDocument}
                    hidden
                    ref={inputFile}
                  />
                </Button>
              </MUIGrid>
            </MUIGrid>

            <DataGrid
              autoHeight
              headerHeight={120}
              rows={documentRows}
              density="compact"
              columns={lineItemsColumns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'uploadDate', sort: 'desc' }],
                },
              }}
              pageSize={25}
              rowsPerPageOptions={[25]}
              getRowId={(row) => row.documentId}
              checkboxSelection={false}
              components={{ Toolbar: GridToolbar }}
              sx={{
                borderColor: 'transparent',
                '& .MuiDataGrid-columnSeparator--sideRight': {
                  display: 'none !important',
                },
              }}
              hideFooterSelectedRowCount
            />
            <Box />
          </MUIContent>
        </MUIContainer>
      </Modal>

      {/* PURCHASE ORDER */}
      <Modal
        open={isPurchaseOrderDocumentModalOpen}
        onClose={onClosePurchaseOrderDocumentModal}
      >
        <MUIContainer>
          <MUIContent>
            <Box
              sx={{
                marginBotton: '16px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                Purchase Order Documents
              </Typography>
              <IconButton
                onClick={onClosePurchaseOrderDocumentModal}
                title="Close"
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={3}>
                <Autocomplete
                  onChange={(event: any, newValue: AutocompleteBase) => {
                    if (newValue !== null) {
                      setDocumentTypeValue(newValue);
                      setDocumentTypeError(null);
                    } else {
                      setDocumentTypeValue(null);
                    }
                  }}
                  id="controllable-states"
                  options={DocumentTypeOption}
                  getOptionLabel={(option: AutocompleteBase) => option.label}
                  value={documentTypeValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Document Types"
                      size="small"
                      type="text"
                      sx={{ width: '100%' }}
                      {...(documentTypeError?.documentTypeValue && {
                        error: true,
                        helperText: documentTypeError?.documentTypeValue,
                      })}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Button
                  sx={{
                    width: '100%',
                    display:
                      handleUserPermissionAllow(
                        UserPermissionEnum.PurchaseOrder_DocumentUpload,
                      ) || isSupplierAccount
                        ? 'flex !important'
                        : 'none',
                  }}
                  variant="contained"
                  size="medium"
                  component="label"
                  fileInput
                  disabled={documentTypeValue === null}
                >
                  <AddIcon /> Upload files
                  <input
                    type="file"
                    name="file"
                    accept=".pdf, .jpg, .jpeg, .png, .doc, .docx, .xls, .csv, .xlsx, .xml"
                    multiple
                    onChange={uploadDocumentPurchaseOrder}
                    hidden
                    ref={inputFile}
                  />
                </Button>
              </MUIGrid>
            </MUIGrid>
            <DataGrid
              autoHeight
              headerHeight={120}
              rows={documentRowsPurchaseOrder}
              disableColumnMenu
              density="compact"
              columns={lineItemsColumnsPurchaseOrder}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'uploadDate', sort: 'desc' }],
                },
              }}
              pageSize={25}
              rowsPerPageOptions={[25]}
              getRowId={(row) => row.documentId}
              checkboxSelection={false}
              components={{ Toolbar: GridToolbar }}
              sx={{
                borderColor: 'transparent',
                '& .MuiDataGrid-columnSeparator--sideRight': {
                  display: 'none !important',
                },
              }}
              hideFooterSelectedRowCount
            />
            <Box />
          </MUIContent>
        </MUIContainer>
      </Modal>
    </>
  );
});
