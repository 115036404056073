import React, { useContext, useEffect, useState } from 'react';

import { usePackAndShipContext } from 'pages/packandship/context';
import { GlobalContext } from 'store/contexts/GlobalContext';

import CloseIcon from '@mui/icons-material/Close';
import {
  Modal,
  Typography,
  Grid as MUIGrid,
  Box,
  TextField,
  IconButton,
  Chip,
  Button,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { padding } from '@mui/system';

const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;
    border: none;
    width: 100%;
    max-width: 500px;
    border-radius: 20px;
    outline: none;
  }
`;

const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 40px;
    gap: 16px;
  }
`;

export default React.memo(() => {
  const { isSerialNumberScanOpen, setIsSerialNumberScanOpen } =
    useContext(GlobalContext);

  const {
    pickTicketFormObj,
    serialNumberData,
    serialNumberBarcode,
    serialNumbers,
    isShowSerialNumberBarcodeError,
    messageShowSerialNumberBarcodeError,
    setSerialNumberBarcode,
    setSerialNumbers,
    setIsShowSerialNumberBarcodeError,
    createPackAndShipPackageLineitemSerialNoFunction,
  } = usePackAndShipContext();

  const onTextChanged = async (e: any) => {
    setSerialNumberBarcode(e.target.value);
  };

  const confirmHandler = () => {
    if (
      serialNumberData?.pickTicketLineItemId !== undefined ||
      serialNumberData?.pickTicketLineItemId !== null
    ) {
      createPackAndShipPackageLineitemSerialNoFunction(
        serialNumberData.pickTicketLineItemId,
        serialNumbers,
      );
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        setIsSerialNumberScanOpen(false);
      } else if (event.key === 'Enter') {
        event.preventDefault();
        confirmHandler();
      }
    };
    document.addEventListener('keyup', keyDownHandler);
    return () => {
      document.removeEventListener('keyup', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (serialNumberBarcode && serialNumberBarcode.length > 0) {
        setSerialNumbers([...serialNumbers, serialNumberBarcode]);
      }
      setSerialNumberBarcode('');
    }, 600);
    return () => clearTimeout(delayDebounceFn);
  }, [serialNumberBarcode]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setIsShowSerialNumberBarcodeError(false);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [isShowSerialNumberBarcodeError]);

  useEffect(() => {
    setSerialNumbers([]);
  }, [isSerialNumberScanOpen]);

  return (
    <Modal open={isSerialNumberScanOpen}>
      <MUIContainer>
        <MUIContent>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={12} sx={{ textAlignLast: 'right' }}>
              <IconButton
                color="inherit"
                // onClick={() => setOpen(false)}
                aria-label="close"
                onClick={() => setIsSerialNumberScanOpen(false)}
              >
                <CloseIcon style={{ transform: 'scale(1.9)' }} />
                <span style={{ fontSize: 'x-small' }}>
                  {' '}
                  (Press Esc to close)
                </span>
              </IconButton>
            </MUIGrid>

            <MUIGrid item xs={12}>
              <Typography variant="h6">
                Scan Serial Number: <b>{serialNumberData?.sku}</b>
              </Typography>

              <TextField
                sx={{ width: '100%' }}
                placeholder="000000"
                value={serialNumberBarcode}
                onChange={onTextChanged}
                autoFocus
                inputProps={{
                  'data-state': 'serialnumber',
                  autoComplete: 'off',
                }}
                inputRef={(input) => {
                  if (input != null) {
                    setTimeout(() => {
                      input.focus();
                    }, 100);
                  }
                }}
                autoComplete="off"
              />
            </MUIGrid>

            {serialNumbers.length > 0 && (
              <MUIGrid item xs={12}>
                {serialNumbers.map((x, i) => (
                  <Chip
                    sx={{ margin: 0.2 }}
                    key={Math.random()}
                    variant="outlined"
                    label={x}
                    onDelete={() => {
                      let serialNumbersTemp: string[] = [...serialNumbers];

                      serialNumbersTemp = [
                        ...serialNumbersTemp.filter((sn, ii) => ii !== i),
                      ];

                      setSerialNumbers(serialNumbersTemp);
                    }}
                  />
                ))}
              </MUIGrid>
            )}

            {isShowSerialNumberBarcodeError && (
              <MUIGrid
                item
                xs={12}
                sx={{
                  marginLeft: 1.4,
                }}
              >
                <Box
                  className="customizeSnackActionRed pulse"
                  sx={{ display: '' }}
                >
                  <Typography variant="subtitle2">
                    {messageShowSerialNumberBarcodeError}
                  </Typography>
                </Box>
              </MUIGrid>
            )}
          </MUIGrid>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              width: '100% !important',
              marginLeft: '1px !important',
            }}
          >
            <Button onClick={confirmHandler} variant="contained" size="medium">
              Confirm
            </Button>
          </MUIGrid>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
