import React, { useContext, useState, useEffect } from 'react';

import { snackActions } from 'config/snackbar.js';
import { getNavigationPageList } from 'services/api/navigationpages/navigationpages.api';
import { createQuickLink } from 'services/api/quicklinks/quicklinks.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Grid as MUIGrid,
  Button,
  Autocomplete,
  TextField,
  InputAdornment,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 400px;
    border-radius: 20px;
    max-height: 200%;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 40px;
    gap: 16px;
    height: 100%;
  }
`;

type NavigationPageType = {
  navigationPageId: number;
  name: string;
};

const lettersOnlyRegex = /^[a-zA-Z]+$/;
const AddQuickLinkModal = () => {
  const {
    setAddQuickLinkModalOpen,
    isAddQuickLinkModalOpen,
    quickLinks,
    setUserQuickLinks,
  } = useContext(GlobalContext);
  const { currentUser } = useContext(AuthContext);

  const [navigationPage, setNavigationPage] =
    useState<NavigationPageType>(null);

  const [isControlIncluded, setControlIncluded] = useState(false);
  const [isAltIncluded, setAltIncluded] = useState(false);
  const [hotKey, setHotKey] = useState('');
  const [formErrors, setFormErrors] = useState<any>({});
  const [navigationPages, setNavigationPages] = useState<NavigationPageType[]>(
    [],
  );

  const generateShortcutKeys = () => {
    let shortcutKeys = '';
    if (isControlIncluded) {
      shortcutKeys += 'ctrl+';
    }
    if (isAltIncluded) {
      shortcutKeys += 'alt+';
    }
    shortcutKeys += hotKey;
    return shortcutKeys;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getNavigationPageList();
        setNavigationPages(response);
      } catch (error) {
        snackActions.error(error);
      }
    };

    fetchData();
  }, [currentUser.Claim_CustomerId]);

  const formValidation = () => {
    const validationMessages: any = {};

    if (navigationPage === null) {
      validationMessages.navigationPage = 'This field is required';
    }

    if (hotKey === '' || hotKey === null) {
      validationMessages.hotKey = 'This field is required';
    }

    if (!isControlIncluded && !isAltIncluded) {
      validationMessages.controlAlt = 'Please select at least one option';
    }

    setFormErrors({ ...validationMessages });
    return Object.keys(validationMessages).length === 0;
  };

  const addQuickLink = async () => {
    const isValid = formValidation();
    if (!isValid) {
      return;
    }

    const payload = {
      navigationPageId: navigationPage.navigationPageId,
      shortcutKeys: generateShortcutKeys(),
    };

    try {
      const response = await createQuickLink(payload);
      snackActions.success('Custom link added successfully.');
      const sortedQuickLinks = [...quickLinks, response].sort((a, b) =>
        a.navigationPageName.localeCompare(b.navigationPageName),
      );
      setUserQuickLinks(sortedQuickLinks);
      setNavigationPage(null);
      setControlIncluded(false);
      setAltIncluded(false);
      setHotKey('');
      setFormErrors({});
    } catch (err) {
      snackActions.error(err);
    }
  };

  return (
    <Modal open={isAddQuickLinkModalOpen}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Add New Custom Link
        </Typography>
        <MUIContent>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold">
                Select Link
              </Typography>
              <Autocomplete
                options={navigationPages}
                value={navigationPage}
                getOptionLabel={(option: NavigationPageType) => option.name}
                size="small"
                onChange={(event: any, newValue: NavigationPageType) => {
                  setNavigationPage(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    error={!!formErrors.navigationPage}
                    helperText={formErrors.navigationPage}
                    sx={{
                      '& .MuiInputBase-root': {
                        background: 'white',
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </MUIGrid>
            <MUIGrid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold">
                Create Shortcut Key
              </Typography>
              <MUIGrid
                container
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ margin: 1 }}
              >
                <MUIGrid item xs={7} container justifyContent="space-between">
                  <MUIGrid item>
                    <Button
                      variant={isControlIncluded ? 'contained' : 'outlined'}
                      size="small"
                      sx={{
                        padding: 1,
                        backgroundColor: isControlIncluded
                          ? 'rgb(75, 126, 254) !important'
                          : 'white',
                      }}
                      onClick={() => {
                        setControlIncluded(!isControlIncluded);
                      }}
                    >
                      Ctrl
                    </Button>
                  </MUIGrid>
                  <MUIGrid item>
                    <Button
                      variant={isAltIncluded ? 'contained' : 'outlined'}
                      size="small"
                      sx={{
                        padding: 1,
                        backgroundColor: isAltIncluded
                          ? 'rgb(75, 126, 254) !important'
                          : 'white',
                      }}
                      onClick={() => {
                        setAltIncluded(!isAltIncluded);
                      }}
                    >
                      Alt
                    </Button>
                  </MUIGrid>
                  <MUIGrid item>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                      }}
                    >
                      +
                    </Typography>
                  </MUIGrid>
                  {formErrors.controlAlt && (
                    <Typography
                      variant="body2"
                      color="error"
                      sx={{ fontSize: '12px' }}
                    >
                      {formErrors.controlAlt}
                    </Typography>
                  )}
                </MUIGrid>
                <MUIGrid item container xs={4}>
                  <TextField
                    onKeyDown={(event) => {
                      if (!lettersOnlyRegex.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    error={!!formErrors.hotKey}
                    helperText={formErrors.hotKey}
                    inputProps={{
                      maxLength: 1,
                      endAdornment: formErrors.hotKey && (
                        <InputAdornment position="end">
                          <Typography variant="body2" color="error">
                            {formErrors.hotKey}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    value={hotKey}
                    onChange={(event) => {
                      const hotkey = event.target.value;
                      setHotKey(hotkey);
                    }}
                  />
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIContent>
        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={8}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                type="submit"
                variant="outlined"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                }}
                onClick={() => {
                  setAddQuickLinkModalOpen(false);
                  setNavigationPage(null);
                  setControlIncluded(false);
                  setAltIncluded(false);
                  setHotKey('');
                  setFormErrors({});
                }}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={addQuickLink}
              >
                ADD
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
};

export default AddQuickLinkModal;
