import React, { useContext, useEffect, useRef, useState } from 'react';

import { decimalValidationHelper } from 'pages/inventory/item/details';
import { usePackAndShipContext } from 'pages/packandship/context';
import { AutocompleteBase } from 'pages/sales/orderprocessingrules/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { getItemsAsPallets } from 'services/api/inventory/inventory-new.api';
import { getPalletizePackage } from 'services/api/packandship/packandship.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Typography,
  Grid as MUIGrid,
  Box,
  TextField,
  Button,
  Autocomplete,
  createFilterOptions,
} from '@mui/material';

interface IPalletizeModel {
  outboundPalletId?: number;
  pickTicketId?: string;
  heightInch?: number;
  widthInch?: number;
  lengthInch?: number;
  weightPound?: number;
  palletPackagingType?: AutocompleteBase;
}

export default React.memo(() => {
  const {
    isEditPalletizeMode,
    isPalletizeModalOpen,
    setEditPalletizeData,
    setIsEditPalletizeMode,
    setIsPalletizeModalOpen,
    editPalletizeData: editPalletizeValue,
  } = useContext(GlobalContext);

  const { createPalletizeFunction, setAllowBarcodeScanner, pickTicketFormObj } =
    usePackAndShipContext();

  const InitialFormPalletize: IPalletizeModel = {
    outboundPalletId: null,
    heightInch: null,
    widthInch: null,
    lengthInch: null,
    weightPound: null,
    palletPackagingType: null,
  };

  const [palletizeValue, setPalletizeValue] = useState(InitialFormPalletize);
  const [palletizeValueFormError, setPalletizeValueFormError] = useState<any>(
    {},
  );

  const [optionPalletPackaging, setOptionPalletPackaging] = useState<
    AutocompleteBase[] | null | []
  >([]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

  const validatePalletize = (fieldValues = palletizeValue) => {
    const temp: any = {};

    temp.palletPackagingType = fieldValues?.palletPackagingType
      ? ''
      : 'This field is required';
    temp.heightInch = fieldValues.heightInch ? '' : 'This field is required';
    temp.widthInch = fieldValues.widthInch ? '' : 'This field is required';
    temp.lengthInch = fieldValues.lengthInch ? '' : 'This field is required';
    temp.weightPound = fieldValues.weightPound ? '' : 'This field is required';

    setPalletizeValueFormError({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleCancelPalletize = async (e: any) => {
    e.preventDefault();
    setAllowBarcodeScanner(true);
    setIsPalletizeModalOpen(false);
    setEditPalletizeData(null);
    setIsEditPalletizeMode(false);
  };

  const handleSavePalletize = async (e: any) => {
    e.preventDefault();

    if (!validatePalletize()) {
      return;
    }

    createPalletizeFunction(palletizeValue);
    handleCancelPalletize(e);
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        setIsPalletizeModalOpen(false);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const itemRef = useRef(null);

  useEffect(() => {
    if (isPalletizeModalOpen) {
      getItemsAsPallets(pickTicketFormObj?.warehouseCustomerId ?? 0).then(
        (res) => {
          setOptionPalletPackaging(
            res.map((c: any) => ({ label: c.text, value: c.value })),
          );

          setAllowBarcodeScanner(false);
          itemRef.current.focus();
        },
      );

      if (editPalletizeValue && editPalletizeValue.outboundPalletId) {
        getPalletizePackage(editPalletizeValue.outboundPalletId).then((res) => {
          const data = {
            outboundPalletId: res?.outboundPalletId,
            heightInch: res?.heightInch,
            widthInch: res?.widthInch,
            lengthInch: res?.lengthInch,
            weightPound: res?.weightPound,
            palletPackagingType: {
              value: res?.itemAsPalletId,
              label: res?.itemAsPalletName,
            },
          };
          setPalletizeValue(data);
        });
      }
    }
  }, [isPalletizeModalOpen]);

  return (
    <Modal open={isPalletizeModalOpen}>
      <MUIContainer sx={{ maxWidth: '300px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          {isEditPalletizeMode ? 'Edit' : 'Create'} Palletize
        </Typography>
        <MUIContent sx={{ padding: '8px 20px !important ' }}>
          <Box>
            <MUIGrid
              container
              direction="row"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <Autocomplete
                  disableClearable
                  filterOptions={filterOptions}
                  onChange={(event: any, newValue: any) => {
                    if (newValue !== null) {
                      setPalletizeValue((prevState) => ({
                        ...prevState,
                        palletPackagingType: newValue,
                      }));
                    } else {
                      setPalletizeValue((prevState) => ({
                        ...prevState,
                        palletPackagingType: null,
                      }));
                    }
                  }}
                  id="controllable-states"
                  options={optionPalletPackaging}
                  value={palletizeValue?.palletPackagingType}
                  getOptionLabel={(option: AutocompleteBase) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      id="outlined"
                      label="Pallet Packaging"
                      size="small"
                      sx={{ width: '100%' }}
                      inputRef={itemRef}
                      required
                      {...(palletizeValueFormError.palletPackagingType && {
                        error: true,
                        helperText: palletizeValueFormError.palletPackagingType,
                      })}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  label="Height Inch"
                  name="heightInch"
                  value={palletizeValue.heightInch || ''}
                  onChange={(e) => {
                    if (Number(e.target.value) < 0) {
                      e.target.value = '0';
                    }
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setPalletizeValue((prev) => ({
                      ...prev,
                      heightInch: Number(validateDecimal),
                    }));
                  }}
                  size="small"
                  type="number"
                  autoFocus
                  required
                  {...(palletizeValueFormError.heightInch && {
                    error: true,
                    helperText: palletizeValueFormError.heightInch,
                  })}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  label="Width Inch"
                  name="widthInch"
                  value={palletizeValue.widthInch || ''}
                  onChange={(e) => {
                    if (Number(e.target.value) < 0) {
                      e.target.value = '0';
                    }
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setPalletizeValue((prev) => ({
                      ...prev,
                      widthInch: Number(validateDecimal),
                    }));
                  }}
                  size="small"
                  type="number"
                  required
                  {...(palletizeValueFormError.widthInch && {
                    error: true,
                    helperText: palletizeValueFormError.widthInch,
                  })}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  label="Length Inch"
                  name="lengthInch"
                  value={palletizeValue.lengthInch || ''}
                  onChange={(e) => {
                    if (Number(e.target.value) < 0) {
                      e.target.value = '0';
                    }
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setPalletizeValue((prev) => ({
                      ...prev,
                      lengthInch: Number(validateDecimal),
                    }));
                  }}
                  size="small"
                  type="number"
                  required
                  {...(palletizeValueFormError.lengthInch && {
                    error: true,
                    helperText: palletizeValueFormError.lengthInch,
                  })}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  label="Weight Pound"
                  name="weightPound"
                  value={palletizeValue.weightPound || ''}
                  onChange={(e) => {
                    if (Number(e.target.value) < 0) {
                      e.target.value = '0';
                    }
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setPalletizeValue((prev) => ({
                      ...prev,
                      weightPound: Number(validateDecimal),
                    }));
                  }}
                  size="small"
                  type="number"
                  required
                  {...(palletizeValueFormError.weightPound && {
                    error: true,
                    helperText: palletizeValueFormError.weightPound,
                  })}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={10}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={handleCancelPalletize}
                variant="outlined"
                size="medium"
                sx={{ padding: 1, width: '100%' }}
              >
                Cancel
              </Button>

              <Button
                onClick={(e) => handleSavePalletize(e)}
                variant="contained"
                size="medium"
                sx={{ padding: 1, width: '100%' }}
              >
                Save
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
