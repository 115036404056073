/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router';

import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import {
  UpdateCycleCountItemCounted,
  UpdateEndCycleCount,
  editCycleCount,
  getCycleCountDetail,
  getCycleCountList,
} from 'services/api/cyclecount/cyclecount.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { GridEditRowsModel } from '@mui/x-data-grid';

type AutocompleteBase = {
  label?: string;
  value?: number;
};
type CustomerType = AutocompleteBase;

type QueryStringType = {
  type?: string;
  form?: string;
  id?: number;
};

export enum cycleCountType {
  Item = 1,
  Location = 2,
}

type PageViewerType = {
  isNew: () => boolean;
  isView?: () => boolean;
  isEdit?: () => boolean;
  isViewOrEdit: () => boolean;
};
export enum FormQueryStringStatus {
  View = 'view',
  New = 'new',
  Edit = 'edit',
}

type CycleCountDataGrid = {
  cycleCountId: number;
  cycleCountName?: string;
  created?: string;
  started?: string;
  dueDate?: string;
  assignedUser?: Date;
  progress?: string;
  discrepancies?: string[];
  lastActivity?: string;
  status?: string;
};

type CycleCountDataSet = {
  cycleCountName?: string;
  warehouseCustomer?: {
    value: number;
    label: string;
  };
  dueDate?: string;
  assignedUser?: {
    value: number;
    label: string;
  };
  locationType?: string[];
  itemType?: string[];
};

type CycleCountDetailDataSet = {
  item?: string;
  location?: string;
  expected?: number;
  counted?: number;
};

type CycleCountProviderProps = {
  children: React.ReactNode;
};
interface ICycleCount {
  cycleCountUpdateCountDetailModal: CycleCountDetailDataSet;
  cycleCountDataGrid: any;
  filteredCycleCountsGrid: any;
  filteredCycleCountDetails: any;
  cycleCountDataSet: any;
  locationDataModal: any;
  setCycleCountDataSet: any;
  setQueryStringItemCycleCount: any;
  queryStringItemCycleCount: QueryStringType;
  pageViewer: PageViewerType;
  setCycleCountDataGrid: React.Dispatch<
    React.SetStateAction<CycleCountDataGrid[] | []>
  >;
  setFilteredCycleCountsGrid: React.Dispatch<
    React.SetStateAction<CycleCountDataGrid[] | []>
  >;
  setLocationDataModal: React.Dispatch<React.SetStateAction<CycleCountDataSet>>;
  setCycleCountUpdateCountDetailModal: React.Dispatch<
    React.SetStateAction<CycleCountDetailDataSet>
  >;
  setFilteredCycleCountDetails: React.Dispatch<
    React.SetStateAction<CycleCountDataGrid>
  >;
  OnLoadCycleCountDetail: (id) => void;
  onHandleCycleCountUpdate: (e) => Promise<void>;
  onClickEndCycleCount: (e) => Promise<void>;
  onClickConfirmEndCycleCount: (e) => Promise<void>;
  cycleCountDataDetailModal: any;
  setCycleCountDataDetailModal: any;
  cycleCountDataDetailModalErrors: any;
  viewLoadDataCycleCount: any;
  setSelectionModelId: any;
  selectionModelId: any;
  setEditRowsModel: any;
  editRowsModel: any;
  handleEditSingleRowModelChange: any;
  setEditCountedDataModel: any;
}

const CycleCountContext = createContext<ICycleCount>({} as ICycleCount);
export const useCycleCountContext = () => useContext(CycleCountContext);

export const CycleCountContextProvider = ({
  children,
}: CycleCountProviderProps) => {
  const query = useQuery();
  const navigate = useNavigate();

  const {
    onOpenConfirmEndCycleCountModal,
    onCloseConfirmEndCycleCountModal,
    isLocationAndFacilityModalOpen,
  } = useContext(GlobalContext);
  const { currentLocationAndFacility, currentUser } = useContext(AuthContext);

  const initialStateOfQueryParams: QueryStringType = {
    type: query.get('type'),
    form: query.get('form'),
    id: Number(query.get('id')),
  };

  const [queryStringItemCycleCount, setQueryStringItemCycleCount] = useState(
    initialStateOfQueryParams,
  );

  const pageViewer: PageViewerType = {
    isNew: () => queryStringItemCycleCount.form === FormQueryStringStatus.New,
    isView: () =>
      queryStringItemCycleCount.form === FormQueryStringStatus.View &&
      queryStringItemCycleCount.id > 0,
    isEdit: () =>
      queryStringItemCycleCount.form === FormQueryStringStatus.Edit &&
      queryStringItemCycleCount.id > 0,
    isViewOrEdit: () =>
      (queryStringItemCycleCount.form === FormQueryStringStatus.View ||
        queryStringItemCycleCount.form === FormQueryStringStatus.Edit) &&
      queryStringItemCycleCount.id > 0,
  };

  const [cycleCountDataGrid, setCycleCountDataGrid] = useState([]);
  const [filteredCycleCountsGrid, setFilteredCycleCountsGrid] = useState([]);
  const [cycleCountDataSet, setCycleCountDataSet] =
    useState<CycleCountDataSet>();

  const initialStatecycleCountDataDetailModal: any = {
    cycleCountId: '',
    cycleCountName: '',
    cycleCountType: '',
    cycleCountTypeName: '',
    cycleCountTypeDropDown: {
      value: -1,
      label: '',
    },
    createdDateTime: '',
    dueDate: '',
    createdBy: '',
    startedDateTime: '',
    lastActivity: '',
    warehouseCustomer: {
      value: -1,
      label: '',
    },
    isBlindAccount: true,
    isRecountDiscrepancy: false,
    isFlagItemsLotDiscrepancy: false,
    isFlagForRecount: false,
    locationType: [],
    itemType: [],
    locationOrItemList: [],
  };

  // const [locationDataModal, setLocationDataModal] = useState<CycleCountDataSet>(
  //   initialStateOfDataModal,
  // );

  const [locationDataModal, setLocationDataModal] = useState({
    cycleCountName: '',
    warehouseCustomer: {
      value: -1,
      label: '',
    },
    dueDate: '',
    assignedUser: {
      value: -1,
      label: '',
    },
    locationType: [],
  });

  const [cycleCountDataDetailModal, setCycleCountDataDetailModal] =
    useState<any>(initialStatecycleCountDataDetailModal);
  const [filteredCycleCountDetails, setFilteredCycleCountDetails] =
    useState<any>([]);
  const [cycleCountDataDetailModalErrors, setCycleCountDataDetailModalErrors] =
    useState<any>({});

  const [selectionModelId, setSelectionModelId] = useState([]);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [editCountedDataModel, setEditCountedDataModel] = useState<any>();

  const [
    cycleCountUpdateCountDetailModal,
    setCycleCountUpdateCountDetailModal,
  ] = useState<CycleCountDetailDataSet>({});

  const onLoadCycleCountList = async () => {
    try {
      const cycleCountFromApi = await getCycleCountList(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
      );
      return cycleCountFromApi;
    } catch (err) {
      return err;
    }
  };

  const viewLoadDataCycleCount = async () => {
    const response = onLoadCycleCountList();
    response
      .then((opt) => {
        setCycleCountDataGrid(
          opt.map((c: any) => ({
            cycleCountId: c.cycleCountId,
            cycleCountName: c.cycleCountName,
            created: c.created,
            started: c.started,
            dueDate: c.dueDate,
            assignedUser: c.assignedToUserId,
            assignedToUserName: c.assignedToUserName,
            lastActivity: c.lastActivity,
            status: c.status,
            statusName: c.statusName,
            cycleCountTypeName: c.cycleCountTypeName,
          })),
        );

        setFilteredCycleCountsGrid(
          opt.map((c: any) => ({
            cycleCountId: c.cycleCountId,
            cycleCountName: c.cycleCountName,
            created: c.created,
            started: c.started,
            dueDate: c.dueDate,
            assignedUser: c.assignedToUserId,
            assignedToUserName: c.assignedToUserName,
            lastActivity: c.lastActivity,
            status: c.status,
            statusName: c.statusName,
            cycleCountTypeName: c.cycleCountTypeName,
          })),
        );
      })
      .catch((err) => console.log(err));
  };

  const OnLoadCycleCountDetail = async (cycleCountId) => {
    try {
      const response = getCycleCountDetail(cycleCountId);

      response.then((c) => {
        setCycleCountDataDetailModal((prev) => ({
          ...prev,
          cycleCountId: c.cycleCountId,
          cycleCountName: c.cycleCountName,
          cycleCountType: c.cycleCountType,
          cycleCountTypeName: c.cycleCountTypeName,
          createdDateTime: c.createdDateTime,
          dueDate: c.dueDate,
          createdBy: c.createdBy,
          startedDateTime: c.startedDateTime,
          lastActivity: c.lastActivity,
          isBlindAccount: c.isBlindAccount,
          isRecountDiscrepancy: c.isRecountDiscrepancy,
          isFlagItemsLotDiscrepancy: c.isFlagItemsLotDiscrepancy,
          isFlagForRecount: c.isFlagForRecount,
          statusName: c.statusName,
          warehouseCustomer: {
            value: c.warehouseCustomerId,
            label: c.warehouseCustomerName,
          },
          locationType: c.locationDetails,
          itemType: c.itemDetails,
          cycleCountTypeDropDown: {
            value: c.cycleCountType,
            label: c.cycleCountTypeName,
          },
          locationOrItemList: c.itemOrLocationList,
        }));

        setFilteredCycleCountDetails(c.itemOrLocationList);
      });

      return true;
    } catch (err) {
      return err;
    }
  };

  const validateOnlick = (fieldValues = cycleCountDataDetailModal) => {
    const temp: any = {};

    if ('cycleCountName' in fieldValues) {
      temp.cycleCountName = fieldValues.cycleCountName
        ? ''
        : 'This field is required';
    }
    // if ('warehouseCustomer' in fieldValues) {
    //   temp.warehouseCustomer =
    //     fieldValues.warehouseCustomer.value !== -1
    //       ? ''
    //       : 'This field is required';
    // }
    if ('dueDate' in fieldValues) {
      temp.dueDate = fieldValues.dueDate ? '' : 'This field is required';
    }

    // if (cycleCountDataDetailModal.cycleCountTypeDropDown.value === 1) {
    //   if ('itemType' in fieldValues) {
    //     temp.itemType =
    //       fieldValues.itemType.length > 0 ? '' : 'This field is required';
    //   }
    //   setCycleCountDataDetailModal((prev) => ({
    //     ...prev,
    //     locationType: [],
    //   }));
    // }

    // if (cycleCountDataDetailModal.cycleCountTypeDropDown.value === 2) {
    //   if ('locationType' in fieldValues) {
    //     temp.locationType =
    //       fieldValues.locationType.length > 0 ? '' : 'This field is required';
    //   }
    //   setCycleCountDataDetailModal((prev) => ({
    //     ...prev,
    //     itemType: [],
    //   }));
    // }

    setCycleCountDataDetailModalErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const onClickEndCycleCount = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();
    onOpenConfirmEndCycleCountModal();
  };

  const onClickConfirmEndCycleCount = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    const payload = {
      cycleCountId: queryStringItemCycleCount.id,
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
    };
    try {
      await UpdateEndCycleCount(payload);
      OnLoadCycleCountDetail(queryStringItemCycleCount.id);
      snackActions.success('End cycle count was successful.');
      onCloseConfirmEndCycleCountModal();
    } catch (err) {
      snackActions.error(err);
    }
  };

  const onBlurUpdateCountedCycleCount = async () => {
    const payload = {
      cycleCountId: queryStringItemCycleCount.id,
      cycleCountDetailId: editCountedDataModel.countDetailId,
      countedQty: editCountedDataModel.counted,
    };

    try {
      await UpdateCycleCountItemCounted(payload);
      OnLoadCycleCountDetail(queryStringItemCycleCount.id);
    } catch (err) {
      snackActions.error(err);
    }
  };

  const onHandleCycleCountUpdate = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    const payload = {
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      cycleCountId: cycleCountDataDetailModal.cycleCountId,
      warehouseCustomerId: cycleCountDataDetailModal.warehouseCustomer
        ? cycleCountDataDetailModal.warehouseCustomer.value
        : null,
      cycleCountName: cycleCountDataDetailModal.cycleCountName,
      dueDate: moment(cycleCountDataDetailModal.dueDate).format('MM/DD/YYYY'),
      cycleCountType: cycleCountDataDetailModal.cycleCountTypeDropDown.value,
      isBlindAccount: cycleCountDataDetailModal.isBlindAccount,
      isRecountDiscrepancy: cycleCountDataDetailModal.isRecountDiscrepancy,
      isFlagItemsLotDiscrepancy:
        cycleCountDataDetailModal.isFlagItemsLotDiscrepancy,
      isFlagForRecount: cycleCountDataDetailModal.isFlagForRecount,
      locationTypes: cycleCountDataDetailModal.locationType
        ? Object.keys(cycleCountDataDetailModal.locationType).map(
            (key) => cycleCountDataDetailModal.locationType[key].value,
          )
        : null,
      itemTypes: cycleCountDataDetailModal.itemType
        ? Object.keys(cycleCountDataDetailModal.itemType).map(
            (key) => cycleCountDataDetailModal.itemType[key].value,
          )
        : null,
    };

    if (validateOnlick()) {
      try {
        const result = await editCycleCount(payload);
        snackActions.success(
          `Cycle Count ${result.cycleCountName} has been successfully updated.`,
        );

        if (result.cycleCountType === 1) {
          navigate(
            `/cycle-count/detail?type=${cycleCountType[
              cycleCountType.Item
            ].toLocaleLowerCase()}&form=${FormQueryStringStatus.View}&id=${
              result.cycleCountId
            }`,
          );
        } else {
          navigate(
            `/cycle-count/detail?type=${cycleCountType[
              cycleCountType.Location
            ].toLocaleLowerCase()}&form=${FormQueryStringStatus.View}&id=${
              result.cycleCountId
            }`,
          );
        }
        setQueryStringItemCycleCount((prevState) => ({
          ...prevState,
          type:
            result.cycleCountType === 1
              ? cycleCountType[cycleCountType.Item].toLocaleLowerCase()
              : cycleCountType[cycleCountType.Location].toLocaleLowerCase(),
          form: 'view',
        }));

        OnLoadCycleCountDetail(result.cycleCountId);
      } catch (err) {
        snackActions.error(err);
      }
    }
  };

  const handleEditSingleRowModelChange = async (model: GridEditRowsModel) => {
    if (cycleCountDataDetailModal.statusName === 'Counted') {
      setEditRowsModel(model);
      const data = [...cycleCountDataDetailModal.locationOrItemList];

      const newValue = data.filter(
        (row) => row.countId === Number(Object.keys(model)[0]),
      );
      newValue[0].counted = Number(Object.values(model)[0].counted.value);

      const filterItemFromArray = data.filter(
        (item) => item.countId !== Number(Object.keys(model)[0]),
      );
      setCycleCountDataDetailModal((e) => ({
        ...e,
        locationDataModal: filterItemFromArray,
      }));

      setEditCountedDataModel(newValue[0]);

      if (Object.values(model)) {
        onBlurUpdateCountedCycleCount();
      }
    }
  };

  useEffect(() => {
    if (selectionModelId.length !== 0) {
      const selectedIDs = new Set(selectionModelId);
      const selectedRowData =
        cycleCountDataDetailModal.locationOrItemList.filter((row) =>
          new Set(selectedIDs).has(row.countId),
        );

      setFilteredCycleCountDetails(selectedRowData);
    }
  }, [selectionModelId]);

  useEffect(() => {
    if (!isLocationAndFacilityModalOpen) {
      viewLoadDataCycleCount();
    }
  }, [currentUser, isLocationAndFacilityModalOpen]);

  const value: ICycleCount = useMemo(
    () => ({
      pageViewer,
      cycleCountDataSet,
      cycleCountDataGrid,
      filteredCycleCountsGrid,
      filteredCycleCountDetails,
      cycleCountDataDetailModal,
      cycleCountUpdateCountDetailModal,
      locationDataModal,
      queryStringItemCycleCount,
      cycleCountDataDetailModalErrors,
      editRowsModel,
      selectionModelId,
      setEditCountedDataModel,
      handleEditSingleRowModelChange,
      setCycleCountDataSet,
      setCycleCountDataGrid,
      setCycleCountDataDetailModal,
      setCycleCountUpdateCountDetailModal,
      setFilteredCycleCountsGrid,
      setFilteredCycleCountDetails,
      setQueryStringItemCycleCount,
      setLocationDataModal,
      OnLoadCycleCountDetail,
      onClickEndCycleCount,
      onClickConfirmEndCycleCount,
      onHandleCycleCountUpdate,
      viewLoadDataCycleCount,
      setSelectionModelId,
      setEditRowsModel,
    }),
    [
      pageViewer,
      cycleCountDataSet,
      cycleCountDataGrid,
      filteredCycleCountsGrid,
      filteredCycleCountDetails,
      cycleCountDataDetailModalErrors,
      cycleCountUpdateCountDetailModal,
      cycleCountDataDetailModal,
      locationDataModal,
      queryStringItemCycleCount,
      selectionModelId,
      editRowsModel,
    ],
  );
  return (
    <CycleCountContext.Provider value={value}>
      {children}
    </CycleCountContext.Provider>
  );
};
