/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useContext, useState, ChangeEvent, useEffect } from 'react';

import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import { isUsername } from 'helpers/validators';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { getSupplierLookup } from 'services/api/suppliers/suppliers.api';
import {
  createUser,
  editUser,
  getUserSupplier,
  getUsersRoleOperator,
  getUsersRoleOperatorByUserId,
  getUsersRoleWarehouseCustomer,
  getUsersRoleWarehouseCustomerByUserId,
  getUsersRoleSupplierByUserId,
} from 'services/api/user/users.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Modal,
  Box,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Checkbox,
  Grid as MUIGrid,
  Button,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface CustomerUserModalProps {
  userData?: any;
  admin?: boolean;
  superAdmin?: boolean;
  edit?: boolean;
  callBack?: () => void;
}

interface AutoCompleteOptionType {
  id: number;
  label: string;
}

interface AutoCompleteType {
  value: number;
  label: string;
}
type RoleOperatorType = AutoCompleteOptionType;
type RoleWarehouseCustomerType = AutoCompleteOptionType;
type RoleSupplierType = AutoCompleteType;
export const optionSupplierTypes: readonly RoleSupplierType[] = [
  { value: 1, label: 'PO' },
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default React.memo(
  ({ userData, admin, superAdmin, callBack, edit }: CustomerUserModalProps) => {
    const theme = useTheme();
    const defaultAutocompleteOption: AutoCompleteOptionType | null = {
      id: -1,
      label: '',
    };
    const userRoles = [
      { id: 2, label: 'Admin' },
      { id: 3, label: 'Ops Manager' },
      { id: 4, label: 'Operator' },
      { id: 5, label: 'Viewer' },
      { id: 9, label: 'Customer' },
      { id: 10, label: 'Supplier' },
    ];
    const [viewerSelected, setViewerSelected] = useState(false);
    const [operatorSelected, setOperatorSelected] = useState(false);
    const [warehouseCustomerSelected, setWarehouseCustomerSelected] =
      useState(false);
    const [supplierSelected, setSupplierSelected] = useState(false);
    const [suppliers, setSuppliers] = useState<any>([]);
    const [mobileModules, setMobileModules] = useState<any>([]);
    const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
    const { isUserModalOpen, onCloseUserModal, handleUpdateData, updateData } =
      useContext(GlobalContext);
    const [error, setError] = useState('');
    const initialFormErrorsState: any = {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      role: '',
      password: '',
    };
    const initialState: any = {
      userId: '',
      customerLocationId: '',
      customerId: '',
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      role: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      roleOperator: [],
      password: '',
    };
    const [formErrors, setFormErrors] = useState(initialFormErrorsState);
    const [form, setForm] = useState<any>(initialState);
    const [viewerSuppliers, setViewerSuppliers] = useState<any>([]);
    const [operatorModules, setOperatorModules] = useState<any>([]);
    const [selectedSupplier, setSelectedSupplier] =
      useState<AutoCompleteOptionType | null>(defaultAutocompleteOption);
    const [selectedModule, setSelectedModule] =
      useState<AutoCompleteOptionType | null>(defaultAutocompleteOption);
    const [optionRoleOperators, setOptionRoleOperators] = useState<
      RoleOperatorType[]
    >([]);

    const [optionRoleWarehouseCustomers, setOptionRoleWarehouseCustomers] =
      useState<RoleWarehouseCustomerType[]>([]);
    const [optionRoleSuppliers, setOptionRoleSuppliers] =
      useState(optionSupplierTypes);

    const [roleOperatorValue, setRoleOperatorValue] = useState([]);
    const [roleWarehouseCustomerValue, setRoleWarehouseCustomerValue] =
      useState([]);
    const [roleSupplierValue, setRoleSupplierValue] = useState([]);

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const onForm = (key, text) => {
      setForm(() => ({
        ...form,
        [key]: text,
      }));
    };
    const onLoadCustomerSuppliers = async (customerId: number) => {
      try {
        const suppliersFromApi = await getSupplierLookup(customerId, '');
        setSuppliers(suppliersFromApi);
        return true;
      } catch (err: any) {
        return err;
      }
    };
    const onLoadOperatorModules = async () => {
      try {
        return await getUsersRoleOperator();
      } catch (err: any) {
        return err;
      }
    };
    const onLoadOperatorModulesByUserId = async (userID) => {
      try {
        return await getUsersRoleOperatorByUserId(userID);
      } catch (err: any) {
        return err;
      }
    };
    const onLoadWarehouseCustomerModules = async () => {
      try {
        return await getUsersRoleWarehouseCustomer();
      } catch (err: any) {
        return err;
      }
    };
    const onLoadWarehouseCustomerModulesByUserId = async (userID) => {
      try {
        return await getUsersRoleWarehouseCustomerByUserId(userID);
      } catch (err: any) {
        return err;
      }
    };
    const onLoadSupplierModulesByUserId = async (userID) => {
      try {
        return await getUsersRoleSupplierByUserId(userID);
      } catch (err: any) {
        return err;
      }
    };
    const onLoadViewerSuppliers = async (userId) => {
      try {
        const viewerSuppliersFromApi = await getUserSupplier(userId);

        setViewerSuppliers(viewerSuppliersFromApi);

        return true;
      } catch (err: any) {
        return err;
      }
    };

    const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) =>
      onForm(key, event.target.value);

    const autoCompleteInputHandler = (
      key: string,
      value: AutoCompleteOptionType,
    ) => {
      if (value.id === 5) {
        setViewerSelected(true);
        if (superAdmin) {
          onLoadCustomerSuppliers(userData.customerId);
        } else {
          onLoadCustomerSuppliers(currentUser.Claim_CustomerId);
        }
      } else {
        setViewerSelected(false);
      }

      if (value.id === 4) {
        setOperatorSelected(true);
        if (userData.userId === '') {
          setRoleOperatorValue(
            optionRoleOperators.map((c: any) => ({
              label: c.label,
              value: c.value,
            })),
          );
        }
      } else {
        setOperatorSelected(false);
      }

      if (value.id === 9) {
        setWarehouseCustomerSelected(true);
        if (userData.userId === '') {
          setRoleWarehouseCustomerValue(
            optionRoleWarehouseCustomers.map((c: any) => ({
              label: c.label,
              value: c.value,
            })),
          );
        }
      } else {
        setWarehouseCustomerSelected(false);
      }

      if (value.id === 10) {
        setSupplierSelected(true);
        if (userData.userId === '') {
          setRoleSupplierValue(
            optionRoleSuppliers.map((c: any) => ({
              label: c.label,
              value: c.value,
            })),
          );
        }
      } else {
        setSupplierSelected(false);
      }

      setForm((e) => ({
        ...e,
        role: value,
      }));
    };

    const supplierInputHandler = (
      key: string,
      value: AutoCompleteOptionType,
    ) => {
      if (value !== null) {
        setSelectedSupplier(value);
        const exists = viewerSuppliers.find(
          (supplier) => supplier.supplierId.toString() === value.id.toString(),
        );

        if (!exists) {
          setViewerSuppliers([
            ...viewerSuppliers,
            suppliers.find((supplier) => supplier.supplierId === value.id),
          ]);
        }
      }
    };

    const validateOnlick = (fieldValues = form) => {
      const temp: any = {};

      if ('firstName' in fieldValues) {
        temp.firstName = fieldValues.firstName ? '' : 'This field is required';
      }
      if ('lastName' in fieldValues) {
        temp.lastName = fieldValues.lastName ? '' : 'This field is required';
      }

      if ('username' in fieldValues) {
        if (fieldValues.username) {
          if (!isUsername(fieldValues.username)) {
            temp.username =
              'Username may contain only lowercase letters and numbers.';
          } else {
            temp.username = '';
          }
        } else {
          temp.username = 'This field is required';
        }
      }
      if ('email' in fieldValues) {
        if (fieldValues.email) {
          temp.email = /$^|.+@.+..+/.test(fieldValues.email)
            ? ''
            : 'Email is not valid';
        } else {
          temp.email = 'This field is required';
        }
      }

      if ('role' in fieldValues) {
        if (fieldValues.role.id !== -1) {
          if (fieldValues.role.id === 4) {
            temp.roleOperator =
              roleOperatorValue.length > 0 ? '' : 'This field is required';
          }
        } else {
          temp.role = 'A role must be selected';
        }
      }

      if ('role' in fieldValues) {
        if (fieldValues.role.id !== -1) {
          if (fieldValues.role.id === 9) {
            temp.roleWarehouseCustomer =
              roleWarehouseCustomerValue.length > 0
                ? ''
                : 'This field is required';
          }

          if (fieldValues.role.id === 10) {
            temp.roleSupplier =
              roleSupplierValue.length > 0 ? '' : 'This field is required';
          }
        } else {
          temp.role = 'A role must be selected';
        }
      }

      if ('password' in fieldValues) {
        if (fieldValues.password === '') {
          temp.password = fieldValues.password ? '' : 'This field is required';
        }
      }

      setFormErrors({
        ...temp,
      });
      return Object.values(temp).every((x) => x === '');
    };

    const supplierSelectionOptions = Array.isArray(suppliers)
      ? suppliers.map((supplier, index) => ({
          id: supplier.supplierId,
          label: `${supplier.name}`,
        }))
      : [{ id: 0, label: 'Supplier' }];

    const handleCloseUserModal = () => {
      onCloseUserModal();
      setFormErrors(initialFormErrorsState);
      setError('');
      if (edit) {
        callBack();
      }
      setViewerSelected(false);
      setSelectedSupplier({
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      });
      setViewerSuppliers([]);
      setRoleOperatorValue([]);
      setRoleWarehouseCustomerValue([]);
      setRoleSupplierValue([]);
      setOperatorSelected(false);
      setWarehouseCustomerSelected(false);
      setSupplierSelected(false);
      setIsSubmitLoading(false);
      setForm(initialState);
    };
    const handleSetEditForm = () => {
      setForm({
        customerLocationId: userData.customerLocationId,
        customerId: userData.customerId,
        firstName: userData.firstName,
        lastName: userData.lastName,
        username: userData.username,
        email: userData.email,
        role: userRoles.find((r) => r.id === userData.roleId),
        password: userData.password,
      });

      if (userData.roleId === 5) {
        setViewerSelected(true);
        onLoadViewerSuppliers(userData.userId);
        onLoadCustomerSuppliers(
          superAdmin ? userData.customerId : currentUser.Claim_CustomerId,
        );
      }

      if (userData.roleId === 4) {
        setOperatorSelected(true);
        const loadData = onLoadOperatorModulesByUserId(userData.userId);
        loadData
          .then((opt) => {
            setRoleOperatorValue(
              opt.map((c: any) => ({
                label: c.name,
                value: c.id,
              })),
            );
          })
          .catch((err) => console.log(err));
      }

      if (userData.roleId === 9) {
        setWarehouseCustomerSelected(true);
        const loadData = onLoadWarehouseCustomerModulesByUserId(
          userData.userId,
        );
        loadData
          .then((opt) => {
            setRoleWarehouseCustomerValue(
              opt.map((c: any) => ({
                label: c.name,
                value: c.id,
              })),
            );
          })
          .catch((err) => console.log(err));
      }
      if (userData.roleId === 10) {
        setSupplierSelected(true);
        const loadData = onLoadSupplierModulesByUserId(userData.userId);
        loadData
          .then((opt) => {
            setRoleSupplierValue(
              opt.map((c: any) => ({
                label: c.name,
                value: c.id,
              })),
            );
          })
          .catch((err) => console.log(err));
      }
    };

    const onSaveNewUser = async () => {
      const newForm: any = {};

      if (superAdmin) {
        newForm.customerId = userData.customerId;
        newForm.customerLocationId = userData.customerLocationId;
      } else {
        newForm.customerId = currentUser.Claim_CustomerId;
        newForm.customerLocationId =
          currentLocationAndFacility.customerLocationId;
      }

      if (validateOnlick()) {
        try {
          setIsSubmitLoading(true);

          newForm.email = form.email;
          newForm.firstName = form.firstName;
          newForm.lastName = form.lastName;
          newForm.operatorPermissions = roleOperatorValue.map((e) => e.value);
          newForm.password = form.password;
          newForm.role = form.role.id;
          newForm.supplierIds = viewerSuppliers.map(
            (supplier) => supplier.supplierId,
          );
          newForm.supplierPermissions = roleSupplierValue.map((e) => e.value);
          newForm.userId = form.userId;
          newForm.username = form.username;
          newForm.warehouseCustomerPermissions = roleWarehouseCustomerValue.map(
            (e) => e.value,
          );

          if (!edit) {
            await createUser(newForm);
            if (superAdmin) {
              snackActions.success(
                `Successfully created new user for ${userData.name}.`,
              );
            } else {
              snackActions.success(
                `Successfully created new user at ${currentLocationAndFacility.locationName}.`,
              );
            }
          } else {
            newForm.userId = userData.userId;
            await editUser(userData.userId, newForm);

            if (superAdmin) {
              snackActions.success(
                `Successfully edited user: ${userData.username} for ${userData.name}.`,
              );
            } else {
              snackActions.success(
                `Successfully edited user: ${userData.username} at ${currentLocationAndFacility.locationName}.`,
              );
            }
          }
        } catch (err: any) {
          setError(err);
          setFormErrors(initialFormErrorsState);
          handleSetEditForm();
          snackActions.error(`${err}`);
        }
        handleCloseUserModal();
        handleUpdateData();
      }
    };

    const handleSupplierDelete = async (supplierId) => {
      try {
        snackActions.success(`Successfully removed Supplier from list.`);
        const newViewerSuppliers = viewerSuppliers.filter(
          (supplier) => supplier.supplierId !== supplierId,
        );
        setViewerSuppliers(newViewerSuppliers);
        return true;
      } catch (err: any) {
        snackActions.error(err);
        return err;
      }
    };

    useEffect(() => {
      const optionsRoleOperator = onLoadOperatorModules();
      optionsRoleOperator
        .then((opt) => {
          setOptionRoleOperators(
            opt.map((c: any) => ({
              label: c.name,
              value: c.id,
            })),
          );
        })
        .catch((err) => console.log(err));

      const optionsRoleWarehouseCustomer = onLoadWarehouseCustomerModules();
      optionsRoleWarehouseCustomer
        .then((opt) => {
          setOptionRoleWarehouseCustomers(
            opt.map((c: any) => ({
              label: c.name,
              value: c.id,
            })),
          );
        })
        .catch((err) => console.log(err));
      if (isUserModalOpen) {
        if (!superAdmin && userData.userId !== '') {
          handleSetEditForm();
        } else {
          setForm(initialState);
        }
      }
    }, [isUserModalOpen]);

    if (!isUserModalOpen) return null;

    return (
      <Modal open={isUserModalOpen} onClose={() => handleCloseUserModal()}>
        <MUIContainer sx={{ width: '18% !important' }}>
          {admin ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {!edit ? (
                <Typography
                  className="modalTextHeader"
                  variant="h6"
                  fontWeight="bold"
                >
                  Create User ({currentLocationAndFacility.locationName})
                </Typography>
              ) : (
                <Typography
                  className="modalTextHeader"
                  variant="h6"
                  fontWeight="bold"
                >
                  Edit User ({currentLocationAndFacility.locationName})
                </Typography>
              )}
            </>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {superAdmin && (
                <Typography
                  sx={{ marginBottom: '16px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Create User
                </Typography>
              )}
            </>
          )}
          <MUIContent sx={{ padding: '10px 15px !important ' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                [theme.breakpoints.up('lg')]: {
                  flexDirection: 'row',
                },
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                },
                gap: '16px',
              }}
            >
              <Input
                sx={{ width: '100%' }}
                placeholder="First name"
                value={form.firstName}
                onChange={(value) => inputHandler('firstName', value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSaveNewUser();
                  }
                }}
                error={formErrors.firstName}
              />
              <Input
                sx={{ width: '100%' }}
                placeholder="Last name"
                value={form.lastName}
                onChange={(value) => inputHandler('lastName', value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSaveNewUser();
                  }
                }}
                error={formErrors.lastName}
              />
            </Box>
            <Input
              sx={{ width: '100%' }}
              placeholder="Username"
              value={form.username}
              onChange={(value) => inputHandler('username', value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSaveNewUser();
                }
              }}
              error={formErrors.username}
            />
            <Input
              sx={{ width: '100%' }}
              placeholder="Email"
              value={form.email}
              onChange={(value) => inputHandler('email', value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSaveNewUser();
                }
              }}
              error={formErrors.email}
            />
            <Autocomplete
              disableClearable
              sx={{ width: '100%' }}
              options={userRoles.filter((f) => ![9, 10].includes(f.id))}
              size="small"
              disabled={
                (userData.roleId === 9 && edit) ||
                (userData.roleId === 10 && edit)
              }
              value={form?.role || null}
              getOptionLabel={(option: AutoCompleteOptionType) => option.label}
              isOptionEqualToValue={(option, selected) =>
                option.id === selected.id
              }
              onChange={(event: any, value: AutoCompleteOptionType | null) => {
                autoCompleteInputHandler('role', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...(formErrors.role && {
                    error: true,
                    helperText: formErrors.role,
                  })}
                  label="Role"
                />
              )}
            />
            {viewerSelected && (
              <>
                <Autocomplete
                  sx={{ width: '100%' }}
                  options={supplierSelectionOptions}
                  size="small"
                  value={selectedSupplier}
                  onChange={(
                    event: any,
                    value: AutoCompleteOptionType | null,
                  ) => {
                    supplierInputHandler('supplier', value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Supplier" />
                  )}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                    gap: '16px',
                  }}
                >
                  {viewerSuppliers.map((supplier) => (
                    <Chip
                      key={supplier.supplierId}
                      label={`${supplier.name}`}
                      variant="outlined"
                      onDelete={(event: any) =>
                        handleSupplierDelete(supplier.supplierId)
                      }
                    />
                  ))}
                </Box>
              </>
            )}
            {operatorSelected && (
              <>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={optionRoleOperators}
                  value={roleOperatorValue}
                  disableCloseOnSelect
                  getOptionLabel={(option: AutoCompleteOptionType) =>
                    option.label
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  onChange={(event: any, newValue) => {
                    if (newValue !== null) {
                      setRoleOperatorValue(newValue);
                    } else {
                      setRoleOperatorValue(null);
                    }
                  }}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Module"
                      {...(formErrors.roleOperator && {
                        error: true,
                        helperText: formErrors.roleOperator,
                      })}
                      placeholder="Module"
                    />
                  )}
                />
                {}
              </>
            )}
            {warehouseCustomerSelected && (
              <>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={optionRoleWarehouseCustomers}
                  value={roleWarehouseCustomerValue}
                  disableCloseOnSelect
                  getOptionLabel={(option: AutoCompleteOptionType) =>
                    option.label
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  onChange={(event: any, newValue) => {
                    if (newValue !== null) {
                      setRoleWarehouseCustomerValue(newValue);
                    } else {
                      setRoleWarehouseCustomerValue(null);
                    }
                  }}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Permission"
                      {...(formErrors.roleWarehouseCustomer && {
                        error: true,
                        helperText: formErrors.roleWarehouseCustomer,
                      })}
                      placeholder="Permission"
                    />
                  )}
                />
                {}
              </>
            )}
            {supplierSelected && (
              <>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={optionRoleSuppliers}
                  value={roleSupplierValue}
                  disableCloseOnSelect
                  getOptionLabel={(option: AutoCompleteOptionType) =>
                    option.label
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  onChange={(event: any, newValue) => {
                    if (newValue !== null) {
                      setRoleSupplierValue(newValue);
                    } else {
                      setRoleSupplierValue(null);
                    }
                  }}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Permission"
                      {...(formErrors.roleSupplier && {
                        error: true,
                        helperText: formErrors.roleSupplier,
                      })}
                      placeholder="Permission"
                    />
                  )}
                />
                {}
              </>
            )}

            {!edit && (
              <Input
                sx={{ width: '100%' }}
                placeholder="Password"
                value={form.password}
                onChange={(value) => inputHandler('password', value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSaveNewUser();
                  }
                }}
                type="password"
                error={formErrors.password}
              />
            )}

            <Typography variant="subtitle2" color="#d32f2f">
              {error}
            </Typography>
          </MUIContent>
          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={8}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                  alignItems: 'center',
                  float: 'right',
                }}
              >
                {isSubmitLoading ? (
                  <>
                    <CircularProgress />
                    <Typography variant="subtitle2">Please Wait</Typography>
                  </>
                ) : (
                  <>
                    <Button
                      sx={{ minHeight: '48px' }}
                      variant="outlined"
                      size="medium"
                      onClick={() => handleCloseUserModal()}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{ minHeight: '48px' }}
                      variant="contained"
                      size="medium"
                      onClick={() => {
                        onSaveNewUser();
                      }}
                    >
                      Submit
                    </Button>
                  </>
                )}
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
    );
  },
);
