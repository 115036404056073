/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useContext,
  createContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router';

import { ISupplierDetail } from 'common/interfaces';
import { ToDecimal } from 'common/utils';
import Card from 'components/card';
import Header from 'components/header';
import Input from 'components/input';
import { Grid } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import useQuery from 'helpers/useQuery/useQuery';
import { MainContainer, ContentContainer } from 'pages/styles';
import { getCountries } from 'services/api/country/country.api';
import {
  EditCustomerSupplier,
  createSupplierPortalAccount,
  generateActivationUrl,
  getSupplierByID,
  getSupplierPortalAccount,
  updateSupplierPortalAccountIsActive,
} from 'services/api/customer/customer.api';
import { DatagridStandard } from 'styles';

import {
  Typography,
  Grid as MUIGrid,
  TextField,
  Autocomplete,
  Box,
  Button,
} from '@mui/material';
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid';

type AutocompleteBase = {
  label?: string;
  value?: number;
};
export type CustomerType = AutocompleteBase;
type PageViewerType = {
  isNew: () => boolean;
  isView?: () => boolean;
  isEdit?: () => boolean;
  isViewOrEdit: () => boolean;
};
enum FormQueryStringStatus {
  // eslint-disable-next-line no-unused-vars
  View = 'view',
  // eslint-disable-next-line no-unused-vars
  New = 'new',
  // eslint-disable-next-line no-unused-vars
  Edit = 'edit',
}
type QueryStringType = {
  form: string;
  id: number;
};

interface ISupplier {
  optionCountries: [] | AutocompleteBase[];
  supplierDetailData: ISupplierDetail;
  supplierPortalAccount: any[];
  setSupplierDetailData: React.Dispatch<React.SetStateAction<ISupplierDetail>>;
  setOptionCountries: React.Dispatch<
    React.SetStateAction<[] | AutocompleteBase[]>
  >;
  setSupplierPortalAccount: React.Dispatch<React.SetStateAction<any[]>>;
  pageViewerSupplier: PageViewerType;
  // eslint-disable-next-line no-unused-vars
  handleEditSupplier: (e: any) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  handleOnCreateSupplierPortalAccount: (e: any) => Promise<void>;
  supplierDetailDataFormError: any;
  // eslint-disable-next-line no-unused-vars
  OnClickSupplierPortalAccountAccess: (rowData: any) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  OnClickSupplierPortalAccountResendActication: (rowData: any) => Promise<void>;
  queryStringSupplier: QueryStringType;
  setQueryStringSupplier: React.Dispatch<React.SetStateAction<QueryStringType>>;
}
type SupplierProviderProps = {
  children: React.ReactNode;
};

const SupplierContext = createContext<ISupplier>({} as ISupplier);

export const useSupplierContext = () => useContext(SupplierContext);

export const SupplierContextProvider = ({
  children,
}: SupplierProviderProps) => {
  const navigate = useNavigate();
  const query = useQuery();
  const initialStateOfQueryParams: QueryStringType = {
    form: query.get('form'),
    id: Number(query.get('id')),
  };
  const [queryStringSupplier, setQueryStringSupplier] = useState(
    initialStateOfQueryParams,
  );
  const pageViewerSupplier: PageViewerType = {
    isNew: () => queryStringSupplier.form === FormQueryStringStatus.New,
    isView: () =>
      queryStringSupplier.form === FormQueryStringStatus.View &&
      queryStringSupplier.id > 0,
    isEdit: () =>
      queryStringSupplier.form === FormQueryStringStatus.Edit &&
      queryStringSupplier.id > 0,
    isViewOrEdit: () =>
      (queryStringSupplier.form === FormQueryStringStatus.View ||
        queryStringSupplier.form === FormQueryStringStatus.Edit) &&
      queryStringSupplier.id > 0,
  };
  const [optionCountries, setOptionCountries] = useState<
    CustomerType[] | null | []
  >([]);
  const [supplierPortalAccount, setSupplierPortalAccount] = useState([]);

  const initialFormSupplier: ISupplierDetail = {
    country: {
      value: 236,
      label: 'United States',
    },
    emailAddressPortal: '',
  };

  const initialFormErrorSupplier = {
    companyName: '',
    emailAddressPortal: '',
  };

  const [supplierDetailData, setSupplierDetailData] =
    useState(initialFormSupplier);

  const [supplierDetailDataFormError, setsupplierDetailDataFormError] =
    useState(initialFormErrorSupplier);

  const onLoadCountries = async () => {
    try {
      const countriesFromApi = await getCountries('');
      return countriesFromApi;
    } catch (err) {
      return err;
    }
  };
  const onLoadSupplierDetail = async (id: number) => {
    try {
      const supplierDetail = await getSupplierByID(id);

      return supplierDetail;
    } catch (err) {
      return err;
    }
  };
  const onLoadSupplierPortalAccount = async (id: number) => {
    try {
      const result = await getSupplierPortalAccount(id);

      return result;
    } catch (err) {
      return err;
    }
  };

  const viewLoadData = async () => {
    if (pageViewerSupplier.isViewOrEdit()) {
      onLoadSupplierDetail(queryStringSupplier.id)
        .then((data: any) => {
          setSupplierDetailData((e) => ({
            ...e,
            supplierId: data.supplierId,
            companyName: data.companyName,
            address1: data.address1,
            address2: data.address2,
            country: {
              value: data.country,
              label: data.countryStr,
            },
            cityTownship: data.cityTownship,
            stateProvinceRegion: data.stateProvinceRegion,
            zipPostalCode: data.zipPostalCode,
            recipientName: data.recipientName,
            phone: data.phone,
            email: data.email,
            departmentNo: data.departmentNo,
            emailAddressPortal: '',
            items: data.items,
          }));
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));

      onLoadSupplierPortalAccount(queryStringSupplier.id)
        .then((data: any) => {
          setSupplierPortalAccount(data);
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    } else {
      navigate('/inventory/suppliers');
    }
  };

  const handleEditSupplier = async (e: any) => {
    e.preventDefault();

    if (pageViewerSupplier.isView()) {
      setQueryStringSupplier((f) => ({
        ...f,
        form: 'edit',
      }));
      window.history.replaceState(
        null,
        '',
        `?form=edit&id=${queryStringSupplier.id}`,
      );
    }

    if (pageViewerSupplier.isEdit()) {
      try {
        const fieldValues = supplierDetailData;
        const temp: any = {};
        if (
          fieldValues.email !== '' &&
          fieldValues.email !== null &&
          fieldValues.email !== undefined
        ) {
          temp.email =
            fieldValues.email && /$^|.+@.+..+/.test(fieldValues.email)
              ? ''
              : 'Email Address is not valid';
        }

        temp.companyName = fieldValues.companyName
          ? ''
          : 'This field is required';

        setsupplierDetailDataFormError({
          ...temp,
        });

        if (!Object.values(temp).every((x) => x === '')) {
          return;
        }

        const payload = {
          SupplierId: supplierDetailData.supplierId,
          CompanyName: supplierDetailData?.companyName,
          RecipientName: supplierDetailData.recipientName,
          Phone: supplierDetailData.phone,
          Email: supplierDetailData.email,
          Address1: supplierDetailData.address1,
          Address2: supplierDetailData.address2,
          CityTownship: supplierDetailData.cityTownship,
          StateProvinceRegion: supplierDetailData.stateProvinceRegion,
          ZipPostalCode: supplierDetailData.zipPostalCode,
          Country: supplierDetailData.country.value,
          DepartmentNumber: supplierDetailData.departmentNo,
        };

        await EditCustomerSupplier(payload.SupplierId, payload);

        setQueryStringSupplier((f) => ({
          ...f,
          form: 'view',
          id: payload.SupplierId,
        }));
        window.history.replaceState(
          null,
          '',
          `?form=view&id=${payload.SupplierId}`,
        );
        viewLoadData();

        snackActions.success(
          `Successfully update ${payload.CompanyName} supplier.`,
        );
      } catch (err) {
        snackActions.error(`${err}`);
      }
    }
  };

  const handleOnCreateSupplierPortalAccount = async (e: any) => {
    e.preventDefault();

    const payload = {
      supplierId: supplierDetailData.supplierId,
      email: supplierDetailData.emailAddressPortal,
      url: '',
      supplierAccountId: null,
    };
    const fieldValues = payload;
    const temp: any = {};
    if (
      fieldValues.email !== '' ||
      fieldValues.email !== null ||
      fieldValues.email !== undefined
    ) {
      temp.emailAddressPortal = /$^|.+@.+..+/.test(fieldValues.email)
        ? ''
        : 'Email Address is not valid';
    } else {
      temp.emailAddressPortal = fieldValues.email
        ? ''
        : 'Email Address is required';
    }
    setsupplierDetailDataFormError({
      ...temp,
    });

    const bool = Object.values(temp).every((x) => x === '');

    if (!bool) {
      return;
    }

    try {
      const result = await createSupplierPortalAccount(payload);
      payload.url = `${window.location.protocol}//${window.location.hostname}/SupplierActivation`;
      payload.supplierAccountId = result.supplierAccountId;
      await generateActivationUrl(payload);
      snackActions.success(
        `Supplier Account "${payload.email}" has been successfully added.`,
      );
      viewLoadData();
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };

  // Supplier Portal Account Activation and Data Grid ---------------------------------
  const OnClickSupplierPortalAccountAccess = async (rowData) => {
    try {
      await updateSupplierPortalAccountIsActive(rowData);
      snackActions.success(
        `Supplier Portal Account "${rowData.email}" has been successfully changed status`,
      );
      viewLoadData();
    } catch (err) {
      snackActions.error(err);
    }
  };

  const OnClickSupplierPortalAccountResendActication = async (rowData) => {
    try {
      const payload = {
        url: `${window.location.protocol}//${window.location.hostname}/SupplierActivation`,
        email: rowData.email,
        supplierAccountId: rowData.supplierAccountId,
      };
      await generateActivationUrl(payload);
      snackActions.success(
        `Activation message successfully sent to "${rowData.email}"`,
      );
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    const optCountries = onLoadCountries();
    optCountries
      .then((ddl) => {
        setOptionCountries(
          ddl.map((c: any) => ({
            label: c.name,
            value: c.countryId,
          })),
        );
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
    viewLoadData();
  }, []);

  const value = useMemo(
    () => ({
      optionCountries,
      pageViewerSupplier,
      supplierDetailData,
      queryStringSupplier,
      supplierPortalAccount,
      supplierDetailDataFormError,
      setOptionCountries,
      handleEditSupplier,
      setSupplierDetailData,
      setQueryStringSupplier,
      setSupplierPortalAccount,
      OnClickSupplierPortalAccountAccess,
      handleOnCreateSupplierPortalAccount,
      OnClickSupplierPortalAccountResendActication,
    }),
    [
      optionCountries,
      pageViewerSupplier,
      supplierDetailData,
      queryStringSupplier,
      supplierPortalAccount,
      supplierDetailDataFormError,
    ],
  );

  return (
    <SupplierContext.Provider value={value}>
      {children}
    </SupplierContext.Provider>
  );
};

const CardSupplierDetail = () => {
  const {
    pageViewerSupplier,
    optionCountries,
    supplierDetailData,
    supplierDetailDataFormError,
    setSupplierDetailData,
  } = useSupplierContext();
  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="subtitle1" fontWeight="bold" mb={2}>
        Details
      </Typography>
      <MUIGrid container spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={3}>
          <Input
            placeholder="Company Name"
            size="small"
            value={supplierDetailData.companyName || ''}
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            onChange={(v) =>
              setSupplierDetailData((e) => ({
                ...e,
                companyName: v.target.value,
              }))
            }
            required
            disabled={pageViewerSupplier.isView()}
            {...(supplierDetailDataFormError.companyName && {
              error: true,
              helperText: supplierDetailDataFormError.companyName,
            })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <Input
            placeholder="Address"
            size="small"
            value={supplierDetailData.address1 || ''}
            onChange={(v) =>
              setSupplierDetailData((e) => ({
                ...e,
                address1: v.target.value,
              }))
            }
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            disabled={pageViewerSupplier.isView()}
            {...(supplierDetailDataFormError?.address1 && {
              error: true,
              helperText: supplierDetailDataFormError?.address1,
            })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <Input
            placeholder="Address 2"
            size="small"
            value={supplierDetailData.address2 || ''}
            onChange={(v) =>
              setSupplierDetailData((e) => ({
                ...e,
                address2: v.target.value,
              }))
            }
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            disabled={pageViewerSupplier.isView()}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <Autocomplete
            disableClearable
            onChange={(event: any, newValue: CustomerType) => {
              if (newValue !== null) {
                setSupplierDetailData((e) => ({
                  ...e,
                  country: newValue,
                }));
              } else {
                setSupplierDetailData((e) => ({
                  ...e,
                  country: null,
                }));
              }
            }}
            id="controllable-states"
            options={optionCountries}
            getOptionLabel={(option: CustomerType) => option.label}
            value={supplierDetailData.country}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                id="outlined-required"
                label="Country"
                size="small"
                sx={{ width: '100%' }}
                disabled={pageViewerSupplier.isView()}
                {...(supplierDetailDataFormError?.country && {
                  error: true,
                  helperText: supplierDetailDataFormError?.country,
                })}
              />
            )}
            // disabled={pageViewerPO.isView()}
          />
        </MUIGrid>
      </MUIGrid>

      <MUIGrid container spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={3}>
          <Input
            placeholder="City/Township"
            size="small"
            value={supplierDetailData.cityTownship || ''}
            onChange={(v) =>
              setSupplierDetailData((e) => ({
                ...e,
                cityTownship: v.target.value,
              }))
            }
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            disabled={pageViewerSupplier.isView()}
            {...(supplierDetailDataFormError?.cityTownship && {
              error: true,
              helperText: supplierDetailDataFormError?.cityTownship,
            })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <Input
            placeholder="State/Province/Region"
            size="small"
            value={supplierDetailData.stateProvinceRegion || ''}
            onChange={(v) =>
              setSupplierDetailData((e) => ({
                ...e,
                stateProvinceRegion: v.target.value,
              }))
            }
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            disabled={pageViewerSupplier.isView()}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <Input
            placeholder="Zip/Postal Code"
            size="small"
            value={supplierDetailData.zipPostalCode || ''}
            onChange={(v) =>
              setSupplierDetailData((e) => ({
                ...e,
                zipPostalCode: v.target.value,
              }))
            }
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            disabled={pageViewerSupplier.isView()}
            {...(supplierDetailDataFormError?.zipPostalCode && {
              error: true,
              helperText: supplierDetailDataFormError?.zipPostalCode,
            })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <Input
            placeholder="Phone"
            size="small"
            value={supplierDetailData.phone || ''}
            onChange={(v) =>
              setSupplierDetailData((e) => ({
                ...e,
                phone: v.target.value,
              }))
            }
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            disabled={pageViewerSupplier.isView()}
            {...(supplierDetailDataFormError?.phone && {
              error: true,
              helperText: supplierDetailDataFormError?.phone,
            })}
          />
        </MUIGrid>
      </MUIGrid>
      <MUIGrid container spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={3}>
          <Input
            placeholder="Recipient Name"
            size="small"
            value={supplierDetailData.recipientName || ''}
            onChange={(v) =>
              setSupplierDetailData((e) => ({
                ...e,
                recipientName: v.target.value,
              }))
            }
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            disabled={pageViewerSupplier.isView()}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <Input
            placeholder="Email"
            size="small"
            value={supplierDetailData.email || ''}
            onChange={(v) =>
              setSupplierDetailData((e) => ({
                ...e,
                email: v.target.value,
              }))
            }
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            disabled={pageViewerSupplier.isView()}
            {...(supplierDetailDataFormError?.email && {
              error: true,
              helperText: supplierDetailDataFormError?.email,
            })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <Input
            placeholder="Department No."
            size="small"
            value={supplierDetailData.departmentNo || ''}
            onChange={(v) =>
              setSupplierDetailData((e) => ({
                ...e,
                departmentNo: v.target.value,
              }))
            }
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            disabled={pageViewerSupplier.isView()}
          />
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

const CardSupplierAccount = () => {
  const {
    supplierDetailData,
    supplierPortalAccount,
    supplierDetailDataFormError,
    setSupplierDetailData,
    handleOnCreateSupplierPortalAccount,
    OnClickSupplierPortalAccountAccess,
    OnClickSupplierPortalAccountResendActication,
  } = useSupplierContext();

  const supplierPortalAccountColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 30,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label={params.row.isActive ? 'Revoke Access' : 'Reactivate'}
          onClick={() => OnClickSupplierPortalAccountAccess(params.row)}
          showInMenu
          disabled={!params.row.activeInactiveButtonEnable}
        />,
        <GridActionsCellItem
          label="Resend Activation"
          onClick={() =>
            OnClickSupplierPortalAccountResendActication(params.row)
          }
          showInMenu
          disabled={!params.row.resendActivationButtonEnable}
        />,
      ],
    },
    {
      field: 'email',
      width: 250,
      renderHeader: () => (
        <Box sx={{ gap: '5px', marginBottom: '0px !important' }}>
          <Typography fontWeight="bold">Email</Typography>
        </Box>
      ),
    },
    {
      field: 'isActiveStr',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Active / Inactive</Typography>
        </Box>
      ),
    },
  ];
  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="subtitle1" fontWeight="bold" mb={2}>
        Supplier Portal Accounts
      </Typography>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={5}>
          <Input
            placeholder="Email Address"
            size="small"
            value={supplierDetailData.emailAddressPortal || ''}
            onChange={(v) =>
              setSupplierDetailData((e) => ({
                ...e,
                emailAddressPortal: v.target.value,
              }))
            }
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            {...(supplierDetailDataFormError.emailAddressPortal && {
              error: true,
              helperText: supplierDetailDataFormError.emailAddressPortal,
            })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <Button
            variant="contained"
            size="medium"
            onClick={(e) => handleOnCreateSupplierPortalAccount(e)}
          >
            Activate New Account
          </Button>
        </MUIGrid>
      </MUIGrid>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={12}>
          <Grid
            autoHeight
            rows={supplierPortalAccount}
            density="compact"
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            columns={supplierPortalAccountColumns}
            rowsPerPageOptions={[5]}
            pageSize={10}
            hideFooterSelectedRowCount
            getRowId={(row) => row.supplierAccountId}
            sx={{
              borderColor: 'transparent',
              '& .MuiDataGrid-columnSeparator--sideRight': {
                display: 'none !important',
              },
            }}
          />
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

const CardSupplierItems = () => {
  const { supplierDetailData } = useSupplierContext();
  const cols: GridColumns = [
    {
      field: 'sku',
      width: 150,
      headerName: 'SKU',
    },
    {
      field: 'description',
      width: 350,
      headerName: 'Description',
    },
    {
      field: 'partNumber',
      width: 130,
      headerName: 'Part Number',
    },
    {
      field: 'sellPrice',
      width: 100,
      headerName: 'Sell Price',
      renderCell: (param) => (
        <span>$ {ToDecimal(Number(param.row.sellPrice), 2)}</span>
      ),
    },
    {
      field: 'cost',
      width: 100,
      headerName: 'Cost',
      renderCell: (param) => (
        <span>$ {ToDecimal(Number(param.row.cost), 2)}</span>
      ),
    },
    {
      field: 'costShipping',
      width: 150,
      headerName: 'Shipping Cost',
      renderCell: (param) => (
        <span>$ {ToDecimal(Number(param.row.costShipping), 2)}</span>
      ),
    },
  ];
  return (
    <Card>
      <Box sx={{ display: 'flex', flex: 'auto', flexDirection: 'column' }}>
        <Typography variant="subtitle1" fontWeight="bold" mb={1}>
          Items
        </Typography>
        <DatagridStandard
          autoHeight
          rows={supplierDetailData.items ?? []}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          columns={cols}
          rowsPerPageOptions={[30]}
          pageSize={30}
          hideFooterSelectedRowCount
          getRowId={(row) => row.itemId}
        />
      </Box>
    </Card>
  );
};

function SupplierDetailPage() {
  return (
    <MainContainer>
      <SupplierContextProvider>
        <Header />
        <ContentContainer>
          <Box
            sx={{
              width: '100%',
              typography: 'body1',
              gap: '10px',
            }}
          >
            <CardSupplierDetail />
            <CardSupplierAccount />
            <CardSupplierItems />
          </Box>
        </ContentContainer>
      </SupplierContextProvider>
    </MainContainer>
  );
}

export default React.memo(SupplierDetailPage);
