import React, { useContext, useEffect, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { deletePackageInProcess } from 'services/api/packandship/packandship.api';
import { CompleteOrderManually } from 'services/api/salesorders/salesorders-new.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Box,
  Modal,
  Typography,
  Grid as MUIGrid,
  Button,
  CircularProgress,
  Link,
} from '@mui/material';

import { useSalesOrderContext } from '../context';

export default React.memo(() => {
  const {
    completeOrderManuallyPayload,
    isConfirmPackingRemoveModalOpen,
    setIsConfirmPackingRemoveModalOpen,
  } = useContext(GlobalContext);

  const { orderPickTicketItemRows, viewLoadData } = useSalesOrderContext();

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const handleSave = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    if (orderPickTicketItemRows.length === 0 || !orderPickTicketItemRows) {
      snackActions.error('Pick Ticket not found');
      return;
    }
    deletePackageInProcess(orderPickTicketItemRows[0].pickTicketId).then(() => {
      setIsBusy(true);

      CompleteOrderManually(completeOrderManuallyPayload)
        .then(() => {
          viewLoadData();
          setIsConfirmPackingRemoveModalOpen(false);
        })
        .catch((err) => {
          snackActions.error(err);
        })
        .finally(() => {
          setIsBusy(false);
        });
    });
  };
  return (
    <Modal open={isConfirmPackingRemoveModalOpen}>
      <MUIContainer sx={{ maxWidth: '650px !important' }}>
        <Typography
          className="modalTextHeaderError"
          variant="h6"
          fontWeight="bold"
        >
          Warning: This order is already in the packing state.
        </Typography>

        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Box autoComplete="off" component="form">
            <Typography variant="h6" sx={{ fontSize: '0.875rem' }}>
              Do you want to continue completing the order? <br /> <br />
              <i>This will delete the packages in the pack and ship.</i>{' '}
              {orderPickTicketItemRows.length > 0 && (
                <Link
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    textDecoration: 'none',
                  }}
                  href={`/pack-and-ship/pick-ticket?form=view&id=${orderPickTicketItemRows[0]?.pickTicketId}&pickId=${orderPickTicketItemRows[0]?.pickId}`}
                  target="_blank"
                >
                  Go to Pack and Ship
                </Link>
              )}
            </Typography>
          </Box>
        </MUIContent>
        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={isBusy ? 8 : 6}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                disabled={isBusy}
                onClick={() => {
                  setIsConfirmPackingRemoveModalOpen(false);
                }}
              >
                CANCEL
              </Button>

              {!isBusy && (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => handleSave(e)}
                >
                  Yes, Continue
                </Button>
              )}
              {isBusy && (
                <Button
                  disabled
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                    color: 'white !important',
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: 'white !important',
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                  <Box sx={{ paddingLeft: '5px' }}>Saving</Box>
                </Button>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
