import React from 'react';

import moment from 'moment';

import { Chip, Tooltip } from '@mui/material';

type Flag = {
  isHold: boolean;
  isBackOrder: boolean;
  isPartial: boolean;
  backOrderDateTime: string;
  isAddressInvalid: boolean;
};

const OrderStatusSmallChip = ({
  isHold,
  isBackOrder,
  isPartial,
  backOrderDateTime,
  isAddressInvalid,
}: Flag) => (
  <>
    {isBackOrder && (
      <Tooltip
        title={`Backorder ${moment(backOrderDateTime).format('MMM DD YYYY')}`}
        placement="right"
      >
        <Chip
          label="B"
          size="small"
          sx={{
            marginRight: 1,
            fontWeight: 'bold',
            width: 40,
            color: 'white',
          }}
          style={{ backgroundColor: '#CC3333' }}
        />
      </Tooltip>
    )}
    {isHold && (
      <Tooltip title="On Hold" placement="right">
        <Chip
          label="H"
          size="small"
          sx={{
            marginRight: 1,
            fontWeight: 'bold',
            width: 40,
            color: 'white',
          }}
          style={{ backgroundColor: '#707070' }}
        />
      </Tooltip>
    )}
    {isPartial && (
      <Tooltip title="Partial" placement="right">
        <Chip
          label="P"
          size="small"
          sx={{
            marginRight: 1,
            fontWeight: 'bold',
            width: 40,
            color: 'white',
          }}
          style={{ backgroundColor: '#3375E0' }}
        />
      </Tooltip>
    )}
    {isAddressInvalid && (
      <Tooltip
        title="Flagged possible invalid address by postal office"
        placement="right"
      >
        <Chip
          label="A"
          size="small"
          sx={{
            marginRight: 1,
            fontWeight: 'bold',
            width: 40,
          }}
          color="warning"
        />
      </Tooltip>
    )}
  </>
);

export default React.memo(OrderStatusSmallChip);
