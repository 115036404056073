import React, {
  useEffect,
  useState,
  useContext,
  ChangeEvent,
  createContext,
  useMemo,
} from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';

import Card from 'components/card';
import Footer from 'components/footer';
import Header from 'components/header';
import Input from 'components/input';
import { Grid } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import CancelTransferModal from 'pages/shared/canceltransfermodal';
import { MainContainer, ContentContainer } from 'pages/styles';
import {
  getTransferHistory,
  holdTransferHistory,
  removeTransferHistory,
  updateTransferStatusToCancel,
  UpdateTransferStatusToOpen,
  UpdateTransferToComplete,
} from 'services/api/transfers/transfers.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridStandard } from 'styles';

import { Typography, Box, Link, Switch } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridColDef,
  GridToolbar,
  GridColumns,
  GridActionsCellItem,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';

type TransferContextProviderProps = {
  children: React.ReactNode;
};

const TransferContext = createContext(
  {} as {
    handleOnClickCancelTransfer: any;
    _transferId: any;
    setTransferId: any;
    fromLocation: any;
    setFromLocation: any;
    toLocation: any;
    setToLocation: any;
    dataSelected: any;
    setDataSelected: any;
  },
);

export const useTransferContext = () => useContext(TransferContext);

const TransferContextProvider = ({
  children,
}: TransferContextProviderProps) => {
  const navigate = useNavigate();
  const { onCloseCancelTransferModal } = useContext(GlobalContext);
  const [dataSelected, setDataSelected] = useState({
    customerId: '',
    sku: '',
    description: '',
    uom: '',
  });
  const [_transferId, setTransferId] = useState(0);
  const [fromLocation, setFromLocation] = useState('');
  const [toLocation, setToLocation] = useState('');

  const handleOnClickCancelTransfer = async (e: any) => {
    e.preventDefault();

    try {
      const payload = { transferId: _transferId };
      const response = await updateTransferStatusToCancel(payload);
      snackActions.success('Successfully created new item.');
      onCloseCancelTransferModal();
    } catch (err) {
      snackActions.error(err);
    }
  };

  const value = useMemo(
    () => ({
      handleOnClickCancelTransfer,
      _transferId,
      setTransferId,
      fromLocation,
      setFromLocation,
      toLocation,
      setToLocation,
      dataSelected,
      setDataSelected,
    }),
    [_transferId, fromLocation, toLocation],
  );

  return (
    <TransferContext.Provider value={value}>
      {children}
    </TransferContext.Provider>
  );
};

const TransferList = () => {
  const {
    setTransferId,
    setFromLocation,
    setToLocation,
    dataSelected,
    setDataSelected,
  } = useTransferContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    loading,
    updateLoading,
    updateData,
    onOpenCancelTransferModal,
    isCancelTransferModalOpen,
  } = useContext(GlobalContext);
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const [transferHistory, setTransferHistory] = useState([]);
  const [transfersPending, setTransfersPending] = useState(0);
  const [transfersCompleted, setTransfersCompleted] = useState(0);
  const [unitsToday, setUnitsToday] = useState(0);
  const [filteredTransfers, setFilteredTransfers] = useState([]);

  const [checked, setChecked] = React.useState(false);

  const [searchParams, setSearchParams] = useState({
    searchStatus: '',
    searchTransperType: '',
    searchCreated: '',
    searchCreatedBy: '',
    searchSKU: '',
    searchDesc: '',
    searchLot: '',
    searchUOM: '',
    searchFromZone: '',
    searchFromBin: '',
    searchToZone: '',
    searchToBin: '',
    searchQtyTransfer: '',
    searchLastUpdate: '',
  });

  const filterTransferHistory = () => {
    const statusSearchResult = transferHistory.filter((t) =>
      t.status.toLowerCase().includes(searchParams.searchStatus.toLowerCase()),
    );
    const searchSKUResults = statusSearchResult.filter((t) =>
      t.itemSKU.toLowerCase().includes(searchParams.searchSKU.toLowerCase()),
    );
    const searchUOMResult = searchSKUResults.filter((t) =>
      t.itemUOM.toLowerCase().includes(searchParams.searchUOM.toLowerCase()),
    );
    const searchFromZoneResult = searchUOMResult.filter((t) =>
      t.fromZone
        .toLowerCase()
        .includes(searchParams.searchFromZone.toLowerCase()),
    );
    const searchFromBinResult = searchFromZoneResult.filter((t) =>
      t.fromBin
        .toLowerCase()
        .includes(searchParams.searchFromBin.toLowerCase()),
    );
    const searchToZoneResult = searchFromBinResult.filter((t) =>
      t.toZone.toLowerCase().includes(searchParams.searchToZone.toLowerCase()),
    );
    const searchToBinResult = searchToZoneResult.filter((t) =>
      t.toBin.toLowerCase().includes(searchParams.searchToBin.toLowerCase()),
    );
    const result = searchToBinResult.filter((t) =>
      t.qtyTransfer
        .toString()
        .toLowerCase()
        .includes(searchParams.searchQtyTransfer.toString().toLowerCase()),
    );

    setFilteredTransfers(result);
  };

  const filterShowCompletedTransfer = () => {
    if (checked) {
      setFilteredTransfers(transferHistory);
    } else {
      const result = transferHistory.filter((t) => t.status !== 'Complete');
      setFilteredTransfers(result);
    }
  };

  const onForm = (key, text) => {
    setSearchParams(() => ({
      ...searchParams,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    onForm(key, event.target.value);
  };

  const onLoadCustomerTransferQueue = async () => {
    try {
      const transfersFromApi = await getTransferHistory(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
      );
      setUnitsToday(transfersFromApi.unitToday);
      setTransfersPending(transfersFromApi.pending);
      setTransfersCompleted(transfersFromApi.completed);

      if (checked) {
        setTransferHistory(transfersFromApi.transfers);
        setFilteredTransfers(transfersFromApi.transfers);
      } else {
        const result = transfersFromApi.transfers.filter(
          (t) => t.status !== 'Complete',
        );
        setTransferHistory(result);
        setFilteredTransfers(result);
      }

      return true;
    } catch (error) {
      return error;
    }
  };

  const handleOnClickRemoveTransferHistory = async (e: any, id: any) => {
    e.preventDefault();

    try {
      const payload = { transferId: id };
      const response = await removeTransferHistory(payload);
      onLoadCustomerTransferQueue();
      snackActions.success('Successfully removed history item.');
    } catch (err) {
      snackActions.error(err);
    }
  };

  const handleOnClickHoldTransferHistory = async (e: any, row: any) => {
    e.preventDefault();
    try {
      const payload = { transferId: row.transferId };
      if (row.status === 'Open') {
        await holdTransferHistory(payload);
      } else {
        await UpdateTransferStatusToOpen(payload);
      }
      onLoadCustomerTransferQueue();
      snackActions.success('Successfully hold history item.');
    } catch (err) {
      snackActions.error(err);
    }
  };

  const handleOnClickTrasferToComplete = async (e: any, row: any) => {
    e.preventDefault();
    try {
      const payload = {
        CustomerLocationId: currentLocationAndFacility.customerLocationId,
        CustomerFacilityId: currentLocationAndFacility.customerFacilityId,
        TransferId: row,
      };

      await UpdateTransferToComplete(payload);

      snackActions.success('Transfer successfully completed.');

      onLoadCustomerTransferQueue();
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    setTransferHistory(null);
    setFilteredTransfers([]);
    onLoadCustomerTransferQueue();
    updateLoading(true);
  }, [loading, updateData, isCancelTransferModalOpen, checked]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  type TransferHistoryRows = typeof transferHistory[number];

  const transferColumns: GridColumns<TransferHistoryRows> = [
    {
      hide: true,
      field: 'transferId',
      hideable: false,
      filterable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      hideable: false,
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label={params.row.status === 'Hold' ? 'Open' : 'Hold'}
          onClick={(e) => handleOnClickHoldTransferHistory(e, params.row)}
          showInMenu
          {...(params.row.status === 'Complete' && {
            disabled: true,
          })}
          {...(params.row.status === 'Manual' && {
            disabled: true,
          })}
        />,
        <GridActionsCellItem
          label="Remove"
          onClick={(e) =>
            handleOnClickRemoveTransferHistory(e, params.row.transferId)
          }
          showInMenu
        />,
        <GridActionsCellItem
          label="Complete"
          disabled={
            params.row.status === 'Complete' ||
            params.row.type === 'Replenishment'
          }
          onClick={(e) =>
            handleOnClickTrasferToComplete(e, params.row.transferId)
          }
          showInMenu
        />,
      ],
    },
    {
      field: 'type',
      width: 200,
      headerName: 'Transfer Type',
    },
    {
      field: 'status',
      width: 200,
      headerName: 'Status',
    },
    {
      field: 'itemSKU',
      width: 200,
      headerName: 'SKU',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`/inventory/item-details?form=view&id=${params.row.itemId}`}
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.itemSKU}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'fromBin',
      width: 200,
      headerName: 'From Location',
      renderCell: (params) => (
        <Typography sx={{ color: [theme.palette.primary.main] }}>
          {params.row.fromBin}
        </Typography>
      ),
    },
    {
      field: 'toBin',
      width: 200,
      headerName: 'To Location',
      renderCell: (params) => (
        <Typography sx={{ color: [theme.palette.primary.main] }}>
          {params.row.toBin}
        </Typography>
      ),
    },
    {
      field: 'qtyTransfer',
      width: 200,
      headerName: 'Quantity to Transfer',
      renderCell: (params) => (
        <Typography sx={{ color: [theme.palette.primary.main] }}>
          {params.row.qtyTransfer?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: 'itemLot',
      width: 200,
      headerName: 'Lot',
    },
    {
      field: 'itemUOM',
      width: 200,
      headerName: 'UOM',
    },
    {
      field: 'lastUpdate',
      width: 200,
      headerName: 'Last Update',
      renderCell: (params) => (
        <Typography>
          {params.row.lastUpdate !== undefined
            ? moment
                .utc(params.row.lastUpdate)
                .local()
                .format('MMM DD hh:mm A')
                .toUpperCase()
            : null}
        </Typography>
      ),
    },
    {
      field: 'created',
      width: 200,
      headerName: 'Created',
      type: 'date',
      renderCell: (params) => (
        <Typography>
          {params.row.created
            ? moment(params.row.created).format('MM/DD/YYYY')
            : ''}
        </Typography>
      ),
    },
    {
      field: 'createdBy',
      width: 200,
      headerName: 'Created By',
    },
  ];

  function toolbar() {
    return (
      <Box
        sx={{
          paddingBottom: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: '8px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '8px',
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Transfer Queue
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '8px',
            }}
          >
            <GridToolbarExport />
            <GridToolbarQuickFilter variant="outlined" size="small" />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          marginBottom: '16px',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '192px',
          }}
        >
          <Typography sx={{ color: [theme.palette.primary.main] }} variant="h3">
            {transfersPending.toLocaleString()}
          </Typography>
          <Typography variant="caption" fontWeight="bold">
            Pending
          </Typography>
        </Card>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '192px',
          }}
        >
          <Typography sx={{ color: [theme.palette.primary.main] }} variant="h3">
            {transfersCompleted.toLocaleString()}
          </Typography>
          <Typography variant="caption" fontWeight="bold">
            Completed
          </Typography>
        </Card>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '192px',
          }}
        >
          <Typography sx={{ color: [theme.palette.primary.main] }} variant="h3">
            {unitsToday.toLocaleString()}
          </Typography>
          <Typography variant="caption" fontWeight="bold">
            Units Today
          </Typography>
        </Card>
      </Box>
      <Card sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <div style={{ textAlignLast: 'right' }}>
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          Show Completed
        </div>
        <DatagridStandard
          autoHeight
          headerHeight={30}
          rows={filteredTransfers}
          columns={transferColumns}
          pageSize={25}
          rowsPerPageOptions={[25]}
          getRowId={(row) => row.transferId}
          checkboxSelection={false}
          onSelectionModelChange={(itemId) => {
            const selectedId = itemId[0];
            const selectedRowData = transferHistory.filter(
              (c) => c.itemId === selectedId,
            );
            if (selectedId === undefined) {
              setDataSelected({
                customerId: '',
                sku: '',
                description: '',
                uom: '',
              });
            } else {
              setDataSelected(selectedRowData[0]);
            }
          }}
          components={{ Toolbar: GridToolbar }}
          density="compact"
        />
      </Card>
    </>
  );
};

function TransferView() {
  return (
    <MainContainer>
      <TransferContextProvider>
        <Header />
        <CancelTransferModal />
        <ContentContainer>
          <TransferList />
        </ContentContainer>
      </TransferContextProvider>
    </MainContainer>
  );
}

export default React.memo(TransferView);
