import React, { useContext, useEffect, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { useSalesOrderDataTableViewContext } from 'pages/sales/context-provider';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { orderMultipleUpdateStatus } from 'services/api/salesorders/salesorders-new.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Autocomplete,
  Box,
  Checkbox,
  Modal,
  TextField,
  Grid as MUIGrid,
  Typography,
  Button,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface IFlagStatusType {
  value: number;
  label: string;
}

export const optionStatusTypes = [
  { value: '1', label: 'New' },
  { value: '2', label: 'Open' },
  { value: '4', label: 'Shipment' },
  { value: '6', label: 'Partial' },
  { value: '11', label: 'Ready for Pickup' },
  { value: '5', label: 'Complete' },
  { value: '7', label: 'Cancelled' },
];

export default React.memo(() => {
  const theme = useTheme();

  const { isUpdateStatusOnOrderModalOpen, setIsUpdateStatusOnOrderModalOpen } =
    useContext(GlobalContext);

  const {
    selectedRowsOnCheckbox,
    initialStateSearchParams,
    searchParams,
    setSearchParams,
    OnClickUserFilterSearchApply,
  } = useSalesOrderDataTableViewContext();

  const initialFormFStatus = {
    value: 1,
    label: 'New',
  };

  const [salesOrderFlagStatus, setSalesOrderFlagStatus] =
    useState<IFlagStatusType>(initialFormFStatus);
  const [isReleaseHold, setIsReleaseHold] = useState(false);
  const [isAnyHoldOrder, setIsAnyHoldOrder] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const updateStatusMultipleOrderOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    const payload = {
      newStatus: salesOrderFlagStatus.value,
      isReleaseHold,
      OrderToUpdateStatuses: selectedRowsOnCheckbox.map((c: any) => ({
        OrderId: c.orderId,
        StatusId: c.statusValue,
        OrderNo: c.orderNo,
      })),
    };

    setIsBusy(true);

    try {
      orderMultipleUpdateStatus(payload)
        .then((result) => {
          if (result.data.length > 0) {
            result.data.forEach((element) => {
              snackActions.info(element.resultMessage);
            });
          }

          setIsUpdateStatusOnOrderModalOpen(false);
          OnClickUserFilterSearchApply(null, true);
          setSearchParams({
            ...initialStateSearchParams,
            status: searchParams.status,
          });

          setIsBusy(false);
        })
        .catch((err) => {
          setIsBusy(false);
          snackActions.error(err);
        });
    } catch (err) {
      setIsBusy(false);
      snackActions.error(err);
    }
  };

  useEffect(() => {
    if (isUpdateStatusOnOrderModalOpen) {
      if (
        selectedRowsOnCheckbox?.length > 0 &&
        selectedRowsOnCheckbox?.some((x) => x.isHold === true)
      ) {
        setIsAnyHoldOrder(true);
      } else {
        setIsAnyHoldOrder(false);
      }

      setIsBusy(false);
    }
  }, [isUpdateStatusOnOrderModalOpen]);

  return (
    <Modal open={isUpdateStatusOnOrderModalOpen}>
      <MUIContainer sx={{ width: '25% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Update Status
        </Typography>
        <MUIContent sx={{ padding: '10px 20px !important ' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Autocomplete
              disableClearable
              aria-required
              disabled={isBusy}
              onChange={(event: any, newValue: any) => {
                if (newValue !== null) {
                  setSalesOrderFlagStatus((e) => ({
                    ...e,
                    value: newValue.value,
                    label: newValue.label,
                  }));
                } else {
                  setSalesOrderFlagStatus(null);
                }
              }}
              id="controllable-states"
              options={optionStatusTypes}
              getOptionLabel={(option: any) => option.label}
              value={salesOrderFlagStatus}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined-required"
                  label="Status"
                  size="small"
                  sx={{ width: '100%' }}
                />
              )}
            />
            {isAnyHoldOrder && (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isBusy}
                    onChange={(e) => setIsReleaseHold(e.target.checked)}
                    checked={isReleaseHold}
                  />
                }
                label="Release the hold for the orders that are on hold"
              />
            )}
            {salesOrderFlagStatus?.label && (
              <Typography variant="subtitle1" className="cellDatagridWrapper">
                Are you sure you want to update the order status to &quot;
                {salesOrderFlagStatus?.label}&quot;?
              </Typography>
            )}
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={10}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="outlined"
                size="medium"
                sx={{ padding: 1, width: '100%' }}
                disabled={isBusy}
                onClick={() => setIsUpdateStatusOnOrderModalOpen(false)}
              >
                Cancel
              </Button>
              {isBusy === false && (
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => updateStatusMultipleOrderOnClick(e)}
                >
                  Update
                </Button>
              )}
              {isBusy && (
                <Button
                  disabled
                  type="submit"
                  variant="contained"
                  size="medium"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                    color: 'white !important',
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: 'white !important',
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                  <Box sx={{ paddingLeft: '5px' }}>Update</Box>
                </Button>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
