/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useEffect,
  useState,
  useContext,
  ChangeEvent,
  createContext,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router';

import {
  WarehouseCustomerChargeMethodRateEnum,
  WarehouseCustomerHandlingChargeMethodEnum,
  WarehouseCustomerHandlingChargeTypeEnum,
} from 'common/enums';
import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import { DropdownListItem } from 'common/models';
import { CustomerFacilityLocationDropDownListItem } from 'common/models/customerfacilities';
import { GetEmailFormatValidation } from 'common/utils';
import Card from 'components/card';
import CardTab from 'components/card/card-tab';
import Header from 'components/header';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import AddWarehouseCarrierModal from 'pages/ordercustomers/warehousecustomer/add-warehouse-carrier-modal';
import CarrierAccountDeleteModal from 'pages/ordercustomers/warehousecustomer/carrieraccountdeletemodal';
import CarrierAccountModal from 'pages/ordercustomers/warehousecustomer/carrieraccountmodal';
import CarrierIntegrationModal from 'pages/ordercustomers/warehousecustomer/carrierintegrationmodal';
import CopyBillingSetupModal from 'pages/ordercustomers/warehousecustomer/copy-billing-setup-modal';
import CopyDefaultFromCustomerModal from 'pages/ordercustomers/warehousecustomer/copy-default-from-customer-modal';
import CustomerPortalDeleteModal from 'pages/ordercustomers/warehousecustomer/customer-portal-delete-modal';
import IntegrationLogModal from 'pages/ordercustomers/warehousecustomer/integrationlogmodal';
import { IinvoiceDetails } from 'pages/ordercustomers/warehousecustomer/invoicedialog';
import PermissionsModal from 'pages/ordercustomers/warehousecustomer/permissionsmodal';
import QuickBooksIntegrationLogsModal from 'pages/ordercustomers/warehousecustomer/quickbooksintegrationlogsmodal';
import ShippingAccountEditModal, {
  IShippingCarrierEditModal,
} from 'pages/ordercustomers/warehousecustomer/shipping-account-edit-modal';
import ShopifyLocationModal from 'pages/ordercustomers/warehousecustomer/shopifylocationmodal';
import ShopifySyncTypeModal from 'pages/ordercustomers/warehousecustomer/shopifysynctypemodal';
import ChannelIcon from 'pages/sales/salesorder/channel-image';
import { StyledMenu } from 'pages/sales/salesorder/orderstatusdropdown';
import BillingChargeModal from 'pages/shared/billingchargesmodal';
import BillingReportModal from 'pages/shared/billingreportmodal';
import ConfirmationDialog from 'pages/shared/confirmdeletemodal';
import ConnectCarrierModal from 'pages/shared/connectCarrierModal';
import IntegrationModal from 'pages/shared/integrationmodal';
import ExtensivIntegrationModal from 'pages/shared/integrationmodal/extensiv';
import ExtensivEditOrderRuleIntegrationModal from 'pages/shared/integrationmodal/extensiv-order-rules-modal';
import OtherContactsModal from 'pages/shared/othercontactmodal';
import { CheckboxState, defaultItemStates } from 'pages/users/context';
import UserPermissionsModal, {
  PageUsedEnum,
} from 'pages/users/users-permission-modal';
import {
  getCartonCodeDDL,
  getCartonCodes,
  getPackagingList,
} from 'services/api/cartoncodes/cartoncodes.api';
import { getCountries } from 'services/api/country/country.api';
import {
  getCustomerFacilitiesByCustomerId,
  getCustomerBinsByFacilityId,
  GetCustomerFacilityLocationDDL,
} from 'services/api/customerfacilities/customerfacilities.api';
import {
  getCarrierAccountByCustomer,
  createEasyPostConfiguration,
  getEasyPostConfiguration,
  disconnectConfiguration,
} from 'services/api/integrations/easypost/easypost.api';
import {
  createEHubConfiguration,
  disconnectEhubConfigurationApi,
  getEHubConfiguration,
} from 'services/api/integrations/ehub/ehub.api';
import {
  getExtensivIntegrations,
  createExtensivCartRoverConfiguration,
  getExtensivCartRoverConfiguration,
  disconnectExtensivCartRoverConfiguration,
} from 'services/api/integrations/extensiv/extensive.api';
import {
  getShopifyIntegrationByCustomerId,
  disableShopifyIntegration,
  connectWithXero,
  checkXeroConnectionExists,
  connectWithQuickBooks,
  checkQuickBooksConnectionExists,
  getListOfInvoicesToBeSynced,
  pushInvoices,
  enableShopifyIntegration,
  inventorySyncUp,
  shopifyOrdersSyncUp,
  pushInventoryQty,
  enableAutoSync,
  disableAutoSync,
  triggerAlereJobs,
  pushIndividualInvoiceApi,
} from 'services/api/integrations/integrations.api';
import {
  getOrderCarrierservices,
  getAllCarriersByCustomerId,
  enableDisableShippingAccount,
  addAllWarehouseCarriers,
  setCarrierAccountBillable,
} from 'services/api/integrations/shipment/shipment.api';
import {
  createShipStationConfiguration,
  getShipStationConfiguration,
  syncOrderShipStationManually,
} from 'services/api/integrations/shipStation/shipstation.api';
import { getSkuLookupWithwarehouseCustomer } from 'services/api/salesorders/salesorders-new.api';
import {
  loadStatusOptions,
  createWarehouseCustomer,
  updateWarehouseCustomer,
  getWarehouseCustomerById,
  updateWarehouseCustomerShippingAccount,
  deleteWarehouseCustomerShippingAccount,
  createWarehouseCustomerShippingAccount,
  createCustomerPortalAccount,
  editCustomerPortalAccount,
  generateActivationUrl,
  updateCustomerPortalAccountAccess,
  loadShippingRatePreferenceOptions,
  getBillingData,
  updateCustomerPortalAccountEmailNotification,
  copyDefaultWarehouseCustomer,
  GetWarehouseCustomerViewModel,
} from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridStandard } from 'styles';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Typography,
  Grid as MUIGrid,
  Autocomplete,
  TextField,
  Button,
  Box,
  IconButton,
  Checkbox,
  Collapse,
  ListItemButton,
  ListItemText,
  Link,
  Chip,
  Switch,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid';

import { autoCompleteTimeStamp } from '../../../helpers/autocompletetimestamp';
import { MainContainer } from '../../styles';
import {
  CarrierTypeEnum,
  CustomerType,
  IOrderAIRuleDetail,
  NewRulesManagementInterface,
} from './customer-rules-management';
import InvoiceInterfaceTab from './tabs/invoices/index';
import { UOMChargeRateModal } from './uom-charge-rate-modal';

interface AutoCompleteOptionType {
  id: number;
  label: string;
}

interface BillingFrequency {
  id: number;
  label: string;
}

interface ISignatureType {
  value?: number;
  label?: string;
}
export const defaultOrderTypes: ISignatureType[] = [
  { value: 1, label: 'Consumer' },
  { value: 2, label: 'Commercial' },
];

export const signatureTypes: ISignatureType[] = [
  { value: 1, label: 'Indirect' },
  { value: 2, label: 'Direct' },
  { value: 3, label: 'Adult' },
];

export const documentTypes: ISignatureType[] = [
  {
    value: 1,
    label: 'Packing Slip',
  },
  { value: 2, label: 'Invoice' },
  { value: 3, label: 'Client Document Types' },
];

export const WarehouseCustomerContext = createContext(
  {} as {
    addUPSAccount: any;
    addFedexAccount: any;
    autoCompleteInputHandler: any;
    billingData: any;
    billingStorageChargesColumns: any;
    billingHandlingChargesColumns: any;
    billingAdhocChargesColumns: any;
    countries: any;
    customerPortalAccount: any;
    customerPortalAccountColumns: any;
    customerPortalEmailInput: any;
    dataGridAdhocCharges: any;
    dataGridHandlingCharges: any;
    dataGridStorageCharges: any;
    dataModalAdhocCharge: any;
    dataModalHandlingCharge: any;
    dataModalStorageCharge: any;
    storageChargeSlidingRate: any;
    handlingChargeSlidingRate: any;
    dataSelectedDelete: any;
    dataSelected: any;
    deleteOtherContact: any;
    handleEditViewOnlick: any;
    handleInputChangeUpsAccount: any;
    handleOnKeyUpShippingAccount: any;
    handleOnCreateWarehouseCustomer: any;
    handleOnCreateCustomerPortalAccount: any;
    handleTabOnChange: any;
    imageDataText: any;
    imageData: any;
    inputUPSAccount: any;
    inputFedExAccount: any;
    inputHandler: any;
    isAddNew: any;
    isViewForm: any;
    isEditForm: any;
    isAdhocChargeEdit: any;
    isHandlingChargeEdit: any;
    isStorageChargeEdit: any;
    isAdhocChargeDelete: any;
    isHandlingChargeDelete: any;
    isStorageChargeDelete: any;
    formErrors: any;
    form: any;
    loadCountries: any;
    notes: any;
    otherContacts: any;
    otherContactData: any;
    otherContactsColumns: any;
    queryString: any;
    services: any;
    setOtherContactData: any;
    setDataSelected: any;
    setDataSelectedDelete: any;
    setDeleteOtherContact: any;
    carrierIntegrationType: any;
    setCarrierIntegrationType: any;
    handleOnCarrierIntegrationType: any;
    shippoCarriers: any;
    loadBillingData: any;
    loadCarriers: any;
    setCustomerPortalEmailInput: any;
    setDataModalAdhocCharge: any;
    setDataModalHandlingCharge: any;
    uomChargeRates: any;
    setUOMChargeRates: any;
    selectedUOMChargeRate: any;
    setSelectedUOMChargeRate: any;
    isUOMChargeRateModal: boolean;
    setIsUOMChargeRateModal: React.Dispatch<React.SetStateAction<boolean>>;
    setDataModalStorageCharge: any;
    setStorageChargeSlidingRate: any;
    setHandlingChargeSlidingRate: any;
    setServices: any;
    setFormErrors: any;
    setForm: any;
    setImageDataText: any;
    setImageData: any;
    setNotes: any;
    statusOptions: any;
    setStatus: any;
    setStatusOptions: any;
    status: any;
    facility: CustomerFacilityLocationDropDownListItem;
    setFacility: React.Dispatch<
      React.SetStateAction<CustomerFacilityLocationDropDownListItem>
    >;
    facilityDDL: CustomerFacilityLocationDropDownListItem[];
    setFacilityDDL: React.Dispatch<
      React.SetStateAction<CustomerFacilityLocationDropDownListItem[]>
    >;
    setTabValue: any;
    setIsAdhocChargeEdit: any;
    setIsHandlingChargeEdit: any;
    setIsStorageChargeEdit: any;
    tabValue: any;
    viewLoadData: any;
    validateOnlick: any;
    shippingRateOptions: any;
    setShippingRatePreferences: any;
    billingFrequencyValue: any;
    billBasedOnValue: any;
    setBillBasedOnValue: any;
    setBillOrderAfterShipped: any;
    billOrderAfterShipped: any;
    setBillingFrequencyValue: any;
    setSelectedDayOfWeek: any;
    selectedDayOfWeek: any;
    billBasedOnOptions: any;
    billOrderAfterShippedOptions: any;
    optionBillingFrequency: any;
    setOptionBillingFrequency: any;
    loadExtensivIntegrations: () => Promise<void>;
    extensivIntegrations: any;
    setExtensivIntegrationProps: (props: any) => void;
    setExtensivIntegrationDelete: (props: any) => void;
    extensivIntegrationProps: any;
    extensivIntegrationDelete: any;
    setInvoiceDateDialog: React.Dispatch<React.SetStateAction<IinvoiceDetails>>;
    invoiceDateDialog: IinvoiceDetails;
    availableIntegrationOptions: any[];
    setAddAvailableIntegration: React.Dispatch<React.SetStateAction<boolean>>;
    addAvailableIntegration: boolean;
    xeroConnectionExists: boolean;
    quickBooksConnectionExists: boolean;
    setXeroConnectionExists: React.Dispatch<React.SetStateAction<boolean>>;
    setQuickBooksConnectionExists: React.Dispatch<
      React.SetStateAction<boolean>
    >;
    invoicesToBeSynced: any[];
    loadInvoicesToBeSynced: () => void;
    initialFormErrorsState: any;
    setIsEditForm: React.Dispatch<React.SetStateAction<boolean>>;
    setIsViewForm: React.Dispatch<React.SetStateAction<boolean>>;
    setEmailToActivate: React.Dispatch<React.SetStateAction<string>>;
    emailToActivate: string;
    setPermissionWarehouseCustomerValue: React.Dispatch<
      React.SetStateAction<any[]>
    >;
    permissionWarehouseCustomerValue: any[];
    setUserInfo: React.Dispatch<any>;
    userInfo: any;
    handleCloseCustomerPortalAccountModal: () => void;
    loadShipStationConfiguration: () => any;
    setDataModalCustomerPortalUser: React.Dispatch<any>;
    dataModalCustomerPortalUser: any;
    optionLocation: any[];
    carrierTypesOption: any[];
    setCarrierTypesOption: React.Dispatch<React.SetStateAction<any[]>>;
    handleCopyDefaultFromCustomer: () => Promise<void>;
    skuMappingOption: any[];
    setOrderAIRuleDetail: React.Dispatch<
      React.SetStateAction<IOrderAIRuleDetail>
    >;
    orderAIRuleDetail: IOrderAIRuleDetail;
    orderAIRuleDetailForm: IOrderAIRuleDetail;
    setIsShowRulesForm: React.Dispatch<React.SetStateAction<boolean>>;
    isShowRulesForm: boolean;
    handleAddOrderSourceOnlick: () => Promise<void>;
    handleEditOrderSourceOnlick: (params: any) => void;
    isRulesFormEditMode: boolean;
    setIsManageClientDefault: React.Dispatch<React.SetStateAction<boolean>>;
    isManageClientDefault: boolean;
    handleAddEditRulesManagementOnClick: () => Promise<void>;
    carrierTypeServiceOptionShipping: any[];
    carrierTypeServiceOptionCarrierType: any[];
    handleOnDefaultPackageType: (inputCarriers?: any[]) => Promise<any>;
    defaultPackgeTypeOption: any[];
    handleOnCarrierChange: (inputCarrierId?: any) => Promise<any>;
    handleOnCarrierChangeArrayList: (inputCarriers?: any[]) => Promise<any>;
    setShippingCarrierEditData: React.Dispatch<
      React.SetStateAction<IShippingCarrierEditModal>
    >;
    shippingCarrierEditData: IShippingCarrierEditModal;
    isSetShopifyLocationModalOpen: boolean;
    setIsSetShopifyLocationModalOpen: React.Dispatch<
      React.SetStateAction<boolean>
    >;
    selectedIntegrationId: number;
    setSelectedIntegrationId: React.Dispatch<React.SetStateAction<number>>;
    isViewLogsIntegrationModalOpen: boolean;
    setIsViewLogsIntegrationModalOpen: React.Dispatch<
      React.SetStateAction<boolean>
    >;
    isSyncInventoryModalOpen: boolean;
    setIsSyncInventoryModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    view: string;
    setView: React.Dispatch<React.SetStateAction<string>>;
  },
);

export const useWarehouseCustomerContext = () =>
  useContext(WarehouseCustomerContext);

export const WareHouseCustomerContextProvider = ({ children }: any) => {
  const query = useQuery();
  const navigate = useNavigate();
  const {
    onOpenOtherContactModal,
    onOpenConfirmDeleteDialog,
    isIntegrationModalOpen,
    onOpenShippoIntegrationModal,
    onOpenBillingStorageModal,
    onOpenBillingHandlingModal,
    onOpenBillingAdhocModal,
    onOpenCarrierAccountModal,
    setIsCustomerPortalPermissionModalOpen,
    setIsUserWarehouseCustomerDeleteModalOpen,
    setIsCopyDefaultCustomerModalOpen,
    setUserPermission,
    setUsersPermissionModal,
    setUserPermissionStates,
    setPageUsedPermission,
  } = useContext(GlobalContext);

  const [isLoadingData, setIsLoadingData] = useState(false);

  const defaultAutocompleteOption: AutoCompleteOptionType | null = {
    id: -1,
    label: '',
  };
  const initialStateOfQueryParams = {
    form: query.get('form'),
    id: query.get('id'),
  };
  const [invoiceDateDialog, setInvoiceDateDialog] = useState<IinvoiceDetails>();

  const [statusItems, setStatusOptions] = useState([]);
  const [facilityDDL, setFacilityDDL] = useState<
    CustomerFacilityLocationDropDownListItem[]
  >([]);
  const [shippingRatePreferences, setShippingRatePreferences] = useState([]);
  const [notes, setNotes] = useState('');
  const [queryString] = useState(initialStateOfQueryParams);

  const {
    currentUser,
    isWarehouseCustomerAccount,
    currentLocationAndFacility,
    handleUserPermissionAllow,
  } = useContext(AuthContext);

  const [billingFrequencyValue, setBillingFrequencyValue] =
    useState<BillingFrequency>(null);
  const [billBasedOnValue, setBillBasedOnValue] =
    useState<BillingFrequency>(null);
  const [billOrderAfterShipped, setBillOrderAfterShipped] =
    useState<BillingFrequency>(null);
  const [selectedDayOfWeek, setSelectedDayOfWeek] =
    useState<AutoCompleteOptionType>(null);
  const [optionBillingFrequency, setOptionBillingFrequency] = useState([]);
  const [billBasedOnOptions, setBillBasedOnOptions] = useState([]);
  const [billOrderAfterShippedOptions, setBillOrderAfterShippedOptions] =
    useState([]);

  const InitialOtherContact: any = {
    warehouseCustomerOtherContactId: '',
    name: '',
    title: '',
    email: '',
    phone: '',
    phone2: '',
    notes: '',
  };

  const InitialService: any = {
    warehouseCustomerShippingAccountId: '',
    name: '',
    type: '',
  };
  const [services, setServices] = useState(InitialService);
  const [otherContactData, setOtherContactData] = useState([]);
  const [customerPortalAccount, setCustomerPortalAccount] = useState([]);
  const [customerPortalEmailInput, setCustomerPortalEmailInput] = useState('');

  const initialFormState: any = {
    selectedCarrierConnection: '',
    selectedCarrierId: 0,
    customerId: '',
    name: '',
    status: {
      id: defaultAutocompleteOption.id,
      label: defaultAutocompleteOption.label,
    },
    facility: null,
    shippingRatePreference: {
      id: defaultAutocompleteOption.id,
      label: defaultAutocompleteOption.label,
    },
    streetAddress1: '',
    streetAddress2: '',
    cityTownship: '',
    stateProvinceRegion: '',
    zipPostalCode: '',
    country: {
      id: 236,
      label: 'United States of America',
    },
    notes: '',
    upsAccountNumber: '',
    fedexAccountNumber: '',
    accountingName: '',
    accountingClientName: '',
    manufacturerId: '',
    companyLogo: '',
    customerInterchangeId: '',
    customerInterchangeIdQualifier: '',
    threePLInterchangeId: '',
    threePLInterchangeIdQualifier: '',
    contactName: '',
    contactTitle: '',
    contactEmail: '',
    contactPhone: '',
    contactPhone2: '',
    contactNotes: '',
    companyLogoBase64: '',
    otherContacts: [],
    shippingAccounts: [services],
    storeUrl: '',
    integrationActive: false,
    customerEmailPortal: '',
    customerPortalAccount: [],
    customerRuleManagementId: null,
    signatureOption: '',
    signatureOptionOrderValue: '',
    signatureOptionOrderWeight: '',
    signatureType: null,
    signatureTypeWeight: null,
    carrierSelection: '',
    carrierSelectionOrderValue: '',
    carrierSelectionOrderWeight: '',
    carrierType: [],
    carrierTypeWeight: [],
    printCartonContentLabel: '',
    shippingDocumentSettings: '',
    documentType: null,
    internationalOrders: '',
    internationalOrdersDollarValue: '',
    acceptReturns: '',
    maxAllowedReturnDays: '',
    overrideMaximumReturnDays: '',
    acceptReceiveInWhole: '',
    isSendAlertLowInventory: '',
    alertPrimaryEmail: '',
    alertEmailsCC: '',
    isAutoCreatePurchaseOrderLowInventory: '',
    inboundSectionSettingId: null,
    defaultReceivedToLocation: null,
    requireQualityControl: '',
    isConfirmReceiptLineItem: '',
    isAllowMultiplePalletsInOneLocation: '',
    qualityControlOnItems: [],
    outboundSectionSettingId: null,
    requireApprovalBeforePicking: '',
    packAndShipRequirePackingSlip: '',
    completeOrdersAfterPickApproval: '',
    eachOrderLineShouldBeApproved: '',
    orderSourceRulesDataRow: [],
    isUsingTote: false,
    isPickerViewAssignedOrdersOnly: false,
    noOfTotes: null,
    notificationEmails: [],
    startOfWeek: null,
    shippingIntegration: null,
  };

  const initialFormErrorsState: any = {
    customerId: '',
    name: '',
    status: '',
    streetAddress1: '',
    streetAddress2: '',
    cityTownship: '',
    stateProvinceRegion: '',
    zipPostalCode: '',
    country: {
      id: defaultAutocompleteOption.id,
      label: defaultAutocompleteOption.label,
    },
    notes: '',
    upsAccountNumber: '',
    fedexAccountNumber: '',
    manufacturerId: '',
    companyLogo: '',
    customerInterchangeId: '',
    customerInterchangeIdQualifier: '',
    threePLInterchangeId: '',
    threePLInterchangeIdQualifier: '',
    contactName: '',
    contactTitle: '',
    contactEmail: '',
    contactPhone: '',
    contactPhone2: '',
    contactNotes: '',
    companyLogoBase64: '',
    otherContacts: [InitialOtherContact],
    shippingAccounts: [InitialService],
    customerEmailPortal: '',
    shippingRatePreference: '',
    customerNotificationEmails: '',
  };

  // ------------ Billing initialstate ---------------------------------------
  const initialFormStateBillingTab: any = {
    adhocCharges_AddCharge_Enable: '',
    adhocCharges_AddEditCharge_ChargeTypeDDL: [],
    handlingCharges_AddCharge_Enable: '',
    handlingCharges_AddEditCharge_ChargeTypeDDL: [],
    handlingCharges_AddEditCharge_ChargeTypeInbound_ChargeMethodDDL: [],
    handlingCharges_AddEditCharge_ChargeTypeOutbound_ChargeMethodDDL: [],
    handlingCharges_AddEditCharge_ChargeTypePickingCharge_ChargeMethodDDL: [],
    handlingCharges_AddEditCharge_ChargeTypePutAwayCharge_ChargeMethodDDL: [],
    storageCharges_AddCharge_Enable: [],
    storageCharges_AddEditCharge_ChargeMethodDDL: [],
    storageCharges_AddEditCharge_Rate_ChargeMethodDDL: [],
  };

  const initialFormStateBillingAdhoc: any = {
    warehouseCustomerAdhocChargeId: 0,
    chargeName: '',
    accountingCodeName: '',
    rate: '',
    required: false,
    chargeType: {
      text: '',
      value: -1,
    },
  };

  const initialFormStateBillingHandling: any = {
    chargeName: '',
    accountingCodeName: '',
    warehouseCustomerHandlingChargeId: 0,
    chargeType: {
      text: '',
      value: -1,
    },
    chargeMethod: {
      text: '',
      value: -1,
    },
    rateOption: {
      text: '',
      value: -1,
    },
    rate: '',
    slidingRate: [],
    selectedPackages: [],
  };

  const initialFormStateBillingStorage: any = {
    chargeName: '',
    accountingCodeName: '',
    warehouseCustomerStorageChargeId: 0,
    chargeMethod: {
      text: '',
      value: -1,
    },
    palletType: {
      text: '',
      value: -1,
    },
    rateOption: {
      text: '',
      value: -1,
    },
    rate: '',
    slidingRate: [],
    startDay: null,
    discountPercentage: null,
  };

  const initialFormStateBillingStorageSlidingRate: any = {
    slidingRateId: '',
    fromQty: 0,
    toQty: 0,
    rate: '',
  };

  const [tabValue, setTabValue] = React.useState('1');

  const [billingData, setBillingData] = useState(initialFormStateBillingTab);
  const [dataGridAdhocCharges, setDataGridAdhocCharges] = useState([]);
  const [dataGridHandlingCharges, setDataGridHandlingCharges] = useState([]);
  const [dataGridStorageCharges, setDataGridStorageCharges] = useState([]);

  const [dataModalAdhocCharge, setDataModalAdhocCharge] = useState(
    initialFormStateBillingAdhoc,
  );
  const [dataModalHandlingCharge, setDataModalHandlingCharge] = useState(
    initialFormStateBillingHandling,
  );
  const [dataModalStorageCharge, setDataModalStorageCharge] = useState(
    initialFormStateBillingStorage,
  );

  const [storageChargeSlidingRate, setStorageChargeSlidingRate] = useState([
    initialFormStateBillingStorageSlidingRate,
  ]);

  const [handlingChargeSlidingRate, setHandlingChargeSlidingRate] = useState([
    initialFormStateBillingStorageSlidingRate,
  ]);

  const [isAdhocChargeEdit, setIsAdhocChargeEdit] = useState<boolean>(false);
  const [isHandlingChargeEdit, setIsHandlingChargeEdit] =
    useState<boolean>(false);
  const [isStorageChargeEdit, setIsStorageChargeEdit] =
    useState<boolean>(false);

  const [isAdhocChargeDelete, setIsAdhocChargeDelete] =
    useState<boolean>(false);
  const [isHandlingChargeDelete, setIsHandlingChargeDelete] =
    useState<boolean>(false);
  const [isStorageChargeDelete, setIsStorageChargeDelete] =
    useState<boolean>(false);

  const [uomChargeRates, setUOMChargeRates] = useState([]);
  const [selectedUOMChargeRate, setSelectedUOMChargeRate] = useState(null);
  const [isUOMChargeRateModal, setIsUOMChargeRateModal] =
    useState<boolean>(false);

  // ----------------------------------------------------------------------

  const [otherContacts, setOtherContacts] = useState(InitialOtherContact);
  const [formErrors, setFormErrors] = useState<any>([initialFormErrorsState]);
  const [form, setForm] = useState<any>(initialFormState);
  const [status, setStatus] = useState('');
  const [facility, setFacility] =
    useState<CustomerFacilityLocationDropDownListItem>(null);
  const [imageData, setImageData] = useState(null);
  const [deleteOtherContact, setDeleteOtherContact] = useState(false);
  const [dataSelectedDelete, setDataSelectedDelete] = useState({
    customerId: parseInt(currentUser.Claim_CustomerId, 10),
    warehouseCustomerId: '',
    warehouseCustomerOtherContactId: '',
    name: '',
    title: '',
    email: '',
    phone: '',
    phone2: '',
    notes: '',
  });
  const [dataSelected, setDataSelected] = useState({
    warehouseCustomerOtherContactId: '',
    name: '',
    title: '',
    email: '',
    phone: '',
    phone2: '',
    notes: '',
  });
  const [countries, setCountries] = useState([]);
  const [skuMappingOption, setSkuMappingOption] = useState([]);
  const [isAddNew, setIsAddNew] = useState(true);
  const [isEditForm, setIsEditForm] = useState(false);
  const [isViewForm, setIsViewForm] = useState(false);

  const [imageDataText, setImageDataText] = useState('');
  const [inputUPSAccount, setInputUPSAccount] = useState([services]);
  const [inputFedExAccount, setInputFedExAccount] = useState([services]);
  const [shippoCarriers, setShippoCarriers] = useState([]);

  const [carrierIntegrationType, setCarrierIntegrationType] = useState([]);

  // SHIPPPING ACCOUNT EDIT CARRIER MODEL ------------------------------------
  const InitialShippingCarrierEditData: IShippingCarrierEditModal = {
    markupMethod: null,
    markupPercent: '',
    billable: true,
  };

  const [shippingCarrierEditData, setShippingCarrierEditData] = useState(
    InitialShippingCarrierEditData,
  );

  // --------------- ORDER AI RULES -----------------------------------------

  const orderAIRuleDetailForm: IOrderAIRuleDetail = {
    orderSource: '',
    orderSourceDescription: '',
    orderSourceLongDescription: '',
    signatureOptionOrderValue: '',
    signatureType: null,
    signatureOptionOrderWeight: '',
    signatureTypeWeight: null,
    carrierSelectionOrderValue: '',
    carrierSelectionOrderWeight: '',
    insuranceOptionOrderValue: '',
    insuranceOptionOrderWeight: '',
    printCartonContentLabelImage: '',
    shipFromName: '',
    shipFromAddress: '',
    shipFromCity: '',
    shipFromState: '',
    shipFromZip: '',
    shipFromCountry: null,
    returnName: '',
    returnAddress: '',
    returnCity: '',
    returnState: '',
    returnZip: '',
    returnCountry: null,
    documentType: null,
    internationalOrdersDollarValue: '',
    defaultOrderType: null,
    maxAllowedReturnDays: '',
    shippingAndCarrier: null,
    signatureOptionUseDefault: true,
    carrierSelectionUseDefault: true,
    insuranceOptionUseDefault: true,
    printCartonContentLabelUseDefault: true,
    shippingDocumentSettingsUseDefault: true,
    defaultPackageTypeUseDefault: true,
    ordersUseDefault: true,
    returnOrdersUseDefault: true,
    itemMappingUseDefault: true,
    itemMappingSkus: [],
    shippingDocuments: [],
    carrierSelections: [],
    carrierSelectionServices: [],
  };

  const [orderAIRuleDetail, setOrderAIRuleDetail] = useState(
    orderAIRuleDetailForm,
  );

  const [isShowRulesForm, setIsShowRulesForm] = useState(false);
  const [isRulesFormEditMode, setisRulesFormEditMode] = useState(false);
  const [isManageClientDefault, setIsManageClientDefault] = useState(false);
  const [defaultPackgeTypeOption, setDefaultPackgeTypeOption] = useState([]);

  const [
    carrierTypeServiceOptionShipping,
    setCarrierTypeServiceOptionShipping,
  ] = useState([]);
  const [
    carrierTypeServiceOptionCarrierType,
    setCarrierTypeServiceOptionCarrierType,
  ] = useState([]);

  // ------------------------------------------------------

  const handleTabOnChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    if (newValue === '8') {
      setIsShowRulesForm(false);
      setIsManageClientDefault(false);
    }
  };
  const [extensivIntegrations, setExtensivIntegrations] = useState<any>([]);
  const [extensivIntegrationProps, setExtensivIntegrationProps] =
    useState<any>(null);
  const [extensivIntegrationDelete, setExtensivIntegrationDelete] =
    useState<any>(null);
  const [availableIntegrationOptions, setAvailableIntegrationOptions] =
    useState([]);
  const [addAvailableIntegration, setAddAvailableIntegration] = useState(false);

  // Customer Portal variable -------------------------------------------------
  const [emailToActivate, setEmailToActivate] = useState('');
  const [userInfo, setUserInfo] = useState<any>({});

  const [
    permissionWarehouseCustomerValue,
    setPermissionWarehouseCustomerValue,
  ] = useState([]);

  const [dataModalCustomerPortalUser, setDataModalCustomerPortalUser] =
    useState<any>({});
  // rules management for customer -------------------------------------------
  const [optionLocation, setOptionLocation] = useState([]);
  const [carrierTypesOption, setCarrierTypesOption] = useState([]);
  const [isSetShopifyLocationModalOpen, setIsSetShopifyLocationModalOpen] =
    useState(false);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(0);
  const [isViewLogsIntegrationModalOpen, setIsViewLogsIntegrationModalOpen] =
    useState(false);
  const [isSyncInventoryModalOpen, setIsSyncInventoryModalOpen] =
    useState(false);
  const [view, setView] = useState('shipStation');

  // ---------------------------------------------------------------------------
  const handleInputChangeUpsAccount = (arrayInput, index, event) => {
    const datas = [...arrayInput];

    const data = { ...datas[index] };
    data.name = event.target.value;

    if (arrayInput === inputUPSAccount) {
      data.type = 1;
      datas[index] = data;
      setInputUPSAccount(datas);
    } else if (arrayInput === inputFedExAccount) {
      data.type = 2;
      datas[index] = data;
      setInputFedExAccount(datas);
    }
  };

  const addUPSAccount = () => {
    const newField = services;
    setInputUPSAccount([...inputUPSAccount, newField]);
  };

  const addFedexAccount = () => {
    const newField = services;
    setInputFedExAccount([...inputFedExAccount, newField]);
  };

  const onForm = (key, text) => {
    setForm(() => ({
      ...form,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) =>
    onForm(key, event.target.value);

  const statusOptions = Array.isArray(statusItems)
    ? statusItems.map((v, index) => ({
        id: v.id,
        label: v.name,
      }))
    : [{ id: 0, label: '' }];

  const shippingRateOptions = Array.isArray(shippingRatePreferences)
    ? shippingRatePreferences.map((v, index) => ({
        id: v.id,
        label: v.name,
      }))
    : [{ id: 0, label: '' }];

  const autoCompleteInputHandler = (
    key: string,
    value: AutoCompleteOptionType,
  ) => {
    setForm(() => ({
      ...form,
      [key]:
        value === null
          ? {
              id: defaultAutocompleteOption.id,
              label: defaultAutocompleteOption.label,
            }
          : value,
    }));
  };

  const loadStatus = async () => {
    const statusFromApi = await loadStatusOptions('');
    setStatusOptions(statusFromApi);
  };

  const loadFacilityDDL = async () => {
    const facilityDDLFromApi = await GetCustomerFacilityLocationDDL();
    setFacilityDDL(facilityDDLFromApi);
  };

  const loadViewModel = async () => {
    const result = await GetWarehouseCustomerViewModel(
      currentLocationAndFacility.customerFacilityId,
    );
    setStatusOptions(result.statusOptions);
    setFacilityDDL(result.facilityDDL);
    setShippingRatePreferences(result.shippingRatePreferences);
    setCountries(result.countries);

    setOptionLocation(
      result.optionLocation.map((c: any) => ({
        value: c.binId,
        label: c.name,
      })),
    );
  };

  const loadBillingData = async (warehouseCustomerId) => {
    const result = await getBillingData(warehouseCustomerId);
    setBillingData(() => ({
      adhocCharges_AddCharge_Enable: result.adhocCharges_AddCharge_Enable,
      adhocCharges_AddEditCharge_ChargeTypeDDL:
        result.adhocCharges_AddEditCharge_ChargeTypeDDL,
      handlingCharges_AddCharge_Enable: result.handlingCharges_AddCharge_Enable,
      handlingCharges_AddEditCharge_ChargeTypeDDL:
        result.handlingCharges_AddEditCharge_ChargeTypeDDL,
      handlingCharges_AddEditCharge_ChargeTypeInbound_ChargeMethodDDL:
        result.handlingCharges_AddEditCharge_ChargeTypeInbound_ChargeMethodDDL,
      handlingCharges_AddEditCharge_ChargeTypeOutbound_ChargeMethodDDL:
        result.handlingCharges_AddEditCharge_ChargeTypeOutbound_ChargeMethodDDL,
      handlingCharges_AddEditCharge_ChargeTypePickingCharge_ChargeMethodDDL:
        result.handlingCharges_AddEditCharge_ChargeTypePickingCharge_ChargeMethodDDL,
      handlingCharges_AddEditCharge_ChargeTypePutAwayCharge_ChargeMethodDDL:
        result.handlingCharges_AddEditCharge_ChargeTypePutAwayCharge_ChargeMethodDDL,
      storageCharges_AddCharge_Enable: result.storageCharges_AddCharge_Enable,
      storageCharges_AddEditCharge_ChargeMethodDDL:
        result.storageCharges_AddEditCharge_ChargeMethodDDL,
      storageCharges_AddEditCharge_UOMDDL:
        result.storageCharges_AddEditCharge_UOMDDL,
      storageCharges_AddEditCharge_Rate_ChargeMethodDDL:
        result.storageCharges_AddEditCharge_Rate_ChargeMethodDDL,
      storageCharges_AddEditCharge_BinSizeDDL:
        result.storageCharges_AddEditCharge_BinSizeDDL,
    }));

    setDataGridStorageCharges(result.storageCharges);
    setDataGridHandlingCharges(result.handlingCharges);
    setDataGridAdhocCharges(result.adhocCharges);

    setForm((prevState) => ({
      ...prevState,
      billingFrequency: result.billingFrequency,
      numberOfDays: result.numberOfDays,
      startOfWeek: result.startOfWeek,
      billBasedOnOrder: result.billBasedOnOrder,
      billOrderAfterShipped: result.billOrderAfterShipped,
    }));

    if (result.billBasedOnOrder) {
      setBillBasedOnValue(
        billBasedOnOptions.find((x) => x.label === 'Order Items'),
      );
    } else {
      setBillBasedOnValue(
        billBasedOnOptions.find((x) => x.label === 'Picked Items'),
      );
    }

    if (result.billOrderAfterShipped) {
      setBillOrderAfterShipped(
        billOrderAfterShippedOptions.find(
          (x) => x.label === 'After Order Shipped',
        ),
      );
    } else {
      setBillOrderAfterShipped(
        billOrderAfterShippedOptions.find(
          (x) => x.label === 'After Order Picked',
        ),
      );
    }

    const selectedBillingFrequency = optionBillingFrequency.find(
      (x) => x.id === result.billingFrequency,
    );
    setBillingFrequencyValue(selectedBillingFrequency);
    setSelectedDayOfWeek({
      label: result.startOfWeekStr,
      id: result.startOfWeek,
    });
  };

  const loadExtensivIntegrations = async () => {
    let warehouseCustomerId = queryString?.id || 0;

    if (isWarehouseCustomerAccount) {
      warehouseCustomerId = currentUser.Claim_WarehouseCustomerId;
    }

    try {
      const result = await getExtensivIntegrations(warehouseCustomerId);
      setExtensivIntegrations(
        result.filter((e) => e.key !== undefined && e.secret !== undefined),
      );

      setAvailableIntegrationOptions(
        result
          .filter((x) => x.storeUrl === undefined)
          .map((v) => ({
            value: v.id,
            label: v.name,
            id: v.id,
            name: v.name,
            key: v.key,
            secret: v.secret,
            icon: v.icon,
            fields: v.fields,
          })),
      );
    } catch (err) {
      snackActions.error(err);
    }
  };

  const [xeroConnectionExists, setXeroConnectionExists] = useState(false);
  const [quickBooksConnectionExists, setQuickBooksConnectionExists] =
    useState(false);
  const [invoicesToBeSynced, setInvoicesToBeSynced] = useState([]);
  const loadCheckXeroConnectionExists = async () => {
    try {
      const response = await checkXeroConnectionExists(queryString?.id || 0);
      setXeroConnectionExists(response);
    } catch (err) {
      console.log(err);
    }
  };

  const loadCheckQuickBooksConnectionExists = async () => {
    try {
      const response = await checkQuickBooksConnectionExists(
        queryString?.id || 0,
      );
      setQuickBooksConnectionExists(response);
    } catch (err) {
      console.log(err);
    }
  };

  const loadInvoicesToBeSynced = async () => {
    try {
      const response = await getListOfInvoicesToBeSynced();
      setInvoicesToBeSynced(response);
    } catch (err) {
      console.log(err);
    }
  };

  const loadShipStationConfiguration = async () => {
    let warehouseCustomerId = queryString?.id || 0;

    if (isWarehouseCustomerAccount)
      warehouseCustomerId = currentUser.Claim_WarehouseCustomerId;

    const response = await getShipStationConfiguration(warehouseCustomerId);
    return response;
  };

  useEffect(() => {
    if (isAddNew) return;
    if (tabValue === '4') {
      loadShipStationConfiguration();

      if (!isWarehouseCustomerAccount) {
        loadCheckXeroConnectionExists();
        loadCheckQuickBooksConnectionExists();
        loadInvoicesToBeSynced();
      }
    }
  }, [tabValue]);

  const loadShippingRatePreference = async () => {
    const optionsFromApi = await loadShippingRatePreferenceOptions('');
    setShippingRatePreferences(optionsFromApi);
  };

  const loadCountries = async () => {
    try {
      const countriesFromApi = await getCountries('');
      setCountries(countriesFromApi);
      return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadSkuLookupWithwarehouseCustomer = async (warehouseCustomerId) => {
    try {
      const skuLookupFromApi = await getSkuLookupWithwarehouseCustomer(
        currentUser.Claim_CustomerId,
        warehouseCustomerId,
      );
      setSkuMappingOption(skuLookupFromApi);
      return true;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (form.shippingRatePreference.id === -1) {
      const defaultValue = shippingRateOptions.filter(
        (x) => x.label === 'Cheapest',
      )[0];

      if (defaultValue) {
        setForm((prev) => ({
          ...prev,
          shippingRatePreference: {
            id: defaultValue.id,
            label: defaultValue.label,
          },
        }));
      }
    }
  }, [shippingRateOptions]);

  const handleOnDefaultPackageType = async (inputCarriers?: any[]) => {
    const cartonOptions = await getPackagingList(queryString?.id);

    let setArray = [];
    const toCombineDefault = cartonOptions.map((c: any) => ({
      value: c.itemId,
      label: c.descriptionDropDown,
      dimensions: c.dimensions,
    }));

    setArray = [...setArray, ...toCombineDefault];

    if (inputCarriers && inputCarriers.length > 0) {
      inputCarriers.map(async (e) => {
        const result = await getCartonCodeDDL(e.value);

        const toCombine = result.map((c: any) => ({
          label: c.text,
          value: c.value,
          dimensions: c.dimensions,
          isExternalPackage: c.isExternalPackage,
        }));
        setArray = [...setArray, ...toCombine];

        setDefaultPackgeTypeOption([...setArray]);
      });
    } else {
      setDefaultPackgeTypeOption([...setArray]);
    }
    return null;
  };

  const loadCarriers = async () => {
    let warehouseCustomerId = queryString?.id || 0;

    if (isWarehouseCustomerAccount) {
      warehouseCustomerId = currentUser.Claim_WarehouseCustomerId;
    }
    try {
      const shippoCarriersApi = await getAllCarriersByCustomerId(
        warehouseCustomerId,
      );
      setShippoCarriers(shippoCarriersApi);

      if (!isWarehouseCustomerAccount) {
        if (shippoCarriersApi && shippoCarriersApi.length > 0) {
          const parameter = shippoCarriersApi
            ? shippoCarriersApi
                .filter((x) => x.active)
                .map((v) => ({
                  value: v.carrierId,
                  label: v.accountNickName,
                }))
            : [];
          if (queryString?.id !== undefined) {
            handleOnDefaultPackageType(parameter);
          }
        } else {
          handleOnDefaultPackageType();
        }
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  const handleOnCarrierChange = async (inputCarrierId?: any) => {
    if (inputCarrierId) {
      const result = await getOrderCarrierservices(inputCarrierId);

      const setArray = result.map((c: any) => ({
        value: c.value,
        label: c.text,
        isShippingRate: c.isShippingRate,
        serviceCode: c.serviceCode,
      }));

      setCarrierTypeServiceOptionShipping(setArray);
    } else {
      setCarrierTypeServiceOptionShipping([]);
    }
    return null;
  };

  const handleOnCarrierChangeArrayList = async (inputCarriers?: any[]) => {
    if (inputCarriers && inputCarriers.length > 0) {
      let setArray = [];

      inputCarriers.map(async (e) => {
        const result = await getOrderCarrierservices(e?.carrierSelectionId);

        const toCombine = result.map((c: any) => ({
          value: c.value,
          label: c.text,
          isShippingRate: c.isShippingRate,
          serviceCode: c.serviceCode,
        }));
        setArray = [...setArray, ...toCombine];

        setCarrierTypeServiceOptionCarrierType([...setArray]);
      });
    } else {
      setCarrierTypeServiceOptionCarrierType([]);
    }
    return null;
  };

  const viewLoadData = async () => {
    try {
      if (isLoadingData || isAddNew) return;

      if (tabValue === '4') {
        loadExtensivIntegrations();
      } else if (tabValue === '2') {
        loadBillingData(queryString.id);
      } else if (queryString?.id) {
        const response = await getWarehouseCustomerById(queryString.id);
        setForm(() => ({
          warehouseCustomerId: response.warehouseCustomerId,
          customerId: response.customerId,
          name: response.name,
          status: {
            id: response.status,
            label: response.statusStr,
          },
          facility: response.facility ?? null,
          shippingRatePreference: {
            id: response.shippingRatePreference,
            label: response.shippingRatePreferenceStr,
          },
          streetAddress1: response.streetAddress1,
          streetAddress2: response.streetAddress2,
          cityTownship: response.cityTownship,
          stateProvinceRegion: response.stateProvinceRegion,
          zipPostalCode: response.zipPostalCode,
          country: {
            id: response.country,
            label: response.countryStr,
          },
          notes: response.notes,
          manufacturerId: response.manufacturerId,
          customerInterchangeId: response.customerInterchangeId,
          customerInterchangeIdQualifier:
            response.customerInterchangeIdQualifier,
          accountingName: response.accountingName,
          accountingClientName: response.accountingClientName,
          threePLInterchangeId: response.threePLInterchangeId,
          threePLInterchangeIdQualifier: response.threePLInterchangeIdQualifier,
          contactName: response.contactName,
          contactTitle: response.contactTitle,
          contactEmail: response.contactEmail,
          contactPhone: response.contactPhone,
          contactPhone2: response.contactPhone2,
          contactNotes: response.contactNotes,
          customerEmailPortal: '',
          billingFrequency: response.billingFrequency,
          billBasedOnOrder: response.billBasedOnOrder,
          billOrderAfterShipped: response.billOrderAfterShipped,
          numberOfDays: response.numberOfDays,
          customerRuleManagementId:
            response.rulesManagement?.customerRuleManagementId,

          //  Signature Option ----------------------------------------------
          signatureOptionUseDefault:
            response.rulesManagement?.signatureOptionUseDefault,
          signatureOption: response.rulesManagement?.signatureOption,
          signatureOptionOrderValue:
            response.rulesManagement?.signatureOptionOrderValue,
          signatureOptionOrderWeight:
            response.rulesManagement?.signatureOptionOrderWeight,
          signatureType: response.rulesManagement?.signatureType
            ? signatureTypes.find(
                (e) => e.value === response.rulesManagement?.signatureType,
              )
            : null,
          signatureTypeWeight: response.rulesManagement?.signatureTypeWeight
            ? signatureTypes.find(
                (e) =>
                  e.value === response.rulesManagement?.signatureTypeWeight,
              )
            : null,

          // Carrier Option ----------------------------------------------
          carrierSelectionUseDefault:
            response.rulesManagement?.carrierSelectionUseDefault,
          carrierSelection: response.rulesManagement?.carrierSelection,

          carrierSelectionOrderValue:
            response.rulesManagement?.carrierSelectionOrderValue,
          carrierSelectionOrderWeight:
            response.rulesManagement?.carrierSelectionOrderWeight,
          // Insurance Option ----------------------------------------------
          insuranceOptionUseDefault:
            response?.rulesManagement?.insuranceOptionUseDefault,
          insuranceOption: response?.rulesManagement?.insuranceOption,
          insuranceOptionOrderValue:
            response?.rulesManagement?.insuranceOptionOrderValue,
          insuranceOptionOrderWeight:
            response?.rulesManagement?.insuranceOptionOrderWeight,

          // Print Carton Content Label -------------------------------------
          printCartonContentLabelUseDefault:
            response?.rulesManagement?.printCartonContentLabelUseDefault,
          printCartonContentLabel:
            response.rulesManagement?.printCartonContentLabel,

          // Shipping Document Settings ------------------------------------
          shippingDocumentSettingsUseDefault:
            response.rulesManagement?.shippingDocumentSettingsUseDefault,
          shippingDocumentSettings:
            response.rulesManagement?.shippingDocumentSettings,
          printCartonContentLabelImage:
            response.rulesManagement?.printCartonContentLabelImage,
          overrideShipFromAddress:
            response?.rulesManagement?.overrideShipFromAddress,
          shipFromName: response?.rulesManagement?.shipFromName,
          shipFromAddress: response?.rulesManagement?.shipFromAddress,
          shipFromCity: response?.rulesManagement?.shipFromCity,
          shipFromState: response?.rulesManagement?.shipFromState,
          shipFromZip: response?.rulesManagement?.shipFromZip,
          shipFromCountry: response?.rulesManagement?.shipFromCountry
            ? {
                value: response?.rulesManagement.shipFromCountry,
                label: response?.rulesManagement.shipFromCountryStr,
              }
            : null,
          overrideReturnFromAddress:
            response?.rulesManagement?.overrideReturnFromAddress,
          returnName: response?.rulesManagement?.returnName,
          returnAddress: response?.rulesManagement?.returnAddress,
          returnCity: response?.rulesManagement?.returnCity,
          returnState: response?.rulesManagement?.returnState,
          returnZip: response?.rulesManagement?.returnZip,
          returnCountry: response?.rulesManagement?.returnCountry
            ? {
                value: response?.rulesManagement.returnCountry,
                label: response?.rulesManagement.returnCountryStr,
              }
            : null,

          // Default Package -----------------------------------------------
          defaultPackageTypeUseDefault:
            response.rulesManagement?.defaultPackageTypeUseDefault,
          defaultPackageType: response?.rulesManagement?.defaultPackageType
            ? defaultPackgeTypeOption.find(
                (e) => e.value === response.rulesManagement?.defaultPackageType,
              )
            : null,
          // Orders -------------------------------------------------------
          ordersUseDefault: response.rulesManagement?.ordersUseDefault,
          internationalOrders: response.rulesManagement?.internationalOrders,
          internationalOrdersDollarValue:
            response.rulesManagement?.internationalOrdersDollarValue,
          autoholdAllOrders: response.rulesManagement?.autoholdAllOrders,
          defaultOrderType: response?.rulesManagement?.defaultOrderType
            ? defaultOrderTypes.find(
                (e) => e.value === response.rulesManagement?.defaultOrderType,
              )
            : null,

          // Return Orders ------------------------------------------------
          returnOrdersUseDefault:
            response.rulesManagement?.returnOrdersUseDefault,
          acceptReturns: response.rulesManagement?.acceptReturns,
          maxAllowedReturnDays: response.rulesManagement?.maxAllowedReturnDays,
          overrideMaximumReturnDays:
            response.rulesManagement?.overrideMaximumReturnDays,
          acceptReceiveInWhole: response.rulesManagement?.acceptReceiveInWhole,

          itemMappingSkus: response?.rulesManagement?.itemMappingSkus
            ? response.rulesManagement?.itemMappingSkus.map((i) => ({
                dataGridId: Math.random(),
                customerRuleManagementSkuMappingId:
                  i?.customerRuleManagementSkuMappingId,
                itemId: i?.itemId,
                sku: i?.name,
                skuMapping: i?.skuMapping,
              }))
            : [],

          shippingDocuments: response?.rulesManagement?.shippingDocuments
            ? response.rulesManagement?.shippingDocuments.map((i) => ({
                customerRuleManagementShippingDocumentId:
                  i?.customerRuleManagementShippingDocumentId,
                value: i?.shippingDocumentId,
                label: i?.name,
              }))
            : [],

          carrierSelections: response?.rulesManagement?.carrierSelections
            ? response.rulesManagement?.carrierSelections.map((i) => ({
                dataGridId: Math.random(),
                customerRuleManagementCarrierId:
                  i?.customerRuleManagementCarrierId,
                carrierSelection: {
                  value: i?.carrierId,
                  label: i?.carrierName,
                },
                carrierSelectionId: i?.carrierId,
                carrierSelectionName: i?.carrierName,
                carrierSelectionService: {
                  value: i?.carrierServiceId,
                  label: i?.carrierServiceName,
                },
                carrierSelectionServiceId: i?.carrierServiceId,
                carrierSelectionServiceName: i?.carrierServiceName,
                carrierZipcode: i?.zipcode,
                carrierAccountName: i?.accountName,
                carrierthirdPartyAccount: i?.thirdPartyAccount,
              }))
            : [],

          carrierSelectionServices: response?.rulesManagement
            ?.carrierSelectionServices
            ? response.rulesManagement?.carrierSelectionServices.map((i) => ({
                customerRuleManagementCarrierServiceId:
                  i?.customerRuleManagementCarrierServiceId,
                value: i?.carrierServiceId,
                label: i?.carrierServiceName,
              }))
            : [],
          notificationEmails: response.notificationEmails,
          isSendAlertLowInventory:
            response.rulesManagement?.isSendAlertLowInventory,
          alertPrimaryEmail: response.rulesManagement?.alertPrimaryEmail,
          alertEmailsCC: response.rulesManagement?.alertEmailsCC,
          isAutoCreatePurchaseOrderLowInventory:
            response.rulesManagement?.isAutoCreatePurchaseOrderLowInventory,
          inboundSectionSettingId:
            response.inboundSettings?.inboundSectionSettingId,
          defaultReceivedToLocation: response.inboundSettings?.binId
            ? {
                value: response.inboundSettings?.binId,
                label: response.inboundSettings?.binName,
              }
            : null,
          requireQualityControl: response.inboundSettings?.isQualityControl,
          isConfirmReceiptLineItem:
            response.inboundSettings?.isConfirmReceiptLineItem,
          isAllowMultiplePalletsInOneLocation:
            response.inboundSettings?.isAllowMultiplePalletsInOneLocation,
          qualityControlOnItems: response?.inboundSettings?.itemModels
            ? response.inboundSettings.itemModels.map((v) => ({
                value: v.value,
                label: v.label,
              }))
            : [],
          outboundSectionSettingId:
            response.outboundSettings?.outboundSectionSettingId,
          requireApprovalBeforePicking:
            response.outboundSettings?.isRequiredApprovalBeforePicking,
          packAndShipRequirePackingSlip:
            response.outboundSettings?.isPackAndShip,
          completeOrdersAfterPickApproval:
            response.outboundSettings?.isCompleteOrderAfterPickApproval,
          eachOrderLineShouldBeApproved:
            response.outboundSettings?.isEachOrderLineShouldBeApproved,
          shippingIntegration: response.outboundSettings
            ?.shippingIntegrationProvider
            ? {
                value: response.outboundSettings?.shippingIntegrationProvider,
                label:
                  response.outboundSettings?.shippingIntegrationProviderName,
              }
            : null,
          lockOrderEditsAfter: response.outboundSettings?.lockOrderEditsAfter
            ? ({
                text: response.outboundSettings?.lockOrderEditsAfterStr,
                value: response.outboundSettings?.lockOrderEditsAfter,
              } as DropdownListItem)
            : null,
          isUsingTote: response.outboundSettings?.isUsingTote,
          isPickerViewAssignedOrdersOnly:
            response.outboundSettings?.isPickerViewAssignedOrdersOnly,
          noOfTotes: response.outboundSettings?.noOfTotes,
          orderSourceRulesDataRow: response?.customerOrderSourceRules
            ? response.customerOrderSourceRules.map((v) => ({
                customerOrderSourceRuleId: v.customerOrderSourceRuleId,
                orderSource: v.orderSourceName,
                orderSourceDescription: v.description,
                orderSourceLongDescription: v.longDescription,
                customerRuleManagementId:
                  v?.rulesManagement?.customerRuleManagementId,

                //  Signature Option ----------------------------------------------
                signatureOptionUseDefault:
                  v?.rulesManagement?.signatureOptionUseDefault,
                signatureOption: v?.rulesManagement?.signatureOption,
                signatureOptionOrderValue:
                  v?.rulesManagement?.signatureOptionOrderValue,
                signatureType: v.rulesManagement?.signatureType
                  ? signatureTypes.find(
                      (e) => e.value === v.rulesManagement?.signatureType,
                    )
                  : null,
                signatureTypeWeight: v.rulesManagement?.signatureTypeWeight
                  ? signatureTypes.find(
                      (e) => e.value === v.rulesManagement?.signatureTypeWeight,
                    )
                  : null,
                signatureOptionOrderWeight:
                  v?.rulesManagement?.signatureOptionOrderWeight,

                // Carrier Option ----------------------------------------------
                carrierSelectionUseDefault:
                  v?.rulesManagement?.carrierSelectionUseDefault,
                carrierSelection: v?.rulesManagement?.carrierSelection,
                useAllClientCarriersZipcode:
                  v?.rulesManagement?.useAllClientCarriersZipcode,
                useAllClientCarriersAccountName:
                  v?.rulesManagement?.useAllClientCarriersAccountName,
                thirdPartyAccount: v?.rulesManagement?.thirdPartyAccount,

                carrierSelectionOrderValue:
                  v?.rulesManagement?.carrierSelectionOrderValue,
                carrierSelectionOrderWeight:
                  v?.rulesManagement?.carrierSelectionOrderWeight,

                // Insurance Option ----------------------------------------------
                insuranceOptionUseDefault:
                  v?.rulesManagement?.insuranceOptionUseDefault,
                insuranceOption: v?.rulesManagement?.insuranceOption,
                insuranceOptionOrderValue:
                  v?.rulesManagement?.insuranceOptionOrderValue,
                insuranceOptionOrderWeight:
                  v?.rulesManagement?.insuranceOptionOrderWeight,

                // Print Carton Content Label -------------------------------------
                printCartonContentLabelUseDefault:
                  v?.rulesManagement?.printCartonContentLabelUseDefault,
                printCartonContentLabel:
                  v?.rulesManagement?.printCartonContentLabel,

                // Shipping Document Settings ------------------------------------
                shippingDocumentSettingsUseDefault:
                  v?.rulesManagement?.shippingDocumentSettingsUseDefault,
                shippingDocumentSettings:
                  v?.rulesManagement?.shippingDocumentSettings,
                printCartonContentLabelImage:
                  v.rulesManagement?.printCartonContentLabelImage,
                overrideShipFromAddress:
                  v?.rulesManagement?.overrideShipFromAddress,
                shipFromName: v?.rulesManagement?.shipFromName,
                shipFromAddress: v?.rulesManagement?.shipFromAddress,
                shipFromCity: v?.rulesManagement?.shipFromCity,
                shipFromState: v?.rulesManagement?.shipFromState,
                shipFromZip: v?.rulesManagement?.shipFromZip,
                shipFromCountry: v?.rulesManagement?.shipFromCountry
                  ? {
                      value: v?.rulesManagement.shipFromCountry,
                      label: v?.rulesManagement.shipFromCountryStr,
                    }
                  : null,
                overrideReturnFromAddress:
                  v?.rulesManagement?.overrideReturnFromAddress,
                returnName: v?.rulesManagement?.returnName,
                returnAddress: v?.rulesManagement?.returnAddress,
                returnCity: v?.rulesManagement?.returnCity,
                returnState: v?.rulesManagement?.returnState,
                returnZip: v?.rulesManagement?.returnZip,
                returnCountry: v?.rulesManagement?.returnCountry
                  ? {
                      value: v?.rulesManagement.returnCountry,
                      label: v?.rulesManagement.returnCountryStr,
                    }
                  : null,

                // Default Package -----------------------------------------------
                defaultPackageTypeUseDefault:
                  v?.rulesManagement?.defaultPackageTypeUseDefault,
                defaultPackageType: v?.rulesManagement?.defaultPackageType
                  ? defaultPackgeTypeOption.find(
                      (e) => e.value === v.rulesManagement?.defaultPackageType,
                    )
                  : null,

                // Orders -------------------------------------------------------
                ordersUseDefault: v?.rulesManagement?.ordersUseDefault,
                internationalOrders: v?.rulesManagement?.internationalOrders,
                internationalOrdersDollarValue:
                  v?.rulesManagement?.internationalOrdersDollarValue,
                autoholdAllOrders: v?.rulesManagement?.autoholdAllOrders,
                defaultOrderType: v?.rulesManagement?.defaultOrderType
                  ? defaultOrderTypes.find(
                      (e) => e.value === v.rulesManagement?.defaultOrderType,
                    )
                  : null,

                // Return Orders ------------------------------------------------
                returnOrdersUseDefault:
                  v?.rulesManagement?.returnOrdersUseDefault,
                acceptReturns: v?.rulesManagement?.acceptReturns,
                maxAllowedReturnDays: v?.rulesManagement?.maxAllowedReturnDays,
                overrideMaximumReturnDays:
                  v?.rulesManagement?.overrideMaximumReturnDays,
                acceptReceiveInWhole: v?.rulesManagement?.acceptReceiveInWhole,

                itemMappingSkus: v?.rulesManagement?.itemMappingSkus
                  ? v.rulesManagement?.itemMappingSkus.map((i) => ({
                      dataGridId: Math.random(),
                      customerRuleManagementSkuMappingId:
                        i?.customerRuleManagementSkuMappingId,
                      itemId: i?.itemId,
                      sku: i?.name,
                      skuMapping: i?.skuMapping,
                    }))
                  : [],

                shippingDocuments: v?.rulesManagement?.shippingDocuments
                  ? v.rulesManagement?.shippingDocuments.map((i) => ({
                      carrierId: i?.customerRuleManagementShippingDocumentId,
                      value: i?.shippingDocumentId,
                      label: i?.name,
                    }))
                  : [],

                carrierSelections: v?.rulesManagement?.carrierSelections
                  ? v.rulesManagement?.carrierSelections.map((i) => ({
                      dataGridId: Math.random(),
                      customerRuleManagementCarrierId:
                        i?.customerRuleManagementCarrierId,
                      carrierSelection: {
                        value: i?.carrierId,
                        label: i?.carrierName,
                      },
                      carrierSelectionId: i?.carrierId,
                      carrierSelectionName: i?.carrierName,
                      carrierSelectionService: {
                        value: i?.carrierServiceId,
                        label: i?.carrierServiceName,
                      },
                      carrierSelectionServiceId: i?.carrierServiceId,
                      carrierSelectionServiceName: i?.carrierServiceName,
                      carrierZipcode: i?.zipcode,
                      carrierAccountName: i?.accountName,
                      carrierthirdPartyAccount: i?.thirdPartyAccount,
                    }))
                  : [],

                carrierSelectionServices: v?.rulesManagement
                  ?.carrierSelectionServices
                  ? v.rulesManagement?.carrierSelectionServices.map((i) => ({
                      customerRuleManagementCarrierServiceId:
                        i?.customerRuleManagementCarrierServiceId,
                      value: i?.carrierServiceId,
                      label: i?.carrierServiceName,
                    }))
                  : [],
              }))
            : [],
        }));

        setOtherContactData(response.otherContacts);
        setImageDataText(response.companyLogoBase64);
        setCustomerPortalAccount(response.customerPortalAccounts);
      }

      if (tabValue === '6' || tabValue === '8') {
        loadCarriers();
      }
      setIsLoadingData(false);
    } catch (err) {
      snackActions.error(err);
    }
  };

  const validateOnlick = (fieldValues = form) => {
    const temp: any = {};

    if ('name' in fieldValues) {
      temp.name = fieldValues.name ? '' : 'This field is required';
    }
    if ('status' in fieldValues) {
      temp.status =
        fieldValues.status.id !== -1 ? '' : 'This field is required';
    }
    if ('facility' in fieldValues) {
      if (fieldValues.facility === null) {
        temp.facility = 'This field is required';
      } else {
        temp.facility =
          fieldValues.facility.value !== -1 ? '' : 'This field is required';
      }
    } else {
      temp.facility = 'This field is required';
    }

    if ('shippingRatePreference' in fieldValues) {
      temp.shippingRatePreference =
        fieldValues.shippingRatePreference.id !== -1
          ? ''
          : 'This field is required';
    }
    if ('contactName' in fieldValues) {
      temp.contactName = fieldValues.contactName
        ? ''
        : 'This field is required';
    }
    if ('contactTitle' in fieldValues) {
      temp.contactTitle = fieldValues.contactTitle
        ? ''
        : 'This field is required';
    }
    if ('contactEmail' in fieldValues) {
      if (
        fieldValues.contactEmail !== '' ||
        fieldValues.contactEmail !== null ||
        fieldValues.contactEmail !== undefined
      ) {
        temp.contactEmail = /$^|.+@.+..+/.test(fieldValues.contactEmail)
          ? ''
          : 'Email is not valid';
      } else {
        temp.contactEmail = fieldValues.contactEmail
          ? ''
          : 'This field is required';
      }
    }
    if ('contactPhone' in fieldValues) {
      temp.contactPhone = fieldValues.contactPhone
        ? ''
        : 'This field is required';
    }

    if (fieldValues.billingFrequency === 4 && fieldValues.numberOfDays === '') {
      if ('numberOfDays' in fieldValues) {
        temp.numberOfDays = fieldValues.numberOfDays
          ? ''
          : 'This field is required';
      }
    } else {
      temp.numberOfDays = '';
    }

    if (fieldValues.billingFrequency === 2 && !fieldValues.startOfWeek) {
      temp.startOfWeek = fieldValues.startOfWeek
        ? ''
        : 'This field is required';
    } else {
      temp.startOfWeek = '';
    }

    if (fieldValues.isSendAlertLowInventory) {
      if (fieldValues.alertPrimaryEmail) {
        temp.alertPrimaryEmail = /$^|.+@.+..+/.test(
          fieldValues.alertPrimaryEmail,
        )
          ? ''
          : 'Email is not valid';
      } else {
        temp.alertPrimaryEmail = fieldValues.alertPrimaryEmail
          ? ''
          : 'This field is required';
      }
    }

    if (isShowRulesForm) {
      temp.orderSource = orderAIRuleDetail?.orderSource
        ? ''
        : 'This field is required';
      temp.orderSourceDescription = orderAIRuleDetail?.orderSourceDescription
        ? ''
        : 'This field is required';
    }

    setFormErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  // SAVING WAREHOUSE CUSTOMER
  const handleOnCreateWarehouseCustomer = async (e: any) => {
    e.preventDefault();

    const payload: any = {
      customerId: parseInt(currentUser.Claim_CustomerId, 10),
      CustomerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      warehouseCustomerId: '',
      name: form.name,
      Status: form.status.id,
      FacilityId: form.facility?.value ?? null,
      ShippingRatePreference: form.shippingRatePreference.id,
      StreetAddress1: form.streetAddress1,
      StreetAddress2: form.streetAddress2,
      CityTownship: form.cityTownship,
      StateProvinceRegion: form.stateProvinceRegion,
      ZipPostalCode: form.zipPostalCode,
      Country: form.country.id !== -1 ? form.country.id : null,
      Notes: form.notes,
      ShippingAccounts: [...inputUPSAccount, ...inputFedExAccount],
      ManufacturerId: form.manufacturerId,
      CustomerInterchangeId: form.customerInterchangeId,
      CustomerInterchangeIdQualifier: form.customerInterchangeIdQualifier,
      notificationEmails: form.notificationEmails,
      AccountingName: form.accountingName,
      AccountingClientName: form.accountingClientName,
      ThreePLInterchangeId: form.threePLInterchangeId,
      ThreePLInterchangeIdQualifier: form.customerInterchangeIdQualifier,
      CompanyLogoBase64: imageDataText,
      ContactName: form.contactName,
      ContactTitle: form.contactTitle,
      ContactEmail: form.contactEmail,
      ContactPhone: form.contactPhone,
      ContactPhone2: form.contactPhone2,
      ContactNotes: form.contactNotes,
      OtherContacts: otherContactData,
      billingFrequency: form.billingFrequency,
      billBasedOnOrder: form.billBasedOnOrder,
      billOrderAfterShipped: form.billOrderAfterShipped,
      numberOfDays: form.numberOfDays,
      startOfWeek: form.startOfWeek,
      isSendAlertLowInventory: form?.isSendAlertLowInventory,
      alertPrimaryEmail: form?.alertPrimaryEmail,
      alertEmailsCC: form?.alertEmailsCC,
      isAutoCreatePurchaseOrderLowInventory:
        form?.isAutoCreatePurchaseOrderLowInventory,
      rulesManagement: {
        customerRuleManagementId: form?.customerRuleManagementId,
        warehouseCustomerId: form?.warehouseCustomerId,

        //  Signature Option ----------------------------------------------
        signatureOptionUseDefault: false,
        signatureOption: form?.signatureOption,
        signatureOptionOrderValue: form?.signatureOptionOrderValue,
        signatureType: form?.signatureType?.value,
        signatureOptionOrderWeight: form?.signatureOptionOrderWeight,
        signatureTypeWeight: form?.signatureTypeWeight?.value,

        // Carrier Option ----------------------------------------------
        carrierSelectionUseDefault: false,
        carrierSelection: form?.carrierSelection,
        carrierSelectionOrderValue: form?.carrierSelectionOrderValue,
        carrierSelectionOrderWeight: form?.carrierSelectionOrderWeight,

        // Insurance Option ----------------------------------------------
        insuranceOptionUseDefault: false,
        insuranceOption: form?.insuranceOption,
        insuranceOptionOrderValue: form?.insuranceOptionOrderValue,
        insuranceOptionOrderWeight: form?.insuranceOptionOrderWeight,

        // Print Carton Content Label -------------------------------------
        printCartonContentLabelUseDefault: false,
        printCartonContentLabel: form?.printCartonContentLabel,

        // Shipping Document Settings ------------------------------------
        shippingDocumentSettingsUseDefault: false,
        shippingDocumentSettings: form?.shippingDocumentSettings,
        printCartonContentLabelImage: form?.printCartonContentLabelImage,
        overrideShipFromAddress: form?.overrideShipFromAddress,
        shipFromName: form?.shipFromName,
        shipFromAddress: form?.shipFromAddress,
        shipFromCity: form?.shipFromCity,
        shipFromState: form?.shipFromState,
        shipFromZip: form?.shipFromZip,
        shipFromCountry: form?.shipFromCountry?.value,
        overrideReturnFromAddress: form?.overrideReturnFromAddress,
        returnName: form?.returnName,
        returnAddress: form?.returnAddress,
        returnCity: form?.returnCity,
        returnState: form?.returnState,
        returnZip: form?.returnZip,
        returnCountry: form?.returnCountry?.value,

        // Default Package -----------------------------------------------
        defaultPackageTypeUseDefault: false,
        defaultPackageType: form?.defaultPackageType?.value,
        defaultPackageTypeName: form?.defaultPackageType?.label,

        // Orders -------------------------------------------------------
        ordersUseDefault: false,
        internationalOrders: form?.internationalOrders,
        internationalOrdersDollarValue: form?.internationalOrdersDollarValue,
        autoholdAllOrders: form?.autoholdAllOrders,
        defaultOrderType: form?.defaultOrderType?.value,
        // Return Orders ------------------------------------------------
        returnOrdersUseDefault: false,
        acceptReturns: form?.acceptReturns,
        maxAllowedReturnDays: form?.maxAllowedReturnDays,
        overrideMaximumReturnDays: form?.overrideMaximumReturnDays,
        acceptReceiveInWhole: form?.acceptReceiveInWhole,

        itemMappingUseDefault: form?.itemMappingUseDefault,
        itemMappingSkus: form?.itemMappingSkus
          ? form.itemMappingSkus.map((i) => ({
              customerRuleManagementSkuMappingId:
                i?.customerRuleManagementSkuMappingId,
              itemId: i?.itemId,
              name: i?.skuMapping,
            }))
          : [],

        // Carrier Shipment
        shippingDocuments: form?.shippingDocuments
          ? form.shippingDocuments.map((i) => ({
              customerRuleManagementShippingDocumentId:
                i?.customerRuleManagementShippingDocumentId,
              shippingDocumentId: i?.value,
              name: i?.label,
            }))
          : [],
        // CARRIER LIST
        carrierSelections: form?.carrierSelections
          ? form.carrierSelections.map((i) => ({
              customerRuleManagementCarrierId:
                i?.customerRuleManagementCarrierId,
              carrierId: i?.carrierSelectionId,
              carrierServiceId: i?.carrierSelectionServiceId,
              zipcode: i?.carrierZipcode,
              accountName: i?.carrierAccountName,
              thirdPartyAccount: i?.carrierthirdPartyAccount,
            }))
          : [],

        // CARRIER SERVICES LIST
        carrierSelectionServices: form?.carrierSelectionServices
          ? form.carrierSelectionServices.map((i) => ({
              customerRuleManagementCarrierServiceId:
                i?.customerRuleManagementCarrierServiceId,
              carrierServiceId: i?.value,
              carrierServiceName: i?.label,
              ruleManagementCarrierType: CarrierTypeEnum.ShippingAndCarrier,
            }))
          : [],

        isSendAlertLowInventory: form?.isSendAlertLowInventory,
        alertPrimaryEmail: form?.alertPrimaryEmail,
        alertEmailsCC: form?.alertEmailsCC,
        isAutoCreatePurchaseOrderLowInventory:
          form?.isAutoCreatePurchaseOrderLowInventory,
      },
      inboundSettings: {
        inboundSectionSettingId: form?.inboundSectionSettingId,
        warehouseCustomerId: form?.warehouseCustomerId,
        binId: form?.defaultReceivedToLocation?.value,
        isQualityControl: form?.requireQualityControl,
        isConfirmReceiptLineItem: form?.isConfirmReceiptLineItem,
        isAllowMultiplePalletsInOneLocation:
          form?.isAllowMultiplePalletsInOneLocation,
        itemModels: form?.qualityControlOnItems
          ? form.qualityControlOnItems.map((v) => ({ itemId: v.value }))
          : null,
      },
      outboundSettings: {
        outboundSectionSettingId: form?.outboundSectionSettingId,
        warehouseCustomerId: form?.warehouseCustomerId,
        isRequiredApprovalBeforePicking: form?.requireApprovalBeforePicking,
        isPackAndShip: form?.packAndShipRequirePackingSlip,
        isCompleteOrderAfterPickApproval: form?.completeOrdersAfterPickApproval,
        isEachOrderLineShouldBeApproved: form?.eachOrderLineShouldBeApproved,
        isUsingTote: form?.isUsingTote,
        isPickerViewAssignedOrdersOnly: form?.isPickerViewAssignedOrdersOnly,
        noOfTotes: form?.noOfTotes,
        shippingIntegrationProvider: form?.shippingIntegration?.value,
        lockOrderEditsAfter: form?.lockOrderEditsAfter?.value,
      },
    };

    if (isShowRulesForm) {
      // for order AI rules
      const datas = [...form.orderSourceRulesDataRow];
      const newValue = datas?.find(
        (row) =>
          row.customerOrderSourceRuleId ===
          orderAIRuleDetail?.customerOrderSourceRuleId,
      );
      const newState: IOrderAIRuleDetail[] = datas.map((obj) => {
        if (
          obj.customerOrderSourceRuleId ===
          orderAIRuleDetail.customerOrderSourceRuleId
        ) {
          return {
            ...obj,
            customerOrderSourceRuleId:
              orderAIRuleDetail?.customerOrderSourceRuleId,
            orderSource: orderAIRuleDetail?.orderSource,
            orderSourceDescription: orderAIRuleDetail?.orderSourceDescription,
            orderSourceLongDescription:
              orderAIRuleDetail?.orderSourceLongDescription,
            customerRuleManagementId:
              orderAIRuleDetail?.customerRuleManagementId,
            //  Signature Option ----------------------------------------------
            signatureOptionUseDefault:
              orderAIRuleDetail?.signatureOptionUseDefault,
            signatureOption: orderAIRuleDetail?.signatureOption,
            signatureOptionOrderValue:
              orderAIRuleDetail?.signatureOptionOrderValue,
            signatureType: orderAIRuleDetail?.signatureType,
            signatureOptionOrderWeight:
              orderAIRuleDetail?.signatureOptionOrderWeight,
            signatureTypeWeight: orderAIRuleDetail?.signatureTypeWeight,

            // Carrier Option ----------------------------------------------
            carrierSelectionUseDefault:
              orderAIRuleDetail?.carrierSelectionUseDefault,
            carrierSelection: orderAIRuleDetail?.carrierSelection,
            carrierSelectionOrderValue:
              orderAIRuleDetail?.carrierSelectionOrderValue,
            carrierSelectionOrderWeight:
              orderAIRuleDetail?.carrierSelectionOrderWeight,

            // Insurance Option ----------------------------------------------
            insuranceOptionUseDefault:
              orderAIRuleDetail?.insuranceOptionUseDefault,
            insuranceOption: orderAIRuleDetail?.insuranceOption,
            insuranceOptionOrderValue:
              orderAIRuleDetail?.insuranceOptionOrderValue,

            // Print Carton Content Label -------------------------------------
            printCartonContentLabelUseDefault:
              orderAIRuleDetail?.printCartonContentLabelUseDefault,
            printCartonContentLabel: orderAIRuleDetail?.printCartonContentLabel,

            // Shipping Document Settings ------------------------------------
            shippingDocumentSettingsUseDefault:
              orderAIRuleDetail?.shippingDocumentSettingsUseDefault,
            shippingDocumentSettings:
              orderAIRuleDetail?.shippingDocumentSettings,
            printCartonContentLabelImage:
              orderAIRuleDetail?.printCartonContentLabelImage,
            overrideShipFromAddress: orderAIRuleDetail?.overrideShipFromAddress,
            shipFromName: orderAIRuleDetail?.shipFromName,
            shipFromAddress: orderAIRuleDetail?.shipFromAddress,
            shipFromCity: orderAIRuleDetail?.shipFromCity,
            shipFromState: orderAIRuleDetail?.shipFromState,
            shipFromZip: orderAIRuleDetail?.shipFromZip,
            shipFromCountry: orderAIRuleDetail?.shipFromCountry,
            overrideReturnFromAddress:
              orderAIRuleDetail?.overrideReturnFromAddress,
            returnName: orderAIRuleDetail?.returnName,
            returnAddress: orderAIRuleDetail?.returnAddress,
            returnCity: orderAIRuleDetail?.returnCity,
            returnState: orderAIRuleDetail?.returnState,
            returnZip: orderAIRuleDetail?.returnZip,
            returnCountry: orderAIRuleDetail?.returnCountry,

            // Default Package -----------------------------------------------
            defaultPackageTypeUseDefault:
              orderAIRuleDetail?.defaultPackageTypeUseDefault,
            defaultPackageType: orderAIRuleDetail?.defaultPackageType,

            // Orders -------------------------------------------------------
            ordersUseDefault: orderAIRuleDetail?.ordersUseDefault,
            internationalOrders: orderAIRuleDetail?.internationalOrders,
            internationalOrdersDollarValue:
              orderAIRuleDetail?.internationalOrdersDollarValue,
            autoholdAllOrders: orderAIRuleDetail?.autoholdAllOrders,
            defaultOrderType: orderAIRuleDetail?.defaultOrderType,
            // Return Orders ------------------------------------------------
            returnOrdersUseDefault: orderAIRuleDetail?.returnOrdersUseDefault,
            acceptReturns: orderAIRuleDetail?.acceptReturns,
            maxAllowedReturnDays: orderAIRuleDetail?.maxAllowedReturnDays,
            overrideMaximumReturnDays:
              orderAIRuleDetail?.overrideMaximumReturnDays,
            acceptReceiveInWhole: orderAIRuleDetail?.acceptReceiveInWhole,

            itemMappingUseDefault: orderAIRuleDetail?.itemMappingUseDefault,
            itemMappingSkus: orderAIRuleDetail?.itemMappingSkus
              ? orderAIRuleDetail.itemMappingSkus.map((i) => ({
                  customerRuleManagementSkuMappingId:
                    i?.customerRuleManagementSkuMappingId,
                  itemId: i?.itemId,
                  skuMapping: i?.skuMapping,
                }))
              : [],

            // Carrier Shipment
            shippingDocuments: orderAIRuleDetail?.shippingDocuments
              ? orderAIRuleDetail.shippingDocuments.map((i) => ({
                  carrierId: i?.carrierId,
                  value: i?.value,
                  label: i?.label,
                }))
              : [],
            // CARRIER LIST
            carrierSelections: orderAIRuleDetail?.carrierSelections
              ? orderAIRuleDetail.carrierSelections.map((i) => ({
                  customerRuleManagementCarrierId:
                    i?.customerRuleManagementCarrierId,
                  carrierSelection: i?.carrierSelection,
                  carrierSelectionId: i?.carrierSelectionId,
                  carrierSelectionName: i?.carrierSelectionName,
                  carrierSelectionService: i?.carrierSelectionService,
                  carrierSelectionServiceId: i?.carrierSelectionServiceId,
                  carrierSelectionServiceName: i?.carrierSelectionServiceName,
                  carrierZipcode: i?.carrierZipcode,
                  carrierAccountName: i?.carrierAccountName,
                  carrierthirdPartyAccount: i?.carrierthirdPartyAccount,
                }))
              : [],

            // CARRIER SERVICES LIST
            carrierSelectionServices:
              orderAIRuleDetail?.carrierSelectionServices
                ? orderAIRuleDetail.carrierSelectionServices.map((i) => ({
                    carrierServiceId: i?.carrierServiceId,
                    value: i?.value,
                    label: i?.label,
                    ruleManagementCarrierType:
                      CarrierTypeEnum.ShippingAndCarrier,
                  }))
                : [],
          };
        }
        return obj;
      });

      const mapToListPayload = newValue
        ? newState
        : [...form.orderSourceRulesDataRow, orderAIRuleDetail];

      payload.customerOrderSourceRules = mapToListPayload.map((v) => ({
        customerOrderSourceRuleId: v?.customerOrderSourceRuleId,
        orderSourceName: v?.orderSource,
        Description: v?.orderSourceDescription,
        LongDescription: v?.orderSourceLongDescription,
        notificationEmails: v?.notificationEmails,
        rulesManagement: {
          customerRuleManagementId: v?.customerRuleManagementId,
          //  Signature Option ----------------------------------------------
          signatureOptionUseDefault: v?.signatureOptionUseDefault,
          signatureOption: v?.signatureOption,
          signatureOptionOrderValue: v?.signatureOptionOrderValue,
          signatureType: v?.signatureType?.value,
          signatureOptionOrderWeight: v?.signatureOptionOrderWeight,
          signatureTypeWeight: v?.signatureTypeWeight?.value,
          // Carrier Option ----------------------------------------------
          carrierSelectionUseDefault: v?.carrierSelectionUseDefault,
          carrierSelection: v?.carrierSelection,
          useAllClientCarriersZipcode: v?.useAllClientCarriersZipcode,
          useAllClientCarriersAccountName: v?.useAllClientCarriersAccountName,
          thirdPartyAccount: v?.thirdPartyAccount,

          carrierSelectionOrderValue: v?.carrierSelectionOrderValue,
          carrierSelectionOrderWeight: v?.carrierSelectionOrderWeight,

          // Insurance Option ----------------------------------------------
          insuranceOptionUseDefault: v?.insuranceOptionUseDefault,
          insuranceOption: v?.insuranceOption,
          insuranceOptionOrderValue: v?.insuranceOptionOrderValue,
          insuranceOptionOrderWeight: v?.insuranceOptionOrderWeight,

          // Print Carton Content Label -------------------------------------
          printCartonContentLabelUseDefault:
            v?.printCartonContentLabelUseDefault,
          printCartonContentLabel: v?.printCartonContentLabel,

          // Shipping Document Settings ------------------------------------
          shippingDocumentSettingsUseDefault:
            v?.shippingDocumentSettingsUseDefault,
          shippingDocumentSettings: v?.shippingDocumentSettings,
          printCartonContentLabelImage: v?.printCartonContentLabelImage,
          overrideShipFromAddress: v?.overrideShipFromAddress,
          shipFromName: v?.shipFromName,
          shipFromAddress: v?.shipFromAddress,
          shipFromCity: v?.shipFromCity,
          shipFromState: v?.shipFromState,
          shipFromZip: v?.shipFromZip,
          shipFromCountry: v?.shipFromCountry?.value,
          overrideReturnFromAddress: v?.overrideReturnFromAddress,
          returnName: v?.returnName,
          returnAddress: v?.returnAddress,
          returnCity: v?.returnCity,
          returnState: v?.returnState,
          returnZip: v?.returnZip,
          returnCountry: v?.returnCountry?.value,

          // Default Package -----------------------------------------------
          defaultPackageTypeUseDefault: v?.defaultPackageTypeUseDefault,
          defaultPackageType: v?.defaultPackageType?.value,
          defaultPackageTypeName: v?.defaultPackageType?.label,

          // Orders -------------------------------------------------------
          ordersUseDefault: v?.ordersUseDefault,
          internationalOrders: v?.internationalOrders,
          internationalOrdersDollarValue: v?.internationalOrdersDollarValue,
          autoholdAllOrders: v?.autoholdAllOrders,
          defaultOrderType: v?.defaultOrderType?.value,

          // Return Orders ------------------------------------------------
          returnOrdersUseDefault: v?.returnOrdersUseDefault,
          acceptReturns: v?.acceptReturns,
          maxAllowedReturnDays: v?.maxAllowedReturnDays,
          overrideMaximumReturnDays: v?.overrideMaximumReturnDays,
          acceptReceiveInWhole: v?.acceptReceiveInWhole,

          itemMappingUseDefault: v?.itemMappingUseDefault,
          itemMappingSkus: v?.itemMappingSkus
            ? v.itemMappingSkus.map((i) => ({
                customerRuleManagementSkuMappingId:
                  i?.customerRuleManagementSkuMappingId,
                itemId: i?.itemId,
                name: i?.skuMapping,
              }))
            : [],

          // Carrier Shipment
          shippingDocuments: v?.shippingDocuments
            ? v.shippingDocuments.map((i) => ({
                customerRuleManagementShippingDocumentId: i?.carrierId,
                shippingDocumentId: i?.value,
                name: i?.label,
              }))
            : [],
          // CARRIER LIST
          carrierSelections: v?.carrierSelections
            ? v.carrierSelections.map((i) => ({
                customerRuleManagementCarrierId:
                  i?.customerRuleManagementCarrierId,
                carrierId: i?.carrierSelection?.value,
                carrierServiceId: i?.carrierSelectionService?.value,
                zipcode: i?.carrierZipcode,
                accountName: i?.carrierAccountName,
                thirdPartyAccount: i?.carrierthirdPartyAccount,
              }))
            : [],

          // CARRIER SERVICES LIST
          carrierSelectionServices: v?.carrierSelectionServices
            ? v.carrierSelectionServices.map((i) => ({
                customerRuleManagementCarrierServiceId: i?.carrierServiceId,
                carrierServiceId: i?.value,
                carrierServiceName: i?.label,
                ruleManagementCarrierType: i?.ruleManagementCarrierType,
              }))
            : [],
        },
      }));
    }

    if (!validateOnlick()) {
      return;
    }

    try {
      if (isAddNew) {
        const result = await createWarehouseCustomer(payload);
        snackActions.success(
          `Warehouse customer ${result.name} has been successfully created.`,
        );
        navigate(
          `/customers/warehouseCustomer?form=view&id=${result.warehouseCustomerId}`,
        );
        navigate(0);
      } else if (isEditForm) {
        payload.warehouseCustomerId = form.warehouseCustomerId;

        const result = await updateWarehouseCustomer(payload);
        snackActions.success(
          `Warehouse customer ${result.name} has been successfully updated.`,
        );
        setIsViewForm(true);
        navigate(
          `/customers/warehouseCustomer?form=view&id=${result.warehouseCustomerId}`,
        );
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  const handleCloseCustomerPortalAccountModal = () => {
    setIsCustomerPortalPermissionModalOpen(false);
    setForm((prev) => ({
      ...prev,
      customerEmailPortal: '',
    }));
    setUserInfo({});
    setFormErrors([initialFormErrorsState]);
    setPermissionWarehouseCustomerValue([]);
  };

  const handleOnCreateCustomerPortalAccount = async (e: any) => {
    e.preventDefault();

    const payload = {
      warehouseCustomerId: form.warehouseCustomerId,
      email: form.customerEmailPortal,
      url: '',
      warehouseCustomerAccountId: null,
      userPermissionIds: permissionWarehouseCustomerValue,
    };

    if (permissionWarehouseCustomerValue.length === 0) {
      snackActions.error('Please select at least 1 permission');
      return;
    }

    const fieldValues = payload;
    const temp: any = {};
    if (fieldValues.email) {
      temp.customerEmailPortal = /$^|.+@.+..+/.test(fieldValues.email)
        ? ''
        : 'Email Address is not valid';
    } else {
      temp.customerEmailPortal = fieldValues.email
        ? ''
        : 'Email Address is required';
    }

    setFormErrors({
      ...temp,
    });
    const bool = Object.values(temp).every((x) => x === '');
    if (!bool) {
      return;
    }

    try {
      if (
        userInfo?.warehouseCustomerAccountId !== undefined &&
        userInfo?.warehouseCustomerAccountId !== null
      ) {
        const PAYLOAD_EDIT = {
          warehouseCustomerAccountId: userInfo?.warehouseCustomerAccountId,
          warehouseCustomerPermissions: payload.userPermissionIds,
        };

        await editCustomerPortalAccount(PAYLOAD_EDIT);
      } else {
        const result = await createCustomerPortalAccount(payload);
        // payload.url = 'google.com';
        // payload.url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/Activation`; // local host
        payload.url = `${window.location.protocol}//${window.location.hostname}/Activation`;
        payload.warehouseCustomerAccountId = result.warehouseCustomerAccountId;
        await generateActivationUrl(payload);
        snackActions.success(
          `Client Portal Account "${payload.email}" has been successfully added.`,
        );
      }

      viewLoadData();
      handleCloseCustomerPortalAccountModal();
    } catch (err) {
      snackActions.error(err);
    }
  };

  const handleEditViewOnlick = () => {
    setIsViewForm(false);
    setIsEditForm(true);
    navigate(`/customers/warehouseCustomer?form=edit&id=${queryString.id}`);
  };

  const handleOnCarrierIntegrationType = (e) => {
    if (e === 'Shippo') {
      onOpenShippoIntegrationModal();
    } else if (e === 'EasyPost') {
      onOpenCarrierAccountModal();
    }
  };

  const handleOnKeyUpShippingAccount = async (rowData) => {
    if (isEditForm) {
      const shippingAccount = {
        CustomerId: parseInt(currentUser.Claim_CustomerId, 10),
        WarehouseCustomerId: form.warehouseCustomerId,
        WarehouseCustomerShippingAccountId:
          rowData.warehouseCustomerShippingAccountId,
        Name: rowData.name,
        Type: rowData.type,
      };

      if (rowData.warehouseCustomerShippingAccountId === '') {
        const result = await createWarehouseCustomerShippingAccount(
          shippingAccount,
        );
      } else if (rowData.name !== '') {
        const result = await updateWarehouseCustomerShippingAccount(
          shippingAccount,
        );
      } else {
        const result = await deleteWarehouseCustomerShippingAccount(
          shippingAccount,
        );
      }
    }
  };

  const handleOpenDeleteModal = (rowData) => {
    setDataSelectedDelete((e) => ({
      ...e,
      warehouseCustomerId: form.warehouseCustomerId,
      warehouseCustomerOtherContactId: rowData.warehouseCustomerOtherContactId,
      name: rowData.name,
      title: rowData.title,
      email: rowData.email,
      phone: rowData.phone,
      phone2: rowData.phone2,
      notes: rowData.notes,
    }));
    setDeleteOtherContact(true);
    onOpenConfirmDeleteDialog();
  };

  // Other Contact detail and Data Grid ----------------------------------------------

  const handleOpenOtherContactModal = (rowData) => {
    setDataSelected(rowData);
    onOpenOtherContactModal();
  };
  type ItemRow = typeof otherContactData[number];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const otherContactsColumns: GridColumns<ItemRow> = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      hide: !isEditForm,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleOpenOtherContactModal(params.row)}
          showInMenu
          disabled={isViewForm}
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => handleOpenDeleteModal(params.row)}
          showInMenu
          disabled={isViewForm}
        />,
      ],
    },
    {
      field: 'name',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Name</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.name}</Typography>,
    },
    {
      field: 'title',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Title</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.title}</Typography>,
    },
    {
      field: 'email',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Email</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.email}</Typography>,
    },
    {
      field: 'phone',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Phone</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.phone}</Typography>,
    },
    {
      field: 'phone2',
      minWidth: 200,
      flex: 1,
      hide: true,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Phone 2</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.phone2}</Typography>,
    },
    {
      field: 'notes',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Notes</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.notes}</Typography>,
    },
  ];

  // Customer Portal Account Activation and Data Grid ---------------------------------
  const OnClickCustomerPortalAccountAccess = async (rowData) => {
    try {
      await updateCustomerPortalAccountAccess(rowData);
      snackActions.success(
        `Client Portal Account "${rowData.email}" has been successfully changed status`,
      );
      viewLoadData();
    } catch (err) {
      snackActions.error(err);
    }
  };

  const OnClickCustomerPortalAccountOrderNotification = async (rowData) => {
    try {
      await updateCustomerPortalAccountEmailNotification(
        rowData.warehouseCustomerAccountId,
      );
      viewLoadData();
    } catch (err) {
      snackActions.error(err);
    }
  };

  const OnClickCustomerPortalAccountResendActication = async (rowData) => {
    try {
      const payload = {
        // url: 'google.com',
        // url: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/Activation`,
        url: `${window.location.protocol}//${window.location.hostname}/Activation`,
        email: rowData.email,
        warehouseCustomerAccountId: rowData.warehouseCustomerAccountId,
      };
      await generateActivationUrl(payload);
      snackActions.success(
        `Activation message successfully sent to "${rowData.email}"`,
      );
    } catch (err) {
      snackActions.error(err);
    }
  };

  const handleDeleteRemoveUser = (rowData) => {
    setDataModalCustomerPortalUser(rowData);
    setIsUserWarehouseCustomerDeleteModalOpen(true);
  };

  const handleEditUserPermissionInClientPortal = (row) => {
    setUserPermission(row);
    setUsersPermissionModal(true);

    const permissions = [...defaultItemStates];

    const newState = permissions.map((obj) => {
      if (row?.userPermissionIds.some((x) => x === obj.value)) {
        return {
          ...obj,
          isUserPermissionChecked: true,
          state: CheckboxState.CHECKED,
        };
      }
      return obj;
    });
    setUserPermissionStates(newState);
  };

  type ItemPortalRow = typeof customerPortalAccount[number];
  const customerPortalAccountColumns: GridColumns<ItemPortalRow> = [
    {
      field: 'actions',
      type: 'actions',
      width: 30,
      hide: !handleUserPermissionAllow(
        UserPermissionEnum.Client_CustomerPortal_Edit,
      ),
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label={params.row.isActive ? 'Revoke Access' : 'Reactivate'}
          onClick={() => OnClickCustomerPortalAccountAccess(params.row)}
          showInMenu
          disabled={!params.row.activeInactiveButtonEnable}
        />,
        <GridActionsCellItem
          label="Resend Activation"
          onClick={() =>
            OnClickCustomerPortalAccountResendActication(params.row)
          }
          showInMenu
          disabled={!params.row.resendActivationButtonEnable}
        />,
        <GridActionsCellItem
          label="Edit Permission"
          onClick={() => {
            handleEditUserPermissionInClientPortal(params.row);
            setPageUsedPermission(PageUsedEnum.ClientPortal);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Remove"
          onClick={() => handleDeleteRemoveUser(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'email',
      minWidth: 250,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px', marginBottom: '0px !important' }}>
          <Typography fontWeight="bold">Email</Typography>
        </Box>
      ),
    },
    {
      field: 'isActiveStr',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Active / Inactive</Typography>
        </Box>
      ),
    },
    {
      field: 'isCompleteOrderEmailEnabled',
      minWidth: 250,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Complete Order Notification</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Box sx={{ gap: '5px' }}>
          <Checkbox
            disabled={
              !handleUserPermissionAllow(
                UserPermissionEnum.Client_CustomerPortal_Edit,
              )
            }
            checked={params.row.enableOrderCompleteEmail}
            onChange={() => {
              OnClickCustomerPortalAccountOrderNotification(params.row);
            }}
          />
        </Box>
      ),
    },
  ];

  const handleDeleteBillingStorage = (params) => {
    setDataModalStorageCharge((prev) => ({
      ...prev,
      warehouseCustomerStorageChargeId:
        params.row.warehouseCustomerStorageChargeId,
      chargeMethod: {
        value: params.row.chargeMethod,
        text: params.row.chargeMethodStr,
      },
      rateOption: {
        value: params.row.rateOption,
        text: params.row.rateOptionStr,
      },
      rate: params.row.rate,
    }));
    setIsAdhocChargeDelete(false);
    setIsHandlingChargeDelete(false);
    setIsStorageChargeDelete(true);
    onOpenConfirmDeleteDialog();
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const [openHandling, setOpenHandling] = React.useState(false);

  const handleClickHandling = () => {
    setOpenHandling(!openHandling);
  };

  const theme = useTheme();
  type storageItem = typeof dataGridStorageCharges[number];

  const billingStorageChargesColumns: GridColumns<storageItem> = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      hide: !handleUserPermissionAllow(UserPermissionEnum.Client_Billing_Edit),
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          disabled={!params.row.contextMenu_Edit_Enable}
          onClick={() => {
            onOpenBillingStorageModal();
            setDataModalStorageCharge((prev) => ({
              ...prev,
              warehouseCustomerStorageChargeId:
                params.row.warehouseCustomerStorageChargeId,
              chargeMethod: {
                value: params.row.chargeMethod,
                text: params.row.chargeMethodStr,
              },
              uom: {
                value: params.row.uom,
                text: params.row.uomStr,
              },
              palletType: {
                value: params.row.palletType,
                label: params.row.palletTypeStr,
              },
              rateOption: {
                value: params.row.rateOption,
                text: params.row.rateOptionStr,
              },
              rate: params.row.rate,
              chargeName: params.row.chargeName,
              startDay: params.row.startDay,
              discountPercentage: params.row.percentageDiscount,
              accountingCodeName: params.row.accountingCodeName,
              binSize: {
                value: params.row.binSize,
                text: params.row.binSizeStr,
              } as DropdownListItem,
            }));
            setStorageChargeSlidingRate(
              params.row.storageChargeBillingListGetModel_SlidingRates,
            );
            setIsStorageChargeEdit(true);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          disabled={!params.row.contextMenu_Delete_Enable}
          onClick={() => handleDeleteBillingStorage(params)}
          showInMenu
        />,
      ],
    },
    {
      field: 'chargeName',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Charge Name</Typography>
        </Box>
      ),
    },
    {
      field: 'chargeMethodStr',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Charge Method</Typography>
        </Box>
      ),
    },
    {
      field: 'rate',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Rate</Typography>
        </Box>
      ),
      renderCell: (params) =>
        params.row.rateOption === 0 ? (
          <Typography>{params.row.rate}</Typography>
        ) : (
          <div>
            <ListItemButton onClick={handleClick}>
              <ListItemText primary="Rates" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {params.row.storageChargeBillingListGetModel_SlidingRates.map(
                (row, index) => (
                  <Typography
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    {row.fromQty}-{row.toQty} ${row.rate}
                  </Typography>
                ),
              )}
            </Collapse>
          </div>
        ),
    },
  ];

  const handleDeleteBillingHandling = (params) => {
    setDataModalHandlingCharge((prev) => ({
      ...prev,
      warehouseCustomerHandlingChargeId:
        params.row.warehouseCustomerHandlingChargeId,
      chargeMethod: {
        value: params.row.chargeMethod,
        text: params.row.chargeMethodStr,
      },
      chargeType: {
        value: params.row.chargeType,
        text: params.row.chargeTypeStr,
      },
      rate: params.row.rate,
    }));
    setIsAdhocChargeDelete(false);
    setIsHandlingChargeDelete(true);
    setIsStorageChargeDelete(false);
    onOpenConfirmDeleteDialog();
  };

  const billingHandlingChargesColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      hide: !handleUserPermissionAllow(UserPermissionEnum.Client_Billing_Edit),
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          disabled={!params.row.contextMenu_Edit_Enable}
          onClick={() => {
            onOpenBillingHandlingModal();
            setDataModalHandlingCharge((prev) => ({
              ...prev,
              warehouseCustomerHandlingChargeId:
                params.row.warehouseCustomerHandlingChargeId,
              chargeMethod: {
                value: params.row.chargeMethod,
                text: params.row.chargeMethodStr,
              },
              chargeType: {
                value: params.row.chargeType,
                text: params.row.chargeTypeStr,
              },
              uom: {
                value: params.row.uom,
                text: params.row.uomStr,
              },
              rateOption: {
                value: params.row.rateOption,
                text: params.row.rateOptionStr,
              },
              rate: params.row.rate,
              orderFee: params.row.orderFee,
              itemQty: params.row.itemQty,
              itemFee: params.row.itemFee,
              itemAdditionalFee: params.row.itemAdditionalFee,
              uniqueItemQty: params.row.uniqueItemQty,
              uniqueItemFee: params.row.uniqueItemFee,
              uniqueItemAdditionalFee: params.row.uniqueItemAdditionalFee,
              chargeName: params.row.chargeName,
              accountingCodeName: params.row.accountingCodeName,
              selectedPackages: params.row.selectedPackages,
            }));
            setHandlingChargeSlidingRate(
              params.row.handlingChargeBillingListGetModel_SlidingRates,
            );
            setUOMChargeRates(
              params.row.handlingChargeBillingListGetModel_UOMChargeRates,
            );
            setIsHandlingChargeEdit(true);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          disabled={!params.row.contextMenu_Delete_Enable}
          onClick={() => handleDeleteBillingHandling(params)}
          showInMenu
        />,
      ],
    },
    {
      field: 'chargeName',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Charge Name</Typography>
        </Box>
      ),
    },
    {
      field: 'chargeTypeStr',
      minWidth: 150,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Charge Type</Typography>
        </Box>
      ),
    },
    {
      field: 'chargeMethodStr',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Charge Method</Typography>
        </Box>
      ),
    },
    {
      field: 'rate',
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Rate</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <>
          {params.row.rateOption ===
            WarehouseCustomerChargeMethodRateEnum.FixedRate &&
            (params.row.chargeType !==
              WarehouseCustomerHandlingChargeTypeEnum.PackingFee ||
              (params.row.chargeType ===
                WarehouseCustomerHandlingChargeTypeEnum.PackingFee &&
                (params.row.chargeMethod ===
                  WarehouseCustomerHandlingChargeMethodEnum.BasedOnPalletPerLocation ||
                  params.row.chargeMethod ===
                    WarehouseCustomerHandlingChargeMethodEnum.ByWeight ||
                  params.row.chargeMethod ===
                    WarehouseCustomerHandlingChargeMethodEnum.ByPackageWeight ||
                  params.row.chargeMethod ===
                    WarehouseCustomerHandlingChargeMethodEnum.CarrierPackage ||
                  params.row.chargeMethod ===
                    WarehouseCustomerHandlingChargeMethodEnum.PackageLabel))) && (
              <Typography>{params.row.rate}</Typography>
            )}

          {params.row.rateOption ===
            WarehouseCustomerChargeMethodRateEnum.SlidingRate &&
            (params.row.chargeType !==
              WarehouseCustomerHandlingChargeTypeEnum.PackingFee ||
              (params.row.chargeType ===
                WarehouseCustomerHandlingChargeTypeEnum.PackingFee &&
                params.row.chargeMethod ===
                  WarehouseCustomerHandlingChargeMethodEnum.BasedOnPalletPerLocation)) && (
              <div>
                <ListItemButton onClick={handleClickHandling}>
                  <ListItemText primary="Rates" />
                  {openHandling ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openHandling} timeout="auto" unmountOnExit>
                  {params.row.handlingChargeBillingListGetModel_SlidingRates.map(
                    (row, index) => (
                      <Typography
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        {row.fromQty}-{row.toQty} ${row.rate}
                      </Typography>
                    ),
                  )}
                </Collapse>
              </div>
            )}

          {params.row.chargeType ===
            WarehouseCustomerHandlingChargeTypeEnum.PackingFee &&
            (params.row.chargeMethod ===
              WarehouseCustomerHandlingChargeMethodEnum.ByItemType ||
              params.row.chargeMethod ===
                WarehouseCustomerHandlingChargeMethodEnum.PackageLabel) && (
              <Typography>See rate</Typography>
            )}
        </>
      ),
    },
  ];

  const handleDeleteBillingAdhoc = (params) => {
    setDataModalAdhocCharge((prev) => ({
      ...prev,
      warehouseCustomerAdhocChargeId: params.row.warehouseCustomerAdhocChargeId,
      chargeType: {
        value: params.row.chargeType,
        text: params.row.chargeTypeStr,
      },
      chargeName: params.row.chargeName,
      rate: params.row.rate,
    }));
    setIsAdhocChargeDelete(true);
    setIsHandlingChargeDelete(false);
    setIsStorageChargeDelete(false);
    onOpenConfirmDeleteDialog();
  };

  const billingAdhocChargesColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      hide: !handleUserPermissionAllow(UserPermissionEnum.Client_Billing_Edit),
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          disabled={!params.row.contextMenu_Edit_Enable}
          onClick={() => {
            onOpenBillingAdhocModal();
            setDataModalAdhocCharge((prev) => ({
              ...prev,
              warehouseCustomerAdhocChargeId:
                params.row.warehouseCustomerAdhocChargeId,
              chargeType: {
                value: params.row.chargeType,
                text: params.row.chargeTypeStr,
              },
              chargeName: params.row.chargeName,
              rate: params.row.rate,
              required: params.row.required,
              accountingCodeName: params.row.accountingCodeName,
            }));
            setIsAdhocChargeEdit(true);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          disabled={!params.row.contextMenu_Delete_Enable}
          onClick={() => handleDeleteBillingAdhoc(params)}
          showInMenu
        />,
      ],
    },
    {
      field: 'chargeName',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Charge Name</Typography>
        </Box>
      ),
    },
    {
      field: 'rate',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Rate</Typography>
        </Box>
      ),
    },
    {
      field: 'chargeTypeStr',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Charge Type</Typography>
        </Box>
      ),
    },
  ];

  const handleCopyDefaultFromCustomer = async () => {
    try {
      const payload = {
        customerId: parseInt(currentUser.Claim_CustomerId, 10),
        warehouseCustomerId: form.warehouseCustomerId,
      };

      const result = await copyDefaultWarehouseCustomer(payload);
      snackActions.success(
        `Warehouse customer ${result.name} has been successfully updated.`,
      );
      setIsViewForm(true);
      navigate(
        `/customers/warehouseCustomer?form=view&id=${form.warehouseCustomerId}`,
      );
      setIsCopyDefaultCustomerModalOpen(false);
      viewLoadData();
    } catch (err) {
      snackActions.error(err);
    }
  };

  const handleEditOrderSourceOnlick = (params) => {
    setOrderAIRuleDetail((e) => ({
      ...e,
      customerOrderSourceRuleId: params?.customerOrderSourceRuleId,
      orderSource: params?.orderSource,
      orderSourceDescription: params?.orderSourceDescription,
      orderSourceLongDescription: params?.orderSourceLongDescription,
      customerRuleManagementId: params?.customerRuleManagementId,

      //  Signature Option ----------------------------------------------
      signatureOptionUseDefault: params?.signatureOptionUseDefault,
      signatureOption: params?.signatureOption,
      signatureOptionOrderValue: params?.signatureOptionOrderValue,
      signatureType: params?.signatureType,
      signatureOptionOrderWeight: params?.signatureOptionOrderWeight,
      signatureTypeWeight: params?.signatureTypeWeight,

      // Carrier Option ----------------------------------------------
      carrierSelectionUseDefault: params?.carrierSelectionUseDefault,
      carrierSelection: params?.carrierSelection,

      carrierSelectionOrderValue: params?.carrierSelectionOrderValue,
      carrierSelectionOrderWeight: params?.carrierSelectionOrderWeight,

      // Insurance Option ----------------------------------------------
      insuranceOptionUseDefault: params?.insuranceOptionUseDefault,
      insuranceOption: params?.insuranceOption,
      insuranceOptionOrderValue: params?.insuranceOptionOrderValue,
      insuranceOptionOrderWeight: params?.insuranceOptionOrderWeight,

      // Print Carton Content Label -------------------------------------
      printCartonContentLabelUseDefault:
        params?.printCartonContentLabelUseDefault,
      printCartonContentLabel: params?.printCartonContentLabel,

      // Shipping Document Settings ------------------------------------
      shippingDocumentSettingsUseDefault:
        params?.shippingDocumentSettingsUseDefault,
      shippingDocumentSettings: params?.shippingDocumentSettings,
      printCartonContentLabelImage: params?.printCartonContentLabelImage,
      overrideShipFromAddress: params?.overrideShipFromAddress,
      shipFromName: params?.shipFromName,
      shipFromAddress: params?.shipFromAddress,
      shipFromCity: params?.shipFromCity,
      shipFromState: params?.shipFromState,
      shipFromZip: params?.shipFromZip,
      shipFromCountry: params?.shipFromCountry,
      overrideReturnFromAddress: params?.overrideReturnFromAddress,
      returnName: params?.returnName,
      returnAddress: params?.returnAddress,
      returnCity: params?.returnCity,
      returnState: params?.returnState,
      returnZip: params?.returnZip,
      returnCountry: params?.returnCountry,
      // Default Package -----------------------------------------------
      defaultPackageTypeUseDefault: params?.defaultPackageTypeUseDefault,
      defaultPackageType: params?.defaultPackageType,

      // Orders -------------------------------------------------------
      ordersUseDefault: params?.ordersUseDefault,
      internationalOrders: params?.internationalOrders,
      internationalOrdersDollarValue: params?.internationalOrdersDollarValue,
      autoholdAllOrders: params?.autoholdAllOrders,
      defaultOrderType: params?.defaultOrderType,

      // Return Orders ------------------------------------------------
      returnOrdersUseDefault: params?.returnOrdersUseDefault,
      acceptReturns: params?.acceptReturns,
      maxAllowedReturnDays: params?.maxAllowedReturnDays,
      overrideMaximumReturnDays: params?.overrideMaximumReturnDays,
      acceptReceiveInWhole: params?.acceptReceiveInWhole,

      itemMappingUseDefault: params?.itemMappingUseDefault,
      itemMappingSkus: params?.itemMappingSkus
        ? params.itemMappingSkus.map((i) => ({
            dataGridId: i?.dataGridId,
            customerRuleManagementSkuMappingId:
              i?.customerRuleManagementSkuMappingId,
            itemId: i?.itemId,
            sku: i?.sku,
            skuMapping: i?.skuMapping,
          }))
        : [],

      //  Shipment document
      shippingDocuments: params?.shippingDocuments
        ? params.shippingDocuments.map((i) => ({
            carrierId: i?.carrierId,
            value: i?.value,
            label: i?.label,
          }))
        : [],

      // CARRIER LIST
      carrierSelections: params?.carrierSelections
        ? params.carrierSelections.map((i) => ({
            carrierId: i?.carrierId,
            value: i?.value,
            label: i?.label,

            customerRuleManagementCarrierId: i?.customerRuleManagementCarrierId,
            carrierSelection: i?.carrierSelection,
            carrierSelectionId: i?.carrierSelectionId,
            carrierSelectionName: i?.carrierSelectionName,
            carrierSelectionService: i?.carrierSelectionService,
            carrierSelectionServiceId: i?.carrierSelectionServiceId,
            carrierSelectionServiceName: i?.carrierSelectionServiceName,
            carrierZipcode: i?.carrierZipcode,
            carrierAccountName: i?.carrierAccountName,
            carrierthirdPartyAccount: i?.carrierthirdPartyAccount,
          }))
        : [],

      carrierSelectionServices: params?.carrierSelectionServices
        ? params?.carrierSelectionServices.map((i) => ({
            carrierServiceId: i?.carrierServiceId,
            value: i?.value,
            label: i?.label,
          }))
        : [],
    }));

    handleEditViewOnlick();
    setIsShowRulesForm(true);
    setisRulesFormEditMode(true);
    setFormErrors({});
  };

  const handleAddOrderSourceOnlick = async () => {
    setOrderAIRuleDetail(orderAIRuleDetailForm);
    handleEditViewOnlick();
    setIsShowRulesForm(true);
    setisRulesFormEditMode(false);
    setFormErrors({});
  };

  const handleAddEditRulesManagementOnClick = async () => {
    handleEditViewOnlick();
    setIsManageClientDefault(true);
  };

  useEffect(() => {
    const optionsCarrier = getCarrierAccountByCustomer();
    optionsCarrier
      .then((opt) => {
        setCarrierTypesOption(
          opt.map((c: any) => ({
            value: c.carrierId,
            label: c.carrierName,
          })),
        );
      })
      .catch((err) => console.log(err));
  }, [currentUser.Claim_CustomerId]);

  useEffect(() => {
    viewLoadData();
  }, [carrierTypesOption, currentUser.Claim_CustomerId]);

  useEffect(() => {
    loadViewModel();

    if (queryString.id !== 'new') {
      setIsAddNew(false);
      if (queryString.form === 'view' && parseInt(queryString.id, 10) > 0) {
        setIsViewForm(true);
        setIsEditForm(false);
        onLoadSkuLookupWithwarehouseCustomer(queryString.id);
      } else if (
        queryString.form === 'edit' &&
        parseInt(queryString.id, 10) > 0
      ) {
        setIsViewForm(false);
        setIsEditForm(true);
        onLoadSkuLookupWithwarehouseCustomer(queryString.id);
      }
    }

    const billingFrequencies = [
      {
        id: 1,
        label: 'Daily',
      },
      {
        id: 2,
        label: 'Weekly',
      },
      {
        id: 3,
        label: 'Monthly',
      },
      {
        id: 4,
        label: 'Custom',
      },
    ];

    const billBasedOn = [
      {
        id: 1,
        label: 'Order Items',
      },
      {
        id: 2,
        label: 'Picked Items',
      },
    ];

    const whenOrderIsBilled = [
      {
        id: 1,
        label: 'After Order Shipped',
      },
      {
        id: 2,
        label: 'After Order Picked',
      },
    ];

    setOptionBillingFrequency(billingFrequencies);
    setBillBasedOnOptions(billBasedOn);
    setBillOrderAfterShippedOptions(whenOrderIsBilled);

    const selectedBillingFrequency = billingFrequencies.find((x) => x.id === 1);
    setBillingFrequencyValue(selectedBillingFrequency);

    if (isViewForm || isEditForm) {
      setIsLoadingData(true);
      viewLoadData();
    }
  }, [children]);

  const value = useMemo(
    () => ({
      billingData,
      billingStorageChargesColumns,
      billingHandlingChargesColumns,
      billingAdhocChargesColumns,
      countries,
      customerPortalAccount,
      customerPortalAccountColumns,
      customerPortalEmailInput,
      dataGridAdhocCharges,
      dataGridHandlingCharges,
      dataGridStorageCharges,
      dataModalAdhocCharge,
      dataModalHandlingCharge,
      dataModalStorageCharge,
      storageChargeSlidingRate,
      handlingChargeSlidingRate,
      dataSelected,
      dataSelectedDelete,
      deleteOtherContact,
      form,
      formErrors,
      imageData,
      imageDataText,
      inputUPSAccount,
      inputFedExAccount,
      isAddNew,
      isViewForm,
      isEditForm,
      isAdhocChargeEdit,
      isHandlingChargeEdit,
      isStorageChargeEdit,
      isAdhocChargeDelete,
      isHandlingChargeDelete,
      isStorageChargeDelete,
      notes,
      otherContacts,
      otherContactData,
      otherContactsColumns,
      queryString,
      services,
      carrierIntegrationType,
      shippoCarriers,
      shippingRateOptions,
      status,
      statusOptions,
      facility,
      facilityDDL,
      tabValue,
      emailToActivate,
      permissionWarehouseCustomerValue,
      userInfo,
      dataModalCustomerPortalUser,
      setDataModalCustomerPortalUser,
      setUserInfo,
      setPermissionWarehouseCustomerValue,
      setEmailToActivate,
      addUPSAccount,
      addFedexAccount,
      autoCompleteInputHandler,
      handleInputChangeUpsAccount,
      handleOnCarrierIntegrationType,
      handleOnCreateWarehouseCustomer,
      handleEditViewOnlick,
      handleOnKeyUpShippingAccount,
      handleOnCreateCustomerPortalAccount,
      handleTabOnChange,
      inputHandler,
      loadBillingData,
      uomChargeRates,
      setUOMChargeRates,
      selectedUOMChargeRate,
      setSelectedUOMChargeRate,
      isUOMChargeRateModal,
      setIsUOMChargeRateModal,
      loadCountries,
      loadCarriers,
      setForm,
      setFormErrors,
      setStatusOptions,
      setFacilityDDL,
      setCustomerPortalEmailInput,
      setStatus,
      setFacility,
      setNotes,
      setOtherContactData,
      setServices,
      setImageDataText,
      setImageData,
      setIsAdhocChargeEdit,
      setIsHandlingChargeEdit,
      setIsStorageChargeEdit,
      setDataSelected,
      setDataSelectedDelete,
      setDeleteOtherContact,
      setDataModalAdhocCharge,
      setDataModalHandlingCharge,
      setDataModalStorageCharge,
      setStorageChargeSlidingRate,
      setHandlingChargeSlidingRate,
      setShippingRatePreferences,
      setTabValue,
      validateOnlick,
      viewLoadData,
      billingFrequencyValue,
      setBillingFrequencyValue,
      selectedDayOfWeek,
      setSelectedDayOfWeek,
      billBasedOnValue,
      setBillBasedOnValue,
      billOrderAfterShipped,
      setBillOrderAfterShipped,
      optionBillingFrequency,
      setOptionBillingFrequency,
      billBasedOnOptions,
      billOrderAfterShippedOptions,
      loadExtensivIntegrations,
      setInvoiceDateDialog,
      invoiceDateDialog,
      setCarrierIntegrationType,
      extensivIntegrations,
      setExtensivIntegrationProps,
      setExtensivIntegrationDelete,
      extensivIntegrationProps,
      availableIntegrationOptions,
      setAddAvailableIntegration,
      addAvailableIntegration,
      extensivIntegrationDelete,
      xeroConnectionExists,
      setXeroConnectionExists,
      quickBooksConnectionExists,
      setQuickBooksConnectionExists,
      invoicesToBeSynced,
      loadInvoicesToBeSynced,
      initialFormErrorsState,
      setIsEditForm,
      setIsViewForm,
      handleCloseCustomerPortalAccountModal,
      loadShipStationConfiguration,
      optionLocation,
      carrierTypesOption,
      setCarrierTypesOption,
      handleCopyDefaultFromCustomer,
      skuMappingOption,
      setOrderAIRuleDetail,
      orderAIRuleDetail,
      orderAIRuleDetailForm,
      isShowRulesForm,
      setIsShowRulesForm,
      handleAddOrderSourceOnlick,
      handleEditOrderSourceOnlick,
      isRulesFormEditMode,
      isManageClientDefault,
      setIsManageClientDefault,
      handleAddEditRulesManagementOnClick,
      handleOnCarrierChange,
      handleOnCarrierChangeArrayList,
      carrierTypeServiceOptionShipping,
      carrierTypeServiceOptionCarrierType,
      handleOnDefaultPackageType,
      defaultPackgeTypeOption,
      setIsSetShopifyLocationModalOpen,
      isSetShopifyLocationModalOpen,
      selectedIntegrationId,
      setSelectedIntegrationId,
      shippingCarrierEditData,
      setShippingCarrierEditData,
      setIsViewLogsIntegrationModalOpen,
      isViewLogsIntegrationModalOpen,
      isSyncInventoryModalOpen,
      setIsSyncInventoryModalOpen,
      view,
      setView,
    }),
    [
      billingData,
      billingStorageChargesColumns,
      billingHandlingChargesColumns,
      billingAdhocChargesColumns,
      countries,
      customerPortalAccount,
      customerPortalAccountColumns,
      customerPortalEmailInput,
      dataGridAdhocCharges,
      dataGridHandlingCharges,
      dataGridStorageCharges,
      dataModalAdhocCharge,
      dataModalHandlingCharge,
      dataModalStorageCharge,
      storageChargeSlidingRate,
      handlingChargeSlidingRate,
      dataSelected,
      dataSelectedDelete,
      deleteOtherContact,
      form,
      formErrors,
      imageData,
      imageDataText,
      inputUPSAccount,
      inputFedExAccount,
      isAddNew,
      isViewForm,
      isEditForm,
      isAdhocChargeEdit,
      isHandlingChargeEdit,
      isStorageChargeEdit,
      isAdhocChargeDelete,
      isHandlingChargeDelete,
      isStorageChargeDelete,
      notes,
      otherContacts,
      otherContactData,
      otherContactsColumns,
      queryString,
      services,
      carrierIntegrationType,
      shippoCarriers,
      shippingRateOptions,
      status,
      statusOptions,
      facility,
      facilityDDL,
      tabValue,
      billingFrequencyValue,
      setBillingFrequencyValue,
      optionBillingFrequency,
      setOptionBillingFrequency,
      invoiceDateDialog,
      availableIntegrationOptions,
      addAvailableIntegration,
      extensivIntegrationDelete,
      xeroConnectionExists,
      setXeroConnectionExists,
      quickBooksConnectionExists,
      setQuickBooksConnectionExists,
      invoicesToBeSynced,
      loadInvoicesToBeSynced,
      emailToActivate,
      permissionWarehouseCustomerValue,
      userInfo,
      loadShipStationConfiguration,
      dataModalCustomerPortalUser,
      optionLocation,
      carrierTypesOption,
      skuMappingOption,
      orderAIRuleDetail,
      orderAIRuleDetailForm,
      isShowRulesForm,
      isRulesFormEditMode,
      isManageClientDefault,
      carrierTypeServiceOptionShipping,
      carrierTypeServiceOptionCarrierType,
      defaultPackgeTypeOption,
      setIsSetShopifyLocationModalOpen,
      isSetShopifyLocationModalOpen,
      selectedIntegrationId,
      setSelectedIntegrationId,
      shippingCarrierEditData,
      setIsViewLogsIntegrationModalOpen,
      isViewLogsIntegrationModalOpen,
      uomChargeRates,
      setUOMChargeRates,
      selectedUOMChargeRate,
      setSelectedUOMChargeRate,
      isUOMChargeRateModal,
      setIsUOMChargeRateModal,
      isSyncInventoryModalOpen,
      setIsSyncInventoryModalOpen,
      view,
      setView,
    ],
  );

  return (
    <WarehouseCustomerContext.Provider value={value}>
      {children}
    </WarehouseCustomerContext.Provider>
  );
};

const DetailsInterface = () => {
  const {
    autoCompleteInputHandler,
    countries,
    dataSelected,
    dataSelectedDelete,
    form,
    formErrors,
    inputHandler,
    imageDataText,
    isAddNew,
    isViewForm,
    isEditForm,
    otherContactData,
    otherContactsColumns,
    setDataSelected,
    setForm,
    setImageDataText,
    setStatus,
    setFacility,
    statusOptions,
    facilityDDL,
    setDeleteOtherContact,
    deleteOtherContact,
    loadCarriers,
    viewLoadData,
  } = useWarehouseCustomerContext();

  const {
    onOpenOtherContactModal,
    isShippoIntegrationModalOpen,
    isCarrierAccountModalOpen,
    isCarrierAccountDeleteModalOpen,
  } = useContext(GlobalContext);

  useEffect(() => {
    viewLoadData();
    if (
      !isShippoIntegrationModalOpen &&
      !isCarrierAccountModalOpen &&
      !isCarrierAccountDeleteModalOpen &&
      isEditForm
    ) {
      loadCarriers();
      setForm(() => ({
        ...form,
        selectedCarrierConnection: '',
        selectedCarrierId: 0,
      }));
    }
  }, [
    isShippoIntegrationModalOpen,
    isCarrierAccountModalOpen,
    isCarrierAccountDeleteModalOpen,
  ]);

  const countrySelectionOptions = Array.isArray(countries)
    ? countries.map((v, index) => ({
        id: v.countryId,
        label: v.name,
      }))
    : [{ id: 0, label: '' }];

  const handleOpenOtherContactModal = () => {
    setDataSelected({
      warehouseCustomerOtherContactId: '',
      name: '',
      title: '',
      email: '',
      phone: '',
      phone2: '',
      notes: '',
    });
    onOpenOtherContactModal();
  };

  const onImageChange = (event) => {
    const MIN_FILE_SIZE = 1024; // 1MB

    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size / 1024 > MIN_FILE_SIZE) {
        // eslint-disable-next-line no-alert
        window.alert('Please upload a file smaller than 1 MB');
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          const regex = /data:.*base64,/;
          setImageDataText(reader.result.toString().replace(regex, ''));
        };
        // setImageData(URL.createObjectURL(event.target.files[0]));
      }
    }
  };

  const handleResetDataSelected = () => {
    setDataSelected({
      warehouseCustomerOtherContactId: '',
      name: '',
      title: '',
      email: '',
      phone: '',
      phone2: '',
      notes: '',
    });
    setDeleteOtherContact(false);
  };

  const handleNotificationEmailChange = async (
    event: any,
    value: string[] | undefined,
  ) => {
    const invalidEmail = value.find(
      (email) => !GetEmailFormatValidation.test(email),
    );
    if (invalidEmail && invalidEmail.length > 0) {
      snackActions.warning(`${invalidEmail} is not a valid email.`);
      const values = value.filter((v) => v !== invalidEmail);
      setForm((prev) => ({
        ...prev,
        notificationEmails: values,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        notificationEmails: value,
      }));
    }
  };

  useEffect(() => {
    if (form.notificationEmails && form.notificationEmails.length > 0) {
      const noteEmails = [...form.notificationEmails];
      setForm((prev) => ({
        ...prev,
        notificationEmails: noteEmails,
      }));
    }
  }, [form.notificationEmails]);

  return (
    <>
      {deleteOtherContact && (
        <ConfirmationDialog
          deleteOtherContact
          otherContactData={dataSelectedDelete}
          callBack={() => handleResetDataSelected()}
        />
      )}

      {/* Details */}
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={8}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              border: '0.5px solid #ebeaea',
              margin: '15px !important',
              padding: '12px !important',
            }}
          >
            <Typography variant="subtitle1" fontWeight="bold">
              Details
            </Typography>

            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Input
                  placeholder="Company Name *"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.name}
                  error={formErrors.name}
                  onChange={(value) => inputHandler('name', value)}
                  disabled={isViewForm}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Autocomplete
                  disableClearable
                  sx={{ width: '100%' }}
                  options={statusOptions}
                  getOptionLabel={(option) =>
                    option.label ? option.label : ''
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  size="small"
                  value={form.status}
                  onChange={(
                    event: any,
                    value: AutoCompleteOptionType | null,
                  ) => {
                    autoCompleteInputHandler('status', value);
                  }}
                  disabled={isViewForm}
                  renderInput={(params) =>
                    formErrors.status ? (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        sx={{
                          '& .MuiInputBase-root': {
                            background: 'white',
                          },
                        }}
                        {...params}
                        label="Status *"
                        error
                        helperText={formErrors.status}
                      />
                    ) : (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        sx={{
                          '& .MuiInputBase-root': {
                            background: 'white',
                          },
                        }}
                        label="Status *"
                      />
                    )
                  }
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Autocomplete
                  value={form?.facility}
                  onChange={(
                    event,
                    newValue: CustomerFacilityLocationDropDownListItem,
                  ) => {
                    setForm((prevState) => ({
                      ...prevState,
                      facility: newValue,
                    }));
                  }}
                  id="controllable-states"
                  disabled={isViewForm}
                  options={facilityDDL}
                  getOptionLabel={(
                    option: CustomerFacilityLocationDropDownListItem,
                  ) => option.text}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(
                    props,
                    option: CustomerFacilityLocationDropDownListItem,
                  ) => (
                    <li {...props} key={option.value}>
                      <Box display="flex">
                        <Box display="flex" flexDirection="column">
                          <Typography color="text.primary">
                            {option.text}
                          </Typography>
                          <Typography color="text.secondary" variant="body2">
                            {option.customerLocation}
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      {...(formErrors?.facility && {
                        error: true,
                        helperText: formErrors?.facility,
                      })}
                      id="outlined-required"
                      label="Facility"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Input
                  placeholder="Street Address 1"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.streetAddress1}
                  error={formErrors.streetAddress1}
                  onChange={(value) => inputHandler('streetAddress1', value)}
                  disabled={isViewForm}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Input
                  placeholder="Street Address 2"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.streetAddress2}
                  error={formErrors.streetAddress2}
                  onChange={(value) => inputHandler('streetAddress2', value)}
                  disabled={isViewForm}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Input
                  placeholder="City/Township"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.cityTownship}
                  error={formErrors.cityTownship}
                  onChange={(value) => inputHandler('cityTownship', value)}
                  disabled={isViewForm}
                />
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Input
                  placeholder="State/Province/Region"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.stateProvinceRegion}
                  error={formErrors.stateProvinceRegion}
                  onChange={(value) =>
                    inputHandler('stateProvinceRegion', value)
                  }
                  disabled={isViewForm}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Input
                  placeholder="Zip/Postal Code"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.zipPostalCode}
                  onChange={(value) => inputHandler('zipPostalCode', value)}
                  disabled={isViewForm}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Autocomplete
                  disableClearable
                  sx={{ width: '100%' }}
                  options={countrySelectionOptions}
                  getOptionLabel={(option) =>
                    option.label ? option.label : ''
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  size="small"
                  value={form.country}
                  onChange={(
                    event: any,
                    value: AutoCompleteOptionType | null,
                  ) => {
                    autoCompleteInputHandler('country', value);
                  }}
                  disabled={isViewForm}
                  renderInput={(params) =>
                    formErrors.country ? (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        label="Country"
                        error
                        helperText={formErrors.country}
                      />
                    ) : (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        label="Country"
                        onChange={(event) => {
                          setStatus(event.target.value);
                        }}
                      />
                    )
                  }
                />
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Notes"
                  value={form.notes}
                  error={formErrors.notes}
                  onChange={(value) => inputHandler('notes', value)}
                  multiline
                  minRows={2}
                  sx={{ width: '100%' }}
                  size="small"
                  disabled={isViewForm}
                />
              </MUIGrid>
            </MUIGrid>
          </Card>
        </MUIGrid>
        {/* Primary Point of Contact */}
        <MUIGrid item xs={4} sx={{ paddingLeft: '0px !important' }}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              border: '0.5px solid #ebeaea',
              margin: '15px',
              marginLeft: '0px !important',
              padding: '12px !important',
            }}
          >
            <Typography variant="subtitle1" fontWeight="bold">
              Primary Point of Contact
            </Typography>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <Input
                  placeholder="Name *"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.contactName}
                  error={formErrors.contactName}
                  onChange={(value) => inputHandler('contactName', value)}
                  disabled={isViewForm}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Input
                  placeholder="Title *"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.contactTitle}
                  error={formErrors.contactTitle}
                  onChange={(value) => inputHandler('contactTitle', value)}
                  disabled={isViewForm}
                />
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <Input
                  placeholder="Email *"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.contactEmail}
                  error={formErrors.contactEmail}
                  onChange={(value) => inputHandler('contactEmail', value)}
                  disabled={isViewForm}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Input
                  placeholder="Phone *"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.contactPhone}
                  error={formErrors.contactPhone}
                  onChange={(value) => inputHandler('contactPhone', value)}
                  disabled={isViewForm}
                />
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Notes"
                  value={form.contactNotes}
                  error={formErrors.contactNotes}
                  onChange={(value) => inputHandler('contactNotes', value)}
                  multiline
                  minRows={2}
                  sx={{ width: '100%' }}
                  size="small"
                  disabled={isViewForm}
                />
              </MUIGrid>
            </MUIGrid>
          </Card>
        </MUIGrid>
      </MUIGrid>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          border: '0.5px solid #ebeaea',
          margin: '15px',
          marginTop: '0px !important',
          padding: '12px !important',
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          Client Services
        </Typography>
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={12}>
            <Autocomplete
              value={form.notificationEmails ?? []}
              multiple
              id="emails-n-filled"
              options={[]}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              onChange={handleNotificationEmailChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Notification Email"
                  placeholder="Add email here for notice"
                />
              )}
            />
          </MUIGrid>
        </MUIGrid>
      </Card>
      {/* Settings */}
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          border: '0.5px solid #ebeaea',
          margin: '15px',
          marginTop: '0px !important',
          padding: '12px !important',
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          Settings
        </Typography>
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={3}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Button
                  sx={{
                    height: '90px',
                    maxWidth: '100%',
                    width: '100px',
                    border: '2px dashed #4171ff !important',
                    backgroundColor: 'rgb(216 232 255) !important',
                    borderRadius: '5px !important',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                  }}
                  variant="contained"
                  component="label"
                  disabled={isViewForm}
                >
                  <UploadFileIcon
                    sx={{ fontSize: '40px', color: 'black !important', mb: 1 }}
                  />
                  <Typography
                    sx={{ fontSize: '7.5px', color: 'black !important' }}
                    fontWeight="bold"
                  >
                    Company Logo
                  </Typography>
                  <input
                    hidden
                    type="file"
                    onChange={onImageChange}
                    className="filetype"
                    accept="image/png, image/jpeg"
                  />
                </Button>
              </MUIGrid>
              <MUIGrid
                item
                xs={8}
                rowSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ paddingLeft: ' 5px important' }}
              >
                {imageDataText ? (
                  <input
                    disabled
                    type="image"
                    src={`data:image/jpeg;base64,${imageDataText}`}
                    alt="preview image"
                    width="86"
                    accept="image/*"
                  />
                ) : (
                  <>
                    <Typography fontWeight="bold" variant="subtitle2">
                      Upload Company Logo
                    </Typography>
                    <Typography sx={{ fontSize: 'x-small', color: 'red' }}>
                      (Only JPG & PNG)
                    </Typography>
                  </>
                )}
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
          <MUIGrid item xs={9}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              mb={1}
            >
              <MUIGrid item xs={3}>
                <Input
                  placeholder="Accounting Name"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.accountingName}
                  onChange={(value) => inputHandler('accountingName', value)}
                  disabled={isViewForm}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Input
                  placeholder="Accounting Client Name"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.accountingClientName}
                  onChange={(value) =>
                    inputHandler('accountingClientName', value)
                  }
                  disabled={isViewForm}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Input
                  placeholder="Manufacturer ID"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.manufacturerId}
                  error={formErrors.manufacturerId}
                  onChange={(value) => inputHandler('manufacturerId', value)}
                  disabled={isViewForm}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Input
                  placeholder="Client Interchange ID"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.customerInterchangeId}
                  error={formErrors.customerInterchangeId}
                  onChange={(value) =>
                    inputHandler('customerInterchangeId', value)
                  }
                  disabled={isViewForm}
                />
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={3}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Interchange X12 940/945
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Input
                  placeholder="3PL Interchange ID"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.threePLInterchangeId}
                  onChange={(value) =>
                    inputHandler('threePLInterchangeId', value)
                  }
                  disabled={isViewForm}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Input
                  placeholder="3PL Interchange ID Qualifier"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.threePLInterchangeIdQualifier}
                  onChange={(value) =>
                    inputHandler('threePLInterchangeIdQualifier', value)
                  }
                  disabled={isViewForm}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Input
                  placeholder="Client Interchange ID Qualifier"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={form.customerInterchangeIdQualifier}
                  onChange={(value) =>
                    inputHandler('customerInterchangeIdQualifier', value)
                  }
                  disabled={isViewForm}
                />
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
      </Card>
      {/* Other Contacts */}
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          border: '0.5px solid #ebeaea',
          margin: '15px',
          marginTop: '0px !important',
          padding: '12px !important',
        }}
      >
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Other Contacts
            </Typography>
          </MUIGrid>
          <MUIGrid item xs={6} sx={{ textAlignLast: 'right' }}>
            <Button
              variant="contained"
              size="small"
              disabled={isViewForm}
              onClick={() => handleOpenOtherContactModal()}
            >
              Add New
            </Button>
          </MUIGrid>
        </MUIGrid>
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={12}>
            {isAddNew ? (
              <DatagridStandard
                autoHeight
                headerHeight={120}
                rows={otherContactData}
                columns={otherContactsColumns}
                pageSize={15}
                density="compact"
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                hideFooterSelectedRowCount
                disableColumnMenu
                rowsPerPageOptions={[15]}
                onSelectionModelChange={(name) => {
                  const selectedRowData = otherContactData.filter(
                    (f) => f.name === name,
                  );
                  if (name === undefined) {
                    setDataSelected({
                      warehouseCustomerOtherContactId: '',
                      name: '',
                      title: '',
                      email: '',
                      phone: '',
                      phone2: '',
                      notes: '',
                    });
                  } else {
                    setDataSelected(selectedRowData[0]);
                  }
                }}
                selectionModel={dataSelected}
                getRowId={(row) => row.name}
              />
            ) : (
              <DatagridStandard
                autoHeight
                headerHeight={120}
                rows={otherContactData}
                columns={otherContactsColumns}
                pageSize={15}
                density="compact"
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                hideFooterSelectedRowCount
                disableColumnMenu
                rowsPerPageOptions={[15]}
                onSelectionModelChange={(warehouseCustomerOtherContactId) => {
                  const selectedId = warehouseCustomerOtherContactId[0];
                  const selectedRowData = otherContactData.filter(
                    (f) => f.warehouseCustomerOtherContactId === selectedId,
                  );
                  if (selectedId === undefined) {
                    setDataSelected({
                      warehouseCustomerOtherContactId: '',
                      name: '',
                      title: '',
                      email: '',
                      phone: '',
                      phone2: '',
                      notes: '',
                    });
                  } else {
                    setDataSelected(selectedRowData[0]);
                  }
                }}
                selectionModel={dataSelected}
                getRowId={(row) => row.warehouseCustomerOtherContactId}
              />
            )}
          </MUIGrid>
        </MUIGrid>
      </Card>
    </>
  );
};

const ShippingAccountsInterface = () => {
  const {
    onOpenShippoIntegrationModal,
    onOpenCarrierAccountModal,
    onOpenCarrierAccountDeleteModal,
    setIsShippingAccountEditModalOpen,
    setIsAddWarehouseCarrierModalOpen,
  } = useContext(GlobalContext);

  const { handleUserPermissionAllow, currentUser, isWarehouseCustomerAccount } =
    useContext(AuthContext);

  const {
    form,
    setForm,
    shippoCarriers,
    loadCarriers,
    setShippingCarrierEditData,
  } = useWarehouseCustomerContext();

  const deleteCarrierAccount = (carrierId) => {
    if (carrierId !== undefined) {
      setForm(() => ({
        ...form,
        selectedCarrierId: carrierId,
      }));
      onOpenCarrierAccountDeleteModal();
    }
  };

  const handleActivateCustomerAccount = (carrierId) => {
    enableDisableShippingAccount(carrierId).then((res) => {
      loadCarriers();
    });
  };

  const handleSetAccountBillable = (carrierId) => {
    setCarrierAccountBillable(carrierId).then((res) => {
      loadCarriers();
    });
  };

  const handleAddAllWarehouseCarriers = async () => {
    let warehouseCustomerId = form?.warehouseCustomerId || 0;

    if (isWarehouseCustomerAccount)
      warehouseCustomerId = currentUser.Claim_WarehouseCustomerId;

    try {
      await addAllWarehouseCarriers(warehouseCustomerId, false, '').then(
        async () => {
          loadCarriers();
        },
      );

      snackActions.success('All carriers has been successfully added');
    } catch (err) {
      snackActions.error(err);
    }
  };

  const shippingAccountColumns: GridColumns = [
    {
      field: 'carrierName',
      minWidth: 150,
      flex: 2,
      renderHeader: () => (
        <Box sx={{ gap: '5px', marginBottom: '0px !important' }}>
          <Typography fontWeight="bold">Carrier</Typography>
        </Box>
      ),
    },
    {
      hide: isWarehouseCustomerAccount,
      field: 'accountingName',
      minWidth: 150,
      flex: 2,
      renderHeader: () => (
        <Box sx={{ gap: '5px', marginBottom: '0px !important' }}>
          <Typography fontWeight="bold">Accounting Name</Typography>
        </Box>
      ),
    },
    {
      hide: isWarehouseCustomerAccount,
      field: 'noOfMarkup',
      minWidth: 90,
      flex: 0.5,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">No Of Markups</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row?.noOfMarkup || params.row?.noOfMarkup > 0
            ? params.row.noOfMarkup
            : ''}
        </Typography>
      ),
    },
    {
      hide: isWarehouseCustomerAccount,
      field: 'markupMethodTypeStr',
      minWidth: 90,
      flex: 0.5,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Markup Method</Typography>
        </Box>
      ),
    },
    {
      hide: isWarehouseCustomerAccount,
      field: 'markPercent',
      minWidth: 90,
      flex: 0.3,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Markup %</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row?.markPercent ? `${params.row.markPercent}%` : ''}
        </Typography>
      ),
    },
    {
      field: 'action',
      minWidth: 80,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Action</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Box sx={{ display: 'flex' }}>
          {!isWarehouseCustomerAccount && (
            <IconButton
              aria-label="edit"
              color="primary"
              sx={{ marginRight: '10px' }}
              disabled={
                !params.row?.active ||
                !handleUserPermissionAllow(
                  UserPermissionEnum.Client_ShippingAccounts_Edit,
                )
              }
              onClick={() => {
                setIsShippingAccountEditModalOpen(true);
                const shipping: IShippingCarrierEditModal = {
                  carrierId: params.row?.carrierId,
                  carrierName: params.row?.accountNickName,
                  markupMethod: {
                    value: params.row?.markupMethodType,
                    label: params.row?.markupMethodTypeStr,
                  },
                  billable: params.row?.billable,
                  markupPercent: params.row?.markPercent,
                  shippingCarrierLineItem: params.row?.carrierAndCarrierServices
                    ? params.row?.carrierAndCarrierServices.map((i) => ({
                        carrierAndCarrierServiceId:
                          i?.carrierAndCarrierServiceId,
                        carrierServices: i?.carrierServiceShippingAccounts
                          ? i.carrierServiceShippingAccounts.map((v) => ({
                              carrierServiceShippingAccountId:
                                v?.carrierServiceShippingAccountId,
                              value: v?.carrierServiceId,
                              label: v?.carrierServiceName,
                            }))
                          : [],
                        markupMethod: i?.markupMethodType
                          ? {
                              value: i.markupMethodType,
                              label: i.markupMethodTypeStr,
                            }
                          : null,
                        markupPercent: i?.markPercent,
                      }))
                    : [],
                };

                setShippingCarrierEditData(shipping);
              }}
            >
              <ModeEditIcon />
            </IconButton>
          )}
          <IconButton
            disabled={
              params.row?.isWarehouseAccount ||
              !params.row?.active ||
              !handleUserPermissionAllow(
                UserPermissionEnum.Client_ShippingAccounts_Edit,
              )
            }
            onClick={() => deleteCarrierAccount(params.row?.carrierId)}
            color="primary"
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      hide: isWarehouseCustomerAccount,
      field: 'enabled',
      minWidth: 80,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Enabled</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Switch
          checked={params.row?.active}
          disabled={
            !handleUserPermissionAllow(
              UserPermissionEnum.Client_ShippingAccounts_Edit,
            )
          }
          onChange={() => {
            handleActivateCustomerAccount(params.row?.carrierId);
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      hide: isWarehouseCustomerAccount,
      field: 'billable',
      minWidth: 180,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Billable</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Switch
          checked={params.row?.billable}
          onChange={() => {
            handleSetAccountBillable(params.row?.carrierId);
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isWarehouseCustomerAccount) {
      loadCarriers();
    }
  }, [currentUser]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        border: '0.5px solid #ebeaea',
        margin: '15px',
        padding: '12px !important',
      }}
    >
      <CarrierAccountModal />
      <CarrierAccountDeleteModal />
      <ShippingAccountEditModal />
      <AddWarehouseCarrierModal />

      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={6}>
          <Typography variant="body1" fontWeight="bold">
            Shipping Accounts
          </Typography>
        </MUIGrid>
        <MUIGrid
          item
          xs={6}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            variant="contained"
            onClick={() => {
              onOpenCarrierAccountModal();
            }}
            size="small"
            sx={{ marginRight: '10px', textTransform: 'capitalize' }}
            disabled={
              !handleUserPermissionAllow(
                UserPermissionEnum.Client_ShippingAccounts_Edit,
              )
            }
          >
            + Add Carrier
          </Button>
          {!isWarehouseCustomerAccount && (
            <>
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textTransform: 'capitalize',
                }}
                disabled={
                  !handleUserPermissionAllow(
                    UserPermissionEnum.Client_ShippingAccounts_Edit,
                  )
                }
              >
                Add Warehouse Carriers
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleAddAllWarehouseCarriers();
                  }}
                  disableRipple
                >
                  All Carriers
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsAddWarehouseCarrierModalOpen(true);
                  }}
                  disableRipple
                >
                  Select Carrier
                </MenuItem>
              </StyledMenu>
            </>
          )}
        </MUIGrid>
        <MUIGrid item xs={12}>
          <DatagridStandard
            autoHeight
            rows={shippoCarriers}
            density="compact"
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            columns={shippingAccountColumns}
            rowsPerPageOptions={[5]}
            pageSize={15}
            hideFooterSelectedRowCount
            getRowId={() => Math.random()}
          />
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

const CustomerPortalInterface = () => {
  const { setForm, customerPortalAccount, customerPortalAccountColumns } =
    useWarehouseCustomerContext();

  const { setIsCustomerPortalPermissionModalOpen } = useContext(GlobalContext);

  return (
    /* Customer Portal Accounts */
    <>
      <CustomerPortalDeleteModal />
      <UserPermissionsModal />
      <PermissionsModal />
      <MUIGrid
        container
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ padding: '20px', paddingBottom: '0px' }}
      >
        <MUIGrid item xs={4}>
          <Typography variant="subtitle1" fontWeight="bold">
            Client Portal Accounts
          </Typography>
        </MUIGrid>
        <MUIGrid item xs={8} sx={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setForm((prev) => ({
                ...prev,
                customerEmailPortal: '',
              }));
              setIsCustomerPortalPermissionModalOpen(true);
            }}
          >
            Activate New Account
          </Button>
        </MUIGrid>
      </MUIGrid>
      <MUIGrid
        container
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ padding: '5px 20px' }}
      >
        <MUIGrid item xs={12}>
          <DatagridStandard
            autoHeight
            rows={customerPortalAccount}
            density="compact"
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            columns={customerPortalAccountColumns}
            rowsPerPageOptions={[5]}
            pageSize={15}
            hideFooterSelectedRowCount
            getRowId={(row) => row.warehouseCustomerAccountId}
          />
        </MUIGrid>
      </MUIGrid>
    </>
  );
};

const BillingInterface = () => {
  const {
    billingStorageChargesColumns,
    billingHandlingChargesColumns,
    billingAdhocChargesColumns,
    dataGridAdhocCharges,
    dataGridHandlingCharges,
    dataGridStorageCharges,
    dataModalAdhocCharge,
    dataModalHandlingCharge,
    dataModalStorageCharge,
    isAdhocChargeDelete,
    isHandlingChargeDelete,
    isStorageChargeDelete,
    isViewForm,
    isEditForm,
    setIsStorageChargeEdit,
    setIsHandlingChargeEdit,
    setIsAdhocChargeEdit,
    setDataModalStorageCharge,
    setDataModalHandlingCharge,
    setDataModalAdhocCharge,
    setStorageChargeSlidingRate,
    setHandlingChargeSlidingRate,
    viewLoadData,
    billingFrequencyValue,
    billBasedOnValue,
    setBillBasedOnValue,
    billOrderAfterShipped,
    setBillOrderAfterShipped,
    selectedDayOfWeek,
    setSelectedDayOfWeek,
    setBillingFrequencyValue,
    optionBillingFrequency,
    setForm,
    form,
    formErrors,
    tabValue,
    queryString,
    billBasedOnOptions,
    billOrderAfterShippedOptions,
  } = useWarehouseCustomerContext();

  const {
    onOpenBillingStorageModal,
    onOpenBillingHandlingModal,
    onOpenBillingAdhocModal,
    onOpenBillingReportModal,
  } = useContext(GlobalContext);

  const { handleUserPermissionAllow } = useContext(AuthContext);
  const daysOfWeek = [
    { label: 'Sunday', id: 0 },
    { label: 'Monday', id: 1 },
    { label: 'Tuesday', id: 2 },
    { label: 'Wednesday', id: 3 },
    { label: 'Thursday', id: 4 },
    { label: 'Friday', id: 5 },
    { label: 'Saturday', id: 6 },
  ];

  useEffect(() => {
    if (isViewForm || isEditForm) {
      viewLoadData();
    }
  }, [isViewForm, isEditForm, tabValue]);

  return (
    <>
      {isAdhocChargeDelete && (
        <ConfirmationDialog
          billingAdhocChargeData={dataModalAdhocCharge}
          deleteBillingAdhoc
        />
      )}
      {isHandlingChargeDelete && (
        <ConfirmationDialog
          billingHandlingChargeData={dataModalHandlingCharge}
          deleteBillingHandling
        />
      )}
      {isStorageChargeDelete && (
        <ConfirmationDialog
          billingStorageChargeData={dataModalStorageCharge}
          deleteBillingStorage
        />
      )}
      <CopyBillingSetupModal />
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={6}>
          {/* Handling Charges */}
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              border: '0.5px solid #ebeaea',
              margin: '15px',
              padding: '12px !important',
            }}
          >
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Handling Charges
                </Typography>
              </MUIGrid>
              {handleUserPermissionAllow(
                UserPermissionEnum.Client_Billing_Edit,
              ) && (
                <>
                  <MUIGrid item xs={6} sx={{ textAlignLast: 'right' }}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        onOpenBillingHandlingModal();
                        setIsHandlingChargeEdit(false);
                        setDataModalHandlingCharge({
                          warehouseCustomerHandlingChargeId: 0,
                          chargeType: {
                            text: '',
                            value: -1,
                          },
                          chargeMethod: {
                            text: '',
                            value: -1,
                          },
                          rateOption: {
                            text: 'Fixed Rate',
                            value: 0,
                          },
                          rate: '',
                        });
                        setHandlingChargeSlidingRate([
                          {
                            fromQty: 0,
                            toQty: 0,
                            rate: '',
                          },
                        ]);
                      }}
                    >
                      Add Charge
                    </Button>
                  </MUIGrid>
                  <MUIGrid item xs={4}>
                    <Autocomplete
                      disableClearable
                      onChange={(event: any, newValue: BillingFrequency) => {
                        if (newValue !== null) {
                          setBillBasedOnValue(newValue);
                          setForm((prevState) => ({
                            ...prevState,
                            billBasedOnOrder: newValue.label === 'Order Items',
                          }));
                        } else {
                          setBillBasedOnValue(null);
                          setForm((prevState) => ({
                            ...prevState,
                            billBasedOnOrder: false,
                          }));
                        }
                      }}
                      id="controllable-states"
                      options={billBasedOnOptions}
                      getOptionLabel={(option: BillingFrequency) =>
                        option.label
                      }
                      value={billBasedOnValue}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          id="outlined"
                          label="Billing Based on"
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      )}
                      disabled={isViewForm}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={4}>
                    <Autocomplete
                      disableClearable
                      onChange={(event: any, newValue: BillingFrequency) => {
                        if (newValue !== null) {
                          setBillOrderAfterShipped(newValue);
                          setForm((prevState) => ({
                            ...prevState,
                            billOrderAfterShipped:
                              newValue.label === 'Order Items',
                          }));
                        } else {
                          setBillOrderAfterShipped(null);
                          setForm((prevState) => ({
                            ...prevState,
                            billOrderAfterShipped: false,
                          }));
                        }
                      }}
                      id="controllable-states"
                      options={billOrderAfterShippedOptions}
                      getOptionLabel={(option: BillingFrequency) =>
                        option.label
                      }
                      value={billOrderAfterShipped}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          id="outlined"
                          label="When order is billed?"
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      )}
                      disabled={isViewForm}
                    />
                  </MUIGrid>
                </>
              )}
              <MUIGrid item xs={12}>
                <DatagridStandard
                  autoHeight
                  rows={dataGridHandlingCharges}
                  columns={billingHandlingChargesColumns}
                  pageSize={15}
                  density="compact"
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  hideFooterSelectedRowCount
                  rowsPerPageOptions={[15]}
                  getRowId={(row) => row.warehouseCustomerHandlingChargeId}
                />
              </MUIGrid>
            </MUIGrid>
          </Card>

          {/* Adhoc Charges */}
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              border: '0.5px solid #ebeaea',
              margin: '15px',
              marginTop: '0px !important',
              padding: '12px !important',
            }}
          >
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Adhoc Charges
                </Typography>
              </MUIGrid>
              {handleUserPermissionAllow(
                UserPermissionEnum.Client_Billing_Edit,
              ) && (
                <MUIGrid item xs={6} sx={{ textAlignLast: 'right' }}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      onOpenBillingAdhocModal();
                      setIsAdhocChargeEdit(false);
                      setDataModalAdhocCharge({
                        warehouseCustomerAdhocChargeId: 0,
                        chargeName: '',
                        rate: '',
                        chargeType: {
                          text: '',
                          value: -1,
                        },
                      });
                    }}
                  >
                    Add Charge
                  </Button>
                </MUIGrid>
              )}
              <MUIGrid item xs={12}>
                <DatagridStandard
                  autoHeight
                  rows={dataGridAdhocCharges}
                  columns={billingAdhocChargesColumns}
                  pageSize={15}
                  density="compact"
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  hideFooterSelectedRowCount
                  rowsPerPageOptions={[15]}
                  getRowId={(row) => row.warehouseCustomerAdhocChargeId}
                />
              </MUIGrid>
            </MUIGrid>
          </Card>
        </MUIGrid>
        <MUIGrid item xs={6} sx={{ paddingLeft: '0px !important' }}>
          {/* Storage Charges */}
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              border: '0.5px solid #ebeaea',
              margin: '15px',
              padding: '12px !important',
              marginLeft: '0px !important',
            }}
          >
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid
                item
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={6} />

                <MUIGrid item xs={6} sx={{ textAlignLast: 'right' }}>
                  <Button
                    sx={{
                      width: '150px',
                    }}
                    variant="contained"
                    size="small"
                    disabled={queryString?.id === 'new'}
                    onClick={() => {
                      onOpenBillingReportModal();
                    }}
                  >
                    Billing Report
                  </Button>
                  {handleUserPermissionAllow(
                    UserPermissionEnum.Client_Billing_Edit,
                  ) && (
                    <Button
                      sx={{
                        width: '125px',
                        marginLeft: '15px',
                      }}
                      variant="contained"
                      size="small"
                      onClick={() => {
                        onOpenBillingStorageModal();
                        setIsStorageChargeEdit(false);
                        setDataModalStorageCharge({
                          warehouseCustomerStorageChargeId: 0,
                          chargeMethod: {
                            text: '',
                            value: -1,
                          },
                          rateOption: {
                            text: 'Fixed Rate',
                            value: 0,
                          },
                          rate: '',
                        });
                        setStorageChargeSlidingRate([
                          {
                            fromQty: 0,
                            toQty: 0,
                            rate: '',
                          },
                        ]);
                      }}
                    >
                      Add Charge
                    </Button>
                  )}
                </MUIGrid>
              </MUIGrid>

              <MUIGrid item xs={6}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Storage Charges
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Storage Billing Frequency
                </Typography>
              </MUIGrid>
              <MUIGrid
                item
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={6} />
                <MUIGrid item xs={6}>
                  <Autocomplete
                    disableClearable
                    onChange={(event: any, newValue: BillingFrequency) => {
                      if (newValue !== null) {
                        setBillingFrequencyValue(newValue);
                        if (newValue.id !== 4) {
                          setForm((prevState) => ({
                            ...prevState,
                            billingFrequency: newValue.id,
                            numberOfDays: '',
                          }));
                        } else {
                          setForm((prevState) => ({
                            ...prevState,
                            billingFrequency: newValue.id,
                          }));
                        }
                      } else {
                        setBillingFrequencyValue(null);
                      }
                    }}
                    id="controllable-states"
                    options={optionBillingFrequency}
                    getOptionLabel={(option: BillingFrequency) => option.label}
                    value={billingFrequencyValue}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        id="outlined"
                        label="Billing Frequency*"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                    disabled={isViewForm}
                  />
                </MUIGrid>
                {billingFrequencyValue?.id === 4 && (
                  <MUIGrid item xs={6}>
                    <Input
                      placeholder="Number of Days*"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      error={formErrors.numberOfDays}
                      value={form.numberOfDays}
                      onChange={(e) => {
                        if (e.target.value !== null) {
                          setForm((prevState) => ({
                            ...prevState,
                            numberOfDays: e.target.value,
                          }));
                        } else {
                          setForm((prevState) => ({
                            ...prevState,
                            numberOfDays: '',
                          }));
                        }
                      }}
                      disabled={isViewForm}
                    />
                  </MUIGrid>
                )}
                {billingFrequencyValue?.id === 2 && (
                  <MUIGrid item xs={6}>
                    <Autocomplete
                      disableClearable
                      onChange={(
                        event: any,
                        newValue: AutoCompleteOptionType,
                      ) => {
                        if (newValue !== null) {
                          setSelectedDayOfWeek(newValue);
                          setForm((prevState) => ({
                            ...prevState,
                            startOfWeek: newValue.id,
                          }));
                        } else {
                          setSelectedDayOfWeek(null);
                        }
                      }}
                      id="controllable-states"
                      options={daysOfWeek}
                      getOptionLabel={(option: AutoCompleteOptionType) =>
                        option.label
                      }
                      value={selectedDayOfWeek}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          error={formErrors.startOfWeek}
                          autoComplete="off"
                          id="outlined"
                          label="Start of Week"
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      )}
                      disabled={isViewForm}
                    />
                  </MUIGrid>
                )}
              </MUIGrid>

              <MUIGrid item xs={12}>
                <DatagridStandard
                  autoHeight
                  rows={dataGridStorageCharges}
                  columns={billingStorageChargesColumns}
                  pageSize={15}
                  density="compact"
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  hideFooterSelectedRowCount
                  rowsPerPageOptions={[15]}
                  getRowId={(row) => row.warehouseCustomerStorageChargeId}
                />
              </MUIGrid>
            </MUIGrid>
          </Card>
        </MUIGrid>
      </MUIGrid>
    </>
  );
};

export const IntegrationsInterface = (prop: {
  isShowQuickBookEhubXero?: boolean;
}) => {
  const {
    view,
    form,
    tabValue,
    formErrors,
    setTabValue,
    queryString,
    invoicesToBeSynced,
    xeroConnectionExists,
    extensivIntegrations,
    extensivIntegrationDelete,
    quickBooksConnectionExists,
    setView,
    loadInvoicesToBeSynced,
    setXeroConnectionExists,
    loadExtensivIntegrations,
    setSelectedIntegrationId,
    setAddAvailableIntegration,
    setExtensivIntegrationProps,
    loadShipStationConfiguration,
    setExtensivIntegrationDelete,
    setQuickBooksConnectionExists,
    setIsViewLogsIntegrationModalOpen,
  } = useWarehouseCustomerContext();

  const { currentUser, isWarehouseCustomerAccount, handleUserPermissionAllow } =
    useContext(AuthContext);
  const initialApiKeyForm = {
    apiKey: '',
    apiSecret: '',
    warehouseCustomerId: queryString?.id,
    isConnected: false,
    customerFacility: {
      label: '',
      value: undefined,
    },
  };

  type AutocompleteBase = {
    label?: string;
    value?: number;
    auxValue?: number;
  };

  const [shipStationForm, setShipStationForm] =
    useState<any>(initialApiKeyForm);

  const [easyPostForm, setEasyPostForm] = useState<any>(initialApiKeyForm);
  const [eHubForm, setEHubForm] = useState<any>(initialApiKeyForm);
  const [extensivCartRoverForm, setExtensivCartRoverForm] =
    useState<any>(initialApiKeyForm);
  const [optionsCustomerFacility, setOptionCustomerFacility] = useState<
    AutocompleteBase[] | null | []
  >([]);
  const [customerFacilitiesValue, setCustomerFacilitiesValue] =
    useState<AutocompleteBase>(null);

  const theme = useTheme();

  const loadEasyPostConfiguration = async () => {
    getEasyPostConfiguration().then((res) => {
      setEasyPostForm(() => ({
        ...easyPostForm,
        apiKey: res.apiKey,
        apiSecret: res.apiSecret,
        isConnected: res && res.apiKey !== '',
      }));
    });
  };

  const loadEHubConfiguration = async () => {
    getEHubConfiguration().then((res) => {
      setEHubForm(() => ({
        ...eHubForm,
        apiKey: res,
        isConnected: res && res !== '',
      }));
    });
  };

  const loadExtensivCartRoverConfiguration = async () => {
    getExtensivCartRoverConfiguration().then((res) => {
      setExtensivCartRoverForm(() => ({
        ...extensivCartRoverForm,
        apiKey: res.apiKey,
        apiSecret: res.apiSecret,
        isConnected: res && res.apiKey !== '',
      }));
    });
  };

  const viewloadForShipStationTab = async () => {
    loadExtensivIntegrations();
    loadEasyPostConfiguration();
    loadExtensivCartRoverConfiguration();

    getCustomerFacilitiesByCustomerId(
      form.customerId || currentUser.Claim_CustomerId,
    )
      .then((opt) => {
        setOptionCustomerFacility(
          opt.map((c: any) => ({
            label: c.name,
            value: c.customerFacilityId,
            auxValue: c.customerLocationId,
          })),
        );

        loadShipStationConfiguration().then((res) => {
          if (res) {
            const selectedFacility = opt.filter(
              (x) => x.customerFacilityId === res.customerFacilityId,
            )[0];

            const facilityValue = {
              label: selectedFacility.name,
              value: selectedFacility.customerFacilityId,
              auxValue: selectedFacility.customerLocationId,
            };

            setCustomerFacilitiesValue(facilityValue);
            setShipStationForm(() => ({
              ...shipStationForm,
              ecommerceIntegrationId: res.ecommerceIntegrationId,
              apiKey: res.apiKey,
              apiSecret: res.apiSecret,
              isConnected: res.apiKey !== '',
              customerFacility: facilityValue,
              isActive: res.isActive,
              isDisconnected: false,
            }));
          } else {
            setShipStationForm(() => ({
              ...shipStationForm,
              isDisconnected: true,
            }));
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (tabValue === '4' || isWarehouseCustomerAccount) {
      viewloadForShipStationTab();
      loadEHubConfiguration();
    }
  }, [tabValue]);

  const {
    onOpenIntegrationModal,
    isIntegrationModalOpen,
    onOpenConfirmDeleteDialog,
    setIsIntegrationLogsModalOpen,
    setExtensivIntegrationModalOpen,
    setIsExtensivEditOrderRulesModalOpen,
  } = useContext(GlobalContext);

  const [filteredList, setFilteredList] = useState([]);
  const [shopifyIntegrationList, setShopifyIntegrationList] = useState([]);
  const [isConnectedIntegrationDelete, setIsConnectedIntegrationDelete] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isIntegrationModalOpen) {
      getShopifyIntegrationByCustomerId(form.warehouseCustomerId).then(
        (res) => {
          setShopifyIntegrationList(res);
        },
      );
    }
  }, [isIntegrationModalOpen]);

  useEffect(() => {
    setFilteredList(extensivIntegrations || []);
  }, [extensivIntegrations]);

  const handleDeleteConnectIntegration = (params) => {
    setExtensivIntegrationDelete(params);
    setIsConnectedIntegrationDelete(true);
    onOpenConfirmDeleteDialog();
  };

  const handleConnectWithXeroClick = async () => {
    try {
      const response = await connectWithXero(queryString.id);

      if (response !== undefined && response.loginUrl !== undefined) {
        const url = `${response.loginUrl}?response_type=code&client_id=${response.clientId}&redirect_uri=${response.redirectUri}&scope=openid profile email files accounting.transactions accounting.contacts offline_access accounting.settings&state=${response.connectionIdentifier}`;
        window.open(url, '_blank');
        setXeroConnectionExists(true);
        snackActions.success('Connection succeeded.');
      } else {
        setXeroConnectionExists(false);
        snackActions.success('Account disconnected successfully.');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleConnectWithQuickBooks = async () => {
    try {
      const response = await connectWithQuickBooks(queryString.id);

      if (response !== undefined && response !== '') {
        window.open(response, '_blank');
        setQuickBooksConnectionExists(true);
        snackActions.success('Connection succeeded.');
      } else {
        setQuickBooksConnectionExists(false);
        snackActions.success('Account disconnected successfully.');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const pushIndividualInvoice = async (invoiceId) => {
    try {
      await pushIndividualInvoiceApi(invoiceId);
      loadInvoicesToBeSynced();
      snackActions.success(`Invoice pushed successfully.`);
    } catch (err) {
      snackActions.error(err);
      console.log(err);
    }
  };

  const extensivIntegrationColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      hide: !handleUserPermissionAllow(
        UserPermissionEnum.Client_Integration_Edit,
      ),
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit Source"
          showInMenu
          disabled={isWarehouseCustomerAccount}
          onClick={() => {
            setIsExtensivEditOrderRulesModalOpen(true);
            setExtensivIntegrationProps(params.row);
          }}
        />,
        <GridActionsCellItem
          label="Remove"
          showInMenu
          disabled={isWarehouseCustomerAccount}
          onClick={() => {
            handleDeleteConnectIntegration(params.row);
          }}
        />,
      ],
    },
    {
      field: 'name',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Name</Typography>
        </Box>
      ),
      renderCell: (params) => (
        // eslint-disable-next-line react/jsx-no-undef, jsx-a11y/anchor-is-valid
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href="#"
          onClick={() => {
            if (!isWarehouseCustomerAccount) {
              setIsExtensivEditOrderRulesModalOpen(true);
              setExtensivIntegrationProps(params.row);
            }
          }}
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.name}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'storeUrl',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Store Url</Typography>
        </Box>
      ),
    },
    {
      field: 'orderSource',
      minWidth: 100,
      flex: 2,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Source</Typography>
        </Box>
      ),
    },
  ];

  const disableIntegration = (integrationId) => {
    disableShopifyIntegration(integrationId, true).then((obj) => {
      getShopifyIntegrationByCustomerId(form.warehouseCustomerId).then(
        (res) => {
          snackActions.success('Integration disconnected.');
          setShopifyIntegrationList(res);
        },
      );
    });
  };

  const shopifyIntegrationColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      hide: !handleUserPermissionAllow(
        UserPermissionEnum.Client_Integration_Edit,
      ),
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          showInMenu
          onClick={(e) => {
            setSelectedIntegrationId(params.row.integrationId);
            onOpenIntegrationModal();
          }}
        />,
        <GridActionsCellItem
          label="Disconnect"
          showInMenu
          onClick={(e) => {
            disableIntegration(params.row.integrationId);
          }}
        />,
      ],
    },
    {
      field: 'storeUrl',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Store Url</Typography>
        </Box>
      ),
    },
    {
      field: 'orderSource',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Source</Typography>
        </Box>
      ),
    },
    {
      field: 'customerFacilityName',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Facility</Typography>
        </Box>
      ),
    },
  ];

  const invoicesToBeSyncedColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Push"
          showInMenu
          onClick={(e) => {
            pushIndividualInvoice(params.row.invoiceId);
          }}
        />,
      ],
    },
    {
      field: 'name',
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <Typography>{params.row.warehouseCustomerName}</Typography>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Name</Typography>
        </Box>
      ),
    },
    {
      field: 'invoiceDate',
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.invoiceCreatedDate).format('MM/DD/YYYY')}
        </Typography>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Invoice Date</Typography>
        </Box>
      ),
    },
    {
      field: 'invoiceQuantity',
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <Typography>{params.row.invoiceQuantity}</Typography>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Quantity</Typography>
        </Box>
      ),
    },
    {
      field: 'invoiceTotal',
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {params.row.invoiceTotal.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </Typography>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Amount</Typography>
        </Box>
      ),
    },
  ];

  const saveConfiguration = async (isFromToggle?, toggleValue?) => {
    try {
      if (shipStationForm.apiKey === '') {
        snackActions.error('Please inform the API Key');
        return;
      }

      if (shipStationForm.apiSecret === '') {
        snackActions.error('Please inform the API Secret');
        return;
      }

      if (shipStationForm.customerFacility.value === undefined) {
        snackActions.error('Please inform the Client Facility.');
        return;
      }

      if (isWarehouseCustomerAccount) {
        shipStationForm.warehouseCustomerId =
          currentUser.Claim_WarehouseCustomerId;
      }

      shipStationForm.customerFacilityId =
        shipStationForm.customerFacility.value;

      if (isFromToggle === true) {
        shipStationForm.isActive = toggleValue;
      }

      await createShipStationConfiguration(shipStationForm);

      if (isFromToggle === true) {
        if (toggleValue === true) {
          snackActions.success('ShipStation is now Enabled.');
        } else {
          snackActions.success('ShipStation is now Disabled.');
        }
      } else {
        snackActions.success('Successfully saved.');
      }
      viewloadForShipStationTab();
    } catch (error) {
      snackActions.error(error);
    }
  };

  const saveEasyPostConfiguration = async () => {
    try {
      if (easyPostForm.apiKey === '') {
        snackActions.error('Please inform the API Key');
        return;
      }

      await createEasyPostConfiguration(easyPostForm);
      setEasyPostForm(() => ({
        ...easyPostForm,
        isConnected: true,
      }));
      snackActions.success('Successfully saved.');
    } catch (error) {
      snackActions.error(error);
    }
  };

  const saveEHubConfiguration = async () => {
    try {
      if (eHubForm.apiKey === '') {
        snackActions.error('Please inform the API Key');
        return;
      }

      await createEHubConfiguration(eHubForm);
      setEHubForm(() => ({
        ...eHubForm,
        isConnected: true,
      }));
      snackActions.success('Successfully saved.');
    } catch (error) {
      snackActions.error(error);
    }
  };

  const saveExtensivCartRoverConfiguration = async () => {
    try {
      if (extensivCartRoverForm.apiKey === '') {
        snackActions.error('Please inform the API Key');
        return;
      }

      if (extensivCartRoverForm.apiSecret === '') {
        snackActions.error('Please inform the API Secret');
        return;
      }

      await createExtensivCartRoverConfiguration(extensivCartRoverForm);
      setExtensivCartRoverForm(() => ({
        ...extensivCartRoverForm,
        isConnected: true,
      }));
      snackActions.success('Successfully saved.');
    } catch (error) {
      snackActions.error(error);
    }
  };

  const disconnectEasyPostConfiguration = async () => {
    try {
      await disconnectConfiguration();
      snackActions.success('Successfully disconnected.');
      setEasyPostForm(initialApiKeyForm);
    } catch (err) {
      snackActions.error(err);
    }
  };

  const disconnectEHubConfiguration = async () => {
    try {
      await disconnectEhubConfigurationApi();
      snackActions.success('Successfully disconnected.');
      setEHubForm(initialApiKeyForm);
    } catch (err) {
      snackActions.error(err);
    }
  };

  const handleDisconnectExtensivCartRoverConfiguration = async () => {
    try {
      await disconnectExtensivCartRoverConfiguration();
      snackActions.success('Successfully disconnected.');
      setExtensivCartRoverForm(initialApiKeyForm);
    } catch (err) {
      snackActions.error(err);
    }
  };

  const handleSyncClick = async () => {
    try {
      await inventorySyncUp();
      snackActions.success(`Clients synced successfully.`);
      snackActions.success(`Inventory synced successfully.`);
      await pushInvoices();
      loadInvoicesToBeSynced();
      snackActions.success(`Invoices synced successfully.`);
    } catch (err) {
      snackActions.error(err);
      console.log(err);
    }
  };

  const [loading, setLoading] = useState(false);

  const handleShipStationSyncOrders = async (e) => {
    e.preventDefault();

    if (shipStationForm?.ecommerceIntegrationId) {
      const response = await syncOrderShipStationManually(
        shipStationForm.ecommerceIntegrationId,
      );
      const { status, data } = response;
      if (status === 200) {
        snackActions.success(`ShipStation orders are downloading...`);
      } else {
        snackActions.error('The synchronization of orders has failed.');
      }
    } else {
      snackActions.error('ShipStation not found!');
    }
    setLoading(false);
  };

  const loadCheckQuickBooksConnectionExists = async () => {
    try {
      const response = await checkQuickBooksConnectionExists(
        queryString?.id || 0,
      );
      setQuickBooksConnectionExists(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    switch (view.toLowerCase()) {
      case 'shopify':
        getShopifyIntegrationByCustomerId(
          form.warehouseCustomerId ?? currentUser.Claim_WarehouseCustomerId,
        ).then((res) => {
          setShopifyIntegrationList(res);
        });
        break;
      case 'quickbooks':
        if (!isWarehouseCustomerAccount) {
          loadCheckQuickBooksConnectionExists();
          loadInvoicesToBeSynced();
        }
        break;
      case 'ehub':
        loadEHubConfiguration();
        break;
      default:
        break;
    }
  }, [view]);

  function quickBooksInterface() {
    return (
      <Box
        sx={{
          padding: '12px !important',
        }}
      >
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {/* <MUIGrid item xs={3}>
            <Typography variant="body1" fontWeight="bold">
              Quick Books
            </Typography>
          </MUIGrid> */}

          {handleUserPermissionAllow(
            UserPermissionEnum.Client_Integration_Edit,
          ) && (
            <MUIGrid item xs={12} sx={{ textAlignLast: 'right' }}>
              <Chip
                sx={{ marginRight: '20px' }}
                className={
                  !quickBooksConnectionExists
                    ? 'statusRedChip'
                    : 'statusGreenChip'
                }
                label={
                  !quickBooksConnectionExists ? 'Not Connected' : 'Connected'
                }
              />
              {quickBooksConnectionExists && queryString.id == null && (
                <Button
                  variant="outlined"
                  size="medium"
                  sx={{
                    boxShadow: 'none !important',
                    marginRight: '15px',
                  }}
                  onClick={() => {
                    handleSyncClick();
                  }}
                >
                  Sync
                </Button>
              )}
              <Button
                variant={!quickBooksConnectionExists ? 'contained' : 'outlined'}
                size="medium"
                sx={{
                  boxShadow: 'none !important',
                }}
                onClick={() => {
                  handleConnectWithQuickBooks();
                }}
              >
                {!quickBooksConnectionExists ? 'Connect' : 'Disconnect'}
              </Button>
              {quickBooksConnectionExists && queryString.id == null && (
                <Button
                  variant="outlined"
                  size="medium"
                  sx={{
                    boxShadow: 'none !important',
                    marginLeft: '15px',
                  }}
                  onClick={() => {
                    setIsIntegrationLogsModalOpen(true);
                  }}
                >
                  View Logs
                </Button>
              )}
            </MUIGrid>
          )}
        </MUIGrid>
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={1}
        >
          <MUIGrid item xs={12}>
            {quickBooksConnectionExists && queryString.id == null && (
              <DatagridStandard
                autoHeight
                headerHeight={120}
                rows={invoicesToBeSynced}
                columns={invoicesToBeSyncedColumns}
                pageSize={15}
                density="compact"
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                hideFooterSelectedRowCount
                rowsPerPageOptions={[15]}
                getRowId={() => Math.random()}
              />
            )}
          </MUIGrid>
        </MUIGrid>
      </Box>
    );
  }

  function shipStationInteface() {
    return (
      <Box
        sx={{
          margin: queryString.id == null ? '15px' : '0px',
          marginTop: queryString.id == null ? '15px' : '0px',
          padding: '12px !important',
        }}
      >
        <MUIGrid
          container
          spacing={2}
          sx={{ alignItems: 'center' }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={3}>
            <Typography variant="body1" fontWeight="bold">
              Ship Station
            </Typography>
          </MUIGrid>

          {handleUserPermissionAllow(
            UserPermissionEnum.Client_Integration_Edit,
          ) && (
            <MUIGrid
              item
              xs={9}
              sx={{
                justifyContent: 'right',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Chip
                sx={{ marginRight: '20px' }}
                className={
                  shipStationForm?.isDisconnected === false
                    ? 'statusGreenChip'
                    : 'statusRedChip'
                }
                label={
                  shipStationForm?.isDisconnected === false
                    ? 'Connected'
                    : 'Not Connected'
                }
              />
              <Button
                type="submit"
                variant="contained"
                size="small"
                onClick={() => {
                  saveConfiguration();
                }}
              >
                Save
              </Button>
            </MUIGrid>
          )}
        </MUIGrid>
        <MUIGrid
          container
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={1}
        >
          <MUIGrid item xs={4}>
            <TextField
              label="API Key"
              size="small"
              type="password"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={shipStationForm.apiKey}
              onChange={(value) =>
                setShipStationForm(() => ({
                  ...shipStationForm,
                  apiKey: value.target.value,
                }))
              }
            />
          </MUIGrid>
          <MUIGrid item xs={4}>
            <TextField
              label="API Secret"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              type="password"
              value={shipStationForm.apiSecret}
              onChange={(value) =>
                setShipStationForm(() => ({
                  ...shipStationForm,
                  apiSecret: value.target.value,
                }))
              }
            />
          </MUIGrid>
          <MUIGrid item xs={4}>
            <Autocomplete
              onChange={(event: any, newValue: AutocompleteBase) => {
                if (newValue !== null) {
                  setCustomerFacilitiesValue(newValue);
                  setShipStationForm(() => ({
                    ...shipStationForm,
                    customerFacility: newValue,
                  }));
                } else {
                  setCustomerFacilitiesValue(null);
                  setShipStationForm(() => ({
                    ...shipStationForm,
                    customerFacility: { label: '', value: undefined },
                  }));
                }
              }}
              id="controllable-states"
              options={optionsCustomerFacility}
              getOptionLabel={(option: AutocompleteBase) => option.label}
              value={customerFacilitiesValue}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              renderOption={(props, option) => (
                <li
                  data-name="customerFacilityId"
                  {...props}
                  key={option.value}
                >
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  {...(formErrors.customer && {
                    error: true,
                    helperText: formErrors.customer,
                  })}
                  id="outlined-required"
                  label="Client Facility"
                  size="small"
                  sx={{ width: '100%' }}
                />
              )}
            />
          </MUIGrid>

          <MUIGrid item xs={12}>
            <MUIGrid
              container
              direction="row"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <MUIGrid
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid
                    item
                    xs={6}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 700, marginRight: '20px' }}
                    >
                      Enabled
                    </Typography>
                    <Switch
                      checked={shipStationForm?.isActive}
                      sx={{
                        marginRight: '20px',
                      }}
                      onChange={(v) => {
                        setShipStationForm((e) => ({
                          ...e,
                          isActive: v.target.checked,
                        }));
                        saveConfiguration(true, v.target.checked);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={shipStationForm?.isDisconnected === true}
                    />
                  </MUIGrid>
                </MUIGrid>
                <Typography variant="caption">
                  (If disabled, no order will be downloaded and no quantity will
                  sync)
                </Typography>
              </MUIGrid>
              <MUIGrid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 700, marginRight: '20px' }}
                >
                  Sync Orders
                </Typography>

                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    width: '20%',
                    textTransform: 'none',
                    padding: '15px',
                  }}
                  onClick={(e) => {
                    setLoading(true);
                    handleShipStationSyncOrders(e);
                  }}
                  disabled={shipStationForm?.isActive !== true || loading}
                >
                  {loading && <CircularProgress />} Sync Orders
                </Button>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
      </Box>
    );
  }

  function extensivCartRoverInterface() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: queryString.id == null ? '15px' : '0px',
          marginTop: queryString.id == null ? '15px' : '0px',
          padding: '12px !important',
        }}
      >
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={3}>
            <Typography variant="body1" fontWeight="bold">
              CartRover
            </Typography>
          </MUIGrid>
          {handleUserPermissionAllow(
            UserPermissionEnum.Client_Integration_Edit,
          ) && (
            <MUIGrid item xs={9} sx={{ textAlignLast: 'right' }}>
              <Chip
                sx={{ marginRight: '20px' }}
                className={
                  !extensivCartRoverForm.isConnected
                    ? 'statusRedChip'
                    : 'statusGreenChip'
                }
                label={
                  !extensivCartRoverForm.isConnected
                    ? 'Not Connected'
                    : 'Connected'
                }
              />
              <Button
                type="submit"
                variant={
                  !extensivCartRoverForm.isConnected ? 'contained' : 'outlined'
                }
                size="small"
                onClick={() => {
                  saveExtensivCartRoverConfiguration();
                }}
              >
                {!extensivCartRoverForm.isConnected ? 'Connect' : 'Update'}
              </Button>
              {extensivCartRoverForm.isConnected && (
                <Button
                  type="submit"
                  variant="outlined"
                  size="small"
                  sx={{ marginLeft: '15px' }}
                  onClick={() => {
                    handleDisconnectExtensivCartRoverConfiguration();
                  }}
                >
                  Disconnect
                </Button>
              )}
            </MUIGrid>
          )}
        </MUIGrid>
        <MUIGrid
          container
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={1}
        >
          <MUIGrid item xs={6}>
            <Input
              placeholder="API User"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={extensivCartRoverForm.apiKey}
              onChange={(value) =>
                setExtensivCartRoverForm(() => ({
                  ...extensivCartRoverForm,
                  apiKey: value.target.value,
                }))
              }
            />
          </MUIGrid>
          <MUIGrid item xs={6}>
            <Input
              placeholder="API Key"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={extensivCartRoverForm.apiSecret}
              onChange={(value) =>
                setExtensivCartRoverForm(() => ({
                  ...extensivCartRoverForm,
                  apiSecret: value.target.value,
                }))
              }
            />
          </MUIGrid>
        </MUIGrid>
      </Box>
    );
  }

  function easyPostInteface() {
    return (
      <Box
        sx={{
          margin: queryString.id == null ? '15px' : '0px',
          marginTop: queryString.id == null ? '15px' : '0px',
          padding: '12px !important',
        }}
      >
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={3}>
            <Typography variant="body1" fontWeight="bold">
              EasyPost
            </Typography>
          </MUIGrid>

          <MUIGrid item xs={9} sx={{ textAlignLast: 'right' }}>
            <Chip
              sx={{ marginRight: '20px' }}
              className={
                !easyPostForm.isConnected ? 'statusRedChip' : 'statusGreenChip'
              }
              label={!easyPostForm.isConnected ? 'Not Connected' : 'Connected'}
            />
            <Button
              type="submit"
              variant={!easyPostForm.isConnected ? 'contained' : 'outlined'}
              size="small"
              onClick={() => {
                saveEasyPostConfiguration();
              }}
            >
              {!easyPostForm.isConnected ? 'Connect' : 'Update'}
            </Button>
            {easyPostForm.isConnected && (
              <Button
                type="submit"
                variant="outlined"
                size="small"
                sx={{ marginLeft: '10px' }}
                onClick={() => {
                  disconnectEasyPostConfiguration();
                }}
              >
                Disconnect
              </Button>
            )}
          </MUIGrid>
        </MUIGrid>
        <MUIGrid
          container
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={6}>
            <Input
              placeholder="API Key"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={easyPostForm.apiKey}
              onChange={(value) =>
                setEasyPostForm(() => ({
                  ...easyPostForm,
                  apiKey: value.target.value,
                }))
              }
            />
          </MUIGrid>
        </MUIGrid>
      </Box>
    );
  }

  function eHubInteface() {
    return (
      <Box
        sx={{
          margin: queryString.id == null ? '15px' : '0px',
          marginTop: queryString.id == null ? '15px' : '0px',
          padding: '12px !important',
        }}
      >
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={3}>
            <Typography variant="body1" fontWeight="bold">
              eHub
            </Typography>
          </MUIGrid>

          <MUIGrid item xs={9} sx={{ textAlignLast: 'right' }}>
            <Chip
              sx={{ marginRight: '20px' }}
              className={
                !eHubForm.isConnected ? 'statusRedChip' : 'statusGreenChip'
              }
              label={!eHubForm.isConnected ? 'Not Connected' : 'Connected'}
            />
            <Button
              type="submit"
              variant={!eHubForm.isConnected ? 'contained' : 'outlined'}
              size="small"
              onClick={() => {
                saveEHubConfiguration();
              }}
            >
              {!eHubForm.isConnected ? 'Connect' : 'Update'}
            </Button>
            {eHubForm.isConnected && (
              <Button
                type="submit"
                variant="outlined"
                size="small"
                sx={{ marginLeft: '10px' }}
                onClick={() => {
                  disconnectEHubConfiguration();
                }}
              >
                Disconnect
              </Button>
            )}
          </MUIGrid>
        </MUIGrid>
        <MUIGrid
          container
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={6}>
            <Input
              placeholder="API Key"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={eHubForm.apiKey}
              onChange={(value) =>
                setEHubForm(() => ({
                  ...eHubForm,
                  apiKey: value.target.value,
                }))
              }
            />
          </MUIGrid>
        </MUIGrid>
      </Box>
    );
  }

  function xeroInterface() {
    return (
      <Box
        sx={{
          display: 'flex',
          padding: '12px !important',
          justifyContent: 'center',
        }}
      >
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ placeItems: 'center' }}
        >
          <MUIGrid item xs={4}>
            <Typography variant="body1" fontWeight="bold">
              Xero
            </Typography>
          </MUIGrid>
          {handleUserPermissionAllow(
            UserPermissionEnum.Client_Integration_Edit,
          ) && (
            <MUIGrid item xs={8} sx={{ textAlignLast: 'right' }}>
              <Chip
                sx={{ marginRight: '20px' }}
                className={
                  !xeroConnectionExists ? 'statusRedChip' : 'statusGreenChip'
                }
                label={!xeroConnectionExists ? 'Not Connected' : 'Connected'}
              />
              <Button
                variant={!xeroConnectionExists ? 'contained' : 'outlined'}
                size="medium"
                sx={{ boxShadow: 'none !important' }}
                onClick={() => {
                  handleConnectWithXeroClick();
                }}
              >
                {!xeroConnectionExists ? 'Connect Xero' : 'Disconnect Xero'}
              </Button>
            </MUIGrid>
          )}
        </MUIGrid>
      </Box>
    );
  }

  function shopifyInteface() {
    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          border: '0.5px solid #ebeaea',
          margin: '15px',
          marginLeft: '0px !important',
          padding: '12px !important',
        }}
      >
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={3}>
            <Typography variant="subtitle1" fontWeight="bold">
              Shopify
            </Typography>
          </MUIGrid>

          <MUIGrid item xs={9} sx={{ textAlignLast: 'right' }}>
            <Button
              type="submit"
              variant={
                !form?.integrationAccessToken && !form.storeUrl
                  ? 'contained'
                  : 'outlined'
              }
              size="small"
              onClick={() => {
                setIsViewLogsIntegrationModalOpen(true);
              }}
            >
              View Logs
            </Button>
            {handleUserPermissionAllow(
              UserPermissionEnum.Client_Integration_Edit,
            ) && (
              <Button
                type="submit"
                variant={
                  !form?.integrationAccessToken && !form.storeUrl
                    ? 'contained'
                    : 'outlined'
                }
                sx={{ marginLeft: '25px' }}
                size="small"
                onClick={() => {
                  onOpenIntegrationModal();
                }}
              >
                Add New Connection
              </Button>
            )}
          </MUIGrid>
        </MUIGrid>
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={12}>
            <DatagridStandard
              autoHeight
              rows={shopifyIntegrationList}
              columns={shopifyIntegrationColumns}
              pageSize={15}
              density="compact"
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              hideFooterSelectedRowCount
              rowsPerPageOptions={[15]}
              getRowId={() => Math.random()}
            />
          </MUIGrid>
        </MUIGrid>
      </Card>
    );
  }

  function alereInterface() {
    const handleRumJobsClick = async () => {
      try {
        await triggerAlereJobs();
        snackActions.success('Jobs are running.');
      } catch (err) {
        snackActions.error(err);
      }
    };

    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          border: '0.5px solid #ebeaea',
          margin: '15px',
          marginLeft: '0px !important',
          padding: '12px !important',
        }}
      >
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={3}>
            <Typography variant="subtitle1" fontWeight="bold">
              Alere
            </Typography>
          </MUIGrid>

          <MUIGrid item xs={9} sx={{ textAlignLast: 'right' }}>
            <Button
              type="submit"
              variant={
                !form?.integrationAccessToken && !form.storeUrl
                  ? 'contained'
                  : 'outlined'
              }
              size="small"
              onClick={() => {
                setIsViewLogsIntegrationModalOpen(true);
              }}
            >
              View Logs
            </Button>

            <Button
              type="submit"
              variant="contained"
              size="small"
              sx={{
                marginLeft: '10px',
              }}
              onClick={() => {
                handleRumJobsClick();
              }}
            >
              Run Jobs
            </Button>
          </MUIGrid>
        </MUIGrid>
      </Card>
    );
  }

  return (
    <>
      {isConnectedIntegrationDelete && (
        <ConfirmationDialog
          connectedIntegrationData={extensivIntegrationDelete}
          deleteConnectedIntegration
        />
      )}
      <ExtensivEditOrderRuleIntegrationModal />
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={12}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              border: '0.5px solid #ebeaea',
              margin: '15px',
              padding: '12px !important',
            }}
          >
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  Connected Integrations
                </Typography>
              </MUIGrid>
              {handleUserPermissionAllow(
                UserPermissionEnum.Client_Integration_Edit,
              ) && (
                <MUIGrid item xs={6} sx={{ textAlignLast: 'right' }}>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ boxShadow: 'none !important' }}
                    onClick={() => {
                      setExtensivIntegrationProps(null);
                      setAddAvailableIntegration(true);
                      setExtensivIntegrationModalOpen(true);
                    }}
                  >
                    Add New Integration
                  </Button>
                </MUIGrid>
              )}
              <MUIGrid item xs={12}>
                <DatagridStandard
                  autoHeight
                  rows={filteredList}
                  columns={extensivIntegrationColumns}
                  pageSize={15}
                  density="compact"
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  hideFooterSelectedRowCount
                  rowsPerPageOptions={[15]}
                  getRowId={() => Math.random()}
                />
              </MUIGrid>
            </MUIGrid>
          </Card>
        </MUIGrid>
      </MUIGrid>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={12}>
          <CardTab
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0px !important',
              margin: '21px',
              marginBottom: 1,
              overflow: 'auto',
              borderRadius: 2,
              border: '1px solid #cdcdcd',
            }}
            tabValue={view}
            handleChange={(event, newValue) => setView(newValue)}
            tabView={[
              {
                title: 'Quick Books',
                value: 'quickBooks',
                // eslint-disable-next-line react/destructuring-assignment
                isDisplay: prop.isShowQuickBookEhubXero === true,
                children: quickBooksInterface(),
              },
              {
                title: (
                  <ChannelIcon type="shipstation" withText="Ship Station" />
                ),
                value: 'shipStation',
                isDisplay: true,
                children: shipStationInteface(),
              },
              {
                title: 'Xero',
                value: 'xero',
                // eslint-disable-next-line react/destructuring-assignment
                isDisplay: prop.isShowQuickBookEhubXero === true,
                children: xeroInterface(),
              },
              {
                title: <ChannelIcon type="shopify" withText="Shopify" />,
                value: 'shopify',
                isDisplay: true,
                children: shopifyInteface(),
              },
              {
                title: 'EasyPost',
                value: 'easyPost',
                isDisplay: !isWarehouseCustomerAccount,
                children: easyPostInteface(),
              },
              {
                title: 'CartRover',
                value: 'cartRover',
                isDisplay: !isWarehouseCustomerAccount,
                children: extensivCartRoverInterface(),
              },
              {
                title: 'Alere',
                value: 'alere',
                isDisplay:
                  currentUser.Claim_CustomerName.toLowerCase() === 'cibao meat',
                children: alereInterface(),
              },
              {
                title: 'eHub',
                value: 'eHub',
                // eslint-disable-next-line react/destructuring-assignment
                isDisplay: prop.isShowQuickBookEhubXero === true,
                children: eHubInteface(),
              },
            ]}
          />
        </MUIGrid>
      </MUIGrid>
    </>
  );
};

export function WarehouseCustomerContainer() {
  const {
    form,
    setForm,
    tabValue,
    isViewForm,
    formErrors,
    queryString,
    orderAIRuleDetail,
    handleTabOnChange,
    setOrderAIRuleDetail,
    isManageClientDefault,
  } = useWarehouseCustomerContext();

  const { handleUserPermissionAllow } = useContext(AuthContext);

  return (
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={12}>
        <CardTab
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px !important',
            margin: '21px',
            marginBottom: 1,
            overflow: 'auto',
            borderRadius: 2,
            border: '1px solid #cdcdcd',
          }}
          tabValue={tabValue}
          handleChange={handleTabOnChange}
          tabView={[
            {
              title: 'Details',
              value: '1',
              isDisplay: true,
              children: DetailsInterface(),
            },
            {
              title: 'Billing Setup',
              value: '2',
              isDisplay: handleUserPermissionAllow(
                UserPermissionEnum.Client_Billing_View,
              ),
              children: BillingInterface(),
            },
            {
              title: 'Invoices',
              value: '3',
              isDisplay: true,
              children: InvoiceInterfaceTab(),
            },
            {
              title: 'Integrations',
              value: '4',
              isDisplay: handleUserPermissionAllow(
                UserPermissionEnum.Client_Integration_View,
              ),
              children: IntegrationsInterface({
                isShowQuickBookEhubXero: false,
              }),
            },
            {
              title: 'Client Portal',
              value: '5',
              isDisplay: handleUserPermissionAllow(
                UserPermissionEnum.Client_CustomerPortal_View,
              ),
              children: CustomerPortalInterface(),
            },
            {
              title: 'Shipping Accounts',
              value: '6',
              isDisplay: handleUserPermissionAllow(
                UserPermissionEnum.Client_ShippingAccounts_View,
              ),
              children: ShippingAccountsInterface(),
            },
            {
              title: 'Rules Management',
              value: '7',
              isDisplay: handleUserPermissionAllow(
                UserPermissionEnum.Client_Rules_View,
              ),
              children: (
                <NewRulesManagementInterface
                  customerType={CustomerType.WarehouseCustomer}
                  formRules={form}
                  setFormRules={setForm}
                  queryStringId={queryString.id}
                  isViewForm={isViewForm}
                  formErrors={formErrors}
                  IsRulesManagementTab
                />
              ),
            },
            {
              title: (
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    fontWeight="bold"
                    sx={{
                      textTransform: 'capitalize !important',
                    }}
                  >
                    Order Source Rules
                  </Typography>
                  <ElectricBoltIcon fontSize="medium" />
                </Box>
              ),
              value: '8',
              isDisplay: true,
              children: isManageClientDefault ? (
                <NewRulesManagementInterface
                  customerType={CustomerType.WarehouseCustomer}
                  formRules={form}
                  setFormRules={setForm}
                  queryStringId={queryString.id}
                  isViewForm={isViewForm}
                  formErrors={formErrors}
                />
              ) : (
                <NewRulesManagementInterface
                  customerType={CustomerType.WarehouseCustomer}
                  formRules={orderAIRuleDetail}
                  setFormRules={setOrderAIRuleDetail}
                  queryStringId={queryString.id}
                  isViewForm={isViewForm}
                  formErrors={formErrors}
                  IsOrderAIRules
                  orderSourceRulesDataRow={form?.orderSourceRulesDataRow}
                />
              ),
            },
          ]}
        />
      </MUIGrid>
    </MUIGrid>
  );
}

export function IntegrationsInterfacePage(props: {
  isShowQuickBookEhubXero?: boolean;
}) {
  const { isShowQuickBookEhubXero } = props;
  return (
    <MainContainer>
      <WareHouseCustomerContextProvider>
        <IntegrationModal />
        <IntegrationsInterface
          isShowQuickBookEhubXero={isShowQuickBookEhubXero}
        />
        <ExtensivIntegrationModal />
        <QuickBooksIntegrationLogsModal />
        <ShopifyLocationModal />
        <ShopifySyncTypeModal />
        <IntegrationLogModal />
      </WareHouseCustomerContextProvider>
    </MainContainer>
  );
}

export function IntegrationsInterfacePageWithHeader() {
  return (
    <MainContainer>
      <WareHouseCustomerContextProvider>
        <Header />
        <IntegrationsInterface isShowQuickBookEhubXero={false} />
        <ExtensivEditOrderRuleIntegrationModal />
        <ExtensivIntegrationModal />
        <ShopifyLocationModal />
        <ShopifySyncTypeModal />
      </WareHouseCustomerContextProvider>
    </MainContainer>
  );
}

export function ShippingAccountsInterfacePageWithHeader() {
  return (
    <MainContainer>
      <WareHouseCustomerContextProvider>
        <Header />
        <ShippingAccountsInterface />
      </WareHouseCustomerContextProvider>
    </MainContainer>
  );
}

export function InvoiceInterfacePageWithHeader() {
  return (
    <MainContainer>
      <WareHouseCustomerContextProvider>
        <Header />
        <InvoiceInterfaceTab />
      </WareHouseCustomerContextProvider>
    </MainContainer>
  );
}

function WarehouseCustomerPage() {
  return (
    <MainContainer>
      <WareHouseCustomerContextProvider>
        <Header />
        <OtherContactsModal />
        <IntegrationModal />
        <ShopifyLocationModal />
        <ShopifySyncTypeModal />
        <IntegrationLogModal />
        <ConnectCarrierModal />
        <BillingChargeModal />
        <UOMChargeRateModal />
        <BillingReportModal />
        <CarrierIntegrationModal />
        <ExtensivIntegrationModal />
        <QuickBooksIntegrationLogsModal />
        <CopyDefaultFromCustomerModal />
        {/* <InvoiceDialog /> */}
        <WarehouseCustomerContainer />
      </WareHouseCustomerContextProvider>
    </MainContainer>
  );
}

export default React.memo(WarehouseCustomerPage);
