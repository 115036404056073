import React, { useContext, useEffect } from 'react';

import {
  PickTickLineItemManualItem,
  PickTicketLineItemBinLotQtyDropDownListItem,
} from 'common/models/picktickets/packandship';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import { usePackAndShipContext } from 'pages/packandship/context';
import {
  ManualItemDropDownListItem,
  ManualItemModal,
} from 'pages/packandship/models';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { createThePickTicketPackageLineItem } from 'services/api/packandship/packandship.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Autocomplete,
  Modal,
  Button,
  Box,
  Typography,
  TextField,
  Grid as MUIGrid,
  CircularProgress,
} from '@mui/material';

const INITIAL_STATE_MANUALITEMMODAL: ManualItemModal = {
  manualItemDDL: [],
  pickTicketLineItemBinLotQtyDDL: [],
  manualItem: null,
  description: null,
  uom: null,
  pickTicketLineItemBinLotQty: null,
  quantity: null,
  note: null,
};

type ManualItemModalError = {
  manualItem: string;
  pickTicketLineItemBinLotQty: string;
  quantity: string;
};

const INITIAL_STATE_MANUALITEMMODAL_ERROR: ManualItemModalError = {
  manualItem: '',
  pickTicketLineItemBinLotQty: '',
  quantity: '',
};

function AddItemModal() {
  const {
    isPackAndShipAddItemModalOpen,
    onClosePackAndShipAddItemModal,
    setIsChangePackageModalOpen,
    setIsNextPackageModalOpen,
    setIsPalletizeModalOpen,
  } = useContext(GlobalContext);
  const { currentLocationAndFacility } = useContext(AuthContext);

  const [manualItemModal, setManualItemModal] = React.useState<ManualItemModal>(
    INITIAL_STATE_MANUALITEMMODAL,
  );

  const [manualItemModalError, setManualItemModalError] =
    React.useState<ManualItemModalError>(INITIAL_STATE_MANUALITEMMODAL_ERROR);

  const [isBusy, setIsBusy] = React.useState<boolean>(false);

  const {
    pickTicketFormObj,
    viewLoadData,
    packageIdValue,
    isNewPackageForManualItem,
  } = usePackAndShipContext();

  useEffect(() => {
    setIsBusy(false);
    setManualItemModalError({
      ...INITIAL_STATE_MANUALITEMMODAL_ERROR,
    });

    if (isPackAndShipAddItemModalOpen) {
      if (pickTicketFormObj !== undefined) {
        const manualItemDDL: ManualItemDropDownListItem[] =
          pickTicketFormObj.manualItems.map(
            (x: PickTickLineItemManualItem) => ({
              value: x.pickTicketLineItemId,
              text: x.sku,
              description: x.description,
              uom: x.uom,
              quantity: x.quantity,
              packed: x.packed,
            }),
          );

        setManualItemModal({
          ...INITIAL_STATE_MANUALITEMMODAL,
          manualItemDDL: [...manualItemDDL],
        });
      }
    } else {
      setManualItemModal({ ...INITIAL_STATE_MANUALITEMMODAL });
    }

    setIsChangePackageModalOpen(false);
    setIsNextPackageModalOpen(false);
    setIsPalletizeModalOpen(false);
  }, [isPackAndShipAddItemModalOpen]);

  const validateForm = () => {
    const temp = { ...INITIAL_STATE_MANUALITEMMODAL_ERROR };
    const re = /[0-9]+/g;

    if (manualItemModal.manualItem === null) {
      temp.manualItem = 'This field is required';
    }

    if (manualItemModal.pickTicketLineItemBinLotQtyDDL.length > 0) {
      if (manualItemModal.pickTicketLineItemBinLotQty === null) {
        temp.pickTicketLineItemBinLotQty = 'This field is required';
      }
    }

    if (manualItemModal.quantity === null) {
      temp.quantity = 'This field is required';
    } else if (!re.test(manualItemModal.quantity.toString())) {
      temp.quantity = 'Must be a number';
    }

    // no lot
    if (
      manualItemModal.manualItem !== null &&
      manualItemModal.quantity !== null &&
      manualItemModal.pickTicketLineItemBinLotQtyDDL.length === 0 &&
      manualItemModal.pickTicketLineItemBinLotQty === null
    ) {
      const remaining =
        manualItemModal.manualItem.quantity - manualItemModal.manualItem.packed;
      if (manualItemModal.quantity > remaining) {
        temp.quantity = `Item's quantity to pack have remaining of: ${remaining}. Cannot pack more than that.`;
      }
    }

    // has lot
    if (
      manualItemModal.manualItem !== null &&
      manualItemModal.quantity !== null &&
      manualItemModal.pickTicketLineItemBinLotQtyDDL.length > 0 &&
      manualItemModal.pickTicketLineItemBinLotQty !== null
    ) {
      const remaining = manualItemModal.pickTicketLineItemBinLotQty.qty;
      if (manualItemModal.quantity > remaining) {
        temp.quantity = `Item's quantity to pack have remaining of: ${remaining}. Cannot pack more than that.`;
      }
    }

    setManualItemModalError({ ...temp });

    return Object.values(temp).every((x) => x === '');
  };

  function addManualItem(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();

    if (!validateForm()) {
      snackActions.error('Please complete required fields');
    } else {
      setIsBusy(true);

      let lotId = null;
      if (
        manualItemModal.pickTicketLineItemBinLotQty !== null &&
        manualItemModal.pickTicketLineItemBinLotQty.value !== 0
      ) {
        lotId = manualItemModal.pickTicketLineItemBinLotQty.value;
      }

      const PAYLOAD = {
        customerLocationId: currentLocationAndFacility.customerLocationId,
        customerFacilityId: currentLocationAndFacility.customerFacilityId,
        packageId: packageIdValue > 0 ? packageIdValue : null,
        pickTicketLineItemId: manualItemModal.manualItem.value,
        qty: manualItemModal.quantity,
        note: manualItemModal.note,
        lotId,
        isNewPackage: isNewPackageForManualItem,
      };

      createThePickTicketPackageLineItem(PAYLOAD)
        .then(() => {
          viewLoadData(true);
          snackActions.success('Successfully added package item.');
        })
        .catch((err) => {
          snackActions.error(err);
        })
        .finally(() => {
          setIsBusy(false);
          onClosePackAndShipAddItemModal();
        });
    }
  }

  return (
    <Modal open={isPackAndShipAddItemModalOpen}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Add Item
        </Typography>
        <MUIContent>
          <Box autoComplete="off" component="form" noValidate>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6} sm={6}>
                <Autocomplete
                  value={manualItemModal.manualItem}
                  onChange={(event, newValue: ManualItemDropDownListItem) => {
                    if (newValue !== null) {
                      let pickTicketLineItemBinLotQtyDDL: PickTicketLineItemBinLotQtyDropDownListItem[] =
                        [];

                      const manualItem = pickTicketFormObj.manualItems.find(
                        (x) => x.pickTicketLineItemId === newValue.value,
                      );
                      if (manualItem !== undefined) {
                        pickTicketLineItemBinLotQtyDDL =
                          manualItem.pickTicketLineItemBinLotQtyDDL.map(
                            (x) => ({
                              ...x,
                              expirationDate: x.expirationDate ?? null,
                            }),
                          );
                      }

                      let pickTicketLineItemBinLotQty: PickTicketLineItemBinLotQtyDropDownListItem =
                        null;
                      if (pickTicketLineItemBinLotQtyDDL.length === 1) {
                        pickTicketLineItemBinLotQty = {
                          ...pickTicketLineItemBinLotQtyDDL[0],
                        };
                      }

                      setManualItemModal((prev: ManualItemModal) => ({
                        ...prev,
                        pickTicketLineItemBinLotQtyDDL,
                        manualItem: { ...newValue },
                        description: newValue.description,
                        uom: newValue.uom,
                        pickTicketLineItemBinLotQty,
                        quantity: null,
                        note: null,
                      }));
                    } else {
                      const manualItemDDL = [...manualItemModal.manualItemDDL];
                      setManualItemModal({
                        ...INITIAL_STATE_MANUALITEMMODAL,
                        manualItemDDL,
                      });
                    }

                    setManualItemModalError({
                      ...INITIAL_STATE_MANUALITEMMODAL_ERROR,
                    });
                  }}
                  id="controllable-states"
                  disabled={isBusy}
                  options={manualItemModal.manualItemDDL}
                  getOptionLabel={(option: ManualItemDropDownListItem) =>
                    option.text
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option: ManualItemDropDownListItem) => (
                    <li {...props} key={option.value}>
                      <Box display="flex">
                        <Box display="flex" flexDirection="column">
                          <Typography color="text.primary">
                            {option.text}
                          </Typography>
                          <Typography color="text.secondary" variant="body2">
                            {option.description}
                          </Typography>
                          <Typography color="text.secondary" variant="body2">
                            {option.uom}
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      {...(manualItemModalError.manualItem && {
                        error: true,
                        helperText: manualItemModalError.manualItem,
                      })}
                      id="outlined-required"
                      label="SKU"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={6} sm={6}>
                <TextField
                  id="outlined"
                  label="Description"
                  name="description"
                  value={manualItemModal?.description || ''}
                  size="small"
                  disabled
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={6} sm={6}>
                <Autocomplete
                  value={manualItemModal.pickTicketLineItemBinLotQty}
                  onChange={(
                    event,
                    newValue: PickTicketLineItemBinLotQtyDropDownListItem,
                  ) => {
                    if (newValue !== null) {
                      setManualItemModal((prev: ManualItemModal) => ({
                        ...prev,
                        pickTicketLineItemBinLotQty: newValue,
                      }));
                    } else {
                      setManualItemModal((prev: ManualItemModal) => ({
                        ...prev,
                        pickTicketLineItemBinLotQty: null,
                      }));
                    }
                  }}
                  id="controllable-states"
                  disabled={
                    isBusy ||
                    manualItemModal.pickTicketLineItemBinLotQtyDDL.length === 0
                  }
                  options={manualItemModal.pickTicketLineItemBinLotQtyDDL}
                  getOptionLabel={(
                    option: PickTicketLineItemBinLotQtyDropDownListItem,
                  ) => option.text}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(
                    props,
                    option: PickTicketLineItemBinLotQtyDropDownListItem,
                  ) => (
                    <li {...props} key={option.value}>
                      <Box display="flex">
                        <Box display="flex" flexDirection="column">
                          <Typography color="text.primary">
                            {option.text}
                          </Typography>
                          <Typography color="text.secondary" variant="body2">
                            {option.expirationDate !== null
                              ? moment(option.expirationDate).format(
                                  'MM/DD/YYYY',
                                )
                              : ''}
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      {...(manualItemModalError.pickTicketLineItemBinLotQty && {
                        error: true,
                        helperText:
                          manualItemModalError.pickTicketLineItemBinLotQty,
                      })}
                      id="outlined-required"
                      label="Lot No."
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={6} sm={6}>
                <TextField
                  disabled
                  value={
                    manualItemModal.pickTicketLineItemBinLotQty !== null &&
                    manualItemModal.pickTicketLineItemBinLotQty
                      ?.expirationDate !== null
                      ? moment(
                          manualItemModal.pickTicketLineItemBinLotQty
                            ?.expirationDate,
                        ).format('MM/DD/YYYY')
                      : ''
                  }
                  id="outlined-required"
                  label="Lot Exp. Date"
                  size="small"
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={6} sm={6}>
                <TextField
                  disabled
                  id="outlined"
                  label="UOM"
                  name="uom"
                  value={manualItemModal?.uom || ''}
                  size="small"
                  sx={{ width: '100%' }}
                />
              </MUIGrid>

              <MUIGrid item xs={6} sm={6}>
                <TextField
                  required
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.value !== '') {
                      event.preventDefault();
                      const validateDecimal = decimalValidationHelper(
                        event.target.value,
                      );

                      setManualItemModal((prev: ManualItemModal) => ({
                        ...prev,
                        quantity: Number(validateDecimal),
                      }));
                    } else {
                      setManualItemModal((prev: ManualItemModal) => ({
                        ...prev,
                        quantity: null,
                      }));
                    }
                  }}
                  {...(manualItemModalError.quantity && {
                    error: true,
                    helperText: manualItemModalError.quantity,
                  })}
                  onKeyPress={(e) =>
                    !/[0-9.]/.test(e.key) && e.preventDefault()
                  }
                  type="number"
                  value={manualItemModal?.quantity || ''}
                  disabled={isBusy}
                  id="outlined-required"
                  label="Quantity"
                  size="small"
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12}>
                <TextField
                  id="outlined"
                  label="Note"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.value !== '') {
                      setManualItemModal((prev: ManualItemModal) => ({
                        ...prev,
                        note: event.target.value,
                      }));
                    } else {
                      setManualItemModal((prev: ManualItemModal) => ({
                        ...prev,
                        quantity: null,
                      }));
                    }
                  }}
                  value={manualItemModal?.note || ''}
                  disabled={isBusy}
                  name="note"
                  size="small"
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => onClosePackAndShipAddItemModal()}
                disabled={isBusy}
                variant="outlined"
                size="large"
                sx={{ padding: 1, width: '100%' }}
              >
                CANCEL
              </Button>

              {!isBusy && (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => {
                    addManualItem(e);
                  }}
                >
                  ADD
                </Button>
              )}
              {isBusy && (
                <Button
                  disabled
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                    color: 'white !important',
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: 'white !important',
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                  <Box sx={{ paddingLeft: '5px' }}>ADDING</Box>
                </Button>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
}

export default React.memo(AddItemModal);
