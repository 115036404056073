import React, { useEffect, useContext, useState, useLayoutEffect } from 'react';

import { PageLoadStateEnum } from 'common/enums';
import { PutAwayLineItemLookupGetModel } from 'common/models/putaways/PutAwayLineItemDeviceGetModel';
import Card from 'components/card';
import Header from 'components/header';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { ContainerFlat } from 'styles';
import { DataGridProStyle } from 'styles/mui/datagrid/premiumpro';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Typography, Box, Link, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridColDef,
  GridRowParams,
  GridRenderCellParams,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro';

import { MainContainer, ContentContainer } from '../styles';
import { PutAwayContextProvider, usePutAwayContext } from './context';
import { PutAwayDeleteModal } from './putaway-delete-modal';
import { PutAwayLineItemModal } from './putaway-lineitem-modal';

function PutAwayQueue() {
  const { state, openPutAwayLineItemModal, setGetPutAwayIdsToDelete } =
    usePutAwayContext();
  const { setIsPutawayModalOpen } = useContext(GlobalContext);

  const theme = useTheme();
  const { loading, updateLoading } = useContext(GlobalContext);
  const [getRowIds, setGetRowIds] = useState([]);

  useEffect(() => {
    if (state.pageLoadState !== PageLoadStateEnum.LOAD_FINISH) {
      updateLoading(true);
    } else {
      updateLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, state.pageLoadState]);

  const onRowsSelectionToDeleteHandler = (receiptId, id, isDeleteAll) => {
    if (isDeleteAll) {
      const putawayIds = state.vm.lineItems
        .filter((x) => x.receiptId === receiptId)
        .map((row: any) => row.insideDatagrid)
        .flat()
        .map((item: any) => item.putAwayId);
      setGetPutAwayIdsToDelete(putawayIds);
    } else {
      setGetPutAwayIdsToDelete([id]);
    }
  };

  const putAwayColumns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      hideable: false,
      width: 30,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Delete All Put Away"
          onClick={() => {
            onRowsSelectionToDeleteHandler(params.row.receiptId, [], true);
            setIsPutawayModalOpen(true);
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'referenceNo',
      width: 200,
      renderCell: (params) =>
        params.row?.referenceNo && (
          <Chip
            label={`Reference No: ${params.row?.referenceNo}`}
            size="small"
            className="blueChipParagraph"
          />
        ),
    },
    {
      field: 'receivedTimeStr',
      width: 200,
      renderCell: (params) =>
        params.row?.receivedTimeStr && (
          <Chip
            label={`Received Date: ${params.row?.receivedTimeStr}`}
            size="small"
            className="blueChipParagraph"
          />
        ),
    },
    {
      field: 'container',
      width: 200,
      renderCell: (params) =>
        params.row?.container && (
          <Chip
            label={`Container: ${params.row?.container}`}
            size="small"
            className="blueChipParagraph"
          />
        ),
    },
    {
      field: 'po',
      width: 200,
      renderCell: (params) =>
        params.row?.po && (
          <Chip
            label={`PO: ${params.row?.po}`}
            size="small"
            className="blueChipParagraph"
          />
        ),
    },
  ];
  const putAwayColumnsLineItem: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      hideable: false,
      width: 30,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params: GridRowParams<PutAwayLineItemLookupGetModel>) => [
        <GridActionsCellItem
          label="Put Away"
          onClick={() => {
            openPutAwayLineItemModal(params.row);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            onRowsSelectionToDeleteHandler(null, params.row.putAwayId, false);
            setIsPutawayModalOpen(true);
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'sku',
      width: 200,
      headerName: 'Item SKU',
      renderCell: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params: GridRenderCellParams<any, PutAwayLineItemLookupGetModel>,
      ) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`/inventory/item-details?form=view&id=${params.row.itemId}`}
          target="_blank"
          underline="none"
        >
          <Typography sx={{ cursor: 'pointer' }}>{params.row.sku}</Typography>
        </Link>
      ),
    },
    {
      field: 'uomStr',
      width: 150,
      headerName: 'UoM',
    },
    {
      field: 'qty',
      minWidth: 120,
      align: 'left',
      headerName: 'Put Away QTY',
    },
    {
      field: 'remaining',
      minWidth: 140,
      align: 'left',
      headerName: 'Remaining Qty',
    },
    {
      field: 'description',
      minWidth: 200,
      flex: 0.5,
      headerName: 'Description',
    },
    {
      field: 'licensePlateNo',
      minWidth: 150,
      flex: 0.5,
      headerName: 'LPN',
    },
    {
      field: 'statusStr',
      minWidth: 150,
      flex: 0.5,
      headerName: 'Status',
    },
    {
      field: 'reason',
      minWidth: 150,
      flex: 0.5,
      headerName: 'Reason',
    },
  ];

  useLayoutEffect(() => {
    setGetRowIds(state.vm.lineItems.map((e) => e.receiptId));
  }, [state]);

  if (getRowIds.length <= 0) {
    return (
      <ContainerFlat>
        <DataGridProStyle
          autoHeight
          columnHeaderHeight={0}
          disableColumnMenu
          rows={state.vm.lineItems}
          columns={putAwayColumns}
          hideFooterSelectedRowCount
          getRowId={(row) => row.receiptId}
          density="compact"
        />
      </ContainerFlat>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          marginBottom: '16px',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '192px',
          }}
        >
          <Typography sx={{ color: [theme.palette.primary.main] }} variant="h3">
            {state.vm.items.toLocaleString()}
          </Typography>
          <Typography variant="caption" fontWeight="bold">
            Items
          </Typography>
        </Card>
      </Box>
      <ContainerFlat>
        <DataGridProStyle
          autoHeight
          columnHeaderHeight={0}
          rows={state.vm.lineItems}
          columns={putAwayColumns}
          pagination
          density="compact"
          getRowId={(row) => row.receiptId}
          disableColumnMenu
          // eslint-disable-next-line react/no-unstable-nested-components
          getDetailPanelContent={({ row }) => (
            <Box
              sx={{
                flex: 1,
                mx: 'auto',
                width: '96%',
                p: 1,
              }}
            >
              <DataGridProStyle
                autoHeight
                density="compact"
                columns={putAwayColumnsLineItem}
                rows={row.insideDatagrid}
                sx={{
                  flex: 1,
                  backgroundColor: 'white',
                }}
                getRowClassName={(params) =>
                  params.row.statusStr !== 'Available'
                    ? 'nonAvailableStatus'
                    : ''
                }
                getRowId={(x) => x.putAwayId}
                // componentsProps={{
                //   toolbar: {
                //     title: 'Inventory',
                //     showDeleteInventoryButton: true,
                //     disableDeleteInventoryButton:
                //       !selectedToDeleteRows || selectedToDeleteRows?.length === 0,
                //     deleteInventoryMethod: onOpenDeleteInventoryModal,
                //   },
                // }}
                // getRowId={() => Math.random()}
                disableColumnMenu
              />
            </Box>
          )}
          getDetailPanelHeight={() => 'auto'}
          initialState={{
            detailPanel: {
              expandedRowIds: getRowIds,
            },
          }}
          slots={{
            detailPanelExpandIcon: KeyboardArrowDownIcon,
            detailPanelCollapseIcon: KeyboardArrowUpIcon,
          }}
        />
      </ContainerFlat>
    </>
  );
}

const PutAway = () => (
  <MainContainer>
    <PutAwayContextProvider>
      <Header />
      <ContentContainer>
        <PutAwayQueue />
        <PutAwayLineItemModal />
        <PutAwayDeleteModal />
      </ContentContainer>
    </PutAwayContextProvider>
  </MainContainer>
);

export default React.memo(PutAway);
