import React, { useContext, useState, useEffect } from 'react';

import { IAutoCompleteOption } from 'common/interfaces';
import Button from 'components/button';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { useSalesOrderDataTableViewContext } from 'pages/sales/context-provider';
import { UpdateTagOrders } from 'services/api/salesorders/salesorders-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Box,
  Modal,
  Chip,
  Typography,
  TextField,
  Autocomplete,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';

export default React.memo(() => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);

  const {
    isAddTagsModalOpen,
    setIsAddTagsModalOpen,
    isAddTagsConfirmModalOpen,
    setIsAddTagsConfirmModalOpen,
  } = useContext(GlobalContext);

  const {
    tagsOnOrderOption,
    OnClickUserFilterSearchApply,
    selectedRowsOnCheckbox,
  } = useSalesOrderDataTableViewContext();

  const InitialFormTagFormError = {
    toRemoveTag: '',
  };
  const [tagDetailsFormError, setTagDetailsFormError] = useState<any>(
    InitialFormTagFormError,
  );

  const [selectedTag, setSelectedTag] = useState<any>({});
  const [tagParam, setTagParam] = useState('');

  const [selectedTagToRemove, setSelectedTagToRemove] = useState<any>(null);
  const [tagSelectionOptions, setTagSelectionOptions] = useState<any>({});
  const [isToRemoveTag, setIsToRemoveTag] = useState(false);

  const handleCancelConfirmModal = () => {
    setSelectedTagToRemove(null);
    setIsAddTagsConfirmModalOpen(false);
    setIsAddTagsModalOpen(true);
    setIsToRemoveTag(false);
    setTagParam('');
    setTagDetailsFormError({});
  };

  const updateTagsOrderOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    if (isToRemoveTag === true) {
      const temp: any = {};
      temp.toRemoveTag =
        selectedTagToRemove?.value === -1 || selectedTagToRemove === null
          ? 'Please select tag'
          : '';
      setTagDetailsFormError({
        ...temp,
      });

      if (!Object.values(temp).every((x) => x === '')) {
        return;
      }
    }

    if ((tagParam === null || tagParam === '') && selectedTag.tagCount === 0) {
      const temp: any = {};

      temp.tagParamError = 'Please input tag';

      setTagDetailsFormError({
        ...temp,
      });

      if (!Object.values(temp).every((x) => x === '')) {
        return;
      }
    }

    const payload = {
      TagName:
        selectedTag.tagCount === -1 ? selectedTagToRemove?.label : tagParam,
      IsToRemove: isToRemoveTag,
      OrderIds: selectedRowsOnCheckbox.map((c: any) => c.orderId),
    };

    try {
      await UpdateTagOrders(payload);
      snackActions.success('Tag has been successfully updated.');
      OnClickUserFilterSearchApply(e, true);
      handleCancelConfirmModal();
      setIsAddTagsModalOpen(false);
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    const setTags = Array.isArray(tagsOnOrderOption)
      ? tagsOnOrderOption
          .filter((v) => v.tagCount > 0)
          .map((v, index) => ({
            value: index,
            label: v.tagName,
          }))
      : [{ value: 0, label: '' }];

    setTagSelectionOptions(setTags);
  }, [currentUser.Claim_CustomerId, isAddTagsConfirmModalOpen]);

  return (
    <>
      <Modal open={isAddTagsModalOpen}>
        <ModalBox sx={{ maxWidth: '450px !important' }}>
          <ModalContent sx={{ maxWidth: '450px !important' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography
                sx={{ marginBottom: '8px' }}
                variant="h6"
                fontWeight="bold"
              >
                Add Tags
              </Typography>
              <List
                dense
                sx={{
                  width: '100%',
                  maxWidth: 450,
                }}
              >
                <Typography sx={{ marginBottom: '10px', fontSize: '20px' }}>
                  Select a tag below
                </Typography>
                {tagsOnOrderOption &&
                  tagsOnOrderOption.map((value, index) => {
                    const labelId = `checkbox-list-secondary-label-${value.tagName}`;
                    return (
                      <ListItem
                        key={value.tagName}
                        secondaryAction={
                          <Chip
                            label={value.tagCount}
                            size="small"
                            className="grayChip"
                            sx={{
                              display: value.tagCount <= 0 ? 'none' : 'block',
                            }}
                          />
                        }
                        sx={{
                          borderTop:
                            index === 0 ? 'solid 1px hsl(0, 0%, 64%)' : 'none',
                          borderBottom: 'solid 1px hsl(0, 0%, 64%)',
                        }}
                        disablePadding
                      >
                        <ListItemButton
                          onClick={() => {
                            setIsAddTagsConfirmModalOpen(true);
                            setIsAddTagsModalOpen(false);
                            setSelectedTag(value);
                            if (value.tagCount === -1) {
                              setIsToRemoveTag(true);
                            }
                            if (value.tagCount > 0) {
                              setTagParam(value.tagName);
                            }
                          }}
                        >
                          <ListItemText
                            id={labelId}
                            primary={`${value.tagName}`}
                            sx={{
                              color:
                                value.tagColor === 'White'
                                  ? 'black'
                                  : value.tagColor,
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                  justifyContent: 'flex-end',
                },
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'center',
                },
                gap: '8px',
              }}
            >
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="text"
                size="small"
                onClick={() => {
                  setIsAddTagsModalOpen(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          </ModalContent>
        </ModalBox>
      </Modal>
      <Modal open={isAddTagsConfirmModalOpen}>
        <ModalBox sx={{ maxWidth: '450px !important' }}>
          <ModalContent sx={{ maxWidth: '450px !important' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              {selectedTag.tagCount === 0 && (
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  label="Tag Name"
                  value={tagParam}
                  onChange={(e) => {
                    setTagParam(e.target.value);
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                  {...(tagDetailsFormError.tagParamError && {
                    error: true,
                    helperText: tagDetailsFormError.tagParamError,
                  })}
                />
              )}
              {selectedTag.tagCount === -1 && (
                <Autocomplete
                  options={tagSelectionOptions}
                  isOptionEqualToValue={(option, selected) =>
                    selected ? option.value === selected.value : null
                  }
                  value={selectedTagToRemove || null}
                  onChange={(
                    event: any,
                    newValue: IAutoCompleteOption | null,
                  ) => {
                    if (newValue === null) {
                      setSelectedTagToRemove(null);
                    } else {
                      setSelectedTagToRemove((prev) => ({
                        ...prev,
                        value: newValue.value,
                        label: newValue.label,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <div>
                      <TextField
                        {...params}
                        label="Tags"
                        variant="outlined"
                        sx={{ boxShadow: 'none !important' }}
                        fullWidth
                        size="small"
                        {...(tagDetailsFormError.toRemoveTag && {
                          error: true,
                          helperText: tagDetailsFormError.toRemoveTag,
                        })}
                      />
                    </div>
                  )}
                />
              )}
              {selectedTag.tagCount > 0 && (
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Tag Name: {selectedTag.tagName}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                  justifyContent: 'flex-end',
                },
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'center',
                },
                gap: '8px',
              }}
            >
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="text"
                size="small"
                onClick={() => {
                  handleCancelConfirmModal();
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{ minHeight: '48px', maxWidth: '150px' }}
                variant="contained"
                size="small"
                onClick={(e) => {
                  updateTagsOrderOnClick(e);
                }}
              >
                {isToRemoveTag ? 'Remove' : 'Add'} Tag
              </Button>
            </Box>
          </ModalContent>
        </ModalBox>
      </Modal>
    </>
  );
});
