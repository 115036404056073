import React, { useEffect, useContext, useState } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from 'components/button';

import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Stack,
  Modal,
  Box,
  Typography,
  TextField,
  Divider,
  Grid as MUIGrid,
} from '@mui/material';

import { useProductionContext } from 'pages/production/context';
import DatePickerInput from 'components/datepicker';
import { styled as MuiStyled } from '@mui/material/styles';
import { autoCompleteTimeStamp } from '../../../helpers/autocompletetimestamp';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;
    width: 100%;
    max-width: 400px;
    border-radius: 4px;
  }
`;

type AutocompleteBase = {
  label?: string;
  value?: number;
};
type UOMType = AutocompleteBase;

type ExtendSkuType = {
  sku?: string;
  description: string;
};
type ExtendLotNoType = {
  lotId?: number;
  lotExpiration?: string;
};
export type LotNoType = AutocompleteBase & ExtendLotNoType;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;

export default React.memo(() => {
  const { isProductionReportModalOpen, onCloseProductionReportModal } =
    useContext(GlobalContext);

  const {
    OnSavePostProductionReport,
    productionReportModal,
    setProductionReportModal,
    productionReportFormErrors,
  } = useProductionContext();

  return (
    <Modal open={isProductionReportModalOpen}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Update Production
          </Typography>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined-required"
                  label="Qty Complete"
                  value={
                    productionReportModal.qtyComplete >= 0
                      ? productionReportModal.qtyComplete
                      : 0
                  }
                  onChange={(e) =>
                    setProductionReportModal((prev) => ({
                      ...prev,
                      qtyComplete: e.target.value,
                    }))
                  }
                  size="small"
                  type="number"
                  sx={{ width: '100%' }}
                  error={productionReportFormErrors.qtyComplete}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12}>
                <DatePickerInput
                  label="Start Date"
                  inputFormat="MM/dd/yyyy"
                  value={productionReportModal.startDateTime}
                  onChange={(e) =>
                    setProductionReportModal((prev) => ({
                      ...prev,
                      startDateTime: e,
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled
                      {...params}
                      error={productionReportFormErrors.startDateTime}
                      helperText={productionReportFormErrors.startDateTime}
                      size="small"
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <TextField
                      inputProps={{
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="time"
                      label="Start Time"
                      size="small"
                      type="time"
                      value={productionReportModal.startTime}
                      onChange={(e) =>
                        setProductionReportModal((prev) => ({
                          ...prev,
                          startTime: e.target.value,
                        }))
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={productionReportFormErrors.startTime}
                      helperText={productionReportFormErrors.startTime}
                    />
                  </Stack>
                </LocalizationProvider>
              </MUIGrid>
              <MUIGrid item xs={12} sm={12}>
                <DatePickerInput
                  label="Completed Date"
                  inputFormat="MM/dd/yyyy"
                  value={productionReportModal.completeDateTime}
                  onChange={(e) =>
                    setProductionReportModal((prev) => ({
                      ...prev,
                      completeDateTime: e,
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled
                      {...params}
                      error={productionReportFormErrors.completeDateTime}
                      helperText={productionReportFormErrors.completeDateTime}
                      size="small"
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <TextField
                      inputProps={{
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="time"
                      label="Completed Time"
                      size="small"
                      type="time"
                      value={productionReportModal.completeTime}
                      onChange={(e) =>
                        setProductionReportModal((prev) => ({
                          ...prev,
                          completeTime: e.target.value,
                        }))
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={productionReportFormErrors.completeTime}
                      helperText={productionReportFormErrors.completeTime}
                    />
                  </Stack>
                </LocalizationProvider>
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => {
                      onCloseProductionReportModal();
                    }}
                    variant="outlined"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                    onClick={(e) => {
                      OnSavePostProductionReport(e, productionReportModal);
                    }}
                  >
                    UPDATE
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
