import { ReactComponent as AcceptReturnsIcon } from 'assets/icons/AcceptReturns.svg';
import { ReactComponent as AutoHoldIcon } from 'assets/icons/AutoHold.svg';
import { ReactComponent as CarrierIcon } from 'assets/icons/Carrier.svg';
import { ReactComponent as DefaultPackageIcon } from 'assets/icons/DefaultPackage.svg';
import { ReactComponent as InsuranceIcon } from 'assets/icons/Insurance.svg';
import { ReactComponent as ItemsMappingIcon } from 'assets/icons/ItemsMapping.svg';
import { ReactComponent as PrintCartonIcon } from 'assets/icons/PrintCarton.svg';
import { ReactComponent as ReturnOrdersIcon } from 'assets/icons/ReturnOrders.svg';
import { ReactComponent as ShippingDocOptionIcon } from 'assets/icons/ShippingDocOption.svg';
import { ReactComponent as ShippingDocSettingIcon } from 'assets/icons/ShippingDocSetting.svg';
import { ReactComponent as SignatureIcon } from 'assets/icons/Signature.svg';

import { IconProps } from '@mui/material';

type MyIconProps = {
  name:
    | 'acceptReturnsIcon'
    | 'autoHoldIcon'
    | 'carrierIcon'
    | 'defaultPackageIcon'
    | 'insuranceIcon'
    | 'itemsMappingIcon'
    | 'returnOrdersIcon'
    | 'shippingDocOptionIcon'
    | 'shippingDocSettingIcon'
    | 'signatureIcon'
    | 'printCartonIcon';
};

const OrderRulesIcon = (props: MyIconProps) => {
  const { name, ...svgProps } = props;

  const Icons: Record<IconProps['className'], any> = {
    acceptReturnsIcon: (
      <AcceptReturnsIcon {...svgProps} className="orderRulesIcon" />
    ),
    autoHoldIcon: <AutoHoldIcon {...svgProps} className="orderRulesIcon" />,
    carrierIcon: <CarrierIcon {...svgProps} className="orderRulesIcon" />,
    defaultPackageIcon: (
      <DefaultPackageIcon {...svgProps} className="orderRulesIcon" />
    ),
    insuranceIcon: <InsuranceIcon {...svgProps} className="orderRulesIcon" />,
    itemsMappingIcon: (
      <ItemsMappingIcon {...svgProps} className="orderRulesIcon" />
    ),
    returnOrdersIcon: (
      <ReturnOrdersIcon {...svgProps} className="orderRulesIcon" />
    ),
    shippingDocOptionIcon: (
      <ShippingDocOptionIcon {...svgProps} className="orderRulesIcon" />
    ),
    shippingDocSettingIcon: (
      <ShippingDocSettingIcon {...svgProps} className="orderRulesIcon" />
    ),
    signatureIcon: <SignatureIcon {...svgProps} className="orderRulesIcon" />,
    printCartonIcon: (
      <PrintCartonIcon {...svgProps} className="orderRulesIcon" />
    ),
  };

  return Icons[name];
};

export default OrderRulesIcon;
