import React, { useEffect, useContext, useState } from 'react';

import { Grid } from 'components/styles';
import moment from 'moment';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { loadIntegrationLogs } from 'services/api/integrations/integrations.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  TextField,
  Divider,
  Grid as MUIGrid,
  Autocomplete,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { GridColumns } from '@mui/x-data-grid';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;
    width: 100%;
    max-width: 1024px;
    border-radius: 4px;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;

export default React.memo(() => {
  const { isIntegrationLogsModalOpen, setIsIntegrationLogsModalOpen } =
    useContext(GlobalContext);

  const [integrationLogsList, setIntegrationLogsList] = useState([]);

  const getListOfIntegrationLogs = async () => {
    const response = await loadIntegrationLogs();
    setIntegrationLogsList(response);
  };

  useEffect(() => {
    if (isIntegrationLogsModalOpen) {
      getListOfIntegrationLogs();
    }
  }, [isIntegrationLogsModalOpen]);

  const invoicesToBeSyncedColumns: GridColumns = [
    {
      field: 'transactionNo',
      width: 120,
      renderCell: (params) => (
        <Typography>{params.row.transactionNo}</Typography>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Transaction</Typography>
        </Box>
      ),
    },
    {
      field: 'action',
      width: 200,
      renderCell: (params) => (
        <Typography>{params.row.transactionType}</Typography>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Type</Typography>
        </Box>
      ),
    },
    {
      field: 'createdDate',
      width: 200,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdDateTime).format('MM/DD/YYYY')}
        </Typography>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Date</Typography>
        </Box>
      ),
    },
    {
      field: 'result',
      width: 604,
      renderCell: (params) => (
        <Typography>{params.row.transactionResult}</Typography>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Result</Typography>
        </Box>
      ),
    },
  ];

  return (
    <Modal
      open={isIntegrationLogsModalOpen}
      onClose={() => {
        setIsIntegrationLogsModalOpen(false);
      }}
    >
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Integration Logs
          </Typography>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <MUIGrid
              container
              direction="row"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <Grid
                  autoHeight
                  headerHeight={60}
                  rows={integrationLogsList}
                  columns={invoicesToBeSyncedColumns}
                  pageSize={15}
                  density="compact"
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  hideFooterSelectedRowCount
                  rowsPerPageOptions={[15]}
                  getRowId={() => Math.random()}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
