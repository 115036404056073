import React, { useContext, useEffect, useState } from 'react';

import DatePickerInput from 'components/datepicker';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import {
  decimalValidationHelper,
  useInventoryItemDetailsContext,
} from 'pages/inventory/item/details';
import {
  ItemUnitOfMeasureType,
  LotNoType,
} from 'pages/receiving/receipts/subpage';
import {
  getBinLookupCrossDock,
  getCustomerBinsByFacilityId,
} from 'services/api/customerfacilities/customerfacilities.api';
import {
  GetReasonTransferData,
  getItemById,
  getItemUnitOfMeasureDDL,
} from 'services/api/item/item.api';
import {
  createLotNo,
  getLotNo,
  getLicensePlate,
  createReceiptV2,
  confirmReceipt,
  getStatusArrival,
} from 'services/api/receipts/receipts-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import InfoIcon from '@mui/icons-material/Info';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Divider,
  Grid as MUIGrid,
  InputAdornment,
  Tooltip,
  CircularProgress,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { MUIContainer, MUIContent, isNumberKey } from '../editreceiptmodal';

type AutocompleteBase = {
  label?: string;
  value?: number;
};

type LicensePlateType = AutocompleteBase;
type StatusOnArrivalType = AutocompleteBase;
type LocationCrossDockType = AutocompleteBase;
type ReasonOnStatusType = AutocompleteBase;

interface ModalProps {
  itemId: string;
}

export default React.memo(({ itemId }: ModalProps) => {
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const {
    addLotNoModalData,
    setAddLotNoModalData,
    setIsAddLotNoModalOpen,
    isAddLotNoUpdateOptions,
    setIsAddLotNoUpdateOptions,
    isAdjustInventoryModalOpen,
    onCloseAdjustInventoryModal,
  } = useContext(GlobalContext);

  const { onLoadItemDetailSummary, onLoadItemDetailInventory } =
    useInventoryItemDetailsContext();

  const initialForm = {
    skuValue: '',
    totalQty: '',
    lotNo: '',
    lotCreationDate: '',
    lotExpirationDate: '',
    location: '',
    licensePlateType: '',
    isSerialNo: false,
    serialNo: '',
    statusOnArrival: '',
    reasonStatus: '',
    crossDockBinId: '',
    qty: '',
    receivedDate: '',
    isNonBillable: true,
  };

  const [formValues, setFormValues] = useState<any>(initialForm);
  const [optionLicensePlateValue, setOptionLicensePlateValue] =
    useState<LicensePlateType>({ label: '', value: 0 });
  const [optionLicensePlateTypes, setOptionLicensePlateTypes] = useState<
    LicensePlateType[]
  >([]);
  const [lotNoValue, setLotNoValue] = useState<LotNoType>(null);
  const [optionLotNo, setOptionLotNo] = useState<LotNoType[]>([]);
  const [optionLocation, setOptionLocation] = useState([]);
  const [locationValue, setLocationValue] = useState(null);
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [optionStatusOnArrivalType, setOptionStatusOnArrivalType] = useState<
    StatusOnArrivalType[]
  >([]);
  const [optionStatusOnArrivalValue, setOptionStatusOnArrivalValue] =
    useState<StatusOnArrivalType>(null);
  const [optionLocationCrossDockValue, setOptionLocationCrossDockValue] =
    useState<LocationCrossDockType>(null);
  const [optionsReasonOnStatusType, setOptionsReasonOnStatusType] = useState<
    ReasonOnStatusType[]
  >([]);
  const [reasonOnStatusTypeValue, setReasonOnStatusTypeValue] =
    useState<ReasonOnStatusType>(null);

  const [optionsLocationCrossDock, setOptionsLocationCrossDock] = useState([]);

  const [formValueErrors, setFormValueErrors] = useState(null);

  const [uomValue, setUomValue] = useState<ItemUnitOfMeasureType>(null);
  const [optionsUOM, setOptionsUOM] = useState<ItemUnitOfMeasureType[]>([]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

  const onLoadGetLocation = async () => {
    try {
      const optionsLocation = await getCustomerBinsByFacilityId(
        currentLocationAndFacility.customerFacilityId,
      );
      setOptionLocation(
        optionsLocation.map((c: any) => ({
          value: c.binId,
          label: c.name,
        })),
      );

      return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadLicensePlateTypeLookup = async () => {
    try {
      return await getLicensePlate();
    } catch (err) {
      return err;
    }
  };

  const onLoadGetLotNoOnChange = async () => {
    try {
      return await getLotNo(itemId);
    } catch (err) {
      return err;
    }
  };

  const onLoadStatusOnArrival = async () => {
    try {
      return await getStatusArrival();
    } catch (err) {
      return err;
    }
  };

  const onLoadReasonsDetails = async () => {
    try {
      const transferReasonsFromApi = await GetReasonTransferData();

      return transferReasonsFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadGetLocationCrossDock = async () => {
    try {
      const result = await getBinLookupCrossDock(
        currentLocationAndFacility.customerFacilityId,
      );
      setOptionsLocationCrossDock(
        result.map((c: any) => ({
          value: c.binId,
          label: c.name,
        })),
      );

      return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadGetItemUnitOfMeasureDDLOnChange = async () => {
    try {
      return await getItemUnitOfMeasureDDL(itemId);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (isAdjustInventoryModalOpen) {
      setOptionLicensePlateValue(null);
      setLocationValue(null);
      setLotNoValue(null);
      setFormValues(initialForm);
      getItemById(itemId).then((res) => {
        setFormValues({
          skuValue: res.sku,
          totalQty: res.qtyOfPrimaryOUMPerLP,
          isSerialNo: res.isSerialNo,
          serialNo: '',
          warehouseCustomerId: res.warehouseCustomerId,
          isNonBillable: true,
        });
      });

      const optionsLicensePlateType = onLoadLicensePlateTypeLookup();
      optionsLicensePlateType.then((opt) => {
        setOptionLicensePlateTypes(
          opt.map((c: any) => ({
            label: c.name,
            value: c.id,
          })),
        );
      });

      onLoadGetLotNoOnChange().then((x) => {
        setOptionLotNo([]);
        x.map((lot) =>
          setOptionLotNo((prev) => [
            ...prev,
            {
              value: lot.lotId,
              label: lot.lotNo,
              lotCreationDate: lot.creationDate,
              lotExpirationDate: lot.expirationDate,
            },
          ]),
        );
      });

      const optionsStatusOnArrival = onLoadStatusOnArrival();
      optionsStatusOnArrival.then((opt) => {
        setOptionStatusOnArrivalType(
          opt.map((c: any) => ({
            label: c.name,
            value: c.id,
          })),
        );
        if (opt.length !== 0) {
          const result = optionStatusOnArrivalType.find(
            (o) => o.label === 'Available',
          );
          if (result !== undefined) {
            setOptionStatusOnArrivalValue(result);
            setFormValues((prevState) => ({
              ...prevState,
              statusOnArrival: result.value,
            }));
          }
        }
      });

      onLoadReasonsDetails()
        .then((x) => {
          const { reasonDDL } = x;
          setOptionsReasonOnStatusType(
            reasonDDL?.map((dropdown) => ({
              value: dropdown.value,
              label: dropdown.text,
            })),
          );
        })
        .catch((err) => snackActions.error(err));
      onLoadGetItemUnitOfMeasureDDLOnChange().then((x) => {
        setOptionsUOM([]);
        x.map((uom) =>
          setOptionsUOM((prev) => [
            ...prev,
            {
              value: uom.value,
              label: uom.text,
              uomAbbr: uom.uomAbbr,
              isPrimaryUOM: uom.isPrimaryUOM,
              qty: uom.qty,
              primaryUOM: uom.primaryUOM,
              primaryUOMStr: uom.primaryUOMStr,
              primaryUOMAbbr: uom.primaryUOMAbbr,
              uomType: uom.uomType,
              textInPerCase: uom.textInPerCase,
              primaryTextInPerCase: uom.primaryTextInPerCase,
              textInTotal: uom.textInTotal,
            },
          ]),
        );
      });

      onLoadGetLocation();
      onLoadGetLocationCrossDock();
      setReasonOnStatusTypeValue(null);
    }
  }, [isAdjustInventoryModalOpen]);

  const submitForm = async (e: any) => {
    e.preventDefault();
    setLoadingPage(true);
    const temp: any = {};
    const re = /[0-9]+/g;

    temp.location = formValues?.location ? '' : 'This field is required';
    temp.qty = formValues?.qty ? '' : 'This field is required';
    temp.receivedDate = formValues?.receivedDate
      ? ''
      : 'This field is required';

    temp.licensePlateType = formValues?.licensePlateType
      ? ''
      : 'This field is required';

    if (
      optionStatusOnArrivalValue?.value === 5 &&
      formValues.crossDockBinId === ''
    ) {
      temp.locationCrossDock = 'This field is required';
    } else {
      temp.locationCrossDock = '';
    }

    temp.statusOnArrival = optionStatusOnArrivalValue
      ? ''
      : 'This field is required';

    if (optionStatusOnArrivalValue?.value < 5) {
      temp.reasonStatus = formValues.reasonStatus
        ? ''
        : 'This field is required';
    } else {
      temp.reasonStatus = '';
    }
    temp.uomValue = uomValue ? '' : 'This field is required';

    // primary uom
    if (formValues.itemUnitOfMeasureId) {
      if (formValues.totalCases === '' || formValues.totalCases === null) {
        temp.totalCases = 'This field is required';
      } else if (!re.test(formValues.qty)) {
        temp.totalCases = 'Must be a number';
      } else {
        temp.totalCases = '';
      }
    }
    // other uom
    else if (formValues.qty === '' || formValues.qty === null) {
      temp.qty = 'This field is required';
    } else if (!re.test(formValues.qty)) {
      temp.qty = 'Must be a number';
    } else {
      temp.qty = '';
    }
    setFormValueErrors({
      ...temp,
    });
    if (!Object.values(temp).every((x) => x === '')) {
      setLoadingPage(false);
      return;
    }

    const payload = {
      lineItems: [
        {
          itemId,
          qty: formValues.qty,
          totalCases: formValues?.totalCases,
          eachesPerCase: formValues?.eachesPerCase,
          itemUnitOfMeasureId: formValues?.itemUnitOfMeasureId,
          licensePlateType: formValues.licensePlateType,
          lotId: formValues.lotId,
          lotCreationDate: formValues.lotCreationDate
            ? moment(formValues.lotCreationDate).format('MM/DD/YY')
            : '',
          lotExpirationDate: formValues.lotExpirationDate
            ? moment(formValues.lotExpirationDate).format('MM/DD/YY')
            : '',
          statusOnArrival: formValues?.statusOnArrival,
          reasonStatus: formValues?.reasonStatus,
          crossDockBinId: formValues?.crossDockBinId,
          serialNo: formValues.serialNo,
        },
      ],
      customerId: parseInt(currentUser.Claim_CustomerId, 10),
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      warehouseCustomerId: formValues.warehouseCustomerId,
      warehouse: null,
      referenceNo: 'manual',
      binId: formValues.location,
      arrivalDate: moment(formValues.receivedDate).format('MM/DD/YY'),
      isNonBillable: formValues.isNonBillable,
    };

    if (formValues.location === undefined) {
      snackActions.error('Location is required.');
      setLoadingPage(false);
      return;
    }

    if (formValues.qty === undefined) {
      formValues.qty = null;
    }

    if (formValues.qty === null) {
      snackActions.error('Qty is required.');
      setLoadingPage(false);
      return;
    }

    if (formValues.qty !== null && !Number(formValues.qty)) {
      snackActions.error('Qty must be number.');
      setLoadingPage(false);
      return;
    }

    if (formValues.lotNo === undefined || formValues.lotNo === null) {
      formValues.lotNo = '';
    }

    if (formValues.lotNo !== '') {
      if (
        formValues.lotCreationDate === null ||
        formValues.lotCreationDate === undefined ||
        formValues.lotCreationDate === ''
      ) {
        snackActions.error('Lot creation date is required.');
        setLoadingPage(false);
        return;
      }
    }

    if (formValues.licensePlateType === undefined) {
      formValues.licensePlateType = null;
    }

    if (formValues.licensePlateType === null) {
      snackActions.error('License plate is required.');
      setLoadingPage(false);
      return;
    }

    if (formValues.serialNo === undefined) {
      formValues.serialNo = '';
    }

    if (
      formValues.isSerialNo &&
      formValues.serialNo !== '' &&
      Number(formValues.qty) > 1
    ) {
      snackActions.error(
        'Qty value should be 1. Because this sku is using serial no.',
      );
      setLoadingPage(false);
      return;
    }

    try {
      const response = await createReceiptV2(payload);

      await confirmReceipt({
        receiptId: response.receiptId,
      });

      onLoadItemDetailSummary();
      onLoadItemDetailInventory();
      setLoadingPage(false);
      onCloseAdjustInventoryModal();
      setFormValueErrors(null);
      setUomValue(null);
      snackActions.success('Inventory adjusted successfully.');
    } catch (err) {
      snackActions.error(err);
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    if (isAddLotNoUpdateOptions) {
      onLoadGetLotNoOnChange().then((x) => {
        setOptionLotNo([]);
        x.map((lot) =>
          setOptionLotNo((prev) => [
            ...prev,
            {
              value: lot.lotId,
              label: lot.lotNo,
              lotCreationDate: lot.creationDate,
              lotExpirationDate: lot.expirationDate,
            },
          ]),
        );
      });

      setLotNoValue((prev) => ({
        ...prev,
        lotId: addLotNoModalData?.value,
        label: addLotNoModalData?.label,
        value: addLotNoModalData?.value,
      }));

      setFormValues((prevState) => ({
        ...prevState,
        lotId: addLotNoModalData?.value,
        lotNo: addLotNoModalData?.label,
        lotCreationDate: addLotNoModalData?.lotCreationDate,
        lotExpirationDate: addLotNoModalData?.lotExpirationDate,
      }));
    }
  }, [isAddLotNoUpdateOptions]);

  const handleFilterOption = (options: any[], filter) => {
    const result = optionLocation.filter((r) =>
      r.label
        .trim()
        .toLowerCase()
        .includes(filter.inputValue.trim().toLowerCase()),
    );
    return result.sort((a, b) => a.label.localeCompare(b.label));
  };

  return (
    <Modal open={isAdjustInventoryModalOpen}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Add Inventory
        </Typography>
        <MUIContent sx={{ padding: '10px 25px !important' }}>
          <Box>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Autocomplete
                  disableClearable
                  sx={{ width: '100%' }}
                  options={optionLocation}
                  value={locationValue}
                  size="small"
                  filterOptions={handleFilterOption}
                  onChange={(event: any, newValue) => {
                    if (newValue) {
                      setFormValues((prevState) => ({
                        ...prevState,
                        location: newValue.value,
                      }));
                      setLocationValue(newValue);
                    } else {
                      setFormValues((prevState) => ({
                        ...prevState,
                        location: null,
                      }));
                      setLocationValue(null);
                      if (optionLocation.length === 1) {
                        optionLocation.forEach((o) => {
                          setFormValues((e) => ({
                            ...e,
                            location: o.value,
                          }));
                        });
                      }
                    }
                  }}
                  renderOption={(props, option: any) => (
                    <li {...props} key={option.value}>
                      <Box display="flex">
                        <Box display="flex" flexDirection="column">
                          <Typography color="text.primary">
                            {option.label}
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      {...params}
                      label="Location"
                      required
                      {...(formValueErrors?.location && {
                        error: true,
                        helperText: formValueErrors?.location,
                      })}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12} md={4}>
                <TextField
                  required
                  disabled
                  value={formValues.skuValue}
                  id="outlined-required"
                  label="SKU"
                  size="small"
                  sx={{ width: '100%' }}
                />
              </MUIGrid>

              <MUIGrid item xs={4}>
                <Autocomplete
                  disableClearable
                  onChange={(event: any, newValue: ItemUnitOfMeasureType) => {
                    if (newValue !== null) {
                      setUomValue(newValue);
                      if (newValue.isPrimaryUOM) {
                        setFormValues((prevState) => ({
                          ...prevState,
                          primaryUOM: newValue.value,
                          primaryUOMAbbr: newValue.uomAbbr,
                          itemUnitOfMeasureId: null,
                          otherUOMStr: '',
                          otherUOMAbbr: '',
                          otherUOMCount: null,
                          totalCases: null,
                          eachesPerCase: null,
                          qty: null,
                          qtyOfPrimaryOUMPerLP: null,
                        }));
                      } else {
                        setFormValues((prevState) => ({
                          ...prevState,
                          primaryUOM: newValue.primaryUOM,
                          primaryUOMAbbr: newValue.primaryUOMAbbr,
                          primaryTextInPerCase: newValue.primaryTextInPerCase,
                          itemUnitOfMeasureId: newValue.value,
                          otherUOMStr: newValue.label,
                          otherUOMAbbr: newValue.uomAbbr,
                          otherUOMCount: newValue.qty,
                          eachesPerCase: newValue.qty
                            ? newValue.qty.toString()
                            : '0',
                          totalCases: null,
                          qty: null,
                          qtyOfPrimaryOUMPerLP: null,
                        }));
                      }
                    } else {
                      setUomValue(null);
                      setFormValues((prevState) => ({
                        ...prevState,
                        primaryUOM: '',
                        primaryUOMAbbr: '',
                        itemUnitOfMeasureId: '',
                        otherUOMStr: '',
                        otherUOMAbbr: '',
                        otherUOMCount: null,
                        totalCases: null,
                        qty: null,
                        qtyOfPrimaryOUMPerLP: null,
                      }));
                    }
                  }}
                  id="controllable-states"
                  options={optionsUOM}
                  getOptionLabel={(option: ItemUnitOfMeasureType) =>
                    option.label
                  }
                  value={uomValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option: ItemUnitOfMeasureType) => (
                    <li
                      {...props}
                      key={option?.value}
                      data-uomabbr={option?.uomAbbr}
                      data-isprimaryuom={option?.isPrimaryUOM}
                      data-qty={option?.qty}
                      data-primaryuom={option?.primaryUOM}
                      data-primaryuomstr={option?.primaryUOMStr}
                      data-primaryuomabbr={option?.primaryUOMAbbr}
                    >
                      {option?.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      autoComplete="off"
                      required
                      id="outlined-required"
                      label="Units Of Measure"
                      size="small"
                      sx={{ width: '100%' }}
                      {...(formValueErrors?.uomValue && {
                        error: true,
                        helperText: formValueErrors?.uomValue,
                      })}
                    />
                  )}
                />
              </MUIGrid>
              {uomValue && !uomValue?.isPrimaryUOM && (
                <>
                  <MUIGrid item xs={4}>
                    <TextField
                      disabled={uomValue?.isPrimaryUOM}
                      required={uomValue?.isPrimaryUOM === false}
                      onChange={(event: any) => {
                        if (isNumberKey(event)) {
                          setFormValues((prevState) => ({
                            ...prevState,
                            totalCases: event.target.value,
                            qty: (
                              event.target.value *
                              (prevState.eachesPerCase
                                ? Number(prevState.eachesPerCase)
                                : 0)
                            ).toString(),
                          }));
                        }
                      }}
                      value={formValues?.totalCases || ''}
                      {...(formValueErrors?.totalCases && {
                        error: true,
                        helperText: formValueErrors?.totalCases,
                      })}
                      id="outlined"
                      label={`Total ${uomValue?.textInTotal}`}
                      size="small"
                      sx={{ width: '100%' }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                <h5>
                                  Total quantity (i.e. qty of boxes, bags,
                                  cases)
                                </h5>
                              }
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    color: 'white ',
                                    backgroundColor: 'rgb(46, 105, 255)',
                                  },
                                },
                              }}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={4}>
                    <TextField
                      disabled={uomValue?.isPrimaryUOM}
                      required={uomValue?.isPrimaryUOM === false}
                      onChange={(event: any) => {
                        if (isNumberKey(event)) {
                          setFormValues((prevState) => ({
                            ...prevState,
                            eachesPerCase: event.target.value,
                            qty: (
                              event.target.value *
                              (prevState.totalCases
                                ? Number(prevState.totalCases)
                                : 0)
                            ).toString(),
                          }));
                        }
                      }}
                      value={formValues?.eachesPerCase || ''}
                      id="outlined"
                      label={`${uomValue?.primaryTextInPerCase} Per ${uomValue?.textInPerCase}`}
                      size="small"
                      sx={{ width: '100%' }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={<h5>Number of pieces per case</h5>}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    color: 'white ',
                                    backgroundColor: 'rgb(46, 105, 255)',
                                  },
                                },
                              }}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MUIGrid>
                </>
              )}
              {uomValue && (
                <MUIGrid item xs={4}>
                  <TextField
                    disabled={uomValue?.isPrimaryUOM === false}
                    id="outlined-required"
                    required
                    value={formValues?.qty || ''}
                    onChange={(event: any) => {
                      setFormValues((prevState) => ({
                        ...prevState,
                        qty: event.target.value,
                      }));
                    }}
                    label={`Total Qty (${
                      uomValue?.primaryUOMAbbr
                        ? uomValue?.primaryUOMAbbr
                        : uomValue?.uomAbbr
                    })`}
                    size="small"
                    sx={{ width: '100%' }}
                    {...(formValueErrors?.qty && {
                      error: true,
                      helperText: formValueErrors?.qty,
                    })}
                  />
                </MUIGrid>
              )}
            </MUIGrid>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ marginTop: 0.5 }}
            >
              <MUIGrid item md={2.5}>
                <Autocomplete
                  filterOptions={filterOptions}
                  value={lotNoValue}
                  onChange={(event: any, newValue: LotNoType) => {
                    if (newValue !== null) {
                      setLotNoValue(newValue);
                      setFormValues((prevState) => ({
                        ...prevState,
                        lotId: newValue.value,
                        lotNo: newValue.label,
                        lotCreationDate: newValue?.lotCreationDate,
                        lotExpirationDate: newValue?.lotExpirationDate,
                      }));
                    } else {
                      setLotNoValue(null);
                      setFormValues((prevState) => ({
                        ...prevState,
                        lotId: null,
                        lotNo: '',
                        lotCreationDate: null,
                        lotExpirationDate: null,
                      }));
                    }
                  }}
                  id="controllable-states"
                  options={optionLotNo}
                  getOptionLabel={(option: LotNoType) => option.label}
                  defaultValue={optionLotNo?.find(
                    (o: LotNoType) => o.label === lotNoValue?.label,
                  )}
                  isOptionEqualToValue={(
                    option: LotNoType,
                    selected: LotNoType,
                  ) => option.value === selected.value}
                  {...(itemId === null && {
                    disabled: true,
                  })}
                  renderOption={(props, option: LotNoType) => (
                    <li {...props} key={option.value}>
                      <Box display="flex">
                        <Box display="flex" flexDirection="column">
                          <Typography color="text.primary">
                            {option.label}
                          </Typography>
                          <Typography color="text.secondary" variant="body2">
                            {option.lotExpirationDate &&
                              moment(option.lotExpirationDate).format(
                                'MM/DD/YY',
                              )}
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      id="outlined"
                      label="Lot No."
                      size="small"
                      sx={{ width: '100%' }}
                      onChange={(e) => {
                        setFormValues((prevState) => ({
                          ...prevState,
                          lotNo: e.target.value,
                        }));
                      }}
                    />
                  )}
                />
              </MUIGrid>

              <MUIGrid item md={1.5} sx={{ paddingLeft: '10px !important' }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setAddLotNoModalData((prevState) => ({
                      ...prevState,
                      value: null,
                      label: '',
                      lotId: null,
                      lotCreationDate: null,
                      lotExpirationDate: null,
                    }));
                    setIsAddLotNoModalOpen(true);
                    setIsAddLotNoUpdateOptions(false);
                  }}
                  sx={{
                    padding: '8px !important',
                    width: '100%',
                    textTransform: 'capitalize',
                  }}
                >
                  + New LOT NO
                </Button>
              </MUIGrid>
              <MUIGrid item xs={12} sm={12} md={2.5}>
                <Autocomplete
                  disableClearable
                  filterOptions={filterOptions}
                  onChange={(event: any, newValue: LicensePlateType) => {
                    if (newValue !== null) {
                      setOptionLicensePlateValue(newValue);
                      setFormValues((prevState) => ({
                        ...prevState,
                        licensePlateType: newValue.value,
                      }));
                    } else {
                      setOptionLicensePlateValue(null);
                    }
                  }}
                  id="controllable-states"
                  options={optionLicensePlateTypes}
                  value={optionLicensePlateValue}
                  getOptionLabel={(option: LicensePlateType) => option.label}
                  isOptionEqualToValue={(
                    option: LicensePlateType,
                    selected: LicensePlateType,
                  ) => option.value === selected.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      id="outlined"
                      label="License Plate"
                      size="small"
                      sx={{ width: '100%' }}
                      required
                      {...(formValueErrors?.licensePlateType && {
                        error: true,
                        helperText: formValueErrors?.licensePlateType,
                      })}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12} md={2.5}>
                <Autocomplete
                  disableClearable
                  filterOptions={filterOptions}
                  onChange={(event: any, newValue: StatusOnArrivalType) => {
                    if (newValue !== null) {
                      setOptionStatusOnArrivalValue(newValue);
                      setOptionLocationCrossDockValue(null);
                      setFormValues((prevState) => ({
                        ...prevState,
                        statusOnArrival: newValue.value,
                        crossDockBinId: '',
                        reasonStatus: '',
                      }));
                    } else {
                      setOptionStatusOnArrivalValue(null);
                    }
                  }}
                  id="controllable-states"
                  options={optionStatusOnArrivalType || null}
                  value={optionStatusOnArrivalValue}
                  getOptionLabel={(option: any) => option.label}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      id="outlined"
                      label="Status on Arrival"
                      size="small"
                      sx={{ width: '100%' }}
                      {...(formValueErrors?.statusOnArrival && {
                        error: true,
                        helperText: formValueErrors?.statusOnArrival,
                      })}
                      required
                    />
                  )}
                />
              </MUIGrid>
              {optionStatusOnArrivalValue?.value < 5 && (
                <MUIGrid item xs={12} sm={12} md={3}>
                  <Autocomplete
                    onChange={(event: any, newValue: any) => {
                      if (newValue !== null) {
                        setReasonOnStatusTypeValue(newValue);
                        setFormValues((prevState) => ({
                          ...prevState,
                          reasonStatus: newValue.value,
                        }));
                      } else {
                        setReasonOnStatusTypeValue(null);
                        setFormValues((prevState) => ({
                          ...prevState,
                          reasonStatus: '',
                        }));
                      }
                    }}
                    id="controllable-states"
                    options={optionsReasonOnStatusType}
                    getOptionLabel={(option: any) => option.label}
                    value={reasonOnStatusTypeValue}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                        }}
                        id="outlined-required"
                        label="Reason"
                        size="small"
                        sx={{ width: '100%' }}
                        required
                        {...(formValueErrors?.reasonStatus && {
                          error: true,
                          helperText: formValueErrors?.reasonStatus,
                        })}
                      />
                    )}
                  />
                </MUIGrid>
              )}
              {optionStatusOnArrivalValue?.value === 5 && (
                <MUIGrid item xs={12} sm={12} md={3}>
                  <Autocomplete
                    sx={{ width: '100%' }}
                    options={optionsLocationCrossDock}
                    value={optionLocationCrossDockValue}
                    size="small"
                    onChange={(event: any, newValue) => {
                      if (newValue === null || newValue === undefined) {
                        setOptionLocationCrossDockValue(null);
                        setFormValues((prevState) => ({
                          ...prevState,
                          crossDockBinId: '',
                        }));
                      } else {
                        setOptionLocationCrossDockValue(newValue);
                        setFormValues((prevState) => ({
                          ...prevState,
                          crossDockBinId: newValue.value,
                        }));
                      }
                    }}
                    renderInput={(params) =>
                      formValueErrors?.locationCrossDock ? (
                        <TextField
                          sx={{
                            '& .MuiInputBase-root': {
                              background: 'white',
                            },
                          }}
                          {...params}
                          label="Cross Dock Location*"
                          error
                          helperText={formValueErrors?.locationCrossDock}
                        />
                      ) : (
                        <TextField
                          sx={{
                            '& .MuiInputBase-root': {
                              background: 'white',
                            },
                          }}
                          {...params}
                          label="Cross Dock Location*"
                        />
                      )
                    }
                  />
                </MUIGrid>
              )}
            </MUIGrid>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ marginTop: 0.5 }}
            >
              <MUIGrid item xs={4}>
                <DatePickerInput
                  sx={{ width: '100%', background: 'white' }}
                  inputFormat="MM/dd/yyyy"
                  value={formValues?.lotExpirationDate || null}
                  label="Lot Expiration Date"
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid date') {
                      setFormValues((prevState) => ({
                        ...prevState,
                        lotExpirationDate: e,
                      }));
                    } else {
                      setFormValues((prevState) => ({
                        ...prevState,
                        lotExpirationDate: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      error={false}
                      size="small"
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <DatePickerInput
                  sx={{ width: '100%', background: 'white' }}
                  inputFormat="MM/dd/yyyy"
                  value={formValues?.receivedDate || null}
                  label="Received Date"
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid date') {
                      setFormValues((prevState) => ({
                        ...prevState,
                        receivedDate: e,
                      }));
                    } else {
                      setFormValues((prevState) => ({
                        ...prevState,
                        receivedDate: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      error={false}
                      size="small"
                      required
                      {...(formValueErrors?.receivedDate && {
                        error: true,
                        helperText: formValueErrors?.receivedDate,
                      })}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setFormValues((prevState) => ({
                          ...prevState,
                          isNonBillable: event.target.checked,
                        }))
                      }
                      checked={formValues.isNonBillable}
                    />
                  }
                  label="Non-billable"
                />
              </MUIGrid>
            </MUIGrid>

            {formValues.isSerialNo && (
              <MUIGrid
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ marginTop: 0.5 }}
              >
                <MUIGrid item xs={12} sm={12} md={4}>
                  <TextField
                    value={formValues.serialNo}
                    id="outlined-required"
                    label="Serial No."
                    size="small"
                    sx={{ width: '100%' }}
                    onChange={(e) => {
                      setFormValues((prevState) => ({
                        ...prevState,
                        serialNo: e.target.value,
                      }));
                    }}
                  />
                </MUIGrid>
              </MUIGrid>
            )}
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '10px',
                padding: '0px',
                justifyContent: 'flex-end',
              }}
            >
              {loadingPage ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ padding: 1 }}
                    onClick={() => {
                      onCloseAdjustInventoryModal();
                      setLotNoValue(null);
                      setOptionLotNo([]);
                      setFormValues(initialForm);
                      setFormValueErrors(null);
                      setUomValue(null);
                    }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: ' rgb(75, 126, 254) !important',
                      marginRight: '10px',
                    }}
                    onClick={(e) => submitForm(e)}
                    disabled={loadingPage}
                  >
                    SUBMIT
                  </Button>
                </>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
