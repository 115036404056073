import React, { useContext, useEffect, useState } from 'react';

import Button from 'components/button';
import { snackActions } from 'config/snackbar.js';
import { GlobalContext } from 'store/contexts/GlobalContext';

import AddIcon from '@mui/icons-material/Add';
import { Modal, Box, Typography } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

import './styles.css';
import { uploadItemImage } from 'services/api/item/item.api';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 600px;
    border-radius: 4px;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;

interface ModalProps {
  itemId: any;
  reloadImageCallback: (base64Image: any) => void;
}

export default React.memo(({ itemId, reloadImageCallback }: ModalProps) => {
  const { isItemPictureModalOpen, onCloseItemPictureModal } =
    useContext(GlobalContext);

  const uploadImage = (event) => {
    try {
      uploadItemImage(itemId, event.target.files[0]).then((result) => {
        snackActions.success('Image uploaded.');
        onCloseItemPictureModal();

        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function () {
          reloadImageCallback(reader.result);
        };
      });
    } catch (err) {
      snackActions.error(err);
    }
  };

  return (
    <Modal open={isItemPictureModalOpen} onClose={onCloseItemPictureModal}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Image Upload
          </Typography>
          <Box>
            <Button
              sx={{ width: '100%' }}
              variant="contained"
              size="large"
              component="label"
              fileInput
            >
              <AddIcon /> Upload file
              <input
                type="file"
                name="file"
                accept=".jpg, .jpeg, .png"
                multiple
                onChange={uploadImage}
                hidden
              />
            </Button>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
