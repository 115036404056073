import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getNavigationPageList = async () => {
  try {
    const response = await httpClient.get(`/NavigationPage/NavigationPageList`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
