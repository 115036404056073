import React, { useContext } from 'react';

import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Typography, Grid as MUIGrid, Button } from '@mui/material';

export default React.memo(() => {
  const {
    isErrorInventoryImportModalOpen,
    setIsErrorInventoryImportModalOpen,
    errorMessageInventoryImport,
    isSuccessInventoryImportModalOpen,
    setIsSuccessInventoryImportModalOpen,
  } = useContext(GlobalContext);

  return (
    <>
      <Modal open={isErrorInventoryImportModalOpen}>
        <MUIContainer sx={{ width: '40% !important' }}>
          <Typography
            className="modalTextHeaderError"
            variant="h6"
            fontWeight="bold"
          >
            Import Inventory Error
          </Typography>
          <MUIContent sx={{ padding: '0px 20px !important ' }}>
            <Box
              autoComplete="off"
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
              }}
              noValidate
            >
              {isErrorInventoryImportModalOpen &&
                errorMessageInventoryImport &&
                errorMessageInventoryImport.length > 0 && (
                  <ul style={{ padding: '5%' }}>
                    {errorMessageInventoryImport.map((key) => (
                      <li>
                        <Typography variant="body2">{key}</Typography>
                      </li>
                    ))}
                  </ul>
                )}
            </Box>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={4}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={() => {
                    setIsErrorInventoryImportModalOpen(false);
                  }}
                  variant="contained"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                >
                  OK
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
      <Modal open={isSuccessInventoryImportModalOpen}>
        <MUIContainer sx={{ width: '40% !important' }}>
          <Typography
            className="modalTextHeaderSuccess"
            variant="h6"
            fontWeight="bold"
          >
            Import Inventory Success
          </Typography>
          <MUIContent sx={{ padding: '0px 20px !important ' }}>
            <Box
              autoComplete="off"
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
              }}
              noValidate
            >
              <Typography variant="body2">
                {errorMessageInventoryImport}
              </Typography>
            </Box>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={4}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={() => {
                    setIsSuccessInventoryImportModalOpen(false);
                  }}
                  variant="contained"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                >
                  OK
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
    </>
  );
});
