import { ChangeEvent, useState } from 'react';

import { IUserInfo } from 'common/interfaces';
import Input from 'components/input';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';

import { Box, Button, Modal, Typography, Grid as MUIGrid } from '@mui/material';

interface IModalChangePassword {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (data: IChangePasswordData) => void;
  user: IUserInfo;
}

export interface IChangePasswordData {
  userId: number;
  passwordNew: string;
  passwordConfirm: string;
}

export const ModalChangePassword = (props: IModalChangePassword) => {
  const { isOpen, onCancel, onConfirm, user } = props;
  const [error, setError] = useState('');
  const initialFormErrorsState: any = {
    passwordNew: '',
    passwordConfirm: '',
  };
  const initialState: any = {
    passwordNew: '',
    passwordConfirm: '',
  };
  const [formErrors, setFormErrors] = useState(initialFormErrorsState);
  const [form, setForm] = useState<any>(initialState);

  const onForm = (key, text) => {
    setForm(() => ({
      ...form,
      [key]: text,
    }));
  };

  const validateOnlick = (fieldValues = form) => {
    const temp: any = {};

    if ('password' in fieldValues) {
      if (fieldValues.password === '') {
        temp.password = fieldValues.password
          ? ''
          : 'Password field is required';
      }
    }

    if ('passwordConfirm' in fieldValues) {
      if (fieldValues.passwordConfirm === '') {
        temp.passwordConfirm = fieldValues.passwordConfirm
          ? ''
          : 'Password Confirm field is required';
      }
    }

    setFormErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) =>
    onForm(key, event.target.value);

  const onSubmitForm = () => {
    if (validateOnlick()) {
      if (form.passwordNew !== form.passwordConfirm) {
        const temp: any = {
          passwordConfirm: 'Password does not match',
        };
        setFormErrors({
          ...temp,
        });
        return;
      }
      const newData: IChangePasswordData = {
        userId: user.userId,
        passwordNew: form.passwordNew,
        passwordConfirm: form.passwordConfirm,
      };
      setForm(initialState);
      onConfirm(newData);
    }
  };

  const onCancelForm = () => {
    setForm(initialState);
    onCancel();
  };

  return (
    <Modal open={isOpen} onClose={() => onCancelForm()}>
      <MUIContainer sx={{ width: '20% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Change Password
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Input
            sx={{ width: '100%' }}
            placeholder="New Password"
            value={form.passwordNew}
            min={5}
            max={40}
            onChange={(value: any) => inputHandler('passwordNew', value)}
            error={formErrors.passwordNew}
            type="password"
          />
          <Input
            sx={{ width: '100%' }}
            placeholder="Confirm Password"
            value={form.passwordConfirm}
            min={5}
            max={40}
            onChange={(value: any) => inputHandler('passwordConfirm', value)}
            error={formErrors.passwordConfirm}
            type="password"
          />
        </MUIContent>
        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={8}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                sx={{ minHeight: '48px' }}
                variant="text"
                size="large"
                onClick={() => onCancelForm()}
              >
                Cancel
              </Button>
              <Button
                sx={{ minHeight: '48px' }}
                variant="contained"
                size="large"
                onClick={() => onSubmitForm()}
              >
                Submit
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
};
