import { useContext, useEffect, useState } from 'react';

import { DropdownListItem } from 'common/models';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import {
  AutoCompletePaper,
  AutoCompletePopper,
  MUIContainer,
  MUIContent,
} from 'pages/shared/editreceiptmodal';
import { getSkuLookupWithwarehouseCustomer } from 'services/api/salesorders/salesorders-new.api';
import { AuthContext } from 'store/contexts/AuthContext';

import {
  Modal,
  Box,
  Typography,
  Button,
  Grid as MUIGrid,
  Autocomplete,
  TextField,
  InputAdornment,
} from '@mui/material';

import { useWarehouseCustomerContext } from '../index';

export const UOMChargeRateModal = () => {
  const {
    uomChargeRates,
    setUOMChargeRates,
    selectedUOMChargeRate,
    setSelectedUOMChargeRate,
    isUOMChargeRateModal,
    setIsUOMChargeRateModal,
    billingData,
    queryString,
  } = useWarehouseCustomerContext();

  const initialFormStateError = {
    itemType: '',
    itemFee: '',
    itemAdditionalFee: '',
  };

  const [formErrors, setFormErrors] = useState(initialFormStateError);

  type SkuType = ExtendSkuType & DropdownListItem;

  type ExtendSkuType = {
    sku?: string;
    description: string;
  };

  const defaultSkuDropdownValue: SkuType = {
    text: 'All',
    value: 0,
    sku: null,
    description: 'All',
  };

  const [skuValue, setSkuValue] = useState<SkuType>(defaultSkuDropdownValue);

  const [optionSkus, setOptionSkus] = useState<SkuType[] | null | []>([]);

  const { currentUser } = useContext(AuthContext);

  const validateForm = () => {
    const temp = { ...initialFormStateError };

    if (selectedUOMChargeRate?.itemType === null) {
      temp.itemType = 'This field is required';
    } else {
      temp.itemType = '';
    }

    if (selectedUOMChargeRate?.itemFee === null) {
      temp.itemFee = 'This field is required';
    } else if (selectedUOMChargeRate?.itemFee === '') {
      temp.itemFee = 'This field is required';
    } else {
      temp.itemFee = '';
    }

    if (selectedUOMChargeRate?.itemAdditionalFee === null) {
      temp.itemAdditionalFee = 'This field is required';
    } else if (selectedUOMChargeRate?.itemAdditionalFee === '') {
      temp.itemAdditionalFee = 'This field is required';
    } else {
      temp.itemAdditionalFee = '';
    }

    setFormErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleOnClickSaveUOMChargeRate = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    e.preventDefault();

    if (!validateForm()) {
      snackActions.error('Please complete required fields');
    } else {
      let uomChargeRate = null;
      if (selectedUOMChargeRate.rowId !== undefined) {
        uomChargeRate = uomChargeRates.find(
          (x) => x.rowId === selectedUOMChargeRate.rowId,
        );
      }
      // exist in db
      else {
        uomChargeRate = uomChargeRates.find(
          (x) =>
            x.warehouseCustomerHandlingChargeUOMChargeRateId ===
            selectedUOMChargeRate.warehouseCustomerHandlingChargeUOMChargeRateId,
        );
      }

      // add
      if (uomChargeRate === undefined) {
        setUOMChargeRates([...uomChargeRates, { ...selectedUOMChargeRate }]);
      }
      // edit
      else {
        const newArray = [...uomChargeRates];

        let index = null;
        if (selectedUOMChargeRate.rowId !== undefined) {
          index = uomChargeRates.findIndex(
            (v) => v.rowId === selectedUOMChargeRate.rowId,
          );
        }
        // exist in db
        else {
          index = uomChargeRates.findIndex(
            (v) =>
              v.warehouseCustomerHandlingChargeUOMChargeRateId ===
              selectedUOMChargeRate.warehouseCustomerHandlingChargeUOMChargeRateId,
          );
        }

        newArray[index] = { ...selectedUOMChargeRate };
        setUOMChargeRates(newArray);
      }

      setFormErrors({ ...initialFormStateError });
      setSkuValue(defaultSkuDropdownValue);
      setIsUOMChargeRateModal(false);
    }
  };

  useEffect(() => {
    if (isUOMChargeRateModal) {
      const skuLookupFromApi = getSkuLookupWithwarehouseCustomer(
        currentUser.Claim_CustomerId,
        queryString?.id || 0,
      );

      skuLookupFromApi.then((opt) => {
        setOptionSkus(
          opt.map((c: any) => ({
            text: c.sku,
            value: c.itemId,
            description: c.description,
          })),
        );
      });
    }
  }, [isUOMChargeRateModal, queryString?.id, currentUser.Claim_CustomerId]);

  const handleFilterOption = (options: any[], filter) => {
    const result = options.filter(
      (r) =>
        r.text.toLowerCase().includes(filter.inputValue.toLowerCase()) ||
        r.description.toLowerCase().includes(filter.inputValue.toLowerCase()),
    );
    return result;
  };

  return (
    <Modal open={isUOMChargeRateModal}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Charge Rate by Item Type
        </Typography>
        <MUIContent>
          <Box autoComplete="off" component="form" noValidate>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Autocomplete
                  onChange={(event: any, newValue: SkuType) => {
                    if (newValue !== null) {
                      setSkuValue(newValue);
                      setSelectedUOMChargeRate((prev) => ({
                        ...prev,
                        sku: newValue,
                      }));
                    } else {
                      setSkuValue(defaultSkuDropdownValue);
                      setSelectedUOMChargeRate((prev) => ({
                        ...prev,
                        sku: null,
                      }));
                    }
                  }}
                  disableClearable
                  aria-required
                  id="controllable-states"
                  options={optionSkus}
                  getOptionLabel={(option: SkuType) => option.text}
                  value={skuValue || null}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  filterOptions={handleFilterOption}
                  renderOption={(props, option) => (
                    <li {...props} key={props.id}>
                      <div className="td tw-140">{option.text}</div>
                      <div className="td">{option.description}</div>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="SKU"
                      size="small"
                    />
                  )}
                  PopperComponent={AutoCompletePopper}
                  PaperComponent={AutoCompletePaper}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Autocomplete
                  disableClearable
                  size="small"
                  value={selectedUOMChargeRate?.itemType}
                  onChange={(event, newValue: DropdownListItem) => {
                    setSelectedUOMChargeRate((e) => ({
                      ...e,
                      itemType: newValue,
                    }));
                  }}
                  id="controllable-states"
                  options={billingData.storageCharges_AddEditCharge_UOMDDL}
                  getOptionLabel={(option: DropdownListItem) => option.text}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option: DropdownListItem) => (
                    <li {...props} key={option.value}>
                      {option.text}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      label="UOM"
                      {...(formErrors.itemType && {
                        error: true,
                        helperText: formErrors.itemType,
                      })}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={2}>
                <Input
                  type="number"
                  placeholder="First Item Fee"
                  size="small"
                  required
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={selectedUOMChargeRate?.itemFee}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setSelectedUOMChargeRate((prev) => ({
                      ...prev,
                      itemFee: validateDecimal,
                    }));
                  }}
                  InputProps={{
                    autoComplete: 'off',
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  error={formErrors.itemFee}
                />
              </MUIGrid>
              <MUIGrid item xs={2}>
                <Input
                  type="number"
                  placeholder="Additional Item Fee"
                  size="small"
                  required
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={selectedUOMChargeRate?.itemAdditionalFee}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setSelectedUOMChargeRate((prev) => ({
                      ...prev,
                      itemAdditionalFee: validateDecimal,
                    }));
                  }}
                  InputProps={{
                    autoComplete: 'off',
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  error={formErrors.itemAdditionalFee}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => {
                  setFormErrors({ ...initialFormStateError });
                  setSkuValue(defaultSkuDropdownValue);
                  setIsUOMChargeRateModal(false);
                }}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                CANCEL
              </Button>

              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={(e) => {
                  handleOnClickSaveUOMChargeRate(e);
                }}
              >
                SAVE
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
};
