import { styled } from '@mui/material/styles';

export const ContainerFlat = styled('div')(() => ({
  padding: 0,
  margin: 0,
  background: '#ffffff',
}));

export const ContainerFlexBetween = styled('div')(() => ({
  padding: '7px',
  display: 'flex',
  justifyContent: 'space-between',
}));
