/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';

import Button from 'components/button';
import { useSubpageReceiptsContext } from 'pages/receiving/receipts/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Typography, Grid as MUIGrid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface ConfirmationModalProps {
  dialogText: string;
  poId: number;
}

export default React.memo(({ dialogText, poId }: ConfirmationModalProps) => {
  const theme = useTheme();

  const { isConfirmLinkedPOModalOpen, onCloseConfirmLinkedPOModal } =
    useContext(GlobalContext);
  const { onLoadPurchaseOrderDetailById, setPoIdLinkedLineitemValue } =
    useSubpageReceiptsContext();

  const handleModalClose = () => {
    onCloseConfirmLinkedPOModal();
  };

  const handleConfirmClick = async () => {
    onLoadPurchaseOrderDetailById(poId);
    onCloseConfirmLinkedPOModal();
    setPoIdLinkedLineitemValue(null);
  };

  if (!isConfirmLinkedPOModalOpen) return null;

  return (
    <Modal open={isConfirmLinkedPOModalOpen} onClose={handleModalClose}>
      <MUIContainer sx={{ maxWidth: '500px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Link Packing List
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{ marginBottom: '16px' }}
              variant="h6"
              fontWeight="bold"
            >
              {dialogText}
            </Typography>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={8}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{
                  padding: 1,
                  width: '70%',
                  textTransform: 'capitalize !important',
                }}
                onClick={() => handleModalClose()}
              >
                No
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                  textTransform: 'capitalize !important',
                }}
                onClick={() => handleConfirmClick()}
              >
                Yes, Continue
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
