import React, { useEffect } from 'react';
import Barcode from 'react-barcode/lib/react-barcode';

import { PrintContainer, PrintDivider } from 'components/styles';
import moment from 'moment';

import { Typography, Box } from '@mui/material';

export const SinglePrintLineItemV2 = React.forwardRef(
  (props: any, ref: any) => {
    useEffect(() => {
      props.data.button?.click();
    }, [props.data]);

    return (
      <PrintContainer
        ref={ref}
        sx={{ display: 'block', pageBreakBefore: 'always' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              paddingY: '24px',
            }}
          >
            <Typography variant="h4">{props.data?.customer}</Typography>
            <Typography variant="subtitle1">
              {`Reference No: ${props.data?.reference}`}
            </Typography>
            <Typography variant="subtitle1">
              {`Received: ${moment(props.data?.arrivalDate).format('ll')}`}
            </Typography>
            <Barcode
              value={
                props.data?.licensePlateNo === undefined
                  ? '00000000'
                  : props.data?.licensePlateNo
              }
              width={5}
              height={120}
              fontSize={100}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '95%',
              }}
            >
              <Box
                sx={{
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                SKU
              </Box>
              <Box
                sx={{
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                Cases
              </Box>
              <Box
                sx={{
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'start',
                  width: '20%',
                }}
              >
                Ea/Cases
              </Box>
              <Box
                sx={{
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'start',
                  width: '20%',
                }}
              >
                Total Qty
              </Box>
              <Box
                sx={{
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                Lot No.
              </Box>
              <Box
                sx={{
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                Exp. Date
              </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '95%' }}>
              <PrintDivider />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '95%',
                fontSize: '12px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                {props.data?.sku ? props.data?.sku : '-'}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                {props.data?.totalCases ? props.data?.totalCases : '-'}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  width: '20%',
                }}
              >
                {props.data?.eaCase ? props.data?.eaCase : '-'}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  width: '20%',
                }}
              >
                {props.data?.each ? props.data?.each : '-'}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                {props.data?.lot?.lotNo ? props.data?.lot?.lotNo : '-'}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                {props.data?.lot?.lotExpirationDate
                  ? moment(props.data?.lot?.lotExpirationDate).format(
                      'MM/DD/YYYY',
                    )
                  : '-'}
              </Box>
            </Box>
          </Box>
        </Box>
      </PrintContainer>
    );
  },
);

export const PrintAllLineItemV2 = React.forwardRef((props: any, ref: any) => {
  useEffect(() => {
    // props.data.button?.click();
    if (props.data?.lps !== undefined) {
      props.data.button?.click();
    }
  }, [props.data]);

  return (
    <PrintContainer ref={ref} sx={{ display: 'block' }}>
      {props.data?.lps?.map((x) => (
        <Box
          key={x.receiptLineItemId}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            pageBreakBefore: 'always',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              paddingY: '24px',
            }}
          >
            <Typography variant="subtitle1">
              {`Reference No: ${x.reference}`}
            </Typography>
            <Typography variant="subtitle1">
              {`Received: ${moment(x?.arrivalDate).format('ll')}`}
            </Typography>
            <Barcode
              value={
                x.licensePlateNo === undefined ? '00000000' : x.licensePlateNo
              }
              width={5}
              height={120}
              fontSize={100}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '95%',
              }}
            >
              <Box
                sx={{
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                SKU
              </Box>
              <Box
                sx={{
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                Cases
              </Box>
              <Box
                sx={{
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'start',
                  width: '20%',
                }}
              >
                Ea/Cases
              </Box>
              <Box
                sx={{
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'start',
                  width: '20%',
                }}
              >
                Total Qty
              </Box>
              <Box
                sx={{
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                Lot No.
              </Box>
              <Box
                sx={{
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                Exp. Date
              </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '95%' }}>
              <PrintDivider />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '95%',
                fontSize: '12px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                {x.sku ? x.sku : '-'}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                {x.totalCases ? x.totalCases : '-'}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  width: '20%',
                }}
              >
                {x.eaCase ? x.eaCase : '-'}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  width: '20%',
                }}
              >
                {x.each ? x.each : '-'}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                {x?.lot?.lotNo ? x?.lot?.lotNo : '-'}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  width: '15%',
                }}
              >
                {x?.lot?.expirationDate
                  ? moment(x?.lot?.expirationDate).format('MM/DD/YYYY')
                  : '-'}
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </PrintContainer>
  );
});
