import React, { ReactNode } from 'react';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/system';

import Card from '..';

export interface tabProps {
  title: ReactNode;
  value: string;
  isDisplay: boolean;
  children: ReactNode;
}

interface CardProps {
  tabView: tabProps[];
  sx?: SxProps<Theme>;
  tabValue: string;
  handleChange: (event: React.SyntheticEvent, newValue: string) => void;
}

function CardTab({ tabView, sx, tabValue, handleChange }: CardProps) {
  return (
    <Card sx={sx}>
      <TabContext value={tabValue}>
        <TabList
          onChange={handleChange}
          sx={{
            background: 'rgb(238 238 238)',
            borderBottom: '1px solid #cdcdcd',
          }}
          variant="scrollable"
        >
          {tabView.map((row) => (
            <Tab
              label={
                <Typography
                  fontWeight="bold"
                  sx={{
                    textTransform: 'capitalize !important',
                  }}
                >
                  {row.title}
                </Typography>
              }
              value={row.value}
              sx={{
                background: 'rgb(238 238 238)',
                display: row.isDisplay ? 'block' : 'none',
                '&.Mui-selected': {
                  background: 'white',
                  color: '#3375e0',
                  borderBottom: 'none',
                  borderRadius: 2,
                },
              }}
            />
          ))}
        </TabList>
        {tabView.map((row) => (
          <TabPanel className="tabcontent" value={row.value}>
            {row.children}
          </TabPanel>
        ))}
      </TabContext>
    </Card>
  );
}

export default React.memo(CardTab);
