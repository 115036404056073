import React, { useState, useCallback, useContext, useEffect } from 'react';

import { JobStatus, useProductionContext } from 'pages/production/context';
import { GlobalContext } from 'store/contexts/GlobalContext';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const ProductionJobStatusDropdown = () => {
  const { onOpenPickTicketSalesOrderModal, onOpenProductionReportModal } =
    useContext(GlobalContext);

  const {
    changeStatusSalesOrderOnClick,
    productionJobFlagStatus,
    getProductionPickTicketOnClick,
    setProductionReportModal,
    pageViewer,
  } = useProductionContext();

  const initialFormProductReport = {
    customerLocationId: 0,
    jobId: 0,
    qtyComplete: 0,
    startDateTime: '',
    startTime: '',
    completeDateTime: '',
    completeTime: '',
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {productionJobFlagStatus?.status !== JobStatus.New &&
        !pageViewer.isNew() && (
          <Button
            variant="text"
            size="large"
            onClick={() => {
              setProductionReportModal(initialFormProductReport);
              onOpenProductionReportModal();
            }}
          >
            Update Production Report
          </Button>
        )}
      <Button
        sx={{
          marginLeft: '2px !important',
          padding: 1,
          // background: '#FFFFFF',
          // borderColor: '#DADCE0',
          // color: '#3375E0',
          // fontWeight: 600,
        }}
        id="customized-button"
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {productionJobFlagStatus?.statusStr}
      </Button>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          'aria-labelledby': 'customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={(e) => {
            handleClose();
            onOpenPickTicketSalesOrderModal();
            getProductionPickTicketOnClick();
            // changeStatusSalesOrderOnClick(e, JobStatus.Open);
          }}
          disableRipple
        >
          Open
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleClose();
            changeStatusSalesOrderOnClick(e, JobStatus.OnHold);
          }}
          disableRipple
        >
          Hold
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleClose();
            changeStatusSalesOrderOnClick(e, JobStatus.Canceled);
          }}
          disableRipple
        >
          Cancel
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleClose();
            changeStatusSalesOrderOnClick(e, JobStatus.Close);
          }}
          disableRipple
        >
          Close
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

export default React.memo(ProductionJobStatusDropdown);
