import React, { useContext, useState, useEffect } from 'react';

import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { checkedIcon, icon } from 'pages/settings/tabviews/InboundSection';
import { getCustomerFacilitiesByCustomerId } from 'services/api/customerfacilities/customerfacilities.api';
import {
  getShopifyIntegrationDetail,
  createShopifyIntegrationWithAccessToken,
  updateShopifyIntegration,
  getShopifyLocations,
  shopifyOrdersSyncUp,
  pushInventoryQty,
  enableShopifyIntegration,
  disableShopifyIntegration,
  enableAutoSync,
  disableAutoSync,
} from 'services/api/integrations/integrations.api';
import { getOrderSourceList } from 'services/api/salesorders/salesorders-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  Modal,
  Box,
  Typography,
  Autocomplete,
  Grid as MUIGrid,
  Button,
  Switch,
  IconButton,
  Checkbox,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import SyncOrderModal from './syncorder';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 900px;
    border-radius: 20px;
    max-height: 80%;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 40px;
    gap: 16px;
    overflow-y: auto;
    height: 100%;
  }
`;

export default React.memo(() => {
  const {
    isIntegrationModalOpen,
    onCloseIntegrationModal,
    isSyncOrderModalOpen,
    setIsSyncOrderModalOpen,
  } = useContext(GlobalContext);

  const {
    form,
    selectedIntegrationId,
    setSelectedIntegrationId,
    setIsSyncInventoryModalOpen,
  } = useWarehouseCustomerContext();

  const initialFormErrorsState: any = {};
  const initialState: any = {
    storeUrl: '',
    customerId: null,
    warehouseCustomerId: null,
    customerLocationId: null,
    accessToken: '',
    orderSource: null,
    locationId: null,
    isActive: true,
    autoSync: true,
    ignoreMissingItems: false,
    trackingInfoUpdate: true,
  };
  type AutocompleteBase = {
    label?: string;
    value?: number;
    auxValue?: number;
  };

  const [formErrors, setFormErrors] = useState<any>(initialFormErrorsState);
  const [internalForm, setForm] = useState<any>(initialState);
  const [error, setError] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [customerFacilitiesValue, setCustomerFacilitiesValue] =
    useState<AutocompleteBase>(null);
  const [orderSourceValue, setOrderSourceValue] =
    useState<AutocompleteBase>(null);
  const [optionsCustomerFacility, setOptionCustomerFacility] = useState<
    AutocompleteBase[] | null | []
  >([]);
  interface AutoCompleteOptionType {
    id: number;
    label: string;
  }

  const [locationOptions, setLocationOptions] = useState<
    AutoCompleteOptionType[]
  >([]);

  const [selectedLocation, setSelectedLocation] = useState<any[]>([]);
  const { currentUser } = useContext(AuthContext);

  const handleCloseIntegrationModal = () => {
    setForm(initialState);
    setFormErrors(initialFormErrorsState);
    setError('');
    onCloseIntegrationModal();
    setSelectedIntegrationId(null);
    setCustomerFacilitiesValue(null);
    setOrderSourceValue(null);
    setSelectedLocation(null);
  };

  const autoCompleteOnChange = (
    e: any,
    item: AutocompleteBase,
    custom: any = null,
  ): void => {
    e.preventDefault();

    const { dataset } = e.target;
    setForm((prev) => ({
      ...prev,
      [dataset.name]: item.value,
      customerLocationId: item.auxValue,
    }));
  };

  const inputHandler = (inputName, value) => {
    setForm(() => ({
      ...internalForm,
      [inputName]: value.target.value,
    }));
  };

  const requestShopifyAuthorizationUrlFromApi = () => {
    const payload = {
      storeUrl: internalForm.storeUrl,
      customerId: form.customerId || currentUser.Claim_CustomerId,
      warehouseCustomerId: form.warehouseCustomerId,
      customerLocationId: internalForm.customerLocationId,
      customerFacilityId: internalForm.customerFacilityId,
      accessToken: internalForm.accessToken,
      orderSourceId: internalForm.orderSourceId,
      orderSource: internalForm.orderSourceId,
      locationId: selectedLocation.map((x) => x.value),
      ignoreMissingItems: internalForm.ignoreMissingItems,
      trackingInfoUpdate: internalForm.trackingInfoUpdate,
    };

    if (selectedIntegrationId && selectedIntegrationId > 0) {
      updateShopifyIntegration(selectedIntegrationId, payload).then(() => {
        handleCloseIntegrationModal();
        snackActions.success('Integration updated.');
      });
    } else {
      createShopifyIntegrationWithAccessToken(payload)
        .then(() => {
          handleCloseIntegrationModal();
          snackActions.success('Integration created.');
        })
        .catch((err) => {
          snackActions.error(err);
        });
    }
  };

  const [optionOrderSource, setOptionOrderSource] = useState<
    AutocompleteBase[] | null | []
  >([]);

  const [modalTitle, setModalTitle] = useState('Add New');

  useEffect(() => {
    if (isIntegrationModalOpen) {
      getCustomerFacilitiesByCustomerId(
        form.customerId || currentUser.Claim_CustomerId,
      )
        .then((opt) => {
          setOptionCustomerFacility(
            opt.map((c: any) => ({
              label: c.name,
              value: c.customerFacilityId,
              auxValue: c.customerLocationId,
            })),
          );
        })
        .catch((err) => {
          console.log(err);
        });

      const optOrderSources = getOrderSourceList(form.warehouseCustomerId || 0);

      optOrderSources
        .then((opt) => {
          setOptionOrderSource(
            opt.map((c: any) => ({
              label: c.text,
              value: c.value,
            })),
          );
        })
        .catch();

      if (selectedIntegrationId) {
        setModalTitle('Edit');
        getShopifyLocations(selectedIntegrationId).then((res) => {
          setLocationOptions(
            res.map((c: any) => ({
              value: c.value,
              label: c.text,
            })),
          );

          getShopifyIntegrationDetail(selectedIntegrationId).then((details) => {
            setForm(() => ({
              ...internalForm,
              ...details,
              orderSourceId: details.orderSource,
            }));
            setCustomerFacilitiesValue({
              label: details.customerFacilityName,
              value: details.customerFacilityId,
              auxValue: details.customerLocationId,
            });
            setOrderSourceValue({ label: details.orderSource, value: 0 });

            if (details.locationId) {
              const selectedValues = [];
              for (let i = 0; i < details.locationId.length; i += 1) {
                const selectedValue = res.filter(
                  (c) => c.value === details.locationId[i],
                )[0];

                if (selectedValue) {
                  selectedValues.push(selectedValue);
                }
              }

              setSelectedLocation(
                selectedValues.map((x) => ({
                  value: x.value,
                  label: x.text,
                })),
              );
            }
          });
        });
      }
    }
  }, [
    isIntegrationModalOpen,
    form.customerId,
    form.warehouseCustomerId,
    selectedIntegrationId,
  ]);

  const handleRefreshLocation = () => {
    if (selectedIntegrationId > 0) {
      getShopifyLocations(selectedIntegrationId).then((res) => {
        setLocationOptions(
          res.map((c: any) => ({
            value: c.value,
            label: c.text,
          })),
        );
      });
    }
  };

  const syncShopifyOrders = async () => {
    setIsSyncOrderModalOpen(true);
  };

  const handlePushInventory = () => {
    if (selectedIntegrationId > 0) {
      pushInventoryQty(selectedIntegrationId).then(() =>
        snackActions.success('Inventory synced successfully.'),
      );
    }
  };

  const handlePullInventory = () => {
    if (selectedIntegrationId > 0) {
      setSelectedIntegrationId(selectedIntegrationId);
      setIsSyncInventoryModalOpen(true);
    }
  };

  const toggleShopifyIntegrationActive = async (selected) => {
    setForm((e) => ({
      ...e,
      isActive: selected,
    }));

    if (selectedIntegrationId > 0) {
      if (selected) {
        try {
          await enableShopifyIntegration(selectedIntegrationId);

          snackActions.success('Integration enabled.');
        } catch (err) {
          snackActions.error(err);
          setForm((e) => ({
            ...e,
            isActive: !selected,
          }));
        }
      } else {
        await disableShopifyIntegration(selectedIntegrationId, false);

        snackActions.success('Integration disabled.');
      }
    }
  };

  const toggleIgnoreMissingItems = async (selected) => {
    setForm((e) => ({
      ...e,
      ignoreMissingItems: selected,
    }));
  };

  const toggleShopifyAutoSync = async (selected) => {
    setForm((e) => ({
      ...e,
      autoSync: selected,
    }));

    if (selectedIntegrationId > 0) {
      if (selected) {
        await enableAutoSync(selectedIntegrationId);

        snackActions.success('Auto sync enabled.');
      } else {
        await disableAutoSync(selectedIntegrationId);

        snackActions.success('Auto sync disabled.');
      }
    }
  };

  return (
    <Modal
      open={isIntegrationModalOpen}
      onClose={() => handleCloseIntegrationModal()}
    >
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          {modalTitle} Connection
        </Typography>
        <MUIContent>
          <>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <Input
                  placeholder="Store URL"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={internalForm.storeUrl}
                  onChange={(value) => inputHandler('storeUrl', value)}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Autocomplete
                  onChange={(event: any, newValue: AutocompleteBase) => {
                    if (newValue !== null) {
                      setCustomerFacilitiesValue(newValue);
                      autoCompleteOnChange(event, newValue);
                    } else {
                      setCustomerFacilitiesValue(null);
                      autoCompleteOnChange(event, null, {
                        name: 'customerFacilityId',
                        value: undefined,
                      });
                    }
                  }}
                  id="controllable-states"
                  options={optionsCustomerFacility}
                  getOptionLabel={(option: AutocompleteBase) => option.label}
                  value={customerFacilitiesValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li
                      data-name="customerFacilityId"
                      {...props}
                      key={option.value}
                    >
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      {...(formErrors.customer && {
                        error: true,
                        helperText: formErrors.customer,
                      })}
                      id="outlined-required"
                      label="Client Facility"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <Input
                  placeholder="Access Token"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={internalForm.accessToken}
                  onChange={(value) => inputHandler('accessToken', value)}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Autocomplete
                  onChange={(event: any, newValue: AutocompleteBase) => {
                    if (newValue !== null) {
                      setOrderSourceValue(newValue);
                      autoCompleteOnChange(event, newValue);
                    } else {
                      setOrderSourceValue(null);
                      autoCompleteOnChange(event, {
                        value: undefined,
                        auxValue: undefined,
                      });
                    }
                  }}
                  id="controllable-states"
                  options={optionOrderSource}
                  getOptionLabel={(option: AutocompleteBase) => option.label}
                  value={orderSourceValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li data-name="orderSourceId" {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      autoComplete="off"
                      {...(formErrors.orderSource && {
                        error: true,
                        helperText: formErrors.orderSource,
                      })}
                      id="outlined-required"
                      size="small"
                      sx={{ width: '100%' }}
                      label="Source"
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={11}>
                <Autocomplete
                  id="checkboxes-tags-demo"
                  options={locationOptions}
                  value={selectedLocation || []}
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option: AutoCompleteOptionType) =>
                    option.label
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  onChange={(event: any, newValue) => {
                    if (newValue !== null) {
                      setSelectedLocation(newValue);
                    } else {
                      setSelectedLocation(null);
                    }
                    setForm((e) => ({
                      ...e,
                      locationId: newValue,
                    }));
                  }}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      placeholder="Location"
                      required
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={1}>
                <IconButton onClick={handleRefreshLocation}>
                  <AutorenewIcon />
                </IconButton>
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={11}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Enabled
                </Typography>
                <Typography variant="caption">
                  (If disabled, no order will be downloaded and no quantity will
                  sync)
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={1}>
                <Switch
                  checked={internalForm.isActive}
                  onChange={(v) => {
                    toggleShopifyIntegrationActive(v.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </MUIGrid>
            </MUIGrid>

            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={11}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Ignore Missing Items
                </Typography>
                <Typography variant="caption">
                  (Create the order and ignore any missing items in the Packem.)
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={1}>
                <Switch
                  checked={internalForm.ignoreMissingItems}
                  onChange={(v) => {
                    toggleIgnoreMissingItems(v.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </MUIGrid>
            </MUIGrid>

            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={11}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Enabled Automatic Inventory Sync
                </Typography>
                <Typography variant="caption">
                  (If disabled, no inventory will sync)
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={1}>
                <Switch
                  checked={internalForm.autoSync}
                  onChange={(v) => {
                    toggleShopifyAutoSync(v.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </MUIGrid>
            </MUIGrid>

            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={11}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Enable Order Tracking Updates
                </Typography>
                <Typography variant="caption">
                  (If disabled, no tracking updates will be sent.)
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={1}>
                <Switch
                  checked={internalForm.trackingInfoUpdate}
                  onChange={(v) => {
                    setForm((e) => ({
                      ...e,
                      trackingInfoUpdate: v.target.checked,
                    }));
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </MUIGrid>
            </MUIGrid>

            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={9}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Manual Inventory Sync
                </Typography>
                <Typography variant="caption">
                  (Manually sync inventory to Shopify)
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    handlePushInventory();
                  }}
                  disabled={!selectedIntegrationId}
                >
                  Sync Inventory Now
                </Button>
              </MUIGrid>
            </MUIGrid>

            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={8}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Sync down Inventory and Orders
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    handlePullInventory();
                  }}
                  disabled={!selectedIntegrationId}
                >
                  Sync Inventory
                </Button>
              </MUIGrid>

              <MUIGrid item xs={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    syncShopifyOrders();
                  }}
                  disabled={!selectedIntegrationId}
                >
                  Sync Orders
                </Button>
              </MUIGrid>
            </MUIGrid>

            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    onClick={() => {
                      handleCloseIntegrationModal();
                    }}
                    variant="outlined"
                    size="small"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      padding: 1,
                      width: '100%',
                      backgroundColor: ' rgb(75, 126, 254) !important',
                    }}
                    onClick={() => {
                      requestShopifyAuthorizationUrlFromApi();
                    }}
                  >
                    SUBMIT
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
            <SyncOrderModal />
          </>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
