import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

interface CustomerInventoryGetProps {
  customerId: string;
  warehouseCustomerId?: string;
  customerFacilityId: string;
  showZeroInventory: boolean;
  showItemView: boolean;
  searchString?: string;
}

export const getCustomerInventoryByCustomerId = async ({
  customerId,
  warehouseCustomerId,
  customerFacilityId,
  showZeroInventory,
  showItemView,
  searchString,
}: CustomerInventoryGetProps) => {
  try {
    const response = await httpClient.get(
      `/Item/Lookup/${customerId}/${customerFacilityId}/${showZeroInventory}/${showItemView}`,
      {
        params: {
          warehouseCustomerId,
          showLoading: true,
        },
      },
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getCustomerInventoryFiltered = async (modelSearch) => {
  try {
    const response = await httpClient.post(
      `/Item/GetItemLookupFiltered`,
      modelSearch,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

interface CustomerInventoryInfoGetProps {
  customerId: string;
  customerFacilityId: string;
  showZeroInventory: boolean;
  warehouseCustomer?: string;
}

export const getTransferDetails = async (
  customerLocationId,
  customerFacilityId,
  inventoryBinDetailId,
) => {
  try {
    const response = await httpClient.get(
      `/Transfer/Createtransfer/Data/${customerLocationId}/${customerFacilityId}/${inventoryBinDetailId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getGetBinLookupTransfer = async (zoneId, itemId, searchText?) => {
  try {
    const response = await httpClient.get(
      `/Bin/Transfer/Lookup/${zoneId}/${itemId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// //if transfer type === pallet ? ignore LPN Dropdown
// //if transfer type === eaches ? show LPN  Dropdown if value 2
export const getGetLPNLookupTransfer = async (binId, itemId) => {
  try {
    const response = await httpClient.get(
      `/Pallet/ItemInBin/${binId}/${itemId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const createItem = async (itemData) => {
  try {
    const response = await httpClient.post(`/Item/CreateItem`, itemData);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateItemExpirationDate = async (itemData) => {
  try {
    const response = await httpClient.post(
      `/Item/Update/ExpirationDate`,
      itemData,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateItemThreshold = async (itemData) => {
  try {
    const response = await httpClient.post(`/Item/Update/Threshold`, itemData);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteItem = async (deleteForm) => {
  try {
    const response = await httpClient.post(`/Item/Delete`, deleteForm);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getItemById = async (itemId) => {
  try {
    const response = await httpClient.get(`/Item/${itemId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const searchItemBySKU = async (customerId, itemSKU) => {
  try {
    const response = await httpClient.get(
      `/Item/Lookup/SKU/${customerId}?sku=${itemSKU}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getItemDetail = async (customerId, customerFacilityId, itemId) => {
  try {
    const response = await httpClient.get(
      `/Item/Detail/${customerId}/${customerFacilityId}/${itemId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getItemLookup = async (customerId, searchText) => {
  try {
    const response = await httpClient.get(
      `/Item/Lookup/BySKU/${customerId}?searchText=${searchText}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/item/lookup/bycarton
export const GetItemLookupByCarton = async () => {
  try {
    const response = await httpClient.get(`/Item/Lookup/ByCarton`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createItemTransfer = async (transferForm) => {
  try {
    const response = await httpClient.post(
      `/Transfer/CreateTransfer`,
      transferForm,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// V2 item endpoints
export const getItemLookupBySku = async (customerId, searchText) => {
  try {
    const response = await httpClient.get(
      `/Item/Lookup/bysku/${customerId}?searchText=${searchText}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// get Reason option for Transfer and Adjusting
export const GetReasonTransferData = async () => {
  try {
    const response = await httpClient.get(`/transfer/GetReasonTransferData`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const importInventory = async (model) => {
  try {
    const response = await httpClient.post(`/Item/ImportInventory`, model);

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

// create create supplier
export const createItemSupplierFromInventory = async (model) => {
  try {
    const response = await httpClient.post(`/Item/itemSupplier/create`, model);

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const uploadItemImage = async (itemId, file) => {
  try {
    const formData = new FormData();

    formData.append('file', file);

    const response = await httpClient.post(
      `item/image/upload/${itemId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteItemImage = async (itemId) => {
  try {
    const response = await httpClient.post(`item/image/delete/${itemId}`, null);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/item/ddl/itemunitofmeasure/{itemId}
export const getItemUnitOfMeasureDDL = async (itemId) => {
  try {
    const response = await httpClient.get(
      `/Item/DDL/ItemUnitOfMeasure/${itemId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// GET /api/item/ddl/itemunitofmeasurewithpallet/{itemId}
export const getItemUnitOfMeasureWithPalletDDL = async (itemId) => {
  try {
    const response = await httpClient.get(
      `/Item/DDL/ItemUnitOfMeasureWithPallet/${itemId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// GET /api/item/ddl/itemserialnumber/{itemId}/{customerFacilityId}
export const GetItemSerialNumberDDL = async (
  itemId: number,
  customerFacilityId?: number,
) => {
  try {
    const response = await httpClient.get(
      `/Item/DDL/ItemSerialNumber/${itemId}/${customerFacilityId ?? 0}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// GET /api/item/ddl/itempalletinventory/{itemId}/{customerFacilityId}
export const GetItemPalletInventoryDDL = async (
  itemId: number,
  customerFacilityId?: number,
) => {
  try {
    const response = await httpClient.get(
      `/Item/DDL/ItemPalletInventory/${itemId}/${customerFacilityId ?? 0}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// GET /api/item/pickinguom/{itemId}/{orderQty}
export const getItemPickingUOM = async (itemId, orderQty) => {
  try {
    const response = await httpClient.get(
      `/Item/PickingUOM/${itemId}/${orderQty}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// GET /api/item/ddl/itemaspackage?warehouseCustomer={warehouseCustomerId}
export const GetItemAsPackageDDL = async (warehouseCustomerId?: number) => {
  try {
    const response = await httpClient.get(
      `/Item/DDL/ItemAsPackage?warehouseCustomerId=${warehouseCustomerId ?? 0}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const checkItemOnPackingListImport = async (model) => {
  try {
    const response = await httpClient.post(
      `item/CheckItemOnPackingListImport`,
      model,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/item/ddl/inventorybindetail/itemunitofmeasure/{inventoryBinDetailId}
export const GetInventoryBinDetailItemUnitOfMeasureDDL = async (
  inventoryBinDetailId: number,
) => {
  try {
    const response = await httpClient.get(
      `/Item/DDL/InventoryBinDetail/ItemUnitOfMeasure/${inventoryBinDetailId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};
