import BatchPickingImage from 'assets/images/batch-picking.png';

import { Box } from '@mui/material';

const BatchPickingIcon = () => {
  const imageSrc = BatchPickingImage; // Default image if type doesn't match

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <img
        src={imageSrc}
        alt="batch picking"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </Box>
  );
};

export default BatchPickingIcon;
