import styled from 'styled-components';

import { Button } from '@mui/material';

export const CellButtonIcon = styled(Button)<{ $color?: string }>`
  font-size: 13px;
  color: ${(props) => props.$color ?? '#333333'};
  min-width: 25px !important;
  padding: 0;
  font-size: 13px;
  svg {
    width: 18px;
    height: 18px;
  }
`;
