import React, { useContext, memo, useEffect, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { getCustomersLookup } from 'services/api/salesorders/salesorders-new.api';
import { copyBillingSetup } from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Typography,
  Button,
  Grid as MUIGrid,
  Box,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';

import { useWarehouseCustomerContext } from '..';

type AutocompleteBase = {
  label?: string;
  value?: number;
};
export default memo(() => {
  const { isCopyBillingSetupModalOpen, setIsCopyBillingSetupModalOpen } =
    useContext(GlobalContext);

  const { currentUser } = useContext(AuthContext);

  const { queryString, loadBillingData } = useWarehouseCustomerContext();

  const [optionCustomers, setOptionCustomers] = useState([]);
  const [customerValue, setCustomerValue] = useState(null);
  const [formError, setFormError] = useState(null);

  const [isCopyLoading, setIsCopyLoading] = useState(false);

  const onLoadCustomersLookup = async () => {
    try {
      const warehouseCustomersLookupFromApi = await getCustomersLookup(
        currentUser.Claim_CustomerId,
        '',
      );

      return warehouseCustomersLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const handleCloseModel = () => {
    setIsCopyBillingSetupModalOpen(false);
    setCustomerValue(null);
    setFormError(null);
  };

  const customerValidation = () => {
    const temp: any = {};
    temp.customer = customerValue ? '' : 'Please select a Client.';

    setFormError({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const handleCopyBillingSetup = async () => {
    if (!customerValidation()) {
      return;
    }

    try {
      setIsCopyLoading(true);
      const payload = {
        FromWarehouseCustomerId: Number(customerValue.value),
        ToWarehouseCustomerId: Number(queryString.id),
      };
      copyBillingSetup(payload)
        .then(() => {
          snackActions.success('Billing Setup Copied Successfully');
          loadBillingData(queryString.id).then(() => {
            handleCloseModel();
          });
        })
        .finally(() => setIsCopyLoading(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isCopyBillingSetupModalOpen) {
      onLoadCustomersLookup()
        .then((opt) => {
          setOptionCustomers(
            opt
              .filter(
                (c: any) =>
                  c.warehouseCustomerId !== 0 &&
                  c.warehouseCustomerId !== Number(queryString.id),
              )
              .map((c: any) => ({
                label: c.name,
                value: c.warehouseCustomerId,
              })),
          );
        })
        .catch();
    }
  }, [isCopyBillingSetupModalOpen]);

  return (
    <Modal open={isCopyBillingSetupModalOpen}>
      <MUIContainer sx={{ width: '20% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Copy Billing Setup
        </Typography>
        <MUIContent sx={{ padding: '5px 20px !important ' }}>
          <MUIGrid container direction="row" alignItems="center" spacing={2}>
            <MUIGrid item xs={12}>
              <Autocomplete
                disableClearable
                id="controllable-states"
                options={optionCustomers}
                getOptionLabel={(option: AutocompleteBase) => option.label}
                value={customerValue}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                onChange={(event: any, newValue: AutocompleteBase) => {
                  if (newValue !== null) {
                    setCustomerValue(newValue);
                  }
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.label}</li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined-required"
                    size="small"
                    sx={{ width: '100%', background: '#ffffff' }}
                    label="Client"
                    {...(formError?.customer && {
                      error: true,
                      helperText: formError?.customer,
                    })}
                    required
                  />
                )}
              />
            </MUIGrid>
          </MUIGrid>
        </MUIContent>

        <MUIGrid
          container
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
            justifyContent: 'flex-end',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={isCopyLoading ? 10 : 8}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '10px',
                padding: '0px',
              }}
            >
              <Button
                onClick={handleCloseModel}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                onClick={handleCopyBillingSetup}
                disabled={isCopyLoading}
              >
                {isCopyLoading ? (
                  <Box display="flex" alignItems="center" gap={1}>
                    <CircularProgress size={20} sx={{ color: 'white' }} />
                    Loading...
                  </Box>
                ) : (
                  <>Confirm</>
                )}
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
