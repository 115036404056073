import React from 'react';

import DatePickerInput from 'components/datepicker';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';

import {
  Modal,
  Box,
  Typography,
  Button,
  Grid as MUIGrid,
  TextField,
  CircularProgress,
} from '@mui/material';

import { useInvoiceInterfaceTabContext } from '../../context';

function GenerateInvoiceModal() {
  const {
    state,
    closeInvoiceModal,
    submitInvoiceModal,
    setInvoiceFrom,
    setInvoiceTo,
  } = useInvoiceInterfaceTabContext();

  return (
    <Modal open={state.vm.invoiceModal !== null}>
      <MUIContainer sx={{ maxWidth: '600px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Invoice
        </Typography>

        <MUIContent>
          <Box autoComplete="off" component="form" noValidate>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12}>
                <DatePickerInput
                  label="From Date"
                  inputFormat="MM/dd/yyyy"
                  value={state.vm.invoiceModal?.invoice?.from}
                  disabled={state.isBusy}
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid Date') {
                      setInvoiceFrom(e);
                    } else {
                      setInvoiceFrom(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      disabled
                      {...params}
                      {...(state.vm.invoiceModal?.invoiceError?.from && {
                        error: true,
                        helperText: state.vm.invoiceModal?.invoiceError?.from,
                      })}
                      size="small"
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12}>
                <DatePickerInput
                  label="To Date"
                  inputFormat="MM/dd/yyyy"
                  value={state.vm.invoiceModal?.invoice?.to}
                  disabled={state.isBusy}
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid Date') {
                      setInvoiceTo(e);
                    } else {
                      setInvoiceTo(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      disabled
                      {...params}
                      {...(state.vm.invoiceModal?.invoiceError?.to && {
                        error: true,
                        helperText: state.vm.invoiceModal?.invoice?.to,
                      })}
                      size="small"
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={state.isBusy ? 8 : 6}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => {
                  closeInvoiceModal();
                }}
                disabled={state.isBusy}
                variant="outlined"
                size="large"
                sx={{ padding: 1, width: '100%' }}
              >
                CANCEL
              </Button>

              {!state.isBusy && (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => {
                    // this will trigger a state changed at state.vm.invoiceModalIsValid
                    // state.vm.invoiceModalIsValid has useEffect where we can trigger the actual GENERATE
                    submitInvoiceModal();

                    //   onHandleInvoiceGenerate(e);
                    //   onOpenInvoiceDialogModal();
                  }}
                >
                  Generate
                </Button>
              )}
              {state.isBusy && (
                <Button
                  disabled
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                    color: 'white !important',
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: 'white !important',
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                  <Box sx={{ paddingLeft: '5px' }}>Generating</Box>
                </Button>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
}

export default React.memo(GenerateInvoiceModal);
