import React, { useEffect, useState } from 'react';
import Barcode from 'react-barcode/lib/react-barcode';

import { PrintContainerOtherUOM } from 'components/styles';

import { Box } from '@mui/material';

export type OtherUOMPrintModel = {
  clientName: string;
  barcode: string;
  description: string;
  qtyToDisplay: string;
  qty: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buttonPrint?: React.MutableRefObject<any>;
};

interface OtherUOMPrintProps {
  data?: OtherUOMPrintModel;
}

export const OtherUOMPrint = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ data }: OtherUOMPrintProps, ref: React.MutableRefObject<any>) => {
    const [qtyArray, setQtyArray] = useState<number[]>([]);

    useEffect(() => {
      if (data !== null && data.qty > 0) {
        const qtyArr = [];

        let d = 1;
        for (let index = 0; index < data.qty; index += 1) {
          d += index;
          qtyArr.push(d); // add dummy data
        }
        setQtyArray(qtyArr);
      }
    }, [data]);

    useEffect(() => {
      if (qtyArray.length > 0 && data !== null && data?.buttonPrint !== null) {
        data.buttonPrint?.current?.click();
      }
    }, [qtyArray, data, data?.buttonPrint]);

    if (data === null) return null;

    return (
      <div>
        <style type="text/css" media="print">
          {'\
  @page { size: landscape; size: 6in 4in; margin: 0px; }\
'}
        </style>
        <PrintContainerOtherUOM
          ref={ref}
          sx={{
            display: 'block',
          }}
        >
          {qtyArray.map((d) => (
            <>
              <Box
                key={d}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    paddingTop: '80px',
                  }}
                >
                  <p
                    style={{
                      fontSize: '16px',
                      lineHeight: 'normal',
                      whiteSpace: 'nowrap',
                      paddingBottom: '0px',
                    }}
                  >
                    {data?.clientName}
                  </p>
                  <Barcode
                    marginLeft={0}
                    marginRight={0}
                    marginTop={0}
                    marginBottom={1}
                    displayValue={false}
                    value={data.barcode}
                    width={1.5}
                    height={180}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      lineHeight: 'normal',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {data.description}
                    <br />
                    {data.qtyToDisplay}
                  </p>
                </Box>
              </Box>
              <div className="page-break" />
            </>
          ))}
        </PrintContainerOtherUOM>
      </div>
    );
  },
);
