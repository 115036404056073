import { createContext, useContext, useMemo, useState } from 'react';

import { PageLoadStateEnum } from 'common/enums';
import { IPrintBarcodeLocation } from 'pages/shared/binprintbarcode';
import { GlobalContext } from 'store/contexts/GlobalContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

interface State {
  pageLoadState: PageLoadStateEnum;
}

const INITIAL_STATE: State = {
  pageLoadState: PageLoadStateEnum.LOAD_NONE,
};

// MEMO CHILDER ADD FUNCTIONS AND VARIABLES
interface ILocation {
  setState: React.Dispatch<React.SetStateAction<State>>;
  state: State;
  setCurrentView: React.Dispatch<React.SetStateAction<number>>;
  currentView: number;
  setBinCount: React.Dispatch<React.SetStateAction<number>>;
  binCount: number;
  setZoneCount: React.Dispatch<React.SetStateAction<number>>;
  zoneCount: number;
  setOpenSpaces: React.Dispatch<React.SetStateAction<number>>;
  openSpaces: number;
  setError: React.Dispatch<React.SetStateAction<string>>;
  error: string;
  setCustomerBins: React.Dispatch<React.SetStateAction<any[]>>;
  customerBins: any[];
  setFilteredBins: React.Dispatch<React.SetStateAction<any[]>>;
  filteredBins: any[];
  setFacilityZones: React.Dispatch<React.SetStateAction<any[]>>;
  facilityZones: any[];
  setFilteredZones: React.Dispatch<React.SetStateAction<any[]>>;
  filteredZones: any[];
  setDeleteLocation: React.Dispatch<React.SetStateAction<boolean>>;
  deleteLocation: boolean;
  setZoneAction: React.Dispatch<React.SetStateAction<boolean>>;
  zoneAction: boolean;
  setEditZone: React.Dispatch<React.SetStateAction<boolean>>;
  editZone: boolean;
  setLocationBarcode: React.Dispatch<
    React.SetStateAction<IPrintBarcodeLocation>
  >;
  locationBarcode: IPrintBarcodeLocation;
  setBillTypeCallback: React.Dispatch<any>;
  billTypeCallback: any;
  setLocationFilter: React.Dispatch<React.SetStateAction<string>>;
  locationFilter: string;
  pageLoadingStart(): void;
  setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>;
  selectedRows: any[];
  setNotForDeletion: React.Dispatch<React.SetStateAction<any[]>>;
  notForDeletion: any[];
  setForDeletion: React.Dispatch<React.SetStateAction<any[]>>;
  forDeletion: any[];
  handleDeleteBulkBin: () => Promise<void>;
  handlePrintBarcode: () => Promise<void>;
}

type LocationProviderProps = {
  children: React.ReactNode;
};
const LocationContext = createContext<ILocation>({} as ILocation);
export const useLocationContext = () => useContext(LocationContext);

export const LocationContextProvider = ({
  children,
}: LocationProviderProps) => {
  const {
    onOpenConfirmDeleteDialog,
    onOpenBinPrintBarcodeModal,
    setIsBulkDeleteLocationModalOpen,
  } = useContext(GlobalContext);

  const [state, setState] = useState<State>(INITIAL_STATE);
  const [currentView, setCurrentView] = useState(0);

  const [binCount, setBinCount] = useState(0);
  const [zoneCount, setZoneCount] = useState(0);
  const [openSpaces, setOpenSpaces] = useState(0);
  const [error, setError] = useState('');
  const [customerBins, setCustomerBins] = useState([]);
  const [filteredBins, setFilteredBins] = useState([]);
  const [facilityZones, setFacilityZones] = useState([]);
  const [filteredZones, setFilteredZones] = useState([]);
  const [deleteLocation, setDeleteLocation] = useState(false);
  const [zoneAction, setZoneAction] = useState(false);
  const [editZone, setEditZone] = useState(false);
  const [billTypeCallback, setBillTypeCallback] = useState<any>(null);

  const [locationBarcode, setLocationBarcode] = useState<IPrintBarcodeLocation>(
    {},
  );
  const [locationFilter, setLocationFilter] = useState('All');

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [notForDeletion, setNotForDeletion] = useState([]);
  const [forDeletion, setForDeletion] = useState([]);

  function pageLoadingStart() {
    setState((prevState) => ({
      ...prevState,
      pageLoadState: PageLoadStateEnum.LOAD_START,
    }));
  }

  const handleDeleteBulkBin = async () => {
    if (selectedRows.length <= 0) {
      withReactContent(Swal).fire({
        title: 'Please select a bin location to delete',
        icon: 'info',
        confirmButtonText: 'Ok',
      });
      return;
    }

    const binNotForDeletion = selectedRows.filter((row) => row.uniqueSKU !== 0);
    const binForDeletion = selectedRows.filter((row) => row.uniqueSKU === 0);

    setNotForDeletion([...binNotForDeletion]);
    setForDeletion([...binForDeletion]);

    onOpenConfirmDeleteDialog();
    setIsBulkDeleteLocationModalOpen(true);
  };

  const handlePrintBarcode = async () => {
    if (selectedRows.length <= 0) {
      setLocationBarcode((e) => ({
        ...e,
        locations: filteredBins.map((item) => ({
          name: item.name,
        })),
      }));
    }
    onOpenBinPrintBarcodeModal();
  };

  const value: ILocation = useMemo(
    () => ({
      setState,
      state,
      setCurrentView,
      currentView,
      binCount,
      setBinCount,
      zoneCount,
      setZoneCount,
      openSpaces,
      setOpenSpaces,
      error,
      setError,
      customerBins,
      setCustomerBins,
      filteredBins,
      setFilteredBins,
      facilityZones,
      setFacilityZones,
      filteredZones,
      setFilteredZones,
      deleteLocation,
      setDeleteLocation,
      zoneAction,
      setZoneAction,
      editZone,
      setEditZone,
      billTypeCallback,
      setBillTypeCallback,
      locationBarcode,
      setLocationBarcode,
      locationFilter,
      setLocationFilter,
      pageLoadingStart,
      selectedRows,
      setSelectedRows,
      notForDeletion,
      setNotForDeletion,
      forDeletion,
      setForDeletion,
      handleDeleteBulkBin,
      handlePrintBarcode,
    }),
    [
      forDeletion,
      notForDeletion,
      state,
      selectedRows,
      currentView,
      binCount,
      zoneCount,
      openSpaces,
      error,
      customerBins,
      filteredBins,
      facilityZones,
      filteredZones,
      deleteLocation,
      zoneAction,
      editZone,
      billTypeCallback,
      locationFilter,
      locationBarcode,
    ],
  );

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};
