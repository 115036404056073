import React, { useContext, useEffect, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Button,
  Grid as MUIGrid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from '@mui/material';

import { getCarrierAccountByCustomer } from 'services/api/integrations/easypost/easypost.api';
import { addAllWarehouseCarriers } from 'services/api/integrations/shipment/shipment.api';

import { useWarehouseCustomerContext } from '..';

export default React.memo(() => {
  const { isAddWarehouseCarrierModalOpen, setIsAddWarehouseCarrierModalOpen } =
    useContext(GlobalContext);
  const { currentUser, isWarehouseCustomerAccount } = useContext(AuthContext);

  const { form, loadCarriers, shippoCarriers } = useWarehouseCustomerContext();
  const [carrierList, setCarrierList] = useState([]);
  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleModalOnClose = () => {
    setIsAddWarehouseCarrierModalOpen(false);
    setChecked([]);
  };

  const handleAddAllWarehouseCarriers = async () => {
    if (checked.length <= 0) {
      snackActions.error('Please select carrier');
      return;
    }

    let warehouseCustomerId = form?.warehouseCustomerId || 0;

    if (isWarehouseCustomerAccount)
      warehouseCustomerId = currentUser.Claim_WarehouseCustomerId;

    try {
      await addAllWarehouseCarriers(
        warehouseCustomerId,
        true,
        checked.join(','),
      ).then(async () => {
        loadCarriers();
        handleModalOnClose();
      });

      snackActions.success('All carriers has been successfully added');
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    if (isAddWarehouseCarrierModalOpen) {
      getCarrierAccountByCustomer().then((data) => {
        setCarrierList(data);
      });
    }
  }, [isAddWarehouseCarrierModalOpen]);

  return (
    <Modal open={isAddWarehouseCarrierModalOpen}>
      <MUIContainer sx={{ maxWidth: '600px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Warehouse Carriers
        </Typography>

        <MUIContent sx={{ padding: '0px 20px !important' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '3px',
            }}
          >
            <List sx={{ width: '100%' }}>
              {carrierList.map((value, index) => {
                const labelId = `checkbox-list-label-${value?.carrierId}`;
                const classToDisplay = index % 2 === 0 ? 'Mui-even' : 'Mui-odd';

                const exisitingCarrier = shippoCarriers.findIndex(
                  (a) =>
                    a?.accountId === value?.accountId &&
                    a?.carrierName === value?.carrierName,
                );
                return (
                  <ListItem
                    key={value?.carrierId}
                    disablePadding
                    className={classToDisplay}
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(value?.carrierId)}
                      dense
                      disabled={exisitingCarrier >= 0}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(value?.carrierId) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={`${value?.carrierName}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                onClick={() => {
                  handleModalOnClose();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={() => handleAddAllWarehouseCarriers()}
              >
                Confirm
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
