import React, { useContext } from 'react';

import { usePurchaseOrderContext } from 'pages/receiving/purchaseordersv2/context';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridStandard } from 'styles';

import { Modal, Box, Typography, Grid as MUIGrid, Button } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { GridColumns } from '@mui/x-data-grid';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 850px;
    border-radius: 20px;
    max-height: 80%;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 40px;
    gap: 16px;
    overflow-y: scroll;
    height: 100%;
  }
`;

const CostSheetModal = () => {
  const { setCostSheetGridModalOpen, isCostSheetGridModalOpen } =
    useContext(GlobalContext);
  const { purchaseOrderLineItemModal } = usePurchaseOrderContext();

  const purchaseOrderLineItemColumns: GridColumns = [
    {
      field: 'date',
      width: 150,
      renderHeader: () => <Typography>Date</Typography>,
      renderCell: (params) => <Typography>{params.row.date}</Typography>,
    },
    {
      field: 'cost',
      width: 200,
      renderHeader: () => <Typography>Unit Cost</Typography>,
      renderCell: (params) => (
        <Typography>${params.row.cost ? params.row.cost : '0.00'}</Typography>
      ),
    },
    {
      field: 'shippingCost',
      width: 200,
      renderHeader: () => <Typography>Shipping Cost</Typography>,
      renderCell: (params) => (
        <Typography>
          ${params.row.shippingCost ? params.row.shippingCost : '0.00'}
        </Typography>
      ),
    },
    {
      field: 'partNumber',
      width: 200,
      renderHeader: () => <Typography>Part Number</Typography>,
      renderCell: (params) => <Typography>{params.row.partNumber}</Typography>,
    },
  ];

  return (
    <Modal open={isCostSheetGridModalOpen}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Cost Sheets
        </Typography>
        <MUIContent>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={12}>
              <DatagridStandard
                autoHeight
                sx={{ border: 'none !important' }}
                headerHeight={120}
                rows={purchaseOrderLineItemModal?.itemCostSheets}
                columns={purchaseOrderLineItemColumns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
                hideFooterSelectedRowCount
                pageSize={6}
                density="compact"
                rowsPerPageOptions={[6]}
                getRowId={(row) =>
                  row.itemCostSheetId !== undefined
                    ? row.itemCostSheetId
                    : Math.random()
                }
                componentsProps={{
                  toolbar: {
                    title: 'Cost Sheet',
                  },
                }}
              />
            </MUIGrid>
          </MUIGrid>
        </MUIContent>
        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={() => {
                  setCostSheetGridModalOpen(false);
                }}
              >
                CLOSE
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
};

export default CostSheetModal;
