import React, { useEffect } from 'react';
import Barcode from 'react-barcode/lib/react-barcode';

import { PrintContainerSku } from 'components/styles';

import { Typography, Box } from '@mui/material';

export const LocationToPrint = React.forwardRef((props: any, ref: any) => {
  useEffect(() => {
    if (props.data !== undefined && props.data !== null) {
      if (props.data.labelToPrint.length > 0) {
        props.data.button?.click();
      }
    }
  }, [props.data]);

  return (
    <div>
      <PrintContainerSku ref={ref} sx={{ display: 'block' }}>
        {props?.data !== undefined &&
          props?.data !== null &&
          props?.data?.labelToPrint?.length > 0 && (
            <>
              {props.data.labelToPrint.map((data) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                      paddingY: '24px',
                    }}
                  >
                    {data?.clientName && (
                      <Typography variant="h5">{data.clientName}</Typography>
                    )}
                    {data?.customer && (
                      <Typography variant="h5">{data.customer}</Typography>
                    )}
                    <Barcode
                      displayValue={false}
                      value={data.barcode}
                      width={2.2}
                      height={120}
                      fontSize={30}
                    />
                    <Typography variant="h5">{data?.labelName}</Typography>
                    {data?.qtyToDisplay && (
                      <Typography variant="h5">{data.qtyToDisplay}</Typography>
                    )}
                    {data?.receiptDate && (
                      <Typography variant="h5">{data.receiptDate}</Typography>
                    )}
                  </Box>
                </Box>
              ))}
            </>
          )}
      </PrintContainerSku>
    </div>
  );
});
