import React, { useState } from 'react';
import { Box } from '@mui/material';
import Card from 'components/card';
import Header from 'components/header';
import { MainContainer, ContentContainer } from 'pages/styles';
import DashboardBackorder from './dashboard-backorder';
import DashboardOrderStatus from './dashboard-order-status';
import DashboardOperations from './dashboard-operations';
import DashboardSalesHeatmap from './dashboard-sales-heatmap';
import DashboardTopCustomer from './dashboard-top-customer';

function Dashboard() {
  return (
    <MainContainer>
      <Header />
      <ContentContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'row' },
              width: '100%',
              height: { xs: '10%', sm: '20%', md: '20%', lg: '43%', xl: '50%' },
              gap: '12px',
            }}
          >
            <Card
              sx={{ width: { xs: '100%', md: '50%', position: 'relative' } }}
            >
              <DashboardSalesHeatmap />
            </Card>
            <Card sx={{ width: { xs: '100%', md: '50%' } }}>
              <DashboardOrderStatus />
            </Card>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row' },
              width: '100%',
              minHeight: '50%',
              gap: '12px',
              paddingBottom: 5,
            }}
          >
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '100%', lg: '30%' },
                gap: '8px',
              }}
            >
              <DashboardTopCustomer />
            </Card>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '100%', lg: '40%' },
                gap: '8px',
              }}
            >
              <DashboardBackorder />
            </Card>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '100%', lg: '30%' },
                gap: '8px',
              }}
            >
              <DashboardOperations />
            </Card>
          </Box>
        </Box>
      </ContentContainer>
    </MainContainer>
  );
}

export default React.memo(Dashboard);
