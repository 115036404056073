import React, { useContext, useEffect, useState } from 'react';

import Button from 'components/button';
import { useSalesOrderContext } from 'pages/sales/salesorder/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Divider,
  Grid as MUIGrid,
} from '@mui/material';

export default React.memo(() => {
  const {
    isConfirmSalesOrderLineItemModalOpen,
    onCloseConfirmSalesOrderLineItemModal,
  } = useContext(GlobalContext);

  const {
    singleOrderLineItemRow,
    deleteOrderLineItemOnClick,
    duplicateOrderLineItemOnClick,
    cancelOrderLineItemOnClick,
  } = useSalesOrderContext();

  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalContent, setModalContent] = useState<string>('');
  const [modalButtonText, setModalButtonText] = useState<string>('');

  useEffect(() => {
    if (singleOrderLineItemRow?.action === 'Duplicate') {
      setModalButtonText('Duplicate');
      setModalTitle('Duplicate Line Item');
      setModalContent('Are you sure you want to duplicate line item?');
    } else if (singleOrderLineItemRow?.action === 'Cancel') {
      setModalButtonText('Cancel');
      setModalTitle('Cancel Line Item');
      setModalContent('Are you sure you want to cancel line item?');
    } else if (singleOrderLineItemRow?.action === 'Delete') {
      setModalButtonText('Remove');
      setModalTitle('Remove Line Item');
      setModalContent('Are you sure you want to delete line item?');
    }
  }, [singleOrderLineItemRow]);

  return (
    <Modal open={isConfirmSalesOrderLineItemModalOpen}>
      <MUIContainer sx={{ maxWidth: '500px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          {modalTitle}
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Box autoComplete="off" component="form" noValidate>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={12}>
                <Typography variant="subtitle1">{modalContent}</Typography>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={7}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => onCloseConfirmSalesOrderLineItemModal()}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                {singleOrderLineItemRow?.action === 'Cancel'
                  ? 'CLOSE'
                  : 'CANCEL'}
              </Button>
              <Button
                onClick={(e) => {
                  if (singleOrderLineItemRow?.action === 'Duplicate') {
                    duplicateOrderLineItemOnClick(
                      e,
                      singleOrderLineItemRow.data,
                    );
                  } else if (singleOrderLineItemRow?.action === 'Cancel') {
                    cancelOrderLineItemOnClick(
                      e,
                      singleOrderLineItemRow?.data?.lineId,
                    );
                  } else if (singleOrderLineItemRow?.action === 'Delete') {
                    deleteOrderLineItemOnClick(
                      e,
                      singleOrderLineItemRow?.data?.lineId,
                    );
                  }
                  onCloseConfirmSalesOrderLineItemModal();
                }}
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
              >
                {modalButtonText}
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
