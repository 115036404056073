import { styled } from '@mui/material/styles';

export const DatagridChipStatus = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  '& .MuiChip-root': {
    width: '30px',
    height: '18px',
    '& span': {
      fontSize: '11px',
    },
  },
}));
