import React, { ReactNode, useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/system';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

type DatePickerInputProps = {
  value?: any;
  label?: string;
  inputFormat?: string;
  sx?: SxProps<Theme>;
  onChange?: any;
  disabled?: boolean;
  renderInput?: any;
  disableFuture?: boolean;
  maxDate?: any;
  minDate?: any;
  readonly?: boolean;
};

function DatePickerInput({
  value,
  label,
  inputFormat,
  onChange,
  disabled,
  renderInput,
  sx,
  disableFuture,
  maxDate,
  minDate,
  readonly,
  ...props
}: DatePickerInputProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3} sx={{ width: '100%' }}>
        <DesktopDatePicker
          label={label}
          disabled={disabled}
          inputFormat={inputFormat}
          value={value}
          onChange={onChange}
          renderInput={renderInput}
          maxDate={maxDate}
          minDate={minDate}
          disableFuture={disableFuture}
          readOnly={readonly}
          {...props}
        />
      </Stack>
    </LocalizationProvider>
  );
}

export default React.memo(DatePickerInput);
