import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const createQuickLink = async (data) => {
  try {
    const response = await httpClient.post(`/QuickLink/CreateQuickLink`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteQuickLink = async (quickLinkId) => {
  try {
    const response = await httpClient.post(`/QuickLink/Delete/${quickLinkId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getUserQuickLinks = async () => {
  try {
    const response = await httpClient.get(`/QuickLink/UserQuickLinks`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
