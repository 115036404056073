import { Box, CircularProgress } from '@mui/material';

export function DataGridProgress() {
  return (
    <Box
      style={{
        width: '100%',
        height: '70vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress color="inherit" />
    </Box>
  );
}
