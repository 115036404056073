/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { IPurchaseOrderFees } from 'common/interfaces';
import { GetNum, GetSum } from 'common/utils';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import {
  ItemUnitOfMeasureType,
  LotNoType,
} from 'pages/receiving/receipts/subpage';
import { getCountries } from 'services/api/country/country.api';
import { getCustomerFacilitiesByCustomerId } from 'services/api/customerfacilities/customerfacilities.api';
import {
  getPurchaseOrderDetail,
  createEditPurchaseOrder,
  getGeneratedPurchaseOrderNo,
  getAllPurchaseOrdersV2,
} from 'services/api/purchaseOrders/purchaseOrders.api';
import { getCustomersLookup } from 'services/api/salesorders/salesorders-new.api';
import { getSupplierLookupByName } from 'services/api/suppliers/suppliers.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { serialize } from 'v8';

type AutocompleteBase = {
  label?: string;
  value?: number;
};
export type CustomerType = AutocompleteBase;

export type PageViewerType = {
  isNew: () => boolean;
  isView?: () => boolean;
  isEdit?: () => boolean;
  isViewOrEdit: () => boolean;
};

// eslint-disable-next-line no-unused-vars
export enum FormQueryStringStatus {
  // eslint-disable-next-line no-unused-vars
  View = 'view',
  // eslint-disable-next-line no-unused-vars
  New = 'new',
  // eslint-disable-next-line no-unused-vars
  Edit = 'edit',
}
export type QueryStringType = {
  form: string;
  id: number;
};

export enum FormPurchaseOrderStatuses {
  New = 1,
  Receiving = 2,
  Closed = 3,
  Submitted = 4,
  Accepted = 5,
  Rejected = 6,
  Cancelled = 7,
}

interface IPurchaseOrderDescription {
  customer?: {
    value?: number;
    label?: string;
  };
  poNumber?: string;
  issueDate?: string;
  acceptedDate?: string;
  expectedArrivalDate?: string;
  estimatedBulkReadyDate?: string;
  note?: string;
  knownSuppliers?: {
    value?: number;
    label?: string;
  };
  companyName?: string;
  recipientName?: string;
  phone?: string;
  email?: string;
  address1?: string;
  address2?: string;
  cityTownship?: string;
  stateProvinceRegion?: string;
  zipPostalCode?: string;
  country?: {
    value?: number;
    label?: string;
  };
  supplierIdentifier?: string;
  departmentNo?: string;
  paymentStatus?: {
    value?: number;
    label?: string;
  };
}
interface IPurchaseOrderLineItems {
  shipTo?: {
    value?: number;
    label?: string;
  };
  isCreatePackingListItems?: boolean;
  lineItems?: IPurchaseOrderLineItem[] | [];
}
interface IPurchaseOrderLineItem {
  dataGridId?: number;
  receiveId?: number;
  lineIdCount?: number;
  item?: {
    value?: number;
    label?: string;
  };
  itemId?: string;
  sku?: string;
  description?: string;
  isSerialNo?: boolean;
  qty?: string;
  unitCost?: string;
  shippingCost?: string;
  primaryInventoryUOMStr?: string;
  itemCostSheets?: any[];
}
export interface IPurchaseOrderPackingList {
  packingListCount?: number;
  packingListId?: number;
  shipTo?: {
    value?: number;
    label?: string;
  };
  shipDate?: string;
  isConfirmedReceipt?: boolean;
  estimatedArrival?: string;
  shippingCost?: number;
  carrier?: string;
  trackingNo?: string;
  packingLineItems?: IPurchaseOrderPackingListLineItem[] | [];
}
export interface IPurchaseOrderPackingListLineItem {
  lineIdCount?: number;
  packingListCount?: number;
  dataGridId?: number;
  packingListLineItemId?: number;
  receiveId?: number;
  item?: {
    value?: number;
    label?: string;
  };
  lotNoValue?: LotNoType;
  binItemType?: {
    value?: number;
    label?: string;
  };
  palletCount?: string;
  weight?: string;
  itemId?: string;
  sku?: string;
  description?: string;
  isSerialNo?: boolean;
  serialNo?: string;
  eaCase?: string;
  totalCases?: string;
  eachesPerCase?: string;
  qty?: string;
  received?: string;
  receipts?: [];
  unitCost?: string;
  primaryUOM?: number;
  primaryUOMAbbr?: string;
  itemUnitOfMeasureId?: number;
  otherUOMStr?: string;
  otherUOMAbbr?: string;
  otherUOMCount?: number;
  qtyOfPrimaryOUMPerLP?: string;
  notes?: string;
}
interface IPurchaseOrderActivityLog {
  changeBy?: string;
  changeDate?: string;
  description?: string;
}

interface IPurchaseOrderDetails {
  purchaseOrderId?: number;
  status?: number;
  statusStr?: string;
  isAccepted?: boolean;
  totalQuantity?: string;
  subTotalCost?: string;
  totalCost?: string;
  shippingFees?: string;
  shipments?: number;
  skus?: number;
  adjustmentAmount?: string;
  unitsExpected?: string;
  unitsReceived?: string;
  poDescription?: IPurchaseOrderDescription;
  poLineItem?: IPurchaseOrderLineItems;
  poPackingList?: IPurchaseOrderPackingList[] | [];
  poActivityLog?: IPurchaseOrderActivityLog[] | [];
}

// MEMO CHILDER ADD FUNCTIONS AND VARIABLES
interface IPurchaseOrder {
  optionCustomers: AutocompleteBase[] | [];
  optionCountries: AutocompleteBase[] | [];
  optionCustomerSupplier: AutocompleteBase[] | [];
  optionCustomerFacility: AutocompleteBase[] | [];
  purchaseOrderDetails: IPurchaseOrderDetails;
  // eslint-disable-next-line no-unused-vars
  handleOnCreatePurchaseOrder: (e?: any, isStatus?: boolean) => Promise<void>;
  setPurchaseOrderDetails: React.Dispatch<
    React.SetStateAction<IPurchaseOrderDetails>
  >;
  purchaseOrderLineItemModal: IPurchaseOrderLineItem;
  setPurchaseOrderLineItemModal: React.Dispatch<
    React.SetStateAction<IPurchaseOrderLineItem>
  >;
  purchaseOrderDetailsFormError: any;
  setPurchaseOrderDetailsFormError: any;
  addPurchaseOrderLineItem: () => void;
  handleEditPurchaseOrder: () => void;
  pageViewerPO: PageViewerType;
  setIsAddNewSupplier: React.Dispatch<React.SetStateAction<boolean>>;
  isAddNewSupplier: boolean;
  // eslint-disable-next-line no-unused-vars
  onLoadGeneratedPONumber: (id?: number) => Promise<unknown>;
  addPurchaseOrderPackingList: () => void;
  addPurchaseOrderPackingListLineItem: () => void;
  viewLoadDataPO: (
    isForceToLoad?: boolean,
    purchaseOrderId?: any,
  ) => Promise<void>;
  setPurchaseOrderPackingListLineItemModal: React.Dispatch<
    React.SetStateAction<IPurchaseOrderPackingListLineItem>
  >;
  purchaseOrderPackingListLineItemModal: IPurchaseOrderPackingListLineItem;
  setPurchaseOrderPackingListModal: React.Dispatch<
    React.SetStateAction<IPurchaseOrderPackingList>
  >;
  purchaseOrderPackingListModal: IPurchaseOrderPackingList;
  setIsAddNewSupplierPage: React.Dispatch<React.SetStateAction<boolean>>;
  isAddNewSupplierPage: boolean;
  // eslint-disable-next-line no-unused-vars
  deletePurchaseOrderLineItem: (e: any) => Promise<void>;
  deletePurchaseOrderPackingListLineItem: (e: any) => Promise<void>;
  setPurchaseOrderIdPrint: React.Dispatch<React.SetStateAction<number>>;
  purchaseOrderIdPrint: number;
  printPurcahseOrderItemOnClick: (
    e: React.SyntheticEvent,
    id: any,
  ) => Promise<void>;
  hasAlreadySupplier: boolean;
  setUomValue: React.Dispatch<React.SetStateAction<ItemUnitOfMeasureType>>;
  uomValue: ItemUnitOfMeasureType;
  setOptionsUOM: React.Dispatch<React.SetStateAction<ItemUnitOfMeasureType[]>>;
  optionsUOM: ItemUnitOfMeasureType[];
  poFees: IPurchaseOrderFees;
  getFees: () => void;
  purchaseOrderTabValue: string;
  setPurchaseOrderTabValue: React.Dispatch<React.SetStateAction<string>>;
  handleChangeTabValue: (event: React.SyntheticEvent, newValue: string) => void;
  setQueryStringPurchaseOrder: React.Dispatch<
    React.SetStateAction<QueryStringType>
  >;
  queryStringPurchaseOrder: QueryStringType;
  setPoPackingListFormError: React.Dispatch<any>;
  poPackingListFormError: any;
  setPurchaseOrders: React.Dispatch<React.SetStateAction<any[]>>;
  purchaseOrders: any[];
  setFilteredPurchaseOrders: React.Dispatch<React.SetStateAction<any[]>>;
  filteredPurchaseOrders: any[];
  setPurchaseOrderIdDelete: React.Dispatch<any>;
  purchaseOrderIdDelete: any;
  setImportErrorMessage: React.Dispatch<any>;
  importErrorMessage: any;
  setPackinglistIdImport: React.Dispatch<any>;
  packinglistIdImport: any;
  validationForItemQtyLimit: (isAddPackingList: boolean) => boolean;
  setStatusFilter: React.Dispatch<React.SetStateAction<string>>;
  statusFilter: string;
}
// context -----------------------------------------------------

type PurchaseOrderProviderProps = {
  children: React.ReactNode;
};
const PurchaseOrderContext = createContext<IPurchaseOrder>(
  {} as IPurchaseOrder,
);
export const usePurchaseOrderContext = () => useContext(PurchaseOrderContext);

export const PurchaseOrderContextProvider = ({
  children,
}: PurchaseOrderProviderProps) => {
  const query = useQuery();
  const navigate = useNavigate();

  const {
    currentUser,
    currentLocationAndFacility,
    isWarehouseCustomerAccount,
  } = useContext(AuthContext);
  const { onOpenPrintFormTemplateModal, setIsPOLoadAllItemsModalOpen } =
    useContext(GlobalContext);

  const [purchaseOrderTabValue, setPurchaseOrderTabValue] = React.useState('0');
  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setPurchaseOrderTabValue(newValue);
  };

  const [statusFilter, setStatusFilter] = React.useState('Submitted');

  const initialStateOfQueryParams: QueryStringType = {
    form: query.get('form'),
    id: Number(query.get('id')),
  };
  // const { onCloseProductionReportModal } = useContext(GlobalContext);
  const [queryStringPurchaseOrder, setQueryStringPurchaseOrder] = useState(
    initialStateOfQueryParams,
  );

  const pageViewerPO: PageViewerType = {
    isNew: () => queryStringPurchaseOrder.form === FormQueryStringStatus.New,
    isView: () =>
      queryStringPurchaseOrder.form === FormQueryStringStatus.View &&
      queryStringPurchaseOrder.id > 0,
    isEdit: () =>
      queryStringPurchaseOrder.form === FormQueryStringStatus.Edit &&
      queryStringPurchaseOrder.id > 0,
    isViewOrEdit: () =>
      (queryStringPurchaseOrder.form === FormQueryStringStatus.View ||
        queryStringPurchaseOrder.form === FormQueryStringStatus.Edit) &&
      queryStringPurchaseOrder.id > 0,
  };
  const [purchaseOrderIdPrint, setPurchaseOrderIdPrint] = useState(0);

  const InitialFormPurchaseOrder: IPurchaseOrderDetails = {
    subTotalCost: '0',
    totalCost: '0',
    shippingFees: '0',
    shipments: 0,
    skus: 0,
    adjustmentAmount: '',
    unitsExpected: '0/0',
    unitsReceived: '0',
    poDescription: {
      customer: {
        value: -1,
        label: '',
      },
      poNumber: '',
      country: {
        value: 236,
        label: 'United States of America',
      },
      knownSuppliers: {
        value: -1,
        label: '',
      },
      issueDate: moment(new Date()).format('MM/DD/yyyy'),
      acceptedDate: null,
      expectedArrivalDate: null,
      estimatedBulkReadyDate: null,
      paymentStatus: {
        value: 2,
        label: 'Not Paid',
      },
    },
    poLineItem: {
      shipTo: {
        value: -1,
        label: '',
      },
      lineItems: [],
      isCreatePackingListItems: false,
    },
    poPackingList: [
      // {
      //   packingListId: null,
      //   shipDate: '',
      //   estimatedArrival: '',
      //   shippingCost: 0,
      //   packingLineItems: [],
      // },
    ],
    poActivityLog: [],
  };

  const InitialFormPurchaseOrderFormError = {
    customer: '',
    poNumber: '',
    issueDate: '',
    acceptedDate: '',
    expectedArrivalDate: '',
    estimatedBulkReadyDate: '',
    note: '',
    knownSuppliers: '',
    companyName: '',
    recipientName: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    cityTownship: '',
    stateProvinceRegion: '',
    zipPostalCode: '',
    country: '',
    supplierIdentifier: '',
    departmentNo: '',
    shipTo: '',
    isCreatePackingListItems: '',
  };

  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(
    InitialFormPurchaseOrder,
  );

  const [purchaseOrderLineItemModal, setPurchaseOrderLineItemModal] =
    useState<IPurchaseOrderLineItem>({
      item: null,
      qty: '',
      unitCost: '',
      shippingCost: '',
      itemCostSheets: [],
    });

  const [purchaseOrderPackingListModal, setPurchaseOrderPackingListModal] =
    useState<IPurchaseOrderPackingList>();

  const [
    purchaseOrderPackingListLineItemModal,
    setPurchaseOrderPackingListLineItemModal,
  ] = useState<IPurchaseOrderPackingListLineItem>({
    item: null,
    binItemType: null,
    isSerialNo: false,
    palletCount: '',
    weight: '',
    qty: '',
    received: '',
    unitCost: '',
    lotNoValue: {
      value: null,
      label: '',
      lotId: null,
      lotCreationDate: '',
      lotExpirationDate: '',
    },
    notes: '',
  });

  const [purchaseOrderDetailsFormError, setPurchaseOrderDetailsFormError] =
    useState<any>(InitialFormPurchaseOrderFormError);

  const initialFormPoPackingList: any = {
    estimatedArrival: '',
  };

  const initialPoPackingListFormError: any = {
    poPackingList: [initialFormPoPackingList],
  };

  const [poPackingListFormError, setPoPackingListFormError] = useState<any>([
    initialPoPackingListFormError,
  ]);

  const [optionCustomers, setOptionCustomers] = useState<
    CustomerType[] | null | []
  >([]);
  const [optionCountries, setOptionCountries] = useState<
    CustomerType[] | null | []
  >([]);
  const [optionCustomerSupplier, setOptionCustomerSupplier] = useState<
    CustomerType[] | null | []
  >([]);
  const [optionCustomerFacility, setOptionCustomerFacility] = useState<
    CustomerType[] | null | []
  >([]);
  const [isAddNewSupplier, setIsAddNewSupplier] = useState<boolean>(true);
  const [isAddNewSupplierPage, setIsAddNewSupplierPage] =
    useState<boolean>(false);
  const [hasAlreadySupplier, setHasAlreadySupplier] = useState<boolean>(false);

  // PACKING LIST LINE ITEM
  const [uomValue, setUomValue] = useState<ItemUnitOfMeasureType>(null);
  const [optionsUOM, setOptionsUOM] = useState<ItemUnitOfMeasureType[]>([]);

  const [importErrorMessage, setImportErrorMessage] = useState(null);

  // ---------------------------------------------

  const defaultFees: IPurchaseOrderFees = {
    totalSub: 0,
    totalShipping: 0,
    grandTotal: 0,
  };
  const [poFees, setPoFees] = useState<IPurchaseOrderFees>(defaultFees);

  // PO LANDING PAGE ---------------------------------------------------
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [filteredPurchaseOrders, setFilteredPurchaseOrders] = useState([]);
  const [purchaseOrderIdDelete, setPurchaseOrderIdDelete] = useState(null);
  const [packinglistIdImport, setPackinglistIdImport] = useState(null);
  // -----------------------------------------------------------------------

  // CHECKING QTY ON THE PO LINE ITEMS ---------------------------------------

  const validationForItemQtyLimit = (isAddPackingList: boolean) => {
    const temp: any = [];

    const getItemIds = purchaseOrderDetails.poLineItem.lineItems.map(
      (poItemId) => poItemId.receiveId,
    );

    for (let i = 0; i < getItemIds.length; i += 1) {
      const poLineItems = [...purchaseOrderDetails.poLineItem.lineItems];
      const poItemQtyTotal =
        poLineItems
          .filter((v) => v.receiveId === getItemIds[i])
          .map((o) => Number(o.qty))
          .reduce((a, b) => a + b) ?? 0;
      const poPackedItems = [...purchaseOrderDetails.poPackingList];
      const packItemQtyTotal =
        poPackedItems.length > 0
          ? poPackedItems
              .map((p) => {
                if (p.packingLineItems && p.packingLineItems.length > 0) {
                  const mob = p.packingLineItems.filter(
                    (v: any) => v.receiveId === getItemIds[i],
                  );
                  if (mob.length <= 0) return 0;
                  return p.packingLineItems
                    .filter((v: any) => v.receiveId === getItemIds[i])
                    .map((o) => Number(o.qty))
                    .reduce((a, b) => a + b);
                }
                return 0;
              })
              .reduce((a, b) => a + b) ?? 0
          : 0;
      const itemTotals = {
        label: poLineItems.filter((v) => v.receiveId === getItemIds[i])[0].item
          .label,
        poQty: poItemQtyTotal,
        poQtyUsed: packItemQtyTotal,
      };
      temp.push(itemTotals);
    }

    if (isAddPackingList) {
      if (temp.map((c) => c.poQtyUsed < c.poQty).some((x) => x === true))
        return true;
      return false;
    }

    if (!isAddPackingList) {
      if (temp.map((c) => c.poQtyUsed === 0).every((x) => x === true))
        return true;
      return false;
    }

    return true;
  };

  // ------------------------------------------------------------------------
  const onLoadCustomersLookup = async () => {
    try {
      const warehouseCustomersLookupFromApi = await getCustomersLookup(
        currentUser.Claim_CustomerId,
        '',
      );

      return warehouseCustomersLookupFromApi;
    } catch (err) {
      return err;
    }
  };
  const onLoadCountries = async () => {
    try {
      const countriesFromApi = await getCountries('');
      return countriesFromApi;
    } catch (err) {
      return err;
    }
  };
  const onLoadCustomerSuppliers = async () => {
    try {
      const suppliersFromApi = await getSupplierLookupByName(
        currentUser.Claim_CustomerId,
        '',
      );
      return suppliersFromApi;
    } catch (err) {
      return err;
    }
  };
  const onLoadCustomerFacilities = async () => {
    try {
      const facilitiesFromApi = await getCustomerFacilitiesByCustomerId(
        currentUser.Claim_CustomerId,
      );

      return facilitiesFromApi;
    } catch (error) {
      return error;
    }
  };
  const onLoadPurchaseOrderDetail = async (id: number) => {
    try {
      const purchaseOrderDetail = await getPurchaseOrderDetail(id);

      return purchaseOrderDetail;
    } catch (err) {
      return err;
    }
  };
  const onLoadGeneratedPONumber = async (id?: number) => {
    try {
      const generatedPONumberFromApi = await getGeneratedPurchaseOrderNo(
        currentUser.Claim_CustomerId,
        id,
      );
      setPurchaseOrderDetails((e) => ({
        ...e,
        poDescription: {
          ...e.poDescription,
          poNumber: generatedPONumberFromApi,
        },
      }));
      return true;
    } catch (err) {
      return err;
    }
  };

  const getFees = () => {
    const result: IPurchaseOrderFees = {
      totalSub: 0,
      totalShipping: 0,
      grandTotal: 0,
    };
    const arrSubTotal = purchaseOrderDetails.poLineItem.lineItems.map(
      (i: IPurchaseOrderLineItem) =>
        GetNum(i.qty) * (GetNum(i.unitCost) ? GetNum(i.unitCost) : 0),
    );

    const arrShipping = purchaseOrderDetails.poLineItem.lineItems.map(
      (i: IPurchaseOrderLineItem) =>
        GetNum(i.qty) * (GetNum(i.shippingCost) ? GetNum(i.shippingCost) : 0),
    );

    result.totalSub = GetSum(arrSubTotal);
    result.totalShipping = GetSum(arrShipping);
    result.grandTotal =
      result.totalSub +
      result.totalShipping +
      (GetNum(purchaseOrderDetails.adjustmentAmount) ?? 0);

    setPurchaseOrderDetails((e) => ({
      ...e,
      subTotalCost: result.totalSub.toFixed(2),
      shippingFees: result.totalShipping.toFixed(2),
      totalCost: result.grandTotal.toFixed(2),
    }));
    setPoFees(result);
  };

  const handleEditPurchaseOrder = () => {
    setQueryStringPurchaseOrder((f) => ({
      ...f,
      form: 'edit',
    }));
    window.history.replaceState(
      null,
      '',
      `?form=edit&id=${queryStringPurchaseOrder.id}`,
    );
  };

  const viewLoadDataPO = async (
    isForceToLoad?: boolean,
    purchaseOrderId?: any,
  ) => {
    if (pageViewerPO.isViewOrEdit() || isForceToLoad) {
      onLoadPurchaseOrderDetail(
        isForceToLoad ? purchaseOrderId : queryStringPurchaseOrder.id,
      )
        .then((data: any) => {
          let subtotal = 0;
          data.poLineItems.forEach((v) => {
            subtotal += v.qty * (v.unitCost ? v.unitCost : 0);
          });

          setPurchaseOrderDetails((prev) => ({
            ...prev,
            purchaseOrderId: data?.purchaseOrderId,
            status: data?.status,
            statusStr: data?.statusStr,
            isAccepted: data?.isAccepted,
            shippingFees: data?.shippingFees,
            shipments: data?.shipments,
            skus: data?.skUs ? data.skUs : 0,
            adjustmentAmount: data?.adjustmentAmount
              ? data.adjustmentAmount
              : '',
            subTotalCost: subtotal.toString(),
            totalCost: data?.totalCosts,
            totalQuantity: data.poLineItems.reduce(
              (total, currentValue) => total + currentValue.qty,
              0,
            ),
            unitsExpected: data?.unitsExpected,
            unitsReceived: data?.unitsReceived,
            poDescription: {
              ...prev.poDescription,
              customer: {
                value: data.warehouseCustomerId,
                label: data.customer,
              },
              poNumber: data.purchaseOrderNo,
              estimatedBulkReadyDate: data.estimatedBulkReadyDate
                ? moment(data.estimatedBulkReadyDate).format('MM/DD/yyyy')
                : null,
              issueDate: data.issueDate
                ? moment(data.issueDate).format('MM/DD/yyyy')
                : null,
              acceptedDate: data.acceptedDate
                ? moment(data.acceptedDate).format('MM/DD/yyyy')
                : null,
              note: data.note,
              expectedArrivalDate: data?.expectedArrivalDate
                ? moment(data.expectedArrivalDate).format('MM/DD/yyyy')
                : null,
              knownSuppliers: {
                value: data.supplier ? data.supplier.supplierId : -1,
                label: data.supplier ? data.supplier.supplier : '',
              },
              companyName: data.supplier ? data.supplier.companyName : '',
              recipientName: data.supplier ? data.supplier.recipientName : '',
              phone: data.supplier ? data.supplier.phone : '',
              email: data.supplier ? data.supplier.email : '',
              address1: data.supplier ? data.supplier.address1 : '',
              address2: data.supplier ? data.supplier.address2 : '',
              cityTownship: data.supplier ? data.supplier.cityTownship : '',
              stateProvinceRegion: data.supplier
                ? data.supplier.stateProvinceRegion
                : '',
              zipPostalCode: data.supplier ? data.supplier.zipPostalCode : '',
              country: {
                value: data.supplier ? data.supplier.country : 236,
                label: data.supplier
                  ? data.supplier.countryStr
                  : 'United States of America',
              },
              supplierIdentifier: data.supplier
                ? data.supplier.supplierIdentifier
                : '',
              departmentNo: data.supplier ? data.supplier.departmentNo : '',
              paymentStatus: {
                value: data.paymentStatus,
                label: data.paymentStatusStr,
              },
            },
            poLineItem: {
              ...prev.poLineItem,
              shipTo: {
                value: data?.customerFacilityId,
                label: data.shipTo,
              },

              lineItems: data.poLineItems.map((v, index) => ({
                lineIdCount: index + 1,
                receiveId: v.receiveId,
                item: {
                  value: v.itemId,
                  label: v.sku,
                },
                itemId: v.itemId,
                sku: v.sku,
                description: v.description,
                qty: v.qty,
                qtyWithUom: v.qtyWithUom,
                availableQty: v.availableQty,
                unitCost: v?.unitCost ? v.unitCost.toFixed(2) : '',
                shippingCost: v.shippingCost ? v.shippingCost.toFixed(2) : '',
                dataGridId: Math.random(),
                itemCostSheets: v.itemCostSheets,
              })),
            },
            poPackingList: data?.packingLists.map((v) => ({
              packingListCount: v.packingListId,
              packingListId: v.packingListId,
              isConfirmedReceipt: v?.isConfirmedReceipt,
              shipTo: {
                value: v.shipToCustomerFacilityId,
                label: v.shipTo,
              },
              shipDate: v.shipDate
                ? moment(v.shipDate).format('MM/DD/yyyy')
                : null,
              estimatedArrival: v.estimatedArrival
                ? moment(v.estimatedArrival).format('MM/DD/yyyy')
                : null,
              shippingCost: v.shippingCost,
              carrier: v.carrier,
              trackingNo: v.trackingNo,
              packingLineItems: v.packingListLineItems.map((l, index) => ({
                lineIdCount: index + 1,
                packingListCount: v?.packingListId,
                dataGridId: l?.packingListLineItemId,
                packingListLineItemId: l?.packingListLineItemId,
                receiveId: l?.receiveId,
                item: {
                  value: l.receiveId,
                  label: l.sku,
                },
                sku: l?.sku,
                itemId: l?.itemId,
                description: l?.description,
                qty: l?.qty,
                received: l?.received,
                eaCase: l?.eaCase,
                totalCases: l?.totalCases,
                eachesPerCase: l?.eachesPerCase,
                receipts: l?.receiptLinkeds?.map((r) => ({
                  value: r.receiptId,
                  label: r.name,
                })),
                unitCost: l?.unitCost ? l.unitCost.toFixed(2) : '',
                binItemType: {
                  value: l.binItemType,
                  label: l.binItemTypeStr,
                },
                palletCount: l.palletCount,
                weight: l.weight,
                lotNoValue: {
                  value: l?.lotId,
                  label: l?.lotNo,
                  lotId: l?.lotId,
                  lotCreationDate: l?.lotCreationDate,
                  lotExpirationDate: l?.lotExpirationDate,
                },
                lotNo: l?.lotNo,
                lotExpirationDate: l?.lotExpirationDate,
                islotDisplay: l?.islotDisplay,
                primaryUOM: l?.primaryUOM,
                primaryUOMStr: l?.primaryUOMStr,
                primaryUOMAbbr: l?.primaryUOMAbbr,
                itemUnitOfMeasureId: l?.itemUnitOfMeasureId,
                otherUOMStr: l?.otherUOMStr,
                otherUOMAbbr: l?.otherUOMAbbr,
                otherUOMCount: l?.otherUOMCount,
                notes: l.notes,
                isSerialNo: l.isSerialNo,
                serialNo: l.serialNo,
              })),
            })),
            poActivityLog: data.poActivityLog.map((v) => ({
              changeBy: v.changeBy,
              changeDate: v.changeDate
                ? moment(v.changeDate).format('MM/DD/yyyy')
                : null,
              description: v.description,
            })),
          }));

          if (data.supplier) {
            setHasAlreadySupplier(true);
          }
        }) // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    }
  };
  const validateOnlick = (fieldValues = purchaseOrderDetails) => {
    const temp: any = {};

    if (!isAddNewSupplierPage) {
      temp.companyName = fieldValues.poDescription.companyName
        ? ''
        : 'This field is required';
      temp.phone = fieldValues.poDescription.phone
        ? ''
        : 'This field is required';
      temp.address1 = fieldValues.poDescription.address1
        ? ''
        : 'This field is required';
      temp.cityTownship = fieldValues.poDescription.cityTownship
        ? ''
        : 'This field is required';
      temp.zipPostalCode = fieldValues.poDescription.zipPostalCode
        ? ''
        : 'This field is required';
      temp.country = fieldValues.poDescription.country
        ? ''
        : 'This field is required';
    }

    temp.shipTo =
      fieldValues.poLineItem.shipTo.value === -1 ||
      fieldValues.poLineItem.shipTo === null
        ? 'This field is required'
        : '';

    temp.issueDate =
      fieldValues.poDescription.issueDate === null
        ? 'This field is required'
        : '';

    temp.poNumber = fieldValues.poDescription.poNumber
      ? ''
      : 'This field is required';

    setPurchaseOrderDetailsFormError({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleOnCreatePurchaseOrder = async (e?: any, isStatus?: boolean) => {
    e?.preventDefault();

    const payload = {
      // PURCHASE ORDER DESCRIPTION SECTION
      purchaseOrderId: purchaseOrderDetails?.purchaseOrderId,
      status: purchaseOrderDetails?.status, // for now = 1: new
      isAccepted: purchaseOrderDetails?.isAccepted,
      customerId: parseInt(currentUser.Claim_CustomerId, 10),
      customerLocationId: !isWarehouseCustomerAccount
        ? currentLocationAndFacility.customerLocationId
        : 0, // set the value at the backend. because 'warehouse customer user' doesn't have 'currentLocationAndFacility'
      customerFacilityId:
        purchaseOrderDetails.poLineItem.shipTo.value !== -1
          ? purchaseOrderDetails.poLineItem.shipTo.value
          : currentLocationAndFacility.customerFacilityId,
      // if its true it will create packing list from po line items
      isCreatePackingListItems:
        purchaseOrderDetails?.poLineItem?.isCreatePackingListItems,
      warehouseCustomerId:
        purchaseOrderDetails.poDescription.customer !== null &&
        purchaseOrderDetails.poDescription.customer.value !== -1
          ? purchaseOrderDetails.poDescription.customer.value
          : 0,
      purchaseOrderNo: purchaseOrderDetails.poDescription.poNumber
        ? purchaseOrderDetails.poDescription.poNumber
        : null,
      issueDate: purchaseOrderDetails.poDescription.issueDate
        ? moment(purchaseOrderDetails.poDescription.issueDate).format(
            'MM/DD/yyyy',
          )
        : null,
      acceptedDate: purchaseOrderDetails.poDescription.acceptedDate
        ? moment(purchaseOrderDetails.poDescription.acceptedDate).format(
            'MM/DD/yyyy',
          )
        : null,
      expectedArrivalDate: purchaseOrderDetails.poDescription
        .expectedArrivalDate
        ? moment(purchaseOrderDetails.poDescription.expectedArrivalDate).format(
            'MM/DD/yyyy',
          )
        : null,
      estimatedBulkReadyDate: purchaseOrderDetails.poDescription
        .estimatedBulkReadyDate
        ? moment(
            purchaseOrderDetails.poDescription.estimatedBulkReadyDate,
          ).format('MM/DD/yyyy')
        : null,
      note: purchaseOrderDetails.poDescription.note,
      adjustmentAmount: purchaseOrderDetails.adjustmentAmount,
      paymentStatus: purchaseOrderDetails.poDescription.paymentStatus.value,
      // SUPPLIER SECTION
      supplier: {
        supplierId:
          purchaseOrderDetails.poDescription.knownSuppliers.value === -1
            ? null
            : purchaseOrderDetails.poDescription.knownSuppliers.value,
        companyName: purchaseOrderDetails.poDescription.companyName,
        recipientName: purchaseOrderDetails.poDescription.recipientName,
        phone: purchaseOrderDetails.poDescription.phone,
        email: purchaseOrderDetails.poDescription.email,
        address1: purchaseOrderDetails.poDescription.address1,
        address2: purchaseOrderDetails.poDescription.address2,
        cityTownship: purchaseOrderDetails.poDescription.cityTownship,
        stateProvinceRegion:
          purchaseOrderDetails.poDescription.stateProvinceRegion,
        zipPostalCode: purchaseOrderDetails.poDescription.zipPostalCode,
        country: purchaseOrderDetails.poDescription.country.value,
        supplierIdentifier:
          purchaseOrderDetails.poDescription.supplierIdentifier,
        departmentNo: purchaseOrderDetails.poDescription.departmentNo,
      },
      // PO LINE ITEM SECTION
      pOLineItems: purchaseOrderDetails.poLineItem.lineItems.map((v) => ({
        receiveId: v.receiveId,
        itemId: v.item.value,
        sKU: v.item.label,
        description: v.description,
        qty: v.qty,
        unitCost: v.unitCost,
        shippingCost: v.shippingCost,
      })),
      packingLists: purchaseOrderDetails.poPackingList.map((p) => ({
        packingListId: p?.packingListId,
        shipToCustomerFacilityId: p?.shipTo?.value,
        shipTo: p?.shipTo?.label,
        shipDate: p?.shipDate ? moment(p.shipDate).format('MM/DD/yyyy') : null,
        estimatedArrival: p.estimatedArrival
          ? moment(p.estimatedArrival).format('MM/DD/yyyy')
          : null,
        shippingCost: p?.shippingCost,
        carrier: p?.carrier,
        trackingNo: p?.trackingNo,
        PackingListLineItems: p.packingLineItems.map((l) => ({
          packingListLineItemId: l?.packingListLineItemId,
          receiveId: l?.receiveId,
          itemId: l?.itemId,
          sKU: l?.sku,
          description: l?.description,
          qty: l?.qty,
          received: l.received ? l.received : 0,
          eaCase: l?.eaCase,
          eachesPerCase: l?.eachesPerCase,
          totalCases: l?.totalCases,
          receipts: l?.receipts,
          unitCost: l?.unitCost,
          binItemType: l?.binItemType.value,
          palletCount: l?.palletCount,
          weight: l?.weight,
          lotId: l?.lotNoValue?.lotId,
          lotExpirationDate: l?.lotNoValue?.lotExpirationDate
            ? moment(l?.lotNoValue?.lotExpirationDate).format('MM/DD/yyyy')
            : null,
          primaryUOM: l?.primaryUOM,
          itemUnitOfMeasureId: l?.itemUnitOfMeasureId,
          notes: l?.notes,
          isSerialNo: l?.isSerialNo,
          serialNo: l?.serialNo,
        })),
      })),
    };

    if (!validateOnlick()) {
      snackActions.error('Please complete required fields');
      return;
    }
    try {
      const result = await createEditPurchaseOrder(payload);

      if (pageViewerPO.isNew()) {
        snackActions.success(`Successfully created a new purchase order.`);
      } else {
        snackActions.success(`Successfully updated the purchase order.`);
      }
      setQueryStringPurchaseOrder((f) => ({
        ...f,
        form: 'view',
        id: result.purchaseOrderId,
      }));
      window.history.replaceState(
        null,
        '',
        `?form=view&id=${result.purchaseOrderId}`,
      );
      viewLoadDataPO(true, result.purchaseOrderId);
      setOptionsUOM([]);

      setPoPackingListFormError([initialPoPackingListFormError]);
      setIsAddNewSupplierPage(true);
    } catch (err) {
      if (isStatus && payload.isAccepted === false) {
        navigate('/supplier-purchase-order');
        // navigate(`/supplier-purchase-order`);
      }

      if (isStatus) {
        snackActions.error(`${err}`);
        viewLoadDataPO();
      } else {
        snackActions.error(`${err}`);
      }
    }
  };

  const addPurchaseOrderLineItemAdd = () => {
    purchaseOrderLineItemModal.dataGridId = Math.random();
    setPurchaseOrderDetails((e) => ({
      ...e,
      poLineItem: {
        ...e.poLineItem,
        lineItems: [...e.poLineItem.lineItems, purchaseOrderLineItemModal],
      },
    }));
  };

  const addPurchaseOrderLineItemUpdate = (datas: IPurchaseOrderLineItem[]) => {
    const newState = datas.map((obj) => {
      if (obj.dataGridId === purchaseOrderLineItemModal.dataGridId) {
        return {
          ...obj,
          sku: purchaseOrderLineItemModal.sku,
          qty: purchaseOrderLineItemModal.qty,
          unitCost: purchaseOrderLineItemModal.unitCost,
          shippingCost: purchaseOrderLineItemModal.shippingCost,
          item: purchaseOrderLineItemModal.item,
          itemId: purchaseOrderLineItemModal.itemId,
          receiveId: purchaseOrderLineItemModal.receiveId,
          dataGridId: purchaseOrderLineItemModal.dataGridId,
        };
      }
      return obj;
    });
    setPurchaseOrderDetails((e) => ({
      ...e,
      poLineItem: {
        ...e.poLineItem,
        lineItems: [...newState],
      },
    }));
  };

  const addPurchaseOrderLineItemCombine = (
    datas: IPurchaseOrderLineItem[],
    result: IPurchaseOrderLineItem,
  ) => {
    const newState = datas.map((obj) => {
      if (obj.dataGridId === result.dataGridId) {
        return {
          ...obj,
          qty: (
            Number(purchaseOrderLineItemModal.qty) + Number(obj.qty)
          ).toString(),
        };
      }
      return obj;
    });
    setPurchaseOrderDetails((e) => ({
      ...e,
      poLineItem: {
        ...e.poLineItem,
        lineItems: [...newState],
      },
    }));
  };

  const addPurchaseOrderLineItem = () => {
    const datas = [...purchaseOrderDetails.poLineItem.lineItems];
    const gridId = purchaseOrderLineItemModal.dataGridId ?? 0;
    if (gridId === 0) {
      const itemCount = datas.length ?? 0;
      if (itemCount > 0) {
        const itemResult = datas.find(
          (r) => r.itemId === purchaseOrderLineItemModal.itemId,
        );
        if (itemResult) {
          withReactContent(Swal)
            .fire({
              title: `This item '${purchaseOrderLineItemModal.item.label}' already exists on the PO. Are you sure you want to add it again?`,
              icon: 'question',
              showDenyButton: true,
              showCancelButton: true,
              denyButtonText: 'No',
              cancelButtonText: 'Combine Qty',
              confirmButtonText: 'Yes',
              customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
              },
            })
            .then((result) => {
              if (result.isConfirmed) {
                addPurchaseOrderLineItemAdd();
              }
              if (result.isDismissed) {
                addPurchaseOrderLineItemCombine(datas, itemResult);
              }
            });
          return;
        }
      }
      addPurchaseOrderLineItemAdd();
      return;
    }
    const newValue = datas?.find(
      (row) => row.dataGridId === purchaseOrderLineItemModal?.dataGridId,
    );
    if (newValue !== undefined && newValue !== null) {
      addPurchaseOrderLineItemUpdate(datas);
    }
  };

  const addPurchaseOrderPackingList = () => {
    if (!validationForItemQtyLimit(true)) {
      snackActions.error(
        'All PO items have already been added to the Packing list. You can add more to the PO.',
      );
      return;
    }

    const newfield: IPurchaseOrderPackingList = {
      packingListId: null,
      shipTo: purchaseOrderDetails.poLineItem.shipTo,
      shipDate: null,
      estimatedArrival: null,
      shippingCost: 0,
      packingLineItems: [],
    };

    setPurchaseOrderDetails((e) => ({
      ...e,
      poPackingList: [...e.poPackingList, newfield],
    }));

    setPurchaseOrderTabValue(
      Number(purchaseOrderDetails.poPackingList.length + 1).toString(),
    );
  };

  const addPurchaseOrderPackingListLineItem = () => {
    const datas = [...purchaseOrderDetails.poPackingList];
    let newValue: IPurchaseOrderPackingList = {};

    if (
      purchaseOrderPackingListLineItemModal.packingListLineItemId === undefined
    ) {
      newValue = datas?.find(
        (row, index) =>
          index === purchaseOrderPackingListLineItemModal.packingListCount,
      );
    } else {
      newValue = datas?.find(
        (row) =>
          row.packingListCount ===
          purchaseOrderPackingListLineItemModal.packingListCount,
      );
    }

    if (newValue !== undefined && newValue !== null) {
      if (purchaseOrderPackingListLineItemModal.dataGridId === undefined) {
        purchaseOrderPackingListLineItemModal.dataGridId = Math.random();
        newValue.packingLineItems = [
          ...newValue.packingLineItems,
          purchaseOrderPackingListLineItemModal,
        ];
      } else {
        const dataLineItems = [...newValue.packingLineItems];

        const newState = dataLineItems.map((obj) => {
          if (
            obj.dataGridId === purchaseOrderPackingListLineItemModal.dataGridId
          ) {
            return {
              ...obj,
              packingListLineItemId:
                purchaseOrderPackingListLineItemModal?.packingListLineItemId,
              dataGridId: purchaseOrderPackingListLineItemModal?.dataGridId,
              receiveId: purchaseOrderPackingListLineItemModal?.receiveId,
              item: purchaseOrderPackingListLineItemModal?.item,
              binItemType: purchaseOrderPackingListLineItemModal?.binItemType,
              palletCount: purchaseOrderPackingListLineItemModal?.palletCount,
              weight: purchaseOrderPackingListLineItemModal?.weight,
              lotNoValue: purchaseOrderPackingListLineItemModal?.lotNoValue,
              itemId: purchaseOrderPackingListLineItemModal?.itemId,
              sku: purchaseOrderPackingListLineItemModal?.sku,
              description: purchaseOrderPackingListLineItemModal?.description,
              eaCase: purchaseOrderPackingListLineItemModal?.eaCase,
              totalCases: purchaseOrderPackingListLineItemModal?.totalCases,
              eachesPerCase:
                purchaseOrderPackingListLineItemModal?.eachesPerCase,
              qty: purchaseOrderPackingListLineItemModal?.qty,
              received: purchaseOrderPackingListLineItemModal?.received,
              receipts: purchaseOrderPackingListLineItemModal?.receipts,
              unitCost: purchaseOrderPackingListLineItemModal?.unitCost,
              primaryUOM: purchaseOrderPackingListLineItemModal?.primaryUOM,
              itemUnitOfMeasureId:
                purchaseOrderPackingListLineItemModal?.itemUnitOfMeasureId,
              notes: purchaseOrderPackingListLineItemModal?.notes,
              isSerialNo: purchaseOrderPackingListLineItemModal?.isSerialNo,
              serialNo: purchaseOrderPackingListLineItemModal?.serialNo,
            };
          }
          return obj;
        });

        newValue.packingLineItems = [...newState];
      }

      const newState = datas.map((obj, index) => {
        if (
          obj.packingListId
            ? obj.packingListId ===
              purchaseOrderPackingListModal.packingListCount
            : index === purchaseOrderPackingListModal.packingListCount
        ) {
          return {
            ...obj,
            packingListId: newValue?.packingListId,
            shipTo: newValue?.shipTo,
            shipDate: newValue?.shipDate,
            estimatedArrival: newValue?.estimatedArrival,
            shippingCost: newValue?.shippingCost,
            packingLineItems: newValue?.packingLineItems,
          };
        }
        return obj;
      });

      setPurchaseOrderDetails((e) => ({
        ...e,
        poPackingList: [...newState],
      }));
    }
  };

  const deletePurchaseOrderLineItem = async (e: any) => {
    e.preventDefault();

    if (pageViewerPO.isNew()) {
      const datas = [...purchaseOrderDetails.poLineItem.lineItems];
      const newValues = datas.filter(
        (r) => r.dataGridId !== purchaseOrderLineItemModal.dataGridId,
      );
      setPurchaseOrderDetails((prev) => ({
        ...prev,
        poLineItem: {
          ...prev.poLineItem,
          lineItems: [...newValues],
        },
      }));

      return;
    }

    const datas = [...purchaseOrderDetails.poLineItem.lineItems];
    const newValues = datas.filter(
      (r) => r.dataGridId !== purchaseOrderLineItemModal.dataGridId,
    );

    const payload = {
      // PURCHASE ORDER DESCRIPTION SECTION
      purchaseOrderId: purchaseOrderDetails?.purchaseOrderId,
      status: purchaseOrderDetails?.status, // for now = 1: new
      isAccepted: purchaseOrderDetails?.isAccepted,
      customerId: parseInt(currentUser.Claim_CustomerId, 10),
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId:
        purchaseOrderDetails.poLineItem.shipTo.value !== -1
          ? purchaseOrderDetails.poLineItem.shipTo.value
          : currentLocationAndFacility.customerFacilityId,
      warehouseCustomerId:
        purchaseOrderDetails.poDescription.customer !== null &&
        purchaseOrderDetails.poDescription.customer.value !== -1
          ? purchaseOrderDetails.poDescription.customer.value
          : 0,
      purchaseOrderNo: purchaseOrderDetails.poDescription?.poNumber
        ? purchaseOrderDetails.poDescription?.poNumber
        : null,
      issueDate: purchaseOrderDetails.poDescription?.issueDate
        ? moment(purchaseOrderDetails.poDescription.issueDate).format(
            'MM/DD/yyyy',
          )
        : null,
      acceptedDate: purchaseOrderDetails.poDescription.acceptedDate
        ? moment(purchaseOrderDetails.poDescription.acceptedDate).format(
            'MM/DD/yyyy',
          )
        : null,
      expectedArrivalDate: purchaseOrderDetails?.poDescription
        ?.expectedArrivalDate
        ? moment(purchaseOrderDetails.poDescription.expectedArrivalDate).format(
            'MM/DD/yyyy',
          )
        : null,
      estimatedBulkReadyDate: purchaseOrderDetails.poDescription
        .estimatedBulkReadyDate
        ? moment(
            purchaseOrderDetails.poDescription.estimatedBulkReadyDate,
          ).format('MM/DD/yyyy')
        : null,
      note: purchaseOrderDetails.poDescription.note,
      paymentStatus: purchaseOrderDetails.poDescription.paymentStatus.value,
      // SUPPLIER SECTION
      supplier: {
        supplierId:
          purchaseOrderDetails.poDescription.knownSuppliers.value === -1
            ? null
            : purchaseOrderDetails.poDescription.knownSuppliers.value,
        companyName: purchaseOrderDetails.poDescription?.companyName,
        recipientName: purchaseOrderDetails.poDescription?.recipientName,
        phone: purchaseOrderDetails.poDescription?.phone,
        email: purchaseOrderDetails.poDescription?.email,
        address1: purchaseOrderDetails.poDescription?.address1,
        address2: purchaseOrderDetails.poDescription?.address2,
        cityTownship: purchaseOrderDetails.poDescription?.cityTownship,
        stateProvinceRegion:
          purchaseOrderDetails.poDescription.stateProvinceRegion,
        zipPostalCode: purchaseOrderDetails.poDescription.zipPostalCode,
        country: purchaseOrderDetails.poDescription.country.value,
        supplierIdentifier:
          purchaseOrderDetails.poDescription.supplierIdentifier,
        departmentNo: purchaseOrderDetails.poDescription.departmentNo,
      },
      // PO LINE ITEM SECTION
      pOLineItems: newValues.map((v) => ({
        receiveId: v.receiveId,
        itemId: v.item.value,
        sKU: v.item.label,
        description: v.description,
        qty: v.qty,
        unitCost: v.unitCost,
        shippingCost: v.shippingCost,
      })),
      packingLists: purchaseOrderDetails.poPackingList.map((p) => ({
        packingListId: p?.packingListId,
        shipToCustomerFacilityId: p?.shipTo?.value,
        shipTo: p?.shipTo?.label,
        shipDate: p.shipDate ? moment(p.shipDate).format('MM/DD/yyyy') : null,
        estimatedArrival: p.estimatedArrival
          ? moment(p.estimatedArrival).format('MM/DD/yyyy')
          : null,
        shippingCost: p?.shippingCost,
        carrier: p?.carrier,
        trackingNo: p?.trackingNo,
        PackingListLineItems: p.packingLineItems.map((l) => ({
          packingListLineItemId: l?.packingListLineItemId,
          receiveId: l?.receiveId,
          itemId: l?.itemId,
          sKU: l?.sku,
          description: l?.description,
          qty: l?.qty,
          received: l?.received,
          eaCase: l?.eaCase,
          totalCases: l?.totalCases,
          eachesPerCase: l?.eachesPerCase,
          receipts: l?.receipts,
          unitCost: l?.unitCost,
          binItemType: l?.binItemType.value,
          palletCount: l?.palletCount,
          weight: l?.weight,
          lotId: l?.lotNoValue?.lotId,
          primaryUOM: l?.primaryUOM,
          itemUnitOfMeasureId: l?.itemUnitOfMeasureId,
        })),
      })),
    };

    if (!validateOnlick()) {
      snackActions.error('Please complete required fields');
      return;
    }
    try {
      const result = await createEditPurchaseOrder(payload);
      setQueryStringPurchaseOrder((f) => ({
        ...f,
        form: 'view',
        id: result.purchaseOrderId,
      }));
      window.history.replaceState(
        null,
        '',
        `?form=view&id=${result.purchaseOrderId}`,
      );
      viewLoadDataPO();
      snackActions.success(`Successfully updated the purchase order.`);
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };

  const deletePurchaseOrderPackingListLineItem = async (e: any) => {
    e.preventDefault();

    const datas = [...purchaseOrderDetails.poPackingList];
    const newValue = datas?.find(
      (row) =>
        row.packingListCount ===
        purchaseOrderPackingListLineItemModal.packingListCount,
    );
    const LineItem = [...newValue.packingLineItems];
    const removeLineItem = LineItem.filter(
      (r) => r.dataGridId !== purchaseOrderPackingListLineItemModal.dataGridId,
    );

    const newState = datas.map((obj) => {
      if (
        obj.packingListId ===
        purchaseOrderPackingListLineItemModal.packingListCount
      ) {
        return {
          ...obj,
          packingListId: newValue?.packingListId,
          shipTo: newValue?.shipTo,
          shipDate: newValue?.shipDate,
          estimatedArrival: newValue?.estimatedArrival,
          shippingCost: newValue?.shippingCost,
          packingLineItems: removeLineItem,
        };
      }
      return obj;
    });

    const payload = {
      // PURCHASE ORDER DESCRIPTION SECTION
      purchaseOrderId: purchaseOrderDetails?.purchaseOrderId,
      status: purchaseOrderDetails?.status, // for now = 1: new
      isAccepted: purchaseOrderDetails?.isAccepted,
      customerId: parseInt(currentUser.Claim_CustomerId, 10),
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId:
        purchaseOrderDetails.poLineItem.shipTo.value !== -1
          ? purchaseOrderDetails.poLineItem.shipTo.value
          : currentLocationAndFacility.customerFacilityId,
      warehouseCustomerId:
        purchaseOrderDetails.poDescription.customer !== null &&
        purchaseOrderDetails.poDescription.customer.value !== -1
          ? purchaseOrderDetails.poDescription.customer.value
          : 0,
      purchaseOrderNo: purchaseOrderDetails.poDescription?.poNumber
        ? purchaseOrderDetails.poDescription?.poNumber
        : null,
      issueDate: purchaseOrderDetails.poDescription?.issueDate
        ? moment(purchaseOrderDetails.poDescription.issueDate).format(
            'MM/DD/yyyy',
          )
        : null,
      acceptedDate: purchaseOrderDetails.poDescription.acceptedDate
        ? moment(purchaseOrderDetails.poDescription.acceptedDate).format(
            'MM/DD/yyyy',
          )
        : null,
      expectedArrivalDate: purchaseOrderDetails?.poDescription
        ?.expectedArrivalDate
        ? moment(purchaseOrderDetails.poDescription.expectedArrivalDate).format(
            'MM/DD/yyyy',
          )
        : null,
      estimatedBulkReadyDate: purchaseOrderDetails.poDescription
        .estimatedBulkReadyDate
        ? moment(
            purchaseOrderDetails.poDescription.estimatedBulkReadyDate,
          ).format('MM/DD/yyyy')
        : null,
      note: purchaseOrderDetails.poDescription.note,
      paymentStatus: purchaseOrderDetails.poDescription.paymentStatus.value,
      // SUPPLIER SECTION
      supplier: {
        supplierId:
          purchaseOrderDetails.poDescription.knownSuppliers.value === -1
            ? null
            : purchaseOrderDetails.poDescription.knownSuppliers.value,
        companyName: purchaseOrderDetails.poDescription?.companyName,
        recipientName: purchaseOrderDetails.poDescription?.recipientName,
        phone: purchaseOrderDetails.poDescription?.phone,
        email: purchaseOrderDetails.poDescription?.email,
        address1: purchaseOrderDetails.poDescription?.address1,
        address2: purchaseOrderDetails.poDescription?.address2,
        cityTownship: purchaseOrderDetails.poDescription?.cityTownship,
        stateProvinceRegion:
          purchaseOrderDetails.poDescription.stateProvinceRegion,
        zipPostalCode: purchaseOrderDetails.poDescription.zipPostalCode,
        country: purchaseOrderDetails.poDescription.country.value,
        supplierIdentifier:
          purchaseOrderDetails.poDescription.supplierIdentifier,
        departmentNo: purchaseOrderDetails.poDescription.departmentNo,
      },
      // PO LINE ITEM SECTION
      pOLineItems: purchaseOrderDetails.poLineItem.lineItems.map((v) => ({
        receiveId: v.receiveId,
        itemId: v.item.value,
        sKU: v.item.label,
        description: v.description,
        qty: v.qty,
        unitCost: v.unitCost,
        shippingCost: v.shippingCost,
      })),
      packingLists: newState.map((p) => ({
        packingListId: p?.packingListId,
        shipToCustomerFacilityId: p?.shipTo?.value,
        shipTo: p?.shipTo?.label,
        shipDate: p.shipDate ? moment(p.shipDate).format('MM/DD/yyyy') : null,
        estimatedArrival: p.estimatedArrival
          ? moment(p.estimatedArrival).format('MM/DD/yyyy')
          : null,
        shippingCost: p?.shippingCost,
        carrier: p?.carrier,
        trackingNo: p?.trackingNo,
        PackingListLineItems: p.packingLineItems.map((l) => ({
          packingListLineItemId: l?.packingListLineItemId,
          receiveId: l?.receiveId,
          itemId: l?.itemId,
          sKU: l?.sku,
          description: l?.description,
          qty: l?.qty,
          received: l?.received,
          eaCase: l?.eaCase,
          totalCases: l?.totalCases,
          eachesPerCase: l?.eachesPerCase,
          receipts: l?.receipts,
          unitCost: l?.unitCost,
          binItemType: l?.binItemType.value,
          palletCount: l?.palletCount,
          weight: l?.weight,
          lotId: l?.lotNoValue?.lotId,
          primaryUOM: l?.primaryUOM,
          itemUnitOfMeasureId: l?.itemUnitOfMeasureId,
          notes: l?.notes,
        })),
      })),
    };

    if (!validateOnlick()) {
      snackActions.error('Please complete required fields');
      return;
    }
    try {
      const result = await createEditPurchaseOrder(payload);
      setQueryStringPurchaseOrder((f) => ({
        ...f,
        form: 'view',
        id: result.purchaseOrderId,
      }));
      window.history.replaceState(
        null,
        '',
        `?form=view&id=${result.purchaseOrderId}`,
      );
      viewLoadDataPO();
      snackActions.success(`Successfully updated the purchase order.`);
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };

  const printPurcahseOrderItemOnClick = useCallback(
    async (e: React.SyntheticEvent, id: any): Promise<void> => {
      e.preventDefault();
      setPurchaseOrderIdPrint(id);
      onOpenPrintFormTemplateModal();
    },
    [purchaseOrderIdPrint],
  );

  useEffect(() => {
    const optWarehouseCustomer = onLoadCustomersLookup();
    optWarehouseCustomer
      .then((ddl) => {
        setOptionCustomers(
          ddl.map((c: any) => ({
            label: c.name,
            value: c.warehouseCustomerId,
          })),
        );

        if (ddl.length === 1) {
          setPurchaseOrderDetails((e) => ({
            ...e,
            poDescription: {
              ...e.poDescription,
              customer: {
                value: ddl[0].warehouseCustomerId,
                label: ddl[0].name,
              },
            },
          }));
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));

    const optCountries = onLoadCountries();
    optCountries
      .then((ddl) => {
        setOptionCountries(
          ddl.map((c: any) => ({
            label: c.name,
            value: c.countryId,
          })),
        );
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));

    const optCustomerSupplier = onLoadCustomerSuppliers();
    optCustomerSupplier
      .then((ddl) => {
        setOptionCustomerSupplier(
          ddl.map((c: any) => ({
            label: c.name,
            value: c.supplierId,
          })),
        );
      })
      // eslint-disable-next-line no-console

      .catch((err) => console.log(err));
    const optCustomerFacility = onLoadCustomerFacilities();
    optCustomerFacility
      .then((ddl) => {
        setOptionCustomerFacility(
          ddl.map((c: any) => ({
            label: c.name,
            value: c.customerFacilityId,
          })),
        );

        if (!purchaseOrderDetails.poLineItem.shipTo) {
          setPurchaseOrderDetails((e) => ({
            ...e,
            poLineItem: {
              ...e.poLineItem,
              shipTo: {
                value: ddl[0].customerFacilityId,
                label: ddl[0].name,
              },
            },
          }));
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));

    viewLoadDataPO();
  }, [currentUser.Claim_CustomerName]);

  const value: IPurchaseOrder = useMemo(
    () => ({
      optionCustomers,
      optionCountries,
      optionCustomerSupplier,
      optionCustomerFacility,
      purchaseOrderDetails,
      purchaseOrderDetailsFormError,
      purchaseOrderLineItemModal,
      purchaseOrderPackingListModal,
      purchaseOrderPackingListLineItemModal,
      pageViewerPO,
      purchaseOrderIdPrint,
      isAddNewSupplier,
      isAddNewSupplierPage,
      hasAlreadySupplier,
      uomValue,
      optionsUOM,
      poFees,
      purchaseOrderTabValue,
      queryStringPurchaseOrder,
      poPackingListFormError,
      purchaseOrders,
      filteredPurchaseOrders,
      purchaseOrderIdDelete,
      importErrorMessage,
      packinglistIdImport,
      statusFilter,
      setPackinglistIdImport,
      setImportErrorMessage,
      setPurchaseOrderIdDelete,
      setPurchaseOrders,
      setFilteredPurchaseOrders,
      setPoPackingListFormError,
      setQueryStringPurchaseOrder,
      setPurchaseOrderTabValue,
      handleChangeTabValue,
      setOptionsUOM,
      onLoadGeneratedPONumber,
      handleOnCreatePurchaseOrder,
      handleEditPurchaseOrder,
      setIsAddNewSupplierPage,
      setPurchaseOrderDetails,
      setPurchaseOrderDetailsFormError,
      setPurchaseOrderLineItemModal,
      addPurchaseOrderLineItem,
      setIsAddNewSupplier,
      addPurchaseOrderPackingList,
      addPurchaseOrderPackingListLineItem,
      viewLoadDataPO,
      setPurchaseOrderPackingListModal,
      setPurchaseOrderPackingListLineItemModal,
      deletePurchaseOrderLineItem,
      deletePurchaseOrderPackingListLineItem,
      setPurchaseOrderIdPrint,
      printPurcahseOrderItemOnClick,
      setUomValue,
      getFees,
      validationForItemQtyLimit,
      setStatusFilter,
    }),
    [
      statusFilter,
      optionCustomers,
      optionsUOM,
      uomValue,
      isAddNewSupplier,
      isAddNewSupplierPage,
      optionCountries,
      optionCustomerSupplier,
      optionCustomerFacility,
      purchaseOrderDetails,
      purchaseOrderDetailsFormError,
      purchaseOrderLineItemModal,
      purchaseOrderPackingListLineItemModal,
      pageViewerPO,
      purchaseOrderIdPrint,
      hasAlreadySupplier,
      handleOnCreatePurchaseOrder,
      poFees,
      getFees,
      purchaseOrderTabValue,
      queryStringPurchaseOrder,
      poPackingListFormError,
      purchaseOrders,
      filteredPurchaseOrders,
      purchaseOrderIdDelete,
      importErrorMessage,
      packinglistIdImport,
    ],
  );

  return (
    <PurchaseOrderContext.Provider value={value}>
      {children}
    </PurchaseOrderContext.Provider>
  );
};
