import React, { useContext, useState } from 'react';

import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import { useSubpageReceiptsContext } from 'pages/receiving/receipts/context';
import { ModalTitleStatus } from 'pages/receiving/receipts/subpage';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Grid as MUIGrid,
  Button,
  CircularProgress,
} from '@mui/material';

import { MUIContainer, MUIContent } from '../editreceiptmodal';

export default React.memo(() => {
  const { isConfirmReceiptModalOpen, onCloseConfirmReceiptModal } =
    useContext(GlobalContext);

  const { handleUserPermissionAllow } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const {
    handleOnClickConfirmReceipt,
    handleOnClickDeleteLineItem,
    modalTitle,
    singleLineItemRow,
    setModalTitle,
    queryStringItemReceipts,
  } = useSubpageReceiptsContext();

  return (
    <Modal open={isConfirmReceiptModalOpen}>
      <MUIContainer
        sx={{
          maxWidth:
            modalTitle === ModalTitleStatus.Delete
              ? '500px !important'
              : '1000px',
        }}
      >
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          {modalTitle === ModalTitleStatus.Delete
            ? 'Delete Line Item'
            : 'Confirm Receipt'}
        </Typography>
        <MUIContent sx={{ padding: '1px 31px !important' }}>
          {modalTitle === ModalTitleStatus.Delete ? (
            <Typography variant="subtitle1">
              Are you sure you want to delete {singleLineItemRow.sku} from this
              receipt?
            </Typography>
          ) : (
            <Typography variant="subtitle1">
              Confirm receipt in the current state? Any remaining Put Away items
              will be moved to Put Away queue.
            </Typography>
          )}
        </MUIContent>
        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={9}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '60%' }}
                onClick={() => {
                  onCloseConfirmReceiptModal();
                  setModalTitle(null);
                  setLoading(false);
                }}
                disabled={loading}
              >
                Cancel
              </Button>
              {modalTitle === ModalTitleStatus.Delete ? (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                  }}
                  onClick={(e) => {
                    setLoading(true);
                    handleOnClickDeleteLineItem(e).then(() => {
                      setLoading(false);
                    });
                  }}
                  disabled={loading}
                >
                  {loading && <CircularProgress sx={{ color: 'white' }} />}
                  Delete
                </Button>
              ) : (
                <>
                  {handleUserPermissionAllow(
                    UserPermissionEnum.Receiving_ConfirmQC,
                  ) && (
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      sx={{
                        padding: 1,
                        width: '100%',
                        marginRight: '10px',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                      }}
                      onClick={(e) => {
                        setLoading(true);
                        handleOnClickConfirmReceipt(
                          e,
                          queryStringItemReceipts.id,
                          true,
                          true,
                        ).then(() => {
                          setLoading(false);
                        });
                      }}
                      disabled={loading}
                    >
                      {loading && (
                        <CircularProgress
                          sx={{
                            width: '25px !important',
                            height: '25px !important',
                          }}
                        />
                      )}
                      CONFIRM QC
                    </Button>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    onClick={(e) => {
                      setLoading(true);
                      handleOnClickConfirmReceipt(
                        e,
                        queryStringItemReceipts.id,
                        true,
                      ).then(() => {
                        setLoading(false);
                      });
                    }}
                    sx={{
                      padding: 1,
                      width: '125%',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                    }}
                    disabled={loading}
                  >
                    {loading && (
                      <CircularProgress
                        sx={{
                          width: '25px !important',
                          height: '25px !important',
                        }}
                      />
                    )}
                    Confirm & Move to Put Away
                  </Button>
                </>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
