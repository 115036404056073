import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

import { responsiveFontSizes } from '@mui/material';

export const getCustomers = async () => {
  try {
    const response = await httpClient.get('/Customer/Customers');

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const toggleCustomerStatus = async (customerId) => {
  try {
    const response = await httpClient.get(
      `/Customer/toggleCustomerStatus/${customerId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getCustomerById = async (customerId) => {
  try {
    const response = await httpClient.get(`/Customer/${customerId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCustomerDefaultThreshold = async (customerId) => {
  try {
    const response = await httpClient.get(
      `/Customer/GetDefaultThreshold/${customerId}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateCustomerDefaultThreshold = async (customerId, threshold) => {
  try {
    const response = await httpClient.put(
      `/Customer/UpdateDefaultThreshold/${customerId}/${threshold}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createCustomer = async (data) => {
  try {
    const response = await httpClient.post(`/Customer/CreateCustomer`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const editCustomer = async (customerId, editForm) => {
  try {
    const response = await httpClient.put(`/Customer/${customerId}`, editForm);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateCustomerIsActive = async (isActiveData) => {
  try {
    const response = await httpClient.post(
      `/Customer/Update/IsActive`,
      isActiveData,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCustomerSuppliers = async (customerId) => {
  try {
    const response = await httpClient.get(
      `/Supplier/SupplierItems/${customerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createCustomerSupplier = async (data) => {
  try {
    const response = await httpClient.post(`/Supplier/CreateSupplier`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const EditCustomerSupplier = async (supplierId, editForm) => {
  try {
    const response = await httpClient.put(`/supplier/${supplierId}`, editForm);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// ------------------------ supplier page
export const getSupplierByID = async (supplierId) => {
  try {
    const response = await httpClient.get(
      `/supplier/getsupplierbyid/${supplierId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// get supplier portal account

export const getSupplierPortalAccount = async (supplierId) => {
  try {
    const response = await httpClient.get(
      `/supplier/SupplierPortalAccount/${supplierId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createSupplierPortalAccount = async (data) => {
  try {
    const response = await httpClient.post(
      `/Supplier/SupplierPortalAccount/Create`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const generateActivationUrl = async (data) => {
  try {
    const response = await httpClient.post(
      `/Supplier/SupplierPortalAccount/GenerateActivationUrl`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const updateSupplierPortalAccountIsActive = async (data) => {
  try {
    const response = await httpClient.post(
      `/Supplier/SupplierPortalAccount/UpdateIsActive`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// data for supplier activation page
export const GetAccountDetailForActivationSupplier = async (key) => {
  try {
    const response = await httpClient.get(
      `/Supplier/SupplierPortalAccount/AccountDetailForActivation?key=${key}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const updateSupplierPortalAccountActivation = async (data) => {
  try {
    const response = await httpClient.post(
      `/Supplier/SupplierPortalAccount/UpdateSupplierPortalAccountActivation`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const checkCustomerIsActive = async () => {
  try {
    const response = await httpClient.get(`/Customer/checkCustomerActive`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const InventoryCheckSetToHangFire = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/inventorychecksettohangfire`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// RULES MANAGEMENT -----------------------
export const updateCustomerRulesManagement = async (data) => {
  try {
    const response = await httpClient.post(
      `/Customer/UpdateCustomerRulesManagement`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCustomerRulesManagementByCustomerId = async (customerId) => {
  try {
    const response = await httpClient.get(
      `/Customer/GetCustomerRulesManagementByCustomerId/${customerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCustomerOrderSourceRulesByCustomerId = async (customerId) => {
  try {
    const response = await httpClient.get(
      `/Customer/GetCustomerOrderSourceRulesByCustomerId/${customerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const checkCustomerPullOrdersInProgress = async (customerId) => {
  try {
    const response = await httpClient.get(
      `/Customer/CheckCustomerPullOrdersDownloading/${customerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCustomerLogoFromLogin = async (customerName) => {
  try {
    const response = await httpClient.get(
      `/Customer/GetCustomerLogoFromLogin?customer=${customerName}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
