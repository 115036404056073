import React, { useState, ChangeEvent } from 'react';

import Card from 'components/card';
import Header from 'components/header';
import Input from 'components/input/Input';
import { Grid } from 'components/styles';
import {
  PickingIssueProvider,
  usePickingIssueContext,
  PickTicketLineItemIssueEnum,
} from 'pages/picking/pickingissue/context';
import { MainContainer, ContentContainer } from 'pages/styles';

import { Search } from '@mui/icons-material';
import { Typography, Chip, Box, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridActionsCellItem,
  GridColumns,
  GridToolbar,
} from '@mui/x-data-grid';

const PickingIssueView = () => {
  const {
    pickTicketLineItems,
    pickTicketLineItemsReset,
    setPickTicketLineItems,
    onPickTicketLineItemIssueResolve,
  } = usePickingIssueContext();
  const theme = useTheme();

  const [searchParams, setSearchParams] = useState({
    searchPickId: '',
    searchOrderNo: '',
    searchIssueStr: '',
    searchBin: '',
    searchLpn: '',
  });

  const onForm = (key, text) => {
    setSearchParams(() => ({
      ...searchParams,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    onForm(key, event.target.value);
  };

  const filterDataRows = () => {
    const dataPickId = pickTicketLineItems.filter((d) =>
      d.pickId
        .toString()
        .toLowerCase()
        .includes(searchParams.searchPickId.toLowerCase()),
    );

    const dataOrderNo = dataPickId.filter((d) =>
      d.orderNo
        .toString()
        .toLowerCase()
        .includes(searchParams.searchOrderNo.toLowerCase()),
    );

    const dataIssueStr = dataOrderNo.filter((d) =>
      d.issueStr
        .toString()
        .toLowerCase()
        .includes(searchParams.searchIssueStr.toLowerCase()),
    );

    const dataBin = dataIssueStr.filter((d) =>
      d.bin
        .toString()
        .toLowerCase()
        .includes(searchParams.searchBin.toLowerCase()),
    );

    const result = dataBin.filter((d) =>
      d.lpn
        .toString()
        .toLowerCase()
        .includes(searchParams.searchLpn.toLowerCase()),
    );

    setPickTicketLineItems(result);

    if (Object.values(searchParams).every((x) => x === '')) {
      setPickTicketLineItems(pickTicketLineItemsReset);
    }
  };

  type PickingIssueRows = typeof pickTicketLineItems[number];

  const pickingIssueColumns: GridColumns<PickingIssueRows> = [
    {
      field: 'actions',
      type: 'actions',
      hideable: false,
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Resolve"
          disabled={!params.row.contextMenu_Resolve_Enable}
          onClick={(e) => {
            onPickTicketLineItemIssueResolve(
              e,
              params.row.pickTicketLineItemId,
            );
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'pickId',
      width: 150,
      disableColumnMenu: true,
      disableReorder: true,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Pick Ticket</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchPickId}
            onChange={(value) => inputHandler('searchPickId', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterDataRows();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.pickId}</Typography>,
    },
    {
      field: 'orderNo',
      width: 150,
      disableColumnMenu: true,
      disableReorder: true,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Order No.</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchOrderNo}
            onChange={(value) => inputHandler('searchOrderNo', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterDataRows();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`/orders/sales-orders?form=view&id=${params.row.orderId}`}
          target="_blank"
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.orderNo}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'issueStr',
      width: 200,
      disableColumnMenu: true,
      disableReorder: true,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Issue</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchIssueStr}
            onChange={(value) => inputHandler('searchIssueStr', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterDataRows();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Box>
          {params.row.issue === PickTicketLineItemIssueEnum.NoStock && (
            <Chip
              label={params.row.issueStr}
              size="small"
              sx={{
                color: 'white',
                bgcolor: '#b00020',
              }}
            />
          )}

          {params.row.issue === PickTicketLineItemIssueEnum.LowStock && (
            <Chip
              label={params.row.issueStr}
              size="small"
              sx={{
                color: 'white',
                bgcolor: '#c2d509',
              }}
            />
          )}

          {params.row.issue === PickTicketLineItemIssueEnum.InvalidLocation && (
            <Chip
              label={params.row.issueStr}
              size="small"
              sx={{
                color: 'white',
                bgcolor: '#1772e4',
              }}
            />
          )}

          {params.row.issue === PickTicketLineItemIssueEnum.NoLocation && (
            <Chip
              label={params.row.issueStr}
              size="small"
              sx={{
                color: 'white',
                bgcolor: '#b306ec',
              }}
            />
          )}

          {params.row.issue === PickTicketLineItemIssueEnum.InvalidBarcode && (
            <Chip
              label={params.row.issueStr}
              size="small"
              sx={{
                color: 'white',
                bgcolor: '#e69f01',
              }}
            />
          )}
        </Box>
      ),
    },
    {
      field: 'bin',
      width: 150,
      disableColumnMenu: true,
      disableReorder: true,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Location</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchBin}
            onChange={(value) => inputHandler('searchBin', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterDataRows();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.bin}</Typography>,
    },
    {
      field: 'lpn',
      width: 150,
      disableColumnMenu: true,
      disableReorder: true,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">LPN</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchLpn}
            onChange={(value) => inputHandler('searchLpn', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterDataRows();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.lpn}</Typography>,
    },
  ];

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <Box>
        <Grid
          autoHeight
          headerHeight={120}
          rows={pickTicketLineItems}
          columns={pickingIssueColumns}
          pageSize={15}
          density="compact"
          rowsPerPageOptions={[15]}
          disableSelectionOnClick
          getRowId={(row) => row.pickTicketLineItemId}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              title: 'Picking Report',
            },
          }}
        />
      </Box>
    </Card>
  );
};

const PickingIssueContainer = () => <PickingIssueView />;

function PickingIssue() {
  return (
    <MainContainer>
      <PickingIssueProvider>
        <Header />
        <ContentContainer>
          <PickingIssueContainer />
        </ContentContainer>
      </PickingIssueProvider>
    </MainContainer>
  );
}

export default React.memo(PickingIssue);
