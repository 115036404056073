import React, { useContext, memo, useEffect, useState, useRef } from 'react';

import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Typography,
  Grid as MUIGrid,
  Box,
  Button,
  TextField,
} from '@mui/material';

import { usePackAndShipContext } from '../context';

export default memo(() => {
  const { isAddCustomPackageModalOpen, setIsAddCustomPackageModalOpen } =
    useContext(GlobalContext);

  const {
    pickTicketFormObj,
    setPickTicketFormObj,
    setAllowBarcodeScanner,
    updateCartonCodeFunction,
  } = usePackAndShipContext();

  const [customPackageError, setCustomPackageError] = useState(null);
  const itemRef = useRef(null);

  const customPackageValidation = () => {
    const temp: any = {};

    temp.width = pickTicketFormObj?.widthCustomPackage
      ? ''
      : 'This field is required';
    temp.height = pickTicketFormObj?.heightCustomPackage
      ? ''
      : 'This field is required';
    temp.length = pickTicketFormObj?.lengthCustomPackage
      ? ''
      : 'This field is required';

    setCustomPackageError(temp);
    return Object.values(temp).every((x) => x === '');
  };

  const handleCloseCustomPackage = () => {
    setIsAddCustomPackageModalOpen(false);
    setCustomPackageError(null);
    setAllowBarcodeScanner(true);
  };

  const handleCustomPackageSave = (e?: any) => {
    e?.preventDefault();

    if (!customPackageValidation()) {
      return;
    }
    const gettempPackage = [...pickTicketFormObj.packages];

    updateCartonCodeFunction(
      gettempPackage[0].packageId.toString(),
      '',
      false,
      false,
      true,
    ).then(() => {
      handleCloseCustomPackage();
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isAddCustomPackageModalOpen) {
        itemRef.current.focus();
        setAllowBarcodeScanner(false);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [isAddCustomPackageModalOpen]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        handleCloseCustomPackage();
      }
    };
    document.addEventListener('keyup', keyDownHandler);
    return () => {
      document.removeEventListener('keyup', keyDownHandler);
    };
  }, []);

  return (
    <Modal open={isAddCustomPackageModalOpen}>
      <MUIContainer sx={{ width: '20% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Use Custom Package
        </Typography>
        <MUIContent>
          <TextField
            id="outlined"
            name="width"
            value={pickTicketFormObj?.widthCustomPackage}
            onChange={(e) => {
              setPickTicketFormObj((prev) => ({
                ...prev,
                widthCustomPackage: e.target.value,
              }));
            }}
            type="number"
            label="Width(in)"
            size="small"
            sx={{ width: '100%' }}
            {...(customPackageError?.width && {
              error: true,
              helperText: customPackageError?.width,
            })}
            required
            inputRef={itemRef}
          />
          <TextField
            id="outlined"
            name="height"
            value={pickTicketFormObj?.heightCustomPackage}
            onChange={(e) => {
              setPickTicketFormObj((prev) => ({
                ...prev,
                heightCustomPackage: e.target.value,
              }));
            }}
            type="number"
            label="Height(in)"
            size="small"
            sx={{ width: '100%' }}
            {...(customPackageError?.height && {
              error: true,
              helperText: customPackageError?.height,
            })}
            required
          />
          <TextField
            id="outlined"
            name="length"
            value={pickTicketFormObj?.lengthCustomPackage}
            onChange={(e) => {
              setPickTicketFormObj((prev) => ({
                ...prev,
                lengthCustomPackage: e.target.value,
              }));
            }}
            type="number"
            label="Length(in)"
            size="small"
            sx={{ width: '100%' }}
            {...(customPackageError?.length && {
              error: true,
              helperText: customPackageError?.length,
            })}
            required
          />
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={10}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => handleCloseCustomPackage()}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                Cancel (Esc)
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={(e) => {
                  handleCustomPackageSave(e);
                }}
              >
                Save
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
