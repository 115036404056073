import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getCountries = async (searchText) => {
  try {
    const response = await httpClient.get(
      `/Country/Lookup/?searchText=${searchText}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
