import React, { useContext } from 'react';

import Button from 'components/button';
import { usePackAndShipContext } from 'pages/packandship/context';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Divider,
  Grid as MUIGrid,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

export const MUIContainer = MuiStyled(Box)`
    && {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  
      background: ${(props) =>
        props.theme.palette.mode === 'dark'
          ? props.theme.palette.background.default
          : 'white'};
      box-shadow: 0px 24px 38px #00000024;
      width: 100%;
      max-width: 600px;
      border-radius: 4px;
    }
  `;
export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;
export default React.memo(() => {
  const {
    isPackAndShipFindPickTicketWarningModalOpen,
    onOpenPackAndShipFindPickTicketWarningModal,
    onClosePackAndShipFindPickTicketWarningModal,
  } = useContext(GlobalContext);

  const { findPickTicketFormObj, setFindPickTicketFormObj } =
    usePackAndShipContext();

  if (
    findPickTicketFormObj?.isHold === true ||
    findPickTicketFormObj?.isCancelled
  ) {
    onOpenPackAndShipFindPickTicketWarningModal();
  }

  return (
    <Modal open={isPackAndShipFindPickTicketWarningModalOpen}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Cannot Continue
          </Typography>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              console.log('NO IMPLEMENTATION YET');
            }}
            noValidate
          >
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12}>
                {findPickTicketFormObj?.isCancelled && (
                  <Typography sx={{ paddingY: '12px' }} variant="body1">
                    That order appears to be cancelled.
                  </Typography>
                )}
                {findPickTicketFormObj?.isHold && (
                  <Typography sx={{ paddingY: '12px' }} variant="body1">
                    That order appears to be On Hold.
                  </Typography>
                )}
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={4}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => {
                      onClosePackAndShipFindPickTicketWarningModal();
                      setFindPickTicketFormObj((prev) => ({
                        ...prev,
                        pickTicketId: -1,
                        isHold: false,
                        isCancelled: false,
                      }));
                    }}
                    variant="contained"
                    size="small"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    Close
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
