import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';
import { number } from 'yup';

export const getLotLookup = async (itemId) => {
  try {
    const response = await httpClient.get(`/Lot/Lookup/${itemId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createLot = async (lot) => {
  try {
    const response = await httpClient.post(`/Lot/Create`, lot);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const getLotNoByCustomerId = async () => {
  try {
    const response = await httpClient.get(`Lot/GetLotLookupCustomerId`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getLotLookupDatagrid = async (
  customerFacilityId: number,
  view?: string,
) => {
  try {
    const viewQuery =
      view === undefined || view === null ? '' : `?view=${view}`;

    const response = await httpClient.get(
      `Lot/GetLotLookupDatagrid/${customerFacilityId}${viewQuery}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getLotActivityLog = async (
  id: number,
  itemId: number,
  view: string,
) => {
  try {
    const response = await httpClient.get(
      `Lot/GetLotActivityLog/${id}/${itemId}?view=${view}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
