import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getCustomerOrders = async (customerId) => {
  try {
    const response = await httpClient.get(``);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getCompletedOrdersForReturn = async (
  warehouseCustomerId,
  customerFacilityId,
  searchText?: string,
) => {
  try {
    const response = await httpClient.get(
      `/order/CompletedOrdersForReturn/${
        warehouseCustomerId || 0
      }/${customerFacilityId}?searchText=${searchText}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const getOrderDetailsForReturn = async (orderId) => {
  try {
    const response = await httpClient.get(
      `/order/OrderDetailsForReturn/${orderId}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
