import React, { useContext } from 'react';
import { useNavigate } from 'react-router';

import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Grid as MUIGrid,
  Button,
  CircularProgress,
} from '@mui/material';

export default React.memo((props: { notRedirect?: boolean }) => {
  const navigate = useNavigate();

  const { notRedirect } = props;
  const {
    isLabelToPrintModalOpen,
    setIsLabelToPrintModalOpen,
    labelToPrintDataUrl,
  } = useContext(GlobalContext);

  return (
    <Modal open={isLabelToPrintModalOpen}>
      <MUIContainer sx={{ width: '45% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Print Label
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            {!labelToPrintDataUrl ? (
              <CircularProgress />
            ) : (
              <iframe
                src={labelToPrintDataUrl}
                width="800px"
                height="800px"
                title="Embedded Page"
                frameBorder="0" // To remove the border around the iframe
                allowFullScreen // Optional: Allows the iframe to be fullscreen
              />
            )}
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="center"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => {
                  if (!notRedirect) navigate(`/pack-and-ship/find-pick-ticket`);

                  setIsLabelToPrintModalOpen(false);
                }}
                variant="contained"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                Close
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
