import React, { useContext, useEffect, useState } from 'react';

import {
  BinItemTypeEnum,
  getItemInventoryUOMEnumAbbreviation,
  ItemInventoryUOMEnum,
  ItemInventoryUOMTypeEnum,
  PageLoadStateEnum,
} from 'common/enums';
import { StateBase } from 'common/models';
import { ItemUnitOfMeasureDDL } from 'common/models/items/ddl';
import {
  InventoryBinDetailPickingWebModel,
  PickTicketLineItemLocationsPickingWebResponse,
} from 'common/models/picktickets/pickingweb/pickTicketLineItemLocations';
import { PickTicketLineItemPickPickingWebRequest } from 'common/models/picktickets/pickingweb/pickticketlineitempick';
import { snackActions } from 'config/snackbar.js';
import { AutoCompletePopper } from 'pages/shared/editreceiptmodal';
import {
  MUIContainer,
  MUIContent,
} from 'pages/shared/productionpickticketmodal';
import {
  GetPickTicketLineItemLocationsPickingWeb,
  GetInventoryBinDetailItemUnitOfMeasureDDLPickingWeb,
  PickTicketLineItemPickPickingWeb,
} from 'services/api/picktickets/picktickets.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { AutoCompleteTablePaper, AutoCompleteTableHeader } from 'styles/mui';

import {
  Box,
  Modal,
  Typography,
  Button,
  Grid as MUIGrid,
  CircularProgress,
  Autocomplete,
  TextField,
} from '@mui/material';

import { usePickingContext } from '../context';

type Form = {
  inventoryBinDetail?: InventoryBinDetailPickingWebModel;
  qty?: number;
  pickedUOM: ItemUnitOfMeasureDDL;
  availableQtyString?: string;
};

const INITIAL_STATE_FORM: Form = {
  inventoryBinDetail: null,
  qty: null,
  pickedUOM: null,
  availableQtyString: null,
};

type FormError = {
  inventoryBinDetail: string;
  qty: string;
  pickedUOM: string;
};

const INITIAL_STATE_FORM_ERROR: FormError = {
  inventoryBinDetail: '',
  qty: '',
  pickedUOM: '',
};

type Model = {
  inventoryBinDetails: InventoryBinDetailPickingWebModel[];
  pickedUOMs: ItemUnitOfMeasureDDL[];
  form: Form;
  formError: FormError;
};

const INITIAL_STATE_MODEL: Model = {
  inventoryBinDetails: [],
  pickedUOMs: [],
  form: INITIAL_STATE_FORM,
  formError: INITIAL_STATE_FORM_ERROR,
};

type State = StateBase<Model> & {
  isBusy: boolean;
};

const INITIAL_STATE: State = {
  pageLoadState: PageLoadStateEnum.LOAD_NONE,
  isBusy: false,
  vm: INITIAL_STATE_MODEL,
};

const AutoCompletePaper = ({ children }) => (
  <AutoCompleteTablePaper sx={{ maxHeight: '250px' }}>
    <AutoCompleteTableHeader>
      <div className="th tw-140">Location</div>
      <div className="th">Area</div>
      <div className="th">Available Qty</div>
      <div className="th">Type</div>
      <div className="th">Lot</div>
      <div className="th">LPN</div>
    </AutoCompleteTableHeader>
    {children}
  </AutoCompleteTablePaper>
);

const InternalModal = () => {
  const [state, setState] = useState<State>(INITIAL_STATE);

  const {
    state: pickingModalState,
    closePickTicketLineItemPickModal,
    closePickTicketLineItemPickModalAfterPick,
  } = usePickingContext();
  const { currentLocationAndFacility } = useContext(AuthContext);

  function pageLoadingStart() {
    setState((prevState) => ({
      ...prevState,
      pageLoadState: PageLoadStateEnum.LOAD_START,
    }));
  }

  function viewLoadData() {
    const model: Model = INITIAL_STATE_MODEL;
    model.form = { ...INITIAL_STATE_FORM };
    model.formError = { ...INITIAL_STATE_FORM_ERROR };

    GetPickTicketLineItemLocationsPickingWeb(
      pickingModalState.vm.pickTicketLineItemToPick.pickTicketLineItemId ?? 0,
      currentLocationAndFacility.customerFacilityId ?? 0,
    )
      .then((x: PickTicketLineItemLocationsPickingWebResponse) => {
        model.inventoryBinDetails = x.inventoryBinDetails;

        if (x.inventoryBinDetails.length > 0) {
          const { 0: first } = x.inventoryBinDetails;
          model.form.inventoryBinDetail = first;

          if (
            pickingModalState.vm.pickTicketLineItemToPick.uomType ===
              ItemInventoryUOMTypeEnum.Pallet &&
            first.binItemType === BinItemTypeEnum.Pallet
          ) {
            model.form.qty = 1;
          }

          GetInventoryBinDetailItemUnitOfMeasureDDLPickingWeb(
            pickingModalState.vm.pickTicketLineItemToPick
              .pickTicketLineItemId ?? 0,
            first.inventoryBinDetailId,
          )
            .then((z) => {
              const itemUnitOfMeasures = z as ItemUnitOfMeasureDDL[];

              let pickedUOM: ItemUnitOfMeasureDDL = null;
              let availableQtyString: string | null = null;
              if (
                itemUnitOfMeasures.length > 0 &&
                itemUnitOfMeasures.length === 1
              ) {
                const itemUnitOfMeasure = { ...itemUnitOfMeasures[0] };
                pickedUOM = itemUnitOfMeasure;
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                availableQtyString = getAvailableQtyString(itemUnitOfMeasure);
              }

              setState((prevState) => ({
                ...prevState,
                vm: {
                  ...prevState.vm,
                  pickedUOMs: [...itemUnitOfMeasures],
                  form: {
                    ...prevState.vm.form,
                    pickedUOM,
                    availableQtyString,
                  },
                },
              }));
            })
            .catch((err) => {
              snackActions.error(err);
            });
        }

        setState((prevState) => ({
          ...prevState,
          pageLoadState: PageLoadStateEnum.LOAD_FINISH,
          vm: model,
        }));
      })
      .catch((err) => {
        setState((prevState) => ({
          ...prevState,
          pageLoadState: PageLoadStateEnum.LOAD_FINISH,
          vm: model,
        }));
        snackActions.error(err);
      });
  }

  // page load
  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_NONE) {
      pageLoadingStart();
    }
  }, [state.pageLoadState]);

  // load data upon page load
  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_START) {
      viewLoadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pageLoadState, currentLocationAndFacility]);

  // facility change
  useEffect(() => {
    // this will automatically trigger viewLoadData
    pageLoadingStart();
  }, [currentLocationAndFacility]);

  function closeModal() {
    closePickTicketLineItemPickModal();
  }

  function getAvailableQtyString(
    newValue: ItemUnitOfMeasureDDL,
    inventoryBinDetail: InventoryBinDetailPickingWebModel | null = null,
  ) {
    let inventoryBinDetailInternal: InventoryBinDetailPickingWebModel =
      state.vm.form.inventoryBinDetail;
    if (inventoryBinDetail !== null) {
      inventoryBinDetailInternal = inventoryBinDetail;
    }

    let availableQtyString: string | null = null;
    if (newValue !== null) {
      if (
        pickingModalState.vm.pickTicketLineItemToPick.uomType ===
        ItemInventoryUOMTypeEnum.Primary
      ) {
        if (newValue.uomType === ItemInventoryUOMTypeEnum.Primary) {
          availableQtyString = `${inventoryBinDetailInternal.availableQty}`;
        } else if (newValue.uomType === ItemInventoryUOMTypeEnum.Other) {
          availableQtyString = `${inventoryBinDetailInternal.type}`;
        } else if (newValue.uomType === ItemInventoryUOMTypeEnum.Pallet) {
          availableQtyString = `1 pallet (${
            inventoryBinDetailInternal.availableQty
          } ${getItemInventoryUOMEnumAbbreviation(
            pickingModalState.vm.pickTicketLineItemToPick.primaryUOM,
          )})`;
        }
      } else if (
        pickingModalState.vm.pickTicketLineItemToPick.uomType ===
        ItemInventoryUOMTypeEnum.Other
      ) {
        availableQtyString = `${inventoryBinDetailInternal.type}`;
      } else if (
        pickingModalState.vm.pickTicketLineItemToPick.uomType ===
        ItemInventoryUOMTypeEnum.Pallet
      ) {
        availableQtyString = `1`;
      }
    }

    return availableQtyString;
  }

  const validateForm = () => {
    const temp = state.vm.formError;
    const re = /[0-9]+/g;

    if (state.vm.form?.inventoryBinDetail === null) {
      temp.inventoryBinDetail = 'This field is required';
    } else {
      temp.inventoryBinDetail = '';
    }

    if (state.vm.form?.qty === null) {
      temp.qty = 'This field is required';
    } else if (!re.test(state.vm.form?.qty.toString())) {
      temp.qty = 'Must be a number';
    } else {
      temp.qty = '';
    }

    if (state.vm.form?.pickedUOM === null) {
      temp.pickedUOM = 'This field is required';
    } else {
      temp.pickedUOM = '';
    }

    setState((prevState) => ({
      ...prevState,
      vm: {
        ...prevState.vm,
        formError: { ...temp },
      },
    }));

    return Object.values(temp).every((x) => x === '');
  };

  function submitPick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();

    if (!validateForm()) {
      snackActions.error('Please complete required fields');
      return;
    }

    setState((prevState) => ({
      ...prevState,
      isBusy: true,
    }));

    let isOtherUOM = false;
    let otherUOM: ItemInventoryUOMEnum | null = null;
    let otherUOMEaCases: number | null = null;
    if (
      pickingModalState.vm.pickTicketLineItemToPick.uomType ===
      ItemInventoryUOMTypeEnum.Primary
    ) {
      if (
        state.vm.form.pickedUOM.uomType !== ItemInventoryUOMTypeEnum.Primary
      ) {
        isOtherUOM = true;
        otherUOM = state.vm.form.pickedUOM.itemUOM;
        otherUOMEaCases = state.vm.form.pickedUOM.qty;
      }
    }
    const PAYLOAD: PickTicketLineItemPickPickingWebRequest = {
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      pickTicketLineItemId:
        pickingModalState.vm.pickTicketLineItemToPick.pickTicketLineItemId,
      inventoryBinDetailId:
        state.vm.form.inventoryBinDetail.inventoryBinDetailId,
      qty: state.vm.form.qty,
      isOtherUOM,
      otherUOM,
      otherUOMEaCases,
    };

    PickTicketLineItemPickPickingWeb(PAYLOAD)
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          isBusy: false,
        }));

        closePickTicketLineItemPickModalAfterPick();
      })
      .catch((err) => {
        setState((prevState) => ({
          ...prevState,
          isBusy: false,
        }));
        snackActions.error(err);
      });
  }

  return (
    <Modal open={pickingModalState.vm.isPickTicketLineItemPickModal}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Pick
        </Typography>

        {state.pageLoadState === PageLoadStateEnum.LOAD_START && (
          <MUIContent>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          </MUIContent>
        )}

        {state.pageLoadState === PageLoadStateEnum.LOAD_FINISH && (
          <>
            <MUIContent>
              <Box autoComplete="off" component="form" noValidate>
                <Box className="modalLabelHeader">
                  <Typography variant="subtitle2" fontWeight="bold">
                    {pickingModalState.vm.pickTicketLineItemToPick.sku}
                  </Typography>

                  <Typography variant="body1" className="modalLabelSubText">
                    Description:{' '}
                    <b>
                      {
                        pickingModalState.vm.pickTicketLineItemToPick
                          .description
                      }
                    </b>
                  </Typography>

                  {pickingModalState.vm.pickTicketLineItemToPick
                    .licensePlateNo !== undefined &&
                    pickingModalState.vm.pickTicketLineItemToPick
                      .licensePlateNo !== null && (
                      <Typography variant="body1" className="modalLabelSubText">
                        LPN:{' '}
                        <b>
                          {
                            pickingModalState.vm.pickTicketLineItemToPick
                              .licensePlateNo
                          }
                        </b>
                      </Typography>
                    )}

                  <Typography variant="body1" className="modalLabelSubText">
                    UoM:{' '}
                    <b>
                      {pickingModalState.vm.pickTicketLineItemToPick.orderUOM}
                    </b>
                  </Typography>

                  {pickingModalState.vm.pickTicketLineItemToPick.lotNo !==
                    undefined &&
                    pickingModalState.vm.pickTicketLineItemToPick.lotNo !==
                      null && (
                      <Typography variant="body1" className="modalLabelSubText">
                        Lot No:{' '}
                        <b>
                          {pickingModalState.vm.pickTicketLineItemToPick.lotNo}
                        </b>
                      </Typography>
                    )}

                  <Typography variant="body1" className="modalLabelSubText">
                    Qty:{' '}
                    <b>
                      {`${pickingModalState.vm.pickTicketLineItemToPick.received} of ${pickingModalState.vm.pickTicketLineItemToPick.qty}`}
                    </b>
                  </Typography>
                </Box>
                <MUIGrid
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={6}>
                    <Autocomplete
                      value={state.vm.form.inventoryBinDetail}
                      onChange={(
                        event,
                        newValue: InventoryBinDetailPickingWebModel,
                      ) => {
                        let qty = null;

                        if (newValue !== null) {
                          if (
                            pickingModalState.vm.pickTicketLineItemToPick
                              .uomType === ItemInventoryUOMTypeEnum.Pallet &&
                            newValue.binItemType === BinItemTypeEnum.Pallet
                          ) {
                            qty = 1;
                          }
                        }

                        setState((prevState) => ({
                          ...prevState,
                          vm: {
                            ...prevState.vm,
                            form: {
                              ...prevState.vm.form,
                              inventoryBinDetail: newValue,
                              qty,
                              pickedUOM: null,
                              availableQtyString: null,
                            },
                          },
                        }));

                        if (newValue !== null) {
                          GetInventoryBinDetailItemUnitOfMeasureDDLPickingWeb(
                            pickingModalState.vm.pickTicketLineItemToPick
                              .pickTicketLineItemId ?? 0,
                            newValue.inventoryBinDetailId,
                          )
                            .then((x) => {
                              const itemUnitOfMeasures =
                                x as ItemUnitOfMeasureDDL[];

                              let pickedUOM: ItemUnitOfMeasureDDL = null;
                              let availableQtyString: string | null = null;
                              if (
                                itemUnitOfMeasures.length > 0 &&
                                itemUnitOfMeasures.length === 1
                              ) {
                                pickedUOM = { ...itemUnitOfMeasures[0] };
                                availableQtyString = getAvailableQtyString(
                                  pickedUOM,
                                  newValue,
                                );
                              }

                              setState((prevState) => ({
                                ...prevState,
                                vm: {
                                  ...prevState.vm,
                                  pickedUOMs: [...itemUnitOfMeasures],
                                  form: {
                                    ...prevState.vm.form,
                                    pickedUOM,
                                    availableQtyString,
                                  },
                                },
                              }));
                            })
                            .catch((err) => {
                              snackActions.error(err);
                            });
                        } else {
                          setState((prevState) => ({
                            ...prevState,
                            vm: {
                              ...prevState.vm,
                              pickedUOMs: [],
                              availableQtyString: null,
                            },
                          }));
                        }
                      }}
                      id="controllable-states"
                      disabled={state.isBusy}
                      options={state.vm.inventoryBinDetails}
                      getOptionLabel={(
                        option: InventoryBinDetailPickingWebModel,
                      ) => option.bin}
                      isOptionEqualToValue={(option, selected) =>
                        option.inventoryBinDetailId ===
                        selected.inventoryBinDetailId
                      }
                      renderOption={(
                        props,
                        option: InventoryBinDetailPickingWebModel,
                      ) => (
                        <li {...props} key={option.inventoryBinDetailId}>
                          <div className="td tw-140">{option.bin}</div>
                          <div className="td">{option.zone}</div>
                          <div className="td">{option.availableQty}</div>
                          <div className="td">{option.type}</div>
                          <div className="td">{option.lotNo}</div>
                          <div className="td">{option.lpNo}</div>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          {...(state.vm.formError?.inventoryBinDetail && {
                            error: true,
                            helperText: state.vm.formError?.inventoryBinDetail,
                          })}
                          id="outlined-required"
                          label="Select Location"
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      )}
                      PopperComponent={AutoCompletePopper}
                      PaperComponent={AutoCompletePaper}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={6}>
                    <TextField
                      required
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setState((prevState) => ({
                          ...prevState,
                          vm: {
                            ...prevState.vm,
                            form: {
                              ...prevState.vm.form,
                              qty: Number(event.target.value),
                            },
                          },
                        }));
                      }}
                      {...(state.vm.formError?.qty && {
                        error: true,
                        helperText: state.vm.formError?.qty,
                      })}
                      disabled={
                        state.isBusy ||
                        pickingModalState.vm.pickTicketLineItemToPick
                          .uomType === ItemInventoryUOMTypeEnum.Pallet
                      }
                      value={state.vm.form?.qty || ''}
                      id="outlined-required"
                      label="Enter Pick Quantity"
                      size="small"
                      type="number"
                      sx={{ width: '100%' }}
                    />
                  </MUIGrid>
                </MUIGrid>
                <MUIGrid
                  sx={{ mt: 1 }}
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={6}>
                    <Autocomplete
                      value={state.vm.form.pickedUOM}
                      onChange={(event, newValue: ItemUnitOfMeasureDDL) => {
                        const availableQtyString =
                          getAvailableQtyString(newValue);
                        setState((prevState) => ({
                          ...prevState,
                          vm: {
                            ...prevState.vm,
                            form: {
                              ...prevState.vm.form,
                              pickedUOM: newValue,
                              availableQtyString,
                            },
                          },
                        }));
                      }}
                      id="controllable-states"
                      disabled={state.isBusy}
                      options={state.vm.pickedUOMs}
                      getOptionLabel={(option: ItemUnitOfMeasureDDL) =>
                        option.text
                      }
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderOption={(props, option: ItemUnitOfMeasureDDL) => (
                        <li {...props} key={option.value}>
                          <Box display="flex">
                            <Box display="flex" flexDirection="column">
                              <Typography color="text.primary">
                                {option.text}
                              </Typography>
                            </Box>
                          </Box>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          {...(state.vm.formError?.pickedUOM && {
                            error: true,
                            helperText: state.vm.formError?.pickedUOM,
                          })}
                          id="outlined-required"
                          label="Picked UOM"
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={6}>
                    <TextField
                      disabled
                      value={state.vm.form?.availableQtyString || ''}
                      id="outlined-required"
                      label="Available Quantity"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  </MUIGrid>
                </MUIGrid>
              </Box>
            </MUIContent>

            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              sx={{
                borderTop: '1px solid #dbdbdb',
                width: '100% !important',
                marginLeft: '1px !important',
              }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    margin: '20px',
                    padding: '0px',
                  }}
                >
                  <Button
                    onClick={() => closeModal()}
                    disabled={state.isBusy}
                    variant="outlined"
                    size="small"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  {!state.isBusy && (
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      sx={{
                        padding: 1,
                        width: '100%',
                        backgroundColor: ' rgb(75, 126, 254) !important',
                      }}
                      onClick={(e) => {
                        submitPick(e);
                      }}
                    >
                      SUBMIT
                    </Button>
                  )}
                  {state.isBusy && (
                    <Button
                      disabled
                      type="submit"
                      variant="contained"
                      size="small"
                      sx={{
                        padding: 1,
                        width: '100%',
                        backgroundColor: ' rgb(75, 126, 254) !important',
                        color: 'white !important',
                      }}
                    >
                      <CircularProgress
                        sx={{
                          color: 'white !important',
                          width: '25px !important',
                          height: '25px !important',
                        }}
                      />
                      <Box sx={{ paddingLeft: '5px' }}>SUBMIT</Box>
                    </Button>
                  )}
                </Box>
              </MUIGrid>
            </MUIGrid>
          </>
        )}
      </MUIContainer>
    </Modal>
  );
};

const PickTicketLineItemPickModal = () => <InternalModal />;

export default React.memo(PickTicketLineItemPickModal);
