import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

// GET /api/outboundsectionsetting/ddl/lockordereditsafter
export const GetLockOrderEditsAfterDDL = async () => {
  try {
    const response = await httpClient.get(
      `/OutboundSectionSetting/DDL/LockOrderEditsAfter`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
