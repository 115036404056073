import React, { useContext, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { checkItemOnPackingListImport } from 'services/api/item/item.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import * as XLSX from 'xlsx';

import {
  Modal,
  Box,
  Typography,
  Grid as MUIGrid,
  Button,
  Link,
} from '@mui/material';

import {
  IPurchaseOrderPackingList,
  IPurchaseOrderPackingListLineItem,
  usePurchaseOrderContext,
} from '../context';

export default React.memo(() => {
  const {
    isPackingListImportModalOpen,
    setIsPackingListImportModalOpen,
    setIsErrorPackingListImportModalOpen,
  } = useContext(GlobalContext);

  const { currentLocationAndFacility } = useContext(AuthContext);

  const {
    packinglistIdImport,
    purchaseOrderDetails,
    setImportErrorMessage,
    setPurchaseOrderDetails,
    handleOnCreatePurchaseOrder,
  } = usePurchaseOrderContext();

  const addPurchaseOrderPackingListLineItem = (
    model: IPurchaseOrderPackingListLineItem,
  ) => {
    const datas = [...purchaseOrderDetails.poPackingList];

    const newValue: IPurchaseOrderPackingList = datas?.find(
      (row, index) => row.packingListId === model.packingListCount,
    );

    // model.dataGridId = Math.random();
    newValue.packingLineItems = [...newValue.packingLineItems, model];

    const newState = datas.map((obj, index) => {
      if (
        obj.packingListId
          ? obj.packingListId === model.packingListCount
          : index === model.packingListCount
      ) {
        return {
          ...obj,
          packingListId: newValue?.packingListId,
          shipTo: newValue?.shipTo,
          shipDate: newValue?.shipDate,
          estimatedArrival: newValue?.estimatedArrival,
          shippingCost: newValue?.shippingCost,
          packingLineItems: newValue?.packingLineItems,
        };
      }
      return obj;
    });

    setPurchaseOrderDetails((e) => ({
      ...e,
      poPackingList: [...newState],
    }));
  };

  const csvTemplate = (e) => {
    e.preventDefault();

    const rows = [
      'SKU',
      'DESCRIPTION',
      'WEIGHT (lbs)',
      'UOM',
      'TOTAL QUANTITY',
      'QTY (EACH/CASE)',
      'IS PALLET (YES/NO)',
      'LOT NO',
      'LOT EXPIRATION DATE',
      'NO OF PALLETS',
      'NOTES',
    ];

    const csvContent = `data:text/csv;charset=utf-8,${rows}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'packingList_template.csv');
    document.body.appendChild(link);

    link.click();
  };

  const buttonImportFileRef = React.useRef<HTMLInputElement>(null);

  const handleOnSave = async (e: any) => {
    e.preventDefault();

    try {
      buttonImportFileRef.current.click();
    } catch (err) {
      snackActions.error(err);
    }
  };

  const ImportPackingList = (event) => {
    const [file] = event.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {
        type: 'binary',
        cellDates: true,
        raw: true,
      });

      wb.SheetNames.forEach(async (sheetName) => {
        const Heading = [
          'Sku',
          'Description',
          'Weight',
          'Uom',
          'TotalQuantity',
          'QuantityEach',
          'PalletType',
          'LotNo',
          'LotExpirationDate',
          'NoOfPallets',
          'Notes',
        ];

        const rowObject = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], {
          header: Heading,
          raw: false,
        });

        const payload = {
          customerId: purchaseOrderDetails.poDescription.customer.value,
          customerLocationId: currentLocationAndFacility.customerLocationId,
          purchaseOrderId: purchaseOrderDetails.purchaseOrderId,
          itemImportModels: JSON.parse(
            JSON.stringify(rowObject.slice(1), (k, v) =>
              v && typeof v === 'object' ? v : `${v}`,
            ),
          ),
        };

        try {
          const response = await checkItemOnPackingListImport(payload);
          const { status, data } = response;

          if (status === 200) {
            if (data.errorMessage.length > 0) {
              setImportErrorMessage(data.errorMessage);

              setIsPackingListImportModalOpen(false);
              setIsErrorPackingListImportModalOpen(true);
              return;
            }

            const loadToLineItem: IPurchaseOrderPackingListLineItem[] =
              data.itemImportModels.map((l) => ({
                dataGridId: Math.random(),
                packingListCount: packinglistIdImport,
                receiveId: l?.receiveId,
                item: {
                  value: l.itemId,
                  label: l.sku,
                },
                sku: l?.sku,
                itemId: l?.itemId,
                description: l?.description,
                qty: l?.sumOfAllQty ? l.sumOfAllQty : l.totalQuantity,
                totalCases: l?.sumOfAllQty ? l?.totalQuantity : null,
                eachesPerCase: l?.sumOfAllQty ? l?.quantityEach : null,
                binItemType: {
                  value: l.palletTypeEnum,
                  label: l.palletTypeEnum === 2 ? 'Yes' : 'No',
                },
                palletCount: l.palletTypeEnum === 2 ? l?.noOfPallets : 1,
                weight: l.weight,
                lotNoValue: {
                  value: l?.lotId,
                  label: l?.lotNo,
                  lotId: l?.lotId,
                  lotCreationDate: l?.lotCreationDate,
                  lotExpirationDate: l?.lotExpirationDate,
                },
                primaryUOM: l?.primaryUOM,
                primaryUOMStr: l?.primaryUOMStr,
                primaryUOMAbbr: l?.primaryUOMAbbr,
                itemUnitOfMeasureId: l?.itemUnitOfMeasureId,
                otherUOMStr: l?.otherUOMStr,
                otherUOMAbbr: l?.otherUOMAbbr,
                otherUOMCount: l?.otherUOMCount,
                notes: l?.notes,
              }));

            for (let i = 0; i < loadToLineItem.length; i += 1) {
              addPurchaseOrderPackingListLineItem(loadToLineItem[i]);
            }

            await handleOnCreatePurchaseOrder();
            snackActions.success(`Import of packing list was successful.`);
            setImportErrorMessage('');
          } else {
            setImportErrorMessage(`${data} `);
          }
        } catch (err) {
          snackActions.error(err);
        }

        setIsPackingListImportModalOpen(false);
      });
    };
    reader.readAsBinaryString(file);
  };

  return (
    <Modal open={isPackingListImportModalOpen}>
      <MUIContainer sx={{ width: '25% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Import Packing List
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <Typography variant="body2">
              Use this import feature to add packing list using the CSV upload
            </Typography>
            <Box>
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link href="#" onClick={csvTemplate}>
                  CSV Template
                </Link>
              }
            </Box>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={8}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => {
                  setIsPackingListImportModalOpen(false);
                }}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={(e) => {
                  handleOnSave(e);
                }}
              >
                Import
              </Button>
              <input
                type="file"
                name="file"
                accept=".xlsx,.csv"
                ref={buttonImportFileRef}
                onChange={ImportPackingList}
                hidden
              />
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
