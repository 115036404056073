import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import {
  IChoroplethData,
  IChoroplethWorld,
  IListData,
  IWarehouseCustomer,
} from 'common/interfaces';
import {
  BarChart,
  ChoroplethUs,
  ChoroplethWorld,
  IBarChart,
  IListChart,
  ListChart,
} from 'components/charts';
import DatePickerInput from 'components/datepicker';
import { randomColors } from 'helpers/colorScale';
import _ from 'lodash';
import {
  getDashboardSalesSummary,
  getDashboardChartStates,
  getDashboardChartWorld,
} from 'services/api/dashboard/dashboard.api';
import { getCustomersLookup } from 'services/api/salesorders/salesorders-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { ButtonMapOption } from 'styles/mui/button';

import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

type ChartMode = 'Map' | 'Chart' | 'List';

function DashboardSalesHeatmap() {
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const defBarchart: IBarChart = {
    labels: ['Jan', 'Feb', 'Mar'],
    datasets: [
      {
        label: 'Summary',
        data: [100, 30, 50],
        backgroundColor: ['#eee', '#888', '#000'],
        borderColor: ['#333', '#333', '#333'],
        borderWidth: 1,
      },
    ],
  };
  const defListchart: IListChart = {
    data: [],
  };
  const [filter, setFilter] = useState(0);
  const [statesMap, setStatesMap] = useState<IChoroplethData[]>([]);
  const [worldMap, setWorldMap] = useState<IChoroplethWorld[]>([]);
  const [barchartData, setBarchartData] = useState<IBarChart>(defBarchart);
  const [listData, setListData] = useState<IListChart>(defListchart);
  const [optCustomers, setOptCustomers] = useState<IWarehouseCustomer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState(0);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [geoStates, setGeoStates] = useState<any[]>([]);
  const [geoWorld, setGeoWorld] = useState<any[]>([]);
  const [currentMode, setChartMode] = useState<ChartMode>('Map');
  const [isUsOnly, setIsUsOnly] = useState(true);

  const processChartUs = (data: IChoroplethData[]) => {
    setStatesMap(data);
    const bgColors = randomColors(data.length);
    const bcColors = randomColors(data.length);
    const chartData = {
      labels: data.map((r) => r.state),
      datasets: [
        {
          label: 'Orders',
          data: data.map((r) => r.orders),
          backgroundColor: bgColors.map((r) => r.color),
          borderColor: bgColors.map((r) => r.borderColor),
          borderWidth: 1,
        },
        {
          label: 'Quantity',
          data: data.map((r) => r.quantity),
          backgroundColor: bcColors.map((r) => r.color),
          borderColor: bcColors.map((r) => r.borderColor),
          borderWidth: 1,
        },
      ],
    };

    setBarchartData(chartData);

    const lst: IListData[] = data.map((r, i) => ({
      code: r.state,
      name: r.state,
      orders: r.orders,
      quantity: r.quantity,
      backgroundColor: bgColors[i].color,
      borderColor: bgColors[i].borderColor,
    }));

    setListData((prev) => ({
      ...prev,
      data: lst,
    }));
  };

  const processChartInternational = (data: IChoroplethData[]) => {
    const rawData: IChoroplethWorld[] = _.map(
      _.groupBy(data, 'country'),
      (o, idx) => ({
        country: idx,
        countryCode: idx,
        orders: _.sumBy(o, 'orders'),
        quantity: _.sumBy(o, 'quantity'),
        sales: _.sumBy(o, 'sales').toString(),
      }),
    );

    const newData = rawData.filter(
      (r) => r.country !== 'United States of America',
    );

    setWorldMap(newData);

    const bgColors = randomColors(newData.length);
    const bcColors = randomColors(newData.length);
    const chartData = {
      labels: newData.map((r) => r.country),
      datasets: [
        {
          label: 'Orders',
          data: newData.map((r) => r.orders),
          backgroundColor: bgColors.map((r) => r.color),
          borderColor: bgColors.map((r) => r.borderColor),
          borderWidth: 1,
        },
        {
          label: 'Quantity',
          data: newData.map((r) => r.quantity),
          backgroundColor: bcColors.map((r) => r.color),
          borderColor: bcColors.map((r) => r.borderColor),
          borderWidth: 1,
        },
      ],
    };

    setBarchartData(chartData);

    const lst: IListData[] = newData.map((r, i) => ({
      code: r.country,
      name: r.country,
      orders: r.orders,
      quantity: r.quantity,
      backgroundColor: bgColors[i].color,
      borderColor: bgColors[i].borderColor,
    }));

    setListData((prev) => ({
      ...prev,
      data: lst,
    }));
  };

  const fetchGeoStates = async () => {
    try {
      const result = await getDashboardChartStates();
      setGeoStates(result);
      return true;
    } catch (err) {
      return err;
    }
  };

  const fetchGeoWorld = async () => {
    try {
      const result = await getDashboardChartWorld();
      setGeoWorld(result);
      return true;
    } catch (err) {
      return err;
    }
  };

  const fetchCustomer = async () => {
    try {
      const result = await getCustomersLookup(currentUser.Claim_CustomerId, '');
      setOptCustomers(result as IWarehouseCustomer[]);
      setSelectedCustomer(0);
      return true;
    } catch (err) {
      return err;
    }
  };

  const fetchDataSalesSummary = async (
    customerId: number,
    dayFilter: number,
    states: boolean,
  ) => {
    try {
      const result = await getDashboardSalesSummary({
        customerId,
        customerLocationId: Number(
          currentLocationAndFacility.customerLocationId,
        ),
        customerFacilityId: Number(
          currentLocationAndFacility.customerFacilityId,
        ),
        dayFilter,
        dateFrom,
        dateTo,
      });
      if (states) {
        processChartUs(result.data ?? []);
      } else {
        processChartInternational(result.data ?? []);
      }
      return true;
    } catch (err: any) {
      return err;
    }
  };

  const handleChartRangeChange = async (
    e: React.SyntheticEvent,
    newValue: number,
  ) => {
    setFilter(newValue);
    await fetchDataSalesSummary(selectedCustomer, newValue, isUsOnly);
  };

  const onCustomerChange = async (e: SelectChangeEvent) => {
    const num = Number(e.target.value);
    setSelectedCustomer(num);
    await fetchDataSalesSummary(num, filter, isUsOnly);
  };

  const onDatePickerChange = async (date: Date, source: string) => {
    if (source === 'from') {
      setDateFrom(date);
    } else {
      setDateTo(date);
    }
    await fetchDataSalesSummary(selectedCustomer, filter, isUsOnly);
  };

  const onSwitchChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsUsOnly(e.target.checked);
  };

  useEffect(() => {
    fetchGeoWorld();
    fetchGeoStates();
    fetchCustomer();
    fetchDataSalesSummary(selectedCustomer, filter, isUsOnly);
  }, [currentLocationAndFacility, isUsOnly]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          sx={{
            position: 'absolute',
            top: '10px',
            left: '24px',
            '& .MuiFormControlLabel-label': {
              fontSize: '13px',
            },
          }}
          control={
            <Switch
              size="small"
              checked={isUsOnly}
              onChange={onSwitchChange}
              name="IsUsOnly"
            />
          }
          label={isUsOnly === true ? 'US States' : 'International'}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" fontWeight="bold" sx={{ display: 'flex' }}>
            Sales {currentMode}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tabs value={filter} onChange={handleChartRangeChange} centered>
              <Tab
                sx={{ minWidth: '50px', padding: 1, fontSize: '0.8rem' }}
                value={0}
                label="All"
              />
              <Tab
                sx={{ minWidth: '50px', padding: 1, fontSize: '0.8rem' }}
                value={1}
                label="Today"
              />
              <Tab
                sx={{ minWidth: '50px', padding: 1, fontSize: '0.8rem' }}
                value={3}
                label="WTD"
              />
              <Tab
                sx={{ minWidth: '50px', padding: 1, fontSize: '0.8rem' }}
                value={4}
                label="MTD"
              />
              <Tab
                sx={{ minWidth: '50px', padding: 1, fontSize: '0.8rem' }}
                value={5}
                label="YTD"
              />
              <Tab
                sx={{ minWidth: '50px', padding: 1, fontSize: '0.8rem' }}
                value={6}
                label="Custom"
              />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '40px',
          }}
        >
          <Stack direction="row">
            <ButtonMapOption title="Map" onClick={() => setChartMode('Map')}>
              Map
            </ButtonMapOption>
            <ButtonMapOption
              title="Chart"
              onClick={() => setChartMode('Chart')}
            >
              Chart
            </ButtonMapOption>
            <ButtonMapOption title="List" onClick={() => setChartMode('List')}>
              List
            </ButtonMapOption>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              transform: 'scale(0.8)',
              transformOrigin: '100% 50%',
            }}
          >
            {filter === 6 && (
              <Box sx={{ display: 'flex' }}>
                <DatePickerInput
                  label="From"
                  inputFormat="MM/dd/yyyy"
                  value={dateFrom}
                  onChange={(val: Date) => onDatePickerChange(val, 'from')}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      error={false}
                      size="small"
                      style={{ fontSize: 12, width: 140 }}
                    />
                  )}
                />
                <DatePickerInput
                  label="To"
                  inputFormat="MM/dd/yyyy"
                  value={dateTo}
                  onChange={(val: Date) => onDatePickerChange(val, 'to')}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      error={false}
                      size="small"
                      style={{ fontSize: 12, width: 140, marginLeft: 5 }}
                    />
                  )}
                />
              </Box>
            )}
            <FormControl sx={{ m: 1, p: 0 }} size="small">
              <InputLabel id="label-wc" style={{ backgroundColor: '#fff' }}>
                Client
              </InputLabel>
              <Select
                style={{ width: 180 }}
                variant="outlined"
                id="select-wc"
                labelId="label-wc"
                value={selectedCustomer}
                defaultValue={currentUser.Claim_CustomerName}
                onChange={onCustomerChange}
              >
                {optCustomers.map((wc) => (
                  <MenuItem
                    key={wc.warehouseCustomerId}
                    value={wc.warehouseCustomerId}
                  >
                    {wc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        {currentMode === 'Map' &&
          (isUsOnly ? (
            <ChoroplethUs states={geoStates} summary={statesMap} />
          ) : (
            <ChoroplethWorld countries={geoWorld} summary={worldMap} />
          ))}
        {currentMode === 'Chart' && (
          <BarChart
            labels={barchartData.labels}
            datasets={barchartData.datasets}
          />
        )}
        {currentMode === 'List' && <ListChart data={listData.data} />}
      </Box>
    </Box>
  );
}

export default React.memo(DashboardSalesHeatmap);
