import React, { useContext, useState } from 'react';

import Button from 'components/button';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { OrderAssignTo } from 'services/api/salesorders/salesorders-new.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Autocomplete, Box, Modal, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useSalesOrderDataTableViewContext } from 'pages/sales/context-provider';

export default React.memo(() => {
  const theme = useTheme();
  const { isAssingToOnOrderModalOpen, setIsAssingToOnOrderModalOpen } =
    useContext(GlobalContext);

  const {
    warehouseUserValue,
    optionWarehouseUser,
    setWarehouseUserValue,
    OnClickUserFilterSearchApply,
    selectedRowsOnCheckbox,
  } = useSalesOrderDataTableViewContext();

  const updateAsignToOrderOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    const payload = {
      WarehouseUserId: warehouseUserValue.value,
      OrderToAssigns: selectedRowsOnCheckbox.map((c: any) => ({
        OrderId: c.orderId,
      })),
    };

    try {
      await OrderAssignTo(payload);
      snackActions.success('Successfully updated assigned user.');
      setIsAssingToOnOrderModalOpen(false);
      OnClickUserFilterSearchApply(e, true);
    } catch (err) {
      snackActions.error(err);
    }
  };

  return (
    <Modal open={isAssingToOnOrderModalOpen}>
      <ModalBox>
        <ModalContent>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{ marginBottom: '16px' }}
              variant="h6"
              fontWeight="bold"
            >
              Assign To
            </Typography>
            <Autocomplete
              sx={{ width: '100%' }}
              options={optionWarehouseUser}
              value={warehouseUserValue}
              size="small"
              onChange={(event: any, newValue) => {
                if (newValue === null || newValue === undefined) {
                  setWarehouseUserValue(null);
                } else {
                  setWarehouseUserValue((prevState) => ({
                    ...prevState,
                    ...newValue,
                  }));
                }
              }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    '& .MuiInputBase-root': {
                      background: 'white',
                    },
                  }}
                  {...params}
                  label="Assign To"
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end',
              },
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
              },
              gap: '8px',
            }}
          >
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="text"
              size="large"
              onClick={() => setIsAssingToOnOrderModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="contained"
              size="large"
              onClick={(e) => updateAsignToOrderOnClick(e)}
            >
              Update
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
