import httpClient from 'infra/httpClient/index.js';
import { IChangePasswordData } from 'pages/shared/customerusermodal/change-password';
import { errorCatch } from 'services/api/serivceBase.js';

export const getUser = async (userId) => {
  try {
    const response = await httpClient.get(`/User/${userId}`);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getUsers = async () => {
  try {
    const response = await httpClient.get(`/User/Users`);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getUserSupplier = async (userId) => {
  try {
    const response = await httpClient.get(`/User/Supplier/${userId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getUsersByCustomerId = async (customerId, isHideCustomer) => {
  try {
    const response = await httpClient.get(
      `/User/customer/${customerId}/${isHideCustomer}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const createUser = async (data) => {
  try {
    const response = await httpClient.post(`/User/CreateUser`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const editUser = async (userId, editForm) => {
  try {
    const response = await httpClient.put(`/User/${userId}`, editForm);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const changeUserActiveStatus = async (statusForm) => {
  try {
    const response = await httpClient.post(`/User/Update/isActive`, statusForm);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getUsersRoleOperator = async () => {
  try {
    const response = await httpClient.get(`/User/operatorpermission/lookup`);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getUsersRoleOperatorByUserId = async (userId) => {
  try {
    const response = await httpClient.get(
      `/User/operatorpermission/user/${userId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getUsersRoleWarehouseCustomer = async () => {
  try {
    const response = await httpClient.get(
      `/User/warehousecustomerpermission/lookup`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getUsersRoleWarehouseCustomerByUserId = async (userId) => {
  try {
    const response = await httpClient.get(
      `/User/warehousecustomerpermission/user/${userId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// SUPPLIER PERMISSION
export const getUsersRoleSupplierByUserId = async (userId) => {
  try {
    const response = await httpClient.get(
      `/User/supplierPermission/user/${userId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const UserDelete = async (userModel) => {
  try {
    const response = await httpClient.post(`/User/delete/user`, userModel);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getUserOperators = async () => {
  try {
    const response = await httpClient.get(`/user/useroperators`);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getUserNameByCustomer = async (customerLocationId) => {
  try {
    const response = await httpClient.get(
      `/user/GetUserNameByCustomer/${customerLocationId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// FILTER SEARCH DATA
export const getUserFilterSearchValue = async (pageName) => {
  try {
    const response = await httpClient.get(
      `/user/UserFilterSearchValue/${pageName}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const deleteOrderFilter = async (model) => {
  try {
    const response = await httpClient.post(`/user/DeleteFilter`, model);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const CreateUserFilterSearchValue = async (model) => {
  try {
    const response = await httpClient.post(
      `/User/createuserfiltersearchvalue`,
      model,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const ManageUserPermission = async (data) => {
  try {
    const response = await httpClient.post(`/user/manageuserpermission`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const saveManageUserPermission = async (model) => {
  try {
    const response = await httpClient.post(`/User/ManageUserPermission`, model);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// Get /api/user/isneedrefreshtoken
export const IsUserNeedRefreshToken = async () => {
  try {
    const response = await httpClient.get(`/User/IsNeedRefreshToken/`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// Get /api/user/isneedrefreshtoken/updatetofalse
export const UpdateIsUserNeedRefreshTokenToFalse = async () => {
  try {
    const response = await httpClient.post(
      `/User/IsNeedRefreshToken/UpdateToFalse`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const UserChangePassword = async (data: IChangePasswordData) => {
  try {
    const response = await httpClient.post(`/User/ChangePassword`, data);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
