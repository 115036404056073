import { Box, Paper, Stack, styled } from '@mui/material';
import { IListData } from 'common/interfaces';
import React from 'react';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  flexGrow: 1,
}));

export interface IListChart {
  data: IListData[];
}

export const ListChart = React.memo((props: IListChart) => {
  const descData = props.data.sort((a, b) => b.orders - a.orders);
  return (
    <Box sx={{ width: '100%', overflow: 'auto' }}>
      {descData.map((r) => (
        <Box
          key={`${r.name}${r.orders}${r.quantity}`}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: { md: '100%', lg: '50%', xl: '33%' },
            fontSize: '13px',
          }}
        >
          <Box
            sx={{
              backgroundColor: r.backgroundColor,
              width: '20px',
              margin: '2px',
            }}
          />
          <Box sx={{ flex: 'auto', paddingLeft: '10px' }}>{r.name}</Box>
          <strong>{r.orders}</strong>
        </Box>
      ))}
    </Box>
  );
});
