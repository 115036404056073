import React from 'react';

import Card from 'components/card';
import Header from 'components/header';

import { Typography, TextField, Grid as MUIGrid } from '@mui/material';

import { MainContainer, ContentContainer } from '../../../styles';

const Description = () => (
  <Card
    sx={{
      marginBottom: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    }}
  >
    <Typography variant="h6" fontWeight="bold" mb={2}>
      Description
    </Typography>
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={6}>
        <TextField
          required
          id="outlined-required"
          label="SKU"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={6}>
        <TextField
          id="outlined"
          label="Sell Price"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
    </MUIGrid>
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={6}>
        <TextField
          id="outlined"
          label="Cost"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={6}>
        <TextField
          required
          id="outlined-required"
          label="Description"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
    </MUIGrid>
  </Card>
);

const InventorySettings = () => (
  <Card
    sx={{
      marginBottom: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    }}
  >
    <Typography variant="h6" fontWeight="bold" mb={2}>
      Inventory Settings
    </Typography>
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={6}>
        <TextField
          id="outlined"
          label="Allocation Method"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={3}>
        <TextField
          id="outlined"
          label="Min on Hand"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={3}>
        <TextField
          id="outlined"
          label="Max on Hand"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
    </MUIGrid>
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={6}>
        <TextField
          id="outlined"
          label="Storage Temperature"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={6}>
        <TextField
          id="outlined"
          label="Reorder Qty"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
    </MUIGrid>
  </Card>
);

const Packaging = () => (
  <Card
    sx={{
      marginBottom: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    }}
  >
    <Typography variant="h6" fontWeight="bold" mb={2}>
      Packaging
    </Typography>
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={4}>
        <TextField
          id="outlined"
          label="Packaging UOM"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={4}>
        <TextField
          id="outlined"
          label="Units Per Package"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={4}>
        <TextField
          id="outlined"
          label="Packaging UPC"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
    </MUIGrid>
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={3}>
        <TextField
          id="outlined"
          label="Length (in)"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={3}>
        <TextField
          id="outlined"
          label="Width (in)"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={3}>
        <TextField
          id="outlined"
          label="Height (in)"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={3}>
        <TextField
          id="outlined"
          label="Total Area (soft)"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
    </MUIGrid>
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={3}>
        <TextField
          id="outlined"
          label="Weight (lbs)"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={3}>
        <TextField
          id="outlined"
          label="Net Weight (lbs)"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
    </MUIGrid>
  </Card>
);

const LicensePlates = () => (
  <Card
    sx={{
      marginBottom: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    }}
  >
    <Typography variant="h6" fontWeight="bold" mb={2}>
      License Plates
    </Typography>
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={4}>
        <TextField
          id="outlined"
          label="Type"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={4}>
        <TextField
          id="outlined"
          label="Qty (Primary UOM)"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={4}>
        <TextField
          id="outlined"
          label="Weight (lbs)"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
    </MUIGrid>
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={3}>
        <TextField
          id="outlined"
          label="Length (in)"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={3}>
        <TextField
          id="outlined"
          label="Width (in)"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={3}>
        <TextField
          id="outlined"
          label="Height (in)"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
      <MUIGrid item xs={3}>
        <TextField
          id="outlined"
          label="Total Area (soft)"
          size="small"
          sx={{ width: '100%' }}
        />
      </MUIGrid>
    </MUIGrid>
  </Card>
);

// const Locations = () => {
//   const lineItemsColumns: GridColumns = [
//     {
//       field: 'lineId',
//       width: 200,
//       renderHeader: () => (
//         <Box sx={{ gap: '5px' }}>
//           <Typography fontWeight="bold">Line Id</Typography>
//           <Input
//             sx={{ maxWidth: '140px' }}
//             placeholder="Search"
//             value={null}
//             onChange={(value) => console.log(value)}
//             rightIcon={<Search />}
//             onKeyDown={(e) => {
//               if (e.key === 'Enter') {
//                 console.log(e);
//               }
//             }}
//           />
//         </Box>
//       ),
//     },
//     {
//       field: 'sku',
//       width: 200,
//       renderHeader: () => (
//         <Box sx={{ gap: '5px' }}>
//           <Typography fontWeight="bold">SKU</Typography>
//           <Input
//             sx={{ maxWidth: '140px' }}
//             placeholder="Search"
//             value={null}
//             onChange={(value) => console.log(value)}
//             rightIcon={<Search />}
//             onKeyDown={(e) => {
//               if (e.key === 'Enter') {
//                 console.log(e);
//               }
//             }}
//           />
//         </Box>
//       ),
//     },
//   ];

//   const rows: GridRowsProp = [
//     {
//       lineId: 'V23451',
//       sku: '184SHIRTSM',
//     },
//     {
//       lineId: 'V23452',
//       sku: '184SHIRTSM',
//     },
//   ];
//   function toolbar() {
//     return (
//       <Box
//         sx={{
//           paddingBottom: 1,
//           width: '100%',
//           display: 'flex',
//           flexDirection: 'row',
//           justifyContent: 'flex-end',
//           gap: '8px',
//         }}
//       >
//         <Box
//           sx={{
//             width: '100%',
//             display: 'flex',
//             flexDirection: 'row',
//             justifyContent: 'space-between',
//             gap: '8px',
//           }}
//         >
//           <Typography variant="h6" fontWeight="bold">
//             Locations
//           </Typography>
//         </Box>
//       </Box>
//     );
//   }

//   return (
//     <Card
//       sx={{
//         marginBottom: 2,
//         display: 'flex',
//         flexDirection: 'column',
//         gap: '8px',
//       }}
//     >
//       <Box
//         sx={{
//           width: '100%',
//         }}
//       >
//         <DataGrid
//           autoHeight
//           headerHeight={120}
//           rows={rows}
//           density="compact"
//           disableColumnFilter
//           disableColumnSelector
//           disableDensitySelector
//           disableColumnMenu
//           columns={lineItemsColumns}
//           pageSize={6}
//           rowsPerPageOptions={[6]}
//           getRowId={(row) => row.lineId}
//           checkboxSelection
//           components={{ Toolbar: toolbar }}
//           sx={{
//             borderColor: 'transparent',
//             '& .MuiDataGrid-columnSeparator--sideRight': {
//               display: 'none !important',
//             },
//           }}
//         />
//       </Box>
//     </Card>
//   );
// };

function InventoryTempNewItem() {
  return (
    <MainContainer>
      <Header />

      <ContentContainer>
        <MUIGrid container spacing={2}>
          <MUIGrid item md={6}>
            <Description />
            <InventorySettings />
            <Packaging />
            <LicensePlates />
          </MUIGrid>
          {/* <MUIGrid item md={6}>
            <Locations />
          </MUIGrid> */}
        </MUIGrid>
      </ContentContainer>
    </MainContainer>
  );
}

export default React.memo(InventoryTempNewItem);
