import styled from 'styled-components';

export const ModalFooterEnd = styled.div`
  display: flex;
  justify-content: end;
  gap: 15px;
  button {
    width: 90px;
    height: 40px;
  }
`;
