import React, { useState } from 'react';
import {
  IGridTile,
  IGridTileLayout,
  IGridTileSelect,
  IGridWidget,
} from 'common/interfaces';
import * as Utils from 'common/utils';
import GridLayout from 'components/grid';
import Header from 'components/header';
import { ModalInputNumber, ModalLocationInfo } from 'components/modal';
import { snackActions } from 'config/snackbar.js';
import { times, reject } from 'lodash';
import { ContentContainer, MainContainer } from 'pages/styles';
import styled from 'styled-components';
import GridHeader from './header';

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const cols = 18;
const rowHeight = 56;
const padding: [number, number] = [2, 2];
const layoutConfig = {
  cols,
  rowHeight,
  padding,
};

function LocationBuilder() {
  const [count, setCount] = useState(0);
  const [droppingItem, setDroppingItem] = useState<IGridWidget>({
    i: 'dropping',
    h: 1,
    w: 1,
  });
  const defaultTiles = times(0).map((i, key, list) => ({
    i: key.toString(),
    code: Utils.GetRandomString(5),
    type: 'text',
    layout: {
      i: key.toString(),
      x: Math.floor(Math.random() * 1),
      y: Math.floor(Math.random() * 1),
      w: Math.floor(Math.random() * 2) + 1,
      h: Math.floor(Math.random() * 2) + 1,
    },
    children: <div>00</div>,
  }));
  const [items, setItems] = useState<IGridTile[]>(defaultTiles);
  const [IsOpenModalPlus, SetIsOpenModalPlus] = useState(false);
  const [IsOpenModalLocation, SetIsOpenModalLocation] = useState(false);
  const [currentTile, setCurrentTile] = useState<IGridTileSelect>();

  const onDragStart = (item: IGridWidget) => {
    setDroppingItem(item);
  };

  const onDrop = (layouts: IGridTileLayout[], tile: IGridTileLayout) => {
    const tilesPatch = [...layouts];
    const itemsPatch = items.map((p) => {
      const patch = tilesPatch.find((t) => t.i === p.i);
      return Utils.ToGridTileClone(p, patch);
    });
    const tileIndex = tilesPatch.findIndex((t) => t.i === tile.i);
    const tileInfo = tilesPatch[tileIndex];
    const id = `${Utils.GetISOIdString()}${tileInfo.x}${count}`;
    const code = Utils.GetRandomString(5);
    const newItem = {
      i: id,
      code,
      type: 'text',
      layout: {
        i: id,
        x: tileInfo.x,
        y: tileInfo.y,
        w: tileInfo.w,
        h: tileInfo.h,
      },
      children: (
        <div style={{ textTransform: 'uppercase' }}>
          {Utils.GetLastring(id, 5)}
        </div>
      ),
    };
    itemsPatch.push(newItem);
    setItems(itemsPatch);
    setCount(count + 1);
  };

  const onLayoutChange = (layouts: IGridTileLayout[]) => {
    setCount(layouts.length);
  };

  const onResizeStop = (
    layout: IGridTileLayout[],
    oldItem: IGridTileLayout,
    newItem: IGridTileLayout,
  ) => {
    const n = items.findIndex((t) => t.i === newItem.i);
    const arr = [...items];
    arr[n].layout.w = newItem.w;
    arr[n].layout.h = newItem.h;
    setItems(arr);
  };

  const onBoxRemove = (id: string) => {
    setItems(reject(items, { i: id }));
  };

  const onBoxEdit = (id: string) => {
    SetIsOpenModalLocation(true);
  };

  const onBoxEditClose = async () => {
    SetIsOpenModalLocation(false);
  };

  const onBoxPlus = (id: string, direction: string) => {
    setCurrentTile({
      i: id,
      direction,
    });
    SetIsOpenModalPlus(true);
  };

  const onBoxPlusClose = async () => {
    SetIsOpenModalPlus(false);
  };

  const onBoxPlusConfirm = async (val: number) => {
    if (val <= 0 || val > 20) {
      snackActions.error('Valid location count is 1 to 20');
      return;
    }
    SetIsOpenModalPlus(false);
    const itemInfo = items.find((t) => t.i === currentTile.i);
    if (itemInfo) {
      const itemBase = Utils.ToGridTileLayout(itemInfo);
      const itemsNew = Utils.GridTileCloner(
        itemBase,
        currentTile.direction,
        val,
      );
      const newItems = itemsNew.map((tile) => {
        const code = Utils.GetRandomString(5);
        const item: IGridTile = {
          i: tile.i,
          code,
          type: 'text',
          layout: {
            i: tile.i,
            x: tile.x,
            y: tile.y,
            w: tile.w,
            h: tile.h,
          },
          children: (
            <div style={{ textTransform: 'uppercase' }}>
              {Utils.GetLastring(tile.i, 5)}
            </div>
          ),
        };
        return item;
      });
      const compacted = Utils.GridTileCompact(
        items,
        newItems,
        currentTile.direction,
      );
      setItems(compacted);
      setCount(count + val);
    }
  };

  const onClear = () => {
    setItems(defaultTiles);
  };

  return (
    <MainContainer>
      <Header />
      <ContentContainer>
        <GridContainer>
          <GridHeader onDragStart={onDragStart} onClear={onClear} />
          <GridLayout
            minHeight="80vh"
            padding={layoutConfig.padding}
            cols={layoutConfig.cols}
            rowHeight={layoutConfig.rowHeight}
            droppingItem={droppingItem}
            items={items}
            onDrop={onDrop}
            onLayoutChange={onLayoutChange}
            onResizeStop={onResizeStop}
            onBoxEdit={onBoxEdit}
            onBoxPlus={onBoxPlus}
            onBoxRemove={onBoxRemove}
            {...layoutConfig}
          />
          <ModalInputNumber
            Title={`Add number of block in ${currentTile?.direction} direction`}
            IsOpen={IsOpenModalPlus}
            OnModalClose={onBoxPlusClose}
            OnConfirm={onBoxPlusConfirm}
          />
          <ModalLocationInfo
            IsOpen={IsOpenModalLocation}
            OnModalClose={onBoxEditClose}
          />
        </GridContainer>
      </ContentContainer>
    </MainContainer>
  );
}

export default React.memo(LocationBuilder);
