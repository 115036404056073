/* eslint-disable no-unused-vars */
export enum WarehouseCustomerHandlingChargeMethodEnum {
  ByItemType = 1,
  BasedOnPalletPerLocation = 2,
  FlatRate = 3, // only for ChargeType == ByItemType
  ByWeight = 4,
  ByClassification = 5,
  ByHour = 6, // only for ChargeType == ByItemType and PutAwayCharge
  ByPackageWeight = 7,
  CarrierPackage = 8,
  PackageLabel = 9,
}
