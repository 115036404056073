export const ToDecimal = (num: number, place: number) =>
  ((num * 100) / 100).toFixed(place);

export const ToCurrency = (num: number, curreny: string, isFront: boolean) =>
  isFront ? `${curreny} ${num}` : `${num} ${curreny}`;

export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
