import React, { useContext, useState, useEffect, ChangeEvent } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';

import Button from 'components/button';
import Card from 'components/card';
import DatePickerInput from 'components/datepicker';
import Footer from 'components/footer';
import Header from 'components/header';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import PurchaseOrderItemModalV2 from 'pages/shared/purchaseorderitemmodalv2';
import { getCountries } from 'services/api/country/country.api';
import {
  getPurchaseOrderDetailV2,
  createPurchaseOrderV2,
  editPurchaseOrder,
  getGeneratedPurchaseOrderNo,
} from 'services/api/purchaseOrders/purchaseOrders.api';
import {
  deleteReceiveV2,
  closeReceiveV2,
} from 'services/api/receive/receive.api';
import { getCustomersLookup } from 'services/api/salesorders/salesorders-new.api';
import {
  editSupplier,
  getSupplierLookupByWarehouseCustomerSearchByName,
} from 'services/api/suppliers/suppliers.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import * as yup from 'yup';

import {
  Typography,
  Grid as MUIGrid,
  Autocomplete,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import {
  GridColumns,
  DataGrid,
  GridRowsProp,
  GridActionsCellItem,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';

import { autoCompleteTimeStamp } from '../../../helpers/autocompletetimestamp';
import { MainContainer, ContentContainer } from '../../styles';

interface AutoCompleteOptionType {
  id: number;
  label: string;
}

interface LineItemsProps {
  items?: any;
  update?: boolean;
  removeItem?: React.Dispatch<any>;
  edit?: React.Dispatch<React.SetStateAction<boolean>>;
  editItem?: React.Dispatch<any>;
  isNewOrder?: boolean;
}

type AutocompleteBase = {
  label?: string;
  value?: number;
};
type CustomerType = AutocompleteBase;

const LineItems = ({
  items,
  update,
  removeItem,
  edit,
  editItem,
  isNewOrder,
}: LineItemsProps) => {
  const urlQuery = useQuery();
  const [passedItems, setItems] = useState(items);
  const [updateComponent, setUpdate] = useState(false);
  const { onOpenPurchaseOrderItemModal, handleUpdateData } =
    useContext(GlobalContext);
  const { isWarehouseCustomerAccount } = useContext(AuthContext);

  const handleRemoveReceive = (itemId?) => {
    const newItems = items.filter((item) => item.itemId !== itemId);
    removeItem(newItems);
    snackActions.success('Removed order line item.');
    setUpdate(!updateComponent);
  };

  const handleFetchEditItem = (params, receiveId) => {
    let fetchedItem;
    if (receiveId !== undefined) {
      fetchedItem = items.filter((item) => item.receiveId === receiveId);
    } else {
      fetchedItem = items.filter((item) => item.tempId === params);
    }
    edit(true);
    editItem(fetchedItem[0]);
    onOpenPurchaseOrderItemModal();
  };

  const handleDeleteReceive = async (receiveId, itemId) => {
    const deleteForm = {
      receiveId,
    };
    if (isNewOrder) {
      handleRemoveReceive(itemId);
    } else {
      try {
        const deleteResponse = await deleteReceiveV2(deleteForm);

        snackActions.success('Removed line item from order.');
        handleUpdateData();
      } catch (err: any) {
        snackActions.error(`${err}`);
      }
    }
  };

  const handleCloseReceive = async (receiveId) => {
    const closeForm = {
      receiveId,
    };
    try {
      const closeResponse = await closeReceiveV2(closeForm);

      snackActions.success('Successfully closed receiving for line item.');
      handleUpdateData();
    } catch (err: any) {
      snackActions.error(`${err}`);
    }
  };

  type LineItemRows = typeof passedItems[number];

  const lineItemsColumns: GridColumns<LineItemRows> = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleFetchEditItem(params.id, params.row.receiveId)}
          showInMenu
          disabled={isWarehouseCustomerAccount}
        />,
        <GridActionsCellItem
          label="Close"
          disabled={
            params.row.status === 'Closed' ||
            params.row.status === null ||
            isWarehouseCustomerAccount
          }
          onClick={() => handleCloseReceive(params.row.receiveId)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() =>
            handleDeleteReceive(params.row.receiveId, params.row.itemId)
          }
          showInMenu
          disabled={isWarehouseCustomerAccount}
        />,
      ],
    },
    {
      field: 'itemSKU',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">SKU</Typography>
        </Box>
      ),
    },
    {
      field: 'description',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Description</Typography>
        </Box>
      ),
    },
    {
      field: 'qty',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Qty</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.qty?.toLocaleString()}</Typography>
      ),
    },
    {
      field: 'received',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Received</Typography>
        </Box>
      ),
    },
    {
      hide: true,
      field: 'lotId',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Lot Id</Typography>
        </Box>
      ),
    },
    {
      field: 'lotNo',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Lot No.</Typography>
        </Box>
      ),
    },
    {
      field: 'lotExpirationDate',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Lot Exp. Date</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography variant="inherit">
          {params.row.lotExpirationDate !== undefined &&
          params.row.lotExpirationDate !== '' &&
          params.row.lotExpirationDate !== null &&
          params.row.lotExpirationDate !== '0001-01-01T00:00:00' &&
          params.row.lotExpirationDate !== '0001-01-02T00:00:00'
            ? moment(params.row.lotExpirationDate).format('MMM DD YYYY')
            : undefined}
        </Typography>
      ),
    },
    {
      field: 'expectedShipDate',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Ship Date</Typography>
        </Box>
      ),
    },
    {
      field: 'expectedArrivalDate',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Arrival Date</Typography>
        </Box>
      ),
    },
    {
      field: 'shipVia',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Ship Via</Typography>
        </Box>
      ),
    },
    {
      field: 'status',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Status</Typography>
        </Box>
      ),
    },
    {
      field: 'notes',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Notes</Typography>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setItems(passedItems);
  }, [items, update, updateComponent]);

  function toolbar() {
    return (
      <>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: '8px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '8px',
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Line Items
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              <GridToolbarExport />
              <GridToolbarQuickFilter variant="outlined" size="small" />
            </Box>
          </Box>
        </Box>
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={3}>
            {/*             <Input placeholder="Ship To" size="small" sx={{ width: '100%' }} />
             */}
          </MUIGrid>
          <MUIGrid item xs={3}>
            {/* <DatePickerInput
              label="Expected date"
              inputFormat="MM/dd/yyyy"
              renderInput={(params) => (
                <TextField {...params} error={false} size="small" />
              )}
            /> */}
          </MUIGrid>
        </MUIGrid>
      </>
    );
  }

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <DataGrid
          autoHeight
          headerHeight={120}
          rows={items}
          density="compact"
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          columns={lineItemsColumns}
          pageSize={6}
          rowsPerPageOptions={[6]}
          getRowId={(row) =>
            urlQuery.get('id') === 'new' ? row.tempId : row.receiveId
          }
          components={{ Toolbar: toolbar }}
          sx={{
            borderColor: 'transparent',
            '& .MuiDataGrid-columnSeparator--sideRight': {
              display: 'none !important',
            },
          }}
        />

        <Button
          variant="outlined"
          size="small"
          onClick={() => onOpenPurchaseOrderItemModal()}
          sx={{
            padding: '8px !important;',
            textTransform: 'capitalize !important',
          }}
        >
          Add Line Item
        </Button>
      </Box>
    </Card>
  );
};

const PurchaseOrders = () => {
  const theme = useTheme();
  const urlQuery = useQuery();
  const navigate = useNavigate();
  const [updateLineItems, setUpdateLineItems] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [createSupplier, setCreateSupplier] = useState(false);
  const [customerSuppliers, setCustomerSuppliers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [lineItems, setLineItems] = useState<any>([]);
  const [isNewOrder, setNewOrder] = useState(false);
  const [enableFormEdit, setEnabledFormEdit] = useState<boolean>(false);
  const [editLineItem, setEditLineItem] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<any>({});
  const {
    currentUser,
    currentLocationAndFacility,
    isWarehouseCustomerAccount,
  } = useContext(AuthContext);
  const { handleUpdateData, updateData } = useContext(GlobalContext);
  const defaultAutocompleteOption: AutoCompleteOptionType | null = {
    id: -1,
    label: '',
  };
  const initialFormState: any = {
    customerId: currentUser.Claim_CustomerId,
    customerLocationId: currentLocationAndFacility.customerLocationId,
    customerFacilityId: currentLocationAndFacility.customerFacilityId,
    warehouseCustomerId: undefined,
    purchaseOrderNo: '',
    supplierId: {
      id: defaultAutocompleteOption.id,
      label: defaultAutocompleteOption.label,
    },
    issueDate: new Date().toLocaleDateString(),
    arrivalDate: '',
    notes: '',
    supplierCompanyName: '',
    supplierRecipientName: '',
    supplierPhone: '',
    supplierEmail: '',
    supplierAddress1: '',
    supplierAddress2: '',
    supplierCityTownship: '',
    supplierStateProvinceRegion: '',
    supplierZipPostalCode: '',
    supplierCountry: {
      id: defaultAutocompleteOption.id,
      label: defaultAutocompleteOption.label,
    },
    supplierDepartmentNumber: '',
    lineItems: [
      {
        tempId: 0,
        itemId: 0,
        qty: 0,
        expectedArrivalDate: '',
        expectedShipDate: '',
        shipVia: '',
        notes: '',
        lotId: 0,
        lotNo: '',
        lotExpirationDate: '',
      },
    ],
  };
  const initialFormErrorState: any = {
    warehouseCustomerId: '',
    purchaseOrderNo: '',
    supplierId: '',
    issueDate: '',
    notes: '',
    supplierCompanyName: '',
    supplierRecipientName: '',
    supplierPhone: '',
    supplierEmail: '',
    supplierAddress1: '',
    supplierAddress2: '',
    supplierCityTownship: '',
    supplierStateProvinceRegion: '',
    supplierZipPostalCode: '',
    supplierCountry: '',
    supplierDepartmentNumber: '',
    lineItems: '',
  };
  const [form, setForm] = useState<any>(initialFormState);
  const [formErrors, setFormErrors] = useState<any>(initialFormErrorState);
  const [showErrors, setShowErrors] = useState(false);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [customerValue, setCustomerValue] = useState<CustomerType>(null);
  const [optionCustomers, setOptionCustomers] = useState<
    CustomerType[] | null | []
  >([]);

  const handleInitiateEditPO = () => {
    setEnabledFormEdit(true);
    setDisabled(false);
  };

  const handleUpdateLineItems = () => {
    setUpdateLineItems(!updateLineItems);
  };

  const onForm = (key, text) => {
    setForm(() => ({
      ...form,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) =>
    onForm(key, event.target.value);

  const dateInputHandler = (newValue: Date | null) => {
    onForm('issueDate', newValue);
  };

  const arrivalDateInputHandler = (newValue: Date | null) => {
    onForm('arrivalDate', newValue);
  };

  const autoCompleteInputHandler = (
    key: string,
    value: AutoCompleteOptionType,
  ) => {
    setForm(() => ({
      ...form,
      [key]:
        value === null
          ? {
              id: defaultAutocompleteOption.id,
              label: defaultAutocompleteOption.label,
            }
          : value,
    }));
  };

  const schema = yup.object().shape({
    customerId: yup.number().required('Required'),
    customerLocationId: yup.number().required('Required'),
    customerFacilityId: yup.number().required('Required'),
    warehouseCustomerId: yup.number().required('Required'),
    purchaseOrderNo: yup.string().required('Required'),
    supplierId: yup
      .object()
      .shape({ id: yup.string().nullable(), label: yup.string().nullable() }),
    issueDate: yup.string().required('Required'),
    notes: yup.string().required('Required'),
    supplierCompanyName: yup.string(),
    supplierRecipientName: yup.string(),
    supplierPhone: yup.string(),
    supplierEmail: yup.string(),
    supplierAddress1: yup.string(),
    supplierAddress2: yup.string(),
    supplierCityTownship: yup.string(),
    supplierStateProvinceRegion: yup.string(),
    supplierZipPostalCode: yup.string(),
    supplierCountry: yup
      .object()
      .shape({ id: yup.string().nullable(), label: yup.string() }),
    supplierDepartmentNumber: yup.string(),
  });

  const onLoadSuppliers = async () => {
    try {
      const countriesFromApi = await getCountries('');
      const suppliersFromApi =
        await getSupplierLookupByWarehouseCustomerSearchByName(
          currentUser.Claim_CustomerId,
          0,
          '',
        );
      setCountries(countriesFromApi);
      setCustomerSuppliers(suppliersFromApi);

      return true;
    } catch (err) {
      return err;
    }
  };

  const supplierSelectionOptions = Array.isArray(customerSuppliers)
    ? customerSuppliers.map((v, index) => ({
        id: v.supplierId,
        label: v.name,
      }))
    : [{ id: 0, label: 'New Supplier' }];

  const countrySelectionOptions = Array.isArray(countries)
    ? countries.map((v, index) => ({
        id: v.countryId,
        label: v.name,
      }))
    : [{ id: 0, label: '' }];

  const onLoadPurchaseOrderById = async () => {
    try {
      const purchaseOrderDetailsFromApi = await getPurchaseOrderDetailV2(
        urlQuery.get('id'),
      );

      const { items } = purchaseOrderDetailsFromApi;
      const { purchaseOrderDetail } = purchaseOrderDetailsFromApi;

      const newItems = [];

      items.map((v) =>
        newItems.push({
          receiveId: v.receiveId,
          itemId: v.itemId,
          itemSKU: v.itemSKU,
          description: v.description,
          qty: v.qty,
          received: v.received,
          expectedArrivalDate: v.expectedArrivalDate,
          shipVia: v.shipVia,
          expectedShipDate: v.expectedShipDate,
          status: v.status,
          notes: v.notes,
          lotId: v.lot?.lotId,
          lotNo: v.lot?.lotNo,
          lotExpirationDate: v.lot?.expirationDate,
        }),
      );

      setLineItems(newItems);

      setDisabled(true);
      setForm({
        customerId: currentUser.Claim_CustomerId,
        customerLocationId: currentLocationAndFacility.customerLocationId,
        customerFacilityId: currentLocationAndFacility.customerFacilityId,
        warehouseCustomerId: purchaseOrderDetail.warehouseCustomerId,
        purchaseOrderNo: purchaseOrderDetail.purchaseOrderNo,
        purchaseOrderId: purchaseOrderDetail.purchaseOrderId,
        supplierId: {
          id: purchaseOrderDetail.supplierId,
          label: purchaseOrderDetail.companyName,
        },
        issueDate: purchaseOrderDetail.issueDate,
        arrivalDate: purchaseOrderDetail.arrivalDate,
        notes: purchaseOrderDetail.notes,
        supplierCompanyName: purchaseOrderDetail.companyName,
        supplierRecipientName: purchaseOrderDetail.recipientName,
        supplierPhone: purchaseOrderDetail.phone,
        supplierEmail: purchaseOrderDetail.email,
        supplierAddress1: purchaseOrderDetail.address1,
        supplierAddress2: purchaseOrderDetail.address2,
        supplierCityTownship: purchaseOrderDetail.cityTownship,
        supplierStateProvinceRegion: purchaseOrderDetail.stateProvinceRegion,
        supplierZipPostalCode: purchaseOrderDetail.zipPostalCode,
        supplierCountry: {
          id: purchaseOrderDetail.countryId,
          label: purchaseOrderDetail.country,
        },
        supplierDepartmentNumber: purchaseOrderDetail.departmentNo,
      });

      return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadGeneratedPONumber = async () => {
    try {
      const generatedPONumberFromApi = await getGeneratedPurchaseOrderNo(
        currentUser.Claim_CustomerId,
        form.warehouseCustomerId,
      );
      setForm((current) => ({
        ...current,
        purchaseOrderNo: generatedPONumberFromApi,
      }));
      return true;
    } catch (err) {
      return err;
    }
  };

  const onSaveNewPurchaseOrder = async () => {
    form.customerId = currentUser.Claim_CustomerId;
    schema
      .validate(form, {
        abortEarly: false,
      })
      .then(async () => {
        try {
          const newForm: any = {};
          newForm.IsUsedinPurchasedOrder = true;

          if (!enableFormEdit) {
            newForm.customerId = form.customerId;
            newForm.customerLocationId = form.customerLocationId;
            newForm.customerFacilityId = form.customerFacilityId;
            newForm.warehouseCustomerId = form.warehouseCustomerId;
            newForm.purchaseOrderNo = form.purchaseOrderNo;
            if (form.supplierId.id === -1) {
              newForm.supplierId = null;
            } else {
              newForm.supplierId = form.supplierId.id;
            }
            newForm.issueDate = form.issueDate;
            newForm.arrivalDate = form.arrivalDate
              ? moment(form.arrivalDate).format('MM/DD/YYYY')
              : '';
            newForm.notes = form.notes;
            newForm.supplierCompanyName = form.supplierCompanyName;
            newForm.supplierRecipientName = form.supplierRecipientName;
            newForm.supplierPhone = form.supplierPhone;
            newForm.supplierEmail = form.supplierEmail;
            newForm.supplierAddress1 = form.supplierAddress1;
            newForm.supplierAddress2 = form.supplierAddress2;
            newForm.supplierCityTownship = form.supplierCityTownship;
            newForm.supplierStateProvinceRegion =
              form.supplierStateProvinceRegion;
            newForm.supplierZipPostalCode = form.supplierZipPostalCode;
            if (form.supplierCountry.id === -1) {
              newForm.supplierCountry = null;
            } else {
              newForm.supplierCountry = form.supplierCountry.id;
            }
            newForm.supplierDepartmentNumber = form.supplierDepartmentNumber;
            newForm.lineItems = lineItems.map((item) => ({
              itemId: item.itemId,
              qty: item.qty,
              expectedArrivalDate: item.expectedArrivalDate,
              expectedShipDate: item.expectedShipDate,
              shipVia: item.shipVia,
              notes: item.notes,
              lotId: item.lotId,
              lotExpirationDate: item?.lotExpirationDate,
            }));
            const newPurchaseOrder = await createPurchaseOrderV2(newForm);

            snackActions.success(`Successfully created a new purchase order.`);
            setDisabled(true);
            setFormErrors(initialFormErrorState);
            const querysearchParams = {
              orderNo: newPurchaseOrder.purchaseOrderNo,
              id: newPurchaseOrder.purchaseOrderId,
            };
            navigate({
              pathname: !isWarehouseCustomerAccount
                ? `/receiving/purchase-orders`
                : `/warehouse-customer-receiving`,
              search: `?${createSearchParams(querysearchParams)}`,
            });
            handleUpdateData();
          } else if (enableFormEdit) {
            newForm.purchaseOrderId = urlQuery.get('id');
            newForm.customerId = form.customerId;
            newForm.customerFacilityId = form.customerFacilityId;
            newForm.warehouseCustomerId = form.warehouseCustomerId;
            newForm.notes = form.notes;
            newForm.issueDate = form.issueDate;
            newForm.arrivalDate = form.arrivalDate
              ? moment(form.arrivalDate).format('MM/DD/YYYY')
              : '';
            // newForm.warehouseCustomerId = form.warehouseCustomerId;
            if (form.supplierId.id !== -1 || form.supplierId.id !== undefined) {
              newForm.supplierId = form.supplierId.id;
              newForm.SupplierExternalId = form.supplierId.id;
              newForm.CompanyName = form.supplierCompanyName; // for supplier company name
              newForm.RecipientName = form.supplierRecipientName;
              newForm.Phone = form.supplierPhone;
              newForm.Email = form.supplierEmail;
              newForm.Address1 = form.supplierAddress1;
              newForm.Address2 = form.supplierAddress2;
              newForm.CityTownship = form.supplierCityTownship;
              newForm.StateProvinceRegion = form.supplierStateProvinceRegion;
              newForm.ZipPostalCode = form.supplierZipPostalCode;
              if (
                form.supplierCountry.id === -1 ||
                form.supplierCountry.id === undefined
              ) {
                newForm.supplierCountry = null;
              } else {
                newForm.Country = form.supplierCountry.id;
              }
              newForm.DepartmentNumber = form.supplierDepartmentNumber;
              const editSaveSupplier = await editSupplier(
                newForm.supplierId,
                newForm,
              );
            }
            const editedPurchaseOrder = await editPurchaseOrder(newForm);
            snackActions.success(
              `Successfully edited PO ${urlQuery.get('orderNo')}`,
            );
            setDisabled(true);
            setEnabledFormEdit(false);
            setFormErrors(initialFormErrorState);
          }
        } catch (err: any) {
          setDisabled(true);
          setEnabledFormEdit(false);
          setError(err);
          setFormErrors(initialFormErrorState);
          snackActions.error(`${err}`);
        }
      })
      .catch((err) => {
        const errorsFound = err.inner.reduce((acc, curr) => {
          if (!acc[curr.path]) acc[curr.path] = curr.message;
          return acc;
        }, {});
        setFormErrors(errorsFound);
        setError('');
        setShowErrors(true);
      });
  };

  const handleResetEditStates = () => {
    setEditLineItem(false);
    setEditItem({});
  };
  const onLoadCustomersLookup = async () => {
    try {
      const warehouseCustomersLookupFromApi = await getCustomersLookup(
        currentUser.Claim_CustomerId,
        '',
      );

      return warehouseCustomersLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    const optCustomers = onLoadCustomersLookup();
    optCustomers
      .then((opt) => {
        setOptionCustomers(
          opt.map((c: any) => ({
            label: c.name,
            value: c.warehouseCustomerId,
          })),
        );
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    onLoadSuppliers();

    if (urlQuery.get('id') === 'new') {
      setIsCreate(true);
      setCustomerSuppliers([]);
      setNewOrder(true);
      if (form.warehouseCustomerId !== undefined) {
        setForm((prev) => ({
          ...prev,
          warehouseCustomerId: customerValue.value,
        }));
        onLoadGeneratedPONumber();
      } else {
        setForm(initialFormState);
      }
    } else if (
      urlQuery.get('id') === undefined ||
      urlQuery.get('id') === null
    ) {
      navigate('/receiving/purchase-orders?id=new');
      setCustomerSuppliers([]);
      onLoadGeneratedPONumber();
      setForm(initialFormState);
    } else {
      onLoadPurchaseOrderById();
      setIsCreate(false);
      setCreateSupplier(true);
      setDisabled(true);
      setIsReadOnly(true);
    }
  }, [updateData, form.warehouseCustomerId]);

  useEffect(() => {
    if (optionCustomers !== null) {
      const customer = (el: CustomerType) => {
        if (urlQuery.get('id') === 'new')
          return optionCustomers.length === 1
            ? el.label === currentUser.Claim_CustomerName
            : null;
        if (urlQuery.get('id') !== undefined || urlQuery.get('id') !== null)
          return el.value === form.warehouseCustomerId;
        return null;
      };

      const vmCustomer = optionCustomers.find(customer);

      if (vmCustomer !== undefined) {
        setCustomerValue(vmCustomer);
        setForm((prev) => ({
          ...prev,
          warehouseCustomerId: vmCustomer.value,
        }));
      }

      if (optionCustomers.length === 1) {
        optionCustomers.forEach((o) => {
          setCustomerValue((e) => ({
            ...e,
            value: o.value,
            label: o.label,
          }));
          setForm((prev) => ({
            ...prev,
            warehouseCustomerId: o.value,
          }));
        });
      }
    }
  }, [optionCustomers, form.warehouseCustomerId]);
  return (
    <MainContainer>
      {enableFormEdit ? (
        <Header poSaveCallBack={() => onSaveNewPurchaseOrder()} />
      ) : (
        <Header poEditCallBack={() => handleInitiateEditPO()} />
      )}
      {isNewOrder ? (
        <PurchaseOrderItemModalV2
          lineItems={lineItems}
          addItem={setLineItems}
          edit={editLineItem}
          editItem={editItem}
          editCallBack={() => handleResetEditStates()}
        />
      ) : (
        <PurchaseOrderItemModalV2
          edit={editLineItem}
          editItem={editItem}
          callBack={() => handleUpdateLineItems()}
          editCallBack={() => handleResetEditStates()}
        />
      )}
      <ContentContainer>
        <Card
          sx={{
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography variant="h6" fontWeight="bold" mb={2}>
            PO Details
          </Typography>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={3}>
              <Autocomplete
                disabled={isReadOnly}
                onChange={(event: any, newValue: CustomerType) => {
                  if (newValue !== null) {
                    setCustomerValue(newValue);
                    // onForm('warehouseCustomerId', newValue.value);
                    setForm((prev) => ({
                      ...prev,
                      warehouseCustomerId: newValue.value,
                    }));
                  } else {
                    setCustomerValue(null);
                    setForm((prev) => ({
                      ...prev,
                      warehouseCustomerId: undefined,
                    }));

                    // onForm('warehouseCustomerId', undefined);
                  }
                }}
                id="controllable-states"
                options={optionCustomers}
                getOptionLabel={(option: CustomerType) => option.label}
                value={customerValue}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderOption={(props, option) => (
                  <li
                    data-name="warehouseCustomerId"
                    {...props}
                    key={option.value}
                  >
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    {...(formErrors.warehouseCustomerId && {
                      error: true,
                      helperText: formErrors.warehouseCustomerId,
                    })}
                    id="outlined-required"
                    label="Client"
                    size="small"
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </MUIGrid>
            <MUIGrid item xs={3}>
              <Input
                required
                placeholder="PO No."
                size="small"
                sx={{
                  width: '100%',
                  backgroundColor: '#ffffff !important',
                }}
                value={form.purchaseOrderNo}
                error={formErrors.purchaseOrderNo}
                onChange={(value) => inputHandler('purchaseOrderNo', value)}
                disabled={isReadOnly}
              />
            </MUIGrid>
            <MUIGrid item xs={3}>
              {/* <Button
                variant="contained"
                size="small"
                sx={{
                  padding: '8px !important;',
                  textTransform: 'capitalize !important',
                }}
              >
                Create
              </Button> */}
            </MUIGrid>
          </MUIGrid>
        </Card>
        <Card
          sx={{
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography variant="h6" fontWeight="bold" mb={2}>
            PO Header
          </Typography>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={3}>
              <DatePickerInput
                sx={{ width: '100%', background: 'white' }}
                inputFormat="MM/dd/yyyy"
                value={form.issueDate}
                label="Issue Date"
                onChange={dateInputHandler}
                disabled={disabled}
                renderInput={(params) =>
                  formErrors.issueDate ? (
                    <TextField
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      {...params}
                      size="small"
                      error
                      helperText={formErrors.issueDate}
                      disabled={disabled}
                    />
                  ) : (
                    <TextField
                      {...params}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      error={false}
                      size="small"
                      disabled={disabled}
                    />
                  )
                }
              />
            </MUIGrid>
            <MUIGrid item xs={3}>
              <Input
                required
                placeholder="Notes"
                size="small"
                sx={{
                  width: '100%',
                  backgroundColor: '#ffffff !important',
                }}
                value={form.notes}
                error={formErrors.notes}
                onChange={(value) => inputHandler('notes', value)}
                disabled={disabled}
              />
            </MUIGrid>

            <MUIGrid item xs={3}>
              <DatePickerInput
                sx={{ width: '100%', background: 'white' }}
                inputFormat="MM/dd/yyyy"
                value={form.arrivalDate || null}
                label="Arrival Date"
                onChange={arrivalDateInputHandler}
                disabled={disabled}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-root': {
                        background: 'white',
                      },
                    }}
                    error={false}
                    size="small"
                    disabled={disabled}
                  />
                )}
              />
            </MUIGrid>
          </MUIGrid>
          <Box
            sx={{
              marginTop: 2,
              padding: 2,
              width: '100%',
              backgroundColor:
                theme.palette.mode === 'light' ? '#f5f5f5' : '#17262f',
            }}
          >
            <Typography variant="h6" fontWeight="bold" mb={2}>
              Supplier
            </Typography>
            {isCreate && (
              <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <MUIGrid item xs={3}>
                  <Autocomplete
                    options={supplierSelectionOptions}
                    size="small"
                    disabled={isReadOnly}
                    value={form.supplierId}
                    sx={{ width: '100%' }}
                    onChange={(event: any, values) => {
                      setForm((prevState) => ({
                        ...prevState,
                        supplierId: values,
                      }));
                    }}
                    renderInput={(params) =>
                      formErrors.supplierId ? (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          sx={{
                            '& .MuiInputBase-root': {
                              background: 'white',
                            },
                          }}
                          label="Supplier"
                          error
                          helperText={formErrors.supplierId}
                          hidden={isCreate}
                          disabled={isReadOnly}
                        />
                      ) : (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          sx={{
                            '& .MuiInputBase-root': {
                              background: 'white',
                            },
                          }}
                          label="Supplier"
                          hidden={isCreate}
                          disabled={isReadOnly}
                        />
                      )
                    }
                  />
                </MUIGrid>
                <MUIGrid item xs={3}>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      padding: '8px !important;',
                      textTransform: 'capitalize !important',
                    }}
                    onClick={() => setCreateSupplier(true)}
                  >
                    New Supplier
                  </Button>
                </MUIGrid>
              </MUIGrid>
            )}

            <br />
            {createSupplier && (
              <>
                <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <MUIGrid item xs={3}>
                    <Input
                      placeholder="Company Name *"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={form.supplierCompanyName}
                      error={formErrors.supplierCompanyName}
                      onChange={(value) =>
                        inputHandler('supplierCompanyName', value)
                      }
                      disabled={disabled}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <Input
                      placeholder="Recipient Name"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={form.supplierRecipientName}
                      error={formErrors.supplierRecipientName}
                      onChange={(value) =>
                        inputHandler('supplierRecipientName', value)
                      }
                      disabled={disabled}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <Input
                      placeholder="Phone"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={form.supplierPhone}
                      error={formErrors.supplierPhone}
                      onChange={(value) => inputHandler('supplierPhone', value)}
                      disabled={disabled}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <Input
                      placeholder="Email"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={form.supplierEmail}
                      error={formErrors.supplierEmail}
                      onChange={(value) => inputHandler('supplierEmail', value)}
                      disabled={disabled}
                    />
                  </MUIGrid>
                </MUIGrid>
                <br />
                <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <MUIGrid item xs={3}>
                    <Input
                      placeholder="Address 1"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={form.supplierAddress1}
                      error={formErrors.supplierAddress1}
                      onChange={(value) =>
                        inputHandler('supplierAddress1', value)
                      }
                      disabled={disabled}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <Input
                      placeholder="Address 2"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={form.supplierAddress2}
                      error={formErrors.supplierAddress2}
                      onChange={(value) =>
                        inputHandler('supplierAddress2', value)
                      }
                      disabled={disabled}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <Input
                      placeholder="City/Township"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={form.supplierCityTownship}
                      error={formErrors.supplierCityTownship}
                      onChange={(value) =>
                        inputHandler('supplierCityTownship', value)
                      }
                      disabled={disabled}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <Input
                      placeholder="State/Province/Region"
                      value={form.supplierStateProvinceRegion}
                      error={formErrors.supplierStateProvinceRegion}
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      onChange={(value) =>
                        inputHandler('supplierStateProvinceRegion', value)
                      }
                      disabled={disabled}
                    />
                  </MUIGrid>
                </MUIGrid>
                <br />
                <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <MUIGrid item xs={3}>
                    <Input
                      placeholder="Postal Code"
                      size="small"
                      value={form.supplierZipPostalCode}
                      error={formErrors.supplierZipPostalCode}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      onChange={(value) =>
                        inputHandler('supplierZipPostalCode', value)
                      }
                      disabled={disabled}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <Autocomplete
                      options={countrySelectionOptions}
                      size="small"
                      disabled={disabled}
                      value={form.supplierCountry}
                      sx={{ width: '100%' }}
                      onChange={(
                        event: any,
                        value: AutoCompleteOptionType | null,
                      ) => {
                        autoCompleteInputHandler('supplierCountry', value);
                      }}
                      getOptionLabel={(option) =>
                        option.label ? option.label : ''
                      }
                      renderInput={(params) =>
                        formErrors.supplierCountry ? (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: autoCompleteTimeStamp().toString(),
                            }}
                            autoComplete="off"
                            sx={{
                              '& .MuiInputBase-root': {
                                background: 'white',
                              },
                            }}
                            label="Country"
                            error
                            helperText={formErrors.supplierId}
                            disabled={isReadOnly}
                          />
                        ) : (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: autoCompleteTimeStamp().toString(),
                            }}
                            autoComplete="off"
                            sx={{
                              '& .MuiInputBase-root': {
                                background: 'white',
                              },
                            }}
                            {...params}
                            label="Country"
                            disabled={disabled}
                          />
                        )
                      }
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <Input
                      placeholder="Department No."
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={form.supplierDepartmentNumber}
                      error={formErrors.supplierDepartmentNumber}
                      onChange={(value) =>
                        inputHandler('supplierDepartmentNumber', value)
                      }
                      disabled={disabled}
                    />
                  </MUIGrid>
                </MUIGrid>
              </>
            )}
          </Box>
        </Card>
        <LineItems
          update={updateLineItems}
          items={lineItems}
          edit={setEditLineItem}
          editItem={setEditItem}
          removeItem={setLineItems}
          isNewOrder={isNewOrder}
        />
        {urlQuery.get('id') === 'new' && (
          <Card sx={{ display: 'flex', gap: '8px' }}>
            <Button
              variant="contained"
              size="small"
              sx={{
                padding: '8px !important;',
                textTransform: 'capitalize !important',
              }}
              onClick={() => onSaveNewPurchaseOrder()}
            >
              Submit PO
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{
                padding: '8px !important;',
                textTransform: 'capitalize !important',
              }}
            >
              Submit & Print
            </Button>
          </Card>
        )}
      </ContentContainer>
    </MainContainer>
  );
};

export default React.memo(PurchaseOrders);
