import { createBin } from './bin.js';
import { createRack } from './rack.js';

export const populateScene = (
  numAisles,
  racksPerAisle,
  levels,
  scene,
  bins,
  aisleSpacing,
  rackSpacing,
  startX, // New parameter
) => {
  const racks = []; // Array to store rack objects

  for (let aisle = 0; aisle < numAisles; aisle += 1) {
    for (let row = 0; row < racksPerAisle; row += 1) {
      const rack = createRack(aisle, row, levels);
      const xPosition = startX + aisle * aisleSpacing; // Adjusted to use startX
      const zPosition = row * rackSpacing;

      rack.position.set(xPosition, 0, zPosition);
      scene.add(rack);
      racks.push(rack);

      const arrBins = bins.filter(
        (r) => r.row - 1 === row && r.aisle - 1 === aisle,
      );

      arrBins.forEach((bin) => {
        if (bin.items && bin.items.length > 0) {
          const yPosition = (bin.level - 1) * 5 + 1.5;
          const binModel = createBin(xPosition, yPosition, zPosition, bin);
          scene.add(binModel);
        }
      });
    }
  }
  return racks;
};
