/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useReducer, Reducer } from 'react';

import { PageLoadStateEnum } from 'common/enums';
import Button from 'components/button';
import { snackActions } from 'config/snackbar.js';
import { ReceiptLineItemImageType } from 'pages/receiving/receipts/subpage';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import ImageFullScreenPortal from 'pages/shared/imagefullscreenportal';
import { getReceiptLineItemImages } from 'services/api/receipts/receipts-new.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  Grid as MUIGrid,
} from '@mui/material';

interface ItemReceiptPictureModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locationDetails?: any;
}

type Model = {
  images: ReceiptLineItemImageType[];
  showFullScreen: boolean;
  base64DataFullScreen?: string;
};

interface State {
  pageLoadState: PageLoadStateEnum;
  model: Model;
  error: string | null;
}

const INITIAL_STATE: State = {
  pageLoadState: PageLoadStateEnum.LOAD_NONE,
  model: {
    images: [],
    showFullScreen: false,
    base64DataFullScreen: null,
  },
  error: '',
};

enum ACTION_TYPES {
  PAGE_LOADING_START = 'PAGE_LOADING_START',
  SET_MODEL = 'SET_MODEL',
  IMAGE_SET_FULLSCREEN_TRUE = 'IMAGE_SET_FULLSCREEN_TRUE',
  IMAGE_SET_FULLSCREEN_FALSE = 'IMAGE_SET_FULLSCREEN_FALSE',
}

interface Action {
  type: ACTION_TYPES;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

function reducer(state: State, action: Action) {
  const { type } = action;

  switch (type) {
    case ACTION_TYPES.PAGE_LOADING_START: {
      return { ...state, pageLoadState: PageLoadStateEnum.LOAD_START };
    }
    case ACTION_TYPES.SET_MODEL: {
      return {
        ...state,
        pageLoadState: PageLoadStateEnum.LOAD_FINISH,
        model: action.payload,
      };
    }
    case ACTION_TYPES.IMAGE_SET_FULLSCREEN_TRUE: {
      return {
        ...state,
        model: {
          ...state.model,
          showFullScreen: true,
          base64DataFullScreen: action.payload,
        },
      };
    }
    case ACTION_TYPES.IMAGE_SET_FULLSCREEN_FALSE: {
      return {
        ...state,
        model: {
          ...state.model,
          showFullScreen: false,
          base64DataFullScreen: null,
        },
      };
    }
    default:
      return state;
  }
}

export default React.memo(
  ({ locationDetails }: ItemReceiptPictureModalProps) => {
    const [state, dispatch] = useReducer<Reducer<State, Action>>(
      reducer,
      INITIAL_STATE,
    );

    const { isItemReceiptPictureModalOpen, setIsItemReceiptPictureModalOpen } =
      useContext(GlobalContext);

    // page load
    useEffect(() => {
      if (state.pageLoadState === PageLoadStateEnum.LOAD_NONE) {
        dispatch({ type: ACTION_TYPES.PAGE_LOADING_START, payload: null });
      }
    }, [state.pageLoadState]);
    useEffect(() => {
      if (state.pageLoadState === PageLoadStateEnum.LOAD_START) {
        if (locationDetails.receiptLineItemId !== undefined) {
          const res = getReceiptLineItemImages(
            locationDetails.receiptLineItemId,
          );
          res
            .then((x: ReceiptLineItemImageType[]) => {
              const model: Model = {
                images: x,
                showFullScreen: false,
                base64DataFullScreen: null,
              };
              dispatch({ type: ACTION_TYPES.SET_MODEL, payload: model });
            })
            .catch(() => {
              snackActions.error('Something went wrong!');
            });
        } else {
          const model: Model = {
            images: [],
            showFullScreen: false,
            base64DataFullScreen: null,
          };
          dispatch({ type: ACTION_TYPES.SET_MODEL, payload: model });
        }
      }
    }, [state.pageLoadState, locationDetails.receiptLineItemId]);

    const imageClickHandler = (model: Model, row: ReceiptLineItemImageType) => {
      if (model.showFullScreen) {
        dispatch({
          type: ACTION_TYPES.IMAGE_SET_FULLSCREEN_FALSE,
          payload: null,
        });
      } else {
        dispatch({
          type: ACTION_TYPES.IMAGE_SET_FULLSCREEN_TRUE,
          payload: row.base64Data,
        });
      }
    };

    const imageFullScreenCloseHandler = () => {
      dispatch({
        type: ACTION_TYPES.IMAGE_SET_FULLSCREEN_FALSE,
        payload: null,
      });
    };

    return (
      <Modal open={isItemReceiptPictureModalOpen}>
        <MUIContainer>
          <Typography
            className="modalTextHeader"
            variant="h6"
            fontWeight="bold"
          >
            Images
          </Typography>
          <MUIContent>
            <Box
              sx={{
                width: '100%',
              }}
            >
              {state.pageLoadState === PageLoadStateEnum.LOAD_START && (
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ marginBottom: 1 }}
                >
                  <MUIGrid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <CircularProgress />
                  </MUIGrid>
                </MUIGrid>
              )}

              {state.pageLoadState === PageLoadStateEnum.LOAD_FINISH &&
                state.model.images.length === 0 && (
                  <MUIGrid
                    container
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ marginBottom: 1 }}
                  >
                    <MUIGrid
                      item
                      xs={12}
                      sx={{ display: 'flex', justifyContent: 'space-around' }}
                    >
                      NO RECORD FOUND
                    </MUIGrid>
                  </MUIGrid>
                )}

              {state.pageLoadState === PageLoadStateEnum.LOAD_FINISH && (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {state.model.images.map((row: ReceiptLineItemImageType) => (
                    <Box key={row.base64Data} margin={1}>
                      <Box
                        component="img"
                        src={`${row.base64Data}`}
                        sx={{
                          height: '125px',
                          width: '125px',
                          border: '2px dashed #e6e6e6',
                          borderRadius: '5px',
                          textAlign: 'center',
                          backgroundColor: '#f5f5f5',
                          cursor: 'pointer',
                        }}
                        onClick={() => imageClickHandler(state.model, row)}
                      />
                    </Box>
                  ))}
                </Box>
              )}

              <MUIGrid
                container
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={6} sm={6} md={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      boxShadow: 'none',
                      width: '100%',
                      marginTop: '20px',
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ padding: 1, width: '100%' }}
                      onClick={() => setIsItemReceiptPictureModalOpen(false)}
                    >
                      Close
                    </Button>
                  </Box>
                </MUIGrid>
              </MUIGrid>

              {state.model.showFullScreen && (
                <ImageFullScreenPortal
                  isOpen={state.model.showFullScreen}
                  imageSrc={state.model.base64DataFullScreen.toString()}
                  onImageFullScreenClose={imageFullScreenCloseHandler}
                />
              )}
            </Box>
          </MUIContent>
        </MUIContainer>
      </Modal>
    );
  },
);
