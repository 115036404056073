import React from 'react';

import {
  AddBusinessOutlined as AddBusinessOutlinedIcon,
  CorporateFareOutlined as CorporateFareOutlinedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  Download as DownloadIcon,
  DriveFileMoveOutlined as DriveFileMoveOutlinedIcon,
  HourglassEmpty as HourglassEmptyIcon,
  ImportExportOutlined as ImportExportOutlinedIcon,
  Inbox as InboxIcon,
  Inventory2Outlined as Inventory2OutlinedIcon,
  ListAlt as ListAltIcon,
  ManageAccountsOutlined as ManageAccountsOutlinedIcon,
  MoveDownOutlined as MoveDownOutlinedIcon,
  MoveToInboxOutlined as MoveToInboxOutlinedIcon,
  PanoramaHorizontal as PanoramaHorizontalIcon,
  People as PeopleIcon,
  Receipt as ReceiptIcon,
  ReceiptLong as ReceiptLongIcon,
  Settings as SettingsIcon,
  TapAndPlayOutlined as TapAndPlayOutlinedIcon,
  WorkspacesOutlined as WorkspacesOutlinedIcon,
} from '@mui/icons-material';

const NavigationPageIcon = ({ iconName, styleProperties }) => {
  const iconMappings = {
    AddBusinessOutlined: {
      icon: <AddBusinessOutlinedIcon />,
      style: styleProperties,
    },
    CorporateFareOutlined: {
      icon: <CorporateFareOutlinedIcon />,
      style: styleProperties,
    },
    DescriptionOutlined: {
      icon: <DescriptionOutlinedIcon />,
      style: styleProperties,
    },
    Download: {
      icon: <DownloadIcon />,
      style: styleProperties,
    },
    DriveFileMoveOutlined: {
      icon: <DriveFileMoveOutlinedIcon />,
      style: styleProperties,
    },
    HourglassEmpty: {
      icon: <HourglassEmptyIcon />,
      style: styleProperties,
    },
    ImportExportOutlined: {
      icon: <ImportExportOutlinedIcon />,
      style: styleProperties,
    },
    Inbox: {
      icon: <InboxIcon />,
      style: styleProperties,
    },
    Inventory2Outlined: {
      icon: <Inventory2OutlinedIcon />,
      style: styleProperties,
    },
    ListAlt: {
      icon: <ListAltIcon />,
      style: styleProperties,
    },
    ManageAccountsOutlined: {
      icon: <ManageAccountsOutlinedIcon />,
      style: styleProperties,
    },
    MoveDownOutlined: {
      icon: <MoveDownOutlinedIcon />,
      style: styleProperties,
    },
    MoveToInboxOutlined: {
      icon: <MoveToInboxOutlinedIcon />,
      style: styleProperties,
    },
    PanoramaHorizontal: {
      icon: <PanoramaHorizontalIcon />,
      style: styleProperties,
    },
    People: {
      icon: <PeopleIcon />,
      style: styleProperties,
    },
    Receipt: {
      icon: <ReceiptIcon />,
      style: styleProperties,
    },
    ReceiptLong: {
      icon: <ReceiptLongIcon />,
      style: styleProperties,
    },
    Settings: {
      icon: <SettingsIcon />,
      style: styleProperties,
    },
    TapAndPlayOutlined: {
      icon: <TapAndPlayOutlinedIcon />,
      style: styleProperties,
    },
    WorkspacesOutlined: {
      icon: <WorkspacesOutlinedIcon />,
      style: styleProperties,
    },
  };

  if (Object.prototype.hasOwnProperty.call(iconMappings, iconName)) {
    const { icon, style } = iconMappings[iconName];
    return React.cloneElement(icon, { style });
  }

  return null;
};
export default NavigationPageIcon;
