import React, {
  useEffect,
  useState,
  useContext,
  ChangeEvent,
  useRef,
} from 'react';
import { useReactToPrint } from 'react-to-print';

import Button from 'components/button';
import Card from 'components/card';
import Header from 'components/header';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import Printformtemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import PrintRollModal from 'pages/shared/printroll';
import { MainContainer, ContentContainer } from 'pages/styles';
import {
  getLicensePlateToPrint,
  deleteLicensePlate,
} from 'services/api/licensePlates/licensePlates-new.api';
import { getLicensePlateHistory } from 'services/api/licensePlates/licensePlates.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { ContainerFlat, DatagridStandard } from 'styles';

import { Search } from '@mui/icons-material';
import { Typography, Box } from '@mui/material';
import {
  GridActionsCellItem,
  GridColumns,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridToolbar,
} from '@mui/x-data-grid';

import BatchPrintModal from './batchprintmodal';
import { SinglePrintLicensePlate, MultiplePrintLicensePlate } from './lp-print';

function LicensePlates() {
  const { currentUser } = useContext(AuthContext);

  const {
    loading,
    updateData,
    isPrintRollsModalOpen,
    onOpenPrintFormTemplateModal,
  } = useContext(GlobalContext);
  const [licensePlateHistory, setLicensePlateHistory] = useState([]);
  const [printOfLPItem, setPrintOfLPItem] = useState<any>('');
  const [printOfLPItemBatch, setPrintOfLPItemBatch] = useState<any[]>([]);
  const [filteredLicensePlateHistory, setFilteredLicensePlateHistory] =
    useState([]);
  const [lpIdToPrint, setLPIdToPrint] = useState(null);

  const componentSinglePrintRef = useRef(null);
  const componentMultiplePrintRef = useRef(null);
  const buttonSinglePrintRef = useRef(null);
  const buttonMultiplePrintRef = useRef(null);

  const [searchParams, setSearchParams] = useState({
    searchPlateNo: '',
    searchType: '',
    searchStatus: '',
    searchSKU: '',
    searchArrival: '',
    searchLocation: '',
    searchGenerated: '',
    searchOwner: '',
  });

  type Row = typeof licensePlateHistory[number];

  const onForm = (key, text) => {
    setSearchParams(() => ({
      ...searchParams,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    onForm(key, event.target.value);
  };

  const filterItems = () => {
    const licensePlateNoSearch = licensePlateHistory.filter((u) =>
      u.licensePlateNo === null || u.licensePlateNo === undefined
        ? u
        : u.licensePlateNo
            ?.toString()
            .toLowerCase()
            .includes(searchParams.searchPlateNo.toLowerCase()),
    );

    const typeSearch = licensePlateNoSearch.filter((u) =>
      u.type === null || u.type === undefined
        ? u
        : u.type
            ?.toString()
            .toLowerCase()
            .includes(searchParams.searchType.toLowerCase()),
    );

    const statusSearch = typeSearch.filter((u) =>
      u.status === null || u.status === undefined
        ? u
        : u.status
            ?.toString()
            .toLowerCase()
            .includes(searchParams.searchStatus.toLowerCase()),
    );

    const skuSearch = statusSearch.filter((u) =>
      u.sku === null || u.sku === undefined
        ? u
        : u.sku
            ?.toString()
            .toLowerCase()
            .includes(searchParams.searchSKU.toLowerCase()),
    );

    const arrivalSearch = skuSearch.filter((u) =>
      u.arrival === null || u.arrival === undefined
        ? u
        : u.arrival
            ?.toString()
            .toLowerCase()
            .includes(searchParams.searchArrival.toLowerCase()),
    );

    const locationSearch = arrivalSearch.filter((u) =>
      u.location === null || u.location === undefined
        ? u
        : u.location
            ?.toString()
            .toLowerCase()
            .includes(searchParams.searchLocation.toLowerCase()),
    );

    const generatedSearch = locationSearch.filter((u) =>
      u.generated === null || u.generated === undefined
        ? u
        : u.generated
            ?.toString()
            .toLowerCase()
            .includes(searchParams.searchGenerated.toLowerCase()),
    );

    const ownerSearch = generatedSearch.filter((u) =>
      u.owner === null || u.owner === undefined
        ? u
        : u.owner
            ?.toString()
            .toLowerCase()
            .includes(searchParams.searchOwner.toLowerCase()),
    );

    const finalResult = ownerSearch;

    setFilteredLicensePlateHistory(finalResult);
  };

  const onLoadLicensePlateHistory = async () => {
    try {
      const licensePlateHistoryFromApi = await getLicensePlateHistory(
        currentUser.Claim_CustomerId,
      );

      setLicensePlateHistory(licensePlateHistoryFromApi);
      setFilteredLicensePlateHistory(licensePlateHistoryFromApi);

      return true;
    } catch (err) {
      return err;
    }
  };

  const handleOnClickDelete = async (e: any, id: any) => {
    e.preventDefault();

    try {
      await deleteLicensePlate(id);
      await onLoadLicensePlateHistory();

      snackActions.success('License plate deleted successfully.');
    } catch (err) {
      snackActions.error(err);
    }
  };

  const handleOnClickPrint = async (e: any, id: any) => {
    e.preventDefault();
    try {
      setLPIdToPrint(id);
      onOpenPrintFormTemplateModal();
      const data = await getLicensePlateToPrint(id);
      setPrintOfLPItem({ ...data });
    } catch (err) {
      console.log(err);
    }
  };
  const handleOnClickSinglePrintTrigger = useReactToPrint({
    content: () => componentSinglePrintRef.current,
  });

  const handleOnClickMultiplePrintTrigger = useReactToPrint({
    content: () => componentMultiplePrintRef.current,
  });

  useEffect(() => {
    onLoadLicensePlateHistory();
  }, [loading, updateData]);

  // useEffect(() => {
  //   setPrintOfLPItem((prev) => ({
  //     ...prev,
  //     button: buttonSinglePrintRef.current,
  //   }));
  // }, []);

  const licensePlateHistoryColumns: GridColumns<Row> = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      hideable: false,
      hide: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Delete"
          onClick={(e) => handleOnClickDelete(e, params.row.licensePlateId)}
          showInMenu
        />,
      ],
    },
    {
      field: 'licensePlateNo',
      width: 200,
      headerName: 'Plate No.',
      renderCell: (params) => (
        <Button
          onClick={(e) => handleOnClickPrint(e, params.row.licensePlateId)}
          variant="text"
          size="small"
        >
          {params.row.licensePlateNo}
        </Button>
      ),
    },
    {
      field: 'type',
      width: 200,
      headerName: 'Type',
      renderCell: (params) => <Typography>{params.row.type}</Typography>,
    },
    {
      field: 'status',
      width: 200,
      headerName: 'Status',
      renderCell: (params) => <Typography>{params.row.status}</Typography>,
    },
    {
      field: 'sku',
      width: 200,
      headerName: 'SKU(s)',
      renderCell: (params) => <Typography>{params.row.sku}</Typography>,
    },
    {
      field: 'arrival',
      width: 250,
      headerName: 'Arrival',
      renderCell: (params) => <Typography>{params.row.arrival}</Typography>,
    },
    {
      field: 'location',
      width: 160,
      headerName: 'Location',
      renderCell: (params) => (
        <Typography>
          {params.row.location ? params.row.location : 'N/a'}
        </Typography>
      ),
    },
    {
      field: 'generated',
      width: 250,
      headerName: 'Generated',
      renderCell: (params) => <Typography>{params.row.generated}</Typography>,
    },
    {
      field: 'owner',
      width: 160,
      headerName: 'Owner',
      renderCell: (params) => <Typography>{params.row.owner}</Typography>,
    },
  ];
  function toolbar() {
    return (
      <Box
        sx={{
          paddingBottom: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: '8px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '8px',
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            History
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '8px',
            }}
          >
            <GridToolbarExport />
            <GridToolbarQuickFilter variant="outlined" size="small" />
          </Box>
        </Box>
      </Box>
    );
  }

  const batchPrintCallback = (data) => {
    if (data.length === 0) {
      setPrintOfLPItemBatch([]);
      return;
    }
    const aux = data;
    aux[0].button = buttonMultiplePrintRef.current;
    setPrintOfLPItemBatch(aux);
  };

  return (
    <MainContainer>
      <Header />
      <ContentContainer>
        <div style={{ display: 'none' }}>
          <SinglePrintLicensePlate
            ref={componentSinglePrintRef}
            data={printOfLPItem}
          />
          <button
            type="button"
            ref={buttonSinglePrintRef}
            onClick={handleOnClickSinglePrintTrigger}
          >
            This one is trigger on single print receipt license plate item
          </button>
        </div>

        <div style={{ display: 'none' }}>
          <MultiplePrintLicensePlate
            ref={componentMultiplePrintRef}
            data={printOfLPItemBatch}
          />
          <button
            type="button"
            ref={buttonMultiplePrintRef}
            onClick={handleOnClickMultiplePrintTrigger}
          >
            This one is trigger on multiple print receipt license plate item
          </button>
        </div>
        <ContainerFlat>
          <DatagridStandard
            autoHeight
            headerHeight={30}
            rows={filteredLicensePlateHistory}
            columns={licensePlateHistoryColumns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            getRowId={(row) => row.licensePlateId}
            components={{ Toolbar: GridToolbar }}
            density="compact"
          />
        </ContainerFlat>
      </ContentContainer>
      {lpIdToPrint !== null && lpIdToPrint > 0 && (
        <Printformtemplate
          preview={false}
          dynamicId={lpIdToPrint}
          formtemplateTypeId={FormTemplateTypeEnum.PalletLabels}
          forPalletLabelsIsUseLicense
        />
      )}
      {isPrintRollsModalOpen && <PrintRollModal />}
      <BatchPrintModal callBack={batchPrintCallback} />
    </MainContainer>
  );
}

export default React.memo(LicensePlates);
