import PackemTextLogo from 'assets/images/logo.png';

import { Box } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

export const FormLoginContainer = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    background: #fff;
    padding: 50px;
    border-radius: 20px;
    width: 100%;
    font-family: 'Inter', sans-serif;
  }
`;

export const FormLoginLogo = MuiStyled(Box)`
  && {
    z-index: 1;
    display: flex;
    background-image: url(${PackemTextLogo});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 185px;
    height: 40px;
    margin: 20px 0px;
  }
`;
