/* eslint-disable dot-notation */
import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useRef,
} from 'react';
import Barcode from 'react-jsbarcode';
import { useReactToPrint } from 'react-to-print';

import Button from 'components/button';
import Card from 'components/card';
import CardWithHeader from 'components/card/CardWithHeader';
import DatePickerInput from 'components/datepicker';
import Header from 'components/header';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import NewLotNumberModal from 'pages/shared/createnewlotnumber';
import PrintRollModal from 'pages/shared/printroll';
import { getItemLookup } from 'services/api/item/item.api';
import {
  editLicensePlateKnownAssignment,
  getLicensePlateKnownAssignment,
  getGenerateLicensePlateNo,
  getLicensePlateLookupByLicensePlateNo,
} from 'services/api/licensePlates/licensePlates.api';
import { getLotLookup } from 'services/api/lot/lot.api.';
import { getSupplierLookupByName } from 'services/api/suppliers/suppliers.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { NumberSchema } from 'yup';
import * as yup from 'yup';

import { ChildCareOutlined, SkipPrevious } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, TextField, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';

import { MainContainer, ContentContainer } from '../../styles';

interface AutoCompleteOptionType {
  id: number;
  label: string;
}

function NewLicensePlate() {
  const {
    loading,
    updateData,
    onOpenNewLotNumberModal,
    isPrintRollsModalOpen,
  } = useContext(GlobalContext);
  const { currentUser } = useContext(AuthContext);
  const defaultAutocompleteOption: AutoCompleteOptionType | null = {
    id: -1,
    label: '',
  };
  const [selectedItemForNewLot, setSelectedItemForNewLot] = useState<{
    id: number;
    index: number;
  }>();
  const [isReadonly, setIsReadonly] = useState(false);
  const [customerSKUs, setCustomerSKUs] = useState([]);
  const [customerSupliers, setCustomerSuppliers] = useState([]);
  const [licensePlates, setLicensePlates] = useState([]);
  const [itemLotNumbers, setItemLotNumbers] = useState<
    {
      lotNos: any[];
    }[]
  >([]);
  const [nextInSequence, setNextInSequence] = useState('');
  const [printQty, setPrintQty] = useState(1);

  const date = new Date();
  const hours =
    date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
  const currentTime = `${hours}:${minutes}`;

  const [error, setError] = useState('');
  const initialFormErrorsState: any = {
    arrivalDate: '',
    arrivalTime: '',
    supplierId: '',
    licensePlateId: '',
    referenceNo: '',
    skus: [
      {
        itemId: '',
        cases: '',
        eaCase: '',
        totalQty: '',
        totalWeight: '',
      },
    ],
  };

  const initialState: any = {
    customerId: '',
    licensePlateNo: '',
    arrivalDate: date,
    arrivalTime: currentTime,
    supplierId: {
      id: defaultAutocompleteOption.id,
      label: defaultAutocompleteOption.label,
    },
    licensePlateId: {
      id: defaultAutocompleteOption.id,
      label: defaultAutocompleteOption.label,
    },
    referenceNo: 0,
    skus: [
      {
        id: 1,
        licensePlateItemId: '',
        itemId: {
          id: defaultAutocompleteOption.id,
          label: defaultAutocompleteOption.label,
        },
        lotId: {
          id: defaultAutocompleteOption.id,
          label: defaultAutocompleteOption.label,
        },
        cases: 0,
        eaCase: 0,
        totalQty: 0,
        totalWeight: 0,
      },
    ],
  };

  const [form, setForm] = useState<any>(initialState);
  const [formErrors, setFormErrors] = useState<any>(initialFormErrorsState);
  const rollRef = useRef(null);
  const print = useReactToPrint({
    content: () => rollRef.current,
  });

  const itemSelectionOptions = Array.isArray(customerSKUs)
    ? customerSKUs.map((i, index) => ({
        id: i.itemId,
        label: i.sku,
      }))
    : [{ id: 0, label: 'New Item' }];

  const supplierSelectionOptions = Array.isArray(customerSupliers)
    ? customerSupliers.map((v, index) => ({
        id: v.supplierId,
        label: v.name,
      }))
    : [{ id: 0, label: 'New Supplier' }];

  const licensePlateSelectionOptions = Array.isArray(licensePlates)
    ? licensePlates.map((l, index) => ({
        id: l.licensePlateId,
        label: l.licensePlateNo,
      }))
    : [{ id: 0, label: 'New License Plate' }];

  const generateLotSelectionOptions = (index) => {
    const lotNumberSelectionOptions = Array.isArray(
      itemLotNumbers[index]?.lotNos,
    )
      ? itemLotNumbers[index]?.lotNos.map((i) => ({
          id: i.lotId,
          label: i.lotNo,
        }))
      : [];
    return lotNumberSelectionOptions;
  };

  const onLoadLotNumbers = async (itemId, index) => {
    try {
      const lotNumbersFromApi = await getLotLookup(itemId);

      itemLotNumbers[index] = {
        lotNos: lotNumbersFromApi,
      };

      setItemLotNumbers([...itemLotNumbers]);
      return lotNumbersFromApi;
    } catch (err) {
      return err;
    }
  };

  const onForm = (key, text) => {
    setForm(() => ({
      ...form,
      [key]: text,
    }));
  };

  const schema = yup.object().shape({
    customerId: yup.number().required('Required.'),
    arrivalDate: yup.string().required('Required'),
    arrivalTime: yup.string().required('Required'),
    supplierId: yup
      .object()
      .shape({ id: yup.string().nullable(), label: yup.string() })
      .test(
        'empty-check',
        'A supplier must be selected',
        (supplier) => !!supplier.label,
      )
      .typeError('Required.'),
    licensePlateId: yup
      .object()
      .shape({ id: yup.string().nullable(), label: yup.string() })
      .test(
        'empty-check',
        'A license plate must be selected',
        (licensePlate) => !!licensePlate.label,
      )
      .typeError('Required.'),
    referenceNo: yup
      .number()
      .test(
        'Is positive?',
        'The number must be greater than 0',
        (value) => value > 0,
      )
      .required('Required.'),
    skus: yup
      .array()
      .of(
        yup.object().shape({
          itemId: yup
            .object()
            .shape({ id: yup.string().nullable(), label: yup.string() })
            .test(
              'empty-check',
              'An item SKU must be selected',
              (item) => !!item.label,
            )
            .typeError('Required.'),
          cases: yup
            .number()
            .test(
              'Is positive?',
              'The number must be greater than 0',
              (value) => value > 0,
            )
            .required('Required.'),
          eaCase: yup
            .number()
            .test(
              'Is positive?',
              'The number must be greater than 0',
              (value) => value > 0,
            )
            .required('Required.'),
          totalQty: yup
            .number()
            .test(
              'Is positive?',
              'The number must be greater than 0',
              (value) => value > 0,
            )
            .required('Required.'),
          totalWeight: yup
            .number()
            .test(
              'Is positive?',
              'The number must be greater than 0',
              (value) => value > 0,
            )
            .required('Required.'),
        }),
      )
      .required('Required'),
  });

  const loadSkusForForm = async (licensePlateData) => {
    let idCounter = 0;
    let lotNumbers = [];

    return Promise.all(
      licensePlateData.products.map(async (product) => {
        if (product.lotId) {
          const lotNumbersFromApi = await onLoadLotNumbers(
            product.itemId,
            idCounter,
          );
          lotNumbers = lotNumbersFromApi.map((x) => ({
            id: x.lotId,
            label: x.lotNo,
          }));
        }

        idCounter += 1;
        return {
          id: idCounter,
          licensePlateItemId: product.licensePlateItemId,
          itemId: itemSelectionOptions.find((x) => x.id === product.itemId),
          lotId: lotNumbers.find((x) => x.id === product.lotId),
          cases: product.cases,
          eaCase: product.eaCase,
          totalQty: product.totalQty,
          totalWeight: product.totalWeight,
        };
      }),
    );
  };

  const onLoadLicensePlateData = async (licensePlateId) => {
    const licensePlateDataFromApi = await getLicensePlateKnownAssignment(
      currentUser.Claim_CustomerId,
      licensePlateId,
    );

    const indexOfT = licensePlateDataFromApi.arrivalDateTime.indexOf('T');
    const firstColon = licensePlateDataFromApi.arrivalDateTime.indexOf(':');
    const secondColon = licensePlateDataFromApi.arrivalDateTime.indexOf(
      ':',
      firstColon + 1,
    );
    const apiDate = licensePlateDataFromApi.arrivalDateTime.slice(0, indexOfT);
    const apiTime = licensePlateDataFromApi.arrivalDateTime.slice(
      indexOfT + 1,
      secondColon,
    );

    form.licensePlateId = licensePlateDataFromApi.licensePlateId;
    form.referenceNo = licensePlateDataFromApi.products[0].referenceNo;
    form.supplierId = supplierSelectionOptions.find(
      (x) => x.id === licensePlateDataFromApi.products[0].supplierId,
    );
    form.arrivalDate = new Date(apiDate);
    form.arrivalTime = apiTime;

    form.skus = await loadSkusForForm(licensePlateDataFromApi);

    setForm({ ...form });
  };

  const dynamicInputHandler = (
    key: string,
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    form.skus[index] = { ...form.skus[index], [key]: event.target.value };

    setForm(() => ({
      ...form,
    }));
  };

  const dynamicAutoCompleteInputHandler = (
    key: string,
    value: AutoCompleteOptionType,
    index: number,
  ) => {
    if (key === 'itemId') {
      if (value) {
        onLoadLotNumbers(value.id, index);
      }
    }

    form.skus[index] = {
      ...form.skus[index],
      [key]: value || {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
    };

    setForm(() => ({
      ...form,
    }));
  };

  const autoCompleteInputHandler = async (
    key: string,
    value: AutoCompleteOptionType,
  ) => {
    if (key === 'licensePlateId') {
      await onLoadLicensePlateData(value.id);
    }

    setForm(() => ({
      ...form,
      [key]:
        value === null
          ? {
              id: defaultAutocompleteOption.id,
              label: defaultAutocompleteOption.label,
            }
          : value,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    onForm(key, event.target.value);
  };

  const dateInputHandler = (newValue: Date | null) => {
    onForm('arrivalDate', newValue);
  };

  const timeInputHandler = (newValue: any) => {
    onForm('arrivalTime', newValue.target.value);
  };

  const onLoadResources = async () => {
    try {
      const itemSKUsFromApi = await getItemLookup(
        currentUser.Claim_CustomerId,
        '',
      );
      const suppliersFromApi = await getSupplierLookupByName(
        currentUser.Claim_CustomerId,
        '',
      );
      const nextInSequenceFromApi = await getGenerateLicensePlateNo(
        currentUser.Claim_CustomerId,
      );

      const licensePlatesFromApi = await getLicensePlateLookupByLicensePlateNo(
        currentUser.Claim_CustomerId,
        '',
      );

      setCustomerSKUs(itemSKUsFromApi);
      setCustomerSuppliers(suppliersFromApi);
      setNextInSequence(nextInSequenceFromApi);
      setLicensePlates(licensePlatesFromApi);

      return true;
    } catch (err) {
      return err;
    }
  };

  const handleAddNewSKU = () => {
    form.skus.push({
      id: form.skus.length,
      itemId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      supplierId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      lotId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      referenceNo: 0,
      cases: 0,
      eaCase: 0,
      totalQty: 0,
      totalWeight: 0,
    });
    setForm({ ...form });
  };

  const handleNewLotModalOpen = (item) => {
    setSelectedItemForNewLot(item);
    onOpenNewLotNumberModal();
  };

  const handleUpdateLotNumberDropdown = () => {
    onLoadLotNumbers(selectedItemForNewLot.id, selectedItemForNewLot.index);
  };

  const handleUpdatePrintQty = (event: ChangeEvent<HTMLInputElement>) => {
    setPrintQty(parseInt(event.target.value, 10));
  };

  const onSaveLicensePlate = async () => {
    form.customerId = currentUser.Claim_CustomerId;

    schema
      .validate(form, {
        abortEarly: false,
      })
      .then(async () => {
        try {
          const newForm: any = {};
          form.arrivalDate.setHours(
            parseInt(form.arrivalTime.split(':')[0], 10),
            parseInt(form.arrivalTime.split(':')[1], 10),
          );
          newForm.customerId = form.customerId;
          newForm.arrivalDateTime = form.arrivalDate;
          newForm.licensePlateId = form.licensePlateId.id.toString();
          newForm.licensePlateNo = nextInSequence;
          newForm.products = form.skus.map((sku) => ({
            licensePlateItemId: sku.licensePlateItemId,
            itemId: sku.itemId.id.toString(),
            lotId: sku.lotId.id === -1 ? null : sku.lotId.id.toString(),
            supplierId: form.supplierId.id.toString(),
            referenceNo: form.referenceNo,
            cases: sku.cases,
            eaCase: sku.eaCase,
            totalQty: sku.totalQty,
            totalWeight: sku.totalWeight,
          }));

          const newLicensePlate = await editLicensePlateKnownAssignment(
            newForm,
          );

          snackActions.success(`Successfully edited license plate.`);
          setIsReadonly(true);
        } catch (err: any) {
          setError(err);
          setFormErrors(initialFormErrorsState);
          snackActions.error(`${err}`);
        }
      })
      .catch((err) => {
        const errorsFound = err.inner.reduce((acc, curr) => {
          if (
            !acc[curr.path] &&
            !curr.path.includes('[') &&
            !curr.path.includes(']')
          )
            acc[curr.path] = curr.message;
          if (curr.path.includes('[') && curr.path.includes(']')) {
            const index = parseInt(
              curr.path.slice(
                curr.path.indexOf('[') + 1,
                curr.path.lastIndexOf(']'),
              ),
              10,
            );
            const nestedField = curr.path.substring(curr.path.indexOf('.') + 1);

            if (!acc['skus']) acc['skus'] = [];
            if (!acc['skus'][index]) acc['skus'][index] = {};

            switch (nestedField) {
              case 'itemId':
                acc['skus'][index].itemId = curr.message;
                break;
              case 'supplierId':
                acc['skus'][index].supplierId = curr.message;
                break;
              case 'referenceNo':
                acc['skus'][index].referenceNo = curr.message;
                break;
              case 'cases':
                acc['skus'][index].cases = curr.message;
                break;
              case 'eaCase':
                acc['skus'][index].eaCase = curr.message;
                break;
              case 'totalQty':
                acc['skus'][index].totalQty = curr.message;
                break;
              case 'totalWeight':
                acc['skus'][index].totalWeight = curr.message;
                break;
              default:
                console.log('nothing');
            }
          }
          return acc;
        }, {});
        setFormErrors(errorsFound);
        setError('');
      });
  };

  useEffect(() => {
    onLoadResources();
  }, [loading, updateData]);

  return (
    <MainContainer>
      {isPrintRollsModalOpen && <PrintRollModal />}
      <NewLotNumberModal
        itemId={selectedItemForNewLot}
        callBack={handleUpdateLotNumberDropdown}
      />
      <Box
        sx={{
          display: 'none',
        }}
      >
        <Box ref={rollRef} sx={{ width: '100%' }}>
          {form.skus.map((sku) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              ref={rollRef}
            >
              <Typography variant="subtitle1">
                {`Reference No: ${sku.referenceNo}`}
              </Typography>
              <Barcode
                value={nextInSequence.length > 0 ? nextInSequence : 'DEFAULT'}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Header />
      <ContentContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: '12px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '66%' }}>
            <CardWithHeader
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
              header="License Plate"
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  gap: '24px',
                  pt: '24px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: '24px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      gap: '24px',
                      alignItems: 'start',
                    }}
                  >
                    <Box sx={{ display: 'flex', width: '25%' }}>
                      <Autocomplete
                        options={licensePlateSelectionOptions}
                        size="medium"
                        value={form.licensePlateId}
                        sx={{ width: '100%' }}
                        disabled={isReadonly}
                        onChange={(
                          event: any,
                          value: AutoCompleteOptionType | null,
                        ) => {
                          autoCompleteInputHandler('licensePlateId', value);
                        }}
                        renderInput={(params) =>
                          formErrors.licensePlateId ? (
                            <TextField
                              sx={{
                                '& .MuiInputBase-root': {
                                  background: 'white',
                                },
                              }}
                              {...params}
                              label="License Plate"
                              error
                              helperText={formErrors.licensePlateId}
                              disabled={isReadonly}
                            />
                          ) : (
                            <TextField
                              sx={{
                                '& .MuiInputBase-root': {
                                  background: 'white',
                                },
                              }}
                              {...params}
                              label="License Plate"
                              disabled={isReadonly}
                            />
                          )
                        }
                      />
                    </Box>
                    <Box sx={{ display: 'flex', width: '25%' }}>
                      <Input
                        sx={{ width: '100%', background: 'white' }}
                        placeholder="Print Qty"
                        type="number"
                        min={0}
                        size="large"
                        value={printQty}
                        onChange={(value) => handleUpdatePrintQty(value)}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', width: '25%' }}>
                      <Autocomplete
                        options={supplierSelectionOptions}
                        size="medium"
                        value={form.supplierId}
                        sx={{ width: '100%' }}
                        disabled={isReadonly}
                        onChange={(
                          event: any,
                          value: AutoCompleteOptionType | null,
                        ) => {
                          autoCompleteInputHandler('supplierId', value);
                        }}
                        renderInput={(params) =>
                          formErrors.supplierId ? (
                            <TextField
                              sx={{
                                '& .MuiInputBase-root': {
                                  background: 'white',
                                },
                              }}
                              {...params}
                              label="supplier"
                              error
                              helperText={formErrors.supplierId}
                              disabled={isReadonly}
                            />
                          ) : (
                            <TextField
                              sx={{
                                '& .MuiInputBase-root': {
                                  background: 'white',
                                },
                              }}
                              {...params}
                              label="Supplier"
                              disabled={isReadonly}
                            />
                          )
                        }
                      />
                    </Box>
                    <Box sx={{ display: 'flex', width: '25%' }}>
                      <Input
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-root': {
                            background: 'white',
                          },
                        }}
                        placeholder="Reference Number"
                        type="number"
                        min={0}
                        size="large"
                        value={form.referenceNo}
                        error={formErrors.referenceNo}
                        disabled={isReadonly}
                        onChange={(value) => inputHandler('referenceNo', value)}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'end',
                      gap: '12px',
                    }}
                  >
                    <Box sx={{ display: 'flex', width: '15%' }}>
                      <Button
                        sx={{
                          background: 'red',
                          width: '100%',
                        }}
                        variant="contained"
                        size="large"
                        disabled={isReadonly}
                        onClick={onSaveLicensePlate}
                      >
                        Save
                      </Button>
                    </Box>
                    <Box sx={{ display: 'flex', width: '15%' }}>
                      <Button
                        sx={{
                          background: 'red',
                          width: '100%',
                        }}
                        variant="text"
                        size="large"
                        // disabled={!isReadonly}
                        onClick={() => print()}
                      >
                        PRINT
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Accordion sx={{ flexGrow: 1 }} defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Product
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                      }}
                    >
                      {form.skus.map((sku, index) => (
                        <Box
                          key={sku.id}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '24px',
                            pb: '24px',
                            borderBottom: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              gap: '24px',
                              alignItems: 'start',
                            }}
                          >
                            <Box sx={{ display: 'flex', width: '33%' }}>
                              <Autocomplete
                                options={itemSelectionOptions}
                                getOptionLabel={(
                                  option: AutoCompleteOptionType,
                                ) => `${option.label}` || ''}
                                size="medium"
                                value={sku.itemId}
                                sx={{ width: '100%' }}
                                disabled={isReadonly}
                                onChange={(
                                  event: any,
                                  value: AutoCompleteOptionType | null,
                                ) => {
                                  dynamicAutoCompleteInputHandler(
                                    'itemId',
                                    value,
                                    index,
                                  );
                                }}
                                renderInput={(params) =>
                                  formErrors.skus[index]?.itemId ? (
                                    <TextField
                                      sx={{
                                        '& .MuiInputBase-root': {
                                          background: 'white',
                                        },
                                      }}
                                      {...params}
                                      label="Item SKU"
                                      error
                                      helperText={
                                        formErrors.skus[index]?.itemId
                                      }
                                      disabled={isReadonly}
                                    />
                                  ) : (
                                    <TextField
                                      sx={{
                                        '& .MuiInputBase-root': {
                                          background: 'white',
                                        },
                                      }}
                                      {...params}
                                      label="Item SKU"
                                      disabled={isReadonly}
                                    />
                                  )
                                }
                              />
                            </Box>
                            <Box sx={{ display: 'flex', width: '33%' }}>
                              <Autocomplete
                                options={generateLotSelectionOptions(index)}
                                getOptionLabel={(
                                  option: AutoCompleteOptionType,
                                ) => `${option.label}` || ''}
                                size="medium"
                                value={sku.lotId}
                                sx={{ width: '100%' }}
                                disabled={isReadonly}
                                onChange={(
                                  event: any,
                                  value: AutoCompleteOptionType | null,
                                ) => {
                                  dynamicAutoCompleteInputHandler(
                                    'lotId',
                                    value,
                                    index,
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    sx={{ background: 'white' }}
                                    {...params}
                                    label="Lot Number"
                                    disabled={isReadonly}
                                  />
                                )}
                                noOptionsText={
                                  <Button
                                    variant="text"
                                    size="small"
                                    onMouseDown={() => {
                                      handleNewLotModalOpen({
                                        id: form.skus[index]?.itemId?.id,
                                        index,
                                      });
                                    }}
                                  >
                                    + Add Lot Number
                                  </Button>
                                }
                              />
                            </Box>
                            <Box sx={{ display: 'flex', width: '33%' }}>
                              <Input
                                sx={{
                                  width: '100%',
                                  '& .MuiInputBase-root': {
                                    background: 'white',
                                  },
                                }}
                                placeholder="Cases"
                                type="number"
                                min={0}
                                size="large"
                                value={sku.cases}
                                error={formErrors.skus[index]?.cases}
                                disabled={isReadonly}
                                onChange={(value) =>
                                  dynamicInputHandler('cases', value, index)
                                }
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              gap: '24px',
                              alignItems: 'start',
                            }}
                          >
                            <Box sx={{ display: 'flex', width: '33%' }}>
                              <Input
                                sx={{
                                  width: '100%',
                                  '& .MuiInputBase-root': {
                                    background: 'white',
                                  },
                                }}
                                placeholder="Ea/Case"
                                type="number"
                                min={0}
                                size="large"
                                value={sku.eaCase}
                                error={formErrors.skus[index]?.eaCase}
                                disabled={isReadonly}
                                onChange={(value) =>
                                  dynamicInputHandler('eaCase', value, index)
                                }
                              />
                            </Box>
                            <Box sx={{ display: 'flex', width: '33%' }}>
                              <Input
                                sx={{
                                  width: '100%',
                                  '& .MuiInputBase-root': {
                                    background: 'white',
                                  },
                                }}
                                placeholder="Calculated"
                                type="number"
                                min={0}
                                size="large"
                                value={sku.cases * sku.eaCase}
                                disabled={isReadonly}
                              />
                            </Box>
                            <Box sx={{ display: 'flex', width: '33%' }}>
                              <Input
                                sx={{
                                  width: '100%',
                                  '& .MuiInputBase-root': {
                                    background: 'white',
                                  },
                                }}
                                placeholder="Total Qty"
                                type="number"
                                min={0}
                                size="large"
                                value={sku.totalQty}
                                error={formErrors.skus[index]?.totalQty}
                                disabled={isReadonly}
                                onChange={(value) =>
                                  dynamicInputHandler('totalQty', value, index)
                                }
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              alignItems: 'start',
                              gap: '24px',
                            }}
                          >
                            <Box sx={{ display: 'flex', width: '33%' }}>
                              <Input
                                sx={{
                                  width: '100%',
                                  '& .MuiInputBase-root': {
                                    background: 'white',
                                  },
                                }}
                                placeholder="Total Wgt"
                                type="number"
                                min={0}
                                size="large"
                                value={sku.totalWeight}
                                error={formErrors.skus[index]?.totalWeight}
                                disabled={isReadonly}
                                onChange={(value) =>
                                  dynamicInputHandler(
                                    'totalWeight',
                                    value,
                                    index,
                                  )
                                }
                              />
                            </Box>
                            <Box sx={{ display: 'flex', width: '33%' }} />
                            <Box sx={{ display: 'flex', width: '33%' }} />
                          </Box>
                        </Box>
                      ))}
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Box sx={{ display: 'flex' }}>
                          <Button
                            sx={{
                              background: 'red',
                              width: '100%',
                              justifyContent: 'end',
                            }}
                            variant="text"
                            size="large"
                            disabled={isReadonly}
                            onClick={handleAddNewSKU}
                          >
                            Add Another SKU
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion sx={{ flexGrow: 1 }} defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Arrival
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '24px',
                      }}
                    >
                      <Box sx={{ display: 'flex', width: '100%', gap: '24px' }}>
                        <Box sx={{ display: 'flex', width: '33%' }}>
                          {formErrors.arrivalTime ? (
                            <TextField
                              id="time"
                              type="time"
                              value={form.arrivalTime}
                              disabled={isReadonly}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-root': {
                                  background: 'white',
                                },
                              }}
                              error
                              helperText={formErrors.arrivalTime}
                              onChange={timeInputHandler}
                            />
                          ) : (
                            <TextField
                              id="time"
                              type="time"
                              value={form.arrivalTime}
                              disabled={isReadonly}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-root': {
                                  background: 'white',
                                },
                              }}
                              onChange={timeInputHandler}
                            />
                          )}
                        </Box>
                        <Box sx={{ display: 'flex', width: '33%' }}>
                          <DatePickerInput
                            sx={{ width: '100%', background: 'white' }}
                            inputFormat="MM/dd/yyyy"
                            value={form.arrivalDate}
                            onChange={dateInputHandler}
                            renderInput={(params) =>
                              formErrors.arrivalDate ? (
                                <TextField
                                  sx={{
                                    width: '100%',
                                    '& .MuiInputBase-root': {
                                      background: 'white',
                                    },
                                  }}
                                  {...params}
                                  size="medium"
                                  error
                                  helperText={formErrors.arrivalDate}
                                  disabled={isReadonly}
                                />
                              ) : (
                                <TextField
                                  sx={{
                                    width: '100%',
                                    '& .MuiInputBase-root': {
                                      background: 'white',
                                    },
                                  }}
                                  {...params}
                                  error={false}
                                  size="medium"
                                  disabled={isReadonly}
                                />
                              )
                            }
                          />
                        </Box>
                        <Box sx={{ display: 'flex', width: '33%' }} />
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </CardWithHeader>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
            <CardWithHeader
              sx={{ display: 'flex', flexDirection: 'column' }}
              header="How it works"
            >
              <Box sx={{ display: 'flex', pr: '36px' }}>
                <Typography variant="body1" fontWeight="regular">
                  Create labels that contain known supplier or product
                  information can be generated using the Generator form. You may
                  also create license plates directly from purchase order line
                  items in the Purchase Order details page.
                </Typography>
              </Box>
            </CardWithHeader>
          </Box>
        </Box>
      </ContentContainer>
    </MainContainer>
  );
}

export default React.memo(NewLicensePlate);
