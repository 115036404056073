import React, { useEffect, useState } from 'react';

import { ILicensePlate, IPalletizeModal } from 'common/interfaces';
import Button from 'components/button';
import { decimalValidationHelper } from 'pages/inventory/item/details';

import {
  Modal,
  Typography,
  Grid as MUIGrid,
  Box,
  TextField,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;
    border: none;
    width: 100%;
    max-width: 300px;
    border-radius: 20px;
    outline: none;
  }
`;

const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 40px;
    gap: 16px;
  }
`;

const PalletizeEditModal = (props: IPalletizeModal) => {
  const { isOpen, licensePlate, onSave, onCancel } = props;

  const defaultLP: ILicensePlate = {
    licensePlateId: 0,
    licensePlateNo: '',
    heightInch: 0,
    widthInch: 0,
    lengthInch: 0,
    weightPound: 0,
  };

  const [LPData, setLPData] = useState<ILicensePlate>(defaultLP);
  const [palletizeValueFormError, setPalletizeValueFormError] = useState<any>(
    {},
  );

  const validatePalletize = (fieldValues = LPData) => {
    const temp: any = {};
    temp.heightInch =
      fieldValues.heightInch > 0 ? '' : 'This field is required';
    temp.widthInch = fieldValues.widthInch > 0 ? '' : 'This field is required';
    temp.lengthInch =
      fieldValues.lengthInch > 0 ? '' : 'This field is required';
    temp.weightPound =
      fieldValues.weightPound > 0 ? '' : 'This field is required';
    setPalletizeValueFormError({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleSavePalletize = async (e: any) => {
    e.preventDefault();

    if (!validatePalletize()) {
      return;
    }
    onSave(LPData);
  };

  const handleCancelPalletize = async (e: any) => {
    e.preventDefault();
    onCancel();
  };

  useEffect(() => {
    if (licensePlate) {
      setLPData((prev) => ({
        ...prev,
        licensePlateId: licensePlate.licensePlateId,
        licensePlateNo: licensePlate.licensePlateNo,
        heightInch: licensePlate.heightInch,
        widthInch: licensePlate.widthInch,
        lengthInch: licensePlate.lengthInch,
        weightPound: licensePlate.weightPound,
      }));
    }
  }, [licensePlate]);

  return (
    <Modal open={isOpen}>
      <MUIContainer>
        <MUIContent>
          <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: 2 }}>
            Palletize
          </Typography>
          <Box>
            <MUIGrid
              container
              direction="row"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <TextField
                  label="Height Inch"
                  name="heightInch"
                  value={LPData?.heightInch}
                  onChange={(e) => {
                    if (Number(e.target.value) < 0) {
                      e.target.value = '0';
                    }
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setLPData((prev) => ({
                      ...prev,
                      heightInch: Number(validateDecimal),
                    }));
                  }}
                  size="small"
                  type="number"
                  autoFocus
                  required
                  {...(palletizeValueFormError.heightInch && {
                    error: true,
                    helperText: palletizeValueFormError.heightInch,
                  })}
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  label="Width Inch"
                  name="widthInch"
                  value={LPData?.widthInch}
                  onChange={(e) => {
                    if (Number(e.target.value) < 0) {
                      e.target.value = '0';
                    }
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setLPData((prev) => ({
                      ...prev,
                      widthInch: Number(validateDecimal),
                    }));
                  }}
                  size="small"
                  type="number"
                  required
                  {...(palletizeValueFormError.widthInch && {
                    error: true,
                    helperText: palletizeValueFormError.widthInch,
                  })}
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  label="Length Inch"
                  name="lengthInch"
                  value={LPData?.lengthInch}
                  onChange={(e) => {
                    if (Number(e.target.value) < 0) {
                      e.target.value = '0';
                    }
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setLPData((prev) => ({
                      ...prev,
                      lengthInch: Number(validateDecimal),
                    }));
                  }}
                  size="small"
                  type="number"
                  required
                  {...(palletizeValueFormError.lengthInch && {
                    error: true,
                    helperText: palletizeValueFormError.lengthInch,
                  })}
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  label="Weight Pound"
                  name="weightPound"
                  value={LPData?.weightPound}
                  onChange={(e) => {
                    if (Number(e.target.value) < 0) {
                      e.target.value = '0';
                    }
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setLPData((prev) => ({
                      ...prev,
                      weightPound: Number(validateDecimal),
                    }));
                  }}
                  size="small"
                  type="number"
                  required
                  {...(palletizeValueFormError.weightPound && {
                    error: true,
                    helperText: palletizeValueFormError.weightPound,
                  })}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: '8px',
            }}
          >
            <Button
              onClick={handleCancelPalletize}
              variant="outlined"
              size="medium"
              sx={{ padding: 1, width: '100%' }}
            >
              Cancel
            </Button>

            <Button
              onClick={handleSavePalletize}
              variant="contained"
              size="medium"
              sx={{ padding: 1, width: '100%' }}
            >
              Save
            </Button>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
};

export default React.memo(PalletizeEditModal);
