import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getSupplierLookup = async (customerId, searchText) => {
  try {
    const response = await httpClient.get(
      `/Supplier/Lookup/${customerId}?searchText=${searchText}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getSupplierLookupByName = async (customerId, searchText) => {
  try {
    const response = await httpClient.get(
      `/Supplier/Lookup/Name/${customerId}?searchText=${searchText}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getSupplierLookupByWarehouseCustomerSearchByName = async (
  customerId,
  warehouseCustomerId,
  searchText,
) => {
  try {
    const response = await httpClient.get(
      `/Supplier/Lookup/Name/wc/${customerId}/${warehouseCustomerId}?searchText=${searchText}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getSkuBySupplier = async (warehouseCustomerId, supplierId) => {
  try {
    const response = await httpClient.get(
      `/item/supplier/${warehouseCustomerId}/${supplierId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const editSupplier = async (supplierId, editForm) => {
  try {
    const response = await httpClient.put(`/Supplier/${supplierId}`, editForm);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
