/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useRef, useState } from 'react';

import { IItemSKUPOInfo } from 'common/interfaces';
import { ItemResponseModel } from 'common/models/serialnumbers';
import {
  ItemRequestModel,
  ValidateSerialNumberBySKURequestModel,
  ValidateSerialNumberBySKURequestTypeEnum,
  ValidateSerialNumberBySKUResponseModel,
} from 'common/models/serialnumbers/ValidateSerialNumberBySKU';
import DatePickerInput from 'components/datepicker';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import moment from 'moment';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import {
  ItemUnitOfMeasureType,
  LotNoType,
} from 'pages/receiving/receipts/subpage';
import { AddLotNoModal } from 'pages/shared/addlotno';
import CostSheetModal from 'pages/shared/costsheetmodal';
import {
  MUIContainer,
  MUIContent,
  isNumberKey,
} from 'pages/shared/editreceiptmodal';
import {
  getItemById,
  getItemUnitOfMeasureDDL,
} from 'services/api/item/item.api';
import { createLotNo, getLotNo } from 'services/api/receipts/receipts-new.api';
import { getSkuLookupWithwarehouseCustomer } from 'services/api/salesorders/salesorders-new.api';
import { ValidateSerialNumberBySKU } from 'services/api/serialnumbers/serialnumbers.api';
import { getSkuBySupplier } from 'services/api/suppliers/suppliers.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { AutoCompleteTableHeader, AutoCompleteTablePaper } from 'styles/mui';

import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import {
  Box,
  InputAdornment,
  Modal,
  TextField,
  Typography,
  Grid as MUIGrid,
  Popper,
  Button,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useTheme, styled as MuiStyled } from '@mui/material/styles';

import { usePurchaseOrderContext } from '../context';

type AutocompleteBase = {
  label?: string;
  value?: number;
  description?: string;
  unitCost?: string;
  receiveId?: number;
  shippingCost?: string;
  itemCostSheets?: any[];
};

type AutocompleteBaseValueAndLabel = {
  label?: string;
  value?: number;
};

type BinTypes = AutocompleteBaseValueAndLabel;

const optionBintype: readonly BinTypes[] = [
  { value: 1, label: 'No' },
  { value: 2, label: 'Yes' },
];

const AutoCompletePopper = (props) => (
  <Popper
    {...props}
    sx={{
      minWidth: '352px',
      width: 'max-content !important',
      height: '250px',
    }}
    placement="bottom-start"
  />
);

const AutoCompletePaper = ({ children }) => (
  <AutoCompleteTablePaper sx={{ maxHeight: '250px' }}>
    <AutoCompleteTableHeader>
      <div className="th tw-140">SKU</div>
      <div className="th tw-140">Description</div>
      {/* <div className="th">Available Qty</div> */}
    </AutoCompleteTableHeader>
    {children}
  </AutoCompleteTablePaper>
);

export default React.memo(() => {
  const { currentUser } = useContext(AuthContext);
  const {
    addLotNoModalData,
    setAddLotNoModalData,
    isPOLineItemModalOpen,
    onClosePOLineItemModal,
    setIsAddLotNoModalOpen,
    isAddLotNoUpdateOptions,
    setCostSheetGridModalOpen,
    setIsAddLotNoUpdateOptions,
    isPOPackingListLineItemModalOpen,
    onClosePOPackingListLineItemModal,
  } = useContext(GlobalContext);

  const {
    purchaseOrderDetails,
    purchaseOrderLineItemModal,
    purchaseOrderPackingListLineItemModal,
    uomValue,
    optionsUOM,
    addPurchaseOrderLineItem,
    addPurchaseOrderPackingListLineItem,
    setPurchaseOrderLineItemModal,
    setPurchaseOrderPackingListLineItemModal,
    handleOnCreatePurchaseOrder,
    setUomValue,
    setOptionsUOM,
  } = usePurchaseOrderContext();

  const [optionBinTypesBody] = useState(optionBintype);

  const [optionCustomerSku, setOptionCustomerSku] = useState<
    AutocompleteBase[] | null | []
  >([]);

  const [optionCustomerSkuPackingList, setOptionCustomerSkuPackingList] =
    useState<AutocompleteBase[] | null | []>([]);

  const [purchaseOrderLineItemFormError, setPurchaseOrderLineItemFormError] =
    useState<any>({});

  const [
    purchaseOrderPackageListLineItemFormError,
    setPurchaseOrderPackageListLineItemFormError,
  ] = useState<any>({});

  const [itemInfo, setItemInfo] = useState<IItemSKUPOInfo>({
    itemId: 0,
    label: '',
    qtyPO: 0,
    qtyPacking: 0,
    qtyBalance: 0,
  });

  const itemRef = useRef(null);
  const qtyRef = useRef(null);

  // LOT NO UPDATES -------------------------------------------------------
  const [itemIsTrackableByLot, setItemIsTrackableByLot] = useState(false);

  const onLoadGetItemUnitOfMeasureDDLOnChange = async (itemId) => {
    try {
      return await getItemUnitOfMeasureDDL(itemId);
    } catch (err) {
      return err;
    }
  };

  const onLoadSkuBySuppliers = async (warehouseCustomerId, supplierId) => {
    try {
      const suppliersFromApi = await getSkuBySupplier(
        warehouseCustomerId,
        supplierId,
      );
      return suppliersFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadSkuLookupWithwarehouseCustomer = async (warehouseCustomerId) => {
    try {
      const skuLookupFromApi = await getSkuLookupWithwarehouseCustomer(
        currentUser.Claim_CustomerId,
        warehouseCustomerId,
      );

      return skuLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadGetLotNoOnChange = async (itemId) => {
    try {
      return await getLotNo(itemId);
    } catch (err) {
      return err;
    }
  };

  const isSkuProvided = () => {
    const temp: any = {};
    const isValidSku = !(
      purchaseOrderLineItemModal.sku === undefined ||
      purchaseOrderLineItemModal.sku === null ||
      purchaseOrderLineItemModal.sku === ''
    );

    temp.sku = !isValidSku ? 'This field is required' : '';
    setPurchaseOrderLineItemFormError({
      ...temp,
    });

    return isValidSku;
  };

  const validateOnlick = (fieldValues = purchaseOrderLineItemModal) => {
    const temp: any = {};

    temp.sku =
      fieldValues.item === null || fieldValues.item.value === -1
        ? 'This field is required'
        : '';
    temp.qty = fieldValues.qty ? '' : 'This field is required';

    setPurchaseOrderLineItemFormError({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const validateOnlickPackingList = (
    fieldValues = purchaseOrderPackingListLineItemModal,
  ) => {
    const temp: any = {};

    temp.sku =
      fieldValues?.item === undefined ||
      fieldValues?.item === null ||
      fieldValues?.item.value === -1
        ? 'This field is required'
        : '';

    temp.qty = fieldValues.qty ? '' : 'This field is required';

    if (Number(fieldValues.qty) <= 0) {
      temp.qty = ' Zero quantity is not allowed';
    }

    if (fieldValues.binItemType.value === 2) {
      temp.palletCount = fieldValues.palletCount
        ? ''
        : 'This field is required';
    }

    if (
      fieldValues?.item &&
      itemInfo.label === fieldValues?.item.label &&
      itemInfo.qtyPO > 0 &&
      Number(fieldValues.qty) > 0
    ) {
      if (itemInfo.qtyBalance <= 0) {
        temp.qty = `Reached max packing qty for ${fieldValues?.item.label}`;
      } else if (fieldValues.binItemType.value === 2) {
        if (
          Number(fieldValues.qty) * Number(fieldValues.palletCount) >
          itemInfo.qtyBalance
        ) {
          temp.qty = `Selected quantity for this item exceeds the quantity in PO`;
          snackActions.error(
            `The quantity allowed for ${fieldValues?.item.label} is only equal or less than to. ${itemInfo.qtyBalance}. Please check total quantity of PO line items and packing line items.`,
          );
        }
      } else if (fieldValues.binItemType.value === 1) {
        if (Number(fieldValues.qty) > itemInfo.qtyBalance) {
          temp.qty = `Selected quantity for this item exceeds the quantity in PO`;
          snackActions.error(
            `The quantity allowed for ${fieldValues?.item.label} is only equal or less than to. ${itemInfo.qtyBalance}. Please check total quantity of PO line items and packing line items.`,
          );
        }
      }
    }

    if (!uomValue?.isPrimaryUOM) {
      temp.totalCases = fieldValues.totalCases ? '' : 'This field is required';
      temp.eachesPerCase = fieldValues.eachesPerCase
        ? ''
        : 'This field is required';
    }

    if (fieldValues.binItemType.value === 2) {
      temp.palletCount = fieldValues.palletCount
        ? ''
        : 'This field is required';
    }

    if (
      fieldValues.isSerialNo &&
      fieldValues.serialNo !== undefined &&
      fieldValues.serialNo !== '' &&
      fieldValues.qty !== undefined
    ) {
      const qty = Number(fieldValues.qty);
      const serialNos = fieldValues.serialNo.split(',');

      if (qty !== serialNos.length) {
        temp.qty = 'Qty value should match the number of serial numbers.';
      }
    }

    setPurchaseOrderPackageListLineItemFormError({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleClosePOLineItemModal = () => {
    setPurchaseOrderLineItemModal({});
    setPurchaseOrderLineItemFormError({});
    onClosePOLineItemModal();
  };

  const handleSavePOLineItemModal = async (e: any) => {
    e.preventDefault();
    if (!validateOnlick()) {
      snackActions.error('Please complete required fields');
      return;
    }
    addPurchaseOrderLineItem();
    handleClosePOLineItemModal();
  };

  const handleClosePOPackingListLineItemModal = () => {
    setPurchaseOrderPackingListLineItemModal({});
    setPurchaseOrderPackageListLineItemFormError({});
    setUomValue(null);
    onClosePOPackingListLineItemModal();
    setItemIsTrackableByLot(false);
  };

  const handleSavePOPackingListLineItemModal = async (e: any) => {
    e.preventDefault();

    if (!validateOnlickPackingList()) {
      return;
    }

    const itemSerialNumbers: ItemRequestModel[] = [];
    let requestType: ValidateSerialNumberBySKURequestTypeEnum = null;

    if (
      purchaseOrderPackingListLineItemModal.packingListLineItemId ===
        undefined ||
      purchaseOrderPackingListLineItemModal.packingListLineItemId === null
    ) {
      requestType = ValidateSerialNumberBySKURequestTypeEnum.New;

      itemSerialNumbers.push({
        id: null,
        itemId: Number(purchaseOrderPackingListLineItemModal.itemId),
        isSerialNo: purchaseOrderPackingListLineItemModal.isSerialNo,
        serialNo: purchaseOrderPackingListLineItemModal.serialNo,
        isSelected: true,
      });
    } else {
      requestType =
        ValidateSerialNumberBySKURequestTypeEnum.PackingListLineItemId;

      itemSerialNumbers.push({
        id: purchaseOrderPackingListLineItemModal.packingListLineItemId,
        itemId: Number(purchaseOrderPackingListLineItemModal.itemId),
        isSerialNo: purchaseOrderPackingListLineItemModal.isSerialNo,
        serialNo: purchaseOrderPackingListLineItemModal.serialNo,
        isSelected: true,
      });
    }

    const payloadSerialNumber: ValidateSerialNumberBySKURequestModel = {
      requestType,
      items: itemSerialNumbers,
    };
    ValidateSerialNumberBySKU(payloadSerialNumber).then(
      (responseSerialNumber: ValidateSerialNumberBySKUResponseModel) => {
        if (responseSerialNumber.duplicates.length > 0) {
          const duplicate = responseSerialNumber.duplicates[0];
          snackActions.error(
            `${duplicate.sku} have a duplicate serial no. ${duplicate.serialNo}`,
          );
        } else {
          addPurchaseOrderPackingListLineItem();
          handleOnCreatePurchaseOrder(e);
          handleClosePOPackingListLineItemModal();
        }
      },
    );
  };

  const handleShiptToValueChanged = (event: any, newValue: any) => {
    if (newValue !== null) {
      setPurchaseOrderLineItemModal((e) => ({
        ...e,
        item: {
          ...e.item,
          value: newValue.value,
          label: newValue.label,
        },
        description: newValue.description,
        unitCost: newValue.unitCost === undefined ? '' : newValue.unitCost,
        shippingCost:
          newValue.shippingCost === undefined ? '' : newValue.shippingCost,
        itemId: newValue.value,
        sku: newValue.label,
        itemCostSheets: newValue.itemCostSheets,
        primaryInventoryUOMStr: newValue.primaryInventoryUOMStr,
      }));
    } else {
      setPurchaseOrderLineItemModal((e) => ({
        ...e,
        item: null,
        description: null,
        itemId: null,
        sku: null,
        unitCost: null,
        shippingCost: null,
        itemCostSheets: null,
        primaryInventoryUOMStr: null,
      }));
    }

    qtyRef?.current?.focus();
  };

  const handleItemIdQtyLimit = (item: IItemSKUPOInfo) => {
    const poLineItems = [...purchaseOrderDetails.poLineItem.lineItems];
    const poItemQtyTotal =
      poLineItems
        .filter((i) => Number(i.itemId) === item.itemId)
        .map((o) => Number(o.qty))
        .reduce((a, b) => a + b) ?? 0;

    const poPackedItems = [...purchaseOrderDetails.poPackingList];
    const packItemQtyTotal =
      poPackedItems
        .map((p) => {
          if (p.packingLineItems && p.packingLineItems.length > 0) {
            const mob = p.packingLineItems.filter(
              (i: any) => Number(i.itemId) === item.itemId,
            );
            if (mob.length <= 0) return 0;
            return p.packingLineItems
              .filter((i: any) => Number(i.itemId) === item.itemId)
              .map(
                (o) =>
                  Number(o.qty) * (o.palletCount ? Number(o.palletCount) : 1),
              )
              .reduce((a, b) => a + b);
          }
          return 0;
        })
        .reduce((a, b) => a + b) ?? 0;

    setItemInfo((data) => ({
      ...data,
      itemId: item.itemId,
      label: item.label,
      qtyPO: poItemQtyTotal,
      qtyPacking: packItemQtyTotal,
      qtyBalance: poItemQtyTotal - packItemQtyTotal,
    }));
  };

  const handlePackageItemSkuChanged = (event: any, newValue: any) => {
    if (newValue !== null) {
      setPurchaseOrderPackingListLineItemModal((e) => ({
        ...e,
        item: {
          ...e.item,
          value: newValue.value,
          label: newValue.label,
        },
        description: newValue.description,
        itemId: newValue.itemId,
        sku: newValue.label,
        receiveId: newValue.receiveId,
      }));
      handleItemIdQtyLimit({
        itemId: newValue.itemId,
        label: newValue.label,
        qtyPO: 0,
        qtyPacking: 0,
        qtyBalance: 0,
      });
    } else {
      setPurchaseOrderPackingListLineItemModal((e) => ({
        ...e,
        item: null,
        description: null,
        itemId: null,
        sku: null,
        receiveId: null,
      }));
    }
  };

  const handleFilterOption = (options: any[], filter) => {
    const result = options.filter(
      (r) =>
        r.label.toLowerCase().includes(filter.inputValue.toLowerCase()) ||
        r.description.toLowerCase().includes(filter.inputValue.toLowerCase()),
    );
    return result;
  };

  useEffect(() => {
    if (purchaseOrderDetails.poDescription.knownSuppliers.value !== -1) {
      const optCustomerSupplier = onLoadSkuBySuppliers(
        purchaseOrderDetails.poDescription.customer !== null &&
          purchaseOrderDetails.poDescription.customer.value !== -1
          ? purchaseOrderDetails.poDescription.customer.value
          : 0,
        purchaseOrderDetails.poDescription.knownSuppliers.value,
      );
      optCustomerSupplier.then((ddl) => {
        setOptionCustomerSku(
          ddl.map((c: any) => ({
            label: c.sku,
            value: c.itemId,
            description: c.description,
            unitCost: c.unitCost,
            shippingCost: c.shippingCost,
            itemCostSheets: c.itemCostSheets,
            primaryInventoryUOM: c.primaryInventoryUOM,
            primaryInventoryUOMStr: c.primaryInventoryUOMStr,
            // availableQty: c.availableQty,
          })),
        );
      });
    } else if (purchaseOrderDetails.poDescription.customer.value !== -1) {
      const optCustomerSupplier = onLoadSkuLookupWithwarehouseCustomer(
        purchaseOrderDetails.poDescription.customer !== null &&
          purchaseOrderDetails.poDescription.customer.value !== -1
          ? purchaseOrderDetails.poDescription.customer.value
          : 0,
      );
      optCustomerSupplier.then((ddl) => {
        setOptionCustomerSku(
          ddl.map((c: any) => ({
            label: c.sku,
            value: c.itemId,
            description: c.description,
            unitCost: c.unitCost,
            shippingCost: c.shippingCost,
            itemCostSheets: c.itemCostSheets,
            primaryInventoryUOM: c.primaryInventoryUOM,
            primaryInventoryUOMStr: c.primaryInventoryUOMStr,
            // availableQty: c.availableQty,
          })),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    purchaseOrderDetails.poDescription.knownSuppliers,
    purchaseOrderDetails.poDescription.customer,
    purchaseOrderDetails.poLineItem.lineItems,
    itemInfo,
  ]);

  useEffect(() => {
    setOptionCustomerSkuPackingList(
      purchaseOrderDetails.poLineItem.lineItems.map((c) => ({
        label: c.sku,
        value: c.receiveId,
        description: c.description,
        itemId: c.itemId,
        receiveId: c.receiveId,
        itemCostSheets: c.itemCostSheets,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPOPackingListLineItemModalOpen]);

  useEffect(() => {
    if (
      purchaseOrderPackingListLineItemModal.itemId &&
      purchaseOrderPackingListLineItemModal.itemId !== null
    ) {
      onLoadGetItemUnitOfMeasureDDLOnChange(
        purchaseOrderPackingListLineItemModal.itemId,
      ).then((x) => {
        setOptionsUOM([]);
        x.map((uom) =>
          setOptionsUOM((prev) => [
            ...prev,
            {
              value: uom.value,
              label: uom.text,
              uomAbbr: uom.uomAbbr,
              isPrimaryUOM: uom.isPrimaryUOM,
              qty: uom.qty,
              primaryUOM: uom.primaryUOM,
              primaryUOMStr: uom.primaryUOMStr,
              primaryUOMAbbr: uom.primaryUOMAbbr,
              uomType: uom.uomType,
            },
          ]),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrderPackingListLineItemModal.itemId]);

  useEffect(() => {
    if (optionsUOM.length > 0) {
      if (
        purchaseOrderPackingListLineItemModal.itemUnitOfMeasureId !== null &&
        purchaseOrderPackingListLineItemModal.itemUnitOfMeasureId !== undefined
      ) {
        const uom = optionsUOM.find(
          (u) =>
            u.value ===
            purchaseOrderPackingListLineItemModal.itemUnitOfMeasureId,
        );
        if (uom !== undefined) {
          setUomValue((prev) => ({
            ...prev,
            value: uom.value,
            label: uom.label,
            uomAbbr: uom.uomAbbr,
            isPrimaryUOM: uom.isPrimaryUOM,
            qty: uom.qty,
            primaryUOM: uom.primaryUOM,
            primaryUOMAbbr: uom.primaryUOMAbbr,
          }));
        }
      } else {
        const uom = optionsUOM.find((x) => x.isPrimaryUOM);

        if (uom !== undefined) {
          setUomValue((prev) => ({
            ...prev,
            value: uom.value,
            label: uom.label,
            uomAbbr: uom.uomAbbr,
            isPrimaryUOM: uom.isPrimaryUOM,
            qty: uom.qty,
            primaryUOM: uom.primaryUOM,
            primaryUOMAbbr: uom.primaryUOMAbbr,
          }));
          setPurchaseOrderPackingListLineItemModal((prevState) => ({
            ...prevState,
            primaryUOM: uom.primaryUOM,
            eachesPerCase: uom.qty ? uom.qty.toString() : '',
          }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsUOM]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

  const [optionLotNo, setOptionLotNo] = useState<LotNoType[]>([]);
  useEffect(() => {
    if (isPOLineItemModalOpen || isPOPackingListLineItemModalOpen) {
      if (purchaseOrderPackingListLineItemModal?.itemId) {
        onLoadGetLotNoOnChange(
          purchaseOrderPackingListLineItemModal?.itemId,
        ).then((x) => {
          setOptionLotNo([]);
          x.map((lot) =>
            setOptionLotNo((prev) => [
              ...prev,
              {
                value: lot.lotId,
                label: lot.lotNo,
                lotId: lot.lotId,
                lotCreationDate: lot.creationDate,
                lotExpirationDate: lot.expirationDate,
              },
            ]),
          );
          if (purchaseOrderPackingListLineItemModal?.lotNoValue?.lotId) {
            const result = x.find(
              (o: LotNoType) =>
                o.lotId ===
                purchaseOrderPackingListLineItemModal?.lotNoValue?.lotId,
            );
            if (result !== undefined) {
              setPurchaseOrderPackingListLineItemModal((prev) => ({
                ...prev,
                lotNoValue: {
                  ...prev.lotNoValue,
                  value: result?.lotId,
                  label: result?.lotNo,
                  lotId: result?.lotId,
                  lotCreationDate: result?.creationDate,
                  lotExpirationDate: result?.expirationDate,
                },
              }));
            } else {
              setPurchaseOrderPackingListLineItemModal((prev) => ({
                ...prev,
                lotNoValue: null,
              }));
            }
          } else {
            setPurchaseOrderPackingListLineItemModal((prev) => ({
              ...prev,
              lotNoValue: null,
            }));
          }
        });
        getItemById(purchaseOrderPackingListLineItemModal?.itemId).then(
          (res) => {
            setPurchaseOrderPackingListLineItemModal((e) => ({
              ...e,
              isSerialNo: res.isSerialNo,
            }));
            setItemIsTrackableByLot(res.isTrackableByLot);
          },
        );
      }
    }
  }, [purchaseOrderPackingListLineItemModal?.itemId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isPOLineItemModalOpen) {
        itemRef?.current?.focus();
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [isPOLineItemModalOpen]);

  useEffect(() => {
    if (isAddLotNoUpdateOptions) {
      onLoadGetLotNoOnChange(
        purchaseOrderPackingListLineItemModal?.itemId,
      ).then((x) => {
        setOptionLotNo([]);
        x.map((lot) =>
          setOptionLotNo((prev) => [
            ...prev,
            {
              value: lot.lotId,
              label: lot.lotNo,
              lotCreationDate: lot.creationDate,
              lotExpirationDate: lot.expirationDate,
            },
          ]),
        );
      });

      setPurchaseOrderPackingListLineItemModal((prev) => ({
        ...prev,
        lotNoValue: addLotNoModalData,
      }));
    }
  }, [isAddLotNoUpdateOptions]);

  return (
    <>
      <Modal open={isPOLineItemModalOpen}>
        <MUIContainer sx={{ width: '25% !important' }}>
          <Typography
            className="modalTextHeader"
            variant="h6"
            fontWeight="bold"
          >
            PO Item
          </Typography>
          <MUIContent>
            <Box autoComplete="off" component="form" noValidate>
              <MUIGrid
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={12}>
                  <Autocomplete
                    disableClearable
                    aria-required
                    onChange={handleShiptToValueChanged}
                    id="controllable-states"
                    options={optionCustomerSku}
                    getOptionLabel={(option: any) => option.label}
                    value={purchaseOrderLineItemModal.item}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    filterOptions={handleFilterOption}
                    renderOption={(props, option) => (
                      <li {...props} key={props.id}>
                        <Typography color="text.primary" className="td tw-140">
                          {option.label}
                        </Typography>
                        <Typography color="text.primary" className="td tw-140">
                          {option.description}
                        </Typography>
                        {/* <Typography color="text.primary" className="td tw-140">
                          {option.availableQty}
                        </Typography> */}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                        }}
                        autoComplete="off"
                        id="outlined-required"
                        label="SKU"
                        size="small"
                        sx={{ width: '100%' }}
                        {...(purchaseOrderLineItemFormError.sku && {
                          error: true,
                          helperText: purchaseOrderLineItemFormError.sku,
                        })}
                        inputRef={itemRef}
                      />
                    )}
                    PopperComponent={AutoCompletePopper}
                    PaperComponent={AutoCompletePaper}
                  />
                </MUIGrid>
                <MUIGrid item xs={12}>
                  <Input
                    placeholder="Description"
                    size="small"
                    value={purchaseOrderLineItemModal.description || ''}
                    sx={{
                      width: '100%',
                      backgroundColor: '#ffffff !important',
                      marginBottom: '16px',
                    }}
                    disabled
                  />
                </MUIGrid>
              </MUIGrid>
              <MUIGrid
                container
                columnSpacing={{ xs: 0, sm: 1, md: 2 }}
                sx={{ display: 'flex', alignItems: 'stretch' }}
              >
                <MUIGrid item xs={6}>
                  <TextField
                    required
                    type="number"
                    placeholder="Qty (Primary UOM)"
                    size="small"
                    value={purchaseOrderLineItemModal.qty}
                    inputRef={qtyRef}
                    onChange={(e) => {
                      if (e.target.value && Number(e.target.value) < 0) {
                        e.target.value = '0';
                      }
                      const validateDecimal = decimalValidationHelper(
                        e.target.value,
                      );
                      setPurchaseOrderLineItemModal((prev) => ({
                        ...prev,
                        qty: validateDecimal,
                      }));
                    }}
                    sx={{
                      width: '100%',
                      backgroundColor: '#ffffff !important',
                      marginBottom: '16px',
                    }}
                    {...(purchaseOrderLineItemFormError.qty && {
                      error: true,
                      helperText: purchaseOrderLineItemFormError.qty,
                    })}
                  />
                </MUIGrid>
                {purchaseOrderLineItemModal?.primaryInventoryUOMStr && (
                  <MUIGrid item xs={6}>
                    <Typography variant="h6">
                      {purchaseOrderLineItemModal?.primaryInventoryUOMStr}
                    </Typography>
                  </MUIGrid>
                )}
              </MUIGrid>
              <MUIGrid container columnSpacing={{ xs: 0, sm: 1, md: 2 }}>
                <MUIGrid item xs={9}>
                  <Input
                    type="number"
                    placeholder="Unit Cost"
                    size="small"
                    value={purchaseOrderLineItemModal.unitCost}
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      const validateDecimal = decimalValidationHelper(
                        e.target.value,
                      );
                      setPurchaseOrderLineItemModal((prev) => ({
                        ...prev,
                        unitCost: validateDecimal,
                      }));
                    }}
                    sx={{
                      width: '100%',
                      backgroundColor: '#ffffff !important',
                      marginBottom: '16px',
                    }}
                    {...(purchaseOrderLineItemFormError.unitCost && {
                      error: true,
                      helperText: purchaseOrderLineItemFormError.unitCost,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </MUIGrid>
                <MUIGrid item xs={3}>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ padding: 1, width: '100%' }}
                    onClick={() => {
                      if (isSkuProvided()) {
                        setCostSheetGridModalOpen(true);
                      }
                    }}
                  >
                    ...
                  </Button>
                </MUIGrid>
              </MUIGrid>

              <Input
                type="number"
                placeholder="Shipping Cost"
                size="small"
                value={purchaseOrderLineItemModal.shippingCost}
                onChange={(e) => {
                  if (e.target.value < 0) {
                    e.target.value = 0;
                  }
                  const validateDecimal = decimalValidationHelper(
                    e.target.value,
                  );
                  setPurchaseOrderLineItemModal((prev) => ({
                    ...prev,
                    shippingCost: validateDecimal,
                  }));
                }}
                sx={{
                  width: '100%',
                  backgroundColor: '#ffffff !important',
                }}
                {...(purchaseOrderLineItemFormError.shippingCost && {
                  error: true,
                  helperText: purchaseOrderLineItemFormError.shippingCost,
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Box>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={8}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                  onClick={() => handleClosePOLineItemModal()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => handleSavePOLineItemModal(e)}
                >
                  Save
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
      <Modal open={isPOPackingListLineItemModalOpen}>
        <MUIContainer>
          <Typography
            className="modalTextHeader"
            variant="h6"
            fontWeight="bold"
          >
            Packing List Item
          </Typography>
          <MUIContent sx={{ padding: '5px 20px !important' }}>
            <MUIGrid
              container
              spacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Autocomplete
                  disableClearable
                  aria-required
                  onChange={handlePackageItemSkuChanged}
                  id="controllable-states"
                  options={optionCustomerSkuPackingList}
                  getOptionLabel={(option: any) => option.label}
                  value={purchaseOrderPackingListLineItemModal.item}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      id="outlined-required"
                      label="SKU"
                      size="small"
                      sx={{ width: '100%', marginBottom: '16px' }}
                      {...(purchaseOrderPackageListLineItemFormError.sku && {
                        error: true,
                        helperText:
                          purchaseOrderPackageListLineItemFormError.sku,
                      })}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Input
                  placeholder="Description"
                  size="small"
                  value={
                    purchaseOrderPackingListLineItemModal.description || ''
                  }
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                    marginBottom: '16px',
                  }}
                  disabled
                />
              </MUIGrid>
              <MUIGrid
                item
                xs={
                  purchaseOrderPackingListLineItemModal?.binItemType?.value ===
                  2
                    ? 2
                    : 4
                }
              >
                <Autocomplete
                  disableClearable
                  aria-required
                  onChange={(event: any, newValue: any) => {
                    if (newValue !== null) {
                      setPurchaseOrderPackingListLineItemModal((e) => ({
                        ...e,
                        binItemType: {
                          ...e.item,
                          value: newValue.value,
                          label: newValue.label,
                        },
                        palletCount: newValue.value === 1 && null,
                      }));
                    } else {
                      setPurchaseOrderPackingListLineItemModal((e) => ({
                        ...e,
                        binItemType: null,
                        palletCount: '',
                      }));
                    }
                  }}
                  id="controllable-states"
                  options={optionBinTypesBody}
                  getOptionLabel={(option: any) => option.label}
                  value={
                    purchaseOrderPackingListLineItemModal.binItemType || null
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Is Pallet"
                      size="small"
                      sx={{ marginBottom: '16px' }}
                      {...(purchaseOrderPackageListLineItemFormError.binItemType && {
                        error: true,
                        helperText:
                          purchaseOrderPackageListLineItemFormError.binItemType,
                      })}
                    />
                  )}
                />
              </MUIGrid>

              {purchaseOrderPackingListLineItemModal?.binItemType?.value ===
                2 && (
                <MUIGrid item xs={2}>
                  <Input
                    type="number"
                    placeholder="No. of Pallets"
                    size="small"
                    value={purchaseOrderPackingListLineItemModal?.palletCount}
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      const validateDecimal = decimalValidationHelper(
                        e.target.value,
                      );
                      setPurchaseOrderPackingListLineItemModal((prev) => ({
                        ...prev,
                        palletCount: validateDecimal,
                      }));
                    }}
                    sx={{
                      width: '100%',
                      backgroundColor: '#ffffff !important',
                      marginBottom: '16px',
                    }}
                    required
                    {...(purchaseOrderPackageListLineItemFormError?.palletCount && {
                      error: true,
                      helperText:
                        purchaseOrderPackageListLineItemFormError?.palletCount,
                    })}
                  />
                </MUIGrid>
              )}
              <MUIGrid item xs={4}>
                <Autocomplete
                  disableClearable
                  onChange={(event: any, newValue: ItemUnitOfMeasureType) => {
                    if (newValue !== null) {
                      setUomValue(newValue);

                      if (newValue.isPrimaryUOM) {
                        setPurchaseOrderPackingListLineItemModal(
                          (prevState) => ({
                            ...prevState,
                            primaryUOM: newValue.value,
                            primaryUOMAbbr: newValue.uomAbbr,
                            itemUnitOfMeasureId: null,
                            otherUOMStr: '',
                            otherUOMAbbr: '',
                            otherUOMCount: null,
                            eachesPerCase: '',
                            totalCases: '',
                            qty: '',
                            qtyOfPrimaryOUMPerLP: '',
                          }),
                        );
                      } else {
                        setPurchaseOrderPackingListLineItemModal(
                          (prevState) => ({
                            ...prevState,
                            primaryUOM: newValue.primaryUOM,
                            primaryUOMAbbr: newValue.primaryUOMAbbr,
                            itemUnitOfMeasureId: newValue.value,
                            otherUOMStr: newValue.label,
                            otherUOMAbbr: newValue.uomAbbr,
                            otherUOMCount: newValue.qty,
                            eachesPerCase: newValue.qty
                              ? newValue.qty.toString()
                              : '0',
                            totalCases: '',
                            qty: '',
                            qtyOfPrimaryOUMPerLP: '',
                          }),
                        );
                      }
                    } else {
                      setUomValue(null);
                      setPurchaseOrderPackingListLineItemModal((prevState) => ({
                        ...prevState,
                        primaryUOM: null,
                        primaryUOMAbbr: '',
                        itemUnitOfMeasureId: null,
                        otherUOMStr: '',
                        otherUOMAbbr: '',
                        otherUOMCount: null,
                        totalCases: '',
                        qty: '',
                        qtyOfPrimaryOUMPerLP: '',
                      }));
                    }
                  }}
                  id="controllable-states"
                  options={optionsUOM}
                  getOptionLabel={(option: ItemUnitOfMeasureType) =>
                    option.label
                  }
                  value={uomValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option: ItemUnitOfMeasureType) => (
                    <li
                      {...props}
                      key={option?.value}
                      data-uomabbr={option?.uomAbbr}
                      data-isprimaryuom={option?.isPrimaryUOM}
                      data-qty={option?.qty}
                      data-primaryuom={option?.primaryUOM}
                      data-primaryuomstr={option?.primaryUOMStr}
                      data-primaryuomabbr={option?.primaryUOMAbbr}
                    >
                      {option?.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      required
                      id="outlined-required"
                      label="Units Of Measure"
                      size="small"
                      sx={{ width: '100%', marginBottom: '16px' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <TextField
                  disabled={uomValue?.isPrimaryUOM}
                  required={uomValue?.isPrimaryUOM === false}
                  onChange={(event: any) => {
                    if (isNumberKey(event)) {
                      setPurchaseOrderPackingListLineItemModal((prevState) => ({
                        ...prevState,
                        totalCases: event.target.value,
                        qty: (
                          event.target.value *
                          (prevState.eachesPerCase
                            ? Number(prevState.eachesPerCase)
                            : 0)
                        ).toString(),
                      }));
                    }
                  }}
                  value={
                    purchaseOrderPackingListLineItemModal?.totalCases || ''
                  }
                  id="outlined"
                  label="Total Cases"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                    marginBottom: '16px',
                  }}
                  {...(purchaseOrderPackageListLineItemFormError.totalCases && {
                    error: true,
                    helperText:
                      purchaseOrderPackageListLineItemFormError.totalCases,
                  })}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <TextField
                  disabled={uomValue?.isPrimaryUOM}
                  required={uomValue?.isPrimaryUOM === false}
                  onChange={(event: any) => {
                    if (isNumberKey(event)) {
                      setPurchaseOrderPackingListLineItemModal((prevState) => ({
                        ...prevState,
                        eachesPerCase: event.target.value,
                        qty: (
                          event.target.value *
                          (prevState.totalCases
                            ? Number(prevState.totalCases)
                            : 0)
                        ).toString(),
                      }));
                    }
                  }}
                  value={
                    purchaseOrderPackingListLineItemModal?.eachesPerCase || ''
                  }
                  id="outlined"
                  label="Eaches Per Case"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                    marginBottom: '16px',
                  }}
                  {...(purchaseOrderPackageListLineItemFormError.eachesPerCase && {
                    error: true,
                    helperText:
                      purchaseOrderPackageListLineItemFormError.eachesPerCase,
                  })}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Input
                  disabled={uomValue?.isPrimaryUOM === false}
                  required
                  type="number"
                  placeholder="Qty (Primary UOM)"
                  size="small"
                  value={purchaseOrderPackingListLineItemModal.qty}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setPurchaseOrderPackingListLineItemModal((prev) => ({
                      ...prev,
                      qty: validateDecimal,
                    }));
                  }}
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  {...(purchaseOrderPackageListLineItemFormError.qty && {
                    error: true,
                    helperText: purchaseOrderPackageListLineItemFormError.qty,
                  })}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Input
                  type="number"
                  placeholder="Weight"
                  size="small"
                  value={purchaseOrderPackingListLineItemModal.weight}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setPurchaseOrderPackingListLineItemModal((prev) => ({
                      ...prev,
                      weight: validateDecimal,
                    }));
                  }}
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                />
              </MUIGrid>
              {purchaseOrderPackingListLineItemModal.isSerialNo && (
                <MUIGrid item xs={4}>
                  <Input
                    placeholder="Serial No. (List multiple separated by comma)"
                    size="small"
                    value={purchaseOrderPackingListLineItemModal.serialNo}
                    onChange={(event: any) => {
                      setPurchaseOrderPackingListLineItemModal((prevState) => ({
                        ...prevState,
                        serialNo: event.target.value,
                      }));
                    }}
                    sx={{
                      width: '100%',
                      backgroundColor: '#ffffff !important',
                    }}
                  />
                </MUIGrid>
              )}
              {itemIsTrackableByLot && (
                <>
                  <MUIGrid item xs={12}>
                    <MUIGrid
                      container
                      direction="row"
                      justifyContent="left"
                      alignItems="center"
                      spacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ marginTop: '0px' }}
                    >
                      <MUIGrid item xs={2.3}>
                        <Autocomplete
                          filterOptions={filterOptions}
                          value={
                            purchaseOrderPackingListLineItemModal?.lotNoValue
                              ?.label !== undefined
                              ? purchaseOrderPackingListLineItemModal?.lotNoValue ||
                                null
                              : null
                          }
                          onChange={(event: any, newValue: LotNoType) => {
                            if (newValue !== null) {
                              setPurchaseOrderPackingListLineItemModal(
                                (prev) => ({
                                  ...prev,
                                  lotNoValue: newValue,
                                }),
                              );
                            } else {
                              setPurchaseOrderPackingListLineItemModal(
                                (prev) => ({
                                  ...prev,
                                  lotNoValue: null,
                                }),
                              );
                            }
                          }}
                          id="controllable-states"
                          options={optionLotNo}
                          getOptionLabel={(option: LotNoType) => option.label}
                          defaultValue={optionLotNo?.find(
                            (o: LotNoType) =>
                              o.label ===
                              purchaseOrderPackingListLineItemModal.lotNoValue
                                ?.label,
                          )}
                          renderOption={(props, option: LotNoType) => (
                            <li {...props} key={option.value}>
                              <Box display="flex">
                                <Box display="flex" flexDirection="column">
                                  <Typography color="text.primary">
                                    {option.label}
                                  </Typography>
                                  <Typography
                                    color="text.secondary"
                                    variant="body2"
                                  >
                                    {option.lotExpirationDate &&
                                      moment(option.lotExpirationDate).format(
                                        'MM/DD/YY',
                                      )}
                                  </Typography>
                                </Box>
                              </Box>
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                              }}
                              onChange={(e) => {
                                setPurchaseOrderPackingListLineItemModal(
                                  (prev) => ({
                                    ...prev,
                                    lotNoValue: {
                                      ...prev.lotNoValue,
                                      label: e.target.value,
                                    },
                                  }),
                                );
                              }}
                              id="outlined"
                              label="Lot No."
                              size="small"
                              sx={{ width: '100%' }}
                            />
                          )}
                        />
                      </MUIGrid>
                      <MUIGrid
                        item
                        md={1.7}
                        sx={{ paddingLeft: '10px !important' }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setAddLotNoModalData((prevState) => ({
                              ...prevState,
                              value: null,
                              label: '',
                              lotId: null,
                              lotCreationDate: null,
                              lotExpirationDate: null,
                            }));
                            setIsAddLotNoModalOpen(true);
                            setIsAddLotNoUpdateOptions(false);
                          }}
                          sx={{
                            padding: '8px !important',
                            width: '100%',
                            textTransform: 'capitalize',
                          }}
                        >
                          + New LOT NO
                        </Button>
                      </MUIGrid>
                    </MUIGrid>
                  </MUIGrid>
                  <MUIGrid item xs={12}>
                    <MUIGrid
                      container
                      direction="row"
                      justifyContent="left"
                      alignItems="center"
                      spacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ marginTop: '0px' }}
                    >
                      <MUIGrid item xs={4}>
                        <DatePickerInput
                          sx={{ width: '100%', background: 'white' }}
                          inputFormat="MM/dd/yyyy"
                          value={
                            purchaseOrderPackingListLineItemModal?.lotNoValue
                              ?.lotExpirationDate !== undefined
                              ? purchaseOrderPackingListLineItemModal
                                  ?.lotNoValue?.lotExpirationDate || null
                              : null
                          }
                          label="Lot Expiration Date"
                          onChange={(e) => {
                            if (e !== null && e !== 'Invalid date') {
                              setPurchaseOrderPackingListLineItemModal(
                                (prevState) => ({
                                  ...prevState,
                                  lotNoValue: {
                                    ...prevState?.lotNoValue,
                                    lotExpirationDate: e,
                                  },
                                }),
                              );
                            } else {
                              setPurchaseOrderPackingListLineItemModal(
                                (prevState) => ({
                                  ...prevState,
                                  lotNoValue: {
                                    ...prevState?.lotNoValue,
                                    lotExpirationDate: null,
                                  },
                                }),
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-root': {
                                  background: 'white',
                                },
                              }}
                              error={false}
                              size="small"
                            />
                          )}
                        />
                      </MUIGrid>
                    </MUIGrid>
                  </MUIGrid>
                </>
              )}
              <MUIGrid item xs={8}>
                <TextField
                  id="outlined-multiline-notes"
                  label="Notes"
                  multiline
                  rows={2}
                  defaultValue=""
                  sx={{ width: '100%', marginTop: '15px' }}
                  onChange={(event: any) => {
                    setPurchaseOrderPackingListLineItemModal((prev: any) => ({
                      ...prev,
                      notes: event.target.value,
                    }));
                  }}
                  value={purchaseOrderPackingListLineItemModal?.notes}
                />
              </MUIGrid>
            </MUIGrid>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
              alignItems: 'center',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {purchaseOrderPackingListLineItemModal.qty ? (
              <MUIGrid
                item
                xs={8}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <ArchiveRoundedIcon
                  sx={{
                    width: '1.5em !important',
                    height: '1.5em !important',
                    color: 'rgb(75, 126, 254) !important',
                    margin: '10px',
                  }}
                />
                {purchaseOrderPackingListLineItemModal?.binItemType.value ===
                  2 && purchaseOrderPackingListLineItemModal?.palletCount ? (
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'rgb(75, 126, 254) !important',
                      fontWeight: '700',
                    }}
                  >
                    {purchaseOrderPackingListLineItemModal?.itemUnitOfMeasureId
                      ? `Receiving ${
                          purchaseOrderPackingListLineItemModal?.palletCount
                        } Pallets of 
                    ${purchaseOrderPackingListLineItemModal.totalCases}${
                          purchaseOrderPackingListLineItemModal?.otherUOMAbbr
                        }(${
                          purchaseOrderPackingListLineItemModal?.eachesPerCase
                        } ${
                          purchaseOrderPackingListLineItemModal?.primaryUOMAbbr
                        }) 
                    = Total ${
                      (purchaseOrderPackingListLineItemModal?.palletCount
                        ? Number(
                            purchaseOrderPackingListLineItemModal?.palletCount,
                          )
                        : 0) *
                      (purchaseOrderPackingListLineItemModal.qty
                        ? Number(purchaseOrderPackingListLineItemModal.qty)
                        : 0)
                    }(${
                          uomValue?.primaryUOMAbbr
                            ? uomValue?.primaryUOMAbbr
                            : uomValue?.uomAbbr
                        })  `
                      : `Receiving ${
                          purchaseOrderPackingListLineItemModal?.palletCount
                        } Pallets of ${
                          purchaseOrderPackingListLineItemModal.qty
                        }(${
                          uomValue?.primaryUOMAbbr
                            ? uomValue?.primaryUOMAbbr
                            : uomValue?.uomAbbr
                        }) = Total ${
                          (purchaseOrderPackingListLineItemModal?.palletCount
                            ? Number(
                                purchaseOrderPackingListLineItemModal?.palletCount,
                              )
                            : 0) *
                          (purchaseOrderPackingListLineItemModal.qty
                            ? Number(purchaseOrderPackingListLineItemModal.qty)
                            : 0)
                        }(${
                          uomValue?.primaryUOMAbbr
                            ? uomValue?.primaryUOMAbbr
                            : uomValue?.uomAbbr
                        })
                      `}
                  </Typography>
                ) : (
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'rgb(75, 126, 254) !important',
                      fontWeight: '700',
                    }}
                  >
                    {purchaseOrderPackingListLineItemModal?.itemUnitOfMeasureId
                      ? `Receiving ${
                          purchaseOrderPackingListLineItemModal.totalCases
                        } ${
                          purchaseOrderPackingListLineItemModal?.otherUOMAbbr
                        }(${
                          purchaseOrderPackingListLineItemModal?.eachesPerCase
                        } ${
                          purchaseOrderPackingListLineItemModal?.primaryUOMAbbr
                        }) = Total ${
                          purchaseOrderPackingListLineItemModal.qty
                        }(${
                          uomValue?.primaryUOMAbbr
                            ? uomValue?.primaryUOMAbbr
                            : uomValue?.uomAbbr
                        })`
                      : `Receiving ${
                          purchaseOrderPackingListLineItemModal.qty
                        }(${
                          uomValue?.primaryUOMAbbr
                            ? uomValue?.primaryUOMAbbr
                            : uomValue?.uomAbbr
                        })`}
                  </Typography>
                )}
              </MUIGrid>
            ) : (
              <MUIGrid
                item
                xs={8}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography> </Typography>
              </MUIGrid>
            )}

            <MUIGrid item xs={4}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                  onClick={() => handleClosePOPackingListLineItemModal()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => handleSavePOPackingListLineItemModal(e)}
                >
                  Save
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
          <AddLotNoModal
            paramId={purchaseOrderPackingListLineItemModal?.itemId}
          />
        </MUIContainer>
      </Modal>
      <CostSheetModal />
    </>
  );
});
