import {
  ConnectToPrintNodeRequestModel,
  ScaleDropdownListItem,
} from 'common/models/Integrations/printnode';
import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

// POST /api/printnode/connect
export const ConnectToPrintNode = async (
  model: ConnectToPrintNodeRequestModel,
) => {
  try {
    const response = await httpClient.post(`/printnode/connect`, model);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const DisconnectToPrintNode = async (
  model: ConnectToPrintNodeRequestModel,
) => {
  try {
    const response = await httpClient.post(`/printnode/disconnect`, model);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/printnode/computer/all
export const GetComputers = async () => {
  try {
    const response = await httpClient.get(`/printnode/computer/all`);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// GET /api/printnode/printer/all
export const GetPrinters = async () => {
  try {
    const response = await httpClient.get(`/printnode/printer/all`);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// GET /api/printnode/printer/bycomputerId/{computerId}
export const GetPrintersByComputerId = async (computerId: number) => {
  try {
    const response = await httpClient.get(
      `/printnode/printer/bycomputerId/${computerId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// GET /api/printnode/scale/{computerId}
export const GetScalesByComputerId = async (computerId: number) => {
  try {
    const response = await httpClient.get(`/printnode/scale/${computerId}`);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

// POST /api/printnode/scale
export const GetScale = async (model: ScaleDropdownListItem) => {
  try {
    const response = await httpClient.post(`/printnode/scale`, model);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/printnode/printersettings
export const GetPrinterSettings = async () => {
  try {
    const response = await httpClient.get(`/printnode/printersettings`);

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};
