/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getInventoryColumns } from 'components/navbarv2';
import { MenuBase } from 'components/styles';
import { AuthContext } from 'store/contexts/AuthContext';

import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import DownloadIcon from '@mui/icons-material/Download';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import PanoramaHorizontalIcon from '@mui/icons-material/PanoramaHorizontal';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

function DropDownMenu({ open, onClose, anchorEl }) {
  const navigate = useNavigate();
  const {
    logout,
    isSuperAdmin,
    isAdmin,
    isInventoryViewer,
    isOperator,
    isWarehouseCustomerAccount,
    isSupplierAccount,
    roleWarehouseCustomerPermission,
    roleSupplierPermission,
  } = useContext(AuthContext);

  const [seletedMenuItem, setSeletedMenuItem] = React.useState('');

  const onLogout = async () => {
    logout();
    navigate('/login');
  };

  const routings = () => {
    if (isSuperAdmin)
      return [
        {
          priority: 3,
          group: 'Admin',
          navigations: [
            {
              link: `/customer-management`,
              label: `Client Management`,
              icon: (v) => <AdminPanelSettingsOutlinedIcon sx={{ color: v }} />,
            },
            {
              link: `/login`,
              label: `Log out`,
              icon: (v) => <PowerSettingsNewOutlinedIcon sx={{ color: v }} />,
            },
          ],
        },
      ];
    if (isInventoryViewer)
      return [
        {
          priority: 0,
          group: 'Unlabeled',
          navigations: [
            {
              link: `/inventory`,
              label: `Inventory`,
              icon: (v) => <CenterFocusStrongIcon sx={{ color: v }} />,
            },
            {
              link: `/login`,
              label: `Log out`,
              icon: (v) => <PowerSettingsNewOutlinedIcon sx={{ color: v }} />,
            },
          ],
        },
      ];
    if (isOperator)
      return [
        {
          priority: 0,
          group: 'Inbound',
          navigations: [
            {
              link: `/license-plates`,
              label: `License Plates`,
              icon: (v) => <PanoramaHorizontalIcon sx={{ color: v }} />,
            },
            {
              link: `/login`,
              label: `Log out`,
              icon: (v) => <PowerSettingsNewOutlinedIcon sx={{ color: v }} />,
            },
          ],
        },
      ];
    if (isWarehouseCustomerAccount)
      return [
        {
          priority: 0,
          group: 'Unlabeled',
          navigations: [
            {
              link: `/warehouse-customer`,
              label: `Dashboard`,
              icon: (v) => <HomeIcon sx={{ color: v }} />,
              hide: false,
            },
            {
              link: `/warehouse-customer-orders`,
              label: `Orders`,
              icon: (v) => <ReceiptLongIcon sx={{ color: v }} />,
              hide:
                !roleWarehouseCustomerPermission.Order &&
                !roleWarehouseCustomerPermission.OrderViewOnly,
            },
            {
              link: `/warehouse-customer-receiving`,
              label: `POs`,
              icon: (v) => <DownloadIcon sx={{ color: v }} />,
              hide: !roleWarehouseCustomerPermission.PO,
            },
            {
              link: `/warehouse-customer-inventory`,
              label: `Inventory`,
              icon: (v) => <CenterFocusStrongIcon sx={{ color: v }} />,
              hide:
                !roleWarehouseCustomerPermission.Inventory &&
                !roleWarehouseCustomerPermission.InventoryDetail,
            },
            {
              link: `/warehouse-customer-integrations`,
              label: `Integrations`,
              icon: (v) => <IntegrationInstructionsIcon sx={{ color: v }} />,
              hide: false,
            },
            {
              link: `/warehouse-customer-reports`,
              label: `Reports`,
              icon: (v) => <DriveFileMoveOutlinedIcon sx={{ color: v }} />,
              hide: !roleWarehouseCustomerPermission.Reports,
            },
            {
              link: `/login`,
              label: `Log out`,
              icon: (v) => <PowerSettingsNewOutlinedIcon sx={{ color: v }} />,
            },
          ],
        },
      ];
    if (isSupplierAccount)
      return [
        {
          priority: 0,
          group: 'Unlabeled',
          navigations: [
            {
              link: `/supplier-purchase-order`,
              label: `POs`,
              icon: (v) => <DownloadIcon sx={{ color: v }} />,
              hide: !roleSupplierPermission.PO,
            },
            {
              link: `/login`,
              label: `Log out`,
              icon: (v) => <PowerSettingsNewOutlinedIcon sx={{ color: v }} />,
            },
          ],
        },
      ];
    if (!isSuperAdmin && isAdmin) return [...getInventoryColumns()];
    return [];
  };

  const menus = routings();

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    if (window.localStorage.getItem('seletedMenuItem') !== undefined) {
      setSeletedMenuItem(window.localStorage.getItem('seletedMenuItem'));
    }
  }, [seletedMenuItem]);

  return (
    <MenuBase
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{
        display: {
          xs: 'flex',
          sm: 'flex',
          md: 'none',
          lg: 'none',
          xl: 'none',
        },
      }}
    >
      <List sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            textAlign: 'center',
            width: '100%',
            padding: '0px 15px',
          }}
        >
          {menus.map((m) => (
            <div key={m.priority}>
              {m?.multiple ? (
                <Accordion
                  sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}
                  expanded={expanded === m.groupName}
                  onChange={handleChange(m?.groupName)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={m?.label}
                    id={m?.groupName}
                    className={!open && 'accordionSummaryAlign'}
                  >
                    <Typography
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        ml: 0.5,
                        mb: 0,
                        justifyContent: 'center',
                      }}
                    >
                      {m?.icon(
                        seletedMenuItem === m?.label ? 'white' : '#3375E0',
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        display: open ? 'block' : 'none',
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {open ? m?.groupName : ''}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ marginTop: -3, marginBottom: -1 }}>
                    {m.navigations.map(
                      (menu) =>
                        !menu.hide && (
                          <ListItemButton
                            key={menu.label}
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (menu.link !== '/login') {
                                navigate(menu.link);
                                setSeletedMenuItem(menu.label);
                                window.localStorage.setItem(
                                  'seletedMenuItem',
                                  menu.label,
                                );
                              } else {
                                onLogout();
                              }
                            }}
                            {...(open &&
                              seletedMenuItem === menu.label && {
                                className: 'menubarSelectedInsideParent',
                              })}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                              }}
                              hidden={menu.hide}
                              className={
                                !open && seletedMenuItem === menu.label
                                  ? 'menubarSelected'
                                  : ''
                              }
                            >
                              {menu.icon(
                                seletedMenuItem === menu.label
                                  ? 'white'
                                  : '#3375E0',
                              )}
                            </ListItemIcon>
                            <ListItemText>
                              <Link
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 500,
                                  color:
                                    seletedMenuItem === menu.label
                                      ? 'white'
                                      : 'black',
                                  opacity: open ? 1 : 0,
                                  textDecoration: 'none',
                                }}
                                onClick={() => {
                                  setSeletedMenuItem(menu.label);
                                  window.localStorage.setItem(
                                    'seletedMenuItem',
                                    menu.label,
                                  );
                                }}
                                href={menu.link}
                              >
                                {menu.label}
                              </Link>
                            </ListItemText>
                          </ListItemButton>
                        ),
                    )}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <ListItem disablePadding sx={{ display: 'block' }}>
                  {m.navigations.map(
                    (menu) =>
                      !menu.hide && (
                        <ListItemButton
                          key={menu.label}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            marginLeft:
                              seletedMenuItem === menu.label &&
                              open &&
                              '-9px !important',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (menu.link !== '/login') {
                              setSeletedMenuItem(menu.label);
                              window.localStorage.setItem(
                                'seletedMenuItem',
                                menu.label,
                              );
                              navigate(menu.link);
                            } else {
                              onLogout();
                            }
                          }}
                        >
                          <Box
                            {...(!open &&
                              seletedMenuItem === menu.label && {
                                className: 'menubarSelectedInsideParent',
                              })}
                            {...(open &&
                              seletedMenuItem === menu.label && {
                                className: 'menubarSelected',
                              })}
                            sx={{ display: 'flex', width: '100%' }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                              }}
                              hidden={menu.hide}
                            >
                              {menu.icon(
                                seletedMenuItem === menu.label
                                  ? 'white'
                                  : '#3375E0',
                              )}
                            </ListItemIcon>
                            {open && (
                              <ListItemText>
                                <Link
                                  sx={{
                                    fontSize: 15,
                                    fontWeight: 500,
                                    opacity: open ? 1 : 0,
                                    textDecoration: 'none',
                                    color:
                                      seletedMenuItem === menu.label
                                        ? 'white'
                                        : 'black',
                                  }}
                                  onClick={() => {
                                    setSeletedMenuItem(menu.label);
                                    window.localStorage.setItem(
                                      'seletedMenuItem',
                                      menu.label,
                                    );
                                  }}
                                  href={menu.link}
                                >
                                  {menu.label}
                                </Link>
                              </ListItemText>
                            )}
                          </Box>
                        </ListItemButton>
                      ),
                  )}
                </ListItem>
              )}
            </div>
          ))}
        </Box>
      </List>
    </MenuBase>
  );
}

export default React.memo(DropDownMenu);
