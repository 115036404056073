import { useState } from 'react';
import { ILocation3DModel } from 'common/interfaces';
import httpClient from 'infra/httpClient/index.js';

interface IHookLocationModel {
  loading: boolean;
  data: ILocation3DModel | undefined;
  getData: (customerId: number, customerFacilityId: number) => Promise<void>;
}

export const useLocationModel = (): IHookLocationModel => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ILocation3DModel | undefined>();

  const getData = async (customerId: number, customerFacilityId: number) => {
    setLoading(true);
    setData(undefined);
    const res = await httpClient.get(
      `/Bin/3DModel/${customerId}/${customerFacilityId}`,
    );
    if (res.status === 200) {
      setData(res.data);
    }
    setLoading(false);
  };

  return {
    loading,
    data,
    getData,
  };
};
