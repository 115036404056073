import React, { useContext } from 'react';

import Button from 'components/button';
import { ModalBox, ModalContent } from 'components/styles';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { textAlign } from '@mui/system';

interface ActivePOSeeMoreProps {
  listStringLink: any;
}
export default React.memo(({ listStringLink }: ActivePOSeeMoreProps) => {
  const { isActivePOSeeMoreModalOpen, onCloseActivePOSeeMoreModal } =
    useContext(GlobalContext);
  const theme = useTheme();

  const handleCloseImportModal = () => {
    onCloseActivePOSeeMoreModal();
  };

  return (
    <Modal
      open={isActivePOSeeMoreModalOpen}
      onClose={() => handleCloseImportModal()}
    >
      <ModalBox>
        <ModalContent>
          <Box>
            <ul
              style={{
                margin: '0px',
                columnCount: 2,
                display: 'flex',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              {listStringLink?.map((row, index) => (
                <li style={{ margin: '5px' }}>
                  <Link
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      color: '#303538',
                      textDecoration: 'none',
                    }}
                    href={`/receiving/purchase-orders?form=view&orderNo=${row.poName}&id=${row.orderId}`}
                    target="_blank"
                  >
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        color: [theme.palette.primary.main],
                      }}
                    >
                      {row.poName}
                    </Typography>
                  </Link>
                </li>
              ))}
            </ul>
          </Box>
          <Box sx={{ alignSelf: 'center' }}>
            <Button
              sx={{ width: '50%' }}
              variant="contained"
              size="small"
              component="label"
              onClick={() => handleCloseImportModal()}
            >
              Ok
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
