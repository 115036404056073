import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';

import { GlobalContext } from 'store/contexts/GlobalContext';

function KeyPressGlobalFilter() {
  const { onOpenLocationAndFacilityModal, quickLinks } =
    useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    const keyDownHandler = (event) => {
      let pressedKeys = '';
      if (event.ctrlKey) {
        pressedKeys += 'ctrl+';
      }
      if (event.altKey) {
        pressedKeys += 'alt+';
      }
      pressedKeys += event.key;

      if (pressedKeys === 'alt+c') {
        onOpenLocationAndFacilityModal(true);
      }

      if (pressedKeys === 'alt+r') {
        localStorage.setItem('storeWarehouseCustomer', null);
        navigate(0);
      }

      const matchedQuickLink = quickLinks.find(
        (x) => x.shortcutKeys === pressedKeys,
      );
      if (matchedQuickLink) {
        navigate(matchedQuickLink.navigationPagePath);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown ', keyDownHandler);
    };
  }, [quickLinks]);

  return <div />;
}

export default React.memo(KeyPressGlobalFilter);
