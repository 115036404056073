import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { PolarChart } from 'components/charts';
import { getDashboardOrderStatus } from 'services/api/dashboard/dashboard.api';
import { AuthContext } from 'store/contexts/AuthContext';

const ConstLabels = ['New', 'Open', 'Picked', 'Shipment', 'Complete'];
const ConstColors = [
  'rgba(255, 99, 132, 0.7)',
  'rgba(255, 159, 64, 0.7)',
  'rgba(255, 206, 86, 0.7)',
  'rgba(54, 162, 235, 0.7)',
  'rgba(75, 192, 192, 0.7)',
];
const ConstData = [0, 0, 0, 0, 0];

function DashboardOrderStatus() {
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const [statsLabels, setStatsLabels] = useState(ConstLabels);
  const [statsColors, setStatsColors] = useState(ConstColors);
  const [statsData, setStatsData] = useState<number[]>(ConstData);

  const fetchDataOrderStatus = async () => {
    try {
      const result = await getDashboardOrderStatus(
        Number(currentLocationAndFacility.customerLocationId),
        Number(currentLocationAndFacility.customerFacilityId),
      );
      setStatsLabels(result.labels);
      setStatsColors(result.colors);
      setStatsData(result.data);
      return true;
    } catch (err: any) {
      return err;
    }
  };

  useEffect(() => {
    fetchDataOrderStatus();
  }, [currentLocationAndFacility]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Order Status
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '30%',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PolarChart
          caption="Order status"
          labels={statsLabels}
          colors={statsColors}
          data={statsData}
        />
      </Box>
    </Box>
  );
}

export default React.memo(DashboardOrderStatus);
