import { IGridWidget } from 'common/interfaces';
import styled from 'styled-components';

const Container = styled.div`
  height: 35px;
  width: 100%;
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
`;

const BtnAddLocation = styled.div`
  width: 120px;
  background: #052fff;
  color: #fff;
  text-align: center;
  padding: 5px;
  cursor: pointer;
`;

const BtnClear = styled.div`
  width: 120px;
  background: #e46e73;
  color: #fff;
  text-align: center;
  padding: 5px;
  cursor: pointer;
`;

interface Props {
  onDragStart: (item: IGridWidget) => void;
  onClear: () => void;
}

const ContentHeader = (props: Props) => {
  const { onDragStart, onClear } = props;
  return (
    <Container>
      <BtnAddLocation
        draggable
        onDragStart={() => onDragStart({ i: 'dropping', w: 1, h: 1 })}
      >
        Add Bin Location
      </BtnAddLocation>
      <BtnClear onClick={() => onClear()}>Clear</BtnClear>
    </Container>
  );
};

export default ContentHeader;
