import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getAllCarrierTypes = async () => {
  try {
    const response = await httpClient.get(`/shipment/carrier/types`, {
      params: { showLoading: true },
    });

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateCarrierAccount = async (
  payload,
  accountId,
  warehouseCustomerId = null,
) => {
  try {
    const response = await httpClient.post(
      `/shipment/carrier/update/${accountId}/${warehouseCustomerId || 0}`,
      payload,
      { params: { showLoading: true } },
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteCarrierAccount = async (data) => {
  try {
    const response = await httpClient.post(
      `/shipment/carrier/account/internal/delete`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCarrierServicesByName = async (carrierName) => {
  try {
    const response = await httpClient.get(
      `/shipment/carrier/services/byName/${carrierName}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const connectCarrierAccount = async (payload, warehouseCustomerId) => {
  try {
    const response = await httpClient.post(
      `/shipment/carrier/connect/${warehouseCustomerId ?? 0}`,
      payload,
      { params: { showLoading: true } },
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCarrierDetails = async (carrierName, warehouseCustomerId) => {
  try {
    const response = await httpClient.get(
      `/shipment/carrier/details/${carrierName}/${warehouseCustomerId ?? 0}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getShippingIntegrationCompanies = async () => {
  try {
    const response = await httpClient.get(`/shipment/integration/companies`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrderCarriers = async (warehouseCustomerId) => {
  try {
    const response = await httpClient.get(
      `shipment/carrier/list/${warehouseCustomerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getOrderCarrierservices = async (carrierId) => {
  try {
    const response = await httpClient.get(
      `shipment/carrier/services/${carrierId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createLabel = async (data) => {
  try {
    const response = await httpClient.post(
      `shipment/packandship/createLabel`,
      data,
      { params: { showLoading: true } },
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getAllCarriersByCustomerId = async (warehouseCustomerId) => {
  try {
    const response = await httpClient.get(
      `/shipment/carrier/${warehouseCustomerId}/list`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const enableDisableShippingAccount = async (carrierId) => {
  try {
    const response = await httpClient.get(
      `/shipment/carrier/enabledisableshippingaccount/${carrierId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const setCarrierAccountBillable = async (carrierId) => {
  try {
    const response = await httpClient.get(
      `/shipment/carrier/setCarrierAccountBillable/${carrierId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const addAllWarehouseCarriers = async (
  warehouseCustomerId,
  isSelectedCarrier: boolean,
  ids?: any,
) => {
  try {
    const response = await httpClient.get(
      `/shipment/carrier/addallwarehousecarriers/${warehouseCustomerId}/${isSelectedCarrier}?ids=${ids}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateCarrierAndServiceOrderFromPackAndShip = async (data) => {
  try {
    const response = await httpClient.post(
      `order/UpdateCarrierAndServiceOrderFromPackAndShip`,
      data,
    );

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getRatesOptionFromCarriers = async (pickTicketId) => {
  try {
    const response = await httpClient.get(
      `/shipment/showrates/${pickTicketId}`,
      { params: { showLoading: true } },
    );

    return response.data;
  } catch (err) {
    throw errorCatch(err);
  }
};
