import React, { useEffect, useState } from 'react';

import Header from 'components/header';
import { snackActions } from 'config/snackbar.js';
import {
  duplicateFormTemplate,
  getListFormTemplate,
} from 'services/api/printoutforms/printoutform.api';
import { ContainerFlat, DatagridStandard } from 'styles';

import { Typography, Link, useTheme } from '@mui/material';
import { GridColumns, GridActionsCellItem } from '@mui/x-data-grid';

import { MainContainer, ContentContainer } from '../styles';

const LineItems = () => {
  const theme = useTheme();
  const [formTemplates, setFormTemplates] = useState([]);

  const onLoadFormTemplateList = async () => {
    try {
      const formTemplateListFromApi = await getListFormTemplate();
      setFormTemplates(formTemplateListFromApi);

      return true;
    } catch (err) {
      return err;
    }
  };

  const handleDuplicateForm = (id) => {
    duplicateFormTemplate(id)
      .then(() => {
        onLoadFormTemplateList();
      })
      .catch(() => snackActions.error("Duplicate couldn't be created"));
  };
  type FormTemplateRows = typeof formTemplates[number];

  const lineItemsColumns: GridColumns<FormTemplateRows> = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          onClick={() => {
            handleDuplicateForm(params.row.formTemplateId);
          }}
          label="Duplicate"
          showInMenu
        />,
      ],
    },
    {
      field: 'name',
      minWidth: 200,
      headerName: 'Name',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`printoutforms/formdetails?form=view&id=${params.row.formTemplateId}`}
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.name}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'typeName',
      minWidth: 200,
      flex: 0.5,
      headerName: 'Type',
    },
    {
      field: 'clients',
      minWidth: 200,
      flex: 1,
      headerName: 'Clients',
    },
  ];

  useEffect(() => {
    onLoadFormTemplateList();
  }, []);

  return (
    <ContainerFlat>
      <DatagridStandard
        autoHeight
        rows={formTemplates}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        columns={lineItemsColumns}
        hideFooter
        getRowId={(row) => row.formTemplateId}
        density="compact"
      />
    </ContainerFlat>
  );
};

const PrintOutForms = () => (
  <MainContainer>
    <Header />
    <ContentContainer>
      <LineItems />
    </ContentContainer>
  </MainContainer>
);

export default React.memo(PrintOutForms);
