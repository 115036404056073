import React, { useContext, useEffect, useState } from 'react';

import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  TextField,
  Grid as MUIGrid,
  Button,
} from '@mui/material';

import { useSubpageReceiptsContext } from './context';

export default React.memo(() => {
  const { isEditActualQtyModalOpen, setEditActualQtyModalOpen } =
    useContext(GlobalContext);

  const { lineItemRows, setLineItemRows, setFormValues, singleLineItemRow } =
    useSubpageReceiptsContext();

  const [actualValues, setActualValues] = useState<number>(null);
  const [actualValuesError, setActualValuesError] = useState<any>({});

  const handleEditSingleRowModelChange = async () => {
    const data = [...lineItemRows];

    const newValue = data.filter(
      (row) => row.lineId === singleLineItemRow.lineId,
    );

    newValue[0].actual = actualValues;

    lineItemRows.forEach((x) => {
      if (x.lineId.toString() === newValue[0].lineId.toString()) {
        setLineItemRows((prev) =>
          prev.map((row) =>
            row.lineId.toString() === newValue[0].lineId.toString()
              ? {
                  ...row,
                  actual: newValue[0].actual,
                }
              : row,
          ),
        );
      }
    });

    setFormValues((current) => ({
      ...current,
      lineItems: current.lineItems.map((row) =>
        row.lineId.toString() === newValue[0].lineId.toString()
          ? {
              ...row,
              actual: newValue[0].actual,
            }
          : row,
      ),
    }));
  };

  const handleSaveActualQtyModal = async (e: any) => {
    e.preventDefault();

    const temp: any = {};

    if (actualValues === null) {
      temp.actualValues = 'This field is required';
    } else if (actualValues <= 0) {
      temp.actualValues = 'qty must be greater than 0';
    } else {
      temp.actualValues = '';
    }

    setActualValuesError({
      ...temp,
    });

    if (!Object.values(temp).every((x) => x === '')) {
      return;
    }
    handleEditSingleRowModelChange();
    setEditActualQtyModalOpen(false);
  };

  useEffect(() => {
    if (isEditActualQtyModalOpen) {
      if (singleLineItemRow.actual) {
        setActualValues(singleLineItemRow.actual);
      } else {
        setActualValues(
          singleLineItemRow?.totalCases
            ? singleLineItemRow?.totalCases
            : singleLineItemRow?.qty,
        );
      }
    }
  }, [isEditActualQtyModalOpen]);

  return (
    <Modal open={isEditActualQtyModalOpen}>
      <MUIContainer sx={{ maxWidth: '300px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Edit Actual Quantity
        </Typography>
        <MUIContent>
          <TextField
            required
            sx={{ width: '100%' }}
            id="outlined-required"
            label="Actual Quantity"
            size="small"
            type="number"
            value={actualValues || ''}
            onChange={(event: any) => {
              setActualValues(event.target.value);
            }}
            {...(actualValuesError.actualValues && {
              error: true,
              helperText: actualValuesError.actualValues,
            })}
            inputProps={{
              'data-state': 'actualq',
              autoComplete: 'actualqty',
            }}
          />

          {/* Button ------------------ */}
          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  width: '100%',
                  marginTop: '20px',
                }}
              >
                <Button
                  onClick={() => {
                    setEditActualQtyModalOpen(false);
                  }}
                  variant="outlined"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                >
                  CANCEL
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => {
                    handleSaveActualQtyModal(e);
                  }}
                >
                  SUBMIT
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
