import { memo, useState } from 'react';

import Header from 'components/header';
import Input from 'components/input';
import moment from 'moment';
import { InternalToggleButton } from 'pages/inventory';
import LotActivityModal from 'pages/lots/lot-activity-log-modal';
import { MainContainer, ContentContainer } from 'pages/styles';
import { DatagridPremium } from 'styles/mui/datagrid/premium';

import { Search } from '@mui/icons-material';
import {
  Box,
  Card,
  Link,
  Grid as MUIGrid,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';

import { LotsContextProvider, useLotsContext } from './context';

const LotsFilterSearchPanel = () => {
  const {
    lotsDataGrid,
    setFilteredLotsDataGrid,
    viewLoadDataLots,
    setViewBy,
    viewBy,
  } = useLotsContext();
  const [searchFieldValue, setSearchFieldValue] = useState('');

  const handleViewByChange = (event, newValue) => {
    if (newValue === null) {
      return;
    }

    setViewBy(newValue);
    viewLoadDataLots(newValue);
  };

  const handleSearchFieldChange = (newValue) => {
    if (newValue) {
      const filteredList = lotsDataGrid
        ? lotsDataGrid.filter(
            (x) =>
              x?.lotNo?.toLowerCase().includes(newValue?.toLowerCase()) ||
              x?.sku?.toLowerCase().includes(newValue?.toLowerCase()) ||
              x?.poNo?.toLowerCase().includes(newValue?.toLowerCase()) ||
              x?.lpnNo?.toLowerCase().includes(newValue?.toLowerCase()) ||
              x?.customerName?.toLowerCase().includes(newValue?.toLowerCase()),
          )
        : null;
      setFilteredLotsDataGrid(filteredList);
    } else {
      setFilteredLotsDataGrid(lotsDataGrid);
    }
  };

  return (
    <div style={{ width: '100%', padding: '10px' }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'right !important',
        }}
      >
        <Box sx={{ paddingRight: '10px' }}>
          <ToggleButtonGroup
            value={viewBy}
            exclusive
            onChange={(e, newValue) => {
              handleViewByChange(e, newValue);
            }}
            aria-label="View"
            color="primary"
            size="small"
            sx={{
              float: 'right',
              height: '40px',
            }}
          >
            <Typography sx={{ padding: '10px', color: 'rgb(51, 117, 224)' }}>
              VIEW BY
            </Typography>
            <InternalToggleButton
              value="lot"
              aria-label="left"
              sx={{ width: 100 }}
              className="left-side"
            >
              Lot
            </InternalToggleButton>
            <InternalToggleButton
              value="po"
              aria-label="left"
              sx={{ width: 100 }}
              className="right-side"
            >
              PO
            </InternalToggleButton>
            <InternalToggleButton
              value="lpn"
              aria-label="left"
              sx={{ width: 100 }}
              className="right-side"
            >
              LPN
            </InternalToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Input
          sx={{ maxWidth: '100%' }}
          placeholder="Search"
          value={searchFieldValue}
          onChange={(e) => setSearchFieldValue(e.target.value)}
          rightIcon={<Search />}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchFieldChange(e.target.value);
            }
          }}
        />
      </Box>
    </div>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          flex: '1',
          width: '100% !important',
        }}
      >
        <LotsFilterSearchPanel />
      </Box>
    </GridToolbarContainer>
  );
}

const LotsDataTable = () => {
  const {
    filteredLotsDataGrid,
    isShowLotIdHistoryModal,
    viewOpenLotsModal,
    setIsShowLotIdHistoryModal,
    viewBy,
  } = useLotsContext();

  const lotsColumns: GridColDef[] = [
    {
      field: 'customerName',
      minWidth: 150,
      flex: 1,
      headerName: 'Client',
    },
    {
      field: 'lotNo',
      minWidth: 200,
      flex: 1,
      headerName: 'Lot No',
      hide: viewBy === 'po' || viewBy === 'lpn',
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography color="text.primary">{params.row.lotNo}</Typography>
          <Typography
            sx={{
              fontSize: '0.9rem',
              cursor: 'pointer',
              color: '#1C9DCC',
            }}
            onClick={() => {
              if (!isShowLotIdHistoryModal) {
                viewOpenLotsModal(params.row.lotId, params.row.itemId, viewBy);
                setIsShowLotIdHistoryModal(true);
              }
            }}
            className="cellDatagridWrapper"
          >
            Show Activity Log
          </Typography>
        </Box>
      ),
    },
    {
      field: 'expirationDate',
      minWidth: 120,
      flex: 1,
      headerName: 'Expiration Date',
      hide: viewBy === 'po' || viewBy === 'lpn',
      renderCell: (params) => (
        <Typography>
          {params.row.expirationDate &&
            moment(params.row.expirationDate).format('MM/DD/yyyy')}
        </Typography>
      ),
    },
    {
      field: 'poNo',
      minWidth: 200,
      flex: 1,
      headerName: 'Po No',
      hide: viewBy === 'lot' || viewBy === 'lpn',
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography color="text.primary">{params.row.poNo}</Typography>
          <Typography
            sx={{
              fontSize: '0.9rem',
              cursor: 'pointer',
              color: '#1C9DCC',
            }}
            onClick={() => {
              if (!isShowLotIdHistoryModal) {
                viewOpenLotsModal(
                  params.row.purchaseOrderId,
                  params.row.itemId,
                  viewBy,
                );
                setIsShowLotIdHistoryModal(true);
              }
            }}
            className="cellDatagridWrapper"
          >
            Show Activity Log
          </Typography>
        </Box>
      ),
    },
    {
      field: 'lpnNo',
      minWidth: 200,
      flex: 1,
      headerName: 'LPN',
      hide: viewBy === 'lot' || viewBy === 'po',
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography color="text.primary">{params.row.lpnNo}</Typography>
          <Typography
            sx={{
              fontSize: '0.9rem',
              cursor: 'pointer',
              color: '#1C9DCC',
            }}
            onClick={() => {
              if (!isShowLotIdHistoryModal) {
                viewOpenLotsModal(
                  params.row.licensePlateId,
                  params.row.itemId,
                  viewBy,
                );
                setIsShowLotIdHistoryModal(true);
              }
            }}
            className="cellDatagridWrapper"
          >
            Show Activity Log
          </Typography>
        </Box>
      ),
    },
    {
      field: 'qtyOnHand',
      minWidth: 120,
      flex: 1,
      headerName: 'Qty On Hand',
    },
    {
      field: 'sku',
      minWidth: 150,
      flex: 1,
      headerName: 'SKU',
      renderCell: (params) => (
        <Link
          sx={{
            textDecoration: 'none',
          }}
          href={`/inventory/item-details?form=view&id=${params.row.itemId}`}
          target="_blank"
        >
          <Typography
            sx={{
              fontSize: '0.9rem',
              cursor: 'pointer',
            }}
          >
            {params.row.sku}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'description',
      minWidth: 150,
      flex: 1,
      headerName: 'Description',
    },
    {
      field: 'firstReceiptDate',
      minWidth: 120,
      flex: 1,
      headerName: 'Receipt Date',
      renderCell: (params) => (
        <Typography>
          {params.row?.firstReceiptDate &&
            moment(params.row?.firstReceiptDate).format('MM/DD/yyyy')}
        </Typography>
      ),
    },
    {
      field: 'lastOutboundDate',
      minWidth: 120,
      flex: 1,
      headerName: 'Ship Date',
      renderCell: (params) => (
        <Typography>
          {params.row?.lastOutboundDate &&
            moment(params.row?.lastOutboundDate).format('MM/DD/yyyy')}
        </Typography>
      ),
    },
  ];

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={12}>
          <DatagridPremium
            autoHeight
            rows={filteredLotsDataGrid}
            columns={lotsColumns}
            hideFooterSelectedRowCount
            pageSize={25}
            rowsPerPageOptions={[25]}
            pagination
            disableColumnMenu
            density="compact"
            getRowId={() => Math.random()}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

function LotPage() {
  return (
    <MainContainer>
      <LotsContextProvider>
        <Header />
        <LotActivityModal />
        <ContentContainer>
          <LotsDataTable />
        </ContentContainer>
      </LotsContextProvider>
    </MainContainer>
  );
}

export default memo(LotPage);
