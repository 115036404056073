import React, { createContext, useState, useMemo, useEffect } from 'react';
import { decodeToken } from 'react-jwt';

import {
  OutboundSectionSettingLockOrderEditsAfterEnum,
  RoleEnum,
} from 'common/enums';
import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import { AESDecrypt, AESEncrypt } from 'helpers/encryptdecrypt';
import { getCustomerOutboundSection } from 'services/api/uoms/uoms.api';
import {
  getUsersRoleSupplierByUserId,
  getUsersRoleWarehouseCustomerByUserId,
} from 'services/api/user/users.api';
import useLocalStorage from 'store/localStorage/hooks/useLocalStorage';
import { getValue, clear } from 'store/localStorage/useLocalStorage';

export const AuthContext = createContext(null);

type WarehouseCustomerPermission = {
  Order: boolean;
  OrderViewOnly: boolean;
  PO: boolean;
  Reports: boolean;
  Inventory: boolean;
  InventoryDetail: boolean;
};
type SupplierPermission = {
  PO: boolean;
};
type OutboundSectionSetting = {
  isRequiredApprovalBeforePicking: boolean;
  isPackAndShip: boolean;
  isCompleteOrderAfterPickApproval: boolean;
  lockOrderEditsAfter: OutboundSectionSettingLockOrderEditsAfterEnum;
};

const AuthProvider = ({ children }) => {
  const [currentUser, setUserData] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const [useStorage, setUseStorage] = useLocalStorage('auth', null);
  const [storeLocationAndFacility, setStoreLocationAndFacility] =
    useLocalStorage('locationAndFacility', null);

  const [storeWarehouseCustomer, setStoreWarehouseCustomer] = useLocalStorage(
    'storeWarehouseCustomer',
    null,
  );

  const [currentLocationAndFacility, setCurrentLocationAndFacility] =
    useState(null);

  const [currentUserPermission, setcurrentUserPermission] = useState(null);

  const [roleWarehouseCustomerPermission, setRoleWarehouseCustomerPermission] =
    useState<WarehouseCustomerPermission>({
      Order: true,
      OrderViewOnly: false,
      PO: true,
      Reports: false,
      Inventory: false,
      InventoryDetail: false,
    });

  const [roleSupplierPermission, setRoleSupplierPermission] =
    useState<SupplierPermission>({
      PO: false,
    });

  const [outboundSectionSettings, setOutboundSectionSettings] =
    useState<OutboundSectionSetting>({
      isRequiredApprovalBeforePicking: true,
      isPackAndShip: false,
      isCompleteOrderAfterPickApproval: false,
      lockOrderEditsAfter:
        OutboundSectionSettingLockOrderEditsAfterEnum.PickStarted,
    });

  const login = (userPayload) => {
    const decodedToken = decodeToken(userPayload);
    setUserData(decodedToken);
    setUseStorage(true);
  };

  const setFacilityAndLocation = (locationPayload) => {
    setStoreLocationAndFacility(locationPayload.encryptedLocation);
    setCurrentLocationAndFacility(locationPayload.locationFacility);
  };

  const setUserPermission = (permissionsPayload) => {
    setcurrentUserPermission([...permissionsPayload]);
  };

  const updateLocationAndFacility = (locationPayload) => {
    setFacilityAndLocation(locationPayload);
  };

  const updateCurrentUser = (userPayload) => {
    login(userPayload);
  };

  const logout = () => {
    setUserData(null);
    setUseStorage(null);
    setStoreLocationAndFacility(null);
    setCurrentLocationAndFacility(null);
    setAuthorized(false);
    clear();
  };

  const handleSetAuthorized = () => {
    setAuthorized(!authorized);
  };

  const onLoadWarehouseCustomerModulesByUserId = async (userID) => {
    try {
      return await getUsersRoleWarehouseCustomerByUserId(userID);
    } catch (err: any) {
      return err;
    }
  };
  const onLoadSupplierModulesByUserId = async (userID) => {
    try {
      return await getUsersRoleSupplierByUserId(userID);
    } catch (err: any) {
      return err;
    }
  };
  const onLoadOutboundSection = async (id) => {
    try {
      return await getCustomerOutboundSection(id);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (
      currentUser &&
      currentUser.Claim_UserRole === RoleEnum.WarehouseCustomerAccount
    ) {
      const loadData = onLoadWarehouseCustomerModulesByUserId(
        currentUser.Claim_UserId,
      );

      loadData
        .then((opt) => {
          setRoleWarehouseCustomerPermission((value) => ({
            ...value,
            Order: opt.some((element) => {
              if (element.name === 'Order') {
                return true;
              }
              return false;
            }),
            OrderViewOnly: opt.some((element) => {
              if (element.name === 'Order View Only') {
                return true;
              }
              return false;
            }),
            PO: opt.some((element) => {
              if (element.name === 'PO') {
                return true;
              }
              return false;
            }),
            Reports: opt.some((element) => {
              if (element.name === 'Reports') {
                return true;
              }
              return false;
            }),
            Inventory: opt.some((element) => {
              if (element.name === 'Inventory') {
                return true;
              }
              return false;
            }),
            InventoryDetail: opt.some((element) => {
              if (element.name === 'Inventory Detail') {
                return true;
              }
              return false;
            }),
          }));
        })
        .catch();
    }
    if (
      currentUser &&
      currentUser.Claim_UserRole === RoleEnum.SupplierAccount
    ) {
      const loadData = onLoadSupplierModulesByUserId(currentUser.Claim_UserId);
      loadData
        .then((opt) => {
          setRoleSupplierPermission((value) => ({
            ...value,
            PO: opt.some((element) => {
              if (element.name === 'PO') {
                return true;
              }
              return false;
            }),
          }));
        })
        .catch();
    }

    if (currentUser) {
      const loadDataOutboundSetting = onLoadOutboundSection(
        currentUser.Claim_CustomerId,
      );
      loadDataOutboundSetting
        .then((opt) => {
          setOutboundSectionSettings((outbound) => ({
            ...outbound,
            isRequiredApprovalBeforePicking:
              opt.isRequiredApprovalBeforePicking,
            isPackAndShip: opt.isPackAndShip,
            isCompleteOrderAfterPickApproval:
              opt.isCompleteOrderAfterPickApproval,
            lockOrderEditsAfter: opt.lockOrderEditsAfter,
          }));
        })
        .catch();
    }

    if (currentUser && currentUser.Claim_UserPermissions) {
      const userPermissionsInStorage =
        currentUser?.Claim_UserPermissions !== null &&
        JSON.parse(
          JSON.stringify(
            currentUser.Claim_UserPermissions.replaceAll('"', '').split(','),
          ),
        );
      setUserPermission(userPermissionsInStorage);
    }
  }, [currentUser]);

  const isAuth = () => {
    if (currentUser !== null) return true;
    const authStateInStorage = useStorage && getValue('auth');

    const locationAndFacilityInStorage =
      storeLocationAndFacility &&
      JSON.parse(
        JSON.stringify(getValue('locationAndFacility').replaceAll('"', '')),
      );

    if (authStateInStorage && authStateInStorage !== 'false') {
      login(getValue('token'));
      if (
        locationAndFacilityInStorage &&
        locationAndFacilityInStorage !== null
      ) {
        try {
          const locationAndFacilityData = AESDecrypt(
            locationAndFacilityInStorage,
          );
          const locationAndFacilityPayload = {
            locationFacility: locationAndFacilityData,
            encryptedLocation: locationAndFacilityInStorage,
          };
          setFacilityAndLocation(locationAndFacilityPayload);
        } catch (err) {
          // cannot decrypt the location and facility data, logout
          logout();
        }
      }

      return true;
    }

    return false;
  };

  const isSuperAdmin = () => currentUser && currentUser.Claim_UserRole === '1';

  const isAdmin = () => currentUser && currentUser.Claim_UserRole === '2';

  const isOpManager = () => currentUser && currentUser.Claim_UserRole === '3';

  const isOperator = () => currentUser && currentUser.Claim_UserRole === '4';

  const isWarehouseCustomerAccount = () =>
    currentUser &&
    currentUser.Claim_UserRole === RoleEnum.WarehouseCustomerAccount;

  const isInventoryViewer = () =>
    currentUser && currentUser.Claim_UserRole === '5';

  const isSupplierAccount = () =>
    currentUser && currentUser.Claim_UserRole === RoleEnum.SupplierAccount;

  const handleUserPermissionAllow = (permission?: UserPermissionEnum) => {
    if (
      currentUserPermission &&
      currentUserPermission.length > 0 &&
      currentUserPermission.some((x) => Number(x) === permission)
    ) {
      return true;
    }
    return false;
  };
  const props = useMemo(
    () => ({
      authorized,
      useStorage,
      setUseStorage,
      storeLocationAndFacility,
      currentLocationAndFacility,
      storeWarehouseCustomer,
      setStoreWarehouseCustomer,
      currentUser,
      roleWarehouseCustomerPermission,
      roleSupplierPermission,
      outboundSectionSettings,
      isOperator: Boolean(isOperator()),
      isOpManager: Boolean(isOpManager()),
      isSuperAdmin: Boolean(isSuperAdmin()),
      isAdmin: Boolean(isAdmin()),
      isInventoryViewer: Boolean(isInventoryViewer()),
      isWarehouseCustomerAccount: Boolean(isWarehouseCustomerAccount()),
      isSupplierAccount: Boolean(isSupplierAccount()),
      isAuth: Boolean(isAuth()),
      currentUserPermission,
      login,
      logout,
      updateCurrentUser,
      handleSetAuthorized,
      setFacilityAndLocation,
      updateLocationAndFacility,
      handleUserPermissionAllow,
    }),
    [
      currentUser,
      currentLocationAndFacility,
      authorized,
      storeLocationAndFacility,
      roleWarehouseCustomerPermission,
      roleSupplierPermission,
      outboundSectionSettings,
      storeWarehouseCustomer,
      currentUserPermission,
    ],
  );

  return <AuthContext.Provider value={props}>{children}</AuthContext.Provider>;
};

export default React.memo(AuthProvider);
