export enum RoleEnum {
  SuperAdmin = '1', // us, can do everything
  Admin = '2', // limited to the customer data
  OpsManager = '3', // login to web, cannot import
  Operator = '4', // mobile user only
  Viewer = '5', // web, view only, cannot do action. For now limit only to inventory
  CustomerAdmin = '6', // warehousecustomer admin
  CustomerInventory = '7', // warehousecustomer inventory access
  CustomerOrder = '8', // warehousecustomer po access
  WarehouseCustomerAccount = '9', // user that created at warehouse customer page
  SupplierAccount = '10', // user that created at supplier page
}
