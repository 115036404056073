import { memo, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';

import {
  Autocomplete,
  Box,
  Modal,
  TextField,
  Typography,
  Grid as MUIGrid,
  Button,
} from '@mui/material';

import { usePickQueueDataTableViewContext } from '..';

type AutocompleteBase = {
  value?: number;
  text?: string;
};
type AssignedToType = AutocompleteBase;

export default memo(() => {
  const {
    rowSelectionModel,
    filteredPickingQueue,
    isAssignedToUserModal,
    setAnchorEl,
    setIsAssignedToUserModal,
    HandleUpdatePickTicketAssignedTo,
  } = usePickQueueDataTableViewContext();

  const [assignedToValue, setAssignedToValue] = useState<AssignedToType>(null);
  const [fieldError, setFieldError] = useState<any>({});

  const handleOnCloseModal = async (e: any) => {
    setIsAssignedToUserModal(false);
    setAssignedToValue(null);
  };

  const handleOnSave = async (e: any) => {
    e.preventDefault();

    try {
      const temp: any = {};

      temp.assignedToValue = assignedToValue ? '' : 'This field is required';
      setFieldError({
        ...temp,
      });

      if (!Object.values(temp).every((x) => x === '')) {
        return;
      }

      if (rowSelectionModel.length <= 1) {
        snackActions.error('Please select at least two orders to assign user');
        return;
      }

      HandleUpdatePickTicketAssignedTo(
        e,
        0,
        assignedToValue.value,
        rowSelectionModel,
      );
      setAnchorEl(null);
      handleOnCloseModal(e);
    } catch (err) {
      snackActions.error(err);
    }
  };

  return (
    <Modal open={isAssignedToUserModal}>
      <MUIContainer sx={{ width: '20% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Assigned To User
        </Typography>
        <MUIContent sx={{ paddingBottom: '0px !important' }}>
          <Box
            sx={{
              width: '100%',
              textAlignLast: 'center',
            }}
          >
            <Autocomplete
              onChange={(event: any, newValue: AssignedToType) => {
                setAssignedToValue(newValue);
              }}
              id="controllable-states"
              options={filteredPickingQueue[0]?.assignedByUsers}
              getOptionLabel={(option: AssignedToType) => option.text}
              value={assignedToValue}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              renderInput={(paramsAuto) => (
                <TextField
                  {...paramsAuto}
                  inputProps={{
                    ...paramsAuto.inputProps,
                    autoComplete: 'off',
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="Users"
                  size="small"
                  sx={{ width: '100%' }}
                  {...(fieldError?.assignedToValue && {
                    error: true,
                    helperText: fieldError?.assignedToValue,
                  })}
                />
              )}
            />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              sx={{
                borderTop: '1px solid #dbdbdb',
                width: '100% !important',
                marginLeft: '1px !important',
              }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={10}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    margin: '20px',
                    padding: '0px',
                  }}
                >
                  <Button
                    onClick={(e) => {
                      handleOnCloseModal(e);
                    }}
                    variant="outlined"
                    size="small"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      padding: 1,
                      width: '100%',
                      backgroundColor: ' rgb(75, 126, 254) !important',
                    }}
                    onClick={(e) => {
                      handleOnSave(e);
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
