import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';

import {
  Modal,
  Box,
  Typography,
  Button,
  Grid as MUIGrid,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from '@mui/material';

import { KitSkuType, useInventoryItemDetailsContext } from '../details';

export const KitOnDemandComponentModal = () => {
  const {
    optionKitSkus,
    kitOnDemandItemRows,
    setKitOnDemandItemRows,
    kitOnDemandSelectedItemRow,
    setKitOnDemandSelectedItemRow,
  } = useInventoryItemDetailsContext();

  const validateForm = () => {
    let { itemIdError, qtyError } = kitOnDemandSelectedItemRow;
    const re = /[0-9]+/g;

    if (kitOnDemandSelectedItemRow?.itemId === null) {
      itemIdError = 'This field is required';
    } else {
      itemIdError = '';
    }

    if (kitOnDemandSelectedItemRow?.qty === null) {
      qtyError = 'This field is required';
    } else if (!re.test(kitOnDemandSelectedItemRow?.qty.toString())) {
      qtyError = 'Must be a number';
    } else {
      qtyError = '';
    }

    setKitOnDemandSelectedItemRow((prevState) => ({
      ...prevState,
      itemIdError,
      qtyError,
    }));

    if (itemIdError !== '' || qtyError !== '') {
      return false;
    }

    return true;
  };

  const handleSave = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();

    if (!validateForm()) {
      snackActions.error('Please complete required fields');
    } else {
      let kitOnDemand = null;
      if (kitOnDemandSelectedItemRow.rowId !== null) {
        kitOnDemand = kitOnDemandItemRows.find(
          (x) => x.rowId === kitOnDemandSelectedItemRow.rowId,
        );
      }
      // exist in db
      else {
        kitOnDemand = kitOnDemandItemRows.find(
          (x) =>
            x.itemKitOnDemandId ===
            kitOnDemandSelectedItemRow.itemKitOnDemandId,
        );
      }

      // add
      if (kitOnDemand === undefined) {
        setKitOnDemandItemRows([
          ...kitOnDemandItemRows,
          { ...kitOnDemandSelectedItemRow },
        ]);
      }
      // edit
      else {
        const newArray = [...kitOnDemandItemRows];

        let index = null;
        if (kitOnDemandSelectedItemRow.rowId !== null) {
          index = kitOnDemandItemRows.findIndex(
            (v) => v.rowId === kitOnDemandSelectedItemRow.rowId,
          );
        }
        // exist in db
        else {
          index = kitOnDemandItemRows.findIndex(
            (v) =>
              v.itemKitOnDemandId ===
              kitOnDemandSelectedItemRow.itemKitOnDemandId,
          );
        }

        newArray[index] = { ...kitOnDemandSelectedItemRow };
        setKitOnDemandItemRows(newArray);
      }

      setKitOnDemandSelectedItemRow(null);
    }
  };

  return (
    <Modal open={kitOnDemandSelectedItemRow !== null}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Add BOM Item
        </Typography>
        <MUIContent>
          <Box autoComplete="off" component="form" noValidate>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={3}>
                <Autocomplete
                  value={kitOnDemandSelectedItemRow?.kitSkuType}
                  onChange={(event, newValue: KitSkuType) => {
                    if (newValue !== null) {
                      setKitOnDemandSelectedItemRow((prevState) => ({
                        ...prevState,
                        itemId: newValue.value,
                        sku: newValue.label,
                        description: newValue.description,
                        kitSkuType: { ...newValue },
                      }));
                    } else {
                      setKitOnDemandSelectedItemRow((prevState) => ({
                        ...prevState,
                        itemId: null,
                        sku: null,
                        description: null,
                        kitSkuType: null,
                      }));
                    }
                  }}
                  id="controllable-states"
                  disabled={
                    kitOnDemandSelectedItemRow?.itemKitOnDemandId !== null
                  }
                  options={optionKitSkus}
                  getOptionLabel={(option: KitSkuType) => option.label}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option: KitSkuType) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      {...(kitOnDemandSelectedItemRow?.itemIdError && {
                        error: true,
                        helperText: kitOnDemandSelectedItemRow?.itemIdError,
                      })}
                      id="outlined-required"
                      label="SKU"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <TextField
                  disabled
                  value={kitOnDemandSelectedItemRow?.description || ''}
                  id="outlined-required"
                  label="Description"
                  size="small"
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <TextField
                  required
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setKitOnDemandSelectedItemRow((prevState) => ({
                      ...prevState,
                      qty:
                        event.target.value === ''
                          ? null
                          : Number(event.target.value),
                    }));
                  }}
                  {...(kitOnDemandSelectedItemRow?.qtyError && {
                    error: true,
                    helperText: kitOnDemandSelectedItemRow?.qtyError,
                  })}
                  value={kitOnDemandSelectedItemRow?.qty || ''}
                  id="outlined-required"
                  label="Quantity per Kit"
                  size="small"
                  type="number"
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Tooltip
                  title="When checked, this item must be picked. Turn off it the item is already at the packing station."
                  placement="bottom"
                  arrow
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          setKitOnDemandSelectedItemRow((prevState) => ({
                            ...prevState,
                            isNeedPicking: event.target.checked,
                          }));
                        }}
                        checked={kitOnDemandSelectedItemRow?.isNeedPicking}
                      />
                    }
                    label="Need Picking?"
                    sx={{ width: '100%' }}
                  />
                </Tooltip>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => setKitOnDemandSelectedItemRow(null)}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={(e) => {
                  handleSave(e);
                }}
              >
                Save
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
};
