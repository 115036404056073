import React from 'react';

import Card from 'components/card';
import Footer from 'components/footer';
import Header from 'components/header';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { GridColumns, DataGrid, GridRowsProp } from '@mui/x-data-grid';

import { MainContainer, ContentContainer } from '../styles';

const LineItems = () => {
  const lineItemsColumns: GridColumns = [
    {
      field: 'lineId',
      width: 100,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Line Id</Typography>
        </Box>
      ),
    },
    {
      field: 'sku',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">SKU</Typography>
        </Box>
      ),
    },
    {
      field: 'description',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Description</Typography>
        </Box>
      ),
    },
    {
      field: 'qualifier',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Qualifier</Typography>
        </Box>
      ),
    },
    {
      field: 'qty',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Qty</Typography>
        </Box>
      ),
    },
    {
      field: 'shipTo',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Ship To</Typography>
        </Box>
      ),
    },
    {
      field: 'expected',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Expected</Typography>
        </Box>
      ),
    },
    {
      field: 'cancelDate',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Cancel Date</Typography>
        </Box>
      ),
    },
  ];

  const rows: GridRowsProp = [
    {
      lineId: 'V23451',
      sku: '184SHIRTSM',
      description: 'Red Pattern USA GLid',
      qualifier: '',
      qty: '230',
      shipTo: 'DENVER-WH',
      expected: '01 09 2023',
      cancelDate: '',
    },
    {
      lineId: 'V23452',
      sku: '184SHIRTSM',
      description: 'Red Pattern USA GLid',
      qualifier: '',
      qty: '230',
      shipTo: 'DENVER-WH',
      expected: '01 09 2023',
      cancelDate: '',
    },
  ];

  function toolbar() {
    return (
      <Box
        sx={{
          paddingBottom: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: '8px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '8px',
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Inventory
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <DataGrid
          autoHeight
          headerHeight={120}
          rows={rows}
          density="compact"
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          columns={lineItemsColumns}
          pageSize={6}
          rowsPerPageOptions={[6]}
          getRowId={(row) => row.lineId}
          components={{ Toolbar: toolbar }}
          sx={{
            borderColor: 'transparent',
            '& .MuiDataGrid-columnSeparator--sideRight': {
              display: 'none !important',
            },
          }}
        />
      </Box>
    </Card>
  );
};

function InventoryTempList() {
  return (
    <MainContainer>
      <Header />
      <ContentContainer>
        <LineItems />
      </ContentContainer>
    </MainContainer>
  );
}

export default React.memo(InventoryTempList);
