import React, { useContext, useEffect, useState } from 'react';

import DHLEcommerceLogo from 'assets/images/carriers/dhl_ecommerce.svg';
import DhlExpressLogo from 'assets/images/carriers/dhl.svg';
import FedExLogo from 'assets/images/carriers/fedex.svg';
import GLSLogo from 'assets/images/carriers/gls.svg';
import UPSLogo from 'assets/images/carriers/UPS.svg';
import Button from 'components/button';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { getCountries } from 'services/api/country/country.api';
import {
  connectCarrier,
  getCarrierById,
} from 'services/api/integrations/shippo/shippo.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { ToggleOff, ToggleOn } from '@mui/icons-material';
import {
  Modal,
  Box,
  Typography,
  Divider,
  Grid as MUIGrid,
  Autocomplete,
  TextField,
  ImageList,
  ImageListItem,
  IconButton,
  Tooltip,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

enum CarriersEnum {
  Fedex = 0,
  DHL = 1,
  UPS = 2,
  USPS = 3,
  DHLEcommerce = 4,
  GLS = 5,
}

interface AutoCompleteOptionType {
  id: number;
  label: string;
}

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 650px;
    border-radius: 4px;
    max-height: 100%;
    overflow: auto;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 24px;
    gap: 16px;
  }
`;

export default React.memo(() => {
  const { isShippoIntegrationModalOpen, onCloseShippoIntegrationModal } =
    useContext(GlobalContext);

  const { form } = useWarehouseCustomerContext();
  const initialInternalFormErrors = {
    accountNickName: '',
    accountNumber: '',
    userName: '',
    password: '',
    siteId: '',
    pickupNumber: '',
    facilityCode: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    countryInternal: '',
    smartPostHubId: '',
  };

  const initialState = {
    shippoObjectId: '',
    active: true,
    editing: false,
    integrationType: '',
    searchCarrier: '',
    selectedCarrier: '',
    carrierImage: '',
    accountNickName: '',
    accountNumber: '',
    userName: '',
    password: '',
    siteId: '',
    pickupNumber: '',
    facilityCode: '',
    contactInformation: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
    shippingAddress: {
      street: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      countryInternal: '',
      smartPostHubId: '',
    },
  };

  const [internalFormErrors, setInternalFormErrors] = useState<any>(
    initialInternalFormErrors,
  );
  const [internalForm, setInternalForm] = useState<any>(initialState);
  const [carrierOptions, setCarrierOptions] = useState<any[]>([]);
  const [filteredCarrierOptions, setFilteredCarrierOptions] = useState<any[]>(
    [],
  );
  const [countries, setCountries] = useState([]);

  const loadCountries = async () => {
    try {
      const countriesFromApi = await getCountries('');
      setCountries(
        countriesFromApi.map((v, index) => ({
          id: v.countryId,
          label: v.name,
        })),
      );
      return true;
    } catch (err) {
      return err;
    }
  };

  const filterCarriers = () => {
    setFilteredCarrierOptions(
      carrierOptions.filter((item) =>
        item.carrierName
          .toLowerCase()
          .includes(internalForm.searchCarrier.toLowerCase()),
      ),
    );
  };

  useEffect(() => {
    if (
      internalForm.searchCarrier === '' ||
      internalForm.searchCarrier === null
    ) {
      setFilteredCarrierOptions(carrierOptions);
    }
    filterCarriers();
  }, [internalForm.searchCarrier]);

  useEffect(() => {
    if (isShippoIntegrationModalOpen) {
      const res = [
        {
          carrierName: 'FedEx',
          carrierId: 'fedex',
          image: FedExLogo,
          integrationType: CarriersEnum.Fedex,
        },
        {
          carrierName: 'DHL Express',
          carrierId: 'dhl_express',
          image: DhlExpressLogo,
          integrationType: CarriersEnum.DHL,
        },
        {
          carrierName: 'UPS',
          carrierId: 'ups',
          image: UPSLogo,
          integrationType: CarriersEnum.UPS,
        },
        {
          carrierName: 'DHL Ecommerce',
          carrierId: 'dhl_ecommerce',
          image: DHLEcommerceLogo,
          integrationType: CarriersEnum.DHLEcommerce,
        },
        {
          carrierName: 'GLS US',
          carrierId: 'gls_us',
          image: GLSLogo,
          integrationType: CarriersEnum.GLS,
        },
      ];
      setCarrierOptions(res);
      setFilteredCarrierOptions(res);
      loadCountries();
      if (form.selectedCarrierConnection) {
        getCarrierById(form.selectedCarrierConnection).then(
          (selectedCarrier) => {
            if (selectedCarrier) {
              const auxIntegrationType = res.filter(
                (x) => x.carrierId === selectedCarrier.carrier,
              )[0];
              setInternalForm(() => ({
                ...internalForm,
                ...selectedCarrier,
                selectedCarrier: selectedCarrier.carrierName,
                carrierImage: selectedCarrier.image,
                objectId: selectedCarrier.carrier,
                shippoObjectId: form.selectedCarrierConnection,
                editing: true,
                integrationType: auxIntegrationType.integrationType,
              }));
            }
          },
        );
      }
    }
  }, [isShippoIntegrationModalOpen]);

  const inputHandler = (inputName, value) => {
    let schema = internalForm;
    const pList = inputName.split('.');
    const len = pList.length;
    for (let i = 0; i < len - 1; i += 1) {
      const elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }

    const selectedValue =
      inputName === 'shippingAddress.countryInternal'
        ? value
        : value.target.value;
    schema[pList[len - 1]] = selectedValue;
    setInternalForm(() => ({
      ...internalForm,
      [pList[len - 1]]: selectedValue,
    }));
  };

  const selectCarrier = (item) => {
    setInternalFormErrors({ ...initialInternalFormErrors });
    if (item.carrierId === undefined) {
      setInternalForm(() => ({
        ...internalForm,
        ...initialState,
      }));
    } else {
      setInternalForm(() => ({
        ...internalForm,
        ...initialState,
        selectedCarrier: item.carrierName,
        carrierImage: item.image,
        objectId: item.carrierId,
        integrationType: item.integrationType,
        editing: item.editing,
      }));
    }
  };

  const handleCancelButton = () => {
    if (internalForm.selectedCarrier !== '') {
      selectCarrier({
        carrierName: '',
        carrierImages: { bigImage: '' },
        objectId: '',
        editing: false,
      });
    } else {
      onCloseShippoIntegrationModal();
    }
  };

  const validateFields = () => {
    const temp: any = {};
    const requiredMessage = 'Required field.';
    temp.accountNumber =
      internalForm.accountNumber === '' ? requiredMessage : '';
    temp.accountNickName =
      internalForm.accountNickName === '' ? requiredMessage : '';
    switch (internalForm.objectId) {
      case 'dhl_ecommerce':
        temp.userName = internalForm.userName === '' ? requiredMessage : '';
        temp.password = internalForm.password === '' ? requiredMessage : '';
        temp.pickupNumber =
          internalForm.pickupNumber === '' ? requiredMessage : '';
        temp.facilityCode =
          internalForm.facilityCode === '' ? requiredMessage : '';
        break;
      case 'dhl_express':
        temp.accountNumber =
          internalForm.accountNumber === '' ? requiredMessage : '';
        temp.password = internalForm.password === '' ? requiredMessage : '';
        temp.siteId = internalForm.siteId === '' ? requiredMessage : '';
        break;
      case 'ups':
      case 'fedex':
        temp.firstName =
          internalForm.contactInformation.firstName === ''
            ? requiredMessage
            : '';
        temp.lastName =
          internalForm.contactInformation.lastName === ''
            ? requiredMessage
            : '';
        temp.phoneNumber =
          internalForm.contactInformation.phoneNumber === ''
            ? requiredMessage
            : '';
        temp.street =
          internalForm.shippingAddress.street === '' ? requiredMessage : '';
        temp.city =
          internalForm.shippingAddress.city === '' ? requiredMessage : '';
        temp.state =
          internalForm.shippingAddress.state === '' ? requiredMessage : '';
        temp.postalCode =
          internalForm.shippingAddress.postalCode === '' ? requiredMessage : '';
        temp.country =
          internalForm.shippingAddress.countryInternal === ''
            ? requiredMessage
            : '';
        break;
      case 'gls':
        temp.userName = internalForm.userName === '' ? requiredMessage : '';
        temp.password = internalForm.password === '' ? requiredMessage : '';
        break;
      default:
        temp.accountNumber =
          internalForm.accountNumber === '' ? requiredMessage : '';
        temp.accountNickName =
          internalForm.accountNickName === '' ? requiredMessage : '';
        break;
    }

    setInternalFormErrors({ ...temp });

    return Object.values(temp).every((x) => x === '');
  };

  const save = async () => {
    if (internalForm.selectedCarrier === '') {
      snackActions.error('Please select a carrier.');
      return;
    }

    if (validateFields()) {
      if (internalForm.shippingAddress.countryInternal !== undefined) {
        internalForm.shippingAddress.country =
          internalForm.shippingAddress.countryInternal.label;
      }

      const result = await connectCarrier(
        internalForm,
        form.warehouseCustomerId,
      );
      if (result !== '') {
        if (result.non_field_errors !== undefined) {
          snackActions.error(result.non_field_errors[0]);
        } else if (result.detail !== undefined) {
          snackActions.error(result.detail);
        } else {
          snackActions.error(result);
        }
      } else {
        snackActions.success('Carrier saved successfully.');
        setInternalForm(() => ({
          ...internalForm,
          ...initialState,
        }));
        onCloseShippoIntegrationModal();
      }
    }
  };

  const handleToggleConnectionState = () => {
    setInternalForm(() => ({
      ...internalForm,
      active: !internalForm.active,
    }));
  };

  return (
    <Modal
      open={isShippoIntegrationModalOpen}
      onClose={() => onCloseShippoIntegrationModal()}
    >
      <MUIContainer>
        <MUIContent sx={{ marginBotton: '16px' }}>
          {internalForm.selectedCarrier === '' && (
            <Box autoComplete="off" component="form" noValidate>
              <Typography variant="h6" fontWeight="bold">
                Connect Carrier Account
              </Typography>
              <MUIGrid
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={6} sm={6} md={6}>
                  <Input
                    value={internalForm.searchCarrier}
                    onChange={(value) => inputHandler('searchCarrier', value)}
                    placeholder="Search carrier"
                  />
                </MUIGrid>
              </MUIGrid>
              <MUIGrid
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={12} sm={12} md={12} sx={{ minHeight: 300 }}>
                  <ImageList cols={3} rowHeight={100}>
                    {filteredCarrierOptions.map((item) => (
                      <Button
                        onClick={() => selectCarrier(item)}
                        variant="text"
                        size="large"
                      >
                        <ImageListItem
                          key={item.carrierId}
                          sx={{
                            width: 200,
                            height: 62,
                          }}
                        >
                          <img
                            src={`require(${item.image})`}
                            srcSet={`${item.image}`}
                            alt={item.carrierName}
                            loading="lazy"
                          />
                        </ImageListItem>
                      </Button>
                    ))}
                  </ImageList>
                </MUIGrid>
              </MUIGrid>
            </Box>
          )}
          {internalForm.selectedCarrier !== '' && (
            <Box>
              <MUIGrid
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={12}>
                  <img
                    src={internalForm.carrierImage}
                    alt={internalForm.selectedCarrier}
                    style={{ marginLeft: '33%', width: 200, height: 62 }}
                  />
                  <MUIGrid
                    item
                    xs={2}
                    alignItems="right"
                    sx={{ float: 'right' }}
                  >
                    <IconButton
                      color="primary"
                      aria-label="add"
                      component="label"
                    >
                      {internalForm.active && internalForm.editing && (
                        <Tooltip title="Active">
                          <ToggleOn
                            sx={{ fontSize: 40 }}
                            onClick={() => {
                              handleToggleConnectionState();
                            }}
                          />
                        </Tooltip>
                      )}

                      {!internalForm.active && internalForm.editing && (
                        <Tooltip title="Inactive">
                          <ToggleOff
                            sx={{ fontSize: 40, color: 'gray' }}
                            onClick={() => {
                              handleToggleConnectionState();
                            }}
                          />
                        </Tooltip>
                      )}
                    </IconButton>
                  </MUIGrid>
                </MUIGrid>
                <MUIGrid item xs={12}>
                  <Typography variant="h6" fontWeight="bold" align="center">
                    Connect Your {internalForm.selectedCarrier} Account
                  </Typography>
                </MUIGrid>
                <MUIGrid item xs={12} sm={12} md={12}>
                  <Input
                    placeholder="Account Nickname"
                    sx={{ width: '100%' }}
                    value={internalForm.accountNickName}
                    required
                    error={internalFormErrors.accountNickName}
                    onChange={(value) => inputHandler('accountNickName', value)}
                  />
                </MUIGrid>
                <MUIGrid item xs={12} sm={12} md={12}>
                  <Input
                    placeholder="Account Number"
                    sx={{ width: '100%' }}
                    value={internalForm.accountNumber}
                    disabled={internalForm.editing}
                    required
                    error={internalFormErrors.accountNumber}
                    onChange={(value) => inputHandler('accountNumber', value)}
                  />
                </MUIGrid>
                {['dhl_ecommerce', 'gls_us'].includes(
                  internalForm.objectId,
                ) && (
                  <MUIGrid item xs={12} sm={12} md={12}>
                    <Input
                      placeholder="Username"
                      sx={{ width: '100%' }}
                      value={internalForm.userName}
                      required
                      error={internalFormErrors.userName}
                      onChange={(value) => inputHandler('userName', value)}
                    />
                  </MUIGrid>
                )}
                {['dhl_express', 'ups', 'dhl_ecommerce', 'gls_us'].includes(
                  internalForm.objectId,
                ) && (
                  <MUIGrid item xs={12} sm={12} md={12}>
                    <Input
                      placeholder="Password"
                      type="password"
                      sx={{ width: '100%' }}
                      value={internalForm.password}
                      required
                      error={internalFormErrors.password}
                      onChange={(value) => inputHandler('password', value)}
                    />
                  </MUIGrid>
                )}

                {['dhl_express'].includes(internalForm.objectId) && (
                  <MUIGrid item xs={12} sm={12} md={12}>
                    <Input
                      placeholder="Site ID"
                      sx={{ width: '100%' }}
                      value={internalForm.siteId}
                      required
                      error={internalFormErrors.siteId}
                      onChange={(value) => inputHandler('siteId', value)}
                    />
                  </MUIGrid>
                )}
                {['dhl_ecommerce'].includes(internalForm.objectId) && (
                  <MUIGrid item xs={12} sm={12} md={12}>
                    <Input
                      placeholder="Pickup Number"
                      sx={{ width: '100%' }}
                      value={internalForm.pickupNumber}
                      required
                      error={internalFormErrors.pickupNumber}
                      onChange={(value) => inputHandler('pickupNumber', value)}
                    />
                  </MUIGrid>
                )}
                {['dhl_ecommerce'].includes(internalForm.objectId) && (
                  <MUIGrid item xs={12} sm={12} md={12}>
                    <Input
                      placeholder="Facility Code"
                      sx={{ width: '100%' }}
                      value={internalForm.facilityCode}
                      required
                      error={internalFormErrors.facilityCode}
                      onChange={(value) => inputHandler('facilityCode', value)}
                    />
                  </MUIGrid>
                )}
                {['fedex', 'ups'].includes(internalForm.objectId) && (
                  <>
                    <MUIGrid item xs={12} sm={12} md={12}>
                      <Typography variant="h6" fontWeight="bold">
                        Account Contact
                      </Typography>
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12}>
                      <Input
                        placeholder="First Name"
                        sx={{ width: '100%' }}
                        value={internalForm.contactInformation.firstName}
                        required
                        error={internalFormErrors.firstName}
                        onChange={(value) =>
                          inputHandler('contactInformation.firstName', value)
                        }
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12}>
                      <Input
                        placeholder="Last Name"
                        sx={{ width: '100%' }}
                        value={internalForm.contactInformation.lastName}
                        required
                        error={internalFormErrors.lastName}
                        onChange={(value) =>
                          inputHandler('contactInformation.lastName', value)
                        }
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12}>
                      <Input
                        placeholder="Phone number"
                        sx={{ width: '100%' }}
                        value={internalForm.contactInformation.phoneNumber}
                        required
                        error={internalFormErrors.phoneNumber}
                        onChange={(value) =>
                          inputHandler('contactInformation.phoneNumber', value)
                        }
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12}>
                      <Typography variant="h6" fontWeight="bold">
                        Business/Returns Address
                      </Typography>
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12}>
                      <Input
                        placeholder="Street"
                        sx={{ width: '100%' }}
                        value={internalForm.shippingAddress.street}
                        required
                        error={internalFormErrors.street}
                        onChange={(value) =>
                          inputHandler('shippingAddress.street', value)
                        }
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12}>
                      <Input
                        placeholder="City"
                        sx={{ width: '100%' }}
                        value={internalForm.shippingAddress.city}
                        required
                        error={internalFormErrors.city}
                        onChange={(value) =>
                          inputHandler('shippingAddress.city', value)
                        }
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12}>
                      <Input
                        placeholder="State/Province"
                        sx={{ width: '100%' }}
                        value={internalForm.shippingAddress.state}
                        required
                        error={internalFormErrors.state}
                        onChange={(value) =>
                          inputHandler('shippingAddress.state', value)
                        }
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12}>
                      <Input
                        placeholder="Zip/Postal Code"
                        sx={{ width: '100%' }}
                        value={internalForm.shippingAddress.postalCode}
                        required
                        error={internalFormErrors.postalCode}
                        onChange={(value) =>
                          inputHandler('shippingAddress.postalCode', value)
                        }
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12}>
                      <Autocomplete
                        disableClearable
                        sx={{ width: '100%' }}
                        options={countries}
                        getOptionLabel={(option) =>
                          option.label ? option.label : ''
                        }
                        isOptionEqualToValue={(option, selected) =>
                          option.value === selected.value
                        }
                        size="small"
                        value={internalForm.shippingAddress.countryInternal}
                        onChange={(
                          event: any,
                          value: AutoCompleteOptionType | null,
                        ) => {
                          inputHandler(
                            'shippingAddress.countryInternal',
                            value,
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country"
                            required
                            error={internalFormErrors.country}
                          />
                        )}
                      />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={12} md={12}>
                      <Input
                        placeholder="SmartPost Hub ID"
                        sx={{ width: '100%' }}
                        value={internalForm.shippingAddress.smartPostHubId}
                        onChange={(value) =>
                          inputHandler('shippingAddress.smartPostHubId', value)
                        }
                      />
                    </MUIGrid>
                  </>
                )}
              </MUIGrid>
            </Box>
          )}
          <Box>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              sx={{ width: '50%', float: 'left' }}
            >
              <MUIGrid item xs={2}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleCancelButton()}
                >
                  Cancel
                </Button>
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              direction="row"
              justifyContent="right"
              sx={{ width: '50%', marginRight: '-30px' }}
            >
              <MUIGrid item xs={8}>
                <Button variant="outlined" size="small" onClick={() => save()}>
                  {internalForm.editing ? 'Update Account' : 'Connect Account'}
                </Button>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
