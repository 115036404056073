import React, { useContext, useState, ChangeEvent, useEffect } from 'react';

import Input from 'components/input';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import {
  getCustomerFacilityZones,
  createCustomerBin,
  editCustomerBinData,
  getBillingType,
} from 'services/api/customerfacilities/customerfacilities.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {
  Modal,
  Box,
  Typography,
  Autocomplete,
  TextField,
  IconButton,
  Tooltip,
  Divider,
  InputAdornment,
  Checkbox,
  Button,
  Grid as MUIGrid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { height } from '@mui/system';

import { MUIContainer, MUIContent } from '../editreceiptmodal';

type AutocompleteBase = {
  value?: number;
  label?: string;
};

type ZoneType = AutocompleteBase;
type CategoryType = AutocompleteBase;
type SizeType = AutocompleteBase;

interface ICreateBinModalProps {
  binData?: any;
  resetData?: () => void;
}

const binCategoryOptions: CategoryType[] = [
  {
    value: 1,
    label: 'Unassigned',
  },
  {
    value: 2,
    label: 'Picking',
  },
  {
    value: 3,
    label: 'Bulk',
  },
  {
    value: 4,
    label: 'Zero Inventory',
  },
  {
    value: 5,
    label: 'Cross Dock',
  },
];

const binSizeOptions: SizeType[] = [
  {
    value: 1,
    label: 'Small',
  },
  {
    value: 2,
    label: 'Medium',
  },
  {
    value: 3,
    label: 'Large',
  },
  {
    value: 4,
    label: 'XLarge',
  },
];

export default React.memo(({ binData, resetData }: ICreateBinModalProps) => {
  const theme = useTheme();
  const { isCreateBinModalOpen, onCloseCreateBinModal, handleUpdateData } =
    useContext(GlobalContext);
  const { currentLocationAndFacility } = useContext(AuthContext);
  const [error, setError] = useState('');

  const initialState: any = {
    customerLocationId: currentLocationAndFacility.customerLocationId,
    zoneId: '',
    name: '',
    category: '',
    binSize: null,
    isAllowpicking: true,
  };

  const initialFormErrorsState: any = {
    customerLocationId: currentLocationAndFacility.customerLocationId,
    zoneId: '',
    name: '',
    category: '',
  };

  const [form, setForm] = useState<any>(initialState);
  const [formErrors, setFormErrors] = useState(initialFormErrorsState);
  const [optionZone, setOptionZone] = useState<ZoneType[]>([]);
  const [zoneValue, setZoneValue] = useState<any>({
    value: '',
    label: '',
  });

  const [optionCategory, setOptionCategory] = useState<CategoryType[]>([]);
  const [categoryValue, setCategoryValue] = useState<CategoryType>({
    value: 0,
    label: '',
  });

  const [optionBillingType, setOptionBillingType] = useState<any>([]);
  const [billTypeValue, setBillTypeValue] = useState<any>(null);

  const validateOnlick = (fieldValues = form) => {
    const temp: any = {};

    if ('name' in fieldValues) {
      temp.name = fieldValues.name ? '' : 'This field is required';
    }

    temp.zoneId = zoneValue.value ? '' : 'This field is required';
    temp.category = categoryValue.value !== 0 ? '' : 'This field is required';

    setFormErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleCloseCreateBinModal = () => {
    setForm(initialState);
    setFormErrors(initialFormErrorsState);
    setCategoryValue({
      value: 0,
      label: '',
    });
    setZoneValue({
      value: 0,
      label: '',
    });
    setBillTypeValue(null);
    setForm((prevState) => ({
      ...prevState,
      billingType: null,
    }));
    resetData();
    onCloseCreateBinModal();
  };

  const onSaveNewBin = async () => {
    if (validateOnlick()) {
      try {
        const newForm: any = {};
        newForm.binId = binData.binId;
        newForm.name = form.name;
        newForm.zoneId = zoneValue.value;
        newForm.category = categoryValue.value;
        newForm.billingType =
          billTypeValue === null && form.billingType !== undefined
            ? null
            : form.billingType;
        newForm.aisle = form.aisle;
        newForm.row = form.row;
        newForm.shelf = form.shelf;
        newForm.level = form.level;
        newForm.sequenceNumber = form.sequenceNumber;
        newForm.binSize = form.binSize?.value;
        newForm.isAllowPicking = form.isAllowpicking;
        newForm.length = form?.length;
        newForm.width = form?.width;
        newForm.height = form?.height;

        if (binData.binId !== '') {
          await editCustomerBinData(binData.binId, newForm);
          snackActions.success(
            `Successfully edited bin from ${currentLocationAndFacility.locationName} in ${currentLocationAndFacility.facilityName}.`,
          );
        } else {
          newForm.customerLocationId =
            currentLocationAndFacility.customerLocationId;
          newForm.billingType =
            billTypeValue === null && form.billingType !== undefined
              ? null
              : form.billingType;
          await createCustomerBin(newForm);
          snackActions.success(
            `Successfully created new bin for ${currentLocationAndFacility.locationName} in ${currentLocationAndFacility.facilityName}.`,
          );
        }
        handleCloseCreateBinModal();
        handleUpdateData();
      } catch (err: any) {
        setError(err);
        snackActions.error(`${err}`);
      }
    }
  };

  const onLoadCustomerZones = async () => {
    try {
      const customerZonesFromApi = await getCustomerFacilityZones(
        currentLocationAndFacility.customerLocationId,
      );

      setOptionZone(
        customerZonesFromApi.map((v) => ({
          value: v.zoneId,
          label: v.name,
        })),
      );

      setOptionCategory(
        binCategoryOptions.map((v) => ({
          value: v.value,
          label: v.label,
        })),
      );

      return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadBillingTypeLookup = async () => {
    try {
      const countriesLookupFromApi = await getBillingType();
      return countriesLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (isCreateBinModalOpen) {
      const optBillingType = onLoadBillingTypeLookup();
      optBillingType.then((opt: any) => {
        setOptionBillingType(
          opt.map((c: any) => ({
            label: c.label,
            value: c.value,
          })),
        );
      });
    }
  }, [isCreateBinModalOpen]);

  useEffect(() => {
    if (isCreateBinModalOpen) {
      onLoadCustomerZones();
      if (binData.binId !== '') {
        setForm((prev) => ({
          ...prev,
          name: binData.name,
          aisle: binData.aisle,
          row: binData.row,
          shelf: binData.shelf,
          level: binData.level,
          sequenceNumber: binData.sequenceNumber,
          binSize: binData?.binSize
            ? {
                value: binData?.binSize,
                label: binData?.binSizeStr,
              }
            : null,
          isAllowpicking: binData.isAllowPicking,
          length: binData?.length,
          height: binData?.height,
          width: binData?.width,
        }));
        const customerZone = optionZone.find((z) => z.value === binData.zoneId);
        setZoneValue(customerZone);
      }

      if (binData.category !== 0) {
        const category = optionCategory.find(
          (z) => z.value === binData.category,
        );
        setCategoryValue((prev) => ({
          ...prev,
          ...category,
        }));
      }

      const billing = (el: any) => el.value === binData.billingType;

      const vmBilling = optionBillingType.find(billing);
      if (vmBilling !== undefined) {
        setBillTypeValue(vmBilling);
      } else {
        setBillTypeValue(null);
      }
    }
  }, [currentLocationAndFacility, binData, isCreateBinModalOpen]);

  return (
    <Modal open={isCreateBinModalOpen}>
      <MUIContainer sx={{ maxWidth: '500px !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          {binData.binId === ''
            ? 'Add Bin Location'
            : `Edit Bin Location From ${currentLocationAndFacility.facilityName}`}
        </Typography>
        <MUIContent sx={{ padding: '5px 20px !important ' }}>
          <Box autoComplete="off" component="form" noValidate>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <Input
                  sx={{ width: '100%' }}
                  placeholder="Bin Location Name"
                  value={form.name}
                  error={formErrors.name}
                  onChange={(event) => {
                    setForm((prev) => ({
                      ...prev,
                      name: event.target.value,
                    }));
                  }}
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <Autocomplete
                  id="controllable-states"
                  options={optionZone}
                  getOptionLabel={(option: ZoneType) => option.label}
                  value={zoneValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  onChange={(event: any, newValue: ZoneType) => {
                    if (newValue !== null) {
                      setZoneValue(newValue);
                      setForm((prevState) => ({
                        ...prevState,
                        zoneId: newValue.value,
                      }));
                    }
                  }}
                  renderInput={(params) =>
                    formErrors.zoneId ? (
                      <TextField
                        {...params}
                        size="small"
                        error
                        helperText={formErrors.zoneId}
                        label="Area"
                        sx={{ width: '100%' }}
                      />
                    ) : (
                      <TextField {...params} label="Area" size="small" />
                    )
                  }
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={6}>
                    <Autocomplete
                      id="controllable-states"
                      options={optionCategory}
                      value={categoryValue}
                      getOptionLabel={(option: CategoryType) => option.label}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      onChange={(event: any, newValue: CategoryType) => {
                        if (newValue !== null) {
                          setCategoryValue(newValue);
                          setForm((prevState) => ({
                            ...prevState,
                            catergory: newValue.value,
                          }));
                        }
                      }}
                      renderInput={(params) =>
                        formErrors.category ? (
                          <TextField
                            size="small"
                            {...params}
                            error
                            helperText={formErrors.category}
                            label="Category"
                          />
                        ) : (
                          <TextField
                            {...params}
                            label="Category"
                            size="small"
                          />
                        )
                      }
                    />
                  </MUIGrid>

                  <MUIGrid
                    item
                    xs={6}
                    sx={{
                      paddingLeft: '0px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox
                      checked={form?.isAllowpicking}
                      onChange={(e) => {
                        setForm((prev) => ({
                          ...prev,
                          isAllowpicking: e.target.checked,
                        }));
                      }}
                    />
                    <Typography variant="subtitle2">
                      Allow Picking from this location
                    </Typography>
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
              <MUIGrid item xs={12}>
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={6}>
                    <TextField
                      sx={{ width: '100%' }}
                      label="Sequence No."
                      size="small"
                      type="number"
                      value={form.sequenceNumber}
                      error={formErrors.sequenceNumber}
                      onChange={(event) => {
                        setForm((prev) => ({
                          ...prev,
                          sequenceNumber: event.target.value,
                        }));
                      }}
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                <h5>
                                  This field is used for customizing how bin
                                  locations are sequenced during picking. Packem
                                  uses the Aisle and Row and Bin Location name
                                  in an Alpha-numeric order. But you can use the
                                  numeric sequence number field to customize the
                                  ordering.
                                </h5>
                              }
                            >
                              <IconButton
                                aria-label="print"
                                color="primary"
                                sx={{ margin: '0px' }}
                              >
                                <HelpOutlineOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={6}>
                    <Autocomplete
                      id="controllable-states"
                      options={binSizeOptions}
                      value={form?.binSize || null}
                      getOptionLabel={(option: SizeType) => option.label}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      onChange={(event: any, newValue: SizeType) => {
                        if (newValue !== null) {
                          setForm((prevState) => ({
                            ...prevState,
                            binSize: newValue,
                          }));
                        } else {
                          setForm((prevState) => ({
                            ...prevState,
                            binSize: null,
                          }));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Bin Size" size="small" />
                      )}
                    />
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
              <MUIGrid item xs={12}>
                <Divider textAlign="left">
                  Bin Position
                  <Tooltip
                    title="If bin position is filled. 'Location Name' should be Aisle-Row-Shelf-Level-Bin."
                    placement="bottom"
                    arrow
                  >
                    <IconButton aria-label="print" color="primary">
                      <HelpOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Divider>
              </MUIGrid>
              <MUIGrid item xs={12}>
                <Box
                  sx={{
                    display: 'grid',
                    gap: 1,
                    gridTemplateColumns: 'repeat(2, 1fr)',
                  }}
                >
                  <Input
                    placeholder="Aisle"
                    value={form.aisle}
                    error={formErrors.aisle}
                    onChange={(event) => {
                      setForm((prev) => ({
                        ...prev,
                        aisle: event.target.value,
                      }));
                    }}
                  />
                  <Input
                    placeholder="Row"
                    value={form.row}
                    error={formErrors.row}
                    onChange={(event) => {
                      setForm((prev) => ({
                        ...prev,
                        row: event.target.value,
                      }));
                    }}
                  />
                  <Input
                    placeholder="Shelf"
                    value={form.shelf}
                    error={formErrors.shelf}
                    onChange={(event) => {
                      setForm((prev) => ({
                        ...prev,
                        shelf: event.target.value,
                      }));
                    }}
                  />
                  <Input
                    placeholder="Level"
                    value={form.level}
                    error={formErrors.level}
                    onChange={(event) => {
                      setForm((prev) => ({
                        ...prev,
                        level: event.target.value,
                      }));
                    }}
                  />
                </Box>
              </MUIGrid>

              <MUIGrid item xs={12}>
                <Divider textAlign="left">Dimensions</Divider>
              </MUIGrid>

              <MUIGrid item xs={4}>
                <Input
                  placeholder="Length"
                  value={form?.length}
                  error={formErrors?.length}
                  onChange={(e) => {
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setForm((prev) => ({
                      ...prev,
                      length: validateDecimal,
                    }));
                  }}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Input
                  placeholder="Width"
                  value={form?.width}
                  error={formErrors?.width}
                  onChange={(e) => {
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setForm((prev) => ({
                      ...prev,
                      width: validateDecimal,
                    }));
                  }}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Input
                  placeholder="Height"
                  value={form?.height}
                  error={formErrors?.height}
                  onChange={(e) => {
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setForm((prev) => ({
                      ...prev,
                      height: validateDecimal,
                    }));
                  }}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
            alignItems: 'center',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={5}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                onClick={() => handleCloseCreateBinModal()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                onClick={() => onSaveNewBin()}
              >
                {binData.binId === '' ? 'Save' : 'Edit'}
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
