import React, { useEffect, useContext, useState, useRef } from 'react';

import Button from 'components/button';
import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { createOrderNote } from 'services/api/salesorders/salesorders-new.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  TextField,
  Divider,
  Grid as MUIGrid,
  Autocomplete,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

import { useSalesOrderContext } from '../salesorder/context';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;
    width: 100%;
    max-width: 400px;
    border-radius: 4px;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;

const OrderNotesModal = () => {
  const {
    salesOrderForm,
    setOrderNotesModalOpen,
    orderNotesModalOpen,
    loadOrderNotes,
  } = useSalesOrderContext();

  type AutocompleteBase = {
    label?: string;
    value?: string;
  };

  const [orderNote, setOrderNote] = useState('');
  const [noteTypeValue, setNoteTypeValue] = useState<AutocompleteBase>(null);

  const noteOptions: AutocompleteBase[] = [
    {
      label: 'Order Notes',
      value: 'Order',
    },
    {
      label: 'Picking Instructions',
      value: 'Picking',
    },
    {
      label: 'Packing Instructions',
      value: 'Packing',
    },
    {
      label: 'Carrier Notes',
      value: 'Carrier',
    },
  ];

  const handleModalClose = () => {
    setOrderNote('');
    setNoteTypeValue(null);
    setOrderNotesModalOpen(false);
  };

  const saveOrderNote = () => {
    createOrderNote(salesOrderForm.orderId, {
      note: orderNote,
      category: noteTypeValue.value,
    })
      .then((res) => {
        snackActions.success('Note created.');
        loadOrderNotes();
        handleModalClose();
      })
      .catch((err) => snackActions.error(err));
  };

  return (
    <Modal
      open={orderNotesModalOpen}
      onClose={() => {
        handleModalClose();
      }}
    >
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Order Note
          </Typography>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12}>
                <Autocomplete
                  onChange={(event: any, newValue: AutocompleteBase) => {
                    if (newValue !== null) {
                      setNoteTypeValue(newValue);
                    } else {
                      setNoteTypeValue(null);
                    }
                  }}
                  id="controllable-states"
                  options={noteOptions}
                  getOptionLabel={(option: AutocompleteBase) =>
                    option.label ? option.label : ''
                  }
                  value={noteTypeValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li data-name="carrierType" {...props}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      label="Category"
                      autoComplete="off"
                      id="outlined"
                      size="small"
                      sx={{ width: '100%', background: '#ffffff' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12}>
                <TextField
                  placeholder="Note"
                  maxRows={10}
                  multiline
                  sx={{ width: '100%' }}
                  value={orderNote}
                  onChange={(e) => {
                    setOrderNote(e.target.value);
                  }}
                  required
                />
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => {
                      handleModalClose();
                    }}
                    variant="outlined"
                    size="small"
                    sx={{ width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{ width: '100%' }}
                    onClick={(e) => {
                      saveOrderNote();
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
};

export default OrderNotesModal;
