import React, { FC, useState, useContext, ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserPermissionEnum } from 'common/enums';
import { FormLoginContainer, FormLoginLogo } from 'components/forms/style';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import { ModalReturnLineItem } from 'pages/sales/return-line-item-modal';
import ResetPasswordModal from 'pages/shared/resetpasswordmodal';
import {
  login as apiLogin,
  getDefaultLocationAndFacility,
} from 'services/api/auth/auth.api';
import { getCustomerLogoFromLogin } from 'services/api/customer/customer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import * as yup from 'yup';

import {
  AccountCircle,
  Lock,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  Grid as MUIGrid,
} from '@mui/material';

import {
  LoginContainer,
  LoginBackground,
  LoginBackgroundMesh,
} from '../styles';

export type QueryStringType = {
  customer: string;
};

function Login() {
  const query = useQuery();
  const navigate = useNavigate();

  const {
    currentUser,
    isAuth,
    useStorage,
    authorized,
    login,
    setFacilityAndLocation,
    handleSetAuthorized,
    handleUserPermissionAllow,
  } = useContext(AuthContext);

  const { onOpenResetPasswordModal } = useContext(GlobalContext);

  const [customerData, setCustomerData] = useState(null);
  const [formErrors, setFormErrors] = useState({
    username: '',
    password: '',
  });

  const [form, setForm] = useState({
    username: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [error, setError] = useState('');
  const [formError, setFormError] = useState(null);

  const initialStateOfQueryParams: QueryStringType = {
    customer: query.get('customer'),
  };
  // const { onCloseProductionReportModal } = useContext(GlobalContext);
  const [queryStringLogin, setQueryStringLogin] = useState(
    initialStateOfQueryParams,
  );
  const validateLogin = () => {
    const temp: any = {};
    temp.username = form?.username ? '' : 'This field is required';

    temp.password = form?.password ? '' : 'This field is required';

    setFormError({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const onChangeValue = (key, text) => {
    setForm(() => ({
      ...form,
      [key]: text,
    }));
  };

  const handleLogin = async () => {
    if (!validateLogin()) {
      return;
    }

    try {
      apiLogin(form)
        .then((user) => {
          login(user.token);
          if (user.userData) {
            getDefaultLocationAndFacility(user.userData)
              .then((locationAndFacility) => {
                setFacilityAndLocation(locationAndFacility);
                handleSetAuthorized();
              })
              .catch((err) => {
                snackActions.error(err);
                setShowErrors(true);
              });
          }
        })
        .catch((err) => {
          snackActions.error(err);
          setShowErrors(true);
        });
    } catch (err: any) {
      snackActions.error(err);
      setShowErrors(true);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const getCustomerLogoFromLoginHandler = async () => {
    await getCustomerLogoFromLogin(queryStringLogin.customer)
      .then((opt) => {
        setCustomerData((v) => ({
          ...v,
          customerLogo: opt?.customerLogo,
          customerName: opt?.customerName,
        }));
      })
      .catch((err) => console.log(err));
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isAuth) {
      switch (currentUser.Claim_UserRole) {
        case '1':
          navigate(`/customer-management`);
          break;
        case '4':
          navigate(`/license-plates/license-plates-assignment`);
          break;
        case '5':
          navigate(`/customer-inventory`);
          break;
        case '9':
          navigate(
            `${
              handleUserPermissionAllow(UserPermissionEnum.Dashboard_View)
                ? '/warehouse-customer'
                : '/warehouse-customer-inventory'
            }`,
          );
          break;
        case '10':
          navigate(`/supplier-purchase-order`);
          break;
        default:
          navigate(`/dashboard`);
      }
    }
  }, [authorized, currentUser, useStorage]);

  useEffect(() => {
    getCustomerLogoFromLoginHandler();
  }, []);

  return (
    <LoginContainer>
      <ResetPasswordModal />
      <LoginBackgroundMesh>
        <MUIGrid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <MUIGrid
            item
            xs={12}
            md={7}
            sx={{ padding: '8% !important', color: '#f5f1f1' }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '2rem', md: '3rem' },
                fontFamily: 'inherit',
              }}
            >
              Welcome to
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '2.5rem', md: '3.6rem' },
                fontWeight: 700,
                fontFamily: 'inherit',
                marginBottom: '10px',
                overflowWrap: 'anywhere',
              }}
            >
              {customerData?.customerName || 'PackemWMS'} Portal
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: '1.3rem',
                fontFamily: 'inherit',
                fontWeight: 200,
              }}
            >
              Where you can access inventory, order and
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: '1.3rem',
                fontFamily: 'inherit',
                fontWeight: 200,
              }}
            >
              warehouse operation data.
            </Typography>
          </MUIGrid>

          <MUIGrid item xs={12} md={5}>
            <Box component="form" className="loginFormContainer">
              {customerData?.customerLogo ? (
                <input
                  disabled
                  type="image"
                  src={`data:image/jpeg;base64,${customerData?.customerLogo}`}
                  alt="preview"
                  width={70}
                  height={30}
                  accept="image/*"
                />
              ) : (
                <FormLoginLogo />
              )}
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 'bold',
                  fontFamily: 'inherit',
                  fontSize: '1.5rem',
                  marginTop: '20px',
                }}
              >
                Admin & Customer Portal
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: '30px',
                  fontFamily: 'inherit',
                  fontSize: '0.9rem',
                  color: '#a3aaaf',
                }}
              >
                Enter your username and password to login.
              </Typography>

              <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                <InputLabel htmlFor="outlined-adornment-username">
                  Username
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-username"
                  type="text"
                  label="Username"
                  autoFocus
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  }
                  value={form.username}
                  onChange={(e) => onChangeValue('username', e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleLogin();
                    }
                  }}
                  error={formError?.username}
                />
              </FormControl>

              <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  startAdornment={
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  value={form.password}
                  onChange={(e) => onChangeValue('password', e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleLogin();
                    }
                  }}
                  error={formError?.password}
                />
              </FormControl>

              <Button
                variant="contained"
                size="large"
                sx={{
                  padding: '15px',
                  backgroundColor: '#176bf4',
                  textTransform: 'Capitalize',
                  fontFamily: 'inherit',
                  width: '100%',
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleLogin();
                  }
                }}
                onClick={handleLogin}
              >
                Login
              </Button>

              <Typography
                sx={{
                  cursor: 'pointer',
                  color: '#176bf4',
                  fontFamily: 'inherit',
                  fontWeight: 500,
                  marginTop: '30px',
                  marginBottom: '20px',
                }}
                onClick={onOpenResetPasswordModal}
              >
                Reset Password
              </Typography>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </LoginBackgroundMesh>
    </LoginContainer>
  );
}

export default React.memo(Login);
