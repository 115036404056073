import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';

import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import {
  getAllDropdown,
  getKitdetails,
  createProduction,
  editProduction,
  getProductionDetails,
  changeProductionJobStatus,
  getPickTicketDetail,
  getPickTicketLineItemListForNewPickTicket,
  postPickTicketLineItemListForNewPickTicket,
  ProductionEditPickTicket,
  getProductionKitSkuByWarehouseCustomerId,
  ProductionReportCreate,
  ProductionReportEdit,
  GetProductionReportById,
  GetCreateLicensePlateKnownData,
  CreateLicensePlateKnown,
  GetEditLicensePlateKnownData,
  EditLicensePlateKnown,
  GetLocationPutAwayLicensePlateKnownData,
  CreatePutAwayLicensePlateKnown,
  createJobKitBuild,
} from 'services/api/production/production.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

// //Utilities
function isNumberKey(evt) {
  const a = /^\d*\.?\d*$/;
  const regEx = new RegExp(a);
  if (regEx.test(evt.target.value)) {
    return true;
  }
  return false;
}

type AutocompleteBase = {
  label?: string;
  value?: number;
};

type CustomerType = AutocompleteBase;
type JobLocationType = AutocompleteBase;
type KitSkuType = AutocompleteBase;
type SupplierType = AutocompleteBase;
type LotType = AutocompleteBase;
type LocationType = AutocompleteBase;

type PageViewerType = {
  isNew: () => boolean;
  isView?: () => boolean;
  isEdit?: () => boolean;
  isViewOrEdit: () => boolean;
};
enum JobStatus {
  New = 1,
  Open = 2,
  OnHold = 3,
  Complete = 4,
  Canceled = 5,
  Close = 6,
}
enum FormQueryStringStatus {
  View = 'view',
  New = 'new',
  Edit = 'edit',
}
type QueryStringType = {
  form: string;
  id: number;
};
interface IProductionJob {
  isFormDisable: boolean;
  isPickTicketItemEdit: boolean;
  isProductReportEdit: boolean;
  isProductLicensePlateEdit: boolean;
  dataSelected: IProductionPickTicketType;
  pageViewer?: PageViewerType;
  productionFormErrors: any;
  queryStringItemProduction: any;
  productionPickTicketItemModalRows: any;
  productionForm: IProductionForm;
  productionJobFlagStatus: IProductionDropdown;
  productionReport: IProductionReportType[];
  productionReportModal: any;
  setProductionReportModal: any;
  productionLicensePlateModal: any;
  productionLicensePlateFormErrors: any;
  setProductionLicensePlateModal: any;
  optionCustomers: CustomerType[];
  optionSuppliers: SupplierType[];
  optionLots: LotType[];
  optionCustomerValue: CustomerType;
  optionJobLocations: JobLocationType[];
  optionJobLocationValue: JobLocationType;
  optionKitSkuValue: KitSkuType;
  optionKitSkus: KitSkuType[];
  optionLocations: LocationType[];
  kitDetailsLineItem: IProductionKitDetailsLineItem[];
  productionPickTicketItems: IProductionPickTicketType[];
  productionLicensePlateItems: IProductionLicensePlateType[];
  setIsPickTicketItemEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setIsProductLicensePlateEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setOptionCustomerValue: React.Dispatch<React.SetStateAction<CustomerType>>;
  setOptionJobLocationValue: React.Dispatch<
    React.SetStateAction<JobLocationType>
  >;
  setOptionKitSkuValue: React.Dispatch<React.SetStateAction<KitSkuType>>;
  setOptionLots: React.Dispatch<React.SetStateAction<LotType[] | null | []>>;
  setProductionForm: React.Dispatch<React.SetStateAction<IProductionForm>>;
  setOptionKitSkus: React.Dispatch<
    React.SetStateAction<KitSkuType[] | null | []>
  >;
  setDataSelected: React.Dispatch<
    React.SetStateAction<IProductionPickTicketType>
  >;
  setProductionPickTicketItems: React.Dispatch<
    React.SetStateAction<IProductionPickTicketType[] | []>
  >;
  setProductionLicensePlateItems: React.Dispatch<
    React.SetStateAction<IProductionLicensePlateType[] | []>
  >;

  setProductionPickTicketItemModalRows: React.Dispatch<
    React.SetStateAction<IProductionPickTicketLineItemType[] | []>
  >;
  setProductionReport: React.Dispatch<
    React.SetStateAction<IProductionReportType[] | []>
  >;
  productionFormOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  createProductionJobOnClick: (e: React.SyntheticEvent) => Promise<void>;
  changeStatusSalesOrderOnClick: (e: React.SyntheticEvent, id: number) => void;
  OnSavePostPickTicketLineItemListForNewPickTicket: (
    e: React.SyntheticEvent,
  ) => void;

  // operator
  optionOperator: any;
  setOptionOperator: any;
  operatorValue: any;
  setOperatorValue: any;

  OnSavePostProductionReport: (e: React.SyntheticEvent, model: any) => void;
  OnSavePostProductionLicensePlate: (
    e: React.SyntheticEvent,
    model: any,
  ) => void;

  OnSavePostProductionLicensePlatePutAway: (
    e: React.SyntheticEvent,
    model: any,
  ) => void;
  getProductionPickTicketOnClick: () => void;
  editGetPickTicketLineItemListOnClick: (id: number) => void;
  editGetProductionReportOnClick: (id: number) => void;
  editGetPutAwayOnClick: (id: number) => void;
  editGetProductionLicensePlateOnClick: (id: number) => void;
  onPickTicketLineItemListForNewPickTicket: (id: number) => void;
  OnClickGetCreateLicensePlateKnownData: (id: number) => void;
  viewLoadDataProduction: () => void;
  productionReportFormErrors: any;
  setProductionReportFormErrors: any;
  setQueryStringItemProduction: React.Dispatch<
    React.SetStateAction<QueryStringType>
  >;
  setKitBuildForm: React.Dispatch<any>;
  kitBuildForm: any;
  handleCreateJobKitBuiltOnSubmit: (e: React.SyntheticEvent) => Promise<void>;
  setKitsBuiltDataGrid: React.Dispatch<React.SetStateAction<any[]>>;
  kitsBuiltDataGrid: any[];
  setSelectedRowsOnCheckbox: React.Dispatch<any>;
  selectedRowsOnCheckbox: any;
  setKitBuildFormErrors: React.Dispatch<any>;
  kitBuildFormErrors: any;
  kitbuildValidation: () => boolean;
}
interface IProductionForm {
  jobId?: number;
  productionId: number;
  customerId: number;
  customerLocationId: number;
  customerFacilityId: number;
  warehouseCustomerId: number;
  jobNo: string;
  jobReferenceNo: string;
  purchaseOrderNo: string;
  jobLocation: string;
  pricePerKit: string;
  startDate: any;
  endDate: any;
  shipDate: any;
  kitsPerPick: string;
  estimatedKitsPerShift: string;
  estimatedHourlyProduction: string;
  totalJobQuantity: string;
  notes: string;
  kitDetail_KitSKU_EnableForEdit: boolean;
  buildKitButton_Enable: boolean;
  availableTotalBuildKit?: number;
}
interface IProductionDropdown {
  status: number;
  statusStr: string;
}
interface IProductionKitDetailsLineItem {
  componentSKU: string;
  description: string;
  itemId: string;
  qty: string;
  uomStr: string;
}
interface IProductionPickTicketType {
  [x: string]: any;
  PickTicketId?: number;
  PickId?: string;
  Issued?: string;
  Status?: number;
  StatusStr?: string;
  ContextMenu_Edit_Enable?: boolean;
  ContextMenu_Print_Enable?: boolean;
  ContextMenu_Delete_Enable?: boolean;
  availableQtyToBuild?: number;
}
interface IProductionPickTicketLineItemType {
  itemKitComponentId?: number;
  pickTicketLineItemId?: number;
  sku?: string;
  description?: string;
  lotNo?: string;
  uom?: number;
  uomStr?: string;
  quantity?: string;
}
interface IProductionReportType {
  jobProductionReportId?: number;
  supervisor?: string;
  qtyComplete?: string;
  startDateTime?: string;
  startDate?: string;
  startTime?: string;
  completeDateTime?: string;
  completedDate?: string;
  completeTime?: string;
  isUp?: boolean;
  isDown?: boolean;
  actualHourlyProduction?: string;
  contextMenu_Edit_Enable?: boolean;
  contextMenu_Delete_Enable?: boolean;
}
interface IProductionLicensePlateType {
  [x: string]: any;
  LicensePlateId?: number;
  LPN?: string;
  Type?: string;
  Status?: number;
  SKU?: string;
  Location?: string;
  ContextMenu_Edit_Enable?: boolean;
  ContextMenu_PutAway_Enable?: boolean;
  ContextMenu_Delete_Enable?: boolean;
}

type ProductionJobProviderProps = {
  children: React.ReactNode;
};

export {
  type CustomerType,
  type JobLocationType,
  type KitSkuType,
  type SupplierType,
  type LotType,
  type LocationType,
  JobStatus,
};
export {
  isNumberKey,
  type IProductionPickTicketLineItemType,
  type IProductionPickTicketType,
  type IProductionReportType,
  type IProductionLicensePlateType,
};

const ProductionJobContext = createContext<IProductionJob>(
  {} as IProductionJob,
);
export const useProductionContext = () => useContext(ProductionJobContext);

export const ProductionContextProvider = ({
  children,
}: ProductionJobProviderProps) => {
  const { onCloseProductionReportModal } = useContext(GlobalContext);

  const navigate = useNavigate();
  const query = useQuery();

  const { onCloseProductionLicensePlateModal } = useContext(GlobalContext);
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const [optionCustomerValue, setOptionCustomerValue] =
    useState<CustomerType>(null);
  const [optionCustomers, setOptionCustomers] = useState<
    CustomerType[] | null | []
  >([]);
  const [optionSuppliers, setoptionSuppliers] = useState<
    SupplierType[] | null | []
  >([]);

  const [optionLots, setOptionLots] = useState<LotType[] | null | []>([]);
  const [optionLocations, setOptionLocations] = useState<
    LocationType[] | null | []
  >([]);
  const [optionJobLocationValue, setOptionJobLocationValue] =
    useState<JobLocationType>(null);
  const [optionJobLocations, setOptionJobLocations] = useState<
    JobLocationType[] | null | []
  >([]);
  const [optionKitSkuValue, setOptionKitSkuValue] = useState<KitSkuType>(null);
  const [optionKitSkus, setOptionKitSkus] = useState<KitSkuType[] | null | []>(
    [],
  );
  const [productionForm, setProductionForm] = useState<IProductionForm>(null);
  const [productionJobFlagStatus, setProductionJobFlagStatus] =
    useState<IProductionDropdown>(null);
  const [kitDetailsLineItem, setKitDetailsLineItem] = useState<
    IProductionKitDetailsLineItem[] | null | []
  >([]);
  const [isFormDisable, setIsFormDisable] = useState<boolean>(false);
  const [isPickTicketItemEdit, setIsPickTicketItemEdit] =
    useState<boolean>(false);
  const [isProductReportEdit, setIsProductReportEdit] =
    useState<boolean>(false);
  const [isProductLicensePlateEdit, setIsProductLicensePlateEdit] =
    useState<boolean>(false);

  const [editPickTicketId, setEditPickTicketId] = useState<number>();
  const [productionFormErrors, setProductionFormErrors] = useState({});

  const [kitBuildForm, setKitBuildForm] = useState(null);
  const [kitBuildFormErrors, setKitBuildFormErrors] = useState(null);
  const [kitsBuiltDataGrid, setKitsBuiltDataGrid] = useState([]);

  const initialStateOfQueryParams: QueryStringType = {
    form: query.get('form'),
    id: Number(query.get('id')),
  };
  const [queryStringItemProduction, setQueryStringItemProduction] = useState(
    initialStateOfQueryParams,
  );

  const pageViewer: PageViewerType = {
    isNew: () => queryStringItemProduction.form === FormQueryStringStatus.New,
    isView: () =>
      queryStringItemProduction.form === FormQueryStringStatus.View &&
      queryStringItemProduction.id > 0,
    isEdit: () =>
      queryStringItemProduction.form === FormQueryStringStatus.Edit &&
      queryStringItemProduction.id > 0,
    isViewOrEdit: () =>
      (queryStringItemProduction.form === FormQueryStringStatus.View ||
        queryStringItemProduction.form === FormQueryStringStatus.Edit) &&
      queryStringItemProduction.id > 0,
  };

  const [productionPickTicketItems, setProductionPickTicketItems] = useState<
    IProductionPickTicketType[] | []
  >([]);
  const [productionLicensePlateItems, setProductionLicensePlateItems] =
    useState<IProductionLicensePlateType[] | []>([]);

  const [
    productionPickTicketItemModalRows,
    setProductionPickTicketItemModalRows,
  ] = useState<IProductionPickTicketLineItemType[] | []>([]);

  const [dataSelected, setDataSelected] =
    useState<IProductionPickTicketType>(null);

  const [productionReport, setProductionReport] = useState<
    IProductionReportType[] | []
  >(null);

  // operator
  const [operatorValue, setOperatorValue] = useState({
    value: '',
    label: '',
  });
  const [optionOperator, setOptionOperator] = useState([]);
  const [selectedRowsOnCheckbox, setSelectedRowsOnCheckbox] =
    useState<any>(null);

  const initialFormProductReport = {
    customerLocationId: 0,
    jobId: 0,
    qtyComplete: 0,
    startDateTime: '',
    startTime: '',
    completeDateTime: '',
    completeTime: '',
  };

  const [productionReportModal, setProductionReportModal] = useState<any>(
    initialFormProductReport,
  );

  const initialFormProductionLicensePlate = {
    licensePlateId: 0,
    jobId: 0,
    nextInSequence: '',
    nextInSequence_EnableForEdit: false,
    supplierId: {
      label: '',
      value: '',
    },
    supplier_EnableForEdit: false,
    referenceNumber: '',
    referenceNumber_EnableForEdit: false,
    lotId: {
      label: '',
      value: '',
    },
    lotNo_EnableForEdit: false,
    lotExpirationDate: '',
    lotExpirationDate_EnableForEdit: false,
    cases: '',
    cases_EnableForEdit: false,
    eaCase: '',
    eaCase_EnableForEdit: false,
    totalQty: '',
    totalQty_EnableForEdit: false,
    weight: '',
    weight_EnableForEdit: false,
    arrivalDate: '',
    arrivalTime: '',
    arrivalDateTime_EnableForEdit: false,
    warehouseCustomerId: '',
    customerName: '',
    customer_EnableForEdit: false,
    sku: '',
    sku_EnableForEdit: false,
    location: {
      label: '',
      value: '',
    },
  };

  const [productionLicensePlateModal, setProductionLicensePlateModal] =
    useState<any>(initialFormProductionLicensePlate);

  const [
    productionLicensePlateFormErrors,
    setProductionLicensePlateFormErrors,
  ] = useState<any>(initialFormProductionLicensePlate);

  const [productionReportFormErrors, setProductionReportFormErrors] =
    useState<any>(initialFormProductReport);
  const onLoadAllDropdown = async () => {
    try {
      const warehouseCustomersLookupFromApi = await getAllDropdown(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
      );

      return warehouseCustomersLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const getProductionPickTicketOnClick = async () => {
    try {
      const response = getPickTicketLineItemListForNewPickTicket(
        queryStringItemProduction.id,
      );
      response.then((value) => {
        setProductionPickTicketItemModalRows(
          value.map((c: any) => ({
            itemKitComponentId: c.itemKitComponentId,
            sku: c.sku,
            description: c.description,
            lotNo: c.lotNo,
            uom: c.uom,
            uomStr: c.uomStr,
            quantity: c.quantity,
          })),
        );
      });
      setIsPickTicketItemEdit(false);
      return true;
    } catch (err) {
      return err;
    }
  };

  const editGetPickTicketLineItemListOnClick = async (pickTicketId) => {
    try {
      const response = getPickTicketDetail(pickTicketId);
      response.then((value) => {
        setOptionOperator(
          value.assignedByUsers.map((c: any) => ({
            label: c.text,
            value: c.value,
          })),
        );

        if (
          value.assignedByUser === null ||
          value.assignedByUser === undefined
        ) {
          setOperatorValue(null);
        } else {
          setOperatorValue((prevState) => ({
            ...prevState,
            label: value.assignedByUser.text,
            value: value.assignedByUser.value,
          }));
        }

        setProductionPickTicketItemModalRows(
          value.lineItems.map((c: any) => ({
            pickTicketLineItemId: c.pickTicketLineItemId,
            sku: c.sku,
            description: c.description,
            lotNo: c.lotNo,
            uom: c.uom,
            uomStr: c.uomStr,
            quantity: c.quantity,
          })),
        );

        setEditPickTicketId(pickTicketId);
        setIsPickTicketItemEdit(true);
      });
      return true;
    } catch (err) {
      return err;
    }
  };
  const editGetProductionReportOnClick = async (jobProductionReportId) => {
    try {
      const response = GetProductionReportById(jobProductionReportId);
      response.then((value) => {
        setProductionReportModal((prev) => ({
          ...prev,
          jobProductionReportId: value.jobProductionReportId,
          qtyComplete: value.qtyComplete,
          startDateTime: moment(value.startDateTime).format('MM/DD/YYYY'),
          startTime: moment(value.startDateTime).format('HH:mm'),
          completeDateTime: value.completeDateTime,
          completeTime: moment(value.completeDateTime).format('HH:mm'),
        }));
      });
      setIsProductReportEdit(true);
      return true;
    } catch (err) {
      return err;
    }
  };

  const editGetPutAwayOnClick = async (licensePlateIdParam) => {
    try {
      const response = GetLocationPutAwayLicensePlateKnownData(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
        licensePlateIdParam,
      );
      response.then((e) => {
        setOptionLocations(
          e.locationDDL.map((c: any) => ({
            value: c.value,
            label: c.text,
          })),
        );
      });

      setProductionLicensePlateModal((prev) => ({
        ...prev,
        licensePlateId: licensePlateIdParam,
      }));

      return true;
    } catch (err) {
      return err;
    }
  };

  const editGetProductionLicensePlateOnClick = async (licensePlateId) => {
    try {
      setProductionLicensePlateModal(initialFormProductionLicensePlate);

      const response = GetEditLicensePlateKnownData(licensePlateId);
      response.then((value) => {
        setProductionLicensePlateModal((prev) => ({
          ...prev,
          licensePlateId: value.licensePlateId,
          nextInSequence: value.nextInSequence,
          arrivalDate: moment(value.arrivalDateTime).format('MM/DD/YYYY'),
          arrivalTime: moment(value.arrivalDateTime).format('HH:mm'),
          nextInSequence_EnableForEdit: value.nextInSequence_EnableForEdit,
          supplierId: {
            value: value.supplierId ? value.supplierId : '',
            label: value.supplier ? value.supplier : '',
          },
          lotId: {
            value: value.lotId ? value.lotId : '',
            label: value.lotNo ? value.lotNo : '',
          },
          supplier_EnableForEdit: value.supplier_EnableForEdit,
          referenceNumber: value.referenceNumber,
          referenceNumber_EnableForEdit: value.referenceNumber_EnableForEdit,
          lotNo_EnableForEdit: value.lotNo_EnableForEdit,
          lotExpirationDate: value.lotExpirationDate,
          lotExpirationDate_EnableForEdit:
            value.lotExpirationDate_EnableForEdit,
          cases: value.cases,
          cases_EnableForEdit: value.cases_EnableForEdit,
          eaCase: value.eaCase,
          eaCase_EnableForEdit: value.eaCase_EnableForEdit,
          totalQty: value.totalQty,
          totalQty_EnableForEdit: value.totalQty_EnableForEdit,
          weight: value.weight,
          weight_EnableForEdit: value.weight_EnableForEdit,
          arrivalDateTime_EnableForEdit: value.arrivalDateTime_EnableForEdit,
          customerName: value.customer,
          customer_EnableForEdit: value.customer_EnableForEdit,
          sku: value.sku,
          sku_EnableForEdit: value.skU_EnableForEdit,
        }));

        setOptionLots(
          value.lotDDL.map((v: any) => ({
            value: v.lotId,
            label: v.lotNo,
          })),
        );
      });
      setIsProductLicensePlateEdit(true);
      return true;
    } catch (err) {
      return err;
    }
  };

  const OnClickGetCreateLicensePlateKnownData = async (jobId) => {
    try {
      setProductionLicensePlateModal(initialFormProductionLicensePlate);

      const response = GetCreateLicensePlateKnownData(jobId);
      response.then((value) => {
        setProductionLicensePlateModal((prev) => ({
          ...prev,
          nextInSequence: value.nextInSequence,
          customerName: value.customer,
          sku: value.sku,
          arrivalDate: moment(Date().toLocaleString()).format('L'),
          arrivalTime: moment(Date().toLocaleString()).format('HH:mm'),
        }));

        setoptionSuppliers(
          value.supplierDDL.map((v: any) => ({
            value: v.value,
            label: v.text,
          })),
        );

        setOptionLots(
          value.lotDDL.map((v: any) => ({
            value: v.lotId,
            label: v.lotNo,
          })),
        );
      });

      return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadKitDetails = async (itemId) => {
    try {
      const kitDetailsFromApi = await getKitdetails(itemId);

      return kitDetailsFromApi;
    } catch (err) {
      return err;
    }
  };

  const getProductionKitSkusByWarehouseCustomer = async (
    warehouseCustomerId,
  ) => {
    try {
      const kitDetailsFromApi = await getProductionKitSkuByWarehouseCustomerId(
        warehouseCustomerId,
      );
      return kitDetailsFromApi;
    } catch (err) {
      return err;
    }
  };

  const onPickTicketLineItemListForNewPickTicket = async (jobID) => {
    try {
      const pickTicketLineItemsNewFromApi =
        await getPickTicketLineItemListForNewPickTicket(jobID);

      return pickTicketLineItemsNewFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadProductionDetail = async () => {
    try {
      const warehouseCustomersLookupFromApi = await getProductionDetails(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
        queryStringItemProduction.id,
      );

      return warehouseCustomersLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const productionFormValidation = () => {
    const temp: any = {};
    if (optionCustomerValue === null) {
      temp.customer = 'This field is required';
    } else {
      temp.customer = '';
    }

    if (optionJobLocationValue === null) {
      temp.jobLocation = 'This field is required';
    } else {
      temp.jobLocation = '';
    }

    if (optionKitSkuValue === null && optionCustomerValue) {
      temp.kitSku = 'This field is required';
    } else {
      temp.kitSku = '';
    }

    if (productionForm.shipDate === undefined) {
      temp.shipDate = 'This field is required';
    } else {
      temp.shipDate = '';
    }

    temp.totalJobQuantity = productionForm.totalJobQuantity
      ? ''
      : 'This field is required';

    setProductionFormErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };
  const productionFormOnChange = (e: any): void => {
    e.preventDefault();
    const { name, value } = e.target;
    setProductionForm((prev: IProductionForm) => ({
      ...prev,
      [name]: value,
    }));
  };

  const createProductionJobOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    if (!productionFormValidation()) {
      snackActions.error('Please complete required fields');
      return;
    }

    try {
      if (pageViewer.isNew()) {
        const PAYLOAD = {
          customerLocationId: currentLocationAndFacility.customerLocationId,
          customerFacilityId: currentLocationAndFacility.customerFacilityId,
          warehouseCustomerId: optionCustomerValue.value,
          jobNo: productionForm.jobNo, // uniqueness, customer level
          jobReferenceNo: productionForm.jobReferenceNo,
          purchaseOrderNo: productionForm.purchaseOrderNo,
          binId: optionJobLocationValue.value,
          itemId: optionKitSkuValue.value,
          pricePerKit: productionForm.pricePerKit,
          startDate: productionForm.startDate,
          endDate: productionForm.endDate,
          shipDate: productionForm.shipDate,
          kitsPerPick: productionForm.kitsPerPick,
          estimatedKitsPerShift: productionForm.estimatedKitsPerShift,
          estimatedHourlyProduction: productionForm.estimatedHourlyProduction,
          totalJobQuantity: productionForm.totalJobQuantity,
          notes: productionForm.notes,
        };
        try {
          const response = await createProduction(PAYLOAD);
          setQueryStringItemProduction((prev) => ({
            ...prev,
            form: 'view',
          }));
          setProductionForm((prev) => ({
            ...prev,
            jobId: response.jobId,
          }));

          snackActions.success('Successfully created new production job.');
          navigate(`?form=view&id=${response.jobId}`);
          navigate(0);
        } catch (err) {
          snackActions.error(err);
        }
      }

      if (pageViewer.isEdit()) {
        const PAYLOAD = {
          jobId: queryStringItemProduction?.id,
          customerLocationId: currentLocationAndFacility.customerLocationId,
          customerFacilityId: currentLocationAndFacility.customerFacilityId,
          // warehouseCustomerId: optionCustomerValue.value,
          // jobNo: productionForm.jobNo, // uniqueness, customer level
          jobReferenceNo: productionForm.jobReferenceNo,
          purchaseOrderNo: productionForm.purchaseOrderNo,
          binId: optionJobLocationValue.value,
          itemId: optionKitSkuValue.value,
          pricePerKit: productionForm.pricePerKit,
          startDate: productionForm.startDate,
          endDate: productionForm.endDate,
          shipDate: productionForm.shipDate,
          kitsPerPick: productionForm.kitsPerPick,
          estimatedKitsPerShift: productionForm.estimatedKitsPerShift,
          estimatedHourlyProduction: productionForm.estimatedHourlyProduction,
          totalJobQuantity: productionForm.totalJobQuantity,
          notes: productionForm.notes,
        };
        try {
          const response = await editProduction(PAYLOAD);

          setQueryStringItemProduction((prev) => ({
            ...prev,
            form: 'view',
          }));
          setProductionForm((prev) => ({
            ...prev,
            jobId: response.jobId,
          }));
          navigate(`?form=view&id=${response.jobId}`);

          snackActions.success('Successfully updated production job.');
        } catch (err) {
          snackActions.error(err);
        }
      }

      if (pageViewer.isView()) {
        setQueryStringItemProduction((prev) => ({
          ...prev,
          form: 'edit',
        }));
        setProductionForm((prev) => ({
          ...prev,
          jobId: queryStringItemProduction.id,
        }));

        navigate(`?form=edit&id=${queryStringItemProduction.id}`);
      }
    } catch (error) {
      snackActions.error(error);
    }
  };

  const changeStatusSalesOrderOnClick = async (
    e: React.SyntheticEvent,
    id: number,
  ): Promise<void> => {
    e.preventDefault();

    const PAYLOAD = {
      JobId: queryStringItemProduction.id,
      StatusId: id,
    };

    try {
      const response = await changeProductionJobStatus(PAYLOAD);
      setProductionJobFlagStatus((prev) => ({
        ...prev,
        status: response.status,
        statusStr: response.statusStr,
      }));
      snackActions.success('Status updated successfully.');
    } catch (err) {
      snackActions.error(err);
    }
  };

  const viewLoadDataProduction = async () => {
    onLoadProductionDetail().then((job: any) => {
      setProductionForm((prev) => ({
        ...prev,
        jobId: job.jobId,
        // // warehouseCustomerId: 2,
        // // warehouseCustomer: 'Sample Supplier',
        // // status: 1,
        // // statusStr: 'New',
        jobNo: job.jobNo,
        jobReferenceNo: job.jobReferenceNo,
        // // jobLocationId: 25,
        // // jobLocation: 'Zone 2 - BB-Z2-01',

        pricePerKit: job.pricePerKit,
        purchaseOrderNo: job.purchaseOrderNo,

        startDate: job.startDate,

        endDate: job.endDate,

        shipDate: job.shipDate,

        kitsPerPick: job.kitsPerPick,

        estimatedKitsPerShift: job.estimatedKitsPerShift,

        estimatedHourlyProduction: job.estimatedHourlyProduction,

        totalJobQuantity: job.totalJobQuantity,

        notes: job.notes,

        kitDetail_KitSKU_EnableForEdit: job.kitDetail_KitSKU_EnableForEdit,
        buildKitButton_Enable: job?.buildKitButton_Enable,
        availableTotalBuildKit: job?.availableTotalBuildKit,
      }));
      setProductionPickTicketItems(
        job?.pickTickets.map((v: any) => ({
          pickTicketId: v.pickTicketId,
          pickId: v.pickId,
          issued: v.issued,
          status: v.status,
          statusStr: v.statusStr,
          contextMenu_Edit_Enable: v.contextMenu_Edit_Enable,
          contextMenu_Print_Enable: v.contextMenu_Print_Enable,
          contextMenu_Delete_Enable: v.contextMenu_Delete_Enable,
          availableQtyToBuild: v?.availableQtyToBuild,
        })),
      );

      setProductionReport(
        job?.productionReports.map((v: any) => ({
          jobProductionReportId: v.jobProductionReportId,
          supervisor: v.supervisor,
          startDate: v.startDate,
          completedDate: v.completedDate,
          duration: v.duration,
          qtyCompleted: v.qtyCompleted,
          isUp: v.isUp,
          isDown: v.isDown,
          actualHourlyProduction: v.actualHourlyProduction,
          contextMenu_Edit_Enable: v.contextMenu_Edit_Enable,
          contextMenu_Delete_Enable: v.contextMenu_Delete_Enable,
        })),
      );

      setProductionLicensePlateItems(
        job?.licensePlates.map((v: any) => ({
          licensePlateId: v.licensePlateId,
          lpn: v.lpn,
          type: v.type,
          status: v.status,
          sku: v.sku,
          location: v.location,
          contextMenu_Edit_Enable: v.contextMenu_Edit_Enable,
          contextMenu_PutAway_Enable: v.contextMenu_PutAway_Enable,
          contextMenu_Delete_Enable: v.contextMenu_Delete_Enable,
        })),
      );

      setKitsBuiltDataGrid(
        job?.jobKitBuilts.map((v: any) => ({
          jobKitBuiltId: v?.jobKitBuiltId,
          quantity: v?.quantity,
          duration: v?.duration,
          completedDateTime: v?.completedDateTime,
          completedDate: moment(v?.completedDateTime).format('MM/DD/yyyy'),
          completedTime: moment(v?.completedDateTime).format('hh:mm a'),
          isPutAway: v?.isPutAway,
          jobPutAwayStatus: v?.jobPutAwayStatus,
        })),
      );

      setOptionCustomerValue((prev) => ({
        ...prev,
        label: job.warehouseCustomer,
        value: job.warehouseCustomerId,
      }));
      setOptionJobLocationValue((prev) => ({
        ...prev,
        label: job.jobLocation,
        value: job.jobLocationId,
      }));

      setOptionKitSkuValue((prev) => ({
        ...prev,
        label: job.kitDetail.kitSKU,
        value: job.kitDetail.itemId,
      }));

      setProductionJobFlagStatus((prev) => ({
        ...prev,
        status: job.status,
        statusStr: job.statusStr,
      }));
    });
  };

  const OnSavePostPickTicketLineItemListForNewPickTicket = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();
    try {
      const PAYLOAD = {
        PickTicketId: null,
        JobId: queryStringItemProduction.id, // uniqueness, warehouse customer level
        UserId: operatorValue?.value ?? null,
        LineItems: productionPickTicketItemModalRows,
      };

      if (isPickTicketItemEdit) {
        PAYLOAD.PickTicketId = editPickTicketId;

        const response = await ProductionEditPickTicket(PAYLOAD);
        snackActions.success('Successfully updated pict ticket.');

        setIsPickTicketItemEdit(false);
      } else {
        const response = await postPickTicketLineItemListForNewPickTicket(
          PAYLOAD,
        );
        snackActions.success('Successfully created new pict ticket.');
      }

      viewLoadDataProduction();
    } catch (err) {
      snackActions.error(err);
    }
  };

  const productionReportValidation = (fieldValues = productionReportModal) => {
    const temp: any = {};

    if ('qtyComplete' in fieldValues) {
      if (
        fieldValues.qtyComplete === undefined ||
        fieldValues.qtyComplete === null ||
        fieldValues.qtyComplete === ''
      ) {
        temp.qtyComplete = 'Qty complete is required';
      } else {
        temp.qtyComplete =
          fieldValues.qtyComplete !== 0 &&
          Number(fieldValues.qtyComplete) >= 0 &&
          fieldValues.qtyComplete
            ? ''
            : 'Qty complete is required';
      }
    }

    if ('startDateTime' in fieldValues) {
      temp.startDateTime = fieldValues.startDateTime
        ? ''
        : 'Start date is required';
    }

    if ('startTime' in fieldValues) {
      temp.startTime = fieldValues.startTime ? '' : 'Start time is required';
    }

    if ('completeDateTime' in fieldValues) {
      temp.completeDateTime = fieldValues.completeDateTime
        ? ''
        : 'Complete date is required';
    }

    if ('completeTime' in fieldValues) {
      temp.completeTime = fieldValues.completeTime
        ? ''
        : 'Complete time is required';
    }

    setProductionReportFormErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const OnSavePostProductionReport = async (
    e: React.SyntheticEvent,
    model: any,
  ): Promise<void> => {
    e.preventDefault();

    if (productionReportValidation()) {
      try {
        const startDateTimeParam = new Date(
          `${moment(model.startDateTime).format('MM/DD/YYYY')} ${' '} ${moment(
            model.startTime,
            'HH:mm:ss',
          ).format('h:mm:ss A')}`,
        );

        const completeDateTimeParam = new Date(
          `${moment(model.completeDateTime).format(
            'MM/DD/YYYY',
          )} ${' '} ${moment(model.completeTime, 'HH:mm:ss').format(
            'h:mm:ss A',
          )}`,
        );

        const PAYLOAD = {
          JobProductionReportId: '',
          JobId: queryStringItemProduction.id,
          CustomerLocationId: currentLocationAndFacility.customerLocationId,
          QtyComplete: Number(model.qtyComplete),
          startDateTime: moment(startDateTimeParam).format(),
          completeDateTime: moment(completeDateTimeParam).format(),
        };

        if (isProductReportEdit) {
          PAYLOAD.JobProductionReportId =
            productionReportModal.jobProductionReportId;

          const response = await ProductionReportEdit(PAYLOAD);
          snackActions.success('Successfully updated pick ticket.');

          setIsProductReportEdit(false);
        } else {
          const response = await ProductionReportCreate(PAYLOAD);
          snackActions.success('Successfully created new pick ticket.');
        }
        setProductionReportFormErrors('');
        onCloseProductionReportModal();
        viewLoadDataProduction();
      } catch (err) {
        snackActions.error(err);
      }
    }
  };

  const productionLicensePlateValidation = (
    fieldValues = productionLicensePlateModal,
  ) => {
    const temp: any = {};

    if ('totalQty' in fieldValues) {
      if (
        fieldValues.totalQty === undefined ||
        fieldValues.totalQty === null ||
        fieldValues.totalQty === ''
      ) {
        temp.totalQty = 'Total Qty is required';
      } else {
        temp.totalQty =
          fieldValues.totalQty !== 0 &&
          Number(fieldValues.totalQty) >= 0 &&
          fieldValues.totalQty
            ? ''
            : 'Total Qty is required';
      }
    }

    setProductionLicensePlateFormErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const OnSavePostProductionLicensePlate = async (
    e: React.SyntheticEvent,
    model: any,
  ): Promise<void> => {
    e.preventDefault();
    try {
      if (productionLicensePlateValidation()) {
        const arrivalDateTimeParam = new Date(
          `${moment(model.arrivalDate).format('MM/DD/YYYY')} ${' '} ${moment(
            model.arrivalTime,
            'HH:mm:ss',
          ).format('h:mm:ss A')}`,
        );

        const PAYLOAD = {
          licensePlateId: '',
          JobId: queryStringItemProduction.id,
          NextInSequence: model.nextInSequence,
          SupplierId: model.supplierId.value,
          LotId: model.lotId.value ? model.lotId.value : null,
          LotExpirationDate: model.lotExpirationDate
            ? moment(model.lotExpirationDate).format('MM/DD/YYYY')
            : null,
          Cases: model.cases,
          EaCase: model.eaCase,
          TotalQty: model.totalQty,
          Weight: model.weight,
          ReferenceNumber: model.referenceNumber,
          ArrivalDateTime: moment(arrivalDateTimeParam).format(),
        };

        if (isProductLicensePlateEdit) {
          PAYLOAD.licensePlateId = productionLicensePlateModal.licensePlateId;

          await EditLicensePlateKnown(PAYLOAD);
          snackActions.success('Successfully updated license plate number.');

          setIsProductLicensePlateEdit(false);
        } else {
          await CreateLicensePlateKnown(PAYLOAD);
          snackActions.success(
            'Successfully created new license plate number.',
          );
        }
        onCloseProductionLicensePlateModal();

        viewLoadDataProduction();
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  const OnSavePostProductionLicensePlatePutAway = async (
    e: React.SyntheticEvent,
    model: any,
  ): Promise<void> => {
    e.preventDefault();
    try {
      const PAYLOAD = {
        CustomerLocationId: currentLocationAndFacility.customerLocationId,
        CustomerFacilityId: currentLocationAndFacility.customerFacilityId,
        LicensePlateId: productionLicensePlateModal.licensePlateId,
        BinId: productionLicensePlateModal.location.value,
      };

      await CreatePutAwayLicensePlateKnown(PAYLOAD);
      snackActions.success('Successfully created put away.');

      setProductionLicensePlateModal((prev) => ({
        ...prev,
        location: {
          label: '',
          value: '',
        },
      }));

      viewLoadDataProduction();
    } catch (err) {
      snackActions.error(err);
    }
  };

  const kitbuildValidation = () => {
    const temp: any = {};
    temp.qty = kitBuildForm?.qty ? '' : 'This field is required';
    if (productionForm?.availableTotalBuildKit < kitBuildForm?.qty) {
      temp.qty = `Quantity must be less than or equal to ${productionForm?.availableTotalBuildKit}`;
    }
    temp.duration = kitBuildForm?.duration ? '' : 'This field is required';

    setKitBuildFormErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const handleCreateJobKitBuiltOnSubmit = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    const PAYLOAD = {
      jobId: queryStringItemProduction?.id,
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      binId: optionJobLocationValue?.value,
      quantity: kitBuildForm?.qty,
      duration: kitBuildForm?.duration,
      completedDateTime:
        kitBuildForm?.completedDate &&
        moment(
          `${moment(kitBuildForm?.completedDate).format('MM/DD/YYYY')} ${
            kitBuildForm?.completedTime
          }`,
        ).format('MM/DD/YYYY HH:mm'),
    };

    try {
      await createJobKitBuild(PAYLOAD)
        .then(() => {
          viewLoadDataProduction();
          snackActions.success('Successfully created new kit build.');
        })
        .catch((err) => snackActions.error(err));
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    onLoadAllDropdown()
      .then((ddl) => {
        const { jobLocationDDL, warehouseCustomerDDL } = ddl;
        setOptionCustomers(
          warehouseCustomerDDL.map((c: any) => ({
            label: c.text,
            value: c.value,
          })),
        );
        setOptionJobLocations(
          jobLocationDDL.map((c: any) => ({
            label: c.text,
            value: c.value,
          })),
        );
        if (queryStringItemProduction.form === 'new') {
          setProductionForm((prev) => ({
            ...prev,
            jobNo: ddl.jobNo,
          }));
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }, []);

  useEffect(() => {
    if (pageViewer.isView()) {
      setIsFormDisable(true);
      navigate(`?form=view&id=${queryStringItemProduction.id}`);
    }
    if (pageViewer.isEdit()) {
      setIsFormDisable(false);
      navigate(`?form=edit&id=${queryStringItemProduction.id}`);
    }
    if (pageViewer.isViewOrEdit()) {
      viewLoadDataProduction();
    }
  }, [queryStringItemProduction.form]);

  useEffect(() => {
    if (optionKitSkuValue !== null) {
      onLoadKitDetails(optionKitSkuValue?.value).then((x) => {
        const { components } = x;
        setKitDetailsLineItem(
          components.map((component: IProductionKitDetailsLineItem, index) => ({
            id: index,
            componentSKU: component.componentSKU,
            description: component.description,
            itemId: component.itemId,
            qty: component.qty,
            uomStr: component.uomStr,
          })),
        );
      });
    } else {
      setKitDetailsLineItem([]);
    }
  }, [optionKitSkuValue?.value]);

  const value: IProductionJob = useMemo(
    () => ({
      isFormDisable,
      isPickTicketItemEdit,
      isProductReportEdit,
      isProductLicensePlateEdit,
      dataSelected,
      pageViewer,
      productionForm,
      productionFormErrors,
      queryStringItemProduction,
      productionReport,
      productionReportModal,
      productionJobFlagStatus,
      productionPickTicketItems,
      productionLicensePlateItems,
      productionLicensePlateFormErrors,
      productionPickTicketItemModalRows,
      productionLicensePlateModal,
      optionCustomers,
      optionSuppliers,
      optionLots,
      optionCustomerValue,
      optionJobLocations,
      optionJobLocationValue,
      optionKitSkuValue,
      optionKitSkus,
      optionLocations,
      kitDetailsLineItem,
      productionReportFormErrors,
      setIsPickTicketItemEdit,
      setIsProductLicensePlateEdit,
      setOptionJobLocationValue,
      setOptionCustomerValue,
      setOptionKitSkuValue,
      setOptionLots,
      setProductionForm,
      setOptionKitSkus,
      setDataSelected,
      setProductionReport,
      setProductionReportModal,
      setProductionPickTicketItems,
      setProductionLicensePlateItems,
      setProductionPickTicketItemModalRows,
      setProductionLicensePlateModal,
      productionFormOnChange,
      createProductionJobOnClick,
      changeStatusSalesOrderOnClick,
      onPickTicketLineItemListForNewPickTicket,
      OnSavePostPickTicketLineItemListForNewPickTicket,
      OnSavePostProductionReport,
      OnSavePostProductionLicensePlate,
      OnSavePostProductionLicensePlatePutAway,
      getProductionPickTicketOnClick,
      editGetPickTicketLineItemListOnClick,
      editGetProductionReportOnClick,
      editGetPutAwayOnClick,
      editGetProductionLicensePlateOnClick,
      viewLoadDataProduction,
      setProductionReportFormErrors,
      OnClickGetCreateLicensePlateKnownData,
      optionOperator,
      setOptionOperator,
      operatorValue,
      setOperatorValue,
      setQueryStringItemProduction,
      kitBuildForm,
      setKitBuildForm,
      handleCreateJobKitBuiltOnSubmit,
      kitsBuiltDataGrid,
      setKitsBuiltDataGrid,
      selectedRowsOnCheckbox,
      setSelectedRowsOnCheckbox,
      kitBuildFormErrors,
      setKitBuildFormErrors,
      kitbuildValidation,
    }),
    [
      kitBuildFormErrors,
      selectedRowsOnCheckbox,
      kitsBuiltDataGrid,
      kitBuildForm,
      dataSelected,
      isFormDisable,
      isProductLicensePlateEdit,
      productionJobFlagStatus,
      productionFormErrors,
      productionReportModal,
      productionLicensePlateModal,
      productionLicensePlateFormErrors,
      productionForm,
      optionCustomers,
      optionSuppliers,
      optionLots,
      optionLocations,
      optionCustomerValue,
      optionJobLocations,
      optionJobLocationValue,
      kitDetailsLineItem,
      productionReport,
      productionPickTicketItems,
      productionLicensePlateItems,
      productionPickTicketItemModalRows,
      productionReportFormErrors,
      optionOperator,
      operatorValue,
      optionKitSkus,
    ],
  );
  return (
    <ProductionJobContext.Provider value={value}>
      {children}
    </ProductionJobContext.Provider>
  );
};
