import React, { useEffect, useContext, useState } from 'react';

import Button from 'components/button';
import { snackActions } from 'config/snackbar.js';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { getCarrierAccountByCarrierId } from 'services/api/integrations/easypost/easypost.api';
import { deleteCarrierAccount } from 'services/api/integrations/shipment/shipment.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Divider,
  Grid as MUIGrid,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;
    width: 100%;
    max-width: 400px;
    border-radius: 4px;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;

const initialFormErrorsState = {
  carrierId: '',
};

const initialState = {
  carrierId: '',
};

export default React.memo(() => {
  const { isCarrierAccountDeleteModalOpen, onCloseCarrierAccountDeleteModal } =
    useContext(GlobalContext);

  const { form, setForm, loadCarriers } = useWarehouseCustomerContext();

  const [formErrors, setFormErrors] = useState<any>(initialFormErrorsState);
  const [carrierForm, setCarrierForm] = useState<any>(initialState);

  const getCarrierAccount = async (carrierId: number) => {
    try {
      const carrierAccount = await getCarrierAccountByCarrierId(carrierId);
      setCarrierForm((prev) => ({
        ...prev,
        carrierId: carrierAccount.carrierId,
        accountId: carrierAccount.accountId,
      }));

      return true;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (form.selectedCarrierId !== 0) {
      getCarrierAccount(form.selectedCarrierId);
    }
  }, [isCarrierAccountDeleteModalOpen]);

  return (
    <Modal open={isCarrierAccountDeleteModalOpen}>
      <MUIContainer>
        <MUIContent>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{ marginBottom: '16px' }}
              variant="h6"
              fontWeight="bold"
            >
              Delete Carrier Account
            </Typography>
            <Typography variant="subtitle1">
              Are you sure you wanted to delete this carrier with account id of{' '}
              <b>{carrierForm.accountId}</b>?
            </Typography>
          </Box>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => {
                      setFormErrors((temp) => ({
                        ...temp,
                        carrierId: '',
                        accountId: '',
                      }));

                      setCarrierForm(() => ({
                        ...carrierForm,
                        ...initialState,
                      }));

                      setForm(() => ({
                        ...form,
                        selectedCarrierId: 0,
                      }));

                      onCloseCarrierAccountDeleteModal();
                    }}
                    variant="outlined"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                    onClick={async (e) => {
                      e.preventDefault();

                      const fieldValues = carrierForm.accountId;

                      const temp: any = {};
                      temp.accountId =
                        fieldValues !== '' ? '' : 'This field is required';

                      setFormErrors({
                        ...temp,
                      });
                      const bool = Object.values(temp).every((x) => x === '');

                      if (bool) {
                        try {
                          await deleteCarrierAccount({
                            carrierId: carrierForm.carrierId,
                          });

                          snackActions.success('Carrier deleted successfully.');
                          setCarrierForm(() => ({
                            ...carrierForm,
                            ...initialState,
                          }));
                          setForm(() => ({
                            ...form,
                            selectedCarrierId: 0,
                          }));
                          loadCarriers();
                          // this will trigger the loadCarriers() at warehousecustomer page
                          // because we have useEffect with dependency array of isCarrierAccountDeleteModalOpen
                          onCloseCarrierAccountDeleteModal();
                        } catch (err: any) {
                          setFormErrors(initialFormErrorsState);
                          snackActions.error(`${err}`);
                        }
                      }
                    }}
                  >
                    CONFIRM
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
