import styled from 'styled-components';

export const TextSpanMedium = styled('span')`
  font-size: 16px;
`;

export const TextSpanMediumBold = styled('strong')<{ $color?: string }>`
  font-size: 16px;
  color: ${(props) => props.$color ?? '#333333'};
`;
