/* eslint-disable no-unused-vars */
export enum WarehouseCustomerStorageChargeMethodEnum {
  ByLocation = 1,
  ByVolume = 2,
  ByWeight = 3,
  ByPallet = 4,
  ByItemType = 5,
  FlatRate = 6,
  PalletByDays = 7,
  UOMQtyByDays = 8,
  ByPalletType = 9,
  PartialStorage = 10,
  BinLocationBySize = 11,
}
