import React, { useEffect, useState, useContext, ChangeEvent } from 'react';

import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import Card from 'components/card';
import Header from 'components/header';
import CustomerSupplierModal from 'pages/shared/customersuppliermodal';
import { MainContainer, ContentContainer } from 'pages/styles';
import { getCustomerSuppliers } from 'services/api/customer/customer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridStandard } from 'styles';

import { Typography, Box, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridToolbar,
  GridColumns,
  GridActionsCellItem,
} from '@mui/x-data-grid';

function Suppliers() {
  const theme = useTheme();
  const { onOpenSupplierModal, loading, updateLoading, updateData } =
    useContext(GlobalContext);
  const { currentUser, handleUserPermissionAllow } = useContext(AuthContext);
  const [customerSuppliers, setCustomerSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [dataSelected, setDataSelected] = useState({
    supplierId: '',
    customerId: '',
    account: '',
    name: '',
    contact: '',
    address: '',
    city: '',
    stateProvince: '',
    zipPostalCode: '',
    phone: '',
  });
  const [searchParams, setSearchParams] = useState({
    searchSupplierNo: '',
    searchName: '',
    searchPointOfContact: '',
    searchAddress: '',
    searchPhoneNumber: '',
  });

  const filterSuppliers = () => {
    const supplierNoResult = customerSuppliers.filter((v) =>
      v.supplierId
        .toString()
        .toLowerCase()
        .includes(searchParams.searchSupplierNo),
    );
    const searchNameResult = supplierNoResult.filter((v) =>
      v.name.toLowerCase().includes(searchParams.searchName),
    );
    const searchPointOfContactResult = searchNameResult.filter((v) =>
      v.contact.toLowerCase().includes(searchParams.searchPointOfContact),
    );
    const searchAddressResult = searchPointOfContactResult.filter((v) =>
      v.address.toLowerCase().includes(searchParams.searchAddress),
    );
    const finalResult = searchAddressResult.filter((v) =>
      v.phone.toString().toLowerCase().includes(searchParams.searchPhoneNumber),
    );

    setFilteredSuppliers(finalResult);
  };

  const onForm = (key, text) => {
    setSearchParams(() => ({
      ...searchParams,
      [key]: text,
    }));
  };

  const onLoadCustomerSuppliers = async () => {
    try {
      const suppliersFromApi = await getCustomerSuppliers(
        currentUser.Claim_CustomerId,
      );
      setCustomerSuppliers(suppliersFromApi);
      setFilteredSuppliers(suppliersFromApi);

      return true;
    } catch (error) {
      return error;
    }
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    onForm(key, event.target.value);
  };

  useEffect(() => {
    updateLoading(true);
    setCustomerSuppliers([]);
    setFilteredSuppliers([]);
    onLoadCustomerSuppliers();
  }, [loading, updateData]);

  const handleResetDataSelected = () => {
    setDataSelected({
      supplierId: '',
      customerId: '',
      account: '',
      name: '',
      contact: '',
      address: '',
      city: '',
      stateProvince: '',
      zipPostalCode: '',
      phone: '',
    });
  };

  const handleOpenTransferModal = (rowData) => {
    setDataSelected(rowData);
    onOpenSupplierModal();
  };

  type ItemRow = typeof customerSuppliers[number];

  const supplierColumns: GridColumns<ItemRow> = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleOpenTransferModal(params.row)}
          showInMenu
          disabled={
            !handleUserPermissionAllow(UserPermissionEnum.Suppliers_Edit)
          }
        />,
      ],
    },
    {
      field: 'supplierId',
      width: 150,
      headerName: 'Supplier No.',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`/inventory/supplier-details?form=view&id=${params.row.supplierId}`}
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.supplierId}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'items',
      width: 100,
      headerName: 'Items',
    },
    {
      field: 'name',
      width: 200,
      headerName: 'Name',
    },
    {
      field: 'addressFull',
      width: 350,
      headerName: 'Address',
    },
    {
      field: 'phone',
      width: 150,
      headerName: 'Phone Number',
    },
    {
      field: 'recipientName',
      width: 180,
      headerName: 'Recipient',
    },
  ];

  return (
    <MainContainer>
      <Header />
      <CustomerSupplierModal
        supplier={dataSelected}
        callBack={handleResetDataSelected}
      />
      <ContentContainer>
        <DatagridStandard
          autoHeight
          rows={filteredSuppliers}
          columns={supplierColumns}
          pageSize={100}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          rowsPerPageOptions={[100]}
          getRowId={(row) => row.supplierId}
          // checkboxSelection
          onSelectionModelChange={(supplierId) => {
            const selectedId = supplierId[0];
            const selectedRowData = customerSuppliers.filter(
              (c) => c.supplierId === selectedId,
            );
            if (selectedId === undefined) {
              setDataSelected({
                supplierId: '',
                customerId: '',
                account: '',
                name: '',
                contact: '',
                address: '',
                city: '',
                stateProvince: '',
                zipPostalCode: '',
                phone: '',
              });
            } else {
              setDataSelected(selectedRowData[0]);
            }
          }}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          density="compact"
        />
      </ContentContainer>
    </MainContainer>
  );
}

export default React.memo(Suppliers);
