/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useReducer, Reducer } from 'react';

import { ItemInventoryUOMTypeEnum, PageLoadStateEnum } from 'common/enums';
import Button from 'components/button';
import { snackActions } from 'config/snackbar.js';
import { useSalesOrderContext } from 'pages/sales/salesorder/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { getItemPickingUOM } from 'services/api/item/item.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  Grid as MUIGrid,
} from '@mui/material';

type ItemUnitOfMeasureDDLType = {
  value: number;
  text: string;
  uomAbbr: string;
  isPrimaryUOM: boolean;
  qty?: number;
  primaryUOM?: number;
  primaryUOMStr?: string;
  primaryUOMAbbr?: string;
  uomType: ItemInventoryUOMTypeEnum;
  textInTotal: string;
  primaryTextInPerCase?: string;
  textInPerCase?: string;
};

type ItemPickingUOMGetModelType = {
  unitOfMeasure: ItemUnitOfMeasureDDLType;
  qty: number;
};

type Model = {
  itemPickingUOMs: ItemPickingUOMGetModelType[];
};

interface State {
  pageLoadState: PageLoadStateEnum;
  model: Model;
  error: string | null;
}

const INITIAL_STATE: State = {
  pageLoadState: PageLoadStateEnum.LOAD_NONE,
  model: {
    itemPickingUOMs: [],
  },
  error: '',
};

enum ACTION_TYPES {
  SET_INITIALSTATE = 'SET_INITIALSTATE',
  PAGE_LOADING_START = 'PAGE_LOADING_START',
  SET_MODEL = 'SET_MODEL',
}

interface Action {
  type: ACTION_TYPES;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

function reducer(state: State, action: Action) {
  const { type } = action;

  switch (type) {
    case ACTION_TYPES.SET_INITIALSTATE: {
      return { ...INITIAL_STATE };
    }
    case ACTION_TYPES.PAGE_LOADING_START: {
      return { ...state, pageLoadState: PageLoadStateEnum.LOAD_START };
    }
    case ACTION_TYPES.SET_MODEL: {
      return {
        ...state,
        pageLoadState: PageLoadStateEnum.LOAD_FINISH,
        model: action.payload,
      };
    }
    default:
      return state;
  }
}

export default React.memo(() => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    reducer,
    INITIAL_STATE,
  );

  const { isOrderPickingUomModalOpen, setIsOrderPickingUomModalOpen } =
    useContext(GlobalContext);

  const { singleOrderLineItemRow } = useSalesOrderContext();

  // page load
  useEffect(() => {
    if (isOrderPickingUomModalOpen) {
      if (state.pageLoadState === PageLoadStateEnum.LOAD_NONE) {
        dispatch({ type: ACTION_TYPES.PAGE_LOADING_START, payload: null });
      }
    }
  }, [state.pageLoadState, isOrderPickingUomModalOpen]);
  useEffect(() => {
    if (singleOrderLineItemRow !== null) {
      const { itemId, qty } = singleOrderLineItemRow;

      if (isOrderPickingUomModalOpen) {
        if (state.pageLoadState === PageLoadStateEnum.LOAD_START) {
          const res = getItemPickingUOM(itemId, qty);
          res
            .then((x: ItemPickingUOMGetModelType[]) => {
              const model: Model = {
                itemPickingUOMs: x,
              };
              dispatch({ type: ACTION_TYPES.SET_MODEL, payload: model });
            })
            .catch(() => {
              const model: Model = {
                itemPickingUOMs: [],
              };
              dispatch({ type: ACTION_TYPES.SET_MODEL, payload: model });
              snackActions.error('Something went wrong!');
            });
        }
      }
    }
  }, [state.pageLoadState, isOrderPickingUomModalOpen, singleOrderLineItemRow]);

  return (
    <Modal open={isOrderPickingUomModalOpen}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Picking UOM
        </Typography>
        <MUIContent>
          <Box
            sx={{
              width: '100%',
            }}
          >
            {state.pageLoadState === PageLoadStateEnum.LOAD_START && (
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ marginBottom: 1 }}
              >
                <MUIGrid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  <CircularProgress />
                </MUIGrid>
              </MUIGrid>
            )}

            {state.pageLoadState === PageLoadStateEnum.LOAD_FINISH &&
              state.model.itemPickingUOMs.length === 0 && (
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ marginBottom: 1 }}
                >
                  <MUIGrid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    NO RECORD FOUND
                  </MUIGrid>
                </MUIGrid>
              )}

            {state.pageLoadState === PageLoadStateEnum.LOAD_FINISH && (
              <Box>
                {state.model.itemPickingUOMs.map(
                  (row: ItemPickingUOMGetModelType) => (
                    <Box key={row.unitOfMeasure.value} margin={1}>
                      {row.unitOfMeasure.uomType ===
                        ItemInventoryUOMTypeEnum.Primary &&
                        `${row.qty.toLocaleString()} ${
                          row.unitOfMeasure.uomAbbr
                        }`}

                      {row.unitOfMeasure.uomType ===
                        ItemInventoryUOMTypeEnum.Other &&
                        `${row.qty.toLocaleString()} ${
                          row.unitOfMeasure.uomAbbr
                        } (${row.qty * row.unitOfMeasure.qty} ${
                          row.unitOfMeasure.primaryUOMAbbr
                        })`}

                      {row.unitOfMeasure.uomType ===
                        ItemInventoryUOMTypeEnum.Pallet &&
                        `${row.qty.toLocaleString()} pallet (${
                          row.qty * row.unitOfMeasure.qty
                        } ${row.unitOfMeasure.primaryUOMAbbr})`}
                    </Box>
                  ),
                )}
              </Box>
            )}

            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6} sm={6} md={2}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                    marginTop: '20px',
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ padding: 1, width: '100%' }}
                    onClick={() => {
                      setIsOrderPickingUomModalOpen(false);
                      dispatch({
                        type: ACTION_TYPES.SET_INITIALSTATE,
                        payload: null,
                      });
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
