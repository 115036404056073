import React, { useContext, memo, useEffect, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { usePackAndShipContext } from 'pages/packandship/context';
import { CarrierType } from 'pages/sales/salesorder/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import {
  getOrderCarriers,
  getOrderCarrierservices,
  updateCarrierAndServiceOrderFromPackAndShip,
} from 'services/api/integrations/shipment/shipment.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Typography,
  Button,
  Grid as MUIGrid,
  Box,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';

type AutocompleteBase = {
  label?: string;
  value?: number;
};
export default memo(() => {
  const {
    isUpdateCarrierAndServiceModalOpen,
    setIsUpdateCarrierAndServiceModalOpen,
  } = useContext(GlobalContext);

  const { pickTicketFormObj, viewLoadData, setAllowBarcodeScanner } =
    usePackAndShipContext();

  const initialForm: any = {
    carrier: null,
    carrierService: null,
  };

  const [formValue, setFormValue] = useState(initialForm);
  const [loading, setLoading] = useState(false);

  const [optionCarriers, setOptionCarriers] = useState<
    CarrierType[] | null | []
  >([]);
  const [optionCarrierServices, setOptionCarrierServices] = useState<
    CarrierType[] | null | []
  >([]);

  const initialFormError: any = {
    carrier: '',
    carrierService: '',
  };

  const [formError, setFormError] = useState<any>([initialFormError]);

  const onLoadOrderCarriers = async (warehouseCustomerId: number) => {
    try {
      const carriersFromApi = await getOrderCarriers(warehouseCustomerId);
      return carriersFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadOrderCarrierServices = async (carrierId: any) => {
    try {
      if (carrierId === 4) {
        setOptionCarrierServices(
          [
            { text: 'Cheapest', value: 'Cheapest' },
            { text: 'Fastest', value: 'Fastest' },
          ].map((c: any) => ({
            label: c.text,
            value: c.text,
            isShippingRate: true,
          })),
        );
      } else if (carrierId === 5) {
        setOptionCarrierServices(
          [
            { text: 'LTL or Truckload', value: 'LTL or Truckload' },
            { text: 'Will Call', value: 'Will Call' },
            { text: 'Pack and Hold', value: 'Pack and Hold' },
          ].map((c: any) => ({
            label: c.text,
            value: c.text,
            isShippingRate: true,
          })),
        );
      } else {
        const carriersFromApi = await getOrderCarrierservices(carrierId);

        setOptionCarrierServices(
          carriersFromApi.map((c: any) => ({
            label: c.text,
            value: c.serviceCode,
            isShippingRate: c.isShippingRate,
            serviceCode: c.serviceCode,
          })),
        );
      }

      return null;
    } catch (err) {
      return err;
    }
  };

  const handleCarrierandServiceOnClose = () => {
    setAllowBarcodeScanner(true);
    setIsUpdateCarrierAndServiceModalOpen(false);
    setLoading(false);
    setFormValue(null);
  };

  const formValidation = () => {
    const temp: any = {};
    temp.carrier = formValue?.carrier ? '' : 'This field is required';
    setFormError({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleCarrierandServiceOnSubmit = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    if (!formValidation()) {
      return;
    }

    const PAYLOAD = {
      OrderId: pickTicketFormObj.orderNoId,
      CarrierId: formValue?.carrier.value,
      CarrierName: formValue?.carrier.label,
      CarrierService: formValue?.carrierService?.label,
      IsShippingRate: formValue?.carrier?.isShippingRate,
    };

    try {
      setLoading(true);
      await updateCarrierAndServiceOrderFromPackAndShip(PAYLOAD).then(() => {
        viewLoadData();
        handleCarrierandServiceOnClose();
      });
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    if (isUpdateCarrierAndServiceModalOpen) {
      setAllowBarcodeScanner(false);

      const optCarriers = onLoadOrderCarriers(
        pickTicketFormObj.warehouseCustomerId ?? 0,
      );
      optCarriers.then((opt) => {
        setOptionCarriers(
          opt.map((c: any) => ({
            label: c.text,
            value: c.value,
            isShippingRate: c.isShippingRate,
          })),
        );
      });
    }
  }, [isUpdateCarrierAndServiceModalOpen]);

  useEffect(() => {
    if (formValue?.carrier) {
      onLoadOrderCarrierServices(formValue?.carrier.value);
    }
  }, [formValue?.carrier]);

  return (
    <Modal open={isUpdateCarrierAndServiceModalOpen}>
      <MUIContainer sx={{ width: '20% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Update Carrier and Service
        </Typography>
        <MUIContent sx={{ padding: '5px 20px !important ' }}>
          <MUIGrid container direction="row" alignItems="center" spacing={2}>
            <MUIGrid item xs={12}>
              <Autocomplete
                disableClearable
                id="controllable-states"
                options={optionCarriers}
                getOptionLabel={(option: AutocompleteBase) => option.label}
                value={formValue?.carrier}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                onChange={(event: any, newValue: AutocompleteBase) => {
                  if (newValue !== null) {
                    setFormValue((e) => ({
                      ...e,
                      carrier: newValue,
                      carrierService: null,
                    }));
                  }
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.label}</li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined-required"
                    size="small"
                    sx={{ width: '100%', background: '#ffffff' }}
                    label="Carrier"
                    {...(formError?.carrier && {
                      error: true,
                      helperText: formError?.carrier,
                    })}
                  />
                )}
              />
            </MUIGrid>
            <MUIGrid item xs={12}>
              <Autocomplete
                id="controllable-states"
                options={optionCarrierServices}
                getOptionLabel={(option: CarrierType) => option.label}
                value={formValue?.carrierService}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                onChange={(event: any, newValue: CarrierType) => {
                  if (newValue !== null) {
                    setFormValue((e) => ({
                      ...e,
                      carrierService: {
                        value: newValue.value,
                        label:
                          newValue.isShippingRate === true
                            ? newValue.label
                            : newValue.serviceCode,
                      },
                    }));
                  } else {
                    setFormValue((e) => ({
                      ...e,
                      carrierService: null,
                    }));
                  }
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.label}</li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined-required"
                    size="small"
                    sx={{ width: '100%', background: '#ffffff' }}
                    label="Carrier Service"
                  />
                )}
                disabled={formValue?.isShowRate}
              />
            </MUIGrid>
          </MUIGrid>
        </MUIContent>

        <MUIGrid
          container
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
            justifyContent: 'flex-end',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={10}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '10px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => handleCarrierandServiceOnClose()}
                variant="outlined"
                size="large"
                sx={{ padding: 1, width: '100%' }}
              >
                CANCEL
              </Button>
              <Button
                onClick={(e) => {
                  handleCarrierandServiceOnSubmit(e);
                }}
                variant="contained"
                size="large"
                sx={{ padding: 1, width: '100%' }}
                disabled={loading}
              >
                {loading && <CircularProgress sx={{ marginRight: 1 }} />}
                UPDATE
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
