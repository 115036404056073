import styled from 'styled-components';

import { Box } from '@mui/material';

export const BoxIcon = styled(Box)<{ $color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #bdb9b9;
  border-radius: 50%;
  padding: 5px;
  svg {
    fill: ${(props) => props.$color ?? '#333333'};
    width: 1.3em;
    height: 1.3em;
  }
`;

export const BoxAccordionChild = styled(Box)<{ $color?: string }>`
  border-top: 1px solid #e0e0e0;
  padding-top: 15px;
  margin-bottom: 15px;
`;
