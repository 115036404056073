import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const createShipStationConfiguration = async (payload) => {
  try {
    const response = await httpClient.post(
      `/shipstation/configuration`,
      payload,
      { params: { showLoading: true } },
    );

    return response;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getShipStationConfiguration = async (warehouseCustomerId) => {
  try {
    const response = await httpClient.get(
      `/shipstation/configuration/${warehouseCustomerId}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const syncOrderShipStationManually = async (ecommerceIntegrationId) => {
  try {
    const response = await httpClient.get(
      `/shipstation/SyncOrderShipStationManuallyAsync/${ecommerceIntegrationId}`,
    );

    return response;
  } catch (error) {
    return errorCatch(error);
  }
};

export const triggerAllIntegrations = async (customerId, facilityId) => {
  try {
    const response = await httpClient.get(
      `/shipstation/pullOrders/TriggerAllIntegrationsAsync/${customerId}/${facilityId}`,
    );

    return response;
  } catch (error) {
    return errorCatch(error);
  }
};
