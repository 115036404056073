import React, { Reducer, useEffect, useReducer, useState } from 'react';

import { PageLoadStateEnum } from 'common/enums';
import { DropDownListItemById, DropdownListItem } from 'common/models';
import {
  ConnectToPrintNodeRequestModel,
  ScaleDropdownListItem,
} from 'common/models/Integrations/printnode';
import Card from 'components/card';
import CardWithHeader from 'components/card/CardWithHeader';
import { snackActions } from 'config/snackbar.js';
import {
  ConnectToPrintNode,
  GetScalesByComputerId,
  GetPrinterSettings,
  DisconnectToPrintNode,
} from 'services/api/integrations/printnode/printnode.api';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid as MUIGrid,
  TextField,
  Button,
  Typography,
} from '@mui/material';

interface PrinterSettingsGetModel {
  apiKey: string;
  computerDDL: DropdownListItem[];
  printerDDLById: DropDownListItemById[];
  errorMessage?: string;
}

export interface PrinterSettingsState {
  computerId?: number;
  printerId?: number;
  scale?: ScaleDropdownListItem;
}

interface PrinterSettingsModel {
  computerDDL: DropdownListItem[];
  printerDDLById: DropDownListItemById[];
  printerDDL: DropdownListItem[];
  scaleDDL: ScaleDropdownListItem[];
  apiKey?: string;
  hasValidApiKey: boolean;
  computer?: DropdownListItem;
  printer?: DropdownListItem;
  scale?: ScaleDropdownListItem;
}

const initialStateOfForm: PrinterSettingsModel = {
  computerDDL: [],
  printerDDLById: [],
  printerDDL: [],
  scaleDDL: [],
  apiKey: null,
  hasValidApiKey: false,
  computer: null,
  printer: null,
};

interface PrinterSettingsErrorModel {
  apiKey: string;
  computerId: string;
  printerId: string;
}

const initialStateOfFormError = {
  apiKey: '',
  computerId: '',
  printerId: '',
};

interface State {
  pageLoadState: PageLoadStateEnum;
  isBusy: boolean;
  isConnecting: boolean;
  isPrinterConnected: boolean;
  form: PrinterSettingsModel;
  formErrors: PrinterSettingsErrorModel;
  error: string | null;
}

const INITIAL_STATE: State = {
  pageLoadState: PageLoadStateEnum.LOAD_NONE,
  isBusy: false,
  isConnecting: false,
  isPrinterConnected: false,
  form: initialStateOfForm,
  formErrors: initialStateOfFormError,
  error: '',
};

enum ACTION_TYPES {
  PAGE_LOADING_START = 'PAGE_LOADING_START',
  PAGE_LOADING_FINISH = 'PAGE_LOADING_FINISH',
  SET_FORM = 'SET_FORM',
  SET_APIKEY = 'SET_APIKEY',
  SET_COMPUTER = 'SET_COMPUTER',
  SET_COMPUTER_NULL = 'SET_COMPUTER_NULL',
  SET_PRINTER = 'SET_PRINTER',
  SET_PRINTER_NULL = 'SET_PRINTER_NULL',
  SET_SCALE = 'SET_SCALE',
  SET_SCALE_NULL = 'SET_SCALE_NULL',
  SET_FORM_ERRORS = 'SET_FORM_ERRORS',
  SET_STATE_ISBUSY = 'SET_STATE_ISBUSY',
  SET_STATE_ISCONNECTING = 'SET_STATE_ISCONNECTING',
  SET_STATE_ISPRINTERCONNECTED = 'SET_STATE_ISPRINTERCONNECTED',
  SAVE = 'SAVE',
}

interface Action {
  type: ACTION_TYPES;
  payload: any;
}

function reducer(state: State, action: Action) {
  const { type } = action;

  switch (type) {
    case ACTION_TYPES.PAGE_LOADING_START: {
      return { ...state, pageLoadState: PageLoadStateEnum.LOAD_START };
    }
    case ACTION_TYPES.PAGE_LOADING_FINISH: {
      return { ...state, pageLoadState: PageLoadStateEnum.LOAD_FINISH };
    }
    case ACTION_TYPES.SET_FORM: {
      return {
        ...state,
        isPrinterConnected: action.payload.apiKey !== null,
        form: action.payload,
      };
    }
    case ACTION_TYPES.SET_APIKEY: {
      return {
        ...state,
        form: {
          ...state.form,
          apiKey: action.payload,
        },
      };
    }
    case ACTION_TYPES.SET_COMPUTER: {
      let printerDDL: DropdownListItem[] = [];
      if (action.payload !== null && action.payload.computer !== null) {
        const printerDDLByComputerId = state.form.printerDDLById.filter(
          (z) => z.id === action.payload.computer.value,
        );

        if (printerDDLByComputerId.length > 0) {
          printerDDL = printerDDLByComputerId.map((z: DropdownListItem) => ({
            value: z.value,
            text: z.text,
          }));
        }
      }

      return {
        ...state,
        form: {
          ...state.form,
          printerDDL,
          scaleDDL: action.payload.scaleDDL,
          computer: action.payload.computer,
          printer: null,
          scale: null,
        },
      };
    }
    case ACTION_TYPES.SET_COMPUTER_NULL: {
      return {
        ...state,
        form: {
          ...state.form,
          printerDDL: [],
          scaleDDL: [],
          computer: null,
          printer: null,
          scale: null,
        },
      };
    }
    case ACTION_TYPES.SET_PRINTER: {
      return {
        ...state,
        form: {
          ...state.form,
          printer: action.payload,
        },
      };
    }
    case ACTION_TYPES.SET_PRINTER_NULL: {
      return {
        ...state,
        form: {
          ...state.form,
          printer: null,
        },
      };
    }
    case ACTION_TYPES.SET_SCALE: {
      return {
        ...state,
        form: {
          ...state.form,
          scale: action.payload,
        },
      };
    }
    case ACTION_TYPES.SET_SCALE_NULL: {
      return {
        ...state,
        form: {
          ...state.form,
          scale: null,
        },
      };
    }
    case ACTION_TYPES.SET_FORM_ERRORS: {
      return {
        ...state,
        formErrors: action.payload,
      };
    }
    case ACTION_TYPES.SET_STATE_ISBUSY: {
      return {
        ...state,
        isBusy: action.payload,
      };
    }
    case ACTION_TYPES.SET_STATE_ISCONNECTING: {
      return {
        ...state,
        isConnecting: action.payload,
      };
    }
    case ACTION_TYPES.SET_STATE_ISPRINTERCONNECTED: {
      return {
        ...state,
        isPrinterConnected: action.payload,
      };
    }

    default:
      return state;
  }
}

function PrinterSettings() {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    reducer,
    INITIAL_STATE,
  );

  const [isPrinterConnecting, setIsPrinterConnecting] = useState(false);

  const validateApiKey = () => {
    const temp = initialStateOfFormError;

    temp.computerId = '';
    temp.printerId = '';

    if (state.form.apiKey === null || state.form.apiKey === '') {
      temp.apiKey = 'This field is required';
    } else {
      temp.apiKey = '';
    }

    dispatch({
      type: ACTION_TYPES.SET_FORM_ERRORS,
      payload: temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const validateForm = () => {
    const temp = initialStateOfFormError;

    if (state.form.computer === null) {
      temp.computerId = 'This field is required';
    } else {
      temp.computerId = '';
    }

    if (state.form.computer !== null && state.form.printer === null) {
      temp.printerId = 'This field is required';
    } else {
      temp.printerId = '';
    }

    dispatch({
      type: ACTION_TYPES.SET_FORM_ERRORS,
      payload: temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const connect = async () => {
    if (!validateApiKey()) {
      snackActions.error('Please complete required fields');
    } else {
      setIsPrinterConnecting(true);

      dispatch({
        type: ACTION_TYPES.SET_STATE_ISCONNECTING,
        payload: true,
      });

      const PAYLOAD: ConnectToPrintNodeRequestModel = {
        apiKey: state.form.apiKey,
      };

      ConnectToPrintNode(PAYLOAD)
        .then(() => {
          snackActions.success('Connect success.');

          dispatch({
            type: ACTION_TYPES.SET_STATE_ISPRINTERCONNECTED,
            payload: true,
          });

          const printerSettingsState: PrinterSettingsState = {
            computerId: null,
            printerId: null,
            scale: null,
          };

          localStorage.setItem(
            'printerSettingsState',
            JSON.stringify(printerSettingsState),
          );

          // this will automatically trigger viewLoadData
          dispatch({ type: ACTION_TYPES.PAGE_LOADING_START, payload: null });
        })
        .catch((err) => {
          dispatch({
            type: ACTION_TYPES.SET_STATE_ISPRINTERCONNECTED,
            payload: false,
          });

          snackActions.error(err);
        })
        .finally(() => {
          dispatch({
            type: ACTION_TYPES.SET_STATE_ISCONNECTING,
            payload: false,
          });
          setIsPrinterConnecting(false);
        });
    }
  };

  const disConnectPrinter = () => {
    if (!validateApiKey()) {
      snackActions.error('Please complete required fields');
    } else {
      dispatch({
        type: ACTION_TYPES.SET_STATE_ISCONNECTING,
        payload: true,
      });

      const PAYLOAD: ConnectToPrintNodeRequestModel = {
        apiKey: state.form.apiKey,
      };

      DisconnectToPrintNode(PAYLOAD)
        .then(() => {
          snackActions.success('Disconnection success.');

          const printerSettingsState: PrinterSettingsState = {
            computerId: null,
            printerId: null,
            scale: null,
          };

          localStorage.setItem(
            'printerSettingsState',
            JSON.stringify(printerSettingsState),
          );
          dispatch({ type: ACTION_TYPES.PAGE_LOADING_START, payload: null });
        })
        .catch((err) => {
          snackActions.error(err);
        })
        .finally(() => {
          dispatch({
            type: ACTION_TYPES.SET_STATE_ISCONNECTING,
            payload: false,
          });

          dispatch({
            type: ACTION_TYPES.SET_STATE_ISPRINTERCONNECTED,
            payload: false,
          });

          setIsPrinterConnecting(false);
        });
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href =
      'https://dl.printnode.com/client/printnode/4.28.0/PrintNode-4.28.0.exe';
    link.download = 'PrintNode-4.28.0.exe';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const save = async () => {
    if (!validateForm()) {
      snackActions.error('Please complete required fields');
    } else {
      const printerSettingsState: PrinterSettingsState = {
        computerId: state.form.computer.value,
        printerId: state.form.printer.value,
        scale: state.form.scale,
      };

      localStorage.setItem(
        'printerSettingsState',
        JSON.stringify(printerSettingsState),
      );
      snackActions.success('Printer settings saved succesfully.');
    }
  };

  // page load
  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_NONE) {
      dispatch({ type: ACTION_TYPES.PAGE_LOADING_START, payload: null });
    }
  }, []);

  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_START) {
      const stateFromLocalStorage = localStorage?.getItem(
        'printerSettingsState',
      );

      const printerSettingsState = JSON.parse(
        stateFromLocalStorage,
      ) as PrinterSettingsState;

      const data: PrinterSettingsModel = { ...initialStateOfForm };
      let errorMessage: string = null;

      GetPrinterSettings()
        .then((x: PrinterSettingsGetModel) => {
          let selectedComputer: DropdownListItem = null;
          if (
            printerSettingsState !== null &&
            printerSettingsState.computerId !== null
          ) {
            selectedComputer = x.computerDDL.find(
              (z) => z.value === printerSettingsState.computerId,
            );
          }

          let printerDDL: DropdownListItem[] = [];
          if (
            printerSettingsState !== null &&
            printerSettingsState.computerId !== null
          ) {
            const printerDDLByComputerId = x.printerDDLById.filter(
              (z) => z.id === printerSettingsState.computerId,
            );

            if (printerDDLByComputerId.length > 0) {
              printerDDL = printerDDLByComputerId.map(
                (z: DropdownListItem) => ({
                  value: z.value,
                  text: z.text,
                }),
              );
            }
          }

          let apiKey = x.apiKey ?? null;
          if (apiKey !== null && apiKey === '') {
            apiKey = null;
          }

          data.computerDDL = x.computerDDL ?? [];
          data.printerDDLById = x.printerDDLById ?? [];
          data.printerDDL = printerDDL;
          data.apiKey = apiKey;
          data.hasValidApiKey = !!x.apiKey;
          data.computer = selectedComputer ?? null;

          if (isPrinterConnecting) {
            errorMessage = x.errorMessage;
          }
        })
        .catch(() => {
          snackActions.error('Something went wrong!');
        })
        .finally(() => {
          if (
            printerSettingsState !== null &&
            printerSettingsState.computerId !== null
          ) {
            let scaleDDL: ScaleDropdownListItem[] = [];

            GetScalesByComputerId(printerSettingsState.computerId)
              .then((y: ScaleDropdownListItem[]) => {
                scaleDDL = y.map((z: ScaleDropdownListItem) => ({
                  computerId: z.computerId,
                  vendorId: z.vendorId,
                  productId: z.productId,
                  deviceNum: z.deviceNum,
                  value: z.value,
                  text: z.text,
                }));
              })
              .finally(() => {
                let selectedPrinter: DropdownListItem = null;
                if (data.printerDDL.length > 0) {
                  selectedPrinter = data.printerDDL.find(
                    (z) => z.value === printerSettingsState.printerId,
                  );
                }

                let selectedScale: ScaleDropdownListItem = null;
                if (scaleDDL.length > 0) {
                  selectedScale = scaleDDL.find(
                    (z) => z.value === printerSettingsState.scale?.value,
                  );
                }

                data.scaleDDL = scaleDDL;
                data.printer = selectedPrinter ?? null;
                data.scale = selectedScale ?? null;

                dispatch({ type: ACTION_TYPES.SET_FORM, payload: data });
                dispatch({
                  type: ACTION_TYPES.PAGE_LOADING_FINISH,
                  payload: null,
                });

                if (errorMessage) {
                  snackActions.error(errorMessage);
                }
              });
          } else {
            dispatch({ type: ACTION_TYPES.SET_FORM, payload: data });
            dispatch({
              type: ACTION_TYPES.PAGE_LOADING_FINISH,
              payload: null,
            });

            if (errorMessage) {
              snackActions.error(errorMessage);
            }
          }
        });
    }
  }, [state.pageLoadState]);

  return (
    <>
      {state.pageLoadState === PageLoadStateEnum.LOAD_START && (
        <CardWithHeader
          sx={{ display: 'flex', flexDirection: 'column' }}
          header="Printer Settings"
        >
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ marginBottom: 1 }}
          >
            <MUIGrid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'space-around' }}
            >
              <CircularProgress />
            </MUIGrid>
          </MUIGrid>
        </CardWithHeader>
      )}

      {state.pageLoadState === PageLoadStateEnum.LOAD_FINISH && (
        <Card sx={{ display: 'flex', flexDirection: 'column' }}>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ marginBottom: 1 }}
          >
            <MUIGrid item xs={12}>
              <MUIGrid container>
                <MUIGrid item xs={12}>
                  <MUIGrid
                    container
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <MUIGrid item xs={6}>
                      <Typography variant="h6">
                        <Box fontWeight="bold">Printer Settings</Box>
                      </Typography>
                    </MUIGrid>

                    <MUIGrid
                      item
                      xs={6}
                      sx={{ display: 'flex', justifyContent: 'end' }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<ArrowDownwardIcon />}
                        size="small"
                        sx={{
                          fontSize: '10px',
                          textTransform: 'none',
                          marginRight: 1,
                        }}
                        onClick={handleDownload}
                      >
                        Download PrintNode
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => save()}
                        disabled={
                          state.isConnecting || !state.form.hasValidApiKey
                        }
                      >
                        SAVE
                      </Button>
                    </MUIGrid>
                  </MUIGrid>
                </MUIGrid>
                <MUIGrid item xs={12}>
                  <Typography variant="subtitle1">
                    <Box>Manage printer to use at Pack & Ship.</Box>
                  </Typography>
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ marginBottom: 1 }}
          >
            <MUIGrid item xs={!state.isConnecting ? 3 : 2}>
              <TextField
                required
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch({
                    type: ACTION_TYPES.SET_APIKEY,
                    payload: event.target.value,
                  });
                }}
                {...(state.formErrors.apiKey && {
                  error: true,
                  helperText: state.formErrors.apiKey,
                })}
                disabled={state.isBusy || state.isConnecting}
                value={state.form.apiKey || ''}
                label="Api Key"
                size="small"
                sx={{ width: '100%' }}
              />
            </MUIGrid>

            {!state.isConnecting && (
              <MUIGrid
                item
                xs={state.isPrinterConnected ? 2 : 1}
                sx={{ marginLeft: '5px !imporant' }}
              >
                {state.isPrinterConnected ? (
                  <Button
                    sx={{
                      display: 'flex',
                      width: '100%',
                      marginTop: '5px',
                      padding: '5px 7px',
                      backgroundColor: '#f4302d',
                    }}
                    variant="contained"
                    size="small"
                    disabled={state.isBusy}
                    onClick={() => disConnectPrinter()}
                  >
                    DISCONNECT
                  </Button>
                ) : (
                  <Button
                    sx={{ display: 'flex', width: '100%', marginTop: '5px' }}
                    variant="contained"
                    size="small"
                    disabled={state.isBusy}
                    onClick={() => connect()}
                  >
                    CONNECT
                  </Button>
                )}
              </MUIGrid>
            )}
            {state.isConnecting && (
              <MUIGrid item xs={2}>
                {state.isPrinterConnected ? (
                  <Button
                    disabled
                    sx={{
                      width: '100%',
                      marginTop: '5px',
                      backgroundColor: '#f4302d !important',
                      color: 'white !important',
                    }}
                    variant="contained"
                    size="small"
                  >
                    <CircularProgress
                      sx={{
                        color: 'white !important',
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                    <Box sx={{ paddingLeft: '5px' }}>DISCONNECTING</Box>
                  </Button>
                ) : (
                  <Button
                    disabled
                    sx={{
                      width: '100%',
                      marginTop: '5px',
                      backgroundColor: '#1C9DCC !important',
                      color: 'white !important',
                    }}
                    variant="contained"
                    size="small"
                  >
                    <CircularProgress
                      sx={{
                        color: 'white !important',
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                    <Box sx={{ paddingLeft: '5px' }}>CONNECTING</Box>
                  </Button>
                )}
              </MUIGrid>
            )}

            <MUIGrid item xs={4}>
              <Autocomplete
                sx={{ width: '100%' }}
                options={state.form.computerDDL}
                value={state.form.computer}
                size="small"
                disabled={state.isConnecting || !state.form.hasValidApiKey}
                getOptionLabel={(option: DropdownListItem) => option.text}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                onChange={(event, newValue) => {
                  const data = newValue as DropdownListItem;
                  if (newValue !== null) {
                    dispatch({
                      type: ACTION_TYPES.SET_STATE_ISBUSY,
                      payload: true,
                    });

                    let scaleDDL: ScaleDropdownListItem[] = [];
                    GetScalesByComputerId(data.value)
                      .then((y: ScaleDropdownListItem[]) => {
                        scaleDDL = y.map((z: ScaleDropdownListItem) => ({
                          computerId: z.computerId,
                          vendorId: z.vendorId,
                          productId: z.productId,
                          deviceNum: z.deviceNum,
                          value: z.value,
                          text: z.text,
                        }));
                      })
                      .finally(() => {
                        dispatch({
                          type: ACTION_TYPES.SET_COMPUTER,
                          payload: { computer: data, scaleDDL },
                        });

                        dispatch({
                          type: ACTION_TYPES.SET_STATE_ISBUSY,
                          payload: false,
                        });
                      });
                  } else {
                    dispatch({
                      type: ACTION_TYPES.SET_COMPUTER_NULL,
                      payload: null,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...(state.formErrors.computerId && {
                      error: true,
                      helperText: state.formErrors.computerId,
                    })}
                    sx={{
                      '& .MuiInputBase-root': {
                        background: 'white',
                      },
                    }}
                    {...params}
                    label="Computer"
                  />
                )}
              />
            </MUIGrid>

            <MUIGrid item xs={4}>
              <Autocomplete
                sx={{ width: '100%' }}
                options={state.form.printerDDL}
                value={state.form.printer}
                size="small"
                disabled={state.form.computer === null || state.isConnecting}
                getOptionLabel={(option: DropdownListItem) => option.text}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                onChange={(event, newValue) => {
                  const data = newValue as DropdownListItem;
                  if (newValue !== null) {
                    dispatch({ type: ACTION_TYPES.SET_PRINTER, payload: data });
                  } else {
                    dispatch({
                      type: ACTION_TYPES.SET_PRINTER_NULL,
                      payload: null,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...(state.formErrors.printerId && {
                      error: true,
                      helperText: state.formErrors.printerId,
                    })}
                    sx={{
                      '& .MuiInputBase-root': {
                        background: 'white',
                      },
                    }}
                    {...params}
                    label="Printer"
                  />
                )}
              />
            </MUIGrid>

            <MUIGrid item xs={4}>
              <Autocomplete
                sx={{ width: '100%' }}
                options={state.form.scaleDDL}
                value={state.form.scale}
                size="small"
                disabled={state.form.computer === null || state.isConnecting}
                getOptionLabel={(option: ScaleDropdownListItem) => option.text}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                onChange={(event, newValue) => {
                  const data = newValue as ScaleDropdownListItem;
                  if (newValue !== null) {
                    dispatch({ type: ACTION_TYPES.SET_SCALE, payload: data });
                  } else {
                    dispatch({
                      type: ACTION_TYPES.SET_SCALE_NULL,
                      payload: null,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      '& .MuiInputBase-root': {
                        background: 'white',
                      },
                    }}
                    {...params}
                    label="Scale"
                  />
                )}
              />
            </MUIGrid>
          </MUIGrid>
        </Card>
      )}
    </>
  );
}

export default React.memo(PrinterSettings);
