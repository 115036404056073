/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  Reducer,
  useState,
} from 'react';

import { PageLoadStateEnum, PutAwayItemTypeEnum } from 'common/enums';
import { Action, DropdownListItem, StateBase } from 'common/models';
import { BinZoneDropDownListItem } from 'common/models/bins';
import {
  PutAwayLineItemLookupGetModel,
  PutAwayQueueGetModel,
} from 'common/models/putaways';
import { snackActions } from 'config/snackbar.js';
import {
  getPutAwayQueue,
  createPutAwayBin,
  createPutAwayPallet,
} from 'services/api/putaway/putaway.api';
import { AuthContext } from 'store/contexts/AuthContext';

type PutAway = {
  lineItem?: PutAwayLineItemLookupGetModel;
  bin?: BinZoneDropDownListItem;
  qty?: number;
};

type PutAwayError = {
  bin: string;
  qty: string;
};

const INITIAL_STATE_PUTAWAY_ERROR: PutAwayError = {
  bin: '',
  qty: '',
};

type ViewModel = {
  items: number;
  lineItems: PutAwayLineItemLookupGetModel[];
  binDDL: BinZoneDropDownListItem[];
  isPutAwayLineItemModal: boolean;
  putAway?: PutAway;
  putAwayError?: PutAwayError;
};

const INITIAL_STATE_VM: ViewModel = {
  items: 0,
  lineItems: [],
  binDDL: [],
  isPutAwayLineItemModal: false,
  putAway: null,
  putAwayError: null,
};

type State = StateBase<ViewModel> & {
  isBusy: boolean;
};

const INITIAL_STATE: State = {
  pageLoadState: PageLoadStateEnum.LOAD_NONE,
  isBusy: false,
  vm: INITIAL_STATE_VM,
};

enum ACTION_TYPES {
  SET_INITIALSTATE = 'SET_INITIALSTATE',
  PAGE_LOADING_START = 'PAGE_LOADING_START',
  SET_VM = 'SET_VM',
  PUTAWAY_MODAL_OPEN = 'PUTAWAY_MODAL_OPEN',
  PUTAWAY_MODAL_CLOSE = 'PUTAWAY_MODAL_CLOSE',
  SET_VM_PUTAWAY_LOCATION = 'SET_VM_PUTAWAY_LOCATION',
  SET_VM_PUTAWAY_QTY = 'SET_VM_PUTAWAY_QTY',
  SET_STATE_ISBUSY = 'SET_STATE_ISBUSY',
  SUBMIT_VM_PUTAWAY = 'SUBMIT_VM_PUTAWAY',
  SET_VM_PUTAWAYERROR = 'SET_VM_PUTAWAYERROR',
}

function reducer(state: State, action: Action<ACTION_TYPES>) {
  const { type } = action;

  switch (type) {
    case ACTION_TYPES.SET_INITIALSTATE: {
      return { ...INITIAL_STATE };
    }
    case ACTION_TYPES.PAGE_LOADING_START: {
      return { ...state, pageLoadState: PageLoadStateEnum.LOAD_START };
    }
    case ACTION_TYPES.SET_VM: {
      return {
        ...state,
        pageLoadState: PageLoadStateEnum.LOAD_FINISH,
        vm: action.payload,
      };
    }
    case ACTION_TYPES.PUTAWAY_MODAL_OPEN: {
      const lineItem = action.payload as PutAwayLineItemLookupGetModel;

      const putAway: PutAway = {
        lineItem,
        bin: null,
        qty: null,
      };

      // pallet quantity is always ONE
      // because we put away the whole pallet
      if (lineItem?.putAwayItemType === PutAwayItemTypeEnum.Pallet) {
        putAway.qty = 1;
      }

      if (lineItem?.bulkLocationBinId === undefined) {
        lineItem.bulkLocationBinId = null;
        lineItem.bulkLocationBinName = null;
      }

      if (lineItem?.bulkLocationBinId !== null) {
        const bins = state.vm.binDDL.filter(
          (x) => x.value === lineItem?.bulkLocationBinId,
        );

        if (bins.length > 0) {
          const bin = bins[0];
          putAway.bin = bin;
        }
      }

      return {
        ...state,
        vm: {
          ...state.vm,
          isPutAwayLineItemModal: true,
          putAway,
          putAwayError: { ...INITIAL_STATE_PUTAWAY_ERROR },
        },
      };
    }
    case ACTION_TYPES.PUTAWAY_MODAL_CLOSE: {
      return {
        ...state,
        vm: {
          ...state.vm,
          isPutAwayLineItemModal: false,
          putAway: null,
          putAwayError: null,
        },
      };
    }
    case ACTION_TYPES.SET_VM_PUTAWAY_LOCATION: {
      return {
        ...state,
        vm: {
          ...state.vm,
          putAway: {
            ...state.vm.putAway,
            bin: action.payload,
          },
        },
      };
    }
    case ACTION_TYPES.SET_VM_PUTAWAY_QTY: {
      return {
        ...state,
        vm: {
          ...state.vm,
          putAway: {
            ...state.vm.putAway,
            qty: action.payload,
          },
        },
      };
    }
    case ACTION_TYPES.SET_STATE_ISBUSY: {
      return {
        ...state,
        isBusy: action.payload,
      };
    }
    case ACTION_TYPES.SUBMIT_VM_PUTAWAY: {
      return {
        ...state,
      };
    }
    case ACTION_TYPES.SET_VM_PUTAWAYERROR: {
      return {
        ...state,
        vm: {
          ...state.vm,
          putAwayError: action.payload,
        },
      };
    }
    default:
      return state;
  }
}

// MEMO STATE
interface IPutAway {
  state: State;
  openPutAwayLineItemModal: (lineItem: PutAwayLineItemLookupGetModel) => void;
  closePutAwayLineItemModal: () => void;
  setPutAwayLocation(bin?: DropdownListItem);
  setPutAwayQty(qty?: number);
  submitPutAway: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  setGetPutAwayIdsToDelete: React.Dispatch<React.SetStateAction<any[]>>;
  getPutAwayIdsToDelete: any[];
  viewLoadData(): void;
}

type PutAwayProviderProps = {
  children: React.ReactNode;
};
const PutAwayContext = createContext<IPutAway>({} as IPutAway);

export const usePutAwayContext = () => useContext(PutAwayContext);

export const PutAwayContextProvider = ({ children }: PutAwayProviderProps) => {
  const [state, dispatch] = useReducer<Reducer<State, Action<ACTION_TYPES>>>(
    reducer,
    INITIAL_STATE,
  );

  const [getPutAwayIdsToDelete, setGetPutAwayIdsToDelete] = useState([]);
  const { currentLocationAndFacility } = useContext(AuthContext);

  function viewLoadData() {
    const vm: ViewModel = INITIAL_STATE_VM;

    const getStoredWarehouseValue = JSON.parse(
      localStorage.getItem('storeWarehouseCustomer'),
    );

    const response = getPutAwayQueue(
      currentLocationAndFacility.customerLocationId,
      currentLocationAndFacility.customerFacilityId,
      getStoredWarehouseValue ? getStoredWarehouseValue.id : null,
    );

    response
      .then((x: PutAwayQueueGetModel) => {
        vm.items = x.items;
        vm.lineItems = x.lineItems;
        vm.binDDL = x.binDDL;

        dispatch({ type: ACTION_TYPES.SET_VM, payload: vm });
      })
      .catch((err) => {
        dispatch({ type: ACTION_TYPES.SET_VM, payload: vm });
        snackActions.error(err);
      });
  }

  function openPutAwayLineItemModal(lineItem: PutAwayLineItemLookupGetModel) {
    dispatch({
      type: ACTION_TYPES.PUTAWAY_MODAL_OPEN,
      payload: lineItem,
    });
  }

  function closePutAwayLineItemModal() {
    dispatch({
      type: ACTION_TYPES.PUTAWAY_MODAL_CLOSE,
      payload: null,
    });
  }

  function setPutAwayLocation(bin?: DropdownListItem) {
    dispatch({
      type: ACTION_TYPES.SET_VM_PUTAWAY_LOCATION,
      payload: bin,
    });
  }

  function setPutAwayQty(qty?: number) {
    dispatch({
      type: ACTION_TYPES.SET_VM_PUTAWAY_QTY,
      payload: qty,
    });
  }

  const validateForm = () => {
    const temp = state.vm.putAwayError;
    const re = /[0-9]+/g;

    if (state.vm.putAway?.bin === null) {
      temp.bin = 'This field is required';
    } else {
      temp.bin = '';
    }

    if (state.vm.putAway?.qty === null) {
      temp.qty = 'This field is required';
    } else if (!re.test(state.vm.putAway?.qty.toString())) {
      temp.qty = 'Must be a number';
    } else {
      temp.qty = '';
    }

    dispatch({
      type: ACTION_TYPES.SET_VM_PUTAWAYERROR,
      payload: temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  function submitPutAway(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();

    if (!validateForm()) {
      snackActions.error('Please complete required fields');
    } else {
      dispatch({
        type: ACTION_TYPES.SET_STATE_ISBUSY,
        payload: true,
      });

      if (
        state.vm.putAway.lineItem.putAwayItemType === PutAwayItemTypeEnum.Each
      ) {
        const PAYLOAD = {
          customerLocationId: currentLocationAndFacility.customerLocationId,
          userId: null, // will set the value in the backend
          customerFacilityId: currentLocationAndFacility.customerFacilityId,
          putAwayId: state.vm.putAway.lineItem.putAwayId,
          binGetCreate: {
            zoneId: state.vm.putAway.bin.zoneId,
            name: state.vm.putAway.bin.text,
          },
          qty: state.vm.putAway.qty,
          putAwayType: state.vm.putAway.lineItem.putAwayType,
          // NOTE: remove capability to put away EACH to existing PALLET
          licensePlateId: null,
        };

        const response = createPutAwayBin(PAYLOAD);

        response
          .then(() => {
            dispatch({
              type: ACTION_TYPES.SET_STATE_ISBUSY,
              payload: false,
            });

            snackActions.success('Put away success.');

            // this will automatically trigger viewLoadData
            dispatch({ type: ACTION_TYPES.PAGE_LOADING_START, payload: null });
          })
          .catch((err) => {
            dispatch({
              type: ACTION_TYPES.SET_STATE_ISBUSY,
              payload: false,
            });
            snackActions.error(err);
          });
      }
      // pallet
      // pallet only have one item
      else {
        const putAway = {
          putAwayId: state.vm.putAway.lineItem.putAwayId,
        };

        const PAYLOAD = {
          customerLocationId: currentLocationAndFacility.customerLocationId,
          userId: null, // will set the value in the backend
          customerFacilityId: currentLocationAndFacility.customerFacilityId,
          putAways: [putAway],
          binGetCreate: {
            zoneId: state.vm.putAway.bin.zoneId,
            name: state.vm.putAway.bin.text,
          },
        };

        const response = createPutAwayPallet(PAYLOAD);

        response
          .then(() => {
            dispatch({
              type: ACTION_TYPES.SET_STATE_ISBUSY,
              payload: false,
            });

            snackActions.success('Put away success.');

            // this will automatically trigger viewLoadData
            dispatch({ type: ACTION_TYPES.PAGE_LOADING_START, payload: null });
          })
          .catch((err) => {
            dispatch({
              type: ACTION_TYPES.SET_STATE_ISBUSY,
              payload: false,
            });
            snackActions.error(err);
          });
      }
    }
  }

  // page load
  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_NONE) {
      dispatch({ type: ACTION_TYPES.PAGE_LOADING_START, payload: null });
    }
  }, [state.pageLoadState]);

  // load data upon page load
  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_START) {
      viewLoadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pageLoadState, currentLocationAndFacility]);

  // facility change
  useEffect(() => {
    // this will automatically trigger viewLoadData
    dispatch({ type: ACTION_TYPES.PAGE_LOADING_START, payload: null });
  }, [currentLocationAndFacility]);

  const value: IPutAway = useMemo(
    () => ({
      state,
      viewLoadData,
      setPutAwayQty,
      submitPutAway,
      setPutAwayLocation,
      setGetPutAwayIdsToDelete,
      openPutAwayLineItemModal,
      closePutAwayLineItemModal,
      getPutAwayIdsToDelete,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, getPutAwayIdsToDelete],
  );

  return (
    <PutAwayContext.Provider value={value}>{children}</PutAwayContext.Provider>
  );
};
