import React, { useContext } from 'react';

import { snackActions } from 'config/snackbar.js';
import { useOrderSourceRuleSettingsContext } from 'pages/settings/context-order-source-rule';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { deleteOrderSourceRule } from 'services/api/warehousecustomer/warehouseCustomer.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Typography, Grid as MUIGrid, Button } from '@mui/material';

import { useWarehouseCustomerContext } from '../warehousecustomer';
import { CustomerType } from '../warehousecustomer/customer-rules-management';

interface INewRulesManagementInterface {
  customerType: CustomerType;
}
export const OrderSourceRuleModal = ({
  customerType,
}: INewRulesManagementInterface) => {
  const {
    isOrderSourceDeleteModalOpen,
    setIsOrderSourceDeleteModalOpen,
    orderSourceRulesData,
    setOrderSourceRulesData,
  } = useContext(GlobalContext);

  // WAREHOUSE CUSTOMER CONTEXT
  const { viewLoadData } = useWarehouseCustomerContext();
  // CUSTOMER CONTEXT
  const { onLoadCustomerRulesManagement } = useOrderSourceRuleSettingsContext();

  const onClickCancel = () => {
    setIsOrderSourceDeleteModalOpen(false);
    setOrderSourceRulesData(null);
  };

  const onClickDeleteOrderSourceRules = async () => {
    try {
      await deleteOrderSourceRule({
        CustomerOrderSourceRuleId: orderSourceRulesData?.id,
      }).then(() => {
        snackActions.success(
          `${orderSourceRulesData?.name} has been successfully removed.`,
        );
        onClickCancel();
        if (customerType === CustomerType.Customer) {
          onLoadCustomerRulesManagement();
        } else if (customerType === CustomerType.WarehouseCustomer) {
          viewLoadData();
        }
      });
    } catch (err) {
      snackActions.error(err);
    }
  };

  return (
    <Modal open={isOrderSourceDeleteModalOpen}>
      <MUIContainer sx={{ width: '450px !important' }}>
        <Typography
          className="modalTextHeaderError"
          variant="h6"
          fontWeight="bold"
        >
          Delete Order Source Rule
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Box
            sx={{
              width: '100%',
              textAlignLast: 'center',
            }}
          >
            <Typography variant="subtitle1">
              Are you sure you want to delete {orderSourceRulesData?.name}?
            </Typography>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={8}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '10px',
                padding: '0px',
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                onClick={() => onClickCancel()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                onClick={() => onClickDeleteOrderSourceRules()}
              >
                Confirm
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
};
