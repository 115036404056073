/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';

import Button from 'components/button';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { deletePurchaseOrder } from 'services/api/purchaseOrders/purchaseOrders.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Box, Modal, Typography, Grid as MUIGrid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { usePurchaseOrderContext } from '../context';

export default React.memo(() => {
  const theme = useTheme();
  const {
    isPODeleteLineItemModalOpen,
    onClosePODeleteLineItemModal,
    isPODeletePackingListLineItemModalOpen,
    onClosePODeletePackingListLineItemModal,
    isPurchaseOrderDetailDeleteModalOpen,
    setIsPurchaseOrderDetailDeleteModalOpen,
    handleUpdateData,
  } = useContext(GlobalContext);

  const { currentLocationAndFacility } = useContext(AuthContext);

  const {
    purchaseOrderIdDelete,
    purchaseOrderLineItemModal,
    purchaseOrderPackingListLineItemModal,
    setPurchaseOrderLineItemModal,
    deletePurchaseOrderLineItem,
    setPurchaseOrderPackingListLineItemModal,
    deletePurchaseOrderPackingListLineItem,
  } = usePurchaseOrderContext();

  const handleClosePODeleteLineItemModal = () => {
    setPurchaseOrderLineItemModal({});
    onClosePODeleteLineItemModal();
  };

  const handleClosePODeletePackingListLineItemModal = () => {
    setPurchaseOrderPackingListLineItemModal({});
    onClosePODeletePackingListLineItemModal();
  };

  const handleDeletePOLineItemModal = async (e: any) => {
    e.preventDefault();

    deletePurchaseOrderLineItem(e);
    setPurchaseOrderLineItemModal({});
    onClosePODeleteLineItemModal();
  };

  const handleDeletePOPackingListLineItemModal = async (e: any) => {
    e.preventDefault();

    deletePurchaseOrderPackingListLineItem(e);
    handleClosePODeletePackingListLineItemModal();
  };

  const deletePO = async () => {
    try {
      const data = {
        purchaseOrderId: purchaseOrderIdDelete,
        customerFacilityId: currentLocationAndFacility.customerFacilityId,
      };

      await deletePurchaseOrder(data);
      snackActions.success(`Successfully removed purchase order.`);
      setIsPurchaseOrderDetailDeleteModalOpen(false);
      handleUpdateData();
    } catch (err: any) {
      snackActions.error(`${err}`);
    }
  };

  return (
    <>
      <Modal open={isPODeleteLineItemModalOpen}>
        <MUIContainer sx={{ maxWidth: '500px !important' }}>
          <Typography
            className="modalTextHeaderError"
            variant="h6"
            fontWeight="bold"
          >
            Delete PO Line Item
          </Typography>
          <MUIContent sx={{ padding: '0px 20px !important' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography sx={{ marginBottom: '16px' }} variant="h6">
                PO Line Item <b>{purchaseOrderLineItemModal.sku}</b>
              </Typography>
              <Typography variant="h6">
                Are you sure you want to delete?
              </Typography>
            </Box>
          </MUIContent>
          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={8}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '10px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={() => handleClosePODeleteLineItemModal()}
                  variant="outlined"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => handleDeletePOLineItemModal(e)}
                >
                  Confirm
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
      <Modal open={isPODeletePackingListLineItemModalOpen}>
        <MUIContainer sx={{ maxWidth: '500px !important' }}>
          <Typography
            className="modalTextHeaderError"
            variant="h6"
            fontWeight="bold"
          >
            Delete Packing List Line Item
          </Typography>
          <MUIContent sx={{ padding: '0px 20px !important' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography sx={{ marginBottom: '16px' }} variant="h6">
                Packing List Line Item{' '}
                <b>{purchaseOrderPackingListLineItemModal.sku}</b>
              </Typography>
              <Typography variant="h6">
                Are you sure you want to delete?
              </Typography>
            </Box>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={8}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '10px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={() => handleClosePODeletePackingListLineItemModal()}
                  variant="outlined"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => handleDeletePOPackingListLineItemModal(e)}
                >
                  Confirm
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>

      <Modal open={isPurchaseOrderDetailDeleteModalOpen}>
        <MUIContainer>
          <Typography
            className="modalTextHeaderError"
            variant="h6"
            fontWeight="bold"
          >
            Delete Purchase Order
          </Typography>
          <MUIContent sx={{ padding: '0px 20px !important' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h6">
                Are you sure you want to delete?
              </Typography>
            </Box>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={4}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={() => setIsPurchaseOrderDetailDeleteModalOpen(false)}
                  variant="outlined"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={deletePO}
                >
                  Confirm
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
    </>
  );
});
