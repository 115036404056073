import React, { useState, useContext, ChangeEvent, useEffect } from 'react';

import Button from 'components/button';
import Input from 'components/input';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { useSubpageReceiptsContext } from 'pages/receiving/receipts/context';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Grid as MUIGrid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface PrintQtyModalProps {
  // eslint-disable-next-line no-unused-vars
  onCallback: (qty: number) => void;
}

type Model = {
  qty?: number;
};

interface ErrorModel {
  qty: string;
}

interface State {
  model: Model;
  error: ErrorModel;
}

const INITIAL_STATE: State = {
  model: {
    qty: 1,
  },
  error: {
    qty: '',
  },
};

export default React.memo(({ onCallback }: PrintQtyModalProps) => {
  const theme = useTheme();
  const { numberToPrint } = useSubpageReceiptsContext();
  const { isPrintQtyModal, setIsPrintQtyModal, numberToPrintGlobal } =
    useContext(GlobalContext);
  const [state, setState] = useState<State>(INITIAL_STATE);

  const validateModel = () => {
    const error = { qty: '' } as ErrorModel;

    if (state.model.qty === null) {
      error.qty = 'This field is required';
    } else if (state.model.qty === 0) {
      error.qty = 'Qty cannot be zero.';
    } else if (state.model.qty < 0) {
      error.qty = 'Qty cannot be negative.';
    }

    setState((prevState: State) => ({
      ...prevState,
      error: {
        ...prevState.error,
        ...error,
      },
    }));

    return Object.values(error).every((x) => x === '');
  };

  const cancelButtonClickHandler = () => {
    setState(INITIAL_STATE);
    setIsPrintQtyModal(false);
  };

  const confirmButtonClickHandler = async () => {
    try {
      if (!validateModel()) {
        snackActions.error('Please complete required fields');
      } else {
        setState(INITIAL_STATE);
        onCallback(state.model.qty);
        setIsPrintQtyModal(false);
      }
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };

  useEffect(() => {
    if (isPrintQtyModal && (numberToPrint > 0 || numberToPrintGlobal > 0)) {
      if (numberToPrint > 0)
        setState((prevState: State) => ({
          ...prevState,
          model: {
            ...prevState.model,
            qty: numberToPrint,
          },
        }));
      if (numberToPrintGlobal > 0)
        setState((prevState: State) => ({
          ...prevState,
          model: {
            ...prevState.model,
            qty: numberToPrintGlobal,
          },
        }));
    }
  }, [isPrintQtyModal]);

  if (!isPrintQtyModal) return null;

  return (
    <Modal open={isPrintQtyModal}>
      <ModalBox>
        <ModalContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            PRINT LABEL
          </Typography>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <Input
                  sx={{ width: '100%', background: 'white' }}
                  placeholder="Qty"
                  type="number"
                  min={0}
                  size="small"
                  value={state.model.qty}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (!e.target.value) {
                      setState((prevState: State) => ({
                        ...prevState,
                        model: {
                          ...prevState.model,
                          qty: null,
                        },
                      }));
                    } else {
                      setState((prevState: State) => ({
                        ...prevState,
                        model: {
                          ...prevState.model,
                          qty: parseInt(e.target.value, 10),
                        },
                      }));
                    }
                  }}
                  required
                  {...(state.error.qty && {
                    error: true,
                    helperText: state.error.qty,
                  })}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end',
              },
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
              },
              gap: '8px',
            }}
          >
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="text"
              size="large"
              onClick={() => cancelButtonClickHandler()}
            >
              Cancel
            </Button>
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="contained"
              size="large"
              onClick={() => confirmButtonClickHandler()}
            >
              Confirm
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
