import React, { useContext } from 'react';

import Button from 'components/button';
import {
  Autocomplete,
  Modal,
  Box,
  Typography,
  TextField,
  Divider,
  Grid as MUIGrid,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { GlobalContext } from 'store/contexts/GlobalContext';
import Card from 'components/card';
import {
  OrderBillingType,
  usePackAndShipContext,
} from 'pages/packandship/context';
import { autoCompleteTimeStamp } from '../../../../helpers/autocompletetimestamp';

export const MUIContainer = MuiStyled(Box)`
    && {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  
      background: ${(props) =>
        props.theme.palette.mode === 'dark'
          ? props.theme.palette.background.default
          : 'white'};
      box-shadow: 0px 24px 38px #00000024;
      width: 100%;
      max-width: 1000px;
      border-radius: 4px;
    }
  `;
export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;
export default React.memo(() => {
  const { isPackAndShipCarrierModalOpen, onClosePackAndShipCarrierModal } =
    useContext(GlobalContext);

  const {
    optionOrderBillings,
    orderBillValue,
    carrierForm,
    carrierFormOnChange,
    setOrderBillValue,
    setCarrierForm,
    updateThePickTicketCarrierDetailsOnClick,
  } = usePackAndShipContext();
  return (
    <Modal open={isPackAndShipCarrierModalOpen}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Carrier
          </Typography>
          <Box autoComplete="off" component="form" noValidate>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={3}>
                <TextField
                  id="outlined"
                  label="Carrier"
                  size="small"
                  name="carrier"
                  value={carrierForm?.carrier || ''}
                  onChange={carrierFormOnChange}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Autocomplete
                  id="controllable-states"
                  options={[]}
                  getOptionLabel={(option: any) => option.label}
                  value={null}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li data-name="accountNo" {...props}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Account No."
                      size="small"
                      sx={{ width: '100%', background: '#ffffff' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="Account Zip/Postal Code"
                  size="small"
                  name="accountZipPostalCode"
                  value={carrierForm?.accountZipPostalCode || ''}
                  onChange={carrierFormOnChange}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={0.1}
              sx={{ marginTop: 2 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={3}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="Delivery Type"
                  size="small"
                  name="deliveryType"
                  value={carrierForm?.deliveryType || ''}
                  onChange={carrierFormOnChange}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="Tracking No."
                  size="small"
                  name="trackingNo"
                  value={carrierForm?.trackingNo || ''}
                  onChange={carrierFormOnChange}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Autocomplete
                  onChange={(event: any, newValue: OrderBillingType) => {
                    if (newValue !== null) {
                      setOrderBillValue(newValue);
                      setCarrierForm((prev) => ({
                        ...prev,
                        billingType: newValue.value,
                      }));
                    }
                  }}
                  id="controllable-states"
                  options={optionOrderBillings}
                  getOptionLabel={(option: OrderBillingType) => option.label}
                  value={orderBillValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props}>{option.label}</li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Billing Type"
                      size="small"
                      sx={{ width: '100%', background: '#ffffff' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="SCAC Code"
                  size="small"
                  name="scacCode"
                  value={carrierForm?.scacCode || ''}
                  onChange={carrierFormOnChange}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={0.1}
              sx={{ marginTop: 2 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={3}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  label="Warehouse Notes"
                  size="small"
                  name="warehouseNotes"
                  value={carrierForm?.warehouseNotes || ''}
                  onChange={carrierFormOnChange}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  label="Carrier Notes"
                  size="small"
                  name="carrierNotes"
                  value={carrierForm?.carrierNotes || ''}
                  onChange={carrierFormOnChange}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => onClosePackAndShipCarrierModal()}
                    variant="outlined"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    onClick={(e) => {
                      updateThePickTicketCarrierDetailsOnClick(e);
                      onClosePackAndShipCarrierModal();
                    }}
                    variant="contained"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    UPDATE
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
