import React, { useContext, memo, useEffect, useState } from 'react';

import DatePickerInput from 'components/datepicker';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Typography,
  Button,
  Grid as MUIGrid,
  Box,
  TextField,
  CircularProgress,
  Stack,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useProductionContext } from '../context';

export default memo(() => {
  const { isKittinBuildKitModalOpen, setIsKittinBuildKitModalOpen } =
    useContext(GlobalContext);

  const {
    kitBuildForm,
    productionForm,
    kitBuildFormErrors,
    setKitBuildForm,
    kitbuildValidation,
    setKitBuildFormErrors,
    handleCreateJobKitBuiltOnSubmit,
  } = useProductionContext();

  const [loading, setLoading] = useState(false);

  const handleCloseOnCLick = () => {
    setIsKittinBuildKitModalOpen(false);
    setKitBuildForm(null);
    setLoading(false);
    setKitBuildFormErrors(null);
  };

  return (
    <Modal open={isKittinBuildKitModalOpen}>
      <MUIContainer sx={{ width: '20% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Kit Details
        </Typography>
        <MUIContent sx={{ padding: '10px 20px !important ' }}>
          <MUIGrid container direction="row" alignItems="center" spacing={2}>
            <MUIGrid item xs={12}>
              <TextField
                onChange={(event: any) => {
                  setKitBuildForm((prev) => ({
                    ...prev,
                    qty: event.target.value,
                  }));
                }}
                autoComplete="off"
                required
                id="outlined-required"
                label="Quantity"
                value={kitBuildForm?.qty}
                size="small"
                inputProps={{
                  autoComplete: 'off',
                }}
                sx={{ width: '100%' }}
                error={!!kitBuildFormErrors?.qty}
                helperText={kitBuildFormErrors?.qty}
              />
            </MUIGrid>
            <MUIGrid item xs={12}>
              <TextField
                onChange={(event: any) => {
                  setKitBuildForm((prev) => ({
                    ...prev,
                    duration: event.target.value,
                  }));
                }}
                autoComplete="off"
                required
                id="outlined-required"
                label="Duration"
                value={kitBuildForm?.duration}
                size="small"
                inputProps={{
                  autoComplete: 'off',
                }}
                sx={{ width: '100%' }}
                error={!!kitBuildFormErrors?.duration}
                helperText={kitBuildFormErrors?.duration}
              />
            </MUIGrid>
            <MUIGrid item xs={12}>
              <DatePickerInput
                label="Completed Date"
                inputFormat="MM/dd/yyyy"
                onChange={(e) => {
                  if (e !== null && e !== 'Invalid Date') {
                    if (e instanceof Date) {
                      setKitBuildForm((prevState) => ({
                        ...prevState,
                        completedDate: e,
                        completedTime: moment(new Date()).format('hh:mm'),
                      }));
                    }
                  } else {
                    setKitBuildForm((prevState) => ({
                      ...prevState,
                      completedDate: null,
                      completedTime: null,
                    }));
                  }
                }}
                value={kitBuildForm?.completedDate || ''}
                renderInput={(params) => (
                  <TextField {...params} error={false} size="small" />
                )}
                sx={{ width: '100%' }}
              />
            </MUIGrid>
            <MUIGrid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <TextField
                    autoComplete="off"
                    id="time"
                    size="small"
                    type="time"
                    label="Completed Time"
                    value={kitBuildForm?.completedTime || null || ''}
                    onChange={(e) => {
                      const date = new Date(kitBuildForm?.completedDate)
                        .toISOString()
                        .split('T')[0];
                      const dateTime = new Date(
                        Date.parse(`${date}T${e.currentTarget.value}:00.000Z`),
                      );
                      if (e?.currentTarget?.value) {
                        setKitBuildForm((prevState) => ({
                          ...prevState,
                          completedTime: e.currentTarget.value,
                          completedDateTime: dateTime.toISOString(),
                        }));
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
              </LocalizationProvider>
            </MUIGrid>
          </MUIGrid>
        </MUIContent>

        <MUIGrid
          container
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
            justifyContent: 'flex-end',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={10}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '10px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => handleCloseOnCLick()}
                variant="outlined"
                size="large"
                sx={{ padding: 1, width: '100%' }}
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => {
                  if (!kitbuildValidation()) {
                    return;
                  }
                  setLoading(true);

                  handleCreateJobKitBuiltOnSubmit(e).then(() => {
                    handleCloseOnCLick();
                  });
                }}
                variant="contained"
                size="large"
                sx={{ padding: 1, width: '100%' }}
                disabled={loading}
              >
                {loading && <CircularProgress sx={{ marginRight: 1 }} />}
                Save
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
