import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getCarrierDDL = async () => {
  try {
    const response = await httpClient.get(`/cartonCodes/ddl/carrier`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCartonCodeDDL = async (carrierId) => {
  try {
    const response = await httpClient.get(`/cartonCodes/ddl/${carrierId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const GetPackageForOrderDDL = async (warehouseCustomerId, carrierId) => {
  try {
    const response = await httpClient.get(
      `/cartonCodes/getpackagefororderddl/${warehouseCustomerId}/${carrierId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getAllCarrierCartonCodeDDL = async (warehouseCustomerId) => {
  try {
    const response = await httpClient.get(
      `/cartonCodes/ddl/all/bycarrier/${warehouseCustomerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createCartonCode = async (data) => {
  try {
    const response = await httpClient.post(`/cartonCodes/create`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateCartonCode = async (data) => {
  try {
    const response = await httpClient.post(`/cartonCodes/update`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteCartonCode = async (data) => {
  try {
    const response = await httpClient.post(`/cartonCodes/delete`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCartonCodes = async () => {
  try {
    const response = await httpClient.get(`/cartonCodes/list`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCartonCodeDetail = async (cartonCodeId) => {
  try {
    const response = await httpClient.get(
      `/cartonCodes/detail/${cartonCodeId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const getPackagingList = async (warehouseCustomerId?: any) => {
  try {
    const response = await httpClient.get(
      `/cartonCodes/GetPackagingList?warehouseCustomerId=${
        warehouseCustomerId || ''
      }`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const getVoidFillList = async () => {
  try {
    const response = await httpClient.get(`/cartonCodes/GetVoidFillList`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
