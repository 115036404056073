import React, { useContext, useEffect } from 'react';

import { StyledMenu } from 'pages/sales/salesorder/orderstatusdropdown';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { FormPurchaseOrderStatuses, usePurchaseOrderContext } from '../context';

const PurchaseOrderStatusDropdown = () => {
  const { isSupplierAccount } = useContext(AuthContext);
  const {
    onOpenPurchaseOrderDocumentModal,
    isPrintFormTemplateLoading,
    setIsPrintFormTemplateLoading,
  } = useContext(GlobalContext);

  const {
    pageViewerPO,
    purchaseOrderDetails,
    viewLoadDataPO,
    setPurchaseOrderDetails,
    addPurchaseOrderPackingList,
    handleOnCreatePurchaseOrder,
    printPurcahseOrderItemOnClick,
  } = usePurchaseOrderContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isUpdate, setIsUpdate] = React.useState<boolean>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMain = async (e: any) => {
    setAnchorEl(null);
  };
  const handleClose = async (e: any) => {
    setAnchorEl(null);
    setIsUpdate(true);
  };

  const handlePrintPO = async (e: any) => {
    setIsPrintFormTemplateLoading(true);
    printPurcahseOrderItemOnClick(e, purchaseOrderDetails.purchaseOrderId);
  };

  useEffect(() => {
    if (isUpdate === true) {
      try {
        handleOnCreatePurchaseOrder(null, true);
      } catch (error) {
        viewLoadDataPO(true);
      }
      setIsUpdate(false);
    }
  }, [isUpdate]);

  return (
    pageViewerPO.isViewOrEdit() && (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          onClick={() => onOpenPurchaseOrderDocumentModal()}
          variant="outlined"
          size="medium"
          sx={{ marginRight: '10px' }}
        >
          Documents
        </Button>

        <LoadingButton
          size="medium"
          onClick={handlePrintPO}
          loading={isPrintFormTemplateLoading}
          loadingPosition="end"
          variant="outlined"
          sx={{ marginRight: '10px' }}
          {...((purchaseOrderDetails?.isAccepted === undefined ||
            purchaseOrderDetails?.isAccepted === null ||
            purchaseOrderDetails?.isAccepted === false) &&
            isSupplierAccount && {
              sx: { display: 'none' },
            })}
        >
          <span>Print PO</span>
        </LoadingButton>
        <Button
          variant="outlined"
          size="medium"
          sx={{ marginRight: '10px' }}
          {...((purchaseOrderDetails?.isAccepted === undefined ||
            purchaseOrderDetails?.isAccepted === null ||
            purchaseOrderDetails?.isAccepted === false) &&
            isSupplierAccount && {
              sx: { display: 'none' },
            })}
          onClick={addPurchaseOrderPackingList}
        >
          Add Packing List
        </Button>
        {isSupplierAccount &&
          purchaseOrderDetails.status !== FormPurchaseOrderStatuses.New &&
          purchaseOrderDetails.status ===
            FormPurchaseOrderStatuses.Submitted && (
            <>
              <Button
                variant="outlined"
                size="medium"
                sx={{ marginRight: '10px' }}
                {...(purchaseOrderDetails.isAccepted === true && {
                  sx: { display: 'none' },
                })}
                onClick={() => {
                  setPurchaseOrderDetails((e) => ({
                    ...e,
                    isAccepted: false,
                  }));
                  setIsUpdate(true);
                }}
              >
                {purchaseOrderDetails.isAccepted === false
                  ? 'Rejected'
                  : 'Reject'}
              </Button>
              <Button
                variant="contained"
                size="medium"
                sx={{ marginRight: '10px' }}
                {...(purchaseOrderDetails.isAccepted === true && {
                  disabled: true,
                })}
                onClick={() => {
                  setPurchaseOrderDetails((e) => ({
                    ...e,
                    isAccepted: true,
                    status: FormPurchaseOrderStatuses.Receiving,
                    statusStr: 'Receiving',
                  }));
                  setIsUpdate(true);
                }}
              >
                {purchaseOrderDetails.isAccepted === true
                  ? 'Accepted'
                  : 'Accept'}
              </Button>
            </>
          )}

        {!isSupplierAccount && (
          <Button
            id="customized-button"
            aria-controls={open ? 'customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="outlined"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {purchaseOrderDetails.statusStr}
          </Button>
        )}

        <StyledMenu
          id="customized-menu"
          MenuListProps={{
            'aria-labelledby': 'customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMain}
        >
          <MenuItem
            {...(purchaseOrderDetails.status ===
              FormPurchaseOrderStatuses.New && {
              sx: { display: 'none' },
            })}
            onClick={(o) => {
              setPurchaseOrderDetails((e) => ({
                ...e,
                status: FormPurchaseOrderStatuses.New,
                statusStr: 'New',
              }));
              handleClose(o);
            }}
            disableRipple
          >
            New
          </MenuItem>
          <MenuItem
            {...(purchaseOrderDetails.status ===
              FormPurchaseOrderStatuses.Submitted && {
              sx: { display: 'none' },
            })}
            onClick={(o) => {
              setPurchaseOrderDetails((e) => ({
                ...e,
                status: FormPurchaseOrderStatuses.Submitted,
                statusStr: 'Submitted',
              }));
              handleClose(o);
              setIsUpdate(true);
            }}
            disableRipple
          >
            Submitted
          </MenuItem>

          {/* <MenuItem
            {...(purchaseOrderDetails.status ===
              FormPurchaseOrderStatuses.Accepted && {
              sx: { display: 'none' },
            })}
            onClick={() => {
              setPurchaseOrderDetails((e) => ({
                ...e,
                status: FormPurchaseOrderStatuses.Accepted,
                statusStr: 'Accepted',
              }));
              handleClose();
            }}
            disableRipple
          >
            Accepted
          </MenuItem>
          <MenuItem
            {...(purchaseOrderDetails.status ===
              FormPurchaseOrderStatuses.Rejected && {
              sx: { display: 'none' },
            })}
            onClick={() => {
              setPurchaseOrderDetails((e) => ({
                ...e,
                status: FormPurchaseOrderStatuses.Rejected,
                statusStr: 'Rejected',
              }));
              handleClose();
            }}
            disableRipple
          >
            Rejected
          </MenuItem> */}

          <MenuItem
            {...(purchaseOrderDetails.status ===
              FormPurchaseOrderStatuses.Receiving && {
              sx: { display: 'none' },
            })}
            onClick={(o) => {
              setPurchaseOrderDetails((e) => ({
                ...e,
                status: FormPurchaseOrderStatuses.Receiving,
                statusStr: 'Receiving',
              }));
              handleClose(o);
            }}
            disableRipple
          >
            Receiving
          </MenuItem>

          <MenuItem
            {...(purchaseOrderDetails.status ===
              FormPurchaseOrderStatuses.Cancelled && {
              sx: { display: 'none' },
            })}
            onClick={(o) => {
              setPurchaseOrderDetails((e) => ({
                ...e,
                status: FormPurchaseOrderStatuses.Cancelled,
                statusStr: 'Cancelled',
              }));
              handleClose(o);
            }}
            disableRipple
          >
            Cancelled
          </MenuItem>

          <MenuItem
            {...(purchaseOrderDetails.status ===
              FormPurchaseOrderStatuses.Closed && {
              sx: { display: 'none' },
            })}
            onClick={(o) => {
              setPurchaseOrderDetails((e) => ({
                ...e,
                status: FormPurchaseOrderStatuses.Closed,
                statusStr: 'Closed',
              }));
              handleClose(o);
            }}
            disableRipple
          >
            Closed
          </MenuItem>
        </StyledMenu>
      </div>
    )
  );
};
export default React.memo(PurchaseOrderStatusDropdown);
