import React, { useState, useCallback, useContext, useEffect } from 'react';

import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import { IAutoCompleteOption } from 'common/interfaces';
import Button from 'components/button';
import Card from 'components/card';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import {
  IPickTicketItemType,
  useSalesOrderContext,
  UOMType,
} from 'pages/sales/salesorder/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridStandard } from 'styles';

import {
  Modal,
  Box,
  Typography,
  Divider,
  Autocomplete,
  Grid as MUIGrid,
  TextField,
} from '@mui/material';
import { DataGrid, GridColumns, GridEditRowsModel } from '@mui/x-data-grid';

const PickTicketItems = () => {
  const {
    pageViewer,
    orderPickTicketItemModalRows,
    setButtonDisable,
    setOrderCreationOfPickTicketItemRows,
  } = useSalesOrderContext();

  const [selectionModelId, setSelectionModelId] = React.useState(() =>
    orderPickTicketItemModalRows
      .filter((r) => r.orderLineItemId)
      .map((r) => r.orderLineItemId),
  );

  const [editRowsModel, setEditRowsModel] = React.useState({});

  const handleEditSingleRowModelChange = React.useCallback(
    (model: GridEditRowsModel) => {
      setEditRowsModel(model);
      const data = [...orderPickTicketItemModalRows];
      const newValue = data.filter(
        (row) => row.orderLineItemId === Number(Object.keys(model)[0]),
      );
      newValue[0].quantity = Number(Object.values(model)[0].quantity.value);

      const filterItemFromArray = data.filter(
        (item) => item.orderLineItemId !== Number(Object.keys(model)[0]),
      );
    },
    [],
  );

  const pickTicketItemsColumns: GridColumns = [
    {
      field: 'sku',
      minWidth: 200,
      flex: 1,
      renderHeader: () => <Typography fontWeight="bold">SKU</Typography>,
    },
    {
      field: 'description',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Typography fontWeight="bold">Description</Typography>
      ),
    },
    {
      field: 'lotNo',
      width: 120,
      renderHeader: () => <Typography fontWeight="bold">Lot No.</Typography>,
    },
    {
      field: 'quantity',
      width: 120,
      type: 'number',
      editable: true,
      valueFormatter: ({ value }) => (value < 0 ? 0 : value),
      renderHeader: () => <Typography fontWeight="bold">Quantity</Typography>,
      renderCell: (params) => (
        <Typography>
          {params?.row?.uomType === UOMType.Primary &&
            `${params.row.quantity.toLocaleString()}`}

          {params?.row?.uomType === UOMType.Other &&
            `${params.row.quantity.toLocaleString()} ${
              params?.row?.otherUOMAbbr
            } (${decimalValidationHelper(
              (params.row.quantity * params.row.otherUOMCount).toString(),
            )} ${params.row.primaryUOMAbbr})`}

          {params?.row?.uomType === UOMType.Pallet &&
            `${params.row.totalCases.toLocaleString()} pallet (${decimalValidationHelper(
              (params.row.totalCases * params.row.otherUOMCount).toString(),
            )} ${params.row.primaryUOMAbbr})`}
        </Typography>
      ),
    },
  ];

  useEffect(() => {
    if (selectionModelId.length !== 0) {
      const selectedIDs = new Set(selectionModelId);
      const selectedRowData = orderPickTicketItemModalRows.filter((row) =>
        new Set(selectedIDs).has(row.orderLineItemId),
      );

      setOrderCreationOfPickTicketItemRows(selectedRowData);

      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  }, [selectionModelId]);

  return (
    <DatagridStandard
      autoHeight
      headerHeight={120}
      rows={orderPickTicketItemModalRows}
      density="compact"
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      disableColumnMenu
      disableSelectionOnClick
      selectionModel={selectionModelId}
      editRowsModel={editRowsModel}
      onEditRowsModelChange={handleEditSingleRowModelChange}
      checkboxSelection={!pageViewer.isEdit()}
      onSelectionModelChange={(ids) => {
        if (!pageViewer.isEdit()) {
          const selectedIDs = new Set(ids);
          const selectedRowData = orderPickTicketItemModalRows.filter((row) =>
            selectedIDs.has(row.orderLineItemId),
          );
          setSelectionModelId(
            selectedRowData
              ?.filter((r) => r.orderLineItemId)
              .map((r) => r.orderLineItemId),
          );
        }
      }}
      columns={pickTicketItemsColumns}
      rowsPerPageOptions={[40]}
      pageSize={40}
      getRowId={(row) => row.orderLineItemId}
      sx={{
        borderColor: 'transparent',
        '& .MuiDataGrid-columnSeparator--sideRight': {
          display: 'none !important',
        },
      }}
    />
  );
};

export default React.memo(() => {
  const { isPickTicketSalesOrderModalOpen, onClosePickTicketSalesOrderModal } =
    useContext(GlobalContext);
  const { handleUserPermissionAllow } = useContext(AuthContext);

  const {
    pageViewer,
    isButtonDisable,
    createOrderPickTicketAndChangeStatusOnClick,
    updateOrderPickTicketAndChangeStatusOnClick,
    optionOperator,
    operatorValue,
    setOperatorValue,
    salesOrderFlagStatus,
    isPickTicketEdit,
    setIsPickTicketEdit,
  } = useSalesOrderContext();

  return (
    <Modal open={isPickTicketSalesOrderModalOpen}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Pick Ticket
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Typography variant="subtitle1">
            Create a pick ticket with the available inventory for the following
            item?
          </Typography>
          <MUIGrid item xs={2} width="300px">
            {handleUserPermissionAllow(
              UserPermissionEnum.Orders_Assignment,
            ) && (
              <Autocomplete
                sx={{ width: '100%' }}
                options={optionOperator}
                value={operatorValue}
                size="small"
                getOptionLabel={(option: IAutoCompleteOption) => option.label}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                onChange={(event: any, newValue) => {
                  if (newValue === null || newValue === undefined) {
                    setOperatorValue(null);
                  } else {
                    setOperatorValue((prevState) => ({
                      ...prevState,
                      ...newValue,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      '& .MuiInputBase-root': {
                        background: 'white',
                      },
                    }}
                    {...params}
                    label="Operator"
                  />
                )}
              />
            )}
          </MUIGrid>

          <PickTicketItems />
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={7}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
              autoComplete="off"
              component="form"
            >
              <Button
                onClick={() => {
                  setIsPickTicketEdit(false);
                  onClosePickTicketSalesOrderModal();
                }}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '50%' }}
              >
                CANCEL
              </Button>

              <Button
                disabled={!isButtonDisable}
                onClick={
                  isPickTicketEdit
                    ? updateOrderPickTicketAndChangeStatusOnClick
                    : createOrderPickTicketAndChangeStatusOnClick
                }
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  ...(!isButtonDisable && {
                    border: '1px solid #999999 !important',
                    backgroundColor: '#cccccc !important',
                    color: '#666666 !important',
                  }),
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
              >
                {!isPickTicketEdit && 'CREATE TICKET AND CHANGE STATUS'}

                {isPickTicketEdit && 'UPDATE TICKET'}
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
