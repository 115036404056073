import React, { useState } from 'react';

import { IAutoComplete, IModal } from 'common/props';
import Button from 'components/button';
import Input from 'components/input';
import { ModalBox, ModalContent } from 'components/styles';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {
  Modal,
  Box,
  Typography,
  Autocomplete,
  TextField,
  IconButton,
  Tooltip,
  Divider,
} from '@mui/material';

import { ModalFooterEnd } from './styles';

const optionDefault: IAutoComplete[] = [
  {
    label: '',
    value: '',
  },
  {
    label: 'DEFAULT',
    value: 'DEFAULT',
  },
];

const selecedDefault: IAutoComplete = {
  label: '',
  value: '',
};

export const ModalLocationInfo = (props: IModal) => {
  const { IsOpen, OnModalClose } = props;
  const [Option, SetOption] = useState<IAutoComplete[]>(optionDefault);
  const [SelectedOption, SetSelectedOption] =
    useState<IAutoComplete>(selecedDefault);
  return (
    <Modal open={IsOpen}>
      <ModalBox>
        <ModalContent>
          <Typography
            sx={{ marginBottom: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Location
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Input sx={{ width: '100%' }} placeholder="Bin Location Name" />

            <Autocomplete
              id="controllable-states"
              options={Option}
              value={SelectedOption}
              renderInput={(params) => (
                <TextField {...params} label="Area" size="small" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
            />

            <Autocomplete
              id="controllable-states-2"
              options={Option}
              renderInput={(params) => (
                <TextField {...params} label="Category" size="small" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
            />

            <Autocomplete
              id="controllable-states-3"
              options={Option}
              renderInput={(params) => (
                <TextField {...params} label="Billing Type" size="small" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
            />

            <Input
              sx={{ width: '100%' }}
              placeholder="Sequence No."
              type="number"
            />

            <Divider sx={{ mt: 2 }} textAlign="left">
              Bin Position
              <Tooltip
                title="If bin position is filled. 'Location Name' should be Aisle-Row-Shelf-Level-Bin."
                placement="bottom"
                arrow
              >
                <IconButton aria-label="print" color="primary">
                  <HelpOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Divider>
            <Input sx={{ width: '100%' }} placeholder="Aisle" />
            <Input sx={{ width: '100%' }} placeholder="Row" />
            <Input sx={{ width: '100%' }} placeholder="Shelf" />
            <Input sx={{ width: '100%' }} placeholder="Level" />
          </Box>
          <ModalFooterEnd>
            <Button variant="contained" size="medium">
              SAVE
            </Button>
            <Button variant="text" size="medium" onClick={OnModalClose}>
              CANCEL
            </Button>
          </ModalFooterEnd>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
};
