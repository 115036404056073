import React, { useEffect, useState } from 'react';

import moment from 'moment';
import {
  getAlereLogsList,
  getShopifyLogsList,
} from 'services/api/integrations/integrations.api';
import styled from 'styled-components';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Autocomplete,
  Box,
  Modal,
  TextField,
  Typography,
  Button,
  Grid as MUIGrid,
  IconButton,
  Link,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { GridColumns, gridClasses } from '@mui/x-data-grid';

import { DatagridStandard } from 'styles';

import { useWarehouseCustomerContext } from '..';

interface AutoCompleteOptionType {
  id: number;
  label: string;
}

const ModalBox = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 876px;
    max-height: 820px;
    border-radius: 4px;
  }
`;

const ModalContent = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;

export default React.memo(() => {
  const {
    isViewLogsIntegrationModalOpen,
    setIsViewLogsIntegrationModalOpen,
    setSelectedIntegrationId,
    queryString,
    view,
  } = useWarehouseCustomerContext();

  const filterList: AutoCompleteOptionType[] = [
    {
      id: 1,
      label: 'Last 1 day',
    },
    {
      id: 7,
      label: 'Last 7 days',
    },
    {
      id: 30,
      label: 'Last 30 days',
    },
    {
      id: 0,
      label: 'All',
    },
  ];

  const logTypeList: AutoCompleteOptionType[] = [
    {
      id: 0,
      label: 'Success',
    },
    {
      id: 1,
      label: 'Error',
    },
  ];

  const [selectedView, setSelectedView] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<any>(null);
  const [logsList, setLogsList] = useState<any[]>([]);
  const defaultListValue = {
    id: 0,
    label: 'All',
  };

  useEffect(() => {
    setSelectedView(defaultListValue);
    setSelectedType(defaultListValue);
  }, [isViewLogsIntegrationModalOpen]);

  useEffect(() => {
    if (
      isViewLogsIntegrationModalOpen &&
      selectedType !== null &&
      selectedView !== null
    )
      if (view.toLowerCase() === 'alere') {
        getAlereLogsList(selectedType.label, selectedView.id).then((res) => {
          setLogsList(res);
        });
      } else {
        getShopifyLogsList(
          queryString.id ?? 0,
          selectedType.label,
          selectedView.id,
        ).then((res) => {
          setLogsList(res);
        });
      }
  }, [selectedView, selectedType, isViewLogsIntegrationModalOpen]);

  const handleCloseModal = () => {
    setIsViewLogsIntegrationModalOpen(false);
    setSelectedIntegrationId(null);
  };

  const csvTemplate = (e, stringToList: string) => {
    e.preventDefault();
    const csvContent = `data:text/csv;charset=utf-8,${stringToList}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'integration_log.csv');
    document.body.appendChild(link);

    link.click();
  };

  const gridColumns: GridColumns = [
    {
      field: 'transactionLog',
      minWidth: 400,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1px' }}>
          <Typography fontWeight="bold">Result</Typography>
        </Box>
      ),
      renderCell: (params) =>
        params.row.integrationSource === 'Alere' &&
        params.row.isError === false &&
        params.row.logType === 'Inventory' ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            href="#"
            onClick={(e) => csvTemplate(e, params.row.transactionLog)}
          >
            View File
          </Link>
        ) : (
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>
            {params.row.transactionLog}
          </Typography>
        ),
    },
    {
      field: 'createdDate',
      minWidth: 150,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdDate).format('MM/DD/YYYY hh:mm A')}
        </Typography>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1px' }}>
          <Typography fontWeight="bold">Date</Typography>
        </Box>
      ),
    },
    {
      field: 'type',
      width: 70,
      renderCell: (params) => (
        <IconButton aria-label="">
          {params.row.isError ? (
            <ErrorIcon aria-label="Error" />
          ) : (
            <CheckCircleIcon aria-label="Success" />
          )}
        </IconButton>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Type</Typography>
        </Box>
      ),
    },
  ];

  return (
    <Modal
      open={isViewLogsIntegrationModalOpen}
      onClose={() => handleCloseModal()}
    >
      <ModalBox>
        <ModalContent sx={{ maxHeight: '820px', overflow: 'auto' }}>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Logs
          </Typography>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={6}>
              <Autocomplete
                id="checkboxes-tags-demo"
                options={filterList}
                value={selectedView}
                getOptionLabel={(option: AutoCompleteOptionType) =>
                  option.label
                }
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option.label}</li>
                )}
                onChange={(event: any, newValue) => {
                  if (newValue !== null) {
                    setSelectedView(newValue);
                  } else {
                    setSelectedView(defaultListValue);
                  }
                }}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="View"
                    placeholder="View"
                    size="small"
                  />
                )}
              />
            </MUIGrid>
            <MUIGrid item xs={6}>
              <Autocomplete
                id="checkboxes-tags-demo"
                options={logTypeList}
                value={selectedType}
                getOptionLabel={(option: AutoCompleteOptionType) =>
                  option.label
                }
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option.label}</li>
                )}
                onChange={(event: any, newValue) => {
                  if (newValue !== null) {
                    setSelectedType(newValue);
                  } else {
                    setSelectedType(defaultListValue);
                  }
                }}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type"
                    placeholder="Type"
                    size="small"
                  />
                )}
              />
            </MUIGrid>
          </MUIGrid>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={12}>
              <DatagridStandard
                sx={{
                  [`& .${gridClasses.cell}`]: {
                    maxHeight: 'none !important;',
                    height: 'auto !important;',
                    wrapText: true,
                  },
                  [`& .${gridClasses.row}`]: {
                    maxHeight: 'none !important;',
                    height: 'auto !important;',
                    wrapText: true,
                    color: 'black',
                  },
                  [`& .${gridClasses}`]: {
                    maxHeight: 'none !important;',
                    height: 'auto !important;',
                    wrapText: true,
                  },
                  [`& .${gridClasses.virtualScroller}`]: {
                    height: '420px !important;',
                    wrapText: true,
                  },
                }}
                autoHeight
                rows={logsList}
                columns={gridColumns}
                density="compact"
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
                hideFooterSelectedRowCount
                initialState={{
                  pagination: {
                    pageSize: 25,
                  },
                }}
                getRowId={() => Math.random()}
              />
            </MUIGrid>
          </MUIGrid>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: '8px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              size="medium"
              onClick={() => handleCloseModal()}
            >
              Close
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
