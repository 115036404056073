import { createPortal } from 'react-dom';

import { Box } from '@mui/material';

interface ImageFullScreenPortalProps {
  isOpen: boolean;
  imageSrc: string;
  onImageFullScreenClose: () => void;
}

export default function ImageFullScreenPortal({
  isOpen,
  imageSrc,
  onImageFullScreenClose,
}: ImageFullScreenPortalProps) {
  if (isOpen === false) {
    return null;
  }

  return createPortal(
    <Box
      onClick={onImageFullScreenClose}
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '9999',
        cursor: 'pointer',
      }}
    >
      <img
        style={{
          objectFit: 'contain',
        }}
        src={imageSrc}
        alt="Full Screen"
      />
    </Box>,
    document.getElementById('image-fullscreen-root'),
  );
}
