import React, {
  useContext,
  useState,
  ChangeEvent,
  useEffect,
  useRef,
} from 'react';

import Button from 'components/button';
import DatePickerInput from 'components/datepicker';
import Input from 'components/input';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import {
  updateWarehouseCustomerOtherContact,
  createWarehouseCustomerOtherContact,
} from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { autoCompleteTimeStamp } from '../../../helpers/autocompletetimestamp';

export default React.memo(() => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);

  const {
    form,
    setForm,
    isAddNew,
    isEditForm,
    dataSelected,
    otherContactData,
    setOtherContactData,
  } = useWarehouseCustomerContext();

  const {
    isOtherContactModalOpen,
    onCloseOtherContactModal,
    handleUpdateData,
  } = useContext(GlobalContext);

  const initialState: any = {
    warehouseCustomerOtherContactId: '',
    name: '',
    title: '',
    email: '',
    phone: '',
    phone2: '',
    notes: '',
  };

  const initialFormErrorsState = {
    name: '',
    title: '',
    email: '',
    phone: '',
    phone2: '',
    notes: '',
  };

  const [formOther, setFormOther] = useState<any>(dataSelected);
  const [formErrors, setFormErrors] = useState<any>(initialFormErrorsState);
  const [notesValue, setNotesValue] = useState('');

  const handleCloseOtherContactModal = () => {
    setFormErrors(initialFormErrorsState);
    onCloseOtherContactModal();
  };

  const onForm = (key, text) => {
    setFormOther(() => ({
      ...formOther,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    onForm(key, event.target.value);
  };

  const validateOnlick = (fieldValues = formOther) => {
    const temp: any = {};

    if ('name' in fieldValues) {
      temp.name = fieldValues.name ? '' : 'This field is required!';
    }

    if ('email' in fieldValues) {
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ''
        : 'Email is not valid!';
    }

    setFormErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleOnAddOtherContact = async (e: any) => {
    e.preventDefault();

    if (validateOnlick()) {
      if (isAddNew) {
        const payload = {
          name: formOther.name,
          title: formOther.title,
          email: formOther.email,
          phone: formOther.phone,
          phone2: formOther.phone2,
          notes: notesValue,
        };

        const newField = payload;
        setOtherContactData([...otherContactData, newField]);
      } else if (isEditForm) {
        const payload = {
          customerId: parseInt(currentUser.Claim_CustomerId, 10),
          warehouseCustomerId: form.warehouseCustomerId,
          warehouseCustomerOtherContactId:
            formOther.warehouseCustomerOtherContactId,
          name: formOther.name,
          title: formOther.title,
          email: formOther.email,
          phone: formOther.phone,
          phone2: formOther.phone2,
          notes: notesValue,
        };

        if (formOther.warehouseCustomerOtherContactId !== '') {
          const updateRecord = payload;
          const result = await updateWarehouseCustomerOtherContact(
            updateRecord,
          );
          snackActions.success(
            `Other Contact ${result.name} has been successfully updated.`,
          );
          const existingList = [...otherContactData];
          const newValue = existingList.find(
            (a) =>
              a.warehouseCustomerOtherContactId ===
              payload.warehouseCustomerOtherContactId,
          );

          newValue.name = result.name;
          newValue.title = result.title;
          newValue.email = result.email;
          newValue.phone = result.phone;
          newValue.phone2 = result.phone2;
          newValue.notes = result.notes;

          setOtherContactData(existingList);
        } else {
          const createRecord = payload;
          const result = await createWarehouseCustomerOtherContact(
            createRecord,
          );
          snackActions.success(
            `Other Contact ${result.name} has been successfully created.`,
          );

          const newValue = {
            warehouseCustomerOtherContactId:
              result.warehouseCustomerOtherContactId,
            name: result.name,
            title: result.title,
            email: result.email,
            phone: result.phone,
            phone2: result.phone2,
            notes: result.notes,
          };
          setOtherContactData((existArray) => [newValue, ...existArray]);
        }
      }
      setNotesValue('');
      setFormOther(initialState);
      handleUpdateData();
      handleCloseOtherContactModal();
    }

    // else UPDATE FUNCTION
  };

  useEffect(() => {
    if (isAddNew) {
      if (dataSelected != null) {
        setFormOther((e) => ({
          ...e,
          name: dataSelected.name,
          title: dataSelected.title,
          email: dataSelected.email,
          phone: dataSelected.phone,
          phone2: dataSelected.phone2,
        }));
        setNotesValue(dataSelected.notes);
      }
    }

    if (isEditForm) {
      if (dataSelected != null) {
        setFormOther((e) => ({
          ...e,
          warehouseCustomerOtherContactId:
            dataSelected.warehouseCustomerOtherContactId,
          name: dataSelected.name,
          title: dataSelected.title,
          email: dataSelected.email,
          phone: dataSelected.phone,
          phone2: dataSelected.phone2,
        }));
        setNotesValue(dataSelected.notes);
      }
    }
  }, [dataSelected]);

  return (
    <Modal
      open={isOtherContactModalOpen}
      onClose={handleCloseOtherContactModal}
    >
      <ModalBox>
        <ModalContent>
          <>
            <Typography
              sx={{ marginBottom: '16px' }}
              variant="h6"
              fontWeight="bold"
            >
              Contact Details
            </Typography>
            <Input
              placeholder="Name *"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={formOther.name}
              error={formErrors.name}
              onChange={(value) => inputHandler('name', value)}
            />
            <Input
              placeholder="Title"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={formOther.title}
              onChange={(value) => inputHandler('title', value)}
            />
            <Input
              placeholder="Email"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={formOther.email}
              error={formErrors.email}
              onChange={(value) => inputHandler('email', value)}
            />
            <Input
              placeholder="Phone"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={formOther.phone}
              onChange={(value) => inputHandler('phone', value)}
            />
            <Input
              placeholder="Phone 2"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={formOther.phone2}
              onChange={(value) => inputHandler('phone2', value)}
            />
            <TextField
              inputProps={{
                autoComplete: autoCompleteTimeStamp().toString(),
              }}
              autoComplete="off"
              label="Notes"
              value={notesValue}
              onChange={(e) => setNotesValue(e.target.value)}
              multiline
              minRows={6}
              sx={{ width: '100%' }}
              size="small"
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                  justifyContent: 'flex-end',
                },
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'center',
                },
                gap: '8px',
              }}
            >
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="text"
                size="large"
                onClick={() => handleCloseOtherContactModal()}
              >
                Cancel
              </Button>
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="contained"
                size="large"
                onClick={handleOnAddOtherContact}
              >
                Save
              </Button>
            </Box>
          </>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
