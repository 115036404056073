import { IGridTile, IGridTileLayout } from 'common/interfaces';
import { GetISOIdString } from './global';

export const ToGridTile = (
  i: string,
  code: string,
  layout: IGridTileLayout,
  children: JSX.Element,
) =>
  <IGridTile>{
    i,
    code,
    layout,
    children,
    type: 'text',
  };

export const ToGridTileClone = (tile: IGridTile, layout: IGridTileLayout) => {
  const clone = { ...tile };
  clone.layout.x = layout.x;
  clone.layout.y = layout.y;
  clone.layout.w = layout.w;
  clone.layout.h = layout.h;
  return clone;
};

export const ToGridTileLayout = (tile: IGridTile) =>
  <IGridTileLayout>{
    i: tile.i,
    x: tile.layout.x,
    y: tile.layout.y,
    w: tile.layout.w,
    h: tile.layout.h,
  };

export const GridTileCloner = (
  base: IGridTileLayout,
  direction: string,
  count: number,
) => {
  const arr: IGridTileLayout[] = [];

  if (direction === 'right') {
    let nextX = base.x;
    const newTiles = new Array(count).fill(undefined).map(() => {
      nextX += base.w;
      const id = `${GetISOIdString()}${nextX}${count}`;
      const item: IGridTileLayout = {
        i: id,
        x: nextX,
        y: base.y,
        w: base.w,
        h: base.h,
      };
      return item;
    });
    arr.push(...newTiles);
  }

  if (direction === 'left') {
    let nextX = base.x;
    const newTiles = new Array(count).fill(undefined).map(() => {
      nextX -= base.w;
      const id = `${GetISOIdString()}${nextX}${count}`;
      const item: IGridTileLayout = {
        i: id,
        x: nextX,
        y: base.y,
        w: base.w,
        h: base.h,
      };
      return item;
    });
    arr.push(...newTiles);
  }

  if (direction === 'bottom') {
    let nextY = base.y;
    const newTiles = new Array(count).fill(undefined).map(() => {
      nextY += base.h;
      const id = `${GetISOIdString()}${nextY}${count}`;
      const item: IGridTileLayout = {
        i: id,
        x: base.x,
        y: nextY,
        w: base.w,
        h: base.h,
      };
      return item;
    });
    arr.push(...newTiles);
  }

  if (direction === 'top') {
    let nextY = base.y;
    const newTiles = new Array(count).fill(undefined).map(() => {
      nextY -= base.h;
      const id = `${GetISOIdString()}${nextY}${count}`;
      const item: IGridTileLayout = {
        i: id,
        x: base.x,
        y: nextY,
        w: base.w,
        h: base.h,
      };
      return item;
    });
    arr.push(...newTiles);
  }

  return arr;
};

export const GridTilesSortingByRowCol = (items: IGridTileLayout[]) =>
  [].concat(items).sort((a, b) => {
    if (a.y === b.y && a.x === b.x) {
      return 0;
    }
    if (a.y > b.y || (a.y === b.y && a.x > b.x)) {
      return 1;
    }
    return -1;
  });

export const HasTileCollide = (
  item1: IGridTileLayout,
  item2: IGridTileLayout,
) => {
  if (item1 === item2) return false;
  const compare =
    item1.x + (item1.w - 1) >= item2.x &&
    item2.x + (item2.w - 1) >= item1.x &&
    item1.y + (item1.h - 1) >= item2.y &&
    item2.y + (item2.h - 1) >= item1.y;
  return compare;
};

export const GetGridTileCollisions = (
  items: IGridTileLayout[],
  item: IGridTileLayout,
) => items.filter((i) => HasTileCollide(i, item));

export const GridTileShift = (
  items: IGridTileLayout[],
  collision: IGridTileLayout[],
  item: IGridTileLayout,
  direction: string,
) => {
  console.log('collision', collision);
};

export const GridTileCompact = (
  items: IGridTile[],
  newItems: IGridTile[],
  direction: string,
) => {
  const clonedItems = [...items];
  const layouts = clonedItems.map((c) => ToGridTileLayout(c));
  newItems.forEach((newItem) => {
    const collision = GetGridTileCollisions(layouts, newItem.layout);
    if (collision.length === 0) {
      clonedItems.push(newItem);
    } else {
      GridTileShift(layouts, collision, newItem.layout, direction);
      clonedItems.push(newItem);
    }
  });
  return clonedItems;
};
