import React, { useContext, useEffect, useRef, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { usePackAndShipContext } from 'pages/packandship/context';
import { UpdateCartonCodeFunctionModel } from 'pages/packandship/models';
import { AutocompleteBase } from 'pages/sales/orderprocessingrules/context';
import { PrinterSettingsState } from 'pages/settings/tabviews/PrinterSettings';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import CloseIcon from '@mui/icons-material/Close';
import WidgetsIcon from '@mui/icons-material/Widgets';
import {
  Modal,
  Typography,
  Grid as MUIGrid,
  Box,
  Button,
  IconButton,
  CircularProgress,
  Tooltip,
  Divider,
  TextField,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

export type CartonCodesTypes = AutocompleteBase;

export default React.memo(() => {
  const {
    isPalletizeModalOpen,
    isNextPackageModalOpen,
    setIsPalletizeModalOpen,
    isChangePackageModalOpen,
    setIsAutoCreateLabelData,
    setIsNextPackageModalOpen,
    setIsChangePackageModalOpen,
    setIsAutoCreateLabelModalOpen,
    setIsAutoCreateLabelErrorModal,
    setIsAddCustomPackageModalOpen,
    setIsAutoCreateLabelErrorMessage,
    setIsPackAndShipCompleteModalOpen,
  } = useContext(GlobalContext);

  const {
    checklists,
    dataUrlLabels,
    allowPackageNo,
    pickTicketFormObj,
    isNotPrimaryPackage,
    isLoadingToComplete,
    scanTheItemCodeField,
    isGeneratePrintLabel,
    useNotPrimaryPackageId,
    billingAdhocChargesData,
    optionPackageCartonCodes,
    queryStringItemPackAndShip,
    disablePressEnterToConfirmButton,
    setAllowPackageNo,
    setIsVoidFillModal,
    setPickTicketFormObj,
    createPackageFunction,
    setIsNotPrimaryPackage,
    setAllowBarcodeScanner,
    updateCartonCodeFunction,
    setUseNotPrimaryPackageId,
    setUpdateCartonCodeFunctionModel,
    updatePackageToShipAndCompleteOnClick,
    updatePackAndShipToShipEasyPostFunction,
  } = usePackAndShipContext();

  const { currentLocationAndFacility } = useContext(AuthContext);

  const [packageNo, setPackageNo] = useState('');
  const packageNoRef = useRef(null);
  const ticker = useRef(null);

  const [cartonCodesToHighLight, setCartonCodesToHighLight] =
    useState<AutocompleteBase>({ value: null, label: '' });
  const [isLoadingLabel, setIsLoadingLabel] = useState<boolean>(false);
  const [hasPrinterSettings, setHasPrinterSettings] = useState<boolean>(false);

  const handlePressEnterToConfirmButton = async (event?: any) => {
    event?.preventDefault();

    if (pickTicketFormObj?.isAllLineItemPack) {
      const gettempPackage = [...pickTicketFormObj.packages];

      if (
        gettempPackage[0].isNoPackage === false &&
        gettempPackage[0].itemAsPackage.value === undefined &&
        !gettempPackage[0].carrierPredefinedPackage &&
        gettempPackage[0].isCustomPackage !== true
      ) {
        snackActions.error(
          'One or more packages do not have a package type selected. Select a package type and try again.',
        );
        setIsNextPackageModalOpen(false);
        return;
      }

      if (
        checklists?.length !==
        checklists?.filter((item) => item.isChecked).length
      ) {
        setIsAutoCreateLabelErrorMessage('Please check all checklists.');
        setIsAutoCreateLabelModalOpen(false);
        setIsAutoCreateLabelErrorModal(true);
        return;
      }

      if (pickTicketFormObj?.isCustomOrOtherCarrier) {
        setPickTicketFormObj((prev) => ({
          ...prev,
          justComplete: true,
        }));
        setAllowBarcodeScanner(false);
        setIsPackAndShipCompleteModalOpen(true);
      } else {
        updatePackageToShipAndCompleteOnClick(event);
      }
    } else {
      const gettempPackage = [...pickTicketFormObj.packages];

      if (gettempPackage[0].itemAsPackage.value) {
        const tempPackages = [...optionPackageCartonCodes];
        const packageToFind = tempPackages.find(
          (x) => x.value === gettempPackage[0].itemAsPackage.value,
        );

        if (packageToFind.acceptsVoidFill) {
          const updateCartonCodeFunctionModel: UpdateCartonCodeFunctionModel = {
            packageIdParam: gettempPackage[0].packageId.toString(),
            cartonCodeIdParam: gettempPackage[0].itemAsPackage.value.toString(),
            isCreateNewPackage: true,
            isExternalPackage: undefined,
            isCustomPackageParam: undefined,
          };
          setUpdateCartonCodeFunctionModel({
            ...updateCartonCodeFunctionModel,
          });

          setIsVoidFillModal(true);
          setIsChangePackageModalOpen(false);
          setIsNextPackageModalOpen(false);
          setIsPalletizeModalOpen(false);
        } else {
          updateCartonCodeFunction(
            gettempPackage[0].packageId.toString(),
            gettempPackage[0].itemAsPackage.value.toString(),
            true,
            gettempPackage[0].isExternalPackage,
            undefined,
            null,
          );
        }
      } else {
        if (
          gettempPackage[0].itemAsPackage.value === undefined &&
          !gettempPackage[0].carrierPredefinedPackage
        ) {
          snackActions.error('Please select package');
          return;
        }

        createPackageFunction();
      }
    }
    setIsChangePackageModalOpen(false);
    setIsNextPackageModalOpen(false);
  };

  useEffect(() => {
    setIsLoadingLabel(true);

    const keyDownHandler = (event) => {
      if (packageNo === 'a' || packageNo === 'A') {
        setIsAddCustomPackageModalOpen(true);
        setIsChangePackageModalOpen(false);
        setIsNextPackageModalOpen(false);
        setIsPalletizeModalOpen(false);
        return;
      }

      if (isChangePackageModalOpen) {
        if (event.key === 'Escape') {
          event.preventDefault();
          setIsChangePackageModalOpen(false);
          setIsNextPackageModalOpen(false);
          setIsPalletizeModalOpen(false);
          // eslint-disable-next-line no-restricted-globals
        } else if (!isNaN(parseInt(packageNo, 10)) && event.key === 'Enter') {
          const gettempPackage = [...pickTicketFormObj.packages];
          const getValue = optionPackageCartonCodes.find(
            (e, index) => index === Number(packageNo),
          );
          if (getValue !== undefined) {
            if (getValue.acceptsVoidFill) {
              const updateCartonCodeFunctionModel: UpdateCartonCodeFunctionModel =
                {
                  packageIdParam:
                    isNotPrimaryPackage === true
                      ? useNotPrimaryPackageId
                      : gettempPackage[0].packageId.toString(),
                  cartonCodeIdParam: getValue.value.toString(),
                  isCreateNewPackage: null,
                  isExternalPackage: getValue.isExternalPackage,
                };
              setUpdateCartonCodeFunctionModel({
                ...updateCartonCodeFunctionModel,
              });

              setIsVoidFillModal(true);
              setIsChangePackageModalOpen(false);
              setIsNextPackageModalOpen(false);
              setIsPalletizeModalOpen(false);
            } else {
              updateCartonCodeFunction(
                isNotPrimaryPackage === true
                  ? useNotPrimaryPackageId
                  : gettempPackage[0].packageId.toString(),
                getValue.value.toString(),
                null,
                getValue.isExternalPackage,
              );

              setIsChangePackageModalOpen(false);
              setIsNextPackageModalOpen(false);
              setIsPalletizeModalOpen(false);
            }
          } else {
            snackActions.warning('No Selected Package Found');
          }
          setIsNotPrimaryPackage(false);
          setUseNotPrimaryPackageId(null);
        }

        scanTheItemCodeField.current.focus();
      }

      if (isNextPackageModalOpen) {
        if (event.key === 'Escape') {
          event.preventDefault();
          setIsChangePackageModalOpen(false);
          setIsNextPackageModalOpen(false);
          setIsPalletizeModalOpen(false);
          // eslint-disable-next-line no-restricted-globals
        } else if (!isNaN(parseInt(packageNo, 10)) && event.key === 'Enter') {
          const gettempPackage = [...pickTicketFormObj.packages];
          const getValue = optionPackageCartonCodes.find(
            (e, index) => index === Number(packageNo),
          );

          if (getValue !== undefined) {
            setCartonCodesToHighLight(getValue);
            updateCartonCodeFunction(
              gettempPackage[0].packageId.toString(),
              getValue.value.toString(),
              false,
              getValue.isExternalPackage,
            );
            gettempPackage[0].itemAsPackage.value = getValue.value;

            setPackageNo('');
          } else {
            snackActions.warning('No Selected Package Found');
          }
        } else if (!packageNo && event.key === 'Enter') {
          handlePressEnterToConfirmButton(event);
        }
      }
    };
    document.addEventListener('keyup', keyDownHandler);
    return () => {
      document.removeEventListener('keyup', keyDownHandler);
    };
  }, [packageNo]);

  useEffect(() => {
    if (isNextPackageModalOpen || isChangePackageModalOpen) {
      ticker.current = setInterval(() => {
        packageNoRef.current.focus();
      }, 1000);
    } else {
      clearInterval(ticker.current);
    }
    return () => clearInterval(ticker.current);
  }, [isNextPackageModalOpen, isChangePackageModalOpen]);

  useEffect(() => {
    if (isLoadingLabel) {
      if (
        pickTicketFormObj?.isAllLineItemPack &&
        pickTicketFormObj.isShipAndComplete !== true
      ) {
        const stateFromLocalStorage = localStorage?.getItem(
          'printerSettingsState',
        );

        const printerSettingsState = JSON.parse(
          stateFromLocalStorage,
        ) as PrinterSettingsState;

        if (
          printerSettingsState !== null &&
          printerSettingsState.computerId !== null &&
          printerSettingsState.printerId !== null
        ) {
          setHasPrinterSettings(true);
        }
      }
    }
  }, [isLoadingLabel, isNextPackageModalOpen]);

  const onCloseChangeAndNextPackage = async () => {
    setIsChangePackageModalOpen(false);
    setIsNextPackageModalOpen(false);
    setIsPalletizeModalOpen(false);
  };

  const handlePackangeNoGotFocus = () => {
    setAllowPackageNo(true);
    setAllowBarcodeScanner(false);
  };

  const onTextChanged = async (e: any) => {
    setAllowPackageNo(true);
    setPackageNo(e.target.value);
    setCartonCodesToHighLight((v) => ({ ...v, value: e.target.value }));
  };

  return (
    <Modal
      open={
        !isPalletizeModalOpen &&
        (isNextPackageModalOpen || isChangePackageModalOpen)
      }
    >
      <MUIContainer>
        <MUIContent>
          {isLoadingToComplete ? (
            <MUIGrid
              container
              direction="row"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column-reverse',
              }}
            >
              <MUIGrid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  Waiting to complete and proceed to the next order
                  <CircularProgress />
                </Typography>
              </MUIGrid>
            </MUIGrid>
          ) : (
            <>
              <MUIGrid
                container
                direction="row"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ width: '100%' }}
              >
                <MUIGrid item xs={6}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Confirm the current package or select from one below
                  </Typography>
                </MUIGrid>

                <MUIGrid item xs={6} sx={{ textAlignLast: 'right' }}>
                  <IconButton
                    color="inherit"
                    // onClick={() => setOpen(false)}
                    aria-label="close"
                    onClick={() => onCloseChangeAndNextPackage()}
                  >
                    <CloseIcon style={{ transform: 'scale(1.9)' }} />
                    <span style={{ fontSize: 'x-small' }}>
                      {' '}
                      (Press Esc to close)
                    </span>
                  </IconButton>
                </MUIGrid>
              </MUIGrid>
              <MUIGrid
                container
                direction="row"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {optionPackageCartonCodes.map((value, index) => (
                  <Tooltip title={value.dimensions}>
                    <MUIGrid item xs={3}>
                      <MUIGrid
                        container
                        direction="row"
                        spacing={2}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{
                          borderStyle:
                            (cartonCodesToHighLight.value
                              ? Number(cartonCodesToHighLight.value)
                              : null) === index
                              ? 'solid'
                              : 'none',
                          margin: '3px',
                        }}
                      >
                        <MUIGrid item xs={4}>
                          <WidgetsIcon sx={{ fontSize: 60 }} />
                        </MUIGrid>
                        <MUIGrid item xs={8}>
                          <Typography variant="caption" fontWeight="bold">
                            {value.label}
                          </Typography>
                          {/* <br />
                        <Typography variant="caption" color="text.secondary">
                          {value.dimensions}
                        </Typography> */}
                          <Typography variant="body2">Press {index}</Typography>
                        </MUIGrid>
                      </MUIGrid>
                    </MUIGrid>
                  </Tooltip>
                ))}
              </MUIGrid>
            </>
          )}
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={12}>
            <Box
              sx={{
                gap: '8px',
              }}
            >
              <MUIGrid
                container
                direction="row"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ marginBottom: 2, marginTop: 2 }}
              >
                <MUIGrid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      padding: 1,
                      width: '30%',
                      backgroundColor: ' rgb(75, 126, 254) !important',
                    }}
                    onClick={() => {
                      setIsAddCustomPackageModalOpen(true);
                      setIsChangePackageModalOpen(false);
                      setIsNextPackageModalOpen(false);
                      setIsPalletizeModalOpen(false);
                      setAllowBarcodeScanner(false);
                    }}
                  >
                    Use Custom Package (Press A)
                  </Button>
                  <TextField
                    sx={{
                      width: '20%',
                      marginRight: 2,
                    }}
                    label="Type Package No."
                    value={packageNo}
                    onChange={onTextChanged}
                    autoFocus
                    inputProps={{
                      'data-state': 'carrier',
                      autoComplete: 'off',
                    }}
                    autoComplete="off"
                    size="small"
                    inputRef={packageNoRef}
                    onFocus={handlePackangeNoGotFocus}
                  />
                </MUIGrid>
              </MUIGrid>

              <MUIGrid
                container
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{
                  display: 'flex',
                  alignItems: 'baseline',
                  marginBottom: 2,
                  marginTop: 2,
                }}
              >
                <MUIGrid item xs={isNextPackageModalOpen ? 5 : 12}>
                  <Typography variant="subtitle1">
                    Current Package Type:{' '}
                    {pickTicketFormObj.packages[0]?.isCustomPackage &&
                      ` ${pickTicketFormObj.packages[0].widthInch}X${pickTicketFormObj.packages[0].heightInch}X${pickTicketFormObj.packages[0].lengthInch} `}
                    {pickTicketFormObj?.packages !== undefined &&
                      pickTicketFormObj.packages[0]?.isCustomPackage !== true &&
                      pickTicketFormObj?.packages?.length > 0 && (
                        <b>
                          {pickTicketFormObj.packages[0]?.itemAsPackage
                            ?.label ||
                            pickTicketFormObj.packages[0]
                              ?.carrierPredefinedPackage}
                        </b>
                      )}
                  </Typography>
                </MUIGrid>
                {isNextPackageModalOpen && (
                  <MUIGrid
                    item
                    xs={7}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {isGeneratePrintLabel && !dataUrlLabels ? (
                      <Typography
                        variant="subtitle1"
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Generating print labels
                        <CircularProgress />
                      </Typography>
                    ) : (
                      <Button
                        disabled={disablePressEnterToConfirmButton}
                        type="submit"
                        variant="contained"
                        size="small"
                        sx={{
                          padding: 1,
                          width: '40%',
                          backgroundColor: ' rgb(75, 126, 254) !important',
                        }}
                        onClick={(event) => {
                          handlePressEnterToConfirmButton(event);
                        }}
                      >
                        Press enter to confirm
                      </Button>
                    )}

                    {/* show create label button if no printer */}
                    {pickTicketFormObj?.isAllLineItemPack &&
                      pickTicketFormObj?.isCustomOrOtherCarrier !== true && (
                        <Button
                          disabled={isGeneratePrintLabel && !dataUrlLabels}
                          sx={{
                            padding: '7px 15px',
                            marginRight: '15px',
                            textTransform: 'capitalize',
                            color: '#052fff',
                            borderColor: '#052fff',
                            borderWidth: 'revert',
                          }}
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.preventDefault();
                            if (dataUrlLabels && dataUrlLabels.length > 0) {
                              dataUrlLabels.forEach((element, index) => {
                                window.open(element.labelUrl, `${index}`);
                              });
                            } else {
                              updatePackAndShipToShipEasyPostFunction();
                            }
                          }}
                        >
                          {dataUrlLabels && dataUrlLabels.length > 0
                            ? 'Print Label'
                            : 'Create Label'}
                        </Button>
                      )}
                  </MUIGrid>
                )}
              </MUIGrid>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
