import { useMemo, useState } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';

import { IGridLayout, IGridTile } from 'common/interfaces';

import { Add, Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

import GridBackground from './grid';
import {
  Container,
  Item,
  Actions,
  PlusRight,
  PlusTop,
  PlusLeft,
  PlusBottom,
} from './styles';

const GridLayout = (props: IGridLayout) => {
  const ResponsiveReactGridLayout = useMemo(
    () => WidthProvider(Responsive),
    [],
  );
  const {
    minHeight,
    padding,
    cols,
    rowHeight,
    droppingItem,
    items,
    onDrop,
    onLayoutChange,
    onResizeStop,
    onBoxEdit,
    onBoxPlus,
    onBoxRemove,
  } = props;

  const [width, setWidth] = useState<number>(900);
  const [hoverItem, setHoverItem] = useState('');

  const onWidthChange = (containerWidth: number) => {
    setWidth(containerWidth);
  };

  const onBoxHoverEnter = (id: string) => {
    setHoverItem(id);
  };

  const onBoxHoverLeave = () => {
    setHoverItem('');
  };

  const renderItem = (item: any) => {
    const { layout, children } = item;

    return (
      <Item
        key={layout.i}
        data-i={layout.i}
        data-x={layout.x}
        data-y={layout.y}
        data-w={layout.w}
        data-h={layout.h}
        className="grid-widget"
        data-grid={layout}
        onMouseEnter={() => onBoxHoverEnter(layout.i)}
        onMouseLeave={() => onBoxHoverLeave()}
        style={{
          zIndex: hoverItem === layout.i ? 50 : 1,
          cursor: hoverItem === layout.i ? 'pointer' : '',
        }}
      >
        <Actions className="cancelSelector">
          <IconButton
            aria-label="edit"
            size="small"
            style={{ padding: 0 }}
            onClick={() => onBoxEdit(layout.i)}
          >
            <Edit fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            style={{ padding: 0 }}
            onClick={() => onBoxRemove(layout.i)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Actions>
        <PlusTop
          style={{
            visibility: hoverItem === layout.i ? 'visible' : 'hidden',
            zIndex: hoverItem === layout.i ? 60 : 2,
          }}
        >
          <IconButton
            aria-label="edit"
            size="small"
            style={{ padding: 0 }}
            onClick={() => onBoxPlus(layout.i, 'top')}
          >
            <Add fontSize="small" />
          </IconButton>
        </PlusTop>
        <PlusLeft
          style={{
            visibility: hoverItem === layout.i ? 'visible' : 'hidden',
            zIndex: hoverItem === layout.i ? 60 : 2,
          }}
        >
          <IconButton
            aria-label="edit"
            size="small"
            style={{ padding: 0 }}
            onClick={() => onBoxPlus(layout.i, 'left')}
          >
            <Add fontSize="small" />
          </IconButton>
        </PlusLeft>
        <PlusBottom
          style={{
            visibility: hoverItem === layout.i ? 'visible' : 'hidden',
            zIndex: hoverItem === layout.i ? 60 : 2,
          }}
        >
          <IconButton
            aria-label="edit"
            size="small"
            style={{ padding: 0 }}
            onClick={() => onBoxPlus(layout.i, 'bottom')}
          >
            <Add fontSize="small" />
          </IconButton>
        </PlusBottom>
        <PlusRight
          style={{
            visibility: hoverItem === layout.i ? 'visible' : 'hidden',
            zIndex: hoverItem === layout.i ? 60 : 2,
          }}
        >
          <IconButton
            aria-label="edit"
            size="small"
            style={{ padding: 0 }}
            onClick={() => onBoxPlus(layout.i, 'right')}
          >
            <Add fontSize="small" />
          </IconButton>
        </PlusRight>
        {children}
      </Item>
    );
  };

  return (
    <Container>
      <GridBackground
        containerWidth={width}
        cols={cols}
        rowHeight={rowHeight}
        padding={padding}
        background="hsl(210, 44%, 91%)"
      />
      <ResponsiveReactGridLayout
        margin={padding}
        cols={{ lg: cols }}
        rowHeight={rowHeight}
        breakpoints={{ lg: 600 }}
        compactType={null}
        isDroppable
        droppingItem={droppingItem}
        onDrop={onDrop}
        onResizeStop={onResizeStop}
        onWidthChange={onWidthChange}
        onLayoutChange={onLayoutChange}
        draggableCancel=".cancelSelector"
        style={{ minHeight }}
      >
        {items.map(renderItem)}
      </ResponsiveReactGridLayout>
    </Container>
  );
};

export default GridLayout;
