import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getExtensivIntegrations = async (warehouseCustomerId) => {
  try {
    const response = await httpClient.get(
      `/extensivintegration/list/all/${warehouseCustomerId}`,
      { params: { showLoading: true } },
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createExtensivIntegration = async (payload) => {
  try {
    const response = await httpClient.post(
      `/extensivintegration/createCart`,
      payload,
      { params: { showLoading: true } },
    );

    return response;
  } catch (error) {
    return errorCatch(error);
  }
};

export const removeExtensivIntegration = async (
  extensivCartConfigurationId,
) => {
  try {
    const response = await httpClient.delete(
      `/extensivintegration/removeCart/${extensivCartConfigurationId}`,
    );

    return response;
  } catch (error) {
    return errorCatch(error);
  }
};

export const createExtensivCartRoverConfiguration = async (payload) => {
  try {
    const response = await httpClient.post(
      `/extensivintegration/configuration/create`,
      payload,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getExtensivCartRoverConfiguration = async () => {
  try {
    const response = await httpClient.get(`/extensivintegration/configuration`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const disconnectExtensivCartRoverConfiguration = async () => {
  try {
    const response = await httpClient.get(
      `/extensivintegration/configuration/disconnect`,
    );

    return response.data;
  } catch (err) {
    throw errorCatch(err);
  }
};

export const UpdateExtensivCartSource = async (payload) => {
  try {
    const response = await httpClient.post(
      `/extensivintegration/configuration/UpdateExtensivCartSource`,
      payload,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
