import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import Header from 'components/header';
import Input from 'components/input';
import Ticket from 'components/ticket';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import FileInputModal from 'pages/shared/addfilemodal';
import ManualReceiptModal from 'pages/shared/manualreceiptmodal';
import Printformtemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import PurchaseOrderModal from 'pages/shared/purchaseordermodal';
import {
  getAllPurchaseOrdersV2,
  addImportedPurchaseOrders,
  getPurchaseOrder,
  deletePurchaseOrder,
} from 'services/api/purchaseOrders/purchaseOrders.api';
import { getReceiptQueue } from 'services/api/receipts/receipts.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { ContainerFlat, DatagridPremium, DatagridStandard } from 'styles';

import { Search } from '@mui/icons-material';
import { Chip, CircularProgress, Link, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import {
  GridColumns,
  useGridApiRef,
  GridActionsCellItem,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

import ImportGlobalMessageModal from '../shared/import-global-message-modal';
import { MainContainer, ContentContainer } from '../styles';
import {
  PurchaseOrderContextProvider,
  usePurchaseOrderContext,
} from './purchaseordersv2/context';
import ImportPurchaseOrderModal from './purchaseordersv2/import-purchase-order';
import PurchaseOrderDeleteModal from './purchaseordersv2/podeletemodal';

export enum PuchaseOrderStatusEnum { // based on the back end enum : PurchaseOrderStatusEnum
  All = 'all',
  New = 'new',
  Submitted = 'submitted',
  Receiving = 'receiving',
  Closed = 'closed',
  Cancelled = 'cancelled',
}

type ImportedPO = {
  customerId: number;
  customerLocationId: number;
  customerFacilityId: number;
  purchaseOrderNo: string;
  shipVia: string;
  supplierName: string;
  orderDate: Date;
};

const POFilterSearchPanel = () => {
  const {
    purchaseOrders,
    setFilteredPurchaseOrders,
    statusFilter,
    setStatusFilter,
  } = usePurchaseOrderContext();

  const [searchFieldValue, setSearchFieldValue] = React.useState('');

  const handleSetFilterList = (newValue) =>
    purchaseOrders
      ? purchaseOrders.filter(
          (x) =>
            x?.status?.toLowerCase().includes(newValue?.toLowerCase()) ||
            x?.poNo?.toLowerCase().includes(newValue?.toLowerCase()) ||
            x?.supplierName?.toLowerCase().includes(newValue?.toLowerCase()) ||
            x?.description?.toLowerCase().includes(newValue?.toLowerCase()) ||
            x?.hiddenProductDescription
              ?.trim()
              ?.toLowerCase()
              .includes(newValue?.trim()?.toLowerCase()),
        )
      : null;

  const handleSearchFieldChange = () => {
    if (searchFieldValue || statusFilter) {
      const filteredList = handleSetFilterList(searchFieldValue);

      if (statusFilter !== 'All') {
        const filteredListStatus = filteredList.filter(
          (x) => x.status.toLowerCase() === statusFilter?.toLowerCase(),
        );
        setFilteredPurchaseOrders(filteredListStatus);
        return;
      }

      setFilteredPurchaseOrders(filteredList);
    } else {
      setFilteredPurchaseOrders(purchaseOrders);
    }
  };

  const statusfilterCount = (value) => {
    const filteredList = handleSetFilterList(searchFieldValue);
    if (value === 'All') {
      return filteredList.length;
    }
    return filteredList.filter((x) => x.status === value).length;
  };

  useEffect(() => {
    handleSearchFieldChange();
  }, [statusFilter]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'right',
        flex: '1',
        width: '100% !important',
        alignItems: 'center',
        padding: '10px 0px',
      }}
    >
      <Box sx={{ width: '100%' }}>
        {Object.keys(PuchaseOrderStatusEnum).map((key) => (
          <Chip
            label={`${key} (${statusfilterCount(key)})`}
            size="small"
            sx={{
              color: key === statusFilter ? '#FFF' : '#8a8a8c',
              fontWeight: '500',
              fontSize: '0.75rem',
              padding: '10px 5px',
              height: '35px',
              margin: '0px 2px',
            }}
            style={{
              backgroundColor: key === statusFilter ? '#1C9DCC' : '#ebeef3',
              borderRadius: '4px',
            }}
            onClick={() => {
              setStatusFilter(key);
            }}
          />
        ))}
      </Box>
      <Box
        sx={{
          justifyContent: 'right !important',
        }}
      >
        <Input
          sx={{ maxWidth: '100%' }}
          placeholder="Search"
          value={searchFieldValue}
          onChange={(e) => setSearchFieldValue(e.target.value)}
          rightIcon={<Search />}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchFieldChange();
            }
          }}
        />
      </Box>
    </Box>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      {/* <PurchaseOrderStatusChips /> */}
      <POFilterSearchPanel />
    </GridToolbarContainer>
  );
}
function Receipts() {
  const {
    currentUser,
    currentLocationAndFacility,
    isWarehouseCustomerAccount,
    isSupplierAccount,
  } = useContext(AuthContext);
  const { setIsPurchaseOrderDetailDeleteModalOpen } = useContext(GlobalContext);

  const {
    statusFilter,
    purchaseOrders,
    filteredPurchaseOrders,
    setPurchaseOrders,
    setFilteredPurchaseOrders,
    setPurchaseOrderIdDelete,
  } = usePurchaseOrderContext();

  const { updateData, handleUpdateData } = useContext(GlobalContext);
  const [initiatePrint, setInitiatePrint] = useState(false);
  const [poToPrint, setPOToPrint] = useState({});
  const [purchaseOrderIdPrint, setPurchaseOrderIdPrint] = useState(0);

  const { onOpenPrintFormTemplateModal } = useContext(GlobalContext);

  const navigate = useNavigate();
  const theme = useTheme();
  const ticketRef = useRef(null);
  const print = useReactToPrint({
    content: () => ticketRef.current,
  });
  type Row = typeof purchaseOrders[number];

  const onLoadPurchaseOrdersData = async () => {
    try {
      const getStoredWarehouseValue = JSON.parse(
        localStorage.getItem('storeWarehouseCustomer'),
      );

      const purchaseOrdersFromApi = await getAllPurchaseOrdersV2(
        !isWarehouseCustomerAccount
          ? currentLocationAndFacility.customerFacilityId
          : 0,
        getStoredWarehouseValue ? getStoredWarehouseValue.id : null,
      );
      return purchaseOrdersFromApi;
    } catch (err) {
      return err;
    }
  };

  const navigateToPODetails = (po) => {
    const querysearchParams = {
      orderNo: po.poNo,
      form: 'view',
      id: po.purchaseOrderId,
    };
    navigate({
      pathname: `/receiving/purchase-orders`,
      search: `?${createSearchParams(querysearchParams)}`,
    });
  };

  const mapAndAddPOs = async (data) => {
    let importedPOs: ImportedPO[] = [];

    if (Array.isArray(data)) {
      importedPOs = data
        .filter((po) => po.PONumber !== '')
        .map((po) => ({
          customerId: currentUser.Claim_CustomerId,
          customerLocationId: currentLocationAndFacility.customerLocationId,
          customerFacilityId: currentLocationAndFacility.customerFacilityId,
          purchaseOrderNo: po.PONumber,
          shipVia: po.ShipVia,
          orderQty: po.OrderQty,
          orderDate: po.OrderDate,
          supplierName: po.SupplierName,
          supplierAccount: po.SupplierAccount,
          pointOfCOntact: po.SupplierPOC,
          supplierCity: po.SupplierCity,
          supplierPhone: po.SupplierPhone,
          supplierAddress: po.SupplierAddress,
          supplierAddress2: po.SupplierAddress2,
          supplierZip: po.SupplierZIP,
          supplierStateOrProvince: po.SupplierStateorProvince,
          itemSku: po.ItemSKU,
          itemDescription: po.ItemDescription,
          itemUom: po.ItemUoM,
          itemOrderQty: po.ItemOrderQty,
          lotNumber: po.LotNumber,
          expirationDate: po.ExpirationDate,
        }));
    }

    try {
      await addImportedPurchaseOrders(
        currentLocationAndFacility.customerLocationId,
        importedPOs,
      );
      snackActions.success(`Successfully imported purchase orders.`);
      handleUpdateData();
    } catch (err: any) {
      snackActions.error(`${err}`);
    }
  };

  const printPurcahseOrderItemOnClick = useCallback(
    async (e: React.SyntheticEvent, id: any): Promise<void> => {
      e.preventDefault();
      setPurchaseOrderIdPrint(id);
      onOpenPrintFormTemplateModal();
    },
    [purchaseOrderIdPrint],
  );

  const purchaseOrdercolumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      hideable: false,
      width: 30,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Go to PO Details"
          onClick={() => navigateToPODetails(params.row)}
          showInMenu
          disabled={isWarehouseCustomerAccount}
        />,
        <GridActionsCellItem
          label="Print"
          onClick={(e) => printPurcahseOrderItemOnClick(e, params.id)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setIsPurchaseOrderDetailDeleteModalOpen(true);
            setPurchaseOrderIdDelete(params.row.purchaseOrderId);
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'status',
      width: 200,
      headerName: 'Status',
      renderCell: (params) => <Typography>{params.row.status}</Typography>,
    },
    {
      field: 'poNo',
      width: 200,
      headerName: 'PO Number',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`${
            isSupplierAccount
              ? 'supplier-purchase-order-detail'
              : '/receiving/purchase-orders'
          }?form=view&id=${params.row.purchaseOrderId}`}
          target="_blank"
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.poNo}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'paymentStatus',
      width: 200,
      headerName: 'Payment Status',
      renderCell: (params) => (
        <Typography>{params.row.paymentStatus}</Typography>
      ),
    },
    {
      field: 'qtyExpected',
      width: 150,
      type: 'number',
      headerName: 'Expected Qty',
      renderCell: (params) => (
        <Typography>
          {params.row.qtyExpected}/{params.row.qtyExpectedOver}
        </Typography>
      ),
    },
    {
      field: 'qtyReceived',
      width: 150,
      type: 'number',
      headerName: 'Received',
      renderCell: (params) => (
        <Typography>{params.row.qtyReceived}%</Typography>
      ),
    },
    {
      field: 'packingListQty',
      width: 150,
      type: 'number',
      headerName: 'Packing List Qty',
      renderCell: (params) => (
        <Typography>{params.row?.packingListQty}</Typography>
      ),
    },
    {
      field: 'issueDate',
      width: 150,
      type: 'dateTime',
      headerName: 'PO Date',
      renderCell: (params) => (
        <Typography>
          {params.row.issueDate
            ? moment(params.row.issueDate).format('L')
            : null}
        </Typography>
      ),
    },
    {
      field: 'supplierName',
      width: 200,
      headerName: 'Supplier Name',
      renderCell: (params) => (
        <Typography>{params.row.supplierName}</Typography>
      ),
    },
    {
      field: 'skuCount',
      width: 200,
      headerName: 'SKUs (Count)',
      renderCell: (params) => <Typography>{params.row.skuCount}</Typography>,
    },
    {
      field: 'arrivalDate',
      width: 200,
      headerName: 'Arrival Date',
      renderCell: (params) => (
        <Typography>
          {params.row.arrivalDate
            ? moment(params.row.arrivalDate).format('L')
            : null}
        </Typography>
      ),
    },
    {
      field: 'estimatedBulkReadyDate',
      width: 250,
      headerName: 'Estimated Bulk Ready Date',
      renderCell: (params) => (
        <Typography>
          {params.row.estimatedBulkReadyDate
            ? moment(params.row.estimatedBulkReadyDate).format('L')
            : null}
        </Typography>
      ),
    },
    {
      field: 'acceptedDate',
      width: 250,
      headerName: 'Accepted Date',
      renderCell: (params) => (
        <Typography>
          {params.row.acceptedDate
            ? moment(params.row.acceptedDate).format('L')
            : null}
        </Typography>
      ),
    },
    {
      field: 'customer',
      width: 200,
      headerName: 'Client',
      renderCell: (params) => (
        <Typography>{params.row.customerName}</Typography>
      ),
    },
    {
      field: 'description',
      width: 300,
      headerName: 'Product Description',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`${
            isSupplierAccount
              ? 'supplier-purchase-order-detail'
              : '/receiving/purchase-orders'
          }?form=view&orderNo=${params.row.poNo}&id=${
            params.row.purchaseOrderId
          }`}
          target="_blank"
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.description}
          </Typography>
        </Link>
      ),
    },
  ];

  const purchaseOrdercolumnsHide: GridColumns<Row> = [
    {
      field: 'actions',
      type: 'actions',
      hideable: false,
      width: 30,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Print"
          onClick={(e) => printPurcahseOrderItemOnClick(e, params.id)}
          showInMenu
        />,
      ],
    },
    {
      field: 'poNo',
      width: 200,
      headerName: 'PO No',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`/warehouse-customer-receiving/purchase-orders?form=view&orderNo=${params.row.poNo}&id=${params.row.purchaseOrderId}`}
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.poNo}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'status',
      width: 200,
      headerName: 'Status',
      renderCell: (params) => <Typography>{params.row.status}</Typography>,
    },
    {
      field: 'supplierName',
      width: 200,
      headerName: 'Supplier Name',
      renderCell: (params) => (
        <Typography>{params.row.supplierName}</Typography>
      ),
    },
    {
      field: 'qtyExpexted',
      width: 150,
      headerName: 'Expected Qty',
      renderCell: (params) => <Typography>{params.row.qtyExpexted}</Typography>,
    },
    {
      field: 'qtyRecieved',
      width: 150,
      headerName: 'Recieved Qty',
      renderCell: (params) => <Typography>{params.row.qtyRecieved}</Typography>,
    },
    {
      field: 'arrivalDate',
      width: 200,
      headerName: 'Arrival Date',
      renderCell: (params) => (
        <Typography>
          {params.row.arrivalDate
            ? moment(params.row.arrivalDate).format('L')
            : null}
        </Typography>
      ),
    },
    {
      field: 'estimatedBulkReadyDate',
      width: 250,
      headerName: 'Estimated Bulk Ready Date',
      renderCell: (params) => (
        <Typography>
          {params.row.estimatedBulkReadyDate
            ? moment(params.row.estimatedBulkReadyDate).format('L')
            : null}
        </Typography>
      ),
    },
    {
      field: 'description',
      width: 300,
      headerName: 'Product Description',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`${
            isSupplierAccount
              ? 'supplier-purchase-order-detail'
              : '/receiving/purchase-orders'
          }?form=view&orderNo=${params.row.poNo}&id=${
            params.row.purchaseOrderId
          }`}
          target="_blank"
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.description}
          </Typography>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      await onLoadPurchaseOrdersData().then((opt) => {
        const data = opt.map((c: any) => ({
          arrivalDate: c.arrivalDate,
          customerName: c.customerName,
          description: c.description,
          issueDate: c.issueDate,
          paymentStatus: c.paymentStatus,
          poNo: c.poNo,
          purchaseOrderId: c.purchaseOrderId,
          qtyExpected: c.qtyExpected,
          qtyExpectedOver: c.qtyExpectedOver,
          qtyReceived: c.qtyReceived,
          skuCount: c.skuCount,
          status: c.status,
          supplierName: c.supplierName,
          estimatedBulkReadyDate: c.estimatedBulkReadyDate,
          acceptedDate: c.acceptedDate,
          hiddenProductDescription: c.hiddenProductDescription,
          packingListQty: c.packingListQty,
        }));

        setFilteredPurchaseOrders(data);
        setPurchaseOrders(data);

        if (data && statusFilter && statusFilter !== 'All') {
          const filtered = data.filter((d: any) => d.status === statusFilter);
          setFilteredPurchaseOrders(filtered);
        }
      });
    }
    fetchData();
  }, [currentUser.Claim_CustomerId, updateData]);

  useEffect(() => {
    if (initiatePrint) {
      print();
      setInitiatePrint(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocationAndFacility, updateData, initiatePrint]);

  // Purchase Order state -----------------------------------------------
  const apiRef = useGridApiRef();

  const [initialState, setInitialState] =
    React.useState<GridInitialStatePremium>();

  // DATAGRID STATE MODEL
  const [savedState, setSavedState] = React.useState<{
    initialState: GridInitialStatePremium;
  }>({ initialState: {} });

  // COLUMN MODEL
  const [savedColumnsState, setSavedColumnsState] = React.useState<{
    columnVisibility: GridColumnVisibilityModel;
  }>({ columnVisibility: {} });

  // GET DATAGRID AMD COLUMN VISIBILITY STATE
  const saveSnapshot = React.useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem(
        'purchaseOrderDataGridState',
        JSON.stringify(currentState),
      );
    }
  }, []);

  React.useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem(
      'purchaseOrderDataGridState',
    );
    setInitialState(
      stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {},
    );

    // COLUMN VISIBILITY
    const columnStateFromLocalStorage = localStorage?.getItem(
      'poDataGridStateColumnVisibility',
    );
    setSavedColumnsState((prev) => ({
      ...prev,
      columnVisibility: columnStateFromLocalStorage
        ? JSON.parse(columnStateFromLocalStorage)
        : {},
    }));

    setSavedState((prev) => ({
      ...prev,
      initialState: JSON.parse(stateFromLocalStorage),
    }));
  }, [currentUser.Claim_CustomerId]);

  useEffect(() => {
    if (savedState?.initialState !== null && apiRef?.current?.restoreState) {
      apiRef?.current?.restoreState(savedState?.initialState);
      apiRef.current.setColumnVisibilityModel(
        savedColumnsState.columnVisibility,
      );
    }
  }, [purchaseOrdercolumns]);

  if (!initialState) {
    return (
      <div style={{ textAlign: 'center', paddingTop: '20%' }}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <PurchaseOrderDeleteModal />
      <ImportGlobalMessageModal title="Import Purchase Order Error" />
      <ImportPurchaseOrderModal />
      <PurchaseOrderModal admin />
      <ManualReceiptModal />
      <FileInputModal callBack={mapAndAddPOs} />
      <Box
        sx={{
          display: 'none',
        }}
      >
        <Ticket innerRef={ticketRef} purchaseOrder data={poToPrint} />
      </Box>
      <ContentContainer>
        <ContainerFlat>
          <DatagridPremium
            autoHeight
            disableColumnMenu
            rows={filteredPurchaseOrders}
            columns={
              isWarehouseCustomerAccount
                ? purchaseOrdercolumnsHide
                : purchaseOrdercolumns
            }
            apiRef={apiRef}
            getRowId={() => Math.random()}
            components={{
              Toolbar: CustomToolbar,
            }}
            onStateChange={() => {
              saveSnapshot();
            }}
            onColumnVisibilityModelChange={(e) => {
              localStorage.setItem(
                'poDataGridStateColumnVisibility',
                JSON.stringify(e),
              );
            }}
            density="compact"
            rowsPerPageOptions={[50, 100, 200]}
            pagination
          />
        </ContainerFlat>
        {purchaseOrderIdPrint !== 0 && (
          <Printformtemplate
            preview={false}
            dynamicId={purchaseOrderIdPrint}
            formtemplateTypeId={FormTemplateTypeEnum.PurchaseOrder}
          />
        )}
      </ContentContainer>
    </>
  );
}

const PurchaseOrderList = () => (
  <MainContainer>
    <Header />
    <PurchaseOrderContextProvider>
      <Receipts />
    </PurchaseOrderContextProvider>
  </MainContainer>
);
export default React.memo(PurchaseOrderList);
