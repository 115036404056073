import React, { useContext, useState, useEffect } from 'react';

import Button from 'components/button';
import { snackActions } from 'config/snackbar.js';
import { useInventoryItemDetailsContext } from 'pages/inventory/item/details';
import { createItemSupplierFromInventory } from 'services/api/item/item.api';
import { getSupplierLookupByWarehouseCustomerSearchByName } from 'services/api/suppliers/suppliers.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Divider,
  TextField,
  Grid as MUIGrid,
  Autocomplete,
} from '@mui/material';

import { MUIContainer, MUIContent } from '../productionreportmodal';

type AutocompleteBase = {
  label?: string;
  value?: number;
};
type SupplierType = AutocompleteBase;

export default React.memo(() => {
  const {
    isAddSupplierFromInventoryModalOpen,
    onCloseAddSupplierFromInventoryModal,
    handleUpdateData,
  } = useContext(GlobalContext);

  const { currentUser } = useContext(AuthContext);
  const { queryStringItemDetails, viewLoadDataDetails } =
    useInventoryItemDetailsContext();

  const [optionSuppliers, setOptionSuppliers] = useState<SupplierType[]>([]);

  const initialFormItemSupplier = {
    supplier: {
      value: -1,
      label: '',
    },
  };
  const initialFormItemSupplierError = {
    supplier: '',
  };

  const [paramErrors, setParamErrors] = useState<any>(
    initialFormItemSupplierError,
  );

  const [itemSupplierData, setItemSupplierData] = useState<any>(
    initialFormItemSupplier,
  );

  const onLoadSuppliers = async () => {
    try {
      return await getSupplierLookupByWarehouseCustomerSearchByName(
        currentUser.Claim_CustomerId,
        0,
        '',
      );
    } catch (err) {
      return err;
    }
  };

  const createItemSupplierOnClick = async (e: any) => {
    e.preventDefault();
    const fieldValues = itemSupplierData;
    const temp: any = {};

    if ('supplier' in fieldValues) {
      if (fieldValues.supplier !== null) {
        temp.supplier =
          fieldValues.supplier.value !== -1 ? '' : 'This field is required';
      } else {
        temp.supplier = 'This field is required';
      }
    }

    setParamErrors({ ...temp });
    if (Object.values(temp).every((x) => x === '')) {
      const PAYLOAD = {
        itemId: queryStringItemDetails.id,
        customerId: parseInt(currentUser.Claim_CustomerId, 10),
        supplierId: itemSupplierData.supplier.value,
      };
      try {
        await createItemSupplierFromInventory(PAYLOAD);
        snackActions.success('Successfully created new item.');
        handleUpdateData();
        onCloseAddSupplierFromInventoryModal();
      } catch (err) {
        snackActions.error(err);
      }
    }
  };

  const handleModalClose = () => {
    onCloseAddSupplierFromInventoryModal();
    // setItemCostSheetSingleData({});
  };

  useEffect(() => {
    if (isAddSupplierFromInventoryModalOpen) {
      const optionsSupplier = onLoadSuppliers();
      optionsSupplier
        .then((opt) => {
          setOptionSuppliers(
            opt.map((c: any) => ({
              value: c.supplierId,
              label: c.name,
            })),
          );
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  }, [isAddSupplierFromInventoryModalOpen]);

  return (
    <Modal open={isAddSupplierFromInventoryModalOpen}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Add Supplier
          </Typography>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12}>
                <Autocomplete
                  id="controllable-states"
                  size="small"
                  options={optionSuppliers}
                  value={itemSupplierData.supplier}
                  sx={{ width: '100%' }}
                  onChange={(event: any, values) => {
                    setItemSupplierData((prevState) => ({
                      ...prevState,
                      supplier: values,
                    }));
                  }}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  getOptionLabel={(option: SupplierType) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      id="outlined"
                      size="small"
                      label="Supplier"
                      sx={{ width: '100%' }}
                      error={paramErrors.supplier}
                      helperText={paramErrors.supplier}
                      required
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => {
                      handleModalClose();
                    }}
                    variant="outlined"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                    onClick={(e) => {
                      createItemSupplierOnClick(e);
                    }}
                  >
                    CONFIRM
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
