import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const deleteReceipt = async (receiptId) => {
  try {
    const response = await httpClient.delete(`/Receipt/${receiptId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getReceipts = async (
  customerFacilityId,
  warehouseCustomerId,
  searchSingleParam,
) => {
  try {
    const response = await httpClient.get(
      `/Receipt/List/${customerFacilityId}?searchParam=${
        searchSingleParam || ''
      }`,
      {
        params: {
          warehouseCustomerId,
        },
      },
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getReceiptDetail = async (receiptId) => {
  try {
    const response = await httpClient.get(`/Receipt/Detail/${receiptId}`);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getWarehouseCustomerLookup = async (
  customerId,
  searchText,
  activeOnly = false,
) => {
  try {
    const response = await httpClient.get(
      `/Warehousecustomer/Lookup/Name/Customer/${customerId}?searchText=${''}&activeOnly=${activeOnly}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getFacilitiesWithLocationByCustomerId = async (customerId) => {
  try {
    const response = await httpClient.get(
      `/Customerfacility/FacilitiesWithLocation/Customer/${customerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getSkuLookup = async (customerId, searchText) => {
  try {
    const response = await httpClient.get(
      `Item/Lookup/bysku/${customerId}?searchText=${
        searchText !== undefined ? searchText : ''
      }`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getLicensePlate = async () => {
  try {
    const response = await httpClient.get(
      `/ReceiptLineItem/Licenseplatetype/Lookup`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getStatusArrival = async () => {
  try {
    const response = await httpClient.get(
      `ReceiptLineItem/Statusonarrival/Lookup`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getLotNo = async (itemId) => {
  try {
    const response = await httpClient.get(`Lot/Lookup/${itemId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getLotNoByCustomerId = async () => {
  try {
    const response = await httpClient.get(`Lot/GetLotLookupCustomerId`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getReceiptLineItem = async (receiptLineItemId) => {
  try {
    const response = await httpClient.get(
      `ReceiptLineItem/v2/${receiptLineItemId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const getReceiptLineItemToPrint = async (receiptLineItemId) => {
  try {
    const response = await httpClient.get(
      `ReceiptLineItem/Print/${receiptLineItemId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getReceiptLineItemToPrintAll = async (receiptId) => {
  try {
    const response = await httpClient.get(
      `ReceiptLineItem/Print/All/${receiptId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createReceipt = async (payload) => {
  try {
    const response = await httpClient.post(`/Receipt/Createreceipt`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createLotNo = async (payload) => {
  try {
    const response = await httpClient.post(`/Lot/Create`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createReceiptV2 = async (payload) => {
  try {
    const response = await httpClient.post(
      `/Receipt/Createreceipt/v2`,
      payload,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateReceiptV2 = async (payload) => {
  try {
    const response = await httpClient.post(`/Receipt/Edit/v2`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createReceiptLineItem = async (payload) => {
  try {
    const response = await httpClient.post(`/ReceiptLineItem/Create`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createReceiptLineItemV2 = async (payload) => {
  try {
    const response = await httpClient.post(
      `/ReceiptLineItem/Create/v2`,
      payload,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteReceiptLineItem = async (payload) => {
  try {
    const response = await httpClient.post(`/ReceiptLineItem/Delete`, payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// export const updateReceiptLineItem = async (payload) => {
//   try {
//     const response = await httpClient.post(`/ReceiptLineItem/Update`, payload);
//     return response.data;
//   } catch (error) {
//     throw errorCatch(error);
//   }
// };

export const updateReceiptLineItem = async (payload) => {
  try {
    const response = await httpClient.post(
      `/ReceiptLineItem/Update/v2`,
      payload,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const confirmReceipt = async (receiptId) => {
  try {
    const response = await httpClient.post(`Receipt/Edit/Confirm`, receiptId);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// ---------------------- PO autocomplete / search / input number
export const getPurchaseOrderLookup = async (
  customerFacilityId,
  warehouseCustomerId,
) => {
  try {
    const response = await httpClient.get(
      `purchaseorder/lookup/${customerFacilityId}/${warehouseCustomerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPurchaseOrderPackingList = async (
  customerFacilityId,
  warehouseCustomerId,
) => {
  try {
    const response = await httpClient.get(
      `purchaseorder/lookup/packinglist/${customerFacilityId}/${warehouseCustomerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getReceiptsDocuments = async (receiptId) => {
  try {
    const response = await httpClient.get(`receipt/documents/${receiptId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const uploadReceiptsDocuments = async (
  receiptId,
  files,
  documentType,
) => {
  try {
    const formData = new FormData();

    for (let i = 0; i < files.length; i += 1) formData.append('file', files[i]);

    const response = await httpClient.post(
      `receipt/documents/upload/${receiptId}/${documentType}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteReceiptDocument = async (documentId) => {
  try {
    const response = await httpClient.delete(`receipt/documents/${documentId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getReceiptDocumentService = async (awsFileKey) => {
  try {
    window.open(
      `${httpClient.defaults.baseURL}/receipt/documents/aws/${awsFileKey}`,
      '_blank',
    );
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPackingListToReceiptByPackingListId = async (packingListId) => {
  try {
    const response = await httpClient.get(
      `purchaseorder/GetPackingListToReceipt/${packingListId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/receiptLineItem/images/{receiptLineItemId}
export const getReceiptLineItemImages = async (receiptLineItemId) => {
  try {
    const response = await httpClient.get(
      `receiptLineItem/images/${receiptLineItemId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateIsCheckLineItemAsync = async (receiptLineItemId, value) => {
  try {
    const response = await httpClient.put(
      `receiptLineItem/UpdateIsCheckLineItem/${receiptLineItemId}/${value}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateReceiptNonBillable = async (model) => {
  try {
    const response = await httpClient.post(
      `Receipt/UpdateReceiptNonBillable`,
      model,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getReturnList = async (
  customerId,
  customerLocationId,
  customerFacilityId,
  warehouseCustomerId?: number,
) => {
  try {
    const response = await httpClient.get(
      `Receipt/GetReturnList/${customerId}/${customerLocationId}/${customerFacilityId}?warehouseCustomerId=${
        warehouseCustomerId ?? ''
      }`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// POST /api/receipt/manage/billingchargesectionadhoc
export const ManageReceiptBillingChargeSectionAdhoc = async (model) => {
  try {
    const response = await httpClient.post(
      `Receipt/Manage/BillingChargeSectionAdhoc`,
      model,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const ManagePickTicketBillingChargeSectionAdhoc = async (model) => {
  try {
    const response = await httpClient.post(
      `Receipt/Manage/BillingChargeSectionAdhoc`,
      model,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/receipt/list/billingchargesectionadhoc/{receiptId}
export const GetReceiptBillingChargeSectionAdhoc = async (
  receiptId: number,
) => {
  try {
    const response = await httpClient.get(
      `Receipt/List/BillingChargeSectionAdhoc/${receiptId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
