import React, { useContext, useState } from 'react';

import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import Printformtemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Grid as MUIGrid } from '@mui/material';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import { styled, alpha } from '@mui/material/styles';

import { usePackAndShipContext } from '../context';

const EnumButtonName = {
  Approve: 'Approve',
  ApproveAndPack: 'Approve And Pack (Press P)',
  PackAndShip: 'Pack And Ship',
  PrintInvoice: 'Print Invoice',
  AddPackage: 'Add Package',
  PackingList: 'Packing List',
  Shipping: 'Shipping',
  ShipToAndBillTo: 'Ship To & Bill To ',
  Carrier: 'Carrier',
  PrintPackingList: 'Print Packing List',
  PrintBol: 'Print BOL',
  ShipAndComplete: 'Ship Order (Press C)',
  ReadyForPickup: 'Ready for Pickup',
  Complete: 'Complete',
  VerifyAddress: 'Verify Address',
} as const;

const ProcessTypes = {
  Approval: 'Approval',
  PackAndShip: 'Pack And Ship',
  AddPackage: 'Add Package',
  ShipAndComplete: 'Ship And Complete',
  ReadyForPickup: 'Ready for Pickup',
  Complete: 'Complete',
} as const;

type EnumButtonName = typeof EnumButtonName[keyof typeof EnumButtonName];

const PackAndShipButtons = () => {
  const { outboundSectionSettings, handleUserPermissionAllow } =
    useContext(AuthContext);

  const {
    pickTicketFormObj,
    queryStringItemPackAndShip,
    setTabValue,
    checkShipAndCompleIsDisable,
    loadThePickTicketCarrierDetails,
    approveOrApproveAndPackOnClickV2,
    loadThePickTicketShipToBillToDetails,
    completePackAndShipAndCreateShippmentLabel,
  } = usePackAndShipContext();

  const {
    idToPrintLpnFormTemplate,
    onOpenPrintFormTemplateModal,
    setIsOrderReadyForPickupModal,
    onOpenPackAndShipCarrierModal,
    onOpenPackAndShipShipToAndBillToModal,
  } = useContext(GlobalContext);

  const [formTemplateType, setFormTemplateType] =
    useState<FormTemplateTypeEnum | null>(null);

  const handlePrintBol = () => {
    onOpenPrintFormTemplateModal();
    setFormTemplateType(FormTemplateTypeEnum.BillofLading);
  };

  return (
    <div
      style={{
        width: '100% !important',
        display: 'flex',
        alignItems: 'right !important',
        lineHeight: '0px !important',
        marginLeft: '2px !important',
      }}
    >
      <MUIGrid container spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {pickTicketFormObj?.isPack && (
          <>
            <Button
              sx={{
                marginLeft: '2px !important',
                margin: '0px',
                padding: 1,
                background: '#ECF0F8',
                borderColor: '#DADCE0',
                color: '#3375E0',
                fontWeight: 600,
              }}
              variant="outlined"
              size="small"
              onClick={() => {
                setFormTemplateType(FormTemplateTypeEnum.PackingList);
                onOpenPrintFormTemplateModal();
              }}
            >
              {EnumButtonName.PrintPackingList}
            </Button>
            <Button
              sx={{
                marginLeft: '2px !important',
                padding: 1,
                background: '#3375E0',
                fontWeight: 600,
              }}
              variant="contained"
              size="small"
              onClick={(e) => approveOrApproveAndPackOnClickV2(e, 2)}
            >
              {EnumButtonName.ApproveAndPack}
            </Button>
          </>
        )}

        {pickTicketFormObj?.isPackAndShip && (
          <Button
            sx={{
              marginLeft: '2px !important',
              padding: 1,
              background: '#3375E0',
              fontWeight: 600,
            }}
            variant="contained"
            size="small"
            onClick={(e) => approveOrApproveAndPackOnClickV2(e, 3)}
          >
            {EnumButtonName.PackAndShip}
          </Button>
        )}

        {!pickTicketFormObj?.isApprove &&
          !pickTicketFormObj?.isPack &&
          !pickTicketFormObj?.isPackAndShip && (
            <>
              {handleUserPermissionAllow(
                UserPermissionEnum.PackAndShip_Edit,
              ) && (
                <Button
                  {...(pickTicketFormObj?.isShipAndComplete && {
                    disabled: true,
                  })}
                  sx={{
                    marginLeft: '2px !important',
                    padding: 1,
                    background: '#ECF0F8',
                    borderColor: '#DADCE0',
                    color: '#3375E0',
                    fontWeight: 600,
                  }}
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    setTabValue('1');
                    onOpenPackAndShipShipToAndBillToModal();
                    loadThePickTicketShipToBillToDetails();
                  }}
                >
                  {EnumButtonName.ShipToAndBillTo}
                </Button>
              )}
              <Button
                sx={{
                  marginLeft: '2px !important',
                  padding: 1,
                  background: '#ECF0F8',
                  borderColor: '#DADCE0',
                  color: '#3375E0',
                  fontWeight: 600,
                }}
                onClick={handlePrintBol}
                variant="outlined"
                size="small"
              >
                {EnumButtonName.PrintBol}
              </Button>
              <Button
                {...(pickTicketFormObj?.isShipAndComplete && {
                  disabled: true,
                })}
                sx={{
                  marginLeft: '2px !important',
                  margin: '0px',
                  padding: 1,
                  background: '#ECF0F8',
                  borderColor: '#DADCE0',
                  color: '#3375E0',
                  fontWeight: 600,
                  display: 'none', // temporary hide
                }}
                variant="outlined"
                size="small"
                onClick={(e) => {
                  onOpenPackAndShipCarrierModal();
                  loadThePickTicketCarrierDetails();
                }}
              >
                {EnumButtonName.Carrier}
              </Button>

              <Button
                sx={{
                  marginLeft: '2px !important',
                  margin: '0px',
                  padding: 1,
                  background: '#ECF0F8',
                  borderColor: '#DADCE0',
                  color: '#3375E0',
                  fontWeight: 600,
                }}
                variant="outlined"
                size="small"
                onClick={(e) => {
                  onOpenPrintFormTemplateModal();
                  setFormTemplateType(FormTemplateTypeEnum.PackingList);
                }}
              >
                {EnumButtonName.PrintPackingList}
              </Button>

              {handleUserPermissionAllow(
                UserPermissionEnum.PackAndShip_CompletePack,
              ) && (
                <>
                  <Button
                    {...(checkShipAndCompleIsDisable() && {
                      disabled: true,
                    })}
                    onClick={(e) => {
                      completePackAndShipAndCreateShippmentLabel();
                    }}
                    sx={{
                      marginLeft: '2px !important',
                      margin: '0px',
                      padding: 1,
                      background: '#3375E0',
                      fontWeight: 600,
                    }}
                    variant="contained"
                    size="small"
                  >
                    {EnumButtonName.ShipAndComplete}
                  </Button>
                  <Button
                    {...((pickTicketFormObj?.isShipAndComplete ||
                      !pickTicketFormObj?.readyForPickupButtonEnable) && {
                      disabled: true,
                    })}
                    onClick={() => {
                      setIsOrderReadyForPickupModal(true);
                    }}
                    sx={{
                      marginLeft: '2px !important',
                      margin: '0px',
                      padding: 1,
                      background: '#3375E0',
                      fontWeight: 600,
                    }}
                    variant="contained"
                    size="small"
                  >
                    {EnumButtonName.ReadyForPickup}
                  </Button>
                </>
              )}
            </>
          )}
      </MUIGrid>
      {queryStringItemPackAndShip.id !== 0 &&
        idToPrintLpnFormTemplate === null &&
        formTemplateType === FormTemplateTypeEnum.PackingList && (
          <Printformtemplate
            preview={false}
            dynamicId={queryStringItemPackAndShip.id}
            formtemplateTypeId={FormTemplateTypeEnum.PackingList}
          />
        )}
      {pickTicketFormObj?.orderNoId &&
        formTemplateType === FormTemplateTypeEnum.BillofLading && (
          <Printformtemplate
            preview={false}
            dynamicId={pickTicketFormObj?.orderNoId}
            formtemplateTypeId={formTemplateType}
          />
        )}
    </div>
  );
};

export default React.memo(PackAndShipButtons);
