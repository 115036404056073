import { IOptionColumn, IOrderSearchStatus } from './interfaces';

export const optionSortings: IOptionColumn[] = [
  { value: 'asc', label: 'Ascending' },
  { value: 'desc', label: 'Descending' },
];

export const OrderSearchStatusesData: IOrderSearchStatus[] = [
  { value: 1, label: 'New', isChecked: false },
  { value: 2, label: 'Open', isChecked: false },
  { value: 3, label: 'Picked', isChecked: false },
  { value: 4, label: 'Shipment', isChecked: false },
  { value: 5, label: 'Complete', isChecked: false },
  { value: 6, label: 'Partial', isChecked: false },
  { value: 7, label: 'Cancelled', isChecked: false },
  { value: 11, label: 'Ready for Pickup', isChecked: false },
];
