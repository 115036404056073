import React, { useContext, useEffect, useState } from 'react';

import { DropdownListItem } from 'common/models';
import { snackActions } from 'config/snackbar.js';
import { usePackAndShipContext } from 'pages/packandship/context';
import { GetPackAndShipVoidFillDDL } from 'services/api/packandship/packandship.api';

import CloseIcon from '@mui/icons-material/Close';
import WidgetsIcon from '@mui/icons-material/Widgets';
import {
  Modal,
  Typography,
  Grid as MUIGrid,
  Box,
  Button,
  IconButton,
  CircularProgress,
  Tooltip,
  Divider,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;
    border: none;
    width: 100%;
    max-width: 1000px;
    border-radius: 20px;
    outline: none;
  }
`;

const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 40px;
    gap: 16px;
  }
`;

function VoidFillModal() {
  const {
    pickTicketFormObj,
    isVoidFillModal,
    setIsVoidFillModal,
    shipVoidFillDDL,
    setShipVoidFillDDL,
    updateCartonCodeFunctionModel,
    updateCartonCodeFunction,
  } = usePackAndShipContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);

  function closeVoidFillModal() {
    setIsVoidFillModal(false);
    setShipVoidFillDDL([]);
    setIsBusy(false);
    setIsLoading(false);
  }

  function saveVoidFill() {
    setIsBusy(true);
  }

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Escape') {
        closeVoidFillModal();
      } else {
        const getValue = shipVoidFillDDL.find(
          (e, index) => index === Number(event.key),
        );

        if (getValue !== undefined) {
          updateCartonCodeFunction(
            updateCartonCodeFunctionModel.packageIdParam,
            updateCartonCodeFunctionModel.cartonCodeIdParam,
            updateCartonCodeFunctionModel.isCreateNewPackage,
            updateCartonCodeFunctionModel.isExternalPackage,
            updateCartonCodeFunctionModel.isCustomPackageParam,
            getValue.value,
          );
          closeVoidFillModal();
        }
      }
    };

    document.addEventListener('keyup', keyDownHandler);
    return () => {
      document.removeEventListener('keyup', keyDownHandler);
    };
  }, [shipVoidFillDDL]);

  useEffect(() => {
    if (isVoidFillModal) {
      setIsLoading(true);

      const ddl: DropdownListItem[] = [];
      ddl.push({ value: 0, text: 'NO VOID FILL' });

      GetPackAndShipVoidFillDDL(pickTicketFormObj.warehouseCustomerId ?? 0)
        .then((x) => {
          const voidFillDDL: DropdownListItem[] = x.data;
          voidFillDDL.forEach((z) => {
            ddl.push(z);
          });
        })
        .catch((err) => {
          snackActions.error(err);
        })
        .finally(() => {
          setShipVoidFillDDL([...ddl]);
          setIsLoading(false);
        });
    }
  }, [isVoidFillModal]);

  return (
    <Modal open={isVoidFillModal}>
      <MUIContainer>
        <MUIContent>
          {isLoading && (
            <MUIGrid
              container
              direction="row"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column-reverse',
              }}
            >
              <MUIGrid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <CircularProgress />
                  <Box sx={{ paddingLeft: '5px' }}>Loading</Box>
                </Typography>
              </MUIGrid>
            </MUIGrid>
          )}

          {!isLoading && (
            <>
              <MUIGrid
                container
                direction="row"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ width: '100%' }}
              >
                <MUIGrid item xs={6}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Confirm the current void fill or select from one below
                  </Typography>
                </MUIGrid>

                <MUIGrid item xs={6} sx={{ textAlignLast: 'right' }}>
                  <IconButton
                    color="inherit"
                    aria-label="close"
                    onClick={() => closeVoidFillModal()}
                  >
                    <CloseIcon style={{ transform: 'scale(1.9)' }} />
                    <span style={{ fontSize: 'x-small' }}>
                      {' '}
                      (Press Esc to close)
                    </span>
                  </IconButton>
                </MUIGrid>
              </MUIGrid>
              <MUIGrid
                container
                direction="row"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {shipVoidFillDDL.map((ddl: DropdownListItem, index) => (
                  <Tooltip title={ddl.text} key={ddl.value}>
                    <MUIGrid item xs={3}>
                      <MUIGrid
                        container
                        direction="row"
                        spacing={2}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        // sx={{
                        //   borderStyle:
                        //     cartonCodesToHighLight.value === value.value
                        //       ? 'solid'
                        //       : 'none',
                        //   margin: '3px',
                        // }}
                        sx={{
                          margin: '3px',
                        }}
                      >
                        <MUIGrid item xs={4}>
                          <WidgetsIcon sx={{ fontSize: 60 }} />
                        </MUIGrid>
                        <MUIGrid item xs={8}>
                          <Typography variant="caption" fontWeight="bold">
                            {ddl.text}
                          </Typography>
                          {/* <br />
                    <Typography variant="caption" color="text.secondary">
                      {value.dimensions}
                    </Typography> */}
                          <Typography variant="body2">Press {index}</Typography>
                        </MUIGrid>
                      </MUIGrid>
                    </MUIGrid>
                  </Tooltip>
                ))}
              </MUIGrid>
              <Divider sx={{ mt: 1, mb: 1 }} />

              <MUIGrid
                container
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{
                  display: 'flex',
                  alignItems: 'baseline',
                }}
              >
                {/* <MUIGrid item xs={isNextPackageModalOpen ? 5 : 12}>
                  <Typography variant="subtitle1">
                    Current Void Fill:{' '}
                    {pickTicketFormObj?.packages !== undefined &&
                      pickTicketFormObj?.packages?.length > 0 && (
                        <b>
                          {pickTicketFormObj.packages[0]?.itemAsPackage
                            ?.label ||
                            pickTicketFormObj.packages[0]
                              ?.carrierPredefinedPackage}
                        </b>
                      )}
                  </Typography>
                </MUIGrid> */}
              </MUIGrid>
            </>
          )}
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
}

export default React.memo(VoidFillModal);
