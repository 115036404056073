/* eslint no-use-before-define: ["error", { "functions": false, "variables": false, "classes": false }] */
/* eslint no-param-reassign: "error" */
import React, { useEffect, useRef, useContext, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import Header from 'components/header';
import { DropdownSelectList } from 'components/dropdown';
import { ContentContainer, MainContainer } from 'pages/styles';
import { useLocationModel } from 'services/hooks';
import { AuthContext } from 'store/contexts/AuthContext';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material';

import { toggleBinSelection } from './libs/bin.js';
import { populateScene } from './libs/draw.js';
import {
  getBinCameraDirection,
  getHorizontalParallelPoint,
  getWorldPosition,
} from './libs/helper.js';

const filters = [
  { field: 'sku', alias: 'SKU', type: 'string' },
  { field: 'description', alias: 'Description', type: 'string' },
  { field: 'lotNo', alias: 'Lot No.', type: 'string' },
  { field: 'expirationDate', alias: 'Lot Expiration', type: 'string' },
  { field: 'qty', alias: 'Qty On Hand', type: 'number' },
  { field: 'lpn', alias: 'License Plate No.', type: 'string' },
];

const ThreeScene = React.memo(() => {
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const { data, getData } = useLocationModel();
  const contentRef = useRef(null);
  const rendererRef = useRef(null);
  const animationFrameIdRef = useRef(null);
  const currentlySelectedBinRef = useRef(null);
  const [matchingBins, setMatchingBins] = useState([]);
  const [currentBinIndex, setCurrentBinIndex] = useState(0);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const racksRef = useRef([]);
  const modelGroupRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState({
    column: filters[0],
    value: '',
  });
  const [checked, setChecked] = React.useState(false);

  const fetchData = async () => {
    await getData(
      Number(
        currentUser.Claim_WarehouseCustomerId ?? currentUser.Claim_CustomerId,
      ),
      Number(currentLocationAndFacility.customerFacilityId),
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!contentRef.current || !data) return;

    const scene = new THREE.Scene();

    sceneRef.current = scene;
    scene.background = new THREE.Color('white');

    const camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      5,
      500,
    );

    cameraRef.current = camera;

    camera.position.set(-118.284, 30, 0);
    camera.lookAt(new THREE.Vector3(0, 0, 0));

    const renderer = new THREE.WebGLRenderer();

    rendererRef.current = renderer;
    renderer.domElement.style.pointerEvents = 'auto';
    renderer.setSize(window.innerWidth - 150, window.innerHeight);
    contentRef.current.appendChild(renderer.domElement);

    const floorGeometry = new THREE.PlaneGeometry(500, 500);
    const floorMaterial = new THREE.MeshStandardMaterial({ color: '0xcfcfcf' });

    const floor = new THREE.Mesh(floorGeometry, floorMaterial);

    floor.rotation.x = -Math.PI / 2;

    scene.add(floor);

    const floorCenterX = floorGeometry.parameters.width / 2;
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);

    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);

    directionalLight.position.set(0, 100, 0);
    directionalLight.target.position.set(0, 0, 0);

    scene.add(directionalLight);
    scene.add(directionalLight.target);

    const controls = new OrbitControls(camera, renderer.domElement);

    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.screenSpacePanning = true;
    controls.maxPolarAngle = Math.PI / 2;

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    function onMouseClick(event) {
      event.preventDefault();
      const rect = renderer.domElement.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);

      const bins = [];
      modelGroupRef.current.traverse((child) => {
        if (child.name.startsWith('BIN')) {
          bins.push(child);
        }
      });

      const intersects = raycaster.intersectObjects(bins, true);

      if (intersects.length > 0) {
        intersects.sort((a, b) => a.distance - b.distance);
        const selectedBin = intersects[0].object;
        currentlySelectedBinRef.current = toggleBinSelection(
          scene,
          camera,
          selectedBin,
          currentlySelectedBinRef.current,
        );
      }
    }

    window.addEventListener('click', onMouseClick);

    function animate() {
      animationFrameIdRef.current = requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    }

    const loader = new FontLoader();

    loader.load(
      'https://threejs.org/examples/fonts/helvetiker_regular.typeface.json',
      () => {
        const modelGroup = new THREE.Group();
        modelGroupRef.current = modelGroup;
        scene.add(modelGroup);

        const AISLE_SPACING = 20;
        const RACK_SPACING = 10;

        const calculateStartX = (aisles, spacing, centerX) =>
          aisles % 2 === 0
            ? centerX - (aisles / 2 - 0.5) * spacing
            : centerX - Math.floor(aisles / 2) * spacing;

        const startX = calculateStartX(
          data.aisles,
          AISLE_SPACING,
          floorCenterX,
        );

        const racks = populateScene(
          data.aisles,
          data.rows,
          data.levels,
          modelGroup,
          data.bins,
          AISLE_SPACING,
          RACK_SPACING,
          startX,
        );
        racksRef.current = racks;

        const box = new THREE.Box3().setFromObject(modelGroup);
        const center = new THREE.Vector3();
        box.getCenter(center);

        modelGroup.position.sub(center);
        modelGroup.position.y = -box.min.y;
      },
      undefined,
      undefined,
    );

    animate();

    const cleanUp = () => {
      window.removeEventListener('click', onMouseClick);

      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
      }

      controls.dispose();
      renderer.dispose();
      scene.children.forEach((child) => {
        if (child.geometry) child.geometry.dispose();
        if (child.material) {
          if (Array.isArray(child.material)) {
            child.material.forEach((material) => material.dispose());
          } else {
            child.material.dispose();
          }
        }
      });

      if (contentRef.current) {
        contentRef.current.removeChild(renderer.domElement);
      }
    };

    // eslint-disable-next-line consistent-return
    return cleanUp;
  }, [data]);

  const animateCamera = (targetPosition, currentlySelectedBin) => {
    cameraRef.current.position.set(-118.284, 30, 0);

    const startPosition = cameraRef.current.position.clone();
    cameraRef.current.lookAt(targetPosition);

    const clearSelectedBin = () => {
      if (currentlySelectedBin) {
        toggleBinSelection(
          sceneRef.current,
          cameraRef.current,
          currentlySelectedBin,
          currentlySelectedBin,
        );
      }
    };

    clearSelectedBin();

    toggleBinSelection(
      sceneRef.current,
      cameraRef.current,
      currentlySelectedBin,
      currentlySelectedBin,
    );

    const binWorldPosition = getWorldPosition(
      modelGroupRef.current.getObjectByName(currentlySelectedBin.name),
    );

    const verticalPoint = new THREE.Vector3(
      startPosition.x,
      binWorldPosition.y,
      startPosition.z,
    );

    const horizontalPoint = new THREE.Vector3(
      verticalPoint.x,
      verticalPoint.y,
      binWorldPosition.z,
    );

    const points = [
      startPosition,
      verticalPoint,
      horizontalPoint,
      binWorldPosition,
    ];

    cameraRef.current.position.copy(points[0]);
    cameraRef.current.lookAt(points[points.length - 1]);

    const durations = [500, 2000, 3000, 2000];
    let start = null;
    let currentSegment = 0;

    function animate(time) {
      if (!start) start = time;
      const elapsed = time - start;
      const t = Math.min(elapsed / durations[currentSegment], 1);

      if (currentSegment === 0) {
        cameraRef.current.position.y = THREE.MathUtils.lerp(
          points[0].y,
          points[1].y,
          t,
        );
      } else if (currentSegment === 1) {
        cameraRef.current.position.x = THREE.MathUtils.lerp(
          points[1].x,
          points[2].x,
          t,
        );
        cameraRef.current.position.z = THREE.MathUtils.lerp(
          cameraRef.current.position.z,
          points[2].z,
          t,
        );
      } else if (currentSegment === 2) {
        cameraRef.current.position.x = THREE.MathUtils.lerp(
          points[2].x,
          points[3].x,
          t,
        );
        cameraRef.current.position.y = THREE.MathUtils.lerp(
          points[2].y,
          points[3].y,
          t,
        );
        cameraRef.current.position.z = THREE.MathUtils.lerp(
          points[2].z,
          points[3].z,
          t,
        );
      } else if (currentSegment === 3) {
        const fixedLookAt = new THREE.Vector3(
          binWorldPosition.x,
          binWorldPosition.y,
          binWorldPosition.x,
        );
        const direction = new THREE.Vector3()
          .subVectors(cameraRef.current.position, fixedLookAt) // Fixed look-at direction
          .normalize();
        cameraRef.current.position.addVectors(
          binWorldPosition,
          direction.multiplyScalar(10 * t),
        );
      }

      if (t < 1) {
        requestAnimationFrame(animate);
      } else if (currentSegment < durations.length - 1) {
        currentSegment += 1;
        start = time;
        requestAnimationFrame(animate);
      }
    }

    requestAnimationFrame(animate);
  };

  const navigateToBin = (bin) => {
    if (currentlySelectedBinRef.current) {
      toggleBinSelection(
        sceneRef.current,
        cameraRef.current,
        currentlySelectedBinRef.current,
        currentlySelectedBinRef.current,
      );
    }

    const selectedBin = toggleBinSelection(
      sceneRef.current,
      cameraRef.current,
      bin,
      currentlySelectedBinRef.current,
    );

    if (selectedBin) {
      currentlySelectedBinRef.current = selectedBin;

      const binPosition = new THREE.Vector3(
        selectedBin.position.x,
        selectedBin.position.y,
        selectedBin.position.z,
      );

      animateCamera(binPosition, selectedBin, false);
    }
  };

  const handlePrev = () => {
    if (matchingBins.length === 0) return;
    setCurrentBinIndex((prev) => (prev === 0 ? 0 : prev - 1));
    navigateToBin(matchingBins[currentBinIndex]);
  };

  const handleNext = () => {
    if (matchingBins.length === 0) return;
    setCurrentBinIndex((prev) =>
      prev === matchingBins.length - 1 ? matchingBins.length - 1 : prev + 1,
    );
    navigateToBin(matchingBins[currentBinIndex]);
  };

  const handleSearch = () => {
    if (!data) return;
    if (searchQuery.value.trim().length <= 0) {
      return;
    }

    const foundBins = [];

    modelGroupRef.current.children.forEach((child) => {
      if (child.name.startsWith('BIN')) {
        const items = child.userData.items || [];

        const { field } = searchQuery.column;

        const matchingItem = items.find((item) => {
          const itemValue = item[field];
          if (searchQuery.column.type === 'string') {
            const itemValueStr = itemValue.toString().toLowerCase().trim();
            const searchValueStr = searchQuery.value.toLowerCase().trim();
            return itemValueStr.includes(searchValueStr);
          }
          if (searchQuery.column.type === 'number') {
            return itemValue === parseFloat(searchQuery.value);
          }
          return false;
        });

        if (matchingItem) {
          foundBins.push(child);
        }
      }
    });

    if (foundBins.length === 0) {
      alert('No item found in BIN');
      return;
    }

    setMatchingBins(foundBins);
    setCurrentBinIndex(0);
    navigateToBin(foundBins[0]);
  };

  const handleLabelCheckboxChange = (event) => {
    setChecked(event.target.checked);
    racksRef.current.forEach((rack) => {
      rack.traverse((child) => {
        if (child.name === 'rackLabel') {
          child.visible = event.target.checked;
        }
      });
    });
    if (rendererRef.current) {
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              key={checked}
              checked={checked}
              onChange={handleLabelCheckboxChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Show Labels"
        />
        <Box sx={{ display: 'flex', gap: '5px' }}>
          <Box sx={{ background: '#fff', width: '150px' }}>
            <DropdownSelectList
              filters={filters}
              onSelect={(selected) =>
                setSearchQuery((prev) => ({
                  ...prev,
                  column: selected,
                }))
              }
            />
          </Box>
          <input
            type="text"
            placeholder="Search"
            defaultValue={searchQuery.value}
            onChange={(e) =>
              setSearchQuery((prev) => ({
                ...prev,
                value: e.target.value,
              }))
            }
            style={{ padding: '5px 7px' }}
          />
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </Box>
      </Box>
      <div ref={contentRef} style={{ position: 'relative', marginTop: '10px' }}>
        <Box
          id="bin-info-overlay"
          className="three-bin-overlay theme-3Dbin-light"
        >
          {matchingBins.length > 1 && (
            <div className="bin-header">
              {`Found ${matchingBins.length} bin${
                matchingBins.length > 1 ? "'s" : ''
              }`}
              <Box>
                <IconButton aria-label="Previous" onClick={handlePrev}>
                  <ChevronLeft />
                </IconButton>
                <IconButton aria-label="Next" onClick={handleNext}>
                  <ChevronRight />
                </IconButton>
              </Box>
            </div>
          )}

          <Box
            id="bin-owner"
            sx={{
              color: '#3375e0',
              textAlign: 'left',
              fontWeight: '600',
              fontSize: '0.9rem',
              padding: '7px 15px',
              borderBottom: '1px solid #eee',
            }}
          />
          <Box id="bin-items" className="bin-items" sx={{ padding: '15px' }} />
        </Box>
      </div>
    </Box>
  );
});

const LocationModeler = () => (
  <MainContainer>
    <Header />
    <ContentContainer>
      <ThreeScene />
    </ContentContainer>
  </MainContainer>
);

export default React.memo(LocationModeler);
