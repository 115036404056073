import * as THREE from 'three';

export const getWorldPosition = (object) => {
  const worldPosition = new THREE.Vector3();
  object.getWorldPosition(worldPosition);
  return worldPosition;
};

export const getBinCameraDirection = (camera, targetPosition) => {
  if (!camera.current || !targetPosition) {
    console.error('Camera position or target position is not defined');
    return 'NONE';
  }

  const cameraRight = new THREE.Vector3().setFromMatrixColumn(
    camera.current.matrixWorld,
    0,
  );
  const vectorToTarget = targetPosition.clone().sub(camera.current.position);

  const dotProduct = cameraRight.dot(vectorToTarget);
  return dotProduct < 0 ? 'LEFT' : 'RIGHT';
};

export const getHorizontalParallelPoint = (
  camera,
  start,
  direction,
  distance,
) => {
  const rightVector = new THREE.Vector3().setFromMatrixColumn(
    camera.current.matrixWorld,
    0,
  );
  rightVector.multiplyScalar(direction === 'LEFT' ? -distance : distance);
  return start.clone().add(rightVector);
};
