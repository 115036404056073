import { REPORT_URL } from 'config/constants';
import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';
import { environmentVariable } from 'store/environment';
import * as GeoJson from 'topojson';

export interface IPayloadSalesSummary {
  customerId: number;
  customerLocationId: number;
  customerFacilityId: number;
  dayFilter: number;
  dateFrom: Date;
  dateTo: Date;
}

export const getDashboardInventoryFlow = async (
  customerLocationId,
  customerFacilityId,
  dateFilter,
) => {
  try {
    const response = await httpClient.get(
      `/Dashboard/InventoryFlow/${customerLocationId}/${customerFacilityId}/${dateFilter}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getDashboardQueues = async (
  customerLocationId,
  customerFacilityId,
  days,
) => {
  try {
    const response = await httpClient.get(
      `/Dashboard/Queues/${customerLocationId}/${customerFacilityId}/${days}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getDashboardTopSalesOrders = async (
  customerLocationId,
  customerFacilityId,
  dateFilter,
) => {
  try {
    const response = await httpClient.get(
      `/Dashboard/TopSalesOrders/${customerLocationId}/${customerFacilityId}/${dateFilter}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getDashboardLowStock = async (
  customerLocationId,
  customerFacilityId,
) => {
  try {
    const response = await httpClient.get(
      `/Dashboard/LowStock/${customerLocationId}/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getDashboardOperations = async (
  customerLocationId,
  customerFacilityId,
  days,
) => {
  try {
    const response = await httpClient.get(
      `/Dashboard/Operations/${customerLocationId}/${customerFacilityId}/${days}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getDashboardSalesSummary = async (
  payload: IPayloadSalesSummary,
) => {
  try {
    const response = await httpClient.post('/Dashboard/SalesSummary', payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getDashboardOrderStatus = async (
  customerLocationId: number,
  customerFacilityId: number,
) => {
  try {
    const response = await httpClient.get(
      `/Dashboard/OrderStatus/${customerLocationId}/${customerFacilityId}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getDashboardChartStates = async () => {
  try {
    const url = environmentVariable(REPORT_URL);
    const response = await fetch(`${url}/json/states-10m.json`)
      .then((r) => r.json())
      .then((us) => GeoJson.feature(us, us.objects.states).features);
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getDashboardChartWorld = async () => {
  try {
    const url = environmentVariable(REPORT_URL);
    const response = await fetch(`${url}/json/countries-50m.json`)
      .then((r) => r.json())
      .then((us) => GeoJson.feature(us, us.objects.countries).features);
    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getDashboardCustomerBackorder = async (
  customerLocationId: number,
  customerFacilityId: number,
  customerId: number,
) => {
  try {
    const response = await httpClient.get(
      `/Dashboard/Customer/Backorder/${customerLocationId}/${customerFacilityId}/${customerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getDashboardCustomerOrderStatus = async (
  customerLocationId: number,
  customerFacilityId: number,
  customerId: number,
) => {
  try {
    const response = await httpClient.get(
      `/Dashboard/Customer/OrderStatus/${customerLocationId}/${customerFacilityId}/${customerId}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
