import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Divider, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DashboardDivider } from 'components/styles';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { getDashboardOperations } from 'services/api/dashboard/dashboard.api';

const operationsDefault = {
  opsManagers: '',
  activeDevices: '',
  operators: '',
  registeredBins: '',
  binsInUse: '',
  utilization: '',
  salesOrders: '',
};

function DashboardOperations() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentLocationAndFacility } = useContext(AuthContext);
  const { updateData } = useContext(GlobalContext);
  const [operationsData, setOperationsData] = useState(operationsDefault);
  const [operationsRange, setOperationsRange] = useState(1);

  const fetchOperations = async (filter: number) => {
    try {
      const operationsFromApi = await getDashboardOperations(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
        filter,
      );
      setOperationsData(operationsFromApi);
      return true;
    } catch (err: any) {
      return err;
    }
  };

  const handleOperationsRangeChange = async (
    _event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setOperationsRange(newValue);
    fetchOperations(newValue);
  };

  useEffect(() => {
    fetchOperations(30);
  }, [currentLocationAndFacility, updateData]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '400px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Operations
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '24px',
          }}
        >
          <Typography
            sx={{
              cursor: 'pointer',
              color: [theme.palette.primary.main],
            }}
            fontWeight="bold"
            onClick={() => {
              navigate('/users');
            }}
          >
            Users
          </Typography>
          <Typography
            sx={{
              cursor: 'pointer',
              color: [theme.palette.primary.main],
            }}
            fontWeight="bold"
            onClick={() => {
              navigate('/locations');
            }}
          >
            Storage
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '24px',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '40%',
            gap: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
              width: '33%',
              gap: '12px',
            }}
          >
            <Typography variant="h3" fontWeight="regular">
              {Object.keys(operationsData).length > 0 &&
                operationsData.opsManagers}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              Ops Managers
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
              width: '33%',
              gap: '12px',
            }}
          >
            <Typography variant="h3" fontWeight="regular">
              {Object.keys(operationsData).length > 0 &&
                operationsData.activeDevices}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              Active Devices
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
              width: '33%',
              gap: '12px',
            }}
          >
            <Typography variant="h3" fontWeight="regular">
              {Object.keys(operationsData).length > 0 &&
                operationsData.operators}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              Operators
            </Typography>
          </Box>
        </Box>
        <DashboardDivider />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '40%',
            gap: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
              width: '33%',
              gap: '12px',
            }}
          >
            <Typography variant="h3" fontWeight="regular">
              {Object.keys(operationsData).length > 0 &&
                operationsData.registeredBins}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              Registered Bins
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
              width: '33%',
              gap: '12px',
            }}
          >
            <Typography variant="h3" fontWeight="regular">
              {Object.keys(operationsData).length > 0 &&
                operationsData.binsInUse}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              Bins in Use
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
              width: '33%',
              gap: '12px',
            }}
          >
            <Typography variant="h3" fontWeight="regular">
              {Object.keys(operationsData).length > 0 &&
                `${operationsData.utilization}`}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              Utilization
            </Typography>
          </Box>
        </Box>
        <DashboardDivider />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '20%',
            gap: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              width: '100%',
              pt: '12px',
              gap: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'start',
                width: '100%',
              }}
            >
              <Typography variant="h4" fontWeight="regular">
                {Object.keys(operationsData).length > 0 &&
                  `${operationsData.salesOrders}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Daily Sales Orders
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  width: '60%',
                  height: '20%',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Tabs
                  value={operationsRange}
                  onChange={handleOperationsRangeChange}
                  centered
                >
                  <Tab
                    sx={{ minWidth: '30px', padding: '8px' }}
                    value={5}
                    label="5 Days"
                  />
                  <Divider orientation="vertical" flexItem />
                  <Tab
                    sx={{ minWidth: '30px', padding: '8px' }}
                    value={15}
                    label="15 Days"
                  />
                  <Divider orientation="vertical" flexItem />
                  <Tab
                    sx={{ minWidth: '30px', padding: '8px' }}
                    value={30}
                    label="30 Days"
                  />
                </Tabs>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(DashboardOperations);
