import { DefaultLocationAndFacilityGetModel } from 'common/models/customerlocations';
import { AESEncrypt } from 'helpers/encryptdecrypt';
import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';
import { setValue } from 'store/localStorage/useLocalStorage';

import { GetDefaultLocationAndFacility } from '../customerlocations/customerlocations.api';
import { getUser } from '../user/users.api';

export const login = async (data) => {
  try {
    const response = await httpClient.post('Auth/RequestUserToken', data);
    setValue('token', response.data.token);

    const user = await getUser(response.data.userId);

    return {
      token: response.data.token,
      userData: user.customerId,
    };
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getDefaultLocationAndFacility = async (customerId) => {
  try {
    const defaultLocationAndFacility: DefaultLocationAndFacilityGetModel =
      await GetDefaultLocationAndFacility(customerId);
    const encryptData: string = AESEncrypt(defaultLocationAndFacility);

    setValue('locationAndFacility', encryptData);

    return {
      locationFacility: defaultLocationAndFacility,
      encryptedLocation: encryptData,
    };
  } catch (error) {
    throw errorCatch(error);
  }
};

export const requestResetPasswordToken = async (requestForm) => {
  try {
    const response = await httpClient.post(
      `/Auth/RequestPasswordResetTokenEmail`,
      requestForm,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const resetPassword = async (resetForm) => {
  try {
    const response = await httpClient.post(`/Auth/ResetPassword`, resetForm);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
