import styled from 'styled-components';

import { Button } from '@mui/material';

export const ButtonPrimary = styled(Button)`
  padding: 5px 15px;
  text-transform: capitalize;
  color: #fff;
  background-color: #052fff;
  border-width: revert;
  min-width: 80px;
  font-size: 13px;
  &:hover {
    background-color: #3375e0;
  }
`;

export const ButtonPrimaryOutline = styled(Button)`
  padding: 5px 15px;
  text-transform: capitalize;
  color: #052fff;
  border: 1px solid #052fff;
  border-width: revert;
  min-width: 80px;
  font-size: 13px;
  &:hover {
    color: #3375e0;
    border: 1px solid #3375e0;
  }
`;
