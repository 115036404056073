import React, {
  useContext,
  useState,
  ChangeEvent,
  useEffect,
  useRef,
} from 'react';
import { useReactToPrint } from 'react-to-print';

import Button from 'components/button';
import DatePickerInput from 'components/datepicker';
import Input from 'components/input/Input';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import { getItemLookup, createItem } from 'services/api/item/item.api';
import { createLot, getLotLookup } from 'services/api/lot/lot.api.';
import {
  createReceiveV2,
  updateReceiveV2,
  updateReceiveStatusV2,
} from 'services/api/receive/receive.api';
import { getCustomerUnitOfMeasures } from 'services/api/uoms/uoms.api';
import { createLotNo, getLotNo } from 'services/api/receipts/receipts-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import * as yup from 'yup';

import { Modal, Box, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { autoCompleteTimeStamp } from '../../../helpers/autocompletetimestamp';

interface PurchaseOrderModalProps {
  lineItems?: any;
  addItem?: React.Dispatch<any>;
  edit?: boolean;
  editItem?: any;
  callBack?: any;
  editCallBack?: () => void;
}

interface AutoCompleteOptionType {
  id: number;
  label: string;
}
type ExtendLotNoType = {
  lotId?: number;
  lotExpirationDate?: string;
};
type LotNoType = AutoCompleteOptionType & ExtendLotNoType;

export default React.memo(
  ({
    lineItems,
    addItem,
    edit,
    editItem,
    callBack,
    editCallBack,
  }: PurchaseOrderModalProps) => {
    const theme = useTheme();
    const urlQuery = useQuery();
    const [existingPo, setExistingPo] = useState(false);
    const [optionLotNo, setOptionLotNo] = useState<LotNoType[]>([]);
    const [lotNoValue, setLotNoValue] = useState<LotNoType>(null);
    const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
    const defaultAutocompleteOption: AutoCompleteOptionType | null = {
      id: -1,
      label: '',
    };
    const {
      isPurchaseOrderItemModalOpen,
      onClosePurchaseOrderItemModal,
      handleUpdateData,
    } = useContext(GlobalContext);
    const [searchText, setSearchText] = useState([]);
    const [newItem, setNewItem] = useState(false);
    const [newLotNumber, setNewLotNumber] = useState(false);
    const [items, setItems] = useState([]);
    const [itemLotNumbers, setItemLotNumbers] = useState([]);
    const [customerUoms, setCustomerUoms] = useState<any>([]);
    const [newItemSKU, setNewItemSKU] = useState('');
    const initialFormState = {
      customerId: currentUser.Claim_CustomerId,
      customerLocationId: currentLocationAndFacility.customerLocationId,
      tempId: 0,
      itemId: {
        id: defaultAutocompleteOption.id,
        label: defaultAutocompleteOption.label,
      },
      qty: 0,
      expectedArrivalDate: '',
      expectedShipDate: '',
      shipVia: '',
      notes: '',
      lotId: 0,
      lotNo: '',
      lotExpirationDate: null,
    };
    const initialFormErrors = {
      customerId: currentUser.Claim_CustomerId,
      customerLocationId: currentLocationAndFacility.customerLocationId,
      itemId: '',
      qty: '',
      expectedArrivalDate: '',
      expectedShipDate: '',
      shipVia: '',
      notes: '',
    };
    const [form, setForm] = useState<any>(initialFormState);
    const [error, setError] = useState('');
    const [formErrors, setFormErrors] = useState<any>(initialFormErrors);

    const handleClosePurchaseOrderItemModal = () => {
      setForm(initialFormState);
      setFormErrors(initialFormErrors);
      setError('');
      /* 
        setNewItem(false);
        setNewLotNumber(false);
        setNewLotNumberValue('');
        setNewItemSKU(''); 
      */
      onClosePurchaseOrderItemModal();
      if (edit) {
        editCallBack();
      }
    };

    const schema = yup.object().shape({
      customerId: yup.number().required('Required'),
      customerLocationId: yup.number().required('Required'),
      receiveId: yup.number(),
      itemId: yup
        .object()
        .shape({ id: yup.string().nullable(), label: yup.string() }),
      qty: yup.string().required('Quantity is required.'),
      shipVia: yup.string(),
    });

    const validateLotNo = () => {
      const temp: any = {};
      if (
        form.lotExpirationDate === null ||
        form.lotExpirationDate === undefined ||
        form.lotExpirationDate === ''
      ) {
        temp.lotExpirationDate = 'This field is required';
      } else {
        temp.lotExpirationDate = '';
      }

      setFormErrors({
        ...temp,
      });
      return Object.values(temp).every((x) => x === '');
    };

    const handleOnClickAddLotNo = async (e: any) => {
      e.preventDefault();

      if (!validateLotNo()) {
        snackActions.error('Please complete required fields');
      } else {
        const PAYLOAD = {
          customerId: parseInt(currentUser.Claim_CustomerId, 10),
          customerLocationId: currentLocationAndFacility.customerLocationId,
          itemId: form.itemId.id,
          lotNo: form.lotNo,
          expirationDate: form.lotExpirationDate,
        };

        try {
          const response = await createLotNo(PAYLOAD);
          setLotNoValue({
            id: response.lotId,
            label: response.lotNo,
            lotExpirationDate: response.expirationDate,
          });
          setOptionLotNo((prev) => [
            ...prev,
            {
              id: response.lotId,
              label: response.lotNo,
              lotExpirationDate: response.expirationDate,
            },
          ]);

          snackActions.success('Successfully created new lot number.');
        } catch (err) {
          snackActions.error(err);
        }
      }
    };

    const handleOnClickAddLotNoV2 = async (e: any) => {
      e.preventDefault();

      const PAYLOAD = {
        customerId: parseInt(currentUser.Claim_CustomerId, 10),
        customerLocationId: currentLocationAndFacility.customerLocationId,
        itemId: form.itemId.id,
        lotNo: form.lotNo,
        expirationDate: form.lotExpirationDate,
      };

      try {
        const response = await createLotNo(PAYLOAD);

        const lotExpirationDate =
          response.expirationDate === '0001-01-01T00:00:00' ||
          response.expirationDate === '0001-01-02T00:00:00'
            ? null
            : response.expirationDate;

        setLotNoValue({
          id: response.lotId,
          label: response.lotNo,
          lotExpirationDate,
        });
        setOptionLotNo((prev) => [
          ...prev,
          {
            id: response.lotId,
            label: response.lotNo,
            lotExpirationDate,
          },
        ]);

        snackActions.success('Successfully created new lot number.');
      } catch (err) {
        snackActions.error(err);
      }
    };

    const onForm = (key, text) => {
      setForm(() => ({
        ...form,
        [key]: text,
      }));
    };

    const onLoadLotNumbers = async (itemId) => {
      try {
        const lotNumbersFromApi = await getLotLookup(itemId);

        setItemLotNumbers(lotNumbersFromApi);
        return true;
      } catch (err) {
        return err;
      }
    };

    const onLoadGetLotNoOnChange = async (itemId) => {
      try {
        return await getLotNo(itemId);
      } catch (err) {
        return err;
      }
    };

    const inputHandler = (
      key: string,
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      if (key === 'qty') {
        onForm(
          key,
          event.target.value === '' ||
            event.target.value === null ||
            Number(event.target.value) < 0
            ? '0'
            : event.target.value,
        );
      } else {
        onForm(key, event.target.value);
      }
    };

    const arrivalDateHandler = (newValue: Date | null) => {
      onForm('expectedArrivalDate', newValue);
    };

    const expectedShipDateHandler = (newValue: Date | null) => {
      onForm('expectedShipDate', newValue);
    };

    const itemSelectionOptions = Array.isArray(items)
      ? items.map((i, index) => ({
          id: i.itemId,
          label: i.sku,
        }))
      : [{ id: 0, label: 'New Item' }];

    const lotNumberSelectionOptions = Array.isArray(itemLotNumbers)
      ? itemLotNumbers.map((i, index) => ({
          id: i.lotId,
          label: i.lotNo,
        }))
      : [];

    const uomSelectionOptions = Array.isArray(customerUoms)
      ? customerUoms.map((uom, index) => ({
          id: uom.unitOfMeasureId,
          label: `${uom.code} - ${uom.description}`,
        }))
      : [{ id: 0, label: 'Uom' }];

    const autoCompleteInputHandler = (
      key: string,
      value: AutoCompleteOptionType,
      event?: any,
    ) => {
      let resetLotNumber = false;
      let lotNumberValue = form.lotId;
      if (key === 'itemId') {
        form.description = items.filter(
          (item) => item.itemId === value.id,
        )[0].description;
        onLoadGetLotNoOnChange(value.id).then((x) => {
          setOptionLotNo([]);
          x.map((lot) =>
            setOptionLotNo((prev) => [
              ...prev,
              {
                id: lot.lotId,
                label: lot.lotNo,
                lotExpirationDate: lot.expirationDate,
              },
            ]),
          );
        });
        console.log(optionLotNo);
        if (value) {
          if (newItem) {
            setNewItem(false);
          } else {
            onLoadLotNumbers(value.id);
          }
        }

        if (!value) {
          if (newItem) {
            setNewItemSKU('');
          } else {
            setItemLotNumbers([]);
            resetLotNumber = true;
          }
        }
      }

      if (key === 'lotNo') {
        setLotNoValue(value);

        const lot = {
          lotId: value.id,
          lotNo: value.label,
          lotExpirationDate: event.target.dataset.lotexpirationdate,
        };

        // setForm((prev) => ({
        //   ...prev,
        //   ...lot,
        // }));
        setFormErrors({});
      }

      if (key === 'lotId') {
        if (value) {
          lotNumberValue = value;
          setNewLotNumber(false);
        } else {
          lotNumberValue = {
            id: defaultAutocompleteOption.id,
            label: defaultAutocompleteOption.label,
          };
        }
      }

      setForm(() => ({
        ...form,
        [key]: !value
          ? {
              id: defaultAutocompleteOption.id,
              label: defaultAutocompleteOption.label,
            }
          : value,
        lotId:
          resetLotNumber && !newLotNumber
            ? {
                id: defaultAutocompleteOption.id,
                label: defaultAutocompleteOption.label,
              }
            : lotNumberValue,
      }));
    };

    const relatedOnLotInputHandler = (
      key: string,
      value: AutoCompleteOptionType,
      event?: any,
    ) => {
      if (key === 'lotNo') {
        setLotNoValue(value);

        const lotExpirationDate =
          event.target.dataset.lotexpirationdate === '0001-01-01T00:00:00' ||
          event.target.dataset.lotexpirationdate === '0001-01-02T00:00:00'
            ? null
            : event.target.dataset.lotexpirationdate;

        const lot = {
          lotId: value.id,
          lotNo: value.label,
          lotExpirationDate,
        };

        setForm((prev) => ({
          ...prev,
          ...lot,
        }));

        setFormErrors({});
      }
      if (key === 'lotExpDate') {
        if (event !== null && event !== 'Invalid Date') {
          setForm((prevState) => ({
            ...prevState,
            lotExpirationDate: event,
          }));
        } else {
          setForm((prevState) => ({
            ...prevState,
            lotExpirationDate: null,
          }));
        }
      }
    };

    const onLoadUomData = async () => {
      try {
        const customerSetUoms = await getCustomerUnitOfMeasures(
          currentUser.Claim_CustomerId,
        );

        setCustomerUoms(customerSetUoms);

        return true;
      } catch (err: any) {
        return err;
      }
    };

    const onLoadItemLookup = async () => {
      try {
        const itemLookupFromApi = await getItemLookup(
          currentUser.Claim_CustomerId,
          searchText,
        );
        setItems(itemLookupFromApi);

        return true;
      } catch (err) {
        return err;
      }
    };

    const validateOnlick = (fieldValues = form) => {
      const temp: any = {};
      form.qty = form.qty <= 0 ? '' : form.qty;

      if ('itemId' in fieldValues) {
        temp.itemId = fieldValues.itemId.id !== -1 ? '' : 'Item is required';
      }
      if ('qty' in fieldValues) {
        temp.qty = fieldValues.qty ? '' : 'Quantity is required';
      }

      setFormErrors({
        ...temp,
      });

      return Object.values(temp).every((x) => x === '');
    };

    const onSaveNewPurchaseOrderItem = () => {
      if (validateOnlick()) {
        if (edit && !existingPo) {
          const newValue = lineItems.find((row) => row.tempId === form.tempId);
          const filterItemFromArray = lineItems.filter(
            (item) => item.tempId !== form.tempId,
          );

          newValue.tempId = form.tempId;
          newValue.itemSKU = form.itemId.label;
          newValue.itemId = form.itemId.id;
          newValue.description = items.filter(
            (item) => item.itemId === form.itemId.id,
          )[0].description;
          newValue.received = '0';
          newValue.status = null;
          newValue.qty = form.qty;
          newValue.expectedArrivalDate = form.expectedArrivalDate;
          newValue.expectedShipDate = form.expectedShipDate;
          newValue.shipVia = form.shipVia;
          newValue.notes = form.notes;
          newValue.lotId = form.lotId === undefined ? 0 : form.lotId;
          newValue.lotNo = form.lotNo;
          newValue.lotExpirationDate = form.lotExpirationDate;
          addItem([...filterItemFromArray, newValue]);
        } else {
          const newLineItems = {
            tempId: Math.random(),
            itemSKU: form.itemId.label,
            itemId: form.itemId.id,
            description: items.filter(
              (item) => item.itemId === form.itemId.id,
            )[0].description,
            received: '0',
            status: null,
            qty: form.qty,
            expectedArrivalDate: form.expectedArrivalDate,
            expectedShipDate: form.expectedShipDate,
            shipVia: form.shipVia,
            notes: form.notes,
            lotId: form.lotId === undefined ? 0 : form.lotId,
            lotNo: form.lotNo,
            lotExpirationDate: form.lotExpirationDate,
          };
          addItem((prev) => [...prev, newLineItems]);
        }
        handleClosePurchaseOrderItemModal();
      }
    };

    const onAddItemToExistingPo = async () => {
      form.customerId = currentUser.Claim_CustomerId;
      form.qty = form.qty <= 0 ? '' : form.qty;
      schema
        .validate(form, {
          abortEarly: false,
        })
        .then(async () => {
          try {
            const newForm: any = {};

            if (!edit) {
              newForm.customerId = form.customerId;
              newForm.customerLocationId = form.customerLocationId;
              newForm.purchaseOrderId = urlQuery.get('id');
              newForm.itemId = form.itemId.id;
              newForm.qty = form.qty;
              newForm.expectedArrivalDate = form.expectedArrivalDate;
              newForm.expectedShipDate = form.expectedShipDate;
              newForm.shipVia = form.shipVia;
              newForm.notes = form.notes;
              newForm.lotId = form.lotId;
              newForm.lotNo = form.lotNo;
              newForm.lotExpirationDate = form.lotExpirationDate;
              const newLineItem = await createReceiveV2(newForm);

              snackActions.success(`Successfully added new line item.`);
            } else if (edit) {
              if (editItem.status === 'Closed') {
                await updateReceiveStatusV2({
                  receiveId: form.receiveId,
                  status: 1,
                });
              }
              newForm.receiveId = form.receiveId;
              newForm.qty = form.qty;
              newForm.expectedArrivalDate = form.expectedArrivalDate;
              newForm.expectedShipDate = form.expectedShipDate;
              newForm.shipVia = form.shipVia;
              newForm.notes = form.notes;
              newForm.lotId = form.lotId === undefined ? 0 : form.lotId;
              newForm.lotNo = form.lotNo;
              newForm.lotExpirationDate = form.lotExpirationDate;
              const editLineItem = await updateReceiveV2(newForm);
              snackActions.success(`Successfully updated line item.`);
            }
            handleClosePurchaseOrderItemModal();
            editCallBack();
            handleUpdateData();
          } catch (err: any) {
            setError(err);
            setFormErrors(initialFormErrors);
            snackActions.error(`${err}`);
          }
        })
        .catch((err) => {
          const errorsFound = err.inner.reduce((acc, curr) => {
            if (!acc[curr.path]) acc[curr.path] = curr.message;
            return acc;
          }, {});
          setFormErrors(errorsFound);
          setError('');
        });
    };

    const setEditForm = () => {
      if (edit) {
        const lotExpirationDate =
          editItem.lotExpirationDate === '0001-01-01T00:00:00' ||
          editItem.lotExpirationDate === '0001-01-02T00:00:00'
            ? null
            : editItem.lotExpirationDate;

        setForm({
          customerId: currentUser.Claim_CustomerId,
          customerLocationId: currentLocationAndFacility.customerLocationId,
          receiveId: editItem.receiveId,
          tempId: editItem.tempId,
          itemId: {
            id: editItem.itemId,
            label: editItem.itemSKU,
          },
          description: editItem.description,
          qty: editItem.qty,
          expectedArrivalDate: editItem.expectedArrivalDate,
          expectedShipDate: editItem.expectedShipDate,
          shipVia: editItem.shipVia,
          notes: editItem.notes,
          lotId: editItem.lotId,
          lotNo: editItem.lotNo,
          lotExpirationDate,
        });

        onLoadGetLotNoOnChange(editItem?.itemId).then((x) => {
          const options = [];
          x.map((lot) =>
            options.push({
              id: lot.lotId,
              label: lot.lotNo,
              lotExpirationDate: lot.expirationDate,
            }),
          );

          setOptionLotNo(options);

          const result = options.find(
            (o: LotNoType) => o.id === editItem?.lotId,
          );

          if (result !== undefined) {
            setLotNoValue(result);
            setForm((prevState) => ({
              ...prevState,
              lotId: result.id,
              lotNo: result.label,
              lotExpirationDate,
            }));
          }
        });
      }
    };

    useEffect(() => {
      onLoadItemLookup();
      onLoadUomData();
      setEditForm();
      if (urlQuery.get('id') !== 'new') {
        setExistingPo(true);
      }
    }, [currentLocationAndFacility, edit]);

    const filterOptions = createFilterOptions({
      matchFrom: 'any',
      limit: 100,
    });

    if (!isPurchaseOrderItemModalOpen) return null;
    return (
      <Modal
        open={isPurchaseOrderItemModalOpen}
        onClose={() => handleClosePurchaseOrderItemModal()}
      >
        <ModalBox>
          <ModalContent>
            {edit ? (
              <Typography
                sx={{ marginBottom: '16px' }}
                variant="h6"
                fontWeight="bold"
              >
                Edit PO Item
              </Typography>
            ) : (
              <Typography
                sx={{ marginBottom: '16px' }}
                variant="h6"
                fontWeight="bold"
              >
                PO Item
              </Typography>
            )}

            <>
              {newItem ? (
                <>
                  <Autocomplete
                    filterOptions={filterOptions}
                    options={itemSelectionOptions}
                    getOptionLabel={(option: AutoCompleteOptionType) =>
                      `${option.label}` || ''
                    }
                    size="small"
                    value={form.itemId}
                    sx={{ width: '100%' }}
                    onChange={(
                      event: any,
                      value: AutoCompleteOptionType | null,
                    ) => {
                      autoCompleteInputHandler('itemId', value);
                    }}
                    renderInput={(params) =>
                      formErrors.itemId ? (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          label="SKU"
                          error
                          helperText={formErrors.itemId}
                          onChange={(event) => {
                            setNewItemSKU(event.target.value);
                          }}
                        />
                      ) : (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          label="SKU"
                          onChange={(event) => {
                            setNewItemSKU(event.target.value);
                          }}
                        />
                      )
                    }
                  />
                  <Autocomplete
                    filterOptions={filterOptions}
                    options={uomSelectionOptions}
                    getOptionLabel={(option: AutoCompleteOptionType) =>
                      `${option.label}` || ''
                    }
                    size="small"
                    value={form.uomId}
                    sx={{ width: '100%' }}
                    onChange={(
                      event: any,
                      value: AutoCompleteOptionType | null,
                    ) => {
                      autoCompleteInputHandler('uomId', value);
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) =>
                      formErrors.uomId ? (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          label="Item UoM"
                          error
                          helperText={formErrors.uomId}
                        />
                      ) : (
                        <TextField {...params} label="Item UoM" />
                      )
                    }
                  />
                  <Input
                    sx={{ width: '100%' }}
                    placeholder="Item Description"
                    value={form.itemDescription}
                    error={formErrors.itemDescription}
                    onChange={(value) => inputHandler('itemDescription', value)}
                  />
                </>
              ) : (
                <Autocomplete
                  filterOptions={filterOptions}
                  options={itemSelectionOptions}
                  getOptionLabel={(option: AutoCompleteOptionType) =>
                    `${option.label}` || ''
                  }
                  disabled={edit}
                  size="small"
                  value={form.itemId}
                  sx={{ width: '100%' }}
                  onChange={(
                    event: any,
                    value: AutoCompleteOptionType | null,
                  ) => {
                    autoCompleteInputHandler('itemId', value);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) =>
                    formErrors.itemId ? (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        label="SKU"
                        error
                        disabled={edit}
                        helperText={formErrors.itemId}
                      />
                    ) : (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        label="SKU"
                      />
                    )
                  }
                />
              )}

              <Input
                sx={{ width: '100%' }}
                placeholder={
                  form.description === '' ||
                  form.description === null ||
                  form.description === undefined
                    ? 'Description'
                    : form.description
                }
                value={form.description}
                disabled
              />
              <Input
                sx={{ width: '100%' }}
                placeholder="Qty (ea)"
                value={form.qty <= 0 ? '' : form.qty}
                error={formErrors.qty}
                onChange={(value) => inputHandler('qty', value)}
                type="number"
              />
              <Autocomplete
                filterOptions={filterOptions}
                value={lotNoValue}
                options={optionLotNo}
                getOptionLabel={(option: AutoCompleteOptionType) =>
                  `${option.label}` || ''
                }
                noOptionsText={
                  <Button
                    variant="contained"
                    size="large"
                    onMouseDown={handleOnClickAddLotNoV2}
                    sx={{ padding: 1, width: '100%' }}
                  >
                    Add Lot No
                  </Button>
                }
                defaultValue={optionLotNo?.find(
                  (o: LotNoType) => o.label === lotNoValue?.label,
                )}
                // isOptionEqualToValue={(option, selected) =>
                //   option?.id === selected?.id
                // }
                {...(form.itemId.id === -1 && {
                  disabled: true,
                })}
                size="small"
                sx={{ width: '100%' }}
                onChange={(
                  event: any,
                  value: AutoCompleteOptionType | null,
                ) => {
                  relatedOnLotInputHandler('lotNo', value, event);
                }}
                renderOption={(props, option: LotNoType) => (
                  <li
                    {...props}
                    key={option?.id}
                    data-lotexpirationdate={option?.lotExpirationDate}
                  >
                    {option?.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    onChange={(e) => {
                      setForm((prevState) => ({
                        ...prevState,
                        lotNo: e.target.value,
                      }));
                    }}
                    id="outlined"
                    label="Lot No."
                    size="small"
                    sx={{ width: '100%' }}
                  />
                )}
              />

              <DatePickerInput
                sx={{ width: '100%', background: 'white' }}
                {...(form.itemId.id === -1 && {
                  disabled: true,
                })}
                inputFormat="MM/dd/yyyy"
                value={
                  form?.lotExpirationDate !== undefined
                    ? form?.lotExpirationDate
                    : null
                }
                label="Lot Exp. Date"
                onChange={(event) =>
                  relatedOnLotInputHandler('lotExpDate', null, event)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-root': {
                        background: 'white',
                      },
                    }}
                    {...(formErrors.lotExpirationDate && {
                      error: true,
                      helperText: formErrors.lotExpirationDate,
                    })}
                    size="small"
                  />
                )}
              />
              <DatePickerInput
                sx={{ width: '100%', background: 'white' }}
                inputFormat="MM/dd/yyyy"
                value={form.expectedShipDate}
                label="Expected Ship Date"
                onChange={expectedShipDateHandler}
                renderInput={(params) =>
                  formErrors.expectedShipDate ? (
                    <TextField
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      {...params}
                      size="small"
                      error
                      helperText={formErrors.expectedShipDate}
                    />
                  ) : (
                    <TextField
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      {...params}
                      error={false}
                      size="small"
                    />
                  )
                }
              />

              <DatePickerInput
                sx={{ width: '100%', background: 'white' }}
                inputFormat="MM/dd/yyyy"
                label="Expected Arrival Date"
                value={form.expectedArrivalDate}
                onChange={arrivalDateHandler}
                renderInput={(params) =>
                  formErrors.expectedArrivalDate ? (
                    <TextField
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      {...params}
                      size="small"
                      error
                      helperText={formErrors.expectedArrivalDate}
                    />
                  ) : (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      error={false}
                      size="small"
                    />
                  )
                }
              />
              <Input
                sx={{ width: '100%' }}
                placeholder="Notes"
                multiline
                rows={4}
                value={form.notes}
                error={formErrors.notes}
                onChange={(value) => inputHandler('notes', value)}
              />
            </>
            <Typography variant="subtitle2" color="#d32f2f">
              {error}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                  justifyContent: 'flex-end',
                },
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'center',
                },
                gap: '8px',
              }}
            >
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="text"
                size="large"
                onClick={() => handleClosePurchaseOrderItemModal()}
              >
                Cancel
              </Button>
              {existingPo && edit ? (
                <Button
                  sx={{ minHeight: '48px', maxWidth: '150px' }}
                  variant="contained"
                  size="large"
                  onClick={() => onAddItemToExistingPo()}
                >
                  Update
                </Button>
              ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {edit ? (
                    <Button
                      sx={{ minHeight: '48px', maxWidth: '150px' }}
                      variant="contained"
                      size="large"
                      onClick={() => onSaveNewPurchaseOrderItem()}
                    >
                      Edit Item
                    </Button>
                  ) : (
                    <Button
                      sx={{ minHeight: '48px', maxWidth: '150px' }}
                      variant="contained"
                      size="large"
                      onClick={() =>
                        urlQuery.get('id') !== 'new'
                          ? onAddItemToExistingPo()
                          : onSaveNewPurchaseOrderItem()
                      }
                    >
                      Add Item
                    </Button>
                  )}
                </>
              )}
            </Box>
          </ModalContent>
        </ModalBox>
      </Modal>
    );
  },
);
