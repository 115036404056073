import React, { useContext, useState, useEffect } from 'react';

import DatePickerInput from 'components/datepicker';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { getCustomerFacilitiesByCustomerId } from 'services/api/customerfacilities/customerfacilities.api';
import {
  getShopifyIntegrationDetail,
  createShopifyIntegrationWithAccessToken,
  updateShopifyIntegration,
  getShopifyLocations,
  shopifyOrdersSyncUp,
  pushInventoryQty,
  enableShopifyIntegration,
  disableShopifyIntegration,
  enableAutoSync,
  disableAutoSync,
} from 'services/api/integrations/integrations.api';
import { getOrderSourceList } from 'services/api/salesorders/salesorders-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  Modal,
  Box,
  Typography,
  Autocomplete,
  Grid as MUIGrid,
  Button,
  Switch,
  IconButton,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 350px;
    border-radius: 20px;
    max-height: 80%;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 40px;
    gap: 16px;
    overflow-y: auto;
    height: 100%;
  }
`;

export default React.memo(() => {
  const { isSyncOrderModalOpen, setIsSyncOrderModalOpen } =
    useContext(GlobalContext);

  const { selectedIntegrationId } = useWarehouseCustomerContext();

  const { currentUser } = useContext(AuthContext);

  const [dateFrom, setDateFrom] = useState(null);

  useEffect(() => {
    setDateFrom(null);
  }, [isSyncOrderModalOpen]);

  const handleCloseIntegrationModal = () => {
    setIsSyncOrderModalOpen(false);
  };

  const syncShopifyOrders = async () => {
    try {
      let dateFromFilter = 'null';
      if (selectedIntegrationId > 0) {
        if (dateFrom) dateFromFilter = moment(dateFrom).format('YYYY-MM-DD');
        const response = await shopifyOrdersSyncUp(
          selectedIntegrationId,
          dateFromFilter,
        );
        snackActions.success('Orders synced successfully.');
        handleCloseIntegrationModal();
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  return (
    <Modal
      open={isSyncOrderModalOpen}
      onClose={() => handleCloseIntegrationModal()}
    >
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Pull Shopify Orders
        </Typography>
        <MUIContent>
          <>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <DatePickerInput
                  label="Start Date"
                  inputFormat="MM/dd/yyyy"
                  value={dateFrom}
                  onChange={(value) => setDateFrom(value)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </MUIGrid>
            </MUIGrid>

            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    onClick={() => {
                      handleCloseIntegrationModal();
                    }}
                    variant="outlined"
                    size="small"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      padding: 1,
                      width: '100%',
                      backgroundColor: ' rgb(75, 126, 254) !important',
                    }}
                    onClick={() => {
                      syncShopifyOrders();
                    }}
                  >
                    SUBMIT
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
