import React, { useContext, useState } from 'react';

import { TriggerRuleModel } from 'common/models/orderprocessingrules';
import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { EnableDisableCustomRule } from 'services/api/orderprocessingrule';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Grid as MUIGrid,
  Button,
  CircularProgress,
} from '@mui/material';

import { useOrderProcessingRulesContext } from '../context';

function TriggerRulesModal() {
  const {
    isEnableDisableRuleModalOpen,
    setIsEnableDisableRuleModalOpen,
    customRuleId,
    setCustomRuleId,
    isEnabledCustomRule,
  } = useContext(GlobalContext);

  const { viewLoadDataGrid } = useOrderProcessingRulesContext();

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const handleCloseModel = () => {
    setIsEnableDisableRuleModalOpen(false);
    setCustomRuleId(null);
  };

  const handleOnClickEnableDisableRule = () => {
    setIsBusy(true);

    const PAYLOAD: TriggerRuleModel = {
      orderProcessingRuleId: customRuleId,
    };

    EnableDisableCustomRule(PAYLOAD)
      .then(() => {
        viewLoadDataGrid();
        handleCloseModel();
      })
      .catch((err) => {
        snackActions.error(err);
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  return (
    <Modal open={isEnableDisableRuleModalOpen}>
      <MUIContainer sx={{ width: '30% !important' }}>
        <Typography
          className={
            isEnabledCustomRule ? 'modalTextHeaderError' : 'modalTextHeader'
          }
          variant="h6"
          fontWeight="bold"
        >
          {isEnabledCustomRule ? 'Disable' : 'Enable'} Custom Rule
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <Typography variant="h6">
              Are you sure you want to{' '}
              {isEnabledCustomRule
                ? '"Disable this rule"'
                : '"Enable  this rule"'}
              ?
            </Typography>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                disabled={isBusy}
                onClick={() => {
                  handleCloseModel();
                }}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                }}
                onClick={() => handleOnClickEnableDisableRule()}
              >
                {isBusy ? (
                  <Box display="flex" alignItems="center" gap={1}>
                    <CircularProgress size={20} color="inherit" />
                    Loading...
                  </Box>
                ) : (
                  <>Confirm</>
                )}
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
}

export default React.memo(TriggerRulesModal);
