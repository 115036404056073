import React, {
  useContext,
  useState,
  ChangeEvent,
  useEffect,
  useRef,
} from 'react';
import Barcode from 'react-barcode/lib/react-barcode';
import { useReactToPrint } from 'react-to-print';

import Button from 'components/button';
import Input from 'components/input/Input';
import {
  ModalBox,
  ModalContent,
  PrintContainer,
  PrintDivider,
} from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import {
  createLicensePlateUnknown,
  getGenerateLicensePlateNo,
  generateLicensePlateUnknown,
} from 'services/api/licensePlates/licensePlates.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import * as yup from 'yup';

import { Modal, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default React.memo(() => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const { isPrintRollsModalOpen, onClosePrintRollModal, handleUpdateData } =
    useContext(GlobalContext);

  const [printQty, setPrintQty] = useState(1);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [licensePlates, setLicensePlates] = useState([]);

  const [error, setError] = useState('');
  const initialErrorsState = {
    nextInSequence: '',
    printQty: '',
  };
  const initialState = {
    nextInSequence: '',
    printQty: 1,
  };
  const [formErrors, setFormErrors] = useState(initialErrorsState);
  const [form, setForm] = useState(initialState);
  const rollRef = useRef(null);
  const print = useReactToPrint({
    content: () => rollRef.current,
  });

  const onForm = (key, text) => {
    setForm(() => ({
      ...form,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    if (key === 'printQty') {
      setPrintQty(parseInt(event.target.value, 10));
    }
    onForm(key, event.target.value);
  };

  const schema = yup.object().shape({
    nextInSequence: yup.string().required('Required.'),
    printQty: yup
      .number()
      .test(
        'Is positive?',
        'The number must be greater than 0',
        (value) => value > 0,
      )
      .required('Required.'),
  });

  const handleClosePrintRollModal = () => {
    setForm(initialState);
    setFormErrors(initialErrorsState);
    setError('');
    setAlreadyExists(false);
    onClosePrintRollModal();
  };

  const onPrintRolls = async () => {
    schema
      .validate(form, {
        abortEarly: false,
      })
      .then(async () => {
        if (!alreadyExists) {
          const PAYLOAD = {
            customerId: currentUser.Claim_CustomerId,
            licensePlateNo: form.nextInSequence,
            qty: form.printQty,
          };

          try {
            const response = await generateLicensePlateUnknown(PAYLOAD);

            setAlreadyExists(response.isExist);
            setLicensePlates(response.licensePlates);
          } catch (err: any) {
            setError(err);
            setFormErrors(initialErrorsState);
            snackActions.error(err);
          }
        } else {
          print();
          handleClosePrintRollModal();
          handleUpdateData();
        }
      })
      .catch((err) => {
        const errorsFound = err.inner.reduce((acc, curr) => {
          if (!acc[curr.path]) acc[curr.path] = curr.message;
          return acc;
        }, {});
        setFormErrors(errorsFound);
        setError('');
      });
  };

  const onLoadNextInSequence = async () => {
    const nextInSequenceFromApi = await getGenerateLicensePlateNo(
      currentUser.Claim_CustomerId,
    );

    setForm({ ...form, nextInSequence: nextInSequenceFromApi });
  };

  useEffect(() => {
    onLoadNextInSequence();
  }, []);

  useEffect(() => {
    if (licensePlates.length > 0 && !alreadyExists) {
      print();
      handleClosePrintRollModal();
      handleUpdateData();
    }
  }, [licensePlates, alreadyExists]);

  if (!isPrintRollsModalOpen) return null;

  return (
    <Modal
      open={isPrintRollsModalOpen}
      onClose={() => handleClosePrintRollModal()}
    >
      <ModalBox>
        <ModalContent>
          <Box
            sx={{
              display: 'none',
            }}
          >
            <PrintContainer ref={rollRef} sx={{ display: 'block' }}>
              {licensePlates.length > 0 && (
                <>
                  {licensePlates.map((data, index) => (
                    <div key={data.licensePlateId}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            paddingY: '24px',
                          }}
                        >
                          <Barcode
                            value={data.licensePlateNo}
                            width={5}
                            height={120}
                            fontSize={100}
                          />
                        </Box>
                      </Box>
                      <div className="page-break" />
                    </div>
                  ))}
                </>
              )}
            </PrintContainer>
          </Box>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            {alreadyExists ? 'Duplicates Warning' : 'Print Roll'}
          </Typography>
          {alreadyExists ? (
            <Typography sx={{ marginBotton: '16px' }} variant="subtitle1">
              {`A batch of plates or this plate number has been created before.
              Are you sure want to print?`}
            </Typography>
          ) : (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                <Input
                  sx={{ width: '100% ' }}
                  placeholder="Next In Sequence"
                  value={form.nextInSequence}
                  error={formErrors.nextInSequence}
                  onChange={(value) => inputHandler('nextInSequence', value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onPrintRolls();
                    }
                  }}
                />
                <Input
                  sx={{ width: '100% ' }}
                  type="number"
                  placeholder="Print Qty"
                  value={form.printQty}
                  error={formErrors.printQty}
                  onChange={(value) => inputHandler('printQty', value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onPrintRolls();
                    }
                  }}
                />
              </Box>
              <Typography variant="subtitle2" color="#d32f2f">
                {error}
              </Typography>
            </>
          )}

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end',
              },
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
              },
              gap: '8px',
            }}
          >
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="text"
              size="large"
              onClick={() => handleClosePrintRollModal()}
            >
              Cancel
            </Button>
            <Button
              sx={{ minHeight: '48px', maxWidth: '150px' }}
              variant="contained"
              size="large"
              onClick={() => onPrintRolls()}
            >
              Yes, Print
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
