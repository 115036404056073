import React, { useContext } from 'react';

import Button from 'components/button';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { DeleteCustomerPortalAccount } from 'services/api/warehousecustomer/warehouseCustomer.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useWarehouseCustomerContext } from '..';

export default React.memo(() => {
  const theme = useTheme();

  const {
    isUserWarehouseCustomerDeleteModalOpen,
    setIsUserWarehouseCustomerDeleteModalOpen,
  } = useContext(GlobalContext);

  const { dataModalCustomerPortalUser, viewLoadData } =
    useWarehouseCustomerContext();

  const deleteContent = async () => {
    try {
      await DeleteCustomerPortalAccount({
        warehouseCustomerAccountId:
          dataModalCustomerPortalUser?.warehouseCustomerAccountId,
      });
      snackActions.success(
        `${dataModalCustomerPortalUser.email} has been successfully removed.`,
      );
      setIsUserWarehouseCustomerDeleteModalOpen(false);
      viewLoadData();
    } catch (err) {
      snackActions.error(err);
    }
  };

  return (
    <Modal open={isUserWarehouseCustomerDeleteModalOpen}>
      <ModalBox>
        <ModalContent>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '3px',
            }}
          >
            <Typography
              sx={{ marginBottom: '8px' }}
              variant="h6"
              fontWeight="bold"
            >
              Delete Client Portal Account
            </Typography>
            <Typography variant="subtitle1">
              Are you sure you want to delete this account (
              {dataModalCustomerPortalUser?.email})?
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end',
              },
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
              },
              gap: '8px',
            }}
          >
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="text"
              size="large"
              onClick={() => setIsUserWarehouseCustomerDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="contained"
              size="large"
              onClick={() => deleteContent()}
            >
              Confirm
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
