import React, {
  useEffect,
  useState,
  useContext,
  ChangeEvent,
  useRef,
} from 'react';
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { PageLoadStateEnum } from 'common/enums';
import Card from 'components/card';
import CustomGridToolbar from 'components/gridtoolbar';
import Header from 'components/header';
import Input from 'components/input/Input';
import { Grid } from 'components/styles';
import moment from 'moment';
import { SinglePrintLicensePlate } from 'pages/licenseplates/lp-print';
import CustomerBinModal from 'pages/shared/customerbinmodal';
import Printformtemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import { MainContainer, ContentContainer } from 'pages/styles';
import { getBinDetails } from 'services/api/customerfacilities/customerfacilities.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Search } from '@mui/icons-material';
import { Typography, Box, Link, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid';

interface State {
  pageLoadState: PageLoadStateEnum;
}

const INITIAL_STATE: State = {
  pageLoadState: PageLoadStateEnum.LOAD_NONE,
};

function BinView() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [itemCount, setItemCount] = useState(0);
  const [uniqueSKUs, setUniqueSKUs] = useState(0);
  const [binItemDetails, setBinItemDetails] = useState([]);
  const [filteredBinItems, setFilteredBinItems] = useState([]);
  const [binDetails, setBinDetails] = useState([]);
  const [urlSearchParams] = useSearchParams();
  const [state, setState] = useState<State>(INITIAL_STATE);

  const {
    idToPrintLpnFormTemplate,
    setIdToPrintLpnFormTemplate,
    onOpenPrintFormTemplateModal,
  } = useContext(GlobalContext);

  const { currentLocationAndFacility } = useContext(AuthContext);

  const [searchParams, setSearchParams] = useState({
    searchSKU: '',
    searchAvailableQty: '',
    searchDesc: '',
    searchUOM: '',
    searchSupplier: '',
    licensePlate: '',
    customer: '',
    receiptDate: '',
    lotNo: '',
    expirationDate: '',
  });

  const onForm = (key, text) => {
    setSearchParams(() => ({
      ...searchParams,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    onForm(key, event.target.value);
  };

  const onLoadBinDetails = async () => {
    try {
      const binDetailsFromApi = await getBinDetails(
        currentLocationAndFacility.customerFacilityId,
        urlSearchParams.get(`binId`),
      );

      setItemCount(binDetailsFromApi.items);
      setUniqueSKUs(binDetailsFromApi.uniqueSKUs);
      setBinItemDetails(binDetailsFromApi.itemDetails);
      setFilteredBinItems(binDetailsFromApi.itemDetails);
      setBinDetails([binDetailsFromApi.binDetail]);

      return true;
    } catch (error) {
      return error;
    }
  };

  // page load
  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_NONE) {
      setState((prev) => ({
        ...prev,
        pageLoadState: PageLoadStateEnum.LOAD_START,
      }));
    }
  }, [state.pageLoadState]);
  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_START) {
      setItemCount(0);
      setUniqueSKUs(0);
      setBinItemDetails([]);
      setFilteredBinItems([]);
      onLoadBinDetails().then(() => {
        setState((prev) => ({
          ...prev,
          pageLoadState: PageLoadStateEnum.LOAD_FINISH,
        }));
      });
    }
  }, [state.pageLoadState, currentLocationAndFacility]);

  // facility change
  useEffect(() => {
    // this will automatically trigger viewLoadData
    setState((prev) => ({
      ...prev,
      pageLoadState: PageLoadStateEnum.LOAD_START,
    }));
  }, [currentLocationAndFacility]);

  const filterItems = () => {
    const searchSKUResult = binItemDetails.filter((c) =>
      c.itemSKU.toLowerCase().includes(searchParams.searchSKU.toLowerCase()),
    );
    const searchReceiptDate = searchSKUResult.filter((c) =>
      moment(c.receiptDate)
        .format('MM/DD/YYYY')
        .includes(searchParams.receiptDate),
    );
    const searchLicensePlate = searchReceiptDate.filter((c) =>
      c.licensePlate
        ?.toLowerCase()
        .includes(searchParams?.licensePlate.toLowerCase()),
    );

    const searchQtyOnHand = searchLicensePlate.filter(
      (c) => c.qty === Number(searchParams?.searchAvailableQty),
    );

    const searchLotNo = searchLicensePlate.filter((c) =>
      c.lotNo?.includes(searchParams?.lotNo),
    );

    const searchExpirationDate = searchLicensePlate.filter((c) =>
      moment(c.expirationDate)
        .format('MM/DD/YYYY')
        .includes(searchParams.expirationDate),
    );

    const searchDesc = searchExpirationDate.filter((c) =>
      c.description
        ?.toLowerCase()
        .includes(searchParams?.searchDesc.toLowerCase()),
    );

    const finalResult = searchDesc.filter((c) =>
      c.customer?.toLowerCase().includes(searchParams?.customer.toLowerCase()),
    );

    setFilteredBinItems(searchLotNo);
  };

  type Row = typeof binDetails[number];

  const binColumns: GridColumns<Row> = [
    {
      field: 'name',
      width: 200,
      sortable: false,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Location</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography
          sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
        >
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'zone',
      width: 200,
      sortable: false,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Zone</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.zone}</Typography>,
    },
  ];

  const handleViewItemDetails = (rowData) => {
    const querySearchParams = {
      itemId: rowData.itemId,
    };
    navigate({
      pathname: `/inventory/item/${rowData.itemSKU}`,
      search: `?${createSearchParams(querySearchParams)}`,
    });
  };

  type ItemRow = typeof binItemDetails[number];

  const [printOfLPItem, setPrintOfLPItem] = useState<any>('');
  const buttonSinglePrintRef = useRef(null);
  const componentSinglePrintRef = useRef(null);

  const handleOnClickPrint = async (e: any, id: any) => {
    e.preventDefault();
    try {
      setIdToPrintLpnFormTemplate(id);
      onOpenPrintFormTemplateModal();
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnClickSinglePrintTrigger = useReactToPrint({
    content: () => componentSinglePrintRef.current,
  });

  const binItemDetailColumns: GridColumns<ItemRow> = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="View Item Details"
          onClick={() => handleViewItemDetails(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'itemSKU',
      width: 200,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Item SKU</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchSKU}
            onChange={(value) => inputHandler('searchSKU', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterItems();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Typography
          sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
        >
          <Link
            href={`/inventory/item-details?form=view&id=${params.row.itemId}`}
            underline="none"
          >
            {params.row.itemSKU}
          </Link>
        </Typography>
      ),
    },
    {
      field: 'receiptDate',
      width: 200,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Receipt Date</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.receiptDate}
            onChange={(value) => inputHandler('receiptDate', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterItems();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row.receiptDate != null
            ? moment(params.row.receiptDate).format('MM/DD/YYYY')
            : ''}
        </Typography>
      ),
    },
    {
      field: 'licensePlate',
      width: 200,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">License Plate</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.licensePlate}
            onChange={(value) => inputHandler('licensePlate', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterItems();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Button
          sx={{
            ...(params.row.licensePlate !== undefined && { display: 'block' }),
            cursor: 'pointer',
            color: [theme.palette.primary.main],
            fontSize: 16,
          }}
          onClick={(e) => handleOnClickPrint(e, params.row.licensePlateId)}
          variant="text"
        >
          {params.row.licensePlate}
        </Button>
      ),
    },
    {
      field: 'availableQty',
      width: 200,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Available Qty</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchAvailableQty}
            onChange={(value) => inputHandler('searchAvailableQty', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterItems();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.availableQty}</Typography>
      ),
    },
    {
      field: 'uom',
      width: 200,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Primary UOM</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchUOM}
            onChange={(value) => inputHandler('searchUOM', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterItems();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.uom}</Typography>,
    },
    {
      field: 'lotNo',
      width: 200,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Lot No.</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.lotNo}
            onChange={(value) => inputHandler('lotNo', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterItems();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.lotNo}</Typography>,
    },
    {
      field: 'expirationDate',
      width: 200,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Lot Exp. Date</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.expirationDate}
            onChange={(value) => inputHandler('expirationDate', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterItems();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row.expirationDate != null
            ? moment(params.row.expirationDate).format('MM/DD/YYYY')
            : ''}
        </Typography>
      ),
    },
    {
      field: 'desc',
      width: 200,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Description</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchDesc}
            onChange={(value) => inputHandler('searchDesc', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterItems();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.description}</Typography>,
    },
    {
      field: 'customer',
      width: 200,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography fontWeight="bold">Client</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.customer}
            onChange={(value) => inputHandler('customer', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterItems();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.customer}</Typography>,
    },
  ];

  return (
    <MainContainer>
      <Header />
      <div style={{ display: 'none' }}>
        <SinglePrintLicensePlate
          ref={componentSinglePrintRef}
          data={printOfLPItem}
        />
        <button
          type="button"
          ref={buttonSinglePrintRef}
          onClick={handleOnClickSinglePrintTrigger}
        >
          This one is trigger on single print receipt license plate item
        </button>
        {idToPrintLpnFormTemplate !== null && (
          <Printformtemplate
            preview={false}
            dynamicId={idToPrintLpnFormTemplate}
            formtemplateTypeId={FormTemplateTypeEnum.PalletLabels}
            forPalletLabelsIsUseLicense
          />
        )}
      </div>
      <CustomerBinModal itemDetails={urlSearchParams.get(`itemId`)} />
      <ContentContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            marginBottom: '16px',
          }}
        >
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '192px',
            }}
          >
            <Typography
              sx={{ color: [theme.palette.primary.main] }}
              variant="h3"
            >
              {itemCount}
            </Typography>
            <Typography variant="caption" fontWeight="bold">
              Items
            </Typography>
          </Card>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '192px',
            }}
          >
            <Typography
              sx={{ color: [theme.palette.primary.main] }}
              variant="h3"
            >
              {uniqueSKUs}
            </Typography>
            <Typography variant="caption" fontWeight="bold">
              Unique SKUs
            </Typography>
          </Card>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Card sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Grid
              autoHeight
              headerHeight={120}
              rows={binDetails}
              columns={binColumns}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              pageSize={6}
              density="compact"
              rowsPerPageOptions={[6]}
              getRowId={(row) => row.binId}
              checkboxSelection
              components={{ Toolbar: CustomGridToolbar }}
              componentsProps={{
                toolbar: {
                  title: 'Details',
                },
              }}
            />
          </Card>
          <Card sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Grid
              autoHeight
              headerHeight={120}
              rows={filteredBinItems}
              columns={binItemDetailColumns}
              pageSize={15}
              density="compact"
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              rowsPerPageOptions={[15]}
              getRowId={(row) => row.inventoryBinDetailId}
              components={{ Toolbar: CustomGridToolbar }}
              componentsProps={{
                toolbar: {
                  title: 'Items',
                },
              }}
            />
          </Card>
        </Box>
      </ContentContainer>
    </MainContainer>
  );
}

export default React.memo(BinView);
