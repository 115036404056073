import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import NavbarV2 from 'components/navbarv2';
import CustomerModal from 'pages/shared/customermodal';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Box } from '@mui/material';

import { MainContainer, Content } from './styles';

function MainLayout() {
  const { isLockedNavbar } = useContext(GlobalContext);
  const [locked, setLocked] = React.useState(false);

  useEffect(() => {
    if (
      window.localStorage.getItem('isLocked') !== undefined &&
      window.localStorage.getItem('isLocked') === 'true'
    ) {
      setLocked(true);
    } else {
      setLocked(false);
    }
  }, [isLockedNavbar]);

  return (
    <MainContainer>
      <Box sx={{ display: 'flex', width: '100%', position: 'relative' }}>
        <CustomerModal />
        {!locked ? (
          <>
            <Box sx={{ width: '4.5%' }}>
              <NavbarV2 />
            </Box>
            <Box sx={{ width: '100%' }}>
              <Content>
                <Outlet />
              </Content>
            </Box>
          </>
        ) : (
          <>
            <NavbarV2 />
            <Content>
              <Outlet />
            </Content>
          </>
        )}
      </Box>
    </MainContainer>
  );
}

export default React.memo(MainLayout);
