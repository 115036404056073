import React, { useEffect, useContext, useState } from 'react';

import DatePickerInput from 'components/datepicker';
import Input from 'components/input';
import moment from 'moment';
import {
  LotType,
  SupplierType,
  LocationType,
  useProductionContext,
} from 'pages/production/context';
import NewLotNumberModal from 'pages/shared/createnewlotnumber';
import { getLotLookup } from 'services/api/lot/lot.api.';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  TextField,
  Grid as MUIGrid,
  Card,
  Autocomplete,
  Button,
  Divider,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

import { autoCompleteTimeStamp } from '../../../helpers/autocompletetimestamp';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 1000px;
    border-radius: 4px;
  }
`;
export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 24px;
    gap: 16px;
  }
`;

export default React.memo(() => {
  const {
    isProductionLicensePlateModalOpen,
    onCloseProductionLicensePlateModal,
    onOpenNewLotNumberModal,
    isProductionLicensePlateModalPutAwayOpen,
    onCloseProductionLicensePlatePutAwayModal,
  } = useContext(GlobalContext);

  const {
    setProductionLicensePlateModal,
    productionLicensePlateModal,
    productionLicensePlateFormErrors,
    optionSuppliers,
    optionKitSkuValue,
    optionLots,
    optionLocations,
    setOptionLots,
    OnSavePostProductionLicensePlate,
    OnSavePostProductionLicensePlatePutAway,
  } = useProductionContext();

  const [selectedItemForNewLot, setSelectedItemForNewLot] = useState<{
    id: number;
    index: number;
  }>();

  const handleNewLotModalOpen = (item) => {
    setSelectedItemForNewLot(item);
    onOpenNewLotNumberModal();
  };

  const onLoadLotNumbers = async (itemId) => {
    try {
      const lotNumbersFromApi = await getLotLookup(itemId);

      lotNumbersFromApi.then((value) => {
        setOptionLots(
          value.map((v: any) => ({
            value: v.lotId,
            label: v.lotNo,
          })),
        );
      });

      return lotNumbersFromApi;
    } catch (err) {
      return err;
    }
  };

  const handleUpdateLotNumberDropdown = () => {
    onLoadLotNumbers(selectedItemForNewLot.id);
  };

  return (
    <>
      <Modal open={isProductionLicensePlateModalOpen}>
        <MUIContainer>
          <MUIContent>
            <NewLotNumberModal
              itemId={selectedItemForNewLot}
              callBack={handleUpdateLotNumberDropdown}
            />
            <Card
              sx={{
                boxShadow: 'none !important',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                backgroundColor: 'white',
              }}
            >
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ marginBottom: '10px' }}
              >
                New License Plate
              </Typography>
              <Box
                sx={{
                  boxShadow: 'none !important',
                  width: '100%',
                }}
              >
                <MUIGrid
                  container
                  spacing={6}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{
                    padding: '8px',
                    paddingTop: '10px',
                  }}
                >
                  <MUIGrid item xs={3}>
                    <TextField
                      label="Next In Sequence"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={productionLicensePlateModal.nextInSequence}
                      aria-readonly
                    />
                  </MUIGrid>

                  <MUIGrid item xs={3}>
                    <TextField
                      label="Client"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={productionLicensePlateModal.customerName}
                      aria-readonly
                    />
                  </MUIGrid>

                  <MUIGrid item xs={3}>
                    <Autocomplete
                      placeholder="Supplier"
                      disableClearable
                      options={optionSuppliers}
                      size="small"
                      value={productionLicensePlateModal.supplierId}
                      sx={{ width: '100%' }}
                      getOptionLabel={(option: SupplierType) => option.label}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      onChange={(event: any, value: SupplierType | null) => {
                        setProductionLicensePlateModal((prev) => ({
                          ...prev,
                          supplierId: value,
                        }));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Supplier"
                          error={false}
                          size="small"
                        />
                      )}
                    />
                  </MUIGrid>

                  <MUIGrid item xs={3}>
                    <TextField
                      label="Reference Number"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={productionLicensePlateModal.referenceNumber}
                      onChange={(e) =>
                        setProductionLicensePlateModal((prev) => ({
                          ...prev,
                          referenceNumber: e.target.value,
                        }))
                      }
                      autoComplete={autoCompleteTimeStamp().toString()}
                    />
                  </MUIGrid>
                </MUIGrid>

                <MUIGrid
                  container
                  spacing={4}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{
                    padding: '8px',
                  }}
                >
                  <MUIGrid item xs={3}>
                    <TextField
                      label="SKU"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={productionLicensePlateModal.sku}
                      aria-readonly
                    />
                  </MUIGrid>

                  <MUIGrid item xs={3}>
                    <Autocomplete
                      placeholder="Lot Number"
                      disableClearable
                      options={optionLots}
                      size="small"
                      value={productionLicensePlateModal.lotId}
                      sx={{ width: '100%' }}
                      getOptionLabel={(option: LotType) => option.label}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      onChange={(event: any, value: LotType | null) => {
                        setProductionLicensePlateModal((prev) => ({
                          ...prev,
                          lotId: value,
                        }));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Lot Number"
                          error={false}
                          size="small"
                        />
                      )}
                      noOptionsText={
                        <Button
                          variant="text"
                          size="small"
                          onMouseDown={() => {
                            handleNewLotModalOpen({
                              id: optionKitSkuValue.value,
                            });
                          }}
                        >
                          + Add Lot Number
                        </Button>
                      }
                    />
                  </MUIGrid>

                  <MUIGrid item xs={3}>
                    <DatePickerInput
                      label="Lot Exp."
                      inputFormat="MM/dd/yyyy"
                      value={productionLicensePlateModal.lotExpirationDate}
                      onChange={(e) =>
                        setProductionLicensePlateModal((prev) => ({
                          ...prev,
                          lotExpirationDate: e,
                        }))
                      }
                      renderInput={(params) => (
                        <TextField
                          disabled
                          {...params}
                          error={false}
                          size="small"
                        />
                      )}
                    />
                  </MUIGrid>
                </MUIGrid>
                <MUIGrid
                  container
                  spacing={4}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{
                    padding: '8px',
                  }}
                >
                  <MUIGrid item xs={2}>
                    <TextField
                      sx={{
                        width: '100%',
                        background: 'white',
                      }}
                      label="Cases"
                      type="number"
                      size="small"
                      value={
                        productionLicensePlateModal.cases > 0
                          ? productionLicensePlateModal.cases
                          : 0
                      }
                      onChange={(e) =>
                        setProductionLicensePlateModal((prev) => ({
                          ...prev,
                          cases: e.target.value,
                        }))
                      }
                      autoComplete={autoCompleteTimeStamp().toString()}
                    />
                  </MUIGrid>

                  <MUIGrid item xs={2}>
                    <TextField
                      sx={{
                        width: '100%',
                        background: 'white',
                      }}
                      label="EA/CS"
                      type="number"
                      size="small"
                      value={
                        productionLicensePlateModal.eaCase > 0
                          ? productionLicensePlateModal.eaCase
                          : 0
                      }
                      onChange={(e) =>
                        setProductionLicensePlateModal((prev) => ({
                          ...prev,
                          eaCase: e.target.value,
                        }))
                      }
                      autoComplete={autoCompleteTimeStamp().toString()}
                    />
                  </MUIGrid>

                  <MUIGrid item xs={2}>
                    <TextField
                      sx={{
                        width: '100%',
                        background: 'white',
                      }}
                      label="Total Qty"
                      required
                      type="number"
                      size="small"
                      value={
                        productionLicensePlateModal.totalQty > 0
                          ? productionLicensePlateModal.totalQty
                          : 0
                      }
                      onChange={(e) =>
                        setProductionLicensePlateModal((prev) => ({
                          ...prev,
                          totalQty: e.target.value,
                        }))
                      }
                      autoComplete={autoCompleteTimeStamp().toString()}
                      error={productionLicensePlateFormErrors.totalQty}
                      helperText={
                        productionLicensePlateFormErrors.totalQty
                          ? productionLicensePlateFormErrors.totalQty
                          : ''
                      }
                    />
                  </MUIGrid>

                  <MUIGrid item xs={2}>
                    <TextField
                      sx={{
                        width: '100%',
                        background: 'white',
                      }}
                      label="Weight"
                      type="number"
                      size="small"
                      value={
                        productionLicensePlateModal.weight > 0
                          ? productionLicensePlateModal.weight
                          : 0
                      }
                      onChange={(e) =>
                        setProductionLicensePlateModal((prev) => ({
                          ...prev,
                          weight: e.target.value,
                        }))
                      }
                      autoComplete={autoCompleteTimeStamp().toString()}
                    />
                  </MUIGrid>
                </MUIGrid>

                <MUIGrid
                  container
                  spacing={6}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{
                    padding: '8px',
                    paddingTop: '10px',
                  }}
                >
                  <MUIGrid item xs={4}>
                    <DatePickerInput
                      label="Arrival Date"
                      inputFormat="MM/dd/yyyy"
                      value={productionLicensePlateModal.arrivalDate}
                      onChange={(e) =>
                        setProductionLicensePlateModal((prev) => ({
                          ...prev,
                          arrivalDate: e,
                        }))
                      }
                      renderInput={(params) => (
                        <TextField
                          disabled
                          {...params}
                          error={false}
                          size="small"
                        />
                      )}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={4}>
                    <TextField
                      inputProps={{
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="time"
                      label="Arrival Time"
                      size="small"
                      type="time"
                      value={productionLicensePlateModal.arrivalTime}
                      onChange={(e) =>
                        setProductionLicensePlateModal((prev) => ({
                          ...prev,
                          arrivalTime: e.target.value,
                        }))
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </MUIGrid>
                </MUIGrid>
              </Box>

              <Divider sx={{ mt: 2, mb: 2 }} />
              <MUIGrid
                container
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={12} sm={12} md={8}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      boxShadow: 'none',
                      width: '100%',
                    }}
                  >
                    <Button
                      onClick={() => {
                        onCloseProductionLicensePlateModal();
                      }}
                      variant="outlined"
                      size="large"
                      sx={{ padding: 1, width: '100%' }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{
                        padding: 1,
                        width: '100%',
                        backgroundColor: '#DE4A50',
                        '&:hover': {
                          backgroundColor: '#DE4A50',
                        },
                      }}
                      onClick={(e) => {
                        OnSavePostProductionLicensePlate(
                          e,
                          productionLicensePlateModal,
                        );
                      }}
                    >
                      SAVE
                    </Button>
                  </Box>
                </MUIGrid>
              </MUIGrid>
            </Card>
          </MUIContent>
        </MUIContainer>
      </Modal>

      <Modal open={isProductionLicensePlateModalPutAwayOpen}>
        <MUIContainer sx={{ maxWidth: '400px !important' }}>
          <MUIContent>
            <Card
              sx={{
                boxShadow: 'none !important',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                backgroundColor: 'white',
              }}
            >
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ marginBottom: '10px' }}
              >
                Put Away
              </Typography>
              <Box
                sx={{
                  boxShadow: 'none !important',
                  width: '100%',
                }}
              >
                <MUIGrid item xs={3}>
                  <Autocomplete
                    placeholder="Location"
                    disableClearable
                    options={optionLocations}
                    size="small"
                    value={productionLicensePlateModal.location}
                    sx={{ width: '100%' }}
                    getOptionLabel={(option: LocationType) => option.label}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    onChange={(event: any, value: LocationType | null) => {
                      setProductionLicensePlateModal((prev) => ({
                        ...prev,
                        location: value,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location"
                        error={false}
                        size="small"
                      />
                    )}
                  />
                </MUIGrid>

                <Divider sx={{ mt: 2, mb: 2 }} />
                <MUIGrid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={12} sm={12} md={8}>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        boxShadow: 'none',
                        width: '100%',
                      }}
                    >
                      <Button
                        onClick={() => {
                          onCloseProductionLicensePlatePutAwayModal();
                        }}
                        variant="outlined"
                        size="large"
                        sx={{ padding: 1, width: '100%' }}
                      >
                        CANCEL
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          padding: 1,
                          width: '100%',
                          backgroundColor: '#DE4A50',
                          '&:hover': {
                            backgroundColor: '#DE4A50',
                          },
                        }}
                        onClick={(e) => {
                          OnSavePostProductionLicensePlatePutAway(
                            e,
                            productionLicensePlateModal,
                          );
                          onCloseProductionLicensePlatePutAwayModal();
                        }}
                      >
                        SAVE
                      </Button>
                    </Box>
                  </MUIGrid>
                </MUIGrid>
              </Box>
            </Card>
          </MUIContent>
        </MUIContainer>
      </Modal>
    </>
  );
});
