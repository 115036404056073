import React from 'react';

import { Box, Chip, Typography } from '@mui/material';

import { usePackAndShipContext } from '../context';

const PackandShipInvalidAddressChip = () => {
  const { pickTicketFormObj } = usePackAndShipContext();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{ paddingRight: 1 }}
          variant="h6"
          fontWeight="bold"
          textTransform="capitalize"
        >
          Pack and Ship / {pickTicketFormObj?.orderNo}
        </Typography>
        {pickTicketFormObj?.isAddressInvalid && (
          <Chip
            label="Invalid By Postal Office"
            color="warning"
            sx={{ marginRight: 1 }}
            size="small"
          />
        )}
      </Box>
    </Box>
  );
};
export default React.memo(PackandShipInvalidAddressChip);
