/* eslint-disable no-unused-expressions */
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import Card from 'components/card';
import DatePickerInput from 'components/datepicker';
import Footer from 'components/footer';
import Header from 'components/header';
import Input from 'components/input';
import { Grid } from 'components/styles';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import {
  CycleCountContextProvider,
  useCycleCountContext,
} from 'pages/cyclecount/context';
import { CustomerType } from 'pages/production/context';
import ConfirmEndCycleCountModal from 'pages/shared/confirmendcyclecount';
import Cyclecountmodal, {
  binCategoryOptions,
  checkedIcon,
  icon,
} from 'pages/shared/cyclecountmodal';
import { ContentContainer, MainContainer } from 'pages/styles';
import { getItemsByCustomerId } from 'services/api/cyclecount/cyclecount.api';
import { getWarehouseCustomerLookup } from 'services/api/inventory/inventory-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Pageview, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid as MUIGrid,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridActionsCellItem,
  GridColumns,
  GridEditRowsModel,
  GridToolbar,
} from '@mui/x-data-grid';

type AutocompleteBase = {
  value?: number;
  label?: string;
};
type CategoryType = AutocompleteBase;

const cycleCountTypeOptions: CategoryType[] = [
  {
    value: 1,
    label: 'Item',
  },
  {
    value: 2,
    label: 'Location',
  },
];

enum DiscrepanciesEnum {
  QUANTITY = 1,
  SKU = 2,
  LOT = 3,
}

const CycleCountConfiguration = () => {
  const {
    setCycleCountDataDetailModal,
    cycleCountDataDetailModal,
    pageViewer,
  } = useCycleCountContext();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', margin: '22px' }}>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={cycleCountDataDetailModal.isBlindAccount}
                onChange={(e) => {
                  setCycleCountDataDetailModal((prev) => ({
                    ...prev,
                    isBlindAccount: e.target.checked,
                  }));
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography fontWeight="bold" variant="subtitle2">
                Blind count
              </Typography>
            }
            disabled={!pageViewer.isEdit()}
            labelPlacement="end"
          />
          <Typography variant="subtitle2" pt={-1} ml={4}>
            Users won&apos;t see the expected amount while counting
          </Typography>
        </MUIGrid>
        <MUIGrid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={cycleCountDataDetailModal.isFlagItemsLotDiscrepancy}
                onChange={(e) =>
                  setCycleCountDataDetailModal((prev) => ({
                    ...prev,
                    isFlagItemsLotDiscrepancy: e.target.checked,
                  }))
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography fontWeight="bold" variant="subtitle2">
                Flag items with lot discrepancies
              </Typography>
            }
            labelPlacement="end"
            disabled={!pageViewer.isEdit()}
          />
        </MUIGrid>
      </MUIGrid>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={cycleCountDataDetailModal.isRecountDiscrepancy}
                onChange={(e) =>
                  setCycleCountDataDetailModal((prev) => ({
                    ...prev,
                    isRecountDiscrepancy: e.target.checked,
                  }))
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography fontWeight="bold" variant="subtitle2">
                Recount discrepancies
              </Typography>
            }
            labelPlacement="end"
            disabled={!pageViewer.isEdit()}
          />
          <Typography variant="subtitle2" pt={-1} ml={4}>
            As soon as a user counts an item with unit discrepancies, Packem
            will flag this SKU as a recount, Additional count(s) will be
            completed until there are two consecutive counts with matching
            discrepancies for the count to be effective in the inventory.
          </Typography>
        </MUIGrid>
        <MUIGrid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={cycleCountDataDetailModal.isFlagForRecount}
                onChange={(e) =>
                  setCycleCountDataDetailModal((prev) => ({
                    ...prev,
                    isFlagForRecount: e.target.checked,
                  }))
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography fontWeight="bold" variant="subtitle2">
                Flag for recount items that were unexpectedly found in a
                location
              </Typography>
            }
            labelPlacement="end"
            disabled={!pageViewer.isEdit()}
          />
        </MUIGrid>
      </MUIGrid>
    </Box>
  );
};

const CycleCountBody = () => {
  const { currentUser } = useContext(AuthContext);

  const {
    cycleCountDataDetailModal,
    queryStringItemCycleCount,
    pageViewer,
    OnLoadCycleCountDetail,
    setCycleCountDataDetailModal,
    cycleCountDataDetailModalErrors,
  } = useCycleCountContext();

  const [optionWarehouseCustomers, setOptionsWarehouseCustomers] = useState<
    CustomerType[]
  >([]);
  const [itemsOption, setItemsOption] = useState<CustomerType[]>([]);

  const onLoadWarehouseCustomerLookup = async () => {
    try {
      return await getWarehouseCustomerLookup(currentUser.Claim_CustomerId, '');
    } catch (err) {
      return err;
    }
  };

  const onLoadUItemsByCustomerData = async () => {
    try {
      return await getItemsByCustomerId(currentUser.Claim_CustomerId);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    OnLoadCycleCountDetail(queryStringItemCycleCount.id);

    const optionsCustomer = onLoadWarehouseCustomerLookup();
    optionsCustomer
      .then((opt) => {
        setOptionsWarehouseCustomers(
          opt.map((c: any) => ({
            label: c.name,
            value: c.warehouseCustomerId,
          })),
        );
      })
      .catch((err) => console.log(err));

    const optionsItemsCustomer = onLoadUItemsByCustomerData();
    optionsItemsCustomer
      .then((opt) => {
        setItemsOption(
          opt.map((c: any) => ({
            label: c.sku,
            value: c.itemId,
          })),
        );
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="h6" fontWeight="bold" mb={2}>
        Details
      </Typography>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={2}>
          <Input
            placeholder="Cycle Count Name"
            size="small"
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            value={cycleCountDataDetailModal.cycleCountName}
            error={cycleCountDataDetailModalErrors.cycleCountName}
            disabled={!pageViewer.isEdit()}
            required
            onChange={(e) =>
              setCycleCountDataDetailModal((prev) => ({
                ...prev,
                cycleCountName: e.target.value,
              }))
            }
          />
        </MUIGrid>
        <MUIGrid item xs={2}>
          <Autocomplete
            disableClearable
            onChange={(event: any, newValue: CustomerType) => {
              if (newValue !== null) {
                setCycleCountDataDetailModal((prev) => ({
                  ...prev,
                  cycleCountTypeDropDown: {
                    value: newValue.value,
                    label: newValue.label,
                  },
                }));
              }
            }}
            id="controllable-states"
            options={cycleCountTypeOptions}
            getOptionLabel={(option: CustomerType) => option.label}
            value={cycleCountDataDetailModal.cycleCountTypeDropDown}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                {...(cycleCountDataDetailModalErrors.cycleCountTypeDropDown && {
                  error: true,
                  helperText:
                    cycleCountDataDetailModalErrors.cycleCountTypeDropDown,
                })}
                autoComplete="off"
                id="outlined-required"
                label="Type"
                size="small"
                sx={{ width: '100%' }}
              />
            )}
            disabled={!pageViewer.isEdit()}
          />
        </MUIGrid>
        <MUIGrid item xs={2}>
          <DatePickerInput
            label="Created Date"
            inputFormat="MM/dd/yyyy"
            value={
              cycleCountDataDetailModal.createdDateTime
                ? moment(cycleCountDataDetailModal.createdDateTime).format(
                    'MM/DD/yyyy',
                  )
                : cycleCountDataDetailModal.createdDateTime
            }
            renderInput={(params) => <TextField {...params} size="small" />}
            disabled
          />
        </MUIGrid>
        <MUIGrid item xs={2}>
          <DatePickerInput
            label="Due Date"
            inputFormat="MM/dd/yyyy"
            value={
              cycleCountDataDetailModal.dueDate
                ? moment(cycleCountDataDetailModal.dueDate).format('MM/DD/yyyy')
                : cycleCountDataDetailModal.dueDate
            }
            onChange={(e) =>
              setCycleCountDataDetailModal((prev) => ({
                ...prev,
                dueDate: e,
              }))
            }
            renderInput={(params) => (
              <TextField
                required
                {...params}
                error={cycleCountDataDetailModalErrors.dueDate}
                helperText={cycleCountDataDetailModalErrors.dueDate}
                size="small"
              />
            )}
            disabled={!pageViewer.isEdit()}
          />
        </MUIGrid>
        <MUIGrid item xs={2}>
          <Input
            placeholder="Created By"
            size="small"
            sx={{
              width: '100%',
              backgroundColor: '#ffffff !important',
            }}
            value={cycleCountDataDetailModal.createdBy}
            disabled
          />
        </MUIGrid>
      </MUIGrid>

      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={2}>
          <DatePickerInput
            label="Started Date"
            inputFormat="MM/dd/yyyy"
            value={
              cycleCountDataDetailModal.startedDateTime
                ? cycleCountDataDetailModal?.startedDateTime
                : null
            }
            renderInput={(params) => <TextField {...params} size="small" />}
            disabled
          />
        </MUIGrid>
        <MUIGrid item xs={2}>
          <DatePickerInput
            label="Last Activity"
            inputFormat="MM/dd/yyyy"
            value={
              cycleCountDataDetailModal.lastActivity
                ? cycleCountDataDetailModal.lastActivity
                : null
            }
            renderInput={(params) => <TextField {...params} size="small" />}
            disabled
          />
        </MUIGrid>
        <MUIGrid item xs={2}>
          <Autocomplete
            onChange={(event: any, newValue: CustomerType) => {
              if (newValue !== null) {
                setCycleCountDataDetailModal((prev) => ({
                  ...prev,
                  warehouseCustomer: {
                    value: newValue.value,
                    label: newValue.label,
                  },
                }));
              } else {
                setCycleCountDataDetailModal((prev) => ({
                  ...prev,
                  warehouseCustomer: null,
                }));
              }
            }}
            id="controllable-states"
            options={optionWarehouseCustomers}
            getOptionLabel={(option: CustomerType) => option.label}
            value={cycleCountDataDetailModal.warehouseCustomer}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                {...(cycleCountDataDetailModalErrors.warehouseCustomer && {
                  error: true,
                  helperText: cycleCountDataDetailModalErrors.warehouseCustomer,
                })}
                autoComplete="off"
                id="outlined-required"
                label="Client"
                size="small"
                sx={{ width: '100%' }}
              />
            )}
            disabled={!pageViewer.isEdit()}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          {cycleCountDataDetailModal.cycleCountTypeDropDown.value === 2 && (
            <Autocomplete
              disableClearable
              multiple
              size="small"
              id="checkboxes-tags-demo"
              options={binCategoryOptions}
              value={cycleCountDataDetailModal.locationType}
              disableCloseOnSelect
              getOptionLabel={(option: AutocompleteBase) => option.label}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              onChange={(event: any, newValue) => {
                if (newValue !== null) {
                  setCycleCountDataDetailModal((prev) => ({
                    ...prev,
                    locationType: newValue,
                  }));
                } else {
                  setCycleCountDataDetailModal((prev) => ({
                    ...prev,
                    locationType: [],
                  }));
                }
              }}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location Type"
                  {...(cycleCountDataDetailModalErrors.locationType && {
                    error: true,
                    helperText: cycleCountDataDetailModalErrors.locationType,
                  })}
                  placeholder="Location Type"
                />
              )}
              disabled={!pageViewer.isEdit()}
            />
          )}
          {cycleCountDataDetailModal.cycleCountTypeDropDown.value === 1 && (
            <Autocomplete
              disableClearable
              multiple
              size="small"
              id="checkboxes-tags-demo"
              options={itemsOption}
              value={cycleCountDataDetailModal.itemType}
              disableCloseOnSelect
              getOptionLabel={(option: AutocompleteBase) => option.label}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              onChange={(event: any, newValue) => {
                if (newValue !== null) {
                  setCycleCountDataDetailModal((prev) => ({
                    ...prev,
                    itemType: newValue,
                  }));
                } else {
                  setCycleCountDataDetailModal((prev) => ({
                    ...prev,
                    itemType: [],
                  }));
                }
              }}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="SKUs"
                  {...(cycleCountDataDetailModalErrors.itemType && {
                    error: true,
                    helperText: cycleCountDataDetailModalErrors.itemType,
                  })}
                  placeholder="SKUs"
                />
              )}
              disabled={!pageViewer.isEdit()}
            />
          )}
        </MUIGrid>
      </MUIGrid>
      <CycleCountConfiguration />
    </Card>
  );
};

const CycleCountListTable = () => {
  const theme = useTheme();

  const {
    setCycleCountUpdateCountDetailModal,
    cycleCountDataDetailModal,
    filteredCycleCountDetails,
    selectionModelId,
    setFilteredCycleCountDetails,
    setSelectionModelId,
    editRowsModel,
    handleEditSingleRowModelChange,
  } = useCycleCountContext();

  const { onOpenCycleCountDetailModal } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useState({
    searchItem: '',
    searchLot: '',
    searchexpirationDate: '',
    searchLicensePlate: '',
    searchArea: '',
    searchLocation: '',
    searchExpected: '',
    searchCounted: '',
    searchDiscrepancies: '',
    searchUser: '',
    searchAvailable: '',
    searchOnHand: '',
  });

  const onForm = (key, text) => {
    setSearchParams(() => ({
      ...searchParams,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    onForm(key, event.target.value);
  };

  const filterCycleCount = () => {
    const itemNameResult = cycleCountDataDetailModal.locationOrItemList.filter(
      (r) =>
        r.itemName
          .toString()
          .toLowerCase()
          .includes(searchParams.searchItem.toLowerCase()),
    );
    const lotResult = itemNameResult.filter((r) =>
      r.lot.toLowerCase().includes(searchParams.searchLot.toLowerCase()),
    );
    const areaResult = lotResult.filter((r) =>
      r.area.toLowerCase().includes(searchParams.searchArea.toLowerCase()),
    );
    const locationNameResult = areaResult.filter((r) =>
      r.locationName
        .toString()
        .toLowerCase()
        .includes(searchParams.searchLocation.toLowerCase()),
    );
    const expectedResult = locationNameResult.filter((r) =>
      r.expected
        .toString()
        .toLowerCase()
        .includes(searchParams.searchExpected.toLowerCase()),
    );
    const countedResult = expectedResult.filter((r) =>
      r.counted === null || r.counted === undefined || r.counted === ''
        ? r
        : r.counted
            .toString()
            .toLowerCase()
            .includes(searchParams.searchCounted.toLowerCase()),
    );
    if (Object.values(searchParams).every((x) => x === '')) {
      setFilteredCycleCountDetails(
        cycleCountDataDetailModal.locationOrItemList,
      );
    } else {
      setFilteredCycleCountDetails(countedResult);
    }
  };

  // const handleOnBlurCounted = async () => {
  //   // onBlurUpdateCountedCycleCount();
  //   console.log('display onblue');
  // };

  const handleOpenCycleCountDetailModal = (rowData) => {
    setCycleCountUpdateCountDetailModal((prev) => ({
      ...prev,
      item: rowData.item,
      location: rowData.location,
      expected: rowData.expected,
      counted: rowData.counted,
    }));
    // setCycleCountUpdateCountDetailModal(rowData);
    onOpenCycleCountDetailModal();
  };

  useEffect(() => {
    filterCycleCount();
  }, []);

  type CycleCountItemRows =
    typeof cycleCountDataDetailModal.locationOrItemList[number];

  const cycleCountItemColumns: GridColumns<CycleCountItemRows> = [
    {
      field: 'actions',
      type: 'actions',
      hideable: false,
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        // <GridActionsCellItem
        //   label="Count"
        //   onClick={() => handleOpenCycleCountDetailModal(params.row)}
        //   showInMenu
        // />,
        <GridActionsCellItem label="Remove" showInMenu />,
      ],
    },
    {
      field: 'itemName',
      width: 150,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>SKU</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchItem}
            onChange={(value) => inputHandler('searchItem', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterCycleCount();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.itemName}</Typography>,
    },
    {
      field: 'lot',
      width: 150,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Lot</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchLot}
            onChange={(value) => inputHandler('searchLot', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterCycleCount();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.lot}</Typography>,
    },
    {
      field: 'expirationDate',
      width: 150,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Expiration Date</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchexpirationDate}
            onChange={(value) => inputHandler('searchexpirationDate', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterCycleCount();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row.expirationDate
            ? moment(params.row.expirationDate).format('MM/DD/yyyy')
            : ''}
        </Typography>
      ),
    },
    {
      field: 'licensePlate',
      width: 150,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>License Plate</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchLicensePlate}
            onChange={(value) => inputHandler('searchLicensePlate', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterCycleCount();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.licensePlate}</Typography>
      ),
    },
    {
      field: 'locationName',
      width: 150,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Location</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchLocation}
            onChange={(value) => inputHandler('searchLocation', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterCycleCount();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.locationName}</Typography>
      ),
    },
    {
      field: 'onHand',
      width: 150,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>On Hand</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchOnHand}
            onChange={(value) => inputHandler('searchOnHand', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterCycleCount();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row.onHand ? params.row.onHand : params.row.counted}
        </Typography>
      ),
    },
    {
      field: 'available',
      width: 150,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Available</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchAvailable}
            onChange={(value) => inputHandler('searchAvailable', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterCycleCount();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.available}</Typography>,
    },
    {
      field: 'counted',
      width: 150,
      editable: true,
      type: 'number',
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Counted</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchCounted}
            onChange={(value) => inputHandler('searchCounted', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filterCycleCount();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.counted}</Typography>
        // <TextField
        //   sx={{ border: 'none' }}
        //   value={params.row.counted}
        //   onBlur={() => handleOnBlurCounted}
        // />
      ),
    },
    {
      field: 'cycleCountDiscrepancies',
      width: 250,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Discrepancies</Typography>
          <Input
            sx={{ maxWidth: '140px' }}
            placeholder="Search"
            value={searchParams.searchDiscrepancies}
            onChange={(value) => inputHandler('searchDiscrepancies', value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // filterInventory();
              }
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Typography
          sx={{
            cursor: 'pointer',
            display: 'flex',
          }}
        >
          {params.value
            ? params.value.map((row, index) => (
                <Chip
                  label={DiscrepanciesEnum[row]}
                  size="small"
                  className="redChipDiscrepancy"
                />
              ))
            : params.value}
        </Typography>
      ),
    },
  ];

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <Box>
        <Typography variant="h6" fontWeight="bold" mb={2}>
          Inventory
        </Typography>
        <Grid
          autoHeight
          headerHeight={120}
          pageSize={25}
          selectionModel={selectionModelId}
          rows={filteredCycleCountDetails}
          columns={cycleCountItemColumns}
          editRowsModel={editRowsModel}
          onEditRowsModelChange={handleEditSingleRowModelChange}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = filteredCycleCountDetails.filter((row) =>
              selectedIDs.has(row.countId),
            );
            setSelectionModelId(selectedRowData?.map((r) => r.countId));
          }}
          density="compact"
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          rowsPerPageOptions={[50]}
          getRowId={(row) => row.countId}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Card>
  );
};

const CycleCountContainer = () => {
  const query = useQuery();

  return (
    <>
      <CycleCountBody />
      <CycleCountListTable />
    </>
  );
};

const CycleCountDetail = () => (
  <MainContainer>
    <CycleCountContextProvider>
      <Header />
      <ContentContainer>
        <Cyclecountmodal />
        <ConfirmEndCycleCountModal />
        <CycleCountContainer />
      </ContentContainer>
    </CycleCountContextProvider>
  </MainContainer>
);

export default React.memo(CycleCountDetail);
