import React, { useEffect, useContext, useState } from 'react';

import Button from 'components/button';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  TextField,
  Divider,
  Grid as MUIGrid,
  Autocomplete,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

import { autoCompleteTimeStamp } from '../../../../helpers/autocompletetimestamp';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;
    width: 100%;
    max-width: 400px;
    border-radius: 4px;
  }
`;

type AutocompleteBase = {
  label?: string;
  value?: number;
};
type CarrierIntegrationType = AutocompleteBase;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;

const initialFormErrorsState: any = {
  carrierIntegrationType: '',
};

export default React.memo(() => {
  const { isCarrierIntegrationModalOpen, onCloseCarrierIntegrationModal } =
    useContext(GlobalContext);

  const { handleOnCarrierIntegrationType } = useWarehouseCustomerContext();

  const [formErrors, setFormErrors] = useState<any>(initialFormErrorsState);
  const [carrierIntegrationTypeValue, setCarrierIntegrationTypeValue] =
    useState<CarrierIntegrationType>(null);
  const [optioncarrierIntegrationTypes, setOptioncarrierIntegrationTypes] =
    useState<CarrierIntegrationType[] | null | []>([]);

  useEffect(() => {
    const addOptions = [
      { value: 1, label: 'Shippo' },
      { value: 2, label: 'EasyPost' },
    ];
    setOptioncarrierIntegrationTypes(addOptions);
  }, [isCarrierIntegrationModalOpen]);

  return (
    <Modal open={isCarrierIntegrationModalOpen}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Carrier Integration
          </Typography>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12}>
                <Autocomplete
                  onChange={(event: any, newValue: CarrierIntegrationType) => {
                    setCarrierIntegrationTypeValue(newValue);
                  }}
                  id="controllable-states"
                  options={optioncarrierIntegrationTypes}
                  getOptionLabel={(option: CarrierIntegrationType) =>
                    option.label
                  }
                  value={carrierIntegrationTypeValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li
                      data-name="carrierIntegrationType"
                      {...props}
                      key={option.value}
                    >
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      {...(formErrors.carrierIntegrationType && {
                        error: true,
                        helperText: formErrors.carrierIntegrationType,
                      })}
                      id="outlined-required"
                      label="Integration Type"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => {
                      setCarrierIntegrationTypeValue(null);
                      setOptioncarrierIntegrationTypes([]);
                      setFormErrors((temp) => ({
                        ...temp,
                        carrierIntegrationType: '',
                      }));
                      onCloseCarrierIntegrationModal();
                    }}
                    variant="outlined"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                    onClick={(e) => {
                      e.preventDefault();

                      const fieldValues = carrierIntegrationTypeValue;
                      const temp: any = {};
                      temp.carrierIntegrationType =
                        fieldValues !== null ? '' : 'This field is required';

                      setFormErrors({
                        ...temp,
                      });
                      const bool = Object.values(temp).every((x) => x === '');

                      if (bool) {
                        handleOnCarrierIntegrationType(
                          carrierIntegrationTypeValue.label,
                        );

                        setCarrierIntegrationTypeValue(null);
                        onCloseCarrierIntegrationModal();
                      }
                    }}
                  >
                    SELECT
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
