import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { Grid } from 'components/styles';
import { getDashboardTopSalesOrders } from 'services/api/dashboard/dashboard.api';
import { AuthContext } from 'store/contexts/AuthContext';

import { Box, Card, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridColumns } from '@mui/x-data-grid';

function DashboardTopCustomer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentLocationAndFacility } = useContext(AuthContext);
  const [topSalesRange, setTopSalesRange] = React.useState(1);
  const [topSalesData, setTopSalesData] = React.useState([]);

  const fecthTopSales = async (filter: number) => {
    try {
      const result = await getDashboardTopSalesOrders(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
        filter,
      );
      setTopSalesData(result);
      return true;
    } catch (err: any) {
      return err;
    }
  };

  const handleTopSalesRangeChange = async (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setTopSalesRange(newValue);
    fecthTopSales(newValue);
  };

  useEffect(() => {
    fecthTopSales(topSalesRange);
  }, [currentLocationAndFacility]);

  type ToSellersRow = typeof topSalesData[number];

  const topSellersColumns: GridColumns<ToSellersRow> = [
    {
      field: 'customer',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Client</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.customer}</Typography>,
    },
    {
      field: 'totalQty',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>Total Qty</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.totalQty}</Typography>,
    },
    {
      field: 'skUs',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>SKUs</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography
          sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
        >
          {params.row.skUs}
        </Typography>
      ),
    },
    {
      field: 'dollarSpent',
      minWidth: 125,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>$ Spent</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.dollarSpent}</Typography>,
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '450px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <Typography sx={{ width: '40%' }} variant="h6" fontWeight="bold">
          Top Clients
        </Typography>
        <Box
          sx={{
            display: 'flex',
            width: '40%',
            alignItems: 'center',
          }}
        >
          <Tabs
            value={topSalesRange}
            onChange={handleTopSalesRangeChange}
            centered
          >
            <Tab sx={{ minWidth: '50px' }} value={1} label="YTD" />
            <Tab sx={{ minWidth: '50px' }} value={2} label="MTD" />
            <Tab sx={{ minWidth: '50px' }} value={3} label="WTD" />
          </Tabs>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '20%',
            justifyContent: 'end',
          }}
        >
          <Typography
            sx={{
              cursor: 'pointer',
              color: [theme.palette.primary.main],
            }}
            fontWeight="bold"
            onClick={() => {
              navigate('/orders');
            }}
          >
            View All
          </Typography>
        </Box>
      </Box>
      <Grid
        headerHeight={120}
        sx={{
          '& .MuiDataGrid-cell': {
            borderBottomColor: '#F0F0F0',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: 'none',
          },
          '& .MuiTypography-body1': {
            fontWeight: 'bold',
          },
        }}
        rows={topSalesData}
        columns={topSellersColumns}
        pageSize={10}
        density="compact"
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        rowsPerPageOptions={[10]}
        getRowId={(row) => row.customer}
        componentsProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  );
}

export default React.memo(DashboardTopCustomer);
