import React, { useContext, useState, ChangeEvent, useEffect } from 'react';

import Button from 'components/button';
import Input from 'components/input';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import {
  createCustomerFacilityZone,
  editCustomerFacilityZone,
  getBillingType,
} from 'services/api/customerfacilities/customerfacilities.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import * as yup from 'yup';

import { Modal, Box, Typography, TextField, Autocomplete } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface FacilityZoneModalProps {
  zoneData?: any;
  edit?: boolean;
  callBack?: () => void;
  billTypeCallBack?: (cb) => void;
}

export default React.memo(
  ({ zoneData, edit, callBack, billTypeCallBack }: FacilityZoneModalProps) => {
    const theme = useTheme();
    const {
      isFacilityZoneModalOpen,
      onCloseFacilityZoneModal,
      handleUpdateData,
      onOpenBillTypeWarningModal,
    } = useContext(GlobalContext);
    const { currentLocationAndFacility } = useContext(AuthContext);

    const initialState = {
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      name: '',
      zoneId: '',
      billingType: null,
    };

    const initialFormErrorState: any = {
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      name: '',
      zoneId: '',
    };
    const [formErrors, setFormErrors] = useState<any>([]);
    const [form, setForm] = useState(initialState);
    const [optionBillingType, setOptionBillingType] = useState<any>([]);
    const [billTypeValue, setBillTypeValue] = useState<any>(null);
    const onForm = (key, text, autoComplete = null) => {
      setForm(() => ({
        ...form,
        [key]: autoComplete !== null ? autoComplete : text,
      }));
    };

    const inputHandler = (
      key: string,
      event?: ChangeEvent<HTMLInputElement>,
      autoComplete?: any,
    ) => onForm(key, event.target.value, autoComplete);

    const schema = yup.object().shape({
      customerLocationId: yup.string().required('Required.'),
      customerFacilityId: yup.string().required('Required'),
      name: yup.string().required('Required.'),
    });

    const handleCloseFacilityZoneModal = () => {
      setForm(initialState);
      setBillTypeValue(null);
      setForm((prevState) => ({
        ...prevState,
        billingType: null,
      }));
      onCloseFacilityZoneModal();
      if (callBack !== undefined) {
        callBack();
      }
    };

    const onSaveNewFacilityZone = async () => {
      form.customerLocationId = currentLocationAndFacility.customerLocationId;
      form.customerFacilityId = currentLocationAndFacility.customerFacilityId;
      schema
        .validate(form, {
          abortEarly: false,
        })
        .then(async () => {
          try {
            if (edit) {
              form.zoneId = zoneData.zoneId;
              form.billingType =
                billTypeValue === null && form.billingType !== undefined
                  ? null
                  : form.billingType;
              const editZone = await editCustomerFacilityZone(
                zoneData.zoneId,
                form,
              );
              snackActions.success(`Successfully edited ${editZone.name}.`);
            } else {
              form.billingType =
                billTypeValue === null && form.billingType !== undefined
                  ? null
                  : form.billingType;
              const newZone = await createCustomerFacilityZone(form);
              snackActions.success(`Successfully created new zone.`);
            }
            setForm(initialState);
            handleCloseFacilityZoneModal();
            handleUpdateData();
          } catch (error) {
            setFormErrors(initialFormErrorState);
            snackActions.error(`${error}`);
          }
        })
        .catch((err) => {
          const errorsFound = err.inner.reduce((acc, curr) => {
            if (!acc[curr.path]) acc[curr.path] = curr.message;
            return acc;
          }, {});
          setFormErrors(errorsFound);
        });
    };

    const onLoadBillingTypeLookup = async () => {
      try {
        const countriesLookupFromApi = await getBillingType();
        return countriesLookupFromApi;
      } catch (err) {
        return err;
      }
    };

    useEffect(() => {
      const optBillingType = onLoadBillingTypeLookup();
      optBillingType.then((opt: any) => {
        setOptionBillingType(
          opt.map((c: any) => ({
            label: c.label,
            value: c.value,
          })),
        );
      });
    }, []);
    useEffect(() => {
      if (edit) {
        setForm(zoneData);
        const billing = (el: any) => el.value === zoneData.billingType;

        const vmBilling = optionBillingType.find(billing);
        if (vmBilling !== undefined) {
          setBillTypeValue(vmBilling);
        } else {
          setBillTypeValue(null);
        }
      }
    }, [edit]);

    if (!isFacilityZoneModalOpen) return null;

    return (
      <Modal
        open={isFacilityZoneModalOpen}
        onClose={() => handleCloseFacilityZoneModal()}
      >
        <ModalBox>
          <ModalContent>
            {edit ? (
              <Typography
                sx={{ marginBottom: '16px' }}
                variant="h6"
                fontWeight="bold"
              >
                Edit Area
              </Typography>
            ) : (
              <Typography
                sx={{ marginBottom: '16px' }}
                variant="h6"
                fontWeight="bold"
              >
                Add Area
                {/* <br /> ({currentLocationAndFacility.locationName} •{' '}
                {currentLocationAndFacility.facilityName}) */}
              </Typography>
            )}

            <Input
              sx={{ width: '100%' }}
              placeholder="Area Name"
              value={form.name}
              onChange={(value) => inputHandler('name', value)}
            />
            <Autocomplete
              onChange={(event: any, newValue: any) => {
                if (newValue !== null) {
                  setBillTypeValue(newValue);
                  inputHandler('billingType', event, newValue.value);
                  console.log(newValue);
                } else {
                  setBillTypeValue(null);
                  inputHandler('billingType', event, null);
                }
              }}
              id="controllable-states"
              options={optionBillingType}
              getOptionLabel={(option: any) => option.label}
              value={billTypeValue}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Billing Type"
                  size="small"
                  sx={{ width: '100%' }}
                />
              )}
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                  justifyContent: 'flex-end',
                },
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'center',
                },
                gap: '8px',
              }}
            >
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="text"
                size="large"
                onClick={() => handleCloseFacilityZoneModal()}
              >
                Cancel
              </Button>
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="contained"
                size="large"
                onClick={() => {
                  if (
                    billTypeValue === null ||
                    form.billingType === undefined
                  ) {
                    onSaveNewFacilityZone();
                  } else {
                    if (edit) {
                      onOpenBillTypeWarningModal();
                      onCloseFacilityZoneModal();
                      billTypeCallBack({
                        onSaveNewFacilityZone,
                        handleCloseFacilityZoneModal,
                      }); // event bus
                    } else {
                      onSaveNewFacilityZone();
                    }
                    console.log('end-block');
                  }
                  console.log(form);
                }}
              >
                {edit ? <text>Edit</text> : <text>ADD</text>}
              </Button>
            </Box>
          </ModalContent>
        </ModalBox>
      </Modal>
    );
  },
);
