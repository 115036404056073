import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const createLicensePlateUnknown = async (data) => {
  try {
    const response = await httpClient.post(
      `/LicensePlate/Create/Unknown`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createLicensePlateKnown = async (data) => {
  try {
    const response = await httpClient.post(`/LicensePlate/Create/Known`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getGenerateLicensePlateNo = async (customerId) => {
  try {
    const response = await httpClient.get(
      `/LicensePlate/Generate/${customerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const generateLicensePlateUnknown = async (data) => {
  try {
    const response = await httpClient.post(
      `/LicensePlate/Generate/Unknown`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getLicensePlateLookupByLicensePlateNo = async (
  customerId,
  searchText,
) => {
  try {
    const response = await httpClient.get(
      `/LicensePlate/Lookup/LPNo/${customerId}?searchText=${searchText}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getLicensePlateKnownAssignment = async (
  customerId,
  licensePlateId,
) => {
  try {
    const response = await httpClient.get(
      `/LicensePlate/Assignment/${customerId}/${licensePlateId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getLicensePlateHistory = async (customerId) => {
  try {
    const response = await httpClient.get(
      `/LicensePlate/History/${customerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const editLicensePlateKnownAssignment = async (editForm) => {
  try {
    const response = await httpClient.post(
      `/LicensePlate/Edit/Known`,
      editForm,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getLicensePlatesForBatchPrint = async (from, to) => {
  try {
    const response = await httpClient.get(`/LicensePlate/print/${from}/${to}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
