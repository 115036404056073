import React, { useContext, useEffect, useState } from 'react';

import { IReportOrderStatus } from 'common/interfaces';
import DatePickerInput from 'components/datepicker';
import Header from 'components/header';
import { MainContainer, ContentContainer } from 'pages/styles';
import { getReportOrderStatus } from 'services/api/reports/reports.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { DatagridStandard } from 'styles';

import { Box, Tab, Tabs, TextField } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';

const OrderStatusReportViewer = () => {
  const [filter, setFilter] = useState(1);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [rows, setRows] = useState<IReportOrderStatus[]>([]);

  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);

  const purchaseOrdercolumns: GridColumns<any[]> = [
    {
      field: 'ctr',
      width: 50,
      headerName: '#',
      filterable: false,
      renderCell: (cell: any) => cell.api.getRowIndex(cell.row.id) + 1,
    },
    {
      field: 'customer',
      width: 200,
      headerName: 'Client',
    },
    {
      field: 'open',
      width: 120,
      headerName: 'Open',
    },
    {
      field: 'picked',
      width: 120,
      headerName: 'Picked',
    },
    {
      field: 'packed',
      width: 120,
      headerName: 'Packed',
    },
    {
      field: 'hold',
      width: 120,
      headerName: 'Hold',
    },
    {
      field: 'shipped',
      width: 120,
      headerName: 'Shipped',
    },
    {
      field: 'total',
      width: 120,
      headerName: 'Total',
    },
  ];

  const fetchData = async (day: number, from: Date, to: Date) => {
    try {
      const result = await getReportOrderStatus({
        customerId: currentUser.Claim_CustomerId,
        customerLocationId: Number(
          currentLocationAndFacility.customerLocationId,
        ),
        customerFacilityId: Number(
          currentLocationAndFacility.customerFacilityId,
        ),
        dayFilter: day,
        dateFrom: from,
        dateTo: to,
      });
      setRows(result as IReportOrderStatus[]);
      return true;
    } catch (err: any) {
      return err;
    }
  };

  const handleFilterChange = async (
    e: React.SyntheticEvent,
    newValue: number,
  ) => {
    setFilter(newValue);
  };

  const onDatePickerChange = async (date: Date, source: string) => {
    if (source === 'from') {
      setDateFrom(date);
    } else {
      setDateTo(date);
    }
  };

  useEffect(() => {
    fetchData(filter, dateFrom, dateTo);
  }, [currentUser, currentLocationAndFacility, dateFrom, dateTo, filter]);

  return (
    <MainContainer>
      <Header />
      <ContentContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            background: '#ffff',
            p: 2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tabs value={filter} onChange={handleFilterChange} centered>
                <Tab
                  sx={{ minWidth: '50px', padding: 1, fontSize: '0.8rem' }}
                  value={1}
                  label="Today"
                />
                <Tab
                  sx={{ minWidth: '50px', padding: 1, fontSize: '0.8rem' }}
                  value={3}
                  label="WTD"
                />
                <Tab
                  sx={{ minWidth: '50px', padding: 1, fontSize: '0.8rem' }}
                  value={4}
                  label="MTD"
                />
                <Tab
                  sx={{ minWidth: '50px', padding: 1, fontSize: '0.8rem' }}
                  value={5}
                  label="YTD"
                />
                <Tab
                  sx={{ minWidth: '50px', padding: 1, fontSize: '0.8rem' }}
                  value={6}
                  label="Custom"
                />
              </Tabs>
            </Box>
            {filter === 6 && (
              <Box sx={{ display: 'flex' }}>
                <DatePickerInput
                  label="From"
                  inputFormat="MM/dd/yyyy"
                  value={dateFrom}
                  onChange={(val: Date) => onDatePickerChange(val, 'from')}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      error={false}
                      size="small"
                      style={{ fontSize: 12, width: 140 }}
                    />
                  )}
                />
                <DatePickerInput
                  label="To"
                  inputFormat="MM/dd/yyyy"
                  value={dateTo}
                  onChange={(val: Date) => onDatePickerChange(val, 'to')}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      error={false}
                      size="small"
                      style={{ fontSize: 12, width: 140, marginLeft: 5 }}
                    />
                  )}
                />
              </Box>
            )}
          </Box>
          <DatagridStandard
            autoHeight
            headerHeight={30}
            rows={rows}
            columns={purchaseOrdercolumns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            getRowId={(row) => row.id}
          />
        </Box>
      </ContentContainer>
    </MainContainer>
  );
};

export default React.memo(OrderStatusReportViewer);
