import { IReportFilter } from 'common/interfaces';
import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getReportOrderStatus = async (payload: IReportFilter) => {
  try {
    const response = await httpClient.post('/Reports/OrderStatus', payload);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
