import React from 'react';

import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import InvoiceDetailForm from 'pages/shared/invoicedetailform';
import { useInvoiceDetailFormContext } from 'pages/shared/invoicedetailform/context';

import {
  Modal,
  Box,
  Typography,
  Button,
  Grid as MUIGrid,
  CircularProgress,
} from '@mui/material';

import {
  InvoiceModalStateEnum,
  useInvoiceInterfaceTabContext,
} from '../../context';

function InvoiceDetailFormModal() {
  const { state, closeInvoiceDetailFormModal, saveInvoiceDetailFormModal } =
    useInvoiceInterfaceTabContext();

  const { state: form } = useInvoiceDetailFormContext();

  return (
    <Modal open={state.vm.invoiceDetailFormModal !== null}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Invoice Detail
        </Typography>

        <MUIContent>
          <Box autoComplete="off" component="form" noValidate>
            <InvoiceDetailForm />
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              {form?.vm?.form?.modalState === InvoiceModalStateEnum.Edit && (
                <Button
                  onClick={() => {
                    closeInvoiceDetailFormModal();
                  }}
                  disabled={state.isBusy}
                  variant="outlined"
                  size="large"
                  sx={{ padding: 1, width: '100%' }}
                >
                  CANCEL
                </Button>
              )}

              {!state.isBusy && (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={() => {
                    saveInvoiceDetailFormModal();
                  }}
                >
                  {form?.vm?.form?.modalState === InvoiceModalStateEnum.Edit
                    ? 'Save'
                    : 'Done'}
                </Button>
              )}
              {state.isBusy && (
                <Button
                  disabled
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                    color: 'white !important',
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: 'white !important',
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                  <Box sx={{ paddingLeft: '5px' }}>Saving</Box>
                </Button>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
}

export default React.memo(InvoiceDetailFormModal);
