import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import { IntegrationTypeEnum } from 'common/enums/enum-carrier-integration-types';
import Card from 'components/card';
import AddNewCarrierModal from 'pages/settings/tabviews/modals/carriers/add-new-carrier-modal';
import CarrierAccountCustomerDeleteModal from 'pages/settings/tabviews/modals/carriers/carrieraccountcustomerdeletemodal';
import CarrierAccountCustomerModal from 'pages/settings/tabviews/modals/carriers/carrieraccountcustomermodal';
import { getCarrierAccountByCustomer } from 'services/api/integrations/easypost/easypost.api';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridStandard } from 'styles';

import { Typography, Box, Grid as MUIGrid, Button, Link } from '@mui/material';
import {
  GridColumns,
  GridToolbar,
  GridActionsCellItem,
} from '@mui/x-data-grid';

function CarriersSection() {
  const initialSearchState = {
    carrierName: '',
    accountId: '',
  };
  const [searchParams, setSearchParams] = useState(initialSearchState);
  const [filteredCarriers, setFilteredCarriers] = useState([]);
  const [listCarriers, setListCarriers] = useState([]);

  const {
    updateData,
    isLoadCarrierTab,
    setCarrierCustomId,
    setIsLoadCarrierTab,
    setIsAddNewCarrierModalOpen,
    isCarrierAccountCustomerModalOpen,
    onOpenCarrierAccountCustomerModal,
    isCarrierAccountCustomerDeleteModalOpen,
    onOpenCarrierAccountCustomerDeleteModal,
  } = useContext(GlobalContext);

  const [carrierData, setCarrierData] = useState(undefined);

  const onForm = (key, text) => {
    setSearchParams(() => ({
      ...searchParams,
      [key]: text,
    }));
  };

  useEffect(() => {
    if (
      (!isCarrierAccountCustomerModalOpen &&
        !isCarrierAccountCustomerDeleteModalOpen) ||
      isLoadCarrierTab
    ) {
      getCarrierAccountByCustomer().then((data) => {
        setFilteredCarriers(data);
        setListCarriers(data);
      });

      setCarrierData(null);
      setIsLoadCarrierTab(false);
    }
  }, [
    isCarrierAccountCustomerModalOpen,
    isCarrierAccountCustomerDeleteModalOpen,
    isLoadCarrierTab,
  ]);

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    onForm(key, event.target.value);
  };

  const filterArray = (gridValue, filterValue) => {
    const search = gridValue !== undefined ? gridValue : '';

    return search.toLowerCase().includes(filterValue.toLowerCase());
  };

  const filterCarrier = () => {
    const carrierNameResult = listCarriers.filter((c) =>
      filterArray(c.carrierName, searchParams.carrierName),
    );

    const accountIdResult = carrierNameResult.filter((c) =>
      filterArray(c.accountId, searchParams.accountId),
    );

    setFilteredCarriers(accountIdResult);
  };

  useEffect(() => {
    filterCarrier();
  }, [searchParams]);

  const viewDetails = (data) => {
    // eslint-disable-next-line no-param-reassign
    data.viewDetails = true;
    setCarrierData(data);
    onOpenCarrierAccountCustomerModal();
  };

  const editCarrier = (data) => {
    if (data?.integrationType === IntegrationTypeEnum.CustomCarrier) {
      setIsAddNewCarrierModalOpen(true);
      setCarrierCustomId(data.carrierId);
    } else {
      setCarrierData(data);
      onOpenCarrierAccountCustomerModal();
    }
  };

  const deleteCarrier = (data) => {
    setCarrierData(data);
    onOpenCarrierAccountCustomerDeleteModal();
  };

  const cartonColumns: GridColumns<any> = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => editCarrier(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => deleteCarrier(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'carrierName',
      minWidth: 300,
      flex: 1,
      headerName: 'Carrier',
      renderCell: (params) => (
        // eslint-disable-next-line react/jsx-no-undef, jsx-a11y/anchor-is-valid
        <Button variant="text" onClick={() => editCarrier(params.row)}>
          <Typography sx={{ cursor: 'pointer', color: '#1C9DCC' }}>
            {params.row.carrierName}
          </Typography>
        </Button>
      ),
    },
    {
      field: 'accountId',
      minWidth: 250,
      flex: 1,
      headerName: 'AccountId',
      renderCell: (params) => <Typography>{params.row.accountId}</Typography>,
    },
  ];

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
      <MUIGrid
        container
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: 1, alignItems: 'center' }}
      >
        <MUIGrid item xs={8}>
          <Typography variant="h6">
            <Box fontWeight="bold">Carriers</Box>
          </Typography>
          <Typography variant="subtitle1">
            <Box>Manage which carriers is available for order.</Box>
          </Typography>
        </MUIGrid>
        <MUIGrid
          item
          xs={4}
          sx={{ justifyContent: 'flex-end', display: 'flex' }}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{ marginRight: '10px' }}
            onClick={() => {
              setIsAddNewCarrierModalOpen(true);
            }}
          >
            Add New Carrier
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              onOpenCarrierAccountCustomerModal();
            }}
          >
            Connect Carrier
          </Button>
        </MUIGrid>
      </MUIGrid>
      <MUIGrid container>
        <MUIGrid item xs={12}>
          <DatagridStandard
            autoHeight
            headerHeight={120}
            rows={filteredCarriers}
            density="compact"
            columns={cartonColumns}
            hideFooterSelectedRowCount
            pageSize={15}
            rowsPerPageOptions={[15]}
            getRowId={(row) => row.carrierId}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                title: 'Carriers',
                printOptions: { disableToolbarButton: true },
                showQuickFilter: false,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </MUIGrid>
      </MUIGrid>
      <AddNewCarrierModal />
      <CarrierAccountCustomerModal carrierData={carrierData} />
      <CarrierAccountCustomerDeleteModal carrierData={carrierData} />
    </Card>
  );
}

export default React.memo(CarriersSection);
