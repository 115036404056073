import React, { useContext } from 'react';

import Button from 'components/button';
import Card from 'components/card';
import { useProductionContext } from 'pages/production/context';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Divider,
  Grid as MUIGrid,
  Autocomplete,
  TextField,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { DataGrid, GridColumns, GridEditRowsModel } from '@mui/x-data-grid';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 1000px;
    border-radius: 4px;
  }
`;
export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 24px;
    gap: 16px;
  }
`;

const PickTicketItems = () => {
  const { productionPickTicketItemModalRows, isPickTicketItemEdit } =
    useProductionContext();

  const [editRowsModel, setEditRowsModel] = React.useState({});

  const pickTicketItemsColumns: GridColumns = [
    {
      field: 'sku',
      width: 200,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">SKU</Typography>
        </Box>
      ),
    },
    {
      field: 'description',
      width: 200,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Description</Typography>
        </Box>
      ),
    },
    {
      field: 'uomStr',
      width: 120,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">UOM</Typography>
        </Box>
      ),
    },
    {
      field: 'lotNo',
      width: 120,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Lot No.</Typography>
        </Box>
      ),
    },
    {
      field: 'quantity',
      width: 160,
      type: 'number',
      editable: true,
      valueFormatter: ({ value }) => (value < 0 ? 0 : value),
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Quantity</Typography>
        </Box>
      ),
    },
  ];

  const handleEditSingleRowModelChange = React.useCallback(
    (model: GridEditRowsModel) => {
      setEditRowsModel(model);

      const data = [...productionPickTicketItemModalRows];
      let newValue = data;

      if (isPickTicketItemEdit) {
        newValue = data.filter(
          (row) => row.pickTicketLineItemId === Number(Object.keys(model)[0]),
        );
      } else {
        newValue = data.filter(
          (row) => row.itemKitComponentId === Number(Object.keys(model)[0]),
        );
      }

      newValue[0].quantity = Object.values(model)[0].quantity.value;

      const filterItemFromArray = data.filter(
        (item) => item.itemKitComponentId !== Number(Object.keys(model)[0]),
      );
    },
    [editRowsModel],
  );

  return (
    <Card
      sx={{
        boxShadow: 'none !important',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          boxShadow: 'none !important',
          width: '100%',
        }}
      >
        <DataGrid
          autoHeight
          headerHeight={120}
          rows={productionPickTicketItemModalRows}
          density="compact"
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          disableSelectionOnClick
          columns={pickTicketItemsColumns}
          rowsPerPageOptions={[25]}
          pageSize={25}
          editRowsModel={editRowsModel}
          onEditRowsModelChange={handleEditSingleRowModelChange}
          getRowId={(row) =>
            row.itemKitComponentId === null ||
            row.itemKitComponentId === undefined
              ? row.pickTicketLineItemId
              : row.itemKitComponentId
          }
          sx={{
            borderColor: 'transparent',
            '& .MuiDataGrid-columnSeparator--sideRight': {
              display: 'none !important',
            },
          }}
        />
      </Box>
    </Card>
  );
};

export default React.memo(() => {
  const { isPickTicketSalesOrderModalOpen, onClosePickTicketSalesOrderModal } =
    useContext(GlobalContext);
  const {
    OnSavePostPickTicketLineItemListForNewPickTicket,
    isPickTicketItemEdit,
    optionOperator,
    operatorValue,
    setOperatorValue,
  } = useProductionContext();

  return (
    <Modal open={isPickTicketSalesOrderModalOpen}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Pick Ticket
          </Typography>
          <Typography variant="subtitle1">
            {isPickTicketItemEdit ? (
              <b>
                Update a pick ticket with the available inventory for the
                following item?
              </b>
            ) : (
              <b>
                Create a pick ticket with the available inventory for the
                following item?
              </b>
            )}
          </Typography>
          <MUIGrid item xs={2} width="300px">
            <Autocomplete
              sx={{ width: '100%' }}
              options={optionOperator}
              value={operatorValue}
              size="small"
              onChange={(event: any, newValue) => {
                if (newValue === null || newValue === undefined) {
                  setOperatorValue(null);
                } else {
                  setOperatorValue((prevState) => ({
                    ...prevState,
                    ...newValue,
                  }));
                }
              }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    '& .MuiInputBase-root': {
                      background: 'white',
                    },
                  }}
                  {...params}
                  label="Operator"
                />
              )}
            />
          </MUIGrid>
          <Box autoComplete="off" component="form">
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={12}>
                <PickTicketItems />
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={10}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => onClosePickTicketSalesOrderModal()}
                    variant="outlined"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>

                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      padding: 1,
                      width: '100%',
                    }}
                    onClick={(e) => {
                      OnSavePostPickTicketLineItemListForNewPickTicket(e);
                      onClosePickTicketSalesOrderModal();
                    }}
                  >
                    {isPickTicketItemEdit ? (
                      <text>UPDATE TICKET AND CHANGE STATUS</text>
                    ) : (
                      <text>CREATE TICKET AND CHANGE STATUS</text>
                    )}
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
