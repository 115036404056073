import { useContext, useState } from 'react';

import { DeletePutAwayRequest } from 'common/models/putaways/DeletePutAway';
import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { DeletePutAway } from 'services/api/putaway/putaway.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Button,
  Grid as MUIGrid,
  CircularProgress,
} from '@mui/material';

import { usePutAwayContext } from '../context';

export const PutAwayDeleteModal = () => {
  const { getPutAwayIdsToDelete, viewLoadData } = usePutAwayContext();

  const { isPutawayModalOpen, setIsPutawayModalOpen } =
    useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const handleCloseOnClick = () => {
    setIsPutawayModalOpen(false);
    setLoading(false);
  };
  const onClickDeletePutAway = async (e) => {
    e.preventDefault();
    try {
      const payload: DeletePutAwayRequest = {
        putAwayIds: getPutAwayIdsToDelete,
      };
      await DeletePutAway(payload).then(() => {
        viewLoadData();
        setLoading(false);
        snackActions.success('Successfully deleted put away.');
        handleCloseOnClick();
      });
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };

  return (
    <Modal open={isPutawayModalOpen}>
      <MUIContainer sx={{ width: '20% !important' }}>
        <Typography
          className="modalTextHeaderError"
          variant="h6"
          fontWeight="bold"
        >
          Delete Put Away
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Box autoComplete="off" component="form" noValidate>
            <Typography variant="body1">
              Are you sure? Delete this task if you have already put away the
              items.
            </Typography>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={loading ? 10 : 8}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => handleCloseOnClick()}
                disabled={loading}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                CANCEL
              </Button>

              {!loading && (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => {
                    setLoading(true);
                    onClickDeletePutAway(e);
                  }}
                >
                  SUBMIT
                </Button>
              )}
              {loading && (
                <Button
                  disabled
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                  }}
                >
                  <CircularProgress
                    sx={{
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                  <Box sx={{ paddingLeft: '5px' }}>Deleting</Box>
                </Button>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
};
