import React, { useContext } from 'react';

import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Typography, Grid as MUIGrid, Button } from '@mui/material';

import { useSubpageReceiptsContext } from './context';

export default React.memo(() => {
  const {
    isConfirmQcValidationModalOpen,
    setIsConfirmQcValidationModalOpen,
    onOpenConfirmReceiptModal,
  } = useContext(GlobalContext);

  const { itemSummary } = useSubpageReceiptsContext();

  return (
    <Modal open={isConfirmQcValidationModalOpen}>
      <MUIContainer sx={{ width: '30% !important' }}>
        <Typography
          className="modalTextHeaderError"
          variant="h6"
          fontWeight="bold"
        >
          Mismatching Expected Quantity
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <Typography variant="subtitle1">
              Received do not match the Expected quantity from the packing list.{' '}
            </Typography>
            <ul style={{ margin: '5px 15px' }}>
              {itemSummary.items.map(
                (item) =>
                  item.qty !== item?.qtyExpected && (
                    <li>
                      <Typography variant="subtitle1">
                        {item.name} = {item.qty}{' '}
                        {item?.qtyExpected && item?.qtyExpected > 0
                          ? `of ${item?.qtyExpected}`
                          : ''}{' '}
                      </Typography>
                    </li>
                  ),
              )}
            </ul>
            <Typography variant="subtitle1">
              Are you sure you want to continue?
            </Typography>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => {
                  setIsConfirmQcValidationModalOpen(false);
                }}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                No
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={() => {
                  setIsConfirmQcValidationModalOpen(false);
                  onOpenConfirmReceiptModal();
                }}
              >
                Yes
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
