import { useEffect, useContext } from 'react';

import { GlobalContext } from 'store/contexts/GlobalContext';

import service from './httpmiddleware.js';

const AxiosInterceptor = ({ children }) => {
  const { onLoading, updateLoading } = useContext(GlobalContext);
  useEffect(() => {
    service.register({
      onRequest(config) {
        if (config.params?.showLoading) updateLoading(true);
        return config;
      },
      onResponse(response) {
        updateLoading(false);
        return response;
      },
      onResponseError(response = {}) {
        updateLoading(false);
        return Promise.reject(response);
      },
    });
  }, [onLoading]);

  return children;
};

export default AxiosInterceptor;
