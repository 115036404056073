import React, { useState } from 'react';

import { TriggerRuleModel } from 'common/models/orderprocessingrules';
import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { TriggerRule } from 'services/api/orderprocessingrule';

import {
  Modal,
  Box,
  Typography,
  Grid as MUIGrid,
  Button,
  CircularProgress,
} from '@mui/material';

import { useOrderProcessingRulesContext } from '../context';

function TriggerRulesModal() {
  const { triggerRulesModal: model, setTriggerRulesModal: setModel } =
    useOrderProcessingRulesContext();
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const handleConfirm = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();

    setIsBusy(true);

    const PAYLOAD: TriggerRuleModel = {
      orderProcessingRuleId: model.orderProcessingRuleId,
    };

    TriggerRule(PAYLOAD)
      .then(() => {
        setModel(null);
      })
      .catch((err) => {
        snackActions.error(err);
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  return (
    <Modal open={model !== null}>
      <MUIContainer sx={{ width: '30% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Trigger Rules
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important ' }}>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <Typography variant="h6">
              Are you sure you want to run this rule now for all matching
              orders?
            </Typography>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                disabled={isBusy}
                onClick={() => {
                  setModel(null);
                  setIsBusy(false);
                }}
              >
                Cancel
              </Button>

              {!isBusy && (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                  }}
                  onClick={(e) => handleConfirm(e)}
                >
                  Confirm
                </Button>
              )}
              {isBusy && (
                <Button
                  disabled
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                    color: 'white !important',
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: 'white !important',
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                  <Box sx={{ paddingLeft: '5px' }}>Confirm</Box>
                </Button>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
}

export default React.memo(TriggerRulesModal);
