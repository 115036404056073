const Footer = () => (
  <div className="footer">
    <a
      style={{
        color: 'rgb(123 123 123)',
        fontSize: 'x-small',
        textDecoration: 'none',
      }}
      href="/terms.html"
      target="_blank"
    >
      @ {`${new Date().getFullYear()}`} by MOZYM LLC. All Rights Reserved.
    </a>
  </div>
);

export default Footer;
