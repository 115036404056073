import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

import DatePickerInput from 'components/datepicker';
import Input from 'components/input';
import { getItemLookup } from 'services/api/item/item.api';
import { getLotLookup } from 'services/api/lot/lot.api.';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 1000px;
    border-radius: 20px;
    max-height: 80%;
  }
`;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 40px;
    gap: 16px;
    overflow-y: scroll;
    height: 100%;
  }
`;

export interface ISKUPalletOption {
  id: number;
  label: string;
  expirationDate?: Date;
}

export interface IMixedPalletItem {
  id: number;
  itemId: ISKUPalletOption;
  lotId: ISKUPalletOption;
  cases: number;
  eaches: number;
  eaCase: number;
  totalQty: number;
  totalWeight: number;
}

export interface IMixedPalletModal {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  pallets?: IMixedPalletItem[];
}

export default React.memo((props: IMixedPalletModal) => {
  const { title, isOpen, onClose, onConfirm, pallets } = props;

  const { currentUser } = useContext(AuthContext);
  const { onOpenNewLotNumberModal } = useContext(GlobalContext);

  const defaultSKUPalletOption: ISKUPalletOption | null = {
    id: -1,
    label: '',
    expirationDate: null,
  };

  const initialFormErrorsState: any = {
    items: [
      {
        itemId: '',
        cases: '',
        eaCase: '',
        totalQty: '',
        totalWeight: '',
        eaches: '',
      },
    ],
  };

  const initialState: any = {
    lotExpirationDate: '',
    lotExpiration: '',
    items: [
      {
        id: 1,
        itemId: {
          id: defaultSKUPalletOption.id,
          label: defaultSKUPalletOption.label,
        },
        lotId: {
          id: defaultSKUPalletOption.id,
          label: defaultSKUPalletOption.label,
        },
        cases: 0,
        eaches: 0,
        eaCase: 0,
        totalQty: 0,
        totalWeight: 0,
      },
    ],
  };

  const [customerSKUs, setCustomerSKUs] = useState([]);
  const [itemLotNumbers, setItemLotNumbers] = useState<
    {
      lotNos: any[];
    }[]
  >([]);
  const [form, setForm] = useState<any>(initialState);
  const [isReadonly, setIsReadonly] = useState(false);
  const [formErrors, setFormErrors] = useState<any>(initialFormErrorsState);
  const [selectedItemForNewLot, setSelectedItemForNewLot] = useState<{
    id: number;
    index: number;
  }>();

  const itemSelectionOptions = Array.isArray(customerSKUs)
    ? customerSKUs.map((i, index) => ({
        id: i.itemId,
        label: i.sku,
      }))
    : [{ id: 0, label: 'New Item' }];

  const onLoadResource = async () => {
    const itemSKUsFromApi = await getItemLookup(
      currentUser.Claim_CustomerId,
      '',
    );

    setCustomerSKUs(itemSKUsFromApi);
  };

  const onLoadLotNumbers = async (itemId, index) => {
    try {
      const lotNumbersFromApi = await getLotLookup(itemId);

      console.log(lotNumbersFromApi);

      itemLotNumbers[index] = {
        lotNos: lotNumbersFromApi,
      };

      setItemLotNumbers([...itemLotNumbers]);
      return true;
    } catch (err) {
      return err;
    }
  };

  const onForm = (key, text) => {
    setForm(() => ({
      ...form,
      [key]: text,
    }));
  };

  const generateLotSelectionOptions = (index) => {
    const lotNumberSelectionOptions = Array.isArray(
      itemLotNumbers[index]?.lotNos,
    )
      ? itemLotNumbers[index]?.lotNos.map((i) => ({
          id: i.lotId,
          label: i.lotNo,
          expirationDate: i.expirationDate,
        }))
      : [];
    return lotNumberSelectionOptions;
  };

  const dynamicAutoCompleteInputHandler = (
    key: string,
    value: ISKUPalletOption,
    index: number,
  ) => {
    if (key === 'itemId') {
      if (value) {
        onLoadLotNumbers(value.id, index);
      }
    }

    if (key === 'lotId') {
      form.items[index] = {
        ...form.items[index],
        lotExpiration: value?.expirationDate,
      };
    }

    form.items[index] = {
      ...form.items[index],
      [key]: value || {
        id: defaultSKUPalletOption.id,
        label: defaultSKUPalletOption.label,
      },
    };

    setForm(() => ({
      ...form,
    }));
  };

  const dynamicInputHandler = (
    key: string,
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    form.items[index] = { ...form.items[index], [key]: event.target.value };

    setForm(() => ({
      ...form,
    }));

    if (key === 'eaCase' || key === 'cases') {
      form.items[index].totalQty =
        form.items[index].eaCase * form.items[index].cases;
      setForm(() => ({
        ...form,
      }));
    }
  };

  const dateInputHandler = (
    key: string,
    newValue: Date | null,
    index: number,
  ) => {
    if (key === 'lotExpiration' || key === 'lotExpiration') {
      form.items[index].lotExpiration = newValue.toLocaleDateString();
      setForm(() => ({
        ...form,
      }));
    }
    onForm(key, newValue.toLocaleDateString());
  };

  const handleNewLotModalOpen = (item) => {
    setSelectedItemForNewLot(item);
    onOpenNewLotNumberModal();
  };

  const handleAddNewSKU = async () => {
    form.items.push({
      id: form.items.length,
      itemId: {
        id: defaultSKUPalletOption.id,
        label: defaultSKUPalletOption.label,
      },
      lotId: {
        id: defaultSKUPalletOption.id,
        label: defaultSKUPalletOption.label,
      },
      cases: 0,
      eaches: 0,
      eaCase: 0,
      totalQty: 0,
      totalWeight: 0,
    });
    setForm({ ...form });
  };

  const handleConfirm = () => {
    console.log(form);
    onConfirm();
  };

  const handleClose = () => {
    setForm(initialState);
    onClose();
  };

  useEffect(() => {
    onLoadResource();
  }, []);

  return (
    <Modal open={isOpen}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          {title}
        </Typography>
        <MUIContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
            }}
          >
            {form.items.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  gap: '24px',
                  pb: '24px',
                  borderBottom: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    gap: '24px',
                    alignItems: 'start',
                  }}
                >
                  <Box sx={{ display: 'flex', width: '215px' }}>
                    <Autocomplete
                      options={itemSelectionOptions}
                      getOptionLabel={(option: ISKUPalletOption) =>
                        `${option.label}` || ''
                      }
                      size="medium"
                      value={item.itemId}
                      sx={{ width: '100%' }}
                      disabled={isReadonly}
                      onChange={(
                        event: any,
                        value: ISKUPalletOption | null,
                      ) => {
                        dynamicAutoCompleteInputHandler('itemId', value, index);
                      }}
                      renderInput={(params) =>
                        formErrors.skus?.[index]?.itemId ? (
                          <TextField
                            sx={{
                              background: 'white',
                            }}
                            {...params}
                            label="Item SKU"
                            error
                            helperText={formErrors.skus[index]?.itemId}
                            disabled={isReadonly}
                          />
                        ) : (
                          <TextField
                            sx={{
                              background: 'white',
                            }}
                            {...params}
                            label="SKU"
                            disabled={isReadonly}
                          />
                        )
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', width: '215px' }}>
                    <Autocomplete
                      options={generateLotSelectionOptions(index)}
                      getOptionLabel={(option: ISKUPalletOption) =>
                        `${option.label}` || ''
                      }
                      size="medium"
                      value={item.lotId}
                      sx={{ width: '100%' }}
                      disabled={isReadonly}
                      onChange={(
                        event: any,
                        value: ISKUPalletOption | null,
                      ) => {
                        dynamicAutoCompleteInputHandler('lotId', value, index);
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ background: 'white' }}
                          {...params}
                          label="Lot Number"
                          disabled={isReadonly}
                        />
                      )}
                      noOptionsText={
                        <Button
                          variant="text"
                          size="medium"
                          onMouseDown={() => {
                            handleNewLotModalOpen({
                              id: form.items[index]?.itemId?.id,
                              index,
                            });
                          }}
                        >
                          + Add Lot Number
                        </Button>
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', width: '250px' }}>
                    <DatePickerInput
                      sx={{ width: '100%', background: 'white' }}
                      inputFormat="MM/dd/yyyy"
                      value={form.items[index]?.lotExpiration || null}
                      label="Lot Exp."
                      onChange={(value) =>
                        dateInputHandler('lotExpiration', value, index)
                      }
                      renderInput={(params) =>
                        formErrors.lotExpiration ? (
                          <TextField
                            sx={{
                              width: '100%',
                              background: 'white',
                            }}
                            {...params}
                            size="medium"
                            error
                            helperText={formErrors.lotExpiration}
                            disabled={isReadonly}
                          />
                        ) : (
                          <TextField
                            sx={{
                              width: '100%',
                              background: 'white',
                            }}
                            {...params}
                            error={false}
                            size="medium"
                            disabled={isReadonly}
                          />
                        )
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', width: '100px' }}>
                    <Input
                      sx={{
                        width: '100%',
                        background: 'white',
                      }}
                      placeholder="Cases"
                      type="number"
                      min={0}
                      size="medium"
                      value={item.cases}
                      error={formErrors.skus?.[index]?.cases}
                      disabled={isReadonly}
                      onChange={(value) =>
                        dynamicInputHandler('cases', value, index)
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', width: '100px' }}>
                    <Input
                      sx={{
                        width: '100%',
                        background: 'white',
                      }}
                      placeholder="EA/CS"
                      type="number"
                      min={0}
                      size="medium"
                      value={item.eaCase}
                      error={formErrors.skus?.[index]?.eaCase}
                      disabled={isReadonly}
                      onChange={(value) =>
                        dynamicInputHandler('eaCase', value, index)
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', width: '100px' }}>
                    <Input
                      sx={{
                        width: '100%',
                        background: 'white',
                      }}
                      placeholder="Total Qty"
                      type="number"
                      min={0}
                      size="medium"
                      value={item.totalQty}
                      error={formErrors.skus?.[index]?.totalQty}
                      onChange={(value) =>
                        dynamicInputHandler('totalQty', value, index)
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', width: '100px' }}>
                    <Input
                      sx={{
                        width: '100%',
                        background: 'white',
                      }}
                      placeholder="Weight"
                      type="number"
                      min={0}
                      size="medium"
                      value={item.totalWeight}
                      error={formErrors.skus?.[index]?.totalWeight}
                      disabled={isReadonly}
                      onChange={(value: any) =>
                        dynamicInputHandler('totalWeight', value, index)
                      }
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Button
                sx={{
                  width: '100%',
                  justifyContent: 'start',
                  borderColor: '#cad0d3',
                }}
                variant="outlined"
                size="large"
                disabled={isReadonly}
                onClick={handleAddNewSKU}
              >
                Add Another SKU
              </Button>
            </Box>
          </Box>
        </MUIContent>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '200px',
              columnGap: 1,
              padding: 2,
            }}
          >
            <Button
              type="button"
              variant="outlined"
              size="small"
              sx={{ padding: 1, width: '100%' }}
              onClick={handleClose}
            >
              CANCEL
            </Button>
            <Button
              type="button"
              variant="contained"
              size="small"
              sx={{
                padding: 1,
                width: '100%',
                backgroundColor: ' rgb(75, 126, 254) !important',
              }}
              onClick={handleConfirm}
            >
              CONFIRM
            </Button>
          </Box>
        </Box>
      </MUIContainer>
    </Modal>
  );
});
