import React, { useContext, useState } from 'react';

import Button from 'components/button';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { useSalesOrderDataTableViewContext } from 'pages/sales/context-provider';
import { UpdateBatchNumber } from 'services/api/salesorders/salesorders-new.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Box, Modal, Chip, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';

export default React.memo(() => {
  const theme = useTheme();
  const {
    isAddToBatchModalOpen,
    setIsAddToBatchModalOpen,
    isAddToBatchConfirmModalOpen,
    setIsAddToBatchConfirmModalOpen,
  } = useContext(GlobalContext);

  const {
    selectedRowsOnCheckbox,
    initialStateSearchParams,
    setSearchParams,
    setAdvanceSearchValue,
    OnClickUserFilterSearchApply,
  } = useSalesOrderDataTableViewContext();

  const { batchesOnOrderOption } = useSalesOrderDataTableViewContext();
  const [selectedBatch, setSelectedBatch] = useState('');

  const updateBatchNumberOrderOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    const payload = {
      BatchNumber: selectedBatch === '+ Create New Batch' ? 0 : selectedBatch,
      OrderToBatchNos: selectedRowsOnCheckbox.map((c: any) => ({
        OrderId: c.orderId,
      })),
    };

    try {
      await UpdateBatchNumber(payload);
      snackActions.success('Batch number updated successfully.');
      setIsAddToBatchConfirmModalOpen(false);
      OnClickUserFilterSearchApply(e, true);
      setSearchParams({
        ...initialStateSearchParams,
        status: 'open',
      });
      setAdvanceSearchValue((prev) => ({
        ...prev,
        filterSearchParameter: null,
        setFilterName: null,
      }));
    } catch (err) {
      snackActions.error(err);
    }
  };

  return (
    <>
      <Modal open={isAddToBatchModalOpen}>
        <ModalBox sx={{ maxWidth: '450px !important' }}>
          <ModalContent sx={{ maxWidth: '450px !important' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography
                sx={{ marginBottom: '8px' }}
                variant="h6"
                fontWeight="bold"
              >
                Add to Batch
              </Typography>
              <List
                dense
                sx={{
                  width: '100%',
                  maxWidth: 450,
                }}
              >
                <Typography sx={{ marginBottom: '10px', fontSize: '15px' }}>
                  Select a batch below to the orders to or create a new batch
                </Typography>
                {batchesOnOrderOption &&
                  batchesOnOrderOption.map((value, index) => {
                    const labelId = `checkbox-list-secondary-label-${value.batchNo}`;
                    return (
                      <ListItem
                        key={value.batchNo}
                        secondaryAction={
                          <Chip
                            label={value.batchNoCount}
                            size="small"
                            className="grayChip"
                            sx={{
                              display:
                                value.batchNoCount === 0 ? 'none' : 'block',
                            }}
                          />
                        }
                        sx={{
                          borderTop:
                            index === 0 ? 'solid 1px hsl(0, 0%, 64%)' : 'none',
                          borderBottom: 'solid 1px hsl(0, 0%, 64%)',
                        }}
                        disablePadding
                      >
                        <ListItemButton
                          onClick={() => {
                            setIsAddToBatchConfirmModalOpen(true);
                            setIsAddToBatchModalOpen(false);
                            setSelectedBatch(value.batchNo);
                          }}
                        >
                          <ListItemText
                            id={labelId}
                            primary={`${value.batchNo}`}
                            sx={{
                              color:
                                value.batchNoCount === 0 ? '#1C9DCC' : 'black',
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                  justifyContent: 'flex-end',
                },
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'center',
                },
                gap: '8px',
              }}
            >
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="text"
                size="small"
                onClick={() => {
                  setIsAddToBatchModalOpen(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          </ModalContent>
        </ModalBox>
      </Modal>
      <Modal open={isAddToBatchConfirmModalOpen}>
        <ModalBox sx={{ maxWidth: '450px !important' }}>
          <ModalContent sx={{ maxWidth: '450px !important' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography
                sx={{ marginBottom: '8px' }}
                variant="h6"
                fontWeight="bold"
              >
                {selectedBatch === '+ Create New Batch'
                  ? 'Generate Batch Number'
                  : `Batch Number: ${selectedBatch}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                  justifyContent: 'flex-end',
                },
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'center',
                },
                gap: '8px',
              }}
            >
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="text"
                size="small"
                onClick={() => {
                  setIsAddToBatchConfirmModalOpen(false);
                  setIsAddToBatchModalOpen(true);
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{ minHeight: '48px', maxWidth: '150px' }}
                variant="contained"
                size="small"
                onClick={(e) => {
                  updateBatchNumberOrderOnClick(e);
                }}
              >
                Move to Batch
              </Button>
            </Box>
          </ModalContent>
        </ModalBox>
      </Modal>
    </>
  );
});
