import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import Card from 'components/card';
import Header from 'components/header';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import ConfirmationModal from 'pages/shared/confirmationmodal';
import { deleteReceipt } from 'services/api/receipts/receipts-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridPremium } from 'styles';

import { Search } from '@mui/icons-material';
import { Typography, Box, Link, Chip, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridColumns,
  GridActionsCellItem,
  useGridApiRef,
  GridColumnVisibilityModel,
  GridToolbarContainer,
} from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

import { MainContainer, ContentContainer } from '../../styles';
import {
  SubpageReceiptsContextProvider,
  useSubpageReceiptsContext,
} from './context';

const POFilterSearchPanel = () => {
  const {
    receipts,
    setFilteredReceipts,
    selectedFilter,
    setSelectedFilter,
    setReceiptIdToDelete,
    onLoadReceiptsData,
  } = useSubpageReceiptsContext();

  const [searchFieldValue, setSearchFieldValue] = React.useState('');
  const handleSearchFieldChange = (newValue) => {
    onLoadReceiptsData(newValue);
  };

  const handleQuickFiltersClick = (filter: string) => {
    if (filter === '') {
      setFilteredReceipts(receipts);
    } else {
      setFilteredReceipts(
        receipts.filter((x) => x.status.toLowerCase() === filter.toLowerCase()),
      );
    }
    setSelectedFilter(filter);
    setReceiptIdToDelete(null);
    setSearchFieldValue('');
  };

  return (
    <>
      <Box sx={{ width: '100%', padding: '10px' }}>
        <Chip
          label="All"
          size="small"
          sx={{
            marginLeft: 1,
            color: selectedFilter === '' ? '#FFF' : '#8a8a8c',
            fontWeight: '500',
            fontSize: '0.75rem',
            padding: '20px 10px',
          }}
          style={{
            backgroundColor: selectedFilter === '' ? '#1C9DCC' : '#ebeef3',
            height: '35px',
            borderRadius: '4px',
            width: '100px',
          }}
          onClick={() => {
            handleQuickFiltersClick('');
          }}
        />
        <Chip
          label="Open"
          size="small"
          sx={{
            marginLeft: 1,
            color: selectedFilter === 'Open' ? '#FFF' : '#8a8a8c',
            fontWeight: '500',
            fontSize: '0.75rem',
            padding: '20px 10px',
          }}
          style={{
            backgroundColor: selectedFilter === 'Open' ? '#1C9DCC' : '#ebeef3',
            height: '35px',
            borderRadius: '4px',
            width: '100px',
          }}
          onClick={() => {
            handleQuickFiltersClick('Open');
          }}
        />
        <Chip
          label="Ready for Put Away"
          size="small"
          sx={{
            marginLeft: 1,
            color: selectedFilter === 'Ready for Put Away' ? '#FFF' : '#8a8a8c',
            fontWeight: '500',
            fontSize: '0.75rem',
            padding: '20px 10px',
          }}
          style={{
            backgroundColor:
              selectedFilter === 'Ready for Put Away' ? '#1C9DCC' : '#ebeef3',
            height: '35px',
            borderRadius: '4px',
            width: '150px',
          }}
          onClick={() => {
            handleQuickFiltersClick('Ready for Put Away');
          }}
        />
        <Chip
          label="Confirmed"
          size="small"
          sx={{
            marginLeft: 1,
            color: selectedFilter === 'Confirmed' ? '#FFF' : '#8a8a8c',
            fontWeight: '500',
            fontSize: '0.75rem',
            padding: '20px 10px',
          }}
          style={{
            backgroundColor:
              selectedFilter === 'Confirmed' ? '#1C9DCC' : '#ebeef3',
            height: '35px',
            borderRadius: '4px',
            width: '100px',
          }}
          onClick={() => {
            handleQuickFiltersClick('Confirmed');
          }}
        />
        <Chip
          label="Put Away Complete"
          size="small"
          sx={{
            marginLeft: 1,
            color: selectedFilter === 'Put Away Complete' ? '#FFF' : '#8a8a8c',
            fontWeight: '500',
            fontSize: '0.75rem',
            padding: '20px 10px',
          }}
          style={{
            backgroundColor:
              selectedFilter === 'Put Away Complete' ? '#1C9DCC' : '#ebeef3',
            height: '35px',
            borderRadius: '4px',
            width: '150px',
          }}
          onClick={() => {
            handleQuickFiltersClick('Put Away Complete');
          }}
        />
      </Box>
      <div style={{ width: '100%', padding: '10px' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'right !important',
          }}
        >
          <Input
            sx={{ maxWidth: '100%' }}
            placeholder="Search"
            value={searchFieldValue}
            onChange={(e) => setSearchFieldValue(e.target.value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchFieldChange(e.target.value);
              }
            }}
          />
        </Box>
      </div>
    </>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          flex: '1',
          width: '100% !important',
        }}
      >
        <POFilterSearchPanel />
      </Box>
    </GridToolbarContainer>
  );
}

const Receipts = () => {
  const theme = useTheme();

  const { currentUser } = useContext(AuthContext);

  const {
    receiptStatus,
    filteredReceipts,
    onLoadReceiptsData,
    receiptIdToDelete,
    setReceiptIdToDelete,
  } = useSubpageReceiptsContext();

  const { onOpenConfirmationModal } = useContext(GlobalContext);
  const navigate = useNavigate();

  const lineItemsColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      hideable: false,
      width: 25,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Details"
          onClick={() => {
            navigate(`subpage?form=view&id=${params.row.receiptId}`);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Edit"
          sx={{
            display:
              receiptStatus === null || params.row.status === 'Confirmed'
                ? 'none'
                : '',
          }}
          onClick={() => {
            navigate(`subpage?form=edit&id=${params.row.receiptId}`);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          sx={{
            display: params.row.receivedQty > 0 ? 'none' : '',
          }}
          onClick={() => {
            setReceiptIdToDelete(params.row.receiptId);
            onOpenConfirmationModal();
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'referenceNo',
      minWidth: 140,
      flex: 1,
      align: 'right',
      headerName: 'Reference No',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`/receiving/receipts/subpage?form=view&id=${params.row.receiptId}`}
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.referenceNo}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'status',
      minWidth: 90,
      flex: 1,
      align: 'center',
      headerName: 'Status',
      renderCell: (params) => <Typography>{params.row.status}</Typography>,
    },
    {
      field: 'expectedQty',
      align: 'center',
      minWidth: 120,
      flex: 1,
      headerName: 'Expected Qty',
      renderCell: (params) => <Typography>{params.row.expectedQty}</Typography>,
    },
    {
      field: 'receivedQty',
      align: 'center',
      minWidth: 120,
      flex: 1,
      headerName: 'Received Qty',
      renderCell: (params) => <Typography>{params.row.receivedQty}</Typography>,
    },
    {
      field: 'creationDate',
      minWidth: 130,
      flex: 1,
      align: 'center',
      headerName: 'Creation Date',
      type: 'date',
      valueFormatter: (params) => moment(params?.value).format('MM/DD/YYYY'),
      renderCell: (params) => (
        <Typography>
          {moment(params.row?.creationDate).format('MM/DD/YYYY')}
        </Typography>
      ),
    },
    {
      field: 'expectedDate',
      minWidth: 130,
      flex: 1,
      align: 'center',
      headerName: 'Expected Date',
      type: 'date',
      valueFormatter: (params) =>
        params?.value !== undefined
          ? moment(params?.value).format('MM/DD/YYYY')
          : undefined,
      renderCell: (params) => (
        <Typography>
          {params.row?.expectedDate !== undefined
            ? moment(params.row?.expectedDate).format('MM/DD/YYYY')
            : undefined}
        </Typography>
      ),
    },
    {
      field: 'arrivalDate',
      minWidth: 130,
      flex: 1,
      align: 'center',
      headerName: 'Arrival Date',
      type: 'date',
      valueFormatter: (params) =>
        params?.value !== undefined
          ? moment(params?.value).format('MM/DD/YYYY')
          : undefined,
      renderCell: (params) => (
        <Typography>
          {params.row?.arrivalDate !== undefined
            ? moment(params.row?.arrivalDate).format('MM/DD/YYYY')
            : undefined}
        </Typography>
      ),
    },
    {
      field: 'sku',
      minWidth: 150,
      flex: 1,
      align: 'right',
      headerName: 'SKU',
      renderCell: (params) => <Typography>{params.row.sku}</Typography>,
    },
    {
      field: 'description',
      minWidth: 150,
      flex: 1,
      align: 'right',
      headerName: 'Description',
      renderCell: (params) => <Typography>{params.row.description}</Typography>,
    },
    {
      field: 'po',
      minWidth: 130,
      flex: 1,
      align: 'right',
      headerName: 'PO',
      renderCell: (params) => <Typography>{params.row.po}</Typography>,
    },
    {
      field: 'purchaseOrderQty',
      minWidth: 130,
      flex: 1,
      align: 'right',
      headerName: 'Total PO Qty',
      renderCell: (params) => (
        <Typography>{params.row.purchaseOrderQty}</Typography>
      ),
    },
    {
      field: 'customer',
      minWidth: 140,
      flex: 1,
      align: 'right',
      headerName: 'Client',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.customer}
        </Typography>
      ),
    },
    {
      field: 'warehouse',
      align: 'right',
      minWidth: 150,
      flex: 1,
      headerName: 'Warehouse',
      renderCell: (params) => <Typography>{params.row.warehouse}</Typography>,
    },
    {
      field: 'notes',
      width: 140,
      headerName: 'Notes',
      renderCell: (params) => <Typography>{params.row.notes}</Typography>,
    },
  ];

  const handleDeleteReceipt = () => {
    deleteReceipt(receiptIdToDelete)
      .then((res) => {
        onLoadReceiptsData();
      })
      .catch((err) => {
        snackActions.error(err);
      });
  };

  // Receipt state -----------------------------------------------
  const apiRef = useGridApiRef();
  const [initialState, setInitialState] =
    React.useState<GridInitialStatePremium>();

  // DATAGRID STATE MODEL
  const [savedState, setSavedState] = React.useState<{
    initialState: GridInitialStatePremium;
  }>({ initialState: {} });

  // COLUMN MODEL
  const [savedColumnsState, setSavedColumnsState] = React.useState<{
    columnVisibility: GridColumnVisibilityModel;
  }>({ columnVisibility: {} });

  // GET DATAGRID AMD COLUMN VISIBILITY STATE
  const saveSnapshot = React.useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem(
        'receiptDataGridState',
        JSON.stringify(currentState),
      );
    }
  }, []);

  // LOAD DATAGRID AMD COLUMN VISIBILITY STATE
  React.useLayoutEffect(() => {
    // DATAGRID STATE
    const stateFromLocalStorage = localStorage?.getItem('receiptDataGridState');
    setInitialState(
      stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {},
    );

    // COLUMN VISIBILITY
    const columnStateFromLocalStorage = localStorage?.getItem(
      'receiptDataGridStateColumnVisibility',
    );
    setSavedColumnsState((prev) => ({
      ...prev,
      columnVisibility: columnStateFromLocalStorage
        ? JSON.parse(columnStateFromLocalStorage)
        : {},
    }));

    setSavedState((prev) => ({
      ...prev,
      initialState: JSON.parse(stateFromLocalStorage),
    }));
  }, [currentUser.Claim_CustomerId]);

  useEffect(() => {
    if (savedState?.initialState !== null && apiRef?.current?.restoreState) {
      apiRef?.current?.restoreState(savedState?.initialState);
      apiRef.current.setColumnVisibilityModel(
        savedColumnsState.columnVisibility,
      );
    }
  }, [lineItemsColumns]);

  if (!initialState) {
    return (
      <div style={{ textAlign: 'center', paddingTop: '20%' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Card
      sx={{
        marginBottom: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <DatagridPremium
          autoHeight
          disableColumnFilter
          disableColumnMenu
          rows={filteredReceipts}
          density="compact"
          columns={lineItemsColumns}
          initialState={{
            sorting: {
              sortModel: [{ field: 'creationDate', sort: 'desc' }],
            },
            pagination: { pageSize: 50 },
          }}
          getRowId={(row) => row.receiptId}
          checkboxSelection={false}
          components={{ Toolbar: CustomToolbar }}
          apiRef={apiRef}
          onStateChange={() => {
            saveSnapshot();
          }}
          onColumnVisibilityModelChange={(e) => {
            localStorage.setItem(
              'receiptDataGridStateColumnVisibility',
              JSON.stringify(e),
            );
          }}
          rowsPerPageOptions={[50, 100, 150]}
          pagination
        />
      </Box>
      <ConfirmationModal
        dialogText="Do you really want to remove this receipt?"
        callBack={handleDeleteReceipt}
      />
    </Card>
  );
};

function ReceiptTempList() {
  return (
    <MainContainer>
      <Header />
      <ContentContainer>
        <SubpageReceiptsContextProvider>
          <Receipts />
        </SubpageReceiptsContextProvider>
      </ContentContainer>
    </MainContainer>
  );
}

export default React.memo(ReceiptTempList);
