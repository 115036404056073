/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';

import { UserPermissionEnum } from 'common/enums';
import { GetISOIdString, GetRandomString } from 'common/utils';
import DatePickerInput from 'components/datepicker';
import Header from 'components/header';
import {
  IconBolt,
  IconClose,
  IconEdit,
  IconMagnify,
  IconPlay,
  IconPlus,
  IconTrash,
} from 'components/icons';
import { Grid } from 'components/styles';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { CustomerType } from 'pages/ordercustomers/warehousecustomer/customer-rules-management';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import {
  BoxIcon,
  ButtonIconAdd,
  ButtonIconClose,
  ButtonPrimary,
  ButtonPrimaryOutline,
  CardBox,
  CellButtonIcon,
  TextNoWrap,
  TextSpanMediumBold,
  TextSpanSmall,
} from 'styles/mui';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import {
  Autocomplete,
  Grid as MUIGrid,
  Box,
  TextField,
  Typography,
  Divider,
  Stack,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  IconButton,
  FormControlLabel,
  Switch,
  Checkbox,
  Tooltip,
} from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';

import { MainContainer, ContentContainer } from '../../styles';
import { CarrierType, CartonCodeDDLType } from '../salesorder/context';
import {
  IRowConditon,
  ItemOption,
  OrderProcessingRulesContextProvider,
  useOrderProcessingRulesContext,
} from './context';
import DeleteCustomRuleModal from './delete-custom-rule-modal';
import EnableDisableRuleModal from './enable-or-disable-rule-modal';
import TriggerRulesModal from './trigger-rules-modal';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const optionColumnNamesWithTypes: any = [
  { isResult: true, type: 'Text', value: 'BatchNo', label: 'Batch No' },
  { isResult: true, type: 'Dropdown', value: 'Status', label: 'Status' },
  { isResult: false, type: 'Text', value: 'OrderNo', label: 'Order No.' },
  {
    isResult: false,
    type: 'Text',
    value: 'ReferenceNo',
    label: 'Reference No.',
  },
  { isResult: false, type: 'Text', value: 'purchaseOrder', label: 'PO' },
  {
    isResult: false,
    type: 'Number',
    value: 'OrderLineItems',
    label: 'Item Count',
  },
  {
    isResult: false,
    type: 'Number',
    value: 'Qty',
    label: 'Total Quantity',
  },
  {
    isResult: true,
    type: 'CarrierDropdown',
    value: 'CarrierType',
    label: 'Carrier',
  },
  {
    isResult: false,
    type: 'Dropdown',
    value: 'WarehouseCustomerId',
    label: 'Client',
  },
  {
    isResult: false,
    type: 'Date',
    value: 'CreationDateTime',
    label: 'Creation Date',
  },
  { isResult: false, type: 'Date', value: 'ShipByDateTime', label: 'Ship By' },
  {
    isResult: false,
    type: 'Date',
    value: 'ShipCancelDateTime',
    label: 'Ship Cancel Date',
  },
  {
    isResult: true,
    type: 'Dropdown',
    value: 'ShipStationOrderTags',
    label: 'Tags',
  },
  {
    isResult: false,
    type: 'Custom',
    value: 'MatchingCondition',
    label: 'Matching (SKU + Qty + Carrier)',
  },
  {
    isResult: false,
    type: 'MultiSelectDropdown',
    value: 'Items',
    label: 'Item',
  },
  {
    isResult: true,
    type: 'CartonCodeDropdown',
    value: 'PackageType',
    label: 'Package Type',
    hideFromCondition: true,
  },
  {
    isResult: true,
    type: 'Text',
    value: 'PackagingInstructions',
    label: 'Packaging Instructions',
    hideFromCondition: true,
  },
  {
    isResult: true,
    type: 'Text',
    value: 'Checklist',
    label: 'Checklist',
    hideFromCondition: true,
  },
  {
    isResult: true,
    type: 'Dropdown',
    value: 'PickAssignment',
    label: 'Pick Assignment',
  },
  {
    isResult: true,
    type: 'Dropdown',
    value: 'ItemClassification',
    label: 'Item Classification',
  },
  {
    isResult: true,
    type: 'AdhocDropdown',
    value: 'AdhocCharge',
    label: 'Adhoc Charge',
    hideFromCondition: true,
  },
  {
    isResult: true,
    type: 'Dropdown',
    value: 'OrderSource',
    label: 'Order Rule',
    hideFromCondition: true,
  },
  {
    isResult: false,
    type: 'Dropdown',
    value: 'OrderChannel',
    label: 'Order Channel',
  },
];

const textConditionOption: any = [
  { value: 'equals', label: 'Equals' },
  { value: 'containing', label: 'Containing' },
  { value: 'notContaining', label: 'Not Containing' },
  { value: 'beginningwith', label: 'Beginning with' },
  { value: 'endingwith', label: 'Ending with' },
  { value: 'isEmpty', label: 'Is Empty' },
];

const textConditionOptionItems: any = [
  { value: 'containing', label: 'Containing' },
];

const numberConditionOption: any = [
  { value: 'equalTo', label: 'Equal to' },
  { value: 'notEqualTo', label: 'Not equal to' },
  { value: 'greaterThan', label: 'Greater than' },
  { value: 'lessThan', label: 'Less than' },
  { value: 'greaterThanOrEqualTo', label: 'Greater than or equal to' },
  { value: 'lessThanOrEqualTo', label: 'Less than or equal To' },
  { value: 'isEmpty', label: 'Is Empty' },
];

const DataGridRules = ({ customerType }: CreateNewRuleHeaderProps) => {
  const {
    setIsEnableDisableRuleModalOpen,
    setCustomRuleId,
    setIsEnabledCustomRule,
  } = useContext(GlobalContext);

  const {
    orderProcessingRulesGrid,
    changeRuleOrder,
    EditRuleHeaderPage,
    setIsShowRulesForm,
    setCustomeRuleIdToDelete,
    setTriggerRulesModal,
    setIsDeleteCustomeRuleModalOpen,
  } = useOrderProcessingRulesContext();

  const { handleUserPermissionAllow } = useContext(AuthContext);

  const ruleColumns: GridColumns = [
    {
      field: 'edit',
      headerName: '',
      headerClassName: 'wp-05',
      cellClassName: 'wp-05',
      type: 'action',
      renderCell: (params) => (
        <>
          <Tooltip
            title={
              params.row.isEnabled ? 'Click to Disable' : 'Click to Enable'
            }
            arrow
          >
            <CellButtonIcon
              $color="var(--light-primary)"
              onClick={() => {
                setIsEnableDisableRuleModalOpen(true);
                setCustomRuleId(params.row.orderProcessingRuleId);
                setIsEnabledCustomRule(params.row.isEnabled);
              }}
              disabled={
                customerType === CustomerType.WarehouseCustomer &&
                !handleUserPermissionAllow(UserPermissionEnum.Client_Rules_Edit)
              }
            >
              {params.row.isEnabled ? (
                <PlayArrowIcon style={{ color: 'green' }} />
              ) : (
                <StopIcon sx={{ color: 'red' }} />
              )}
            </CellButtonIcon>
          </Tooltip>

          <Tooltip title="Trigger Rules" arrow>
            <CellButtonIcon
              $color="var(--light-primary)"
              onClick={() => {
                setTriggerRulesModal((prevState) => ({
                  ...prevState,
                  orderProcessingRuleId: params.row.orderProcessingRuleId,
                }));
              }}
              disabled={
                (customerType === CustomerType.WarehouseCustomer &&
                  !handleUserPermissionAllow(
                    UserPermissionEnum.Client_Rules_Edit,
                  )) ||
                !params.row.isEnabled
              }
            >
              <OfflineBoltIcon />
            </CellButtonIcon>
          </Tooltip>

          <Tooltip title="Edit Rule" arrow>
            <CellButtonIcon
              $color="var(--light-primary)"
              onClick={() => {
                setIsShowRulesForm(true);
                EditRuleHeaderPage(params.row.orderProcessingRuleId);
              }}
              disabled={
                customerType === CustomerType.WarehouseCustomer &&
                !handleUserPermissionAllow(UserPermissionEnum.Client_Rules_Edit)
              }
            >
              <IconEdit />
            </CellButtonIcon>
          </Tooltip>

          <Tooltip title="Delete Rule" arrow>
            <CellButtonIcon
              $color="var(--light-danger)"
              onClick={() => {
                setIsDeleteCustomeRuleModalOpen(true);
                setCustomeRuleIdToDelete(params.row.orderProcessingRuleId);
              }}
              disabled={
                customerType === CustomerType.WarehouseCustomer &&
                !handleUserPermissionAllow(UserPermissionEnum.Client_Rules_Edit)
              }
            >
              <IconTrash />
            </CellButtonIcon>
          </Tooltip>
        </>
      ),
    },
    {
      field: 'order',
      headerClassName: 'wp-05',
      cellClassName: 'wp-05',
      headerName: 'Order',
      renderCell: (params) => (
        <>
          <CellButtonIcon
            onClick={() =>
              changeRuleOrder(params.row.orderProcessingRuleId, 'up')
            }
            disabled={
              customerType === CustomerType.WarehouseCustomer &&
              !handleUserPermissionAllow(UserPermissionEnum.Client_Rules_Edit)
            }
          >
            <ArrowUpwardIcon />
          </CellButtonIcon>
          <CellButtonIcon
            onClick={() =>
              changeRuleOrder(params.row.orderProcessingRuleId, 'down')
            }
            disabled={
              customerType === CustomerType.WarehouseCustomer &&
              !handleUserPermissionAllow(UserPermissionEnum.Client_Rules_Edit)
            }
          >
            <ArrowDownwardIcon />
          </CellButtonIcon>
        </>
      ),
    },
    {
      field: 'ruleName',
      headerClassName: 'wp-15',
      cellClassName: 'wp-15',
      headerName: 'Rule Name',
      renderCell: (params) => <Typography>{params.row.ruleName}</Typography>,
    },
    {
      field: 'numberOfCondition',
      headerClassName: 'wp-15',
      cellClassName: 'wp-15',
      headerName: 'No. of Condition',
      renderCell: (params) => (
        <Typography className="wp-20">
          {params.row.numberOfCondition}
        </Typography>
      ),
    },
    {
      field: 'numberOfAction',
      headerClassName: 'wp-20',
      cellClassName: 'wp-20',
      headerName: 'No. of Action',
      renderCell: (params) => (
        <Typography>{params.row.numberOfAction}</Typography>
      ),
    },
    {
      field: 'createdDateTime',
      headerClassName: 'wp-20',
      cellClassName: 'wp-20',
      headerName: 'Created Date Time',
      renderCell: (params) => (
        <Typography>{params.row.createdDateTime}</Typography>
      ),
    },
    {
      field: 'createdBy',
      headerClassName: 'wp-20',
      cellClassName: 'wp-20',
      headerName: 'Created By',
      renderCell: (params) => <Typography>{params.row.createdBy}</Typography>,
    },
    {
      field: 'delete',
      headerName: '',
      headerClassName: 'wp-05',
      cellClassName: 'wp-05',
      type: 'action',
      renderCell: () => (
        <CellButtonIcon $color="var(--light-danger)">
          <IconTrash />
        </CellButtonIcon>
      ),
    },
  ];

  return (
    <>
      <EnableDisableRuleModal />
      <DeleteCustomRuleModal />
      <TriggerRulesModal />
      <CardBox sx={{ marginBottom: 2, padding: 3, flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextNoWrap variant="body1" sx={{ fontWeight: 'bold' }}>
              List Of Rules
            </TextNoWrap>
          </Box>
          <FormControl sx={{ width: '250px' }} variant="outlined" size="small">
            <InputLabel htmlFor="outlined-search">Search Rule Name</InputLabel>
            <OutlinedInput
              id="outlined-search"
              type="text"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="Search" edge="end">
                    <IconMagnify />
                  </IconButton>
                </InputAdornment>
              }
              label="Search Rule Name"
            />
          </FormControl>
        </Box>
        <Grid
          autoHeight
          headerHeight={30}
          rows={orderProcessingRulesGrid}
          columns={ruleColumns}
          pageSize={15}
          checkboxSelection={false}
          rowsPerPageOptions={[15]}
          getRowId={(row) => row.orderProcessingRuleId}
          hideFooterSelectedRowCount
          hideFooter
          sx={{
            '& .wp-05': {
              maxWidth: '8% !important',
              minWidth: '8% !important',
            },
            '& .wp-15': {
              maxWidth: '15% !important',
              minWidth: '15% !important',
            },
            '& .wp-20': {
              maxWidth: '20% !important',
              minWidth: '20% !important',
            },
            '& .MuiDataGrid-columnHeaders': {
              maxHeight: '30px !important',
              '& .MuiDataGrid-columnHeadersInner': {
                width: '100% !important',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
            },
            '& .MuiDataGrid-row, .MuiDataGrid-cell': {
              maxHeight: '30px !important',
              minHeight: '30px !important',
              '& p': {
                fontSize: '14px',
              },
            },
            '& .MuiDataGrid-virtualScrollerRenderZone, .MuiDataGrid-row': {
              width: '100% !important',
            },
            '& .MuiDataGrid-row:last-child': {
              borderBottom: '1px solid #e0e0e0',
            },
          }}
        />
      </CardBox>
    </>
  );
};

const CreateNewRuleTrigger = () => {
  const {
    conditionValues,
    addCondition,
    removeFieldOnRowsCondition,
    handleFormChangeOrderRules,
    optionItems,
    getComboboxOptions,
    carrierOptions,
  } = useOrderProcessingRulesContext();

  return (
    <Box
      sx={{
        width: '49%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
      }}
    >
      <Box sx={{ display: 'flex', columnGap: '20px', mb: 1 }}>
        <BoxIcon $color="var(--light-primary)">
          <IconBolt />
        </BoxIcon>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '3px' }}>
          <TextSpanMediumBold>Choose a Trigger</TextSpanMediumBold>
          <TextSpanSmall $color="var(--light-primary)">
            If this happens...
          </TextSpanSmall>
        </Box>
      </Box>
      {conditionValues &&
        conditionValues.rows.map((input: IRowConditon, index: number) => (
          <Stack key={input.conditionId} direction="row" spacing={2}>
            {/* COLUMN NAME */}
            <Box sx={{ width: '30%' }}>
              <Autocomplete
                aria-required
                onChange={(event: any, newValue: any) => {
                  if (newValue !== null) {
                    handleFormChangeOrderRules(
                      conditionValues.rows,
                      index,
                      event,
                      'columnNameOption',
                      true,
                      newValue,
                    );
                  }
                }}
                id="controllable-states"
                options={optionColumnNamesWithTypes
                  .filter((x) => x.hideFromCondition !== true)
                  .sort((a, b) => a.label.localeCompare(b.label))}
                getOptionLabel={(option: any) => option.label}
                value={input?.columnNameOption || null}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined-required"
                    label="Column Name"
                    size="small"
                    name="columnNameOption"
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </Box>

            {/* CONDITION */}
            <Box sx={{ width: '30%' }}>
              {input.columnNameOption.value === '' && (
                <Autocomplete
                  disabled
                  aria-required
                  id="controllable-states"
                  options={textConditionOption}
                  getOptionLabel={(option: any) => option.label}
                  value={input?.conditionOptionValue || null}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Condition"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              )}
              {input.columnNameOption.value !== '' &&
                input.columnNameOption.value !== 'MatchingCondition' &&
                input.columnNameOption.value !== 'Items' && (
                  <Autocomplete
                    aria-required
                    onChange={(event: any, newValue: any) => {
                      if (newValue !== null) {
                        handleFormChangeOrderRules(
                          conditionValues.rows,
                          index,
                          event,
                          'conditionOptionValue',
                          true,
                          newValue,
                        );

                        if (newValue.value === 'isEmpty') {
                          if (input.columnNameOption.value === 'CarrierType') {
                            handleFormChangeOrderRules(
                              conditionValues.rows,
                              index,
                              event,
                              'conditionOptionSetCarrierDropdownValue',
                              true,
                              null,
                            );
                          } else if (
                            input.columnNameOption.value ===
                              'WarehouseCustomerId' ||
                            input.columnNameOption.value === 'OrderSource' ||
                            input.columnNameOption.value === 'PickAssignment' ||
                            input.columnNameOption.value === 'Status' ||
                            input.columnNameOption.value ===
                              'ShipStationOrderTags' ||
                            input.columnNameOption.value ===
                              'ItemClassification'
                          ) {
                            handleFormChangeOrderRules(
                              conditionValues.rows,
                              index,
                              event,
                              'conditionOptionSetValue',
                              true,
                              null,
                            );
                          }
                        }
                      }
                    }}
                    id="controllable-states"
                    options={
                      input.isConditionSetValueText === true ||
                      input.isConditionSetValueDropdown === true
                        ? textConditionOption
                        : numberConditionOption
                    }
                    getOptionLabel={(option: any) => option.label}
                    value={input?.conditionOptionValue || null}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                        }}
                        autoComplete="off"
                        id="outlined-required"
                        label="Condition"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                )}
              {input.columnNameOption.value !== '' &&
                input.columnNameOption.value === 'Items' && (
                  <Autocomplete
                    aria-required
                    onChange={(event: any, newValue: any) => {
                      if (newValue !== null) {
                        handleFormChangeOrderRules(
                          conditionValues.rows,
                          index,
                          event,
                          'conditionOptionValue',
                          true,
                          newValue,
                        );
                      }
                    }}
                    id="controllable-states"
                    options={textConditionOptionItems}
                    getOptionLabel={(option: any) => option.label}
                    value={input?.conditionOptionValue || null}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                        }}
                        autoComplete="off"
                        id="outlined-required"
                        label="Condition"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                )}
            </Box>

            {/* VALUE */}
            <Box sx={{ width: '30%' }}>
              {input.isConditionSetValueDate === true && (
                <DatePickerInput
                  sx={{ width: '100%', background: 'white' }}
                  inputFormat="MM/dd/yyyy"
                  value={input?.conditionSetValue || null}
                  label="Value"
                  onChange={(event) =>
                    handleFormChangeOrderRules(
                      conditionValues.rows,
                      index,
                      event,
                      'conditionSetValue',
                      false,
                      null,
                      true,
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        width: '100%',
                        background: 'white',
                      }}
                      {...params}
                      error={false}
                      size="small"
                    />
                  )}
                />
              )}
              {input?.isConditionSetValueText === true && (
                <TextField
                  label="Value"
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  name="conditionSetValue"
                  value={input?.conditionSetValue}
                  onChange={(event) => {
                    handleFormChangeOrderRules(
                      conditionValues.rows,
                      index,
                      event,
                      'conditionSetValue',
                      false,
                    );
                  }}
                  InputProps={{
                    autoComplete: 'off',
                  }}
                />
              )}
              {input?.isConditionSetValueNumber === true && (
                <TextField
                  label="Value"
                  size="small"
                  type="number"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  name="conditionSetValue"
                  value={input?.conditionSetValue}
                  onChange={(event) =>
                    handleFormChangeOrderRules(
                      conditionValues.rows,
                      index,
                      event,
                      'conditionSetValue',
                      false,
                    )
                  }
                  InputProps={{
                    autoComplete: 'off',
                  }}
                />
              )}
              {input.isConditionSetValueDropdown === true && (
                <Autocomplete
                  aria-required
                  onChange={(event: any, newValue: any) => {
                    if (newValue !== null) {
                      handleFormChangeOrderRules(
                        conditionValues.rows,
                        index,
                        event,
                        'conditionOptionSetValue',
                        true,
                        newValue,
                      );
                    }
                  }}
                  id="controllable-states"
                  options={getComboboxOptions(input)}
                  getOptionLabel={(option: any) => option.label}
                  value={input?.conditionOptionSetValue || null}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Value"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              )}
              {input.isMultiSelectDropdown === true && (
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={optionItems}
                  value={input?.conditionOptionSetMultiSelectValue || []}
                  disableCloseOnSelect
                  getOptionLabel={(option: ItemOption) => option.label}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  onChange={(event, newValue) => {
                    const data = newValue as ItemOption[];
                    if (newValue !== null) {
                      handleFormChangeOrderRules(
                        conditionValues.rows,
                        index,
                        event,
                        'conditionOptionSetMultiSelectValue',
                        true,
                        data,
                      );
                    } else {
                      handleFormChangeOrderRules(
                        conditionValues.rows,
                        index,
                        event,
                        'conditionOptionSetMultiSelectValue',
                        true,
                        [],
                      );
                    }
                  }}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Value"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              )}

              {input.isCarrierDropdown === true && (
                <Autocomplete
                  aria-required
                  onChange={(event: any, newValue: CarrierType) => {
                    handleFormChangeOrderRules(
                      conditionValues.rows,
                      index,
                      event,
                      'conditionOptionSetCarrierDropdownValue',
                      true,
                      newValue,
                    );
                  }}
                  id="controllable-states"
                  options={carrierOptions}
                  getOptionLabel={(option: any) => option.label}
                  value={input?.conditionOptionSetCarrierDropdownValue || null}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Value"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                  renderOption={(props, option: CarrierType, { selected }) => (
                    <li {...props}>
                      <Box display="flex">
                        <Box display="flex" flexDirection="column">
                          <Typography color="text.primary">
                            {option.label}
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                  )}
                />
              )}

              {input?.columnNameOption?.value === '' && (
                <TextField
                  label="Value"
                  size="small"
                  type="number"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  name="columnNameValue"
                  InputProps={{
                    autoComplete: 'off',
                  }}
                  disabled
                />
              )}
            </Box>
            <Box
              sx={{
                width: '10%',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <ButtonIconClose
                onClick={() =>
                  removeFieldOnRowsCondition(conditionValues.rows, index)
                }
              >
                <IconClose />
              </ButtonIconClose>
            </Box>
          </Stack>
        ))}
      <Box sx={{ display: 'flex', columnGap: '15px', alignItems: 'center' }}>
        <ButtonIconAdd onClick={() => addCondition()}>
          <IconPlus />
        </ButtonIconAdd>
        <TextSpanSmall> Add Conditions</TextSpanSmall>
      </Box>
    </Box>
  );
};

const CreateNewRuleAction = () => {
  const {
    conditionValues,
    addResult,
    removeFieldOnResults,
    handleFormChangeOrderRulesResults,
    packageTypeOptions,
    carrierOptions,
    getComboboxOptions,
  } = useOrderProcessingRulesContext();

  return (
    <Box
      sx={{
        width: '49%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
      }}
    >
      <Box sx={{ display: 'flex', columnGap: '20px', mb: 1 }}>
        <BoxIcon $color="var(--light-primary)">
          <IconPlay />
        </BoxIcon>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '3px' }}>
          <TextSpanMediumBold>Choose an Action</TextSpanMediumBold>
          <TextSpanSmall $color="var(--light-primary)">
            ...then do this
          </TextSpanSmall>
        </Box>
      </Box>
      {conditionValues &&
        conditionValues.results.map((input: IRowConditon, index: number) => (
          <Stack key={input.conditionId} direction="row" spacing={2}>
            <Box sx={{ width: '45%' }}>
              <Autocomplete
                aria-required
                onChange={(event: any, newValue: any) => {
                  if (newValue !== null) {
                    handleFormChangeOrderRulesResults(
                      conditionValues.results,
                      index,
                      event,
                      'columnNameOption',
                      true,
                      newValue,
                    );
                  }
                }}
                id="controllable-states"
                options={optionColumnNamesWithTypes
                  .filter((x) => x.isResult === true)
                  .sort((a, b) => a.label.localeCompare(b.label))}
                getOptionLabel={(option: any) => option.label}
                value={input?.columnNameOption || null}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off',
                    }}
                    autoComplete="off"
                    id="outlined-required"
                    label="Column to update"
                    size="small"
                    name="columnNameOption"
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </Box>
            <Box sx={{ width: '45%' }}>
              {input?.isConditionSetValueText === true &&
                input.columnNameOption.value !== 'PackagingInstructions' && (
                  <TextField
                    label="Value"
                    size="small"
                    sx={{
                      width: '100%',
                      backgroundColor: '#ffffff !important',
                    }}
                    name="conditionSetValue"
                    value={input?.conditionSetValue}
                    onChange={(event) =>
                      handleFormChangeOrderRulesResults(
                        conditionValues.results,
                        index,
                        event,
                        'conditionSetValue',
                        false,
                      )
                    }
                    InputProps={{
                      autoComplete: 'off',
                    }}
                  />
                )}
              {input?.isConditionSetValueText === true &&
                input.columnNameOption.value === 'PackagingInstructions' && (
                  <TextField
                    label="Value"
                    maxRows={10}
                    multiline
                    size="small"
                    sx={{
                      width: '100%',
                      backgroundColor: '#ffffff !important',
                    }}
                    name="conditionSetValue"
                    value={input?.conditionSetValue}
                    onChange={(event) =>
                      handleFormChangeOrderRulesResults(
                        conditionValues.results,
                        index,
                        event,
                        'conditionSetValue',
                        false,
                      )
                    }
                    InputProps={{
                      autoComplete: 'off',
                    }}
                  />
                )}
              {input.isConditionSetValueDropdown === true && (
                <Autocomplete
                  aria-required
                  onChange={(event: any, newValue: any) => {
                    if (newValue !== null) {
                      handleFormChangeOrderRulesResults(
                        conditionValues.results,
                        index,
                        event,
                        'conditionOptionSetValue',
                        true,
                        newValue,
                      );
                    }
                  }}
                  id="controllable-states"
                  options={getComboboxOptions(input)}
                  getOptionLabel={(option: any) => option.label}
                  value={input?.conditionOptionSetValue || null}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Value"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              )}
              {input.isCartonCodeDropdown === true &&
                input.columnNameOption.value === 'PackageType' && (
                  <Autocomplete
                    aria-required
                    onChange={(event: any, newValue: CartonCodeDDLType) => {
                      if (newValue !== null) {
                        handleFormChangeOrderRulesResults(
                          conditionValues.results,
                          index,
                          event,
                          'conditionOptionSetCartonCodeDropdownValue',
                          true,
                          newValue,
                        );
                      }
                    }}
                    id="controllable-states"
                    options={packageTypeOptions}
                    getOptionLabel={(option: CartonCodeDDLType) => option.label}
                    value={
                      input?.conditionOptionSetCartonCodeDropdownValue || null
                    }
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                        }}
                        autoComplete="off"
                        id="outlined-required"
                        label="Value"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                    renderOption={(
                      props,
                      option: CartonCodeDDLType,
                      { selected },
                    ) => (
                      <li {...props}>
                        <Box display="flex">
                          <Box display="flex" flexDirection="column">
                            <Typography color="text.primary">
                              {option.label}
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                              {option.dimensions}
                            </Typography>
                          </Box>
                        </Box>
                      </li>
                    )}
                  />
                )}
              {input.isCarrierDropdown === true &&
                input.columnNameOption.value === 'CarrierType' && (
                  <Autocomplete
                    aria-required
                    onChange={(event: any, newValue: CarrierType) => {
                      if (newValue !== null) {
                        handleFormChangeOrderRulesResults(
                          conditionValues.results,
                          index,
                          event,
                          'conditionOptionSetCarrierDropdownValue',
                          true,
                          newValue,
                        );
                      }
                    }}
                    id="controllable-states"
                    options={carrierOptions}
                    getOptionLabel={(option: CarrierType) => option.label}
                    value={
                      input?.conditionOptionSetCarrierDropdownValue || null
                    }
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                        }}
                        autoComplete="off"
                        id="outlined-required"
                        label="Value"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                    renderOption={(
                      props,
                      option: CarrierType,
                      { selected },
                    ) => (
                      <li {...props}>
                        <Box display="flex">
                          <Box display="flex" flexDirection="column">
                            <Typography color="text.primary">
                              {option.label}
                            </Typography>
                          </Box>
                        </Box>
                      </li>
                    )}
                  />
                )}
              {input.isAdhocDropdown === true && (
                <Box sx={{ width: '100%', display: 'flex', columnGap: '15px' }}>
                  <Box sx={{ width: '70%' }}>
                    <Autocomplete
                      aria-required
                      onChange={(event: any, newValue: any) => {
                        if (newValue !== null) {
                          handleFormChangeOrderRulesResults(
                            conditionValues.results,
                            index,
                            event,
                            'conditionOptionSetValue',
                            true,
                            newValue,
                          );
                        }
                      }}
                      id="controllable-states"
                      options={getComboboxOptions(input)}
                      getOptionLabel={(option: any) => option.label}
                      value={input?.conditionOptionSetValue || null}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                          }}
                          autoComplete="off"
                          id="outlined-required"
                          label="Value"
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ width: '25%' }}>
                    <TextField
                      label="Quantity"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      name="conditionSetValue"
                      value={input?.conditionSetValue}
                      onChange={(event) =>
                        handleFormChangeOrderRulesResults(
                          conditionValues.results,
                          index,
                          event,
                          'conditionSetValue',
                          false,
                        )
                      }
                      InputProps={{
                        autoComplete: 'off',
                      }}
                    />
                  </Box>
                </Box>
              )}
              {input.columnNameOption.value === '' && (
                <TextField
                  label="Value"
                  size="small"
                  type="number"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  name="columnNameValue"
                  InputProps={{
                    autoComplete: 'off',
                  }}
                  disabled
                />
              )}
            </Box>
            <Box sx={{ width: '10%' }}>
              <ButtonIconClose
                onClick={() =>
                  removeFieldOnResults(conditionValues.results, index)
                }
              >
                <IconClose />
              </ButtonIconClose>
            </Box>
          </Stack>
        ))}
      <Box sx={{ display: 'flex', columnGap: '15px', alignItems: 'center' }}>
        <ButtonIconAdd onClick={() => addResult()}>
          <IconPlus />
        </ButtonIconAdd>
        <TextSpanSmall> Add Action</TextSpanSmall>
      </Box>
    </Box>
  );
};

interface CreateNewRuleHeaderProps {
  customerType: CustomerType;
}
const CreateNewRuleHeader = ({ customerType }: CreateNewRuleHeaderProps) => {
  const {
    conditionValues,
    orderProcessingRulesFormError,
    setConditionValues,
    CancelRuleHeaderPage,
    handleOnCreateUpdateOrderRule,
    setCustomerType,
    queryStringOrderProcessingRule,
    isShowRulesForm,
    setIsShowRulesForm,
  } = useOrderProcessingRulesContext();

  const { handleUserPermissionAllow } = useContext(AuthContext);

  const categoryOptions = [
    { label: 'Picking', value: 'Picking' },
    { label: 'Packing', value: 'Packing' },
    { label: 'Carrier', value: 'Carrier' },
    { label: 'Tags', value: 'Tags' },
  ];

  useEffect(() => {
    if (customerType !== undefined && customerType !== null) {
      setCustomerType(customerType);
    }
  }, [customerType]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          height: '34px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '49%',
          }}
        >
          <TextNoWrap variant="body1" sx={{ fontWeight: 'bold', width: '25%' }}>
            Create New Rule
          </TextNoWrap>
          <TextField
            size="small"
            placeholder="New Rule Name"
            value={conditionValues.ruleName}
            name="ruleName"
            InputProps={{
              autoComplete: 'off',
            }}
            onChange={(v) =>
              setConditionValues((e) => ({
                ...e,
                ruleName: v.target.value,
              }))
            }
            required
            {...(orderProcessingRulesFormError.ruleName && {
              error: true,
              helperText: orderProcessingRulesFormError.ruleName,
            })}
            sx={{ width: '63%' }}
            disabled={
              customerType === CustomerType.WarehouseCustomer &&
              !handleUserPermissionAllow(UserPermissionEnum.Client_Rules_Edit)
            }
          />
        </Box>
        <Box sx={{ display: 'flex', columnGap: '15px' }}>
          <ButtonPrimaryOutline
            onClick={() => {
              CancelRuleHeaderPage();
              setIsShowRulesForm(false);
            }}
            disabled={
              customerType === CustomerType.WarehouseCustomer &&
              !handleUserPermissionAllow(UserPermissionEnum.Client_Rules_Edit)
            }
          >
            {isShowRulesForm ? 'Cancel' : 'Reset'}
          </ButtonPrimaryOutline>

          <ButtonPrimary
            disabled={
              (customerType === CustomerType.WarehouseCustomer &&
                Number.isNaN(queryStringOrderProcessingRule?.id)) ||
              (customerType === CustomerType.WarehouseCustomer &&
                !handleUserPermissionAllow(
                  UserPermissionEnum.Client_Rules_Edit,
                ))
            }
            onClick={(e) => {
              if (isShowRulesForm) {
                handleOnCreateUpdateOrderRule(e);
              } else {
                setIsShowRulesForm(true);
              }
            }}
          >
            {isShowRulesForm ? 'Save' : 'Create'}
          </ButtonPrimary>
        </Box>
      </Box>

      {isShowRulesForm && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              height: '34px',
              marginTop: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '49%',
                alignItems: 'left',
              }}
            >
              <TextNoWrap
                variant="body1"
                sx={{ fontWeight: 'bold', width: '25%', marginTop: '8px' }}
              >
                Category
              </TextNoWrap>
              <Autocomplete
                sx={{ width: '50%' }}
                aria-required
                onChange={(event: any, newValue: any) => {
                  if (newValue !== null) {
                    setConditionValues((e) => ({
                      ...e,
                      category: newValue,
                    }));
                  }
                }}
                id="controllable-states"
                options={categoryOptions}
                getOptionLabel={(option: any) => option.label}
                value={conditionValues?.category || null}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                {...(orderProcessingRulesFormError.category && {
                  error: true,
                  helperText: orderProcessingRulesFormError.category,
                })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined-required"
                    label="Category"
                    size="small"
                    name="CategoryOption"
                    sx={{ width: '100%' }}
                    {...(orderProcessingRulesFormError.category && {
                      error: true,
                      helperText: orderProcessingRulesFormError.category,
                    })}
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', rowGap: '15px', mt: 3, mb: 3 }}>
            <CreateNewRuleTrigger />
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderWidth: '1px', margin: '0 1%' }}
            />
            <CreateNewRuleAction />
          </Box>
        </>
      )}
    </>
  );
};

interface OrderProcessingRulesDetailsProps {
  customerType: CustomerType;
}
const OrderProcessingRulesDetails = ({
  customerType,
}: OrderProcessingRulesDetailsProps) => (
  <MainContainer>
    <OrderProcessingRulesContextProvider>
      <Header />
      <ContentContainer>
        <CardBox sx={{ marginBottom: 2, padding: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <CreateNewRuleHeader customerType={customerType} />
          </Box>
        </CardBox>
        <DataGridRules customerType={customerType} />
      </ContentContainer>
    </OrderProcessingRulesContextProvider>
  </MainContainer>
);

interface IOrderProcessingRulesDetailsWithoutHeaderProps {
  customerType: CustomerType;
}
export function OrderProcessingRulesDetailsWithoutHeader({
  customerType,
}: IOrderProcessingRulesDetailsWithoutHeaderProps) {
  return (
    <OrderProcessingRulesContextProvider>
      <CardBox sx={{ marginBottom: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CreateNewRuleHeader customerType={customerType} />
        </Box>
      </CardBox>
      <DataGridRules customerType={customerType} />
    </OrderProcessingRulesContextProvider>
  );
}

export default React.memo(OrderProcessingRulesDetails);
