import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const createReceive = async (data) => {
  try {
    const response = await httpClient.post(`/Receive/CreateReceive`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateReceiveQty = async (data) => {
  try {
    const response = await httpClient.post(`/Receive/Update/Qty`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const cancelReceive = async (data) => {
  try {
    const response = await httpClient.post(`/Receive/Delete`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// Receive V2 endpoints
export const createReceiveV2 = async (receiveForm) => {
  try {
    const response = await httpClient.post(`/receive/create`, receiveForm);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateReceiveV2 = async (updateReceiveForm) => {
  try {
    const response = await httpClient.post(
      `/Receive/Update`,
      updateReceiveForm,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const closeReceiveV2 = async (closeForm) => {
  try {
    const response = await httpClient.post(`/Receive/Close`, closeForm);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteReceiveV2 = async (deleteForm) => {
  try {
    const response = await httpClient.post(`/Receive/Delete`, deleteForm);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateReceiveStatusV2 = async (updateStatusForm) => {
  try {
    const response = await httpClient.post(
      `/Receive/Update/Status`,
      updateStatusForm,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
