import styled from 'styled-components';

import { Button } from '@mui/material';

export const ButtonIconClose = styled('button')`
  background: red;
  color: #fff;
  border: 0;
  border-radius: 50%;
  width: 19px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background: #cb0e0e;
  }
  svg {
    fill: #fff;
    width: 13px;
    height: 13px;
  }
`;

export const ButtonIconAdd = styled('button')`
  background: #052fff;
  color: #fff;
  border: 0;
  border-radius: 50%;
  width: 24px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background: #0323c5;
  }
  svg {
    fill: #fff;
    width: 19px;
    height: 19px;
  }
`;

export const IconBoxPrimaryOutline = styled(Button)`
  background: #fff;
  border: 1px solid #052fff;
  color: #052fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 20px;
  &:hover,
  &:active,
  &:focus {
    background: #0323c5;
    svg {
      fill: #fff;
    }
  }
  svg {
    fill: #052fff;
    width: 15px;
    height: 15px;
  }
`;

export const IconBoxDangerOutline = styled(Button)`
  background: #fff;
  border: 1px solid #ff5e5e;
  color: ##ff5e5e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 20px;
  &:hover,
  &:active,
  &:focus {
    background: #ff5e5e;
    svg {
      fill: #fff;
    }
  }
  svg {
    fill: #ff5e5e;
    width: 15px;
    height: 15px;
  }
`;
