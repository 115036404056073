/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import Card from 'components/card';
import Header from 'components/header';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import warehousecustomer from 'pages/ordercustomers/warehousecustomer';
import Printformtemplate from 'pages/shared/printformtemplate';
import { ContentContainer, MainContainer } from 'pages/styles';
import { getWarehouseCustomerLookup } from 'services/api/inventory/inventory-new.api';
import {
  createFormTemplate,
  getFormTemplateById,
  UpdateFormTemplateAsync,
} from 'services/api/printoutforms/printoutform.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Checkbox,
  Grid,
  TextField,
  Typography,
  Button,
  Box,
} from '@mui/material';
import { selectedIdsLookupSelector } from '@mui/x-data-grid';
import { Editor } from '@tinymce/tinymce-react';

type AutocompleteBase = {
  label?: string;
  value?: number;
};
type CustomerTypes = AutocompleteBase;
type FormTypes = AutocompleteBase;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
type QueryStringType = {
  form: string;
  id: number;
};
type PageViewerType = {
  isNew: () => boolean;
  isView?: () => boolean;
  isEdit?: () => boolean;
  isViewOrEdit: () => boolean;
};
enum FormQueryStringStatus {
  View = 'view',
  New = 'new',
  Edit = 'edit',
}
export const optionFormTypes: readonly FormTypes[] = [
  { value: 1, label: 'Pick Ticket' },
  { value: 2, label: 'Bill of Lading' },
  { value: 3, label: 'Packing List' },
  { value: 4, label: 'Purchase Order' },
  { value: 5, label: 'Invoice' },
  { value: 6, label: 'Pallet Labels' },
  { value: 7, label: 'Order Detail' },
];

export const PrintOutFormContext = createContext(
  {} as {
    editorRef: any;
    form: any;
    setForm: any;
    formErrors: any;
    optionWarehouseCustomers: any;
    onClickCreateFormTemplate: (e: React.SyntheticEvent) => void;
    pageViewerForms: any;
    queryStringItemForm: any;
    tinyMCEText: any;
    tinyMCEValue: any;
    setFormErrors: any;
    setQueryStringItemForm: any;
    setTinyMCEText: any;
    setTinyMCEValue: any;
    setWarehouseCustomerValue: any;
    warehouseCustomerValue: any;
  },
);

export const usePrintOutFormContext = () => useContext(PrintOutFormContext);

export const PrintOutFormContextProvider = ({ children }: any) => {
  const query = useQuery();
  const navigate = useNavigate();

  const initialStateOfQueryParams: QueryStringType = {
    form: query.get('form'),
    id: Number(query.get('id')),
  };

  const [queryStringItemForm, setQueryStringItemForm] = useState(
    initialStateOfQueryParams,
  );

  const pageViewerForms: PageViewerType = {
    isNew: () => queryStringItemForm.form === FormQueryStringStatus.New,
    isView: () =>
      queryStringItemForm.form === FormQueryStringStatus.View &&
      queryStringItemForm.id > 0,
    isEdit: () =>
      queryStringItemForm.form === FormQueryStringStatus.Edit &&
      queryStringItemForm.id > 0,
    isViewOrEdit: () =>
      (queryStringItemForm.form === FormQueryStringStatus.View ||
        queryStringItemForm.form === FormQueryStringStatus.Edit) &&
      queryStringItemForm.id > 0,
  };

  const initialWarehouseCustomerId: any = {
    warehouseCustomerId: '',
    warehouseCustomerName: '',
  };

  const initialFormState: any = {
    formTemplateId: 0,
    name: '',
    type: {
      value: 0,
      label: '',
    },
    htmlString: '',
    warehouseCustomers: [initialWarehouseCustomerId],
  };

  const initialFormErrorsState: any = {
    name: '',
    type: '',
    htmlString: '',
    warehouseCustomers: '',
  };

  const { currentUser } = useContext(AuthContext);

  const [form, setForm] = useState<any>(initialFormState);
  const [formErrors, setFormErrors] = useState(initialFormErrorsState);
  const [optionWarehouseCustomers, setOptionWarehouseCustomers] = useState<
    CustomerTypes[]
  >([]);
  const [warehouseCustomerValue, setWarehouseCustomerValue] = useState([]);

  const [tinyMCEText, setTinyMCEText] = useState('');
  const [tinyMCEValue, setTinyMCEValue] = useState('');
  const editorRef = useRef(null);

  const onLoadWarehouseCustomerLookup = async () => {
    try {
      return await getWarehouseCustomerLookup(currentUser.Claim_CustomerId, '');
    } catch (err) {
      return err;
    }
  };

  const onLoadFormTemplateDetail = async (id: number) => {
    try {
      const formTemplateLookupFromApi = await getFormTemplateById(id);

      return formTemplateLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const validateOnlick = (fieldValues = form) => {
    const temp: any = {};

    if ('name' in fieldValues) {
      temp.name = fieldValues.name ? '' : 'This field is required';
    }
    if ('type' in fieldValues) {
      temp.type = fieldValues.type.value !== 0 ? '' : 'This field is required';
    }
    if ('htmlString' in fieldValues) {
      if (fieldValues.htmlString) {
        temp.htmlString = '';
      } else {
        snackActions.error('Form editor field is required');
      }
    }

    // if ('warehouseCustomers' in fieldValues) {
    //   temp.warehouseCustomers =
    //     fieldValues.warehouseCustomers.length !== 0
    //       ? ''
    //       : 'This field is required';
    // }

    setFormErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const onClickCreateFormTemplate = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();
    form.warehouseCustomers = warehouseCustomerValue.map((x) => ({
      warehouseCustomerId: x.value,
      warehouseCustomerName: x.label,
    }));

    form.htmlString = tinyMCEValue;

    if (validateOnlick()) {
      const payload = {
        customerId: parseInt(currentUser.Claim_CustomerId, 10),
        formTemplateId: 0,
        name: form.name,
        type: form.type.value,
        typeName: form.type.label,
        htmlString: form.htmlString,
        warehouseCustomers: form.warehouseCustomers,
      };

      try {
        if (pageViewerForms.isNew()) {
          const result = await createFormTemplate(payload);
          snackActions.success(`Successfully created new form template.`);
          setQueryStringItemForm((prevState) => ({
            ...prevState,
            form: 'view',
            id: result.formTemplateId,
          }));
          navigate(
            `/printoutforms/formdetails?form=view&id=${result.formTemplateId}`,
          );
        } else if (pageViewerForms.isEdit()) {
          payload.formTemplateId =
            form.formTemplateId === 0
              ? queryStringItemForm.id
              : form.formTemplateId;
          const result = await UpdateFormTemplateAsync(payload);
          snackActions.success(`Successfully updated form template.`);
          setQueryStringItemForm((prevState) => ({
            ...prevState,
            form: 'view',
            id: result.formTemplateId,
          }));
          navigate(
            `/printoutforms/formdetails?form=view&id=${result.formTemplateId}`,
          );
        }
      } catch (err) {
        snackActions.error(err);
      }
    }
  };

  useEffect(() => {
    if (!pageViewerForms.isNew()) {
      if (pageViewerForms.isEdit() || pageViewerForms.isView()) {
        onLoadFormTemplateDetail(queryStringItemForm.id).then((x) => {
          setForm(() => ({
            formTemplateId: x.formTemplateId,
            name: x.name,
            type: {
              value: x.type,
              label: x.typeName,
            },
            htmlString: x.htmlString,
          }));

          const selectedIDs = new Set(
            x.formTemplateWarehouseCustomers.map((v) => v.warehouseCustomerId),
          );

          setWarehouseCustomerValue(
            optionWarehouseCustomers.filter((input) =>
              selectedIDs.has(input.value),
            ),
          );
          setTinyMCEValue(x.htmlString);

          if (warehouseCustomerValue.length === 0) {
            setWarehouseCustomerValue([
              {
                value: 0,
                label: currentUser.Claim_CustomerName,
              },
            ]);
          }
        });
      } else {
        navigate('/printoutforms');
      }
    }
  }, [optionWarehouseCustomers]);
  useEffect(() => {
    const optionsCustomer = onLoadWarehouseCustomerLookup();
    optionsCustomer
      .then((opt) => {
        setOptionWarehouseCustomers(
          opt.map((c: any) => ({
            label: c.name,
            value: c.warehouseCustomerId,
          })),
        );
      })
      .catch((err) => console.log(err));
  }, [children]);

  const value = useMemo(
    () => ({
      editorRef,
      form,
      setForm,
      formErrors,
      optionWarehouseCustomers,
      onClickCreateFormTemplate,
      pageViewerForms,
      queryStringItemForm,
      tinyMCEText,
      tinyMCEValue,
      setFormErrors,
      setQueryStringItemForm,
      setTinyMCEText,
      setTinyMCEValue,
      setWarehouseCustomerValue,
      warehouseCustomerValue,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      editorRef,
      form,
      setForm,
      formErrors,
      optionWarehouseCustomers,
      onClickCreateFormTemplate,
      queryStringItemForm,
      tinyMCEText,
      tinyMCEValue,
      setFormErrors,
      setQueryStringItemForm,
      setTinyMCEText,
      setTinyMCEValue,
      setWarehouseCustomerValue,
      warehouseCustomerValue,
    ],
  );

  return (
    <PrintOutFormContext.Provider value={value}>
      {children}
    </PrintOutFormContext.Provider>
  );
};

const PrintOutFormDetails = () => {
  const { onOpenPrintFormTemplateModal } = useContext(GlobalContext);

  const {
    editorRef,
    form,
    formErrors,
    optionWarehouseCustomers,
    pageViewerForms,
    setForm,
    setTinyMCEText,
    setTinyMCEValue,
    tinyMCEValue,
    setWarehouseCustomerValue,
    warehouseCustomerValue,
    queryStringItemForm,
  } = usePrintOutFormContext();

  const [boolPreview, setboolPreview] = useState<boolean>(true);
  const [InputId, setInputId] = useState<number>(form.formTemplateId);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100% !important',
        margin: '16px',
        flexDirection: 'column',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100% !important',
        }}
      >
        <Grid container spacing={3} sx={{ height: '100%' }}>
          <Grid item xs={2}>
            <TextField
              required
              id="outlined-required"
              label="Form Name"
              size="small"
              value={form.name}
              error={formErrors.name}
              inputProps={{ 'data-state': 'FormName', autoComplete: 'off' }}
              onChange={(event) => {
                setForm((e) => ({ ...e, name: event.target.value }));
              }}
              sx={{
                marginBottom: 2,
                flexDirection: 'column',
                padding: '0px !important',
                backgroundColor: 'white !important',
                width: '100%',
              }}
              disabled={pageViewerForms.isView()}
            />
            <Autocomplete
              id="controllable-states"
              disableClearable
              options={optionFormTypes}
              value={form.type}
              getOptionLabel={(option: FormTypes) => option.label}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              onChange={(event: any, newValue) => {
                if (newValue !== null) {
                  setForm(() => ({
                    ...form,
                    type: newValue,
                  }));
                }
              }}
              disabled={pageViewerForms.isView()}
              renderInput={(params) =>
                formErrors.type ? (
                  <TextField
                    {...params}
                    required
                    error
                    helperText={formErrors.type}
                    label="Form Type"
                    sx={{
                      marginBottom: 2,
                      flexDirection: 'column',
                      padding: '0px !important',
                      backgroundColor: 'white !important',
                    }}
                  />
                ) : (
                  <TextField
                    {...params}
                    required
                    label="Form Type"
                    sx={{
                      marginBottom: 2,
                      flexDirection: 'column',
                      padding: '0px !important',
                      backgroundColor: 'white !important',
                    }}
                  />
                )
              }
            />
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={optionWarehouseCustomers}
              value={warehouseCustomerValue}
              disableCloseOnSelect
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              getOptionLabel={(option: CustomerTypes) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              onChange={(event: any, newValue) => {
                if (newValue !== null) {
                  setWarehouseCustomerValue(newValue);
                } else {
                  setWarehouseCustomerValue(null);
                }
              }}
              disabled={pageViewerForms.isView()}
              renderInput={(params) =>
                formErrors.warehouseCustomers ? (
                  <TextField
                    {...params}
                    error
                    helperText={formErrors.warehouseCustomers}
                    label="Client"
                    sx={{
                      marginBottom: 3,
                      flexDirection: 'column',
                      padding: '0px !important',
                      backgroundColor: 'white !important',
                    }}
                  />
                ) : (
                  <TextField
                    {...params}
                    label="Client"
                    sx={{
                      marginBottom: 3,
                      flexDirection: 'column',
                      padding: '0px !important',
                      backgroundColor: 'white !important',
                    }}
                  />
                )
              }
            />

            <Button
              variant="contained"
              size="large"
              onClick={() => onOpenPrintFormTemplateModal()}
            >
              Preview
            </Button>
          </Grid>

          <Grid item xs={10}>
            <Editor
              apiKey="ghr9fy9bhnllh7r2pxm6jyf09550ggekjp919vxlpys2fupy"
              cloudChannel="6-dev"
              id="mytextarea"
              value={tinyMCEValue}
              init={{
                height: '105%',
                forced_root_block: 'div',
                entity_encoding: 'raw',
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'preview',
                  'help',
                  'wordcount',
                  'image',
                  'codesample',
                ],
              }}
              onInit={(evt, editor) => {
                setTinyMCEText(editor.getContent({ format: 'text' }));
                editorRef.current = editor;
              }}
              onEditorChange={(newValue, editor) => {
                setTinyMCEValue(newValue);
                setTinyMCEText(editor.getContent({ format: 'text' }));
              }}
              disabled={pageViewerForms.isView()}
            />
          </Grid>
        </Grid>
        <Printformtemplate
          preview={boolPreview}
          previewId={queryStringItemForm.id}
        />
      </Card>
    </Box>
  );
};

const FormsDetails = () => (
  <MainContainer>
    <PrintOutFormContextProvider>
      <Header />
      <PrintOutFormDetails />
    </PrintOutFormContextProvider>
  </MainContainer>
);

export default React.memo(FormsDetails);
