import * as THREE from 'three';

const createRackLabel = (text) => {
  const shelfThickness = 1;
  const canvasHeight = shelfThickness * 100;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = `Bold ${canvasHeight * 0.8}px Arial`;
  context.fillStyle = 'rgba(255,255,255,1)';
  const textWidth = context.measureText(text).width;

  canvas.width = textWidth + 20;
  canvas.height = canvasHeight;

  context.font = `Bold ${canvasHeight * 0.8}px Arial`;
  context.fillStyle = 'rgba(255,255,255,1)';
  context.fillText(text, 10, canvasHeight * 0.8);

  const texture = new THREE.CanvasTexture(canvas);
  texture.needsUpdate = true;

  const material = new THREE.SpriteMaterial({ map: texture });
  const sprite = new THREE.Sprite(material);
  sprite.scale.set(canvas.width / 100, canvas.height / 100, 1);

  return sprite;
};

export const createRack = (aisle, row, levels) => {
  const beamMaterial = new THREE.MeshStandardMaterial({ color: 0x252525 });
  const shelfMaterial = new THREE.MeshStandardMaterial({ color: 0x5f5f5f });
  const edgeMaterial = new THREE.LineBasicMaterial({ color: 0x3c3c3c });

  const beamGeometry = new THREE.BoxGeometry(1, levels * 5 + 5, 1);
  const shelfGeometry = new THREE.BoxGeometry(10, 1, 10);
  const horizontalBeamGeometry = new THREE.BoxGeometry(10, 1, 1);

  const rack = new THREE.Group();

  const support1 = new THREE.Mesh(beamGeometry, beamMaterial);
  const support2 = new THREE.Mesh(beamGeometry, beamMaterial);
  const support3 = new THREE.Mesh(beamGeometry, beamMaterial);
  const support4 = new THREE.Mesh(beamGeometry, beamMaterial);

  support1.position.set(-5, (levels * 5 + 5) / 2 - 2.5, -5);
  support2.position.set(5, (levels * 5 + 5) / 2 - 2.5, -5);
  support3.position.set(-5, (levels * 5 + 5) / 2 - 2.5, 5);
  support4.position.set(5, (levels * 5 + 5) / 2 - 2.5, 5);

  rack.add(support1, support2, support3, support4);

  for (let i = 0; i < levels; i += 1) {
    const horizontalBeam1 = new THREE.Mesh(
      horizontalBeamGeometry,
      beamMaterial,
    );
    const horizontalBeam2 = new THREE.Mesh(
      horizontalBeamGeometry,
      beamMaterial,
    );

    horizontalBeam1.position.set(0, i * 5, -5);
    horizontalBeam2.position.set(0, i * 5, 5);

    rack.add(horizontalBeam1, horizontalBeam2);

    const shelf = new THREE.Mesh(shelfGeometry, shelfMaterial);
    shelf.position.set(0, i * 5, 0);

    const edges = new THREE.EdgesGeometry(shelfGeometry);
    const shelfEdges = new THREE.LineSegments(edges, edgeMaterial);
    shelf.add(shelfEdges);

    rack.add(shelf);

    const label = createRackLabel(`A:${aisle + 1}, R:${row + 1}, L:${i + 1}`);
    label.name = 'rackLabel';
    label.visible = false;
    label.position.set(shelfGeometry.parameters.width / 2 + 3, 3, 0);
    shelf.add(label);
  }

  return rack;
};
