import React, { useEffect, ChangeEvent, useContext, useState } from 'react';

import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { getCarrierAccountByCarrierId } from 'services/api/integrations/easypost/easypost.api';
import {
  getAllCarrierTypes,
  updateCarrierAccount,
  getCarrierServicesByName,
  connectCarrierAccount,
  getCarrierDetails,
} from 'services/api/integrations/shipment/shipment.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Grid as MUIGrid,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Switch,
} from '@mui/material';

import { MarkupMethodOption } from '../shipping-account-edit-modal';

const initialFormErrorsState = {
  carrierId: '',
  accountId: '',
};

const initialState = {
  carrierId: '',
  accountId: '',
  customWorkFlow: false,
};

export default React.memo(() => {
  const { isCarrierAccountModalOpen, onCloseCarrierAccountModal } =
    useContext(GlobalContext);

  const { form, setForm, loadCarriers } = useWarehouseCustomerContext();

  const [formErrors, setFormErrors] = useState<any>(initialFormErrorsState);
  const [carrierForm, setCarrierForm] = useState<any>(initialState);
  const [carrierTypes, setCarrierTypes] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState<any>(null);
  const [carrierFields, setCarrierFields] = useState([]);
  const [serviceLevels, setServiceLevels] = useState([]);
  const [predefinedPackages, setPredefinedPackages] = useState([]);

  const InitialMarkupData = {
    isNoMarkUp: true,
    markupMethodType: null,
    markupPercent: '',
  };

  const [markupData, setMarkupData] = useState<any>(InitialMarkupData);

  const onForm = (key, text) => {
    setCarrierForm(() => ({
      ...carrierForm,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) =>
    onForm(key, event.target.value);

  const handleCarrierTypeChange = (selected) => {
    setCarrierFields([]);
    setSelectedCarrier(selected);

    setCarrierForm(initialState);
    if (selected === null) {
      setCarrierForm(initialState);
    } else {
      getCarrierDetails(selected.readable, form?.warehouseCustomerId)
        .then((res) => {
          if (res) {
            setCarrierForm((prev) => ({
              ...prev,
              ...res.credentials,
              accountId: res.accountId,
            }));
          }

          getCarrierServicesByName(selected.type).then((response) => {
            setServiceLevels(response.filter((x) => x.type === 0));
            setPredefinedPackages(response.filter((x) => x.type === 1));
          });

          setCarrierForm((prev) => ({
            ...prev,
            carrierId: selected.type,
            customWorkFlow: selected.fields.custom_workflow || false,
          }));
          const entries = [];

          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(
            selected.fields.credentials,
          )) {
            const entry = value as CarrierType;
            if (entry.visibility !== 'invisible') {
              entries.push({
                fieldId: key,
                label: entry.label,
                visibility:
                  entry.visibility === 'readonly'
                    ? 'visible'
                    : entry.visibility,
              });
            }
          }

          if (selected.fields.creation_fields) {
            if (selected.fields.creation_fields.address_information) {
              entries.push({
                fieldId: '',
                label: 'Address Information',
                visibility: 'title',
              });

              // eslint-disable-next-line no-restricted-syntax
              for (const [key, value] of Object.entries(
                selected.fields.creation_fields.address_information,
              )) {
                const entry = value as CarrierType;
                entries.push({
                  fieldId: key,
                  label: entry.label,
                  visibility: entry.visibility,
                });
              }
            }

            if (selected.fields.creation_fields.company_information) {
              entries.push({
                fieldId: '',
                label: 'Company Information',
                visibility: 'title',
              });

              // eslint-disable-next-line no-restricted-syntax
              for (const [key, value] of Object.entries(
                selected.fields.creation_fields.company_information,
              )) {
                const entry = value as CarrierType;
                entries.push({
                  fieldId: key,
                  label: entry.label,
                  visibility: entry.visibility,
                });
              }
            }

            if (selected.fields.creation_fields.credential_information) {
              entries.push({
                fieldId: '',
                label: 'Credential Information',
                visibility: 'title',
              });

              // eslint-disable-next-line no-restricted-syntax
              for (const [key, value] of Object.entries(
                selected.fields.creation_fields.credential_information,
              )) {
                const entry = value as CarrierType;
                entries.push({
                  fieldId: key,
                  label: entry.label,
                  visibility: entry.visibility,
                });
              }
            }

            if (selected.fields.creation_fields.registration_data) {
              // eslint-disable-next-line no-restricted-syntax
              for (const [key, value] of Object.entries(
                selected.fields.creation_fields.registration_data,
              )) {
                const entry = value as CarrierType;
                entries.push({
                  fieldId: key,
                  label: entry.label,
                  visibility: entry.visibility,
                });
              }
            }
          }

          if (carrierForm.accountId !== '' && res) {
            setCarrierFields(
              entries.filter((x) => x.fieldId in res.credentials),
            );
          } else {
            setCarrierFields(entries);
          }
        })
        .catch((err) => snackActions.error(err));
    }
  };

  const getCarrierAccount = async (carrierId: number) => {
    try {
      const carrierAccount = await getCarrierAccountByCarrierId(carrierId);
      setCarrierForm((prev) => ({
        ...prev,
        carrierId: carrierAccount.carrierId,
        accountId: carrierAccount.accountId,
        carrierName: carrierAccount.carrierName,
      }));

      handleCarrierTypeChange(
        carrierTypes.filter(
          (x) => x.readable === carrierAccount.carrierName,
        )[0],
      );
      return carrierAccount;
    } catch (err) {
      return err;
    }
  };

  type CarrierType = {
    label: string;
    visibility: string;
  };

  useEffect(() => {
    if (
      form.selectedCarrierId === undefined ||
      form.selectedCarrierId === null
    ) {
      form.selectedCarrierId = 0;
    }

    getAllCarrierTypes()
      .then((res) => {
        setCarrierTypes(res);

        if (form.selectedCarrierId !== 0) {
          getCarrierAccount(form.selectedCarrierId).then((carrierAccount) => {
            handleCarrierTypeChange(
              res.filter((x) => x.readable === carrierAccount.carrierName)[0],
            );
          });
        }
      })
      .catch((err) => {
        snackActions.error(err);
      });
  }, [isCarrierAccountModalOpen]);

  const handleCloseModal = () => {
    setFormErrors((temp) => ({
      ...temp,
      carrierId: '',
      accountId: '',
    }));

    setCarrierForm(initialState);
    setSelectedCarrier(null);
    setForm(() => ({
      ...form,
      selectedCarrierId: 0,
    }));

    onCloseCarrierAccountModal();
    setMarkupData(InitialMarkupData);
    setServiceLevels([]);
    setPredefinedPackages([]);
  };

  const handleCarrierCreation = async () => {
    try {
      if (carrierForm.accountId === '') {
        const response = await connectCarrierAccount(
          {
            carrierId: carrierForm.carrierId,
            jsonPayload: JSON.stringify(carrierForm),
            isNoMarkUp: markupData?.isNoMarkUp,
            markupMethodType: markupData?.markupMethodType?.value,
            markPercent: markupData?.markupPercent,
            customWorkFlow: carrierForm.customWorkFlow,
          },
          form.warehouseCustomerId,
        );

        if (
          (response !== '' && carrierForm.customWorkFlow) ||
          carrierForm.carrierId.toLowerCase() === 'upsaccount'
        ) {
          window.open(response, '_blank');
        }

        snackActions.success('Carrier account created successfully.');
        loadCarriers();
        handleCloseModal();
      } else {
        const response = await updateCarrierAccount(
          {
            carrierId: carrierForm.carrierId,
            jsonPayload: JSON.stringify(carrierForm),
          },
          carrierForm.accountId,
          form.warehouseCustomerId,
        );

        if (
          (response !== '' && carrierForm.customWorkFlow) ||
          carrierForm.carrierId.toLowerCase() === 'upsaccount'
        ) {
          window.open(response, '_blank');
        }

        snackActions.success('Carrier account updated successfully.');
        handleCloseModal();
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  return (
    <Modal open={isCarrierAccountModalOpen}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          {carrierForm.accountId === '' && <>Add Carrier Account</>}
          {carrierForm.accountId !== '' && <>Edit Carrier Account</>}
        </Typography>
        <MUIContent>
          <MUIGrid
            container
            direction="row"
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            mt={-3}
          >
            <MUIGrid item xs={12}>
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={12} sm={12}>
                  <Autocomplete
                    onChange={(event: any, newValue) => {
                      handleCarrierTypeChange(newValue);
                    }}
                    id="controllable-states"
                    options={carrierTypes}
                    getOptionLabel={(option) => option.readable}
                    value={selectedCarrier}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option) => (
                      <li
                        data-name="carrierIntegrationType"
                        {...props}
                        key={option.readable}
                      >
                        {option.readable}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        {...(formErrors.carrierIntegrationType && {
                          error: true,
                          helperText: formErrors.carrierIntegrationType,
                        })}
                        id="outlined-required"
                        label="Carrier"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </MUIGrid>

                {selectedCarrier != null &&
                  carrierFields.map((row) => {
                    if (row.visibility === 'title') {
                      return (
                        <MUIGrid item xs={12} key={row.fieldId}>
                          <Typography
                            sx={{ marginBotton: '16px' }}
                            variant="h6"
                            fontWeight="bold"
                          >
                            {row.label}
                          </Typography>
                        </MUIGrid>
                      );
                    }

                    return (
                      <MUIGrid item xs={6} key={row.fieldId}>
                        {/* Text Fields */}
                        {(row.visibility === 'visible' ||
                          row.visibility === 'fake' ||
                          row.visibility === 'masked') && (
                          <Input
                            placeholder={row.label}
                            sx={{ width: '100%' }}
                            value={carrierForm[row.fieldId]}
                            onChange={(value) => {
                              inputHandler(row.fieldId, value);
                            }}
                          />
                        )}

                        {/* Password Fields */}
                        {row.visibility === 'password' && (
                          <Input
                            placeholder={row.label}
                            sx={{ width: '100%' }}
                            value={carrierForm[row.fieldId]}
                            type="password"
                            onChange={(value) => {
                              inputHandler(row.fieldId, value);
                            }}
                          />
                        )}

                        {/* Checkbox Fields */}
                        {row.visibility === 'checkbox' && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                size="small"
                                checked={carrierForm[row.fieldId] || false}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  onForm(row.fieldId, event.target.checked);
                                }}
                              />
                            }
                            label={row.label}
                          />
                        )}
                      </MUIGrid>
                    );
                  })}
              </MUIGrid>
            </MUIGrid>

            <MUIGrid item xs={12}>
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                mt={1}
                sx={{ display: 'flex', alignItems: 'flex-start' }}
              >
                <MUIGrid
                  item
                  xs={3}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Switch
                    checked={markupData?.isNoMarkUp}
                    onChange={(v) =>
                      setMarkupData((e) => ({
                        ...e,
                        isNoMarkUp: v.target.checked,
                      }))
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <p>No Mark Up</p>
                </MUIGrid>

                <MUIGrid item xs={9}>
                  <MUIGrid
                    container
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    {!markupData?.isNoMarkUp && (
                      <>
                        <MUIGrid item xs={6}>
                          <Autocomplete
                            id="controllable-states"
                            options={MarkupMethodOption}
                            value={markupData?.markupMethodType}
                            onChange={(event: any, newValue) => {
                              if (newValue !== null) {
                                setMarkupData((e) => ({
                                  ...e,
                                  markupMethodType: newValue,
                                }));
                              } else {
                                setMarkupData((e) => ({
                                  ...e,
                                  markupMethodType: null,
                                }));
                              }
                            }}
                            getOptionLabel={(option: any) => option.label}
                            isOptionEqualToValue={(option, selected) =>
                              option.value === selected.value
                            }
                            renderOption={(props, option) => (
                              <li {...props} key={option.value}>
                                {option.label}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: Math.random().toString(),
                                }}
                                autoComplete="off"
                                id="outlined-required"
                                label="Default Markup Method"
                                size="small"
                                sx={{ width: '100%' }}
                              />
                            )}
                          />
                        </MUIGrid>
                        <MUIGrid item xs={6}>
                          <TextField
                            size="small"
                            sx={{
                              backgroundColor: '#ffffff !important',
                              width: '100%',
                            }}
                            label="Default Markup %"
                            value={markupData?.markupPercent}
                            InputProps={{
                              autoComplete: Math.random().toString(),
                            }}
                            onChange={(e) => {
                              let validateDecimal = decimalValidationHelper(
                                e.target.value,
                              );

                              if (Number(validateDecimal) < 0) {
                                validateDecimal = '0';
                              }
                              setMarkupData((prev) => ({
                                ...prev,
                                markupPercent: validateDecimal,
                              }));
                            }}
                          />
                        </MUIGrid>
                      </>
                    )}

                    <MUIGrid item xs={6}>
                      <Box mt={0.3} className="ModalBoxTemplate">
                        <Typography variant="h6" fontWeight="bold" mb={0.5}>
                          Service Levels
                        </Typography>
                        <ul>
                          {serviceLevels != null &&
                            serviceLevels.map((row) => (
                              <li
                                style={{
                                  listStyleType: 'none',
                                  fontSize: '16px',
                                }}
                              >
                                <b>{row.human_readable ?? `- ${row.name} `} </b>
                                {row.description ? (
                                  <p> &nbsp;&nbsp;{row.description}</p>
                                ) : (
                                  ``
                                )}
                              </li>
                            ))}
                        </ul>
                      </Box>
                    </MUIGrid>

                    <MUIGrid item xs={6}>
                      <Box mt={0.3} className="ModalBoxTemplate">
                        <Typography variant="h6" fontWeight="bold" mb={0.5}>
                          Predefined Packages
                        </Typography>
                        <ul>
                          {predefinedPackages != null &&
                            predefinedPackages.map((row) => (
                              <li
                                style={{
                                  listStyleType: 'none',
                                  fontSize: '16px',
                                }}
                              >
                                <b>- {row.name}:</b>
                                {row.dimensions.length > 0 ? (
                                  <p>&nbsp;&nbsp;{row.dimensions}</p>
                                ) : (
                                  ``
                                )}
                                {row.maxWeight ? (
                                  <p>
                                    &nbsp;&nbsp;- Max Weight: ${row.maxWeight}
                                  </p>
                                ) : (
                                  ``
                                )}
                              </li>
                            ))}
                        </ul>
                      </Box>
                    </MUIGrid>
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => {
                  handleCloseModal();
                }}
                variant="outlined"
                size="medium"
                sx={{ padding: 1, width: '100%' }}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                size="medium"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={async (e) => {
                  e.preventDefault();

                  handleCarrierCreation();
                }}
              >
                {carrierForm.accountId === '' && <>ADD</>}
                {carrierForm.accountId !== '' &&
                  carrierForm.carrierId.toLowerCase() !== 'upsaccount' && (
                    <>EDIT</>
                  )}
                {carrierForm.accountId !== '' &&
                  carrierForm.carrierId.toLowerCase() === 'upsaccount' && (
                    <>Authenticate</>
                  )}
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
