import React, { useContext, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { useInvoiceDetailFormContext } from 'pages/shared/invoicedetailform/context';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Button,
  Grid as MUIGrid,
  CircularProgress,
} from '@mui/material';

import { useInvoiceInterfaceTabContext } from '../../context';

function VoidConfirmationModal() {
  const {
    isVoidConfirmationModalOpen,
    setIsVoidConfirmationModalOpen,
    isDeleteInvoiceModalOpen,
    setisDeleteInvoiceModalOpen,
    isSubmitInvoiceModalOpen,
    setIsSubmitInvoiceModalOpen,
  } = useContext(GlobalContext);

  const { handleUpdateInvoiceStatus, deleteInvoice } =
    useInvoiceInterfaceTabContext();

  const { state } = useInvoiceDetailFormContext();

  const [loading, setLoading] = useState(false);

  // void ---------------------------------------------------
  const handleCloseVoidOnCLick = () => {
    setIsVoidConfirmationModalOpen(false);
    setLoading(false);
  };

  const handleVoidInvoice = async () => {
    setLoading(true);

    try {
      await handleUpdateInvoiceStatus(state.vm.form.invoiceId, 4).then(() => {
        snackActions.success('Invoice voided successfully');
        handleCloseVoidOnCLick();
      });
    } catch (err) {
      setLoading(false);
    }
  };

  // delete ---------------------------------------------------
  const handleCloseDeleteOnCLick = () => {
    setisDeleteInvoiceModalOpen(false);
    setLoading(false);
  };

  const handleDeleteInvoice = async () => {
    setLoading(true);

    try {
      await deleteInvoice(state.vm.form.invoiceId);

      handleCloseDeleteOnCLick();
    } catch (err) {
      setLoading(false);
    }
  };

  // submit ---------------------------------------------------
  const handleCloseSubmitOnCLick = () => {
    setIsSubmitInvoiceModalOpen(false);
    setLoading(false);
  };

  const handleSubmitInvoice = async () => {
    setLoading(true);

    try {
      await handleUpdateInvoiceStatus(state.vm.form.invoiceId, 1).then(() => {
        snackActions.success('Invoice submitted successfully');
        handleCloseSubmitOnCLick();
      });
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal open={isVoidConfirmationModalOpen}>
        <MUIContainer sx={{ maxWidth: '400px !important' }}>
          <Typography
            className="modalTextHeaderVoid"
            variant="h6"
            fontWeight="bold"
          >
            Void Confirmation
          </Typography>

          <MUIContent sx={{ padding: '0px 20px !important ' }}>
            <Box autoComplete="off" component="form" noValidate>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  marginBottom: '15px',
                }}
              >
                Are you sure you want to void this invoice?
              </Typography>
            </Box>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={loading ? 8 : 6}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={handleCloseVoidOnCLick}
                  disabled={loading}
                  variant="outlined"
                  size="large"
                  sx={{ padding: 1, width: '100%' }}
                >
                  No
                </Button>

                {!loading && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{
                      padding: 1,
                      width: '100%',
                      backgroundColor: ' rgb(75, 126, 254) !important',
                    }}
                    onClick={() => {
                      handleVoidInvoice();
                    }}
                  >
                    Yes
                  </Button>
                )}
                {loading && (
                  <Button
                    disabled
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{
                      padding: 1,
                      width: '100%',
                      backgroundColor: ' rgb(75, 126, 254) !important',
                      color: 'white !important',
                    }}
                  >
                    <CircularProgress
                      sx={{
                        color: 'white !important',
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                  </Button>
                )}
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
      <Modal open={isDeleteInvoiceModalOpen}>
        <MUIContainer sx={{ maxWidth: '400px !important' }}>
          <Typography
            className="modalTextHeaderError"
            variant="h6"
            fontWeight="bold"
          >
            Delete Confirmation
          </Typography>

          <MUIContent sx={{ padding: '0px 20px !important ' }}>
            <Box autoComplete="off" component="form" noValidate>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  marginBottom: '15px',
                }}
              >
                Are you sure you want to delete this invoice?
              </Typography>
            </Box>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={loading ? 8 : 6}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={handleCloseDeleteOnCLick}
                  disabled={loading}
                  variant="outlined"
                  size="large"
                  sx={{ padding: 1, width: '100%' }}
                >
                  No
                </Button>

                {!loading && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{
                      padding: 1,
                      width: '100%',
                      backgroundColor: ' rgb(75, 126, 254) !important',
                    }}
                    onClick={() => {
                      handleDeleteInvoice();
                    }}
                  >
                    Yes
                  </Button>
                )}
                {loading && (
                  <Button
                    disabled
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{
                      padding: 1,
                      width: '100%',
                      backgroundColor: ' rgb(75, 126, 254) !important',
                      color: 'white !important',
                    }}
                  >
                    <CircularProgress
                      sx={{
                        color: 'white !important',
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                  </Button>
                )}
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
      <Modal open={isSubmitInvoiceModalOpen}>
        <MUIContainer sx={{ maxWidth: '400px !important' }}>
          <Typography
            className="modalTextHeaderSuccess"
            variant="h6"
            fontWeight="bold"
          >
            Submit Confirmation
          </Typography>

          <MUIContent sx={{ padding: '0px 20px !important ' }}>
            <Box autoComplete="off" component="form" noValidate>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  marginBottom: '15px',
                }}
              >
                Are you sure you want to submit this invoice?
              </Typography>
            </Box>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={loading ? 8 : 6}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={handleCloseSubmitOnCLick}
                  disabled={loading}
                  variant="outlined"
                  size="large"
                  sx={{ padding: 1, width: '100%' }}
                >
                  No
                </Button>

                {!loading && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{
                      padding: 1,
                      width: '100%',
                      backgroundColor: ' rgb(75, 126, 254) !important',
                    }}
                    onClick={() => {
                      handleSubmitInvoice();
                    }}
                  >
                    Yes
                  </Button>
                )}
                {loading && (
                  <Button
                    disabled
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{
                      padding: 1,
                      width: '100%',
                      backgroundColor: ' rgb(75, 126, 254) !important',
                      color: 'white !important',
                    }}
                  >
                    <CircularProgress
                      sx={{
                        color: 'white !important',
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                  </Button>
                )}
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
    </>
  );
}

export default React.memo(VoidConfirmationModal);
