import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

export type LabelUrlPrintModel = {
  url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buttonPrint?: React.MutableRefObject<any>;
};

interface LabelUrlPrintProps {
  data?: LabelUrlPrintModel;
}

export const LabelUrlPrint = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ data }: LabelUrlPrintProps, ref: React.MutableRefObject<any>) => {
    const [labelUrl, setLabelURL] = useState('');

    useEffect(() => {
      if (data !== null && data.url) {
        setLabelURL(data.url);
      }
    }, [data?.url]);

    useEffect(() => {
      if (
        data !== null &&
        data?.url &&
        data?.url.length > 0 &&
        data?.buttonPrint?.current
      ) {
        const timeoutId = setTimeout(() => {
          data.buttonPrint.current.click();
        }, 1500);
        return () => {
          clearTimeout(timeoutId);
        };
      }
      return undefined;
    }, [data]);

    if (data === null) return null;

    return (
      <div>
        <style type="text/css" media="print">
          {'\
  @page { size: landscape; size: 6.50in 7.50in; margin: 0px; }\
'}
        </style>
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={labelUrl}
            alt="Print Label URL"
            style={{ width: '400px', height: 'auto' }}
          />
        </Box>
      </div>
    );
  },
);
