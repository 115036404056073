import React, { useEffect, useState } from 'react';
import Barcode from 'react-barcode/lib/react-barcode';

import { Box } from '@mui/material';

export type SKUPrintModel = {
  sku: string;
  description: string;
  qty: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buttonPrint?: React.MutableRefObject<any>;
};

interface SKUPrintProps {
  data?: SKUPrintModel;
}

export const SKUPrint = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ data }: SKUPrintProps, ref: React.MutableRefObject<any>) => {
    const [qtyArray, setQtyArray] = useState<number[]>([]);

    useEffect(() => {
      if (data !== null && data.qty > 0) {
        const qtyArr = [];

        let d = 1;
        for (let index = 0; index < data.qty; index += 1) {
          d += index;
          qtyArr.push(d); // add dummy data
        }
        setQtyArray(qtyArr);
      }
    }, [data]);

    useEffect(() => {
      if (qtyArray.length > 0 && data !== null && data?.buttonPrint !== null) {
        data.buttonPrint?.current?.click();
      }
    }, [qtyArray, data, data?.buttonPrint]);

    if (data === null) return null;

    return (
      <div>
        <style type="text/css" media="print">
          {
            '\
  @page { size: landscape;  visibility: visible !important; top: 0; left: 0; padding: 0px; margin: 0px;}\
'
          }
        </style>
        <Box
          ref={ref}
          sx={{
            display: 'block',
          }}
        >
          {qtyArray.map((d) => (
            <Box
              key={d}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Barcode
                marginLeft={0}
                marginRight={0}
                marginTop={0}
                marginBottom={0.3}
                displayValue={false}
                value={data.sku}
                width={3.5}
                height={80}
                fontSize={30}
              />
              <p
                style={{
                  fontSize: '30px',
                  lineHeight: 'normal',
                  whiteSpace: 'nowrap',
                }}
              >
                {data.description}
              </p>
            </Box>
          ))}
        </Box>
      </div>
    );
  },
);
