import { memo, useContext, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { usePackAndShipContext } from 'pages/packandship/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { deletePackageInProcess } from 'services/api/packandship/packandship.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Button,
  Box,
  Typography,
  Grid as MUIGrid,
  CircularProgress,
} from '@mui/material';

export default memo(() => {
  const { isDeleteAllPackagesModalOpen, setIsDeleteAllPackagesModalOpen } =
    useContext(GlobalContext);

  const { pickTicketFormObj, setAllowBarcodeScanner, viewLoadData } =
    usePackAndShipContext();

  const [loading, setLoading] = useState(false);

  const handleCloseOnClick = () => {
    setIsDeleteAllPackagesModalOpen(false);
    setAllowBarcodeScanner(true);
  };

  const handleConfirmDeleteAllPackages = () => {
    deletePackageInProcess(pickTicketFormObj.pickTicketId)
      .then(() => {
        viewLoadData();
        handleCloseOnClick();
        snackActions.success('Packages deleted successfully.');
      })
      .catch((e) => {
        snackActions.error(e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal open={isDeleteAllPackagesModalOpen}>
      <MUIContainer sx={{ width: '25% !important' }}>
        <Typography
          className="modalTextHeaderError"
          variant="h6"
          fontWeight="bold"
        >
          Delete All Packages
        </Typography>
        <MUIContent sx={{ padding: '10px 20px !important' }}>
          <Box autoComplete="off" component="form" noValidate>
            <Typography variant="subtitle1">
              Are you sure you want to delete all packages?
            </Typography>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={loading ? 9 : 8}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => handleCloseOnClick()}
                variant="outlined"
                size="large"
                sx={{ padding: 1, width: '100%' }}
              >
                Cancel
              </Button>

              {loading ? (
                <Button
                  disabled
                  variant="contained"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                >
                  <CircularProgress
                    sx={{
                      width: '25px !important',
                      height: '25px !important',
                      marginRight: '10px !important',
                    }}
                  />
                  Confirm
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={() => {
                    setLoading(true);
                    handleConfirmDeleteAllPackages();
                  }}
                >
                  Confirm
                </Button>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
