import { ItemInventoryUOMTypeEnum, PutAwayItemTypeEnum } from 'common/enums';
import { BinZoneDropDownListItem } from 'common/models/bins';
import moment from 'moment';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';

import {
  Modal,
  Box,
  Typography,
  Button,
  Grid as MUIGrid,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';

import { usePutAwayContext } from '../context';

export const PutAwayLineItemModal = () => {
  const {
    state,
    closePutAwayLineItemModal,
    setPutAwayLocation,
    setPutAwayQty,
    submitPutAway,
  } = usePutAwayContext();

  return (
    <Modal open={state.vm.isPutAwayLineItemModal}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Put Away
        </Typography>
        <MUIContent>
          <Box autoComplete="off" component="form" noValidate>
            <Box className="modalLabelHeader">
              <Typography variant="subtitle2" fontWeight="bold">
                {(state.vm.putAway?.lineItem?.uomType ===
                  ItemInventoryUOMTypeEnum.Primary ||
                  state.vm.putAway?.lineItem?.uomType ===
                    ItemInventoryUOMTypeEnum.Other) &&
                  `${state.vm.putAway?.lineItem.sku}`}

                {state.vm.putAway?.lineItem?.uomType ===
                  ItemInventoryUOMTypeEnum.Pallet &&
                  `${state.vm.putAway?.lineItem.licensePlateNo}`}
              </Typography>
              <Typography variant="body1" className="modalLabelSubText">
                Description: <b>{state.vm.putAway?.lineItem?.description}</b>
              </Typography>
              <Typography variant="body1" className="modalLabelSubText">
                UOM: <b>{state.vm.putAway?.lineItem?.uomStr}</b>
              </Typography>

              {state.vm.putAway?.lineItem?.isTrackableByLot && (
                <Typography variant="body1" className="modalLabelSubText">
                  Lot: <b>{state.vm.putAway?.lineItem?.lotNo}</b>
                </Typography>
              )}

              <Typography variant="body1" className="modalLabelSubText">
                Remaining: <b>{state.vm.putAway?.lineItem?.remaining}</b>
              </Typography>
              <Typography variant="body1" className="modalLabelSubText">
                Date Received:{' '}
                <b>
                  {moment(state.vm.putAway?.lineItem?.receivedTime).format(
                    'MM/DD/YYYY',
                  )}
                </b>
              </Typography>
            </Box>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={6}>
                <Autocomplete
                  value={state.vm.putAway?.bin}
                  onChange={(event, newValue: BinZoneDropDownListItem) => {
                    setPutAwayLocation(newValue);
                  }}
                  id="controllable-states"
                  disabled={state.isBusy}
                  options={state.vm.binDDL}
                  getOptionLabel={(option: BinZoneDropDownListItem) =>
                    option.text
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option: BinZoneDropDownListItem) => (
                    <li {...props} key={option.value}>
                      <Box display="flex">
                        <Box display="flex" flexDirection="column">
                          <Typography color="text.primary">
                            {option.text}
                          </Typography>
                          <Typography color="text.secondary" variant="body2">
                            {option.zone}
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      {...(state.vm.putAwayError?.bin && {
                        error: true,
                        helperText: state.vm.putAwayError?.bin,
                      })}
                      id="outlined-required"
                      label="Select Put away Location"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={6}>
                <TextField
                  required
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPutAwayQty(Number(event.target.value));
                  }}
                  {...(state.vm.putAwayError?.qty && {
                    error: true,
                    helperText: state.vm.putAwayError?.qty,
                  })}
                  disabled={
                    state.isBusy ||
                    state.vm.putAway?.lineItem?.putAwayItemType ===
                      PutAwayItemTypeEnum.Pallet
                  }
                  value={state.vm.putAway?.qty || ''}
                  id="outlined-required"
                  label="Enter Put away Quantity"
                  size="small"
                  type="number"
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => closePutAwayLineItemModal()}
                disabled={state.isBusy}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                CANCEL
              </Button>

              {!state.isBusy && (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => {
                    submitPutAway(e);
                  }}
                >
                  SUBMIT
                </Button>
              )}
              {state.isBusy && (
                <Button
                  disabled
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                    color: 'white !important',
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: 'white !important',
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                  <Box sx={{ paddingLeft: '5px' }}>SAVING</Box>
                </Button>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
};
