import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const createEHubConfiguration = async (payload) => {
  try {
    const response = await httpClient.post(`/ehub/configuration`, payload);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const disconnectEhubConfigurationApi = async () => {
  try {
    const response = await httpClient.delete(`/ehub/configuration`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getEHubConfiguration = async () => {
  try {
    const response = await httpClient.get(`/ehub/configuration`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
