import React, { useContext, useEffect } from 'react';

import { PageLoadStateEnum } from 'common/enums';
import { PickTicketLineItemPickingWebModel } from 'common/models/picktickets/pickingweb/pickticketlineitems';
import {
  MUIContainer,
  MUIContent,
} from 'pages/shared/productionpickticketmodal';
import { AuthContext } from 'store/contexts/AuthContext';
import { DataGridProStyle } from 'styles/mui/datagrid/premiumpro';

import {
  Box,
  Modal,
  Typography,
  Button,
  Grid as MUIGrid,
  CircularProgress,
  Link,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

import BatchPickingIcon from './batch-picking-icon';
import { PickingContextProvider, usePickingContext } from './context';

import { usePickQueueDataTableViewContext } from '..';

import PickTicketLineItemPickModal from './pick-ticket-line-item-pick-modal';

const InternalModal = () => {
  const {
    state,
    pageLoadingStart,
    viewLoadData,
    closePickingModal,
    openPickTicketLineItemPickModal,
    pickTicketLineItemPick,
  } = usePickingContext();
  const { currentLocationAndFacility } = useContext(AuthContext);
  const { isPickingModal, onLoadPickingQueue } =
    usePickQueueDataTableViewContext();

  // page load
  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_NONE) {
      pageLoadingStart();
    }
  }, [state.pageLoadState]);

  // load data upon page load
  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_START) {
      viewLoadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pageLoadState, currentLocationAndFacility]);

  // facility change
  useEffect(() => {
    // this will automatically trigger viewLoadData
    pageLoadingStart();
  }, [currentLocationAndFacility]);

  useEffect(() => {
    // close modal if there is no more line items after pick
    if (state?.vm.pickTicketLineItems.length === 0 && pickTicketLineItemPick) {
      closePickingModal();
      onLoadPickingQueue();
    }
  }, [state?.vm, pickTicketLineItemPick]);

  const pickTicketLineItemColumns: GridColDef<PickTicketLineItemPickingWebModel>[] =
    [
      {
        field: 'pickTicketLineItemId',
        width: 70,
        renderHeader: () => (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Typography />
          </Box>
        ),
        renderCell: (params) => (
          <Button
            aria-label="batch picking"
            color="primary"
            onClick={() => {
              openPickTicketLineItemPickModal({ ...params.row });
            }}
          >
            <BatchPickingIcon />
          </Button>
        ),
      },
      {
        field: 'sku',
        width: 200,
        headerName: 'Item SKU',
        renderCell: (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          params: GridRenderCellParams<any, PickTicketLineItemPickingWebModel>,
        ) => (
          <Link
            sx={{
              fontSize: 14,
              fontWeight: 600,
              textDecoration: 'none',
            }}
            href={`/inventory/item-details?form=view&id=${params.row.itemId}`}
            target="_blank"
            underline="none"
          >
            <Typography sx={{ cursor: 'pointer' }}>{params.row.sku}</Typography>
          </Link>
        ),
      },
      {
        field: 'orderUOM',
        width: 150,
        headerName: 'UoM',
      },
      {
        field: 'qty',
        minWidth: 120,
        align: 'left',
        headerName: 'Qty',
      },
      {
        field: 'remaining',
        minWidth: 140,
        align: 'left',
        headerName: 'Remaining Qty',
      },
      {
        field: 'description',
        minWidth: 200,
        flex: 0.5,
        headerName: 'Description',
      },
      {
        field: 'licensePlateNo',
        minWidth: 150,
        flex: 0.5,
        headerName: 'LPN',
      },
      {
        field: 'lotNo',
        minWidth: 150,
        flex: 0.5,
        headerName: 'Lot No',
      },
    ];

  return (
    <>
      <Modal open={isPickingModal}>
        <MUIContainer>
          <Typography
            className="modalTextHeader"
            variant="h6"
            fontWeight="bold"
          >
            Picking
          </Typography>

          {state.pageLoadState === PageLoadStateEnum.LOAD_START && (
            <MUIContent>
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            </MUIContent>
          )}

          {state.pageLoadState === PageLoadStateEnum.LOAD_FINISH && (
            <>
              <MUIContent>
                <Box autoComplete="off" component="form" noValidate>
                  <Box className="modalLabelHeader">
                    <Typography variant="subtitle2" fontWeight="bold">
                      {state.vm.pickTicketNo}
                    </Typography>
                    {state.vm.referenceNo && (
                      <Typography variant="body1" className="modalLabelSubText">
                        Reference No: <b>{state.vm.referenceNo}</b>
                      </Typography>
                    )}

                    <Typography variant="body1" className="modalLabelSubText">
                      Total Quantity: <b>{state.vm.totalQuantity}</b>
                    </Typography>
                  </Box>
                  <MUIGrid
                    container
                    direction="row"
                    justifyContent="left"
                    alignItems="center"
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        mx: 'auto',
                        width: '96%',
                        p: 1,
                      }}
                    >
                      <DataGridProStyle
                        autoHeight
                        density="compact"
                        columns={pickTicketLineItemColumns}
                        rows={state.vm.pickTicketLineItems}
                        getRowId={(x) => x.pickTicketLineItemId}
                        pagination
                        disableColumnMenu
                      />
                    </Box>
                  </MUIGrid>
                </Box>
              </MUIContent>

              <MUIGrid
                container
                direction="row"
                justifyContent="flex-end"
                sx={{
                  borderTop: '1px solid #dbdbdb',
                  width: '100% !important',
                  marginLeft: '1px !important',
                }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      boxShadow: 'none',
                      margin: '20px',
                      padding: '0px',
                    }}
                  >
                    <Button
                      onClick={() => closePickingModal()}
                      disabled={state.isBusy}
                      variant="outlined"
                      size="small"
                      sx={{ padding: 1, width: '100%' }}
                    >
                      CANCEL
                    </Button>
                  </Box>
                </MUIGrid>
              </MUIGrid>
            </>
          )}
        </MUIContainer>
      </Modal>
      {state.vm.isPickTicketLineItemPickModal && (
        <PickTicketLineItemPickModal />
      )}
    </>
  );
};

const PickingModal = () => (
  <PickingContextProvider>
    <InternalModal />
  </PickingContextProvider>
);

export default React.memo(PickingModal);
