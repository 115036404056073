import React, { useContext, useState, ChangeEvent, useEffect } from 'react';

import Button from 'components/button';
import Input from 'components/input';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import {
  registerDevice,
  editDeviceData,
  getGeneratedToken,
} from 'services/api/devices/devices.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Typography, Grid as MUIGrid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface DeviceRegisterProps {
  customer?: any;
  edit?: boolean;
  callBack?: () => void;
}

export default React.memo(
  ({ customer, edit, callBack }: DeviceRegisterProps) => {
    const theme = useTheme();
    const { isDeviceModalOpen, onCloseDeviceModal, handleUpdateData } =
      useContext(GlobalContext);
    const { currentLocationAndFacility } = useContext(AuthContext);
    const [showErrors, setShowErrors] = useState(false);
    const [error, setError] = useState('');
    const [formErrors, setFormErrors] = useState({
      customerLocationId: '',
      serialNumber: '',
      deviceToken: '',
    });
    const initialState = {
      deviceId: '',
      customerId: '',
      customerLocationId: currentLocationAndFacility.customerLocationId,
      serialNumber: '',
      deviceToken: '',
    };
    const [form, setForm] = useState(initialState);

    const onForm = (key, text) => {
      setForm(() => ({
        ...form,
        [key]: text,
      }));
    };

    const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) =>
      onForm(key, event.target.value);

    const handleCloseDeviceModal = () => {
      if (edit) {
        callBack();
      }
      setForm(initialState);
      setFormErrors({
        customerLocationId: '',
        serialNumber: '',
        deviceToken: '',
      });
      onCloseDeviceModal();
    };

    const validateOnlick = (fieldValues = form) => {
      const temp: any = {};

      temp.serialNumber = fieldValues.serialNumber
        ? ''
        : 'This field is required';

      temp.deviceToken = fieldValues.deviceToken
        ? ''
        : 'This field is required';

      setFormErrors({
        ...temp,
      });

      return Object.values(temp).every((x) => x === '');
    };

    const onSaveNewDevice = async () => {
      form.customerId = customer.customerId;

      if (!validateOnlick()) {
        snackActions.error('Please complete required fields');
        return;
      }

      try {
        if (edit) {
          await editDeviceData(customer.deviceId, form);
          snackActions.success(`Successfully edited device.`);
        } else {
          await registerDevice(form);
          snackActions.success(`Successfully created new device.`);
        }
        setShowErrors(false);
        setError('');
        setForm(initialState);
        handleCloseDeviceModal();
        handleUpdateData();
      } catch (err: any) {
        setError(err);
        setFormErrors({
          customerLocationId: '',
          serialNumber: '',
          deviceToken: '',
        });
        snackActions.error(`${err}`);
      }
    };

    const handleGenerateToken = async () => {
      const data = await getGeneratedToken();

      setForm((e) => ({
        ...e,
        deviceToken: data,
      }));
    };

    useEffect(() => {
      if (edit) {
        setForm(customer);
      }
    }, [customer]);

    return (
      <Modal open={isDeviceModalOpen} onClose={() => handleCloseDeviceModal()}>
        <ModalBox>
          <ModalContent>
            {edit ? (
              <Typography
                sx={{ marginBottom: '16px' }}
                variant="h6"
                fontWeight="bold"
              >
                Edit Device
              </Typography>
            ) : (
              <Typography
                sx={{ marginBottom: '16px' }}
                variant="h6"
                fontWeight="bold"
              >
                Add Device
              </Typography>
            )}
            <MUIGrid
              container
              spacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <Input
                  sx={{ width: '100%' }}
                  placeholder="Device Name"
                  value={form.serialNumber}
                  onChange={(value) => inputHandler('serialNumber', value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSaveNewDevice();
                    }
                  }}
                  {...(formErrors.serialNumber && {
                    error: formErrors.serialNumber,
                  })}
                />
              </MUIGrid>
              <MUIGrid item xs={7.5}>
                <Input
                  sx={{ width: '100%' }}
                  placeholder="Token"
                  value={form.deviceToken || ''}
                  onChange={(value) => inputHandler('deviceToken', value)}
                  {...(formErrors.deviceToken && {
                    error: formErrors.deviceToken,
                  })}
                />
              </MUIGrid>
              <MUIGrid
                item
                xs={3}
                sx={{ padding: '0px !important', marginLeft: '20px' }}
              >
                <Button
                  variant="text"
                  size="small"
                  onClick={() => handleGenerateToken()}
                >
                  Generate
                </Button>
              </MUIGrid>
              <Typography variant="subtitle2" color="#d32f2f">
                {error}
              </Typography>
            </MUIGrid>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                  justifyContent: 'flex-end',
                },
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'center',
                },
                gap: '8px',
              }}
            >
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="text"
                size="large"
                onClick={() => handleCloseDeviceModal()}
              >
                Cancel
              </Button>
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="contained"
                size="large"
                onClick={() => onSaveNewDevice()}
              >
                Submit
              </Button>
            </Box>
          </ModalContent>
        </ModalBox>
      </Modal>
    );
  },
);
