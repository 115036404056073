import React, { useContext, useEffect, useState } from 'react';

import Card from 'components/card';
import { snackActions } from 'config/snackbar.js';
import { getCustomerBinsByFacilityId } from 'services/api/customerfacilities/customerfacilities.api';
import { getItemsByCustomerId } from 'services/api/cyclecount/cyclecount.api';
import {
  createInboundSectionForCustomer,
  getCustomerInboundSection,
} from 'services/api/uoms/uoms.api';
import { AuthContext } from 'store/contexts/AuthContext';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Box,
  Checkbox,
  Grid as MUIGrid,
  Switch,
  TextField,
  Typography,
  Divider,
  Button,
} from '@mui/material';

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckBoxIcon fontSize="small" />;

function InboundSection() {
  const { currentLocationAndFacility, currentUser } = useContext(AuthContext);

  const initialFormStateInbound = {
    inboundSectionSettingId: null,
    customerId: currentUser.Claim_CustomerId,
    location: {
      value: -1,
      label: '',
    },
    isQualityControl: false,
    itemModels: [],
  };

  const initialFormStateInboundError = {
    binId: '',
    qualityControl: false,
  };

  const [inboundData, setInboundData] = useState<any>(initialFormStateInbound);

  const [formErrors, setFormErrors] = useState<any>(
    initialFormStateInboundError,
  );

  const [optionLocation, setOptionLocation] = useState([]);

  // const [optionQuantityControl, setOptionQuantityControl] = useState([]);

  const [itemsOption, setItemsOption] = useState([]);
  const [checkedQuantityControl, setCheckedQuantityControl] =
    React.useState(false);

  const handleChangeQuantityControl = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInboundData((e) => ({ ...e, isQualityControl: event.target.checked }));
  };
  // onlocation
  const onLoadGetLocation = async () => {
    try {
      const resultOptionsLocation = await getCustomerBinsByFacilityId(
        currentLocationAndFacility.customerFacilityId,
      );
      setOptionLocation(
        resultOptionsLocation.map((c: any) => ({
          value: c.binId,
          label: c.name,
        })),
      );

      return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadUItemsByCustomerData = async () => {
    try {
      return await getItemsByCustomerId(currentUser.Claim_CustomerId);
    } catch (err) {
      return err;
    }
  };

  const onLoadInboundSection = async () => {
    try {
      const resultInbound = await getCustomerInboundSection(
        currentUser.Claim_CustomerId,
      );
      // setOptionQuantityControl(resultInbound.qualityControlDDL);
      setInboundData((e) => ({
        ...e,
        inboundSectionSettingId: resultInbound?.inboundSectionSettingId,
        customerId: currentUser.Claim_CustomerId,
        location: {
          value: resultInbound?.binId ? resultInbound.binId : -1,
          label: resultInbound?.binName ? resultInbound.binName : '',
        },
        isQualityControl: resultInbound.isQualityControl,
        itemModels: resultInbound?.itemModels ? resultInbound.itemModels : [],
      }));

      return true;
    } catch (err) {
      return err;
    }
  };

  const handleSaveCustomerOutbound = async () => {
    try {
      const payload = {
        inboundSectionSettingId: inboundData.inboundSectionSettingId,
        customerId: inboundData.customerId,
        binId:
          inboundData.location.value === -1 ? null : inboundData.location.value,
        isQualityControl: inboundData.isQualityControl,
        itemModels: inboundData.itemModels
          ? inboundData.itemModels.map((e) => ({ itemId: e.value }))
          : null,
      };

      await createInboundSectionForCustomer(payload);
      onLoadInboundSection();
      snackActions.success(`Inbound setting has been successfully saved.`);
    } catch (err: unknown) {
      snackActions.error(`${err}`);
    }
  };

  useEffect(() => {
    onLoadGetLocation();
    onLoadInboundSection();

    const optionsItemsCustomer = onLoadUItemsByCustomerData();
    optionsItemsCustomer
      .then((opt) => {
        setItemsOption(
          opt.map((c: any) => ({
            value: c.itemId,
            label: c.sku,
          })),
        );
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [currentUser.Claim_CustomerId]);

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
      <MUIGrid
        container
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: 1 }}
      >
        <MUIGrid item xs={12}>
          <Typography variant="h6">
            <Box fontWeight="bold">Inbound Settings</Box>
          </Typography>
        </MUIGrid>
        <MUIGrid item xs={6}>
          <MUIGrid item xs={12} sx={{ marginBottom: '7px' }}>
            <Typography variant="h6">
              <Box fontWeight="bold">Received to Location</Box>
            </Typography>
          </MUIGrid>
          <MUIGrid item xs={12}>
            <Autocomplete
              sx={{ width: '100%' }}
              options={optionLocation}
              value={inboundData.location}
              size="small"
              onChange={(event: any, newValue) => {
                if (newValue !== null) {
                  setInboundData((prevState) => ({
                    ...prevState,
                    location: newValue,
                  }));
                } else {
                  setInboundData((prevState) => ({
                    ...prevState,
                    location: {
                      label: '',
                      value: -1,
                    },
                  }));
                }
              }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    '& .MuiInputBase-root': {
                      background: 'white',
                    },
                  }}
                  {...params}
                  label="Default Received To Location"
                />
              )}
            />
          </MUIGrid>
        </MUIGrid>
        <MUIGrid item xs={6}>
          <Divider orientation="vertical" flexItem />
          <MUIGrid item xs={12}>
            Require Quality Control:
            <Switch
              checked={inboundData.isQualityControl}
              onChange={handleChangeQuantityControl}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </MUIGrid>
          <MUIGrid item xs={12}>
            <Autocomplete
              multiple
              size="small"
              id="checkboxes-tags-demo"
              options={itemsOption}
              value={inboundData.itemModels}
              disableCloseOnSelect
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              onChange={(event: any, newValue) => {
                if (newValue !== null) {
                  setInboundData((prev) => ({
                    ...prev,
                    itemModels: newValue,
                  }));
                } else {
                  setInboundData((prev) => ({
                    ...prev,
                    itemModels: [],
                  }));
                }
              }}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    '& .MuiInputBase-root': {
                      background: 'white',
                    },
                  }}
                  {...params}
                  label="Quality Control On Items"
                />
              )}
            />
          </MUIGrid>
        </MUIGrid>
      </MUIGrid>
      <MUIGrid
        container
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: 1 }}
      >
        <MUIGrid item xs={1}>
          <Button
            sx={{ display: 'flex', width: '100%', padding: '10px 0' }}
            variant="contained"
            size="small"
            onClick={() => handleSaveCustomerOutbound()}
          >
            SAVE
          </Button>
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
}
export default React.memo(InboundSection);
