import { createContext, useContext, useMemo, useState } from 'react';

import { PageLoadStateEnum } from 'common/enums';
import { StateBase } from 'common/models';
import {
  PickTicketLineItemPickingWebModel,
  PickTicketLineItemsPickingWebResponse,
} from 'common/models/picktickets/pickingweb/pickticketlineitems';
import { snackActions } from 'config/snackbar.js';
import { usePickQueueDataTableViewContext } from 'pages/picking';
import { GetPickTicketLineItemsPickingWeb } from 'services/api/picktickets/picktickets.api';

type Model = {
  pickTicketNo: string;
  referenceNo?: string;
  totalQuantity: number;
  pickTicketLineItems: PickTicketLineItemPickingWebModel[];
  pickTicketLineItemToPick: PickTicketLineItemPickingWebModel | null;
  isPickTicketLineItemPickModal: boolean;
};

const INITIAL_STATE_MODEL: Model = {
  pickTicketNo: '',
  referenceNo: null,
  totalQuantity: 0,
  pickTicketLineItems: [],
  pickTicketLineItemToPick: null,
  isPickTicketLineItemPickModal: false,
};

type State = StateBase<Model> & {
  isBusy: boolean;
};

const INITIAL_STATE: State = {
  pageLoadState: PageLoadStateEnum.LOAD_NONE,
  isBusy: false,
  vm: INITIAL_STATE_MODEL,
};

// MEMO STATE
interface IPicking {
  state: State;
  pageLoadingStart: () => void;
  viewLoadData(): void;
  closePickingModal: () => void;
  openPickTicketLineItemPickModal: (
    // eslint-disable-next-line no-unused-vars
    lineItem: PickTicketLineItemPickingWebModel,
  ) => void;
  closePickTicketLineItemPickModal: () => void;
  closePickTicketLineItemPickModalAfterPick: () => void;
  pickTicketLineItemPick: boolean;
}

type PickingProviderProps = {
  children: React.ReactNode;
};
const PickingContext = createContext<IPicking>({} as IPicking);

export const usePickingContext = () => useContext(PickingContext);

export const PickingContextProvider = ({ children }: PickingProviderProps) => {
  const [state, setState] = useState<State>(INITIAL_STATE);
  const [pickTicketLineItemPick, setPickTicketLineItemPick] =
    useState<boolean>(false);

  const { pickTicketId, setIsPickingModal } =
    usePickQueueDataTableViewContext();

  function pageLoadingStart() {
    setState((prevState) => ({
      ...prevState,
      pageLoadState: PageLoadStateEnum.LOAD_START,
    }));
  }

  function viewLoadData() {
    const model: Model = INITIAL_STATE_MODEL;

    GetPickTicketLineItemsPickingWeb(pickTicketId ?? 0)
      .then((x: PickTicketLineItemsPickingWebResponse) => {
        model.pickTicketNo = x.pickTicketNo;
        model.referenceNo = x.referenceNo ?? '';
        model.totalQuantity = x.totalQuantity;
        model.pickTicketLineItems = x.pickTicketLineItems;

        setState((prevState) => ({
          ...prevState,
          pageLoadState: PageLoadStateEnum.LOAD_FINISH,
          vm: model,
        }));
      })
      .catch((err) => {
        setState((prevState) => ({
          ...prevState,
          pageLoadState: PageLoadStateEnum.LOAD_FINISH,
          vm: model,
        }));
        snackActions.error(err);
      });
  }

  function closePickingModal() {
    setPickTicketLineItemPick(false);
    setIsPickingModal(false);
  }

  function openPickTicketLineItemPickModal(
    lineItem: PickTicketLineItemPickingWebModel,
  ) {
    setPickTicketLineItemPick(false);
    setState((prevState) => ({
      ...prevState,
      vm: {
        ...prevState.vm,
        pickTicketLineItemToPick: lineItem,
        isPickTicketLineItemPickModal: true,
      },
    }));
  }

  function closePickTicketLineItemPickModal() {
    setPickTicketLineItemPick(false);
    setState((prevState) => ({
      ...prevState,
      vm: {
        ...prevState.vm,
        pickTicketLineItemToPick: null,
        isPickTicketLineItemPickModal: false,
      },
    }));
  }

  function closePickTicketLineItemPickModalAfterPick() {
    setPickTicketLineItemPick(true);
    setState((prevState) => ({
      ...prevState,
      vm: {
        ...prevState.vm,
        pickTicketLineItemToPick: null,
        isPickTicketLineItemPickModal: false,
      },
      pageLoadState: PageLoadStateEnum.LOAD_START,
    }));
  }

  const value: IPicking = useMemo(
    () => ({
      state,
      pageLoadingStart,
      viewLoadData,
      closePickingModal,
      openPickTicketLineItemPickModal,
      closePickTicketLineItemPickModal,
      closePickTicketLineItemPickModalAfterPick,
      pickTicketLineItemPick,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );

  return (
    <PickingContext.Provider value={value}>{children}</PickingContext.Provider>
  );
};
