import React, { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import {
  ILicensePlate,
  IOrderChecklistObj,
  IPalletizeGroup,
  IPickTicketLineItemsFormObj,
  IPickTicketPackageFormObj,
} from 'common/interfaces';
import Card from 'components/card';
import { snackActions } from 'config/snackbar.js';
import _ from 'lodash';
import moment from 'moment';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import { SinglePrintLicensePlate } from 'pages/licenseplates/lp-print';
import AddCustomerPackageModal from 'pages/packandship/modal/add-custom-package-modal';
import AutoCreateLabelModal from 'pages/packandship/modal/auto-create-label';
import AutoCreateLabelErrorMessageModal from 'pages/packandship/modal/auto-create-label-error-message';
import ChangeAndNextPackage from 'pages/packandship/modal/changeandnextpackage';
import ConfirmationForCustomAndOtherCarrierModal from 'pages/packandship/modal/confirmation-for-custom-and-other-carrier';
import DeleteAllPackagesModal from 'pages/packandship/modal/delete-all-packages-modal';
import DeletePalletizeModal from 'pages/packandship/modal/delete-palletize-modal';
import EachOrderLineShouldbeApprovedModal from 'pages/packandship/modal/each-order-line-should-be-approved-modal';
import LabelToPrintWhenCompletedModal from 'pages/packandship/modal/label-to-print-when-completed';
import PalletizeModal from 'pages/packandship/modal/palletize';
import PalletizeModalEdit from 'pages/packandship/modal/palletize/modal.edit';
import RatesToCompleteModal from 'pages/packandship/modal/rates-to-complete-status';
import SerialNumberScanModal from 'pages/packandship/modal/serialnumberscan';
import UpdateCarrierAndServiceModal from 'pages/packandship/modal/update-carrier-and-service-modal';
import VoidFillModal from 'pages/packandship/modal/voidfill';
import {
  CompleteOrderManuallyModal,
  pageEnum,
} from 'pages/sales/salesorder/complete-order-manually-modal';
import { UOMType } from 'pages/sales/salesorder/context';
import { PrinterSettingsState } from 'pages/settings/tabviews/PrinterSettings';
import ConfirmationDialog from 'pages/shared/confirmdeletemodal';
import Printformtemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import { GetScale } from 'services/api/integrations/printnode/printnode.api';
import { getRatesOptionFromCarriers } from 'services/api/integrations/shipment/shipment.api';
import {
  createThePickTicketPackageLineItem,
  deletePackageLicensePlate,
  packSelectedItems,
  updatePackageLicensePlate,
} from 'services/api/packandship/packandship.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridPremium, DatagridStandard } from 'styles';
import {
  BoxAccordionChild,
  IconBoxDangerOutline,
  IconBoxPrimaryOutline,
} from 'styles/mui';

import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrintIcon from '@mui/icons-material/Print';
import RemoveIcon from '@mui/icons-material/Remove';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Typography,
  TextField,
  Box,
  Grid as MUIGrid,
  Button,
  IconButton,
  FormControlLabel,
  Divider,
  Checkbox,
  Paper,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Link,
  Chip,
  CircularProgress,
} from '@mui/material';
import { GridColumns, GridRowParams } from '@mui/x-data-grid-premium';

import { usePackAndShipContext } from '../context';

interface IPackAndShipTable {
  serialNoColumnIsHidden: boolean;
  lotColumnIsHidden: boolean;
  dataRow: any;
  onItemDeleteClick: (rowData: any) => void;
}

interface IPackageBox {
  packageCurrentIndex: number;
  packageInfo: IPickTicketPackageFormObj;
  arrayIndex: number;
}

const PackAndShipPickTicket = () => {
  const {
    setRatesOptionData,
    setIsRatesToCompleteModalOpen,
    setIsAutoCreateLabelErrorModal,
    setIsAutoCreateLabelErrorMessage,
    setIsUpdateCarrierAndServiceModalOpen,
    setIsDeleteAllPackagesModalOpen,
  } = useContext(GlobalContext);

  const { currentLocationAndFacility } = useContext(AuthContext);

  const {
    isPulseNumber,
    pickTicketFormObj,
    ptLineItemsrowSelectionModel,
    viewLoadData,
    WeightsValidation,
    setPtLineItemsrowSelectionModel,
  } = usePackAndShipContext();

  const pickTicketItemsColumns: GridColumns = [
    {
      field: 'itemSKU',
      flex: 1,
      minWidth: 120,
      renderHeader: () => <Typography fontWeight="bold">SKU</Typography>,
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            textDecoration: 'none',
          }}
          href={`/inventory/item-details?form=view&id=${params.row.itemId}`}
          target="_blank"
        >
          <Typography sx={{ cursor: 'pointer' }}>
            {params.row.itemSKU}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'itemDescription',
      flex: 2,
      minWidth: 120,
      renderHeader: () => (
        <Typography fontWeight="bold">Description</Typography>
      ),
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.itemDescription}
        </Typography>
      ),
    },
    {
      field: 'qty',
      flex: 1,
      minWidth: 100,
      renderHeader: () => (
        <Typography fontWeight="bold">Qty Ordered</Typography>
      ),
      renderCell: (params) => (
        <Typography>
          {params?.row?.uomType === UOMType.Primary &&
            `${params.row.qty.toLocaleString()}`}

          {params?.row?.uomType === UOMType.Other &&
            `${params.row.totalCases.toLocaleString()} ${
              params?.row?.otherUOMAbbr
            } (${decimalValidationHelper(
              (params.row.totalCases * params.row.otherUOMCount).toString(),
            )} ${params.row.primaryUOMAbbr})`}

          {params?.row?.uomType === UOMType.Pallet &&
            params?.row?.isOrderByLPN === false &&
            `${params.row.totalCases.toLocaleString()} pallet (${decimalValidationHelper(
              (params.row.totalCases * params.row.otherUOMCount).toString(),
            )} ${params.row.primaryUOMAbbr})`}

          {params?.row?.uomType === UOMType.Pallet &&
            params?.row?.isOrderByLPN &&
            `1 Pallet (${params.row.lpn})`}
        </Typography>
      ),
    },
    {
      field: 'packed',
      minWidth: 100,
      renderHeader: () => <Typography fontWeight="bold">Packed</Typography>,
      renderCell: (params) => (
        <Typography
          className={
            isPulseNumber.value === params?.row?.itemSKU &&
            isPulseNumber.isPulse
              ? 'pulseNumber'
              : null
          }
          sx={{
            fontSize: 'inherit',
            color: params.row.qty === params.row.packed ? '#1DBA00' : '#000000',
          }}
        >
          {params.row.packed}
        </Typography>
      ),
    },
  ];

  const [isLoadPackSelectedItem, setIsLoadPackSelectedItem] = useState(false);

  const sumTotal = pickTicketFormObj?.lineItems?.reduce(
    (prev, current) => prev + current.qty,
    0,
  );
  const sumRemaining = pickTicketFormObj?.lineItems?.reduce(
    (prev, current) => prev + current.packed,
    0,
  );

  const onCLickedShowRates = () => {
    if (pickTicketFormObj?.isCustomOrOtherCarrier !== true) {
      if (!WeightsValidation()) {
        return;
      }
    }

    const gettempPackage = [...pickTicketFormObj.packages];

    if (gettempPackage?.length === 0) {
      setIsAutoCreateLabelErrorMessage('Add at least one package.');
      setIsAutoCreateLabelErrorModal(true);
      return;
    }

    if (
      gettempPackage[0].isNoPackage === false &&
      gettempPackage[0].itemAsPackage.value === undefined &&
      !gettempPackage[0].carrierPredefinedPackage &&
      gettempPackage[0].isCustomPackage !== true
    ) {
      setIsAutoCreateLabelErrorMessage(
        'One or more packages do not have a package type selected. Select a package type and try again.',
      );
      setIsAutoCreateLabelErrorModal(true);
      return;
    }

    try {
      getRatesOptionFromCarriers(pickTicketFormObj.pickTicketId).then((opt) => {
        setRatesOptionData(opt && opt.sort((x, y) => x.price - y.price));
        setIsRatesToCompleteModalOpen(true);
      });
    } catch (err) {
      setIsAutoCreateLabelErrorMessage(err);
      setIsAutoCreateLabelErrorModal(true);
    }
  };

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) =>
      pickTicketFormObj?.manualItems.find(
        (row) => row.pickTicketLineItemId === id,
      ),
    );
    const getPackedValue = selectedRowsData.flatMap((item) => {
      const matchedRow = pickTicketFormObj?.lineItems.find(
        (row) => row.pickTicketLineItemId === item?.pickTicketLineItemId,
      );

      const packValue =
        (item?.quantity ?? 0) - (matchedRow ? matchedRow.packed : 0);

      if (matchedRow?.isPackedAsCarton === true || item?.uomType === 2) {
        // Create an array of objects based on packValue
        return Array.from({ length: Math.max(packValue, 0) }, () => ({
          pickTicketLineItemId: item?.pickTicketLineItemId,
          qty: 1, // Each object represents one unit
          lotId:
            item?.pickTicketLineItemBinLotQty?.value &&
            item?.pickTicketLineItemBinLotQty?.value !== 0
              ? item.pickTicketLineItemBinLotQty.value
              : null,
        }));
      }
      return {
        pickTicketLineItemId: item?.pickTicketLineItemId,
        qty: packValue,
        lotId:
          item?.pickTicketLineItemBinLotQty !== null &&
          item?.pickTicketLineItemBinLotQty?.value !== 0
            ? item?.pickTicketLineItemBinLotQty?.value
            : null,
      };
    });
    setPtLineItemsrowSelectionModel(getPackedValue);
  };

  const handlePackSelectedItems = (event) => {
    event.preventDefault();
    setIsLoadPackSelectedItem(true);

    const PAYLOAD = {
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      items: ptLineItemsrowSelectionModel.map((item) => ({
        packageId: null,
        pickTicketLineItemId: item?.pickTicketLineItemId,
        qty: item?.qty,
        lotId: item?.lotId,
        isNewPackage: true,
      })),
    };

    packSelectedItems(PAYLOAD)
      .then(() => {
        viewLoadData(true);
        snackActions.success('Successfully Packed Selected Items.');
      })
      .catch((err) => {
        snackActions.error(err);
      })
      .finally(() => setIsLoadPackSelectedItem(false));
  };

  return (
    <>
      <UpdateCarrierAndServiceModal />
      <CompleteOrderManuallyModal page={pageEnum.PackAndShipApprove} />
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          padding: '18px !important ',
        }}
      >
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={9}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={8} sx={{ display: 'flex' }}>
                <Typography variant="h6" sx={{ marginRight: '10px' }}>
                  <b>Pick Ticket: {pickTicketFormObj?.pickId}</b>
                </Typography>
                <Button
                  {...(pickTicketFormObj?.isShipAndComplete && {
                    disabled: true,
                  })}
                  sx={{
                    textTransform: 'capitalize',
                    display:
                      !pickTicketFormObj?.isApprove &&
                      !pickTicketFormObj?.isPack &&
                      !pickTicketFormObj?.isPackAndShip
                        ? 'flex '
                        : 'none',
                    width: '220px',
                    alignItems: 'flex-start',
                    marginRight: '10px',
                  }}
                  variant="contained"
                  size="medium"
                  onClick={(e) => {
                    handlePackSelectedItems(e);
                  }}
                  disabled={
                    pickTicketFormObj?.isComplete ||
                    ptLineItemsrowSelectionModel?.length === 0 ||
                    isLoadPackSelectedItem
                  }
                >
                  {isLoadPackSelectedItem ? (
                    <Box display="flex" alignItems="center" gap={1}>
                      <CircularProgress size={20} color="inherit" />
                      Loading...
                    </Box>
                  ) : (
                    <>
                      Pack Selected Items
                      <Chip
                        sx={{ marginLeft: '5px', height: '18px !important' }}
                        label="Beta"
                        size="small"
                        className="statusGreenChip"
                      />
                    </>
                  )}
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  sx={{
                    textTransform: 'capitalize',
                    color: '#ff5e5e',
                    borderColor: '#ff5e5e',
                  }}
                  disabled={pickTicketFormObj?.packages?.length === 0}
                  onClick={() => setIsDeleteAllPackagesModalOpen(true)}
                >
                  Delete All Packages
                </Button>
              </MUIGrid>
              <MUIGrid
                item
                xs={4}
                sx={{
                  justifyContent: 'flex-end',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6">
                  &nbsp; {sumTotal - sumRemaining}
                </Typography>
                <Typography fontSize={15}>
                  &nbsp; left to pack out of {sumTotal}
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={12} sx={{ paddingTop: '0px !important' }}>
                <Box
                  style={{
                    maxHeight: '300px',
                    width: '100%',
                    paddingTop: '15px',
                    overflowY: 'auto',
                  }}
                >
                  <DatagridPremium
                    autoHeight
                    rows={pickTicketFormObj?.lineItems || []}
                    pageSize={10}
                    density="compact"
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu
                    disableSelectionOnClick
                    columns={pickTicketItemsColumns}
                    getRowId={(row) => row.pickTicketLineItemId}
                    checkboxSelection
                    isRowSelectable={(params: GridRowParams) =>
                      params.row?.qty !== params.row?.packed
                    }
                    onSelectionModelChange={(ids) =>
                      onRowsSelectionHandler(ids)
                    }
                  />
                </Box>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
          <MUIGrid item xs={3}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className="headerTileGrayBGPackAndShip"
              sx={{
                height: '100%',
                marginTop: '0px !important',
              }}
            >
              <MUIGrid item xs={12}>
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{
                    overflowWrap: 'break-word',
                    height: '100%',
                    fontSize: '14px !important',
                  }}
                >
                  <MUIGrid item xs={6} sx={{ paddingLeft: '8px !important' }}>
                    <Typography
                      className="textFieldParagraph"
                      fontWeight="bold"
                    >
                      Order No.:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={6} sx={{ paddingLeft: '8px !important' }}>
                    <Link
                      sx={{
                        color: '#303538',
                        textDecoration: 'none',
                      }}
                      href={`/orders/sales-orders?form=view&id=${pickTicketFormObj?.orderNoId}`}
                      target="_blank"
                    >
                      <Typography
                        className="textFieldParagraph"
                        sx={{
                          cursor: 'pointer',
                          color: '#1C9DCC',
                        }}
                      >
                        {pickTicketFormObj?.orderNo ?? ''}
                      </Typography>
                    </Link>
                  </MUIGrid>
                  <MUIGrid item xs={6} sx={{ paddingLeft: '8px !important' }}>
                    <Typography
                      className="textFieldParagraph"
                      fontWeight="bold"
                    >
                      Order Date:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={6} sx={{ paddingLeft: '8px !important' }}>
                    <Typography className="textFieldParagraph">
                      {pickTicketFormObj?.orderDate ?? ''}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={6} sx={{ paddingLeft: '8px !important' }}>
                    <Typography
                      className="textFieldParagraph"
                      fontWeight="bold"
                    >
                      Client:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={6} sx={{ paddingLeft: '8px !important' }}>
                    <Typography className="textFieldParagraph">
                      {pickTicketFormObj?.customerWarehouseName ?? ''}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={6} sx={{ paddingLeft: '8px !important' }}>
                    <Typography
                      className="textFieldParagraph"
                      fontWeight="bold"
                    >
                      Ref No.:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={6} sx={{ paddingLeft: '8px !important' }}>
                    <Typography className="textFieldParagraph">
                      {pickTicketFormObj?.referenceNo ?? ''}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={6} sx={{ paddingLeft: '8px !important' }}>
                    <Typography
                      className="textFieldParagraph"
                      fontWeight="bold"
                    >
                      Ship By Date:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={6} sx={{ paddingLeft: '8px !important' }}>
                    <Typography className="textFieldParagraph">
                      {pickTicketFormObj?.shipByDate ?? ''}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    xs={6}
                    sx={{
                      paddingLeft: '8px !important',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginBottom: pickTicketFormObj?.deliveryMethod
                        ? '0px'
                        : '15px',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      className="textFieldParagraph"
                      fontWeight="bold"
                    >
                      Carrier/Service:
                    </Typography>
                    <Button
                      className="buttonLink"
                      onClick={() =>
                        setIsUpdateCarrierAndServiceModalOpen(true)
                      }
                    >
                      <b>Update</b>
                    </Button>
                  </MUIGrid>
                  <MUIGrid
                    item
                    xs={6}
                    sx={{
                      paddingLeft: '8px !important',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginBottom: pickTicketFormObj?.deliveryMethod
                        ? '0px'
                        : '15px',
                      alignItems: 'center',
                    }}
                  >
                    {pickTicketFormObj?.carrierName ? (
                      <>
                        <Link
                          sx={{
                            color: '#303538',
                            textDecoration: 'none',
                          }}
                          href={`/orders/sales-orders?form=view&id=${pickTicketFormObj?.orderNoId}`}
                          target="_blank"
                        >
                          <Typography
                            className="textFieldParagraph"
                            sx={{
                              cursor: 'pointer',
                              color: '#1C9DCC',
                              overflowWrap: 'anywhere',
                              marginRight: '5px',
                            }}
                          >
                            {pickTicketFormObj?.carrierName ?? ''}
                          </Typography>
                        </Link>
                        <Button
                          sx={{
                            textTransform: 'capitalize',
                            borderRadius: '35px',
                            height: '30px',
                            backgroundColor: '#3375E0',
                            border: '#dbdbdb 0px solid',
                          }}
                          variant="contained"
                          size="small"
                          onClick={() => onCLickedShowRates()}
                        >
                          <Box sx={{ marginLeft: '-6px', marginTop: '8px' }}>
                            <AttachMoneyIcon
                              sx={{
                                borderRadius: '50%',
                                color: '#3375E0',
                                backgroundColor: 'white',
                                padding: '4px',
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              className="textFieldParagraph"
                              sx={{
                                cursor: 'pointer',
                                color: 'white',
                                overflowWrap: 'anywhere',
                                marginLeft: '6px',
                                fontSize: '10px !important',
                              }}
                            >
                              Show Rates
                            </Typography>
                          </Box>
                        </Button>
                      </>
                    ) : (
                      <Typography
                        className="textFieldParagraph"
                        sx={{ color: 'red' }}
                      >
                        Update Order Carrier!
                      </Typography>
                    )}
                  </MUIGrid>

                  {pickTicketFormObj?.deliveryMethod && (
                    <>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{
                          paddingLeft: '8px !important',
                          marginBottom: '15px',
                        }}
                      >
                        <Typography
                          className="textFieldParagraph"
                          fontWeight="bold"
                        >
                          Delivery Method:
                        </Typography>
                      </MUIGrid>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{ paddingLeft: '8px !important' }}
                      >
                        <Typography className="textFieldParagraph">
                          {pickTicketFormObj?.deliveryMethod ?? ''}
                          {pickTicketFormObj?.latestDeliveryDate
                            ? `- ${pickTicketFormObj?.latestDeliveryDate}`
                            : ''}
                        </Typography>
                      </MUIGrid>
                    </>
                  )}
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
      </Card>
    </>
  );
};

const OrderNotes = () => {
  const { pickTicketFormObj } = usePackAndShipContext();

  const orderNotesColumns: GridColumns = [
    {
      field: 'note',
      flex: 2,
      minWidth: 220,
      renderHeader: () => <Typography fontWeight="bold">Note</Typography>,
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.note}
        </Typography>
      ),
    },
  ];

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="h6" fontWeight="bold" mb={2}>
        Instructions
      </Typography>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={12}>
          <Box
            style={{
              height: '215px',
              width: '100%',
              paddingTop: '15px',
            }}
          >
            <DatagridPremium
              autoHeight
              rows={pickTicketFormObj?.orderNotes || []}
              density="compact"
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              disableSelectionOnClick
              columns={orderNotesColumns}
              hideFooterPagination
              hideFooter
              getRowId={(row) => row.orderNoteId}
            />
          </Box>
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

const PackAndShipApproved = () => {
  const { pickTicketFormObj, processType } = usePackAndShipContext();
  return (
    (processType?.isApprove || processType?.isPack) && (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          minHeight: '0px !important',
          paddingX: '10px !important',
          paddingY: '11px !important',
          height: !pickTicketFormObj?.isShowWarningForPartialOrder
            ? '100% !important'
            : 'auto',
        }}
      >
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={5}>
            <Typography variant="body2">Approved by:</Typography>
          </MUIGrid>
          <MUIGrid
            item
            xs={7}
            sx={{ textAlign: 'center', paddingX: '0px !important' }}
          >
            <Typography variant="body2">
              <strong>{pickTicketFormObj.approvedBy}</strong>
            </Typography>
          </MUIGrid>
          <MUIGrid item xs={5} sx={{ paddingTop: '10px !important' }}>
            <Typography variant="body2"> Picked by:</Typography>
          </MUIGrid>
          <MUIGrid
            item
            xs={7}
            sx={{ textAlign: 'center', paddingTop: '10px !important' }}
          >
            <Typography variant="body2">
              <strong>{pickTicketFormObj.pickedBy}</strong>
            </Typography>
          </MUIGrid>
        </MUIGrid>
      </Card>
    )
  );
};

const PackAndShipScanner = () => {
  const {
    isPalletizeModalOpen,
    isNextPackageModalOpen,
    setIsPalletizeModalOpen,
    isChangePackageModalOpen,
    setIsNextPackageModalOpen,
    setIsChangePackageModalOpen,
    onOpenPackAndShipAddItemModal,
  } = useContext(GlobalContext);

  const { handleUserPermissionAllow } = useContext(AuthContext);

  const {
    barcode,
    setBarcode,
    focusIsOnBarcode,
    pickTicketFormObj,
    isShowBarcodeError,
    isChecklistComplete,
    allowBarcodeScanner,
    messageShowBarcodeError,
    setPackageIdValue,
    WeightsValidation,
    setFocusIsOnBarcode,
    setAllowBarcodeScanner,
    checkShipAndCompleIsDisable,
    setIsNewPackageForManualItem,
    completePackAndShipAndCreateShippmentLabel,
    GetScanPackAndShipPickTicketLineItemfunction,
  } = usePackAndShipContext();

  const onTextChanged = async (e: any) => {
    setFocusIsOnBarcode(true);
    setBarcode(e.target.value);
  };

  const ticker = useRef(null);
  const barcodeRef = useRef(null);

  const handleBarcodeGotFocus = () => {
    setAllowBarcodeScanner(true);
  };

  const handlePalletizeOpen = () => {
    setAllowBarcodeScanner(false);
    setIsPalletizeModalOpen(true);
  };

  const handlePackageModelOpen = () => {
    setAllowBarcodeScanner(false);
    setIsNextPackageModalOpen(true);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (barcode && barcode.length > 1) {
        GetScanPackAndShipPickTicketLineItemfunction(barcode);
        setBarcode('');
        setFocusIsOnBarcode(false);
      } else {
        setBarcode('');
      }
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [barcode]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      // if (!focusIsOnBarcode) { // remove checker when focus is on Barcode
      if (!isPalletizeModalOpen && barcode && barcode.length === 1) {
        if (event.key === 'a' || event.key === 'A') {
          event.preventDefault();
          if (barcode.length === 1) {
            if (!isChangePackageModalOpen) {
              setIsChangePackageModalOpen(true);
            }
          }
        }
        if (event.key === 'b' || event.key === 'B') {
          event.preventDefault();
          if (
            barcode.length === 1 &&
            pickTicketFormObj?.isCustomOrOtherCarrier !== true
          ) {
            if (!pickTicketFormObj.carrierName) {
              return;
            }

            if (!WeightsValidation()) {
              return;
            }
          }

          setIsNextPackageModalOpen(true);
        }
        if (event.key === 'c' || event.key === 'C') {
          if (checkShipAndCompleIsDisable()) {
            return;
          }

          completePackAndShipAndCreateShippmentLabel();
        }
      }

      if (
        (event.key === 'p' || event.key === 'P') &&
        (isNextPackageModalOpen !== true ||
          isChangePackageModalOpen !== true) &&
        barcode &&
        barcode.length === 1
      ) {
        event.preventDefault();
        setIsPalletizeModalOpen(true);
      }
      // }
    };

    document.addEventListener('keyup', keyDownHandler);

    return () => {
      document.removeEventListener('keyup', keyDownHandler);
    };
  }, [focusIsOnBarcode, barcode]);

  useEffect(() => {
    if (allowBarcodeScanner) {
      ticker.current = setInterval(() => {
        barcodeRef.current.focus();
      }, 6000);
    } else {
      clearInterval(ticker.current);
    }
    return () => clearInterval(ticker.current);
  }, [allowBarcodeScanner]);

  return (
    <div>
      {!pickTicketFormObj?.isApprove &&
        !pickTicketFormObj?.isPack &&
        !pickTicketFormObj?.isPackAndShip && (
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              padding: '15px 24px !important',
            }}
          >
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sx={{ display: 'flex' }}>
                <Typography variant="h6" sx={{ marginRight: '10px' }}>
                  <strong>Scan the Item Code</strong>{' '}
                </Typography>
                <Button
                  {...(pickTicketFormObj?.isShipAndComplete && {
                    disabled: true,
                  })}
                  sx={{
                    padding: '7px 15px',
                    textTransform: 'capitalize',
                    borderColor: '#052fff',
                    borderWidth: 'revert',
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setIsNewPackageForManualItem(true);
                    onOpenPackAndShipAddItemModal();
                  }}
                  disabled={pickTicketFormObj?.isComplete}
                >
                  Add Item
                </Button>
              </MUIGrid>
              <MUIGrid item xs={4} sx={{ display: 'flex' }}>
                <TextField
                  sx={{ width: '100%' }}
                  placeholder="000000"
                  value={barcode}
                  onChange={onTextChanged}
                  autoFocus
                  inputProps={{
                    'data-state': 'carrier',
                    autoComplete: 'off',
                    style: {
                      height: '0.4em',
                    },
                  }}
                  autoComplete="off"
                  inputRef={barcodeRef}
                  onFocus={handleBarcodeGotFocus}
                  disabled={
                    !handleUserPermissionAllow(
                      UserPermissionEnum.PackAndShip_Edit,
                    )
                  }
                />
              </MUIGrid>

              <MUIGrid item xs={2.5} sx={{ display: 'flex' }}>
                <Typography variant="body1" sx={{ marginRight: '10px' }}>
                  And place the item <br />
                  in package{' '}
                  <strong> No. {pickTicketFormObj?.packages?.length}</strong>
                </Typography>
              </MUIGrid>
              <MUIGrid
                item
                xs={5.5}
                sx={{
                  textAlign: 'center',
                  borderLeft: '3px solid #ebebeb',
                  paddingLeft: '10px !important',
                }}
              >
                {handleUserPermissionAllow(
                  UserPermissionEnum.PackAndShip_Edit,
                ) && (
                  <>
                    <Button
                      sx={{
                        marginRight: 2,
                        marginBottom: 0,
                        padding: '7px 15px',
                        background: '#ECF0F8',
                        borderColor: '#DADCE0',
                        color: '#3375E0',
                        fontWeight: 600,
                        textTransform: 'capitalize',
                      }}
                      variant="outlined"
                      size="small"
                      disabled={
                        !pickTicketFormObj?.palletizeButtonEnable ||
                        pickTicketFormObj?.isComplete
                      }
                      onClick={handlePalletizeOpen}
                    >
                      Palletize (Press P)
                    </Button>
                    <Button
                      sx={{
                        marginBottom: 0,
                        padding: '7px 15px',
                        textTransform: 'capitalize',
                        background: '#052fff',
                      }}
                      variant="contained"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();

                        if (
                          pickTicketFormObj?.isCustomOrOtherCarrier !== true
                        ) {
                          if (!WeightsValidation()) {
                            return;
                          }
                        }

                        handlePackageModelOpen();
                      }}
                    >
                      Next Package (Press B)
                    </Button>
                  </>
                )}
              </MUIGrid>
            </MUIGrid>
            {isShowBarcodeError && (
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ borderRight: '1px solid #dde0e3' }}
              >
                <MUIGrid
                  item
                  xs={6}
                  sx={{
                    marginLeft: 1.4,
                  }}
                >
                  <Box
                    className="customizeSnackActionRed pulse"
                    sx={{ display: '' }}
                  >
                    <Typography variant="subtitle2">
                      {messageShowBarcodeError}
                    </Typography>
                  </Box>
                </MUIGrid>
              </MUIGrid>
            )}
          </Card>
        )}
    </div>
  );
};

const PackingInstruction = () => {
  const {
    pickTicketFormObj,
    setIsChecklistComplete,
    checklists,
    setChecklists,
  } = usePackAndShipContext();

  useEffect(() => {
    const checklistsChecked = checklists.filter((item) => item.isChecked);
    if (checklistsChecked.length === checklists.length) {
      setIsChecklistComplete(true);
    } else {
      setIsChecklistComplete(false);
    }
  }, [checklists]);

  return (
    <Box padding={2}>
      {pickTicketFormObj?.packingInstructions && (
        <>
          <Typography variant="h6" fontWeight={700}>
            Packing Instructions
          </Typography>

          {pickTicketFormObj?.packingInstructions.split(';').map((x) => (
            <Typography variant="subtitle2">{x}</Typography>
          ))}
          <br />
        </>
      )}

      {pickTicketFormObj?.specialInstructions && (
        <>
          <Typography variant="h6" fontWeight={700}>
            Special Instructions
          </Typography>

          {pickTicketFormObj?.specialInstructions.split(';').map((x) => (
            <Typography variant="subtitle2">{x}</Typography>
          ))}
          <br />
        </>
      )}

      {checklists?.length > 0 && (
        <>
          <Typography variant="h6" fontWeight={700}>
            Checklist
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              columnCount: checklists?.length > 4 ? '2' : '0',
              overflowWrap: 'break-word',
            }}
          >
            {checklists?.length === 0 && 'N/A'}

            {checklists.map((checklist: IOrderChecklistObj) => (
              <>
                <FormControlLabel
                  key={checklist.orderChecklistId}
                  label={checklist.name}
                  control={
                    <Checkbox
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setChecklists((prev) =>
                          prev.map((c: IOrderChecklistObj) => ({
                            ...c,
                            isChecked:
                              c.orderChecklistId === checklist.orderChecklistId
                                ? event.target.checked
                                : c.isChecked,
                          })),
                        );
                      }}
                      checked={checklist.isChecked}
                    />
                  }
                />
                <br />
              </>
            ))}
          </Typography>
        </>
      )}
    </Box>
  );
};

const PackAndShipPackageTable = ({
  serialNoColumnIsHidden,
  lotColumnIsHidden,
  dataRow,
  onItemDeleteClick,
}: IPackAndShipTable) => {
  const { pickTicketFormObj, updatePackageLineItemQtyOnClick } =
    usePackAndShipContext();

  const { handleUserPermissionAllow } = useContext(AuthContext);

  const pickTicketPackageLineItemsColumns: GridColumns = [
    {
      field: 'sku',
      flex: 1,
      minWidth: 180,
      renderHeader: () => <Typography fontWeight="bold">SKU</Typography>,
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.sku}
        </Typography>
      ),
    },
    {
      field: 'description',
      flex: 1,
      minWidth: 180,
      renderHeader: () => (
        <Typography fontWeight="bold">Description</Typography>
      ),
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.description}
        </Typography>
      ),
    },
    {
      field: 'serialNo',
      flex: 1,
      minWidth: 180,
      hide: serialNoColumnIsHidden,
      renderHeader: () => <Typography fontWeight="bold">Serial No</Typography>,
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.serialNo}
        </Typography>
      ),
    },
    {
      field: 'uomStr',
      flex: 1,
      minWidth: 130,
      renderHeader: () => <Typography fontWeight="bold">UOM</Typography>,
    },
    {
      field: 'lotNo',
      flex: 1,
      minWidth: 130,
      hide: lotColumnIsHidden,
      renderHeader: () => <Typography fontWeight="bold">Lot No.</Typography>,
    },
    {
      field: 'lotExpirationDate',
      flex: 1,
      minWidth: 180,
      hide: lotColumnIsHidden,
      renderHeader: () => (
        <Typography fontWeight="bold">Lot Exp. Date</Typography>
      ),
      renderCell: (params) => (
        <Typography variant="inherit">
          {params.row.lotExpirationDate
            ? moment(params.row.lotExpirationDate).format('MM/DD/YYYY')
            : ''}
        </Typography>
      ),
    },
    {
      field: 'quantity',
      flex: 1,
      minWidth: 130,
      renderHeader: () => <Typography fontWeight="bold">Quantity</Typography>,
      renderCell: (param) => (
        <Box sx={{ display: 'flex', flexBasis: 10, alignItems: 'center' }}>
          {handleUserPermissionAllow(UserPermissionEnum.PackAndShip_Edit) && (
            <IconButton
              sx={{
                backgroundColor: '#888',
                borderRadius: '50%',
                color: '#fff',
                height: 24,
                width: 24,
                '& svg': {
                  fontSize: 15,
                },
              }}
              onClick={(e) =>
                updatePackageLineItemQtyOnClick(
                  e,
                  param.row.packageLineItemId,
                  param.row.quantity,
                  'subtraction',
                )
              }
              color="primary"
              aria-label="Minus Quantity"
              component="label"
            >
              <RemoveIcon />
            </IconButton>
          )}
          <Box sx={{ width: 100, textAlign: 'center' }}>
            <Typography sx={{ fontSize: 'inherit' }}>
              {param.row.quantity}/
              {param.row.isNoPackage === false &&
                pickTicketFormObj?.lineItems.find(
                  (x) =>
                    x?.pickTicketLineItemId === param.row.pickTicketLineItemId,
                )?.qty}
              {param.row.isNoPackage === true && param.row.totalQuantity}
            </Typography>
          </Box>
          {handleUserPermissionAllow(UserPermissionEnum.PackAndShip_Edit) && (
            <IconButton
              sx={{
                backgroundColor: '#888',
                borderRadius: '50%',
                color: '#fff',
                height: 24,
                width: 24,
                '& svg': {
                  fontSize: 15,
                },
              }}
              onClick={(e) =>
                updatePackageLineItemQtyOnClick(
                  e,
                  param.row.packageLineItemId,
                  param.row.quantity,
                  'add',
                )
              }
              color="primary"
              aria-label="Add Quantity"
              component="label"
            >
              <AddIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
    {
      field: 'print',
      headerName: '',
      width: 100,
      hide: !handleUserPermissionAllow(UserPermissionEnum.PackAndShip_Edit),
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => {
            onItemDeleteClick(params.row);
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      ),
    },
  ];

  if (!dataRow) {
    <div>No Data</div>;
  }

  return (
    <Box
      sx={{
        height: `${dataRow.length > 1 ? 87.5 * dataRow.length : 134}px`,
        borderBottom: 'none',
      }}
    >
      <DatagridPremium
        autoHeight
        rows={dataRow}
        density="compact"
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        disableSelectionOnClick
        columns={pickTicketPackageLineItemsColumns}
        hideFooterPagination
        hideFooter
        getRowId={(row: IPickTicketLineItemsFormObj) => row.packageLineItemId}
      />
    </Box>
  );
};

const PackAndShipPackageBox = (props: IPackageBox) => {
  const { packageInfo, packageCurrentIndex, arrayIndex } = props;
  const {
    onOpenPackAndShipAddItemModal,
    onOpenConfirmDeleteDialog,
    setIsChangePackageModalOpen,
    setIsNextPackageModalOpen,
    isNextPackageModalOpen,
    isChangePackageModalOpen,
  } = useContext(GlobalContext);

  const {
    pickTicketFormObj,
    allowBarcodeScanner,
    lastPackageWeightLbsFormError,
    setDataSelectedPackageLineItem,
    setPackageIdValue,
    setPickTicketFormObj,
    setDataSelectedPackage,
    setAllowBarcodeScanner,
    setIsNotPrimaryPackage,
    updateWeightPoundFunction,
    setUseNotPrimaryPackageId,
    setIsOpenDeleteConfirmModal,
    setIsNewPackageForManualItem,
  } = usePackAndShipContext();

  const { handleUserPermissionAllow } = useContext(AuthContext);

  const itemRef = useRef(null);
  const weightRef = useRef(null);

  const handleDeletePackageLineItem = async (rowData: any) => {
    setDataSelectedPackageLineItem(rowData);
    setIsOpenDeleteConfirmModal((prev) => ({
      ...prev,
      package: false,
      packageLineItem: true,
    }));

    onOpenConfirmDeleteDialog();
  };

  const handleDeletePackage = async (rowData: any) => {
    setDataSelectedPackage((prev) => ({
      ...prev,
      packageId: rowData,
    }));
    setIsOpenDeleteConfirmModal((prev) => ({
      ...prev,
      package: true,
      packageLineItem: false,
    }));
    onOpenConfirmDeleteDialog();
  };

  const handleWeightOnChanged = async (
    packageIdParam: string,
    weightPoundParam: string,
  ) => {
    updateWeightPoundFunction(packageIdParam, weightPoundParam);
  };

  const handleWeightFocus = () => {
    setAllowBarcodeScanner(false);
    clearInterval(weightRef.current);
  };

  const handleWeightFocusLeave = () => {
    setAllowBarcodeScanner(true);
    clearInterval(weightRef.current);
  };

  useEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem('printerSettingsState');
    const printerSettingsState = JSON.parse(
      stateFromLocalStorage,
    ) as PrinterSettingsState;

    if (printerSettingsState !== null && printerSettingsState.scale !== null) {
      if (
        document.activeElement === itemRef.current &&
        (!isNextPackageModalOpen || !isChangePackageModalOpen)
      ) {
        let attempt = 0;
        weightRef.current = setInterval(() => {
          GetScale(printerSettingsState.scale).then((x) => {
            if (x?.mass?.length > 0) {
              const mcg = Number(x?.mass[0]); // microgram
              const lb = mcg / Number(453592370);
              const lbFormatted = Number(lb.toFixed(2));

              if (mcg > 0) {
                setPickTicketFormObj((prev) => ({
                  ...prev,
                  packages: prev.packages.map((obj) => {
                    if (obj.packageId === packageInfo.packageId) {
                      return {
                        ...obj,
                        weightPound: x?.mass[0] ? lbFormatted : null,
                      };
                    }
                    return {
                      ...obj,
                    };
                  }),
                }));

                // after 2 loops (2 seconds)
                if (attempt >= 2) {
                  clearInterval(weightRef.current);

                  // this will trigger the 'Weight' onBlur
                  itemRef.current.blur();
                }

                attempt += 1;
              }
            }
          });

          if (isNextPackageModalOpen || isChangePackageModalOpen) {
            clearInterval(weightRef.current);
          }
        }, 1000);
      } else {
        clearInterval(weightRef.current);
      }
    }

    return () => clearInterval(weightRef.current);
  }, [allowBarcodeScanner]);

  useEffect(() => {
    if (
      lastPackageWeightLbsFormError.packages !== undefined &&
      lastPackageWeightLbsFormError.packages?.length > 0 &&
      lastPackageWeightLbsFormError.packages[arrayIndex][0] !== ''
    ) {
      itemRef.current.focus();
      clearInterval(weightRef.current);
    }
  }, [lastPackageWeightLbsFormError]);

  return (
    <MUIGrid
      container
      spacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <MUIGrid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" fontWeight="bold">
          {packageInfo.packageName} - &nbsp;
        </Typography>
        <Typography variant="subtitle1" fontWeight="bold">
          {(packageInfo?.isCustomPackage === true ||
            packageInfo?.isNoPackage === true) &&
            ` ${packageInfo.widthInch}X${packageInfo.heightInch}X${packageInfo.lengthInch} `}
          {packageInfo.isNoPackage === false &&
            packageInfo?.isCustomPackage !== true &&
            // eslint-disable-next-line no-nested-ternary
            (packageInfo.itemAsPackage.label ? (
              packageInfo.itemAsPackage.label
            ) : packageInfo.carrierPredefinedPackage ? (
              packageInfo.carrierPredefinedPackage
            ) : (
              <p style={{ color: 'red', margin: '0px' }}>No package selected</p>
            ))}
        </Typography>

        <Button
          sx={{
            marginLeft: '20px',
            padding: '7px 15px',
            background: '#ECF0F8',
            borderColor: '#DADCE0',
            color: '#3375E0',
            fontWeight: 600,
            textTransform: 'capitalize',
            lineHeight: 1,
            fontSize: '0.7rem !important',
            display: handleUserPermissionAllow(
              UserPermissionEnum.PackAndShip_Edit,
            )
              ? 'inline-block '
              : 'none',
          }}
          variant="outlined"
          size="small"
          onClick={() => {
            if (packageCurrentIndex !== packageInfo.packageId) {
              setIsNotPrimaryPackage(true);
              setUseNotPrimaryPackageId(packageInfo.packageId);
            }
            setIsChangePackageModalOpen(true);
          }}
        >
          Change Package{' '}
          {packageCurrentIndex === packageInfo.packageId ? '(Press A)' : ''}
        </Button>

        <Button
          sx={{
            marginLeft: '15px',
            padding: '6px 10px',
            color: '#052fff',
            borderColor: '#ff5e5e',
            borderWidth: 'revert',
            height: '25px',
            minWidth: '45px !important',
          }}
          variant="outlined"
          size="small"
          onClick={() => {
            handleDeletePackage(packageInfo.packageId);
          }}
        >
          <DeleteOutlineIcon sx={{ color: '#ff5e5e' }} />
        </Button>
      </MUIGrid>
      <MUIGrid
        item
        xs={4}
        sx={{
          textAlignLast: 'right',
          paddingLeft: '0px',
        }}
      >
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            alignItems: 'center !important',
          }}
        >
          <MUIGrid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'right',
            }}
          >
            {packageCurrentIndex === packageInfo.packageId &&
              handleUserPermissionAllow(
                UserPermissionEnum.PackAndShip_Edit,
              ) && (
                <Button
                  {...(pickTicketFormObj?.isShipAndComplete && {
                    disabled: true,
                  })}
                  sx={{
                    padding: '7px 15px',
                    textTransform: 'capitalize',
                    borderColor: '#052fff',
                    borderWidth: 'revert',
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    const packageId: number = packageInfo?.packageId;
                    setPackageIdValue(packageId);
                    setIsNewPackageForManualItem(false);
                    onOpenPackAndShipAddItemModal();
                  }}
                  disabled={pickTicketFormObj?.isComplete}
                >
                  Add Item
                </Button>
              )}
            <Typography
              variant="body2"
              sx={{
                marginLeft: '15px',
              }}
            >
              Weight
            </Typography>
            <TextField
              name="weight"
              value={packageInfo?.weightPound}
              onFocus={handleWeightFocus}
              onMouseLeave={handleWeightFocusLeave}
              onChange={(e) => {
                setPickTicketFormObj((prev) => ({
                  ...prev,
                  packages: prev.packages.map((obj) => {
                    if (obj.packageId === packageInfo.packageId) {
                      return {
                        ...obj,
                        weightPound: e.target.value
                          ? Number(e.target.value)
                          : null,
                      };
                    }
                    return {
                      ...obj,
                    };
                  }),
                }));
              }}
              onBlur={() => {
                if (packageInfo.packageId) {
                  handleWeightOnChanged(
                    packageInfo.packageId.toString(),
                    packageInfo?.weightPound?.toString(),
                  );
                }

                clearInterval(weightRef.current);
                setAllowBarcodeScanner(true);
              }}
              key={arrayIndex}
              inputRef={itemRef}
              size="small"
              sx={{
                color: '#052fff',
                padding: '0px 10px',
                width: '30%',
              }}
              type="number"
              {...(lastPackageWeightLbsFormError.packages !== undefined &&
                lastPackageWeightLbsFormError.packages?.length > 0 && {
                  error:
                    lastPackageWeightLbsFormError?.packages[arrayIndex] !==
                    undefined
                      ? lastPackageWeightLbsFormError?.packages[arrayIndex][0]
                      : '',
                })}
            />
            <Typography variant="body2"> (lbs)</Typography>
          </MUIGrid>
        </MUIGrid>
        {lastPackageWeightLbsFormError?.packages !== undefined &&
          lastPackageWeightLbsFormError.packages?.length > 0 && (
            <MUIGrid item xs={12}>
              <Typography variant="body2" sx={{ color: 'red' }}>
                {lastPackageWeightLbsFormError?.packages[arrayIndex] !==
                undefined
                  ? lastPackageWeightLbsFormError?.packages[arrayIndex][0]
                  : ''}
              </Typography>
            </MUIGrid>
          )}
      </MUIGrid>
      <MUIGrid
        item
        xs={12}
        sx={{
          paddingBottom: '0px !important',
        }}
      >
        <PackAndShipPackageTable
          serialNoColumnIsHidden={packageInfo.serialNoColumnIsHidden}
          lotColumnIsHidden={packageInfo.lotColumnIsHidden}
          dataRow={packageInfo.packageLineItems}
          onItemDeleteClick={handleDeletePackageLineItem}
        />
      </MUIGrid>
    </MUIGrid>
  );
};

const PackAndShipPackage = () => {
  const {
    pickTicketFormObj,
    dataSelectedPackageLineItem,
    dataSelectedPackage,
    isOpenDeleteConfirmModal,
    setAllowBarcodeScanner,
  } = usePackAndShipContext();

  const {
    setDeletePalletizeId,
    setEditPalletizeData,
    setIsEditPalletizeMode,
    setIsPalletizeModalOpen,
    setIsDeletePalletizeModalOpen,
  } = useContext(GlobalContext);

  const [lpGroup, setLpGroup] = useState<any[]>([]);
  const [currentPackage, setCurrentPackage] = useState(0);
  const [currentLP, setCurrentLP] = useState<ILicensePlate>();
  const [openEditLP, setOpenEditLP] = useState(false);

  const onSaveEditLPClick = async (data: ILicensePlate) => {
    await updatePackageLicensePlate({
      licensePlateId: data.licensePlateId,
      pickTicketId: pickTicketFormObj.pickTicketId,
      heightInch: data.heightInch,
      widthInch: data.widthInch,
      lengthInch: data.lengthInch,
      weightPound: data.weightPound,
    });
    setOpenEditLP(false);
    setAllowBarcodeScanner(true);
  };

  const onCancelEditLPClick = () => {
    setOpenEditLP(false);
    setAllowBarcodeScanner(true);
  };

  const handleExpandChange = (id: number) => () => {
    setLpGroup(
      lpGroup.map((obj: IPalletizeGroup) => {
        if (obj.outboundPalletId === id) {
          return { ...obj, expandable: !obj.expandable };
        }
        return obj;
      }),
    );
  };

  useEffect(() => {
    if (pickTicketFormObj?.packages && pickTicketFormObj?.packages.length > 0) {
      const currentId = pickTicketFormObj.packages[0].packageId;
      const rawData: IPalletizeGroup[] = _.chain(pickTicketFormObj?.packages)
        .groupBy('outboundPalletId')
        .map((data) => ({
          outboundPalletId: data[0]?.outboundPalletId,
          itemAsPalletName: data[0]?.itemAsPalletName,
          packages: data,
          expandable: true,
        }))
        .value()
        .sort((a, b) => a.outboundPalletId - b.outboundPalletId);
      rawData.reverse();
      setCurrentPackage(currentId);
      setLpGroup(rawData);
    }
  }, [pickTicketFormObj?.packages]);

  return (
    <>
      {pickTicketFormObj?.packages?.length !== 0 &&
        !pickTicketFormObj?.isApprove &&
        !pickTicketFormObj?.isPack &&
        !pickTicketFormObj?.isPackAndShip && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            {lpGroup.map((g: IPalletizeGroup) =>
              g.outboundPalletId ? (
                <Accordion
                  key={g.outboundPalletId}
                  sx={{ background: '#fff' }}
                  expanded={g.expandable}
                >
                  <AccordionSummary
                    key={g.outboundPalletId}
                    expandIcon={
                      <ExpandMoreIcon
                        style={{ cursor: 'pointer' }}
                        onClick={handleExpandChange(g.outboundPalletId)}
                      />
                    }
                    aria-controls={`${g.itemAsPalletName}-content`}
                    id={`${g.itemAsPalletName}-header`}
                    sx={{
                      minHeight: '40px !important',
                      height: '40px',
                      cursor: 'unset !important',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Pallet:
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: '#052fff', fontWeight: 'bold' }}
                      >
                        {g.itemAsPalletName}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <IconBoxPrimaryOutline
                          onClick={() => {
                            setEditPalletizeData((e) => ({
                              ...e,
                              outboundPalletId: g.outboundPalletId,
                            }));
                            setIsEditPalletizeMode(true);
                            setIsPalletizeModalOpen(true);
                          }}
                          sx={{
                            height: '30px',
                          }}
                          title="Edit"
                        >
                          <EditIcon />
                        </IconBoxPrimaryOutline>
                        <IconBoxDangerOutline
                          onClick={() => {
                            setDeletePalletizeId(g.outboundPalletId);
                            setIsDeletePalletizeModalOpen(true);
                          }}
                          sx={{
                            height: '30px',
                          }}
                          title="Delete"
                        >
                          <DeleteIcon />
                        </IconBoxDangerOutline>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {g.packages.map((p: any, index) => (
                      <BoxAccordionChild key={p.packageId}>
                        <PackAndShipPackageBox
                          packageInfo={p}
                          packageCurrentIndex={currentPackage}
                          arrayIndex={index}
                        />
                      </BoxAccordionChild>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ) : (
                g.packages.map((p: any, index) => (
                  <Paper
                    key={p.packageId}
                    sx={{ background: '#fff', padding: '24px' }}
                  >
                    <PackAndShipPackageBox
                      packageInfo={p}
                      packageCurrentIndex={currentPackage}
                      arrayIndex={index}
                    />
                  </Paper>
                ))
              ),
            )}
          </Box>
        )}
      {isOpenDeleteConfirmModal.packageLineItem && (
        <ConfirmationDialog
          packageLineItemData={dataSelectedPackageLineItem}
          deletePackageLineItem
        />
      )}
      {isOpenDeleteConfirmModal.package && (
        <ConfirmationDialog
          packagePackAndShipData={dataSelectedPackage}
          deletePackagePackAndShip
        />
      )}
      <PalletizeModalEdit
        isOpen={openEditLP}
        licensePlate={currentLP}
        pickTicketId={pickTicketFormObj?.pickTicketId}
        onSave={onSaveEditLPClick}
        onCancel={onCancelEditLPClick}
      />
      <DeletePalletizeModal />
    </>
  );
};

const BillingChargeSection = () => {
  const {
    billingAdhocChargesData,
    errorBillingAdhocItemValues,
    handleChangeBillingChargeSection,
    handleRemoveBillingChargeSection,
    pickTicketFormObj,
    pageViewer,
    isBillingChargeSectionEditable,
    setIsBillingChargeSectionEditable,
    isBillingChargeSectionBusy,
    viewLoadData,
    validateBillingCharge,
    handleOnClickSaveBillingCharge,
    dataBillingChargeSectionSelected,
  } = usePackAndShipContext();

  const { handleUserPermissionAllow } = useContext(AuthContext);

  const handleOnClickEditBillingCharge = async (e: any) => {
    e.preventDefault();

    setIsBillingChargeSectionEditable(true);
  };

  const handleOnClickCancelBillingCharge = async (e: any) => {
    e.preventDefault();

    viewLoadData().finally(() => {
      validateBillingCharge(null, true);
      setIsBillingChargeSectionEditable(false);
    });
  };

  return (
    <>
      <Card
        sx={{
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ marginBottom: 4 }}
          >
            <MUIGrid item xs={6}>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ marginBottom: 4 }}
              >
                Billing Charge
              </Typography>
            </MUIGrid>

            {pageViewer.isViewOrEdit() && (
              <MUIGrid
                item
                xs={6}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {isBillingChargeSectionEditable === false &&
                  isBillingChargeSectionBusy === false && (
                    <Button
                      variant="contained"
                      size="medium"
                      sx={{
                        height: '40px',
                      }}
                      onClick={handleOnClickEditBillingCharge}
                    >
                      Edit
                    </Button>
                  )}
                {isBillingChargeSectionEditable &&
                  isBillingChargeSectionBusy === false && (
                    <>
                      <Button
                        variant="outlined"
                        size="medium"
                        sx={{
                          height: '40px',
                        }}
                        onClick={handleOnClickCancelBillingCharge}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        sx={{
                          marginLeft: '5px',
                          textTransform: 'capitalize',
                          height: '40px',
                        }}
                        onClick={handleOnClickSaveBillingCharge}
                      >
                        Save
                      </Button>
                    </>
                  )}
                {isBillingChargeSectionBusy && (
                  <>
                    <Button
                      disabled
                      variant="outlined"
                      sx={{
                        height: '40px',
                      }}
                      size="medium"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled
                      variant="contained"
                      sx={{
                        height: '40px',
                      }}
                      size="medium"
                    >
                      <CircularProgress
                        sx={{
                          width: '25px !important',
                          height: '25px !important',
                        }}
                      />
                      <Box sx={{ paddingLeft: '5px' }}>Save</Box>
                    </Button>
                  </>
                )}
              </MUIGrid>
            )}
          </MUIGrid>

          {billingAdhocChargesData.map((model, index) => (
            <>
              <MUIGrid
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ flex: 1 }}
              >
                <MUIGrid item xs={0.4} xl={0.2} alignSelf="center">
                  <Typography fontWeight="bold" align="right">
                    {' '}
                  </Typography>
                </MUIGrid>
                <MUIGrid item xs={3} xl={2} alignSelf="center">
                  <Typography className="textFieldParagraph" fontWeight="bold">
                    {model.chargeName} {model.required ? '*' : ''}
                  </Typography>
                </MUIGrid>

                {handleUserPermissionAllow(
                  UserPermissionEnum.Client_Billing_View_Charge_Rate,
                ) && (
                  <MUIGrid item xs={2} xl={1.5} alignSelf="center">
                    <TextField
                      id="outlined"
                      name="rate"
                      value={model.rate}
                      onChange={(e) => {
                        handleChangeBillingChargeSection(e, index);
                      }}
                      label="Rate"
                      size="small"
                      sx={{ width: '100%' }}
                      error={errorBillingAdhocItemValues[index]?.rate}
                      helperText={errorBillingAdhocItemValues[index]?.rate}
                      {...(pickTicketFormObj?.isShipAndComplete && {
                        disabled: true,
                      })}
                      {...((isBillingChargeSectionEditable === false ||
                        isBillingChargeSectionBusy) && {
                        disabled: true,
                      })}
                    />
                  </MUIGrid>
                )}
                <MUIGrid item xs={1.5} xl={1} alignSelf="center">
                  <TextField
                    id="outlined"
                    name="qty"
                    value={model.qty || ''}
                    onChange={(e) => {
                      handleChangeBillingChargeSection(e, index);
                    }}
                    label="Qty"
                    size="small"
                    sx={{ width: '100%' }}
                    error={errorBillingAdhocItemValues[index]?.qty}
                    helperText={errorBillingAdhocItemValues[index]?.qty}
                    {...(pickTicketFormObj?.isShipAndComplete && {
                      disabled: true,
                    })}
                    {...((isBillingChargeSectionEditable === false ||
                      isBillingChargeSectionBusy) && {
                      disabled: true,
                    })}
                    required={model?.required}
                  />
                </MUIGrid>

                {handleUserPermissionAllow(
                  UserPermissionEnum.Client_Billing_View_Charge_Rate,
                ) && (
                  <>
                    <MUIGrid item xs={1} xl={0.5} alignSelf="center">
                      <Typography
                        className="textFieldParagraph"
                        fontWeight="bold"
                        align="right"
                      >
                        Total:
                      </Typography>
                    </MUIGrid>
                    <MUIGrid item xs={1} alignSelf="center">
                      <Typography className="textFieldParagraph">
                        {model.total}
                      </Typography>
                    </MUIGrid>
                  </>
                )}
                <MUIGrid item xs={2} xl={1.5}>
                  <TextField
                    rows={1}
                    multiline
                    id="outlined-multiline-flexible"
                    name="notes"
                    value={model.notes}
                    onChange={(e) => {
                      handleChangeBillingChargeSection(e, index);
                    }}
                    label="Notes"
                    size="small"
                    {...(pickTicketFormObj?.isShipAndComplete && {
                      disabled: true,
                    })}
                    {...((isBillingChargeSectionEditable === false ||
                      isBillingChargeSectionBusy) && {
                      disabled: true,
                    })}
                  />
                </MUIGrid>
                {handleUserPermissionAllow(
                  UserPermissionEnum.PackAndShip_Edit,
                ) && (
                  <MUIGrid item xs={1} xl={0.5} alignSelf="center">
                    <IconButton
                      sx={{
                        background: '#F8F9FA',
                        borderRadius: 1,
                        width: 30,
                        height: 30,
                      }}
                      onClick={() => handleRemoveBillingChargeSection(model)}
                      color="primary"
                      aria-label="Minus Quantity"
                      component="label"
                      {...(pickTicketFormObj?.isShipAndComplete && {
                        disabled: true,
                      })}
                      {...((isBillingChargeSectionEditable === false ||
                        isBillingChargeSectionBusy) && {
                        disabled: true,
                      })}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </MUIGrid>
                )}
              </MUIGrid>
              <Divider sx={{ mt: 1, mb: 1 }} />
            </>
          ))}
        </Box>
      </Card>
      <ConfirmationDialog
        billingChargeSectionData={dataBillingChargeSectionSelected}
        deleteBillingChargeSectionPackAndShip
      />
    </>
  );
};

const PackAndShipContainer = () => {
  const {
    checklists,
    printOfLPItem,
    pickTicketFormObj,
    buttonSinglePrintRef,
    billingAdhocChargesData,
    setAllowBarcodeScanner,
    approveOrApproveAndPackOnClickV2,
  } = usePackAndShipContext();
  const {
    isPackAndShipAddItemModalOpen,
    isChangePackageModalOpen,
    isNextPackageModalOpen,
    isPalletizeModalOpen,
    idToPrintLpnFormTemplate,
  } = useContext(GlobalContext);

  const componentSinglePrintRef = useRef(null);

  const handleOnClickSinglePrintTrigger = useReactToPrint({
    content: () => componentSinglePrintRef.current,
  });

  useEffect(() => {
    setAllowBarcodeScanner(true);
  }, []);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (pickTicketFormObj?.isPack === true) {
        if (event.key.toLowerCase() === 'p') {
          event.preventDefault();
          approveOrApproveAndPackOnClickV2(event, 2);
        }
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [pickTicketFormObj]);

  return (
    <Box sx={{ display: 'flex', gap: '15px', flexDirection: 'column' }}>
      {!isPackAndShipAddItemModalOpen &&
        !isPalletizeModalOpen &&
        (isChangePackageModalOpen || isNextPackageModalOpen) && (
          <ChangeAndNextPackage />
        )}
      {!isPackAndShipAddItemModalOpen &&
        isPalletizeModalOpen &&
        (!isNextPackageModalOpen || !isChangePackageModalOpen) && (
          <PalletizeModal />
        )}
      <EachOrderLineShouldbeApprovedModal />
      <LabelToPrintWhenCompletedModal />
      <ConfirmationForCustomAndOtherCarrierModal />
      <DeleteAllPackagesModal />
      <AddCustomerPackageModal />
      <SerialNumberScanModal />
      <RatesToCompleteModal />
      <AutoCreateLabelModal />
      <AutoCreateLabelErrorMessageModal />
      <VoidFillModal />
      <div style={{ display: 'none' }}>
        <SinglePrintLicensePlate
          ref={componentSinglePrintRef}
          data={printOfLPItem}
        />
        <button
          type="button"
          ref={buttonSinglePrintRef}
          onClick={handleOnClickSinglePrintTrigger}
        >
          This one is trigger on single print receipt license plate item
        </button>
        {idToPrintLpnFormTemplate !== null && (
          <Printformtemplate
            preview={false}
            dynamicId={idToPrintLpnFormTemplate}
            formtemplateTypeId={FormTemplateTypeEnum.PalletLabels}
            forPalletLabelsIsUseLicense
          />
        )}
      </div>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid
          item
          sm={12}
          md={
            pickTicketFormObj?.packingInstructions || checklists?.length > 0
              ? 9.5
              : 12
          }
        >
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={9}>
              <PackAndShipScanner />
            </MUIGrid>
            <MUIGrid item xs={3}>
              {pickTicketFormObj?.isShowWarningForPartialOrder && (
                <Box className="warningCard">
                  <WarningIcon sx={{ color: 'red' }} />
                  <Typography variant="body1" sx={{ fontSize: 'small' }}>
                    <b>Warning:</b> This is a partial order. <br /> Not all
                    order items are included in this pick ticket.
                  </Typography>
                </Box>
              )}
              <PackAndShipApproved />
            </MUIGrid>
            <MUIGrid item xs={12}>
              <PackAndShipPickTicket />
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>

        {(pickTicketFormObj?.packingInstructions || checklists?.length > 0) && (
          <MUIGrid
            item
            sm={12}
            md={2.5}
            sx={{
              display: pickTicketFormObj?.isApproveAndPack ? 'block' : 'none',
            }}
          >
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                gap: '8px',
                overflowY: 'auto',
                padding: '0px !important',
              }}
            >
              <PackingInstruction />
            </Card>
          </MUIGrid>
        )}
      </MUIGrid>
      <PackAndShipPackage />
      {billingAdhocChargesData.length > 0 && <BillingChargeSection />}
    </Box>
  );
};

export default React.memo(PackAndShipContainer);
