import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { NoYesEnum } from 'common/enums';
import { DropdownListItem, DropdownListItemType } from 'common/models';
import {
  CreateOrderLineLPNValidationRequestModel,
  CreateOrderLineLPNValidationResponseModel,
  LPNValidationOrderLineItemModel,
} from 'common/models/orderlineitems/CreateOrderLineLPNValidation';
import { PalletInventoryDropdownListItem } from 'common/models/pallets';
import {
  OrderLineItemRequestModel,
  ValidateOrderLineItemSerialNumberRequestModel,
  ValidateOrderLineItemSerialNumberRequestTypeEnum,
  ValidateOrderLineItemSerialNumberResponseModel,
} from 'common/models/serialnumbers/ValidateOrderLineItemSerialNumber';
import DatePickerInput from 'components/datepicker';
import { snackActions } from 'config/snackbar.js';
import { id } from 'date-fns/locale';
import moment from 'moment';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import { ItemUnitOfMeasureType } from 'pages/receiving/receipts/subpage';
import {
  useSalesOrderContext,
  ModalTitleStatus,
  ILineItemType,
  UOMType,
} from 'pages/sales/salesorder/context';
import ConfirmationModal from 'pages/shared/confirmationmodal';
import {
  AutoCompletePaper,
  AutoCompletePopper,
  MUIContainer,
  MUIContent,
} from 'pages/shared/editreceiptmodal';
import {
  GetItemSerialNumberDDL,
  getItemUnitOfMeasureWithPalletDDL,
  GetItemPalletInventoryDDL,
  getItemDetail,
} from 'services/api/item/item.api';
import { createLotNo, getLotNo } from 'services/api/receipts/receipts-new.api';
import {
  getUOM,
  updateOrderLineItem,
  createOrderLineItem,
  backOrderForNew,
  backOrderForExisting,
  CreateOrderLineLPNValidation,
} from 'services/api/salesorders/salesorders-new.api';
import { ValidateOrderLineItemSerialNumber } from 'services/api/serialnumbers/serialnumbers.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Modal,
  Box,
  Typography,
  TextField,
  Divider,
  Grid as MUIGrid,
  InputAdornment,
  Checkbox,
  Button,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { styled as MuiStyled } from '@mui/material/styles';

import { autoCompleteTimeStamp } from '../../../helpers/autocompletetimestamp';
import ImportGlobalMessageModal from '../import-global-message-modal';

type AutocompleteBase = {
  label?: string;
  value?: number;
};

type SkuType = ExtendSkuType & AutocompleteBase;

type ExtendSkuType = {
  sku?: string;
  description: string;
};
type ExtendLotNoType = {
  lotId?: number;
  lotExpiration?: string;
};
export type LotNoType = AutocompleteBase & ExtendLotNoType;

type OrderItemType = {
  lineId?: number | string;
  primaryUOM: number;
  primaryUOMAbbr: string;
  itemUnitOfMeasureId?: number;
  otherUOMStr: string;
  otherUOMAbbr: string;
  otherUOMCount?: number;
  uomType: number;
  quantity: string;
  totalCases?: string;
  lotId: number;
  lotNo: string;
  lotExpiration?: string;
  note: string;
  sellPrice: string;
  uomError: string;

  // Kit on Demand
  isKitOnDemand: boolean;
  isKitOnDemandComponent: boolean;
  orderLineItemKitOnDemandId?: number | string;
  itemKitOnDemandId?: number;
  itemKitOnDemandQty?: number;

  // Serial No
  isSerialNo: boolean;
  serialNumbersValue: DropdownListItemType<string>[];
  serialNumbersValueError: string;

  // Order By LPN
  isOrderByLPN: boolean;
  palletInventoryId?: number;
  lpn?: string;
  lpnError: string;
} & ExtendSkuType;

type KitOnDemand = {
  itemKitOnDemandId: number;
  itemId: number;
  sku: string;
  description: string;
  isNeedPicking: boolean;
  qty: number;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default React.memo(() => {
  const initialOrderItemState: OrderItemType = {
    primaryUOM: 0,
    primaryUOMAbbr: '',
    itemUnitOfMeasureId: null,
    otherUOMStr: '',
    otherUOMAbbr: '',
    otherUOMCount: null,
    uomType: 0,
    description: '',
    quantity: '',
    totalCases: '',
    lotId: 0,
    lotNo: '',
    lotExpiration: '',
    note: '',
    sellPrice: '',
    uomError: '',
    isKitOnDemand: false,
    isKitOnDemandComponent: false,
    orderLineItemKitOnDemandId: null,
    itemKitOnDemandId: null,
    itemKitOnDemandQty: null,
    isSerialNo: false,
    serialNumbersValue: [],
    serialNumbersValueError: '',
    isOrderByLPN: false,
    palletInventoryId: null,
    lpn: null,
    lpnError: '',
  };

  const errorInitialOrderItemState: OrderItemType = {
    primaryUOM: 0,
    primaryUOMAbbr: '',
    itemUnitOfMeasureId: null,
    otherUOMStr: '',
    otherUOMAbbr: '',
    otherUOMCount: null,
    uomType: 0,
    description: '',
    quantity: '',
    totalCases: '',
    lotId: 0,
    lotNo: '',
    lotExpiration: '',
    note: '',
    sellPrice: '',
    uomError: '',
    isKitOnDemand: false,
    isKitOnDemandComponent: false,
    orderLineItemKitOnDemandId: null,
    itemKitOnDemandId: null,
    itemKitOnDemandQty: null,
    isSerialNo: false,
    serialNumbersValue: [],
    serialNumbersValueError: '',
    isOrderByLPN: false,
    palletInventoryId: null,
    lpn: null,
    lpnError: '',
  };

  const {
    currentUser,
    currentLocationAndFacility,
    isWarehouseCustomerAccount,
  } = useContext(AuthContext);
  const {
    modalTitle,
    pageViewer,
    optionSkus,
    salesOrderForm,
    singleOrderLineItemRow,
    apiBackOrderResponse,
    queryStringItemOrders,
    setModalTitle,
    orderItemRows,
    setOrderItemRows,
    setSalesOrderForm,
    setApiBackOrderResponse,
    setOptionsUOM,
    optionsUOM,
    uomValue,
    setUomValue,
    setQueryStringItemOrders,
    optionsSerialNumber,
    setOptionsSerialNumber,
    serialNumbersValue,
    setSerialNumbersValue,
    viewLoadData,
  } = useSalesOrderContext();
  const {
    isOrderItemModalOpen,
    onCloseOrderItemModal,
    onOpenConfirmationModal,
    setImportGlobalErrorMessageList,
    setIsImportGlobalErrorModalOpen,
  } = useContext(GlobalContext);
  const navigate = useNavigate();

  const [optionLotNo, setOptionLotNo] = useState<LotNoType[]>([]);
  const [lotNoValue, setLotNoValue] = useState(null);

  const [skuValue, setSkuValue] = useState<SkuType>(null);
  const [orderLineFormErrors, setOrderLineFormErrors] = useState(
    errorInitialOrderItemState,
  );

  const [orderItem, setOrderItem] = useState<OrderItemType>(
    initialOrderItemState,
  );

  const [kitOnDemands, setKitOnDemands] = useState<KitOnDemand[]>([]);

  // LPN
  const isOrderByLPNDDL: DropdownListItem[] = [
    {
      value: NoYesEnum.No,
      text: 'No',
    },
    {
      value: NoYesEnum.Yes,
      text: 'Yes',
    },
  ];
  const [isOrderByLPN, setIsOrderByLPN] = useState<DropdownListItem>({
    value: NoYesEnum.No,
    text: 'No',
  });
  const [itemLPNDDL, setItemLPNDDL] = useState<
    PalletInventoryDropdownListItem[]
  >([]);
  const [itemLPN, setItemLPN] = useState<PalletInventoryDropdownListItem>(null);

  const onLoadGetLotNoOnChange = async (itemId) => {
    try {
      return await getLotNo(itemId);
    } catch (err) {
      return err;
    }
  };

  const onLoadGetItemUnitOfMeasureDDLOnChange = async (itemId) => {
    try {
      return await getItemUnitOfMeasureWithPalletDDL(itemId);
    } catch (err) {
      return err;
    }
  };

  const onLoadGetItemSerialNumberDDLOnChange = async (
    itemId: number,
    customerFacilityId: number,
  ) => {
    try {
      return await GetItemSerialNumberDDL(itemId, customerFacilityId);
    } catch (err) {
      return err;
    }
  };

  const onLoadGetItemPalletInventoryDDLOnChange = async (
    itemId: number,
    customerFacilityId: number,
  ) => {
    try {
      return await GetItemPalletInventoryDDL(itemId, customerFacilityId);
    } catch (err) {
      return err;
    }
  };

  const resetValue = (): void => {
    setOrderItem(initialOrderItemState);
    setSkuValue(null);
  };

  const orderLineFormValidation = () => {
    const temp: any = {};
    const re = /[0-9]+/g;
    if (skuValue === null) {
      temp.sku = 'This field is required';
    } else {
      temp.sku = '';
    }

    if (isOrderByLPN?.value === NoYesEnum.No) {
      if (uomValue === null) {
        temp.uomError = 'This field is required';
      } else {
        temp.uomError = '';
      }

      let lotNoTemp = orderItem?.lotNo;
      if (orderItem.lotNo !== null && orderItem.lotNo === '') {
        lotNoTemp = null;
      }

      let lotExpirationTemp = orderItem?.lotExpiration;
      if (orderItem.lotExpiration !== null && orderItem.lotExpiration === '') {
        lotExpirationTemp = null;
      }

      if (lotNoTemp === null && lotExpirationTemp !== null) {
        temp.lotNo = 'This field is required';
      } else {
        temp.lotNo = '';
      }

      if (orderItem.quantity === null) {
        orderItem.quantity = '';
      }

      if (orderItem.totalCases === null) {
        orderItem.totalCases = '';
      }

      if (uomValue !== null) {
        if (uomValue.isPrimaryUOM && orderItem.quantity === '') {
          temp.quantity = 'This field is required';
        } else if (
          uomValue.isPrimaryUOM === false &&
          orderItem.totalCases === ''
        ) {
          temp.totalCases = 'This field is required';
        }
      }

      if (orderItem.isSerialNo && orderItem.serialNumbersValue.length === 0) {
        temp.serialNumbersValueError = 'This field is required';
      }

      if (orderItem.isSerialNo && orderItem.quantity !== '') {
        const qty = Number(orderItem.quantity);
        if (qty !== orderItem.serialNumbersValue.length) {
          temp.quantity =
            'Qty value should match the number of serial numbers.';
        }
      }
    } else if (itemLPN == null) {
      temp.lpnError = 'This field is required';
    } else {
      temp.lpnError = '';
    }

    setOrderLineFormErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  function generateTempId(): number {
    const newArray = [...salesOrderForm.lineItems];
    let tempId = Math.floor(Math.random() * 1000000000 + 1);
    let isIdExist =
      newArray.find((x) => parseInt(x.lineId?.toString(), 10) === tempId) ??
      false;
    while (isIdExist) {
      tempId = Math.floor(Math.random() * 1000000000 + 1);
      isIdExist =
        // eslint-disable-next-line no-loop-func
        newArray.find((x) => parseInt(x.lineId?.toString(), 10) === tempId) ??
        false;
    }

    return tempId;
  }

  const addOrderLineItemOnTrigger = async (): Promise<void> => {
    if (pageViewer.isNew() && modalTitle === ModalTitleStatus.Add) {
      const value = {
        lineId: generateTempId(),
        itemId: skuValue.value,
        sku: skuValue.label,
        description: orderItem.description,
        primaryUOM: orderItem.primaryUOM,
        primaryUOMAbbr: orderItem.primaryUOMAbbr,
        itemUnitOfMeasureId: orderItem.itemUnitOfMeasureId,
        otherUOMStr: orderItem.otherUOMStr,
        otherUOMAbbr: orderItem.otherUOMAbbr,
        otherUOMCount: orderItem.otherUOMCount,
        uomType: orderItem.uomType,
        qty: orderItem.quantity,
        totalCases: orderItem.totalCases,
        lotId: orderItem.lotId,
        lotNo: orderItem.lotNo,
        lotExpiration: orderItem.lotExpiration ? orderItem.lotExpiration : null,
        sellPrice: orderItem.sellPrice,
        isKitOnDemand: orderItem.isKitOnDemand,
        isKitOnDemandComponent: false,
        orderLineItemKitOnDemandId: null,
        itemKitOnDemandId: null,
        itemKitOnDemandQty: null,
        isSerialNo: orderItem.isSerialNo,
        serialNumbers: orderItem.serialNumbersValue.map((s) => s.value),
        isOrderByLPN: orderItem.isOrderByLPN,
        palletInventoryId: orderItem.palletInventoryId,
        lpn: orderItem.lpn,
      };

      const BACKORDER_PAYLOAD = {
        OrderId: salesOrderForm.orderId,
        ItemId: skuValue.value,
        Qty: parseFloat(orderItem.quantity),
        TotalCases: parseFloat(orderItem.totalCases),
        ItemUnitOfMeasureId: orderItem.itemUnitOfMeasureId,
        UOMType: orderItem.uomType,
        OrderLineItems: [...salesOrderForm.lineItems, value],
        IsValidation: false,
      };

      backOrderForNew(BACKORDER_PAYLOAD).then(
        (backOrderForExistingResponse) => {
          const { data } = backOrderForExistingResponse;
          setOrderItemRows((prev) => [
            ...prev,
            {
              ...value,
              qtyShipped: data?.orderLineItem?.qtyShipped,
              statusStr: data?.orderLineItem?.statusStr,
              onHand: data?.orderLineItem?.onHand,
              available: data?.orderLineItem?.available,
              backOrder: data?.orderLineItem?.backOrder,
              backOrderDateTime: data?.orderLineItem?.backOrderDateTime,
              systemNote: data?.orderLineItem?.systemNote,
            },
          ]);

          setSalesOrderForm((prev) => ({
            ...prev,
            lineItems: [
              ...prev.lineItems,
              { ...value, qtyShipped: 0, statusStr: '' },
            ],
          }));

          if (value.isKitOnDemand && kitOnDemands.length > 0) {
            const kitOnDemandsNeedPicking = kitOnDemands.filter(
              (x) => x.isNeedPicking,
            );

            kitOnDemandsNeedPicking.forEach((x: KitOnDemand) => {
              const valueKit = {
                lineId: generateTempId(),
                itemId: x.itemId,
                sku: x.sku,
                description: x.description,
                primaryUOM: 0,
                primaryUOMAbbr: null,
                itemUnitOfMeasureId: null,
                otherUOMStr: null,
                otherUOMAbbr: null,
                otherUOMCount: null,
                uomType: 1, // 1 is ItemInventoryUOMType.Primary
                qty: x.qty * parseFloat(value.qty),
                totalCases: null,
                lotId: null,
                lotNo: null,
                lotExpiration: null,
                sellPrice: null,
                isKitOnDemand: false,
                isKitOnDemandComponent: true,
                orderLineItemKitOnDemandId: value.lineId,
                itemKitOnDemandId: x.itemKitOnDemandId,
                itemKitOnDemandQty: x.qty,
                isOrderByLPN: false,
                palletInventoryId: null,
                lpn: null,
              };

              const BACKORDER_PAYLOAD_KIT = {
                OrderId: salesOrderForm.orderId,
                ItemId: x.itemId,
                Qty: x.qty * parseFloat(value.qty),
                TotalCases: 0,
                ItemUnitOfMeasureId: null,
                UOMType: 1, // 1 is ItemInventoryUOMType.Primary
                OrderLineItems: [
                  ...salesOrderForm.lineItems,
                  { itemId: x.itemId, qty: x.qty * parseFloat(value.qty) },
                ],
                IsValidation: false,
              };

              backOrderForNew(BACKORDER_PAYLOAD_KIT).then(
                (backOrderForExistingResponseKit) => {
                  setOrderItemRows((prev) => [
                    ...prev,
                    {
                      ...valueKit,
                      qtyShipped:
                        backOrderForExistingResponseKit?.data?.orderLineItem
                          ?.qtyShipped,
                      statusStr:
                        backOrderForExistingResponseKit?.data?.orderLineItem
                          ?.statusStr,
                      onHand:
                        backOrderForExistingResponseKit?.data?.orderLineItem
                          ?.onHand,
                      available:
                        backOrderForExistingResponseKit?.data?.orderLineItem
                          ?.available,
                      backOrder:
                        backOrderForExistingResponseKit?.data?.orderLineItem
                          ?.backOrder,
                      backOrderDateTime:
                        backOrderForExistingResponseKit?.data?.orderLineItem
                          ?.backOrderDateTime,
                      systemNote:
                        backOrderForExistingResponseKit?.data?.orderLineItem
                          ?.systemNote,
                      isKitOnDemand: false,
                      isKitOnDemandComponent: true,
                      orderLineItemKitOnDemandId: value.lineId,
                      itemKitOnDemandId: x.itemKitOnDemandId,
                      itemKitOnDemandQty: x.qty,
                      isSerialNo: false,
                      serialNumbers: [],
                      isOrderByLPN: false,
                      palletInventoryId: null,
                      lpn: null,
                    },
                  ]);
                  setSalesOrderForm((prev) => ({
                    ...prev,
                    lineItems: [
                      ...prev.lineItems,
                      {
                        ...valueKit,
                        qtyShipped: 0,
                        statusStr: '',
                        isKitOnDemand: false,
                        isKitOnDemandComponent: true,
                        orderLineItemKitOnDemandId: value.lineId,
                        itemKitOnDemandId: x.itemKitOnDemandId,
                        itemKitOnDemandQty: x.qty,
                        isSerialNo: false,
                        serialNumbers: [],
                        isOrderByLPN: false,
                        palletInventoryId: null,
                        lpn: null,
                      },
                    ],
                  }));
                },
              );
            });
          }
        },
      );
    }
    if (pageViewer.isNew() && modalTitle === ModalTitleStatus.Edit) {
      const value = {
        lineId: singleOrderLineItemRow.lineId,
        itemId: skuValue.value,
        sku: skuValue.label,
        description: orderItem.description,
        qty: orderItem.quantity,
        lotId: orderItem?.lotId,
        lotNo: orderItem?.lotNo,
        lotExpiration: orderItem.lotExpiration,
        sellPrice: orderItem.sellPrice,
      };

      setSalesOrderForm((curr) => ({
        ...curr,
        lineItems: curr.lineItems.map((obj) => {
          if (obj.lineId === singleOrderLineItemRow.lineId) {
            const obj1 = {
              lineId: obj.lineId,
              itemId: obj.itemId,
              sku: obj.sku,
              description: obj.description,
              primaryUOM: obj.primaryUOM,
              primaryUOMAbbr: obj.primaryUOMAbbr,
              itemUnitOfMeasureId: obj.itemUnitOfMeasureId,
              otherUOMStr: obj.otherUOMStr,
              otherUOMAbbr: obj.otherUOMAbbr,
              otherUOMCount: obj.otherUOMCount,
              uomType: obj.uomType,
              qty: obj.qty,
              totalCases: obj.totalCases,
              lotId: obj.lotId,
              lotNo: obj.lotNo,
              lotExpiration: obj.lotExpiration,
              sellPrice: obj.sellPrice,
              isKitOnDemand: obj.isKitOnDemand,
              isKitOnDemandComponent: obj.isKitOnDemandComponent,
              orderLineItemKitOnDemandId: obj.orderLineItemKitOnDemandId,
              itemKitOnDemandId: obj.itemKitOnDemandId,
              itemKitOnDemandQty: obj.itemKitOnDemandQty,
              isSerialNo: obj.isSerialNo,
              serialNumbers: obj.serialNumbers,
              isOrderByLPN: obj.isOrderByLPN,
              palletInventoryId: obj.palletInventoryId,
              lpn: obj.lpn,
            };

            if (JSON.stringify(obj1) !== JSON.stringify(orderItem)) {
              return {
                ...obj,
                ...value,
              };
            }
            return obj1;
          }
          return obj;
        }),
      }));
      setOrderItemRows((prev) =>
        prev.map((row) =>
          row.lineId === singleOrderLineItemRow.lineId
            ? {
                ...row,
                ...value,
              }
            : row,
        ),
      );
    }
    if (pageViewer.isViewOrEdit() && modalTitle === ModalTitleStatus.Add) {
      let kitOnDemandsNeedPicking: KitOnDemand[];
      if (orderItem.isKitOnDemand && kitOnDemands.length > 0) {
        kitOnDemandsNeedPicking = kitOnDemands.filter((x) => x.isNeedPicking);
      }

      let kitOnDemandLineItems: {
        itemId: number;
        itemKitOnDemandId: number;
        itemKitOnDemandQty: number;
      }[];
      if (kitOnDemandsNeedPicking !== undefined) {
        kitOnDemandLineItems = kitOnDemandsNeedPicking.map((x) => ({
          itemId: x.itemId,
          itemKitOnDemandId: x.itemKitOnDemandId,
          itemKitOnDemandQty: x.qty,
        }));
      }

      const CREATE_PAYLOAD = {
        orderId: salesOrderForm.orderId,
        itemId: skuValue.value,
        primaryUOM: orderItem.primaryUOM,
        primaryUOMAbbr: orderItem.primaryUOMAbbr,
        itemUnitOfMeasureId: orderItem.itemUnitOfMeasureId,
        otherUOMStr: orderItem.otherUOMStr,
        otherUOMAbbr: orderItem.otherUOMAbbr,
        otherUOMCount: orderItem.otherUOMCount,
        uomType: orderItem.uomType,
        qty: orderItem.quantity,
        totalCases: orderItem.totalCases,
        lotId: lotNoValue !== null ? lotNoValue.value : '',
        lotNo: orderItem.lotNo,
        lotExpiration: orderItem.lotExpiration,
        sellPrice: orderItem.sellPrice,
        isKitOnDemand: orderItem.isKitOnDemand,
        kitOnDemandLineItems,
        isSerialNo: orderItem.isSerialNo,
        serialNumbers: orderItem.serialNumbersValue.map((s) => s.value),
        isOrderByLPN: orderItem.isOrderByLPN,
        palletInventoryId: orderItem.palletInventoryId,
        lpn: orderItem.lpn,
      };

      try {
        await createOrderLineItem(CREATE_PAYLOAD)
          .then((response) => {
            const value: ILineItemType = {
              lineId: response.orderLineItemId,
              itemId: response.itemId,
              sku: response.sku,
              description: response.description,
              primaryUOM: response.primaryUOM,
              primaryUOMAbbr: response.primaryUOMAbbr,
              itemUnitOfMeasureId: response.itemUnitOfMeasureId,
              otherUOMStr: response.otherUOMStr,
              otherUOMAbbr: response.otherUOMAbbr,
              otherUOMCount: response.otherUOMCount,
              uomType: response.uomType,
              qty: response.qtyOrdered,
              totalCases: response.totalCases,
              qtyShipped: response.qtyShipped,
              statusStr: response.statusStr,
              onHand: response.onHand,
              available: response.available,
              backOrder: response.backOrder,
              backOrderDateTime: response.backOrderDateTime,
              systemNote: response.systemNote,
              lotId: response.lotId !== null ? response.lotId : '',
              lotNo: response.lotNo,
              lotExpiration: response.expirationDate,
              sellPrice: response.sellPrice,
              isKitOnDemand: response.isKitOnDemand,
              isKitOnDemandComponent: response.isKitOnDemandComponent,
              orderLineItemKitOnDemandId: response.orderLineItemKitOnDemandId,
              itemKitOnDemandId: response.itemKitOnDemandId,
              itemKitOnDemandQty: response.itemKitOnDemandQty,
              isSerialNo: response.isSerialNo,
              serialNumbers: response.serialNumbers,
              isOrderByLPN: response.isOrderByLPN,
              palletInventoryId: response.palletInventoryId,
              lpn: response.lpn,
            };

            setOrderItemRows((prev) => [...prev, value]);
            setOrderItem((prevState) => ({
              ...prevState,
              lotId: null,
              lotNo: '',
              lotExpiration: '',
            }));
            setLotNoValue(null);
            viewLoadData();
            snackActions.success('Order line item created successfully.');
          })
          .catch((err) => {
            snackActions.error(err);
          });
      } catch (err) {
        snackActions.error(err);
      }
    }
    if (pageViewer.isViewOrEdit() && modalTitle === ModalTitleStatus.Edit) {
      const updateOrderLineItemOnTriggerIsEdit = () => {
        const UPDATE_PAYLOAD = {
          orderLineItemId: singleOrderLineItemRow.lineId,
          qty: orderItem.quantity,
          totalCases: orderItem.totalCases,
          lotId: orderItem.lotId,
          lotExpiration: orderItem.lotExpiration,
          sellPrice: orderItem.sellPrice,
          isSerialNo: orderItem.isSerialNo,
          serialNumbers: orderItem.serialNumbersValue.map((s) => s.value),
          isOrderByLPN: orderItem.isOrderByLPN,
          palletInventoryId: orderItem.palletInventoryId,
          lpn: orderItem.lpn,
        };

        try {
          updateOrderLineItem(UPDATE_PAYLOAD)
            .then((response) => {
              const value: ILineItemType = {
                lineId: response.orderLineItemId,
                itemId: response.itemId,
                sku: response.sku,
                description: response.description,
                primaryUOM: response.primaryUOM,
                primaryUOMAbbr: response.primaryUOMAbbr,
                itemUnitOfMeasureId: response.itemUnitOfMeasureId,
                otherUOMStr: response.otherUOMStr,
                otherUOMAbbr: response.otherUOMAbbr,
                otherUOMCount: response.otherUOMCount,
                uomType: response.uomType,
                qty: response.qtyOrdered,
                totalCases: response.totalCases,
                qtyShipped: response.qtyShipped,
                statusStr: response.statusStr,
                onHand: response.onHand,
                available: response.available,
                backOrder: response.backOrder,
                backOrderDateTime: response.backOrderDateTime,
                systemNote: response.systemNote,
                lotId: response.lotId !== null ? response.lotId : '',
                lotNo: response.lotNo,
                lotExpiration: response.expirationDate,
                sellPrice: response.sellPrice,
                isKitOnDemand: response.isKitOnDemand,
                isKitOnDemandComponent: response.isKitOnDemandComponent,
                orderLineItemKitOnDemandId: response.orderLineItemKitOnDemandId,
                itemKitOnDemandId: response.itemKitOnDemandId,
                itemKitOnDemandQty: response.itemKitOnDemandQty,
                isSerialNo: response.isSerialNo,
                serialNumbers: response.serialNumbers,
                isOrderByLPN: response.isOrderByLPN,
                palletInventoryId: response.palletInventoryId,
                lpn: response.lpn,
              };

              setOrderItemRows((prev) =>
                prev.map((row) =>
                  row.lineId === singleOrderLineItemRow.lineId
                    ? {
                        ...row,
                        ...value,
                      }
                    : row,
                ),
              );
              setOrderItem((prevState) => ({
                ...prevState,
                lotId: null,
                lotNo: '',
                lotExpiration: '',
              }));
              setLotNoValue(null);
              snackActions.success('Order line item updated successfully.');
              viewLoadData();
              resetValue();
            })
            .catch((err) => {
              snackActions.error(err);
            });
        } catch (err) {
          snackActions.error(err);
        }
      };

      if (
        singleOrderLineItemRow.lineId === orderItem.lineId &&
        singleOrderLineItemRow.isKitOnDemand &&
        singleOrderLineItemRow.qty !== orderItem.quantity
      ) {
        withReactContent(Swal)
          .fire({
            title: `This will update the associated bundle?`,
            showCancelButton: true,
            cancelButtonText: 'CANCEL',
            confirmButtonText: 'CONFIRM',
            customClass: {
              actions: 'my-actions',
              cancelButton: 'order-1 right-gap',
              confirmButton: 'order-2',
            },
          })
          .then((result) => {
            if (result.isConfirmed) {
              updateOrderLineItemOnTriggerIsEdit();
            }

            if (result.isDismissed) {
              onCloseOrderItemModal();
              setModalTitle(null);
              setSkuValue(null);
              setUomValue(null);
              setLotNoValue(null);
              setOptionsSerialNumber([]);
              setSerialNumbersValue([]);
              setOrderLineFormErrors(initialOrderItemState);
            }
          });
      } else {
        updateOrderLineItemOnTriggerIsEdit();
      }
    }
  };

  const addOrderLineItemOnClick = async (): Promise<void> => {
    if (pageViewer.isNew() && modalTitle === ModalTitleStatus.Add) {
      const value = {
        lineId: generateTempId(),
        itemId: skuValue.value,
        sku: skuValue.label,
        description: orderItem.description,
        primaryUOM: orderItem.primaryUOM,
        primaryUOMAbbr: orderItem.primaryUOMAbbr,
        itemUnitOfMeasureId: orderItem.itemUnitOfMeasureId,
        otherUOMStr: orderItem.otherUOMStr,
        otherUOMAbbr: orderItem.otherUOMAbbr,
        otherUOMCount: orderItem.otherUOMCount,
        uomType: orderItem.uomType,
        qty: orderItem.quantity,
        totalCases: orderItem.totalCases,
        lotId: orderItem.lotId,
        lotNo: orderItem.lotNo,
        lotExpiration: orderItem.lotExpiration,
        sellPrice: orderItem.sellPrice,
        isSerialNo: orderItem.isSerialNo,
        serialNumbers: orderItem.serialNumbersValue.map((s) => s.value),
        isOrderByLPN: orderItem.isOrderByLPN,
        palletInventoryId: orderItem.palletInventoryId,
        lpn: orderItem.lpn,
      };

      const BACKORDER_PAYLOAD = {
        OrderId: salesOrderForm.orderId,
        ItemId: skuValue.value,
        Qty: parseFloat(orderItem.quantity),
        TotalCases: parseFloat(orderItem.totalCases),
        ItemUnitOfMeasureId: orderItem.itemUnitOfMeasureId,
        UOMType: orderItem.uomType,
        LotId: orderItem.lotId,
        OrderLineItems: [...salesOrderForm.lineItems, value],
        IsValidation: true,
      };

      const backOrderForExistingResponse = await backOrderForNew(
        BACKORDER_PAYLOAD,
      );
      const { status, data } = backOrderForExistingResponse;
      switch (status) {
        case 200:
          if (data !== '' && data.message !== '') {
            setApiBackOrderResponse({
              data,
              click: addOrderLineItemOnTrigger,
            });
          } else {
            addOrderLineItemOnTrigger();
          }
          onCloseOrderItemModal();
          break;
        default:
          break;
      }
    }
    if (pageViewer.isNew() && modalTitle === ModalTitleStatus.Edit) {
      const updateOrderLineItemOnTrigger = () => {
        const value = {
          lineId: singleOrderLineItemRow.lineId,
          itemId: skuValue.value,
          sku: skuValue.label,
          description: orderItem.description,
          primaryUOM: orderItem.primaryUOM,
          primaryUOMAbbr: orderItem.primaryUOMAbbr,
          itemUnitOfMeasureId: orderItem.itemUnitOfMeasureId,
          otherUOMStr: orderItem.otherUOMStr,
          otherUOMAbbr: orderItem.otherUOMAbbr,
          otherUOMCount: orderItem.otherUOMCount,
          uomType: orderItem.uomType,
          qty: orderItem.quantity,
          totalCases: orderItem.totalCases,
          lotId: orderItem.lotId,
          lotNo: orderItem.lotNo,
          lotExpiration: orderItem.lotExpiration,
          sellPrice: orderItem.sellPrice,
          isKitOnDemand: orderItem.isKitOnDemand,
          isKitOnDemandComponent: orderItem.isKitOnDemandComponent,
          orderLineItemKitOnDemandId: orderItem.orderLineItemKitOnDemandId,
          itemKitOnDemandId: orderItem.itemKitOnDemandId,
          itemKitOnDemandQty: orderItem.itemKitOnDemandQty,
          isSerialNo: orderItem.isSerialNo,
          serialNumbers: orderItem.serialNumbersValue.map((s) => s.value),
          isOrderByLPN: orderItem.isOrderByLPN,
          palletInventoryId: orderItem.palletInventoryId,
          lpn: orderItem.lpn,
        };

        setSalesOrderForm((curr) => ({
          ...curr,
          lineItems: curr.lineItems.map((obj) => {
            if (obj.lineId === singleOrderLineItemRow.lineId) {
              const obj1 = {
                lineId: obj.lineId,
                itemId: obj.itemId,
                sku: obj.sku,
                description: obj.description,
                primaryUOM: obj.primaryUOM,
                primaryUOMAbbr: obj.primaryUOMAbbr,
                itemUnitOfMeasureId: obj.itemUnitOfMeasureId,
                otherUOMStr: obj.otherUOMStr,
                otherUOMAbbr: obj.otherUOMAbbr,
                otherUOMCount: obj.otherUOMCount,
                uomType: obj.uomType,
                qty: obj.qty,
                totalCases: obj.totalCases,
                lotId: obj.lotId,
                lotNo: obj.lotNo,
                lotExpiration: obj.lotExpiration,
                sellPrice: obj.sellPrice,
                isKitOnDemand: obj.isKitOnDemand,
                isKitOnDemandComponent: obj.isKitOnDemandComponent,
                orderLineItemKitOnDemandId: obj.orderLineItemKitOnDemandId,
                itemKitOnDemandId: obj.itemKitOnDemandId,
                itemKitOnDemandQty: obj.itemKitOnDemandQty,
                isSerialNo: obj.isSerialNo,
                serialNumbers: obj.serialNumbers,
                isOrderByLPN: obj.isOrderByLPN,
                palletInventoryId: obj.palletInventoryId,
                lpn: obj.lpn,
              };

              if (JSON.stringify(obj1) !== JSON.stringify(orderItem)) {
                return {
                  ...obj,
                  ...value,
                };
              }
              return obj1;
            }
            return obj;
          }),
        }));
        setOrderItemRows((prev) =>
          prev.map((row) =>
            row.lineId === singleOrderLineItemRow.lineId
              ? {
                  ...row,
                  ...value,
                }
              : row,
          ),
        );

        if (orderItem.isKitOnDemand) {
          const orderItemRowsNeedPicking = orderItemRows.filter(
            (x) =>
              x.isKitOnDemandComponent &&
              x.orderLineItemKitOnDemandId === orderItem.lineId,
          );

          orderItemRowsNeedPicking.forEach((x) => {
            setSalesOrderForm((curr) => ({
              ...curr,
              lineItems: curr.lineItems.map((obj) => {
                if (obj.lineId === x.lineId) {
                  const obj1 = {
                    lineId: obj.lineId,
                    itemId: obj.itemId,
                    sku: obj.sku,
                    description: obj.description,
                    primaryUOM: obj.primaryUOM,
                    primaryUOMAbbr: obj.primaryUOMAbbr,
                    itemUnitOfMeasureId: obj.itemUnitOfMeasureId,
                    otherUOMStr: obj.otherUOMStr,
                    otherUOMAbbr: obj.otherUOMAbbr,
                    otherUOMCount: obj.otherUOMCount,
                    uomType: obj.uomType,
                    qty: obj.qty,
                    totalCases: obj.totalCases,
                    lotId: obj.lotId,
                    lotNo: obj.lotNo,
                    lotExpiration: obj.lotExpiration,
                    sellPrice: obj.sellPrice,
                    isKitOnDemand: obj.isKitOnDemand,
                    isKitOnDemandComponent: obj.isKitOnDemandComponent,
                    orderLineItemKitOnDemandId: obj.orderLineItemKitOnDemandId,
                    itemKitOnDemandId: obj.itemKitOnDemandId,
                    itemKitOnDemandQty: obj.itemKitOnDemandQty,
                    isSerialNo: false,
                    serialNumbers: [],
                    isOrderByLPN: false,
                    palletInventoryId: null,
                    lpn: null,
                  };

                  if (JSON.stringify(obj1) !== JSON.stringify(orderItem)) {
                    return {
                      ...obj,
                      ...x,
                      qty: (x.itemKitOnDemandQty ?? 0) * parseFloat(value.qty),
                    };
                  }
                  return obj1;
                }
                return obj;
              }),
            }));
            setOrderItemRows((prev) =>
              prev.map((row) =>
                row.lineId === x.lineId
                  ? {
                      ...row,
                      ...x,
                      qty: (x.itemKitOnDemandQty ?? 0) * parseFloat(value.qty),
                    }
                  : row,
              ),
            );
          });
        }
        viewLoadData();
        onCloseOrderItemModal();
      };

      if (
        singleOrderLineItemRow.lineId === orderItem.lineId &&
        singleOrderLineItemRow.isKitOnDemand &&
        singleOrderLineItemRow.qty !== orderItem.quantity
      ) {
        onCloseOrderItemModal();

        withReactContent(Swal)
          .fire({
            title: `This will update the associated bundle?`,
            showCancelButton: true,
            cancelButtonText: 'CANCEL',
            confirmButtonText: 'CONFIRM',
            customClass: {
              actions: 'my-actions',
              cancelButton: 'order-1 right-gap',
              confirmButton: 'order-2',
            },
          })
          .then((result) => {
            if (result.isConfirmed) {
              updateOrderLineItemOnTrigger();
            }

            if (result.isDismissed) {
              onCloseOrderItemModal();
              setModalTitle(null);
              setSkuValue(null);
              setUomValue(null);
              setLotNoValue(null);
              setOptionsSerialNumber([]);
              setSerialNumbersValue([]);
              setOrderLineFormErrors(initialOrderItemState);
            }
          });
      } else {
        updateOrderLineItemOnTrigger();
      }
    }
    if (pageViewer.isViewOrEdit() && modalTitle === ModalTitleStatus.Add) {
      const BACKORDER_PAYLOAD = {
        OrderId: salesOrderForm.orderId,
        ItemId: skuValue.value,
        Qty: parseFloat(orderItem.quantity),
        TotalCases: parseFloat(orderItem.totalCases),
        ItemUnitOfMeasureId: orderItem.itemUnitOfMeasureId,
        UOMType: orderItem.uomType,
        LotId: orderItem.lotId,
      };

      const backOrderForExistingResponse = await backOrderForExisting(
        BACKORDER_PAYLOAD,
      );
      const { status, data } = backOrderForExistingResponse;
      switch (status) {
        case 200:
          if (data !== '') {
            setApiBackOrderResponse({
              data,
              click: addOrderLineItemOnTrigger,
            });
          } else {
            addOrderLineItemOnTrigger();
          }
          onCloseOrderItemModal();
          break;

        default:
          break;
      }
    }
    if (pageViewer.isViewOrEdit() && modalTitle === ModalTitleStatus.Edit) {
      const BACKORDER_PAYLOAD = {
        OrderId: salesOrderForm.orderId,
        OrderLineItemId: orderItem.lineId,
        ItemId: skuValue.value,
        Qty: parseFloat(orderItem.quantity),
        TotalCases: parseFloat(orderItem.totalCases),
        ItemUnitOfMeasureId: orderItem.itemUnitOfMeasureId,
        UOMType: orderItem.uomType,
        LotId: orderItem.lotId,
      };

      const backOrderForExistingResponse = await backOrderForExisting(
        BACKORDER_PAYLOAD,
      );
      const { status, data } = backOrderForExistingResponse;
      switch (status) {
        case 200:
          if (data !== '') {
            setApiBackOrderResponse({
              data,
              click: addOrderLineItemOnTrigger,
            });
          } else {
            addOrderLineItemOnTrigger();
          }
          onCloseOrderItemModal();
          break;

        default:
          break;
      }
    }
  };

  const relatedOnLotInputHandler = (
    key: string,
    value?: AutocompleteBase,
    event?: any,
  ) => {
    if (key === 'lotNo') {
      setLotNoValue(value);

      const lotExpirationDate =
        event.target.dataset.lotexpirationdate === '0001-01-01T00:00:00' ||
        event.target.dataset.lotexpirationdate === '0001-01-02T00:00:00'
          ? null
          : event.target.dataset.lotexpirationdate;

      const lot = {
        value: value.value,
        label: value.label,
        lotExpirationDate,
      };

      setLotNoValue((prev) => ({
        ...prev,
        ...lot,
      }));

      setOrderLineFormErrors(initialOrderItemState);
    }
    if (key === 'lotExpDate') {
      if (event !== null && event !== 'Invalid Date') {
        setOrderItem((prevState) => ({
          ...prevState,
          lotExpiration: event,
        }));
      } else {
        setOrderItem((prevState) => ({
          ...prevState,
          lotExpiration: null,
        }));
      }
    }
  };

  useEffect(() => {
    if (modalTitle === ModalTitleStatus.Add) {
      if (optionsUOM.length > 0) {
        const uom = optionsUOM.find((x) => x.isPrimaryUOM);
        if (uom !== undefined) {
          setUomValue((prev) => ({
            ...prev,
            value: uom.value,
            label: uom.label,
            uomAbbr: uom.uomAbbr,
            isPrimaryUOM: uom.isPrimaryUOM,
            qty: uom.qty,
            primaryUOM: uom.primaryUOM,
            primaryUOMStr: uom.primaryUOMStr,
            primaryUOMAbbr: uom.primaryUOMAbbr,
            uomType: uom.uomType,
          }));

          setOrderItem((prevState) => ({
            ...prevState,
            primaryUOM: uom.value,
            primaryUOMAbbr: uom.uomAbbr,
            itemUnitOfMeasureId: null,
            otherUOMStr: '',
            otherUOMAbbr: '',
            otherUOMCount: null,
            uomType: uom.uomType,
            quantity: '',
            totalCases: null,
            sellPrice: '',
          }));
        }
      }
    }
  }, [optionsUOM]);

  useEffect(() => {
    if (modalTitle === ModalTitleStatus.Edit) {
      if (singleOrderLineItemRow.itemUnitOfMeasureId === undefined) {
        singleOrderLineItemRow.itemUnitOfMeasureId = null;
      }

      if (singleOrderLineItemRow !== null) {
        // primary uom
        if (singleOrderLineItemRow.uomType === UOMType.Primary) {
          // set primaryUom Id to 0 at the backend
          const uom = optionsUOM.find((u) => u.value === 0);
          if (uom !== undefined) {
            setUomValue((prev) => ({
              ...prev,
              value: uom.value,
              label: uom.label,
              uomAbbr: uom.uomAbbr,
              isPrimaryUOM: uom.isPrimaryUOM,
              qty: uom.qty,
              primaryUOM: uom.primaryUOM,
              primaryUOMStr: uom.primaryUOMStr,
              primaryUOMAbbr: uom.primaryUOMAbbr,
              uomType: uom.uomType,
            }));
          }
          // other uom
        } else if (singleOrderLineItemRow.uomType === UOMType.Other) {
          const uom = optionsUOM.find(
            (u) => u.value === singleOrderLineItemRow.itemUnitOfMeasureId,
          );
          if (uom !== undefined) {
            setUomValue((prev) => ({
              ...prev,
              value: uom.value,
              label: uom.label,
              uomAbbr: uom.uomAbbr,
              isPrimaryUOM: uom.isPrimaryUOM,
              qty: uom.qty,
              primaryUOM: uom.primaryUOM,
              primaryUOMStr: uom.primaryUOMStr,
              primaryUOMAbbr: uom.primaryUOMAbbr,
              uomType: uom.uomType,
            }));
          }
        }
        // pallet
        else if (singleOrderLineItemRow.uomType === UOMType.Pallet) {
          const uom = optionsUOM.find((u) => u.uomType === UOMType.Pallet);
          if (uom !== undefined) {
            setUomValue((prev) => ({
              ...prev,
              value: uom.value,
              label: uom.label,
              uomAbbr: uom.uomAbbr,
              isPrimaryUOM: uom.isPrimaryUOM,
              qty: uom.qty,
              primaryUOM: uom.primaryUOM,
              primaryUOMStr: uom.primaryUOMStr,
              primaryUOMAbbr: uom.primaryUOMAbbr,
              uomType: uom.uomType,
            }));
          }
        }

        if (singleOrderLineItemRow.isOrderByLPN) {
          setIsOrderByLPN((prevState) => ({
            ...prevState,
            value: NoYesEnum.Yes,
            text: 'Yes',
          }));
        } else {
          setIsOrderByLPN((prevState) => ({
            ...prevState,
            value: NoYesEnum.No,
            text: 'No',
          }));
        }
      }
    }
  }, [optionsUOM, singleOrderLineItemRow]);

  useEffect(() => {
    if (skuValue !== null) {
      getItemDetail(
        currentUser.Claim_CustomerId,
        !isWarehouseCustomerAccount
          ? currentLocationAndFacility.customerFacilityId
          : salesOrderForm.customerFacilityId,
        skuValue.value,
      )
        .then((a) => {
          setOrderItem((prev) => ({
            ...prev,
            description: a.description,
            isKitOnDemand: a.isKitOnDemand,
            // sellPrice: a.sellPriceOrderlineItem ? a.sellPriceOrderlineItem : '',
            isSerialNo: a.isSerialNo,
          }));

          onLoadGetItemUnitOfMeasureDDLOnChange(skuValue.value).then((x) => {
            setOptionsUOM([]);
            x.map((uom) =>
              setOptionsUOM((prev) => [
                ...prev,
                {
                  value: uom.value,
                  label: uom.text,
                  uomAbbr: uom.uomAbbr,
                  isPrimaryUOM: uom.isPrimaryUOM,
                  qty: uom.qty,
                  primaryUOM: uom.primaryUOM,
                  primaryUOMStr: uom.primaryUOMStr,
                  primaryUOMAbbr: uom.primaryUOMAbbr,
                  uomType: uom.uomType,
                },
              ]),
            );
          });

          if (a.isSerialNo) {
            const customerFacilityId = !isWarehouseCustomerAccount
              ? Number(currentLocationAndFacility.customerFacilityId)
              : salesOrderForm.customerFacilityId;

            onLoadGetItemSerialNumberDDLOnChange(
              skuValue.value,
              customerFacilityId,
            ).then((x: DropdownListItemType<string>[]) => {
              setOptionsSerialNumber([...x]);
            });
          }

          if (modalTitle === ModalTitleStatus.Edit) {
            setOrderItem((prevState) => ({
              ...prevState,
              primaryUOM: singleOrderLineItemRow.primaryUOM,
              primaryUOMAbbr: singleOrderLineItemRow.primaryUOMAbbr,
              itemUnitOfMeasureId: singleOrderLineItemRow.itemUnitOfMeasureId,
              otherUOMStr: singleOrderLineItemRow.otherUOMStr,
              otherUOMAbbr: singleOrderLineItemRow.otherUOMAbbr,
              otherUOMCount: singleOrderLineItemRow.otherUOMCount,
              uomType: singleOrderLineItemRow.uomType,
              totalCases: singleOrderLineItemRow.totalCases,
              isOrderByLPN: singleOrderLineItemRow.isOrderByLPN,
              palletInventoryId: singleOrderLineItemRow.palletInventoryId,
              lpn: singleOrderLineItemRow.lpn,
            }));

            if (singleOrderLineItemRow.isOrderByLPN) {
              const customerFacilityId = !isWarehouseCustomerAccount
                ? Number(currentLocationAndFacility.customerFacilityId)
                : salesOrderForm.customerFacilityId;

              onLoadGetItemPalletInventoryDDLOnChange(
                singleOrderLineItemRow?.itemId,
                customerFacilityId,
              ).then((x: PalletInventoryDropdownListItem[]) => {
                setItemLPNDDL([...x]);

                const itemLPNValue = x.find(
                  (z: PalletInventoryDropdownListItem) =>
                    z.value === singleOrderLineItemRow?.palletInventoryId,
                );

                setItemLPN(itemLPNValue);
              });
            } else {
              setItemLPNDDL([]);
              setItemLPN(null);
            }
          }

          setKitOnDemands(a.kitOnDemands.map((x: KitOnDemand) => ({ ...x })));
        })
        .catch();
    } else {
      setOrderItem(initialOrderItemState);
    }
  }, [skuValue, modalTitle]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

  useEffect(() => {
    if (modalTitle === ModalTitleStatus.Edit) {
      if (optionSkus !== null || optionSkus.length !== 0) {
        const sku = (el: SkuType) =>
          el.value === singleOrderLineItemRow?.itemId;

        const vmSku = optionSkus.find(sku);
        if (vmSku !== undefined) {
          setSkuValue(vmSku);
        }
      }
      setSkuValue((prev) => ({
        ...prev,
        value: singleOrderLineItemRow.itemId,
        label: singleOrderLineItemRow.sku,
      }));
      setOrderItem((prev) => ({
        ...prev,
        lineId: singleOrderLineItemRow.lineId,
        description: singleOrderLineItemRow.description,
        primaryUom: {
          value: singleOrderLineItemRow.primaryUOM,
          label: singleOrderLineItemRow.primaryUOMStr,
        },
        uom: {
          value: singleOrderLineItemRow.uom,
          label: singleOrderLineItemRow.uomStr,
        },
        quantity: singleOrderLineItemRow.qty,
        qtyEach: singleOrderLineItemRow.qtyEach,
        lotId: singleOrderLineItemRow.lotId ?? null,
        lotNo: singleOrderLineItemRow.lotNo ?? null,
        lotExpiration: singleOrderLineItemRow.lotExpiration ?? null,
        sellPrice: singleOrderLineItemRow.sellPrice,
        isKitOnDemand: singleOrderLineItemRow.isKitOnDemand,
        isKitOnDemandComponent: singleOrderLineItemRow.isKitOnDemandComponent,
        orderLineItemKitOnDemandId:
          singleOrderLineItemRow.orderLineItemKitOnDemandId,
        itemKitOnDemandId: singleOrderLineItemRow.itemKitOnDemandId,
        itemKitOnDemandQty: singleOrderLineItemRow.itemKitOnDemandQty,
        isSerialNo: singleOrderLineItemRow.isSerialNo,
        serialNumbersValue: singleOrderLineItemRow.serialNumbers.map(
          (x: string) => ({
            value: x,
            text: x,
          }),
        ),
        serialNumbersValueError: ',',
      }));

      onLoadGetLotNoOnChange(singleOrderLineItemRow?.itemId).then((x) => {
        setOptionLotNo([]);
        x.map((lot) =>
          setOptionLotNo((prev) => [
            ...prev,
            {
              id: lot.lotId,
              label: lot.lotNo,
              lotExpiration: lot.expirationDate,
            },
          ]),
        );
      });

      setLotNoValue({
        value: singleOrderLineItemRow.lotId
          ? singleOrderLineItemRow.lotId
          : null,
        label: singleOrderLineItemRow.lotNo ? singleOrderLineItemRow.lotNo : '',
        lotExpiration: singleOrderLineItemRow.lotExpirationDate ?? null,
      });

      const sns: DropdownListItemType<string>[] =
        singleOrderLineItemRow.serialNumbers.map((x: string) => ({
          value: x,
          text: x,
        }));
      setSerialNumbersValue([...sns]);
    }
  }, [optionSkus, modalTitle, orderLineFormErrors]);

  const handleFilterOption = (options: any[], filter) => {
    const result = options.filter(
      (r) =>
        r.label.toLowerCase().includes(filter.inputValue.toLowerCase()) ||
        r.description.toLowerCase().includes(filter.inputValue.toLowerCase()),
    );
    return result;
  };

  const addOrderLineItem = async () => {
    addOrderLineItemOnClick();
    setModalTitle(null);
    setSkuValue(null);
    setOptionsSerialNumber([]);
    setSerialNumbersValue([]);
    setIsOrderByLPN((prevState) => ({
      ...prevState,
      value: NoYesEnum.No,
      text: 'No',
    }));
    setItemLPNDDL([]);
    setItemLPN(null);
  };

  const [lpnValidationMessage, setLpnValidationMessage] =
    useState<string>(null);
  const lpnValidationCallback = async () => {
    addOrderLineItem();
  };

  const hanldeOrderlineItemOnClick = async (e: any) => {
    e.preventDefault();

    if (!orderLineFormValidation()) {
      return;
    }

    if (pageViewer.isNew()) {
      const lineItemSerialNumbers: OrderLineItemRequestModel[] = [];

      lineItemSerialNumbers.push({
        orderLineItemId: null,
        itemId: skuValue.value,
        isSelected: true,
        serialNumbers: orderItem.serialNumbersValue.map((s) => s.value),
      });

      const tempOrderItemRows = JSON.parse(JSON.stringify(orderItemRows));
      tempOrderItemRows.forEach((x) => {
        if (x.isSerialNo && x.lineId !== orderItem.lineId) {
          lineItemSerialNumbers.push({
            orderLineItemId: null,
            itemId: x.itemId,
            isSelected: false,
            serialNumbers: x.serialNumbers,
          });
        }
      });

      const payloadSerialNumber: ValidateOrderLineItemSerialNumberRequestModel =
        {
          customerFacilityId: salesOrderForm.customerFacilityId,
          requestType: ValidateOrderLineItemSerialNumberRequestTypeEnum.New,
          lineItems: lineItemSerialNumbers,
        };
      ValidateOrderLineItemSerialNumber(payloadSerialNumber).then(
        (x: ValidateOrderLineItemSerialNumberResponseModel) => {
          if (x.hasDuplicate) {
            snackActions.error(
              `${x.duplicate.sku} have a duplicate serial no. ${x.duplicate.serialNo}`,
            );

            return;
          }

          const lineItemLPN: LPNValidationOrderLineItemModel[] = [];
          lineItemLPN.push({
            orderLineItemId: null,
            itemId: skuValue.value,
            isSelected: true,
            palletInventoryId: orderItem.palletInventoryId,
          });
          tempOrderItemRows.forEach((z) => {
            if (z.isOrderByLPN && z.lineId !== orderItem.lineId) {
              lineItemLPN.push({
                orderLineItemId: null,
                itemId: z.itemId,
                isSelected: false,
                palletInventoryId: z.palletInventoryId,
              });
            }
          });

          const payloadLPN: CreateOrderLineLPNValidationRequestModel = {
            customerFacilityId: salesOrderForm.customerFacilityId,
            requestType: ValidateOrderLineItemSerialNumberRequestTypeEnum.New,
            lineItems: lineItemLPN,
          };
          CreateOrderLineLPNValidation(payloadLPN).then(
            (z: CreateOrderLineLPNValidationResponseModel) => {
              if (z.hasDuplicate) {
                setImportGlobalErrorMessageList([z.message]);
                setIsImportGlobalErrorModalOpen(true);

                return;
              }
              if (z.hasDuplicate === false && z.message) {
                setLpnValidationMessage(z.message);
                onOpenConfirmationModal();

                return;
              }

              addOrderLineItem();
            },
          );
        },
      );
    } else if (pageViewer.isViewOrEdit()) {
      let requestType: ValidateOrderLineItemSerialNumberRequestTypeEnum = null;
      const lineItemSerialNumbers: OrderLineItemRequestModel[] = [];

      if (modalTitle === ModalTitleStatus.Add) {
        requestType = ValidateOrderLineItemSerialNumberRequestTypeEnum.New;
        lineItemSerialNumbers.push({
          orderLineItemId: null,
          itemId: skuValue.value,
          isSelected: true,
          serialNumbers: orderItem.serialNumbersValue.map((s) => s.value),
        });
      } else if (modalTitle === ModalTitleStatus.Edit) {
        requestType =
          ValidateOrderLineItemSerialNumberRequestTypeEnum.HasOrderLineItemId;
        lineItemSerialNumbers.push({
          orderLineItemId: Number(orderItem.lineId),
          itemId: null,
          isSelected: true,
          serialNumbers: orderItem.serialNumbersValue.map((s) => s.value),
        });
      }

      const payloadSerialNumber: ValidateOrderLineItemSerialNumberRequestModel =
        {
          customerFacilityId: salesOrderForm.customerFacilityId,
          requestType,
          lineItems: lineItemSerialNumbers,
        };
      ValidateOrderLineItemSerialNumber(payloadSerialNumber).then(
        (x: ValidateOrderLineItemSerialNumberResponseModel) => {
          if (x.hasDuplicate) {
            snackActions.error(
              `${x.duplicate.sku} have a duplicate serial no. ${x.duplicate.serialNo}`,
            );

            return;
          }

          const lineItemLPN: LPNValidationOrderLineItemModel[] = [];
          if (modalTitle === ModalTitleStatus.Add) {
            lineItemLPN.push({
              orderLineItemId: null,
              itemId: skuValue.value,
              isSelected: true,
              palletInventoryId: orderItem.palletInventoryId,
            });
          } else if (modalTitle === ModalTitleStatus.Edit) {
            lineItemLPN.push({
              orderLineItemId: Number(orderItem.lineId),
              itemId: null,
              isSelected: true,
              palletInventoryId: orderItem.palletInventoryId,
            });
          }

          const payloadLPN: CreateOrderLineLPNValidationRequestModel = {
            customerFacilityId: salesOrderForm.customerFacilityId,
            requestType,
            lineItems: lineItemLPN,
          };
          CreateOrderLineLPNValidation(payloadLPN).then(
            (z: CreateOrderLineLPNValidationResponseModel) => {
              if (z.hasDuplicate) {
                setImportGlobalErrorMessageList([z.message]);
                setIsImportGlobalErrorModalOpen(true);

                return;
              }
              if (z.hasDuplicate === false && z.message) {
                setLpnValidationMessage(z.message);
                onOpenConfirmationModal();

                return;
              }

              addOrderLineItem();
            },
          );
        },
      );
    }
  };

  return (
    <Modal open={isOrderItemModalOpen}>
      <MUIContainer sx={{ width: '20% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          {modalTitle} Order Item
        </Typography>
        <MUIContent sx={{ padding: '10px 20px !important ' }}>
          <Box autoComplete="off" component="form">
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12}>
                <Autocomplete
                  onChange={(event: any, newValue: SkuType) => {
                    if (newValue !== null) {
                      setSkuValue(newValue);
                      onLoadGetLotNoOnChange(newValue.value).then((x) => {
                        setOptionLotNo([]);
                        x.map((lot) =>
                          setOptionLotNo((prev) => [
                            ...prev,
                            {
                              id: lot.lotId,
                              label: lot.lotNo,
                              lotExpiration: lot.expirationDate,
                            },
                          ]),
                        );
                      });

                      setLotNoValue(null);
                    } else {
                      setSkuValue(null);
                      setOptionLotNo([]);
                      setLotNoValue(null);
                      setOptionsUOM([]);
                      setUomValue(null);
                      setKitOnDemands([]);
                      setOptionsSerialNumber([]);
                      setSerialNumbersValue([]);

                      setOrderItem((prevState) => ({
                        ...prevState,
                        lotId: null,
                        lotNo: null,
                        lotExpiration: null,
                        primaryUOM: 0,
                        primaryUOMAbbr: '',
                        itemUnitOfMeasureId: null,
                        otherUOMStr: '',
                        otherUOMAbbr: '',
                        otherUOMCount: null,
                        uomType: 0,
                        quantity: '',
                        totalCases: null,
                        sellPrice: '',
                        isSerialNo: false,
                        isOrderByLPN: false,
                        palletInventoryId: null,
                        lpn: null,
                      }));
                    }

                    setIsOrderByLPN((prevState) => ({
                      ...prevState,
                      value: NoYesEnum.No,
                      text: 'No',
                    }));
                    setItemLPN(null);
                    setItemLPNDDL([]);
                  }}
                  disableClearable
                  aria-required
                  id="controllable-states"
                  options={optionSkus}
                  disabled={modalTitle === ModalTitleStatus.Edit}
                  getOptionLabel={(option: SkuType) => option.label}
                  value={skuValue || null}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  filterOptions={handleFilterOption}
                  renderOption={(props, option) => (
                    <li {...props} key={props.id}>
                      <div className="td tw-140">{option.label}</div>
                      <div className="td">{option.description}</div>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      autoComplete="off"
                      {...(orderLineFormErrors.sku && {
                        error: true,
                        helperText: orderLineFormErrors.sku,
                      })}
                      required
                      id="outlined-required"
                      label="SKU"
                      size="small"
                      sx={{ width: '100%', marginBottom: '16px' }}
                    />
                  )}
                  PopperComponent={AutoCompletePopper}
                  PaperComponent={AutoCompletePaper}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12}>
                <TextField
                  inputProps={{
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="Description"
                  value={orderItem.description || ''}
                  size="small"
                  disabled
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid
                item
                xs={isOrderByLPN?.value === NoYesEnum.No ? 12 : 6}
                sm={isOrderByLPN?.value === NoYesEnum.No ? 12 : 6}
              >
                <Autocomplete
                  disableClearable
                  value={isOrderByLPN}
                  onChange={(event, newValue: DropdownListItem) => {
                    setIsOrderByLPN((prevState) => ({
                      ...prevState,
                      value: newValue.value,
                      text: newValue.text,
                    }));

                    setOrderItem((prevState) => ({
                      ...prevState,
                      lotId: null,
                      lotNo: null,
                      lotExpiration: null,
                      primaryUOM: 0,
                      primaryUOMAbbr: '',
                      itemUnitOfMeasureId: null,
                      otherUOMStr: '',
                      otherUOMAbbr: '',
                      otherUOMCount: null,
                      uomType: 0,
                      quantity: '',
                      totalCases: null,
                      isSerialNo: false,
                      isOrderByLPN: false,
                      palletInventoryId: null,
                      lpn: null,
                    }));

                    setItemLPN(null);

                    if (newValue.value === NoYesEnum.Yes) {
                      const customerFacilityId = !isWarehouseCustomerAccount
                        ? Number(currentLocationAndFacility.customerFacilityId)
                        : salesOrderForm.customerFacilityId;

                      onLoadGetItemPalletInventoryDDLOnChange(
                        skuValue.value,
                        customerFacilityId,
                      ).then((x: PalletInventoryDropdownListItem[]) => {
                        setItemLPNDDL([...x]);
                      });
                    } else {
                      setItemLPNDDL([]);
                    }
                  }}
                  id="controllable-states"
                  options={isOrderByLPNDDL}
                  getOptionLabel={(option: DropdownListItem) => option.text}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option: DropdownListItem) => (
                    <li {...props} key={option.value}>
                      {option.text}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      autoComplete="off"
                      required
                      id="outlined-required"
                      label="Order By LPN?"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                  disabled={
                    (pageViewer.isViewOrEdit() &&
                      modalTitle === ModalTitleStatus.Edit) ||
                    skuValue === null
                  }
                />
              </MUIGrid>

              {isOrderByLPN?.value === NoYesEnum.Yes && (
                <MUIGrid item xs={6} sm={6}>
                  <Autocomplete
                    disableClearable
                    value={itemLPN}
                    onChange={(
                      event,
                      newValue: PalletInventoryDropdownListItem,
                    ) => {
                      setItemLPN((e) => ({
                        ...e,
                        value: newValue.value,
                        text: newValue.text,
                        qty: newValue.qty,
                      }));

                      const uom = optionsUOM.find(
                        (x: ItemUnitOfMeasureType) => x.isPrimaryUOM,
                      );

                      setOrderItem((prev) => ({
                        ...prev,
                        primaryUOM: uom.value,
                        primaryUOMAbbr: uom.uomAbbr,
                        itemUnitOfMeasureId: null,
                        otherUOMStr: '',
                        otherUOMAbbr: '',
                        otherUOMCount: null,
                        uomType: UOMType.Pallet,
                        quantity: newValue.qty.toString(),
                        totalCases: '1',
                        isSerialNo: false,
                        isOrderByLPN: true,
                        palletInventoryId: newValue.value,
                        lpn: newValue.text,
                      }));
                    }}
                    id="controllable-states"
                    options={itemLPNDDL}
                    getOptionLabel={(option: PalletInventoryDropdownListItem) =>
                      option.text
                    }
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(
                      props,
                      option: PalletInventoryDropdownListItem,
                    ) => (
                      <li {...props} key={option.value}>
                        {option.text}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                        }}
                        {...(orderLineFormErrors.lpnError && {
                          error: true,
                          helperText: orderLineFormErrors.lpnError,
                        })}
                        autoComplete="off"
                        required
                        id="outlined-required"
                        label="LPN"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </MUIGrid>
              )}

              {isOrderByLPN?.value === NoYesEnum.No && (
                <>
                  <MUIGrid item xs={12} sm={12}>
                    <Autocomplete
                      disableClearable
                      onChange={(
                        event: any,
                        newValue: ItemUnitOfMeasureType,
                      ) => {
                        if (newValue !== null) {
                          setUomValue(newValue);

                          if (newValue.isPrimaryUOM) {
                            setOrderItem((prevState) => ({
                              ...prevState,
                              primaryUOM: newValue.value,
                              primaryUOMAbbr: newValue.uomAbbr,
                              itemUnitOfMeasureId: null,
                              otherUOMStr: '',
                              otherUOMAbbr: '',
                              otherUOMCount: null,
                              uomType: newValue.uomType,
                              quantity: '',
                              totalCases: null,
                              sellPrice: '',
                              isOrderByLPN: false,
                              palletInventoryId: null,
                              lpn: null,
                            }));
                          } else {
                            setOrderItem((prevState) => ({
                              ...prevState,
                              primaryUOM: newValue.primaryUOM,
                              primaryUOMAbbr: newValue.primaryUOMAbbr,
                              itemUnitOfMeasureId: newValue.value,
                              otherUOMStr: newValue.label,
                              otherUOMAbbr: newValue.uomAbbr,
                              otherUOMCount: newValue.qty,
                              uomType: newValue.uomType,
                              quantity: '',
                              totalCases: null,
                              sellPrice: '',
                              isOrderByLPN: false,
                              palletInventoryId: null,
                              lpn: null,
                            }));
                          }
                        } else {
                          setUomValue(null);
                          setOrderItem((prevState) => ({
                            ...prevState,
                            primaryUOM: 0,
                            primaryUOMAbbr: '',
                            itemUnitOfMeasureId: null,
                            otherUOMStr: '',
                            otherUOMAbbr: '',
                            otherUOMCount: null,
                            uomType: 0,
                            quantity: '',
                            totalCases: null,
                            sellPrice: '',
                            isOrderByLPN: false,
                            palletInventoryId: null,
                            lpn: null,
                          }));
                        }
                      }}
                      id="controllable-states"
                      options={optionsUOM}
                      getOptionLabel={(option: ItemUnitOfMeasureType) =>
                        option.label
                      }
                      value={uomValue}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderOption={(props, option: ItemUnitOfMeasureType) => (
                        <li
                          {...props}
                          key={option?.value}
                          data-uomabbr={option?.uomAbbr}
                          data-isprimaryuom={option?.isPrimaryUOM}
                          data-qty={option?.qty}
                          data-primaryuom={option?.primaryUOM}
                          data-primaryuomstr={option?.primaryUOMStr}
                          data-primaryuomabbr={option?.primaryUOMAbbr}
                          data-uomtype={option?.uomType}
                        >
                          {option?.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          {...(orderLineFormErrors.uomError && {
                            error: true,
                            helperText: orderLineFormErrors.uomError,
                          })}
                          required
                          id="outlined-required"
                          label="UOM"
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      )}
                      disabled={
                        (pageViewer.isViewOrEdit() &&
                          modalTitle === ModalTitleStatus.Edit) ||
                        skuValue === null
                      }
                    />
                  </MUIGrid>
                  {/* IF NOT PRIMARY UOM IS SELECTED */}
                  {uomValue?.isPrimaryUOM === false && (
                    <MUIGrid
                      container
                      spacing={6}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{
                        paddingLeft: '6%',
                        paddingTop: '4%',
                      }}
                    >
                      <MUIGrid item xs={6} sm={6}>
                        <TextField
                          inputProps={{
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          id="outlined"
                          label="Primary UOM"
                          value={uomValue.primaryUOMStr}
                          disabled
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={6} sm={6}>
                        <TextField
                          inputProps={{
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          id="outlined"
                          label="Primary Units per Package"
                          value={uomValue.qty}
                          size="small"
                          disabled
                          sx={{ width: '100%' }}
                        />
                      </MUIGrid>
                    </MUIGrid>
                  )}
                  {/* ----------------------------------- */}
                  <MUIGrid item xs={12} sm={12}>
                    <Autocomplete
                      filterOptions={filterOptions}
                      value={lotNoValue}
                      onChange={(event: any, newValue: any) => {
                        if (newValue !== null) {
                          setLotNoValue({
                            value: newValue?.id,
                            label: newValue?.label,
                            lotExpiration: newValue?.lotExpiration,
                          });
                          setOrderItem((prevState) => ({
                            ...prevState,
                            lotId: newValue.id,
                            lotNo: newValue.label,
                            lotExpiration: newValue?.lotExpiration,
                          }));
                        } else {
                          setLotNoValue(null);
                          setOrderItem((prevState) => ({
                            ...prevState,
                            lotId: null,
                            lotNo: '',
                            lotExpiration: null,
                          }));
                        }
                      }}
                      id="controllable-states"
                      options={optionLotNo}
                      getOptionLabel={(option: LotNoType) => option.label}
                      // noOptionsText={
                      //   <Button
                      //     variant="contained"
                      //     size="large"
                      //     onMouseDown={handleOnClickAddLotNoV2}
                      //     sx={{ padding: 1, width: '100%' }}
                      //   >
                      //     Add Lot No
                      //   </Button>
                      // }
                      defaultValue={optionLotNo?.find(
                        (o: LotNoType) => o.label === lotNoValue?.label,
                      )}
                      // isOptionEqualToValue={(option, selected) =>
                      //   option.value === selected
                      // }
                      {...(skuValue === null && {
                        disabled: true,
                      })}
                      renderOption={(props, option: LotNoType) => (
                        <li
                          {...props}
                          key={option?.value}
                          data-lotexpirationdate={option?.lotExpiration}
                        >
                          {option?.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          onChange={(e) => {
                            setOrderItem((prevState) => ({
                              ...prevState,
                              lotNo: e.target.value,
                            }));
                          }}
                          {...(orderLineFormErrors.lotNo && {
                            error: true,
                            helperText: orderLineFormErrors.lotNo,
                          })}
                          id="outlined"
                          label="Lot No."
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={12} sm={12}>
                    <DatePickerInput
                      {...(skuValue === null && {
                        disabled: true,
                      })}
                      label="Lot Exp. Date"
                      inputFormat="MM/dd/yyyy"
                      value={orderItem.lotExpiration}
                      onChange={(event) =>
                        relatedOnLotInputHandler('lotExpDate', null, event)
                      }
                      renderInput={(params) => (
                        <TextField {...params} error={false} size="small" />
                      )}
                    />
                  </MUIGrid>

                  {orderItem.isSerialNo && (
                    <MUIGrid item xs={12} sm={12}>
                      <Autocomplete
                        multiple
                        options={optionsSerialNumber}
                        value={serialNumbersValue}
                        disableCloseOnSelect
                        getOptionLabel={(
                          option: DropdownListItemType<string>,
                        ) => option.text}
                        isOptionEqualToValue={(option, selected) =>
                          option.value === selected.value
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.text}
                          </li>
                        )}
                        onChange={(
                          event,
                          newValue: DropdownListItemType<string>[],
                        ) => {
                          if (newValue !== null) {
                            setSerialNumbersValue(newValue);
                            setOrderItem((prevState) => ({
                              ...prevState,
                              serialNumbersValue: newValue,
                            }));
                          } else {
                            setSerialNumbersValue([]);
                            setOrderItem((prevState) => ({
                              ...prevState,
                              serialNumbersValue: [],
                            }));
                          }
                        }}
                        sx={{ width: '100%' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Serial No."
                            {...(orderLineFormErrors.serialNumbersValueError && {
                              error: true,
                              helperText:
                                orderLineFormErrors.serialNumbersValueError,
                            })}
                            placeholder="Serial No."
                          />
                        )}
                      />
                    </MUIGrid>
                  )}

                  {/* QUANTITY - IF PRIMARY UOM IS SELECTED */}
                  {uomValue?.isPrimaryUOM && (
                    <MUIGrid item xs={12} sm={12}>
                      <TextField
                        inputProps={{
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        required
                        {...(orderLineFormErrors.quantity && {
                          error: true,
                          helperText: orderLineFormErrors.quantity,
                        })}
                        id="outlined-required"
                        label="Quantity"
                        onChange={(e: any) => {
                          e.preventDefault();
                          const validateDecimal = decimalValidationHelper(
                            e.target.value,
                          );

                          setOrderItem((prev) => ({
                            ...prev,
                            quantity: validateDecimal,
                            totalCases: '',
                          }));
                        }}
                        onKeyPress={(e) =>
                          !/[0-9.]/.test(e.key) && e.preventDefault()
                        }
                        type="number"
                        value={orderItem.quantity || ''}
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    </MUIGrid>
                  )}

                  {/* QUANTITY - IF NOT PRIMARY UOM IS SELECTED */}
                  {uomValue?.isPrimaryUOM === false && (
                    <MUIGrid item xs={12} sm={12}>
                      <TextField
                        inputProps={{
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        required
                        {...(orderLineFormErrors.totalCases && {
                          error: true,
                          helperText: orderLineFormErrors.totalCases,
                        })}
                        id="outlined-required"
                        label="Quantity"
                        onChange={(e: any) => {
                          e.preventDefault();
                          const validateDecimal = decimalValidationHelper(
                            e.target.value,
                          );

                          const qty = validateDecimal;
                          const qtyE =
                            (uomValue.qty !== null ? uomValue.qty : 0) *
                              parseFloat(qty) || '';

                          setOrderItem((prev) => ({
                            ...prev,
                            quantity: qtyE.toString(),
                            totalCases: qty.toString(),
                          }));
                        }}
                        onKeyPress={(e) =>
                          !/[0-9.]/.test(e.key) && e.preventDefault()
                        }
                        type="number"
                        value={orderItem.totalCases || ''}
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    </MUIGrid>
                  )}

                  {/* IF NOT PRIMARY UOM IS SELECTED */}
                  {uomValue?.isPrimaryUOM === false && (
                    <MUIGrid item xs={12} sm={12}>
                      <TextField
                        disabled
                        inputProps={{
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        id="outlined-required"
                        label="Total Quantity"
                        value={orderItem.quantity || ''}
                        {...(orderItem.isSerialNo &&
                          orderItem.quantity &&
                          orderItem.serialNumbersValue.length !==
                            Number(orderItem.quantity) && {
                            error: true,
                            helperText: orderLineFormErrors.quantity,
                          })}
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    </MUIGrid>
                  )}
                </>
              )}

              <MUIGrid item xs={12} sm={12}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  {...(orderLineFormErrors.sellPrice && {
                    error: true,
                    helperText: orderLineFormErrors.sellPrice,
                  })}
                  id="outlined-required"
                  label="Sell Price Per Unit"
                  onChange={(e) => {
                    e.preventDefault();
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setOrderItem((prev) => ({
                      ...prev,
                      sellPrice: validateDecimal,
                    }));
                  }}
                  onKeyPress={(e) =>
                    !/[0-9.]/.test(e.key) && e.preventDefault()
                  }
                  type="number"
                  value={orderItem.sellPrice || ''}
                  size="small"
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={9}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => {
                  onCloseOrderItemModal();
                  setModalTitle(null);
                  setSkuValue(null);
                  setUomValue(null);
                  setLotNoValue(null);
                  setOptionsSerialNumber([]);
                  setSerialNumbersValue([]);
                  setOrderLineFormErrors(initialOrderItemState);
                }}
                variant="outlined"
                size="medium"
                sx={{ padding: 1, width: '100%' }}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                size="medium"
                sx={{ padding: 1, width: '100%' }}
                onClick={(e) => {
                  hanldeOrderlineItemOnClick(e);
                }}
              >
                {modalTitle === ModalTitleStatus.Add && <>ADD</>}

                {modalTitle === ModalTitleStatus.Edit && <>UPDATE</>}
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>

        <ImportGlobalMessageModal title="Duplicate LPN" />
        <ConfirmationModal
          dialogText={lpnValidationMessage}
          callBack={lpnValidationCallback}
        />
      </MUIContainer>
    </Modal>
  );
});
