import React, { useState } from 'react';
import { ModalBox, ModalContent } from 'components/styles';
import { Close } from '@mui/icons-material';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { IModalInputNumber } from 'common/props';

export const ModalInputNumber = (props: IModalInputNumber) => {
  const { Title, IsOpen, OnConfirm, OnModalClose } = props;
  const [Value, SetValue] = useState(1);
  const OnConfirmClick = async () => {
    const newValue = Value;
    SetValue(1);
    OnConfirm(newValue);
  };
  return (
    <Modal open={IsOpen}>
      <ModalBox>
        <ModalContent>
          <Typography variant="body1">
            <strong>{Title}</strong>
            <IconButton
              onClick={OnModalClose}
              style={{ float: 'right', padding: 0 }}
            >
              <Close fontSize="small" />
            </IconButton>
          </Typography>
          <FormControl
            sx={{ m: 1, width: '25ch' }}
            variant="outlined"
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: 15,
              padding: 0,
              margin: 0,
            }}
          >
            <OutlinedInput
              id="location-number"
              endAdornment={<InputAdornment position="end">#</InputAdornment>}
              inputProps={{
                'aria-label': 'Number Value',
              }}
              type="number"
              value={Value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                SetValue(Number(event.target.value));
              }}
            />
            <Button
              variant="contained"
              size="medium"
              sx={{ flex: 'auto' }}
              onClick={OnConfirmClick}
            >
              Confirm
            </Button>
          </FormControl>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
};
