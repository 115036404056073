import React, { useContext } from 'react';

import { UserPermissionEnum } from 'common/enums';
import { AuthContext } from 'store/contexts/AuthContext';

import { Typography, Box, Button } from '@mui/material';
import { GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';

interface CustomGridToolbarProps {
  title?: string;
  showQuickFilter?: boolean;
  showExportButton?: boolean;
  showAdjustInventoryButton?: boolean;
  showAddSupplierButton?: boolean;
  showDeleteInventoryButton?: boolean;
  disableDeleteInventoryButton?: boolean;
  adjustInventoryMethod?: (e: React.SyntheticEvent) => void;
  deleteInventoryMethod?: (e: React.SyntheticEvent) => void;
  addSupplierFromInventoryOpenModalMethod?: (e: React.SyntheticEvent) => void;
}

function CustomGridToolbar({
  title,
  showQuickFilter = true,
  showExportButton = true,
  showAdjustInventoryButton = false,
  showAddSupplierButton = false,
  showDeleteInventoryButton = false,
  disableDeleteInventoryButton = false,
  adjustInventoryMethod,
  deleteInventoryMethod,
  addSupplierFromInventoryOpenModalMethod,
}: CustomGridToolbarProps) {
  const { handleUserPermissionAllow } = useContext(AuthContext);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '8px',
        }}
      >
        <Typography variant="h6" fontWeight="bold" sx={{ color: 'black' }}>
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '8px',
            padding: '7px',
          }}
        >
          {showAddSupplierButton && (
            <Button
              variant="outlined"
              size="small"
              onClick={addSupplierFromInventoryOpenModalMethod}
            >
              Add Supplier
            </Button>
          )}
          {showAdjustInventoryButton ? (
            <Button
              variant="outlined"
              size="small"
              onClick={adjustInventoryMethod}
            >
              Add Inventory
            </Button>
          ) : (
            <span />
          )}
          {showDeleteInventoryButton &&
          handleUserPermissionAllow(
            UserPermissionEnum.Inventory_DeleteItems,
          ) ? (
            <Button
              variant="outlined"
              size="small"
              disabled={disableDeleteInventoryButton}
              onClick={deleteInventoryMethod}
            >
              Delete Inventory
            </Button>
          ) : (
            <span />
          )}

          {showExportButton ? <GridToolbarExport /> : <span />}
          {showQuickFilter ? (
            <GridToolbarQuickFilter
              variant="outlined"
              size="small"
              sx={{ pb: 0 }}
            />
          ) : (
            <span />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(CustomGridToolbar);
